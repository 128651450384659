import { Button } from 'components/button/button'
import { ChevronLeft } from './../../assets/icons'

export function GoBack(props: {
  goBack(): void
  className?: string
}): JSX.Element {
  return (
    <Button
      onClick={() => props.goBack()}
      buttonType='link'
      autoWidth
      content={<ChevronLeft color='iconPrimaryInitial' size={38} />}
      shape='square'
    />
  )
}
