import { TransactionFiltersStatusParam, TransactionsTab } from 'interfaces'

export const getTransactionStatusForTab = (
  tab: TransactionsTab,
): TransactionFiltersStatusParam => {
  if (tab === TransactionsTab.closed) {
    return TransactionFiltersStatusParam.CANCELLED
  }
  if (tab === TransactionsTab.pending) {
    return TransactionFiltersStatusParam.PENDING
  }
  if (tab === TransactionsTab.executed) {
    return TransactionFiltersStatusParam.COMPLETED
  }

  return null
}
