import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'
import './assessmentModal.styles.scss'
import { useTranslation } from '@hooks'
import {
  CustomModal,
  BigSpinner,
  Button,
  InputRadio,
  Show,
  Spacer,
  Text,
  BasicLink,
} from '@components'
import {
  GlobalAssetManager,
  ResistentToVolatility,
  AlignedToNeeds,
} from '@assets/illustrations/savingPlans'
import { useEffect, useMemo, useState } from 'react'
import {
  AssessmentAnswers,
  AssessmentChoiceType,
  AssessmentQuestion,
  SavingPlansInfoCard,
} from '@interfaces'
import {
  fetchAssessmentEvaluation,
  fetchSavingsPlansAssessment,
  saveSavingsPlansAssessment,
} from '@store/commonReducers/savingsPlans'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useDispatch, useSelector } from 'react-redux'

import SwiperCore, { Pagination, Navigation } from 'swiper/core'
import { RootState } from 'store'
import { AssessmentIncorrectIcon } from '@assets/svg'
import { ReactComponent as SuccessIcon } from '@assets/svg/success.svg'
import { useNavigate } from '@hooks'

// install Swiper used modules

SwiperCore.use([Pagination, Navigation])

export enum AssessmentModalMode {
  presentation = 'presentation',
  questions = 'questions',
  results = 'results',
}

export default function AssessmentModal({
  onClose,
  onStartTrading,
  step,
}: {
  onClose: () => void
  onStartTrading?: () => void
  step?: AssessmentModalMode
}): JSX.Element {
  const { t } = useTranslation()
  const { navigate } = useNavigate()
  const dispatch = useDispatch()

  const {
    loadingSave,
    assessmentSaved,
    showAssessmentCard,
    riskAssessment,
    riskAssessmentEvaluation,
  } = useSelector((state: RootState) => state.savingsPlans)

  const assessmentQuestions = riskAssessment?.questions

  const cancelText = t('common.cancel')

  const [mode, setMode] = useState<AssessmentModalMode>(
    step || AssessmentModalMode.presentation,
  )

  const [controlledSwiper, setControlledSwiper] = useState(null)
  const [buttonEnabled, setButtonEnabled] = useState(false)
  const [showCloseModal, setShowCloseModal] = useState(false)

  const [resultsTitle, setResultsTitle] = useState('')
  const [resultsDesc, setResultsDesc] = useState('')
  const [resultsIcon, setResultsIcon] = useState(null)

  const [requiredAnswers, setRequiredAnswers] = useState<string[]>()

  const assessmentSelection: AssessmentAnswers = useMemo(
    () =>
      assessmentQuestions?.length
        ? assessmentQuestions?.reduce((acc, { id }) => {
            return { ...acc, [`${id}`]: '' }
          }, {})
        : {},
    [assessmentQuestions],
  )

  const [assessmentSelections, setAssessmentSelections] =
    useState<AssessmentAnswers>(assessmentSelection)

  const infoCards: SavingPlansInfoCard[] = [
    {
      id: 'globalAssetManager',
      image: <GlobalAssetManager />,
      title: t('savingsPlans.globalAssetManagerTitle'),
      description: t('savingsPlans.globalAssetManagerDesc'),
    },
    {
      id: 'resilientToVolatility',
      image: <ResistentToVolatility />,
      title: t('savingsPlans.resilientToVolatilityTitle'),
      description: t('savingsPlans.resilientToVolatilityDesc'),
    },
    {
      id: 'alignToYourNeeds',
      image: <AlignedToNeeds />,
      title: t('savingsPlans.alignToYourNeedsTitle'),
      description: t('savingsPlans.alignToYourNeedsDesc'),
    },
  ]

  const updateSwiper = (swiper: SwiperCore): void => {
    setControlledSwiper(swiper)
    swiper.slideTo(0)
    swiper.update()
    swiper.updateProgress()
    swiper.updateSlides()
  }

  const swiperCommonSettings = {
    pagination: true,
    navigation: true,
    observer: true,
    observeParents: true,
    onObserverUpdate: updateSwiper,
  }

  const handleOptionPressed = (
    questionId: string,
    choiceId: string,
    multipleChoice: boolean,
  ): void => {
    if (multipleChoice) {
      const checkedOptions = assessmentSelections[`${questionId}`]
      const selectedIdx = checkedOptions.indexOf(choiceId)
      if (selectedIdx === -1) {
        // Not present, we add it
        setAssessmentSelections({
          ...assessmentSelections,
          [`${questionId}`]: [...checkedOptions, `${questionId}_${choiceId}`],
        })
      } else {
        checkedOptions.splice(selectedIdx, 1)
        setAssessmentSelections({
          ...assessmentSelections,
          [`${questionId}`]: checkedOptions,
        })
      }
    } else {
      setAssessmentSelections({
        ...assessmentSelections,
        [`${questionId}`]: [`${questionId}_${choiceId}`],
      })
      controlledSwiper.slideNext()
    }
  }

  const handleCloseModal = (): void => {
    setShowCloseModal(true)
    onClose()
  }

  const handleSubmit = (): void => {
    const formattedAnswers = Object.keys(assessmentSelections).reduce(
      (prev, key) => {
        return assessmentSelections[key]
          ? {
              ...prev,
              [key]: assessmentSelections[key].map((e) => e.split('_')[1]),
            }
          : { ...prev }
      },
      {},
    )

    dispatch(saveSavingsPlansAssessment(formattedAnswers))
    dispatch(fetchAssessmentEvaluation())
    setMode(AssessmentModalMode.results)
  }

  const goToSavingPlans = (): void => {
    navigate('/discover/blk', { replace: true })
  }

  const renderChoices = (question: AssessmentQuestion): JSX.Element => {
    const { id: questionId, choices, choiceType: type } = question
    return (
      <>
        {choices?.length
          ? choices?.map((choice, idx) => (
              <div
                className={`question-item__option paragraph2 tertiary--text mb-3 cursor-pointer`}
                key={`question_${questionId}_${idx}`}
                onClick={() => {
                  handleOptionPressed(
                    questionId,
                    choice.id,
                    type === AssessmentChoiceType.multipleSelection,
                  )
                }}
                // The following properties are for accessibility reasons
                // eslint-disable-next-line sonarjs/no-identical-functions
                onKeyPress={() => {
                  handleOptionPressed(
                    questionId,
                    choice.id,
                    type === AssessmentChoiceType.multipleSelection,
                  )
                }}
                tabIndex={idx}
                aria-selected='false'
                role='option'
              >
                {idx !== 0 ? (
                  <div className='question-item__option-divider mb-3' />
                ) : null}
                {type === AssessmentChoiceType.singleSelection ? (
                  <InputRadio
                    position='left'
                    containerStyle='d-flex align-center input-disable' // Solve double jump bug
                    id={`${questionId}_${choice.id}`}
                    value={`${questionId}_${choice.id}`}
                    checked={
                      assessmentSelections?.[questionId]?.[0] ===
                      `${questionId}_${choice.id}`
                    }
                    text={choice.text}
                    name={`${questionId}_${choice.id}`}
                  />
                ) : (
                  <div className='d-flex align-center'>
                    <input
                      type='checkbox'
                      id={`${questionId}_${choice.id}`}
                      readOnly
                      checked={
                        !!assessmentSelections?.[questionId]?.length &&
                        assessmentSelections[questionId].includes(
                          `${questionId}_${choice.id}`,
                        )
                      }
                      className='mr-2'
                      value={`${questionId}_${choice.id}`}
                      name={`${questionId}_${choice.id}`}
                    />
                    <label className='cursor-pointer text-left'>
                      {choice.text}
                    </label>
                  </div>
                )}
              </div>
            ))
          : null}
      </>
    )
  }

  const renderQuestion = (question: AssessmentQuestion): JSX.Element => {
    return (
      <SwiperSlide key={`${question?.id}-slide`}>
        <div className='question-item' key={question?.id}>
          <div className='question-item__title headline2 mb-1'>
            {question?.title}
          </div>
          <div className='question-item__description paragraph2 mb-5'>
            {question?.text}
          </div>
          {question?.choices?.length ? renderChoices(question) : null}
        </div>
      </SwiperSlide>
    )
  }

  const renderInfoElement = (card: SavingPlansInfoCard): JSX.Element => {
    return (
      <SwiperSlide key={`${card?.id}-slide`}>
        <div className='item'>
          <div className='item__image'>{card?.image}</div>
          <div className='item__title headline3 mb-1'>{card?.title}</div>
          <div className='item__description paragraph2 tertiary--text mb-3'>
            {card?.description}
          </div>
        </div>
      </SwiperSlide>
    )
  }

  useEffect(() => {
    dispatch(fetchSavingsPlansAssessment())
  }, [dispatch])

  useEffect(() => {
    setAssessmentSelections(assessmentSelection)

    setRequiredAnswers(
      assessmentQuestions?.reduce(
        (acc, { id, required }) => (required ? [...acc, id] : acc),
        [],
      ),
    )
  }, [assessmentQuestions, assessmentSelection])

  useEffect(() => {
    if (!assessmentSelections) {
      return
    }
    if (mode === AssessmentModalMode.questions) {
      const selections = Object.values(assessmentSelections)
      const answeredQuestions = Object.keys(assessmentSelections).reduce(
        (acc, ele, idx) => {
          if (selections[idx].length > 0) {
            return [...acc, ele]
          }
          return acc
        },
        [],
      )
      setButtonEnabled(
        requiredAnswers?.every((required) =>
          answeredQuestions?.includes(required),
        ),
      )
      return
    }
    setButtonEnabled(false)
  }, [assessmentSelections, mode, requiredAnswers])

  useEffect(() => {
    if (riskAssessmentEvaluation) {
      if (riskAssessmentEvaluation.testPassed) {
        setResultsTitle(t('savingsPlans.results.passedTitle'))
        setResultsDesc(t('savingsPlans.results.passedText'))
        setResultsIcon(<SuccessIcon className='resultsIcon' />)
        return
      }
      const incongruitiesId =
        riskAssessmentEvaluation.incongruitiesId || 'notPassed'
      const resultTexts = {
        notPassed: {
          title: t('savingsPlans.results.notPassedTitle'),
          text: t('savingsPlans.results.notPassedText'),
        },
        studiesLevel: {
          title: t('savingsPlans.results.inconsistentTitle'),
          text: t('savingsPlans.results.inconsistentStudiesText'),
        },
        professionalExperience: {
          title: t('savingsPlans.results.inconsistentTitle'),
          text: t('savingsPlans.results.inconsistentExperienceText'),
        },
        notPassedForTrade: {
          title: t('savingsPlans.results.notPassedTitleInDetails'),
          text: t('savingsPlans.results.notPassedTextInDetails'),
        },
      }
      setResultsTitle(resultTexts[incongruitiesId]?.title)
      setResultsDesc(resultTexts[incongruitiesId]?.text)
      setResultsIcon(<AssessmentIncorrectIcon className='resultsIcon' />)
    }
  }, [mode, riskAssessmentEvaluation, t])

  const loadingResults =
    !riskAssessmentEvaluation || loadingSave || !assessmentSaved

  return (
    <>
      <CustomModal show placeOn='right' height='fullHeight'>
        {mode === AssessmentModalMode.presentation ? (
          <>
            <CustomModal.Header
              text={t('common.moreInfo')}
              linkText={cancelText}
              className='subtitle3 dark--label-title'
              onClick={onClose}
            />
            <CustomModal.Content>
              <div className='assessmentModalCarousel'>
                <Swiper {...swiperCommonSettings}>
                  {infoCards?.map((card) => renderInfoElement(card))}
                </Swiper>
              </div>
              <div className=''></div>
            </CustomModal.Content>
            <CustomModal.Footer className='assessmentModalFooter'>
              {showAssessmentCard ? (
                <div className='mb-2 assessmentModalFooter__note'>
                  {t('savingsPlans.assessmentNeeded')}
                </div>
              ) : null}
              {assessmentSaved ? (
                <Button
                  text={t('savingsPlans.startTrading')}
                  type='submit'
                  size='big'
                  disabled={false}
                  loading={false}
                  onClick={goToSavingPlans}
                />
              ) : (
                <Button
                  text={t('savingsPlans.startAssessment')}
                  type='submit'
                  size='big'
                  disabled={false}
                  loading={false}
                  onClick={() => {
                    setMode(AssessmentModalMode.questions)
                  }}
                />
              )}
            </CustomModal.Footer>
          </>
        ) : null}

        {assessmentQuestions?.length &&
        mode === AssessmentModalMode.questions ? (
          <>
            <CustomModal.Header
              linkText={cancelText}
              className='subtitle3 dark--label-title'
              onClick={() => setShowCloseModal(true)}
            />
            <CustomModal.Content>
              <div className='assessmentModalCarousel'>
                <Swiper
                  {...swiperCommonSettings}
                  shortSwipes={false}
                  longSwipesRatio={0.15}
                  spaceBetween={50}
                >
                  {assessmentQuestions
                    ? assessmentQuestions?.map((q) => renderQuestion(q))
                    : null}
                </Swiper>
              </div>
            </CustomModal.Content>
            <CustomModal.Footer>
              <Button
                text={t('common.submit')}
                type='submit'
                size='big'
                disabled={!buttonEnabled}
                loading={false}
                onClick={handleSubmit}
              />
            </CustomModal.Footer>
          </>
        ) : null}

        {mode === AssessmentModalMode.results ? (
          <>
            <CustomModal.Header
              linkText={cancelText}
              className='subtitle3 dark--label-title'
              onClick={onClose}
            />
            <CustomModal.Content className='d-flex modalCenter'>
              {loadingResults ? (
                <BigSpinner />
              ) : (
                <div className='d-flex d-flex-col modalCenter'>
                  <div className='resultsIcon'>{resultsIcon}</div>
                  <span className='headline1 primary-text text-center mt-3 mb-5'>
                    {resultsTitle}
                  </span>
                  <div className='resultDescriptionBox'>
                    <p className='space text-center primary--text paragraph2'>
                      {resultsDesc}
                    </p>
                  </div>
                </div>
              )}
            </CustomModal.Content>

            <CustomModal.Footer className='d-flex d-flex-col'>
              <Button
                text={t('savingsPlans.startTrading')}
                type='submit'
                size='big'
                loading={loadingResults}
                disabled={loadingResults}
                onClick={onStartTrading}
              />
            </CustomModal.Footer>
          </>
        ) : null}
      </CustomModal>
      <Show when={showCloseModal}>
        <CustomModal show theme='lighter' size='small'>
          <CustomModal.Header text={t('savingsPlans.assessmentIncomplete')} />
          <CustomModal.Content>
            <Spacer preset='small' />
            <Text
              preset='paragraph1'
              text={t('savingsPlans.assessmentClosedDesc')}
              align='center'
            />
            <Spacer preset='tiny' />
            <Text
              preset='paragraph1'
              text={t('savingsPlans.assessmentClosedDesc2')}
              align='center'
            />
            <Spacer preset='small' />
          </CustomModal.Content>
          <CustomModal.Footer>
            <div className='d-flex d-flex-col w-100'>
              <Button
                text={t('savingsPlans.resumeAssessment')}
                onClick={() => setShowCloseModal(false)}
                buttonType='primary'
              />
              <BasicLink
                text={t('common.exit')}
                type='action'
                className='w-100 mt-3'
                onClick={handleCloseModal}
              />
            </div>
          </CustomModal.Footer>
        </CustomModal>
      </Show>
    </>
  )
}
