import { ChangeEvent } from 'react'
import { InputRadioProps } from './inputRadio.props'
import './inputRadio.styles.scss'
import { CheckInCircleIcon } from '@assets/icons/checkInCircleIcon'

export const InputRadio = ({
  id,
  defaultChecked,
  checked,
  onChange,
  text,
  value,
  containerStyle,
  name,
  subtitle,
  position = 'right',
  textStyle,
  textContainerStyle,
  children,
}: InputRadioProps): JSX.Element => {
  const handleOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (onChange) {
      onChange(event)
    }
  }

  const container = containerStyle
    ? `${containerStyle} position-relative`
    : 'position-relative'

  return (
    <div className={container}>
      <input
        data-position={position}
        type='radio'
        id={id}
        value={value}
        defaultChecked={defaultChecked}
        checked={checked}
        onChange={handleOnChange}
        name={name}
      />
      <label htmlFor={id} className={textContainerStyle}>
        <div className={`d-flex ${textContainerStyle as string}`}>
          {children}
          {(text || subtitle) && (
            <div className='d-flex d-flex-col'>
              {text && <span className={textStyle}>{text}</span>}
              {subtitle && <span className='subtitle'>{subtitle}</span>}
            </div>
          )}
        </div>
        <i className='icon'>
          <CheckInCircleIcon
            size={20}
            color='iconPrimaryInitial'
            secondaryColor='surfacePositiveSubtleInitial'
          />
        </i>
      </label>
    </div>
  )
}
