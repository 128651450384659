import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { getSepaConditions } from '@services'
import { BankTransferInfo } from '@interfaces'

export const fetchSepaConditions = createAsyncThunk<BankTransferInfo>(
  'fetchSepaConditions',
  () => getSepaConditions(),
)

export const clearSepaConditions = createAction('clearSepaConditions')
