import { createAsyncThunk } from '@reduxjs/toolkit'
import { getBankAccounts } from '@services'
import { Accounts } from '@interfaces'

export const fetchBankAccount = createAsyncThunk<Accounts, { bankId: string }>(
  'fetchBankAccount',
  async ({ bankId }) => {
    return await getBankAccounts(bankId)
  },
)
