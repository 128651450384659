import { SearchState } from '@interfaces'
import { api } from '@utils/api/api'
import { buildQueryParams } from '@utils/helpers'
import { SelectedFilters } from '@interfaces'

/**
 * Retrieves stock list, calling to heyTrade info provider.
 * @param query Filter by ticker or name
 * @param page The current page number
 * @param assetTypes List of asset types to include or exclude. Savings plans excluded by default
 * Include stocks and ETF's: STOCK, ETF
 * Exclude stocks and ETF's: !STOCK, !ETF
 * Include savings plans: SAVING_PLAN
 * Exclude savings plans: !SAVING_PLAN
 * You can't mix excludes and includes at the same time.
 * @returns stock list
 */
export const getStocks = (
  query = '',
  page = 0,
  assetTypes = '',
  selectedFilters: SelectedFilters = {},
): Promise<SearchState> => {
  let filters: SelectedFilters = {
    page: page.toString(),
    query,
  }

  if (assetTypes) {
    filters = {
      ...filters,
      assetTypes,
    }
  }

  if (selectedFilters) {
    filters = {
      ...filters,
      ...selectedFilters,
    }
  }

  const params = buildQueryParams(filters)

  return api.get(`api/v1/stocks${Object.keys(filters)?.length ? params : ''}`)
}
