import { FiltersResponse } from '@interfaces/searchFilters'
import { FilterValue, SelectedFilters } from 'interfaces'
import { RawTreeNode, TreeNode, createTree } from 'tr33'

export const prepareTreeStructure = (
  filters: FiltersResponse,
  level: number,
): Array<RawTreeNode<FilterValue>> => {
  if (!filters?.length) return []
  return filters?.map((f) => {
    let r: RawTreeNode<FilterValue> = {
      id: f?.id,
      value: {
        name: f?.name || null,
        logo: f?.logo || null,
        checked: false,
        level: level,
        hasChildren: !!f?.options?.length,
      },
    }

    if (f?.options) {
      r = {
        ...r,
        children: prepareTreeStructure(f?.options, level + 1),
      }
    }

    return r
  })
}

export const getTrees = (
  value: Array<RawTreeNode<FilterValue>>,
): Array<TreeNode<FilterValue>> => {
  return createTree(value) as Array<TreeNode<FilterValue>>
}

export const treesToRaw = (
  items: Array<TreeNode<FilterValue>>,
): Array<RawTreeNode<FilterValue>> => items?.map((i) => i.value())

export const findNode = (
  arr: Array<TreeNode<FilterValue>>,
  id: string,
): TreeNode<FilterValue> => {
  for (let i = 0; i < arr.length; i++) {
    const item = arr[i]?.find(id)
    if (item) return item
  }

  return null
}

export const getFilterSelectedOptions = (
  filter: RawTreeNode<FilterValue>,
  key?: string,
): SelectedFilters[] => {
  const options = filter?.children?.reduce(
    (accu: string, item: RawTreeNode<FilterValue>) => {
      const showComma = accu?.length === 0 ? '' : ','
      const param = key ? `${filter?.id}.${item?.id}` : item?.id
      return item?.value?.checked ? `${accu}${showComma}${param}` : accu
    },
    '',
  )
  return options ? [{ [key ? key : filter.id]: options }] : []
}
