import React from 'react'
import ReactDOM from 'react-dom'
import './styles/index.scss'
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import './i18n'
import store from './store/store'
import { Provider } from 'react-redux'
import App from './App'
import { changeTheme } from './utils/theme'
import * as Sentry from '@sentry/react'
import { shortenEnvironment } from './utils/helpers'
import { environment } from './config'

// import reportWebVitals from './reportWebVitals'
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    analytics: any
  }
}

Sentry.init({
  dsn: 'https://e3489e0438b3bf67743e5038f2c5d835@o1010857.ingest.us.sentry.io/4507129794461696',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  debug: false,
  tracesSampleRate: 1,
  maxBreadcrumbs: 50,
  environment: shortenEnvironment(environment?.env), // 'dev' | 'pre' | 'pro' ,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

if (localStorage.getItem('heySettings')) {
  const settings = JSON.parse(localStorage.getItem('heySettings') || '')
  if (settings.theme)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    changeTheme(settings.theme)
} else {
  changeTheme()
}

const preferDark = '(prefers-color-scheme: dark)'
const modeData = (): void => {
  let toSetTheme = 'default'
  if (window.matchMedia) {
    window.matchMedia(preferDark).matches
      ? (toSetTheme = 'dark')
      : (toSetTheme = 'light')
  }
  document
    .getElementsByTagName('body')[0]
    .setAttribute('data-theme', toSetTheme)
}

try {
  window.matchMedia(preferDark).addEventListener('change', modeData)
} catch (e1) {
  try {
    // Support for old browsers which doesn't support "addEventListener"
    // i.e. Safari old versions
    window.matchMedia(preferDark).addListener(modeData)
  } catch (e2) {
    // do nothing yet
  }
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
