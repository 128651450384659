// eslint-disable no-unused-vars
import {
  extraDigits,
  formatCurrency,
  formatEndDateRemaining,
  formatMoney,
} from '@utils/helpers'
import {
  OrderOperationTypeEnum,
  OrderTypeEnum,
  PreOrderData,
} from '@interfaces'
import { SummaryCardProps, SummaryRowProps } from '@components'
import { t } from 'i18next'
import { InfoIcon } from '@assets/svg'
import { trackingService } from '@services'
import { LimitOrderEvents } from '@utils/eventTracker/eventKeys'

const estimatedTotalLabel = 'buySellFlow.summary.estimatedTotal'
const estimatedEarningsLabel = 'buySellFlow.estimatedEarnings'

export function getPreOrderFormattedFxShares(checkout: PreOrderData): string {
  return `${formatMoney(
    checkout?.forexRate,
    checkout?.totalCost?.currency,
    4,
  )} = ${formatMoney(1, checkout?.asset?.currency)}`
}

export function getOperationTitle(
  isBuy: boolean,
  orderType: OrderTypeEnum,
): string {
  if (isBuy && orderType === OrderTypeEnum.market) {
    return 'buySellFlow.orderTypes.buyMarketOrder'
  }

  if (isBuy && orderType === OrderTypeEnum.limit) {
    return 'buySellFlow.orderTypes.buyLimitOrder'
  }

  if (!isBuy && orderType === OrderTypeEnum.market) {
    return 'buySellFlow.orderTypes.sellMarketOrder'
  }

  if (!isBuy && orderType === OrderTypeEnum.limit) {
    return 'buySellFlow.orderTypes.sellLimitOrder'
  }

  return ''
}

export function isLimitOrder(preorder: PreOrderData): boolean {
  return preorder?.method === OrderTypeEnum.limit
}

export function isMarketOrder(preorder: PreOrderData): boolean {
  return preorder?.method === OrderTypeEnum.market
}

export function getBalanceCashColor(checkout: PreOrderData): string {
  return checkout?.enoughCashForOrder ? 'orange--text' : 'negative--text'
}

export function getSharesCashColor(availableForSaleShares: number): string {
  return availableForSaleShares ? 'orange--text' : 'negative--text'
}

const showTotalToWithholdExplanation = (showInfoModal): void => {
  showInfoModal(
    t('buySellFlow.summary.totalToWithhold'),
    t('buySellFlow.summary.totalToWithholdExplanation'),
  )
}

const showTotalRequiredExplanation = (showInfoModal): void => {
  showInfoModal(
    t('buySellFlow.summary.totalRequired'),
    t('buySellFlow.summary.totalToWithholdExplanation'),
  )
}
const showEstimatedTotalExplanation = (showInfoModal): void => {
  showInfoModal(
    t(estimatedTotalLabel),
    t('buySellFlow.summary.estimatedTotalExplanation'),
  )
}

const showEstimatedEarningsExplanation = (showInfoModal): void => {
  showInfoModal(
    t(estimatedEarningsLabel),
    t('buySellFlow.summary.estimatedEarningsExplanation'),
  )
}

const balanceInfoNote = (
  checkout: PreOrderData,
  showBalanceModal: () => void,
): JSX.Element => {
  return (
    <div
      onClick={showBalanceModal}
      className={`d-flex paragraph3 mt-1 cursor-pointer ${getBalanceCashColor(
        checkout,
      )}`}
      role='button'
      aria-hidden
    >
      {t('buySellFlow.balanceAvailableToInvest', {
        cashAvailable: formatCurrency(checkout?.cashAvailableForInvesting),
      })}
    </div>
  )
}

const availableSharesNote = (
  checkout: PreOrderData,
  availableForSaleShares: number,
  showInfoModal: (title: string, content: string) => void,
): JSX.Element => {
  const showAvailableSharesModal = (): void => {
    showInfoModal(
      t('buySellFlow.availableForSaleModalTitle'),
      t('buySellFlow.sharesAvailableForSaleExplanation'),
    )
  }

  return (
    <div
      onClick={showAvailableSharesModal}
      className={`d-flex paragraph3 mt-1 cursor-pointer ${getSharesCashColor(
        availableForSaleShares,
      )}`}
      role='button'
      aria-hidden
    >
      {t('buySellFlow.sharesAvailableForSale', {
        maxShares: availableForSaleShares,
        symbol: checkout?.asset?.ticker,
      })}
    </div>
  )
}

export function getMarketOrderTotalRow(
  checkout: PreOrderData,
  showBalanceModal: () => void,
  showInfoModal: (title: string, content: string) => void,
  availableForSaleShares: number,
): SummaryRowProps {
  const isBuy = checkout?.type === OrderOperationTypeEnum.buy

  const firstTotalValue = formatCurrency(
    isBuy ? checkout?.lockedCash : checkout?.totalCost,
  )

  const firstRowTotalHandler = (): void => {
    isBuy
      ? showTotalRequiredExplanation(showInfoModal)
      : showEstimatedEarningsExplanation(showInfoModal)
  }

  return {
    labelChildren: (
      <div className='totalRow w-100'>
        <div className='mb-1 justify-space-between d-flex'>
          <div className='d-flex align-center'>
            <p className='headline4'>
              {isBuy
                ? t('buySellFlow.summary.totalRequired')
                : t(estimatedEarningsLabel)}
            </p>
            <InfoIcon
              className='ml-1 cursor-pointer'
              onClick={firstRowTotalHandler}
            />
          </div>
          <p className='headline4'>{firstTotalValue}</p>
        </div>
        {isBuy ? (
          <div className='justify-space-between d-flex'>
            <div className='d-flex align-center'>
              <p className='paragraph2'>{t(estimatedTotalLabel)}</p>
              <InfoIcon
                className='ml-1 cursor-pointer'
                onClick={() => showEstimatedTotalExplanation(showInfoModal)}
              />
            </div>
            <p className='paragraph2'>{formatCurrency(checkout?.totalCost)}</p>
          </div>
        ) : null}
        <div className='d-flex justify-end'>
          {isBuy
            ? balanceInfoNote(checkout, showBalanceModal)
            : availableSharesNote(
                checkout,
                availableForSaleShares,
                showInfoModal,
              )}
        </div>
      </div>
    ),
  }
}

export function getLimitOrderTotalRow(
  checkout: PreOrderData,
  showBalanceModal: () => void,
  showInfoModal: (title: string, content: string) => void,
  availableForSaleShares: number,
): SummaryRowProps {
  const isBuy = checkout?.type === OrderOperationTypeEnum.buy

  const firstTotalValue = formatCurrency(
    isBuy ? checkout?.lockedCash : checkout?.totalCost,
  )

  const firstRowTotalHandler = (): void => {
    isBuy
      ? showTotalToWithholdExplanation(showInfoModal)
      : showEstimatedTotalExplanation(showInfoModal)
  }

  return {
    labelChildren: (
      <div className='totalRow w-100'>
        <div className='mb-1 justify-space-between d-flex'>
          <div className='d-flex align-center'>
            <p className='headline4'>
              {isBuy
                ? t('buySellFlow.summary.totalToWithhold')
                : t(estimatedEarningsLabel)}
            </p>
            <InfoIcon
              className='ml-1 cursor-pointer'
              onClick={firstRowTotalHandler}
            />
          </div>
          <p className='headline4'>{firstTotalValue}</p>
        </div>
        {isBuy ? (
          <div className='justify-space-between d-flex'>
            <div className='d-flex align-center'>
              <p className='paragraph2'>{t(estimatedTotalLabel)}</p>
              <InfoIcon
                className='ml-1 cursor-pointer'
                onClick={() => showEstimatedTotalExplanation(showInfoModal)}
              />
            </div>
            <p className='paragraph2'>{formatCurrency(checkout?.totalCost)}</p>
          </div>
        ) : null}
        <div className='d-flex justify-end'>
          {isBuy
            ? balanceInfoNote(checkout, showBalanceModal)
            : availableSharesNote(
                checkout,
                availableForSaleShares,
                showInfoModal,
              )}
        </div>
      </div>
    ),
  }
}

export function getStocksSections(
  checkout: PreOrderData,
  availableForSaleShares: number,
  showBalanceModal: () => void,
  showTaxesModal: () => void,
  showInfoModal: (title: string, content: string) => void,
): SummaryCardProps[] {
  const exchangeRateText = getPreOrderFormattedFxShares(checkout)

  const isLimit = isLimitOrder(checkout)

  const isBuy = checkout?.type === OrderOperationTypeEnum.buy

  const amountLabel = isLimit
    ? t('transactions.estimatedGrossAmount')
    : t('buySellFlow.summary.amount')

  const amountRow = {
    label: amountLabel,
    value: formatCurrency(checkout?.grossAmount),
  }

  const fxRateRow = {
    label: t('buySellFlow.summary.fxRate'),
    value: exchangeRateText,
  }

  const taxesRow = {
    label: t('buySellFlow.summary.taxes'),
    value: formatCurrency(checkout?.marketTaxes),
    labelChildren: (
      <InfoIcon className='ml-1 cursor-pointer' onClick={showTaxesModal} />
    ),
  }

  const bufferIncludedTxOptions = {
    percent: checkout?.contingenciesBuffer * 100,
  }

  const numberOfShares = {
    label: t('buySellFlow.numberOfShares'),
    value: checkout?.shares?.toString(),
  }

  const estimatedNetAmount = {
    label: t('transactions.estimatedNetAmount'),
    value: `${formatCurrency(
      checkout?.netAmountInPortfoliosCurrency,
    )} = ${formatCurrency(checkout?.netAmount)}`,
  }

  const bufferRow = {
    label: t('transactions.bufferIncluded', bufferIncludedTxOptions),
    value: formatCurrency(checkout?.contingenciesBufferAmount),
    labelChildren: (
      <InfoIcon
        className='ml-1 cursor-pointer'
        onClick={() => {
          trackingService.trackEvent({ event: LimitOrderEvents.bufferModal })
          showInfoModal(
            t('transactions.bufferIncluded', bufferIncludedTxOptions),
            t('transactions.bufferExplanation', bufferIncludedTxOptions),
          )
        }}
      />
    ),
  }

  const commonCommissions = [
    {
      label: t('common.commissions.executionFees'),
      value: formatCurrency(checkout?.commissions?.executionFees?.amount),
    },
    {
      label: t('common.commissions.exchangeFee'),
      value: formatCurrency(checkout?.commissions?.forexFees?.amount),
    },
  ]

  const totalCommissions = {
    label: t('common.commissions.totalCommissions'),
    value: formatCurrency(checkout?.commissions?.totalCommission),
  }

  const buyBufferRows = isBuy
    ? [
        bufferRow,
        {
          label: t('buySellFlow.requiredNetAmount'),
          value: formatCurrency(checkout?.requiredNetAmount),
        },
      ]
    : []

  if (checkout?.method === OrderTypeEnum.market) {
    const totalCostRow: SummaryRowProps = getMarketOrderTotalRow(
      checkout,
      showBalanceModal,
      showInfoModal,
      availableForSaleShares,
    )

    return [
      {
        id: checkout.id.concat('MarketPrice'),
        overTitle: t('buySellFlow.marketOrderDetails'),
        data: [
          {
            label: t('buySellFlow.unitPrice'),
            value: formatCurrency(checkout?.price, extraDigits),
          },
          numberOfShares,
          amountRow,
          taxesRow,
          fxRateRow,
          estimatedNetAmount,
          ...buyBufferRows,
        ],
      },
      {
        id: checkout.id.concat('OperationPrice'),
        overTitle: t('buySellFlow.operationPrice'),
        data: [
          ...commonCommissions,
          // ...buyCommissionsRows,
          totalCommissions,
          totalCostRow,
        ],
      },
    ]
  }

  if (isLimit) {
    const limitPriceTotalRow: SummaryRowProps = getLimitOrderTotalRow(
      checkout,
      showBalanceModal,
      showInfoModal,
      availableForSaleShares,
    )

    const expirationDate = checkout?.expirationDate
      ? formatEndDateRemaining(new Date(), new Date(checkout?.expirationDate))
      : null

    return [
      {
        id: checkout.id.concat('LimitOrderDetails'),
        overTitle: t('buySellFlow.limitOrderDetails'),
        data: [
          {
            label: t('buySellFlow.limitPrice'),
            value: formatCurrency(checkout?.price, extraDigits),
          },
          {
            label: t('transactions.expirationDate'),
            value: expirationDate,
          },
          numberOfShares,
          amountRow,
          taxesRow,
          fxRateRow,
          estimatedNetAmount,
          ...buyBufferRows,
        ],
      },
      {
        id: checkout.id.concat('ExecutionCommissions'),
        overTitle: t('transactions.executionCommissions'),
        data: [
          ...commonCommissions,
          // ...buyCommissionsRows,
          totalCommissions,
          limitPriceTotalRow,
        ],
      },
    ]
  }

  return []
}

export function usePreOrderSections(
  checkout: PreOrderData,
  availableForSaleShares: number,
  showBalanceModal: () => void,
  showTaxesModal: () => void,
  showInfoModal: (title: string, content: string) => void,
): SummaryCardProps[] {
  return getStocksSections(
    checkout,
    availableForSaleShares,
    showBalanceModal,
    showTaxesModal,
    showInfoModal,
  )
}
