export enum OnboardingStatus {
  emailPending = 'EMAIL_PENDING',
  mobilePending = 'MOBILE_PENDING',
  kycPending = 'KYC_PENDING',
  kycStarted = 'KYC_STARTED',
  kycVerifying = 'KYC_VERIFYING',
  kycReviewing = 'KYC_REVIEWING',
  mifidPending = 'MIFID_PENDING',
  contractsPending = 'CONTRACTS_PENDING',
  contractsSigning = 'CONTRACTS_SIGNING',
  traderPending = 'TRADER_PENDING',
  onboardingCompleted = 'ONBOARDING_COMPLETED',
  onboardingContactSupport = 'ONBOARDING_CONTACT_SUPPORT',
}
