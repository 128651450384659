import { UIIcon, UIIconColorProps, getIconColorSVGFill } from 'components/utils'

export const RestoreIcon = ({
  color,
  size = 24,
}: UIIcon & UIIconColorProps): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_3732_9610)'>
        <path
          d='M13.5 3C8.53 3 4.5 7.03 4.5 12H1.5L5.5 15.99L9.5 12H6.5C6.5 8.13 9.63 5 13.5 5C17.37 5 20.5 8.13 20.5 12C20.5 15.87 17.37 19 13.5 19C11.57 19 9.82 18.21 8.56 16.94L7.14 18.36C8.77 19.99 11.01 21 13.5 21C18.47 21 22.5 16.97 22.5 12C22.5 7.03 18.47 3 13.5 3ZM12.5 8V13L16.75 15.52L17.52 14.24L14 12.15V8H12.5Z'
          fill={color ? getIconColorSVGFill(color) : 'currentColor'}
        />
      </g>
      <defs>
        <clipPath id='clip0_3732_9610'>
          <rect
            width='24'
            height='24'
            fill={color ? getIconColorSVGFill(color) : 'currentColor'}
            transform='translate(0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}
