import { RootState } from '@store'
import { routes } from 'navigation'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { sessionStorageService } from 'services/session-storage'
import { useNavigate } from '@hooks'

export function RequireAuth({
  children,
}: {
  children: JSX.Element
}): JSX.Element {
  const auth = useSelector((state: RootState) => state.login)

  const { navigate } = useNavigate()

  useEffect(() => {
    const user = sessionStorageService.getSessionState()?.user
    if (!auth.token && !user?.accessToken) {
      navigate(routes.nonAuth.login)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.token])

  return auth?.token ? children : null
}
