import './codeInputCell.styles.scss'
import { CodeInputCellProps } from './codeInputCell.props'
import { ChangeEvent, KeyboardEvent } from 'react'

export default function CodeInputCell({
  idx,
  onChange,
  focusPrev,
  focusNext,
  value,
  disabled = false,
}: CodeInputCellProps): JSX.Element {
  const clean = (): void => {
    onChange(null, idx)
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    switch (event.key) {
      // Handle deletion
      case 'Delete':
      case 'Backspace':
      case 'Clear':
      case 'Undo':
        clean()
        if (value === null || value === '') focusPrev(idx, true)
        break
      // Handle arrow movement
      case 'ArrowLeft':
        focusPrev(idx, false)
        break
      case 'ArrowRight':
        focusNext(idx)
        break
      // Handle numbers and characters
      default:
        break
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value.trim()
    if (
      value !== null &&
      value !== undefined &&
      value !== '' &&
      Number.isInteger(Number(value))
    ) {
      onChange(value, idx)
    }
  }

  return (
    <div className='code-input-container'>
      <input
        disabled={disabled}
        className='code-input bigText'
        autoComplete='off'
        id={`code-input-${idx}`}
        type='text'
        value={value}
        onKeyDown={(e) => handleKeyDown(e)}
        onChange={handleChange}
      />
    </div>
  )
}
