export const EuroAndDollarIcons = (): JSX.Element => {
  return (
    <svg
      width='37'
      height='36'
      viewBox='0 0 37 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='0.728516'
        y='0.25'
        width='23.5'
        height='23.5'
        rx='11.75'
        fill='#D09F14'
      />
      <rect
        x='0.728516'
        y='0.25'
        width='23.5'
        height='23.5'
        rx='11.75'
        stroke='#595B67'
        strokeWidth='0.5'
      />
      <g clipPath='url(#clip0_12702_139125)'>
        <path
          d='M12.3447 11.2667C10.8314 10.8733 10.3447 10.4667 10.3447 9.83333C10.3447 9.10667 11.0181 8.6 12.1447 8.6C13.3314 8.6 13.7714 9.16667 13.8114 10H15.2847C15.2381 8.85333 14.5381 7.8 13.1447 7.46V6H11.1447V7.44C9.85141 7.72 8.81141 8.56 8.81141 9.84667C8.81141 11.3867 10.0847 12.1533 11.9447 12.6C13.6114 13 13.9447 13.5867 13.9447 14.2067C13.9447 14.6667 13.6181 15.4 12.1447 15.4C10.7714 15.4 10.2314 14.7867 10.1581 14H8.69141C8.77141 15.46 9.86474 16.28 11.1447 16.5533V18H13.1447V16.5667C14.4447 16.32 15.4781 15.5667 15.4781 14.2C15.4781 12.3067 13.8581 11.66 12.3447 11.2667Z'
          fill='white'
        />
      </g>
      <rect
        x='12.7285'
        y='12.25'
        width='23.5'
        height='23.5'
        rx='11.75'
        fill='#D09F14'
      />
      <rect
        x='12.7285'
        y='12.25'
        width='23.5'
        height='23.5'
        rx='11.75'
        stroke='#595B67'
        strokeWidth='0.5'
      />
      <g clipPath='url(#clip1_12702_139125)'>
        <path
          d='M25.1445 28.3333C23.4712 28.3333 22.0245 27.3867 21.3045 26H25.1445V24.6667H20.8645C20.8312 24.4467 20.8112 24.2267 20.8112 24C20.8112 23.7733 20.8312 23.5533 20.8645 23.3333H25.1445V22H21.3045C22.0245 20.6133 23.4779 19.6667 25.1445 19.6667C26.2179 19.6667 27.2045 20.06 27.9645 20.7133L29.1445 19.5333C28.0845 18.58 26.6779 18 25.1445 18C22.5312 18 20.3179 19.6733 19.4912 22H17.1445V23.3333H19.1845C19.1579 23.5533 19.1445 23.7733 19.1445 24C19.1445 24.2267 19.1579 24.4467 19.1845 24.6667H17.1445V26H19.4912C20.3179 28.3267 22.5312 30 25.1445 30C26.6845 30 28.0845 29.42 29.1445 28.4667L27.9579 27.2867C27.2045 27.94 26.2245 28.3333 25.1445 28.3333Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_12702_139125'>
          <rect
            width='16'
            height='16'
            fill='white'
            transform='translate(4.47852 4)'
          />
        </clipPath>
        <clipPath id='clip1_12702_139125'>
          <rect
            width='16'
            height='16'
            fill='white'
            transform='translate(16.4785 16)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}
