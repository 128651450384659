import { UIIcon, UIIconColorProps, getIconColorSVGFill } from 'components/utils'

export const ReloadIcon = ({
  color,
  size = 24,
}: UIIcon & UIIconColorProps): JSX.Element => {
  return (
    <svg width={size} height={size} viewBox='0 0 53 53'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M52.6924 26.6924C52.6924 41.0518 41.0518 52.6924 26.6924 52.6924C12.333 52.6924 0.692383 41.0518 0.692383 26.6924C0.692383 12.333 12.333 0.692383 26.6924 0.692383C41.0518 0.692383 52.6924 12.333 52.6924 26.6924ZM34.0917 18.3877C29.2486 14.3779 22.0391 15.1361 18.1395 20.1188C14.5475 24.7109 15.1932 31.5692 19.5792 35.4101C24.3401 39.5776 31.5724 38.9673 35.5741 34.1338C37.2224 32.1429 38.0643 29.7426 38.1298 27.3447C38.148 26.6016 37.5019 26.0182 36.7575 26.0795C36.1276 26.1388 35.6551 26.651 35.6349 27.2795C35.59 29.1459 34.9357 31.0079 33.6599 32.5489C30.5694 36.2818 25.0199 36.7689 21.3171 33.6226C17.7962 30.6269 17.2806 25.1508 20.1806 21.5506C23.2392 17.759 28.774 17.2114 32.5069 20.3019L31.0805 22.0248C30.7318 22.4459 31.0665 23.0939 31.6167 23.0334L36.5076 22.5817C36.8575 22.5488 37.1031 22.252 37.0702 21.9021L36.6011 17.0128C36.558 16.461 35.8668 16.2437 35.5102 16.6744L34.0917 18.3877Z'
        fill={color ? getIconColorSVGFill(color) : 'currentColor'}
      />
    </svg>
  )
}
