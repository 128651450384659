import { Button, CustomModal, Spacer, Text } from '@components'
import { useTranslation } from '@hooks'

export const ResetPasswordModal = ({
  close,
}: {
  close: () => void
}): JSX.Element => {
  const { t } = useTranslation()

  return (
    <CustomModal show={true} theme='lighter' size='small'>
      <CustomModal.Header text={t('forgotPassword.forgot')} />
      <CustomModal.Content>
        <Spacer preset='tiny' />
        <Text
          preset='paragraph1'
          text={t('forgotPassword.resetAlertDescription')}
          align='center'
        />
        <Spacer preset='tiny' />
      </CustomModal.Content>
      <CustomModal.Footer>
        <Button text={t('common.ok')} onClick={close} buttonType='primary' />
      </CustomModal.Footer>
    </CustomModal>
  )
}
