import { useTranslation } from '@hooks'
import { OrderOperationTypeEnum } from '@interfaces'
import { RootState } from '@store'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

export const useOperationTypeTabs = (): {
  title: string
  id: OrderOperationTypeEnum
}[] => {
  const { t } = useTranslation()

  const { companyPosition, companyStocks, investments } = useSelector(
    (state: RootState) => state,
  )

  return useMemo(() => {
    const stockIsInPortfolio = investments?.portfolios?.some((portfolio) =>
      portfolio?.positions?.some((p) => p?.id === companyStocks?.id),
    )

    const sellAllowed = stockIsInPortfolio && companyPosition?.shares

    let operationTypeTabs = [
      {
        title: t('common.buy'),
        id: OrderOperationTypeEnum.buy,
      },
    ]

    if (sellAllowed) {
      operationTypeTabs = [
        ...operationTypeTabs,
        {
          title: t('common.sell'),
          id: OrderOperationTypeEnum.sell,
        },
      ]
    }

    return operationTypeTabs
  }, [companyPosition?.shares, companyStocks?.id, investments?.portfolios, t])
}
