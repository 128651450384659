import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import {
  AnalystRatings,
  BullishBearish,
  Earning,
  Forecast,
  GetAllNews,
} from '@interfaces/companyOverview'
import {
  getAnalystRating,
  getBullBears,
  getEarnings,
  getForecast,
  getNews,
} from '@services'

interface TipRankBody {
  assetId: string
}

export const fetchAnalystRating = createAsyncThunk<AnalystRatings, TipRankBody>(
  'fetchAnalystRating',
  ({ assetId }) => getAnalystRating(assetId),
)

export const fetchForecast = createAsyncThunk<Forecast, TipRankBody>(
  'fetchForecast',
  ({ assetId }) => getForecast(assetId),
)

export const fetchBullBears = createAsyncThunk<BullishBearish[], TipRankBody>(
  'fetchBullBears',
  ({ assetId }) => getBullBears(assetId),
)
export const fetchNews = createAsyncThunk<GetAllNews[], TipRankBody>(
  'fetchNews',
  ({ assetId }) => getNews(assetId),
)

export const fetchEarnings = createAsyncThunk<Earning[], TipRankBody>(
  'fetchEarnings',
  ({ assetId }) => getEarnings(assetId),
)

export const resetTipRank = createAction('resetTipRank')
