import { CheckIcon, CloseIcon } from '@assets/icons'
import { ListType } from '../../interfaces/typePasswordRequirement'
import { Spacer, Text } from 'components'

type TypePasswordListItem = {
  listType: ListType
  text: string
}

const RequirementListItem = ({
  listType,
  text,
}: TypePasswordListItem): JSX.Element => {
  return (
    <div className='d-flex'>
      {listType === ListType.ERROR ? (
        <CloseIcon size={16} color={'negative'} />
      ) : (
        <CheckIcon
          size={16}
          color={
            listType === ListType.SUCCESS
              ? 'iconPositiveInitial'
              : 'iconNeutralMedium'
          }
        />
      )}
      <Spacer preset='tiny' />
      <Text
        preset='caption'
        text={text}
        color={
          listType === ListType.ERROR
            ? 'negative'
            : listType === ListType.SUCCESS
            ? 'positive'
            : 'secondary'
        }
      />
    </div>
  )
}

export default RequirementListItem
