import './filterDropdownContent.styles.scss'
import { DropdownState, FilterValue, FocusedFilter } from 'interfaces'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { RawTreeNode } from 'tr33'

const SPACE_BETWEEN_DROPDOWNS = 250
const REMOME_ANIMATION_TIME = 250

export const FilterDropdownContent = ({
  dropdownState,
  dropdownClasses,
  dropdownLeftPosition,
  children,
  activePanelIdx,
  filters,
  focusedFilter,
}: {
  dropdownState: DropdownState
  dropdownLeftPosition: {
    fromButton: number
    fromWindowLeftSide: number
  }
  dropdownClasses: string
  children: JSX.Element[]
  activePanelIdx: number
  filters: Array<RawTreeNode<FilterValue>>
  focusedFilter: FocusedFilter
}): JSX.Element => {
  const optionsContainers = useRef<HTMLDivElement[]>([])
  const [panelTranslation, setPanelTranslation] = useState(0)
  const [panelWidth, setPanelWidth] = useState(0)
  const [panelHeight, setPanelHeight] = useState(0)
  const [additionalAnimations, setAdditionalAnimations] = useState(false)

  const isMounted = useRef<boolean>()

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  const calculateWidthAndHeight = useCallback(() => {
    let idx = filters?.findIndex((f) => f?.id === focusedFilter?.id)

    // If focused filter is not on top level...
    if (idx === -1) {
      idx = filters?.findIndex((f) =>
        f?.children?.some((c) => c?.id === focusedFilter?.id),
      )
    }

    const currentIndex = idx

    const rect =
      optionsContainers.current[currentIndex]?.getBoundingClientRect()

    const maxWidth =
      window?.innerWidth - dropdownLeftPosition?.fromWindowLeftSide

    setPanelWidth(rect?.width > maxWidth ? maxWidth : rect?.width)
    setPanelHeight(rect?.height)

    setPanelTranslation(-currentIndex * SPACE_BETWEEN_DROPDOWNS)
  }, [dropdownLeftPosition, filters, focusedFilter?.id])

  /**
   * Calculates new dropdown width and translationX value to show the correct filters.
   */
  useEffect(() => {
    if (focusedFilter?.id) {
      calculateWidthAndHeight()
    }
  }, [calculateWidthAndHeight, dropdownLeftPosition, filters, focusedFilter])

  const dropDownStyles = useMemo(() => {
    return {
      left: `${dropdownLeftPosition?.fromButton}px`,
      height: `${panelHeight}px`,
    }
  }, [dropdownLeftPosition, panelHeight])

  const wrapperStyles = useMemo(() => {
    return {
      transform: `translateX(${panelTranslation}px)`,
      width: panelWidth ? `${panelWidth}px` : 'fit-content',
    }
  }, [panelTranslation, panelWidth])

  useEffect(() => {
    let id
    if (dropdownState === DropdownState.OPENED) {
      id = setTimeout(() => {
        if (isMounted.current) {
          setAdditionalAnimations(true)
        }
      }, REMOME_ANIMATION_TIME)
    }

    if (dropdownState === DropdownState.CLOSED) {
      setAdditionalAnimations(false)
    }

    return () => {
      if (id) clearTimeout(id as number)
    }
  }, [dropdownState])

  return (
    <div
      className={`filters-dropdown ${dropdownClasses} ${
        additionalAnimations && '--aditional-animations'
      }`}
      style={dropDownStyles}
    >
      <div className='filters-dropdown__options d-flex' style={wrapperStyles}>
        {children?.map((child, index) => (
          <React.Fragment key={index}>
            <div
              className={`filters-dropdown__wrapper ${
                activePanelIdx === index ? '--active' : ''
              }`}
              ref={(el) => (optionsContainers.current[index] = el)}
              style={{
                zIndex: activePanelIdx === index ? 1 : 0,
                left: index * SPACE_BETWEEN_DROPDOWNS,
              }}
              onClick={() => {
                // For some reason height is not calculated correctly without a setTimeout
                // We will also disable animations when switching between tabs in a dropdown content
                setTimeout(() => {
                  if (isMounted.current) {
                    setAdditionalAnimations(false)
                    calculateWidthAndHeight()
                  }
                }, 0)

                setTimeout(() => {
                  if (!isMounted.current) return
                  setAdditionalAnimations(true)
                }, SPACE_BETWEEN_DROPDOWNS)
              }}
              aria-hidden
            >
              {child}
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}
