import BaseAxios, {
  AxiosInstance,
  AxiosResponse,
  AxiosRequestHeaders,
  AxiosError,
} from 'axios'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import { resetStoreState } from '@store/commonReducers/resetStoreState/thunk'
import store from '@store/store'
import { renewToken } from '@services'
import { doLogout } from '@screens/login/thunk'
import { setToken } from '@screens/login/action'
import { getLang } from '../langs'
import { sessionStorageService } from 'services/session-storage'
import { environment } from '../../config'

let baseURL = environment?.baseUrl || ''
let apiKey = environment?.apiKey || ''

const currentAppVersion = environment?.currentAppVersion || '0.0.0'

export const setEnvironment = (url: string, key: string): void => {
  baseURL = url
  apiKey = key
}

const axiosInstance: AxiosInstance = BaseAxios.create({
  baseURL,
})

const getBaseURL = (): string => baseURL

interface Api {
  get<T = unknown, R = AxiosResponse<T>>(
    url: string,
    noAuth?: boolean,
  ): Promise<R>
  delete<T = unknown, R = AxiosResponse<T>>(
    url: string,
    noAuth?: boolean,
  ): Promise<R>
  head<T = unknown, R = AxiosResponse<T>>(
    url: string,
    noAuth?: boolean,
  ): Promise<R>
  put<T = unknown, R = AxiosResponse<T>>(
    url: string,
    data?: unknown,
    noAuth?: boolean,
  ): Promise<R>
  post<T = unknown, R = AxiosResponse<T>>(
    url: string,
    data?: unknown,
    noAuth?: boolean,
  ): Promise<R>
  patch<T = unknown, R = AxiosResponse<T>>(
    url: string,
    data?: unknown,
    noAuth?: boolean,
  ): Promise<R>
  getBaseURL(): string
}

interface ApiHeaders {
  Authorization?: string
  'x-api-key'?: string
  'x-client-version'?: string
  'x-client-type'?: string
  'x-client-name'?: string
  'accept-language'?: string
}

// Configuration
const isTokenValid = (token: string): boolean => {
  try {
    const currentDate = new Date()
    const { exp }: JwtPayload = jwtDecode(token)
    return (exp || 0) * 1000 >= currentDate.getTime()
  } catch (error) {
    return false
  }
}

// This function updates the token both for redux state and browser storage
const saveNewToken = (token: string): void => {
  store.dispatch(setToken({ token: token }))
  sessionStorageService.updateSessionState({
    user: { accessToken: token },
  })
}

const handleTokenRenewal = async (
  refreshToken: string,
): Promise<string | null> => {
  const refreshIsValid = isTokenValid(refreshToken)

  if (!refreshIsValid) {
    // console.log('Refresh token is not valid')
    return null
  }

  // console.log('Refresh token is valid, requesting new token')
  const newAccess = await renewToken(refreshToken)

  const newAccessIsValid = isTokenValid(newAccess?.accessToken)

  if (!newAccessIsValid) return null
  // console.log('New obtained token is valid, using the new token')

  saveNewToken(newAccess?.accessToken)

  return newAccess?.accessToken
}

const getAuthToken = async (
  token: string, // Redux state token
  refreshToken: string, // Redux state refresh token
): Promise<string> => {
  const accessValid = isTokenValid(token)

  // Kept console logs as it is hard to debug...

  if (accessValid) {
    // console.log('Using redux state token as it is still valid.')
    return token
  }

  const user = sessionStorageService.getSessionState()?.user

  const storedToken = user?.accessToken
  const storedTokenIsValid = isTokenValid(storedToken)

  if (storedTokenIsValid) {
    // console.log('Using token stored in browser as it is valid.')
    saveNewToken(storedToken)
    if (storedToken) return storedToken
  }

  // console.log('As none of the tokens are valid we need to renew the token.')

  // Get new token using the refresh token from redux state
  const newTokenOne = await handleTokenRenewal(refreshToken)
  if (newTokenOne) return newTokenOne

  // Get new token using the token from browser
  const newTokenTwo = await handleTokenRenewal(user?.renewalToken)
  if (newTokenTwo) return newTokenTwo

  return null
}

const configureHeaders = async (
  noAuth: boolean,
): Promise<AxiosRequestHeaders> => {
  const headers: ApiHeaders = {
    'x-api-key': apiKey,
    'accept-language': getLang(), // TODO: I18n.locale,
    'x-client-version': currentAppVersion,
    'x-client-type': 'web',
    'x-client-name': 'tbcc',
  }

  // !noAuth means its a call that needs authentication
  if (!noAuth) {
    const { token, refreshToken } = store.getState().login

    const validatedToken = await getAuthToken(token, refreshToken)

    headers.Authorization = `Bearer ${validatedToken}`
  }

  return headers as AxiosRequestHeaders
}

// Interceptors
axiosInstance?.interceptors.request.use(
  (request) => {
    request.baseURL = baseURL
    return request
  },
  async (error) => {
    return Promise.reject(error)
  },
)

axiosInstance?.interceptors.response.use(
  (response) => {
    return response.data
  },
  async (error: AxiosError) => {
    const { token } = store.getState().login
    const expiredToken = token && !isTokenValid(token)
    if (
      error?.response &&
      (error.response.status === 401 || error.response.status === 403) &&
      expiredToken
    ) {
      store
        .dispatch(doLogout())
        .then(() => {
          sessionStorageService.clear()
          store.dispatch(resetStoreState())
        })
        .catch(() => {
          /* Do nothing */
        })
    }
    return Promise.reject(error)
  },
)

// API methods implementation
const get = async <T = unknown, R = AxiosResponse<T>>(
  url: string,
  noAuth = false,
): Promise<R> => {
  const headers = await configureHeaders(noAuth)
  return axiosInstance.get(url, { headers })
}

// A.K.A. delete
const erase = async <T = unknown, R = AxiosResponse<T>>(
  url: string,
  noAuth = false,
): Promise<R> => {
  const headers = await configureHeaders(noAuth)
  return axiosInstance.delete(url, { headers })
}

const head = async <T = unknown, R = AxiosResponse<T>>(
  url: string,
  noAuth = false,
): Promise<R> => {
  const headers = await configureHeaders(noAuth)
  return axiosInstance.head(url, { headers })
}

const post = async <T = unknown, R = AxiosResponse<T>>(
  url: string,
  data?: unknown,
  noAuth = false,
): Promise<R> => {
  const headers = await configureHeaders(noAuth)
  return axiosInstance.post(url, data, { headers })
}

const put = async <T = unknown, R = AxiosResponse<T>>(
  url: string,
  data?: unknown,
  noAuth = false,
): Promise<R> => {
  const headers = await configureHeaders(noAuth)
  return axiosInstance.put(url, data, { headers })
}

const patch = async <T = unknown, R = AxiosResponse<T>>(
  url: string,
  data?: unknown,
  noAuth = false,
): Promise<R> => {
  const headers = await configureHeaders(noAuth)
  return axiosInstance.patch(url, data, { headers })
}

export const api: Api = {
  post,
  get,
  patch,
  put,
  head,
  delete: erase,
  getBaseURL,
}
