import './stockPerformance.styles.scss'
import { StockPerformanceProps } from './stockPerformance.props'
import { formatMoney, formatNumber } from '@utils/helpers'
import { ReactComponent as UpIcon } from '@assets/svg/stocks_up.svg'
import { ReactComponent as DownIcon } from '@assets/svg/stocks_down.svg'
import { DownVector, UpVector } from '@assets/svg'

export function StockPerformance({
  performancePercentage,
  performanceAmount,
  type,
  plusSymbol,
  noWrap,
  className = '',
}: StockPerformanceProps): JSX.Element {
  const icon =
    performanceAmount?.amount >= 0 ? (
      <UpVector className='wrapper__stock__performance__icon icon--smaller' />
    ) : (
      <DownVector className='wrapper__stock__performance__icon icon--smaller' />
    )
  const bigIcon =
    performanceAmount?.amount >= 0 ? (
      <UpIcon width='24' height='24' />
    ) : (
      <DownIcon width='24' height='24' />
    )

  const performanceStyle =
    performanceAmount?.amount >= 0 ? 'positive' : 'negative'

  const plusSymbolText = performanceAmount?.amount >= 0 && plusSymbol ? '+' : ''
  const performanceAmountText =
    plusSymbolText +
    formatMoney(performanceAmount?.amount, performanceAmount?.currency)

  const performanceSummary = `${formatNumber(performancePercentage)}%`
  const receivedType = type || ''

  const renderSwitch = (receivedType: string): JSX.Element => {
    switch (receivedType) {
      case 'icon':
        return <div className='wrapper__stock__bigIcon mr-2'>{bigIcon}</div>

      case 'amount':
        return (
          <div className='wrapper__stock__performance'>
            {performanceAmountText}
          </div>
        )

      case 'percentage':
        return (
          <div className='wrapper__stock__performance'>
            <span className='wrapper__stock__performance'>
              {performanceSummary}
            </span>
            <span className='wrapper__stock__amount ml-1'>
              {`(${performanceAmountText})`}
            </span>
          </div>
        )
      default:
        return (
          <div
            className={`wrapper__stock ${noWrap ? 'nowrap' : ''} ${className}`}
          >
            <span className='wrapper__stock__amount'>
              {performanceAmountText}
            </span>
            <span className='wrapper__stock__performance'>
              {icon}
              {performanceSummary}
            </span>
          </div>
        )
    }
  }

  return (
    <div className={`performance ${performanceStyle as string}`}>
      {renderSwitch(receivedType)}
    </div>
  )
}
