import { BigSpinner, StickyElementsWrapper, Text } from '@components'
import {
  NotificationMessageType,
  WatchlistBase,
  WatchlistState,
} from '@interfaces'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAssessmentEvaluation, RootState } from '@store'
import {
  fetchWatchlistStocks,
  reloadWatchlistAfterDelete,
  removeItemFromWatchlist,
  unsetWatchlistStockState,
} from './thunk'
import './watchlist.styles.scss'
import { t } from 'i18next'
import { SpinnerIcon } from '@assets/svg'
import { addNotification } from '@store/commonReducers/notifications'
import { EmptyWatchlist } from '@assets/illustrations/watchlist/emptyWatchlistIllustration'
import { WatchlistTable } from './watchlistTable/watchlistTable'

export default function Watchlist(): JSX.Element {
  const dispatch = useDispatch()

  const {
    data,
    loading,
    paginationLoader,
    totalPages,
    currentPage,
    totalItems,
    reloadAfterDelete,
  }: WatchlistState & WatchlistBase = useSelector(
    (state: RootState) => state.watchlist,
  )

  const config = useSelector((state: RootState) => state.config)

  const loader = useRef(null)

  const [page, setPage] = useState(0)

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '24px',
      threshold: 1.0,
    }
    const observer = new IntersectionObserver(handleObserver, options)
    if (loader.current) {
      observer.observe(loader.current as Element)
    }
  }, [])

  const fetchWatchlist = useCallback(
    (page: number) => dispatch(fetchWatchlistStocks(page)),
    [dispatch],
  )

  const handleObserver = (entities: Array<IntersectionObserverEntry>): void => {
    const target = entities[0]
    if (target.isIntersecting) {
      setPage((page) => page + 1)
    }
  }

  useEffect(() => {
    if (config?.features?.savingsPlans) {
      const loadSavingPlans = (): void => {
        dispatch(fetchAssessmentEvaluation())
      }
      loadSavingPlans()
    }
  }, [config?.features?.savingsPlans, dispatch])

  useEffect(() => {
    fetchWatchlist(page)
  }, [fetchWatchlist, page])

  useEffect(() => {
    if (reloadAfterDelete) {
      dispatch(
        addNotification({
          type: NotificationMessageType.positive,
          message: `${t('watchlist.assetRemoved')}`,
        }),
      )
      dispatch(reloadWatchlistAfterDelete(currentPage))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadAfterDelete])

  const onDelete = (id: string): void => {
    dispatch(removeItemFromWatchlist(id))
    dispatch(unsetWatchlistStockState(id))
  }

  return (
    <>
      {loading ? (
        <div className='home__loader --fade-in-animation'>
          <BigSpinner />
        </div>
      ) : (
        <div className='watchlist'>
          {data?.stocks?.length > 0 ? (
            <div className='home__widget mb-6 --fade-in-animation'>
              <StickyElementsWrapper stickTableHeader startPoint={56}>
                <div className='home__watchlist'>
                  <div
                    className='home__watchlist__title pt-3 pb-3 d-flex align-center'
                    data-sticky
                  >
                    <Text
                      preset='hero'
                      text={`${t('watchlist.title')} · ${totalItems}`}
                    />
                  </div>
                  <WatchlistTable
                    assets={data?.stocks}
                    onDelete={onDelete}
                    screen='watchlist'
                  />
                </div>
              </StickyElementsWrapper>
            </div>
          ) : (
            <div className='watchlist__empty --fade-in-animation'>
              <div className='watchlist__empty__content'>
                <div>
                  <EmptyWatchlist />
                </div>
                <div className='headline3 mt-2 mb-1 w-100'>
                  {t('watchlist.title')}
                </div>
                <div className='paragraph2 secondary--text'>
                  {t('watchlist.trackYourFavourite')}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <div
        className={`text-center mt-2 loader ${
          loading || totalPages - 1 == currentPage ? 'd-none' : ''
        }`}
        ref={loader}
      ></div>
      {paginationLoader && (
        <div className='text-center'>
          <SpinnerIcon className='spinner spinner--orange' />
        </div>
      )}
    </>
  )
}
