import { useTranslation } from '@hooks'
import { ReactComponent as CheckoutLogo } from '@assets/svg/checkout_logo.svg'
import { ReactComponent as BitEncryption256 } from '@assets/svg/256_bit_encryption.svg'
import { ReactComponent as VerfiedByVisa } from '@assets/svg/veryfied_by_visa.svg'
import {
  Avatar,
  Button,
  CustomModal,
  GoBack,
  Spacer,
  SummaryCard,
  Text,
} from '@components'
import { VisaCard } from '@assets/illustrations'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@store'
import {
  CardFlowType,
  CardPaymentSummaryFlow,
  Money,
  OperationConfirmationStatus,
  OperationTypeEnum,
  PaymentMethodTypeEnum,
  ToAmount,
  TopUpPaymentType,
} from '@interfaces'
import {
  sendCardPayment,
  sendRecurringCardPayment,
  sendStoredCardPayment,
} from '../topup/thunk'
import {
  useRecurringPaymentOverviewRows,
  useRecurringPaymentSummaryRows,
  useSingleTopUpSummary,
} from '../topup/hooks/useCardTopUpSummary.hooks'
import { buildQueryParams } from '@utils/helpers'
import { useEffect } from 'react'
import { setMoneyOperationStep } from '../thunk'
import { MoneyOperationsSteps } from '../moneyOperations.props'

export interface CardPaymentDetails {
  amount: Money
  fees: Money
  feesPercent: number
  total: Money
}

export function CardPaymentSummary({
  prevStep,
  onClose,
  toTop,
}: {
  toTop: ToAmount
  prevStep: () => void
  onClose?: () => void
}): JSX.Element {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const addMoney = useSelector((state: RootState) => state?.addMoney)

  const flowType = addMoney?.cardPayment?.flowType
  const paymentInfo = addMoney?.cardPayment?.paymentInfo
  const cardDetails = addMoney?.cardPayment?.cardDetails
  const recurringPaymentConfig = addMoney?.recurringTopUpConfig

  const fee = addMoney?.cardPayment?.cardPaymentFees?.find(
    (i) => (i?.type as TopUpPaymentType) === addMoney?.topUpType,
  )

  const amount =
    flowType === CardFlowType.NEWCARD
      ? {
          amount: cardDetails?.amount?.amount,
          currency: cardDetails?.amount?.currency,
        }
      : {
          amount: toTop?.amount,
          currency: toTop?.currency,
        }

  const feesAmount = (amount?.amount / 100) * fee?.percentage

  const fees: Money = {
    amount: feesAmount,
    currency: cardDetails?.amount?.currency,
  }

  const total: Money = {
    amount: Number(amount?.amount || 0) + feesAmount,
    currency: cardDetails?.amount?.currency,
  }

  const details = {
    fees,
    amount,
    total,
    feesPercent: fee?.percentage,
  }

  const summaryRows = useSingleTopUpSummary(details)

  const recurringPaymentsSummaryRows = useRecurringPaymentSummaryRows(details)

  const recurringPaymentsOverviewRows = useRecurringPaymentOverviewRows(details)

  useEffect(() => {
    if (paymentInfo?.id) {
      const paymentUrl = paymentInfo.redirectUrl
      if (paymentUrl) {
        window.location.replace(paymentUrl)
      } else {
        dispatch(
          setMoneyOperationStep(MoneyOperationsSteps.completedCardPayment),
        )
      }
    }
  }, [dispatch, paymentInfo?.id, paymentInfo.redirectUrl])

  const isStoredCard = flowType === CardFlowType.STOREDCARD

  const confirmPayment = (): void => {
    const cardToken = cardDetails?.cardToken
    const baseConfirmationUrl = `${window.location.origin}/confirmation`

    const startingDate = recurringPaymentConfig?.startingDate?.value
    const expirationDate = recurringPaymentConfig?.expiration?.value
    const periodicity = recurringPaymentConfig?.periodicity.value

    const isReccurring = addMoney?.topUpType === TopUpPaymentType.RECURRING

    const summaryFlow: CardPaymentSummaryFlow = {
      isReccurring,
      amount: amount,
      feesPercentage: fee?.percentage?.toString(),
      feesAmount: fees,
      total: total,
      periodicity: recurringPaymentConfig?.periodicity,
      startingDate: startingDate,
      expirationDate: expirationDate,
    }

    localStorage.setItem('cardPaymentSummaryFlow', JSON.stringify(summaryFlow))

    if (isReccurring) {
      const params = {
        operation: OperationTypeEnum.addMoney as string,
        method: PaymentMethodTypeEnum.CARD,
      }

      const successUrl = `${baseConfirmationUrl}${buildQueryParams({
        ...params,
        status: OperationConfirmationStatus.success as string,
      })}`

      const failureUrl = `${baseConfirmationUrl}${buildQueryParams({
        ...params,
        status: OperationConfirmationStatus.error as string,
      })}`

      dispatch(
        sendRecurringCardPayment({
          amount,
          cardId: isStoredCard ? addMoney?.selectedPaymentMethod?.id : null,
          cardToken,
          failureUrl,
          fromDate: startingDate,
          periodicity,
          successUrl,
          toDate: expirationDate,
        }),
      )
    } else {
      const params = {
        operation: OperationTypeEnum.addMoney as string,
        method: PaymentMethodTypeEnum.CARD,
      }

      const successUrl = `${baseConfirmationUrl}${buildQueryParams({
        ...params,
        status: OperationConfirmationStatus.success as string,
      })}`

      const failureUrl = `${baseConfirmationUrl}${buildQueryParams({
        ...params,
        status: OperationConfirmationStatus.error as string,
      })}`

      if (isStoredCard) {
        dispatch(
          sendStoredCardPayment({
            cardId: addMoney?.selectedPaymentMethod?.id,
            amount,
            failureUrl,
            successUrl,
          }),
        )
      } else {
        dispatch(
          sendCardPayment({
            amount,
            cardToken,
            failureUrl,
            saveCard: !!cardDetails?.rememberCard,
            successUrl,
          }),
        )
      }
    }
  }

  const selectedPaymentMethod = addMoney?.selectedPaymentMethod?.cardDetails
  const cardType = cardDetails?.creditCardType || selectedPaymentMethod?.scheme
  const last4 = cardDetails?.last4 || selectedPaymentMethod?.last4
  const last4Text = `${cardType?.toString()?.toUpperCase()} **${last4}`

  return (
    <>
      <CustomModal.Header linkText={t('common.cancel')} onClick={onClose}>
        <GoBack goBack={prevStep} />
      </CustomModal.Header>
      <CustomModal.Content className='d-flex d-flex-col'>
        <div className='headline1 primary--text my-5'>
          {t('confirmPayment.button')}
        </div>
        <div className='d-flex d-flex-col justify-center align-center'>
          <Avatar roundness='full' size='large' image={<VisaCard />} />
          <Spacer preset='tiny' />
          <Text preset='paragraph1' text='Credit card' />
          <Spacer preset='tinyMinus' />
          <Text preset='paragraph2' text={last4Text} />
          <Spacer preset='small' />

          {addMoney?.topUpType === TopUpPaymentType.SINGLE ? (
            <SummaryCard
              className='w-100'
              id='singleTopUpSummaryCard'
              data={summaryRows}
            />
          ) : (
            <>
              <SummaryCard
                className='w-100 text-left'
                id='recurringTopUpSummaryCard'
                overTitle={t('recurringPayments.summaryTitle')}
                data={recurringPaymentsSummaryRows}
              />

              <Spacer preset='medium' />
              <SummaryCard
                className='w-100 text-left'
                id='recurringTopUpSummaryCardOverview'
                overTitle={t('recurringPayments.overview', {
                  count: recurringPaymentConfig?.paymentsCount,
                })}
                data={recurringPaymentsOverviewRows}
              />
            </>
          )}
          <Spacer preset='large' />
          <div className='d-flex'>
            <BitEncryption256 />
            <Spacer preset='small' />
            <CheckoutLogo />
            <Spacer preset='small' />
            <VerfiedByVisa />
          </div>
          <Spacer preset='small' />
        </div>
      </CustomModal.Content>
      <CustomModal.Footer className='d-flex d-flex-col'>
        <Button
          text='Continue'
          type='submit'
          size='big'
          onClick={confirmPayment}
          loading={addMoney?.loading}
        />
      </CustomModal.Footer>
    </>
  )
}
