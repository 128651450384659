import { Spacer, Text } from 'components'
import { Transaction, TransactionGroup } from 'interfaces'
import React from 'react'
import { TransactionItem } from '../transactionItem/transactionItem'
import { formatDateLong } from '@utils/helpers'

export const GroupedTransactions = ({
  groups,
  onTransactionClick,
  withTitle = true,
  animated = false,
}: {
  groups: TransactionGroup<Transaction>[]
  onTransactionClick: (id: string, type: string) => void
  withTitle?: boolean
  animated?: boolean
}): JSX.Element => {
  return (
    <>
      {groups?.map((item, idx) => (
        <div key={`${item?.title}-${idx}`}>
          {withTitle && (
            <div className={animated ? '--fade-in-animation' : ''}>
              <Spacer preset='smaller' />
              <Text
                preset='paragraph1'
                color='tertiary'
                text={formatDateLong(item?.title)}
              />
              <Spacer preset='tinyPlus' />
            </div>
          )}
          {item?.data?.map((transaction) => (
            <React.Fragment key={`executed-transactions-${transaction?.id}`}>
              <TransactionItem
                data={transaction}
                handleOnClick={() =>
                  onTransactionClick(transaction?.id, transaction?.type)
                }
                animated={animated}
              />
              <Spacer preset='tiny' />
            </React.Fragment>
          ))}
        </div>
      ))}
    </>
  )
}
