import { IllustrationProps } from '../types'

export const VisaCard = (props: IllustrationProps): JSX.Element => {
  return (
    <svg
      width={props?.size || 30}
      viewBox='0 0 30 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='0.5'
        y='1'
        width='29'
        height='18'
        rx='3.5'
        fill='white'
        stroke='#F3F3F3'
      />
      <path
        d='M13.3196 13.5307H11.3223L12.5715 6.88037H14.5687L13.3196 13.5307Z'
        fill='#15195A'
      />
      <path
        d='M20.5608 7.0429C20.1668 6.90834 19.5419 6.75977 18.7693 6.75977C16.7969 6.75977 15.4079 7.6653 15.3994 8.95993C15.383 9.91511 16.3939 10.4456 17.1499 10.7641C17.9226 11.0896 18.1853 11.302 18.1853 11.5921C18.1774 12.0376 17.5609 12.243 16.9858 12.243C16.1884 12.243 15.7612 12.1371 15.1118 11.8892L14.8488 11.783L14.5693 13.2758C15.0378 13.4596 15.9008 13.6226 16.7969 13.6298C18.8926 13.6298 20.257 12.7382 20.2732 11.3586C20.2811 10.6015 19.7474 10.0215 18.5966 9.54745C17.8981 9.24316 17.4702 9.03798 17.4702 8.72664C17.4784 8.4436 17.8321 8.1537 18.6207 8.1537C19.27 8.1395 19.7471 8.27387 20.1085 8.40834L20.2891 8.47896L20.5608 7.0429Z'
        fill='#15195A'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24.1357 6.88037H25.6806L27.292 13.5306H25.4426C25.4426 13.5306 25.2617 12.7665 25.2043 12.533H22.6399C22.5658 12.7098 22.2207 13.5306 22.2207 13.5306H20.125L23.0918 7.43215C23.2973 7.00054 23.6593 6.88037 24.1357 6.88037ZM24.0126 9.31402C24.0126 9.31402 23.3797 10.7927 23.2152 11.1747H24.8753C24.7932 10.8422 24.415 9.25036 24.415 9.25036L24.2754 8.67733C24.2166 8.82488 24.1316 9.02775 24.0743 9.16459C24.0354 9.25734 24.0093 9.31976 24.0126 9.31402Z'
        fill='#15195A'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.66862 6.88037H5.88211C6.31768 6.89438 6.67112 7.01465 6.78613 7.43939L7.48451 10.495C7.48458 10.4951 7.48464 10.4953 7.48471 10.4955L7.69845 11.4153L9.65449 6.88037H11.7665L8.62705 13.5237H6.51494L4.73472 7.7451C4.1205 7.43594 3.4195 7.18729 2.63574 7.01474L2.66862 6.88037Z'
        fill='#15195A'
      />
    </svg>
  )
}
