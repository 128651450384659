import { useCallback, useEffect } from 'react'

/**
 * Utility to handle clicks outside an element
 *
 * @param elementCSSClasses Classes that should not be on the path of the element
 * @param callback Callback function to execute when the click is outside the element
 */
export const useClickOutsideElem = (
  elementCSSClasses: Array<string>,
  callback: () => void,
): void => {
  const clickOut = useCallback(
    (e): void => {
      let targetElement = e.target // clicked element
      do {
        if (
          elementCSSClasses?.some((className) =>
            targetElement?.classList?.contains(className),
          )
        ) {
          // This is a click inside. Do nothing, just return.
          return
        }
        // Go up the DOM.
        targetElement = targetElement.parentNode
      } while (targetElement)

      callback()
    },
    [callback, elementCSSClasses],
  )

  useEffect(() => {
    const elem = document.querySelector('html')
    elem?.addEventListener('mousedown', clickOut)

    return () => {
      elem?.removeEventListener('mousedown', clickOut)
    }
  }, [clickOut])
}
