import { useEffect, useState } from 'react'
import CodeInputCell from './codeInputCell'
import { CodeInputProps } from './codeInput.props'

export function CodeInput({
  disabled = false,
  length,
  onSubmit,
}: CodeInputProps): JSX.Element {
  const initialValue = Array(length).fill(null)

  const [values, setValues] = useState<(string | null)[]>(initialValue)

  const focusPrev = (idx: number, shouldClear: boolean): void => {
    const prev = idx - 1
    if (prev < 0) return

    if (shouldClear) {
      const current = values.slice(0)
      current[prev] = null
      setValues(current)
    }

    // set focus on prev
    const prevInput: HTMLInputElement | null = document.querySelector(
      `#code-input-${prev}`,
    )
    if (prevInput) prevInput.focus()
  }

  const focusNext = (idx: number): void => {
    if (idx < length - 1) {
      const next: HTMLInputElement | null = document.querySelector(
        `#code-input-${idx + 1}`,
      )
      if (next) next.focus()
    }
    const current: HTMLInputElement | null = document.querySelector(
      `#code-input-${idx}`,
    )
    if (current) current.blur()
  }

  const handleValue = (value: string | null, idx: number): void => {
    if (value && value.length > 1) {
      value = value.slice(0, length)
      setValues(value.split(''))
    } else if (value && value.length > 0) {
      const current = values.slice(0)
      current[idx] = value
      setValues(current)
      focusNext(idx)
    } else {
      const current = values.slice(0)
      current[idx] = null
      setValues(current)
    }
  }

  useEffect(() => {
    const isValidCode = values.every((i) => i && !isNaN(Number(i)))
    if (isValidCode) {
      setTimeout(() => {
        onSubmit(values.map((i) => Number(i)))
      }, 750)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  useEffect(() => {
    focusNext(-1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='d-flex'>
      {Array(length)
        .fill(null)
        .map((_, idx) => {
          return (
            <CodeInputCell
              disabled={disabled}
              key={idx}
              idx={idx}
              onChange={handleValue}
              focusPrev={focusPrev}
              focusNext={focusNext}
              value={values[idx] || ''}
            />
          )
        })}
    </div>
  )
}
