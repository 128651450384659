import {
  ChangeEvent,
  FocusEvent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { TextField, FieldProps } from '@components'
import { Cancel, SearchIcon } from '@assets/icons'
import { DropdownState } from 'interfaces'

export interface SearchInputProps {
  onChange: (v: string) => void
  onFocus?: (e: FocusEvent<HTMLInputElement, Element>) => unknown
  onBlur?: (e: FocusEvent<HTMLInputElement, Element>) => unknown
  onClear?: () => void
  textFieldProps?: FieldProps
  debounceTime?: number
  dropdownState?: DropdownState
}

export function SearchInput({
  onChange,
  onBlur,
  onFocus,
  onClear,
  debounceTime,
  textFieldProps = {},
  dropdownState,
}: SearchInputProps): JSX.Element {
  const [focus, setFocus] = useState<boolean>(false)
  const [query, setQuery] = useState<string | null>(null)
  const clearInput = useCallback((): void => {
    setQuery(null)
    if (onClear) onClear()
  }, [onClear])

  useEffect(() => {
    if (dropdownState === DropdownState.CLOSED) clearInput()
  }, [clearInput, dropdownState])

  useEffect(() => {
    const id = setTimeout(() => {
      onChange(query)
    }, debounceTime || 250)

    return () => clearTimeout(id)
  }, [debounceTime, onChange, query])

  return (
    <TextField
      type='text'
      left={
        <SearchIcon color={focus ? 'iconPrimaryInitial' : 'iconNeutralSoft'} />
      }
      right={query?.length ? <Cancel color='iconNeutralSoft' /> : null}
      onRightClick={clearInput}
      theme='subtle'
      {...textFieldProps}
      inputProps={{
        ...(textFieldProps?.inputProps || {}),
        onFocus: (e) => {
          setFocus(true)
          if (onFocus) onFocus(e)
        },
        onBlur: (e) => {
          setFocus(false)
          if (onBlur) onBlur(e)
        },
        value: query ?? '',
        onChange: (e: ChangeEvent<HTMLInputElement>) => {
          setQuery(e?.target?.value)
        },
      }}
    />
  )
}

export const SearchBox = ({
  children,
}: {
  children: ReactNode
}): JSX.Element => {
  return <div className='search-input position-relative'>{children}</div>
}

SearchBox.Input = SearchInput
