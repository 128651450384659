import { UIIcon, UIIconColorProps, getIconColorSVGFill } from 'components/utils'

export const SellLimitOrderIcon = ({
  color,
  size = 24,
}: UIIcon & UIIconColorProps): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.1001 21.25L6.63844 15.8865C6.88955 15.5897 7.28989 15.4654 7.66494 15.5677L11.3047 16.5604C11.72 16.6736 12.1611 16.5084 12.3999 16.1503L16.5001 10'
        stroke={color ? getIconColorSVGFill(color) : 'currentColor'}
        strokeWidth='2'
        strokeLinecap='round'
      />
      <path
        d='M1.5 8.5L22.5 8.5'
        stroke={color ? getIconColorSVGFill(color) : 'currentColor'}
        strokeWidth='2'
        strokeLinecap='round'
        strokeDasharray='2 2'
      />
      <circle
        cx='16.5'
        cy='8.5'
        r='2'
        fill='white'
        stroke={color ? getIconColorSVGFill(color) : 'currentColor'}
        strokeWidth='2'
      />
    </svg>
  )
}
