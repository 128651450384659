export const PortfolioLaptopIllustration = (): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='251'
      height='147'
      fill='none'
      viewBox='0 0 251 147'
    >
      <rect
        width='225'
        height='136'
        x='8.564'
        y='0.76'
        fill='#fff'
        rx='3'
      ></rect>
      <path
        fill='var(--illustrationsNeutral40)'
        d='M15.064 7.76a3 3 0 013-3h212a3 3 0 013 3v132h-218v-132z'
      ></path>
      <path
        stroke='var(--actionPrimaryInitial)'
        strokeWidth='4'
        d='M15.064 7.76a3 3 0 013-3h212a3 3 0 013 3v132h-218v-132z'
      ></path>
      <rect
        width='223'
        height='137'
        x='12.564'
        y='3.26'
        stroke='var(--illustrationsSharedNeutral900)'
        rx='2.5'
      ></rect>
      <path
        fill='var(--actionPrimaryInitial)'
        stroke='var(--illustrationsSharedNeutral900)'
        d='M.564 134.76a.5.5 0 01.5-.5H101.85a.5.5 0 01.404.205l1.224 1.679a1.5 1.5 0 001.212.616h42.748c.479 0 .929-.229 1.212-.616l-.404-.295.404.295 1.224-1.679s0 0 0 0a.5.5 0 01.404-.205h98.786a.5.5 0 01.5.5v11a.5.5 0 01-.5.5h-248a.5.5 0 01-.5-.5v-11z'
      ></path>
      <rect
        width='22.644'
        height='5'
        x='33.989'
        y='23.867'
        fill='var(--illustrationsNeutral25)'
        rx='1'
      ></rect>
      <rect
        width='47.506'
        height='7.479'
        x='33.989'
        y='32.867'
        fill='var(--illustrationsNeutral25)'
        rx='1'
      ></rect>
      <rect
        width='32.781'
        height='5'
        x='33.989'
        y='44.346'
        fill='var(--illustrationsSuccess20)'
        rx='1'
      ></rect>
      <rect
        width='76.149'
        height='15.604'
        x='33.989'
        y='65.346'
        fill='var(--illustrationsNeutral25)'
        rx='4'
      ></rect>
      <rect
        width='76.149'
        height='15.604'
        x='33.989'
        y='84.949'
        fill='var(--illustrationsNeutral25)'
        rx='4'
      ></rect>
      <rect
        width='76.149'
        height='15.604'
        x='33.989'
        y='104.553'
        fill='var(--illustrationsNeutral25)'
        rx='4'
      ></rect>
      <mask id='path-11-inside-1_10556_277492' fill='#fff'>
        <path d='M153.024 56.596a26.005 26.005 0 010-13.458l10.046 2.692a15.595 15.595 0 000 8.075l-10.046 2.691z'></path>
      </mask>
      <path
        fill='var(--illustrationsSuccess20)'
        stroke='var(--illustrationsNeutral40)'
        d='M153.024 56.596a26.005 26.005 0 010-13.458l10.046 2.692a15.595 15.595 0 000 8.075l-10.046 2.691z'
        mask='url(#path-11-inside-1_10556_277492)'
      ></path>
      <mask id='path-12-inside-2_10556_277492' fill='#fff'>
        <path d='M153.024 43.138a26 26 0 016.729-11.656l7.354 7.354a15.595 15.595 0 00-4.037 6.994l-10.046-2.692z'></path>
      </mask>
      <path
        fill='var(--illustrationsWarning20)'
        stroke='var(--illustrationsNeutral40)'
        d='M153.024 43.138a26 26 0 016.729-11.656l7.354 7.354a15.595 15.595 0 00-4.037 6.994l-10.046-2.692z'
        mask='url(#path-12-inside-2_10556_277492)'
      ></path>
      <mask id='path-13-inside-3_10556_277492' fill='#fff'>
        <path d='M159.753 31.482a26.004 26.004 0 0111.656-6.729l2.692 10.046a15.607 15.607 0 00-6.994 4.037l-7.354-7.354z'></path>
      </mask>
      <path
        fill='var(--actionPrimaryInitial)'
        stroke='var(--illustrationsNeutral40)'
        d='M159.753 31.482a26.004 26.004 0 0111.656-6.729l2.692 10.046a15.607 15.607 0 00-6.994 4.037l-7.354-7.354z'
        mask='url(#path-13-inside-3_10556_277492)'
      ></path>
      <mask id='path-14-inside-4_10556_277492' fill='#fff'>
        <path d='M171.409 24.753a26 26 0 016.729-.886v10.4c-1.363 0-2.721.179-4.037.532l-2.692-10.046z'></path>
      </mask>
      <path
        fill='var(--illustrationsSharedDestructive500)'
        stroke='var(--illustrationsNeutral40)'
        d='M171.409 24.753a26 26 0 016.729-.886v10.4c-1.363 0-2.721.179-4.037.532l-2.692-10.046z'
        mask='url(#path-14-inside-4_10556_277492)'
      ></path>
      <mask id='path-15-inside-5_10556_277492' fill='#fff'>
        <path d='M178.138 23.867a26 26 0 11-25.114 32.73l10.046-2.692a15.596 15.596 0 0012.025 11.262 15.597 15.597 0 0015.419-5.803 15.593 15.593 0 001.615-16.396 15.591 15.591 0 00-13.991-8.7v-10.4z'></path>
      </mask>
      <path
        fill='var(--illustrationsSharedTertiary500)'
        stroke='var(--illustrationsNeutral40)'
        d='M178.138 23.867a26 26 0 11-25.114 32.73l10.046-2.692a15.596 15.596 0 0012.025 11.262 15.597 15.597 0 0015.419-5.803 15.593 15.593 0 001.615-16.396 15.591 15.591 0 00-13.991-8.7v-10.4z'
        mask='url(#path-15-inside-5_10556_277492)'
      ></path>
      <rect
        width='4'
        height='4'
        x='142.138'
        y='83.867'
        fill='var(--illustrationsSharedTertiary500)'
        rx='2'
      ></rect>
      <rect
        width='23'
        height='4'
        x='149.138'
        y='83.867'
        fill='var(--illustrationsNeutral25)'
        rx='2'
      ></rect>
      <rect
        width='23'
        height='4'
        x='191.138'
        y='83.867'
        fill='var(--illustrationsNeutral25)'
        rx='2'
      ></rect>
      <rect
        width='4'
        height='4'
        x='142.138'
        y='91.867'
        fill='var(--illustrationsSuccess20)'
        rx='2'
      ></rect>
      <rect
        width='14'
        height='4'
        x='149.138'
        y='91.867'
        fill='var(--illustrationsNeutral25)'
        rx='2'
      ></rect>
      <rect
        width='16'
        height='4'
        x='198.138'
        y='91.867'
        fill='var(--illustrationsNeutral25)'
        rx='2'
      ></rect>
      <rect
        width='4'
        height='4'
        x='142.138'
        y='99.867'
        fill='var(--illustrationsWarning20)'
        rx='2'
      ></rect>
      <rect
        width='27'
        height='4'
        x='149.138'
        y='99.867'
        fill='var(--illustrationsNeutral25)'
        rx='2'
      ></rect>
      <rect
        width='19'
        height='4'
        x='195.138'
        y='99.867'
        fill='var(--illustrationsNeutral25)'
        rx='2'
      ></rect>
      <rect
        width='4'
        height='4'
        x='142.138'
        y='107.867'
        fill='var(--actionPrimaryInitial)'
        rx='2'
      ></rect>
      <rect
        width='18'
        height='4'
        x='149.138'
        y='107.867'
        fill='var(--illustrationsNeutral25)'
        rx='2'
      ></rect>
      <rect
        width='11'
        height='4'
        x='203.138'
        y='107.867'
        fill='var(--illustrationsNeutral25)'
        rx='2'
      ></rect>
    </svg>
  )
}
