import { useEffect, useRef } from 'react'
import './stickyElementsWrapper.styles.scss'

export function StickyElementsWrapper({
  stickTableHeader,
  children,
  zIndex = '1',
  startPoint = 0,
}: {
  stickTableHeader?: boolean
  children?: React.ReactNode
  zIndex?: string
  startPoint?: number
}): JSX.Element {
  const refContainer = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const cont = refContainer.current

    if (!cont) return

    let accHeight = startPoint
    const nodes = cont.querySelectorAll<HTMLElement>('[data-sticky]')
    let arr = Array.from(nodes)

    if (stickTableHeader) {
      const tableHeader = cont.querySelectorAll<HTMLElement>('thead')
      arr = tableHeader ? [...arr, ...Array.from(tableHeader)] : arr
    }

    arr.forEach((e) => {
      const { height } = e.getBoundingClientRect()

      e.style.position = 'sticky'
      e.style.zIndex = zIndex
      e.style.top = `${accHeight}px`

      accHeight = accHeight + height
    })
  }, [stickTableHeader, startPoint, zIndex, children])

  return (
    <div className='sticky-container' ref={refContainer}>
      {children}
    </div>
  )
}
