import './savingPlan.styles.scss'
import {
  BigSpinner,
  Button,
  ErrorBlock,
  Spacer,
  StickyElementsWrapper,
  Text,
} from '@components'
import { ReactComponent as NoImg } from '@assets/svg/noImg.svg'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchAllSavingsPlans,
  fetchAssessmentEvaluation,
  fetchSavingsPlansAssessment,
  setShowAssessmentCard,
  clearSavingPlans,
} from '@store/commonReducers/savingsPlans'
import { RootState } from 'store'
import { AssetManagerCode, SavingsPlan } from '@interfaces'
import AssessmentCard from '../assessmentCard/assessmentCard'
import AssessmentModal, {
  AssessmentModalMode,
} from '../assessmentModal/assessmentModal'
import { SavingPlanTable } from './savingPlanTable/savingPlanTable'
import { InfoIcon } from '@assets/icons'

export default function SavingPlan({
  visiblePlans = [],
}: {
  visiblePlans: Array<AssetManagerCode>
}): JSX.Element {
  const dispatch = useDispatch()

  const {
    error,
    loading,
    loadingAssessment,
    savingsPlans,
    showAssessmentCard,
    riskAssessment,
    riskAssessmentEvaluation,
  } = useSelector((state: RootState) => state.savingsPlans)

  const [modalVisible, setModalVisible] = useState(false)
  const [modalMode, setModalMode] = useState(AssessmentModalMode.presentation)
  const loadingScreen = loading || loadingAssessment

  const hideAssessmentBanner = (): void => {
    dispatch(setShowAssessmentCard(false))
  }

  const handleAssessmentStart = (mode: AssessmentModalMode): void => {
    setModalMode(mode)
    setModalVisible(true)
  }

  const loadSavingPlans = (): void => {
    dispatch(clearSavingPlans())
    dispatch(fetchAllSavingsPlans())
    dispatch(fetchSavingsPlansAssessment())
    dispatch(fetchAssessmentEvaluation())
  }

  useEffect(() => {
    loadSavingPlans()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderPlan = (plan: SavingsPlan): JSX.Element => {
    return (
      <StickyElementsWrapper stickTableHeader startPoint={96} key={plan.code}>
        <div className='savingsPlans --fade-in-animation' data-sticky>
          <div className='savingsPlans__logo'>
            {plan?.logo ? <img src={plan.logo} alt={plan.name} /> : <NoImg />}
          </div>
          <div className='savingsPlans__content'>
            <div className='headline2 d-flex align-center'>
              {plan.name}
              <p className='savingsPlans__dot'>{'.'}</p>
              <Spacer preset='tiny' />
              <Button
                buttonType='link'
                shape='square'
                autoWidth
                size='tiny'
                onClick={() =>
                  handleAssessmentStart(AssessmentModalMode.presentation)
                }
                left={<InfoIcon color='tertiary' />}
              />
            </div>
            <Text preset='paragraph2' color='tertiary' text={plan.code} />
          </div>
        </div>
        {showAssessmentCard ? (
          <>
            <AssessmentCard
              onNotNow={() => hideAssessmentBanner()}
              onStart={() =>
                handleAssessmentStart(AssessmentModalMode.questions)
              }
              riskAssessmentIncorrect={
                riskAssessmentEvaluation?.testPassed === false
              }
            />
            <Spacer preset='medium' />
          </>
        ) : null}

        <SavingPlanTable
          assets={plan?.assets}
          screen='Discover - Saving plans'
        />
      </StickyElementsWrapper>
    )
  }

  return (
    <>
      {modalVisible && riskAssessment?.questions ? (
        <AssessmentModal
          onClose={() => {
            setModalVisible(false)
          }}
          step={modalMode}
        />
      ) : null}
      <div className='home__widget__portfolio --fade-in-animation'>
        {loadingScreen ? (
          <div className='savingsPlans__loader d-flex d-flex-col align-center  justify-center'>
            <BigSpinner />
          </div>
        ) : savingsPlans?.length ? (
          savingsPlans
            .filter((p) => visiblePlans?.includes(p?.code as AssetManagerCode))
            .map((s: SavingsPlan) => renderPlan(s))
        ) : null}
        {error ? (
          <>
            <ErrorBlock onReload={loadSavingPlans} />
          </>
        ) : null}
      </div>
    </>
  )
}
