import { PaymentMethodTypeEnum, PaymentMethods } from '../interfaces'
import { api } from '@utils/api/api'

export const getPaymentMethods = (): Promise<PaymentMethods> => {
  return api.get('/api/v1/customers/me/payment-methods')
}

export const deletePaymentMethod = (
  paymentMethodId: string,
  type: PaymentMethodTypeEnum,
): Promise<void> => {
  const isCard = type === PaymentMethodTypeEnum?.CARD
  return isCard
    ? api.delete(`/api/v1/card-payments/cards/${paymentMethodId}`)
    : api.delete(`/api/v1/customers/me/payment-methods/${paymentMethodId}`)
}

export const setPaymentMethod = (paymentMethodId: string): Promise<void> => {
  return api.patch(`/api/v1/customers/me/payment-methods/${paymentMethodId}`, {
    default: true,
  })
}

export const deleteManualTransfer = (paymentId: string): Promise<void> => {
  return api.delete(`/api/v1/manual-transfer-template/${paymentId}`)
}

export const getPaymentMethodStatus = (
  paymentMethodId: string,
): Promise<void> => {
  return api.get(
    `/api/v1/customers/me/payment-methods/${paymentMethodId}/status`,
  )
}
