import {
  AssessmentAnswers,
  AssessmentEvaluation,
  AssessmentQuestionnaire,
} from '@interfaces'
import { api } from '@utils/api/api'

export const getAssessmentQuestions = (
  assessmentId: string,
): Promise<AssessmentQuestionnaire> => {
  return api.get(`/api/v1/assessments/${assessmentId}/questions`)
}

export const saveAssessmentAnswers = (
  assessmentId: string,
  answers: AssessmentAnswers,
): Promise<AssessmentEvaluation> => {
  return api.put(`/api/v1/assessments/${assessmentId}/answers/me`, answers)
}

export const getAssessmentEvaluation = (
  assessmentId: string,
): Promise<AssessmentEvaluation> => {
  return api.get(`/api/v1/assessments/${assessmentId}/answers/me/evaluation`)
}
