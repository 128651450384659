import { AnyAction, createReducer } from '@reduxjs/toolkit'
import {
  AssessmentAnswers,
  AssessmentChoice,
  AssessmentChoiceType,
  AssessmentQuestion,
  AssessmentState,
} from '@interfaces'
import {
  checkAssessmentAnswer,
  clearSavingPlans,
  fetchAllSavingsPlans,
  fetchAssessmentEvaluation,
  fetchSavingsPlan,
  fetchSavingsPlansAssessment,
  saveSavingsPlansAssessment,
  setShowAssessmentCard,
} from './thunk'

const initialState: AssessmentState = {
  loading: true,
  error: null,
  loadingAssessment: true,
  errorAssessment: null,
  loadingSave: false,
  errorSave: null,
  assessmentSaved: false,
  savingsPlans: [],
  showAssessmentCard: true,
  riskAssessment: null,
  riskAssessmentAllRequired: false,
  riskAssessmentAnswers: {},
  riskAssessmentEvaluation: null,
}

const pendingAction = (action: AnyAction): boolean =>
  action.type === fetchAllSavingsPlans.pending.type ||
  action.type === fetchSavingsPlan.pending.type
const fulfilledAction = (action: AnyAction): boolean =>
  action.type === fetchAllSavingsPlans.fulfilled.type ||
  action.type === fetchSavingsPlan.fulfilled.type
const rejectedAction = (action: AnyAction): boolean =>
  action.type === fetchAllSavingsPlans.rejected.type ||
  action.type === fetchSavingsPlan.rejected.type

const addAnswer = (
  answers: AssessmentAnswers,
  questionId: string,
  choiceId: string,
  isSingleOption: boolean,
): AssessmentAnswers => {
  return {
    ...answers,

    [questionId]: isSingleOption
      ? [choiceId]
      : answers[questionId]
      ? [...answers[questionId], choiceId]
      : [choiceId],
  }
}
const removeAnswer = (
  answers: AssessmentAnswers,
  questionId: string,
  choiceId: string,
): AssessmentAnswers => {
  const i = answers[questionId].indexOf(choiceId)
  if (i > -1) {
    answers[questionId].splice(i, 1)
    if (!answers[questionId].length) {
      delete answers[questionId]
    }
  }
  return answers
}

const handleAnswers = (
  questions: AssessmentQuestion[],
  newValue: boolean,
  optionId: string,
  idx: number,
  currentAnswers: AssessmentAnswers,
): { choices: AssessmentChoice[]; answers: AssessmentAnswers } => {
  const question = questions[idx]
  const isSingleOption =
    question.choiceType === AssessmentChoiceType.singleSelection
  let answers = { ...currentAnswers }
  const choices = question.choices.map((c) => {
    if (c.id === optionId) {
      if (newValue) {
        answers = addAnswer(answers, question.id, c.id, isSingleOption)
      }

      if (!newValue && answers[question.id]) {
        answers = removeAnswer(answers, question.id, c.id)
      }

      return { ...c, checked: newValue }
    }
    return isSingleOption ? { ...c, checked: false } : c
  })
  return { choices, answers }
}

export const savingsPlansReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(clearSavingPlans, (acc) => {
      acc.loading = true
      acc.error = null
      acc.savingsPlans = []
      acc.riskAssessmentAnswers = {}
      acc.riskAssessmentEvaluation = null
    })
    .addCase(setShowAssessmentCard, (acc, { payload }) => {
      acc.showAssessmentCard = payload
      if (payload) {
        acc.riskAssessmentAnswers = {}
        acc.assessmentSaved = false
      }
    })
    .addCase(fetchSavingsPlansAssessment.pending, (acc) => {
      acc.loadingAssessment = true
      acc.errorAssessment = null
    })
    .addCase(fetchSavingsPlansAssessment.fulfilled, (acc, { payload }) => {
      acc.loadingAssessment = false
      acc.riskAssessment = payload
    })
    .addCase(fetchSavingsPlansAssessment.rejected, (acc, { error }) => {
      acc.loadingAssessment = false
      acc.errorAssessment = error.message
    })
    .addCase(saveSavingsPlansAssessment.pending, (acc) => {
      acc.loadingSave = true
      acc.errorSave = null
    })
    .addCase(saveSavingsPlansAssessment.fulfilled, (acc, { payload }) => {
      acc.loadingSave = false
      acc.assessmentSaved = true
      acc.showAssessmentCard = !!payload.incongruitiesId || !payload.testPassed
      acc.riskAssessmentEvaluation = payload
    })
    .addCase(fetchAssessmentEvaluation.fulfilled, (acc, { payload }) => {
      acc.assessmentSaved = true
      acc.showAssessmentCard = !!payload.incongruitiesId || !payload.testPassed
      acc.riskAssessmentEvaluation = payload
    })
    .addCase(saveSavingsPlansAssessment.rejected, (acc, { error }) => {
      acc.loadingSave = false
      acc.errorSave = error.message
    })
    .addCase(
      checkAssessmentAnswer,
      (acc, { payload: { newValue, optionId, idx } }) => {
        const { choices, answers } = handleAnswers(
          acc.riskAssessment.questions,
          newValue,
          optionId,
          idx,
          acc.riskAssessmentAnswers,
        )

        acc.riskAssessment.questions[idx].choices = choices
        acc.riskAssessmentAnswers = answers

        const requiredQs = acc.riskAssessment.questions.filter(
          (q) => q.required,
        )
        const responses = Object.keys(answers)
        acc.riskAssessmentAllRequired = requiredQs.length <= responses.length
      },
    )
    .addMatcher(pendingAction, (acc) => {
      acc.loading = true
      acc.error = null
    })
    .addMatcher(fulfilledAction, (acc, { payload }) => {
      acc.loading = false
      acc.error = null
      acc.savingsPlans = payload
    })
    .addMatcher(rejectedAction, (acc, { error }) => {
      acc.loading = false
      acc.error = error.message
      acc.savingsPlans = []
    })
})
