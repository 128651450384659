import {
  UIBackgroundColorProps,
  UIBorderColorProps,
  getBgColorClass,
  getBorderColorClass,
  stringBuilder,
} from 'components/utils'
import './card.styles.scss'
import { useMemo } from 'react'

export interface CardProps {
  children: React.ReactNode
  width?: 'fit-content' | 'full'
  padding?:
    | 'none'
    | 'extraTiny'
    | 'tiny'
    | 'smaller'
    | 'small'
    | 'medium'
    | 'big'
  bordered?: boolean
  onClick?: () => void
}

export const Card = ({
  width = 'fit-content',
  children,
  bgColor = 'secondaryBase',
  padding = 'small',
  borderColor = 'strokeNeutralSoftInitial',
  bordered = false,
  onClick,
}: CardProps & UIBackgroundColorProps & UIBorderColorProps): JSX.Element => {
  const handleClick = (e): void => {
    e?.preventDefault()
    if (onClick) onClick()
  }

  const classes = useMemo(() => {
    return stringBuilder([
      ['card-component', true],
      ['--card-component-with-hover cursor-pointer', !!onClick],
      ['--card-component-padding-none', padding === 'none'],
      ['--card-component-padding-extra-tiny', padding === 'extraTiny'],
      ['--card-component-padding-tiny', padding === 'tiny'],
      ['--card-component-padding-smaller', padding === 'smaller'],
      ['--card-component-padding-small', padding === 'small'],
      ['--card-component-padding-medium', padding === 'medium'],
      ['--card-component-padding-big', padding === 'big'],
      ['--card-component-bordered', !!bordered],
      ['--card-component-full-width', width === 'full'],
      [getBgColorClass(bgColor), true],
      [getBorderColorClass(borderColor), true],
    ])
  }, [onClick, padding, bordered, width, bgColor, borderColor])

  return (
    <div className={classes} onClick={handleClick} aria-hidden>
      {children}
    </div>
  )
}
