/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
/* eslint-disable sonarjs/cognitive-complexity */
import {
  Avatar,
  Button,
  CustomModal,
  InputRadio,
  SelectionSummary,
  Spacer,
  Text,
} from '@components'
import {
  PeriodicityEnum,
  TopUpPaymentType,
  TypeOptionDefinition,
} from '@interfaces'
import { BasicOption, RecurrinPaymentConfigModal } from '@shared/modals'
import { RootState } from '@store'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setTopUpType } from '../topup/thunk'
import {
  ChevronDown,
  OneTimePaymentIcon,
  RecurringPaymentIcon,
} from '@assets/icons'
import { useTranslation } from 'react-i18next'

export const useTopUpTypeSelector = (): {
  selector: JSX.Element
  typeSelectorModal: JSX.Element

  recurringTopUpSummary: JSX.Element
  recurringPaymentModal: JSX.Element
} => {
  const addMoney = useSelector((state: RootState) => state?.addMoney)
  const { t } = useTranslation()
  const [recurringPaymentVisible, setRecurringPaymentVisible] = useState(false)
  const dispatch = useDispatch()

  const [activeOption, setActiveOption] = useState<TypeOptionDefinition>()
  const [showSelectionModal, setShowSelectionModal] = useState<boolean>(false)

  const [options] = useState([
    {
      title: t('recurringPayments.typeModal.singleTitle'),
      description: t('recurringPayments.typeModal.singleDesc'),
      id: TopUpPaymentType.SINGLE,
      icon: (
        <OneTimePaymentIcon
          size={20}
          color={
            activeOption?.id === TopUpPaymentType.SINGLE
              ? 'iconNeutralSoft'
              : null
          }
        />
      ),
      selectedTitle: t('recurringPayments.typeModal.singleTitle'),
    },
    {
      title: t('recurringPayments.typeModal.recurringTitle'),
      description: t('recurringPayments.typeModal.recurringDesc'),
      id: TopUpPaymentType.RECURRING,
      icon: (
        <RecurringPaymentIcon
          size={20}
          color={
            activeOption?.id === TopUpPaymentType.RECURRING
              ? 'iconNeutralSoft'
              : null
          }
        />
      ),
      selectedTitle: t('recurringPayments.typeModal.recurringTitle'),
    },
  ])

  useEffect(() => {
    if (activeOption?.id === TopUpPaymentType.RECURRING) {
      setRecurringPaymentVisible(true)
    }
  }, [activeOption])

  useEffect(() => {
    setActiveOption(options?.find((o) => o?.id === TopUpPaymentType.SINGLE))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const recurringTopUpConfig: Array<BasicOption<PeriodicityEnum | string>> =
    useMemo(() => {
      const obj = addMoney?.recurringTopUpConfig
      return obj
        ? Object.keys(obj)?.reduce((acc, i) => {
            return obj[i]?.value ? [...acc, obj[i]] : acc
          }, [])
        : []
    }, [addMoney?.recurringTopUpConfig])

  return {
    selector: (
      <div
        className='d-flex cursor-pointer'
        onClick={() => setShowSelectionModal(true)}
        aria-hidden
      >
        <Text
          text={
            activeOption
              ? activeOption?.selectedTitle
              : t('recurringPayments.selectType')
          }
          preset='paragraph2'
          color='iconPrimaryInitial'
        />
        <ChevronDown color='actionPrimaryInitial' />
      </div>
    ),
    typeSelectorModal: showSelectionModal && (
      <CustomModal
        showBackgrop={false}
        show={showSelectionModal}
        theme='lighter'
        size='small'
        dismissable
        onClose={() => setShowSelectionModal(false)}
      >
        <CustomModal.Header
          text={t('recurringPayments.selectType')}
          onAction={() => setShowSelectionModal(false)}
        ></CustomModal.Header>
        <CustomModal.Content>
          {options?.map((option, idx) => {
            const isLast = idx === options?.length - 1
            return (
              <div
                key={`selectorOption${idx as undefined as string}`}
                onClick={() => {
                  dispatch(setTopUpType(option?.id))
                  setActiveOption(option)
                  setShowSelectionModal(false)
                }}
                aria-hidden='true'
                role='button'
              >
                <InputRadio
                  containerStyle='selectorOption'
                  checked={option?.id === activeOption?.id}
                  id={`selectorOption${idx as undefined as string}`}
                  value={option.title}
                  name='selectorOption'
                  textContainerStyle='d-flex d-flex-row w-100 align-center'
                >
                  <Avatar
                    image={option?.icon}
                    bgColor='primaryBase'
                    roundness='full'
                    size='mediumPlus'
                  />
                  <Spacer preset='smaller' />
                  <div className='d-flex d-flex-col'>
                    <Text
                      text={option?.title}
                      preset='paragraph2'
                      color='primary'
                    />
                    <Text
                      text={option?.description}
                      preset='paragraph2'
                      color='secondary'
                    />
                  </div>
                  <Spacer preset='smaller' />
                </InputRadio>
                {!isLast ? <Spacer preset='small' /> : null}
              </div>
            )
          })}
        </CustomModal.Content>
        <CustomModal.Footer>
          <Button
            text={t('common.cancel')}
            onClick={() => setShowSelectionModal(false)}
            buttonType='primary'
          />
        </CustomModal.Footer>
      </CustomModal>
    ),
    recurringTopUpSummary: (
      <>
        {addMoney?.topUpType === TopUpPaymentType.RECURRING &&
          !!recurringTopUpConfig?.length && (
            <>
              <Spacer preset='small' />
              <SelectionSummary
                onClick={() => setRecurringPaymentVisible(true)}
                items={recurringTopUpConfig}
              />
              <Spacer preset='small' />
            </>
          )}
      </>
    ),
    recurringPaymentModal: recurringPaymentVisible && (
      <RecurrinPaymentConfigModal
        close={() => {
          setRecurringPaymentVisible(false)
        }}
      />
    ),
  }
}
