import { SummaryRowProps } from '@components'
import { useTranslation } from '@hooks'
import { RootState } from '@store'
import { formatCurrency, formatMoney } from '@utils/helpers'
import { CardPaymentDetails } from 'features/moneyOperations/steps/cardPaymentSummary'
import { useSelector } from 'react-redux'

export const useSingleTopUpSummary = (
  paymentInfo: CardPaymentDetails,
): SummaryRowProps[] => {
  const { t } = useTranslation()

  const currency = paymentInfo?.amount?.currency

  return [
    {
      label: t('confirmPayment.amount'),
      value: formatCurrency(paymentInfo?.amount),
    },
    {
      label: t('confirmPayment.fees', {
        feesPercent: paymentInfo?.feesPercent,
      }),
      value: formatCurrency({
        amount: paymentInfo?.fees?.amount,
        currency,
      }),
    },
    {
      label: t('confirmPayment.total'),
      value: formatCurrency(paymentInfo.total),
    },
  ]
}

export function useRecurringPaymentSummaryRows(
  paymentInfo: CardPaymentDetails,
): SummaryRowProps[] {
  const { t } = useTranslation()

  const recurringPaymentConfig = useSelector(
    (state: RootState) => state?.addMoney?.recurringTopUpConfig,
  )

  return [
    {
      label: t('confirmPayment.amount'),
      value: formatCurrency(paymentInfo?.amount),
    },
    {
      label: t('recurringPayments.periodicity.periodicity'),
      value: recurringPaymentConfig?.periodicity?.title,
    },
    {
      label: t('recurringPayments.date.starting'),
      value: `${recurringPaymentConfig?.startingDate?.description}`,
      valueSubText: `${recurringPaymentConfig?.startingDate?.title}`,
    },
    {
      label: t('recurringPayments.date.expiration'),
      value: `${recurringPaymentConfig?.expiration?.description}`,
      valueSubText: `${recurringPaymentConfig?.expiration?.title}`,
    },
    {
      label: t('recurringPayments.scheduled'),
      value: recurringPaymentConfig?.paymentsCount?.toString(),
    },
    {
      label: t('confirmPayment.fees', {
        feesPercent: paymentInfo?.feesPercent,
      }),
      value: formatCurrency(paymentInfo?.fees),
      valueSubText: t('recurringPayments.perTransaction'),
    },
    {
      label: t('confirmPayment.total'),
      value: formatCurrency(paymentInfo?.total),
      valueSubText: t('recurringPayments.perTransaction'),
    },
  ]
}

export function useRecurringPaymentOverviewRows(
  paymentInfo: CardPaymentDetails,
): SummaryRowProps[] {
  const { t } = useTranslation()

  const recurringInfo = useSelector(
    (state: RootState) => state?.addMoney?.recurringTopUpConfig,
  )
  if (
    !recurringInfo?.periodicity ||
    !recurringInfo?.startingDate ||
    !recurringInfo?.expiration
  ) {
    return []
  }

  const amountOfPayments = recurringInfo?.paymentsCount

  const { amount, fees, feesPercent } = paymentInfo
  const amountPlusFees = amount.amount + fees?.amount

  return [
    {
      label: t('recurringPayments.amount'),
      labelSubText: `${formatCurrency(amount)} · ${amountOfPayments}`,
      value: formatMoney(amount.amount * amountOfPayments, amount.currency),
    },
    {
      label: t('recurringPayments.fees', { feesPercent: feesPercent }),
      labelSubText: `${formatCurrency(fees)} · ${amountOfPayments}`,
      value: formatMoney(fees?.amount * amountOfPayments, amount.currency),
    },
    {
      label: t('recurringPayments.total'),
      labelSubText: `${formatMoney(
        amountPlusFees,
        amount.currency,
      )} · ${amountOfPayments}`,
      value: formatMoney(amountPlusFees * amountOfPayments, amount.currency),
    },
  ]
}
