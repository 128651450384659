import {
  AssetsInfo,
  CompanyInfo,
  CompanyPositionData,
  PeriodEnum,
} from '@interfaces'
import { api } from '@utils/api/api'

export const getCompanyInvestments = (
  id: string,
  realTimePrices = true,
): Promise<CompanyPositionData> => {
  return api.get(
    `/api/v1/portfolio/performance/holding/${id}${
      realTimePrices ? '?realTimePrices=true' : ''
    }`,
  )
}

export const getCompanyStocks = (
  company: string,
  period = PeriodEnum.day,
  includeChart = 'true',
): Promise<AssetsInfo> => {
  return api.get(
    `/api/v1/stocks/${company}?period=${period}&includeChart=${includeChart}`,
  )
}

export const getCompanyInfo = (company: string): Promise<CompanyInfo> => {
  return api.get(`/api/v1/stocks/${company}/company-info`)
}

export const getCryptoCompanyInfo = (company: string): Promise<CompanyInfo> => {
  return api.get(`/api/v1/cryptos/cryptocurrencies/${company}/company-info`)
}

export const getCompanyCryptos = (
  company: string,
  period = PeriodEnum.day,
  includeChart = 'true',
): Promise<AssetsInfo> => {
  return api.get(
    `/api/v1/cryptos/cryptocurrencies/${company}?period=${period}&includeChart=${includeChart}`,
  )
}
