import { BullishBearish } from '@interfaces/companyOverview'
import { Card, Spacer, Text } from 'components'
import { format } from 'date-fns'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'store'

interface BullishSummary {
  bullishSummary: string
}

interface BearishSummary {
  bearishSummary: string
}

interface AllBullBearsFormatData {
  date: string
  bullSays: BullishSummary[]
  bearsSays: BearishSummary[]
}
const BullBears = (): JSX.Element => {
  const { t } = useTranslation()
  const { bullBears } = useAppSelector((state) => state.tipRank)

  const bullishBearishData = (
    data: BullishBearish[],
  ): AllBullBearsFormatData[] => {
    return data.reduce((acc, item) => {
      const formattedDate = format(new Date(item.date), 'MMMM, d')
      const existingGroup = acc.find((group) => group.date === formattedDate)
      if (existingGroup) {
        existingGroup.bullSays.push({ bullishSummary: item.bullishSummary })
        existingGroup.bearsSays.push({ bearishSummary: item.bearishSummary })
      } else {
        acc.push({
          date: formattedDate,
          bullSays: [{ bullishSummary: item.bullishSummary }],
          bearsSays: [{ bearishSummary: item.bearishSummary }],
        })
      }
      return acc
    }, [])
  }

  return (
    <div className='d-flex d-flex-col align-start w-100 tab-scroll-area'>
      <Spacer preset='small' />
      {bullishBearishData(bullBears).map((item, index) => (
        <Fragment key={index}>
          <Text text={item.date} preset='bodyBig' />
          <Spacer preset='small' />
          <div className='d-flex justify-space-between align-start w-100 analyst-rating'>
            <div className='w-50'>
              <Text text={t('companyDetails.bullsSay')} preset='bodyRegular' />
              <Spacer preset='tiny' />
              <Card
                key='rating-card'
                width='full'
                aria-hidden
                padding='smaller'
              >
                <Spacer preset='tiny' />
                {item.bullSays.map((bull, i) => (
                  <Fragment key={i}>
                    <Text text={bull.bullishSummary} preset='bodyRegular' />
                    <Spacer preset='small' />
                  </Fragment>
                ))}
              </Card>
            </div>
            <Spacer preset='medium' />
            <div className='w-50'>
              <Text text={t('companyDetails.bearsSay')} preset='bodyRegular' />
              <Spacer preset='tiny' />
              <Card
                key='rating-card'
                width='full'
                aria-hidden
                padding='smaller'
              >
                <Spacer preset='tiny' />

                {item.bearsSays.map((bear, i) => (
                  <Fragment key={i}>
                    <Text text={bear.bearishSummary} preset='bodyRegular' />
                    <Spacer preset='small' />
                  </Fragment>
                ))}
              </Card>
            </div>
          </div>
          <Spacer preset='small' />
        </Fragment>
      ))}
    </div>
  )
}

export default BullBears
