import { UIIcon, UIIconColorProps, getIconColorSVGFill } from 'components/utils'

export const ImportantIcon = ({
  size = 24,
  color,
}: UIIcon & UIIconColorProps): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4 18.99H15C15.67 18.99 16.27 18.67 16.63 18.16L21 12L16.63 5.84C16.27 5.33 15.67 5 15 5H4L9 12L4 18.99Z'
        fill={color ? getIconColorSVGFill(color) : 'currentColor'}
      />
    </svg>
  )
}
