import './portfolioInfoModal.styles.scss'
import { blackRockIcon } from '@assets/icons/blackRockIcon'
import { CustomModal, Button } from '@components'
import { formatMoney } from '@utils/helpers'
import { PortfolioSlider, StockPerformance } from '@features'
import { ReactComponent as NoImg } from '@assets/svg/noImg.svg'
import { Investments, Stock } from '@interfaces'
import { useTranslation } from '@hooks'

export interface PortfolioInfoProps {
  showInfo: boolean
  setShowInfo: (newValue: boolean) => void
  investments?: Investments
}

export function PortfolioInfoModal({
  showInfo,
  setShowInfo,
  investments,
}: PortfolioInfoProps): JSX.Element {
  const { t } = useTranslation()

  const investedValue = formatMoney(
    investments?.currentValue?.amount,
    investments?.currentValue?.currency,
  )
  const renderAssetHelp = (asset: Stock): JSX.Element => {
    if (!asset) return null
    const logo = asset?.logoUrl
    const isSavingPlan = asset?.savingPlan ? true : false

    const realPrice = formatMoney(asset?.price?.amount, asset?.price?.currency)
    const avgEntryPrice = formatMoney(
      asset?.avgEntryPrice?.amount,
      asset?.avgEntryPrice?.currency,
    )

    return (
      <div
        className='mt-2 py-2 px-2 bradius-1 bg-primary-base d-flex align-center portfolio__info__wrapper assetHelp'
        key={`portfolio-info-${asset.id}-${asset.ticker}`}
      >
        <div className='assetHelp__row'>
          <div className='assetHelp__row__img'>
            {isSavingPlan ? blackRockIcon(asset?.savingPlan?.srri) : null}
            {logo && !isSavingPlan ? (
              <img src={logo} alt='' className='assetHelp__row__img__inner' />
            ) : null}
            {!logo && !isSavingPlan ? <NoImg /> : null}
          </div>

          <div className='assetHelp__row__text'>
            <p className='caption2'>{asset?.name}</p>
            <p className='paragraph2 tertiary--text'>
              {asset?.shares} {asset?.ticker}
            </p>
          </div>

          <div className='assetHelp__row__amounts'>
            <p>{realPrice}</p>
            <StockPerformance
              className='orangeOutline'
              performanceAmount={asset?.totalEarnings}
              performancePercentage={asset?.totalPerformance}
              plusSymbol={true}
            />
          </div>
        </div>

        {isSavingPlan ? (
          <div className='assetHelp__savingPlan'>
            <div className='assetHelp__savingPlan__row'>
              <p className='paragraph2 tertiary--text'>
                {t('savingsPlans.currentPrice')}
              </p>
              <p className='paragraph2 text-right'>{realPrice}</p>
            </div>
            <div className='assetHelp__savingPlan__row'>
              <p className='paragraph2 tertiary--text'>
                {t('savingsPlans.averageEntryPrice')}
              </p>
              <p className='paragraph2 text-right'>{avgEntryPrice}</p>
            </div>
          </div>
        ) : null}
      </div>
    )
  }

  return (
    <CustomModal
      show={showInfo}
      dismissable
      onClose={() => setShowInfo(false)}
      placeOn='right'
      height='fullHeight'
    >
      <div className='portfolio__info__header headline3'>
        {t('portfolioInfo.aboutPortfolio')}
        <span className='orange--text'>h!</span>
      </div>
      <CustomModal.Content>
        <div className='d-flex-col align-self-start text-left'>
          <div className='portfolio__info__row'>
            <p className='portfolio__info__row__title mb-1'>
              {t('portfolioInfo.currentValue')}
            </p>
            <p className='portfolio__info__row__description'>
              {t('portfolioInfo.currentValueSummary')}
            </p>
            <div className='portfolio__info__background__outer mt-2 py-1 px-1 '>
              <div className='home__widget__portfolio__title align-center paragraph5'>
                <p>{t('portfolioInfo.title')}</p>
                <span className='orange--text'>h!</span>
              </div>
              <p className='paragraph5 orangeOutline d-inline'>
                {investedValue}
              </p>
            </div>
          </div>
          <div className='portfolio__info__row'>
            <p className='portfolio__info__row__title mb-1'>
              {t('portfolioInfo.unrealized')}
            </p>
            <p className='portfolio__info__row__description'>
              {t('portfolioInfo.unrealizedSummary')}
            </p>
            <div className='mt-2 pt-1 pb-1 px-2 bradius-1 bg-primary-base d-flex align-center portfolio__info__wrapper'>
              <div className='investment__summary__investedTotal__variationAmount__stock pr-1'>
                <StockPerformance
                  performanceAmount={investments?.totalEarnings}
                  performancePercentage={investments?.totalPerformance}
                  type='amount'
                />
              </div>
              <span className='profit__loss tertiary--text'>
                {t('home.unrealizedProfitLoss')}
              </span>
            </div>
          </div>
          <div className='portfolio__info__row'>
            <p className='portfolio__info__row__title mb-1'>
              {t('portfolioInfo.today')}
            </p>
            <p className='portfolio__info__row__description'>
              {t('portfolioInfo.todaySummary')}
            </p>
            <div className='mt-2 pt-1 pb-1 px-2 bradius-1 bg-primary-base d-flex align-center portfolio__info__wrapper'>
              <div className='investment__summary__investedToday__icon mr-1'>
                <StockPerformance
                  performanceAmount={investments?.totalEarnings}
                  performancePercentage={investments?.totalPerformance}
                  type='icon'
                />
              </div>
              <div className='investment__summary__investedToday__info'>
                <p className='investment__summary__investedToday__info__date tertiary--text'>
                  {t('date.today')}
                </p>
                <span
                  className={`investment__summary__investedToday__info__variationAmount`}
                >
                  <div className='orangeOutline'>
                    <StockPerformance
                      performanceAmount={investments?.totalEarningsToday}
                      performancePercentage={investments?.totalPerformanceToday}
                      type='percentage'
                      className='pr-0'
                    />
                  </div>
                </span>
              </div>
            </div>
          </div>
          <div className='portfolio__info__row'>
            <p className='portfolio__info__row__title mb-1'>
              {t('portfolioInfo.assetsOverview')}
            </p>
            <p className='portfolio__info__row__description'>
              {t('portfolioInfo.assetsOverviewSummary')}
            </p>

            <div className='mt-2 pt-1 pb-1 pr-2 pl-2 bradius-1 bg-primary-base d-flex align-center portfolio__info__wrapper'>
              <PortfolioSlider
                className='portfolio__info__sliderAdjust'
                investments={investments}
                shownPortfolio={0}
              />
            </div>
          </div>

          <div className='portfolio__info__row'>
            <p className='portfolio__info__row__title mb-1'>
              {t('portfolioInfo.assets')}
            </p>
            <p className='portfolio__info__row__description'>
              {t('portfolioInfo.assetsSummary')}
            </p>
            {investments?.portfolios?.map((portfolio) =>
              renderAssetHelp(portfolio?.positions[0]),
            )}
          </div>
          <div className='portfolio__info__row'>
            <p className='portfolio__info__row__title mb-1'>
              {t('portfolioInfo.calculation')}
            </p>
            <p className='portfolio__info__row__description'>
              {t('portfolioInfo.calculationSummary')}
            </p>
          </div>
          <div className='portfolio__info__row'>
            <p className='portfolio__info__row__title'>
              {t('portfolioInfo.note')}
            </p>
            <ul className='default_list portfolio__info__row__description'>
              <li>{t('portfolioInfo.notePoint1')}</li>
              <li>{t('portfolioInfo.notePoint2')}</li>
              <li>{t('portfolioInfo.notePoint3')}</li>
            </ul>
          </div>
        </div>
      </CustomModal.Content>
      <CustomModal.Footer>
        <Button
          text={t('common.ok')}
          type='submit'
          size='big'
          loading={false}
          onClick={() => setShowInfo(false)}
        />
      </CustomModal.Footer>
    </CustomModal>
  )
}
