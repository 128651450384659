import { AppLanguages } from '@interfaces'

export const languages = [
  { code: 'en-US', nativeName: 'English', apiLanguage: AppLanguages.en },
  { code: 'ka', nativeName: 'ქართული ენა', apiLanguage: AppLanguages.ka },
  // { code: 'ru', nativeName: 'Русский', apiLanguage: AppLanguages.ru },
]

export type Language = typeof languages[0]

export const langDefault = 'en-US'
export const changeLang = (lang: string = langDefault): void => {
  if (localStorage.getItem('heySettings')) {
    const settings = JSON.parse(localStorage.getItem('heySettings') || '')
    settings.lang = lang
    localStorage.setItem('heySettings', JSON.stringify(settings))
  } else {
    const settings = { lang }
    localStorage.setItem('heySettings', JSON.stringify(settings))
  }
}

export const getLang = (): string => {
  if (localStorage.getItem('heySettings')) {
    const settings = JSON.parse(localStorage.getItem('heySettings') || '')

    return settings.lang || langDefault
  } else {
    return langDefault
  }
}

export const getAppLang = (): AppLanguages => {
  const lang = getLang()

  if (lang.includes('ka')) {
    return AppLanguages.ka
  }
  if (lang.includes('ru')) {
    return AppLanguages.ru
  }
  return AppLanguages.en
}

export const getFormatLang = (lang = getLang()): string => {
  const formatLangMapper = {
    'es-': 'de-DE',
    'pr-': 'fr-FR',
    ka: 'ru',
  }

  Object.keys(formatLangMapper).forEach((key) => {
    if (lang.includes(key, 0)) lang = formatLangMapper[key]
  })

  return lang
}
