export const EmptyWatchlist = (): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='251'
      height='169'
      fill='none'
      viewBox='0 0 251 169'
    >
      <rect width='225' height='136' x='9' y='23' fill='#fff' rx='3'></rect>
      <path
        fill='var(--illustrationsNeutral40)'
        d='M15.5 30a3 3 0 013-3h212a3 3 0 013 3v132h-218V30z'
      ></path>
      <path
        stroke='var(--illustrationsSharedPrimary500)'
        strokeWidth='4'
        d='M15.5 30a3 3 0 013-3h212a3 3 0 013 3v132h-218V30z'
      ></path>
      <rect
        width='46'
        height='4'
        x='32'
        y='45.5'
        fill='var(--illustrationsNeutral25)'
        stroke='var(--illustrationsNeutral25)'
        rx='0.5'
      ></rect>
      <rect
        width='13'
        height='4'
        x='32'
        y='56.5'
        fill='var(--illustrationsNeutral25)'
        stroke='var(--illustrationsNeutral25)'
        rx='0.5'
      ></rect>
      <rect
        width='14'
        height='5'
        x='49.5'
        y='56'
        fill='var(--illustrationsSuccess20)'
        rx='1'
      ></rect>
      <rect width='130' height='10' x='31.5' y='71' fill='#fff' rx='1'></rect>
      <rect
        width='40.219'
        height='5'
        x='35.171'
        y='73.5'
        fill='var(--illustrationsNeutral25)'
        stroke='var(--illustrationsNeutral25)'
        rx='0.5'
      ></rect>
      <rect
        width='12.683'
        height='2'
        fill='var(--illustrationsNeutral40)'
        rx='1'
        transform='matrix(1 0 0 -1 48.939 77)'
      ></rect>
      <rect
        width='11.683'
        height='1'
        x='0.5'
        y='-0.5'
        fill='var(--illustrationsNeutral25)'
        stroke='var(--illustrationsNeutral25)'
        rx='0.5'
        transform='matrix(1 0 0 -1 93.33 76)'
      ></rect>
      <rect
        width='11.683'
        height='1'
        x='0.5'
        y='-0.5'
        fill='var(--illustrationsNeutral25)'
        stroke='var(--illustrationsNeutral25)'
        rx='0.5'
        transform='matrix(1 0 0 -1 132.963 76)'
      ></rect>
      <path
        stroke='var(--illustrationsPrimary40)'
        strokeWidth='1.5'
        d='M31.5 144l5.62-8.332a.199.199 0 01.165-.089h4.21c.045 0 .09-.015.126-.045l4.98-4.067a.2.2 0 01.244-.007l7.506 5.492a.2.2 0 00.275-.038l5.337-6.75a.194.194 0 00.037-.075l2.613-10.325a.21.21 0 01.03-.065l5.663-8.205a.2.2 0 01.297-.037l4.23 3.729a.2.2 0 00.215.032l8.541-3.858a.202.202 0 01.211.029l4.444 3.735a.2.2 0 00.298-.047l7.43-11.752a.2.2 0 01.355.03l2.486 5.968a.2.2 0 00.254.111l5.611-2.058a.199.199 0 01.16.01l7.507 3.875a.2.2 0 00.184 0l7.521-3.882a.21.21 0 00.092-.099l2.77-6.489a.2.2 0 01.36-.017l3.749 6.893a.201.201 0 00.27.081l6.267-3.35a.208.208 0 00.072-.066l2.605-3.921 4.366-7.214a.2.2 0 01.31-.04l4.568 4.454a.2.2 0 00.183.052l6.542-1.453a.2.2 0 00.077-.035l6.669-5.021a.2.2 0 01.305.082l2.509 5.93a.225.225 0 00.03.048l1.706 2.083'
      ></path>
      <rect width='48' height='50' x='171.5' y='43' fill='#fff' rx='4'></rect>
      <rect
        width='18'
        height='1'
        x='0.5'
        y='-0.5'
        fill='var(--illustrationsNeutral25)'
        stroke='var(--illustrationsNeutral25)'
        rx='0.5'
        transform='matrix(1 0 0 -1 176.5 51)'
      ></rect>
      <rect
        width='36'
        height='1'
        x='0.5'
        y='-0.5'
        fill='var(--illustrationsNeutral25)'
        stroke='var(--illustrationsNeutral25)'
        rx='0.5'
        transform='matrix(1 0 0 -1 176.5 57)'
      ></rect>
      <rect
        width='18'
        height='1'
        x='0.5'
        y='-0.5'
        fill='var(--illustrationsNeutral25)'
        stroke='var(--illustrationsNeutral25)'
        rx='0.5'
        transform='matrix(1 0 0 -1 176.5 65)'
      ></rect>
      <rect
        width='36'
        height='1'
        x='0.5'
        y='-0.5'
        fill='var(--illustrationsNeutral25)'
        stroke='var(--illustrationsNeutral25)'
        rx='0.5'
        transform='matrix(1 0 0 -1 176.5 71)'
      ></rect>
      <rect
        width='18'
        height='1'
        x='0.5'
        y='-0.5'
        fill='var(--illustrationsNeutral25)'
        stroke='var(--illustrationsNeutral25)'
        rx='0.5'
        transform='matrix(1 0 0 -1 176.5 79)'
      ></rect>
      <rect
        width='36'
        height='1'
        x='0.5'
        y='-0.5'
        fill='var(--illustrationsNeutral25)'
        stroke='var(--illustrationsNeutral25)'
        rx='0.5'
        transform='matrix(1 0 0 -1 176.5 85)'
      ></rect>
      <rect width='48' height='50' x='171.5' y='96' fill='#fff' rx='4'></rect>
      <rect
        width='18'
        height='1'
        x='0.5'
        y='-0.5'
        fill='var(--illustrationsNeutral25)'
        stroke='var(--illustrationsNeutral25)'
        rx='0.5'
        transform='matrix(1 0 0 -1 176.5 104)'
      ></rect>
      <rect
        width='36'
        height='1'
        x='0.5'
        y='-0.5'
        fill='var(--illustrationsNeutral25)'
        stroke='var(--illustrationsNeutral25)'
        rx='0.5'
        transform='matrix(1 0 0 -1 176.5 110)'
      ></rect>
      <rect
        width='18'
        height='1'
        x='0.5'
        y='-0.5'
        fill='var(--illustrationsNeutral25)'
        stroke='var(--illustrationsNeutral25)'
        rx='0.5'
        transform='matrix(1 0 0 -1 176.5 118)'
      ></rect>
      <rect
        width='36'
        height='1'
        x='0.5'
        y='-0.5'
        fill='var(--illustrationsNeutral25)'
        stroke='var(--illustrationsNeutral25)'
        rx='0.5'
        transform='matrix(1 0 0 -1 176.5 124)'
      ></rect>
      <rect
        width='18'
        height='1'
        x='0.5'
        y='-0.5'
        fill='var(--illustrationsNeutral25)'
        stroke='var(--illustrationsNeutral25)'
        rx='0.5'
        transform='matrix(1 0 0 -1 176.5 132)'
      ></rect>
      <rect
        width='36'
        height='1'
        x='0.5'
        y='-0.5'
        fill='var(--illustrationsNeutral25)'
        stroke='var(--illustrationsNeutral25)'
        rx='0.5'
        transform='matrix(1 0 0 -1 176.5 138)'
      ></rect>
      <rect
        width='223'
        height='137'
        x='13'
        y='25.5'
        stroke='var(--illustrationsSharedNeutral900)'
        rx='2.5'
      ></rect>
      <path
        fill='var(--illustrationsSharedPrimary500)'
        stroke='var(--illustrationsSharedNeutral900)'
        d='M1 157a.5.5 0 01.5-.5h100.786a.5.5 0 01.404.205l1.224 1.679a1.5 1.5 0 001.212.616h42.748c.479 0 .929-.229 1.212-.616l-.404-.295.404.295 1.224-1.679s0 0 0 0a.5.5 0 01.404-.205H249.5a.5.5 0 01.5.5v11a.5.5 0 01-.5.5H1.5a.5.5 0 01-.5-.5v-11z'
      ></path>
      <g filter='url(#filter0_dd_10556_277488)'>
        <path
          fill='var(--illustrationsSharedPrimary500)'
          stroke='var(--illustrationsSharedNeutral900)'
          d='M142.646 41.301a2.464 2.464 0 01-3.257.65l-.157-.094c-7.521-4.455-12.435-7.374-13.232-12.1-.346-2.072.306-4.258 1.775-5.767 2.752-2.827 6.902-2.612 9.539-.553 1.622-2.925 5.357-4.755 8.997-3.215 1.942.815 3.395 2.573 3.886 4.615 1.124 4.66-2.273 9.269-7.452 16.331l-.099.133z'
        ></path>
      </g>
      <path
        fill='var(--illustrationsSharedPrimary500)'
        stroke='var(--illustrationsSharedNeutral900)'
        d='M158.566 62.22c-.687.447-1.609.299-2.12-.35l-.074-.093c-3.536-4.41-5.846-7.298-5.252-10.34.263-1.334 1.156-2.508 2.39-3.086 2.31-1.085 4.773-.009 5.9 1.839 1.649-1.401 4.327-1.658 6.179.104.99.936 1.469 2.332 1.301 3.68-.382 3.077-3.489 5.092-8.234 8.187l-.09.058zM160.641 18.82a1.594 1.594 0 01-2.026-.714l-.057-.104c-2.713-4.96-4.485-8.206-3.368-11.098.491-1.267 1.575-2.268 2.89-2.622 2.464-.665 4.702.824 5.489 2.84 1.868-1.093 4.55-.879 6.066 1.18.812 1.093 1.041 2.55.64 3.849-.912 2.963-4.323 4.407-9.534 6.627l-.1.042z'
      ></path>
      <defs>
        <filter
          id='filter0_dd_10556_277488'
          width='54.94'
          height='54.94'
          x='111.5'
          y='4.113'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feColorMatrix
            in='SourceAlpha'
            result='hardAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation='5'></feGaussianBlur>
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0'></feColorMatrix>
          <feBlend
            in2='BackgroundImageFix'
            result='effect1_dropShadow_10556_277488'
          ></feBlend>
          <feColorMatrix
            in='SourceAlpha'
            result='hardAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          ></feColorMatrix>
          <feOffset dy='2'></feOffset>
          <feGaussianBlur stdDeviation='2'></feGaussianBlur>
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'></feColorMatrix>
          <feBlend
            in2='effect1_dropShadow_10556_277488'
            result='effect2_dropShadow_10556_277488'
          ></feBlend>
          <feBlend
            in='SourceGraphic'
            in2='effect2_dropShadow_10556_277488'
            result='shape'
          ></feBlend>
        </filter>
      </defs>
    </svg>
  )
}
