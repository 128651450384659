import { TTL } from '@interfaces'
import { api } from '@utils/api/api'
import { AxiosError } from 'axios'

interface ValidationError {
  timestamp: string
  status: number
  error: string
  message: string
  path: string
  validationError: string[]
}
export interface LoginData {
  email: string
  password: string
}

export interface Login2FAResult {
  secondsForChallengeExpiration: number
}

export interface Login2FAData {
  email: string
  challenge: string
}

export interface LoginResult {
  accessToken?: string
  renewalToken?: string
  id?: string
}
export interface RenewResult {
  accessToken: string
}

export interface SignupData {
  email: string
  password?: string
}

export interface DecodedTokenPayload {
  claims: ClaimEnum[]
}

export enum ClaimEnum {
  isTrader = 'isTrader',
  isTester = 'isTester',
  canBuySavingPlans = 'canBuySavingPlans',
}

export async function login2fa(
  email: string,
  password: string,
): Promise<Login2FAResult> {
  const url = '/api/v1/auth/login2fa'
  return await api.post(url, { email, password }, true)
}

export async function login(
  email: string,
  challenge: string,
): Promise<LoginResult> {
  const url = '/api/v1/auth/2fa'
  return await api.post(url, { email, challenge }, true)
}

export async function renewToken(renewalToken: string): Promise<RenewResult> {
  return api.post('/api/v1/auth/renew', { renewalToken }, true)
}

export const getResetMailCode = (email: string): Promise<TTL> => {
  return api.post('/api/v1/auth/reset-password/send', { email }, true)
}

export async function resetPassword(
  email: string,
  password: string,
  verificationCode: string,
): Promise<void> {
  try {
    await api.post(
      '/api/v2/auth/reset-password/verify',
      { email, password, verificationCode },
      true,
    )
  } catch (error) {
    if (
      error instanceof AxiosError &&
      error.response &&
      error.response.status === 400
    ) {
      const validationError: ValidationError = error.response.data
      throw new Error(JSON.stringify(validationError.validationError))
    } else {
      throw new Error(JSON.stringify(['An unexpected error occurred.']))
    }
  }
}
