import { Cancel, InfoIcon, TimerIcon } from '@assets/icons'
import { BasicLink, CustomModal, Spacer, Text } from 'components'
import { useTranslation } from 'hooks/useTranslation'

export const PromotionVisibilityConfigModal = ({
  title,
  onLearnMore,
  onRemindMeLater,
  onNotInterested,
  close,
}: {
  title: string
  onLearnMore: () => void
  onRemindMeLater: () => void
  onNotInterested: () => void
  close: () => void
}): JSX.Element => {
  const { t } = useTranslation()

  return (
    <CustomModal
      size='small'
      show={true}
      onClose={close}
      dismissable
      theme='lighter'
    >
      <CustomModal.Header text={title} />
      <CustomModal.Content>
        <Spacer preset='tiny' />
        <div
          className='d-flex cursor-pointer'
          aria-hidden
          onClick={onLearnMore}
        >
          <InfoIcon color='tertiary' />
          <Spacer preset='smaller' />
          <Text text={t('promotions.learnMore')} preset='paragraph1' />
        </div>
        <Spacer preset='medium' />
        <div
          className='d-flex cursor-pointer'
          aria-hidden
          onClick={onRemindMeLater}
        >
          <TimerIcon color='tertiary' />
          <Spacer preset='smaller' />
          <Text text={t('promotions.remindMeLater')} preset='paragraph1' />
        </div>
        <Spacer preset='medium' />
        <div
          className='d-flex cursor-pointer'
          aria-hidden
          onClick={onNotInterested}
        >
          <Cancel color='negative' />
          <Spacer preset='smaller' />
          <Text
            text={t('promotions.notInterested')}
            preset='paragraph1'
            color='negative'
          />
        </div>
        <Spacer preset='tiny' />
      </CustomModal.Content>
      <CustomModal.Footer>
        <BasicLink
          text={t('common.cancel')}
          type='action'
          className='w-100'
          onClick={() => close()}
        />
      </CustomModal.Footer>
    </CustomModal>
  )
}
