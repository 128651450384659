export const NewPortfolioAnalysisIcon = (): JSX.Element => {
  return (
    <svg
      width='81'
      height='40'
      viewBox='0 0 81 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='0.5'
        width='80'
        height='40'
        rx='4'
        fill='var(--surfaceCanvasSecondaryInitial)'
      />
      <g clipPath='url(#clip0_4220_33541)'>
        <path
          d='M24.5 13.08V10C19.5 10.5 15.5 14.81 15.5 20C15.5 25.19 19.5 29.5 24.5 30V26.92C21.5 26.44 18.5 23.52 18.5 20C18.5 16.48 21.5 13.56 24.5 13.08ZM32.47 19H35.5C35.03 14 31.5 10.47 26.5 10V13.08C29.5 13.51 32.04 16 32.47 19ZM26.5 26.92V30C31.5 29.53 35.03 26 35.5 21H32.47C32.04 24 29.5 26.49 26.5 26.92Z'
          fill='#FF9B63'
        />
      </g>
      <circle cx='41' cy='13' r='1.5' fill='#FF9B63' />
      <rect x='45.5' y='11.5' width='23' height='3' rx='1' fill='#FF9B63' />
      <circle cx='41' cy='20' r='1.5' fill='#FF9B63' />
      <rect x='45.5' y='18.5' width='23' height='3' rx='1' fill='#FF9B63' />
      <circle cx='41' cy='27' r='1.5' fill='#FF9B63' />
      <rect x='45.5' y='25.5' width='23' height='3' rx='1' fill='#FF9B63' />
      <defs>
        <clipPath id='clip0_4220_33541'>
          <rect
            width='24'
            height='24'
            fill='white'
            transform='translate(13.5 8)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}
