import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { getLimitOrderPriceLimits } from '@services'
import { LimitPriceRangeRequest, LimitPriceRangeResponse } from '@interfaces'

export const fetchLimitOrderPriceLimits = createAsyncThunk<
  LimitPriceRangeResponse,
  LimitPriceRangeRequest
>('fetchLimitOrderPriceLimits', async (data: LimitPriceRangeRequest) => {
  return getLimitOrderPriceLimits(data)
})

export const clearLimitOrdersForm = createAction('clearLimitOrdersForm')
