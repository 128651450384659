import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import { BankAggregationItem } from '@interfaces'
import { clearBanksList, requestBanksList } from './thunk'

const initialState: {
  isLoading: boolean
  error: string | null | undefined
  bankList: BankAggregationItem[] | null
} = {
  isLoading: false,
  error: null,
  bankList: null,
}

export const reducers = createReducer(initialState, (builder) => {
  builder
    .addCase(requestBanksList.pending, (acc) => {
      acc.isLoading = true
      acc.error = null
      acc.bankList = null
    })
    .addCase(
      requestBanksList.fulfilled,
      (acc, action: PayloadAction<BankAggregationItem[]>) => {
        const { payload } = action
        acc.isLoading = false
        acc.error = null
        acc.bankList = payload?.length ? payload : null
      },
    )
    .addCase(requestBanksList.rejected, (acc, { error }) => {
      acc.isLoading = false
      acc.error = error.message
      acc.bankList = null
    })
    .addCase(clearBanksList, (acc) => {
      acc.isLoading = false
      acc.error = null
      acc.bankList = null
    })
})

export default reducers
