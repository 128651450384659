import './roundedSwitchButton.styles.scss'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import { stringBuilder } from '@components/utils'

export function RoundedSwitchButton({
  initialState,
  onChange,
  left,
  right,
  size = 'mediumPlus',
}: {
  initialState?: boolean
  onChange?: (state: boolean) => void
  left?: ReactNode
  right?: ReactNode
  size?:
    | 'small'
    | 'smallPlus'
    | 'medium'
    | 'mediumIntermediate'
    | 'mediumIntermediatePlus'
    | 'mediumPlus'
}): JSX.Element {
  const [active, setActive] = useState(initialState || false)

  useEffect(() => {
    if (onChange) onChange(active)
    // including onChange creates an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active])

  const classes = useMemo(() => {
    return stringBuilder([
      ['rounded-switch', true],
      ['--active', active],
      ['--size-small', size === 'small'],
      ['--size-smallPlus', size === 'smallPlus'],
      ['--size-medium', size === 'medium'],
      ['--size-mediumIntermediate', size === 'mediumIntermediate'],
      ['--size-mediumIntermediatePlus', size === 'mediumIntermediatePlus'],
      ['--size-mediumPlus', size === 'mediumPlus'],
    ])
  }, [active, size])

  return (
    <button className={classes} onClick={() => setActive(!active)}>
      {left && <div className='rounded-switch__left'>{left}</div>}
      <div className='rounded-switch__container'>
        <div className='rounded-switch__ball'></div>
      </div>
      {right && <div className='rounded-switch__right'>{right}</div>}
    </button>
  )
}
