import { UIIcon, UIIconColorProps, getIconColorSVGFill } from 'components/utils'

export const MarketOrderIcon = ({
  color,
  size = 24,
}: UIIcon & UIIconColorProps): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.00012 19.5L3.6284 14.4701C3.74174 14.2532 4.05316 14.256 4.16261 14.4749L5.892 17.9337C6.00064 18.1509 6.30897 18.1558 6.42434 17.942L10.9951 9.47264C11.1066 9.26607 11.4014 9.26178 11.5189 9.46504L14.8799 15.2823C14.9977 15.4861 15.2936 15.4811 15.4044 15.2734L18.7502 9'
        stroke={color ? getIconColorSVGFill(color) : 'currentColor'}
        strokeWidth='2'
        strokeLinecap='round'
      />
      <circle
        cx='19.5001'
        cy='7.5'
        r='2'
        fill='white'
        stroke={color ? getIconColorSVGFill(color) : 'currentColor'}
        strokeWidth='2'
      />
    </svg>
  )
}
