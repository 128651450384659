import { UIIcon, UIIconColorProps, getIconColorSVGFill } from 'components/utils'

export const EmailIcon = ({
  color,
  size = 24,
}: UIIcon & UIIconColorProps): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 20 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2 0H18C19.1 0 20 0.9 20 2V14C20 15.1 19.1 16 18 16H2C0.9 16 0 15.1 0 14V2C0 0.9 0.9 0 2 0ZM11.06 8.34L17.6 4.25C17.85 4.09 18 3.82 18 3.53C18 2.86 17.27 2.46 16.7 2.81L10 7L3.3 2.81C2.73 2.46 2 2.86 2 3.53C2 3.82 2.15 4.09 2.4 4.25L8.94 8.34C9.59 8.75 10.41 8.75 11.06 8.34Z'
        fill={color ? getIconColorSVGFill(color) : 'currentColor'}
      />
    </svg>
  )
}
