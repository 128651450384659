import { Spacer, Text } from 'components'
import './analystRatings.styles.scss'
import RatingCard from './ratingCard/ratingCard'
import ForecastCard from './forecastCard/forecastCard'
import { useTranslation } from 'hooks/useTranslation'
import { useAppSelector } from 'store'

const AnalystRatings = (): JSX.Element => {
  const { t } = useTranslation()
  const { symbol } = useAppSelector((state) => state.companyStocks)

  return (
    <>
      <Spacer preset='medium' />
      <div className='d-flex justify-space-between align-start w-100 analyst-rating tab-scroll-area'>
        <div className='w-50 '>
          <Text
            text={t('companyDetails.analystRating.analystRatingsTitle', {
              stockName: symbol,
            })}
            preset='bodyBig'
          />
          <Spacer preset='small' />
          <RatingCard />
        </div>
        <Spacer preset='medium' />
        <div className='w-50 '>
          <Text
            text={t('companyDetails.analystRating.forecast12month')}
            preset='bodyBig'
          />
          <Spacer preset='small' />
          <ForecastCard />
          <Spacer preset='smaller' />
        </div>
      </div>
    </>
  )
}

export default AnalystRatings
