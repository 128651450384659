import { AnyAction, createReducer, PayloadAction } from '@reduxjs/toolkit'
import {
  FilterValue,
  OrderTransactions,
  SelectedFilters,
  Transactions,
  TransactionsTab,
  TransactionState,
} from '@interfaces'
import {
  clearTransactionsList,
  fetchHomePendingTransactions,
  fetchOrderTransactions,
  fetchTransactions,
  setTransactionsScrollPosition,
  setTransactionsTab,
} from './thunk'
import { RawTreeNode, TreeNode } from 'tr33'
import {
  clearTransactionsFilters,
  clearTransactionsFiltersSelection,
  createTransactionsFiltersSelection,
  fetchTransactionsFilters,
  setTransactionsFilterFocused,
  updateTransactionsFilter,
} from './filters.thunk'
import {
  findNode,
  getFilterSelectedOptions,
  getTrees,
  prepareTreeStructure,
} from '@utils/filters'
import {
  getTransactionStatusForTab,
  groupTransactions,
  mapOrderTransactionToTransaction,
} from './utils'

const initialState: TransactionState = {
  loadingCancelOrder: null,
  selectedTab: TransactionsTab.executed,
  byStatus: {
    PENDING: {
      data: null,
      error: null,
      loading: null,
      nextPageLoading: null,
      page: 0,
      totalItems: null,
      totalPages: null,
      rawData: [],
      scrollPosition: 0,
    },
    CANCELLED: {
      data: null,
      error: null,
      loading: null,
      nextPageLoading: null,
      page: 0,
      totalItems: null,
      totalPages: null,
      rawData: [],
      scrollPosition: 0,
    },
    COMPLETED: {
      data: null,
      error: null,
      loading: null,
      nextPageLoading: null,
      page: 0,
      totalItems: null,
      totalPages: null,
      rawData: [],
      scrollPosition: 0,
    },
  },
  filters: {
    byStatus: {
      CANCELLED: null,
      COMPLETED: null,
      PENDING: null,
    },
    focusedFilter: {
      id: null,
      rawValue: null,
      isRoot: null,
      parentIsTree: null,
      options: null,
      parentId: null,
    },
    selected: null,
  },
  homeTransactionsOverview: {
    data: null,
    loading: true,
  },
}

const pendingFetchTransactions = (action: AnyAction): boolean =>
  action.type === fetchOrderTransactions.pending.type ||
  action.type === fetchTransactions.pending.type

const rejectedFetchTransactions = (action: AnyAction): boolean =>
  action.type === fetchOrderTransactions.rejected.type ||
  action.type === fetchTransactions.rejected.type

const reducers = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchHomePendingTransactions.pending, (acc) => {
      acc.homeTransactionsOverview.loading = true
    })
    .addCase(
      fetchHomePendingTransactions.fulfilled,
      (acc, action: PayloadAction<OrderTransactions>) => {
        const {
          payload: { data },
        } = action

        acc.homeTransactionsOverview.data = data
        acc.homeTransactionsOverview.loading = false
      },
    )
    .addCase(
      fetchTransactions.fulfilled, // Completed/executed transactions
      (acc, action: PayloadAction<Transactions>) => {
        const {
          payload: { data, totalPages, currentPage, totalItems },
        } = action

        const grouped = groupTransactions(
          !currentPage ? [] : acc.byStatus['COMPLETED'].data,
          data,
        )

        acc.byStatus['COMPLETED'].rawData = data
        acc.byStatus['COMPLETED'].data = grouped
        acc.byStatus['COMPLETED'].totalItems = totalItems
        acc.byStatus['COMPLETED'].totalPages = totalPages
        acc.byStatus['COMPLETED'].page = currentPage
        acc.byStatus['COMPLETED'].error = null
        acc.byStatus['COMPLETED'].loading = false
        acc.byStatus['COMPLETED'].nextPageLoading = false
      },
    )
    .addCase(
      fetchOrderTransactions.fulfilled, // Pending or closed
      (acc, action: PayloadAction<OrderTransactions>) => {
        const {
          payload: { data, totalPages, currentPage, totalItems },
        } = action

        const status = getTransactionStatusForTab(acc.selectedTab)

        const grouped = groupTransactions(
          !currentPage ? [] : acc.byStatus[status].data,
          data?.map((o) => mapOrderTransactionToTransaction(o)),
        )

        acc.byStatus[status].rawData = data
        acc.byStatus[status].data = grouped
        acc.byStatus[status].totalItems = totalItems
        acc.byStatus[status].totalPages = totalPages
        acc.byStatus[status].page = currentPage
        acc.byStatus[status].error = null
        acc.byStatus[status].loading = false
        acc.byStatus[status].nextPageLoading = false
      },
    )
    .addCase(clearTransactionsList, (acc) => {
      acc.byStatus = initialState.byStatus
      acc.loadingCancelOrder = initialState.loadingCancelOrder
    })
    .addCase(setTransactionsScrollPosition, (acc, { payload }) => {
      const status = getTransactionStatusForTab(acc.selectedTab)
      acc.byStatus[status].scrollPosition = payload?.position || 0
    })
    .addCase(setTransactionsTab, (acc, { payload }) => {
      acc.selectedTab = payload?.tab || TransactionsTab.executed
    })
    .addCase(fetchTransactionsFilters.pending, (acc: TransactionState) => {
      const status = getTransactionStatusForTab(acc.selectedTab)
      acc.filters.selected = null
      acc.filters.focusedFilter.id = null
      acc.filters.focusedFilter.rawValue = null
      acc.byStatus[status].error = null
      acc.byStatus[status].loading = true
    })
    .addCase(fetchTransactionsFilters.fulfilled, (acc, action) => {
      const status = getTransactionStatusForTab(acc.selectedTab)
      acc.filters.byStatus[status] = prepareTreeStructure(
        action?.payload || [],
        0,
      )
      acc.byStatus[status].loading = false
    })
    .addCase(fetchTransactionsFilters.rejected, (acc, { error }) => {
      const status = getTransactionStatusForTab(acc.selectedTab)
      acc.byStatus[status].error = error.message
      acc.byStatus[status].loading = false
    })
    .addCase(setTransactionsFilterFocused, (acc, { payload }) => {
      acc.filters.focusedFilter.id = payload
      const status = getTransactionStatusForTab(acc.selectedTab)
      const trees = getTrees(acc.filters?.byStatus?.[status])
      const tree = findNode(trees, payload)

      if (tree) {
        acc.filters.focusedFilter.parentId = tree?.getParentId()
        acc.filters.focusedFilter.isRoot = tree?.isRoot()
        acc.filters.focusedFilter.rawValue = tree?.value()

        acc.filters.focusedFilter.options = tree
          .find(acc.filters.focusedFilter.id)
          ?.children()
          .map((a) => a.value())
      }
    })
    .addCase(updateTransactionsFilter, (acc, { payload }) => {
      const status = getTransactionStatusForTab(acc.selectedTab)
      const filters = acc?.filters?.byStatus?.[status]
        ? acc.filters?.byStatus?.[status]
        : []
      const trees = getTrees(filters)
      const node = findNode(trees, payload?.id)

      const getNewValue = (
        value: boolean | undefined,
        allChildrenSelected?: boolean,
      ): boolean | null | undefined => {
        switch (payload?.type) {
          case 'toggle':
            return !value
          case 'reset':
            return undefined
          case 'selectAll':
            return !allChildrenSelected
        }
      }

      function updateNodeValue(
        node: TreeNode<FilterValue>,
        value?: boolean | null | undefined,
      ): void {
        const nodeValue = node.value()?.value
        const children = node.children()

        node.update({
          ...nodeValue,
          checked: value,
        })

        if (children?.length) {
          children?.forEach((c) => updateNodeValue(c, value))
        }
      }

      if (node) {
        const nodeValue = node.value()?.value
        const children = node.children()
        const allChildrenSelected: boolean =
          children?.length > 0 &&
          children.every((c) => !!c?.value()?.value?.checked)

        const newValue = getNewValue(
          nodeValue.checked as boolean | undefined,
          allChildrenSelected,
        )

        updateNodeValue(node, newValue)
      }

      acc.filters.byStatus[status] = trees?.map((n) => n?.value())

      // Update visible options
      const focusedFilterNode = findNode(trees, acc.filters.focusedFilter.id)
      const focusedFilterChildren = focusedFilterNode?.children()
      acc.filters.focusedFilter.options = focusedFilterChildren?.map((i) =>
        i?.value(),
      )
    })
    .addCase(createTransactionsFiltersSelection, (acc) => {
      const status = getTransactionStatusForTab(acc.selectedTab)
      const filters = acc.filters.byStatus[status]
        ? acc.filters.byStatus[status]
        : []

      const selection = []

      filters.forEach((f: RawTreeNode<FilterValue>) => {
        selection.push(...getFilterSelectedOptions(f))
        if (f?.children) {
          f?.children?.forEach((o) => {
            selection.push(...getFilterSelectedOptions(o, f.id))
          })
        }
      })

      let newSelection = {}

      selection?.forEach((sel: unknown) => {
        newSelection = Object.keys(sel || {}).reduce(
          (obj: SelectedFilters, item: string) => {
            if (!obj) {
              obj = {}
            }
            obj[item] = obj[item]
              ? `${obj[item]},${sel[item] as string}`
              : sel[item]
            return obj
          },
          newSelection,
        )
      })

      acc.filters.selected = newSelection
    })
    .addCase(clearTransactionsFilters, (acc) => {
      acc.filters.selected = null
      acc.filters.focusedFilter.id = null
      acc.filters.focusedFilter.isRoot = null
      acc.filters.focusedFilter.parentId = null
      acc.filters.focusedFilter.parentIsTree = null
    })
    .addCase(clearTransactionsFiltersSelection, (acc) => {
      acc.filters.selected = null
    })
    .addMatcher(pendingFetchTransactions, (acc, { meta: { arg } }) => {
      const status = getTransactionStatusForTab(acc.selectedTab)
      if (arg.page === 0) acc.byStatus[status].data = []
      acc.byStatus[status].page = arg.page
      acc.byStatus[status].nextPageLoading = arg?.page > 0
      acc.byStatus[status].error = null
      acc.byStatus[status].loading = arg.page === 0
    })
    .addMatcher(rejectedFetchTransactions, (acc, { error }) => {
      const status = getTransactionStatusForTab(acc.selectedTab)
      acc.byStatus[status].error = error.message
      acc.byStatus[status].nextPageLoading = false
      acc.byStatus[status].loading = false
    })
})

export default reducers
