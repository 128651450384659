import { PeriodEnum } from 'interfaces'

export const availablePeriods: Array<{
  label: string
  period: PeriodEnum
}> = [
  {
    label: '1D',
    period: PeriodEnum.day,
  },
  {
    label: '7D',
    period: PeriodEnum.week,
  },
  {
    label: '1M',
    period: PeriodEnum.month,
  },
  {
    label: '1Y',
    period: PeriodEnum.year,
  },
  {
    label: 'MAX',
    period: PeriodEnum.max,
  },
]
