import './calendar.styles.scss'
import { useCallback } from 'react'
import { useTranslation } from '@hooks'
import { CalendarHeader } from './calendarHeader'
import { CalendarFooter } from './calendarFooter'
import { ChevronLeft, ChevronRight } from '@assets/icons'
import { Divider } from 'components/divider/divider'
import { Spacer } from 'components/spacer'
import { Text } from 'components/text/text'
import { CalendarProps } from './calendar.props'

export const Calendar = ({
  id,
  headerSubtitle,
  headerTitle,
  withFooter = false,
  withHeader = false,
  withControls = true,
  handleCancel,
  handleMonth,
  handleRestore,
  handleSelected,
  handleSubmit,
  month,
  selectedYear,
  weekDays,
  submitDisabled,
  monthsMatrix,
}: CalendarProps): JSX.Element => {
  const { t } = useTranslation()

  const renderElement = useCallback(
    (key: string, day: number, disabled: boolean, selected): JSX.Element => {
      return (
        <button
          key={`${id}-${key}_touchable`}
          className={`calendar__column-item`}
          aria-hidden
          disabled={disabled || isNaN(day)}
          onClick={() => handleSelected(day)}
          data-testid={`${id}-${key}_touchable`}
        >
          <div
            className={`calendar__day-selected-container ${
              disabled ? '--disabled' : ''
            } ${selected ? '--selected' : ''}`}
          >
            {!isNaN(day) ? day?.toString() : ''}
          </div>
        </button>
      )
    },
    [handleSelected, id],
  )

  return (
    <div className='calendar__container w-100 bg-secondary-base'>
      {withHeader && (
        <>
          <CalendarHeader
            onCancel={handleCancel}
            headerSubtitle={headerSubtitle}
            headerTitle={headerTitle}
          />
          <Divider />
          <Spacer preset='small' />
        </>
      )}
      <div>
        {withControls && (
          <div className='calendar__row calendar__month-navigator justify-space-between align-center'>
            <Text preset='paragraph1' text={`${month} ${selectedYear}`} />
            <div className='calendar__row '>
              <div
                className='calendar__row'
                aria-hidden
                onClick={() => handleMonth('L')}
              >
                <div className='calendar__chevron-container'>
                  <ChevronLeft />
                </div>
              </div>
              <div
                className='calendar__row'
                aria-hidden
                onClick={() => handleMonth('R')}
              >
                <div className='calendar__chevron-container calendar__chevron-right'>
                  <ChevronRight />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className='calendar__row calendar__week-days'>
          {weekDays.map((d, idx) => (
            <div
              key={`${id}-item_${idx}`}
              className='subtitle2 calendar__column-item'
            >
              <p className='tertiary--text'>{t(d)}</p>
            </div>
          ))}
        </div>
        <div className='calendar__month-container'>
          {monthsMatrix.map((row, i) => {
            return (
              <div
                className='paragraph4 calendar__row calendar__month-view'
                key={`${id}-calendar-row_${i}`}
              >
                {row.map((item) =>
                  renderElement(
                    item?.key,
                    item?.day,
                    item?.disabled,
                    item?.selected,
                  ),
                )}
              </div>
            )
          })}
        </div>
      </div>
      {withFooter && (
        <>
          <Spacer preset='small' />
          <Divider />
          <CalendarFooter
            onRestore={handleRestore}
            onSubmit={handleSubmit}
            disabled={submitDisabled}
          />
        </>
      )}
    </div>
  )
}
