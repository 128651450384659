import { TabItem } from 'components/tabBar/tabBar.props'
import { useAppSelector } from 'store'
import { useTranslation } from './useTranslation'
import { CompanyOverviewTab } from '@interfaces/companyOverview'

type CompanyOverviewTabType = {
  tipsRankTabList: TabItem[]
}

export const useCompanyTipRanksList = (): CompanyOverviewTabType => {
  const { t } = useTranslation()
  const {
    priceTargetCurrencyCode,
    monthlyClosingPrices,
    totalAnalysts,
    newsData,
    bullBears,
    earnings,
  } = useAppSelector((state) => state.tipRank)

  const tipsRankTabList: TabItem[] = [
    {
      title: t('companyDetails.overview'),
      id: CompanyOverviewTab.overview,
    },
  ]

  if (
    priceTargetCurrencyCode &&
    monthlyClosingPrices.length > 0 &&
    totalAnalysts
  ) {
    tipsRankTabList.push({
      title: t('companyDetails.analystRatings'),
      id: CompanyOverviewTab.analystRatings,
    })
  }
  if (earnings.length > 0) {
    tipsRankTabList.push({
      title: t('companyDetails.earnings'),
      id: CompanyOverviewTab.earnings,
    })
  }

  if (newsData.length > 0) {
    tipsRankTabList.push({
      title: t('companyDetails.news'),
      id: CompanyOverviewTab.news,
    })
  }
  if (bullBears.length > 0) {
    tipsRankTabList.push({
      title: t('companyDetails.bullsAndBears'),
      id: CompanyOverviewTab.bullsAndBears,
    })
  }

  return { tipsRankTabList }
}
