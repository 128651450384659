/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import './customModal.styles.scss'
import { ModalContent } from './content'
import { ModalFooter } from './footer'
import { ModalHeader } from './header'
import { ModalOverlayContent } from './overlayContent'
import { stringBuilder } from './../utils/utils'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

export enum ModalSizeEnum {
  fitContent = 'fitContent',
  small = 'small',
  big = 'big',
  fullHeight = 'fullHeight',
}

export enum ModalThemeEnum {
  'normal' = 'normal',
  'lighter' = 'lighter',
}

export type ModalSizePreset = keyof typeof ModalSizeEnum
export type ModalThemePreset = keyof typeof ModalThemeEnum

export interface CustomModalProps {
  children?: React.ReactNode
  className?: string
  dismissable?: boolean
  height?: ModalSizePreset
  onClose?: () => void
  show?: boolean
  size?: ModalSizePreset
  style?: string
  theme?: ModalThemePreset
  blockScrollWhileOpen?: boolean
  showBackgrop?: boolean
  placeOn?: 'left' | 'center' | 'right'
}

export function CustomModal({
  children,
  className,
  height,
  onClose,
  show,
  size,
  style,
  theme = 'normal',
  dismissable = false,
  blockScrollWhileOpen = true,
  showBackgrop = true,
  placeOn = 'center',
}: CustomModalProps): JSX.Element {
  const elem: HTMLElement = document?.querySelector('body')

  const wrapperContent = useRef<HTMLDivElement>(null)

  const [_, setOpenedClass] = useState<string>()

  const removeOpenedModalClass = useCallback(
    (className: string) => {
      if (elem && className) elem?.classList?.remove(className)
    },
    [elem],
  )

  useEffect(() => {
    const id = Math.random().toString(36).slice(2, 9)
    const openedClassName = `--opened-modal-${id}`
    if (show && blockScrollWhileOpen) {
      elem?.classList?.add(openedClassName)
    }
    setOpenedClass(openedClassName)

    return () => {
      removeOpenedModalClass(openedClassName)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockScrollWhileOpen, show])

  const wrapperClasses = useMemo(() => {
    return stringBuilder([
      ['wrapper --fade-in-animation', true],
      [className, !!className],
      ['--theme-normal', theme === 'normal' || !theme],
      ['--theme-lighter', theme === 'lighter'],
      ['--visible', show],
      ['--with-backdrop', showBackgrop],
    ])
  }, [className, theme, show, showBackgrop])

  const contentClasses = useMemo(() => {
    return stringBuilder([
      ['wrapper__content --fade-in-animation', true],
      [style, !!style],
      ['--width-size-small', size === ModalSizeEnum.small],
      ['--width-size-big', size === ModalSizeEnum.big || !size],
      ['--width-size-fit-content', size === ModalSizeEnum.fitContent],
      ['--height-size-small', height === ModalSizeEnum.small],
      ['--height-size-big', height === ModalSizeEnum.big || !height],
      ['--height-size-fit-content', height === ModalSizeEnum.fitContent],
      ['--height-size-full-height', height === ModalSizeEnum.fullHeight],
      ['--place-on-left', placeOn === 'left'],
      ['--place-on-right', placeOn === 'right'],
      ['--place-on-center', placeOn === 'center' || !placeOn],
    ])
  }, [style, size, height, placeOn])

  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>): void => {
    const isInside = wrapperContent?.current?.contains(e?.target as Node)
    if (!isInside && dismissable && onClose) onClose()
  }

  return (
    <div className={wrapperClasses} aria-hidden onClick={handleOutsideClick}>
      <div className={contentClasses} ref={wrapperContent}>
        {children}
      </div>
    </div>
  )
}

CustomModal.Content = ModalContent
CustomModal.Footer = ModalFooter
CustomModal.Header = ModalHeader
CustomModal.OverlayContent = ModalOverlayContent
