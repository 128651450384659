import { createAction, createReducer } from '@reduxjs/toolkit'
import { ResetPasswordState } from '@interfaces'
import { requestResetPassword } from './thunk'

const initialState: ResetPasswordState = {
  loading: false,
  error: null,
  resetPassword: false,
}
export const clearErrors = createAction('clearErrors')

export const reducers = createReducer(initialState, (builder) => {
  builder
    .addCase(requestResetPassword.pending, (acc) => {
      acc.loading = true
      acc.resetPassword = false
      acc.error = null
    })
    .addCase(requestResetPassword.fulfilled, (acc) => {
      acc.loading = false
      acc.resetPassword = true
    })
    .addCase(requestResetPassword.rejected, (acc, { error }) => {
      acc.loading = false
      acc.resetPassword = false
      acc.error = error.message || ''
    })
    .addCase(clearErrors.type, (acc) => {
      acc.error = null
      acc.resetPassword = false
    })
})

export default reducers
