import './portfolio.styles.scss'
import { ChevronLeft, CloseIcon, InfoIcon, PieIcon } from '@assets/icons'
import { fetchInvestments } from '@store/commonReducers/portfolio/thunk'
import {
  BigSpinner,
  Button,
  ButtonedTabBarPrimary,
  Card,
  Spacer,
  StickyElementsWrapper,
  Text,
} from 'components'
import { useOnboardingCompleted } from 'hooks/useOnboardingCompleted'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState, fetchProfileValuation } from 'store'
import { PortfolioInfoModal } from '@screens/home/portfolioInfoModal/portfolioInfoModal'
import { PortfoliosList } from './components/portfoliosList/portfoliosList'
import { PortfolioValueChart } from './components/portfolioValueChart/portfolioValueChart'
import { PortfolioAssetsChart } from './components/portfolioAssetsChart/portfolioAssetsChart'
import { LoadingErrorScreen, PortfolioSummary } from '@shared/components'
import { PortfolioLaptopIllustration } from '@assets/illustrations'
import { useNavigate } from 'hooks/useNavigation'
import { PortfolioAssetsTable } from '@shared/components/tables'
import { ChartTypes } from './types'
import { localStorageService } from 'services/local-storage'
import { Chart } from 'components/chart/chart'
import { PeriodEnum } from 'interfaces'
import { FormattedChartData } from 'components/chart/chart.props'
import { PortfolioPeriods } from './portfolio.constant'
import { usePortfolioValuationRange } from 'hooks/usePortfolioValuationRange'

export default function PortfolioScreen(): JSX.Element {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const leftSideRef = useRef<HTMLDivElement>()
  const rightSideRef = useRef<HTMLDivElement>()
  const [mapChart, setMapChart] = useState<FormattedChartData>(null)
  const [graphPeriod, setGraphPeriod] = useState(PeriodEnum.month)
  const [isFirstOpen, setIsFirstOpen] = useState(true)
  const { userSettings, portfolioValuation, profile } = useSelector(
    (state: RootState) => state,
  )
  const { navigate } = useNavigate()

  const [selectedPortfolio, setSelectedPortfolio] = useState(null)
  const [portfolioTab, setPortfolioTab] = useState('PORTFOLIO_VALUE')
  const [activeTab, setActiveTab] = useState(ChartTypes.MY_ASSETS)
  const onboardingCompleted = useOnboardingCompleted()

  const [showPortfolioInfo, setShowPortfolioInfo] = useState(false)
  const dateRange = usePortfolioValuationRange(
    isFirstOpen ? PeriodEnum.month : graphPeriod,
  )

  const [showDiversifyCard, setShowDiversifyCard] = useState<boolean>(
    !localStorageService.getLocalState()?.hideDiversificationCardOnPortfolio,
  )

  const handleBrowseCatalogue = (): void => {
    navigate('/discover/all')
  }

  const fetchInvestmentsFn = (): void => {
    if (onboardingCompleted) {
      dispatch(fetchInvestments())
    }
  }
  useEffect(() => {
    fetchInvestmentsFn()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboardingCompleted])

  // This makes the table container have a max height equiavlent to the container on the left
  useLayoutEffect(() => {
    if (leftSideRef?.current && rightSideRef?.current) {
      rightSideRef.current.style.maxHeight = `${
        leftSideRef.current.getBoundingClientRect()?.height
      }px`
    }
  })

  const { investments } = useSelector((state: RootState) => state)
  useEffect(() => {
    if (isFirstOpen) {
      setGraphPeriod(PeriodEnum.month)
    }
  }, [isFirstOpen])

  useEffect(() => {
    if (
      portfolioValuation?.valuation.length > 0 &&
      !portfolioValuation.loading
    ) {
      const listing = portfolioValuation?.valuation.map((cs) => {
        const mapDate = new Date(cs.date)?.getTime() / 1000
        const val =
          portfolioTab === 'PORTFOLIO_VALUE'
            ? cs?.valuation?.amount
            : Number(cs?.unrealizedGains?.amount)
        return {
          time: mapDate,
          value: val,
          close: val,
          open: val,
          high: val,
          low: val,
          volume: 0,
        }
      })
      const lastValue =
        portfolioTab === 'PORTFOLIO_VALUE'
          ? investments?.currentValue.amount
          : investments?.totalEarnings.amount

      listing.push({
        time: new Date().getTime() / 1000,
        value: lastValue,
        close: lastValue,
        open: lastValue,
        high: lastValue,
        low: lastValue,
        volume: 0,
      })
      setMapChart({ chartData: listing })
    }
  }, [
    setMapChart,
    portfolioValuation.valuation,
    portfolioValuation.loading,
    investments?.currentValue.amount,
    portfolioTab,
    investments?.totalEarnings.amount,
  ])

  useEffect(() => {
    if (profile.userProfile.portfolioId)
      dispatch(
        fetchProfileValuation({
          portfolioId: profile.userProfile.portfolioId,
          portfolioValuationDate: dateRange,
        }),
      )
  }, [dispatch, profile.userProfile.portfolioId, graphPeriod, dateRange])

  if (
    (investments?.hardLoading || investments?.loading) &&
    !investments?.portfolios?.length
  ) {
    return (
      <div className='home__loader --fade-in-animation'>
        <BigSpinner />
      </div>
    )
  }

  if (investments?.error) {
    return <LoadingErrorScreen onRetry={fetchInvestmentsFn} />
  }

  if (
    !investments?.portfolios?.length ||
    investments?.portfolios?.length === 0
  ) {
    return (
      <div className='portfolio-screen --empty --fade-in-animation'>
        <Spacer preset='mediumPlus' />
        <Text preset='hero' text={t('common.portfolio')} />
        <Spacer preset='huge' />
        <Spacer preset='huge' />
        <div className='d-flex justify-center'>
          <div className='empty-portfolio d-flex justify-center align-center d-flex-col'>
            <PortfolioLaptopIllustration />
            <div className='empty-portfolio__content'>
              <Spacer preset='medium' />
              <Text
                text={t('portfolio.emptyPortfolioTitle')}
                preset='headline2'
                align='center'
              />
              <Spacer preset='smaller' />
              <Text
                align='center'
                text={t('portfolio.emptyPortfolioDesc')}
                preset='paragraph2'
                color='secondary'
              />
              <Spacer preset='small' />
              <Button
                text={t('common.startInvesting')}
                onClick={handleBrowseCatalogue}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='portfolio-screen --with-data --fade-in-animation'>
      <Spacer preset='large' />
      <div className='d-flex justify-space-between align-start'>
        <div className={`w-30 d-overflow `} ref={leftSideRef}>
          <div className='d-flex align-center'>
            <Text preset='hero' text={t('common.portfolio')} />
            <Spacer preset='tinyPlus' />
            <div
              onClick={() => setShowPortfolioInfo(true)}
              aria-hidden
              className='cursor-pointer d-flex align-center'
            >
              <InfoIcon color='tertiary' />
            </div>
          </div>
          <Spacer preset='smaller' />
          <div className='w-100'>
            <PortfolioSummary
              showPnL
              hideAmount={userSettings?.hideAmounts}
              showLast24Label
              portfolioTab={portfolioTab}
              setPortfolioTab={(e) => {
                setIsFirstOpen(true)
                setPortfolioTab(e)
              }}
            />
          </div>
          <Spacer preset='medium' />
          <PortfoliosList
            selectedPortfolio={selectedPortfolio}
            setSelectedPortfolio={setSelectedPortfolio}
          />
          <Spacer preset='extraTiny' />
          <Card padding='small' width='full' bordered>
            <ButtonedTabBarPrimary<ChartTypes>
              width='full'
              activeTab={activeTab}
              setActiveTab={(id) => setActiveTab(id)}
              tabs={[
                {
                  id: ChartTypes.MY_ASSETS,
                  title: t('common.myAssets'),
                },
                {
                  id: ChartTypes.PORTFOLIO_VALUE,
                  title: t('common.portfolioValue'),
                },
              ]}
              loading={false}
              tabsStyle='w-100'
            />
            <div className='w-80 ml-auto mr-auto'>
              <Spacer preset='mediumPlus' />
              {activeTab === ChartTypes.MY_ASSETS && <PortfolioAssetsChart />}
              {activeTab === ChartTypes.PORTFOLIO_VALUE && (
                <PortfolioValueChart />
              )}
            </div>
          </Card>
          <Spacer preset='extraTiny' />
          {showDiversifyCard && (
            <>
              <Spacer preset='smaller' />
              <Card
                padding='small'
                bordered
                borderColor='iconAndNavControls'
                bgColor='transparent'
              >
                <div className='d-flex justify-space-between'>
                  <PieIcon color='iconPrimaryInitial' />
                  <div
                    aria-hidden
                    className='cursor-pointer'
                    onClick={() => {
                      localStorageService.updateLocalState({
                        hideDiversificationCardOnPortfolio: true,
                      })
                      setShowDiversifyCard(false)
                    }}
                  >
                    <CloseIcon size={16} color='iconPrimaryInitial' />
                  </div>
                </div>
                <Spacer preset='smaller' />
                <Text
                  text={t('portfolio.diversifyCard.title')}
                  preset='paragraph2'
                  color='iconPrimaryInitial'
                />
                <Spacer preset='tinyPlus' />
                <Text
                  text={t('portfolio.diversifyCard.diversifyText')}
                  preset='paragraph2'
                />
                <Spacer preset='small' />
                <Button
                  autoWidth
                  size='small'
                  text={t('portfolio.diversifyCard.diversifyButton')}
                  onClick={handleBrowseCatalogue}
                  textPreset='paragraph2'
                />
              </Card>
            </>
          )}
          <Spacer preset='medium' />
        </div>

        <Spacer preset='medium' />
        <div className='w-70 d-overflow '>
          {selectedPortfolio !== null && (
            <>
              <Button
                autoWidth
                text='Portfolio value view'
                size='smaller'
                buttonType='link'
                textPreset='bodyRegular'
                left={<ChevronLeft size={42} />}
                onClick={() => {
                  setSelectedPortfolio(null)
                }}
              />
              <Spacer preset='small' />
            </>
          )}

          <div className='portfolio-screen__table-container d-flex d-flex-col align-center justify-center h-100 w-100'>
            {selectedPortfolio === null && mapChart && (
              <>
                <div className='portfolio_chat'>
                  <Chart
                    selectedPeriod={graphPeriod}
                    onPeriodChange={(p: PeriodEnum) => {
                      setIsFirstOpen(false)
                      setGraphPeriod(p)
                    }}
                    loading={portfolioValuation.loading}
                    data={mapChart}
                    symbolInfo={{
                      symbol: 'Portfolio',
                      period: graphPeriod,
                    }}
                    availablePeriods={PortfolioPeriods}
                    availablePeriodTabFull={true}
                  />
                </div>
                <Spacer preset='medium' />
              </>
            )}

            {investments?.portfolios?.map((portfolio, idx) => {
              return selectedPortfolio === idx ? (
                <>
                  <div className='d-flex align-center justify-between w-100'>
                    <div className='flex '>
                      <Text text={portfolio.title} preset='headline2' />
                    </div>
                    <div className='chips pa-1'>
                      <Text
                        text={`${String(portfolio.positions.length)} assets`}
                        preset='caption'
                      />
                    </div>
                  </div>
                  <Spacer preset='smaller' />
                  <div
                    className='portfolio-screen__table-content --fade-in-animation-fast'
                    key={portfolio?.title}
                  >
                    <StickyElementsWrapper
                      startPoint={0}
                      zIndex={'3'}
                      stickTableHeader
                    >
                      <PortfolioAssetsTable portfolio={portfolio} />
                    </StickyElementsWrapper>
                  </div>
                  <Spacer preset='medium' />
                </>
              ) : null
            })}
          </div>
        </div>
      </div>
      <PortfolioInfoModal
        showInfo={showPortfolioInfo}
        setShowInfo={setShowPortfolioInfo}
        investments={investments}
      />
    </div>
  )
}
