import { useLocation } from 'react-router-dom'
import { routes } from '../../navigation'
import { MenuOption } from './sideMenu.props'
import {
  HeartIcon,
  HeartIconFilled,
  HomeOutline,
  HomeFilled,
  SearchFilled,
  SearchOutline,
  PieChartFilled,
  PieChartOutline,
  UserFilled,
  UserOutline,
  ArrowHorizontalFilled,
  ArrowHorizontalOutline,
} from '@assets/svg'
import { useTranslation } from '@hooks'

export function useMenuOptions(): MenuOption[] {
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const activeStyle = (route: string, type: string): string => {
    const activeStyle = `navbar__${type}--active`
    return `navbar__${type} ${pathname.includes(route) ? activeStyle : ''}`
  }

  return [
    {
      active: routes.auth.home === pathname,
      name: t('home.title'),
      navigateTo: routes.auth.home,
      logo:
        routes.auth.home === pathname ? (
          <HomeFilled className={activeStyle(routes.auth.home, 'logo')} />
        ) : (
          <HomeOutline className={activeStyle(routes.auth.home, 'logo')} />
        ),
      optionStyle: activeStyle(routes.auth.home, 'option'),
    },
    {
      active: pathname.includes('discover'),
      name: t('discover.menu'),
      navigateTo: routes.auth.discoverAll,
      logo: pathname.includes('discover') ? (
        <SearchFilled
          className={`discover--logo ${activeStyle('discover', 'logo')}`}
        />
      ) : (
        <SearchOutline
          className={`discover--logo ${activeStyle('discover', 'logo')}`}
        />
      ),
      optionStyle: activeStyle('discover', 'option'),
    },
    {
      active: pathname.includes('transactions'),
      name: t('transactions.title'),
      navigateTo: routes.auth.transactionsExecuted,
      logo: pathname.includes('transactions') ? (
        <ArrowHorizontalFilled
          className={`transactions--logo ${activeStyle(
            routes.auth.transactions,
            'logo',
          )}`}
        />
      ) : (
        <ArrowHorizontalOutline
          className={`transactions--logo ${activeStyle(
            routes.auth.transactions,
            'logo',
          )}`}
        />
      ),
      optionStyle: activeStyle('transactions', 'option'),
    },
    {
      active: routes.auth.watchlist === pathname,
      name: t('watchlist.title'),
      navigateTo: routes.auth.watchlist,
      logo:
        routes.auth.watchlist === pathname ? (
          <HeartIconFilled
            className={activeStyle(routes.auth.watchlist, 'logo')}
          />
        ) : (
          <HeartIcon className={activeStyle(routes.auth.watchlist, 'logo')} />
        ),
      optionStyle: activeStyle(routes.auth.watchlist, 'option'),
    },
    {
      active: routes.auth.portfolio === pathname,
      name: t('common.portfolio'),
      navigateTo: routes.auth.portfolio,
      logo:
        routes.auth.portfolio === pathname ? (
          <PieChartFilled
            className={activeStyle(routes.auth.portfolio, 'logo')}
          />
        ) : (
          <PieChartOutline
            className={activeStyle(routes.auth.portfolio, 'logo')}
          />
        ),

      optionStyle: activeStyle(routes.auth.portfolio, 'option'),
    },
    {
      active: routes.auth.profile === pathname,
      name: t('profile.title'),
      navigateTo: routes.auth.profile,
      logo:
        routes.auth.profile === pathname ? (
          <UserFilled
            className={`profile--logo ${activeStyle(
              routes.auth.profile,
              'logo',
            )}`}
          />
        ) : (
          <UserOutline
            className={`profile--logo ${activeStyle(
              routes.auth.profile,
              'logo',
            )}`}
          />
        ),
      optionStyle: activeStyle(routes.auth.profile, 'option'),
    },
  ]
}

export function useMenuOpenStyles(menuOpen: boolean): {
  lineStyle: string
  navbarStyle: string
  overlayStyle: string
  textStyle: string
} {
  const lineStyle = menuOpen
    ? 'menuButton__line  menuButton__line--active'
    : ' menuButton__line'

  const navbarStyle = menuOpen
    ? 'navbar__options navbar__options--open'
    : 'navbar__options'

  const overlayStyle = menuOpen
    ? 'navbar__overlay navbar__overlay--active'
    : 'navbar__overlay'

  const textStyle = menuOpen ? 'navbar__text' : 'navbar__text tab'

  return { lineStyle, navbarStyle, overlayStyle, textStyle }
}
