import { UIIcon, UIIconColorProps, getIconColorSVGFill } from 'components/utils'

export const DonutChartIcon = ({
  color,
  size = 24,
}: UIIcon & UIIconColorProps): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 20 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9 3.58V0.5C4 1 0 5.31 0 10.5C0 15.69 4 20 9 20.5V17.42C6 16.94 3 14.02 3 10.5C3 6.98 6 4.06 9 3.58ZM16.97 9.5H20C19.53 4.5 16 0.97 11 0.5V3.58C14 4.01 16.54 6.5 16.97 9.5ZM11 17.42V20.5C16 20.03 19.53 16.5 20 11.5H16.97C16.54 14.5 14 16.99 11 17.42Z'
        fill={color ? getIconColorSVGFill(color) : 'currentColor'}
      />
    </svg>
  )
}
