import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  deletePaymentMethod,
  getPaymentMethods,
  setPaymentMethod,
  deleteManualTransfer,
} from '@services'
import { PaymentMethodTypeEnum, PaymentMethods } from '@interfaces'

export const fetchPaymentMethods = createAsyncThunk<PaymentMethods>(
  'fetchPaymentMethods',
  async () => {
    return await getPaymentMethods()
  },
)

export const removePaymentMethod = createAsyncThunk<
  void,
  { id: string; type: PaymentMethodTypeEnum }
>('removePaymentMethod', ({ id, type }) => deletePaymentMethod(id, type))

export const removeManualTransfer = createAsyncThunk<void, string>(
  'removeManualTransfer',
  (paymentId: string) => deleteManualTransfer(paymentId),
)

export const setDefaultPaymentMethod = createAsyncThunk<void, string>(
  'setDefaultPaymentMethod',
  (paymentId: string) => setPaymentMethod(paymentId),
)
