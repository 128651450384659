import {
  AnalystRatings,
  BullishBearish,
  Earning,
  Forecast,
  GetAllNews,
} from '@interfaces/companyOverview'
import { api } from '@utils/api/api'

export const getAnalystRating = (assetId: string): Promise<AnalystRatings> => {
  return api.get(`/api/v1/assets/tips/${assetId}/analyst-ratings`)
}

export const getForecast = (assetId: string): Promise<Forecast> => {
  return api.get(`/api/v1/assets/tips/${assetId}/forecast`)
}

export const getBullBears = (assetId: string): Promise<BullishBearish[]> => {
  return api.get(`/api/v1/assets/tips/${assetId}/bulls-bears`)
}
export const getNews = (assetId: string): Promise<GetAllNews[]> => {
  return api.get(`/api/v1/assets/tips/${assetId}/news`)
}
export const getEarnings = (assetId: string): Promise<Earning[]> => {
  return api.get(`/api/v1/assets/tips/${assetId}/earnings-revenues`)
}
