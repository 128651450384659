import { useTranslation } from '@hooks'
import ExampleBuyGraphicOne from '../shared/exampleBuyGraphicOne'
import ExampleBuyGraphicTwo from '../shared/exampleBuyGraphicTwo'
import { Button, CustomModal, Text } from '@components'
import { CloseIcon } from '@assets/icons'
import MainLogo from 'components/mainLogo/mainLogo'

export const LimitOrderBuyInfoModal = ({
  onClose,
}: {
  onClose: () => void
}): JSX.Element => {
  const handleClose = (): void => {
    onClose()
  }
  const { t } = useTranslation()
  return (
    <CustomModal show>
      <CustomModal.Header />
      <div className='d-flex align-center px-4 justify-between w-100'>
        <Button
          onClick={() => handleClose()}
          buttonType='link'
          autoWidth
          content={<CloseIcon color='iconPrimaryInitial' size={16} />}
          shape='square'
        />
      </div>
      <CustomModal.Content>
        <div className='d-flex d-flex-col align-start'>
          <div className=''>
            <Text
              text={t('limitOrders.limitBuyInfo.title')}
              preset='headline3'
            />
          </div>
          <div className='dividerInfo mt-2 pb-2 text-left'>
            <Text
              preset='bodyRegular'
              text={t('limitOrders.limitBuyInfo.description')}
            />
          </div>
          <div className='mt-2 text-left'>
            <Text
              preset='subtitle3'
              color='secondary'
              text={t('limitOrders.limitBuyInfo.example')}
            />

            <div className='mb-1'>
              <Text
                preset='bodyRegular'
                color='secondary'
                text={t('limitOrders.limitBuyInfo.explanationOne')}
              />
            </div>
          </div>
          <div className='mt-4 paragraph2'>
            <ExampleBuyGraphicOne
              currentPriceText={t('limitOrders.limitBuyInfo.currentPriceText')}
              buyText={t('limitOrders.limitBuyInfo.buyText')}
            />
          </div>
          <div className='mt-3 text-left'>
            <Text
              preset='bodyRegular'
              color='secondary'
              text={t('limitOrders.limitBuyInfo.explanationFour')}
            />
          </div>
          <div className='mt-4 paragraph2'>
            <ExampleBuyGraphicTwo
              currentPriceText={t('limitOrders.limitBuyInfo.startFillingOrder')}
              buyText={t('limitOrders.limitBuyInfo.buyText')}
            />
          </div>
          <div className='mt-4 pb-2 text-left '>
            <Text
              preset='bodyRegular'
              color='secondary'
              text={t('limitOrders.limitBuyInfo.explanationThree')}
            />
          </div>
          <div className='mt-3 text-left infoLogo '>
            <div className='containerLogo mb-2'>
              <MainLogo className='containerLogo__icon' />
              <p className='paragraph1'>{t('limitOrders.tbcc')}</p>
            </div>
            <div className='containerLogo__info'>
              <p className=' paragraph mb-1'>
                {t('limitOrders.limitBuyInfo.pushNotificationTitle')}
              </p>
              <p className='bodyRegular'>
                {t('limitOrders.limitBuyInfo.pushNotificationDescription')}
              </p>
            </div>
          </div>
          <div className=' mt-4 mb-2 text-left'>
            <Text
              preset='bodyRegular'
              color='secondary'
              text={t('limitOrders.limitBuyInfo.explanationFour')}
            />
          </div>
        </div>
      </CustomModal.Content>
      <CustomModal.Footer>
        <Button
          text={t('common.continue')}
          onClick={() => {
            handleClose()
          }}
        />
      </CustomModal.Footer>
    </CustomModal>
  )
}
