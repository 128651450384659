import './bigSpinner.style.scss'
import { ReactComponent as Spinner } from '@assets/svg/spinner2.svg'
import { ReactComponent as LogoSpinner } from '@assets/svg/logo-spinner.svg'

export function BigSpinner(props: { children?: JSX.Element }): JSX.Element {
  return (
    <div className='bigSpinner'>
      <div>
        <Spinner className='spinner path' />
        <LogoSpinner />
      </div>
      {props.children}
    </div>
  )
}
