export const NewBuySellFlowIllustration = (): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='250'
      height='146'
      fill='none'
      viewBox='0 0 250 146'
    >
      <rect width='225' height='136' x='8' fill='#fff' rx='3'></rect>
      <path fill='#fff' d='M15 7a3 3 0 013-3h213a3 3 0 013 3v132H15V7z'></path>
      <path
        stroke='var(--illustrationsPrimary30)'
        strokeWidth='4'
        d='M15 7a3 3 0 013-3h213a3 3 0 013 3v132H15V7z'
      ></path>
      <rect
        width='223'
        height='137'
        x='12.5'
        y='2.5'
        stroke='var(--illustrationsSharedNeutral900)'
        rx='2.5'
      ></rect>
      <path
        fill='var(--illustrationsPrimary30)'
        stroke='var(--illustrationsSharedNeutral900)'
        d='M.5 134a.5.5 0 01.5-.5h100.786a.5.5 0 01.404.205l1.224 1.679a1.5 1.5 0 001.212.616h42.748c.479 0 .929-.229 1.212-.616l-.404-.295.404.295 1.224-1.679s0 0 0 0a.5.5 0 01.404-.205H249a.5.5 0 01.5.5v11a.5.5 0 01-.5.5H1a.5.5 0 01-.5-.5v-11z'
      ></path>
      <path
        fill='#F9FAFA'
        d='M153.115 7.546H230.98200000000003V133H153.115z'
      ></path>
      <rect
        width='67.309'
        height='73.346'
        x='159.543'
        y='43.585'
        fill='#fff'
        rx='2'
      ></rect>
      <rect
        width='29.55'
        height='7.388'
        x='178.833'
        y='17.318'
        fill='#fff'
        rx='2'
      ></rect>
      <rect
        width='12.133'
        height='4.746'
        x='180.154'
        y='18.639'
        fill='var(--illustrationsNeutral25)'
        stroke='var(--illustrationsNeutral25)'
        rx='1.5'
      ></rect>
      <path
        stroke='var(--illustrationsPrimary30)'
        d='M161.595 18.55l-2.052 2.051 2.052 2.053'
      ></path>
      <rect
        width='39.221'
        height='4.426'
        x='180.975'
        y='49.329'
        fill='var(--illustrationsNeutral25)'
        stroke='var(--illustrationsNeutral25)'
        rx='1.5'
      ></rect>
      <path
        fill='var(--illustrationsNeutral25)'
        stroke='var(--illustrationsNeutral25)'
        strokeWidth='0.41'
        d='M160.159 63.49H226.236V63.9H160.159z'
      ></path>
      <path
        fill='var(--illustrationsNeutral25)'
        stroke='var(--illustrationsNeutral25)'
        strokeWidth='0.41'
        d='M153.32 38.044H230.776V38.45399999999999H153.32z'
      ></path>
      <path
        fill='var(--illustrationsPrimary30)'
        stroke='var(--illustrationsPrimary30)'
        strokeWidth='0.41'
        d='M159.99 38.044H182.74800000000002V38.45399999999999H159.99z'
      ></path>
      <rect
        width='19.753'
        height='2.321'
        x='186.23'
        y='57.185'
        fill='var(--illustrationsSuccess20)'
        rx='1.16'
      ></rect>
      <rect
        width='15.791'
        height='1.463'
        x='163.473'
        y='31.773'
        fill='var(--illustrationsPrimary30)'
        stroke='var(--illustrationsPrimary30)'
        rx='0.731'
      ></rect>
      <rect
        width='15.791'
        height='1.463'
        x='189.381'
        y='31.773'
        fill='var(--illustrationsNeutral25)'
        stroke='var(--illustrationsNeutral25)'
        rx='0.731'
      ></rect>
      <circle
        cx='169.06'
        cy='53.512'
        r='6.489'
        fill='var(--illustrationsNeutral25)'
      ></circle>
      <path
        stroke='#fff'
        strokeLinecap='round'
        d='M165.815 56.216l1.797-3.114 2.995 1.245 1.796-3.114'
      ></path>
      <rect
        width='26.006'
        height='3.104'
        x='165.015'
        y='71.993'
        fill='var(--illustrationsNeutral25)'
        stroke='var(--illustrationsNeutral25)'
        rx='1.5'
      ></rect>
      <rect
        width='17.126'
        height='1.28'
        x='165.015'
        y='77.739'
        fill='var(--illustrationsNeutral25)'
        stroke='var(--illustrationsNeutral25)'
        rx='0.64'
      ></rect>
      <rect
        width='26.006'
        height='3.104'
        x='165.015'
        y='90.052'
        fill='var(--illustrationsNeutral25)'
        stroke='var(--illustrationsNeutral25)'
        rx='1.5'
      ></rect>
      <rect
        width='26.006'
        height='3.104'
        x='165.015'
        y='104.827'
        fill='var(--illustrationsNeutral25)'
        stroke='var(--illustrationsNeutral25)'
        rx='1.5'
      ></rect>
      <path
        fill='var(--illustrationsNeutral25)'
        stroke='var(--illustrationsNeutral25)'
        d='M200.45 89.09a1.5 1.5 0 011.5-1.5h17.504a1.5 1.5 0 011.5 1.5v3.737a1.5 1.5 0 01-1.5 1.5H201.95a1.5 1.5 0 01-1.5-1.5v-3.738z'
      ></path>
      <rect
        width='20.504'
        height='3.104'
        x='200.45'
        y='104.827'
        fill='var(--illustrationsNeutral25)'
        stroke='var(--illustrationsNeutral25)'
        rx='1.5'
      ></rect>
      <path
        stroke='var(--illustrationsPrimary30)'
        strokeWidth='2'
        d='M200.95 72.673a1 1 0 011-1h17.504a1 1 0 011 1v3.737a1 1 0 01-1 1H201.95a1 1 0 01-1-1v-3.737z'
      ></path>
    </svg>
  )
}
