import { HorizontalTabProps } from './horizontalTab.props'
import './horizontalTab.styles.scss'

const HorizontalTab = ({
  loading,
  selectedTabId,
  availableTabs,
  onTabChange,
}: HorizontalTabProps): JSX.Element => {
  const activeStyle = (tabId: string): string => {
    const activeStyle = `tab-details__after-widget__buttons__element--active`
    return `${selectedTabId === tabId ? activeStyle : ''}`
  }
  return (
    <div className={`tab h-100 w-100 ${loading ? '--loading' : ''}`}>
      <div className='tab__controls w-100 d-flex align-center justify-space-between'>
        <div className='tab-details__after-widget__buttons'>
          {availableTabs?.map((p) => {
            return (
              <button
                key={`${p.label}-${p.id}`}
                className={`tab-details__after-widget__buttons__element ${activeStyle(
                  p.id,
                )}`}
                onClick={() => {
                  if (onTabChange) onTabChange(p?.id)
                }}
              >
                {p.label}
              </button>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default HorizontalTab
