import { createUTCDateFromISOString } from '@utils/helpers'
import { DayDisabledProps } from './types'

export function isDayDisabled({
  allowSatAndSun,
  col,
  currentMonthCounter,
  maxDate,
  minDate,
  selectedMonth,
  selectedYear,
}: DayDisabledProps): boolean {
  const isSatOrSun = col === 5 || col === 6

  const time = new Date(
    Date.UTC(selectedYear, selectedMonth, currentMonthCounter, 0, 0, 0, 0),
  ).getTime()

  const isBeforeMinDate = minDate
    ? time - createUTCDateFromISOString(minDate)?.getTime() < 0
    : false

  const isMoreThanMaxDate = maxDate
    ? time - createUTCDateFromISOString(maxDate)?.getTime() > 0
    : false

  return (isSatOrSun && !allowSatAndSun) || isBeforeMinDate || isMoreThanMaxDate
}
