import i18next from 'i18next'
import { AuthenticatedLayout } from './AuthenticatedLayout'
import { AuthProvider } from './AuthProvider'
import { BigSpinner, Notifications } from '@components'
import { ClaimEnum } from './services'
import { fetchTranslations } from '@store/commonReducers/translations'
import { getAppConfiguration } from './store/commonReducers/config'
import { getAppLang, getLang } from '@utils/langs'
import { NotCompatibleScreen } from '@shared/modals'
import { OnlyNonAuthSession } from './OnlyNonAuthSession'
import { RequireAuth } from './RequireAuth'
import { RootState } from './store'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { routes } from './navigation/routes'
import { trackingService } from '@services'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import * as Sentry from '@sentry/react'
import {
  Login,
  Home,
  Discover,
  Profile,
  Signup,
  ResetPassword,
  Company,
  Transactions,
  TransactionDetails,
  CollectionDetails,
  ConfirmationPage,
  Watchlist,
  Collections,
  Help,
  Languages,
  PaymentMethods,
  Themes,
  PortfolioScreen,
} from './navigation/routesScreens'
import { recoverToken } from '@screens/login/thunk'
import { sessionStorageService } from '@services/session-storage'

// Part of Sentry setup for react router v6
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

function usePageViewsAnalytics(): void {
  const location = useLocation()
  useEffect(() => {
    trackingService.trackPage(location.pathname)
  }, [location])
}

function App(): JSX.Element {
  usePageViewsAnalytics()
  const auth = useSelector((state: RootState) => state.login)
  const dispatch = useDispatch()

  const { translations } = useSelector((state: RootState) => state.translations)
  const lang = getAppLang()

  useEffect(() => {
    dispatch(fetchTranslations(lang))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!auth.token && sessionStorageService.getSessionState()?.user) {
      dispatch(recoverToken())
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (translations) {
      i18next.addResourceBundle(getLang(), 'remote', translations, true, true)
      i18next.setDefaultNamespace('remote')
    }
  }, [translations])

  useEffect(() => {
    const isTester = auth?.claims?.some((c) => c === ClaimEnum.isTester)
    if (auth?.token) {
      dispatch(getAppConfiguration(isTester))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.claims, auth.token])

  return (
    <>
      {auth.isLoading ? (
        <div className='home__loader --fade-in-animation'>
          <BigSpinner />
        </div>
      ) : (
        <AuthProvider>
          <SentryRoutes>
            <Route
              key={routes.nonAuth.login}
              path={routes.nonAuth.login}
              element={
                <OnlyNonAuthSession>
                  <Login />
                </OnlyNonAuthSession>
              }
            />
            <Route
              key={routes.nonAuth.signup}
              path={routes.nonAuth.signup}
              element={
                <OnlyNonAuthSession>
                  <Signup />
                </OnlyNonAuthSession>
              }
            />
            <Route
              key={routes.auth.resetPassword}
              path={routes.auth.resetPassword}
              element={
                <OnlyNonAuthSession>
                  <ResetPassword />
                </OnlyNonAuthSession>
              }
            />
            <Route
              key={routes.nonAuth.root}
              path={routes.nonAuth.root}
              element={
                <RequireAuth>
                  <AuthenticatedLayout />
                </RequireAuth>
              }
            >
              <Route
                key={routes.auth.home}
                path={routes.auth.home}
                element={<Home />}
              />
              <Route
                key={routes.auth.discover}
                path={routes.auth.discover}
                element={<Discover />}
              />
              <Route
                key={routes.auth.profile}
                path={routes.auth.profile}
                element={<Profile />}
              />
              <Route
                key={routes.auth.help}
                path={routes.auth.help}
                element={<Help />}
              />
              <Route
                key={routes.auth.themes}
                path={routes.auth.themes}
                element={<Themes />}
              />
              <Route
                key={routes.auth.language}
                path={routes.auth.language}
                element={<Languages />}
              />
              <Route
                key={routes.auth.paymentMethods}
                path={routes.auth.paymentMethods}
                element={<PaymentMethods />}
              />
              <Route
                key={routes.auth.transactions}
                path={routes.auth.transactions}
                element={<Transactions />}
              />
              <Route
                key={routes.auth.transactionDetail}
                path={routes.auth.transactionDetail}
                element={<TransactionDetails />}
              />
              <Route path={routes.auth.company} element={<Company />} />
              <Route
                key={routes.auth.collections}
                path={routes.auth.collections}
                element={<Collections />}
              />
              <Route
                key={routes.auth.collection}
                path={routes.auth.collection}
                element={<CollectionDetails />}
              />
              <Route
                key={routes.auth.portfolio}
                path={routes.auth.portfolio}
                element={<PortfolioScreen />}
              />
              <Route
                key={routes.auth.confirmation}
                path={routes.auth.confirmation}
                element={<ConfirmationPage />}
              />
              <Route
                key={routes.auth.watchlist}
                path={routes.auth.watchlist}
                element={<Watchlist />}
              />
              <Route
                key={'auth-catch-all'}
                path={'/'}
                element={<Navigate to={routes?.auth?.home} />}
              />
            </Route>
            <Route
              key={'catch-all'}
              path={'*'}
              element={<Navigate to={routes?.nonAuth?.login} />}
            />
          </SentryRoutes>
          <Notifications />
          {auth.token && (
            <div className='not-compatible-screen-wrapper'>
              <NotCompatibleScreen />
            </div>
          )}
        </AuthProvider>
      )}
    </>
  )
}

export default App
