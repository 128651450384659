import { ChangeEvent } from 'react'
import { FieldValues, UseFormReturn } from 'react-hook-form'
import { sanitizeNumber } from '@utils/helpers'
import { getFormatLang } from '@utils/langs'
import { formatInputNumber } from '@utils/formatting'

export const useOnChangeMoneyForm = (
  moneyForm: UseFormReturn<FieldValues, unknown>,
  moneyFormValName: string,
  decimalsAmount = 2,
  setPrice?: (amount: number) => void,
) => {
  return async (e: ChangeEvent<HTMLInputElement>) => {
    const lang = getFormatLang()
    const val = formatInputNumber(e.target.value, lang, decimalsAmount)

    if (setPrice) setPrice(sanitizeNumber(val))
    moneyForm.setValue(moneyFormValName, val)
    await moneyForm.trigger(moneyFormValName)
  }
}
