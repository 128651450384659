export enum passwordRequirementTypes {
  MIN_LENGTH_VALIDATION = 'MIN_LENGTH_VALIDATION',
  UPPER_CASE_VALIDATION = 'UPPER_CASE_VALIDATION',
  LOWER_CASE_VALIDATION = 'LOWER_CASE_VALIDATION',
  NUMBER_VALIDATION = 'NUMBER_VALIDATION',
  SPECIAL_CHARACTER_VALIDATION = 'SPECIAL_CHARACTER_VALIDATION',
  USERNAME_INCLUSION_VALIDATION = 'USERNAME_INCLUSION_VALIDATION',
}

export const enum ListType {
  NORMAL = 'normal',
  ERROR = 'error',
  SUCCESS = 'success',
}
