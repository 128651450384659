enum BorderColorsEnum {
  banner1 = 'banner1',
  banner2 = 'banner2',
  banner3 = 'banner3',
  banner4 = 'banner4',
  banner5 = 'banner5',
  banner6 = 'banner6',
  banner7 = 'banner7',
  banner8 = 'banner8',
  strokeNeutralSoftInitial = 'strokeNeutralSoftInitial',
  fixedButton = 'fixedButton',
  primaryBase = 'primaryBase',
  secondaryBase = 'secondaryBase',
  statusNegative = 'statusNegative',
  statusPositive = 'statusPositive',
  statusWarning = 'statusWarning',
  tertiaryBase = 'tertiaryBase',
  transparent = 'transparent',
  iconAndNavControls = 'iconAndNavControls',
}

type BorderColorsPreset = keyof typeof BorderColorsEnum

export interface UIBorderColorProps {
  borderColor?: BorderColorsPreset
}

export function getBorderColorClass(borderColor: BorderColorsPreset): string {
  switch (borderColor) {
    case 'banner1':
      return 'border-color-banner1'
    case 'banner2':
      return 'border-color-banner2'
    case 'banner3':
      return 'border-color-banner3'
    case 'banner4':
      return 'border-color-banner4'
    case 'banner5':
      return 'border-color-banner5'
    case 'banner6':
      return 'border-color-banner6'
    case 'banner7':
      return 'border-color-banner7'
    case 'banner8':
      return 'border-color-banner8'
    case 'strokeNeutralSoftInitial':
      return 'border-color-stroke-divider'
    case 'fixedButton':
      return 'border-color-fixed-button'
    case 'primaryBase':
      return 'border-color-primary-base'
    case 'secondaryBase':
      return 'border-color-secondary-base'
    case 'statusNegative':
      return 'border-color-status-negative'
    case 'statusPositive':
      return 'border-color-status-positive'
    case 'statusWarning':
      return 'border-color-status-warning'
    case 'tertiaryBase':
      return 'border-color-tertiary-base'
    case 'transparent':
      return 'border-color-transparent'
    case 'iconAndNavControls':
      return 'border-color-icon-and-nav-controls'
    default:
      return ''
  }
}
