import { useTranslation } from '@hooks'
import { CustomModal, Button, GoBack, Text } from '@components'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { fetchSepaConditions } from '../thunk'
import { RootState } from 'store'

interface TopUpLegalReminderProps {
  nextStep: () => void
  prevStep: () => void
  onClose?: () => void
}

export function TopUpLegalReminder({
  nextStep,
  prevStep,
  onClose,
}: TopUpLegalReminderProps): JSX.Element {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [isChecked, setIsChecked] = useState(false)
  const handleConfirmation = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setIsChecked(e.target.checked)
  }

  const { bankTransfer } = useSelector((state: RootState) => state)

  const onConfirmation = (): void => {
    dispatch(fetchSepaConditions())
  }

  useEffect(() => {
    if (!bankTransfer.loading && bankTransfer.destinationIban && isChecked) {
      nextStep()
    }
  }, [bankTransfer.destinationIban, bankTransfer.loading, isChecked, nextStep])

  const texts = {
    button: t('common.continue'),
    header: t('bankTransfer.cashout.manualTransferShort'),
    text1: t('bankTransfer.title'),
    text2: t('bankTransfer.legalReminder'),
  }

  return (
    <>
      <CustomModal.Header
        text={texts.header}
        linkText={t('common.cancel')}
        className='subtitle3 dark--label-title'
        onClick={onClose}
      >
        <GoBack goBack={prevStep} />
      </CustomModal.Header>
      <CustomModal.Content className='d-flex d-flex-col'>
        <Text text={texts.text1} preset='headline1' />
        <Text text={texts.text2} preset='paragraph2' color='tertiary' />
      </CustomModal.Content>
      <CustomModal.Footer className='d-flex d-flex-col align-center d-flex-col justify-center'>
        <div className='d-flex d-flex-row align-center justify-start paragraph3 secondary-text mb-3'>
          <input
            type='checkbox'
            id='markAsRead'
            value='first_checkbox'
            className='mr-2'
            defaultChecked={isChecked}
            onChange={(e) => handleConfirmation(e)}
          ></input>
          <label htmlFor='markAsRead' className='crusor-pointer text-left'>
            {t('bankTransfer.markAsRead')}
          </label>
        </div>

        <Button
          text={texts.button}
          type='submit'
          size='big'
          disabled={!isChecked || bankTransfer.loading}
          loading={bankTransfer.loading}
          onClick={onConfirmation}
        />
      </CustomModal.Footer>
    </>
  )
}
