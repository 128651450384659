import {
  NavigateOptions,
  To,
  useNavigate as useReactNavigation,
} from 'react-router-dom'

// This function is a react navigate wrapper so we can add custom actions on each navigation
export const useNavigate = (): {
  navigate: (to: To | -1, options?: NavigateOptions) => void
} => {
  const navigate = useReactNavigation()

  const navigation = (to: To, options?: NavigateOptions): void => {
    if (window) window.scrollTo({ top: 0 })
    navigate(to, options)
  }

  return { navigate: navigation }
}
