import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import {
  AddMoneyData,
  getAuthLinkPayment,
  getCreditCardFees,
  getInfoTopUp,
  getPaymentMethodStatus,
  getValidCreditCards,
  sendCreditCardPayment,
  sendRecurringPayment,
  sendStoredCreditCardPayment,
} from '@services'
import {
  PaymentAuthLink,
  TopUpInfo,
  PaymentMethod,
  TopUpPaymentType,
  CreditCardScheme,
  CreditCardFee,
  CreditCardPaymentInfo,
  CreditCardPaymentData,
  RecurringPaymentData,
  CardFlowType,
  StoredCreditCardPaymentData,
} from '@interfaces'
import { CardDetails, RecurringPaymentConfig } from './reducers'

export const fetchInfoTopUp = createAsyncThunk<TopUpInfo>(
  'getInfoTopUp',
  async () => {
    return getInfoTopUp()
  },
)

export const clearTopUpInfo = createAction('clearTopUpInfo')

export const clearTopUpPaymentUrl = createAction('clearTopUpPaymentUrl')

export const setSelectedPaymentMethod = createAction<PaymentMethod | undefined>(
  'setSelectedPaymentMethod',
)

export const setRecurringPaymentOptions = createAction<RecurringPaymentConfig>(
  'setRecurringPaymentOptions',
)

export const clearRecurringPaymentOptions = createAction(
  'clearRecurringPaymentOptions',
)

export const clearCreditCardPayment = createAction('clearCreditCardPayment')

export const setTopUpType = createAction<TopUpPaymentType>('setTopUpType')

export const clearTopUpType = createAction('clearTopUpType')

export const setCardDetails = createAction<CardDetails>('setCardDetails')

export const setCardFlowType = createAction<CardFlowType>('setCardFlowType')

export const fetchCreditCardFees = createAsyncThunk<CreditCardFee[]>(
  'fetchCreditCardFees',
  async () => {
    return getCreditCardFees()
  },
)

export const clearSelectedBank = createAction('clearSelectedBank')

export const getTopUpPaymentUrl = createAsyncThunk<
  PaymentAuthLink,
  AddMoneyData
>('getTopUpPaymentUrl', async (data: AddMoneyData) => {
  return getAuthLinkPayment(data)
})

export const fetchPaymentMethodStatus = createAsyncThunk<void, string>(
  'fetchPaymentMethodStatus',
  async (id: string) => getPaymentMethodStatus(id),
)

export const fetchValidCreditCards = createAsyncThunk<CreditCardScheme[]>(
  'fetchValidCreditCards',
  async () => {
    return getValidCreditCards()
  },
)

export const sendCardPayment = createAsyncThunk<
  CreditCardPaymentInfo,
  CreditCardPaymentData
>('sendCardPayment', (orderResponse) => {
  return sendCreditCardPayment(orderResponse)
})

export const sendRecurringCardPayment = createAsyncThunk<
  CreditCardPaymentInfo,
  RecurringPaymentData
>('sendRecurringCardPayment', (orderResponse) => {
  return sendRecurringPayment(orderResponse)
})

export const sendStoredCardPayment = createAsyncThunk<
  CreditCardPaymentInfo,
  StoredCreditCardPaymentData
>('sendCardPayment', (orderResponse) => {
  return sendStoredCreditCardPayment(orderResponse)
})
