import { useTranslation } from '@hooks'
import { CustomModal, GoBack, Button, Avatar } from '@components'
import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'store'
import { formatMoney } from '@utils/helpers'
import { policies } from '@utils/externalUrls'
import { getLang } from '@utils/langs'
import { ReactComponent as LogoTL } from '@assets/svg/tl-logo.svg'
import { clearTopUpPaymentUrl } from '../topup/thunk'
import { trackingService } from '@services'
import { TopUpEvents } from '@utils/eventTracker/eventKeys'

export function Summary(props: {
  nextStep: () => void
  values?: {
    accountId: string
    amount: number
    currency: string
    redirectUrl: string
  }
  prevStep: () => void
  onClose?: () => void
  show?: boolean
}): JSX.Element {
  const { t } = useTranslation()
  const defLang = getLang().split('-')[0]
  // If in the future we will use inside API and need to show error message
  const [isSent] = useState(false)
  const { addMoney, selectAccount, cashout } = useSelector(
    (state: RootState) => state,
  )

  const [selectedAccount] = useState(
    selectAccount?.bankAccounts?.find(
      (acc) => acc.id == props.values?.accountId,
    ),
  )

  const realAmount = formatMoney(
    props.values?.amount as number,
    props.values?.currency as string,
  )

  const dispatch = useDispatch()
  const cancelActions = (): void => {
    dispatch(clearTopUpPaymentUrl())
    props.prevStep()
  }

  const handleSelect = (): void => {
    if (addMoney.url) {
      trackingService.trackEvent({ event: TopUpEvents.redirectedToBank })
      localStorage.setItem(
        'summaryFlow',
        JSON.stringify({
          amount: props.values?.amount,
          currency: props.values?.currency,
          concept: addMoney.concept,
        }),
      )
      location.replace(addMoney.url)
    }
  }

  return (
    <>
      <CustomModal.Header
        text={t('addMoney.header')}
        linkText={t('common.cancel')}
        onClick={props.onClose}
      >
        <GoBack
          goBack={() => {
            cancelActions()
          }}
        />
      </CustomModal.Header>
      {!isSent ? (
        <CustomModal.Content className='d-flex d-flex-col'>
          <div className='headline1 primary--text my-5'>
            {t('confirmPayment.confirmPaymentInfo')}
          </div>
          <div className='d-flex justify-center'>
            <Avatar
              image={selectedAccount?.icon}
              roundness='full'
              size='large'
            />
          </div>
          <div className='paragraph2 primary--text mt-1 mb-5'>
            {selectedAccount?.description}
            <p className='paragraph3 secondary--text mt-1'>
              {`${addMoney?.selectedPaymentMethod?.name || ''} ${
                selectedAccount?.number || ''
              }`}
            </p>
          </div>
          <div className='infoCard__content mb-2'>
            <div className='pa-3 text-center'>
              <div className='paragraph2 mb-2'>
                {t('confirmPayment.youArePaying')}
              </div>
              <div className='headline1'>{realAmount}</div>
            </div>
          </div>
          <div className='text-center tertiary--text paragraph2 mt-6'>
            {t('addMoney.securedPartOne')} <LogoTL className='ml-1' />
          </div>
        </CustomModal.Content>
      ) : (
        <CustomModal.Content className='d-flex d-flex-col align-center justify-center'>
          <div className='mt-3'>
            <Avatar
              image={selectedAccount?.icon}
              roundness='full'
              size='large'
            />
          </div>

          <span className='paragraph5 primary--text mt-3'>
            {t('common.oops')}
            <p className='mt-1'>{t('companyDetails.modal.transactionFail')}</p>
          </span>

          <span className='paragraph2 primary--text mt-2 mb-3'>
            {t('companyDetails.modal.errorText')}
          </span>
        </CustomModal.Content>
      )}
      <CustomModal.Footer className='d-flex d-flex-col'>
        <div className='subfooter__text paragraph2 text-left mb-3'>
          <span>{t('addMoney.byContinuing')}</span>{' '}
          <a href={policies.termsUrl[defLang]} target='_blank' rel='noreferrer'>
            {t('addMoney.endUserTermsOfService')}
          </a>{' '}
          <span>{t('common.and')}</span>{' '}
          <a
            href={policies.privacyPolicyUrl[defLang]}
            target='_blank'
            rel='noreferrer'
          >
            {t('addMoney.privacyPolicy')}
          </a>
        </div>
        <Button
          text={t('confirmPayment.continueToBank')}
          type='submit'
          size='big'
          onClick={handleSelect}
          loading={addMoney.loading || cashout.loading}
          disabled={addMoney.loading || cashout.loading}
        />
      </CustomModal.Footer>
    </>
  )
}
