import { AnyAction, createReducer, PayloadAction } from '@reduxjs/toolkit'
import { SearchBase, SearchState } from '@interfaces'
import { clearAssetsListDiscover, fetchStocksDiscover } from './search.thunk'

const initialStateDiscover: SearchState & SearchBase = {
  data: {
    title: '',
    stocks: [],
  },
  currentPage: 0,
  totalItems: 0,
  totalPages: 0,
  loading: false,
  paginationLoader: false,
  error: null,
  query: null,
}

const rejectedAction = (action: AnyAction): boolean =>
  action.type.includes(fetchStocksDiscover.rejected.toString())

export const reducers = createReducer(initialStateDiscover, (builder) => {
  builder
    .addCase(
      fetchStocksDiscover.pending,
      (acc: SearchState & SearchBase, { meta: { arg } }) => {
        const { page, query } = arg
        const filter = (acc.query || query) && !page
        acc.query = query
        acc.loading = page === 0
        acc.paginationLoader = page > 0
        acc.data.stocks = filter ? [] : acc.data.stocks
        acc.error = null
      },
    )
    .addCase(
      fetchStocksDiscover.fulfilled,
      (acc, action: PayloadAction<SearchState>) => {
        const {
          payload: { data, totalPages, currentPage, totalItems },
        } = action

        const currentStocks = acc?.data?.stocks || []
        const newStocks = data?.stocks || []

        acc.data.stocks =
          currentPage === 0 ? [...newStocks] : [...currentStocks, ...newStocks]

        acc.totalItems = totalItems
        acc.totalPages = totalPages
        acc.currentPage = currentPage
        acc.loading = false
        acc.paginationLoader = false
      },
    )
    .addCase(clearAssetsListDiscover, (acc) => {
      acc.query = null
      acc.currentPage = 0
      acc.totalItems = 0
      acc.totalPages = 0
    })
    .addMatcher(rejectedAction, (acc, { error }) => {
      acc.error = error.message
      acc.loading = false
      acc.paginationLoader = false
    })
})

export default reducers
