import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { SearchAssetsState } from '@interfaces'
import { multiAssetsSearch } from '@services'

export const clearQuery = createAction('clearQuery')

export const fetchAssets = createAsyncThunk<
  SearchAssetsState,
  {
    query: string
    type: 'initial' | 'search'
    origin: 'headerSearch' | 'discoverSearch'
  }
>(
  'fetchAssets',
  async (data: {
    query: string
    type: 'initial' | 'search'
    origin: 'headerSearch' | 'discoverSearch'
  }) => {
    const { query } = data
    return await multiAssetsSearch(query)
  },
)
