import { Investments } from '@interfaces'
import { api } from '@utils/api/api'

export const getInvestments = (realTimePrices = true): Promise<Investments> => {
  return api.get(
    `/api/v1/portfolio/performance${
      realTimePrices ? '?realTimePrices=true' : ''
    }`,
  )
}
