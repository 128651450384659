import { BrokerTierEnum, Money } from 'interfaces'

export const environment: {
  env: 'dev' | 'pre' | 'pro'
  segmentKey: string
  checkoutKey: string
  nodeEnv: string
  baseUrl: string
  apiKey: string
  currentAppVersion: string
} = {
  env: import.meta.env.REACT_APP_ENV,
  segmentKey: import.meta.env.REACT_APP_SEGMENT_KEY,
  checkoutKey: import.meta.env.REACT_APP_CHECKOUT,
  nodeEnv: import.meta.env.NODE_ENV,
  baseUrl: import.meta.env.REACT_APP_BASE_URL,
  apiKey: import.meta.env.REACT_APP_X_HEYTRADE_API_KEY,
  currentAppVersion: import.meta.env.REACT_APP_VERSION,
}

export interface BrokerTierConstantsTier {
  currencyExchangeFee: number
  transactionMinimumCost: Money
  transactionPercentualCost: number
  minimumTradesPerMonth: number
  minimumGlobalPosition: Money
}

export interface BrokerTierConstants {
  [BrokerTierEnum.lite]: BrokerTierConstantsTier
  [BrokerTierEnum.pro]: BrokerTierConstantsTier
}

const transactionMinimumCost: Money = {
  amount: 2,
  currency: 'USD',
}

export const brokerTierConstants = {
  [BrokerTierEnum.lite]: {
    transactionMinimumCost: transactionMinimumCost,
    currencyExchangeFee: 0.25,
    transactionPercentualCost: 0.1,
    minimumTradesPerMonth: 0,
    minimumGlobalPosition: {
      amount: 0,
      currency: 'USD',
    },
  },
  [BrokerTierEnum.pro]: {
    transactionMinimumCost: transactionMinimumCost,
    currencyExchangeFee: 0.1,
    transactionPercentualCost: 0.05,
    minimumTradesPerMonth: 15,
    minimumGlobalPosition: {
      amount: 100000,
      currency: 'USD',
    },
  },
}
