import { useTranslation } from 'react-i18next'
import { Button, CustomModal, Divider, Spacer, Text } from '@components'

export function AboutManualTransfer(props: {
  show: boolean
  onClose: () => void
  onTransfer: () => void
}): JSX.Element {
  const { t } = useTranslation()

  return (
    <CustomModal
      show={props.show}
      theme='lighter'
      size='big'
      dismissable
      onClose={props?.onClose}
    >
      <CustomModal.Header
        text={'About manual transfers'}
        onClick={props.onClose}
        linkText={t('common.cancel')}
      />
      <div className='w-100 px-2'>
        <Divider />
      </div>
      <CustomModal.Content className='d-flex-col'>
        <div className='d-flex d-flex-col w-100 align-self-start'>
          <div className='w-100'>
            <Text preset='subtitle2' color='primary'>
              {t('addMoney.guides.followingSteps')}
            </Text>
            <ul className='number_list'>
              <li>
                <Text preset='bodyRegular'>
                  {t('addMoney.guides.beforeMaking')}
                </Text>
              </li>
              <li>
                <Text preset='bodyRegular'>
                  {t('addMoney.guides.pleaseEnsure')}
                </Text>
              </li>
              <li>
                <Text preset='bodyRegular'>
                  {t('addMoney.guides.initiateTransfer')}
                </Text>
              </li>
            </ul>
          </div>
          <Spacer preset='smaller' />
          <div className='w-100'>
            <Text preset='subtitle2' color='primary'>
              {t('addMoney.pleaseNote.pleaseNote')}
            </Text>
            <ul className='default_list'>
              <li>
                <Text preset='bodyRegular'>
                  {t('addMoney.pleaseNote.fees')}
                </Text>
              </li>
              <li>
                <Text preset='bodyRegular'>
                  {t('addMoney.pleaseNote.tradingHold')}
                </Text>
              </li>
              <li>
                <Text preset='bodyRegular'>
                  {t('addMoney.pleaseNote.withdrawHold')}
                </Text>
              </li>
              <li>
                <Text preset='bodyRegular'>
                  {t('addMoney.pleaseNote.mustSole')}
                </Text>
              </li>
              <li>
                <Text preset='bodyRegular'>
                  {t('addMoney.pleaseNote.willNotified')}
                </Text>
              </li>
            </ul>
          </div>
        </div>
      </CustomModal.Content>
      <CustomModal.Footer>
        <div className='w-100'>
          <Button
            text={'Understood'}
            onClick={props.onTransfer}
            buttonType='primary'
            textPreset='caption2'
          />
        </div>
      </CustomModal.Footer>
    </CustomModal>
  )
}
