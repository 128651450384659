import React, { useMemo } from 'react'
import { TabBarProps, TabItem } from './tabBar.props'
import './tabBar.styles.scss'
import { stringBuilder } from 'components/utils'
import { Text } from 'components/text/text'

function TabBarComponent({
  tabsStyle,
  tabs,
  activeTab,
  setActiveTab,
  loading = false,
  fullWidthTabs,
  extraMarginOnSides,
  align = 'start',
}: TabBarProps): JSX.Element {
  const handleClick = (id: string): void => {
    if (id && tabs?.length && !loading) {
      setActiveTab(id)
    }
  }

  const getItem = (item: TabItem): JSX.Element => {
    return (
      <button
        key={item.id}
        className={`tab-bar__tab ${fullWidthTabs ? 'w-100' : ''} ${
          activeTab === item.id ? 'tab-bar__tab--active' : ''
        }`}
        onClick={() =>
          item?.disabled ? item?.onDisabledTabClick() : handleClick(item.id)
        }
      >
        <Text preset='bodyRegular'>{item.title}</Text>
      </button>
    )
  }

  const classes = useMemo(() => {
    return stringBuilder([
      ['tab-bar', true],
      ['--align-start', align === 'start'],
      ['--align-center', align === 'center'],
      ['--align-end', align === 'end'],
    ])
  }, [align])

  const tabClasses = useMemo(() => {
    return stringBuilder([
      ['tab-bar__tabs', true],
      ['--with-extra-margin', extraMarginOnSides],
      [tabsStyle, !!tabsStyle],
      ['--full-width', fullWidthTabs],
    ])
  }, [extraMarginOnSides, tabsStyle, fullWidthTabs])

  return (
    <div className={classes}>
      <div className={tabClasses}>
        {tabs?.length ? tabs.map((t: TabItem) => getItem(t)) : null}
      </div>
    </div>
  )
}

export const TabBar = React.memo(TabBarComponent)
