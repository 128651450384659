import { ChevronRight, VectorDown, VectorUp } from '@assets/icons'
import { formatCurrency, formatNumber } from '@utils/helpers'
import { Spacer, Text } from 'components'
import HorizontalTab from 'components/horizontalTab/horizontalTab'
import { useTranslation } from 'hooks/useTranslation'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

export const PortfolioSummary = ({
  showRightArrow = false,
  showPnL,
  showLast24Label,
  hideAmount = false,
  portfolioTab = 'PORTFOLIO_VALUE',
  setPortfolioTab,
}: {
  showRightArrow?: boolean
  showPnL?: boolean
  showLast24Label?: boolean
  hideAmount?: boolean
  portfolioTab?: string
  setPortfolioTab?: (tab: string) => void
}): JSX.Element => {
  const { investments } = useSelector((state: RootState) => state)
  const { t } = useTranslation()
  const portfolioValue = 'portfolio.portfolioValue'

  const portfolioTabs = [
    { id: 'PORTFOLIO_VALUE', label: t(portfolioValue) },
    { id: 'PROFITS_N_LOSSES', label: t('portfolio.pnl') },
  ]
  const performanceIcon =
    investments?.totalPerformanceToday >= 0 ? (
      <VectorUp color='positive' size={14} />
    ) : (
      <VectorDown color='negative' size={14} />
    )

  const plusSymbolText = investments?.totalEarnings?.amount >= 0 ? '+' : ''
  const performanceAmountText =
    plusSymbolText + formatCurrency(investments?.totalEarnings)

  return (
    <div>
      {showPnL ? (
        <HorizontalTab
          selectedTabId={portfolioTab}
          availableTabs={portfolioTabs}
          onTabChange={(e) => {
            setPortfolioTab && setPortfolioTab(e)
          }}
        />
      ) : (
        <Text preset='subtitle2' text={t(portfolioValue)} color='secondary' />
      )}
      <Spacer preset='smallerPlus' />
      <div className='d-flex align-center'>
        <Text
          preset='hero'
          text={
            portfolioTab === 'PORTFOLIO_VALUE'
              ? formatCurrency(investments?.currentValue)
              : performanceAmountText
          }
          hiddenText={hideAmount}
        />
        {showRightArrow && (
          <>
            <Spacer preset='tinyPlus' />
            <ChevronRight />
          </>
        )}
      </div>
      <Spacer preset='tinyMinus' />
      <div className='d-flex align-center'>
        {performanceIcon}
        <Text
          preset='subtitle1'
          color={
            investments?.totalPerformanceToday >= 0 ? 'positive' : 'negative'
          }
          text={`${formatNumber(investments?.totalPerformanceToday)}% (${
            investments?.totalPerformanceToday >= 0 ? '+' : ''
          }${formatCurrency({
            amount: investments?.totalEarningsToday?.amount,
            currency: investments?.totalEarningsToday?.currency,
          })})`}
        />
        <Spacer preset='tinyPlus' />
        {showLast24Label && (
          <Text preset='paragraph2' text={t('common.24h')} color='tertiary' />
        )}
      </div>
      <Spacer preset='tinyMinus' />
      {showPnL && (
        <div className='d-flex'>
          <Text
            preset='paragraph2'
            text={
              portfolioTab !== 'PORTFOLIO_VALUE'
                ? formatCurrency(investments?.currentValue)
                : performanceAmountText
            }
          />
          <Spacer preset='tinyPlus' />
          <Text
            preset='paragraph2'
            text={
              portfolioTab !== 'PROFITS_N_LOSSES'
                ? t('portfolio.unrealizedProfitLoss')
                : t(portfolioValue)
            }
            color='tertiary'
          />
        </div>
      )}
    </div>
  )
}
