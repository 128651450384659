import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { getDocument, sendDocument } from '@services'

export const cleanSentPdf = createAction('cleanSentPdf')
export const cleanPdf = createAction('cleanPdf')

export const fetchDocument = createAsyncThunk<string, string>(
  'fetchDocument',
  (documentId: string) => getDocument(documentId),
)

export const sendDocumentByEmail = createAsyncThunk<void, string>(
  'sendDocumentByEmail',
  (documentId: string) => sendDocument(documentId),
)
