import { BannerItem } from './bannerItem'
import { ScrollingCarousel } from '@trendyol-js/react-carousel'
import { ReactComponent as RightIcon } from '@assets/svg/next.svg'
import { ReactComponent as LeftIcon } from '@assets/svg/back.svg'
import './bannerSlider.styles.scss'
import { BannersSliderProps } from './bannerSlider.props'

export function BannerSlider({ banners }: BannersSliderProps): JSX.Element {
  return (
    <ScrollingCarousel
      className={`carousel bannerSlider`}
      rightIcon={
        <RightIcon fill='var(--textNeutralStrong)' className='rightIcon' />
      }
      leftIcon={<LeftIcon fill='var(--textNeutralStrong)' />}
    >
      {banners.map((banner) => (
        <BannerItem key={banner.id} {...banner} />
      ))}
    </ScrollingCarousel>
  )
}
