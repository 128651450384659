const DollarSymbol = () => {
  return (
    <svg
      width='25'
      height='25'
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='0.290039'
        y='1.12988'
        width='23.5'
        height='23.5'
        rx='11.75'
        fill='#D09F14'
      />
      <rect
        x='0.290039'
        y='1.12988'
        width='23.5'
        height='23.5'
        rx='11.75'
        stroke='#595B67'
        strokeWidth='0.5'
      />
      <g clipPath='url(#clip0_12702_143844)'>
        <path
          d='M11.9063 12.1465C10.3929 11.7532 9.90626 11.3465 9.90626 10.7132C9.90626 9.98655 10.5796 9.47988 11.7063 9.47988C12.8929 9.47988 13.3329 10.0465 13.3729 10.8799H14.8463C14.7996 9.73322 14.0996 8.67988 12.7063 8.33988V6.87988H10.7063V8.31988C9.41293 8.59988 8.37293 9.43988 8.37293 10.7265C8.37293 12.2666 9.64626 13.0332 11.5063 13.4799C13.1729 13.8799 13.5063 14.4665 13.5063 15.0865C13.5063 15.5465 13.1796 16.2799 11.7063 16.2799C10.3329 16.2799 9.79293 15.6665 9.7196 14.8799H8.25293C8.33293 16.3399 9.42626 17.1599 10.7063 17.4332V18.8799H12.7063V17.4466C14.0063 17.1999 15.0396 16.4465 15.0396 15.0799C15.0396 13.1865 13.4196 12.5399 11.9063 12.1465Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_12702_143844'>
          <rect
            width='16'
            height='16'
            fill='white'
            transform='translate(4.04004 4.87988)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default DollarSymbol
