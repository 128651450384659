import { createReducer } from '@reduxjs/toolkit'
import { IInput, ManualBankTransferInfo, ReducerState } from '@interfaces'
import {
  addInputAmount,
  clearManualTransfer,
  manualTransferDeposit,
  manualTransferWithdraw,
} from './thunk'

const initialState: ReducerState & ManualBankTransferInfo & IInput = {
  loading: false,
  error: null,
  destinationIban: null,
  destinationName: null,
  destinationConcept: null,
  amount: null,
  withdrawalManualTransferEnded: false,
  inputAmount: 0,
}

const reducers = createReducer(initialState, (builder) => {
  builder
    // for add funds
    .addCase(manualTransferDeposit.pending, (acc) => {
      acc.loading = true
      acc.error = null
    })
    .addCase(manualTransferDeposit.fulfilled, (acc, { payload }) => {
      acc.loading = false
      acc.error = null
      acc.destinationIban = payload.destinationIban
      acc.destinationName = payload.destinationName
      acc.destinationConcept = payload.destinationConcept
      acc.amount = payload.amount
      acc.withdrawalManualTransferEnded = true
    })
    .addCase(manualTransferDeposit.rejected, (acc, { error }) => {
      acc.loading = false
      acc.error = error.message
      acc.withdrawalManualTransferEnded = false
    })
    .addCase(manualTransferWithdraw.pending, (acc) => {
      acc.loading = true
      acc.error = null
    })
    // For withdraw
    .addCase(manualTransferWithdraw.fulfilled, (acc, { payload }) => {
      acc.loading = false
      acc.error = null
      acc.withdrawalManualTransferEnded = true
      acc.destinationIban = payload.destinationIban
      acc.destinationName = payload.destinationName
      acc.destinationConcept = payload.destinationConcept
      acc.amount = payload.amount
      acc.withdrawalManualTransferEnded = true
    })
    .addCase(manualTransferWithdraw.rejected, (acc, { error }) => {
      acc.loading = false
      acc.error = error.message
    })
    .addCase(clearManualTransfer, (acc) => {
      acc.loading = false
      acc.error = null
      acc.destinationIban = null
      acc.destinationName = null
      acc.destinationConcept = null
      acc.amount = null
      acc.withdrawalManualTransferEnded = false
      acc.inputAmount = 0
    })
    .addCase(addInputAmount, (acc, { payload }) => {
      acc.inputAmount = payload
    })
})

export default reducers
