import './customTable.styles.scss'
import { Show } from 'components/show/show'
import { stringBuilder } from 'components/utils'
import React, { ReactNode, memo, useMemo } from 'react'

export type CustomRow = Array<{
  value: string | ReactNode
}>

export interface CustomColumn {
  value: string | ReactNode
}

export const TableHeader = ({
  children,
}: {
  children: Array<React.ReactElement<typeof TableHeaderCell>>
}): JSX.Element => {
  return <tr>{children}</tr>
}

export const TableRowComp = ({
  children,
  onClick,
}: {
  children: Array<React.ReactElement<typeof TableCell>>
  onClick?: () => void
}): JSX.Element => {
  return (
    <tr onClick={onClick} className={`${onClick && 'cursor-pointer'}`}>
      {children}
    </tr>
  )
}

export interface CustomTableProps {
  rows: Array<React.ReactElement<typeof TableRowComp>>
  header?: React.ReactElement<typeof TableHeader>
  loading: boolean
  preset?: 'table1' | 'table2'
}

export interface CommonTableCellProps {
  children: ReactNode
  flexDirection?: 'row' | 'column'
  wrapText?: boolean
  align?: 'left' | 'center' | 'right'
  items?: 'center' | 'start' | 'end'
  cellMinWidth?: string
  cellMaxWidth?: string
  onClick?: () => void
}

export const TableCommonCell = ({
  children,
  flexDirection = 'column',
  wrapText = true,
  align,
  items,
}: CommonTableCellProps): JSX.Element => {
  const contClasses = useMemo(() => {
    return stringBuilder([
      ['custom-table__cell', true],
      ['text-left', align === 'left'],
      ['text-center', align === 'center'],
      ['text-right', align === 'right'],
      ['align-center', items === 'center'],
      ['align-start', items === 'start'],
      ['align-end', items === 'end'],

      ['d-flex', true],
      ['nowrap', !wrapText],
      ['wrap', wrapText],
      ['d-flex-row', flexDirection === 'row'],
      ['d-flex-col', flexDirection === 'column'],
    ])
  }, [align, flexDirection, items, wrapText])

  return <div className={contClasses}>{children}</div>
}

export const TableHeaderCell = (props: CommonTableCellProps): JSX.Element => {
  return (
    <th onClick={props.onClick}>
      <TableCommonCell {...props} />
    </th>
  )
}

export const TableCell = (props: CommonTableCellProps): JSX.Element => {
  return (
    <td
      style={{
        minWidth: props?.cellMinWidth ? props?.cellMinWidth : 'auto',
        maxWidth: props?.cellMaxWidth ? props?.cellMaxWidth : 'auto',
      }}
    >
      <TableCommonCell {...props} />
    </td>
  )
}

export const CustomTable = ({
  loading,
  rows,
  header,
  preset = 'table1',
}: CustomTableProps): JSX.Element => {
  return (
    <Show when={!loading}>
      <table
        className={`custom-table --preset-${preset}`}
        aria-label='simple table'
      >
        <thead>{header}</thead>
        <tbody>{rows}</tbody>
      </table>
    </Show>
  )
}

CustomTable.Row = memo(TableRowComp)
CustomTable.Cell = TableCell
CustomTable.Header = TableHeader
CustomTable.HeaderCell = TableHeaderCell
