import { createReducer } from '@reduxjs/toolkit'
import { fetchBankAccount } from './thunk'
import { Account } from '@interfaces'

const initialState: {
  bankAccounts: Account[]
  loading: boolean
  error: string | null
} = {
  loading: false,
  error: null,
  bankAccounts: [],
}

export const reducers = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchBankAccount.pending, (acc) => {
      acc.loading = true
      acc.error = null
    })
    .addCase(fetchBankAccount.fulfilled, (acc, { payload }) => {
      acc.loading = false
      acc.error = null
      acc.bankAccounts = payload.accounts
    })
    .addCase(fetchBankAccount.rejected, (acc, { error }) => {
      acc.loading = false
      acc.error = error.message || ''
      acc.bankAccounts = []
    })
})

export default reducers
