import { MoneyOperationsSteps } from '@features'
import { MoneyOperationTypeEnum, OnboardingStatus } from '@interfaces'
import { Modal } from 'components'
import {
  setMoneyOperationStep,
  setOperationType,
} from 'features/moneyOperations/thunk'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { useTranslation } from './useTranslation'
import { trackingService } from 'services'
import { CashoutEvents, TopUpEvents } from '@utils/eventTracker/eventKeys'

export function useAddFundsAndCashout({
  origin,
}: {
  origin: 'Header' | 'Profile' | 'Home Portfolio'
}): {
  addFunds: () => void
  cashout: () => void
  modalOnboarding: JSX.Element | null
} {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { onboardingStatus } = useSelector((state: RootState) => state)
  const [modalOnboarding, setModalOnboarding] = useState(false)
  const onBoardingCompleted =
    onboardingStatus.onboarding?.onboardingStatus ==
    OnboardingStatus.onboardingCompleted

  const addFunds = (): void => {
    if (onBoardingCompleted) {
      trackingService.trackEvent({
        event: TopUpEvents.modalOpened,
        props: {
          origin,
        },
      })
      dispatch(setOperationType(MoneyOperationTypeEnum.addMoney))
      dispatch(setMoneyOperationStep(MoneyOperationsSteps.selectAmount))
    } else {
      setModalOnboarding(true)
    }
  }
  const cashout = (): void => {
    trackingService.trackEvent({
      event: CashoutEvents.modalOpened,
      props: {
        origin,
      },
    })
    dispatch(setOperationType(MoneyOperationTypeEnum.cashout))
    dispatch(setMoneyOperationStep(MoneyOperationsSteps.selectAmount))
  }

  return {
    addFunds,
    cashout,
    modalOnboarding: modalOnboarding ? (
      <Modal
        show={modalOnboarding}
        type='onboardingProfile'
        buttonText={t('common.ok')}
        onButton={() => setModalOnboarding(false)}
      />
    ) : null,
  }
}
