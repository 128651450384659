import { CloseIcon, PlusIcon } from '@assets/icons'
import { Button, CustomModal, Divider, Spacer, Text } from '@components'
import { formatCurrency } from '@utils/helpers'
import { useTranslation } from 'hooks/useTranslation'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { brokerTierConstants } from '../../../config'

export const BrokerTierGlobalPositionModal = ({
  close,
}: {
  close: () => void
}): JSX.Element => {
  const { t } = useTranslation()

  const { investments, userBalance, brokerTier } = useSelector(
    (state: RootState) => state,
  )

  return (
    <CustomModal
      size='big'
      height='fullHeight'
      show
      placeOn='right'
      dismissable
      onClose={close}
    >
      <CustomModal.Header noBorder text={t('common.globalPosition')}>
        <Button
          onClick={() => close()}
          buttonType='link'
          autoWidth
          content={<CloseIcon color='iconPrimaryInitial' size={16} />}
          shape='square'
        />
      </CustomModal.Header>
      <CustomModal.Content>
        <Text preset='paragraph2'>{t('brokerTier.yourGlobalPosition')}</Text>
        <Spacer preset='smaller' />
        <Text preset='paragraph2'>
          {t('brokerTier.youWillQualify', {
            minimumGlobalPosition: formatCurrency(
              brokerTierConstants.TPR.minimumGlobalPosition,
            ),
            minimumTrades: brokerTierConstants.TPR.minimumTradesPerMonth,
          })}
        </Text>
        <Spacer preset='smaller' />
        <Text preset='paragraph2'>{t('brokerTier.upgradesAndDowngrades')}</Text>
        <Spacer preset='smallPlus' />
        <Divider />
        <Spacer preset='small' />
        <div className='d-flex align-center'>
          <div>
            <Spacer preset='huge' />
          </div>
          <div className='w-70 d-flex-col'>
            <Text preset='subtitle3' text={t('common.availableBalance')} />
            <Spacer preset='extraTiny' />
            <Text
              preset='paragraph2'
              color='secondary'
              text={formatCurrency(userBalance?.cashAvailable)}
            />
          </div>
        </div>
        <Spacer preset='medium' />
        <div className='d-flex align-center'>
          <div>
            <PlusIcon color='tertiary' size={48} />
          </div>
          <Spacer preset='tinyPlus' />
          <div className='w-70 d-flex-col'>
            <Text preset='subtitle3' text={t('common.portfolioValue')} />
            <Spacer preset='extraTiny' />
            <Text
              preset='paragraph2'
              color='secondary'
              text={formatCurrency(investments?.currentValue)}
            />
          </div>
        </div>
        <Spacer preset='smallPlus' />
        <Divider />
        <Spacer preset='small' />
        <div className='d-flex align-center'>
          <div>
            <Spacer preset='huge' />
          </div>
          <div className='w-70 d-flex-col'>
            <Text preset='subtitle3' text={t('common.globalPosition')} />
            <Spacer preset='extraTiny' />
            <Text
              preset='paragraph2'
              color='secondary'
              text={formatCurrency(brokerTier?.totalPortfolio)}
            />
          </div>
        </div>
      </CustomModal.Content>
    </CustomModal>
  )
}
