import { Button, CustomModal, Spacer, Text } from '@components'
import { useTranslation } from '@hooks'
import { useMemo } from 'react'

export const InvalidOTPCode = ({
  close,
  onCase,
}: {
  onCase: 'login' | 'resetPassword'
  close: () => void
}): JSX.Element => {
  const { t } = useTranslation()

  const description = useMemo(() => {
    switch (onCase) {
      case 'login':
        return t('codeEnter.notValidCode')
      case 'resetPassword':
        return t('codeEnter.incorrectCodeDesc')
    }
  }, [onCase, t])

  return (
    <CustomModal show theme='lighter' size='small'>
      <CustomModal.Header text={t('codeEnter.incorrectCode')} />
      <CustomModal.Content>
        <Spacer preset='tiny' />
        <Text preset='paragraph1' text={description} align='center' />
        <Spacer preset='tiny' />
      </CustomModal.Content>
      <CustomModal.Footer>
        <Button text={t('common.ok')} onClick={close} buttonType='primary' />
      </CustomModal.Footer>
    </CustomModal>
  )
}
