import { UIIcon, UIIconColorProps, getIconColorSVGFill } from 'components/utils'

export const LockOpenIcon = ({
  color,
  size = 24,
}: UIIcon & UIIconColorProps): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18 9.12988H17V7.12988C17 4.36988 14.76 2.12988 12 2.12988C9.72 2.12988 7.73 3.66988 7.16 5.87988C7.02 6.41988 7.34 6.95988 7.88 7.09988C8.41 7.23988 8.96 6.91988 9.1 6.37988C9.44 5.05988 10.63 4.12988 12 4.12988C13.65 4.12988 15 5.47988 15 7.12988V9.12988H6C4.9 9.12988 4 10.0299 4 11.1299V21.1299C4 22.2299 4.9 23.1299 6 23.1299H18C19.1 23.1299 20 22.2299 20 21.1299V11.1299C20 10.0299 19.1 9.12988 18 9.12988ZM12 14.1299C10.9 14.1299 10 15.0299 10 16.1299C10 17.2299 10.9 18.1299 12 18.1299C13.1 18.1299 14 17.2299 14 16.1299C14 15.0299 13.1 14.1299 12 14.1299ZM17 21.1299C17.55 21.1299 18 20.6799 18 20.1299V12.1299C18 11.5799 17.55 11.1299 17 11.1299H7C6.45 11.1299 6 11.5799 6 12.1299V20.1299C6 20.6799 6.45 21.1299 7 21.1299H17Z'
        fill={color ? getIconColorSVGFill(color) : 'currentColor'}
      />
    </svg>
  )
}
