export enum AppLanguages {
  en = 'en',
  ka = 'ka',
  ru = 'ru',
}

export interface TranslationsResponse {
  errors: { message: string }[]
  translations: TranslationResponse
}

export interface TranslationResponse {
  [key: string]: string
}
