import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import {
  getAssetLiquidity,
  getCompanyCryptos,
  getCompanyStocks,
  getStockWatchlistState,
  setStockWatchlistState,
  unsetStockWatchlistState,
} from '@services'
import {
  AssetsInfo,
  CompanyAssetsBody,
  CompanyLiquidity,
  PeriodEnum,
} from '@interfaces'

export interface GraphPayload {
  currentIndex: number
  nextIndex: number
}
export const setPeriod = createAction<PeriodEnum>('setPeriod')

export const fetchCompanyStocks = createAsyncThunk<
  AssetsInfo,
  CompanyAssetsBody
>('fetchCompanyStocks', async ({ period = PeriodEnum.day, company }) => {
  return await getCompanyStocks(company, period)
})

export const updateCompanyStocks = createAsyncThunk<
  AssetsInfo,
  CompanyAssetsBody
>('updateCompanyStocks', ({ company, period }) =>
  getCompanyStocks(company, period),
)

export const fetchAssetLiquidity = createAsyncThunk<
  CompanyLiquidity,
  { ticker: string }
>('fetchAssetLiquidity', ({ ticker }) => getAssetLiquidity(ticker))

export const updateGraph = createAsyncThunk<AssetsInfo, CompanyAssetsBody>(
  'updateGraph',
  async ({ period, company }) => {
    return await getCompanyStocks(company, period)
  },
)

export const fetchCompanyCryptos = createAsyncThunk<
  AssetsInfo,
  CompanyAssetsBody
>('fetchCompanyCryptos', async ({ period = PeriodEnum.day, company }) => {
  return await getCompanyCryptos(company, period)
})

export const updateCompanyCryptos = createAsyncThunk<
  AssetsInfo,
  CompanyAssetsBody
>('updateCompanyCryptos', ({ company, period }) =>
  getCompanyCryptos(company, period),
)

export const updateCryptoGraph = createAsyncThunk<
  AssetsInfo,
  CompanyAssetsBody
>('updateCryptoGraph', async ({ period, company }) => {
  return await getCompanyCryptos(company, period)
})

export const fetchWatchlistStockState = createAsyncThunk<
  { uuid: string },
  string
>('fetchWatchlistStockState', async (uuid: string) => {
  return await getStockWatchlistState(uuid)
})

export const setWatchlistStockState = createAsyncThunk<void, string>(
  'setWatchlistStockState',
  async (uuid: string) => {
    return await setStockWatchlistState(uuid)
  },
)

export const unsetWatchlistStock = createAsyncThunk<void, string>(
  'unsetWatchlistStock',
  async (uuid: string) => {
    return await unsetStockWatchlistState(uuid)
  },
)

export const clearWatchlistState = createAction('clearWatchlistState')
