export const DedicatedSpaceIcon = (): JSX.Element => {
  return (
    <svg
      width='81'
      height='40'
      viewBox='0 0 81 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='0.5'
        width='80'
        height='40'
        rx='4'
        fill='var(--surfaceCanvasSecondaryInitial)'
      />
      <path
        d='M18.4986 27.3279V22.3279H22.4986V27.3279C22.4986 27.8779 22.9486 28.3279 23.4986 28.3279H26.4986C27.0486 28.3279 27.4986 27.8779 27.4986 27.3279V20.3279H29.1986C29.6586 20.3279 29.8786 19.7579 29.5286 19.4579L21.1686 11.9279C20.7886 11.5879 20.2086 11.5879 19.8286 11.9279L11.4686 19.4579C11.1286 19.7579 11.3386 20.3279 11.7986 20.3279H13.4986V27.3279C13.4986 27.8779 13.9486 28.3279 14.4986 28.3279H17.4986C18.0486 28.3279 18.4986 27.8779 18.4986 27.3279Z'
        stroke='#FF9B63'
        strokeWidth='2'
      />
      <line
        x1='41.5'
        y1='9'
        x2='41.5'
        y2='32'
        stroke='#FF9B63'
        strokeWidth='2'
      />
      <g clipPath='url(#clip0_4220_33563)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M70.4506 20.9999C69.9489 26.0308 65.6853 29.9601 60.5 29.9601C54.9772 29.9601 50.5 25.5027 50.5 20.0043C50.5 14.8419 54.4467 10.5972 59.5 10.0977V20.9999H70.4506ZM61.5 10.0977V19.0087H70.4506C69.9816 14.3052 66.2244 10.5647 61.5 10.0977Z'
          fill='#FF9B63'
        />
      </g>
      <defs>
        <clipPath id='clip0_4220_33563'>
          <rect
            width='24'
            height='23.8939'
            fill='white'
            transform='translate(48.5 8.10645)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}
