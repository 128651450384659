export interface CreditCardFee {
  id: string
  percentage: number
  type: string
}

export enum TopUpPaymentType {
  SINGLE = 'SINGLE',
  RECURRING = 'RECURRING',
}
