import { AnyAction, createReducer, PayloadAction } from '@reduxjs/toolkit'
import { CompanyInfo, ReducerState } from '@interfaces'
import { fetchCompanyInfo, fetchCryptoCompanyInfo } from './thunk'

const initialState: CompanyInfo & ReducerState = {
  data: [],
  loading: false,
  error: null,
}

const rejectedAction = (action: AnyAction): boolean =>
  action.type.includes(fetchCompanyInfo.rejected.toString()) ||
  action.type.includes(fetchCryptoCompanyInfo.rejected.toString())

export const reducers = createReducer(initialState, (builder) =>
  builder
    .addCase(fetchCompanyInfo.pending, (acc) => {
      acc.loading = true
      acc.error = null
    })
    .addCase(fetchCryptoCompanyInfo.pending, (acc) => {
      acc.loading = true
      acc.error = null
    })
    .addCase(
      fetchCompanyInfo.fulfilled,
      (acc, { payload }: PayloadAction<CompanyInfo>) => {
        acc.data = payload.data
        acc.loading = false
      },
    )
    .addCase(
      fetchCryptoCompanyInfo.fulfilled,
      (acc, { payload }: PayloadAction<CompanyInfo>) => {
        acc.data = payload.data
        acc.loading = false
      },
    )
    .addMatcher(rejectedAction, () => initialState),
)

export default reducers
