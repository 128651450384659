import { Header } from '@components'
import { Outlet } from 'react-router-dom'

export function AuthenticatedLayout(): JSX.Element {
  return (
    <main>
      <Header />
      <div className='container'>
        <Outlet />
      </div>
    </main>
  )
}
