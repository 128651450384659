import { SomethingWentWrongIllustration } from '@assets/illustrations'
import { Button, Spacer, Text } from 'components'
import { useTranslation } from 'hooks/useTranslation'
import './loadingErrorScreen.styles.scss'

export const LoadingErrorScreen = ({
  onRetry,
}: {
  onRetry: () => void
}): JSX.Element => {
  const { t } = useTranslation()
  return (
    <div className='loading-error-screen --fade-in-animation d-flex d-flex-col align-center justify-center'>
      <Spacer preset='huge' />
      <Spacer preset='huge' />
      <SomethingWentWrongIllustration />
      <Spacer preset='huge' />
      <Text
        text={t('common.somethingWentWrong')}
        preset='headline2'
        align='center'
      />
      <Spacer preset='small' />
      <Text
        text={t('common.checkInternetConnection')}
        preset='paragraph2'
        align='center'
      />
      <Spacer preset='medium' />
      <Button text={t('common.tryAgain')} onClick={onRetry} />
      <Spacer preset='medium' />
      <Text
        text={t('common.contactSupportIfTheProblem')}
        preset='paragraph2'
        align='center'
        color='iconPrimaryInitial'
      />
    </div>
  )
}
