import { SearchAssetsState } from '@interfaces'
import { api } from '@utils/api/api'
import { buildQueryParams } from '@utils/helpers'

export const multiAssetsSearch = (
  query: string,
): Promise<SearchAssetsState> => {
  const params = buildQueryParams({
    filter: query,
  })

  return api.get(`/api/v1/mobile/assets/discover${params}`)
}
