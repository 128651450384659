import { ErrorBlockProps } from './errorBlock.props'
import { useTranslation } from 'hooks/useTranslation'
import { Text } from 'components/text/text'
import { Spacer } from 'components/spacer'
import { Button } from 'components/button/button'
import { ReloadIcon } from '@assets/icons'

export function ErrorBlock({ onReload }: ErrorBlockProps): JSX.Element {
  const { t } = useTranslation()
  return (
    <div className='d-flex d-flex-col justify-center align-center'>
      <Spacer preset='enormous' />
      <Button
        autoWidth
        buttonType='link'
        shape='square'
        onClick={onReload}
        left={<ReloadIcon size={52} color='iconPrimaryInitial' />}
      />
      <Spacer preset='small' />
      <Text
        text={t('common.somethingWentWrong')}
        preset='headline2'
        align='center'
      />
      <Spacer preset='smaller' />
      <div className='w-70'>
        <Text
          text={t('common.checkInternetConnection')}
          preset='paragraph2'
          align='center'
          color='secondary'
        />
      </div>

      <Spacer preset='enormous' />
    </div>
  )
}
