import {
  UIIcon,
  UIIconColorProps,
  UISecondaryColorProp,
  getIconColorSVGFill,
} from 'components/utils'

export const GlobalSearchIcon = ({
  color,
  size = 24,
}: UIIcon & UIIconColorProps & UISecondaryColorProp): JSX.Element => {
  return (
    <svg width={size} height={size} viewBox='0 0 25 24' fill='none'>
      <path
        d='M12.2334 22C10.8501 22 9.55007 21.7375 8.3334 21.2125C7.11673 20.6875 6.0584 19.975 5.1584 19.075C4.2584 18.175 3.5459 17.1167 3.0209 15.9C2.4959 14.6833 2.2334 13.3833 2.2334 12C2.2334 10.6167 2.4959 9.31667 3.0209 8.1C3.5459 6.88333 4.2584 5.825 5.1584 4.925C6.0584 4.025 7.11673 3.3125 8.3334 2.7875C9.55007 2.2625 10.8501 2 12.2334 2C14.6667 2 16.7959 2.7625 18.6209 4.2875C20.4459 5.8125 21.5834 7.725 22.0334 10.025H19.9834C19.6667 8.80833 19.0959 7.72083 18.2709 6.7625C17.4459 5.80417 16.4334 5.08333 15.2334 4.6V5C15.2334 5.55 15.0376 6.02083 14.6459 6.4125C14.2542 6.80417 13.7834 7 13.2334 7H11.2334V9C11.2334 9.28333 11.1376 9.52083 10.9459 9.7125C10.7542 9.90417 10.5167 10 10.2334 10H8.2334V12H10.2334V15H9.2334L4.4334 10.2C4.3834 10.5 4.33757 10.8 4.2959 11.1C4.25423 11.4 4.2334 11.7 4.2334 12C4.2334 14.1833 5.00007 16.0583 6.5334 17.625C8.06673 19.1917 9.96673 19.9833 12.2334 20V22ZM21.3334 21.5L18.1334 18.3C17.7834 18.5 17.4084 18.6667 17.0084 18.8C16.6084 18.9333 16.1834 19 15.7334 19C14.4834 19 13.4209 18.5625 12.5459 17.6875C11.6709 16.8125 11.2334 15.75 11.2334 14.5C11.2334 13.25 11.6709 12.1875 12.5459 11.3125C13.4209 10.4375 14.4834 10 15.7334 10C16.9834 10 18.0459 10.4375 18.9209 11.3125C19.7959 12.1875 20.2334 13.25 20.2334 14.5C20.2334 14.95 20.1667 15.375 20.0334 15.775C19.9001 16.175 19.7334 16.55 19.5334 16.9L22.7334 20.1L21.3334 21.5ZM15.7334 17C16.4334 17 17.0251 16.7583 17.5084 16.275C17.9917 15.7917 18.2334 15.2 18.2334 14.5C18.2334 13.8 17.9917 13.2083 17.5084 12.725C17.0251 12.2417 16.4334 12 15.7334 12C15.0334 12 14.4417 12.2417 13.9584 12.725C13.4751 13.2083 13.2334 13.8 13.2334 14.5C13.2334 15.2 13.4751 15.7917 13.9584 16.275C14.4417 16.7583 15.0334 17 15.7334 17Z'
        fill={color ? getIconColorSVGFill(color) : 'currentColor'}
      />
    </svg>
  )
}
