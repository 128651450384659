import {
  CreditCardFee,
  CreditCardPaymentData,
  CreditCardPaymentInfo,
  CreditCardScheme,
  StoredCreditCardPaymentData,
} from '@interfaces'
import { api } from '@utils/api/api'

export const sendCreditCardPayment = (
  data: CreditCardPaymentData,
): Promise<CreditCardPaymentInfo> => {
  return api.post('/api/v1/card-payments/payments', data)
}

export const sendStoredCreditCardPayment = (
  data: StoredCreditCardPaymentData,
): Promise<CreditCardPaymentInfo> => {
  return api.post('/api/v1/card-payments/payments', data)
}

export const getValidCreditCards = (): Promise<CreditCardScheme[]> => {
  return api.get('/api/v1/card-payments/schemes')
}

export const getCreditCardFees = (): Promise<CreditCardFee[]> => {
  return api.get('/api/v1/card-payments/fees')
}

export const deleteCreditCard = (creditCardId: string): Promise<void> => {
  return api.delete(`/api/v1/card-payments/cards/${creditCardId}`)
}
