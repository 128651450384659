import { SendUserChallenge, TTL } from '@interfaces'
import { api } from '@utils/api/api'

function getChallengeParams({
  challengeType,
  contractId,
}: SendUserChallenge): string {
  let params = `challengeType=${challengeType as string}`
  if (contractId) {
    const contractParam = `&contractId=${contractId}`
    params += contractParam
  }
  return params
}

export const getVerificationCode = (
  userChallenge: SendUserChallenge,
): Promise<TTL> => {
  const params = getChallengeParams(userChallenge)
  return api.patch(`/api/v1/customers/me/challenge-verification/send?${params}`)
}

export const sendResetMailCode = (verificationCode: string): Promise<void> => {
  return api.post(
    '/api/v1/customers/me/challenge-verification/reset-password/verify',
    { verificationCode },
    true,
  )
}

export const sendVerificationCode = (
  verificationCode: string,
  userChallenge: SendUserChallenge,
): Promise<void> => {
  const params = getChallengeParams(userChallenge)
  return api.post(
    `/api/v1/customers/me/challenge-verification/verify?${params}`,
    {
      verificationCode,
    },
  )
}
