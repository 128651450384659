import './sideMenu.styles.scss'
import { useState } from 'react'
import { useNavigate } from '@hooks'
import { useMenuOpenStyles, useMenuOptions } from './sideMenu.hooks'
import { ReactComponent as Exit } from '@assets/svg/exit.svg'
import { useTranslation } from '@hooks'
import { useDispatch } from 'react-redux'
import { doLogout } from '@screens/login/thunk'
import { resetStoreState } from '@store/commonReducers/resetStoreState/thunk'
import { trackingService } from '@services'
import { NavigationEvents } from '@utils/eventTracker/eventKeys'
import { LogoutWarningModal } from '@shared/modals'

export function SideMenu(): JSX.Element {
  const dispatch = useDispatch()

  const [menuOpen, setMenuOpen] = useState(false)
  const [logoutModal, setLogoutModal] = useState(false)

  const { navigate } = useNavigate()

  const { t } = useTranslation()

  const { lineStyle, navbarStyle, textStyle } = useMenuOpenStyles(menuOpen)

  const menuOptions = useMenuOptions()

  const logOut = (): void => {
    dispatch(doLogout())
    dispatch(resetStoreState())
  }

  const menuItemHandler = (_: string, navigateTo: string): void => {
    trackingService.trackEvent({
      event: NavigationEvents.menuItemClicked,
      props: {
        origin: window.location.pathname,
        target: navigateTo,
      },
    })
    navigate(navigateTo)
  }

  return (
    <div className='menu'>
      <div
        className='menuButton cursor-pointer'
        aria-hidden
        onClick={() => setMenuOpen(!menuOpen)}
      >
        <div className='menuButton__container'>
          <div className={`menuButton__mainLine ${lineStyle}`}></div>
        </div>
      </div>
      <nav>
        {menuOpen && (
          <div className='navbar__overlay navbar__overlay--active --fade-in-animation-fast'></div>
        )}
        <ul className={navbarStyle}>
          {menuOptions.map(({ logo, name, navigateTo, optionStyle }) => {
            return (
              <li key={`menu_option_${name}`} className={optionStyle}>
                <button
                  id={`menu_option_${name}`}
                  className='navbar__link'
                  onClick={() => menuItemHandler(name, navigateTo)}
                >
                  {logo}
                  <span className={textStyle}>{name}</span>
                </button>
              </li>
            )
          })}
          <li className='navbar__option'>
            <a
              href='/'
              onClick={(e) => {
                e.preventDefault()
                setLogoutModal(true)
              }}
              className='navbar__link logout-link'
            >
              <Exit className='navbar__logo logout--logo' />
              <span className={textStyle}>{t('profile.logout')}</span>
            </a>
          </li>
        </ul>
      </nav>
      {logoutModal && (
        <LogoutWarningModal
          doLogOut={logOut}
          close={() => setLogoutModal(false)}
        />
      )}
    </div>
  )
}
