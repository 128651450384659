import '@screens/login/login.styles.scss'
import '@screens/signup/signup.styles.scss'
import '@screens/resetPassword/resetPassword.styles.scss'
import { ReactComponent as BackIcon } from '@assets/svg/backBrowser.svg'
import { ReactComponent as Spinner } from '@assets/svg/spinner.svg'
import { useTranslation } from '@hooks'
import { VerificationWidgetProps } from './verificationWidget.props'
import { BasicLink, CodeInput } from '@components'
import { useEffect, useState } from 'react'
import { formatSeconds } from '@utils/helpers'

export default function VerificationWidget({
  receiver,
  title,
  expiration,
  loading,
  onSubmit,
  onResend,
  onGoBack,
  onCancel,
}: VerificationWidgetProps): JSX.Element {
  const { t } = useTranslation()

  const [remainingTime, setRemainingTime] = useState<number>()

  useEffect(() => {
    const computeTimer = (): void => {
      const elapsedTime = (Date.now() - expiration?.petitionDate) / 1000
      setRemainingTime(expiration?.seconds - elapsedTime)
    }
    computeTimer()
    const interval = setInterval(() => {
      computeTimer()
    }, 500) // two updates per second to ensure fluidity
    return () => clearInterval(interval)
  }, [expiration])

  const hasExpired = remainingTime ? remainingTime <= 0 : false

  return (
    <div className='login__widget'>
      <div className='login__widget__header pa-3 d-flex align-center justify-space-between'>
        <div className='w-20 text-left d-flex align-center'>
          {onGoBack && (
            <BackIcon className='cursor-pointer' onClick={onGoBack} />
          )}
        </div>
        <span className='login__widget__header__title'>{title}</span>
        <div className='w-20 text-right'>
          {onCancel && (
            <BasicLink
              text={t('common.cancel')}
              onClick={onCancel}
              type='link'
            />
          )}
        </div>
      </div>

      <div className='login__widget__content'>
        <div className='login__widget__content__elements px-3 pt-5'>
          <h2 className='login__widget__content__title mb-1'>
            {t('codeEnter.code')}
          </h2>
          <p className='secondary--text'>
            {t('codeEnter.enterCode')}
            <span className='orange--text'>{receiver}</span>
          </p>

          <div className='login__widget__verification'>
            <CodeInput length={6} onSubmit={onSubmit} disabled={loading} />
            <div className='login__widget__verification__loader'>
              {loading && <Spinner className={'spinner spinner--orange'} />}
            </div>
          </div>
        </div>
      </div>

      <div className='login__widget__footer pa-3'>
        {hasExpired ? (
          <BasicLink
            text={t('codeEnter.requestNewCode')}
            onClick={onResend}
            type='link'
            disabled={loading}
          />
        ) : (
          <span className='secondary--text mr-1'>
            {t('codeEnter.codeExpires', {
              time: remainingTime ? formatSeconds(remainingTime) : '-:--',
            })}
          </span>
        )}
      </div>
    </div>
  )
}
