import { RootState } from '@store'
import { routes } from 'navigation'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

export function OnlyNonAuthSession({
  children,
}: {
  children: JSX.Element
}): JSX.Element {
  const auth = useSelector((state: RootState) => state.login)

  // This type of routes (login, signup , reset password) should not be accessed while a session is active
  if (auth.token) return <Navigate to={routes.auth.home} />

  return children
}
