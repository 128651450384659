import { CheckIcon, LockClosedIcon } from '@assets/icons'

import { Card, Spacer, Text } from '@components'
import { useTranslation } from '@hooks'
import { AmericaAndWorldFeesProps } from '../../brokerTierInfoModal.type'
import { Fragment } from 'react'

type IFeePerShare = {
  isPro?: boolean
  title: string
  logo: JSX.Element
  feesList: AmericaAndWorldFeesProps[]
}

export const FeePerShare = ({
  isPro,
  logo,
  title,
  feesList,
}: IFeePerShare): JSX.Element => {
  const { t } = useTranslation()

  return (
    <>
      <div className='d-flex align-center w-100'>
        {logo}
        <Spacer preset='smaller' />
        <div className='w-80'>
          <Text text={t(title)} preset='caption2' />
        </div>
      </div>

      {feesList.map((item, index) => (
        <Fragment key={index}>
          <Spacer preset='small' />
          <div className='d-flex-col'>
            <div className='d-flex justify-start'>
              <Text text={t(item.tx1)} preset='bodyRegular' color='primary' />
            </div>
            <Spacer preset='smaller' />
            <div className='d-flex'>
              <Card width='full' padding='small'>
                <Card
                  padding='extraTiny'
                  bordered
                  width='full'
                  bgColor='primaryBase'
                  borderColor={!isPro ? 'statusPositive' : 'tertiaryBase'}
                >
                  <div className='d-flex w-100 justify-center align-center'>
                    {isPro ? (
                      <LockClosedIcon color='tertiary' />
                    ) : (
                      <CheckIcon color='iconPositiveInitial' />
                    )}
                    <Spacer preset='tiny' />
                    <Text
                      text='Basic'
                      preset='paragraph2'
                      color={isPro ? 'tertiary' : 'positive'}
                    />
                  </div>
                </Card>
                <Spacer preset='small' />
                <Text preset='caption2' text={t(item.tx2)} />

                <Text preset='bodyRegular' text={t(item.tx3)} />
              </Card>
              <Spacer preset='tiny' />
              <Card width='full' padding='small'>
                <Card
                  padding='extraTiny'
                  bordered
                  width='full'
                  borderColor={isPro ? 'statusPositive' : 'tertiaryBase'}
                  bgColor='primaryBase'
                >
                  <div className='d-flex w-100 justify-center align-center'>
                    {!isPro ? (
                      <LockClosedIcon color='tertiary' />
                    ) : (
                      <CheckIcon color='iconPositiveInitial' />
                    )}
                    <Spacer preset='tiny' />{' '}
                    <Text
                      text='Pro'
                      preset='paragraph2'
                      color={!isPro ? 'tertiary' : 'positive'}
                    />
                  </div>
                </Card>
                <Spacer preset='small' />
                <Text preset='caption2' text={t(item.tx4)} />

                <Text preset='bodyRegular' text={t(item.tx5)} />
              </Card>
            </div>
          </div>
        </Fragment>
      ))}
    </>
  )
}
