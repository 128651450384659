import { Avatar, Spacer, Text, TextField } from '@components'
import { ChangeEvent, useEffect } from 'react'
import { formatCurrency, formatMoney, sanitizeNumber } from '@utils/helpers'
import { InfoIcon, MarketOrderIcon } from '@assets/icons'
import { OperationCardBase } from '../operationCardBase'
import { RootState, setMarketOrderDetails } from '@store'
import { UseFormReturn, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from '@hooks'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { OrderOperationTypeEnum } from '@interfaces'
import { formatInputNumber } from '@utils/formatting'
import { getFormatLang } from '@utils/langs'

export const MarketOrderCard = ({
  loading,
  showAvailableInfoModal,
  focusOnInit,
  disabled,
}: {
  loading: boolean
  showAvailableInfoModal: () => void
  focusOnInit: boolean
  disabled?: boolean
}): JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const lang = getFormatLang()

  const { companyStocks, userBalance, companyPosition, buySell } = useSelector(
    (state: RootState) => state,
  )

  const cashAvailable = formatMoney(
    userBalance?.cashAvailable?.amount,
    userBalance?.cashAvailable?.currency,
  )

  const translateReq = t('common.fieldRequired')

  const schemaReset = yup.object().shape({
    shares: yup.string().required(translateReq),
  })

  const orderOperationType = buySell?.orderUserInput?.orderOperationType

  const isBuy = orderOperationType === OrderOperationTypeEnum.buy

  const marketOperationForm: UseFormReturn = useForm({
    resolver: yupResolver(schemaReset),
    criteriaMode: 'all',
    mode: 'onChange',
  })

  const onChangeStocksForm = async (
    e: ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    const sanitized = sanitizeNumber(e?.target?.value)
    const validValue = sanitized && !isNaN(Number(sanitized))
    const value = formatInputNumber(
      parseInt(sanitized?.toString())?.toString(),
      lang,
      0,
    )

    marketOperationForm.setValue('shares', validValue ? value : 0)

    dispatch(
      setMarketOrderDetails({
        shares: sanitized,
      }),
    )

    await marketOperationForm.trigger('shares')
  }

  // When market order details is reset
  // Reset form value
  useEffect(() => {
    if (!buySell.orderUserInput.marketOrder.shares) {
      marketOperationForm.setValue('shares', null)
    }
  }, [buySell.orderUserInput.marketOrder.shares, marketOperationForm])

  const maxShares = companyPosition?.shares - companyPosition?.lockedShares

  useEffect(() => {
    if (focusOnInit) {
      const el: HTMLInputElement = document.querySelector(`[name="shares"]`)
      el?.focus()
    }
  }, [focusOnInit])

  return (
    <OperationCardBase
      image={
        <Avatar
          roundness='full'
          size='mediumPlus'
          bgColor='primaryBase'
          image={<MarketOrderIcon color='actionPrimaryInitial' />}
        />
      }
      title={t('buySellFlow.limitOrders.market')}
      subtitle={t('buySellFlow.limitOrders.buyMarketDesc')}
      form={
        <>
          <Spacer preset='tiny' />
          <div className='d-flex align-center'>
            <div className='w-55'>
              <Text
                preset='paragraph2'
                textStyle='uppercase'
                text={t('common.amount')}
              />
              {!isBuy && (
                <Text
                  preset='paragraph3'
                  text={`${t('buySellFlow.summary.available')} ${maxShares} ${
                    companyStocks?.symbol
                  }`}
                  color='tertiary'
                />
              )}
            </div>
            <div className='d-flex w-45'>
              <TextField
                style='text-right bigText'
                right={companyStocks.symbol}
                theme='subtle'
                size='small'
                register={marketOperationForm.register}
                inputProps={{
                  name: 'shares',
                  placeholder: '0',
                  disabled: loading || disabled,
                  onChange: onChangeStocksForm,
                  value: marketOperationForm?.getValues()?.shares,
                }}
              />
            </div>
          </div>
          <Spacer preset='small' />
          <div className='d-flex align-center'>
            <div className='w-55'>
              <Text
                preset='paragraph2'
                textStyle='uppercase'
                text={t('common.marketPrice')}
              />
            </div>
            <div className='d-flex w-45 justify-end align-end'>
              <Spacer preset='tiny' />
              <Text
                preset='bigText'
                align='right'
                text={formatCurrency(companyStocks?.price)}
              />
            </div>
          </div>
          <Spacer preset='small' />
          <div className='d-flex align-center'>
            <div className='w-55'>
              <Text
                preset='paragraph2'
                textStyle='uppercase'
                text={t('common.estimatedTotal')}
              />
              <div className='d-flex'>
                <div
                  className='cursor-pointer d-flex align-center'
                  onClick={() => showAvailableInfoModal()}
                  aria-hidden
                >
                  <InfoIcon size={16} color='tertiary' />
                </div>
                <Spacer preset='tiny' />
                <Text
                  preset='paragraph3'
                  text={`${t(
                    'buySellFlow.summary.available',
                  )} ${cashAvailable}`}
                  color='tertiary'
                />
              </div>
            </div>
            <div className='d-flex w-45 justify-end'>
              <Text
                preset='bigText'
                align='right'
                text={formatCurrency({
                  amount:
                    (buySell?.orderUserInput?.marketOrder?.shares || 0) *
                    companyStocks?.price?.amount,
                  currency: companyStocks?.price.currency,
                })}
              />
            </div>
          </div>
          <Spacer preset='tiny' />
        </>
      }
    />
  )
}
