import {
  CreditCardPaymentInfo,
  RecurringPayment,
  RecurringPaymentData,
} from '@interfaces'
import { api } from '@utils/api/api'

export const sendRecurringPayment = (
  data: RecurringPaymentData,
): Promise<CreditCardPaymentInfo> => {
  return api.post('/api/v1/card-payments/recurring-payments', data)
}

export const getRecurringPayments = (): Promise<RecurringPayment[]> => {
  return api.get('/api/v1/card-payments/recurring-payments')
}

export const getRecurringPayment = (id: string): Promise<RecurringPayment> => {
  return api.get(`/api/v1/card-payments/recurring-payments/${id}`)
}

export const deleteRecurringPayment = (id: string): Promise<void> => {
  return api.delete(`/api/v1/card-payments/recurring-payments/${id}`)
}
