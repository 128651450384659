import { Button } from 'components/button/button'
import { BasicLink } from '../basicLink/basicLink'
import './customModal.styles.scss'

export interface ModalHeaderProps {
  text?: string | React.ReactNode
  linkText?: string
  className?: string
  children?: React.ReactNode
  onAction?: () => void
  onClick?: () => void
  noBorder?: boolean
}

export function ModalHeader({
  text,
  linkText,
  className,
  children,
  onAction,
  onClick,
  noBorder,
}: ModalHeaderProps): JSX.Element {
  const modalHeaderStyle = `modalHeader__content ${className} ${noBorder ? '--no-border' : ''
    }`

  return (
    <div className={modalHeaderStyle}>
      <div className={`modalHeader__left w-20`}>
        <BasicLink
          onClick={onAction}
          className='w-20 text-left d-flex align-center'
        >
          {children}
        </BasicLink>
      </div>
      <div className='modalHeader__middle d-flex justify-center'>
        {typeof text === 'string' ? (
          <span className=' dark--label-title w-100'>{text}</span>
        ) : (
          text
        )}
      </div>
      <div className={`modalHeader__right w-20`}>
        {onClick && (
          <Button
            buttonType='link'
            text={linkText}
            textPreset='paragraph2'
            onClick={onClick}
          />
        )}
      </div>
    </div>
  )
}
