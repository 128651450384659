import { useTranslation } from '@hooks'
import {
  CustomModal,
  Button,
  GoBack,
  Card,
  Spacer,
  Text,
  Divider,
} from '@components'
import { useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import { RootState } from 'store'
import { ImportantIcon } from '@assets/icons'

interface CashoutTransferInstructionsProps {
  nextStep: () => void
  prevStep: () => void
  onClose?: () => void
}

export function CashoutInstructions(): JSX.Element {
  const { t } = useTranslation()
  return (
    <Card>
      <div className='d-flex d-flex-row align-center'>
        <ImportantIcon color='tertiary' />
        <Spacer preset='tiny' />
        <Text
          text={t('bankTransfer.cashout.followSteps')}
          preset='paragraph2'
          color='tertiary'
        />
      </div>
      <Spacer preset='smaller' />
      <Divider />
      <Spacer preset='smaller' />
      <Text
        text={t('bankTransfer.cashout.tapOn')}
        preset='paragraph2'
        color='tertiary'
      />
      <Spacer preset='smaller' />
      <Divider />
      <Spacer preset='smaller' />
      <Text
        text={t('bankTransfer.cashout.enterIban')}
        preset='paragraph2'
        color='tertiary'
      >
        <span className='text-decoration-underline'>
          {t('bankTransfer.cashout.enterIbanBold')}
        </span>
      </Text>
      <Spacer preset='smaller' />
      <Divider />
      <Spacer preset='smaller' />
      <p className='--text-tertiary text-left paragraph2'>
        <span>
          <span className='text-decoration-underline d-inline  .text-left'>
            {t('bankTransfer.cashout.mustBeHolderBold')}
          </span>
          {t('bankTransfer.cashout.mustBeHolder')}
        </span>
      </p>
      <Spacer preset='smaller' />
      <Divider />
      <Spacer preset='smaller' />
      <Text
        text={t('bankTransfer.cashout.mustBeSpain')}
        preset='paragraph2'
        color='tertiary'
      />
      <Spacer preset='smaller' />
      <Divider />
      <Spacer preset='smaller' />
      <Text
        text={t('bankTransfer.cashout.customerContact')}
        preset='paragraph2'
        color='tertiary'
      />
    </Card>
  )
}

export function CashoutTransferInstructions({
  nextStep,
  prevStep,
  onClose,
}: CashoutTransferInstructionsProps): JSX.Element {
  const { t } = useTranslation()
  const cancelActions = (): void => {
    prevStep()
  }

  const { bankTransfer } = useSelector((state: RootState) => state)

  const onConfirmation = (): void => {
    nextStep()
  }

  const canGoNextStep = useCallback((): boolean => {
    if (bankTransfer.loading || !bankTransfer.destinationIban) {
      return false
    }

    return true
  }, [bankTransfer.destinationIban, bankTransfer.loading])

  useEffect(() => {
    if (canGoNextStep()) {
      nextStep()
    }
  }, [canGoNextStep, nextStep])

  const texts = {
    button: t('bankTransfer.cashout.sendEmail'),
    header: t('cashout.title'),
    text1: t('bankTransfer.cashout.title'),
    text2: t('bankTransfer.cashout.subtitle'),
  }

  return (
    <>
      <CustomModal.Header
        text={texts.header}
        linkText={t('common.cancel')}
        className='subtitle3 dark--label-title'
        onClick={onClose}
      >
        <GoBack goBack={cancelActions} />
      </CustomModal.Header>
      <CustomModal.Content className='d-flex d-flex-col'>
        <Text text={texts.text1} preset='headline1' />
        <Text text={texts.text2} preset='paragraph2' color='tertiary' />
        <div>
          <Spacer preset='smaller' />
        </div>
        <CashoutInstructions />
      </CustomModal.Content>
      <CustomModal.Footer className='d-flex d-flex-col align-center d-flex-col justify-center'>
        <Button
          text={texts.button}
          type='submit'
          size='big'
          disabled={bankTransfer.loading}
          loading={bankTransfer.loading}
          onClick={onConfirmation}
        />
      </CustomModal.Footer>
    </>
  )
}
