import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import { UserProfileInfo, UserProfileState } from '@interfaces'
import { fetchUserProfile } from './thunk'

const initialState: UserProfileState = {
  userProfile: {
    customerId: '',
    portfolioId: '',
    fullName: '',
    name: '',
    phoneNumber: '',
    email: '',
    balance: {
      amount: 0.0,
      currency: 'USD',
    },
    cashAvailable: {
      amount: 0.0,
      currency: 'USD',
    },
    cashActive: {
      amount: 0.0,
      currency: 'USD',
    },
  },
  loading: false,
  error: null,
}

export const reducers = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchUserProfile.pending, (acc: UserProfileState) => {
      acc.loading = true
    })
    .addCase(
      fetchUserProfile.fulfilled,
      (acc: UserProfileState, action: PayloadAction<UserProfileInfo>) => {
        const { payload } = action
        acc.loading = false
        acc.userProfile = payload
      },
    )
    .addCase(fetchUserProfile.rejected, (acc: UserProfileState, { error }) => {
      acc.loading = false
      acc.error = error.message || ''
    })
})

export default reducers
