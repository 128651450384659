import { OrderTypeEnum } from '@interfaces'
import { RootState } from '@store'
import { useSelector } from 'react-redux'

export const useLimitOrderOperationStatus = (): {
  isPassive: boolean
  isAggressive: boolean
  shouldFetchPreorder: boolean
} => {
  const { limitOrderPriceLimits, buySell } = useSelector(
    (state: RootState) => state,
  )

  const previousTryFailed = buySell?.sendPreOrderError

  const orderType = buySell?.orderUserInput?.orderType

  const limitPrice = buySell?.orderUserInput?.limitOrder?.price

  const lowestAllowedPrice =
    limitOrderPriceLimits.lowestAllowedPrice?.amount !== null &&
    limitOrderPriceLimits.lowestAllowedPrice?.amount !== undefined
      ? limitOrderPriceLimits.lowestAllowedPrice?.amount
      : -Infinity

  const highestAllowedPrice =
    limitOrderPriceLimits.highestAllowedPrice?.amount !== null &&
    limitOrderPriceLimits.highestAllowedPrice?.amount !== undefined
      ? limitOrderPriceLimits.highestAllowedPrice?.amount
      : Infinity

  const isPassive = limitPrice && limitPrice < lowestAllowedPrice

  const isAggressive = limitPrice && limitPrice > highestAllowedPrice

  const shouldFetchPreorder =
    !previousTryFailed &&
    limitOrderPriceLimits.limitsFetched &&
    limitPrice &&
    isPassive === false &&
    isAggressive === false &&
    orderType === OrderTypeEnum.limit &&
    !buySell?.isLoading &&
    !buySell?.checkout?.id

  return {
    isPassive,
    isAggressive,
    shouldFetchPreorder,
  }
}
