import { UIIcon, UIIconColorProps, getIconColorSVGFill } from 'components/utils'

export const InvestArrow = ({
  color,
  size = 24,
}: UIIcon & UIIconColorProps): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 17 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_4478_48140)'>
        <path
          d='M11.7349 4.56667L12.6949 5.52667L9.44161 8.78L7.24828 6.58667C6.98828 6.32667 6.56828 6.32667 6.30828 6.58667L2.30828 10.5933C2.04828 10.8533 2.04828 11.2733 2.30828 11.5333C2.56828 11.7933 2.98828 11.7933 3.24828 11.5333L6.77495 8L8.96828 10.1933C9.22828 10.4533 9.64828 10.4533 9.90828 10.1933L13.6349 6.47333L14.5949 7.43333C14.8016 7.64 15.1616 7.49333 15.1616 7.2V4.33333C15.1683 4.14667 15.0216 4 14.8349 4H11.9749C11.6749 4 11.5283 4.36 11.7349 4.56667Z'
          fill={color ? getIconColorSVGFill(color) : 'currentColor'}
        />
      </g>
      <defs>
        <clipPath id='clip0_4478_48140'>
          <rect
            width='16'
            height='16'
            fill='white'
            transform='translate(0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}
