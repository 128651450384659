import { useCallback, useEffect } from 'react'

export const useOnEscKeypress = (cb: () => void): void => {
  const handleKeyPress = useCallback(
    (event): void => {
      if (event.keyCode === 27) cb()
    },
    [cb],
  )
  useEffect(() => {
    // Add event listener when component mounts
    document.addEventListener('keydown', handleKeyPress)

    // Remove event listener when component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [handleKeyPress])
}
