import './paymentMethods.style.scss'
import { useTranslation } from '@hooks'
import { useSelector, useDispatch } from 'react-redux'
import {
  BasicLink,
  GoBack,
  InputRadio,
  Modal,
  BigSpinner,
  Notification,
} from '@components'
import { fetchPaymentMethods, removePaymentMethod } from '@store/commonReducers'
import { useCallback, useEffect, useState } from 'react'
import { RootState } from 'store'
import {
  removeManualTransfer,
  setDefaultPaymentMethod,
} from '@store/commonReducers/paymentMethods/thunk'
import { PaymentsEmpty } from '@assets/illustrations/payments'
import { useNavigate } from '@hooks'
import { routes } from 'navigation'
import {
  NotificationMessageType,
  PaymentMethod,
  PaymentMethodTypeEnum,
} from 'interfaces'
import { ReactComponent as BankIcon } from '@assets/svg/bank.svg'

export default function PaymentMethods(): JSX.Element {
  const { t } = useTranslation()
  const { navigate } = useNavigate()
  const dispatch = useDispatch()
  const payments = useSelector((state: RootState) => state.paymentMethods)

  const [showNotification, setShowNotification] = useState(false)
  const [modals, setModals] = useState<{
    show: boolean
    paymentMethod: PaymentMethod
  }>({
    show: false,
    paymentMethod: null,
  })

  const deletePaymentMethod = useCallback(
    (id: string, type: PaymentMethodTypeEnum) => {
      dispatch(removePaymentMethod({ id, type }))
    },
    [dispatch],
  )
  const deleteManualTransfer = useCallback(
    (paymentId: string) => dispatch(removeManualTransfer(paymentId)),
    [dispatch],
  )

  const setDefaultMethod = useCallback(
    (paymentId) => dispatch(setDefaultPaymentMethod(paymentId as string)),
    [dispatch],
  )

  const getPaymentMethods = useCallback(() => {
    dispatch(fetchPaymentMethods())
  }, [dispatch])

  useEffect(() => {
    getPaymentMethods()
  }, [getPaymentMethods])

  const unlink = (paymentMethod: PaymentMethod): void => {
    const id = paymentMethod?.id
    if (id) {
      if (paymentMethod?.type === PaymentMethodTypeEnum.MANUAL_TRANSFER) {
        deleteManualTransfer(id)
      } else {
        deletePaymentMethod(id, paymentMethod?.type)
      }
      if (payments.error) return

      if (!payments.loading) {
        setShowNotification(true)
        getPaymentMethods()
        setModals({ show: false, paymentMethod: null })
        setTimeout(() => {
          setShowNotification(false)
        }, 4000)
      }
    }
  }

  return (
    <div className='profile__widget payments__screen --fade-in-animation'>
      <div className='profile__widget__header'>
        <GoBack goBack={() => navigate(routes.auth.profile)} />
        <div className='title mt-3'>{t('paymentMethods.title')}</div>
        {showNotification && (
          <div className='profile__widget__header__notification'>
            <Notification
              type={NotificationMessageType.positive}
              id='copied'
              message={t('paymentMethods.unlinkSuccessMessage')}
              onInit={() => {
                // Do nothing
              }}
              animation='bottom'
            />
          </div>
        )}
      </div>
      <div className='profile__widget__content mt-5'>
        <div className='payments__screen__wrapper'>
          {payments.loading && (
            <div className='container home__loader home__loader--inner'>
              <BigSpinner />
            </div>
          )}
          {!payments.loading &&
            payments.paymentMethods.length > 0 &&
            payments.paymentMethods.map((pm, i) => {
              return (
                <div className='payments__screen__item__content' key={pm.id}>
                  <div className='payments__screen__item__content__top d-flex d-flex-col align-start justify-space-between'>
                    <div className='w-100 d-flex align-end justify-end '>
                      <InputRadio
                        containerStyle='d-flex align-center justify-end'
                        defaultChecked={pm.default}
                        onChange={() => setDefaultMethod(pm.id)}
                        id={`bank__${i}`}
                        value={pm.id}
                        text={
                          pm.default
                            ? t('paymentMethods.default')
                            : t('paymentMethods.setDefault')
                        }
                        name='bank'
                      />
                    </div>
                    <label
                      htmlFor={`bank_${i}`}
                      className='d-flex align-center'
                    >
                      <div className='logo mr-2'>
                        <BankIcon className='black' />
                      </div>
                      <div className='payments__screen__item__content__titles'>
                        <p>
                          {pm.type === PaymentMethodTypeEnum.MANUAL_TRANSFER
                            ? t('paymentMethods.manualTransfer')
                            : pm.name}
                        </p>
                        <small>
                          {`${pm.manualTransferDetails?.bankName} ${pm.manualTransferDetails.accountNumber}`}
                        </small>
                      </div>
                    </label>
                  </div>
                  <hr className='payments__screen__item__content__border' />
                  <div className='text-center payments__screen__item__content__bottom'>
                    <BasicLink
                      text={t('paymentMethods.unlink')}
                      className='link--negative_btn paragraph1'
                      onClick={() =>
                        setModals({ show: true, paymentMethod: pm })
                      }
                    />
                  </div>
                </div>
              )
            })}
          {!payments.loading && payments.paymentMethods.length == 0 && (
            <div className='payments__screen__item__notfound px-2 py-4'>
              <div className='payments__screen__item__notfound__wrapper'>
                <PaymentsEmpty />
                <h3 className='title mt-4 mb-1'>
                  {t('paymentMethods.noFoundMethods')}
                </h3>
                <p className='subtitle'>
                  {t('paymentMethods.noPaymentMethods')}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      {modals && (
        <Modal
          show={modals.show}
          type='unlinkPaymethods'
          buttonText={t('paymentMethods.unlink')}
          buttonType='negative'
          buttonSize='big'
          buttonLoading={payments.loading}
          onButton={() => unlink(modals.paymentMethod)}
          isLink
          onBasicLink={() =>
            setModals({ show: false, paymentMethod: modals?.paymentMethod })
          }
        />
      )}
    </div>
  )
}
