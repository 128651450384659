import { createReducer } from '@reduxjs/toolkit'
import { AppConfig, ReducerState } from 'interfaces'
import { getAppConfiguration } from './thunk'

const initialState: AppConfig & ReducerState = {
  error: null,
  loading: false,
  features: null,
}

export const appConfigReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getAppConfiguration.pending, (acc) => {
      acc.loading = true
      acc.error = null
    })
    .addCase(getAppConfiguration.fulfilled, (acc, { payload }) => {
      acc.loading = false
      acc.features = payload.features
      acc.error = null
    })
    .addCase(getAppConfiguration.rejected, (acc, { error }) => {
      acc.loading = false
      acc.error = error.message
    })
})
