import { UIIcon, UIIconColorProps } from 'components/utils'

export const ArrowSortDown = ({
  size = 24,
}: UIIcon & UIIconColorProps): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 17 17'
      fill='none'
    >
      <g clipPath='url(#clip0_12227_20884)'>
        <path
          d='M10.8727 7.58329V11.59H12.0727C12.3661 11.59 12.5194 11.95 12.3061 12.1566L10.4461 14.01C10.3127 14.1366 10.1061 14.1366 9.97274 14.01L8.11274 12.1566C7.89941 11.95 8.04608 11.59 8.34608 11.59H9.53941V7.58329C9.53941 7.21663 9.83941 6.91663 10.2061 6.91663C10.5727 6.91663 10.8727 7.21663 10.8727 7.58329Z'
          fill='var(--iconPrimaryInitial)'
        />
        <path
          d='M4.11274 4.34672L5.97274 2.48672C6.09941 2.36006 6.31274 2.36006 6.43941 2.48672L8.29941 4.34672C8.51274 4.55339 8.36607 4.91339 8.06607 4.91339H6.87274V8.92006C6.87274 9.28672 6.57274 9.58672 6.20607 9.58672C5.83941 9.58672 5.53941 9.28672 5.53941 8.92006V4.91339H4.34607C4.04607 4.91339 3.89941 4.55339 4.11274 4.34672Z'
          fill='var(--iconNeutralMedium)'
        />
      </g>
      <defs>
        <clipPath id='clip0_12227_20884'>
          <rect
            x='0.207031'
            y='0.25'
            width='16'
            height='16'
            rx='4'
            fill='white'
          />
        </clipPath>
      </defs>
    </svg>
  )
}
