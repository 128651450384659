import './notification.styles.scss'
import { useEffect } from 'react'
import { NotificationsProps } from './notification.props'
import { CheckInCircleIcon } from '@assets/icons'

export function Notification({
  message,
  onInit,
  animation = 'top',
}: NotificationsProps): JSX.Element {
  useEffect(() => {
    if (onInit) onInit()
  }, [onInit])

  return (
    <div className={`notification notification--${animation}`}>
      <div className='icon-container'>
        <CheckInCircleIcon
          color='iconPositiveInitial'
          secondaryColor='surfacePositiveSubtleInitial'
        />
      </div>
      <div className={`notification__text`}>{message}</div>
    </div>
  )
}
