import { OnboardingStatus } from 'interfaces'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

export function useOnboardingCompleted(): boolean {
  const { onboardingStatus } = useSelector((state: RootState) => state)

  return (
    onboardingStatus?.onboarding?.onboardingStatus ==
    OnboardingStatus.onboardingCompleted
  )
}
