import { CloseIcon } from '@assets/icons'
import { OrdersWhenMarketClosedIllustration } from '@assets/illustrations'
import { Button, CustomModal, Spacer, Text } from '@components'
import { useTranslation } from '@hooks'

export const OrdersWhenMarketClosedPromoModal = ({
  close,
}: {
  close: () => void
}): JSX.Element => {
  const { t } = useTranslation()

  return (
    <CustomModal
      size='big'
      height='fullHeight'
      show
      placeOn='right'
      dismissable
      onClose={close}
    >
      <CustomModal.Header>
        <Button
          onClick={() => close()}
          buttonType='link'
          autoWidth
          content={<CloseIcon color='iconPrimaryInitial' size={16} />}
          shape='square'
        />
      </CustomModal.Header>
      <CustomModal.Content>
        <Spacer preset='medium' />
        <div className='d-flex d-flex-col w-100'>
          <div className='d-flex justify-center w-100'>
            <OrdersWhenMarketClosedIllustration />
          </div>
          <Spacer preset='medium' />
          <Text
            align='center'
            preset='headline2'
            text={t('promotions.sendOrdersWhenMarketClosedPromo.title')}
          />
          <Spacer preset='tiny' />
          <Text
            align='center'
            preset='paragraph2'
            text={t('promotions.sendOrdersWhenMarketClosedPromo.desc')}
            color='secondary'
          />
          <Spacer preset='mediumPlus' />
          <Text
            preset='subtitle3'
            text={t(
              'promotions.sendOrdersWhenMarketClosedPromo.howItWorksTitle',
            )}
          />
          <Spacer preset='tiny' />
          <Text
            preset='paragraph2'
            text={t(
              'promotions.sendOrdersWhenMarketClosedPromo.howItWorksDesc',
            )}
            color='secondary'
          />
          <Spacer preset='huge' />
        </div>
      </CustomModal.Content>
      <CustomModal.Footer>
        <Button text={t('common.close')} onClick={close} />
      </CustomModal.Footer>
    </CustomModal>
  )
}
