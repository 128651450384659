import './customModal.styles.scss'

export interface ModalFooterProps {
  className?: string
  children?: React.ReactNode
}

export function ModalFooter({
  className,
  children,
}: ModalFooterProps): JSX.Element {
  const modalFooterStyle = `modal-footer__content ${className as string}`
  return <div className={modalFooterStyle}>{children}</div>
}
