import {
  UIIcon,
  UIIconColorProps,
  UISecondaryColorProp,
  getIconColorSVGFill,
} from 'components/utils'

export const CheckInCircleIcon = ({
  color,
  secondaryColor,
  size = 24,
}: UIIcon & UIIconColorProps & UISecondaryColorProp): JSX.Element => {
  return (
    <svg width={size} height={size} viewBox='0 0 27 27' fill='none'>
      <rect
        x='0.211914'
        y='0.5'
        width='26'
        height='26'
        rx='13'
        fill={color ? getIconColorSVGFill(color) : 'currentColor'}
      />
      <path
        d='M10.2674 17.349L6.73375 13.8153C6.54349 13.6246 6.28518 13.5175 6.01581 13.5175C5.74643 13.5175 5.48813 13.6246 5.29787 13.8153C4.90071 14.2125 4.90071 14.854 5.29787 15.2512L9.55458 19.5079C9.95174 19.9051 10.5933 19.9051 10.9905 19.5079L21.7646 8.73373C22.1618 8.33657 22.1618 7.69501 21.7646 7.29785C21.5744 7.10716 21.3161 7 21.0467 7C20.7773 7 20.519 7.10716 20.3288 7.29785L10.2674 17.349Z'
        fill={
          secondaryColor ? getIconColorSVGFill(secondaryColor) : 'currentColor'
        }
      />
    </svg>
  )
}
