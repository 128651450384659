import '../login/login.styles.scss'
import '../signup/signup.styles.scss'
import './resetPassword.styles.scss'
import { ReactComponent as BigSuccess } from '@assets/svg/big_success.svg'
import { useTranslation } from '@hooks'
import { useState, useEffect, useCallback } from 'react'
import { useNavigate } from '@hooks'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@store'
import { Download } from '@features'
import {
  BasicLink,
  GoBack,
  Header,
  TextField,
  Button,
  CodeInput,
  Show,
  Spacer,
  Text,
} from '@components'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { clearErrors } from './codeEnter/reducers'
import {
  fetchVerificationCode,
  doSendVerificationCode,
} from './codeEnter/thunk'
import { maskEmail } from '@utils/helpers'
import { trackingService } from '@services'
import { ResetPasswordEvents } from '@utils/eventTracker/eventKeys'
import { routes } from 'navigation'
import { InvalidOTPCode, ResetPasswordModal } from '@shared/modals'
import { passwordValidation } from '@utils/validate'
import PasswordRequirements from './passwordRequirements/passwordRequirements'
import { EyeFilled, EyeFilledDisable } from '@assets/svg'

export default function ResetPassword(): JSX.Element {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { navigate } = useNavigate()

  const [isShown, setIsShown] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [email, setEmail] = useState('')
  const [otpCode, setOtpCode] = useState('')
  const [typeModal, setTypeModal] = useState('')

  const [passwordShow, setPasswordShow] = useState(false)
  const [passwordConfirmationShow, setPasswordConfirmationShow] =
    useState(false)

  const translateReq = t('common.fieldRequired')
  const translateNewPassword = t('forgotPassword.newPassword')

  const schemaEmail = yup.object().shape({
    email: yup.string().email(t('common.emailRequired')).required(translateReq),
  })

  const schemaReset = yup.object().shape({
    email: yup.string().email(t('common.emailRequired')).required(translateReq),
    password: passwordValidation(true),
    passwordConfirmation: passwordValidation(true),
  })
  const emailForm = useForm({
    resolver: yupResolver(schemaEmail),
  })

  const resetPasswordForm = useForm({
    resolver: yupResolver(schemaReset),
    criteriaMode: 'all',
    mode: 'onChange',
  })

  const watchPassword = resetPasswordForm.watch('password')
  let watching = resetPasswordForm.watch()

  if (!email && !otpCode) {
    watching = emailForm.watch()
  }

  const codeEnter = useSelector((state: RootState) => state.codeEnter)
  const resetPassword = useSelector((state: RootState) => state.resetPassword)

  const clearErrorState = useCallback(() => dispatch(clearErrors()), [dispatch])

  const onSubmitMail = (): void => {
    if (emailForm?.formState?.errors) {
      dispatch(
        fetchVerificationCode({
          challengeType: 'PASSWORD',
          email: emailForm.getValues().email,
        }),
      )

      clearErrorState()

      setEmail(emailForm.getValues().email as string)

      setTypeModal('resetPassword')
      setIsShown(true)
    } else {
      // ERROR MODAL
    }
  }

  const onSubmitCode = (): void => {
    if (resetPasswordForm?.formState?.errors) {
      const { password, passwordConfirmation } = resetPasswordForm.getValues()
      if (password == passwordConfirmation) {
        dispatch(
          doSendVerificationCode({
            email,
            password,
            verificationCode: otpCode,
          }),
        )
        trackingService.trackEvent({ event: ResetPasswordEvents.formSubmitted })
      }
    }
  }

  function closeModal(): void {
    setIsShown(false)
    setDisabled(true)
    emailForm.reset()
  }

  useEffect(() => {
    if (!email) {
      if (
        Object.values(emailForm.getValues()).includes('') === false &&
        Object.keys(emailForm?.formState?.errors).length == 0
      ) {
        setDisabled(false)
      } else {
        setDisabled(true)
      }
    } else {
      if (
        Object.values(resetPasswordForm.getValues()).includes('') === false &&
        Object.keys(resetPasswordForm?.formState?.errors).length == 0
      ) {
        if (
          resetPasswordForm.getValues().password ==
          resetPasswordForm.getValues().passwordConfirmation
        )
          setDisabled(false)
        else setDisabled(true)
      } else {
        setDisabled(true)
      }
    }
  }, [
    resetPassword,
    email,
    emailForm,
    watching,
    resetPasswordForm,
    codeEnter,
    otpCode,
  ])

  const passwordToggler = (): void => {
    setPasswordShow(!passwordShow)
  }
  const passwordConfirmationToggler = (): void => {
    setPasswordConfirmationShow(!passwordConfirmationShow)
  }

  return (
    <>
      <main className='login reset'>
        <Header simple />
        <div className='container login__wrapper'>
          <div className='login__widget'>
            <div
              className={`login__widget__header pa-3 d-flex align-center justify-space-between`}
            >
              <div className='w-20 d-flex align-center justify-start'>
                {email && !codeEnter?.resetPassword && (
                  <GoBack
                    goBack={() => navigate('/resetPassword', { replace: true })}
                  />
                )}
              </div>
              <span className='login__widget__header__title w-60'>
                {t('forgotPassword.reset')}
              </span>
              <BasicLink
                text={t('common.cancel')}
                url='/login'
                type='link'
                className='w-20 text-right'
              />
            </div>
            <div className='login__widget__content'>
              <div className='reset__widget__elements pa-3'>
                {!email && !otpCode && !codeEnter?.resetPassword && (
                  <>
                    <h2 className='signup__widget__elements__info__title mb-5'>
                      {t('forgotPassword.forgot')}
                    </h2>
                    <p className='signup__widget__elements__info__description mb-5'>
                      {t('forgotPassword.provideEmail')}
                    </p>
                    <form onSubmit={emailForm.handleSubmit(onSubmitMail)}>
                      <TextField
                        type='email'
                        register={emailForm.register}
                        style='paragraph1'
                        inputProps={{
                          name: 'email',
                          onChange: async (e) => {
                            emailForm.setValue('email', e.target.value)
                            await emailForm.trigger('email')
                          },
                          label: t('forgotPassword.email'),
                          value: emailForm?.getValues('email'),
                        }}
                      />
                      <Spacer preset='small' />
                      <Button
                        text={t('signup.Confirm')}
                        loading={codeEnter?.loading}
                        disabled={disabled || !emailForm.formState.isValid}
                        type='submit'
                        size='big'
                      />
                    </form>
                  </>
                )}
                {email && !codeEnter?.resetPassword && (
                  <>
                    <form action=''>
                      <h2 className='signup__widget__elements__info__title'>
                        {t('codeEnter.code')}
                      </h2>
                      <p className='signup__widget__elements__info__subtitle'>
                        {t('codeEnter.enterCode')}{' '}
                        <span className='orange--text'>{maskEmail(email)}</span>
                      </p>
                      <div className='wrapper__code d-flex justify-space-between'>
                        <CodeInput
                          length={6}
                          onSubmit={(code) =>
                            setOtpCode(code.map((e) => e.toString()).join(''))
                          }
                        />
                      </div>

                      <h2 className='signup__widget__elements__info__title mt-5 mb-3'>
                        {translateNewPassword}
                      </h2>
                      <TextField
                        type={passwordShow ? 'text' : 'password'}
                        register={resetPasswordForm.register}
                        style='paragraph1'
                        right={
                          passwordShow ? (
                            <EyeFilled onClick={passwordToggler} />
                          ) : (
                            <EyeFilledDisable onClick={passwordToggler} />
                          )
                        }
                        inputProps={{
                          label: translateNewPassword,
                          name: 'password',
                          onChange: async (e) => {
                            if (codeEnter?.error) clearErrorState()
                            resetPasswordForm.setValue(
                              'password',
                              e.target.value,
                            )
                            await resetPasswordForm.trigger('password')
                          },
                          value: resetPasswordForm?.getValues('password'),
                        }}
                      />
                      <Spacer preset='tiny' />
                      <Text
                        preset='caption'
                        color='secondary'
                        text={t('forgotPassword.passwordRequirement')}
                      />
                      <Spacer preset='tiny' />
                      <PasswordRequirements
                        password={watchPassword}
                        email={email}
                        validationErrors={[codeEnter?.error]}
                      />
                      <Spacer preset='small' />
                      <TextField
                        type={passwordConfirmationShow ? 'text' : 'password'}
                        register={resetPasswordForm.register}
                        style='paragraph1'
                        right={
                          passwordConfirmationShow ? (
                            <EyeFilled onClick={passwordConfirmationToggler} />
                          ) : (
                            <EyeFilledDisable
                              onClick={passwordConfirmationToggler}
                            />
                          )
                        }
                        inputProps={{
                          name: 'passwordConfirmation',
                          onChange: async (e) => {
                            resetPasswordForm.setValue(
                              'passwordConfirmation',
                              e.target.value,
                            )
                            await resetPasswordForm.trigger(
                              'passwordConfirmation',
                            )
                          },
                          value: resetPasswordForm?.getValues(
                            'passwordConfirmation',
                          ),
                          label: t('forgotPassword.confirmNewPassword'),
                        }}
                      />
                      <Spacer preset='small' />
                      <Button
                        text={t('signup.Confirm')}
                        loading={codeEnter?.loading}
                        disabled={
                          disabled ||
                          codeEnter?.loading ||
                          !otpCode ||
                          !watchPassword
                        }
                        onClick={onSubmitCode}
                        type='button'
                      />
                      <Spacer preset='tiny' />
                    </form>
                  </>
                )}
                {email && codeEnter?.resetPassword === true && (
                  <div className='success__password'>
                    <BigSuccess
                      className='mb-3'
                      fill='var(--iconPrimaryInitial)'
                    />
                    <p>{t('forgotPassword.passwordChanged')}</p>
                    <BasicLink
                      className='mt-5'
                      type='link'
                      onClick={() => {
                        navigate(routes.nonAuth.login)
                        clearErrorState()
                        trackingService.trackEvent({
                          event: ResetPasswordEvents.backToLogin,
                        })
                      }}
                      text={t('forgotPassword.backToLogin')}
                    />
                  </div>
                )}
              </div>
            </div>
            {/* <div className='login__widget__footer pa-3'>
              <span className='secondary--text mr-1'>
                {t('login.dontHaveAccount?')}
              </span>
              <BasicLink text={t('login.signUp')} url='/signup' type={'link'} />
            </div> */}
          </div>
          <Download />
        </div>
      </main>
      <Show when={typeModal === 'resetPassword' && isShown}>
        <ResetPasswordModal close={closeModal} />
      </Show>
      <Show when={typeModal === 'errorCode' && isShown}>
        <InvalidOTPCode close={closeModal} onCase='resetPassword' />
      </Show>
    </>
  )
}
