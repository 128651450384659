import './searchResultsCard.styles.scss'
import { formatCurrency } from '@utils/helpers'
import { Button, Spacer, Text } from 'components'
import { Avatar } from 'components/avatar/avatar'
import { AssetType, SearchAssetsStateItem } from 'interfaces'
import React from 'react'
import { StockPerformance } from 'features'
import { useNavigate } from 'hooks/useNavigation'
import { AssetIcon } from '@assets/icons'
import { useTranslation } from '@hooks'

const SearchResultsCardComponent = ({
  results,
  onBuy,
  assetType,
  onAssetClick,
}: {
  results: Array<SearchAssetsStateItem>
  onBuy?: (id: string, assetType: AssetType) => void
  assetType: AssetType
  onAssetClick?: () => void
}): JSX.Element => {
  const { navigate } = useNavigate()

  const { t } = useTranslation()

  return (
    <div className='search-results-card'>
      {results?.map((i) => {
        return (
          <div
            key={`search-results-card-${i?.id}`}
            className='search-results-card__item'
            aria-hidden
            onClick={() => {
              if (onAssetClick) onAssetClick()
              navigate(`/company/${i?.id}`)
            }}
          >
            <div className='search-results-card__column search-results-card__name d-flex align-center'>
              <Avatar
                roundness='full'
                image={i?.logoUrl}
                size='mediumPlus'
                fallback={<AssetIcon size={22} />}
              />
              <Spacer preset='smaller' />
              <Text preset='paragraph2' text={i?.companyName} />
            </div>
            <div className='search-results-card__column search-results-card__price'>
              <Text
                text={formatCurrency(i?.price)}
                preset='paragraph2'
                align='right'
              />
            </div>
            <div className='search-results-card__column search-results-card__performance'>
              <div className='paragraph2 text-right'>
                <StockPerformance
                  performanceAmount={i?.increaseMoney}
                  performancePercentage={i?.increasePercentage}
                  plusSymbol={true}
                />
              </div>
            </div>
            {onBuy && (
              <div className='search-results-card__column search-results-card__buy'>
                <Button
                  id='row-buy-button'
                  text={t('common.buy')}
                  loading={false}
                  autoWidth
                  onClick={() => {
                    if (onAssetClick) onAssetClick()
                    onBuy(i?.id, assetType)
                  }}
                  size='smaller'
                  type='button'
                  textPreset='subtitle2'
                />
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}

export const SearchResultsCard = React.memo(SearchResultsCardComponent)
