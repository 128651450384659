import { BasicLink, Carousel } from '@components'
import { useTranslation } from '@hooks'
import { useSelector } from 'react-redux'
import { useNavigate } from '@hooks'
import { routes } from '../../../navigation'
import { trackingService } from '@services'
import { RootState } from 'store'
import { CollectionEvents } from '@utils/eventTracker/eventKeys'

export default function CollectionsHome(): JSX.Element {
  const { t } = useTranslation()
  const { navigate } = useNavigate()

  const { collections } = useSelector((state: RootState) => state)

  const openCollections = (): void => {
    trackingService.trackEvent({ event: CollectionEvents.viewAll })
    navigate(routes.auth.collections)
  }

  return (
    <>
      {collections?.collections?.length ? (
        <div className='home__collections'>
          <div className='home__collections__title d-flex justify-space-between align-center pt-3 pb-3'>
            {t('collections.title')}
            <BasicLink
              onClick={openCollections}
              className='text-right d-flex align-center paragraph2'
            >
              {t('common.viewAll')}
            </BasicLink>
          </div>
          <Carousel {...collections} />
        </div>
      ) : null}
    </>
  )
}
