import {
  CollectionDetails,
  CollectionItemProps,
  CompanyLiquidity,
} from '@interfaces'
import { api } from '@utils/api/api'

export const getCollectionDetails = (
  collectionId: string,
): Promise<CollectionDetails> => {
  return api.get(
    `/api/v1/stock-collections/${collectionId}?includeStocksDetails=true`,
  )
}

export const getAssetLiquidity = (
  company: string,
): Promise<CompanyLiquidity> => {
  return api.get(`/api/v1/stocks/${company}/liquidity`)
}

export const getCollections = (): Promise<CollectionItemProps[]> => {
  return api.get('/api/v1/stock-collections')
}
