import { HeartIcon, HeartIconFilled } from '@assets/svg'
import { ReactComponent as BackIcon } from '@assets/svg/backBrowser.svg'
import { ReactComponent as DropdownIcon } from '@assets/svg/dropdown_black.svg'
import { BasicLink, Button } from 'components'
import { useOnboardingCompleted } from 'hooks/useOnboardingCompleted'
import { useParams } from 'react-router-dom'
import { useBuyAndSellActions } from 'hooks/useBuyAndSellActions'
import { useTranslation } from 'hooks/useTranslation'
import { CompanyOverview } from '../companyOverview/companyOverview'
import { LowLiquidityInfoModal, OutOfHoursOrderModal } from '@shared/modals'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { setWatchlistStockState, unsetWatchlistStock } from '../thunk'
import { CompanyRightPanel } from '../companyRightPanel/companyRightPanel'
import { useNavigate } from '@hooks'
import { CompanyOverviewTab } from '@interfaces/companyOverview'
import Overview from '../companyTabs/overview/overview'
import AnalystRatings from '../companyTabs/analystRatings/analystRatings'
import Earnings from '../companyTabs/earnings/earnings'
import News from '../companyTabs/news/news'
import BullBears from '../companyTabs/bullBears/bullBears'

export const CompanyMainContent = ({
  setShowInfo,
  showInfo,
}: {
  setShowInfo: () => void
  showInfo: boolean
}): JSX.Element => {
  const origin = 'Company details'
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { navigate } = useNavigate()

  const [activeTab, setActiveTab] = useState<CompanyOverviewTab>(
    CompanyOverviewTab.overview,
  )
  const id = useParams().id || ''
  const onBoardingCompleted = useOnboardingCompleted()

  const { buy, sell, modals } = useBuyAndSellActions()

  const [showOutOfHoursModal, setShowOutOfHoursModal] = useState(false)
  const [lowLiquidityModalVisible, setLowLiquidityModalVisible] =
    useState(false)

  const { companyPosition, companyStocks } = useSelector(
    (state: RootState) => state,
  )

  const goBack = (): void => {
    navigate(-1)
  }

  const handleWatchListToggle = (): void => {
    if (!companyStocks?.isWatchlistStateLoading) {
      if (companyStocks?.isInWatchlist) {
        dispatch(unsetWatchlistStock(id))
      } else {
        dispatch(setWatchlistStockState(id))
      }
    }
  }

  const ActiveTabContent = (activeTab: CompanyOverviewTab): JSX.Element => {
    switch (activeTab) {
      case CompanyOverviewTab.analystRatings:
        return <AnalystRatings />
      case CompanyOverviewTab.earnings:
        return <Earnings />
      case CompanyOverviewTab.news:
        return <News />
      case CompanyOverviewTab.bullsAndBears:
        return <BullBears />
      default:
        return (
          <Overview
            showOutOfHoursModal={() => setShowOutOfHoursModal(true)}
            openLowLiquidityModal={() => setLowLiquidityModalVisible(true)}
          />
        )
    }
  }

  return (
    <>
      <div className='company-details__wrapper --fade-in-animation'>
        <div className='company-details__widget'>
          <div className='company-details__widget__graph pr-4'>
            <div className='company-details__widget__graph__link pb-3'>
              <BackIcon className='cursor-pointer' onClick={goBack} />
              {companyStocks?.isInWatchlist ? (
                <HeartIconFilled
                  className='cursor-pointer'
                  onClick={handleWatchListToggle}
                />
              ) : (
                <HeartIcon
                  className='cursor-pointer'
                  onClick={handleWatchListToggle}
                />
              )}
              <BasicLink
                onClick={() => setShowInfo()}
                className={`company-details__widget__graph__link__button px-2 ${
                  showInfo
                    ? 'company-details__widget__graph__link__button--opened'
                    : ''
                }`}
              >
                <BackIcon
                  width='12'
                  height='12'
                  className='company-details__widget__graph__link__icon__arrow'
                  onClick={() => setShowInfo()}
                />
                <div>
                  {showInfo ? t('common.seeLess') : t('common.seeMore')}
                </div>
                <DropdownIcon className='company-details__widget__graph__link__icon' />
              </BasicLink>
            </div>
            <CompanyOverview
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
            {ActiveTabContent(activeTab)}

            <div className='company-details__after-widget__button buttons__chart'>
              <Button
                text={`${t('home.buy')} ${
                  companyPosition.id ? '' : companyStocks?.symbol
                }`}
                loading={false}
                disabled={
                  !companyStocks?.isEnabled ||
                  companyStocks.marketStatus?.status !== 'OPEN' ||
                  !onBoardingCompleted
                }
                type='button'
                size='big'
                onClick={() =>
                  buy({
                    id: companyStocks?.id,
                    type: companyStocks?.type,
                    origin,
                  })
                }
              />
              {companyPosition.id && (
                <div className='w-100 pl-2'>
                  <Button
                    text={`${t('home.sell')} ${
                      companyPosition.id ? '' : companyStocks?.symbol
                    }`}
                    loading={false}
                    disabled={
                      !companyStocks?.isEnabled ||
                      companyStocks?.marketStatus?.status !== 'OPEN'
                    }
                    type='button'
                    buttonType='ghost'
                    size='big'
                    onClick={() =>
                      sell({
                        id: companyStocks?.id,
                        type: companyStocks?.type,
                        origin,
                      })
                    }
                  />
                </div>
              )}
            </div>
          </div>
          <CompanyRightPanel />
        </div>
      </div>
      {modals}
      {showOutOfHoursModal && (
        <OutOfHoursOrderModal close={() => setShowOutOfHoursModal(false)} />
      )}
      {lowLiquidityModalVisible && (
        <LowLiquidityInfoModal
          close={() => setLowLiquidityModalVisible(false)}
          symbol={companyStocks?.symbol}
        />
      )}
    </>
  )
}
