import { BasicLink } from '@components'
import { NotificationMessageType } from '@interfaces'
import { useEffect } from 'react'
import { useTranslation, useBuyAndSellActions } from '@hooks'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from '@hooks'
import { ReactComponent as RightIcon } from '@assets/svg/next.svg'
import { ReactComponent as LeftIcon } from '@assets/svg/back.svg'
import { reloadWatchlistAfterDelete } from '@screens/watchlist'
import { routes } from '../../../navigation'
import { trackingService } from '@services'
import { RootState } from 'store'
import { addNotification } from '@store/commonReducers/notifications'
import { WatchlistEvents } from '@utils/eventTracker/eventKeys'
import { ScrollingCarousel } from '@trendyol-js/react-carousel'
import { WatchlistItem } from '../watchlist/watchlistItem'

export default function WatchlistHome(): JSX.Element {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { navigate } = useNavigate()

  const { watchlist } = useSelector((state: RootState) => state)

  const maxWatchlistItems = 12
  const watchlistResume = watchlist?.data?.stocks
    ? watchlist?.data?.stocks.slice(0, maxWatchlistItems)
    : []

  const { buy, modals } = useBuyAndSellActions()

  useEffect(() => {
    if (watchlist?.reloadAfterDelete) {
      dispatch(
        addNotification({
          type: NotificationMessageType.positive,
          message: `${t('watchlist.assetRemoved')}`,
        }),
      )
      dispatch(reloadWatchlistAfterDelete(0))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchlist?.reloadAfterDelete])

  const openWatchlist = (): void => {
    trackingService.trackEvent({ event: WatchlistEvents.viewAll })
    navigate(routes.auth.watchlist)
  }

  return (
    <>
      {watchlistResume?.length ? (
        <>
          <div className='home__widget'>
            <div className='home__watchlist'>
              <div className='home__watchlist__title d-flex justify-space-between align-center pt-3 pb-3'>
                <div>
                  {t('watchlist.title')} · {watchlist?.totalItems}
                </div>
                <BasicLink
                  onClick={openWatchlist}
                  className='text-right d-flex align-center paragraph2'
                >
                  {t('common.viewAll')}
                </BasicLink>
              </div>
              <ScrollingCarousel
                className={`carousel`}
                rightIcon={
                  <RightIcon
                    fill='var(--textNeutralStrong)'
                    className='rightIcon'
                  />
                }
                leftIcon={<LeftIcon fill='var(--textNeutralStrong)' />}
              >
                {watchlistResume.map((listItem) => (
                  <WatchlistItem
                    companyStocks={listItem}
                    key={listItem.id}
                    onBuy={() =>
                      buy({
                        id: listItem?.id,
                        type: listItem?.type,
                        origin: 'watchlist',
                      })
                    }
                  />
                ))}
              </ScrollingCarousel>
            </div>
          </div>
          {modals}
        </>
      ) : null}
    </>
  )
}
