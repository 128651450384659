import './customModal.styles.scss'
import { stringBuilder } from '@components/utils'
import { useMemo } from 'react'

export interface ModalContentProps {
  children?: React.ReactNode
}

export function ModalOverlayContent({
  children,
}: ModalContentProps): JSX.Element {
  const classes = useMemo(() => {
    return stringBuilder([
      ['wrapper__content__overlay-element --fade-in-animation-fast', true],
    ])
  }, [])

  return (
    <div className={classes}>
      <div className='wrapper__content__overlay-element__content'>
        {children}
      </div>
    </div>
  )
}
