import { UIIcon, UIIconColorProps, getIconColorSVGFill } from 'components/utils'

export const AssetIcon = ({
  color,
  size = 24,
}: UIIcon & UIIconColorProps): JSX.Element => {
  return (
    <svg width={size} height={size} viewBox='0 0 16 12'>
      <path
        d='M14 3.3335C13.0333 3.3335 12.4933 4.2935 12.7133 5.00683L10.3467 7.38016C10.1467 7.32016 9.85332 7.32016 9.65332 7.38016L7.95332 5.68016C8.17999 4.96683 7.63999 4.00016 6.66666 4.00016C5.69999 4.00016 5.15332 4.96016 5.37999 5.68016L2.33999 8.7135C1.62666 8.4935 0.666656 9.0335 0.666656 10.0002C0.666656 10.7335 1.26666 11.3335 1.99999 11.3335C2.96666 11.3335 3.50666 10.3735 3.28666 9.66016L6.31999 6.62016C6.51999 6.68016 6.81332 6.68016 7.01332 6.62016L8.71332 8.32016C8.48666 9.0335 9.02666 10.0002 9.99999 10.0002C10.9667 10.0002 11.5133 9.04016 11.2867 8.32016L13.66 5.9535C14.3733 6.1735 15.3333 5.6335 15.3333 4.66683C15.3333 3.9335 14.7333 3.3335 14 3.3335Z'
        fill={color ? getIconColorSVGFill(color) : 'currentColor'}
      />
      <path
        d='M10 4L10.6267 2.62L12 2L10.6267 1.38L10 0L9.38667 1.38L8 2L9.38667 2.62L10 4Z'
        fill={color ? getIconColorSVGFill(color) : 'currentColor'}
      />
      <path
        d='M2.33332 5.33333L2.66666 4L3.99999 3.66667L2.66666 3.33333L2.33332 2L1.99999 3.33333L0.666656 3.66667L1.99999 4L2.33332 5.33333Z'
        fill={color ? getIconColorSVGFill(color) : 'currentColor'}
      />
    </svg>
  )
}
