/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useEffect, useMemo, useState } from 'react'

export const ExternalSvg = ({
  url,
  strokeColor,
  fillColor,
}: {
  url: string
  strokeColor?: string
  fillColor?: string
}): JSX.Element => {
  const [svgContent, setSvgContent] = useState(null)

  useEffect(() => {
    const abortController = new AbortController()
    const signal = abortController.signal

    const fetchSvg = async (): Promise<void> => {
      try {
        const response = await fetch(url, { signal })
        const svgText = await response.text()
        if (!signal?.aborted && response?.status === 200) {
          setSvgContent(svgText)
        }
      } catch (error) {
        console.log(error)
      }
    }

    fetchSvg()
    return () => {
      abortController.abort()
    }
  }, [url])

  const style = useMemo(() => {
    const obj: React.CSSProperties = {}

    if (strokeColor) obj.stroke = strokeColor
    if (fillColor) obj.fill = fillColor

    return obj
  }, [fillColor, strokeColor])

  return svgContent ? (
    <div
      className='d-flex align-center justify-center'
      dangerouslySetInnerHTML={{ __html: svgContent }}
      style={style}
    />
  ) : null
}
