import React, { useEffect, useRef, useState } from 'react'
import * as d3 from 'd3'
import { formatCurrency } from '@utils/helpers'
import { useTranslation } from 'react-i18next'

interface LineChartProps {
  data: {
    date: string
    value: number
  }[]
  startDate: string
  endDate: string
  currency: string
  highValue?: number
  avgValue?: number
  lowValue?: number
}

const AdvanceLineChart: React.FC<LineChartProps> = ({
  data,
  startDate,
  endDate,
  currency,
  highValue,
  lowValue,
  avgValue,
}) => {
  const svgRef = useRef<SVGSVGElement | null>(null)
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
  const textAnchor = 'text-anchor'
  const strokeDasharray = 'stroke-dasharray'
  const chartColor = 'var(--chartStocks)'
  const strokeWidth = 'stroke-width'
  const textNeutralStrong = 'var(--textNeutralStrong)'
  const strokeNeutralSoftInitial = 'var(--strokeNeutralSoftInitial)'

  const { t } = useTranslation()

  useEffect(() => {
    const updateDimensions = (): void => {
      if (svgRef.current) {
        const { width, height } = svgRef.current.getBoundingClientRect()
        setDimensions({ width, height })
      }
    }

    // Update dimensions initially and on window resize
    updateDimensions()
    window.addEventListener('resize', updateDimensions)

    return () => {
      window.removeEventListener('resize', updateDimensions)
    }
  }, [])
  useEffect(() => {
    const { width, height } = dimensions
    if (!data.length || width === 0 || height === 0) return

    const svg = d3.select(svgRef.current)
    svg.selectAll('*').remove() // Clear previous content

    const margin = { top: 40, right: 30, bottom: 30, left: 40 }
    const chartWidth1 = width * 0.6 - margin.left - margin.right // 60% width for the chart
    const chartWidth2 = width * 0.4 - margin.left - margin.right // 40% width for the forecast
    const innerHeight = height - margin.top - margin.bottom

    // Parse the date and set scales
    const parseDate = d3.timeParse('%Y-%m-%d')
    const xScale = d3
      .scaleTime()
      .domain(d3.extent(data, (d) => parseDate(d.date)) as [Date, Date])
      .range([0, chartWidth1])

    // Set the y scale
    const yScale = d3
      .scaleLinear()
      .domain([
        (d3.min(data, (d) => d.value) || 0) < lowValue
          ? d3.min(data, (d) => d.value)
          : lowValue,
        ((d3.max(data, (d) => d.value) || 0) < highValue
          ? highValue
          : d3.max(data, (d) => d.value)) * 1.1,
      ])
      .range([innerHeight, 0])

    const g = svg
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`)

    // Draw the box for the entire chart area
    svg
      .append('rect')
      .attr('x', margin.left)
      .attr('y', margin.top)
      .attr('width', width - margin.left - margin.right)
      .attr('height', innerHeight)
      .attr('fill', 'none')
      .attr('stroke', 'var(--strokeNeutralSoftInitial')

    // Add label for the first part
    svg
      .append('text')
      .attr('x', margin.left + chartWidth1 / 2)
      .attr('y', margin.top / 2)
      .attr(textAnchor, 'middle')
      .attr('class', 'caption')
      .attr('fill', textNeutralStrong)
      .text(t('companyDetails.chart.past12Month'))

    // Add label for the second part
    svg
      .append('text')
      .attr('x', margin.left + chartWidth1 + chartWidth2 / 2)
      .attr('y', margin.top / 2)
      .attr(textAnchor, 'middle')
      .attr('class', 'caption')
      .attr('fill', textNeutralStrong)
      .text(t('companyDetails.chart.forecast12Month'))

    // Add grid lines inside both sections

    const numRows = 4
    const numCols = 3

    const rowHeight = innerHeight / numRows
    const colWidth1 = chartWidth1 / numCols

    // Add horizontal grid lines in the Past 12 Months section
    for (let i = 1; i < numRows; i++) {
      svg
        .append('line')
        .attr('x1', margin.left)
        .attr('y1', margin.top + i * rowHeight)
        .attr('x2', margin.left + chartWidth1)
        .attr('y2', margin.top + i * rowHeight)
        .attr('stroke', strokeNeutralSoftInitial)
        .attr(strokeWidth, 1)
        .lower()
    }

    // Add vertical grid lines in the Past 12 Months section
    for (let i = 1; i < numCols - 1; i++) {
      svg
        .append('line')
        .attr('x1', margin.left + i * colWidth1)
        .attr('y1', margin.top)
        .attr('x2', margin.left + i * colWidth1)
        .attr('y2', margin.top + innerHeight)
        .attr('stroke', strokeNeutralSoftInitial)
        .attr(strokeWidth, 1)
        .lower()
    }
    svg
      .append('text')
      .attr('x', margin.left + colWidth1)
      .attr('y', margin.top + innerHeight + 20) // Below the chart
      .attr(textAnchor, 'middle')
      .attr('class', 'caption')
      .attr('fill', textNeutralStrong)
      .text(startDate) // Specific label for the third grid line
    svg
      .append('text')
      .attr(
        'x',
        margin.left + xScale(parseDate(data[data.length - 1].date) as Date),
      )
      .attr('y', margin.top + innerHeight + 20) // Below the chart
      .attr(textAnchor, 'middle')
      .attr('class', 'caption')
      .attr('fill', textNeutralStrong)

      .text(endDate) // Specific label for the third grid line

    // Add horizontal grid lines in the 12 Months Forecast section
    for (let i = 1; i < numRows; i++) {
      svg
        .append('line')
        .attr('x1', margin.left + chartWidth1)
        .attr('y1', margin.top + i * rowHeight)
        .attr('x2', width - margin.right)
        .attr('y2', margin.top + i * rowHeight)
        .attr('stroke', strokeNeutralSoftInitial)
        .attr(strokeWidth, 1)
        .lower()
    }

    // Draw x-axis with labels only for the first and last month

    svg
      .append('line')
      .attr(
        'x1',
        margin.left + xScale(parseDate(data[data.length - 1].date) as Date),
      )
      .attr('y1', margin.top)
      .attr(
        'x2',
        margin.left + xScale(parseDate(data[data.length - 1].date) as Date),
      )
      .attr('y2', margin.top + innerHeight)
      .attr('stroke', strokeNeutralSoftInitial)
      .attr(strokeWidth, 1)

      .lower()

    // Line generator for straight line
    const line = d3
      .line<{ date: string; value: number }>()
      .x((d) => xScale(parseDate(d.date) as Date))
      .y((d) => yScale(d.value)) // Straight line

    // Draw straight line
    g.append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('stroke', chartColor)
      .attr(strokeWidth, 1)
      .attr('d', line)

    // Draw circles for each data point with outline only
    g.selectAll('.dot')
      .data(data)
      .enter()
      .append('circle')
      .attr('class', 'dot')
      .attr('cx', (d) => xScale(parseDate(d.date) as Date))
      .attr('cy', (d) => yScale(d.value))
      .attr('r', 4)
      .attr('fill', (d) =>
        d == data[data.length - 1] ? 'var(--ChartBlackRock)' : 'white',
      ) // No fill
      .attr('stroke', chartColor) // Outline color
      .attr(strokeWidth, 1) // Outline width
      .each(function () {
        this.parentNode.appendChild(this)
      })
    // Dotted lines from the last point in the right half

    // Get Y positions for highest, average, and lowest data points
    const highestY = yScale(highValue as number)
    const lowestY = yScale(lowValue as number)
    const averageY = yScale(avgValue as number)

    const lastDataPoint = data[data.length - 1]
    if (lastDataPoint) {
      const lastY = yScale(lastDataPoint.value)

      // Blue dotted line to top (HIGH)
      const boxWidth = 65
      const boxHeight = 40
      const boxRadius = 5 // Border radius
      const boxShadow = '2px 2px 5px rgba(0, 0, 0, 0.1)' // Shadow effect

      // Function to create boxes
      const createBox = (
        x: number,
        y: number,
        label: string,
        value: string,
        textColor: string,
      ): void => {
        svg
          .append('rect')
          .attr('x', x)
          .attr('y', y)
          .attr('width', boxWidth)
          .attr('height', boxHeight)
          .attr('fill', 'var(--surfaceCanvasSecondaryInitial)')
          .attr('stroke', 'var(--strokeNeutralSoftInitial)')
          .attr('rx', boxRadius)
          .attr('ry', boxRadius)
          .style('filter', `drop-shadow(${boxShadow})`)

        svg
          .append('text')
          .attr('x', x + boxWidth / 2)
          .attr('y', y + 17)
          .attr(textAnchor, 'middle')
          .attr('class', 'caption')
          .attr('fill', textColor)
          .text(label)

        svg
          .append('text')
          .attr('x', x + boxWidth / 2)
          .attr('y', y + boxHeight - 7)
          .attr(textAnchor, 'middle')
          .attr('fill', textColor)
          .attr('class', 'caption')
          .text(value)
      }
      svg
        .append('line')
        .attr('x1', margin.left + chartWidth1)
        .attr('y1', margin.top + lastY)
        .attr('x2', width - margin.right - boxWidth / 2)
        .attr('y2', margin.top + highestY) // Horizontal to the highest point
        .attr('stroke', chartColor)
        .attr(strokeDasharray, '4 2')

      // Create the HIGH box aligned with the highest point
      createBox(
        width - margin.right - boxWidth + 10,
        margin.top + highestY - boxHeight / 2, // Align the box with the highest point
        t('companyDetails.chart.high'),
        `${formatCurrency({ amount: highValue, currency: currency })}`,
        chartColor,
      )

      // Draw the red dotted line for LOW
      svg
        .append('line')
        .attr('x1', margin.left + chartWidth1)
        .attr('y1', margin.top + lastY)
        .attr('x2', width - margin.right - boxWidth / 2)
        .attr('y2', margin.top + lowestY) // Horizontal to the lowest point
        .attr('stroke', 'var(--ChartMutualFunds)')
        .attr(strokeDasharray, '4 2')

      // Create the LOW box aligned with the lowest point
      createBox(
        width - margin.right - boxWidth + 10,
        margin.top + lowestY - boxHeight / 2, // Align the box with the lowest point
        t('companyDetails.chart.low'),
        `${formatCurrency({ amount: lowValue, currency: currency })}`,
        'var(--ChartMutualFunds)',
      )

      // Draw the black dotted line for AVERAGE
      svg
        .append('line')
        .attr('x1', margin.left + chartWidth1)
        .attr('y1', margin.top + lastY)
        .attr('x2', width - margin.right - boxWidth / 2)
        .attr('y2', margin.top + averageY) // Horizontal to the average point
        .attr('stroke', 'var(--chartBlackRockFunds)')
        .attr(strokeDasharray, '4 2')

      // Create the AVERAGE box aligned with the average point
      createBox(
        width - margin.right - boxWidth + 10,
        margin.top + averageY - boxHeight / 2, // Align the box with the average point
        t('companyDetails.chart.average'),
        `${formatCurrency({ amount: avgValue, currency: currency })}`,
        'var(--chartBlackRockFunds)',
      )
    }
  }, [
    data,
    dimensions,
    startDate,
    endDate,
    currency,
    highValue,
    lowValue,
    avgValue,
    t,
  ])

  return <svg ref={svgRef} width='100%' height='400px' />
}

export default AdvanceLineChart
