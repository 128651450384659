import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { BrokerTier, BrokerTierUserData } from '@interfaces'
import { getBrokerTier, getBrokerTierUserData } from '@services'

export const fetchBrokerTier = createAsyncThunk<BrokerTier, void>(
  'fetchBrokerTier',
  async () => getBrokerTier(),
)

export const fetchBrokerTierUserData = createAsyncThunk<
  BrokerTierUserData,
  void
>('fetchBrokerTierUserData', async () => getBrokerTierUserData())

export const setLiteAndProInfoInactive = createAction(
  'setLiteAndProInfoInactive',
)
export const setLiteAndProInfoNotInterested = createAction(
  'setLiteAndProInfoNotInterested',
)
export const resetActiveLiteAndProInfo = createAction(
  'resetActiveLiteAndProInfo',
)
