import { Fragment } from 'react'
import './news.styles.scss'
import { Card, Spacer, Text } from 'components'
import { format, formatDistanceToNow, isToday } from 'date-fns'
import { groupBy, orderBy } from 'lodash'
import { GetAllNews } from '@interfaces/companyOverview'
import { useAppSelector } from 'store'

type GroupedNews = {
  date: string
  news: {
    date: string
    title: string
    link: string
  }[]
}
const groupNewsByDate = (newsData: GetAllNews[]): GroupedNews[] => {
  const sortedNews = orderBy(newsData, (item) => new Date(item.date), ['desc'])

  const grouped = groupBy(sortedNews, (item) => {
    const date = new Date(item.date)
    if (isToday(date)) return 'Today'
    return format(date, 'dd MMMM')
  })

  return Object.entries(grouped).map(([date, news]) => ({
    date,
    news: news.map((item) => ({
      date:
        date === 'Today'
          ? formatDistanceToNow(new Date(item.date), { addSuffix: true })
          : null,
      title: item.title,
      link: item.link,
    })),
  }))
}

const News = (): JSX.Element => {
  const { newsData } = useAppSelector((state) => state.tipRank)

  const groupedNews = groupNewsByDate(newsData)
  return (
    <>
      <Spacer preset='small' />
      <div className='d-flex d-flex-col align-start w-100 tab-scroll-area'>
        {groupedNews.map((group, index) => (
          <Fragment key={index}>
            <Text text={group.date} preset='bodyBig' />
            <Spacer preset='small' />
            <div className=' news-container'>
              {group.news.map((value, groupIndex) => (
                <Card
                  onClick={() => {
                    window.open(value.link)
                  }}
                  key={groupIndex}
                  width='full'
                  aria-hidden
                  padding='smaller'
                >
                  <Text text={value.date} preset='bodyRegular' />
                  <Spacer preset='tiny' />
                  <Text text={value.title} preset='subtitle2' />
                  <Spacer preset='tiny' />
                </Card>
              ))}
            </div>
            <Spacer preset='small' />
          </Fragment>
        ))}
      </div>
    </>
  )
}

export default News
