import { BasicLink, Button, CustomModal, Spacer, Text } from '@components'
import { useTranslation } from '@hooks'

export const LogoutWarningModal = ({
  doLogOut,
  close,
}: {
  doLogOut: () => void
  close: () => void
}): JSX.Element => {
  const { t } = useTranslation()

  return (
    <CustomModal
      show={true}
      theme='lighter'
      size='small'
      blockScrollWhileOpen={false}
      onClose={close}
    >
      <CustomModal.Header text={t('profile.logout')} />
      <CustomModal.Content>
        <Spacer preset='tiny' />
        <Text
          preset='paragraph1'
          text={t('profile.logout_warning')}
          align='center'
        />
        <Spacer preset='tiny' />
      </CustomModal.Content>
      <CustomModal.Footer>
        <div className='d-flex d-flex-col w-100'>
          <Button
            buttonType='negative'
            onClick={doLogOut}
            text={t('profile.logout')}
          />
          <BasicLink
            text={t('common.cancel')}
            type='action'
            className='w-100 mt-3'
            onClick={close}
          />
        </div>
      </CustomModal.Footer>
    </CustomModal>
  )
}
