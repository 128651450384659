import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react'
import { getDaysPerMonth } from '@utils/helpers'
import { useTranslation } from 'hooks/useTranslation'
import { useBuildCalendarMatrix } from './useBuildCalendarMatrix'

export function useHandleMonth({
  selectedMonth,
  selectedYear,
  setSelectedMonth,
  setSelectedYear,
}: {
  selectedMonth: number
  selectedYear: number
  setSelectedMonth: Dispatch<SetStateAction<number>>
  setSelectedYear: Dispatch<SetStateAction<number>>
}) {
  return (direction: 'L' | 'R'): void => {
    if (direction === 'R') {
      const nextM = selectedMonth + 1
      const nextY = selectedYear + 1
      const newY = nextM === 12 ? nextY : selectedYear
      setSelectedMonth(newY === nextY ? 0 : nextM)
      if (newY) setSelectedYear(newY)
      return
    }
    const prevM = selectedMonth - 1
    const prevY = selectedYear - 1
    const newY = prevM === -1 ? prevY : selectedYear
    setSelectedMonth(newY === prevY ? 11 : prevM)
    if (newY) setSelectedYear(newY)
  }
}

export interface UseCalendarReturnValues {
  weekDays: string[]
  selectedMonth: number
  selectedYear: number
  getPreviousMonth: () => number
  setSelectedYear: (v: number) => void
  setSelectedMonth: (v: number) => void
  handleMonth: (direction: 'L' | 'R') => void
  handleCancel: () => void
  handleRestore: () => void
  handleSubmit: () => void
  handleSelected: (day: number) => void
  submitDisabled: boolean
  monthsMatrix: {
    key: string
    day: number
    disabled: boolean
    selected: boolean
  }[][]
  month: string
}

export interface UseCalendarProps {
  minDate?: string // ISO string yyyy-mm-dd
  maxDate?: string // ISO string yyyy-mm-dd
  selectedDates: string[] // ISO string yyyy-mm-dd
  onCancel?: () => void
  onSubmit?: (date: string[]) => void
  onRestore?: () => void
  onChange: (currentSelection: string[], newValue: string) => void
  options: {
    allowSatAndSun: boolean
  }
  initialMonth?: number
  initialYear?: number
}

export function useCalendar({
  onChange,
  options,
  selectedDates,
  maxDate,
  minDate,
  onCancel,
  onRestore,
  onSubmit,
  initialMonth,
  initialYear,
}: UseCalendarProps): UseCalendarReturnValues {
  const { t } = useTranslation()
  const allowSatAndSun = options?.allowSatAndSun
  const today = useMemo(() => new Date(new Date().setHours(0, 0, 0, 0)), [])

  const getSelectedYear = useCallback((): number => {
    if (initialYear) return initialYear
    if (selectedDates?.length === 0 && selectedDates?.[0])
      new Date(selectedDates?.[0])?.getFullYear()

    return today?.getFullYear()
  }, [initialYear, selectedDates, today])

  const getSelectedMonth = useCallback((): number => {
    if (initialMonth) return initialMonth
    if (selectedDates?.length === 0 && selectedDates?.[0])
      new Date(selectedDates?.[0])?.getMonth()

    return today?.getMonth()
  }, [initialMonth, selectedDates, today])

  const [selectedYear, setSelectedYear] = useState<number>(getSelectedYear())

  const [selectedMonth, setSelectedMonth] = useState<number>(getSelectedMonth())

  const currentYear = initialYear
  const currentMonth = initialMonth

  const months = Array(12)
    .fill(null)
    .map((_, i) => `calendar.months.${i}`)
  const weekDays = Array(7)
    .fill(null)
    .map((_, i) => `calendar.days.${i}`)

  const nDays = useMemo(
    (): number[] => getDaysPerMonth(currentYear),
    [currentYear],
  )

  const firstDay = useMemo(() => {
    /**
     * Subtracting 1 from the result of getDay() means that if the first day of the month is a Monday (1),
     * the result will be 0. If the first day of the month is a Tuesday (2), the result will be 1, and so on until Saturday (6).
     * If the first day of the month is a Sunday (0), the result will be -1.
     */
    const day =
      new Date(Date.UTC(selectedYear, selectedMonth, 1)).getUTCDay() - 1

    // -1 means first week day starts on Sunday
    if (day === -1) {
      return 6 // Sunday
    }

    return day
  }, [selectedMonth, selectedYear])

  const getPreviousMonth = useCallback(() => {
    let prevMonthCounter = nDays[selectedMonth - 1]

    // is beggining of the year
    if (currentMonth === 0) {
      // get past year last month days
      const prevYearLastMonth = getDaysPerMonth(selectedYear - 1)

      prevMonthCounter = prevYearLastMonth[prevYearLastMonth.length - 1] //december
    }

    // Number of days prev month - 1st day for current month +1 because last day counts
    return prevMonthCounter - firstDay + 1
  }, [currentMonth, firstDay, selectedMonth, selectedYear, nDays])

  const handleMonth = useHandleMonth({
    selectedMonth,
    selectedYear,
    setSelectedMonth,
    setSelectedYear,
  })

  const restoreValues = useCallback((): void => {
    setSelectedMonth(getSelectedMonth())
    setSelectedYear(getSelectedYear())
    onChange([], null)
  }, [getSelectedMonth, getSelectedYear, onChange])

  const handleSelected = useCallback(
    (day: number): void => {
      const date = Date.UTC(
        Number(selectedYear),
        Number(selectedMonth),
        day,
        0,
        0,
        0,
        0,
      )

      const dateISO = new Date(date)?.toISOString()?.substring(0, 10)

      onChange(selectedDates, dateISO)
    },

    [selectedYear, selectedMonth, onChange, selectedDates],
  )

  const handleCancel = (): void => {
    restoreValues()
    if (onCancel) onCancel()
  }
  const handleRestore = (): void => {
    restoreValues()
    if (onRestore) onRestore()
  }

  const handleSubmit = (): void => {
    onSubmit(selectedDates)
  }

  const submitDisabled = useMemo(() => !selectedDates?.length, [selectedDates])

  const monthsMatrix = useBuildCalendarMatrix({
    firstDay,
    getPreviousMonth,
    nDays,
    selectedDates,
    selectedMonth,
    selectedYear,
    allowSatAndSun,
    maxDate,
    minDate,
  })

  return {
    weekDays,
    selectedYear,
    getPreviousMonth,
    setSelectedYear,
    setSelectedMonth,
    handleMonth,
    handleSelected,
    handleCancel,
    handleRestore,
    handleSubmit,
    submitDisabled,
    monthsMatrix,
    month: t(months[selectedMonth]),
    selectedMonth,
  }
}
