import { BigSpinner, IntersectionObserverComp, Show, Spacer } from '@components'
import { useTranslation } from '@hooks'
import { ReactComponent as SpinnerIcon } from '@assets/svg/spinner.svg'
import { SearchEmpty } from '@assets/illustrations'
import { DiscoverTab, StocksData } from '@interfaces'
import { BasicAssetsTable } from '@shared/components/tables'

export default function ResultsContainer({
  handleNextPage,
  paginationLoader,
  loading,
  totalPages,
  currentPage,
  query,
  data,
  activeTab,
}: {
  paginationLoader: boolean
  loading: boolean
  totalPages: number
  currentPage: number
  query: string
  data: StocksData
  handleNextPage?: () => void
  activeTab: DiscoverTab
}): JSX.Element {
  const { t } = useTranslation()

  return (
    <div className='home__widget__portfolio'>
      <Show
        when={!loading}
        fallback={
          <div className='discover__loader d-flex d-flex-col align-center justify-center'>
            <BigSpinner />
          </div>
        }
      >
        <Show when={!data?.stocks?.length}>
          <div className='discover__notfound px-2 py-4'>
            <div className='discover__notfound__wrapper'>
              <SearchEmpty />
              <h3 className='title mt-4 mb-1'>
                {query
                  ? t('search.notFoundQuery', { query: query })
                  : t('common.weCouldntFindResults')}
              </h3>
              <p className='subtitle'>
                {query ? t('search.tryAgain') : t('search.tryAgainFilters')}
              </p>
            </div>
          </div>
        </Show>
        <Show when={data?.stocks?.length > 0}>
          <div className='home__widget__portfolio__content mb-5'>
            <BasicAssetsTable
              assets={data?.stocks}
              screen={`Discover screen - Tab: ${activeTab}`}
            />
          </div>
          <Show when={!loading && totalPages - 1 > currentPage}>
            <>
              <IntersectionObserverComp
                handler={() => {
                  if (handleNextPage && !paginationLoader && !loading) {
                    handleNextPage()
                  }
                }}
                options={{
                  rootMargin: '24px',
                  threshold: 1.0,
                }}
              />
              <Spacer preset='huge' />
              <SpinnerIcon className='spinner spinner--orange' />
              <Spacer preset='huge' />
            </>
          </Show>
        </Show>
      </Show>
    </div>
  )
}
