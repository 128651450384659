import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import { fetchCurrenciesExchanges } from './thunk'
import { CurrenciesExchangesState, CurrenciesExchanges } from '@interfaces'

const initialState: CurrenciesExchangesState = {
  loading: false,
  error: null,
  currenciesExchanges: {
    target: '',
    exchanges: [],
    cacheDurationInSeconds: 0,
  },
}

export const currenciesExchangesReducer = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(fetchCurrenciesExchanges.pending, (acc) => {
        acc.loading = true
        acc.error = null
      })
      .addCase(
        fetchCurrenciesExchanges.fulfilled,
        (acc, action: PayloadAction<CurrenciesExchanges>) => {
          acc.currenciesExchanges = action.payload
          acc.loading = false
          acc.error = null
        },
      )
      .addCase(fetchCurrenciesExchanges.rejected, (acc, { error }) => {
        acc.loading = false
        acc.error = error.message || ''
      })
  },
)
