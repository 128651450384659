import { WatchlistState } from '@interfaces'
import { api } from '@utils/api/api'

export const getStocksWatchlist = (page = 0): Promise<WatchlistState> => {
  return api.get(`/api/v1/mobile/customers/me/stocks-watchlist?page=${page}`)
}

export const getStockWatchlistState = (
  stockId: string,
): Promise<{ uuid: string }> => {
  return api.get(`/api/v1/customers/me/stocks-watchlists/${stockId}`)
}

export const setStockWatchlistState = (stockId: string): Promise<void> => {
  return api.post(`/api/v1/customers/me/stocks-watchlists/${stockId}`)
}

export const unsetStockWatchlistState = (stockId: string): Promise<void> => {
  return api.delete(`/api/v1/customers/me/stocks-watchlists/${stockId}`)
}
