import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import { Investments, ReducerState } from '@interfaces'
import { fetchInvestments } from './thunk'

const initialState: Investments & ReducerState = {
  currentValue: { amount: 0, currency: 'USD' },
  totalEarnings: { amount: 0, currency: 'USD' },
  totalEarningsToday: { amount: 0, currency: 'USD' },
  totalPerformanceToday: 0,
  totalPerformance: 0,
  portfolios: [],
  error: null,
  loading: false,
  hardLoading: true,
  id: null,
}

export const reducers = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchInvestments.pending, (acc) => {
      acc.loading = true
      acc.error = null
    })
    .addCase(
      fetchInvestments.fulfilled,
      (acc, action: PayloadAction<Investments>) => {
        const { payload } = action
        acc.currentValue = payload.currentValue
        acc.totalEarnings = payload.totalEarnings
        acc.totalEarningsToday = payload.totalEarningsToday
        acc.totalPerformanceToday = payload.totalPerformanceToday
        acc.totalPerformance = payload.totalPerformance
        acc.portfolios = payload.portfolios
        acc.id = payload.id
        acc.loading = false
        acc.error = null
        acc.hardLoading = false
      },
    )
    .addCase(fetchInvestments.rejected, (acc, { error }) => {
      acc.loading = false
      acc.error = error.message || ''
      acc.hardLoading = false
    })
})

export default reducers
