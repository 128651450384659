import { UIIcon, UIIconColorProps, getIconColorSVGFill } from 'components/utils'

export const ReceiptIcon = ({
  color,
  size = 24,
}: UIIcon & UIIconColorProps): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.99 2.21005L21 2.20995H20.99V2.21005ZM20.99 2.21005C20.8634 2.21255 20.7377 2.26252 20.65 2.35995L19.86 3.14995C19.66 3.34995 19.35 3.34995 19.15 3.14995L18.36 2.35995C18.16 2.15995 17.85 2.15995 17.65 2.35995L16.86 3.14995C16.66 3.34995 16.35 3.34995 16.15 3.14995L15.36 2.35995C15.16 2.15995 14.85 2.15995 14.65 2.35995L13.86 3.14995C13.66 3.34995 13.35 3.34995 13.15 3.14995L12.36 2.35995C12.16 2.15995 11.85 2.15995 11.65 2.35995L10.86 3.14995C10.66 3.34995 10.35 3.34995 10.15 3.14995L9.35 2.34995C9.15 2.14995 8.84 2.14995 8.64 2.34995L7.85 3.14995C7.65 3.34995 7.34 3.34995 7.14 3.14995L6.35 2.34995C6.15 2.14995 5.84 2.14995 5.64 2.34995L4.85 3.14995C4.65 3.34995 4.34 3.34995 4.14 3.14995L3.35 2.34995C3.26 2.25995 3.13 2.20995 3 2.20995V21.8C3.13 21.8 3.26 21.75 3.35 21.65L4.14 20.86C4.34 20.66 4.65 20.66 4.85 20.86L5.64 21.65C5.84 21.85 6.15 21.85 6.35 21.65L7.14 20.86C7.34 20.66 7.65 20.66 7.85 20.86L8.64 21.65C8.84 21.85 9.15 21.85 9.35 21.65L10.14 20.86C10.34 20.66 10.65 20.66 10.85 20.86L11.64 21.65C11.84 21.85 12.15 21.85 12.35 21.65L13.14 20.86C13.34 20.66 13.65 20.66 13.85 20.86L14.64 21.65C14.84 21.85 15.15 21.85 15.35 21.65L16.14 20.86C16.34 20.66 16.65 20.66 16.85 20.86L17.64 21.65C17.84 21.85 18.15 21.85 18.35 21.65L19.14 20.86C19.34 20.66 19.65 20.66 19.85 20.86L20.64 21.65C20.74 21.75 20.87 21.8 20.99 21.8V2.21005ZM7 17H17C17.55 17 18 16.55 18 16C18 15.45 17.55 15 17 15H7C6.45 15 6 15.45 6 16C6 16.55 6.45 17 7 17ZM17 13H7C6.45 13 6 12.55 6 12C6 11.45 6.45 11 7 11H17C17.55 11 18 11.45 18 12C18 12.55 17.55 13 17 13ZM7 8.99995H17C17.55 8.99995 18 8.54995 18 7.99995C18 7.44995 17.55 6.99995 17 6.99995H7C6.45 6.99995 6 7.44995 6 7.99995C6 8.54995 6.45 8.99995 7 8.99995Z'
        fill={color ? getIconColorSVGFill(color) : 'currentColor'}
      />
    </svg>
  )
}
