import { createAsyncThunk } from '@reduxjs/toolkit'
import { getUserProfile } from '@services'
import { UserProfileInfo } from '@interfaces'

export const fetchUserProfile = createAsyncThunk<UserProfileInfo>(
  'userProfile',
  async () => {
    return await getUserProfile()
  },
)
