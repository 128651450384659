export const enum CompanyOverviewTab {
  overview = 'Overview',
  analystRatings = 'Analyst ratings',
  earnings = 'Earnings',
  news = 'News',
  bullsAndBears = 'Bulls/Bears Say',
}

export interface AnalystRatings {
  totalAnalysts: number
  buy: number
  sell: number
  hold: number
  consensus: string
  analystRatingsFetched: boolean
}

export interface ClosingPrices {
  amount: number
  currency: string
  date: string
}

export interface Forecast {
  lowPriceTarget: number
  highPriceTarget: number
  priceTarget: number
  estimatedPriceChangePercentage: number
  lastPrice: number
  priceTargetCurrencyCode: string
  monthlyClosingPrices: ClosingPrices[]
  forecastFetched: boolean
}
export interface BullishBearish {
  date: string
  bullishSummary: string
  bearishSummary: string
}
export interface GetAllNews {
  date: string
  link: string
  title: string
}

export interface Earning {
  quarter: number
  year: number
  epsActual: number
  epsEstimate: number
  revenueActual: number
  revenueEstimate: number
  currency: string
}
export interface Earnings {
  earnings: Earning[]
  earningsLoading: boolean
  earningsError: string | null
  earningsFetched: boolean
}
