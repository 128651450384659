import { createReducer } from '@reduxjs/toolkit'
import {
  AssetType,
  FilterValue,
  FocusedFilter,
  SelectedFilters,
} from '@interfaces'
import { RawTreeNode, TreeNode } from 'tr33'
import {
  clearErrors,
  clearFilters,
  clearFiltersSelection,
  createFiltersSelection,
  fetchSearchFilters,
  selectCurrentFilters,
  setAssetTypes,
  setFocusedFilter,
  updateFilter,
} from './filters.thunk'
import {
  findNode,
  getFilterSelectedOptions,
  getTrees,
  prepareTreeStructure,
} from '@utils/filters'
import { FiltersResponse } from '@interfaces/searchFilters'

export interface DiscoverFilterState {
  filters: Array<RawTreeNode<FilterValue>>
  filtersResponse: FiltersResponse
  selected?: SelectedFilters
  focusedFilter?: FocusedFilter
  loading: boolean
  error: string | null
  currentAssetTypes: Array<AssetType>
}

const initialState: DiscoverFilterState = {
  filters: null,
  filtersResponse: null,
  error: null,
  currentAssetTypes: [],
  focusedFilter: {
    id: null,
    rawValue: null,
    isRoot: false,
    parentIsTree: null,
    options: null,
    parentId: null,
  },
  loading: false,
  selected: null,
}

export const filtersReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchSearchFilters.pending, (acc: DiscoverFilterState) => {
      acc.loading = true
      acc.error = null
      acc.selected = null
      acc.focusedFilter.id = null
      acc.focusedFilter.rawValue = null
    })
    .addCase(fetchSearchFilters.fulfilled, (acc, action) => {
      acc.filtersResponse = action?.payload || []
      acc.loading = false
    })
    .addCase(fetchSearchFilters.rejected, (acc, { error }) => {
      acc.error = error.message
      acc.loading = false
      acc.filters = null
    })
    .addCase(setFocusedFilter, (acc, { payload }) => {
      acc.focusedFilter.id = payload
      const trees = getTrees(acc.filters)
      const tree = findNode(trees, payload)

      if (tree) {
        acc.focusedFilter.parentId = tree?.getParentId()
        acc.focusedFilter.isRoot = tree?.isRoot()
        acc.focusedFilter.rawValue = tree?.value()

        acc.focusedFilter.options = tree
          .find(acc.focusedFilter.id)
          ?.children()
          .map((a) => a.value())
      }
    })
    .addCase(updateFilter, (acc, { payload }) => {
      const trees = getTrees(acc.filters)
      const node = findNode(trees, payload?.id)

      const getNewValue = (
        value: boolean | undefined,
        allChildrenSelected?: boolean,
      ): boolean | null | undefined => {
        switch (payload?.type) {
          case 'toggle':
            return !value
          case 'reset':
            return undefined
          case 'selectAll':
            return !allChildrenSelected
        }
      }

      function updateNodeValue(
        node: TreeNode<FilterValue>,
        value?: boolean | null | undefined,
      ): void {
        const nodeValue = node.value()?.value
        const children = node.children()

        node.update({
          ...nodeValue,
          checked: value,
        })

        if (children?.length) {
          children?.forEach((c) => updateNodeValue(c, value))
        }
      }

      if (node) {
        const nodeValue = node.value()?.value
        const children = node.children()
        const allChildrenSelected: boolean =
          children?.length > 0 &&
          children.every((c) => !!c?.value()?.value?.checked)

        const newValue = getNewValue(
          nodeValue.checked as boolean | undefined,
          allChildrenSelected,
        )

        updateNodeValue(node, newValue)
      }

      acc.filters = trees.map((n) => n?.value())

      // Update visible options
      const focusedFilterNode = findNode(trees, acc.focusedFilter.id)
      const focusedFilterChildren = focusedFilterNode?.children()
      acc.focusedFilter.options = focusedFilterChildren?.map((i) => i?.value())
    })
    .addCase(createFiltersSelection, (acc) => {
      const selection = []

      acc.filters.forEach((f: RawTreeNode<FilterValue>) => {
        selection.push(...getFilterSelectedOptions(f))
        if (f?.children) {
          f?.children?.forEach((o) => {
            selection.push(...getFilterSelectedOptions(o, f.id))
          })
        }
      })

      let newSelection = {}

      selection.forEach((sel: unknown) => {
        newSelection = Object.keys(sel).reduce(
          (obj: SelectedFilters, item: string) => {
            if (!obj) {
              obj = {}
            }
            obj[item] = obj[item]
              ? `${obj[item]},${sel[item] as string}`
              : sel[item]
            return obj
          },
          newSelection,
        )
      })

      acc.selected = newSelection
    })
    .addCase(setAssetTypes, (acc, { payload }) => {
      acc.currentAssetTypes = payload
    })
    .addCase(selectCurrentFilters, (acc) => {
      const filtered = acc.filtersResponse?.filter((f) =>
        f?.assetTypes?.some((a) => acc.currentAssetTypes?.includes(a)),
      )
      const treeStructure = prepareTreeStructure(filtered || [], 0)
      acc.filters = getTrees(treeStructure).map((i) => i.value())
    })
    .addCase(clearFilters, (acc) => {
      acc.loading = false
      acc.error = null
      acc.selected = null
      acc.focusedFilter.id = null
      acc.focusedFilter.isRoot = null
      acc.focusedFilter.parentId = null
      acc.focusedFilter.parentIsTree = null
      acc.currentAssetTypes = []
    })
    .addCase(clearFiltersSelection, (acc) => {
      acc.selected = null
    })
    .addCase(clearErrors, (acc) => {
      acc.error = null
    })
})

export default filtersReducer
