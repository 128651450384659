import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { getCompanyInvestments } from '@services'
import { CompanyPositionData } from '@interfaces'

interface InvestmentBody {
  ticker: string
}

export const fetchCompanyInvestments = createAsyncThunk<
  CompanyPositionData,
  InvestmentBody
>('fetchCompanyInvestments', ({ ticker }) => getCompanyInvestments(ticker))

export const clearAssetPosition = createAction('clearAssetPosition')
