import { ChevronRight, InfoIcon } from '@assets/icons'
import { addDays, addMonths } from 'date-fns'
import {
  Button,
  CustomModal,
  CustomModalProps,
  SelectorInput,
  GoBack,
  Spacer,
  Text,
  SpacerProps,
  Calendar,
  Divider,
  useCalendar,
} from '@components'
import { useTranslation } from '@hooks'
import { getLang } from '@utils/langs'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { OptionSelectorModal } from '../optionSelectionModal/optionSelectionModal'
import { PeriodicityEnum } from '@interfaces'
import { calculatePaymentsAmount } from './utils'
import { setRecurringPaymentOptions } from 'features/moneyOperations/topup/thunk'
import { useDispatch } from 'react-redux'
import { RecurringTopUpInfo } from '../recurringTopUpInfo/recurringTopUpInfo'

export interface BasicOption<K> {
  title: string
  description: string
  value: K
  type?: 'DEFAULT' | 'CUSTOM'
}

export interface RecurringPaymentResult {
  startingDate: BasicOption<Date>
  periodicity: BasicOption<PeriodicityEnum>
  expiration: BasicOption<Date>
}

export const RecurrinPaymentConfigModal = ({
  opts = {},
  close,
}: {
  opts?: CustomModalProps
  close: () => void
}): JSX.Element => {
  const { t } = useTranslation()
  const locale = getLang()

  const [showHowItWorks, setShowHowItWorks] = useState(false)

  const [today] = useState(new Date().setHours(0, 0, 0, 0))

  const [startingDateSelection, setStartingDateSelection] =
    useState<string>(null)
  const [expirationDateSelection, setExpirationDateSelection] = useState(null)

  const dispatch = useDispatch()

  const [showStartingDateModal, setShowStartingDateModal] = useState(false)
  const [showPeriodicityModal, setShowPeriodicityModal] = useState(false)
  const [showExpirationModal, setShowExpirationModal] = useState(false)
  const [startingDayCalendarOpen, setStartingDayCalendarOpen] = useState(false)
  const [expirationCalendarOpen, setExpirationCalendarOpen] = useState(false)

  const toLongStyle = useCallback(
    (date: Date): string => {
      return date?.toLocaleDateString(locale, {
        dateStyle: 'long',
      })
    },
    [locale],
  )

  const startingDateOptions = useMemo((): BasicOption<Date>[] => {
    const res: BasicOption<Date>[] = []

    const getDayName = (days: number): string => {
      return addDays(new Date(), days).toLocaleString(locale, {
        weekday: 'long',
      })
    }

    const getDate = (days: number): Date => {
      return new Date(addDays(new Date(today), days))
    }

    res.push(
      ...[1, 2, 3, 4, 5, 6, 7].map(
        (n) =>
          ({
            title: n === 1 ? t('recurringPayments.tomorrow') : getDayName(n),
            description: toLongStyle(getDate(n)),
            value: getDate(n),
            type: 'DEFAULT',
          } as BasicOption<Date>),
      ),
    )

    return res
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [today])

  const getPeriodicityDesc = (value: PeriodicityEnum): string => {
    switch (value) {
      case PeriodicityEnum.bimonthly:
        return t('recurringPayments.periodicity.bimonthlyDesc')
      case PeriodicityEnum.biweekly:
        return t('recurringPayments.periodicity.biweeklyDesc')
      case PeriodicityEnum.monthly:
        return t('recurringPayments.periodicity.monthlyDesc')
      case PeriodicityEnum.weekly:
        return t('recurringPayments.periodicity.weeklyDesc')
    }
  }

  const expirationOptions = useMemo((): BasicOption<Date>[] => {
    const getDate = (months: number): Date =>
      new Date(addMonths(new Date(today), months))

    const expirationLiteral = 'recurringPayments.expiration.months'

    return [
      {
        title: t('recurringPayments.expiration.month', { amount: 1 }),
        description: toLongStyle(getDate(1)),
        value: getDate(1),
      },
      {
        title: t(expirationLiteral, { amount: 2 }),
        description: toLongStyle(getDate(2)),
        value: getDate(2),
      },
      {
        title: t(expirationLiteral, { amount: 4 }),
        description: toLongStyle(getDate(4)),
        value: getDate(4),
      },
      {
        title: t(expirationLiteral, { amount: 6 }),
        description: toLongStyle(getDate(6)),
        value: getDate(6),
      },
      {
        title: t('recurringPayments.expiration.year', { amount: 1 }),
        description: toLongStyle(getDate(12)),
        value: getDate(12),
      },
    ]
  }, [t, toLongStyle, today])

  const periodicityOptions: BasicOption<PeriodicityEnum>[] = [
    {
      title: t('recurringPayments.periodicity.weekly'),
      description: getPeriodicityDesc(PeriodicityEnum.weekly),
      value: PeriodicityEnum.weekly,
    },
    {
      title: t('recurringPayments.periodicity.biweekly'),
      description: getPeriodicityDesc(PeriodicityEnum.biweekly),
      value: PeriodicityEnum.biweekly,
    },
    {
      title: t('recurringPayments.periodicity.monthly'),
      description: getPeriodicityDesc(PeriodicityEnum.monthly),
      value: PeriodicityEnum.monthly,
    },
    {
      title: t('recurringPayments.periodicity.bimonthly'),
      description: getPeriodicityDesc(PeriodicityEnum.bimonthly),
      value: PeriodicityEnum.bimonthly,
    },
  ]

  const [startingDate, setStartingDate] = useState<BasicOption<Date>>(
    startingDateOptions[0],
  )
  const [periodicity, setPeriodicity] = useState<BasicOption<PeriodicityEnum>>(
    periodicityOptions[0],
  )
  const [expiration, setExpiration] = useState<BasicOption<Date>>(
    expirationOptions[3],
  )

  const periodicityDesc = getPeriodicityDesc(periodicity?.value)

  const [paymentsAmount, setPaymentsAmount] = useState<number>(null)

  const dateOptions: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  }

  const customLiteral = t('common.custom')

  const customExpirationDayOpt: BasicOption<Date> = {
    title: customLiteral,
    description: t('common.selectYourPreferredDate'),
    value: null,
    type: 'CUSTOM',
  }

  const customStartingDayOpt: BasicOption<Date> = {
    title: customLiteral,
    description: t('common.selectYourPreferredDate'),
    value: null,
    type: 'CUSTOM',
  }

  useEffect(() => {
    const paymentsInfo = calculatePaymentsAmount(
      startingDate?.value,
      periodicity?.value,
      expiration?.value,
    )
    setPaymentsAmount(paymentsInfo?.totalPayments)
  }, [startingDate, periodicity, expiration])

  const handleClose = (): void => {
    dispatch(
      setRecurringPaymentOptions({
        expiration: {
          ...expiration,
          value: expiration?.value?.toISOString()?.substring(0, 10),
        },
        startingDate: {
          ...startingDate,
          value: startingDate?.value?.toISOString()?.substring(0, 10),
        },
        periodicity: periodicity,
        paymentsCount: Number(paymentsAmount || 0),
      }),
    )

    close()
  }

  const renderOption = ({
    option,
  }: {
    option: { title: string; description: string }
  }): React.ReactNode => {
    return (
      <div className='w-100 d-flex d-flex-col --capitalize'>
        <Text text={option?.title} preset='paragraph2' color='primary' />
        <Text
          text={option?.description}
          preset='paragraph2'
          color='secondary'
        />
      </div>
    )
  }

  const spacerOpts: SpacerProps = { preset: 'medium' }

  const transactionsAmount = `${t(
    paymentsAmount === 1
      ? 'recurringPayments.transactionAmount'
      : 'recurringPayments.transactionsAmount',
    {
      amount: paymentsAmount,
    },
  )}`

  const startingDateCalendar = useCalendar({
    onChange: (_: string[], newDate: string) => {
      setStartingDateSelection(newDate)
    },
    onCancel: () => {
      setStartingDayCalendarOpen(false)
      setStartingDateSelection(null)
    },
    onSubmit: (e) => {
      setStartingDateSelection(e?.[0])
      setStartingDate({
        ...customStartingDayOpt,
        description: toLongStyle(new Date(e?.[0])),
        value: new Date(e?.[0]),
      })
      setStartingDayCalendarOpen((v) => !v)
    },
    options: {
      allowSatAndSun: false,
    },
    selectedDates: startingDateSelection ? [startingDateSelection] : [],
    minDate: new Date().toISOString().substring(0, 10),
  })

  const expirationCalendar = useCalendar({
    onChange: (_: string[], newDate: string) => {
      setExpirationDateSelection(newDate)
    },
    onCancel: () => {
      setExpirationDateSelection(false)
      setExpirationCalendarOpen(null)
    },
    onSubmit: (e) => {
      setExpiration({
        ...customExpirationDayOpt,
        description: toLongStyle(new Date(e?.[0])),
        value: new Date(e?.[0]),
      })
      setExpirationCalendarOpen((v) => !v)
    },
    options: {
      allowSatAndSun: false,
    },
    selectedDates: expirationDateSelection ? [expirationDateSelection] : [],
    minDate: new Date().toISOString().substring(0, 10),
  })

  const startingDateTitle = t('recurringPayments.date.starting')

  return (
    <>
      <CustomModal
        show={true}
        theme='lighter'
        size='big'
        height='fitContent'
        dismissable
        onClose={handleClose}
        {...opts}
      >
        <CustomModal.Header
          text={t('recurringPayments.typeModal.recurringTitle')}
        >
          <GoBack goBack={handleClose} />
        </CustomModal.Header>
        <CustomModal.Content>
          <Spacer preset='tiny' />
          <div
            className='d-flex justify-end align-center cursor-pointer'
            onClick={() => setShowHowItWorks(true)}
            aria-hidden
          >
            <Text preset='paragraph2' text={'How it works'} />
            <Spacer preset='tiny' />
            <InfoIcon />
          </div>
          <Spacer preset='huge' />
          <Text
            preset='headline1'
            text={t('recurringPayments.createScreen.title')}
          />
          <Spacer preset='tiny' />
          <Text
            preset='paragraph2'
            text={t('recurringPayments.createScreen.subtitle')}
            color='secondary'
          />
          <Spacer preset='largeMinus' />
          <SelectorInput
            label={startingDateTitle}
            selection={startingDate?.title || startingDateOptions[0]?.title}
            description={new Date(startingDate?.value).toLocaleString(
              locale,
              dateOptions,
            )}
            onClick={() => setShowStartingDateModal(true)}
          />
          <Spacer preset='smallPlus' />
          <SelectorInput
            label={t('recurringPayments.periodicity.periodicity')}
            selection={periodicity?.title || periodicityOptions[0]?.title}
            description={periodicityDesc}
            onClick={() => setShowPeriodicityModal(true)}
          />
          <Spacer preset='smallPlus' />
          <SelectorInput
            label={t('recurringPayments.date.expiration')}
            selection={expiration?.title}
            description={toLongStyle(expiration?.value)}
            onClick={() => setShowExpirationModal(true)}
          />
          <Spacer preset='tiny' />
          {paymentsAmount ? (
            <div className='d-flex justify-end align-center' aria-hidden>
              <Text
                preset='paragraph2'
                text={transactionsAmount}
                color='secondary'
              />
            </div>
          ) : null}
          <Spacer preset='huge' />
        </CustomModal.Content>
        <CustomModal.Footer>
          <Button
            text={t('recurringPayments.setButtons.recurringPayment')}
            disabled={!startingDate || !periodicity || !expiration}
            onClick={handleClose}
            buttonType='primary'
          />
        </CustomModal.Footer>
      </CustomModal>
      {showHowItWorks && (
        <RecurringTopUpInfo onClose={() => setShowHowItWorks(false)} />
      )}
      {showStartingDateModal && (
        <OptionSelectorModal<BasicOption<Date>, Date>
          title={startingDateTitle}
          options={startingDateOptions}
          currentOption={startingDate}
          onChange={(opt) => setStartingDate(opt)}
          onClose={() => setShowStartingDateModal(false)}
          renderOption={({ option }) => renderOption({ option })}
          spacerOptions={spacerOpts}
          bottomContent={
            <>
              <Spacer preset='medium' />
              <div>
                <div className='--no-modal-padding'>
                  <Divider />
                </div>
                <Spacer preset='small' />
                <div
                  className='d-flex align-center cursor-pointer'
                  onClick={() => setStartingDayCalendarOpen(true)}
                  aria-hidden='true'
                >
                  <div className='w-100 d-flex d-flex-col'>
                    <Text
                      text={customLiteral}
                      preset='paragraph2'
                      color='primary'
                    />
                    <Text
                      color={
                        startingDate.type === 'CUSTOM'
                          ? 'iconPrimaryInitial'
                          : 'secondary'
                      }
                      text={
                        startingDate.type === 'CUSTOM'
                          ? startingDate?.description
                          : customStartingDayOpt.description
                      }
                      preset='paragraph2'
                    />
                  </div>
                  <ChevronRight color='iconPrimaryInitial' />
                </div>
                <CustomModal
                  show={startingDayCalendarOpen}
                  size='small'
                  theme='lighter'
                  dismissable
                  onClose={() => setStartingDayCalendarOpen(false)}
                >
                  <Calendar
                    id='recurring-payments-starting-date-calendar'
                    headerTitle={t('recurringPayments.date.starting')}
                    handleCancel={startingDateCalendar?.handleCancel}
                    handleRestore={startingDateCalendar?.handleRestore}
                    handleSelected={startingDateCalendar?.handleSelected}
                    handleSubmit={startingDateCalendar?.handleSubmit}
                    handleMonth={startingDateCalendar?.handleMonth}
                    getPreviousMonth={startingDateCalendar?.getPreviousMonth}
                    month={startingDateCalendar?.month}
                    selectedYear={startingDateCalendar?.selectedYear}
                    weekDays={startingDateCalendar?.weekDays}
                    submitDisabled={startingDateCalendar?.submitDisabled}
                    monthsMatrix={startingDateCalendar?.monthsMatrix}
                    withFooter
                    withHeader
                    withControls
                  />
                </CustomModal>
              </div>
            </>
          }
        />
      )}
      {showPeriodicityModal && (
        <OptionSelectorModal<BasicOption<PeriodicityEnum>, PeriodicityEnum>
          title={t('recurringPayments.periodicity.periodicity')}
          currentOption={periodicity}
          options={periodicityOptions}
          onChange={(opt) => setPeriodicity(opt)}
          onClose={() => setShowPeriodicityModal(false)}
          renderOption={({ option }) => renderOption({ option })}
          spacerOptions={spacerOpts}
        />
      )}
      {showExpirationModal && (
        <OptionSelectorModal<BasicOption<Date>, Date>
          title={t('recurringPayments.date.expiration')}
          currentOption={expiration}
          options={expirationOptions}
          onChange={(opt) => setExpiration(opt)}
          onClose={() => setShowExpirationModal(false)}
          renderOption={({ option }) => renderOption({ option })}
          spacerOptions={spacerOpts}
          bottomContent={
            <>
              <Spacer preset='medium' />
              <div>
                <div className='--no-modal-padding'>
                  <Divider />
                </div>
                <Spacer preset='small' />
                <div
                  className='d-flex  align-center cursor-pointer'
                  onClick={() => setExpirationCalendarOpen(true)}
                  aria-hidden='true'
                >
                  <div className='w-100 d-flex d-flex-col'>
                    <Text
                      text={customLiteral}
                      preset='paragraph2'
                      color='primary'
                    />
                    <Text
                      color={
                        expiration.type === 'CUSTOM'
                          ? 'iconPrimaryInitial'
                          : 'secondary'
                      }
                      text={
                        expiration.type === 'CUSTOM'
                          ? expiration?.description
                          : customExpirationDayOpt?.description
                      }
                      preset='paragraph2'
                    />
                  </div>
                  <ChevronRight color='iconPrimaryInitial' />
                </div>
                <CustomModal
                  show={expirationCalendarOpen}
                  size='small'
                  theme='lighter'
                  dismissable
                  onClose={() => setExpirationCalendarOpen(false)}
                >
                  <Calendar
                    id='recurring-payments-expiration-calendar'
                    withFooter
                    withHeader
                    headerTitle={t('common.expirationDate')}
                    handleCancel={expirationCalendar?.handleCancel}
                    handleRestore={expirationCalendar?.handleRestore}
                    handleSelected={expirationCalendar?.handleSelected}
                    handleSubmit={expirationCalendar?.handleSubmit}
                    handleMonth={expirationCalendar?.handleMonth}
                    getPreviousMonth={expirationCalendar?.getPreviousMonth}
                    month={expirationCalendar?.month}
                    selectedYear={expirationCalendar?.selectedYear}
                    weekDays={expirationCalendar?.weekDays}
                    submitDisabled={expirationCalendar?.submitDisabled}
                    monthsMatrix={expirationCalendar?.monthsMatrix}
                    withControls
                  />
                </CustomModal>
              </div>
            </>
          }
        />
      )}
    </>
  )
}
