import React, { useRef, useEffect } from 'react'
import * as d3 from 'd3'

interface DonutChartProps {
  data: { label: string; value: number }[]
  innerRadius: number
  outerRadius: number
  gapSize: number
}

const DonutChart: React.FC<DonutChartProps> = ({
  data,
  innerRadius,
  outerRadius,
  gapSize,
}) => {
  const ref = useRef<SVGSVGElement | null>(null)

  useEffect(() => {
    if (ref.current) {
      const svg = d3.select(ref.current)
      const width = outerRadius * 2
      const height = outerRadius * 2
      const arc = d3
        .arc()
        .innerRadius(innerRadius)
        .outerRadius(outerRadius)
        .padAngle(gapSize)
      const pie = d3
        .pie()
        .value((d) => d.value)
        .sort(null)

      const colorScale = d3
        .scaleOrdinal<string>()
        .domain(data.map((d) => d.label))
        .range([
          'var(--actionPrimaryInitial)',
          'var(--actionPrimaryHover)',
          'var(--actionPrimaryDisabled)',
        ])

      svg.selectAll('*').remove()
      svg.attr('width', width).attr('height', height)
      const g = svg
        .append('g')
        .attr('transform', `translate(${width / 2}, ${height / 2})`)

      const arcs = g
        .selectAll('arc')
        .data(pie(data))
        .enter()
        .append('g')
        .attr('class', 'arc')

      arcs
        .append('path')
        .attr('d', arc)
        .attr('fill', (d) => colorScale(d.data.label))

      const totalValue = data.reduce((acc, cur) => acc + cur.value, 0)
      g.append('text')
        .attr('text-anchor', 'middle')
        .attr('dy', '-0.2em')
        .style('font-size', '24px')
        .style('font-weight', 'bold')
        .attr('fill', 'var(--textNeutralStrong)')

        .text(totalValue)

      g.append('text')
        .attr('text-anchor', 'middle')
        .attr('dy', '1.5em')
        .style('font-size', '14px')
        .attr('fill', 'var(--textNeutralStrong)')
        .text('Ratings')
    }
  }, [data, innerRadius, outerRadius, gapSize])

  return <svg ref={ref}></svg>
}

export default DonutChart
