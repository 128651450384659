export const routes = {
  auth: {
    collection: '/collections/:id',
    collections: '/collections',
    company: '/company/:id',
    confirmation: '/confirmation',
    discover: '/discover/:mode',
    discoverAll: '/discover/all',
    help: '/profile/help',
    home: '/home',
    language: '/profile/language',
    paymentMethods: '/profile/paymentMethods',
    profile: '/profile',
    profileHelp: '/profile/help',
    resetPassword: '/resetPassword',
    search: '/discover/etfs',
    themes: '/profile/themes',
    transactionDetail: '/transactions/:transactionId/:transactionType',
    transactions: '/transactions/:mode',
    transactionsExecuted: '/transactions/executed',
    transactionsPending: '/transactions/pending',
    watchlist: '/watchlist',
    portfolio: '/portfolio',
  },
  nonAuth: {
    root: '/',
    login: '/login',
    signup: '/signup',
  },
}

export type Routes = typeof routes
