import { AssetType } from 'interfaces'
import { useTranslation } from './useTranslation'

export const useSearchBlockTitle = (): {
  format: (type: AssetType) => string
} => {
  const { t } = useTranslation()
  return {
    format: (type: AssetType): string => {
      switch (type) {
        case AssetType.stock:
          return t('common.stocks')
        case AssetType.etf:
          return t('common.etfs')
        case AssetType.savingPlan:
          return t('common.blackrockFunds')
      }
    },
  }
}
