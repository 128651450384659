import './portfolioItem.styles.scss'
import { PortfolioItemProps } from './portfolioItem.props'
import { useTranslation } from '@hooks'
import { formatMoney } from '@utils/helpers'
import { useEffect, useState } from 'react'
import { DownVector, UpVector } from '@assets/svg'

export function PortfolioItem({
  title,
  length,
  currentValue,
  onPress,
  totalEarnings,
  totalPerformance,
  focused = false,
  togglable = true,
}: PortfolioItemProps): JSX.Element {
  const { t } = useTranslation()

  const [positivePerformance, setPositivePerformance] = useState(false)

  const amountInvested = formatMoney(
    currentValue?.amount,
    currentValue?.currency,
  )

  const earningsToday = formatMoney(
    totalEarnings?.amount,
    totalEarnings?.currency,
  )

  useEffect(() => {
    if (totalPerformance >= 0) {
      setPositivePerformance(true)
    } else {
      setPositivePerformance(false)
    }
  }, [totalPerformance])

  return (
    <div
      className={`portfolioItem__summary ${
        focused ? 'portfolioItem__summary__active' : ''
      } ${togglable ? '--with-hover' : ''}`}
      aria-hidden
      onClick={onPress}
    >
      <div className='w-100 mr-1'>
        <div className='portfolioItem__summary__topRow '>
          <p className='portfolioItem__summary__topRow__title subtitle3'>
            {title}
          </p>
          <span className='portfolioItem__summary__topRow__amountInvested subtitle3'>
            {amountInvested}
          </span>
        </div>
        <div className='portfolioItem__summary__bottomRow'>
          <div className='portfolioItem__summary__bottomRow__assetAmount d-flex d-flex-row'>
            <p className='portfolioItem__summary__bottomRow__assetAmount__text paragraph2'>
              {length == 1
                ? t('common.asset', { amount: 1 })
                : t('common.assets', { amount: length })}
            </p>
          </div>
          <div className='portfolioItem__summary__bottomRow__performance'>
            {positivePerformance ? (
              <UpVector className='portfolioItem__summary__bottomRow__performance__icon positive--text' />
            ) : (
              <DownVector className='portfolioItem__summary__bottomRow__performance__icon negative--text' />
            )}
            <p
              className={`portfolioItem__summary__bottomRow__performance__text paragraph2 ${
                !positivePerformance &&
                'portfolioItem__summary__bottomRow__performance__text__negative'
              }`}
            >
              {`${totalPerformance}% (${earningsToday})`}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
