import './notifications.styles.scss'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { NotificationMessage } from 'interfaces'
import { Notification } from '@components'
import { removeNotification } from '@store/commonReducers/notifications'

export function Notifications(): JSX.Element {
  const dispatch = useDispatch()
  const { notifications } = useSelector(
    (state: RootState) => state.notifications,
  )

  const handleRemove = (id: string, removeAfter: number): void => {
    setTimeout(() => {
      dispatch(removeNotification({ id }))
    }, removeAfter)
  }

  return (
    <>
      <div className='notifications__wrapper'>
        {notifications?.length
          ? notifications.map((n: NotificationMessage) => (
              <Notification
                key={n?.id}
                {...n}
                onInit={() => handleRemove(n?.id, n?.removeAfter)}
                animation='top'
              />
            ))
          : null}
      </div>
    </>
  )
}
