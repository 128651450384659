import { Modal } from '@components'
import AssessmentModal, {
  AssessmentModalMode,
} from '@screens/discover/components/assessmentModal/assessmentModal'
import { useState } from 'react'

export const IncompleteAssessmentModal = ({
  onStartTrading,
  close,
}: {
  onStartTrading: () => void
  close: () => void
}): JSX.Element => {
  const [step, setStep] = useState<'alert' | 'presentation'>('alert')

  return step === 'alert' ? (
    <Modal
      show
      type='assessmentIncomplete'
      onButton={() => {
        setStep('presentation')
      }}
      onBasicLink={close}
    />
  ) : (
    <AssessmentModal
      onClose={close}
      step={AssessmentModalMode.questions}
      onStartTrading={onStartTrading}
    />
  )
}
