import { ChartItem } from './chartItem'
import { Money } from './money'
import { PeriodEnum } from './periodEnum'

export enum AssetType {
  etf = 'ETF',
  stock = 'STOCK',
  crypto = 'CRYPTO',
  savingPlan = 'SAVING_PLAN',
}

export enum AssetManagerCode {
  blk = 'BLK',
}

export interface AssetManager {
  code: AssetManagerCode
  name: string
  logo: string
}

export interface AssetsInfo {
  id: string
  companyName: string
  ticker?: string
  symbol: string
  price: Money
  lastClosed: Money
  increasePercentage: number
  assetManager?: AssetManager
  increaseMoney: Money
  chart: ChartItem[]
  marketStatus?: { status: string; closingTime?: string } | null
  period: PeriodEnum
  logoUrl: string
  priceTime?: string
  kiidUrl?: string
  type: AssetType
  errorChart?: string | null
  market?: string
  isEnabled?: boolean
  isLowLiquidity?: boolean
}
