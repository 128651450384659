export enum MoneyOperationsSteps {
  selectAmount = 'selectAmount',
  selectBankAccount = 'selectBankAccount',
  selectNewBank = 'selectNewBank',
  selectPaymentMethod = 'selectPaymentMethod',
  bankConnectionResult = 'bankConnectionResult',
  paymentConfirmation = 'paymentConfirmation',
  cardPaymentSummary = 'cardPaymentSummary',
  newCreditCardPayment = 'newCreditCardPayment',
  paymentSubmitted = 'paymentSubmitted',
  truelayerPermissions = 'truelayerPermissions',
  topUpLegalReminder = 'topUpLegalReminder',
  topUpTransferInfo = 'topUpTransferInfo',
  cashoutTransferInstructions = 'cashoutTransferInstructions',
  cashoutTransferEmail = 'cashoutTransferEmail',
  completedCashout = 'completedCashout',
  failedCashout = 'failedCashout',
  completedCardPayment = 'completedCardPayment',
  operateAsset = 'operateAsset',
  reviewAssetOperation = 'reviewAssetOperation',
  assetOperationSummary = 'assetOperationSummary',
  manualBankTransfer = 'manualBankTransfer',
  manualBankWithdraw = 'manualBankWithdraw',
  manualBankWithdrawSuccess = 'manualBankWithdrawSuccess',
}
