import { Button, CustomModal, Divider, Spacer, Text } from '@components'
import { useTranslation } from '@hooks'

export const RecurringTopUpInfo = ({
  onClose,
}: {
  onClose: () => void
}): JSX.Element => {
  const { t } = useTranslation()

  return (
    <CustomModal
      show={true}
      theme='lighter'
      size='big'
      height='fitContent'
      dismissable
      onClose={onClose}
    >
      <CustomModal.Header text={t('recurringPayments.info.title')} />
      <CustomModal.Content>
        <Text
          text={t('recurringPayments.info.section01')}
          preset='paragraph2'
        />
        <Spacer preset='small' />
        <Divider />
        <Spacer preset='small' />
        <Text
          text={t('recurringPayments.info.section02')}
          preset='paragraph2'
        />
        <Spacer preset='small' />
        <Divider />
        <Spacer preset='small' />
        <Text
          text={t('recurringPayments.info.section03')}
          preset='paragraph2'
        />
        <Spacer preset='small' />
        <Text
          text={t('recurringPayments.info.section04')}
          preset='paragraph2'
        />
        <Spacer preset='small' />
        <Text
          text={t('recurringPayments.info.section05')}
          preset='paragraph2'
        />
        <Text
          text={t('recurringPayments.info.section06')}
          preset='paragraph2'
        />
        <Spacer preset='small' />
        <Divider />
        <Spacer preset='small' />
        <Text
          text={t('recurringPayments.info.section07')}
          preset='paragraph2'
        />
        <Spacer preset='small' />
        <Divider />
        <Spacer preset='small' />
        <Text
          text={t('recurringPayments.info.section08')}
          preset='paragraph2'
        />
        <Text
          text={t('recurringPayments.info.section09')}
          preset='paragraph2'
        />
        <Text
          text={t('recurringPayments.info.section10')}
          preset='paragraph2'
        />
        <Text
          text={t('recurringPayments.info.section11')}
          preset='paragraph2'
        />
      </CustomModal.Content>
      <CustomModal.Footer>
        <Button
          text={t('common.continue')}
          onClick={onClose}
          buttonType='primary'
        />
      </CustomModal.Footer>
    </CustomModal>
  )
}
