import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import { WatchlistBase, WatchlistState } from '@interfaces'
import {
  clearWatchlistState,
  fetchWatchlistStocks,
  reloadWatchlistAfterDelete,
  removeItemFromWatchlist,
  unsetWatchlistStockState,
} from './thunk'

const initialState: WatchlistState & WatchlistBase = {
  currentPage: 0,
  totalItems: 0,
  totalPages: 0,
  data: {
    title: '',
    stocks: [],
  },
  loading: true,
  paginationLoader: false,
  error: null,
  reloadAfterDelete: false,
  hardLoading: true,
}
export const watchlistReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      fetchWatchlistStocks.pending,
      (acc: WatchlistState & WatchlistBase, { meta: { arg } }) => {
        const page = arg
        acc.loading = !page
        acc.paginationLoader = !!page
        acc.error = null
      },
    )
    .addCase(
      fetchWatchlistStocks.fulfilled,
      (acc, action: PayloadAction<WatchlistState>) => {
        const {
          payload: { data, totalPages, currentPage, totalItems },
        } = action

        const existingStocks = acc?.data?.stocks || []
        const stocks = data?.stocks || []

        if (data?.stocks) {
          if (!currentPage) {
            acc.data = {
              title: '',
              stocks: [...stocks],
            }
          } else {
            acc.data.stocks = [...existingStocks, ...stocks]
          }
        }

        acc.totalItems = totalItems
        acc.totalPages = totalPages
        acc.currentPage = currentPage
        acc.loading = false
        acc.hardLoading = false
        acc.paginationLoader = false
      },
    )
    .addCase(fetchWatchlistStocks.rejected, (acc, { error }) => {
      acc.loading = false
      acc.hardLoading = false
      acc.paginationLoader = false
      acc.error = error?.message ? error.message : null
    })
    .addCase(
      reloadWatchlistAfterDelete.pending,
      (acc: WatchlistState & WatchlistBase, { meta: { arg } }) => {
        const page = arg
        acc.loading = !page
        acc.paginationLoader = !!page
        acc.error = null
        acc.reloadAfterDelete = false
      },
    )
    .addCase(
      reloadWatchlistAfterDelete.fulfilled,
      (acc, action: PayloadAction<WatchlistState>) => {
        const {
          payload: { data, totalPages, currentPage, totalItems },
        } = action
        if (data?.stocks) {
          data?.stocks.forEach((stock) => {
            const idx = acc.data.stocks.findIndex((i) => i.id === stock.id)
            if (idx === -1) acc.data.stocks.push(stock)
          })
        }

        acc.totalItems = totalItems
        acc.totalPages = totalPages
        acc.currentPage = currentPage
        acc.loading = false
        acc.paginationLoader = false
        acc.reloadAfterDelete = false
      },
    )
    .addCase(reloadWatchlistAfterDelete.rejected, (acc, { error }) => {
      acc.loading = false
      acc.paginationLoader = false
      acc.error = error?.message ? error.message : null
      acc.reloadAfterDelete = false
    })
    .addCase(unsetWatchlistStockState.fulfilled, (acc) => {
      acc.reloadAfterDelete = true
      acc.loading = false
    })
    .addCase(unsetWatchlistStockState.pending, (acc) => {
      acc.reloadAfterDelete = false
      acc.loading = true
    })
    .addCase(unsetWatchlistStockState.rejected, (acc, { error }) => {
      acc.reloadAfterDelete = false
      acc.error = error?.message ? error?.message : null
      acc.loading = false
    })
    .addCase(removeItemFromWatchlist, (acc, action: PayloadAction<string>) => {
      const id = action.payload
      const idx = acc.data.stocks.findIndex((i) => i.id === id)

      if (idx !== -1) {
        acc.data.stocks.splice(idx, 1)
      }
    })
    .addCase(clearWatchlistState, (acc) => {
      acc.loading = true
      acc.paginationLoader = false
      acc.error = null
      acc.currentPage = 0
      acc.totalItems = 0
      acc.totalPages = 0
      acc.data = {
        title: '',
        stocks: [],
      }
    })
})
