import '../moneyOperations.styles.scss'
import { useTranslation } from '@hooks'
import {
  CustomModal,
  GoBack,
  Button,
  InputRadio,
  ModalLoader,
  Text,
  Spacer,
  Divider,
  Avatar,
} from '@components'
import {
  MoneyOperationTypeEnum,
  OperationConfirmationStatus,
  OperationTypeEnum,
  PaymentMethodTypeEnum,
  ToAmount,
} from '@interfaces'
import { useState, useEffect, ChangeEvent, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchBankAccount } from '../accountSelection/thunk'
import { getTopUpPaymentUrl, clearTopUpPaymentUrl } from '../topup/thunk'
import { RootState } from 'store'
import { trackingService } from '@services'
import { CashoutEvents, TopUpEvents } from '@utils/eventTracker/eventKeys'

const windowUrl = window.location

interface SelectBankAccountProps {
  nextStep: (summary: {
    accountId: string
    amount: number
    currency: string
    redirectUrl: string
  }) => void
  values: ToAmount
  prevStep: () => void
  onClose?: () => void
  type: MoneyOperationTypeEnum
}

export function SelectBankAccount({
  nextStep,
  prevStep,
  type,
  values,
  onClose,
}: SelectBankAccountProps): JSX.Element {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [isChecked, setIsChecked] = useState(false)
  const [accountSelected, setAccountSelected] = useState<{
    accountId: string
    amount: number
    currency: string
    redirectUrl: string
  }>({
    accountId: '',
    amount: values?.amount,
    currency: values?.currency,
    redirectUrl: '',
  })

  const { selectAccount, addMoney, cashout } = useSelector(
    (state: RootState) => state,
  )

  const cancelActions = (): void => {
    dispatch(clearTopUpPaymentUrl())
    prevStep()
  }

  const handleConfirmation = (
    event: ChangeEvent<HTMLInputElement> | undefined,
  ): void => {
    if (event?.target.checked) {
      setIsChecked(true)
      const data = {
        accountId: event?.target.value,
        amount: values.amount,
        currency: values.currency,
        redirectUrl: `${windowUrl.origin}/confirmation?operation=${OperationTypeEnum.addMoney}&method=${PaymentMethodTypeEnum.BANK}&status=${OperationConfirmationStatus.success}`,
      }
      setAccountSelected(data)

      if (type == MoneyOperationTypeEnum.addMoney) {
        dispatch(getTopUpPaymentUrl(data))
        trackingService.trackEvent({ event: TopUpEvents.selectBankAccount })
        return
      }

      trackingService.trackEvent({ event: CashoutEvents.selectBankAccount })
    } else {
      setIsChecked(false)
      dispatch(clearTopUpPaymentUrl())
    }
  }

  const handleSelect = (): void => {
    if (
      type === MoneyOperationTypeEnum.addMoney &&
      !addMoney.loading &&
      addMoney.url
    ) {
      trackingService.trackEvent({
        event: TopUpEvents.continueAfterSelection,
        props: { url: addMoney.url },
      })
      nextStep(accountSelected)
      return
    }
    if (type === MoneyOperationTypeEnum.cashout) {
      trackingService.trackEvent({
        event: CashoutEvents.continueAfterSelection,
      })
      nextStep(accountSelected)
    }
  }

  const continueDisabled = useMemo((): boolean => {
    if (type === MoneyOperationTypeEnum.addMoney) {
      return !isChecked || addMoney.loading || !addMoney.url
    }
    if (type === MoneyOperationTypeEnum.cashout) {
      return !isChecked || cashout.loading
    }

    return false
  }, [addMoney.loading, addMoney.url, cashout.loading, isChecked, type])

  useEffect(() => {
    dispatch(clearTopUpPaymentUrl())
    dispatch(
      fetchBankAccount({
        bankId: addMoney?.selectedPaymentMethod?.id,
      }),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!addMoney.error && addMoney.url) {
      window.localStorage.setItem('urlFrom', windowUrl.pathname)
      window.localStorage.setItem('redirectTopUp', 'true')
    }
  }, [addMoney])

  useEffect(() => {
    if (cashout.isSuccess) {
      nextStep(accountSelected)
    }
  }, [accountSelected, cashout.isSuccess, nextStep])

  return (
    <>
      <CustomModal.Header
        text={`${t('banksConnection.title')}`}
        linkText={t('common.cancel')}
        className='subtitle3 dark--label-title'
        onClick={onClose}
      >
        <GoBack goBack={cancelActions} />
      </CustomModal.Header>
      <CustomModal.Content className='d-flex-col'>
        <div className='mt-4'>
          <Text text={t('banksConnection.selectBank')} preset='headline1' />
          <Text
            text={t('selectAccount.subtitle', {
              bankName: addMoney?.selectedPaymentMethod.name,
            })}
            preset='paragraph2'
            color='secondary'
          />
          <Spacer preset='small' />
        </div>

        {selectAccount.loading ? (
          <ModalLoader text={t('banksConnection.connectAccount')} />
        ) : (
          selectAccount?.bankAccounts?.map((account, i) => {
            return (
              <div key={`account-${account.id}`}>
                <Spacer preset='smaller' />
                <InputRadio
                  key={`account_${account.id}`}
                  containerStyle='d-flex'
                  defaultChecked={isChecked}
                  onChange={handleConfirmation}
                  id={account.id}
                  value={account.id}
                  name='account'
                  textContainerStyle='flex d-flex-row align-center'
                >
                  <Avatar
                    image={account?.icon}
                    roundness='full'
                    size='large'
                    objectFit='cover'
                  />
                  <Spacer preset='smaller' />
                  <div>
                    <Text text={account.description} preset='paragraph2' />
                    <Text
                      text={account.number}
                      preset='paragraph2'
                      color='secondary'
                    />
                  </div>
                </InputRadio>
                {i !== selectAccount.bankAccounts?.length - 1 && (
                  <>
                    <Spacer preset='smaller' />
                    <Divider />
                  </>
                )}
              </div>
            )
          })
        )}
      </CustomModal.Content>
      <CustomModal.Footer>
        <Button
          text={t('common.continue')}
          type='submit'
          size='big'
          disabled={continueDisabled}
          onClick={handleSelect}
          loading={addMoney.loading || cashout.loading}
        />
      </CustomModal.Footer>
    </>
  )
}
