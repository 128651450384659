import { createAsyncThunk } from '@reduxjs/toolkit'
import { CurrenciesExchanges } from '@interfaces'
import { getCurrenciesExchanges } from '@services'

export const fetchCurrenciesExchanges = createAsyncThunk<
  CurrenciesExchanges,
  {
    target: string
  }
>('fetchCurrenciesExchanges', async ({ target }) => {
  return await getCurrenciesExchanges(target)
})
