import * as EN from './en/translation.json'
import * as KA from './ka/translation.json'
import * as RU from './ru/translation.json'

export const resources = {
  'en-US': {
    translation: EN,
  },
  ka: {
    translation: KA,
  },
  ru: {
    translation: RU,
  },
}
