import { ReducerState } from './reducerState'
import { SavingsPlan } from './savingsPlan'

export interface AssessmentQuestionnaire {
  id: string
  questions: AssessmentQuestion[]
}

export interface AssessmentQuestion {
  id: string
  title: string
  subtitle: string
  text: string
  choiceType: AssessmentChoiceType
  required: boolean
  choices: AssessmentChoice[]
}

export interface AssessmentChoice {
  id: string
  text: string
  checked: boolean
}

export enum AssessmentChoiceType {
  multipleSelection = 'MultipleSelection',
  singleSelection = 'SingleSelection',
}

export interface AssessmentAnswers {
  [key: string]: string[]
}

export interface AssessmentEvaluation {
  testPassed: boolean
  incongruitiesId: AssessmentIncongruities
}

export enum AssessmentIncongruities {
  studiesLevel = 'studiesLevel',
  professionalExperience = 'professionalExperience',
}

export interface AssessmentState extends ReducerState {
  loadingAssessment: boolean
  errorAssessment: string | null
  loadingSave: boolean
  errorSave: string | null
  assessmentSaved: boolean
  savingsPlans: SavingsPlan[]
  showAssessmentCard: boolean
  riskAssessment: AssessmentQuestionnaire
  riskAssessmentAllRequired: boolean
  riskAssessmentAnswers: AssessmentAnswers
  riskAssessmentEvaluation: AssessmentEvaluation
}

export enum AssessmentResult {
  undone = 'UNDONE',
  ok = 'OK',
  ko = 'KO',
}
