import { createAsyncThunk } from '@reduxjs/toolkit'
import { getCollections } from '@services'
import { CollectionItemProps } from '@interfaces'

export const fetchCollections = createAsyncThunk<CollectionItemProps[]>(
  'fetchCollections',
  async () => {
    return await getCollections()
  },
)
