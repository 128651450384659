import './infoCards.styles.scss'
import { InfoCardsProps } from './infoCards.props'
import { useEffect, useState } from 'react'
import { useTranslation } from '@hooks'
import { BasicLink } from '@components'
import { formatMoney } from '@utils/helpers'
import { StockPerformance } from '@features'
import { ReactComponent as InfoIcon } from '@assets/svg/info.svg'
import { CompanyInfoRow } from 'interfaces'
import { CompanyPerformanceInfo, KeyFiguresInfoModal } from '@shared/modals'

export function InfoCards({
  className,
  data,
  currentValue,
  companyPosition,
}: InfoCardsProps): JSX.Element {
  const cardStyle = `infoCard ${className} pa-3 mb-3`
  const { t } = useTranslation()
  const [visibleKeyfiguresModal, setVisibleKeyfiguresModal] = useState(false)
  const [showMore, setShowMore] = useState(false)
  const [showPerformance, setShowPerformance] = useState(false)

  const [lowest, setLowest] = useState<CompanyInfoRow>()
  const [highest, setHighest] = useState<CompanyInfoRow>()
  const [currency, setCurrency] = useState<string>()
  const [percentLoaded, setPercentLoaded] = useState(0)

  const realCurrentValue = formatMoney(
    companyPosition?.currentValue?.amount,
    companyPosition?.currentValue?.currency,
  )

  const avgEntryPriceValue = formatMoney(
    companyPosition?.avgEntryPrice?.amount,
    companyPosition?.avgEntryPrice?.currency,
  )

  const percentBar = (min: number, max: number, value: number): number => {
    return (
      parseFloat(((value - min) * 100).toFixed(2)) /
      parseFloat((max - min).toFixed(2))
    )
  }

  function displayMore(): void {
    setShowMore(true)
  }
  function displayLess(): void {
    setShowMore(false)
  }

  useEffect(() => {
    const ranges = data?.find((el) => el?.id == 'ranges')
    if (ranges) {
      setLowest(ranges?.elements[0])
      setHighest(ranges?.elements[1])
      setCurrency(ranges?.elements[2]?.value)
      setPercentLoaded(
        percentBar(+lowest?.value, +highest?.value, currentValue?.amount),
      )
    }
  }, [currentValue, data, highest, lowest])

  return (
    <>
      <div className='infoCard__wrapper py-3'>
        {companyPosition && (
          <>
            <div className={cardStyle}>
              <div className='infoCard__header mb-2'>
                <div className='infoCard__header__title'>
                  <div>
                    {t('companyDetails.position')}
                    <p className='infoCard__dot'>{'.'}</p>
                  </div>
                </div>
              </div>
              <div className='infoCard__content'>
                <div className='infoCard__content__item w-100'>
                  <div className='text-left w-50 mr-1'>
                    {t('companyDetails.numberOfShares')}
                  </div>
                  <div className='w-50 font-weight-bold text-right'>
                    {companyPosition.shares}
                  </div>
                </div>
                <div className='infoCard__content__item w-100'>
                  <div className='text-left w-50 mr-1'>
                    {t('companyDetails.value')}
                  </div>
                  <div className='w-50 font-weight-bold text-right'>
                    {realCurrentValue}
                  </div>
                </div>
                <div className='infoCard__content__item w-100'>
                  <div className='text-left w-50 mr-1 d-flex align-center'>
                    {t('companyDetails.performance')}
                    <InfoIcon
                      className='ml-1 cursor-pointer'
                      onClick={() => setShowPerformance(true)}
                      aria-hidden
                    />
                  </div>
                  <div className='w-50 font-weight-bold text-right'>
                    <StockPerformance
                      className='justify-end'
                      noWrap
                      plusSymbol
                      performanceAmount={companyPosition.totalEarnings}
                      performancePercentage={companyPosition.totalPerformance}
                    />
                  </div>
                </div>
                <div className='infoCard__content__item w-100'>
                  <div className='text-left w-50 mr-1'>
                    {t('companyDetails.avgEntryPrice')}
                  </div>
                  <div className='w-50 font-weight-bold text-right'>
                    {avgEntryPriceValue}
                  </div>
                </div>
                <div className='infoCard__content__item w-100'>
                  <div className='text-left w-50 mr-1'>
                    {t('companyDetails.portfolioPercent')}
                  </div>
                  <div className='w-50 font-weight-bold text-right'>
                    {companyPosition.portfolioPercentage}%
                  </div>
                </div>
              </div>
            </div>
            {showPerformance && (
              <CompanyPerformanceInfo close={() => setShowPerformance(false)} />
            )}
          </>
        )}
        {data?.slice(0, 7)?.map((el) => {
          return (
            <div className={cardStyle} key={el.id}>
              <div className='infoCard__header mb-2'>
                <div className='infoCard__header__title'>
                  <div>
                    {el.title}
                    <p className='infoCard__dot'>{'.'}</p>
                  </div>
                  {el.id === 'keyFigures' && (
                    <BasicLink
                      text={t('common.info')}
                      onClick={() => setVisibleKeyfiguresModal(true)}
                      className='infoCard__header__title__link'
                    />
                  )}
                </div>
                {el.subtitle != null && (
                  <div className='panel-wrapper pt-1 pb-2'>
                    <BasicLink
                      text={t('common.seeMore')}
                      onClick={displayMore}
                      className={`${!showMore ? 'show' : 'hide'} my-1`}
                    />
                    <BasicLink
                      text={t('common.seeLess')}
                      onClick={displayLess}
                      className={`${showMore ? 'show' : 'hide'} my-1`}
                    />
                    <div
                      className={`infoCard__header__subtitle__item ${
                        showMore
                          ? 'infoCard__header__subtitle__item--extent'
                          : ''
                      }`}
                    >
                      {el.subtitle}
                    </div>
                  </div>
                )}
              </div>
              <div className='infoCard__content'>
                {el.id === 'ranges' ? (
                  <div className='infoCard__content__range'>
                    <div className='infoCard__content__range__item'>
                      <span>
                        {lowest?.label}: {currency} {lowest?.value}
                      </span>

                      <span>
                        {highest?.label}: {currency} {highest?.value}
                      </span>
                    </div>
                    <input
                      className='mt-1'
                      type='range'
                      width='100'
                      min={lowest?.value}
                      max={highest?.value}
                      step='0.001'
                      value={+currentValue?.amount}
                      readOnly
                      style={{
                        backgroundSize: `${percentLoaded}% 100%`,
                      }}
                    ></input>
                  </div>
                ) : (
                  el.elements.map((ele, ind) => {
                    return (
                      <div key={ind} className='infoCard__content__item w-100'>
                        <div className='text-left w-50 mr-1'>{ele.label}</div>
                        <div className='w-50 font-weight-bold text-right'>
                          {ele.value}
                        </div>
                      </div>
                    )
                  })
                )}
              </div>
            </div>
          )
        })}
      </div>
      {visibleKeyfiguresModal && (
        <KeyFiguresInfoModal close={() => setVisibleKeyfiguresModal(false)} />
      )}
    </>
  )
}
