import { blackRockIcon } from '@assets/icons/blackRockIcon'
import { BuySellEvents } from '@utils/eventTracker/eventKeys'
import { formatCurrency } from '@utils/helpers'
import { Button, CustomTable, Spacer, Text } from 'components'
import { StockPerformance } from 'features'
import { useBuyAndSellActions } from 'hooks/useBuyAndSellActions'
import { useNavigate } from 'hooks/useNavigation'
import { AssetType, SavingsPlanAsset } from 'interfaces'
import { useTranslation } from 'react-i18next'
import { trackingService } from 'services'

export const SavingPlanTable = ({
  assets,
  screen,
}: {
  assets: Array<SavingsPlanAsset>
  screen: string
}): JSX.Element => {
  const { t } = useTranslation()
  const { navigate } = useNavigate()

  const { buy, modals } = useBuyAndSellActions()

  const onBuyClick = (p: { id: string; type: AssetType }): void => {
    trackingService.trackEvent({
      event: BuySellEvents.flowStarted,
      props: { origin, type: 'buy' },
    })
    buy({
      id: p?.id,
      origin: screen,
      type: p?.type,
    })
  }

  return (
    <>
      <CustomTable
        preset='table2'
        loading={false}
        rows={assets?.map((p) => (
          <CustomTable.Row
            key={`portfolio-row-${p?.id}-${p?.ticker}`}
            onClick={() => {
              navigate(`/company/${p?.id}`)
            }}
          >
            <CustomTable.Cell
              flexDirection='row'
              cellMinWidth='300px'
              cellMaxWidth='300px'
            >
              <div className='d-flex align-center'>
                <div className='w-20 d-flex align-center justify-center'>
                  {blackRockIcon(p?.risk?.srri)}
                </div>
                <Spacer preset='smaller' />
                <div className='w-80'>
                  <Text preset='paragraph2' text={p?.name} />
                  <Text preset='paragraph2' color='tertiary' text={p?.ticker} />
                </div>
              </div>
            </CustomTable.Cell>
            <CustomTable.Cell>
              <Text
                preset='paragraph2'
                align='right'
                text={formatCurrency(p?.price)}
              />
            </CustomTable.Cell>
            <CustomTable.Cell>
              <div className='d-flex justify-end'>
                <StockPerformance
                  performanceAmount={p?.performance?.increaseMoney}
                  performancePercentage={p?.performance?.increasePercentage}
                  plusSymbol={true}
                />
              </div>
            </CustomTable.Cell>
            <CustomTable.Cell>
              <Text
                preset='paragraph2'
                align='right'
                text={p?.risk?.srri?.toString()}
              />
            </CustomTable.Cell>
            <CustomTable.Cell wrapText={false}>
              <Text
                preset='paragraph2'
                align='right'
                text={p?.risk?.expectedVolatility}
              />
            </CustomTable.Cell>
            <CustomTable.Cell flexDirection='column' align='right'>
              <Button
                id='row-buy-button'
                text={t('common.buy')}
                loading={false}
                onClick={() =>
                  onBuyClick({
                    id: p?.id,
                    type: p?.type,
                  })
                }
                size='smaller'
                type='button'
                textPreset='subtitle2'
              />
            </CustomTable.Cell>
          </CustomTable.Row>
        ))}
        header={
          <CustomTable.Header>
            <CustomTable.HeaderCell>{t('common.name')}</CustomTable.HeaderCell>
            <CustomTable.HeaderCell align='right' wrapText>
              {t('buySellFlow.summary.price')}
            </CustomTable.HeaderCell>
            <CustomTable.HeaderCell align='right' wrapText>
              {t('table_headers.1day')}
            </CustomTable.HeaderCell>
            <CustomTable.HeaderCell align='right' wrapText>
              {t('discover.srri')}
            </CustomTable.HeaderCell>
            <CustomTable.HeaderCell align='right' wrapText>
              {t('discover.expectedVolatility')}
            </CustomTable.HeaderCell>
            <CustomTable.HeaderCell>
              <span className='d-none'>Buy/Sell</span>
            </CustomTable.HeaderCell>
          </CustomTable.Header>
        }
      />
      {modals}
    </>
  )
}
