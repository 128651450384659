import {
  AmericaAndWorldFeesProps,
  CurrencyExchangeFeesProps,
  SafeKeepingFeesProps,
} from './brokerTierInfoModal.type'

export const northAmericanFees: AmericaAndWorldFeesProps[] = [
  {
    tx1: 'brokerTier.northAmerican.subtitle1.title',
    tx2: 'brokerTier.northAmerican.subtitle1.basicNote1',
    tx3: 'brokerTier.northAmerican.subtitle1.basicNote2',
    tx4: 'brokerTier.northAmerican.subtitle1.proNote1',
    tx5: 'brokerTier.northAmerican.subtitle1.proNote2',
  },
  {
    tx1: 'brokerTier.northAmerican.subtitle2.title',
    tx2: 'brokerTier.northAmerican.subtitle2.basicNote1',
    tx3: 'brokerTier.northAmerican.subtitle2.basicNote2',
    tx4: 'brokerTier.northAmerican.subtitle2.proNote1',
    tx5: 'brokerTier.northAmerican.subtitle2.proNote2',
  },
]

export const worldFees: AmericaAndWorldFeesProps[] = [
  {
    tx1: 'brokerTier.worldFees.subtitle1.title',
    tx2: 'brokerTier.worldFees.subtitle1.basicNote1',
    tx3: 'brokerTier.worldFees.subtitle1.basicNote2',
    tx4: 'brokerTier.worldFees.subtitle1.proNote1',
    tx5: 'brokerTier.worldFees.subtitle1.proNote2',
  },
  {
    tx1: 'brokerTier.worldFees.subtitle2.title',
    tx2: 'brokerTier.worldFees.subtitle2.basicNote1',
    tx3: 'brokerTier.worldFees.subtitle2.basicNote2',
    tx4: 'brokerTier.worldFees.subtitle2.proNote1',
    tx5: 'brokerTier.worldFees.subtitle2.proNote2',
  },
  {
    tx1: 'brokerTier.worldFees.subtitle3.title',
    tx2: 'brokerTier.worldFees.subtitle3.basicNote1',
    tx3: 'brokerTier.worldFees.subtitle3.basicNote2',
    tx4: 'brokerTier.worldFees.subtitle3.proNote1',
    tx5: 'brokerTier.worldFees.subtitle3.proNote2',
  },
  {
    tx1: 'brokerTier.worldFees.subtitle4.title',
    tx2: 'brokerTier.worldFees.subtitle4.basicNote1',
    tx3: 'brokerTier.worldFees.subtitle4.basicNote2',
    tx4: 'brokerTier.worldFees.subtitle4.proNote1',
    tx5: 'brokerTier.worldFees.subtitle4.proNote2',
  },
  {
    tx1: 'brokerTier.worldFees.subtitle5.title',
    tx2: 'brokerTier.worldFees.subtitle5.basicNote1',
    tx3: 'brokerTier.worldFees.subtitle5.basicNote2',
    tx4: 'brokerTier.worldFees.subtitle5.proNote1',
    tx5: 'brokerTier.worldFees.subtitle5.proNote2',
  },
  {
    tx1: 'brokerTier.worldFees.subtitle6.title',
    tx2: 'brokerTier.worldFees.subtitle6.basicNote1',
    tx3: 'brokerTier.worldFees.subtitle6.basicNote2',
    tx4: 'brokerTier.worldFees.subtitle6.proNote1',
    tx5: 'brokerTier.worldFees.subtitle6.proNote2',
  },
  {
    tx1: 'brokerTier.worldFees.subtitle7.title',
    tx2: 'brokerTier.worldFees.subtitle7.basicNote1',
    tx3: 'brokerTier.worldFees.subtitle7.basicNote2',
    tx4: 'brokerTier.worldFees.subtitle7.proNote1',
    tx5: 'brokerTier.worldFees.subtitle7.proNote2',
  },
  {
    tx1: 'brokerTier.worldFees.subtitle8.title',
    tx2: 'brokerTier.worldFees.subtitle8.basicNote1',
    tx3: 'brokerTier.worldFees.subtitle8.basicNote2',
    tx4: 'brokerTier.worldFees.subtitle8.proNote1',
    tx5: 'brokerTier.worldFees.subtitle8.proNote2',
  },
  {
    tx1: 'brokerTier.worldFees.subtitle9.title',
    tx2: 'brokerTier.worldFees.subtitle9.basicNote1',
    tx3: 'brokerTier.worldFees.subtitle9.basicNote2',
    tx4: 'brokerTier.worldFees.subtitle9.proNote1',
    tx5: 'brokerTier.worldFees.subtitle9.proNote2',
  },
  {
    tx1: 'brokerTier.worldFees.subtitle10.title',
    tx2: 'brokerTier.worldFees.subtitle10.basicNote1',
    tx3: 'brokerTier.worldFees.subtitle10.basicNote2',
    tx4: 'brokerTier.worldFees.subtitle10.proNote1',
    tx5: 'brokerTier.worldFees.subtitle10.proNote2',
  },
  {
    tx1: 'brokerTier.worldFees.subtitle11.title',
    tx2: 'brokerTier.worldFees.subtitle11.basicNote1',
    tx3: 'brokerTier.worldFees.subtitle11.basicNote2',
    tx4: 'brokerTier.worldFees.subtitle11.proNote1',
    tx5: 'brokerTier.worldFees.subtitle11.proNote2',
  },
  {
    tx1: 'brokerTier.worldFees.subtitle12.title',
    tx2: 'brokerTier.worldFees.subtitle12.basicNote1',
    tx3: 'brokerTier.worldFees.subtitle12.basicNote2',
    tx4: 'brokerTier.worldFees.subtitle12.proNote1',
    tx5: 'brokerTier.worldFees.subtitle12.proNote2',
  },
  {
    tx1: 'brokerTier.worldFees.subtitle13.title',
    tx2: 'brokerTier.worldFees.subtitle13.basicNote1',
    tx3: 'brokerTier.worldFees.subtitle13.basicNote2',
    tx4: 'brokerTier.worldFees.subtitle13.proNote1',
    tx5: 'brokerTier.worldFees.subtitle13.proNote2',
  },
  {
    tx1: 'brokerTier.worldFees.subtitle14.title',
    tx2: 'brokerTier.worldFees.subtitle14.basicNote1',
    tx3: 'brokerTier.worldFees.subtitle14.basicNote2',
    tx4: 'brokerTier.worldFees.subtitle14.proNote1',
    tx5: 'brokerTier.worldFees.subtitle14.proNote2',
  },
  {
    tx1: 'brokerTier.worldFees.subtitle15.title',
    tx2: 'brokerTier.worldFees.subtitle15.basicNote1',
    tx3: 'brokerTier.worldFees.subtitle15.basicNote2',
    tx4: 'brokerTier.worldFees.subtitle15.proNote1',
    tx5: 'brokerTier.worldFees.subtitle15.proNote2',
  },
  {
    tx1: 'brokerTier.worldFees.subtitle16.title',
    tx2: 'brokerTier.worldFees.subtitle16.basicNote1',
    tx3: 'brokerTier.worldFees.subtitle16.basicNote2',
    tx4: 'brokerTier.worldFees.subtitle16.proNote1',
    tx5: 'brokerTier.worldFees.subtitle16.proNote2',
  },
]
export const safekeeping: SafeKeepingFeesProps[] = [
  {
    tx1: 'brokerTier.safekeeping.basicPro.basicProTitleOne',
    tx2: 'brokerTier.safekeeping.basicPro.basicProSubtitleOne',
    tx3: 'brokerTier.safekeeping.basicPro.basicProTitleTwo',
    tx4: 'brokerTier.safekeeping.basicPro.basicProSubtitleTwo',
    tx5: 'brokerTier.safekeeping.basicPro.basicProTitleThree',
    tx6: 'brokerTier.safekeeping.basicPro.basicProSubtitleThree',
  },
]
export const currentExchange: CurrencyExchangeFeesProps[] = [
  {
    tx1: 'brokerTier.currentExchange.basicPro.basicProTitle',
  },
]
