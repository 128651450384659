import { CustomModal, Spacer, Text } from '@components'
import { useTranslation } from '@hooks'

export const NotCompatibleScreen = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <CustomModal
      show={true}
      theme='lighter'
      size='small'
      blockScrollWhileOpen={false}
    >
      <CustomModal.Header text={t('screen.sizeLimited.title')} />
      <CustomModal.Content>
        <Spacer preset='tiny' />
        <Text
          preset='paragraph1'
          text={t('screen.sizeLimited.description')}
          align='center'
        />
        <Spacer preset='tiny' />
      </CustomModal.Content>
    </CustomModal>
  )
}
