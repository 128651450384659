import './multiAssetsSearch.styles.scss'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { SearchBox, Text, Spacer, ErrorBlock } from '@components'
import { useNavigate, useSearchBlockTitle, useTranslation } from '@hooks'
import { useSelector, useDispatch } from 'react-redux'
import { RootState, clearQuery, fetchAssets } from 'store'
import { ChevronRight } from '@assets/icons'
import { AssetType, DiscoverTab, DropdownState } from 'interfaces'
import { SearchResultsCard } from '@shared/components'
import { useOnEscKeypress } from 'hooks/useOnEscKeypress'

export function MultiAssetsSearch(): JSX.Element {
  const { t } = useTranslation()
  const { navigate } = useNavigate()

  const dispatch = useDispatch()
  const searchState = useSelector((state: RootState) => state.search)

  const [query, setQuery] = useState<string | null>(null)

  const [dropdownState, setdropdownState] = useState(DropdownState.CLOSED)

  const openedClassName = '--opened-search-dropdown'

  const elem: HTMLElement = document?.querySelector('body')

  const { format } = useSearchBlockTitle()

  useEffect(() => {
    let id

    if (dropdownState === DropdownState.CLOSING) {
      id = setTimeout(() => {
        closeDropdown()
      }, 140)
    }

    return () => {
      if (id) clearTimeout(id as number)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownState])

  const goToDiscoverTab = useCallback(
    (assetType: AssetType): void => {
      let tab: DiscoverTab
      switch (assetType) {
        case AssetType.stock:
          tab = DiscoverTab.stocks
          break
        case AssetType.etf:
          tab = DiscoverTab.etfs
          break
        case AssetType.savingPlan:
          tab = DiscoverTab.blk
          break
      }

      navigate(`/discover/${tab}`)
    },
    [navigate],
  )

  /**
   * Trigger search when user makes a query.
   */
  useEffect(() => {
    if (!searchState?.loading && query?.length >= 1) {
      dispatch(
        fetchAssets({
          query,
          type: 'search',
          origin: 'headerSearch',
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  const reload = (): void => {
    dispatch(
      fetchAssets({
        query,
        type: 'search',
        origin: 'headerSearch',
      }),
    )
  }

  /**
   * Removes class that prevents global scroll
   */
  const removeOpenedDropdownClass = useCallback(
    (className: string) => {
      if (elem && className) elem?.classList?.remove(className)
    },
    [elem],
  )

  const addOpenedDropdownClass = useCallback(
    (className: string) => {
      const alreadyHasClass = elem?.classList?.contains(className)
      if (dropdownState === DropdownState.CLOSED && !alreadyHasClass) {
        elem?.classList?.add(className)
      }
    },
    [elem?.classList, dropdownState],
  )

  const browseCatalog = useCallback(() => {
    navigate('/discover/all')
  }, [navigate])

  const data =
    query?.length > 0 ? searchState?.headerSearchData : searchState?.initialData

  const showResults =
    query?.length > 0 &&
    searchState?.headerSearchData?.some((d) => d?.items?.length > 0)

  const showNoResults =
    !searchState?.loading &&
    query?.length > 0 &&
    searchState?.headerSearchData?.every((d) => !d?.items?.length)

  const showInitialData = !searchState?.loading && !query?.length

  const openDropdown = (): void => {
    addOpenedDropdownClass(openedClassName)
    setdropdownState(DropdownState.OPENED)
  }

  const startDropdownClosing = useCallback((): void => {
    if (dropdownState === DropdownState.OPENED) {
      removeOpenedDropdownClass(openedClassName)
      setdropdownState(DropdownState.CLOSING)
    }
  }, [dropdownState, removeOpenedDropdownClass])

  const closeDropdown = (): void => {
    if (dropdownState === DropdownState.CLOSING) {
      setdropdownState(DropdownState.CLOSED)
      clearQueryData()
    }
    removeOpenedDropdownClass(openedClassName)
  }

  const clearQueryData = (): void => {
    setQuery(null)
    clearQuery()
  }

  /**
   * Start dropdown closing on ESC key press
   */
  useOnEscKeypress(useCallback(startDropdownClosing, [startDropdownClosing]))

  const dropdownClases = useMemo(() => {
    switch (dropdownState) {
      case DropdownState.OPENED:
        return '--opened'
      case DropdownState.CLOSING:
        return '--closing'
      default:
        return ''
    }
  }, [dropdownState])

  const results = useMemo(() => {
    return data?.map((block) => {
      // Only shows view more when there are more items than visible ones.

      return block?.items?.length > 0 ? (
        <div
          key={`block-${block?.type}-${block?.items?.length}`}
          className='multi-asset-search__dropdown__results__item'
        >
          <Spacer preset='smaller' />
          <div className='d-flex justify-space-between align-center'>
            <Text text={format(block?.type)} preset='caption2' />
            <div
              className='d-flex cursor-pointer'
              aria-hidden
              onClick={() => {
                startDropdownClosing()
                goToDiscoverTab(block?.type)
              }}
            >
              <Text
                text={block?.totalItems?.toString()}
                preset='paragraph2'
                color='iconPrimaryInitial'
              />
              <Spacer preset='extraTiny' />
              <ChevronRight size={22} color='iconPrimaryInitial' />
            </div>
          </div>
          <Spacer preset='smaller' />
          <SearchResultsCard
            results={block?.items}
            assetType={block?.type}
            onAssetClick={() => startDropdownClosing()}
          />
          <Spacer preset='smaller' />
        </div>
      ) : null
    })
  }, [data, format, goToDiscoverTab, startDropdownClosing])

  return (
    <>
      {dropdownState !== DropdownState.CLOSED && (
        <div
          className={`multi-asset-search__backdrop ${
            dropdownState === DropdownState.CLOSING
              ? '--fade-out-animation-ultra-fast'
              : '--fade-in-animation'
          }`}
          aria-hidden
          onClick={startDropdownClosing}
        ></div>
      )}
      <SearchBox>
        <>
          <div
            className='multi-asset-search__input'
            aria-hidden
            onClick={openDropdown}
          >
            <SearchBox.Input
              dropdownState={dropdownState}
              onChange={(v) => {
                if (v && dropdownState === DropdownState.CLOSED) openDropdown()
                setQuery(v)
              }}
              onClear={clearQueryData}
              debounceTime={300}
              textFieldProps={{
                size: 'small',
                style: `paragraph2`,
                inputProps: {
                  value: query ?? '',
                  placeholder: `${t('search.placeholder')}`,
                  name: 'search',
                },
              }}
            />
          </div>
          <div className={`multi-asset-search__dropdown ${dropdownClases}`}>
            {!searchState?.error && (showResults || showInitialData) && (
              <>
                <div className='multi-asset-search__dropdown__results'>
                  {results}
                </div>
                <div
                  className='multi-asset-search__dropdown__see-all'
                  onClick={() => {
                    startDropdownClosing()
                    browseCatalog()
                  }}
                  aria-hidden
                >
                  <Text
                    align='center'
                    preset='paragraph2'
                    text={t('common.browseCatalog')}
                  />
                  <Spacer preset='tiny' />
                  <ChevronRight size={22} />
                </div>
              </>
            )}
            {!searchState?.error && query && showNoResults ? (
              <div className='multi-asset-search__dropdown__no-results d-flex d-flex-col align-center justify-center'>
                <Text
                  preset='headline3'
                  text={t('common.weCouldntFindResults')}
                />
                <Spacer preset='tiny' />
                <Text
                  preset='paragraph2'
                  color='secondary'
                  text={t('common.tryAgainWithDifferentKeywords')}
                />
              </div>
            ) : null}
            {searchState?.error ? (
              <div className='multi-asset-search__dropdown__no-results'>
                <ErrorBlock onReload={reload} />
              </div>
            ) : null}
          </div>
        </>
      </SearchBox>
    </>
  )
}
