import './collection.styles.scss'
import { useNavigate } from '@hooks'
import { GoBack, BigSpinner, StickyElementsWrapper } from '@components'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'store'
import { ReactComponent as SpinnerIcon } from '@assets/svg/spinner.svg'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { fetchCollectionDetails, fetchCollectionDetailsVirtual } from './thunk'
import { trackingService } from '@services'
import { BasicAssetsTable } from '@shared/components/tables'

export default function CollectionDetails(): JSX.Element {
  const id = useParams().id || ''
  const dispatch = useDispatch()
  const { navigate } = useNavigate()

  const collectionDetail = useSelector(
    (state: RootState) => state.collectionDetails,
  )

  const getCollectionDetail = useCallback(
    () => dispatch(fetchCollectionDetails(id)),
    [dispatch, id],
  )
  useEffect(() => {
    trackingService.trackPage('Collections')
    getCollectionDetail()
  }, [getCollectionDetail])

  const [page, setPage] = useState(0)
  const loader = useRef(null)
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '20px',
      threshold: 1.0,
    }
    const observer = new IntersectionObserver(handleObserver, options)
    if (loader.current) {
      observer.observe(loader.current as Element)
    }
  }, [])

  // Virtual pagination
  useEffect(() => {
    dispatch(fetchCollectionDetailsVirtual(page))
  }, [page, dispatch])

  const handleObserver = (entities: Array<IntersectionObserverEntry>): void => {
    const target = entities[0]
    if (target.isIntersecting) {
      setPage((page) => page + 1)
    }
  }

  return (
    <div className='home__widget --fade-in-animation'>
      <div className='home__widget__portfolio'>
        <StickyElementsWrapper startPoint={56} stickTableHeader={true}>
          <div className='bg-primary-base pt-4' data-sticky>
            <div
              style={{
                backgroundImage: `url(${collectionDetail.background})`,
                backgroundSize: 'cover',
              }}
              className='collectionDetail__imageBackground bradius-2'
            >
              <div className='collectionDetail__header pt-3 pb-4 px-0 bradius-1'>
                <GoBack goBack={() => navigate(-1)} className='mb-8' />
                <div className='title mt-8'>{collectionDetail.description}</div>
              </div>
            </div>
          </div>

          {collectionDetail?.loading ? (
            <div className='container discover__loader'>
              <BigSpinner />
            </div>
          ) : (
            <div className='home__widget__portfolio__content mb-5'>
              <BasicAssetsTable
                assets={collectionDetail.virtualShow}
                screen='collection'
              />
            </div>
          )}
        </StickyElementsWrapper>

        <div
          className={`text-center mt-2 loader ${
            collectionDetail?.loading ||
            collectionDetail?.totalPages - 1 == page
              ? 'd-none'
              : ''
          }`}
          ref={loader}
        >
          {collectionDetail.paginationLoader && (
            <SpinnerIcon className='spinner' />
          )}
        </div>
      </div>
    </div>
  )
}
