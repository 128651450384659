import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import {
  cleanPdf,
  cleanSentPdf,
  fetchDocument,
  sendDocumentByEmail,
} from './thunk'

const initialState = {
  file: '',
  loading: false,
  error: null as string | null,
  loadingSend: false,
  errorSend: null as string | null,
  isPdfSent: false,
}

export const reducers = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchDocument.pending, (acc) => {
      acc.loading = true
      acc.error = null
    })
    .addCase(fetchDocument.fulfilled, (acc, action: PayloadAction<string>) => {
      const { payload } = action
      acc.loading = false
      acc.file = payload
    })
    .addCase(fetchDocument.rejected, (acc, { error }) => {
      acc.loading = false
      acc.error = error.message || ''
    })
    .addCase(sendDocumentByEmail.pending, (acc) => {
      acc.loadingSend = true
      acc.errorSend = null
    })
    .addCase(sendDocumentByEmail.fulfilled, (acc) => {
      acc.loadingSend = false
      acc.isPdfSent = true
    })
    .addCase(sendDocumentByEmail.rejected, (acc, { error }) => {
      acc.loadingSend = false
      acc.errorSend = error.message || ''
      acc.isPdfSent = false
    })
    .addCase(cleanSentPdf, (acc) => {
      acc.isPdfSent = false
    })
    .addCase(cleanPdf, (acc) => {
      acc.file = ''
    })
})

export default reducers
