import { CloseIcon } from '@assets/icons'
import { PortfolioLaptopIllustration } from '@assets/illustrations'
import { Button, CustomModal, Spacer, Text } from '@components'
import { useNavigate, useTranslation } from '@hooks'
import { NewPortfolioAnalysisIcon } from './newPortfolioAnalysisIcon'
import { DedicatedSpaceIcon } from './dedicatedSpaceIcon'
import { YourProfileIcon } from './yourProfileIcon'

export const PortfolioPromotion = ({
  close,
}: {
  close: () => void
}): JSX.Element => {
  const { t } = useTranslation()
  const { navigate } = useNavigate()
  const items = [
    {
      icon: <NewPortfolioAnalysisIcon />,
      title: t('promotions.assetAllocationChartPromo.section1.title'),
      description: t('promotions.assetAllocationChartPromo.section1.text'),
    },
    {
      icon: <DedicatedSpaceIcon />,
      title: t('promotions.assetAllocationChartPromo.section2.title'),
      description: t('promotions.assetAllocationChartPromo.section2.text'),
    },
    {
      icon: <YourProfileIcon />,
      title: t('promotions.assetAllocationChartPromo.section3.title'),
      description: t('promotions.assetAllocationChartPromo.section3.text'),
    },
  ]

  return (
    <CustomModal
      size='big'
      height='fullHeight'
      show
      placeOn='right'
      dismissable
      onClose={close}
    >
      <CustomModal.Header>
        <Button
          onClick={close}
          buttonType='link'
          autoWidth
          content={<CloseIcon color='iconPrimaryInitial' size={16} />}
          shape='square'
        />
      </CustomModal.Header>
      <CustomModal.Content>
        <Spacer preset='large' />
        <PortfolioLaptopIllustration />
        <Spacer preset='large' />
        <div className='d-flex d-flex-col justify-center align-center'>
          <div className=' w-80'>
            <Text
              preset='headline1'
              text={t('promotions.assetAllocationChartPromo.title')}
              align='center'
            />
          </div>
        </div>
        <Spacer preset='large' />
        {items && items.length > 0
          ? items?.map((i) => {
              return (
                <div
                  className='d-flex d-flex-col'
                  key={`promo-item-${i?.title}`}
                >
                  {i?.icon}
                  <Spacer preset='tinyPlus' />
                  <Text
                    preset='paragraph2'
                    text={i?.title}
                    color='iconPrimaryInitial'
                  />
                  <Spacer preset='tinyPlus' />
                  <Text preset='paragraph2' text={i?.description} />
                  <Spacer preset='medium' />
                </div>
              )
            })
          : null}
      </CustomModal.Content>
      <CustomModal.Footer>
        <Button
          onClick={() => {
            navigate('/discover/all')
          }}
          text={t('portfolio.make_first_investment')}
        />
      </CustomModal.Footer>
    </CustomModal>
  )
}
