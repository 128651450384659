import { FiltersResponse } from '@interfaces/searchFilters'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { getSearchFilters } from '@services'
import { AssetType } from 'interfaces'
export const clearErrors = createAction('clearErrors')
export const clearFilters = createAction('clearFilters')
export const clearFiltersSelection = createAction('clearFiltersSelection')
export const createFiltersSelection = createAction('createFiltersSelection')
export const setFocusedFilter = createAction<string>('setFocusedFilter')

export interface UpdateFilterActionPayload {
  id: string
  type: 'toggle' | 'reset' | 'selectAll'
}

export type SetAssetTypesPayload = Array<AssetType>

export const updateFilter =
  createAction<UpdateFilterActionPayload>('updateFilter')

export const setAssetTypes = createAction<SetAssetTypesPayload>('setAssetTypes')
export const selectCurrentFilters = createAction('selectCurrentFilters')

export const fetchSearchFilters = createAsyncThunk<FiltersResponse>(
  'fetchSearchFilters',
  async () => {
    return getSearchFilters()
  },
)
