import { UIIcon, UIIconColorProps, getIconColorSVGFill } from 'components/utils'

export const PlusIcon = ({
  color,
  size = 24,
}: UIIcon & UIIconColorProps): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 49 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M22.8047 38V25.5H10.3047V22.5H22.8047V10H25.8047V22.5H38.3047V25.5H25.8047V38H22.8047Z'
        fill={color ? getIconColorSVGFill(color) : 'currentColor'}
      />
    </svg>
  )
}
