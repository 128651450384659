import { useTranslation } from 'react-i18next'
import { BasicLink, Button, CustomModal } from '@components'

export function ReconnectBank(props: {
  show: boolean
  onClose: () => void
  onAccept: () => void
}): JSX.Element {
  const { t } = useTranslation()

  return (
    <CustomModal show={props.show} theme='lighter' size='small'>
      <CustomModal.Header text={t('addMoney.bankconnectionExpired')} />
      <CustomModal.Content className='d-flex-col'>
        <div className='d-flex d-flex-col align-center justify-center space'>
          {t('addMoney.reconnectBank')}
        </div>
      </CustomModal.Content>
      <CustomModal.Footer>
        <div className='w-100'>
          <Button text={t('addMoney.reconnectBtn')} onClick={props.onAccept} />
          <BasicLink
            text={t('common.cancel')}
            type='action'
            className='w-100 mt-3'
            onClick={props.onClose}
            contentSize={'caption2'}
          />
        </div>
      </CustomModal.Footer>
    </CustomModal>
  )
}
