import { ReactNode, useState } from 'react'
import {
  BasicLink,
  Button,
  CustomModal,
  CustomModalProps,
  InputRadio,
  Spacer,
  SpacerProps,
} from '@components'
import { useTranslation } from '@hooks'

type Option<T, K> = T & { value: K }

export interface OptionSelectorModalProps<T, K> {
  modalProps?: CustomModalProps
  onChange?: (option: Option<T, K>) => void
  options: Array<Option<T, K>>
  renderOption: (props: {
    option: Option<T, K>
    context?: { isFirst: boolean; isLast: boolean }
  }) => React.ReactNode
  title?: string
  onClose: () => void
  spacerOptions?: SpacerProps
  currentOption?: Option<T, K>
  bottomContent?: ReactNode
}

export function OptionSelectorModal<T, K>({
  modalProps,
  options,
  renderOption,
  onChange,
  title,
  onClose,
  spacerOptions,
  currentOption,
  bottomContent,
}: OptionSelectorModalProps<T, K>): JSX.Element {
  const [selected, setSelected] = useState<Option<T, K>>(currentOption)
  const { t } = useTranslation()

  const selectedHandler = (option: Option<T, K>): void => {
    setSelected(option)

    if (onChange) {
      onChange(option)
    }
  }

  const handleClose = (): void => (onClose ? onClose() : null)

  return (
    <CustomModal size='big' show {...modalProps} dismissable onClose={onClose}>
      <CustomModal.Header text={title} />
      <CustomModal.Content>
        {options?.map((o, idx) => (
          <div key={`options-${title}-idx-${idx}`}>
            <div
              onClick={() => selectedHandler(o)}
              aria-hidden
              className='w-100 d-flex justify-between align-items-center cursor-pointer'
            >
              {renderOption({
                option: o,
                context: {
                  isFirst: idx === 0,
                  isLast: idx === options.length - 1,
                },
              })}
              <InputRadio
                containerStyle='d-flex align-items-center'
                checked={o?.value === selected?.value}
                id={`${title}-idx-${idx}-option`}
                value='option'
                name='options-selector'
              />
            </div>
            {idx != options.length - 1 ? (
              <Spacer preset='small' {...spacerOptions} />
            ) : null}
          </div>
        ))}
        {bottomContent}
      </CustomModal.Content>
      <CustomModal.Footer>
        <div className='w-100 d-flex d-flex-col'>
          <Button
            onClick={handleClose}
            buttonType='primary'
            text='Select option'
            disabled={!selected}
          />
          <Spacer preset='small' />
          <BasicLink onClick={handleClose} text={t('common.cancel')} />
        </div>
      </CustomModal.Footer>
    </CustomModal>
  )
}
