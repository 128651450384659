import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { checkout, getAllowedOperationsTypes, requestOrder } from '@services'
import {
  OrderData,
  OrderRequest,
  CompanyOperationsAllowed,
  PreOrderData,
  PreOrderRequest,
  OrderOperationTypeEnum,
  OrderTypeEnum,
  SetMarketOrderPayload,
  SetLimitOrderPayload,
} from '@interfaces'

export const fetchOrderTypes = createAsyncThunk<
  CompanyOperationsAllowed,
  string
>('fetchOrderTypes', async (market) => {
  return getAllowedOperationsTypes(market)
})

export const sendOrderData = createAsyncThunk<OrderData, OrderRequest>(
  'sendOrderData',
  async (orderResponse) => {
    return await requestOrder(orderResponse)
  },
)

export const fetchCheckout = createAsyncThunk<PreOrderData, PreOrderRequest>(
  'fetchCheckout',
  async (preOrderRequest) => {
    return await checkout(preOrderRequest)
  },
)

export const setOperationStockId = createAction<string>('setOperationStockId')

export const setOrderType = createAction<OrderTypeEnum>('setOrderType')

export const clearOrderType = createAction('clearOrderType')
export const clearPreorderError = createAction('clearPreorderError')

export const setOrderOperationType = createAction<OrderOperationTypeEnum>(
  'setOrderOperationType',
)

export const clearOrderOperationType = createAction('clearOrderOperationType')

export const setMarketOrderDetails = createAction<
  SetMarketOrderPayload,
  string
>('setMarketOrderDetails')

export const clearMarketOrderDetails = createAction('clearMarketOrderDetails')

export const setLimitOrderDetails = createAction<SetLimitOrderPayload, string>(
  'setLimitOrderDetails',
)

export const clearLimitOrderDetails = createAction('clearLimitOrderDetails')

export const resetOrder = createAction('resetOrder')

export const resetError = createAction('resetError')
