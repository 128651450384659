export const YourProfileIcon = (): JSX.Element => {
  return (
    <svg
      width='81'
      height='40'
      viewBox='0 0 81 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='0.5'
        width='80'
        height='40'
        rx='4'
        fill='var(--surfaceCanvasSecondaryInitial)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M28.5 16C28.5 18.21 26.71 20 24.5 20C22.29 20 20.5 18.21 20.5 16C20.5 13.79 22.29 12 24.5 12C26.71 12 28.5 13.79 28.5 16ZM26.5 16C26.5 14.9 25.6 14 24.5 14C23.4 14 22.5 14.9 22.5 16C22.5 17.1 23.4 18 24.5 18C25.6 18 26.5 17.1 26.5 16ZM24.5 21C21.83 21 16.5 22.34 16.5 25V27C16.5 27.55 16.95 28 17.5 28H31.5C32.05 28 32.5 27.55 32.5 27V25C32.5 22.34 27.17 21 24.5 21ZM18.5 25.01V26H30.5V25C30.3 24.29 27.2 23 24.5 23C21.8 23 18.7 24.29 18.5 25.01Z'
        fill='#FF9B63'
      />
      <path
        d='M60.5 28C62.45 28 64.1042 27.3208 65.4625 25.9625C66.8208 24.6042 67.5 22.95 67.5 21C67.5 19.2167 66.9125 17.6625 65.7375 16.3375C64.5625 15.0125 63.0917 14.25 61.325 14.05L62.9 12.4L61.5 11L57.5 15L61.5 19L62.9 17.575L61.425 16.1C62.6083 16.3333 63.5833 16.9083 64.35 17.825C65.1167 18.7417 65.5 19.8 65.5 21C65.5 22.3833 65.0125 23.5625 64.0375 24.5375C63.0625 25.5125 61.8833 26 60.5 26H57.5V28H60.5ZM55.5 19V12H46.5V19H55.5ZM55.5 28V21H46.5V28H55.5ZM53.5 26H48.5V23H53.5V26Z'
        fill='#FF9B63'
      />
    </svg>
  )
}
