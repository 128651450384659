import { useTranslation } from '@hooks'
import { StickyElementsWrapper, CollectionItem, Grid } from '@components'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

const NUM_COLUMNS = 3

export default function Collections(): JSX.Element {
  const { t } = useTranslation()

  const collections = useSelector((state: RootState) => state.collections)

  const data = collections?.collections.map((collection, idx) => {
    return (
      <CollectionItem
        {...collection}
        key={idx}
        className='collectionItem--medium'
      />
    )
  })

  return (
    <div className='home__wrapper --fade-in-animation'>
      <div className='home__widget'>
        <div className='home__widget__portfolio'>
          <StickyElementsWrapper startPoint={56} zIndex={'3'}>
            <div
              className='pt-6 pb-4 px-0 d-flex d-flex-row bg-primary-base'
              data-sticky
            >
              <div className='title d-flex align-self-center'>
                {t('collections.title')}
              </div>
            </div>
            <Grid
              data={data}
              numberOfColumns={NUM_COLUMNS}
              className='justify-space-between'
            />
          </StickyElementsWrapper>
        </div>
      </div>
    </div>
  )
}
