import './progressBar.styles.scss'

export const ProgressBar = ({
  steps,
  value,
  maxValue,
}: {
  steps: number
  value: number
  maxValue: number
}): JSX.Element => {
  const stepsAmount = steps || 1
  const stepValue: number = maxValue / stepsAmount

  const percentage = (idx: number): number => {
    const stepMax = (idx + 1) * stepValue
    const stepMin = idx * stepValue
    if (value >= stepMax) return 100
    if (value <= stepMin) return 0
    const remaining = value - idx * stepValue
    return (remaining / stepValue) * 100
  }

  return (
    <div className='progress-bar'>
      {[...Array(stepsAmount || 0).keys()].map((idx) => (
        <div key={`step__${idx}`} className={`progress-bar__step`}>
          <div
            className={`progress-bar__step__fill`}
            style={{
              width: `${percentage(idx)}%`,
            }}
          ></div>
        </div>
      ))}
    </div>
  )
}
