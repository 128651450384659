import React, { useRef, useEffect } from 'react'
import * as d3 from 'd3'
import './linearGraph.styles.scss'

interface LineChartProps {
  data: { date: Date; value: number }[]
  width: number
  height: number
}

const LineChart: React.FC<LineChartProps> = ({ data, width, height }) => {
  const svgRef = useRef<SVGSVGElement | null>(null)

  useEffect(() => {
    const svg = d3.select(svgRef.current)
    svg.selectAll('*').remove()

    const margin = { top: 0, right: 0, bottom: 0, left: 0 }
    const innerWidth = width - margin.left - margin.right
    const innerHeight = height - margin.top - margin.bottom
    const xScale = d3
      .scaleTime()
      .domain(d3.extent(data, (d) => d.date) as [Date, Date])
      .range([0, innerWidth])

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.value)!])
      .range([innerHeight, 0])
    const line = d3
      .line<{ date: Date; value: number }>()
      .x((d) => xScale(d.date))
      .y((d) => yScale(d.value))
    const area = d3
      .area<{ date: Date; value: number }>()
      .x((d) => xScale(d.date))
      .y0(innerHeight)
      .y1((d) => yScale(d.value))

    const g = svg
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`)

    const defs = svg.append('defs')

    const gradient = defs
      .append('linearGradient')
      .attr('id', 'gradient')
      .attr('class', 'custom-gradient')
      .attr('x1', '0%')
      .attr('y1', '0%')
      .attr('x2', '0%')
      .attr('y2', '100%')

    gradient.append('stop').attr('offset', '0%').attr('class', 'gradient-start')

    gradient.append('stop').attr('offset', '100%').attr('class', 'gradient-end')

    g.append('path').datum(data).attr('fill', 'url(#gradient)').attr('d', area)

    g.append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('stroke', 'var(--iconPrimaryInitial)')
      .attr('stroke-width', 2)
      .attr('d', line)

    svg
      .append('text')
      .attr('x', width - margin.right - 10)
      .attr('y', height - margin.bottom)
      .attr('text-anchor', 'end')
      .attr('font-size', '12px')
      .attr('class', 'bottom-right-text bodyRegular')
      .text('1M')
  }, [data, width, height])

  return <svg ref={svgRef} width={width} height={height}></svg>
}

export default LineChart
