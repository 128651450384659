import * as yup from 'yup'
import { RequiredStringSchema } from 'yup/lib/string'
import { AnyObject } from 'yup/lib/types'

export const emailValidation = yup
  .string()
  .email('common.emailRequired')
  .required('common.fieldRequired')

export const passwordValidation = (
  checkUsername: boolean,
): RequiredStringSchema<string, AnyObject> => {
  let schema = yup
    .string()
    .min(8, 'validation.PasswordMin')
    .matches(/[A-Z]/, 'validation.uppercaseLetter')
    .matches(/[a-z]/, 'validation.lowercaseLetter')
    .matches(/\d/, 'validation.oneNumber')
    .matches(/[^a-zA-Z0-9]/, 'validation.nonAlphanumericSymbol')
    .required('validation.passwordRequired')

  if (checkUsername) {
    schema = schema.test(
      'validation.noUsername',
      'validation.usernameNotInclude',
      function (value) {
        const { email }: { email: string } = this.parent
        return email && value ? !value.includes(email.split('@')[0]) : true
      },
    )
  }

  return schema
}

export const schemaPassword = yup.object().shape({
  password: passwordValidation(false),
})
