export enum ChartTypes {
  PORTFOLIO_VALUE = 'PORTFOLIO_VALUE',
  MY_ASSETS = 'MY_ASSETS',
}

export interface PortfolioDistributionSummary {
  portfolios: Array<{
    id: string
    label: string
    value: number
    numberOfAssets: number
  }>
  totalAssetsInPortfolio: number
  totalPortfolioValue: number
}
