import { BrokerTier, BrokerTierUserData } from '@interfaces'
import { api } from '@utils/api/api'

export const getBrokerTier = (): Promise<BrokerTier> => {
  return api.get('/api/v1/broker/tier')
}

export const getBrokerTierUserData = (): Promise<BrokerTierUserData> => {
  return api.get('/api/v1/mobile/tier/data')
}
