import './orderSummaryCard.styles.scss'
import { OrderSummaryCardProps } from './orderSummaryCard.props'
import { useMemo, useState } from 'react'
import { OrderOperationTypeEnum, OrderTypeEnum } from '@interfaces'
import { InfoIcon, NoImg } from '@assets/svg'
import { t } from 'i18next'
import { Modal, Show } from '@components'
import { BalanceInfoModal } from '@shared/modals'

const totalRequiredKey = 'buySellFlow.summary.totalRequired'

export function OrderSummaryCard({
  className,
  orderType,
  orderMethod,
  amount,
  ticker,
  logoUrl,
  total,
  estimatedTotal,
  available,
}: OrderSummaryCardProps): JSX.Element {
  const [showBalanceModal, setShowBalanceModal] = useState<boolean>(false)
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false)
  const [infoTitle, setInfoTitle] = useState<string>()
  const [infoContent, setInfoContent] = useState<string>()

  const availableHandler = (): void => {
    if (orderType === OrderOperationTypeEnum.buy) {
      setShowBalanceModal(true)
      return
    }
    setInfoTitle(t('buySellFlow.availableForSaleModalTitle'))
    setInfoContent(t('buySellFlow.sharesAvailableForSaleExplanation'))
    setShowInfoModal(true)
  }

  const showEstimatedTotalModal = (): void => {
    setInfoTitle(t('buySellFlow.summary.estimatedTotal'))
    setInfoContent(t('buySellFlow.summary.estimatedTotalExplanation'))
    setShowInfoModal(true)
  }

  const showTotalModal = (): void => {
    let infoTitle: string
    let infoContent: string

    if (orderType === OrderOperationTypeEnum.sell) {
      infoTitle = t('buySellFlow.estimatedEarnings')
      infoContent = t('buySellFlow.summary.estimatedEarningsExplanation')
    } else if (orderMethod === OrderTypeEnum.limit) {
      infoTitle = t('buySellFlow.summary.totalToWithhold')
      infoContent = t('buySellFlow.summary.totalToWithholdExplanation')
    } else {
      infoTitle = t(totalRequiredKey)
      infoContent = t('buySellFlow.summary.totalToWithholdExplanation')
    }

    setInfoTitle(infoTitle)
    setInfoContent(infoContent)
    setShowInfoModal(true)
  }

  const title = useMemo(() => {
    switch (orderMethod) {
      case OrderTypeEnum.market:
        if (orderType === OrderOperationTypeEnum.buy) {
          return t('buySellFlow.limitOrders.buyMarket')
        }
        return t('buySellFlow.limitOrders.sellMarket')
      case OrderTypeEnum.limit:
        if (orderType === OrderOperationTypeEnum.buy) {
          return t('buySellFlow.limitOrders.buyLimit')
        }
        return t('buySellFlow.limitOrders.sellLimit')
      default:
        return ''
    }
  }, [orderType, orderMethod])

  const totalLabel = useMemo(() => {
    switch (orderType) {
      case OrderOperationTypeEnum.buy:
        if (orderMethod === OrderTypeEnum.limit) {
          return t('buySellFlow.summary.totalToWithhold')
        }
        return t(totalRequiredKey)
      case OrderOperationTypeEnum.sell:
        return t('buySellFlow.estimatedEarnings')
      default:
        return ''
    }
  }, [orderType, orderMethod])

  const estimatedTotalLabel = useMemo(() => {
    if (orderType === OrderOperationTypeEnum.buy) {
      return t('buySellFlow.summary.estimatedTotal')
    }
    return ''
  }, [orderType])

  const availableLabel = useMemo(() => {
    if (orderType === OrderOperationTypeEnum.buy) {
      return t('buySellFlow.balanceAvailableToInvest', {
        cashAvailable: available,
      })
    }
    return `${t('buySellFlow.infoAssets.title')}: ${available} ${ticker}`
  }, [available, orderType, ticker])

  return (
    <>
      <div className={`${className} orderSummaryCard mb-3`}>
        <div className='orderSummaryCard__topSection border-bottom-default d-flex align-center'>
          <div className='orderSummaryCard__logo icon--massive mr-2'>
            {logoUrl ? (
              <img
                className='orderSummaryCard__logo icon--massive'
                src={logoUrl}
                alt={'Asset logo'}
              />
            ) : (
              <NoImg />
            )}
          </div>
          <div className='d-flex d-flex-col'>
            <p className='d-flex paragraph5 mb-1'>{title}</p>
            <div className='d-flex headline1'>
              <p className='orange--text'>{amount}&nbsp;</p>
              <p className='primary--text'>{ticker}</p>
            </div>
          </div>
        </div>
        <div className='orderSummaryCard__bottomSection headline4'>
          <div className='d-flex justify-space-between'>
            <div className='d-flex align-center'>
              <p>{totalLabel}</p>
              <InfoIcon
                className='ml-1 cursor-pointer'
                onClick={showTotalModal}
                aria-hidden
              />
            </div>
            <p>{total}</p>
          </div>
          {estimatedTotal ? (
            <div className='d-flex justify-space-between mt-1 paragraph2'>
              <div className='d-flex align-center'>
                <p>{estimatedTotalLabel}</p>
                <InfoIcon
                  className='ml-1 cursor-pointer'
                  onClick={showEstimatedTotalModal}
                  aria-hidden
                />
              </div>
              <p>{estimatedTotal}</p>
            </div>
          ) : null}

          <div
            className='mt-1 cursor-pointer'
            role='button'
            onClick={availableHandler}
            aria-hidden
          >
            <p className='text-right orange--text paragraph3'>
              {availableLabel}
            </p>
          </div>
        </div>
      </div>

      {showInfoModal ? (
        <Modal
          className='backdrop--none'
          text={infoTitle}
          show={showInfoModal}
          buttonText={t('common.ok')}
          onButton={() => setShowInfoModal(false)}
        >
          {infoContent}
        </Modal>
      ) : null}

      <Show when={showBalanceModal}>
        <BalanceInfoModal close={() => setShowBalanceModal(false)} />
      </Show>
    </>
  )
}
