import './profile.style.scss'
import { useState, useEffect } from 'react'
import { isNil } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState, fetchUserBalances } from 'store'
import { formatMoney } from '@utils/helpers'
import { Button, Show, Spacer, Text } from '@components'
import { useAddFundsAndCashout, useNavigate } from '@hooks'
import { ListElement } from './components/listElement'
import { routes } from 'navigation'
import { AccountDeletion } from '@features'
import { MoneyOperationTypeEnum } from '@interfaces'
import { ReactComponent as InfoIcon } from '@assets/svg/info.svg'
import { ReactComponent as DeleteAccount } from '@assets/svg/alert.svg'
import {
  BalanceInfoModal,
  BrokerTierGlobalPositionModal,
  BrokerTierInfoModal,
} from '@shared/modals'
import {
  setMoneyOperationStep,
  setOperationType,
} from 'features/moneyOperations/thunk'
import { MoneyOperationsSteps } from 'features/moneyOperations/moneyOperations.props'
import {
  AppliedFees,
  HelpIcon,
  LanguageIcon,
  PaymentsIcon,
  ReceiptIcon,
  ThemeIcon,
} from '@assets/icons'
import { UIIcon, UIIconColorProps } from '@components/utils'
import {
  companyPrivacyPolicyUrl,
  companySecurityPolicyUrl,
} from '@utils/externalUrls'
import { getLang } from '@utils/langs'
import { ProfileHeader } from './components/profileHeader'
import { BrokerTierInfoCard } from './components/brokerTierInfoCard'
import { BrokerTierFeesModal } from '@shared/modals/brokerTierFeesModal/brokerTierFeesModal'
import { BrokerTierModalSteps } from '@shared/modals/brokerTierInfoModal/types'
import {
  fetchBrokerTier,
  fetchBrokerTierUserData,
} from '@store/commonReducers/brokerTier'
import { fetchUserProfile } from './thunk'

export default function Profile(): JSX.Element {
  const { t } = useTranslation()
  const { navigate } = useNavigate()
  const dispatch = useDispatch()
  const defLang = getLang().split('-')[0]

  const { profile, userBalance, brokerTier } = useSelector(
    (state: RootState) => state,
  )

  const { addFunds, cashout, modalOnboarding } = useAddFundsAndCashout({
    origin: 'Profile',
  })

  const cashAvailable = userBalance?.cashAvailable
  const actualCash = formatMoney(cashAvailable?.amount, cashAvailable?.currency)

  const [showBrokerTierInfomodal, setShowBrokerTierInfomodal] = useState(false)
  const [heytradeProFeesModal, setHeytradeProFeesModal] = useState(false)
  const [showBalanceInfoModal, setShowBalanceInfoModal] = useState(false)
  const [showGlobalPositionModal, setShowGlobalPositionModal] = useState(false)

  const goToScreen = (route: string): void => {
    navigate(route)
    window.scrollTo(0, 0)
  }

  const [isDeleteAccountVisible, setDeleteAccountVisible] = useState(false)

  useEffect(() => {
    dispatch(fetchBrokerTier())
    dispatch(fetchBrokerTierUserData())
    dispatch(fetchUserProfile())
    dispatch(fetchUserBalances())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const commonIconProps: UIIcon & UIIconColorProps = {
    size: 20,
    color: 'iconNeutralMedium',
  }

  const validTierInfo =
    brokerTier?.totalPortfolio?.currency &&
    !isNil(brokerTier?.totalPortfolio.amount) &&
    !isNil(brokerTier?.completedOrdersThisMonth)

  return (
    <>
      <div className='profile__wrapper --fade-in-animation'>
        <div className='profile__widget'>
          <div className='profile__widget__header'>
            <Spacer preset='large' />
            <ProfileHeader
              moreInfo={() => setHeytradeProFeesModal(true)}
              name={profile?.userProfile?.fullName}
              email={profile?.userProfile?.email}
              tier={brokerTier?.tier}
            />
            <Spacer preset='medium' />
            <div className='profile__widget__header__balance'>
              <div className='profile__widget__header__balance__title mb-1'>
                {t('profile.balance')}{' '}
                <InfoIcon
                  className='ml-1 cursor-pointer'
                  onClick={() => setShowBalanceInfoModal(true)}
                  aria-hidden
                />
              </div>
              <div className={'profile__widget__header__balance__money'}>
                {actualCash}
              </div>
            </div>
            <div className='profile__widget__header__actions w-85'>
              <div className='pr-1 w-100'>
                <Button
                  text={t('cashout.title')}
                  type='button'
                  buttonType='ghost'
                  size='big'
                  onClick={cashout}
                />
              </div>
              <div className='pl-1 w-100'>
                <Button
                  text={t('statusCards.fundAccountButton')}
                  type='button'
                  size='big'
                  onClick={addFunds}
                />
              </div>
            </div>
          </div>
          <Spacer preset='large' />
          {validTierInfo && (
            <BrokerTierInfoCard
              completedOrdersThisMonth={brokerTier?.completedOrdersThisMonth}
              tier={brokerTier?.tier}
              totalPortfolio={brokerTier?.totalPortfolio}
              moreInfo={() => setShowBrokerTierInfomodal(true)}
              openGlobalPosition={() => setShowGlobalPositionModal(true)}
            />
          )}
          <Spacer preset='large' />
          <div className='profile__widget__content'>
            <div className='list__menu'>
              {/* Payments */}
              <ListElement
                title={t('profile.paymentMethods')}
                subtitle={t('profile.paymentMethods_description')}
                icon={<PaymentsIcon {...commonIconProps} />}
                onClick={() => goToScreen(routes.auth.paymentMethods)}
              />
              {/* Transactions */}
              <ListElement
                title={t('profile.transactions')}
                subtitle={t('profile.transactionsDescription')}
                icon={<ReceiptIcon {...commonIconProps} />}
                onClick={() => navigate(routes.auth.transactionsExecuted)}
              />
              {/* Help */}
              <ListElement
                title={t('profile.help')}
                subtitle={t('profile.help_description')}
                icon={<HelpIcon {...commonIconProps} />}
                onClick={() => goToScreen(routes.auth.help)}
              />
              {/* Applicable Fees */}
              <ListElement
                title={t('profile.appliedFees')}
                subtitle={t('profile.appliedFeesDescription')}
                icon={<AppliedFees {...commonIconProps} />}
                onClick={() => setHeytradeProFeesModal(true)}
              />
              {/* Themes */}
              <ListElement
                title={t('profile.themes.title')}
                subtitle={t('profile.themes.subtitle')}
                icon={<ThemeIcon {...commonIconProps} />}
                onClick={() => goToScreen(routes.auth.themes)}
              />
              {/* Language */}
              <ListElement
                title={t('profile.language')}
                subtitle={t('profile.languageDescription')}
                icon={<LanguageIcon {...commonIconProps} />}
                onClick={() => goToScreen(routes.auth.language)}
              />
            </div>
            <div className='d-flex justify-space-between mt-3'>
              <div className='d-inline-flex align-center'>
                <div
                  aria-hidden
                  className='cursor-pointer'
                  onClick={() =>
                    window.open(companySecurityPolicyUrl[defLang] as string)
                  }
                >
                  <Text
                    textStyle='underline'
                    preset='paragraph2'
                    text={t('common.securityPolicy')}
                    color='iconPrimaryInitial'
                  />
                </div>
                <Spacer preset='tiny' />
                <Text
                  preset='paragraph2'
                  text={t('common.and')}
                  color='iconPrimaryInitial'
                />
                <Spacer preset='tiny' />
                <div
                  className='cursor-pointer'
                  aria-hidden
                  onClick={() =>
                    window.open(companyPrivacyPolicyUrl[defLang] as string)
                  }
                >
                  <Text
                    textStyle='underline'
                    preset='paragraph2'
                    text={t('common.privacyPolicy')}
                    color='iconPrimaryInitial'
                  />
                </div>
              </div>
              <div>
                <Button
                  autoWidth={true}
                  text={t('profile.requestDeletion')}
                  type='button'
                  textPreset='caption2'
                  buttonType='ghost--ultra--subtle'
                  onClick={() => setDeleteAccountVisible(true)}
                  left={<DeleteAccount />}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {isDeleteAccountVisible && (
        <AccountDeletion
          show={true}
          onClose={() => setDeleteAccountVisible(false)}
          onCancel={() => {
            setDeleteAccountVisible(false)
            goToScreen(routes.auth.help)
          }}
        />
      )}

      {modalOnboarding}
      {heytradeProFeesModal && (
        <BrokerTierFeesModal close={() => setHeytradeProFeesModal(false)} />
      )}
      {showBrokerTierInfomodal && (
        <BrokerTierInfoModal
          initialStep={BrokerTierModalSteps.learnMore}
          addFunds={() => {
            setShowBrokerTierInfomodal(false)
            dispatch(setOperationType(MoneyOperationTypeEnum.addMoney))
            dispatch(setMoneyOperationStep(MoneyOperationsSteps.selectAmount))
          }}
          close={() => {
            setShowBrokerTierInfomodal(false)
          }}
        />
      )}
      <Show when={showBalanceInfoModal}>
        <BalanceInfoModal close={() => setShowBalanceInfoModal(false)} />
      </Show>
      <Show when={showGlobalPositionModal}>
        <BrokerTierGlobalPositionModal
          close={() => setShowGlobalPositionModal(false)}
        />
      </Show>
      <Spacer preset='enormous' />
    </>
  )
}
