import { UIIcon, UIIconColorProps, getIconColorSVGFill } from 'components/utils'

export const InfoIcon = ({
  color,
  size = 24,
}: UIIcon & UIIconColorProps): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.00016 1.3335C4.32016 1.3335 1.3335 4.32016 1.3335 8.00016C1.3335 11.6802 4.32016 14.6668 8.00016 14.6668C11.6802 14.6668 14.6668 11.6802 14.6668 8.00016C14.6668 4.32016 11.6802 1.3335 8.00016 1.3335ZM7.3335 4.66683V6.00016H8.66683V4.66683H7.3335ZM8.66683 10.6668C8.66683 11.0335 8.36683 11.3335 8.00016 11.3335C7.6335 11.3335 7.3335 11.0335 7.3335 10.6668V8.00016C7.3335 7.6335 7.6335 7.3335 8.00016 7.3335C8.36683 7.3335 8.66683 7.6335 8.66683 8.00016V10.6668ZM2.66683 8.00016C2.66683 10.9402 5.06016 13.3335 8.00016 13.3335C10.9402 13.3335 13.3335 10.9402 13.3335 8.00016C13.3335 5.06016 10.9402 2.66683 8.00016 2.66683C5.06016 2.66683 2.66683 5.06016 2.66683 8.00016Z'
        fill={color ? getIconColorSVGFill(color) : 'currentColor'}
      />
    </svg>
  )
}
