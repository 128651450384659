import { ConservativeIcon } from './conservativeIcon'
import { GrowthIcon } from './growthIcon'
import { ModerateIcon } from './moderateIcon'

export const blackRockIcon = (srri): JSX.Element => {
  switch (srri) {
    case 3:
      return <ConservativeIcon size={38} />
    case 4:
      return <ModerateIcon size={38} />
    case 5:
      return <GrowthIcon size={38} />
    default:
      return null
  }
}
