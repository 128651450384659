import './button.styles.scss'
import { useMemo } from 'react'
import { ButtonProps } from './button.props'
import { ReactComponent as Spinner } from '@assets/svg/spinner.svg'
import { stringBuilder } from 'components/utils/utils'
import { Text } from 'components/text/text'

export function Button({
  autoWidth = false,
  buttonType = 'primary',
  left,
  content,
  right,
  textPreset = 'bigText',
  disabled,
  size,
  id,
  loading,
  onClick = () => {
    // Do nothing
  },
  text,
  type,
  shape = 'normal',
  testId,
  allowOnClickPropagation = false,
  justifyContent,
}: ButtonProps): JSX.Element {
  const btnClasses = useMemo(() => {
    return stringBuilder([
      ['button', true],
      ['button--with-auto', autoWidth],
      ['loading', !!loading],
      ['button--primary', buttonType === 'primary'],
      ['button--secondary', buttonType === 'secondary'],
      ['button--ghost', buttonType === 'ghost'],
      ['button--ghost--negative', buttonType === 'ghost--negative'],
      ['button--ghost--subtle', buttonType === 'ghost--subtle'],
      ['button--ghost--ultra--subtle', buttonType === 'ghost--ultra--subtle'],
      ['button--menu', buttonType === 'menu'],
      ['button--link', buttonType === 'link'],
      ['button--negative', buttonType === 'negative'],
      ['button--dark--negative', buttonType === 'dark--negative'],
      ['--size-tiny', size === 'tiny'],
      ['--size-smaller', size === 'smaller'],
      ['--size-small', size === 'small'],
      ['--size-medium', size === 'medium' || !size],
      ['--size-big', size === 'big'],
      ['--square-shape', shape === 'square'],
      ['--justify-around', justifyContent === 'around'],
      ['--justify-center', justifyContent === 'center' || !justifyContent],
      ['--justify-end', justifyContent === 'flex-end'],
      ['--justify-start', justifyContent === 'flex-start'],
      ['--justify-between', justifyContent === 'space-between'],
    ])
  }, [autoWidth, buttonType, loading, size, shape, justifyContent])

  const contentElement = useMemo(() => {
    return content ? (
      content
    ) : (
      <Text preset={textPreset} text={text} color='inherit' />
    )
  }, [text, content, textPreset])

  const elems = useMemo(() => {
    return (
      <>
        {left && <div className='button__element'>{left}</div>}
        {contentElement && (
          <div className='button__element'>{contentElement}</div>
        )}
        {right && <div className='button__element'>{right}</div>}
      </>
    )
  }, [left, right, contentElement])

  const spinner = useMemo(() => {
    return (
      <div className='button__element'>
        <Spinner
          className={`spinner ${
            buttonType == 'ghost' ? 'spinner--orange' : ''
          }`}
        />
      </div>
    )
  }, [buttonType])

  const handleClick = (e): void => {
    if (!allowOnClickPropagation) e.stopPropagation()
    onClick()
  }

  return (
    <button
      data-testid={testId}
      id={id}
      className={btnClasses}
      disabled={disabled}
      onClick={handleClick}
      type={type}
    >
      {loading ? spinner : elems}
    </button>
  )
}
