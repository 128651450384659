import {
  UIIcon,
  UIIconColorProps,
  UISecondaryColorProp,
  getIconColorSVGFill,
} from 'components/utils'

export const SuccessIcon = ({
  color,
  secondaryColor,
  size = 24,
}: UIIcon & UIIconColorProps & UISecondaryColorProp): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 26 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        cx='13'
        cy='12.5'
        r='12'
        fill={color ? getIconColorSVGFill(color) : 'currentColor'}
      />
      <path
        d='M10.9069 15.0303L8.641 12.7645C8.51901 12.6422 8.35338 12.5735 8.18065 12.5735C8.00792 12.5735 7.84229 12.6422 7.7203 12.7645C7.46563 13.0191 7.46563 13.4305 7.7203 13.6852L10.4498 16.4146C10.7044 16.6693 11.1158 16.6693 11.3705 16.4146L18.2791 9.50607C18.5337 9.2514 18.5337 8.84002 18.2791 8.58536C18.1571 8.46308 17.9914 8.39437 17.8187 8.39437C17.646 8.39437 17.4804 8.46308 17.3584 8.58536L10.9069 15.0303Z'
        fill={
          secondaryColor ? getIconColorSVGFill(secondaryColor) : 'currentColor'
        }
        stroke={
          secondaryColor ? getIconColorSVGFill(secondaryColor) : 'currentColor'
        }
      />
    </svg>
  )
}
