import { getTheme } from '@utils/theme'

export const BuySellFlowIllustration = (): JSX.Element => {
  const theme = getTheme()
  return theme === 'dark' ? (
    <svg width={25} height={24} viewBox='0 0 25 24' fill='none'>
      <rect x={13.5} y={11} width={8} height={3} fill='#FF9B63' />
      <rect x={3.5} y={11} width={8} height={3} fill='white' />
      <rect x={3.5} y={3} width={8} height={2} fill='white' />
      <rect x={3.5} y={7} width={14} height={1} fill='white' />
      <rect x={3.5} y={17} width={8} height={3} fill='white' />
      <rect x={13.5} y={17} width={8} height={3} fill='white' />
      <path
        d='M1 1C1 0.723858 1.22386 0.5 1.5 0.5H23.5C23.7761 0.5 24 0.723858 24 1V23C24 23.2761 23.7761 23.5 23.5 23.5H1.5C1.22386 23.5 1 23.2761 1 23V1Z'
        stroke='white'
      />
    </svg>
  ) : (
    <svg width={25} height={25} viewBox='0 0 25 25' fill='none'>
      <rect x={13.0288} y={11.5} width={8} height={3} fill='#FF9B63' />
      <rect x={3.02881} y={11.5} width={8} height={3} fill='#242631' />
      <rect x={3.02881} y={3.5} width={8} height={2} fill='#242631' />
      <rect x={3.02881} y={7.5} width={14} height={1} fill='#242631' />
      <rect x={3.02881} y={17.5} width={8} height={3} fill='#242631' />
      <rect x={13.0288} y={17.5} width={8} height={3} fill='#242631' />
      <path
        d='M0.528809 1.5C0.528809 1.22386 0.752667 1 1.02881 1H23.0288C23.305 1 23.5288 1.22386 23.5288 1.5V23.5C23.5288 23.7761 23.305 24 23.0288 24H1.02881C0.752666 24 0.528809 23.7761 0.528809 23.5V1.5Z'
        stroke='#242631'
      />
    </svg>
  )
}
