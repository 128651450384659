import { useTranslation } from '@hooks'
import { Button, CustomModal, Spacer } from '@components'
import { ReactComponent as SuccessIcon } from '@assets/svg/success.svg'
import { formatMoney } from '@utils/helpers'
import { useEffect } from 'react'
import { fetchUserProfile } from '@screens/profile/thunk'
import { useDispatch } from 'react-redux'

interface CompletedCashoutProps {
  values?: {
    accountId: string
    amount: number
    currency: string
    redirectUrl: string
  }
  onClose?: () => void
  show?: boolean
}

export function CompletedCashout({
  onClose,
  values,
}: CompletedCashoutProps): JSX.Element {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const amountToTransfer = formatMoney(values?.amount, values?.currency)

  useEffect(() => {
    dispatch(fetchUserProfile())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <CustomModal.Header linkText={`${t('common.close')}`} onClick={onClose} />
      <CustomModal.Content className='d-flex d-flex-col align-center justify-center'>
        <Spacer preset='medium' />
        <SuccessIcon className='wrapper__table__content__company__logo topup__bigLogo b-0' />
        <span className='headline1 primary--text mt-3 mb-2'>
          {t('cashout.titleTransferOrderSuccess')}
        </span>
        <p className='d-inline primary--text paragraph2'>
          {t('cashout.descriptionTransferOrderSuccess', {
            amountToTransfer,
          })}
        </p>
        <Spacer preset='medium' />
      </CustomModal.Content>
      <CustomModal.Footer>
        <Button text={t('common.close')} onClick={onClose}></Button>
      </CustomModal.Footer>
    </>
  )
}
