import './filterButton.styles.scss'
import { Cancel, ChevronDown } from '@assets/icons'
import { Text } from 'components'
import { useRef } from 'react'

export const FilterButton = ({
  title,
  disabled,
  onPress,
  handleOnClearFilter,
  onMouseEnter,
  isActive,
  activeFiltersNumber,
}: {
  disabled: boolean
  isActive?: boolean
  activeFiltersNumber?: number
  title: string
  onPress: (buttonLeftPosition: number) => void
  handleOnClearFilter: () => void
  onMouseEnter?: (buttonLeftPosition: number) => void
}): JSX.Element => {
  const buttonRef = useRef<HTMLButtonElement>(null)

  const getButtonLeftPosition = (): number => {
    if (buttonRef?.current) {
      return buttonRef?.current.getBoundingClientRect()?.left
    }
  }

  return (
    <button
      ref={buttonRef}
      onClick={() => {
        const pos = getButtonLeftPosition()
        if (pos) onPress(pos)
      }}
      onMouseEnter={() => {
        const pos = getButtonLeftPosition()
        if (pos && onMouseEnter) onMouseEnter(pos)
      }}
      disabled={disabled}
      className={`filters__button --with-background --with-icon ${
        isActive ? '--has-selection' : ''
      } cursor-pointer d-flex align-center justify-center`}
    >
      <div aria-hidden='true'>
        <Text preset='paragraph2' text={title} />
      </div>
      <div className='filters__button-count'>
        {activeFiltersNumber > 0 ? (
          <>
            <span className='filters__button-count-dot --fade-in-animation'>
              ·
            </span>
            <span className='--fade-in-animation'>{activeFiltersNumber}</span>
          </>
        ) : (
          ''
        )}
      </div>
      <div
        className='filters__button-icon d-flex align-center'
        role='button'
        aria-hidden='true'
        onClick={(e) => {
          if (isActive || activeFiltersNumber > 0) {
            e?.stopPropagation()
            handleOnClearFilter()
          }
        }}
      >
        {isActive ? (
          <Cancel size={20} color='iconPrimaryInitial' />
        ) : (
          <ChevronDown />
        )}
      </div>
    </button>
  )
}
