import { UIIcon, UIIconColorProps, getIconColorSVGFill } from 'components/utils'

export const TopUpIcon = ({
  color,
  size = 24,
}: UIIcon & UIIconColorProps): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_2528_19100)'>
        <path
          d='M29 26V27C29 28.1 28.1 29 27 29H13C11.89 29 11 28.1 11 27V13C11 11.9 11.89 11 13 11H27C28.1 11 29 11.9 29 13V14H20C18.89 14 18 14.9 18 16V24C18 25.1 18.89 26 20 26H29ZM20 24H30V16H20V24ZM24 21.5C23.17 21.5 22.5 20.83 22.5 20C22.5 19.17 23.17 18.5 24 18.5C24.83 18.5 25.5 19.17 25.5 20C25.5 20.83 24.83 21.5 24 21.5Z'
          fill={color ? getIconColorSVGFill(color) : 'currentColor'}
        />
      </g>
      <defs>
        <clipPath id='clip0_2528_19100'>
          <rect
            width='24'
            height='24'
            fill='white'
            transform='translate(8 8)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}
