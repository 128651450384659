import { AuthState } from '@interfaces'
import { RootState } from '@store'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

export interface AuthContextType {
  auth: AuthState
}

export function AuthProvider({
  children,
}: {
  children: React.ReactNode
}): JSX.Element {
  const auth = useSelector((state: RootState) => state.login)
  const value = useMemo(() => ({ auth }), [auth])
  const AuthContext = React.createContext<AuthContextType>({ auth: auth })

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
