import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { getResetMailCode, getVerificationCode, resetPassword } from '@services'
import { SendUserChallenge, TTL } from '@interfaces'

interface PasswordChange {
  email: string
  password: string
  verificationCode: string
}

export const clearTtl = createAction('clearTtl')

export const fetchVerificationCode = createAsyncThunk<TTL, SendUserChallenge>(
  'fetchVerificationCode',
  async (userChallenge: SendUserChallenge) => {
    if (userChallenge.challengeType === 'PASSWORD') {
      return getResetMailCode(userChallenge?.email || '')
    }
    return getVerificationCode(userChallenge)
  },
)

export const doSendVerificationCode = createAsyncThunk<void, PasswordChange>(
  'doSendVerificationCode',
  async ({ email, password, verificationCode }) => {
    return await resetPassword(email, password, verificationCode)
  },
)
