import {
  Button,
  CustomModal,
  CustomModalProps,
  Divider,
  Spacer,
  Text,
} from '@components'
import { useBalancesInfo, useTranslation } from '@hooks'
import { formatMoney } from '@utils/helpers'

export const BalanceInfoModal = ({
  opts = {},
  close,
}: {
  opts?: CustomModalProps
  close: () => void
}): JSX.Element => {
  const { t } = useTranslation()
  const userBalances = useBalancesInfo()

  return (
    <CustomModal
      show={true}
      theme='lighter'
      size='big'
      placeOn='right'
      height='fullHeight'
      dismissable
      onClose={close}
      {...opts}
    >
      <CustomModal.Header text={t('balanceInfo.title')} />
      <CustomModal.Content>
        <Text preset='paragraph2' text={t('balanceInfo.modal.balanceDesc')} />
        <Spacer preset='smaller' />
        <Divider />
        <Spacer preset='smaller' />
        <div className='d-flex'>
          <Text
            preset='headline4'
            color='iconPrimaryInitial'
            text={formatMoney(
              userBalances?.cashAvailable?.amount,
              userBalances?.cashAvailable?.currency,
            )}
          />
          &ensp;
          <Text
            preset='headline4'
            text={t('balanceInfo.availableForInvesting')}
          />
        </div>
        <Text
          preset='paragraph2'
          text={t('balanceInfo.modal.availableForInvestingDesc')}
        />
        <Spacer preset='smaller' />
        <Divider />
        <Spacer preset='smaller' />
        <div className='d-flex'>
          <Text
            preset='headline4'
            color='iconPrimaryInitial'
            text={formatMoney(
              userBalances?.lockedCash?.amount,
              userBalances?.lockedCash?.currency,
            )}
          />
          &ensp;
          <Text preset='headline4' text={t('balanceInfo.onHold')} />
        </div>
        <Text preset='paragraph2' text={t('balanceInfo.modal.onHoldDesc')} />
        <Spacer preset='smaller' />
        <Divider />
        <Spacer preset='smaller' />
        <div className='d-flex'>
          <Text
            preset='headline4'
            color='iconPrimaryInitial'
            text={formatMoney(
              userBalances?.maxWithdrawal?.amount,
              userBalances?.maxWithdrawal?.currency,
            )}
          />
          &ensp;
          <Text
            preset='headline4'
            text={t('balanceInfo.availableForCashout')}
          />
        </div>
        <Text
          preset='paragraph2'
          text={t('balanceInfo.modal.availableForCashoutDesc')}
        />
        <Spacer preset='smaller' />
        <Text preset='subtitle1' text={t('balanceInfo.modal.question')} />
        <Spacer preset='smaller' />
        <Text preset='paragraph2' text={t('balanceInfo.modal.answer')} />
      </CustomModal.Content>
      <CustomModal.Footer>
        <Button text={t('common.ok')} onClick={close} buttonType='primary' />
      </CustomModal.Footer>
    </CustomModal>
  )
}
