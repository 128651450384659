import { createReducer } from '@reduxjs/toolkit'
import { ReducerState, TranslationsResponse } from 'interfaces'
import { fetchTranslations } from './thunk'

const initialState: TranslationsResponse & ReducerState = {
  error: null,
  errors: null,
  loading: false,
  translations: null,
}

export const translationsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchTranslations.pending, (acc) => {
      acc.loading = true
      acc.error = null
    })
    .addCase(fetchTranslations.fulfilled, (acc, { payload }) => {
      acc.loading = false
      acc.translations = payload.translations
      acc.error = null
    })
    .addCase(fetchTranslations.rejected, (acc, { error }) => {
      acc.loading = false
      acc.error = error.message
    })
})
