import {
  LimitPriceRangeRequest,
  LimitPriceRangeResponse,
  OrderData,
  OrderRequest,
  CompanyOperationsAllowed,
  PreOrderData,
  PreOrderRequest,
  UserBankInfo,
} from '@interfaces'
import { api } from '@utils/api/api'

export const getAllowedOperationsTypes = (
  company: string,
): Promise<CompanyOperationsAllowed> => {
  return api.get(`/api/v1/orders/asset/${company}/operations`)
}

export const getLimitOrderPriceLimits = ({
  type,
  assetId,
}: LimitPriceRangeRequest): Promise<LimitPriceRangeResponse> => {
  return api.post('/api/v1/orders/markets/price-limit', { type, assetId })
}

export const requestOrder = (order: OrderRequest): Promise<OrderData> => {
  return api.post('/api/v2/orders', order)
}

export const checkout = (preorder: PreOrderRequest): Promise<PreOrderData> => {
  return api.post('/api/v3/pre-orders', preorder)
}

export const userBankInfo = (): Promise<UserBankInfo> =>
  api.get('api/v1/customers/me/bank-info')
