import { createAsyncThunk } from '@reduxjs/toolkit'
import { getOnboardingStatus } from '@services'
import { Onboarding } from '@interfaces'

export const fetchOnboardingStatus = createAsyncThunk<Onboarding>(
  'onboardingStatus',
  async () => {
    return await getOnboardingStatus()
  },
)
