import { PeriodEnum } from '@interfaces'
import { format, subDays, subMonths, subYears } from 'date-fns'

export function usePortfolioValuationRange(period?: PeriodEnum): string {
  if (period === PeriodEnum.max) {
    return ''
  }

  const today = new Date()
  let priorDate: Date

  if (!period) {
    priorDate = subMonths(today, 1)
  } else if (period === PeriodEnum.day) {
    priorDate = subDays(today, 1)
  } else if (period === PeriodEnum.week) {
    priorDate = subDays(today, 7)
  } else if (period === PeriodEnum.month) {
    priorDate = subMonths(today, 1)
  } else if (period === PeriodEnum.twoMonth) {
    priorDate = subMonths(today, 2)
  } else if (period === PeriodEnum.year) {
    priorDate = subYears(today, 1)
  }

  const portfolioTodayDate = format(today, 'yyyy-MM-dd')
  const portfolioPriorDate = format(priorDate, 'yyyy-MM-dd')
  return `${portfolioPriorDate},${portfolioTodayDate}`
}
