import { AssetIcon } from '@assets/icons'
import { BinIcon } from '@assets/svg'
import { BuySellEvents } from '@utils/eventTracker/eventKeys'
import { formatCurrency } from '@utils/helpers'
import { Avatar, Button, CustomTable, Spacer, Text } from 'components'
import { StockPerformance } from 'features'
import { useBuyAndSellActions } from 'hooks/useBuyAndSellActions'
import { useNavigate } from 'hooks/useNavigation'
import { AssetType, CompanyStocks } from 'interfaces'
import { useTranslation } from 'react-i18next'
import { trackingService } from 'services'

export const WatchlistTable = ({
  assets,
  screen,
  onDelete,
}: {
  assets: Array<CompanyStocks>
  screen: string
  onDelete: (id: string) => void
}): JSX.Element => {
  const { t } = useTranslation()
  const { navigate } = useNavigate()

  const { buy, modals } = useBuyAndSellActions()

  const onBuyClick = (p: { id: string; type: AssetType }): void => {
    trackingService.trackEvent({
      event: BuySellEvents.flowStarted,
      props: { origin, type: 'buy' },
    })
    buy({
      id: p?.id,
      origin: screen,
      type: p?.type,
    })
  }

  return (
    <>
      <CustomTable
        preset='table2'
        loading={false}
        rows={assets?.map((p) => (
          <CustomTable.Row
            key={`portfolio-row-${p?.id}-${p?.ticker}`}
            onClick={() => {
              navigate(`/company/${p?.id}`)
            }}
          >
            <CustomTable.Cell flexDirection='row' cellMaxWidth='270px'>
              <Avatar
                image={p?.logoUrl}
                roundness='full'
                fallback={<AssetIcon size={22} />}
                size='large'
              />
              <Spacer preset='smaller' />
              <div>
                <Text preset='paragraph2' text={p?.companyName} />
                <Text preset='paragraph2' color='tertiary' text={p?.ticker} />
              </div>
            </CustomTable.Cell>
            <CustomTable.Cell>
              <Text
                preset='paragraph2'
                align='right'
                text={formatCurrency(p?.price)}
              />
            </CustomTable.Cell>
            <CustomTable.Cell>
              <div className='d-flex justify-end'>
                <StockPerformance
                  performanceAmount={p?.increaseMoney}
                  performancePercentage={p?.increasePercentage}
                  plusSymbol={true}
                />
              </div>
            </CustomTable.Cell>
            <CustomTable.Cell flexDirection='column' align='right'>
              <div className='w-100 d-flex justify-end'>
                <Button
                  id='row-buy-button'
                  text={t('common.buy')}
                  loading={false}
                  autoWidth
                  onClick={() =>
                    onBuyClick({
                      id: p?.id,
                      type: p?.type,
                    })
                  }
                  size='smaller'
                  type='button'
                  textPreset='subtitle2'
                />
                <Spacer preset='tiny' />
                <Button
                  loading={false}
                  onClick={() => onDelete(p?.id)}
                  size='smaller'
                  type='button'
                  autoWidth={true}
                  buttonType='dark--negative'
                  textPreset='subtitle2'
                  content={<BinIcon />}
                />
              </div>
            </CustomTable.Cell>
          </CustomTable.Row>
        ))}
        header={
          <CustomTable.Header>
            <CustomTable.HeaderCell>{t('common.name')}</CustomTable.HeaderCell>
            <CustomTable.HeaderCell align='right'>
              {t('buySellFlow.summary.price')}
            </CustomTable.HeaderCell>
            <CustomTable.HeaderCell align='right'>
              {t('table_headers.1day')}
            </CustomTable.HeaderCell>
            <CustomTable.HeaderCell>
              <span className='d-none'>Buy/Sell</span>
            </CustomTable.HeaderCell>
          </CustomTable.Header>
        }
      />
      {modals}
    </>
  )
}
