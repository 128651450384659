import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import { CompanyPositionData, ReducerState } from '@interfaces'
import { clearAssetPosition, fetchCompanyInvestments } from './thunk'

const initialState: CompanyPositionData & ReducerState = {
  shares: 0,
  lockedShares: 0,
  id: '',
  ticker: '',
  currentValue: { amount: 0, currency: 'USD' },
  totalEarnings: { amount: 0, currency: 'USD' },
  totalPerformance: 0,
  totalEarningsToday: { amount: 0, currency: 'USD' },
  totalPerformanceToday: 0,
  portfolioPercentage: 0,
  loading: false,
  error: null,
  avgEntryPrice: {
    amount: 0,
    currency: 'USD',
  },
  type: null,
}

export const reducers = createReducer(initialState, (builder) =>
  builder
    .addCase(fetchCompanyInvestments.pending, (acc) => {
      acc.loading = true
      acc.error = null
    })
    .addCase(
      fetchCompanyInvestments.fulfilled,
      (acc, { payload }: PayloadAction<CompanyPositionData>) => {
        const {
          id,
          ticker,
          totalPerformance,
          totalEarnings,
          currentValue,
          portfolioPercentage,
          avgEntryPrice,
          type,
          shares,
          lockedShares,
        } = payload
        acc.shares = shares
        acc.lockedShares = lockedShares
        acc.currentValue = currentValue
        acc.type = type
        acc.totalEarnings = totalEarnings
        acc.totalPerformance = totalPerformance
        acc.portfolioPercentage = portfolioPercentage
        acc.avgEntryPrice = avgEntryPrice
        acc.ticker = ticker
        acc.id = id
        acc.loading = false
      },
    )
    .addCase(fetchCompanyInvestments.rejected, () => initialState)
    .addCase(clearAssetPosition, () => initialState),
)

export default reducers
