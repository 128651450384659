import { ModalProps } from './modal.props'
import './modal.styles.scss'
import './../customModal/header.styles.scss'
import './../customModal/footer.styles.scss'
import { BasicLink, Button } from '@components'
import { useTranslation } from '@hooks'
import { useNavigate } from '@hooks'
import { supportEmail } from '@utils/externalUrls'
import { routes } from 'navigation'

export function Modal({
  show,
  type,
  className,
  children,
  text,
  buttonSize,
  buttonType,
  buttonText,
  buttonLoading = false,
  linkText,
  textPreset,
  isLink,
  onButton,
  onBasicLink,
  hsymbol,
  iconButton,
}: ModalProps): JSX.Element {
  const { t } = useTranslation()
  const { navigate } = useNavigate()
  const commonText = t('common.ok')
  const commonCancelText = t('common.cancel')

  const content = [
    {
      id: 0,
      type: 'error',
      title: t('common.error'),
      description: t('common.errorWrapperText'),
      twoOptions: false,
      buttonText: commonText,
      linkText: '',
      route: '/',
      onBasicLink: () => {
        navigate(routes?.nonAuth.root)
      },
      onButton: onButton,
    },
    {
      id: 1,
      type: 'invalid',
      title: t('login.incorrectCredentialsTitle'),
      description: t('login.incorrectCredentialsMessage'),
      twoOptions: false,
      buttonText: commonText,
      linkText: '',
      route: '/',
      onBasicLink: () => {
        navigate(routes?.nonAuth.root)
      },
      onButton: onButton,
    },

    {
      id: 3,
      type: 'resetPassword',
      title: t('forgotPassword.reset'),
      description: t('forgotPassword.resetAlertDescription'),
      twoOptions: false,
      buttonText: commonText,
      linkText: '',
      route: '/',
      onBasicLink: () => {
        navigate(routes?.nonAuth.root)
      },
      onButton: onButton,
    },
    {
      id: 4,
      type: 'lostConnection',
      title: t('common.connectionError'),
      description: t('common.connectionErrorText', {
        supportEmail: supportEmail,
      }),
      twoOptions: false,
      buttonText: commonText,
      linkText: '',
      route: '/',
      onBasicLink: () => {
        navigate(routes?.nonAuth.root)
      },
      onButton: onButton,
    },
    {
      id: 5,
      type: 'backLogin',
      title: t('forgotPassword.passwordChanged'),
      description: '',
      twoOptions: false,
      buttonText: t('forgotPassword.backToLogin'),
      linkText: '',
      route: '/',
      onBasicLink: () => {
        navigate(routes?.nonAuth.root)
      },
      onButton: onButton,
    },

    {
      id: 9,
      type: 'balance',
      title: t('profile.balance'),
      description: (
        <div className='d-flex d-flex-col align-center justify-center space'>
          {t('profile.balance_hint')}
        </div>
      ),
      twoOptions: false,
      linkText: commonCancelText,
      route: '/',
      onBasicLink: () => {
        navigate(routes?.nonAuth.root)
      },
      onButton: onButton,
    },
    {
      id: 11,
      type: 'unlinkPaymethods',
      title: t('paymentMethods.unlinkTitle'),
      description: (
        <div className='d-flex d-flex-col align-center text-left'>
          <span className=''>{t('paymentMethods.unlinkSubtitle')}</span>
        </div>
      ),
      twoOptions: false,
      linkText: commonCancelText,
      route: '/',
      onBasicLink: () => {
        navigate(routes?.nonAuth.root)
      },
      onButton: onButton,
    },
    {
      id: 13,
      type: 'accountDeletion',
      title: t('accountDeletion.unableToDeleteTitle'),
      description: (
        <div className='d-flex d-flex-col align-center justify-center'>
          <span>{t('accountDeletion.unableToDeleteSubtitle')}</span>
        </div>
      ),
      twoOptions: false,
      buttonText: commonText,
      linkText: commonCancelText,
      route: '/',
      onBasicLink: () => {
        navigate(routes?.nonAuth.root)
      },
      onButton: onButton,
    },
    {
      id: 15,
      type: 'psd2token',
      title: t('addMoney.bankconnectionExpired'),
      description: (
        <div className='d-flex d-flex-col align-center justify-center space'>
          {t('addMoney.reconnectBank')}
        </div>
      ),
      twoOptions: true,
      linkText: commonCancelText,
      route: '/',
      onBasicLink: () => {
        navigate(routes?.nonAuth.root)
      },
      onButton: onButton,
    },
    {
      id: 16,
      type: 'incorrectCode',
      title: t('codeEnter.incorrectCode'),
      description: t('codeEnter.incorrectCodeDesc'),
      twoOptions: false,
      buttonText: commonText,
      linkText: '',
      route: '/',
      onBasicLink: () => {
        navigate(routes?.nonAuth.root)
      },
      onButton: onButton,
    },
    {
      id: 18,
      type: 'assessmentIncomplete',
      title: t('savingsPlans.assessmentIncomplete'),
      description: t('savingsPlans.assessmentIncompleteDesc'),
      twoOptions: true,
      buttonText: t('savingsPlans.startAssessment'),
      linkText: commonCancelText,
      route: '/',
      onBasicLink: () => {
        navigate(routes?.nonAuth.root)
      },
      onButton: onButton,
    },

    {
      id: 20,
      type: 'orderLimitType',
      title: t('buySellFlow.limitOrders.market'),
      twoOptions: false,
      buttonText: t('common.cancel'),
      route: '/',
      onButton: onButton,
    },
  ]

  const modalStyle = `wrapper__modal d-flex ${className} ${
    show ? 'wrapper__modal--visible' : ''
  }`

  const dataModal = content?.filter((i) => i.type == type)[0]
  const iconContent = iconButton ? iconButton : ''
  const options = dataModal ? dataModal.twoOptions : ''

  return (
    <div className={modalStyle} aria-hidden>
      <div className='wrapper__modal__content primary--text d-flex d-flex-col align-center justify-center text-center border-radius-default w-100'>
        <div className='wrapper__modal__content__head  px-2 w-100  '>
          <div className='wrapper__modal__content__head__title subtitle2 border-bottom-default px-2 py-3'>
            {text || dataModal?.title}
            {hsymbol ? <span className='orange--text'></span> : ''}
          </div>
          {(isLink || options) && (
            <BasicLink
              text={linkText || dataModal?.linkText}
              type='action'
              className='w-fit wrapper__modal__content__head__cancel'
              onClick={onBasicLink || dataModal?.onBasicLink}
              contentSize={textPreset}
            />
          )}
        </div>
        <div className='wrapper__body paragraph1 d-flex align-center justify-start w-100 py-6 px-3'>
          {children || dataModal?.description}
        </div>
        {(buttonText || dataModal?.buttonText) && (
          <div className=' pa-2 w-100'>
            <Button
              text={buttonText || dataModal?.buttonText}
              loading={buttonLoading}
              disabled={false}
              onClick={onButton || dataModal?.onButton}
              buttonType={buttonType}
              size={buttonSize}
              textPreset={textPreset}
              left={iconContent}
            />
          </div>
        )}
      </div>
    </div>
  )
}
