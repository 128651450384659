import { UIIcon, UIIconColorProps, getIconColorSVGFill } from 'components/utils'

export const CheckIcon = ({
  color,
  size = 24,
}: UIIcon & UIIconColorProps): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.795 15.875L5.325 12.405C5.13817 12.2177 4.88452 12.1125 4.62 12.1125C4.35548 12.1125 4.10183 12.2177 3.915 12.405C3.525 12.795 3.525 13.425 3.915 13.815L8.095 17.995C8.485 18.385 9.115 18.385 9.505 17.995L20.085 7.41499C20.475 7.02499 20.475 6.39499 20.085 6.00499C19.8982 5.81774 19.6445 5.71251 19.38 5.71251C19.1155 5.71251 18.8618 5.81774 18.675 6.00499L8.795 15.875Z'
        fill={color ? getIconColorSVGFill(color) : 'currentColor'}
      />
    </svg>
  )
}
