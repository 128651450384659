import './languages.style.scss'
import { useTranslation } from '@hooks'
import { GoBack } from '@components'
import { ListElement } from '../components/listElement'
import { changeLang, Language, languages } from '@utils/langs'
import { useDispatch } from 'react-redux'
import { fetchTranslations } from '@store/commonReducers/translations'
import i18next from 'i18next'
import { useNavigate } from '@hooks'
import { routes } from 'navigation'

export default function Languages(): JSX.Element {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { navigate } = useNavigate()

  const handleChange = (lang: Language): void => {
    i18next
      .changeLanguage(lang.code)
      .then(() => {
        changeLang(lang.code)
        dispatch(fetchTranslations(lang.apiLanguage))
      })
      .catch(() => {
        // Do nothing
      })
  }

  return (
    <div className='profile__widget lang__screen --fade-in-animation'>
      <div className='profile__widget__header'>
        <GoBack goBack={() => navigate(routes.auth.profile)} />
        <div className='title mt-3'>{t('profile.language')}</div>
      </div>
      <div className='profile__widget__content mt-5 px-3'>
        <div className='lang__screen__wrapper'>
          {languages.map((lang) => {
            return (
              <ListElement
                key={lang.code}
                title={lang.nativeName}
                icon={lang.code.slice(0, 2)}
                onClick={() => handleChange(lang)}
                target='_blank'
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}
