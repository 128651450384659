import { BasicOption } from '@shared/modals'
import { Money } from './money'
import { PeriodicityEnum } from './periodicityEnum'

interface CreditCardPaymentBase {
  amount: Money
  successUrl: string
  failureUrl: string
}

export interface CreditCardPaymentData extends CreditCardPaymentBase {
  cardToken: string
  saveCard?: boolean
}

export interface RecurringPaymentData {
  amount: Money
  periodicity: PeriodicityEnum
  fromDate: string
  toDate: string
  successUrl: string
  failureUrl: string
  cardId?: string
  cardToken?: string
}

export interface RecurringPayment {
  id: string
  amount: Money
  card: {
    id: string
    scheme: string
    last4: string
  }
  periodicity: PeriodicityEnum
  status: string
  createdAt: string
  fromDate: string
  toDate: string
  nextPaymentDate: string
  numExecutedOperations: number
  numOperations: number
}

export interface StoredCreditCardPaymentData extends CreditCardPaymentBase {
  cardId: string
}

export interface CreditCardPaymentInfo {
  id: string
  externalId: string
  status: 'Pending'
  amount: Money
  fee: {
    percentage: number
  }
  totalAmount: Money
  card: {
    scheme: string
    last4: string
  }
  redirectUrl: string
}

export enum CardFlowType {
  NEWCARD = 'NEWCARD',
  STOREDCARD = 'STOREDCARD',
}

export interface CardPaymentSummaryFlow {
  isReccurring: boolean
  amount: Money
  feesPercentage: string
  feesAmount: Money
  total: Money
  periodicity: BasicOption<PeriodicityEnum>
  startingDate: string
  expirationDate: string
}
