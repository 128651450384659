import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import {
  LimitOrderPriceLimitsState,
  LimitPriceRangeResponse,
} from '@interfaces'
import { clearLimitOrdersForm, fetchLimitOrderPriceLimits } from './thunk'

const initialState: LimitOrderPriceLimitsState = {
  error: null,
  isLoading: null,
  assetPrice: null,
  lowestAllowedPrice: null,
  highestAllowedPrice: null,
  limitsFetched: false,
}

export const reducers = createReducer(initialState, (builder) => {
  builder
    .addCase(
      fetchLimitOrderPriceLimits.pending,
      (acc: LimitOrderPriceLimitsState) => {
        acc.isLoading = true
        acc.highestAllowedPrice = null
        acc.lowestAllowedPrice = null
        acc.limitsFetched = false
      },
    )
    .addCase(
      fetchLimitOrderPriceLimits.rejected,
      (acc: LimitOrderPriceLimitsState, { error }) => {
        acc.isLoading = false
        acc.error = error.message
        acc.limitsFetched = false
      },
    )
    .addCase(
      fetchLimitOrderPriceLimits.fulfilled,
      (
        acc: LimitOrderPriceLimitsState,
        action: PayloadAction<LimitPriceRangeResponse>,
      ) => {
        acc.isLoading = false
        acc.assetPrice = action?.payload?.assetPrice
        acc.lowestAllowedPrice = action?.payload?.lowestAllowedPrice
        acc.highestAllowedPrice = action?.payload?.highestAllowedPrice
        acc.limitsFetched = true
      },
    )
    .addCase(clearLimitOrdersForm, (acc: LimitOrderPriceLimitsState) => {
      acc.error = null
      acc.isLoading = null
      acc.assetPrice = null
      acc.lowestAllowedPrice = null
      acc.highestAllowedPrice = null
      acc.limitsFetched = false
    })
})

export default reducers
