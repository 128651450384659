import {
  Button,
  CustomModal,
  Divider,
  GoBack,
  ModalLoader,
  ReadOnlyTextField,
  RoundedSwitchButton,
  Spacer,
  Text,
  TextField,
} from '@components'
import { yupResolver } from '@hookform/resolvers/yup'
import { useBalancesInfo } from 'hooks/useBalancesInfo'
import { t } from 'i18next'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import * as yup from 'yup'
import { ChangeEvent, useEffect, useState } from 'react'
import { useOnChangeMoneyForm } from 'hooks/useOnChangeMoneyForm'
import { AboutManualTransferWithdraw } from '@shared/modals/aboutManualTransferWithdraw/aboutManualTransferWithdraw'
import {
  addInputAmount,
  manualTransferWithdraw,
} from '../manualTransferNewAccount/thunk'
import { setMoneyOperationStep } from '../thunk'
import { MoneyOperationsSteps } from '../moneyOperations.props'
import { formatCurrencyToSymbol } from '@utils/helpers'

interface IForm {
  bankName: string
  accountNumber: string
  amount: string
  rememberAccount: boolean
}

interface IManualTransferWithdraw {
  nextStep: () => void
  prevStep: () => void
  onClose?: () => void
}

const ManualTransferWithdraw = ({
  nextStep,
  prevStep,
}: IManualTransferWithdraw): JSX.Element => {
  const [isManualTransferAboutOpen, setManualTransferAboutOpen] = useState(true)
  const profile = useSelector((state: RootState) => state?.profile)
  const manualBankTransfer = useSelector(
    (state: RootState) => state.manualBankTransfer,
  )
  const userBalance = useSelector((state: RootState) => state.userBalance)
  const { loading, withdrawalManualTransferEnded, inputAmount, error } =
    manualBankTransfer
  const balanceInfo = useBalancesInfo()
  const dispatch = useDispatch()
  const requiredText = t('common.fieldRequired')
  const schemaReset = yup.object().shape({
    bankName: yup.string().required(requiredText),
    accountNumber: yup
      .string()
      .matches(/^[a-zA-Z0-9]+$/, 'No special characters or spaces allowed')
      .required(requiredText),
    amount: yup.string().required(requiredText),
    rememberAccount: yup.boolean(),
  })

  const addFundForm = useForm({
    resolver: yupResolver(schemaReset),
    criteriaMode: 'all',
    mode: 'onChange',
  })

  const {
    register,
    setValue,
    handleSubmit,
    formState,
    watch,
    trigger,
    getValues,
  } = addFundForm
  const onChangeAmount = useOnChangeMoneyForm(
    addFundForm,
    'amount',
    2,
    (amount) => dispatch(addInputAmount(amount)),
  )

  const onChangeBankName = async (
    e: ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    setValue('bankName', e.target.value)
    await trigger('bankName')
  }

  const onChangeAccountNumber = async (
    e: ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    setValue('accountNumber', e.target.value)
    await trigger('accountNumber')
  }

  const onSubmit = (data: IForm): void => {
    dispatch(
      manualTransferWithdraw({
        bankName: data.bankName,
        accountNumber: data.accountNumber,
        amount: inputAmount,
        saveTemplate: data.rememberAccount || true,
        currency: userBalance.balance.currency,
      }),
    )
  }

  useEffect(() => {
    if (error) {
      dispatch(setMoneyOperationStep(MoneyOperationsSteps.failedCashout))
    }
    if (withdrawalManualTransferEnded) {
      nextStep()
    }
  }, [withdrawalManualTransferEnded, nextStep, error, dispatch])

  const disabled = !formState.isValid || loading || inputAmount <= 0

  return (
    <>
      {isManualTransferAboutOpen && (
        <CustomModal.OverlayContent>
          <AboutManualTransferWithdraw
            show={isManualTransferAboutOpen}
            onClose={() => setManualTransferAboutOpen(false)}
          />
        </CustomModal.OverlayContent>
      )}

      {loading && (
        <>
          <ModalLoader
            text={t('manualTransfer.bankAccount.loaderTitle')}
            className='background_transparent'
          />
        </>
      )}
      <>
        <CustomModal.Header
          text={t('withdrawMoney.header')}
          className='subtitle2 dark--label-title'
        >
          <GoBack
            goBack={() => {
              prevStep()
            }}
          />
        </CustomModal.Header>
        <div className='w-100'>
          <Divider />
        </div>
        <CustomModal.Content>
          <form
            id='myForm'
            autoComplete='off'
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className='flex flex-col h-100'>
              <div className='text-left headline1 mt-2 mb-1'>
                {t('withdrawMoney.account.title')}
              </div>
              <Spacer preset='medium' />
              <ReadOnlyTextField
                textStyle='paragraph1'
                label={t('addCreditCard.cardHolder')}
                value={profile?.userProfile?.fullName}
                size='small'
              />
              <Spacer preset='small' />

              <TextField
                register={register}
                theme='subtle'
                inputProps={{
                  name: 'bankName',
                  label: t('manualTransfer.bankAccount.bankName'),
                  onChange: onChangeBankName,
                  value: getValues().bankName,
                }}
              />

              <Spacer preset='small' />
              <TextField
                register={register}
                theme='subtle'
                inputProps={{
                  name: 'accountNumber',
                  label: t('manualTransfer.bankAccount.accountNumber'),
                  onChange: onChangeAccountNumber,
                  value: getValues().accountNumber,
                }}
              />

              <Spacer preset='small' />
              <div className='d-flex align-center justify-between'>
                <div className='flex'>
                  <Text
                    preset='bodyRegular'
                    color='primary'
                    text={t('manualTransfer.bankAccount.switchTitle')}
                  />
                  <Text
                    preset='caption'
                    color='secondary'
                    text={t('withdrawMoney.account.safelyStore')}
                  />
                </div>
                <div className='ml-2'>
                  <RoundedSwitchButton
                    size='mediumPlus'
                    initialState={true}
                    onChange={(value) => {
                      setValue('rememberAccount', value)
                    }}
                  />
                </div>
              </div>
              <Spacer preset='smaller' />

              <div className='text-left headline1 mt-4 mb-2'>
                {t('manualTransfer.bankAccount.amountTransfer')}
              </div>

              <Spacer preset='smaller' />
              <TextField
                left={
                  <div>
                    {watch('amount') ? (
                      <Text
                        preset='subtitle2'
                        text={formatCurrencyToSymbol(
                          userBalance.balance.currency,
                        )}
                      />
                    ) : null}
                  </div>
                }
                register={register}
                theme='subtle'
                inputProps={{
                  name: 'amount',
                  label: t('manualTransfer.bankAccount.amount'),
                  onChange: onChangeAmount,
                  value: getValues().amount,
                }}
              />
              <Spacer preset='tiny' />
              <Text preset='caption' color='tertiary'>
                {t('withdrawMoney.account.balance')} $
                {balanceInfo.cashAvailable.amount}
              </Text>
              <Spacer preset='small' />
            </div>
          </form>
        </CustomModal.Content>
        <CustomModal.Footer>
          <Button
            disabled={disabled}
            onClick={handleSubmit(onSubmit)}
            text={t('withdrawMoney.confirmWithdrawal')}
          />
        </CustomModal.Footer>
      </>
    </>
  )
}

export default ManualTransferWithdraw
