import './../buy.styles.scss'
import { useTranslation } from '@hooks'
import {
  BasicLink,
  CustomModal,
  Button,
  SummaryCard,
  SummaryRowProps,
  Card,
  Spacer,
  Text,
} from '@components'
import { isNil } from 'lodash'
import { useCallback, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'store'
import { formatCurrency } from '@utils/helpers'
import { fetchUserProfile } from '@screens/profile/thunk'
import { trackingService } from '@services'
import { OrderData, OrderOperationTypeEnum, OrderTypeEnum } from '@interfaces'
import { resetOrder } from '@store'
import { useNavigate } from '@hooks'
import { BuySellEvents } from '@utils/eventTracker/eventKeys'
import { clearMoneyOperationsState } from 'features/moneyOperations/thunk'
import { Cancel, SuccessIcon } from '@assets/icons'

export function AssetOperationSummary({
  onClose,
}: {
  onClose: () => void
}): JSX.Element {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { navigate } = useNavigate()

  const orderData: OrderData = useSelector(
    (state: RootState) => state.buySell.orderData,
  )

  const { buySell, companyStocks } = useSelector((state: RootState) => state)

  const error = !isNil(buySell?.sendOrderError)

  const isBuy = orderData?.type === OrderOperationTypeEnum.buy
  const isSell = orderData?.type === OrderOperationTypeEnum.sell

  const successHeader = useMemo(() => {
    const isMarket = orderData?.method === OrderTypeEnum.market
    const isLimit = orderData?.method === OrderTypeEnum.limit

    if (isBuy && isMarket) {
      return t('buySellFlow.confirmationModal.sentBuyMarketOrder')
    }

    if (isSell && isMarket) {
      return t('buySellFlow.confirmationModal.sentSellMarketOrder')
    }

    if (isBuy && isLimit) {
      return t('buySellFlow.confirmationModal.sentBuyLimitOrder')
    }

    if (isSell && isLimit) {
      return t('buySellFlow.confirmationModal.sentSellLimitOrder')
    }

    return null
  }, [isBuy, isSell, orderData?.method, t])

  const errorHeader = t('common.somethingWentWrong')

  const header = error ? errorHeader : successHeader

  const marketOrderRows: SummaryRowProps[] = useMemo(() => {
    return [
      {
        label: t('buySellFlow.confirmationModal.marketPrice'),
        value: `1 ${orderData?.asset?.ticker} = ${formatCurrency(
          orderData?.estimatedPrice,
        )}`,
      },
      {
        labelChildren: (
          <p className='paragraph3'>{t('buySellFlow.summary.totalCost')}</p>
        ),
        valueChildren: (
          <p className='paragraph3'>
            {`${formatCurrency(
              isBuy
                ? orderData?.calculations?.lockedCash
                : orderData?.calculations?.totalCost,
            )}`}
          </p>
        ),
      },
    ]
  }, [isBuy, orderData, t])

  const limitOrderRows: SummaryRowProps[] = useMemo(() => {
    return [
      {
        label: t('buySellFlow.confirmationModal.limitPrice'),
        value: `1 ${orderData?.asset?.ticker} = ${formatCurrency(
          orderData?.estimatedPrice,
        )}`,
      },
      {
        labelChildren: (
          <p className='subtitle3'>
            {isBuy
              ? t('buySellFlow.confirmationModal.totalOnHold')
              : t('buySellFlow.confirmationModal.estimatedEarnings')}
          </p>
        ),
        valueChildren: (
          <p className='subtitle3'>
            {`${formatCurrency(
              isBuy
                ? orderData?.calculations?.lockedCash
                : orderData?.calculations?.totalCost,
            )}`}
          </p>
        ),
      },
    ]
  }, [isBuy, orderData, t])

  const clearOrdersInfo = (): void => {
    dispatch(resetOrder())
  }

  const handleViewInformation = useCallback(() => {
    navigate(`/transactions/${orderData?.id}/${orderData?.type}`)
  }, [navigate, orderData])

  const successDetails: JSX.Element = useMemo(() => {
    const isLimit = orderData?.method === OrderTypeEnum.limit

    return isLimit ? (
      <>
        <SummaryCard
          id={`limitOrderData-${orderData?.id}`}
          data={limitOrderRows}
        />
        {isBuy && (
          <p className='text-left paragraph3 secondary--text pb-2 px-2'>
            {t('buySellFlow.totalOnHoldDisclaimer')}
          </p>
        )}
      </>
    ) : (
      <SummaryCard
        id={`marketOrderData-${orderData?.id}`}
        data={marketOrderRows}
      />
    )
  }, [orderData, limitOrderRows, isBuy, t, marketOrderRows])

  useEffect(() => {
    trackingService.trackEvent({
      event: BuySellEvents.orderSentFE,
      props: {
        type: orderData?.type,
        method: orderData?.method,
        shares: orderData?.shares,
        ticker: orderData?.asset.ticker,
        id: orderData?.asset.id,
        assetGroup: companyStocks?.type,
        totalCost: orderData?.calculations.totalCost.amount,
      },
    })
    dispatch(fetchUserProfile())

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <CustomModal.Header linkText={t('common.close')} onClick={onClose} />
      <CustomModal.Content>
        <div className='w-100 h-100 d-flex d-flex-col justify-center'>
          {!error ? (
            <>
              <Card padding='tiny' width='full'>
                <div className='w-100 d-flex d-flex-col justify-center align-center'>
                  <Spacer preset='medium' />
                  <SuccessIcon size={64} color='positive' />
                  <Spacer preset='smaller' />
                  <Text preset='paragraph5' text={header} align='center' />
                  <Spacer preset='tinyPlus' />
                  <div className='headline1 primary--text mb-3'>
                    <p className='d-inline positive--text'>
                      {orderData?.shares}{' '}
                    </p>
                    {orderData?.asset?.ticker}
                  </div>
                  <Spacer preset='tinyPlus' />
                </div>
                {successDetails}
              </Card>
              <Spacer preset='small' />
              <BasicLink
                onClick={() => {
                  clearOrdersInfo()
                  handleViewInformation()
                  dispatch(clearMoneyOperationsState())
                }}
                className='paragraph2'
              >
                {t('buySellFlow.confirmationModal.viewInformation')}
              </BasicLink>
            </>
          ) : (
            <Card padding='big'>
              <div className='w-100 d-flex d-flex-col justify-center align-center'>
                <Cancel size={64} color='negative' />
                <Spacer preset='smaller' />
                <Text preset='paragraph5' text={header} align='center' />
                <Spacer preset='small' />
                <Text
                  preset='paragraph2'
                  text={t('buySellFlow.confirmationModal.orderFailedError')}
                  align='center'
                />
              </div>
            </Card>
          )}
        </div>
      </CustomModal.Content>
      <CustomModal.Footer className='d-flex d-flex-col'>
        <Button
          text={t('common.close')}
          type='button'
          size='big'
          onClick={onClose}
        />
      </CustomModal.Footer>
    </>
  )
}
