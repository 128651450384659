import { UIIcon, UIIconColorProps, getIconColorSVGFill } from 'components/utils'

export const BuyLimitOrderIcon = ({
  color,
  size = 24,
}: UIIcon & UIIconColorProps): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.09998 4.6001L7.63832 9.96359C7.88943 10.2604 8.28977 10.3847 8.66482 10.2824L12.3046 9.28974C12.7199 9.17649 13.161 9.34167 13.3998 9.69981L17.5 15.8501'
        stroke={color ? getIconColorSVGFill(color) : 'currentColor'}
        strokeWidth='2'
        strokeLinecap='round'
      />
      <path
        d='M1.50012 17.2002L22.5001 17.2002'
        stroke={color ? getIconColorSVGFill(color) : 'currentColor'}
        strokeWidth='2'
        strokeLinecap='round'
        strokeDasharray='2 2'
      />
      <circle
        cx='17.5'
        cy='16.6001'
        r='2'
        fill='white'
        stroke={color ? getIconColorSVGFill(color) : 'currentColor'}
        strokeWidth='2'
      />
    </svg>
  )
}
