import './brokerTierInfoModal.scss'
import { CloseIcon } from '@assets/icons'
import {
  BrokerInfoTier,
  EuroAndDollarIcons,
  NorthAmericaIllustration,
  ProTierTbc,
} from '@assets/illustrations'
import { Accordion, Button, CustomModal, Spacer, Text } from '@components'
import { useTranslation } from '@hooks'
import { formatCurrency } from '@utils/helpers'
import { useState } from 'react'
import { brokerTierConstants } from '../../../config'
import { BrokerTierModalSteps } from './types'
import { RootState } from 'store'
import { useSelector } from 'react-redux'
import { BrokerTierEnum } from 'interfaces'
import {
  currentExchange,
  northAmericanFees,
  safekeeping,
  worldFees,
} from './brokerTierInfoModal.constant'
import { FeePerShare } from './component/freePerShare/FreePerShare'
import { Safekeeping } from './component/safekeeping/Safekeeping'
import { CurrencyExchange } from './component/currencyExchange/currencyExchange'
import RestWorld from '@assets/illustrations/brokerTier/restWorld.illustration'
import DollarSymbol from '@assets/illustrations/brokerTier/dollarSymbol.illustration'

export const BrokerTierInfoModal = ({
  initialStep,
  close,
  addFunds,
  openVisibilityConfig,
}: {
  initialStep: BrokerTierModalSteps
  close: () => void
  addFunds: () => void
  openVisibilityConfig?: () => void
}): JSX.Element => {
  const { t } = useTranslation()

  const [step, setStep] = useState<BrokerTierModalSteps>(
    initialStep || BrokerTierModalSteps.initial,
  )
  const [restWorld, setRestWorld] = useState(2)

  const { brokerTier } = useSelector((state: RootState) => state)
  const isPro = brokerTier.tier === BrokerTierEnum.pro

  return (
    <CustomModal
      size='big'
      height='fullHeight'
      show
      dismissable
      onClose={close}
      placeOn='right'
    >
      <CustomModal.Header>
        <Button
          onClick={() => close()}
          buttonType='link'
          autoWidth
          content={<CloseIcon color='iconPrimaryInitial' size={16} />}
          shape='square'
        />
      </CustomModal.Header>
      {step === BrokerTierModalSteps.initial && (
        <>
          <CustomModal.Content>
            <Spacer preset='medium' />
            <div className='d-flex d-flex-col w-100'>
              <div className='d-flex justify-center w-100'>
                <BrokerInfoTier />
              </div>
              <Spacer preset='medium' />
              <Text
                align='center'
                preset='headline2'
                text={t(
                  isPro ? 'brokerTier.welcomeToPro' : 'brokerTier.investMore',
                )}
              />
              <Spacer preset='tiny' />
              <Text
                align='center'
                preset='paragraph2'
                text={t(
                  isPro
                    ? 'brokerTier.welcomeDesc'
                    : 'brokerTier.learnHowToUnlock',
                )}
                color='secondary'
              />

              <Spacer preset='huge' />
            </div>
          </CustomModal.Content>
          <CustomModal.Footer>
            <div className='w-100'>
              <Button
                text={t('common.learnMore')}
                onClick={() => setStep(BrokerTierModalSteps.learnMore)}
              />
              <Spacer preset='smaller' />
              <Button
                buttonType='link'
                text={t('common.maybeLater')}
                onClick={() => {
                  close()
                  if (openVisibilityConfig) {
                    openVisibilityConfig()
                  }
                }}
              />
            </div>
          </CustomModal.Footer>
        </>
      )}

      {step === BrokerTierModalSteps.learnMore && (
        <>
          <CustomModal.Content>
            <div className='d-flex d-flex-col w-100'>
              <Spacer preset='medium' />
              <div className='d-flex justify-center w-100'>
                <ProTierTbc width={186} height={50} />
              </div>
              <Spacer preset='medium' />
              <Text
                align='center'
                preset='headline1'
                text={t(
                  isPro ? 'brokerTier.welcomeToPro' : 'brokerTier.upgradeToPro',
                )}
              />
              <Spacer preset='tiny' />
              <div className='broker-tier-lead-text'>
                <Text align='center' preset='paragraph4' color='primary'>
                  {t(
                    isPro
                      ? 'brokerTier.keepEnjoying'
                      : 'brokerTier.unlockUnbeatable',
                    {
                      minimumGlobalPosition: formatCurrency(
                        brokerTierConstants.TPR.minimumGlobalPosition,
                        2,
                      ),
                      tradesAmount:
                        brokerTierConstants.TPR.minimumTradesPerMonth,
                    },
                  )}
                </Text>
              </div>
              <Spacer preset='huge' />
              <FeePerShare
                title={'brokerTier.northAmerican.title'}
                logo={<NorthAmericaIllustration />}
                feesList={northAmericanFees}
                isPro={isPro}
              />
              <Spacer preset='huge' />
              <FeePerShare
                title={'brokerTier.worldFees.title'}
                logo={<RestWorld />}
                feesList={worldFees.slice(0, restWorld)}
                isPro={isPro}
              />
              <Spacer preset='medium' />

              <Button
                buttonType='link'
                text={
                  restWorld === worldFees.length
                    ? t('common.viewLess')
                    : t('common.viewMore')
                }
                textPreset='bodyRegular'
                onClick={() => {
                  setRestWorld(
                    restWorld === worldFees.length ? 2 : worldFees.length,
                  )
                }}
              />
              <Spacer preset='huge' />
              <Safekeeping
                title='brokerTier.safekeeping.title'
                logo={<DollarSymbol />}
                feesList={safekeeping}
                isPro={isPro}
              />
              <Spacer preset='huge' />
              <CurrencyExchange
                title='brokerTier.currentExchange.title'
                logo={<EuroAndDollarIcons />}
                feesList={currentExchange}
                isPro={isPro}
              />
              <Spacer preset='medium' />
              <Text
                text={t('brokerTier.questionsAboutCompany')}
                preset='headline2'
              />
              <Spacer preset='small' />
              <Accordion
                items={[
                  {
                    id: 'howToQualify',
                    header: t('brokerTier.howToQualify'),
                    content: t('brokerTier.howToQualifyAnswer', {
                      moneyAmount: formatCurrency(
                        brokerTierConstants.TPR.minimumGlobalPosition,
                      ),
                      tradesAmount:
                        brokerTierConstants.TPR.minimumTradesPerMonth,
                    }),
                  },
                  {
                    id: 'howWillIknowIf',
                    header: t('brokerTier.howWillIknowIf'),
                    content: t('brokerTier.howWillIknowIfAnswer'),
                  },
                  {
                    id: 'howCanIavoid',
                    header: t('brokerTier.howCanIavoid'),
                    content: t('brokerTier.howCanIavoidAnswer', {
                      moneyAmount: formatCurrency(
                        brokerTierConstants.TPR.minimumGlobalPosition,
                      ),
                      tradesAmount:
                        brokerTierConstants.TPR.minimumTradesPerMonth,
                    }),
                  },
                ]}
              />
            </div>
            <Spacer preset='medium' />
          </CustomModal.Content>
          <CustomModal.Footer>
            <Button size='big' text={t('common.addFunds')} onClick={addFunds} />
          </CustomModal.Footer>
        </>
      )}
    </CustomModal>
  )
}
