import { APIEvents } from '@utils/eventTracker/eventKeys'
import { Middleware } from 'redux'
import { trackingService } from '../../services/trackingService/trackingService'
import { RootState } from '../rootReducer'
import { environment } from '../../config'

enum ActionResultEnum {
  fulfilled = 'fulfilled',
  rejected = 'rejected',
}

const trackFetchAction = (
  actionType: string,
  actionResult: ActionResultEnum,
): void => {
  const index = actionType.indexOf('/')
  const name = actionType.substring(0, index)

  trackingService.trackEvent({
    event: APIEvents.apiCalled,
    props: {
      action: name,
      status: actionResult,
    },
  })
}

const trackingMiddleware: Middleware<RootState> = () => (next) => (action) => {
  const { type } = action

  if (type.includes('/fulfilled') && environment?.nodeEnv !== 'test') {
    trackFetchAction(type as string, ActionResultEnum.fulfilled)
  }

  if (type.includes('/rejected') && environment?.nodeEnv !== 'test') {
    trackFetchAction(type as string, ActionResultEnum.rejected)
  }

  return next(action)
}

export default trackingMiddleware
