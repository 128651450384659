import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import { fetchTaxesUrl } from './thunk'

export interface CommonUrlsState {
  taxesUrl: string
}

const initialState: CommonUrlsState = {
  taxesUrl: null,
}

export const commonUrlsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchTaxesUrl.pending, (acc) => {
      acc.taxesUrl = null
    })
    .addCase(
      fetchTaxesUrl.fulfilled,
      (acc, { payload }: PayloadAction<string>) => {
        acc.taxesUrl = payload
      },
    )
})
