import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { getStocksWatchlist, unsetStockWatchlistState } from '@services'
import { WatchlistState } from '@interfaces'

export const clearWatchlistState = createAction('clearWatchlistState')
export const removeItemFromWatchlist = createAction<string, string>(
  'removeItemFromWatchlist',
)

export const fetchWatchlistStocks = createAsyncThunk<WatchlistState, number>(
  'fetchWatchlistStocks',
  async (page: number) => {
    return getStocksWatchlist(page)
  },
)

export const reloadWatchlistAfterDelete = createAsyncThunk<
  WatchlistState,
  number
>('reloadWatchlistAfterDelete', async (page: number) => {
  return getStocksWatchlist(page)
})

export const unsetWatchlistStockState = createAsyncThunk<void, string>(
  'unsetWatchlistStockState',
  async (uuid: string) => {
    return await unsetStockWatchlistState(uuid)
  },
)
