import { UIIcon, UIIconColorProps, getIconColorSVGFill } from 'components/utils'

export const DateRangeIcon = ({
  color,
  size = 24,
}: UIIcon & UIIconColorProps): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 23 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.91667 10.0833H8.75V11.9167H6.91667V10.0833ZM19.75 5.49999V18.3333C19.75 19.3417 18.925 20.1667 17.9167 20.1667H5.08333C4.06583 20.1667 3.25 19.3417 3.25 18.3333L3.25917 5.49999C3.25917 4.49166 4.06583 3.66666 5.08333 3.66666H6V1.83333H7.83333V3.66666H15.1667V1.83333H17V3.66666H17.9167C18.925 3.66666 19.75 4.49166 19.75 5.49999ZM5.08333 7.33333H17.9167V5.49999H5.08333V7.33333ZM17.9167 18.3333V9.16666H5.08333V18.3333H17.9167ZM14.25 11.9167H16.0833V10.0833H14.25V11.9167ZM10.5833 11.9167H12.4167V10.0833H10.5833V11.9167Z'
        fill={color ? getIconColorSVGFill(color) : 'currentColor'}
      />
    </svg>
  )
}
