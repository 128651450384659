export const ResistentToVolatility = (): JSX.Element => {
  return (
    <svg
      width='249'
      height='248'
      viewBox='0 0 249 248'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_610_14333)'>
        <circle
          cx='124.5'
          cy='124'
          r='124'
          fill='url(#paint0_linear_610_14333)'
        />
        <mask
          id='mask0_610_14333'
          style={{ maskType: 'alpha' }}
          maskUnits='userSpaceOnUse'
          x='1'
          y='0'
          width='248'
          height='248'
        >
          <circle
            cx='125'
            cy='124'
            r='124'
            fill='var(--illustrationsSharedPrimary500)'
          />
        </mask>
        <g mask='url(#mask0_610_14333)'>
          <g opacity='var(--illustrationsTransparency)'>
            <circle
              cx='5'
              cy='1'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='11'
              cy='1'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='17'
              cy='1'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='23'
              cy='1'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M30 1C30 1.55228 29.5523 2 29 2C28.4477 2 28 1.55228 28 1C28 0.447715 28.4477 0 29 0C29.5523 0 30 0.447715 30 1Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M36 1C36 1.55228 35.5523 2 35 2C34.4477 2 34 1.55228 34 1C34 0.447715 34.4477 0 35 0C35.5523 0 36 0.447715 36 1Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M42 1C42 1.55228 41.5523 2 41 2C40.4477 2 40 1.55228 40 1C40 0.447715 40.4477 0 41 0C41.5523 0 42 0.447715 42 1Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M48 1C48 1.55228 47.5523 2 47 2C46.4477 2 46 1.55228 46 1C46 0.447715 46.4477 0 47 0C47.5523 0 48 0.447715 48 1Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M54 1C54 1.55228 53.5523 2 53 2C52.4477 2 52 1.55228 52 1C52 0.447715 52.4477 0 53 0C53.5523 0 54 0.447715 54 1Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M60 1C60 1.55228 59.5523 2 59 2C58.4477 2 58 1.55228 58 1C58 0.447715 58.4477 0 59 0C59.5523 0 60 0.447715 60 1Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M66 1C66 1.55228 65.5523 2 65 2C64.4477 2 64 1.55228 64 1C64 0.447715 64.4477 0 65 0C65.5523 0 66 0.447715 66 1Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M72 1C72 1.55228 71.5523 2 71 2C70.4477 2 70 1.55228 70 1C70 0.447715 70.4477 0 71 0C71.5523 0 72 0.447715 72 1Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M78 1C78 1.55228 77.5523 2 77 2C76.4477 2 76 1.55228 76 1C76 0.447715 76.4477 0 77 0C77.5523 0 78 0.447715 78 1Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M84 1C84 1.55228 83.5523 2 83 2C82.4477 2 82 1.55228 82 1C82 0.447715 82.4477 0 83 0C83.5523 0 84 0.447715 84 1Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M90 1C90 1.55228 89.5523 2 89 2C88.4477 2 88 1.55228 88 1C88 0.447715 88.4477 0 89 0C89.5523 0 90 0.447715 90 1Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M96 1C96 1.55228 95.5523 2 95 2C94.4477 2 94 1.55228 94 1C94 0.447715 94.4477 0 95 0C95.5523 0 96 0.447715 96 1Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M102 1C102 1.55228 101.552 2 101 2C100.448 2 100 1.55228 100 1C100 0.447715 100.448 0 101 0C101.552 0 102 0.447715 102 1Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M108 1C108 1.55228 107.552 2 107 2C106.448 2 106 1.55228 106 1C106 0.447715 106.448 0 107 0C107.552 0 108 0.447715 108 1Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M114 1C114 1.55228 113.552 2 113 2C112.448 2 112 1.55228 112 1C112 0.447715 112.448 0 113 0C113.552 0 114 0.447715 114 1Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M120 1C120 1.55228 119.552 2 119 2C118.448 2 118 1.55228 118 1C118 0.447715 118.448 0 119 0C119.552 0 120 0.447715 120 1Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M126 1C126 1.55228 125.552 2 125 2C124.448 2 124 1.55228 124 1C124 0.447715 124.448 0 125 0C125.552 0 126 0.447715 126 1Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M132 1C132 1.55228 131.552 2 131 2C130.448 2 130 1.55228 130 1C130 0.447715 130.448 0 131 0C131.552 0 132 0.447715 132 1Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M138 1C138 1.55228 137.552 2 137 2C136.448 2 136 1.55228 136 1C136 0.447715 136.448 0 137 0C137.552 0 138 0.447715 138 1Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M144 1C144 1.55228 143.552 2 143 2C142.448 2 142 1.55228 142 1C142 0.447715 142.448 0 143 0C143.552 0 144 0.447715 144 1Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='149'
              cy='1'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='155'
              cy='1'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='161'
              cy='1'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='167'
              cy='1'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='173'
              cy='1'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='179'
              cy='1'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='185'
              cy='1'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='191'
              cy='1'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='197'
              cy='1'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='203'
              cy='1'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='209'
              cy='1'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='215'
              cy='1'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='221'
              cy='1'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='227'
              cy='1'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='233'
              cy='1'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='239'
              cy='1'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='245'
              cy='1'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='5'
              cy='146.8'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='11'
              cy='146.8'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='17'
              cy='146.8'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='23'
              cy='146.8'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M30 146.8C30 147.352 29.5523 147.8 29 147.8C28.4477 147.8 28 147.352 28 146.8C28 146.248 28.4477 145.8 29 145.8C29.5523 145.8 30 146.248 30 146.8Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M36 146.8C36 147.352 35.5523 147.8 35 147.8C34.4477 147.8 34 147.352 34 146.8C34 146.248 34.4477 145.8 35 145.8C35.5523 145.8 36 146.248 36 146.8Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M42 146.8C42 147.352 41.5523 147.8 41 147.8C40.4477 147.8 40 147.352 40 146.8C40 146.248 40.4477 145.8 41 145.8C41.5523 145.8 42 146.248 42 146.8Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M48 146.8C48 147.352 47.5523 147.8 47 147.8C46.4477 147.8 46 147.352 46 146.8C46 146.248 46.4477 145.8 47 145.8C47.5523 145.8 48 146.248 48 146.8Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M54 146.8C54 147.352 53.5523 147.8 53 147.8C52.4477 147.8 52 147.352 52 146.8C52 146.248 52.4477 145.8 53 145.8C53.5523 145.8 54 146.248 54 146.8Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M60 146.8C60 147.352 59.5523 147.8 59 147.8C58.4477 147.8 58 147.352 58 146.8C58 146.248 58.4477 145.8 59 145.8C59.5523 145.8 60 146.248 60 146.8Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M66 146.8C66 147.352 65.5523 147.8 65 147.8C64.4477 147.8 64 147.352 64 146.8C64 146.248 64.4477 145.8 65 145.8C65.5523 145.8 66 146.248 66 146.8Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M72 146.8C72 147.352 71.5523 147.8 71 147.8C70.4477 147.8 70 147.352 70 146.8C70 146.248 70.4477 145.8 71 145.8C71.5523 145.8 72 146.248 72 146.8Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M78 146.8C78 147.352 77.5523 147.8 77 147.8C76.4477 147.8 76 147.352 76 146.8C76 146.248 76.4477 145.8 77 145.8C77.5523 145.8 78 146.248 78 146.8Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M84 146.8C84 147.352 83.5523 147.8 83 147.8C82.4477 147.8 82 147.352 82 146.8C82 146.248 82.4477 145.8 83 145.8C83.5523 145.8 84 146.248 84 146.8Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M90 146.8C90 147.352 89.5523 147.8 89 147.8C88.4477 147.8 88 147.352 88 146.8C88 146.248 88.4477 145.8 89 145.8C89.5523 145.8 90 146.248 90 146.8Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M96 146.8C96 147.352 95.5523 147.8 95 147.8C94.4477 147.8 94 147.352 94 146.8C94 146.248 94.4477 145.8 95 145.8C95.5523 145.8 96 146.248 96 146.8Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M102 146.8C102 147.352 101.552 147.8 101 147.8C100.448 147.8 100 147.352 100 146.8C100 146.248 100.448 145.8 101 145.8C101.552 145.8 102 146.248 102 146.8Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M108 146.8C108 147.352 107.552 147.8 107 147.8C106.448 147.8 106 147.352 106 146.8C106 146.248 106.448 145.8 107 145.8C107.552 145.8 108 146.248 108 146.8Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M114 146.8C114 147.352 113.552 147.8 113 147.8C112.448 147.8 112 147.352 112 146.8C112 146.248 112.448 145.8 113 145.8C113.552 145.8 114 146.248 114 146.8Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M120 146.8C120 147.352 119.552 147.8 119 147.8C118.448 147.8 118 147.352 118 146.8C118 146.248 118.448 145.8 119 145.8C119.552 145.8 120 146.248 120 146.8Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M126 146.8C126 147.352 125.552 147.8 125 147.8C124.448 147.8 124 147.352 124 146.8C124 146.248 124.448 145.8 125 145.8C125.552 145.8 126 146.248 126 146.8Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M132 146.8C132 147.352 131.552 147.8 131 147.8C130.448 147.8 130 147.352 130 146.8C130 146.248 130.448 145.8 131 145.8C131.552 145.8 132 146.248 132 146.8Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M138 146.8C138 147.352 137.552 147.8 137 147.8C136.448 147.8 136 147.352 136 146.8C136 146.248 136.448 145.8 137 145.8C137.552 145.8 138 146.248 138 146.8Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M144 146.8C144 147.352 143.552 147.8 143 147.8C142.448 147.8 142 147.352 142 146.8C142 146.248 142.448 145.8 143 145.8C143.552 145.8 144 146.248 144 146.8Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='149'
              cy='146.8'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='155'
              cy='146.8'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='161'
              cy='146.8'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='167'
              cy='146.8'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='173'
              cy='146.8'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='179'
              cy='146.8'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='185'
              cy='146.8'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='191'
              cy='146.8'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='197'
              cy='146.8'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='203'
              cy='146.8'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='209'
              cy='146.8'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='215'
              cy='146.8'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='221'
              cy='146.8'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='227'
              cy='146.8'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='233'
              cy='146.8'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='239'
              cy='146.8'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='245'
              cy='146.8'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='5'
              cy='30.1602'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='11'
              cy='30.1602'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='17'
              cy='30.1602'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='23'
              cy='30.1602'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M30 30.1602C30 30.7124 29.5523 31.1602 29 31.1602C28.4477 31.1602 28 30.7124 28 30.1602C28 29.6079 28.4477 29.1602 29 29.1602C29.5523 29.1602 30 29.6079 30 30.1602Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M36 30.1602C36 30.7124 35.5523 31.1602 35 31.1602C34.4477 31.1602 34 30.7124 34 30.1602C34 29.6079 34.4477 29.1602 35 29.1602C35.5523 29.1602 36 29.6079 36 30.1602Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M42 30.1602C42 30.7124 41.5523 31.1602 41 31.1602C40.4477 31.1602 40 30.7124 40 30.1602C40 29.6079 40.4477 29.1602 41 29.1602C41.5523 29.1602 42 29.6079 42 30.1602Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M48 30.1602C48 30.7124 47.5523 31.1602 47 31.1602C46.4477 31.1602 46 30.7124 46 30.1602C46 29.6079 46.4477 29.1602 47 29.1602C47.5523 29.1602 48 29.6079 48 30.1602Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M54 30.1602C54 30.7124 53.5523 31.1602 53 31.1602C52.4477 31.1602 52 30.7124 52 30.1602C52 29.6079 52.4477 29.1602 53 29.1602C53.5523 29.1602 54 29.6079 54 30.1602Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M60 30.1602C60 30.7124 59.5523 31.1602 59 31.1602C58.4477 31.1602 58 30.7124 58 30.1602C58 29.6079 58.4477 29.1602 59 29.1602C59.5523 29.1602 60 29.6079 60 30.1602Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M66 30.1602C66 30.7124 65.5523 31.1602 65 31.1602C64.4477 31.1602 64 30.7124 64 30.1602C64 29.6079 64.4477 29.1602 65 29.1602C65.5523 29.1602 66 29.6079 66 30.1602Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M72 30.1602C72 30.7124 71.5523 31.1602 71 31.1602C70.4477 31.1602 70 30.7124 70 30.1602C70 29.6079 70.4477 29.1602 71 29.1602C71.5523 29.1602 72 29.6079 72 30.1602Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M78 30.1602C78 30.7124 77.5523 31.1602 77 31.1602C76.4477 31.1602 76 30.7124 76 30.1602C76 29.6079 76.4477 29.1602 77 29.1602C77.5523 29.1602 78 29.6079 78 30.1602Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M84 30.1602C84 30.7124 83.5523 31.1602 83 31.1602C82.4477 31.1602 82 30.7124 82 30.1602C82 29.6079 82.4477 29.1602 83 29.1602C83.5523 29.1602 84 29.6079 84 30.1602Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M90 30.1602C90 30.7124 89.5523 31.1602 89 31.1602C88.4477 31.1602 88 30.7124 88 30.1602C88 29.6079 88.4477 29.1602 89 29.1602C89.5523 29.1602 90 29.6079 90 30.1602Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M96 30.1602C96 30.7124 95.5523 31.1602 95 31.1602C94.4477 31.1602 94 30.7124 94 30.1602C94 29.6079 94.4477 29.1602 95 29.1602C95.5523 29.1602 96 29.6079 96 30.1602Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M102 30.1602C102 30.7124 101.552 31.1602 101 31.1602C100.448 31.1602 100 30.7124 100 30.1602C100 29.6079 100.448 29.1602 101 29.1602C101.552 29.1602 102 29.6079 102 30.1602Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M108 30.1602C108 30.7124 107.552 31.1602 107 31.1602C106.448 31.1602 106 30.7124 106 30.1602C106 29.6079 106.448 29.1602 107 29.1602C107.552 29.1602 108 29.6079 108 30.1602Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M114 30.1602C114 30.7124 113.552 31.1602 113 31.1602C112.448 31.1602 112 30.7124 112 30.1602C112 29.6079 112.448 29.1602 113 29.1602C113.552 29.1602 114 29.6079 114 30.1602Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M120 30.1602C120 30.7124 119.552 31.1602 119 31.1602C118.448 31.1602 118 30.7124 118 30.1602C118 29.6079 118.448 29.1602 119 29.1602C119.552 29.1602 120 29.6079 120 30.1602Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M126 30.1602C126 30.7124 125.552 31.1602 125 31.1602C124.448 31.1602 124 30.7124 124 30.1602C124 29.6079 124.448 29.1602 125 29.1602C125.552 29.1602 126 29.6079 126 30.1602Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M132 30.1602C132 30.7124 131.552 31.1602 131 31.1602C130.448 31.1602 130 30.7124 130 30.1602C130 29.6079 130.448 29.1602 131 29.1602C131.552 29.1602 132 29.6079 132 30.1602Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M138 30.1602C138 30.7124 137.552 31.1602 137 31.1602C136.448 31.1602 136 30.7124 136 30.1602C136 29.6079 136.448 29.1602 137 29.1602C137.552 29.1602 138 29.6079 138 30.1602Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M144 30.1602C144 30.7124 143.552 31.1602 143 31.1602C142.448 31.1602 142 30.7124 142 30.1602C142 29.6079 142.448 29.1602 143 29.1602C143.552 29.1602 144 29.6079 144 30.1602Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='149'
              cy='30.1602'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='155'
              cy='30.1602'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='161'
              cy='30.1602'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='167'
              cy='30.1602'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='173'
              cy='30.1602'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='179'
              cy='30.1602'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='185'
              cy='30.1602'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='191'
              cy='30.1602'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='197'
              cy='30.1602'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='203'
              cy='30.1602'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='209'
              cy='30.1602'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='215'
              cy='30.1602'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='221'
              cy='30.1602'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='227'
              cy='30.1602'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='233'
              cy='30.1602'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='239'
              cy='30.1602'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='245'
              cy='30.1602'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='5'
              cy='175.96'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='11'
              cy='175.96'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='17'
              cy='175.96'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='23'
              cy='175.96'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M30 175.96C30 176.512 29.5523 176.96 29 176.96C28.4477 176.96 28 176.512 28 175.96C28 175.408 28.4477 174.96 29 174.96C29.5523 174.96 30 175.408 30 175.96Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M36 175.96C36 176.512 35.5523 176.96 35 176.96C34.4477 176.96 34 176.512 34 175.96C34 175.408 34.4477 174.96 35 174.96C35.5523 174.96 36 175.408 36 175.96Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M42 175.96C42 176.512 41.5523 176.96 41 176.96C40.4477 176.96 40 176.512 40 175.96C40 175.408 40.4477 174.96 41 174.96C41.5523 174.96 42 175.408 42 175.96Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M48 175.96C48 176.512 47.5523 176.96 47 176.96C46.4477 176.96 46 176.512 46 175.96C46 175.408 46.4477 174.96 47 174.96C47.5523 174.96 48 175.408 48 175.96Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M54 175.96C54 176.512 53.5523 176.96 53 176.96C52.4477 176.96 52 176.512 52 175.96C52 175.408 52.4477 174.96 53 174.96C53.5523 174.96 54 175.408 54 175.96Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M60 175.96C60 176.512 59.5523 176.96 59 176.96C58.4477 176.96 58 176.512 58 175.96C58 175.408 58.4477 174.96 59 174.96C59.5523 174.96 60 175.408 60 175.96Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M66 175.96C66 176.512 65.5523 176.96 65 176.96C64.4477 176.96 64 176.512 64 175.96C64 175.408 64.4477 174.96 65 174.96C65.5523 174.96 66 175.408 66 175.96Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M72 175.96C72 176.512 71.5523 176.96 71 176.96C70.4477 176.96 70 176.512 70 175.96C70 175.408 70.4477 174.96 71 174.96C71.5523 174.96 72 175.408 72 175.96Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M78 175.96C78 176.512 77.5523 176.96 77 176.96C76.4477 176.96 76 176.512 76 175.96C76 175.408 76.4477 174.96 77 174.96C77.5523 174.96 78 175.408 78 175.96Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M84 175.96C84 176.512 83.5523 176.96 83 176.96C82.4477 176.96 82 176.512 82 175.96C82 175.408 82.4477 174.96 83 174.96C83.5523 174.96 84 175.408 84 175.96Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M90 175.96C90 176.512 89.5523 176.96 89 176.96C88.4477 176.96 88 176.512 88 175.96C88 175.408 88.4477 174.96 89 174.96C89.5523 174.96 90 175.408 90 175.96Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M96 175.96C96 176.512 95.5523 176.96 95 176.96C94.4477 176.96 94 176.512 94 175.96C94 175.408 94.4477 174.96 95 174.96C95.5523 174.96 96 175.408 96 175.96Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M102 175.96C102 176.512 101.552 176.96 101 176.96C100.448 176.96 100 176.512 100 175.96C100 175.408 100.448 174.96 101 174.96C101.552 174.96 102 175.408 102 175.96Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M108 175.96C108 176.512 107.552 176.96 107 176.96C106.448 176.96 106 176.512 106 175.96C106 175.408 106.448 174.96 107 174.96C107.552 174.96 108 175.408 108 175.96Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M114 175.96C114 176.512 113.552 176.96 113 176.96C112.448 176.96 112 176.512 112 175.96C112 175.408 112.448 174.96 113 174.96C113.552 174.96 114 175.408 114 175.96Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M120 175.96C120 176.512 119.552 176.96 119 176.96C118.448 176.96 118 176.512 118 175.96C118 175.408 118.448 174.96 119 174.96C119.552 174.96 120 175.408 120 175.96Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M126 175.96C126 176.512 125.552 176.96 125 176.96C124.448 176.96 124 176.512 124 175.96C124 175.408 124.448 174.96 125 174.96C125.552 174.96 126 175.408 126 175.96Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M132 175.96C132 176.512 131.552 176.96 131 176.96C130.448 176.96 130 176.512 130 175.96C130 175.408 130.448 174.96 131 174.96C131.552 174.96 132 175.408 132 175.96Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M138 175.96C138 176.512 137.552 176.96 137 176.96C136.448 176.96 136 176.512 136 175.96C136 175.408 136.448 174.96 137 174.96C137.552 174.96 138 175.408 138 175.96Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M144 175.96C144 176.512 143.552 176.96 143 176.96C142.448 176.96 142 176.512 142 175.96C142 175.408 142.448 174.96 143 174.96C143.552 174.96 144 175.408 144 175.96Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='149'
              cy='175.96'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='155'
              cy='175.96'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='161'
              cy='175.96'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='167'
              cy='175.96'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='173'
              cy='175.96'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='179'
              cy='175.96'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='185'
              cy='175.96'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='191'
              cy='175.96'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='197'
              cy='175.96'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='203'
              cy='175.96'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='209'
              cy='175.96'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='215'
              cy='175.96'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='221'
              cy='175.96'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='227'
              cy='175.96'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='233'
              cy='175.96'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='239'
              cy='175.96'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='245'
              cy='175.96'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='5'
              cy='59.3203'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='11'
              cy='59.3203'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='17'
              cy='59.3203'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='23'
              cy='59.3203'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M30 59.3203C30 59.8726 29.5523 60.3203 29 60.3203C28.4477 60.3203 28 59.8726 28 59.3203C28 58.768 28.4477 58.3203 29 58.3203C29.5523 58.3203 30 58.768 30 59.3203Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M36 59.3203C36 59.8726 35.5523 60.3203 35 60.3203C34.4477 60.3203 34 59.8726 34 59.3203C34 58.768 34.4477 58.3203 35 58.3203C35.5523 58.3203 36 58.768 36 59.3203Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M42 59.3203C42 59.8726 41.5523 60.3203 41 60.3203C40.4477 60.3203 40 59.8726 40 59.3203C40 58.768 40.4477 58.3203 41 58.3203C41.5523 58.3203 42 58.768 42 59.3203Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M48 59.3203C48 59.8726 47.5523 60.3203 47 60.3203C46.4477 60.3203 46 59.8726 46 59.3203C46 58.768 46.4477 58.3203 47 58.3203C47.5523 58.3203 48 58.768 48 59.3203Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M54 59.3203C54 59.8726 53.5523 60.3203 53 60.3203C52.4477 60.3203 52 59.8726 52 59.3203C52 58.768 52.4477 58.3203 53 58.3203C53.5523 58.3203 54 58.768 54 59.3203Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M60 59.3203C60 59.8726 59.5523 60.3203 59 60.3203C58.4477 60.3203 58 59.8726 58 59.3203C58 58.768 58.4477 58.3203 59 58.3203C59.5523 58.3203 60 58.768 60 59.3203Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M66 59.3203C66 59.8726 65.5523 60.3203 65 60.3203C64.4477 60.3203 64 59.8726 64 59.3203C64 58.768 64.4477 58.3203 65 58.3203C65.5523 58.3203 66 58.768 66 59.3203Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M72 59.3203C72 59.8726 71.5523 60.3203 71 60.3203C70.4477 60.3203 70 59.8726 70 59.3203C70 58.768 70.4477 58.3203 71 58.3203C71.5523 58.3203 72 58.768 72 59.3203Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M78 59.3203C78 59.8726 77.5523 60.3203 77 60.3203C76.4477 60.3203 76 59.8726 76 59.3203C76 58.768 76.4477 58.3203 77 58.3203C77.5523 58.3203 78 58.768 78 59.3203Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M84 59.3203C84 59.8726 83.5523 60.3203 83 60.3203C82.4477 60.3203 82 59.8726 82 59.3203C82 58.768 82.4477 58.3203 83 58.3203C83.5523 58.3203 84 58.768 84 59.3203Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M90 59.3203C90 59.8726 89.5523 60.3203 89 60.3203C88.4477 60.3203 88 59.8726 88 59.3203C88 58.768 88.4477 58.3203 89 58.3203C89.5523 58.3203 90 58.768 90 59.3203Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M96 59.3203C96 59.8726 95.5523 60.3203 95 60.3203C94.4477 60.3203 94 59.8726 94 59.3203C94 58.768 94.4477 58.3203 95 58.3203C95.5523 58.3203 96 58.768 96 59.3203Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M102 59.3203C102 59.8726 101.552 60.3203 101 60.3203C100.448 60.3203 100 59.8726 100 59.3203C100 58.768 100.448 58.3203 101 58.3203C101.552 58.3203 102 58.768 102 59.3203Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M108 59.3203C108 59.8726 107.552 60.3203 107 60.3203C106.448 60.3203 106 59.8726 106 59.3203C106 58.768 106.448 58.3203 107 58.3203C107.552 58.3203 108 58.768 108 59.3203Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M114 59.3203C114 59.8726 113.552 60.3203 113 60.3203C112.448 60.3203 112 59.8726 112 59.3203C112 58.768 112.448 58.3203 113 58.3203C113.552 58.3203 114 58.768 114 59.3203Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M120 59.3203C120 59.8726 119.552 60.3203 119 60.3203C118.448 60.3203 118 59.8726 118 59.3203C118 58.768 118.448 58.3203 119 58.3203C119.552 58.3203 120 58.768 120 59.3203Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M126 59.3203C126 59.8726 125.552 60.3203 125 60.3203C124.448 60.3203 124 59.8726 124 59.3203C124 58.768 124.448 58.3203 125 58.3203C125.552 58.3203 126 58.768 126 59.3203Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M132 59.3203C132 59.8726 131.552 60.3203 131 60.3203C130.448 60.3203 130 59.8726 130 59.3203C130 58.768 130.448 58.3203 131 58.3203C131.552 58.3203 132 58.768 132 59.3203Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M138 59.3203C138 59.8726 137.552 60.3203 137 60.3203C136.448 60.3203 136 59.8726 136 59.3203C136 58.768 136.448 58.3203 137 58.3203C137.552 58.3203 138 58.768 138 59.3203Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M144 59.3203C144 59.8726 143.552 60.3203 143 60.3203C142.448 60.3203 142 59.8726 142 59.3203C142 58.768 142.448 58.3203 143 58.3203C143.552 58.3203 144 58.768 144 59.3203Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='149'
              cy='59.3203'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='155'
              cy='59.3203'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='161'
              cy='59.3203'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='167'
              cy='59.3203'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='173'
              cy='59.3203'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='179'
              cy='59.3203'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='185'
              cy='59.3203'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='191'
              cy='59.3203'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='197'
              cy='59.3203'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='203'
              cy='59.3203'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='209'
              cy='59.3203'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='215'
              cy='59.3203'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='221'
              cy='59.3203'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='227'
              cy='59.3203'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='233'
              cy='59.3203'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='239'
              cy='59.3203'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='245'
              cy='59.3203'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='5'
              cy='205.12'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='11'
              cy='205.12'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='17'
              cy='205.12'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='23'
              cy='205.12'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M30 205.12C30 205.672 29.5523 206.12 29 206.12C28.4477 206.12 28 205.672 28 205.12C28 204.568 28.4477 204.12 29 204.12C29.5523 204.12 30 204.568 30 205.12Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M36 205.12C36 205.672 35.5523 206.12 35 206.12C34.4477 206.12 34 205.672 34 205.12C34 204.568 34.4477 204.12 35 204.12C35.5523 204.12 36 204.568 36 205.12Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M42 205.12C42 205.672 41.5523 206.12 41 206.12C40.4477 206.12 40 205.672 40 205.12C40 204.568 40.4477 204.12 41 204.12C41.5523 204.12 42 204.568 42 205.12Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M48 205.12C48 205.672 47.5523 206.12 47 206.12C46.4477 206.12 46 205.672 46 205.12C46 204.568 46.4477 204.12 47 204.12C47.5523 204.12 48 204.568 48 205.12Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M54 205.12C54 205.672 53.5523 206.12 53 206.12C52.4477 206.12 52 205.672 52 205.12C52 204.568 52.4477 204.12 53 204.12C53.5523 204.12 54 204.568 54 205.12Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M60 205.12C60 205.672 59.5523 206.12 59 206.12C58.4477 206.12 58 205.672 58 205.12C58 204.568 58.4477 204.12 59 204.12C59.5523 204.12 60 204.568 60 205.12Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M66 205.12C66 205.672 65.5523 206.12 65 206.12C64.4477 206.12 64 205.672 64 205.12C64 204.568 64.4477 204.12 65 204.12C65.5523 204.12 66 204.568 66 205.12Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M72 205.12C72 205.672 71.5523 206.12 71 206.12C70.4477 206.12 70 205.672 70 205.12C70 204.568 70.4477 204.12 71 204.12C71.5523 204.12 72 204.568 72 205.12Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M78 205.12C78 205.672 77.5523 206.12 77 206.12C76.4477 206.12 76 205.672 76 205.12C76 204.568 76.4477 204.12 77 204.12C77.5523 204.12 78 204.568 78 205.12Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M84 205.12C84 205.672 83.5523 206.12 83 206.12C82.4477 206.12 82 205.672 82 205.12C82 204.568 82.4477 204.12 83 204.12C83.5523 204.12 84 204.568 84 205.12Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M90 205.12C90 205.672 89.5523 206.12 89 206.12C88.4477 206.12 88 205.672 88 205.12C88 204.568 88.4477 204.12 89 204.12C89.5523 204.12 90 204.568 90 205.12Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M96 205.12C96 205.672 95.5523 206.12 95 206.12C94.4477 206.12 94 205.672 94 205.12C94 204.568 94.4477 204.12 95 204.12C95.5523 204.12 96 204.568 96 205.12Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M102 205.12C102 205.672 101.552 206.12 101 206.12C100.448 206.12 100 205.672 100 205.12C100 204.568 100.448 204.12 101 204.12C101.552 204.12 102 204.568 102 205.12Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M108 205.12C108 205.672 107.552 206.12 107 206.12C106.448 206.12 106 205.672 106 205.12C106 204.568 106.448 204.12 107 204.12C107.552 204.12 108 204.568 108 205.12Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M114 205.12C114 205.672 113.552 206.12 113 206.12C112.448 206.12 112 205.672 112 205.12C112 204.568 112.448 204.12 113 204.12C113.552 204.12 114 204.568 114 205.12Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M120 205.12C120 205.672 119.552 206.12 119 206.12C118.448 206.12 118 205.672 118 205.12C118 204.568 118.448 204.12 119 204.12C119.552 204.12 120 204.568 120 205.12Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M126 205.12C126 205.672 125.552 206.12 125 206.12C124.448 206.12 124 205.672 124 205.12C124 204.568 124.448 204.12 125 204.12C125.552 204.12 126 204.568 126 205.12Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M132 205.12C132 205.672 131.552 206.12 131 206.12C130.448 206.12 130 205.672 130 205.12C130 204.568 130.448 204.12 131 204.12C131.552 204.12 132 204.568 132 205.12Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M138 205.12C138 205.672 137.552 206.12 137 206.12C136.448 206.12 136 205.672 136 205.12C136 204.568 136.448 204.12 137 204.12C137.552 204.12 138 204.568 138 205.12Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M144 205.12C144 205.672 143.552 206.12 143 206.12C142.448 206.12 142 205.672 142 205.12C142 204.568 142.448 204.12 143 204.12C143.552 204.12 144 204.568 144 205.12Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='149'
              cy='205.12'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='155'
              cy='205.12'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='161'
              cy='205.12'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='167'
              cy='205.12'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='173'
              cy='205.12'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='179'
              cy='205.12'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='185'
              cy='205.12'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='191'
              cy='205.12'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='197'
              cy='205.12'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='203'
              cy='205.12'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='209'
              cy='205.12'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='215'
              cy='205.12'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='221'
              cy='205.12'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='227'
              cy='205.12'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='233'
              cy='205.12'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='239'
              cy='205.12'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='245'
              cy='205.12'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='5'
              cy='88.4805'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='11'
              cy='88.4805'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='17'
              cy='88.4805'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='23'
              cy='88.4805'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M30 88.4805C30 89.0328 29.5523 89.4805 29 89.4805C28.4477 89.4805 28 89.0328 28 88.4805C28 87.9282 28.4477 87.4805 29 87.4805C29.5523 87.4805 30 87.9282 30 88.4805Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M36 88.4805C36 89.0328 35.5523 89.4805 35 89.4805C34.4477 89.4805 34 89.0328 34 88.4805C34 87.9282 34.4477 87.4805 35 87.4805C35.5523 87.4805 36 87.9282 36 88.4805Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M42 88.4805C42 89.0328 41.5523 89.4805 41 89.4805C40.4477 89.4805 40 89.0328 40 88.4805C40 87.9282 40.4477 87.4805 41 87.4805C41.5523 87.4805 42 87.9282 42 88.4805Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M48 88.4805C48 89.0328 47.5523 89.4805 47 89.4805C46.4477 89.4805 46 89.0328 46 88.4805C46 87.9282 46.4477 87.4805 47 87.4805C47.5523 87.4805 48 87.9282 48 88.4805Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M54 88.4805C54 89.0328 53.5523 89.4805 53 89.4805C52.4477 89.4805 52 89.0328 52 88.4805C52 87.9282 52.4477 87.4805 53 87.4805C53.5523 87.4805 54 87.9282 54 88.4805Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M60 88.4805C60 89.0328 59.5523 89.4805 59 89.4805C58.4477 89.4805 58 89.0328 58 88.4805C58 87.9282 58.4477 87.4805 59 87.4805C59.5523 87.4805 60 87.9282 60 88.4805Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M66 88.4805C66 89.0328 65.5523 89.4805 65 89.4805C64.4477 89.4805 64 89.0328 64 88.4805C64 87.9282 64.4477 87.4805 65 87.4805C65.5523 87.4805 66 87.9282 66 88.4805Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M72 88.4805C72 89.0328 71.5523 89.4805 71 89.4805C70.4477 89.4805 70 89.0328 70 88.4805C70 87.9282 70.4477 87.4805 71 87.4805C71.5523 87.4805 72 87.9282 72 88.4805Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M78 88.4805C78 89.0328 77.5523 89.4805 77 89.4805C76.4477 89.4805 76 89.0328 76 88.4805C76 87.9282 76.4477 87.4805 77 87.4805C77.5523 87.4805 78 87.9282 78 88.4805Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M84 88.4805C84 89.0328 83.5523 89.4805 83 89.4805C82.4477 89.4805 82 89.0328 82 88.4805C82 87.9282 82.4477 87.4805 83 87.4805C83.5523 87.4805 84 87.9282 84 88.4805Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M90 88.4805C90 89.0328 89.5523 89.4805 89 89.4805C88.4477 89.4805 88 89.0328 88 88.4805C88 87.9282 88.4477 87.4805 89 87.4805C89.5523 87.4805 90 87.9282 90 88.4805Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M96 88.4805C96 89.0328 95.5523 89.4805 95 89.4805C94.4477 89.4805 94 89.0328 94 88.4805C94 87.9282 94.4477 87.4805 95 87.4805C95.5523 87.4805 96 87.9282 96 88.4805Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M102 88.4805C102 89.0328 101.552 89.4805 101 89.4805C100.448 89.4805 100 89.0328 100 88.4805C100 87.9282 100.448 87.4805 101 87.4805C101.552 87.4805 102 87.9282 102 88.4805Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M108 88.4805C108 89.0328 107.552 89.4805 107 89.4805C106.448 89.4805 106 89.0328 106 88.4805C106 87.9282 106.448 87.4805 107 87.4805C107.552 87.4805 108 87.9282 108 88.4805Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M114 88.4805C114 89.0328 113.552 89.4805 113 89.4805C112.448 89.4805 112 89.0328 112 88.4805C112 87.9282 112.448 87.4805 113 87.4805C113.552 87.4805 114 87.9282 114 88.4805Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M120 88.4805C120 89.0328 119.552 89.4805 119 89.4805C118.448 89.4805 118 89.0328 118 88.4805C118 87.9282 118.448 87.4805 119 87.4805C119.552 87.4805 120 87.9282 120 88.4805Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M126 88.4805C126 89.0328 125.552 89.4805 125 89.4805C124.448 89.4805 124 89.0328 124 88.4805C124 87.9282 124.448 87.4805 125 87.4805C125.552 87.4805 126 87.9282 126 88.4805Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M132 88.4805C132 89.0328 131.552 89.4805 131 89.4805C130.448 89.4805 130 89.0328 130 88.4805C130 87.9282 130.448 87.4805 131 87.4805C131.552 87.4805 132 87.9282 132 88.4805Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M138 88.4805C138 89.0328 137.552 89.4805 137 89.4805C136.448 89.4805 136 89.0328 136 88.4805C136 87.9282 136.448 87.4805 137 87.4805C137.552 87.4805 138 87.9282 138 88.4805Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M144 88.4805C144 89.0328 143.552 89.4805 143 89.4805C142.448 89.4805 142 89.0328 142 88.4805C142 87.9282 142.448 87.4805 143 87.4805C143.552 87.4805 144 87.9282 144 88.4805Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='149'
              cy='88.4805'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='155'
              cy='88.4805'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='161'
              cy='88.4805'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='167'
              cy='88.4805'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='173'
              cy='88.4805'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='179'
              cy='88.4805'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='185'
              cy='88.4805'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='191'
              cy='88.4805'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='197'
              cy='88.4805'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='203'
              cy='88.4805'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='209'
              cy='88.4805'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='215'
              cy='88.4805'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='221'
              cy='88.4805'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='227'
              cy='88.4805'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='233'
              cy='88.4805'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='239'
              cy='88.4805'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='245'
              cy='88.4805'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='5'
              cy='234.28'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='11'
              cy='234.28'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='17'
              cy='234.28'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='23'
              cy='234.28'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M30 234.28C30 234.833 29.5523 235.28 29 235.28C28.4477 235.28 28 234.833 28 234.28C28 233.728 28.4477 233.28 29 233.28C29.5523 233.28 30 233.728 30 234.28Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M36 234.28C36 234.833 35.5523 235.28 35 235.28C34.4477 235.28 34 234.833 34 234.28C34 233.728 34.4477 233.28 35 233.28C35.5523 233.28 36 233.728 36 234.28Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M42 234.28C42 234.833 41.5523 235.28 41 235.28C40.4477 235.28 40 234.833 40 234.28C40 233.728 40.4477 233.28 41 233.28C41.5523 233.28 42 233.728 42 234.28Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M48 234.28C48 234.833 47.5523 235.28 47 235.28C46.4477 235.28 46 234.833 46 234.28C46 233.728 46.4477 233.28 47 233.28C47.5523 233.28 48 233.728 48 234.28Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M54 234.28C54 234.833 53.5523 235.28 53 235.28C52.4477 235.28 52 234.833 52 234.28C52 233.728 52.4477 233.28 53 233.28C53.5523 233.28 54 233.728 54 234.28Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M60 234.28C60 234.833 59.5523 235.28 59 235.28C58.4477 235.28 58 234.833 58 234.28C58 233.728 58.4477 233.28 59 233.28C59.5523 233.28 60 233.728 60 234.28Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M66 234.28C66 234.833 65.5523 235.28 65 235.28C64.4477 235.28 64 234.833 64 234.28C64 233.728 64.4477 233.28 65 233.28C65.5523 233.28 66 233.728 66 234.28Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M72 234.28C72 234.833 71.5523 235.28 71 235.28C70.4477 235.28 70 234.833 70 234.28C70 233.728 70.4477 233.28 71 233.28C71.5523 233.28 72 233.728 72 234.28Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M78 234.28C78 234.833 77.5523 235.28 77 235.28C76.4477 235.28 76 234.833 76 234.28C76 233.728 76.4477 233.28 77 233.28C77.5523 233.28 78 233.728 78 234.28Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M84 234.28C84 234.833 83.5523 235.28 83 235.28C82.4477 235.28 82 234.833 82 234.28C82 233.728 82.4477 233.28 83 233.28C83.5523 233.28 84 233.728 84 234.28Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M90 234.28C90 234.833 89.5523 235.28 89 235.28C88.4477 235.28 88 234.833 88 234.28C88 233.728 88.4477 233.28 89 233.28C89.5523 233.28 90 233.728 90 234.28Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M96 234.28C96 234.833 95.5523 235.28 95 235.28C94.4477 235.28 94 234.833 94 234.28C94 233.728 94.4477 233.28 95 233.28C95.5523 233.28 96 233.728 96 234.28Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M102 234.28C102 234.833 101.552 235.28 101 235.28C100.448 235.28 100 234.833 100 234.28C100 233.728 100.448 233.28 101 233.28C101.552 233.28 102 233.728 102 234.28Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M108 234.28C108 234.833 107.552 235.28 107 235.28C106.448 235.28 106 234.833 106 234.28C106 233.728 106.448 233.28 107 233.28C107.552 233.28 108 233.728 108 234.28Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M114 234.28C114 234.833 113.552 235.28 113 235.28C112.448 235.28 112 234.833 112 234.28C112 233.728 112.448 233.28 113 233.28C113.552 233.28 114 233.728 114 234.28Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M120 234.28C120 234.833 119.552 235.28 119 235.28C118.448 235.28 118 234.833 118 234.28C118 233.728 118.448 233.28 119 233.28C119.552 233.28 120 233.728 120 234.28Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M126 234.28C126 234.833 125.552 235.28 125 235.28C124.448 235.28 124 234.833 124 234.28C124 233.728 124.448 233.28 125 233.28C125.552 233.28 126 233.728 126 234.28Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M132 234.28C132 234.833 131.552 235.28 131 235.28C130.448 235.28 130 234.833 130 234.28C130 233.728 130.448 233.28 131 233.28C131.552 233.28 132 233.728 132 234.28Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M138 234.28C138 234.833 137.552 235.28 137 235.28C136.448 235.28 136 234.833 136 234.28C136 233.728 136.448 233.28 137 233.28C137.552 233.28 138 233.728 138 234.28Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M144 234.28C144 234.833 143.552 235.28 143 235.28C142.448 235.28 142 234.833 142 234.28C142 233.728 142.448 233.28 143 233.28C143.552 233.28 144 233.728 144 234.28Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='149'
              cy='234.28'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='155'
              cy='234.28'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='161'
              cy='234.28'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='167'
              cy='234.28'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='173'
              cy='234.28'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='179'
              cy='234.28'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='185'
              cy='234.28'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='191'
              cy='234.28'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='197'
              cy='234.28'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='203'
              cy='234.28'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='209'
              cy='234.28'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='215'
              cy='234.28'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='221'
              cy='234.28'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='227'
              cy='234.28'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='233'
              cy='234.28'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='239'
              cy='234.28'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='245'
              cy='234.28'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='5'
              cy='117.64'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='11'
              cy='117.64'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='17'
              cy='117.64'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='23'
              cy='117.64'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M30 117.64C30 118.192 29.5523 118.64 29 118.64C28.4477 118.64 28 118.192 28 117.64C28 117.087 28.4477 116.64 29 116.64C29.5523 116.64 30 117.087 30 117.64Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M36 117.64C36 118.192 35.5523 118.64 35 118.64C34.4477 118.64 34 118.192 34 117.64C34 117.087 34.4477 116.64 35 116.64C35.5523 116.64 36 117.087 36 117.64Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M42 117.64C42 118.192 41.5523 118.64 41 118.64C40.4477 118.64 40 118.192 40 117.64C40 117.087 40.4477 116.64 41 116.64C41.5523 116.64 42 117.087 42 117.64Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M48 117.64C48 118.192 47.5523 118.64 47 118.64C46.4477 118.64 46 118.192 46 117.64C46 117.087 46.4477 116.64 47 116.64C47.5523 116.64 48 117.087 48 117.64Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M54 117.64C54 118.192 53.5523 118.64 53 118.64C52.4477 118.64 52 118.192 52 117.64C52 117.087 52.4477 116.64 53 116.64C53.5523 116.64 54 117.087 54 117.64Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M60 117.64C60 118.192 59.5523 118.64 59 118.64C58.4477 118.64 58 118.192 58 117.64C58 117.087 58.4477 116.64 59 116.64C59.5523 116.64 60 117.087 60 117.64Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M66 117.64C66 118.192 65.5523 118.64 65 118.64C64.4477 118.64 64 118.192 64 117.64C64 117.087 64.4477 116.64 65 116.64C65.5523 116.64 66 117.087 66 117.64Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M72 117.64C72 118.192 71.5523 118.64 71 118.64C70.4477 118.64 70 118.192 70 117.64C70 117.087 70.4477 116.64 71 116.64C71.5523 116.64 72 117.087 72 117.64Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M78 117.64C78 118.192 77.5523 118.64 77 118.64C76.4477 118.64 76 118.192 76 117.64C76 117.087 76.4477 116.64 77 116.64C77.5523 116.64 78 117.087 78 117.64Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M84 117.64C84 118.192 83.5523 118.64 83 118.64C82.4477 118.64 82 118.192 82 117.64C82 117.087 82.4477 116.64 83 116.64C83.5523 116.64 84 117.087 84 117.64Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M90 117.64C90 118.192 89.5523 118.64 89 118.64C88.4477 118.64 88 118.192 88 117.64C88 117.087 88.4477 116.64 89 116.64C89.5523 116.64 90 117.087 90 117.64Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M96 117.64C96 118.192 95.5523 118.64 95 118.64C94.4477 118.64 94 118.192 94 117.64C94 117.087 94.4477 116.64 95 116.64C95.5523 116.64 96 117.087 96 117.64Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M102 117.64C102 118.192 101.552 118.64 101 118.64C100.448 118.64 100 118.192 100 117.64C100 117.087 100.448 116.64 101 116.64C101.552 116.64 102 117.087 102 117.64Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M108 117.64C108 118.192 107.552 118.64 107 118.64C106.448 118.64 106 118.192 106 117.64C106 117.087 106.448 116.64 107 116.64C107.552 116.64 108 117.087 108 117.64Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M114 117.64C114 118.192 113.552 118.64 113 118.64C112.448 118.64 112 118.192 112 117.64C112 117.087 112.448 116.64 113 116.64C113.552 116.64 114 117.087 114 117.64Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M120 117.64C120 118.192 119.552 118.64 119 118.64C118.448 118.64 118 118.192 118 117.64C118 117.087 118.448 116.64 119 116.64C119.552 116.64 120 117.087 120 117.64Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M126 117.64C126 118.192 125.552 118.64 125 118.64C124.448 118.64 124 118.192 124 117.64C124 117.087 124.448 116.64 125 116.64C125.552 116.64 126 117.087 126 117.64Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M132 117.64C132 118.192 131.552 118.64 131 118.64C130.448 118.64 130 118.192 130 117.64C130 117.087 130.448 116.64 131 116.64C131.552 116.64 132 117.087 132 117.64Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M138 117.64C138 118.192 137.552 118.64 137 118.64C136.448 118.64 136 118.192 136 117.64C136 117.087 136.448 116.64 137 116.64C137.552 116.64 138 117.087 138 117.64Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M144 117.64C144 118.192 143.552 118.64 143 118.64C142.448 118.64 142 118.192 142 117.64C142 117.087 142.448 116.64 143 116.64C143.552 116.64 144 117.087 144 117.64Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='149'
              cy='117.64'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='155'
              cy='117.64'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='161'
              cy='117.64'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='167'
              cy='117.64'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='173'
              cy='117.64'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='179'
              cy='117.64'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='185'
              cy='117.64'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='191'
              cy='117.64'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='197'
              cy='117.64'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='203'
              cy='117.64'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='209'
              cy='117.64'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='215'
              cy='117.64'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='221'
              cy='117.64'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='227'
              cy='117.64'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='233'
              cy='117.64'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='239'
              cy='117.64'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='245'
              cy='117.64'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='5'
              cy='15.5801'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='11'
              cy='15.5801'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='17'
              cy='15.5801'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='23'
              cy='15.5801'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M30 15.5801C30 16.1324 29.5523 16.5801 29 16.5801C28.4477 16.5801 28 16.1324 28 15.5801C28 15.0278 28.4477 14.5801 29 14.5801C29.5523 14.5801 30 15.0278 30 15.5801Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M36 15.5801C36 16.1324 35.5523 16.5801 35 16.5801C34.4477 16.5801 34 16.1324 34 15.5801C34 15.0278 34.4477 14.5801 35 14.5801C35.5523 14.5801 36 15.0278 36 15.5801Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M42 15.5801C42 16.1324 41.5523 16.5801 41 16.5801C40.4477 16.5801 40 16.1324 40 15.5801C40 15.0278 40.4477 14.5801 41 14.5801C41.5523 14.5801 42 15.0278 42 15.5801Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M48 15.5801C48 16.1324 47.5523 16.5801 47 16.5801C46.4477 16.5801 46 16.1324 46 15.5801C46 15.0278 46.4477 14.5801 47 14.5801C47.5523 14.5801 48 15.0278 48 15.5801Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M54 15.5801C54 16.1324 53.5523 16.5801 53 16.5801C52.4477 16.5801 52 16.1324 52 15.5801C52 15.0278 52.4477 14.5801 53 14.5801C53.5523 14.5801 54 15.0278 54 15.5801Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M60 15.5801C60 16.1324 59.5523 16.5801 59 16.5801C58.4477 16.5801 58 16.1324 58 15.5801C58 15.0278 58.4477 14.5801 59 14.5801C59.5523 14.5801 60 15.0278 60 15.5801Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M66 15.5801C66 16.1324 65.5523 16.5801 65 16.5801C64.4477 16.5801 64 16.1324 64 15.5801C64 15.0278 64.4477 14.5801 65 14.5801C65.5523 14.5801 66 15.0278 66 15.5801Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M72 15.5801C72 16.1324 71.5523 16.5801 71 16.5801C70.4477 16.5801 70 16.1324 70 15.5801C70 15.0278 70.4477 14.5801 71 14.5801C71.5523 14.5801 72 15.0278 72 15.5801Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M78 15.5801C78 16.1324 77.5523 16.5801 77 16.5801C76.4477 16.5801 76 16.1324 76 15.5801C76 15.0278 76.4477 14.5801 77 14.5801C77.5523 14.5801 78 15.0278 78 15.5801Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M84 15.5801C84 16.1324 83.5523 16.5801 83 16.5801C82.4477 16.5801 82 16.1324 82 15.5801C82 15.0278 82.4477 14.5801 83 14.5801C83.5523 14.5801 84 15.0278 84 15.5801Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M90 15.5801C90 16.1324 89.5523 16.5801 89 16.5801C88.4477 16.5801 88 16.1324 88 15.5801C88 15.0278 88.4477 14.5801 89 14.5801C89.5523 14.5801 90 15.0278 90 15.5801Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M96 15.5801C96 16.1324 95.5523 16.5801 95 16.5801C94.4477 16.5801 94 16.1324 94 15.5801C94 15.0278 94.4477 14.5801 95 14.5801C95.5523 14.5801 96 15.0278 96 15.5801Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M102 15.5801C102 16.1324 101.552 16.5801 101 16.5801C100.448 16.5801 100 16.1324 100 15.5801C100 15.0278 100.448 14.5801 101 14.5801C101.552 14.5801 102 15.0278 102 15.5801Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M108 15.5801C108 16.1324 107.552 16.5801 107 16.5801C106.448 16.5801 106 16.1324 106 15.5801C106 15.0278 106.448 14.5801 107 14.5801C107.552 14.5801 108 15.0278 108 15.5801Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M114 15.5801C114 16.1324 113.552 16.5801 113 16.5801C112.448 16.5801 112 16.1324 112 15.5801C112 15.0278 112.448 14.5801 113 14.5801C113.552 14.5801 114 15.0278 114 15.5801Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M120 15.5801C120 16.1324 119.552 16.5801 119 16.5801C118.448 16.5801 118 16.1324 118 15.5801C118 15.0278 118.448 14.5801 119 14.5801C119.552 14.5801 120 15.0278 120 15.5801Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M126 15.5801C126 16.1324 125.552 16.5801 125 16.5801C124.448 16.5801 124 16.1324 124 15.5801C124 15.0278 124.448 14.5801 125 14.5801C125.552 14.5801 126 15.0278 126 15.5801Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M132 15.5801C132 16.1324 131.552 16.5801 131 16.5801C130.448 16.5801 130 16.1324 130 15.5801C130 15.0278 130.448 14.5801 131 14.5801C131.552 14.5801 132 15.0278 132 15.5801Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M138 15.5801C138 16.1324 137.552 16.5801 137 16.5801C136.448 16.5801 136 16.1324 136 15.5801C136 15.0278 136.448 14.5801 137 14.5801C137.552 14.5801 138 15.0278 138 15.5801Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M144 15.5801C144 16.1324 143.552 16.5801 143 16.5801C142.448 16.5801 142 16.1324 142 15.5801C142 15.0278 142.448 14.5801 143 14.5801C143.552 14.5801 144 15.0278 144 15.5801Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='149'
              cy='15.5801'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='155'
              cy='15.5801'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='161'
              cy='15.5801'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='167'
              cy='15.5801'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='173'
              cy='15.5801'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='179'
              cy='15.5801'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='185'
              cy='15.5801'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='191'
              cy='15.5801'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='197'
              cy='15.5801'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='203'
              cy='15.5801'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='209'
              cy='15.5801'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='215'
              cy='15.5801'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='221'
              cy='15.5801'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='227'
              cy='15.5801'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='233'
              cy='15.5801'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='239'
              cy='15.5801'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='245'
              cy='15.5801'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='5'
              cy='161.38'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='11'
              cy='161.38'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='17'
              cy='161.38'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='23'
              cy='161.38'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M30 161.38C30 161.932 29.5523 162.38 29 162.38C28.4477 162.38 28 161.932 28 161.38C28 160.828 28.4477 160.38 29 160.38C29.5523 160.38 30 160.828 30 161.38Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M36 161.38C36 161.932 35.5523 162.38 35 162.38C34.4477 162.38 34 161.932 34 161.38C34 160.828 34.4477 160.38 35 160.38C35.5523 160.38 36 160.828 36 161.38Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M42 161.38C42 161.932 41.5523 162.38 41 162.38C40.4477 162.38 40 161.932 40 161.38C40 160.828 40.4477 160.38 41 160.38C41.5523 160.38 42 160.828 42 161.38Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M48 161.38C48 161.932 47.5523 162.38 47 162.38C46.4477 162.38 46 161.932 46 161.38C46 160.828 46.4477 160.38 47 160.38C47.5523 160.38 48 160.828 48 161.38Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M54 161.38C54 161.932 53.5523 162.38 53 162.38C52.4477 162.38 52 161.932 52 161.38C52 160.828 52.4477 160.38 53 160.38C53.5523 160.38 54 160.828 54 161.38Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M60 161.38C60 161.932 59.5523 162.38 59 162.38C58.4477 162.38 58 161.932 58 161.38C58 160.828 58.4477 160.38 59 160.38C59.5523 160.38 60 160.828 60 161.38Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M66 161.38C66 161.932 65.5523 162.38 65 162.38C64.4477 162.38 64 161.932 64 161.38C64 160.828 64.4477 160.38 65 160.38C65.5523 160.38 66 160.828 66 161.38Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M72 161.38C72 161.932 71.5523 162.38 71 162.38C70.4477 162.38 70 161.932 70 161.38C70 160.828 70.4477 160.38 71 160.38C71.5523 160.38 72 160.828 72 161.38Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M78 161.38C78 161.932 77.5523 162.38 77 162.38C76.4477 162.38 76 161.932 76 161.38C76 160.828 76.4477 160.38 77 160.38C77.5523 160.38 78 160.828 78 161.38Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M84 161.38C84 161.932 83.5523 162.38 83 162.38C82.4477 162.38 82 161.932 82 161.38C82 160.828 82.4477 160.38 83 160.38C83.5523 160.38 84 160.828 84 161.38Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M90 161.38C90 161.932 89.5523 162.38 89 162.38C88.4477 162.38 88 161.932 88 161.38C88 160.828 88.4477 160.38 89 160.38C89.5523 160.38 90 160.828 90 161.38Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M96 161.38C96 161.932 95.5523 162.38 95 162.38C94.4477 162.38 94 161.932 94 161.38C94 160.828 94.4477 160.38 95 160.38C95.5523 160.38 96 160.828 96 161.38Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M102 161.38C102 161.932 101.552 162.38 101 162.38C100.448 162.38 100 161.932 100 161.38C100 160.828 100.448 160.38 101 160.38C101.552 160.38 102 160.828 102 161.38Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M108 161.38C108 161.932 107.552 162.38 107 162.38C106.448 162.38 106 161.932 106 161.38C106 160.828 106.448 160.38 107 160.38C107.552 160.38 108 160.828 108 161.38Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M114 161.38C114 161.932 113.552 162.38 113 162.38C112.448 162.38 112 161.932 112 161.38C112 160.828 112.448 160.38 113 160.38C113.552 160.38 114 160.828 114 161.38Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M120 161.38C120 161.932 119.552 162.38 119 162.38C118.448 162.38 118 161.932 118 161.38C118 160.828 118.448 160.38 119 160.38C119.552 160.38 120 160.828 120 161.38Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M126 161.38C126 161.932 125.552 162.38 125 162.38C124.448 162.38 124 161.932 124 161.38C124 160.828 124.448 160.38 125 160.38C125.552 160.38 126 160.828 126 161.38Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M132 161.38C132 161.932 131.552 162.38 131 162.38C130.448 162.38 130 161.932 130 161.38C130 160.828 130.448 160.38 131 160.38C131.552 160.38 132 160.828 132 161.38Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M138 161.38C138 161.932 137.552 162.38 137 162.38C136.448 162.38 136 161.932 136 161.38C136 160.828 136.448 160.38 137 160.38C137.552 160.38 138 160.828 138 161.38Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M144 161.38C144 161.932 143.552 162.38 143 162.38C142.448 162.38 142 161.932 142 161.38C142 160.828 142.448 160.38 143 160.38C143.552 160.38 144 160.828 144 161.38Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='149'
              cy='161.38'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='155'
              cy='161.38'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='161'
              cy='161.38'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='167'
              cy='161.38'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='173'
              cy='161.38'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='179'
              cy='161.38'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='185'
              cy='161.38'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='191'
              cy='161.38'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='197'
              cy='161.38'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='203'
              cy='161.38'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='209'
              cy='161.38'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='215'
              cy='161.38'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='221'
              cy='161.38'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='227'
              cy='161.38'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='233'
              cy='161.38'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='239'
              cy='161.38'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='245'
              cy='161.38'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='5'
              cy='44.7402'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='11'
              cy='44.7402'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='17'
              cy='44.7402'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='23'
              cy='44.7402'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M30 44.7402C30 45.2925 29.5523 45.7402 29 45.7402C28.4477 45.7402 28 45.2925 28 44.7402C28 44.1879 28.4477 43.7402 29 43.7402C29.5523 43.7402 30 44.1879 30 44.7402Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M36 44.7402C36 45.2925 35.5523 45.7402 35 45.7402C34.4477 45.7402 34 45.2925 34 44.7402C34 44.1879 34.4477 43.7402 35 43.7402C35.5523 43.7402 36 44.1879 36 44.7402Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M42 44.7402C42 45.2925 41.5523 45.7402 41 45.7402C40.4477 45.7402 40 45.2925 40 44.7402C40 44.1879 40.4477 43.7402 41 43.7402C41.5523 43.7402 42 44.1879 42 44.7402Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M48 44.7402C48 45.2925 47.5523 45.7402 47 45.7402C46.4477 45.7402 46 45.2925 46 44.7402C46 44.1879 46.4477 43.7402 47 43.7402C47.5523 43.7402 48 44.1879 48 44.7402Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M54 44.7402C54 45.2925 53.5523 45.7402 53 45.7402C52.4477 45.7402 52 45.2925 52 44.7402C52 44.1879 52.4477 43.7402 53 43.7402C53.5523 43.7402 54 44.1879 54 44.7402Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M60 44.7402C60 45.2925 59.5523 45.7402 59 45.7402C58.4477 45.7402 58 45.2925 58 44.7402C58 44.1879 58.4477 43.7402 59 43.7402C59.5523 43.7402 60 44.1879 60 44.7402Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M66 44.7402C66 45.2925 65.5523 45.7402 65 45.7402C64.4477 45.7402 64 45.2925 64 44.7402C64 44.1879 64.4477 43.7402 65 43.7402C65.5523 43.7402 66 44.1879 66 44.7402Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M72 44.7402C72 45.2925 71.5523 45.7402 71 45.7402C70.4477 45.7402 70 45.2925 70 44.7402C70 44.1879 70.4477 43.7402 71 43.7402C71.5523 43.7402 72 44.1879 72 44.7402Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M78 44.7402C78 45.2925 77.5523 45.7402 77 45.7402C76.4477 45.7402 76 45.2925 76 44.7402C76 44.1879 76.4477 43.7402 77 43.7402C77.5523 43.7402 78 44.1879 78 44.7402Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M84 44.7402C84 45.2925 83.5523 45.7402 83 45.7402C82.4477 45.7402 82 45.2925 82 44.7402C82 44.1879 82.4477 43.7402 83 43.7402C83.5523 43.7402 84 44.1879 84 44.7402Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M90 44.7402C90 45.2925 89.5523 45.7402 89 45.7402C88.4477 45.7402 88 45.2925 88 44.7402C88 44.1879 88.4477 43.7402 89 43.7402C89.5523 43.7402 90 44.1879 90 44.7402Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M96 44.7402C96 45.2925 95.5523 45.7402 95 45.7402C94.4477 45.7402 94 45.2925 94 44.7402C94 44.1879 94.4477 43.7402 95 43.7402C95.5523 43.7402 96 44.1879 96 44.7402Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M102 44.7402C102 45.2925 101.552 45.7402 101 45.7402C100.448 45.7402 100 45.2925 100 44.7402C100 44.1879 100.448 43.7402 101 43.7402C101.552 43.7402 102 44.1879 102 44.7402Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M108 44.7402C108 45.2925 107.552 45.7402 107 45.7402C106.448 45.7402 106 45.2925 106 44.7402C106 44.1879 106.448 43.7402 107 43.7402C107.552 43.7402 108 44.1879 108 44.7402Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M114 44.7402C114 45.2925 113.552 45.7402 113 45.7402C112.448 45.7402 112 45.2925 112 44.7402C112 44.1879 112.448 43.7402 113 43.7402C113.552 43.7402 114 44.1879 114 44.7402Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M120 44.7402C120 45.2925 119.552 45.7402 119 45.7402C118.448 45.7402 118 45.2925 118 44.7402C118 44.1879 118.448 43.7402 119 43.7402C119.552 43.7402 120 44.1879 120 44.7402Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M126 44.7402C126 45.2925 125.552 45.7402 125 45.7402C124.448 45.7402 124 45.2925 124 44.7402C124 44.1879 124.448 43.7402 125 43.7402C125.552 43.7402 126 44.1879 126 44.7402Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M132 44.7402C132 45.2925 131.552 45.7402 131 45.7402C130.448 45.7402 130 45.2925 130 44.7402C130 44.1879 130.448 43.7402 131 43.7402C131.552 43.7402 132 44.1879 132 44.7402Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M138 44.7402C138 45.2925 137.552 45.7402 137 45.7402C136.448 45.7402 136 45.2925 136 44.7402C136 44.1879 136.448 43.7402 137 43.7402C137.552 43.7402 138 44.1879 138 44.7402Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M144 44.7402C144 45.2925 143.552 45.7402 143 45.7402C142.448 45.7402 142 45.2925 142 44.7402C142 44.1879 142.448 43.7402 143 43.7402C143.552 43.7402 144 44.1879 144 44.7402Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='149'
              cy='44.7402'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='155'
              cy='44.7402'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='161'
              cy='44.7402'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='167'
              cy='44.7402'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='173'
              cy='44.7402'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='179'
              cy='44.7402'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='185'
              cy='44.7402'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='191'
              cy='44.7402'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='197'
              cy='44.7402'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='203'
              cy='44.7402'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='209'
              cy='44.7402'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='215'
              cy='44.7402'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='221'
              cy='44.7402'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='227'
              cy='44.7402'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='233'
              cy='44.7402'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='239'
              cy='44.7402'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='245'
              cy='44.7402'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='5'
              cy='190.54'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='11'
              cy='190.54'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='17'
              cy='190.54'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='23'
              cy='190.54'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M30 190.54C30 191.092 29.5523 191.54 29 191.54C28.4477 191.54 28 191.092 28 190.54C28 189.988 28.4477 189.54 29 189.54C29.5523 189.54 30 189.988 30 190.54Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M36 190.54C36 191.092 35.5523 191.54 35 191.54C34.4477 191.54 34 191.092 34 190.54C34 189.988 34.4477 189.54 35 189.54C35.5523 189.54 36 189.988 36 190.54Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M42 190.54C42 191.092 41.5523 191.54 41 191.54C40.4477 191.54 40 191.092 40 190.54C40 189.988 40.4477 189.54 41 189.54C41.5523 189.54 42 189.988 42 190.54Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M48 190.54C48 191.092 47.5523 191.54 47 191.54C46.4477 191.54 46 191.092 46 190.54C46 189.988 46.4477 189.54 47 189.54C47.5523 189.54 48 189.988 48 190.54Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M54 190.54C54 191.092 53.5523 191.54 53 191.54C52.4477 191.54 52 191.092 52 190.54C52 189.988 52.4477 189.54 53 189.54C53.5523 189.54 54 189.988 54 190.54Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M60 190.54C60 191.092 59.5523 191.54 59 191.54C58.4477 191.54 58 191.092 58 190.54C58 189.988 58.4477 189.54 59 189.54C59.5523 189.54 60 189.988 60 190.54Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M66 190.54C66 191.092 65.5523 191.54 65 191.54C64.4477 191.54 64 191.092 64 190.54C64 189.988 64.4477 189.54 65 189.54C65.5523 189.54 66 189.988 66 190.54Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M72 190.54C72 191.092 71.5523 191.54 71 191.54C70.4477 191.54 70 191.092 70 190.54C70 189.988 70.4477 189.54 71 189.54C71.5523 189.54 72 189.988 72 190.54Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M78 190.54C78 191.092 77.5523 191.54 77 191.54C76.4477 191.54 76 191.092 76 190.54C76 189.988 76.4477 189.54 77 189.54C77.5523 189.54 78 189.988 78 190.54Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M84 190.54C84 191.092 83.5523 191.54 83 191.54C82.4477 191.54 82 191.092 82 190.54C82 189.988 82.4477 189.54 83 189.54C83.5523 189.54 84 189.988 84 190.54Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M90 190.54C90 191.092 89.5523 191.54 89 191.54C88.4477 191.54 88 191.092 88 190.54C88 189.988 88.4477 189.54 89 189.54C89.5523 189.54 90 189.988 90 190.54Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M96 190.54C96 191.092 95.5523 191.54 95 191.54C94.4477 191.54 94 191.092 94 190.54C94 189.988 94.4477 189.54 95 189.54C95.5523 189.54 96 189.988 96 190.54Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M102 190.54C102 191.092 101.552 191.54 101 191.54C100.448 191.54 100 191.092 100 190.54C100 189.988 100.448 189.54 101 189.54C101.552 189.54 102 189.988 102 190.54Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M108 190.54C108 191.092 107.552 191.54 107 191.54C106.448 191.54 106 191.092 106 190.54C106 189.988 106.448 189.54 107 189.54C107.552 189.54 108 189.988 108 190.54Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M114 190.54C114 191.092 113.552 191.54 113 191.54C112.448 191.54 112 191.092 112 190.54C112 189.988 112.448 189.54 113 189.54C113.552 189.54 114 189.988 114 190.54Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M120 190.54C120 191.092 119.552 191.54 119 191.54C118.448 191.54 118 191.092 118 190.54C118 189.988 118.448 189.54 119 189.54C119.552 189.54 120 189.988 120 190.54Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M126 190.54C126 191.092 125.552 191.54 125 191.54C124.448 191.54 124 191.092 124 190.54C124 189.988 124.448 189.54 125 189.54C125.552 189.54 126 189.988 126 190.54Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M132 190.54C132 191.092 131.552 191.54 131 191.54C130.448 191.54 130 191.092 130 190.54C130 189.988 130.448 189.54 131 189.54C131.552 189.54 132 189.988 132 190.54Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M138 190.54C138 191.092 137.552 191.54 137 191.54C136.448 191.54 136 191.092 136 190.54C136 189.988 136.448 189.54 137 189.54C137.552 189.54 138 189.988 138 190.54Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M144 190.54C144 191.092 143.552 191.54 143 191.54C142.448 191.54 142 191.092 142 190.54C142 189.988 142.448 189.54 143 189.54C143.552 189.54 144 189.988 144 190.54Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='149'
              cy='190.54'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='155'
              cy='190.54'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='161'
              cy='190.54'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='167'
              cy='190.54'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='173'
              cy='190.54'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='179'
              cy='190.54'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='185'
              cy='190.54'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='191'
              cy='190.54'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='197'
              cy='190.54'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='203'
              cy='190.54'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='209'
              cy='190.54'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='215'
              cy='190.54'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='221'
              cy='190.54'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='227'
              cy='190.54'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='233'
              cy='190.54'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='239'
              cy='190.54'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='245'
              cy='190.54'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='5'
              cy='73.9004'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='11'
              cy='73.9004'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='17'
              cy='73.9004'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='23'
              cy='73.9004'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M30 73.9004C30 74.4527 29.5523 74.9004 29 74.9004C28.4477 74.9004 28 74.4527 28 73.9004C28 73.3481 28.4477 72.9004 29 72.9004C29.5523 72.9004 30 73.3481 30 73.9004Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M36 73.9004C36 74.4527 35.5523 74.9004 35 74.9004C34.4477 74.9004 34 74.4527 34 73.9004C34 73.3481 34.4477 72.9004 35 72.9004C35.5523 72.9004 36 73.3481 36 73.9004Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M42 73.9004C42 74.4527 41.5523 74.9004 41 74.9004C40.4477 74.9004 40 74.4527 40 73.9004C40 73.3481 40.4477 72.9004 41 72.9004C41.5523 72.9004 42 73.3481 42 73.9004Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M48 73.9004C48 74.4527 47.5523 74.9004 47 74.9004C46.4477 74.9004 46 74.4527 46 73.9004C46 73.3481 46.4477 72.9004 47 72.9004C47.5523 72.9004 48 73.3481 48 73.9004Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M54 73.9004C54 74.4527 53.5523 74.9004 53 74.9004C52.4477 74.9004 52 74.4527 52 73.9004C52 73.3481 52.4477 72.9004 53 72.9004C53.5523 72.9004 54 73.3481 54 73.9004Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M60 73.9004C60 74.4527 59.5523 74.9004 59 74.9004C58.4477 74.9004 58 74.4527 58 73.9004C58 73.3481 58.4477 72.9004 59 72.9004C59.5523 72.9004 60 73.3481 60 73.9004Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M66 73.9004C66 74.4527 65.5523 74.9004 65 74.9004C64.4477 74.9004 64 74.4527 64 73.9004C64 73.3481 64.4477 72.9004 65 72.9004C65.5523 72.9004 66 73.3481 66 73.9004Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M72 73.9004C72 74.4527 71.5523 74.9004 71 74.9004C70.4477 74.9004 70 74.4527 70 73.9004C70 73.3481 70.4477 72.9004 71 72.9004C71.5523 72.9004 72 73.3481 72 73.9004Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M78 73.9004C78 74.4527 77.5523 74.9004 77 74.9004C76.4477 74.9004 76 74.4527 76 73.9004C76 73.3481 76.4477 72.9004 77 72.9004C77.5523 72.9004 78 73.3481 78 73.9004Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M84 73.9004C84 74.4527 83.5523 74.9004 83 74.9004C82.4477 74.9004 82 74.4527 82 73.9004C82 73.3481 82.4477 72.9004 83 72.9004C83.5523 72.9004 84 73.3481 84 73.9004Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M90 73.9004C90 74.4527 89.5523 74.9004 89 74.9004C88.4477 74.9004 88 74.4527 88 73.9004C88 73.3481 88.4477 72.9004 89 72.9004C89.5523 72.9004 90 73.3481 90 73.9004Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M96 73.9004C96 74.4527 95.5523 74.9004 95 74.9004C94.4477 74.9004 94 74.4527 94 73.9004C94 73.3481 94.4477 72.9004 95 72.9004C95.5523 72.9004 96 73.3481 96 73.9004Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M102 73.9004C102 74.4527 101.552 74.9004 101 74.9004C100.448 74.9004 100 74.4527 100 73.9004C100 73.3481 100.448 72.9004 101 72.9004C101.552 72.9004 102 73.3481 102 73.9004Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M108 73.9004C108 74.4527 107.552 74.9004 107 74.9004C106.448 74.9004 106 74.4527 106 73.9004C106 73.3481 106.448 72.9004 107 72.9004C107.552 72.9004 108 73.3481 108 73.9004Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M114 73.9004C114 74.4527 113.552 74.9004 113 74.9004C112.448 74.9004 112 74.4527 112 73.9004C112 73.3481 112.448 72.9004 113 72.9004C113.552 72.9004 114 73.3481 114 73.9004Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M120 73.9004C120 74.4527 119.552 74.9004 119 74.9004C118.448 74.9004 118 74.4527 118 73.9004C118 73.3481 118.448 72.9004 119 72.9004C119.552 72.9004 120 73.3481 120 73.9004Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M126 73.9004C126 74.4527 125.552 74.9004 125 74.9004C124.448 74.9004 124 74.4527 124 73.9004C124 73.3481 124.448 72.9004 125 72.9004C125.552 72.9004 126 73.3481 126 73.9004Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M132 73.9004C132 74.4527 131.552 74.9004 131 74.9004C130.448 74.9004 130 74.4527 130 73.9004C130 73.3481 130.448 72.9004 131 72.9004C131.552 72.9004 132 73.3481 132 73.9004Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M138 73.9004C138 74.4527 137.552 74.9004 137 74.9004C136.448 74.9004 136 74.4527 136 73.9004C136 73.3481 136.448 72.9004 137 72.9004C137.552 72.9004 138 73.3481 138 73.9004Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M144 73.9004C144 74.4527 143.552 74.9004 143 74.9004C142.448 74.9004 142 74.4527 142 73.9004C142 73.3481 142.448 72.9004 143 72.9004C143.552 72.9004 144 73.3481 144 73.9004Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='149'
              cy='73.9004'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='155'
              cy='73.9004'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='161'
              cy='73.9004'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='167'
              cy='73.9004'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='173'
              cy='73.9004'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='179'
              cy='73.9004'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='185'
              cy='73.9004'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='191'
              cy='73.9004'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='197'
              cy='73.9004'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='203'
              cy='73.9004'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='209'
              cy='73.9004'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='215'
              cy='73.9004'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='221'
              cy='73.9004'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='227'
              cy='73.9004'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='233'
              cy='73.9004'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='239'
              cy='73.9004'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='245'
              cy='73.9004'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='5'
              cy='219.7'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='11'
              cy='219.7'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='17'
              cy='219.7'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='23'
              cy='219.7'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M30 219.7C30 220.252 29.5523 220.7 29 220.7C28.4477 220.7 28 220.252 28 219.7C28 219.148 28.4477 218.7 29 218.7C29.5523 218.7 30 219.148 30 219.7Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M36 219.7C36 220.252 35.5523 220.7 35 220.7C34.4477 220.7 34 220.252 34 219.7C34 219.148 34.4477 218.7 35 218.7C35.5523 218.7 36 219.148 36 219.7Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M42 219.7C42 220.252 41.5523 220.7 41 220.7C40.4477 220.7 40 220.252 40 219.7C40 219.148 40.4477 218.7 41 218.7C41.5523 218.7 42 219.148 42 219.7Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M48 219.7C48 220.252 47.5523 220.7 47 220.7C46.4477 220.7 46 220.252 46 219.7C46 219.148 46.4477 218.7 47 218.7C47.5523 218.7 48 219.148 48 219.7Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M54 219.7C54 220.252 53.5523 220.7 53 220.7C52.4477 220.7 52 220.252 52 219.7C52 219.148 52.4477 218.7 53 218.7C53.5523 218.7 54 219.148 54 219.7Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M60 219.7C60 220.252 59.5523 220.7 59 220.7C58.4477 220.7 58 220.252 58 219.7C58 219.148 58.4477 218.7 59 218.7C59.5523 218.7 60 219.148 60 219.7Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M66 219.7C66 220.252 65.5523 220.7 65 220.7C64.4477 220.7 64 220.252 64 219.7C64 219.148 64.4477 218.7 65 218.7C65.5523 218.7 66 219.148 66 219.7Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M72 219.7C72 220.252 71.5523 220.7 71 220.7C70.4477 220.7 70 220.252 70 219.7C70 219.148 70.4477 218.7 71 218.7C71.5523 218.7 72 219.148 72 219.7Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M78 219.7C78 220.252 77.5523 220.7 77 220.7C76.4477 220.7 76 220.252 76 219.7C76 219.148 76.4477 218.7 77 218.7C77.5523 218.7 78 219.148 78 219.7Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M84 219.7C84 220.252 83.5523 220.7 83 220.7C82.4477 220.7 82 220.252 82 219.7C82 219.148 82.4477 218.7 83 218.7C83.5523 218.7 84 219.148 84 219.7Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M90 219.7C90 220.252 89.5523 220.7 89 220.7C88.4477 220.7 88 220.252 88 219.7C88 219.148 88.4477 218.7 89 218.7C89.5523 218.7 90 219.148 90 219.7Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M96 219.7C96 220.252 95.5523 220.7 95 220.7C94.4477 220.7 94 220.252 94 219.7C94 219.148 94.4477 218.7 95 218.7C95.5523 218.7 96 219.148 96 219.7Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M102 219.7C102 220.252 101.552 220.7 101 220.7C100.448 220.7 100 220.252 100 219.7C100 219.148 100.448 218.7 101 218.7C101.552 218.7 102 219.148 102 219.7Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M108 219.7C108 220.252 107.552 220.7 107 220.7C106.448 220.7 106 220.252 106 219.7C106 219.148 106.448 218.7 107 218.7C107.552 218.7 108 219.148 108 219.7Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M114 219.7C114 220.252 113.552 220.7 113 220.7C112.448 220.7 112 220.252 112 219.7C112 219.148 112.448 218.7 113 218.7C113.552 218.7 114 219.148 114 219.7Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M120 219.7C120 220.252 119.552 220.7 119 220.7C118.448 220.7 118 220.252 118 219.7C118 219.148 118.448 218.7 119 218.7C119.552 218.7 120 219.148 120 219.7Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M126 219.7C126 220.252 125.552 220.7 125 220.7C124.448 220.7 124 220.252 124 219.7C124 219.148 124.448 218.7 125 218.7C125.552 218.7 126 219.148 126 219.7Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M132 219.7C132 220.252 131.552 220.7 131 220.7C130.448 220.7 130 220.252 130 219.7C130 219.148 130.448 218.7 131 218.7C131.552 218.7 132 219.148 132 219.7Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M138 219.7C138 220.252 137.552 220.7 137 220.7C136.448 220.7 136 220.252 136 219.7C136 219.148 136.448 218.7 137 218.7C137.552 218.7 138 219.148 138 219.7Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M144 219.7C144 220.252 143.552 220.7 143 220.7C142.448 220.7 142 220.252 142 219.7C142 219.148 142.448 218.7 143 218.7C143.552 218.7 144 219.148 144 219.7Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='149'
              cy='219.7'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='155'
              cy='219.7'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='161'
              cy='219.7'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='167'
              cy='219.7'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='173'
              cy='219.7'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='179'
              cy='219.7'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='185'
              cy='219.7'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='191'
              cy='219.7'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='197'
              cy='219.7'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='203'
              cy='219.7'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='209'
              cy='219.7'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='215'
              cy='219.7'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='221'
              cy='219.7'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='227'
              cy='219.7'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='233'
              cy='219.7'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='239'
              cy='219.7'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='245'
              cy='219.7'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='5'
              cy='103.06'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='11'
              cy='103.06'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='17'
              cy='103.06'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='23'
              cy='103.06'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M30 103.06C30 103.612 29.5523 104.06 29 104.06C28.4477 104.06 28 103.612 28 103.06C28 102.507 28.4477 102.06 29 102.06C29.5523 102.06 30 102.507 30 103.06Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M36 103.06C36 103.612 35.5523 104.06 35 104.06C34.4477 104.06 34 103.612 34 103.06C34 102.507 34.4477 102.06 35 102.06C35.5523 102.06 36 102.507 36 103.06Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M42 103.06C42 103.612 41.5523 104.06 41 104.06C40.4477 104.06 40 103.612 40 103.06C40 102.507 40.4477 102.06 41 102.06C41.5523 102.06 42 102.507 42 103.06Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M48 103.06C48 103.612 47.5523 104.06 47 104.06C46.4477 104.06 46 103.612 46 103.06C46 102.507 46.4477 102.06 47 102.06C47.5523 102.06 48 102.507 48 103.06Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M54 103.06C54 103.612 53.5523 104.06 53 104.06C52.4477 104.06 52 103.612 52 103.06C52 102.507 52.4477 102.06 53 102.06C53.5523 102.06 54 102.507 54 103.06Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M60 103.06C60 103.612 59.5523 104.06 59 104.06C58.4477 104.06 58 103.612 58 103.06C58 102.507 58.4477 102.06 59 102.06C59.5523 102.06 60 102.507 60 103.06Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M66 103.06C66 103.612 65.5523 104.06 65 104.06C64.4477 104.06 64 103.612 64 103.06C64 102.507 64.4477 102.06 65 102.06C65.5523 102.06 66 102.507 66 103.06Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M72 103.06C72 103.612 71.5523 104.06 71 104.06C70.4477 104.06 70 103.612 70 103.06C70 102.507 70.4477 102.06 71 102.06C71.5523 102.06 72 102.507 72 103.06Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M78 103.06C78 103.612 77.5523 104.06 77 104.06C76.4477 104.06 76 103.612 76 103.06C76 102.507 76.4477 102.06 77 102.06C77.5523 102.06 78 102.507 78 103.06Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M84 103.06C84 103.612 83.5523 104.06 83 104.06C82.4477 104.06 82 103.612 82 103.06C82 102.507 82.4477 102.06 83 102.06C83.5523 102.06 84 102.507 84 103.06Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M90 103.06C90 103.612 89.5523 104.06 89 104.06C88.4477 104.06 88 103.612 88 103.06C88 102.507 88.4477 102.06 89 102.06C89.5523 102.06 90 102.507 90 103.06Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M96 103.06C96 103.612 95.5523 104.06 95 104.06C94.4477 104.06 94 103.612 94 103.06C94 102.507 94.4477 102.06 95 102.06C95.5523 102.06 96 102.507 96 103.06Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M102 103.06C102 103.612 101.552 104.06 101 104.06C100.448 104.06 100 103.612 100 103.06C100 102.507 100.448 102.06 101 102.06C101.552 102.06 102 102.507 102 103.06Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M108 103.06C108 103.612 107.552 104.06 107 104.06C106.448 104.06 106 103.612 106 103.06C106 102.507 106.448 102.06 107 102.06C107.552 102.06 108 102.507 108 103.06Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M114 103.06C114 103.612 113.552 104.06 113 104.06C112.448 104.06 112 103.612 112 103.06C112 102.507 112.448 102.06 113 102.06C113.552 102.06 114 102.507 114 103.06Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M120 103.06C120 103.612 119.552 104.06 119 104.06C118.448 104.06 118 103.612 118 103.06C118 102.507 118.448 102.06 119 102.06C119.552 102.06 120 102.507 120 103.06Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M126 103.06C126 103.612 125.552 104.06 125 104.06C124.448 104.06 124 103.612 124 103.06C124 102.507 124.448 102.06 125 102.06C125.552 102.06 126 102.507 126 103.06Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M132 103.06C132 103.612 131.552 104.06 131 104.06C130.448 104.06 130 103.612 130 103.06C130 102.507 130.448 102.06 131 102.06C131.552 102.06 132 102.507 132 103.06Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M138 103.06C138 103.612 137.552 104.06 137 104.06C136.448 104.06 136 103.612 136 103.06C136 102.507 136.448 102.06 137 102.06C137.552 102.06 138 102.507 138 103.06Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M144 103.06C144 103.612 143.552 104.06 143 104.06C142.448 104.06 142 103.612 142 103.06C142 102.507 142.448 102.06 143 102.06C143.552 102.06 144 102.507 144 103.06Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='149'
              cy='103.06'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='155'
              cy='103.06'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='161'
              cy='103.06'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='167'
              cy='103.06'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='173'
              cy='103.06'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='179'
              cy='103.06'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='185'
              cy='103.06'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='191'
              cy='103.06'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='197'
              cy='103.06'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='203'
              cy='103.06'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='209'
              cy='103.06'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='215'
              cy='103.06'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='221'
              cy='103.06'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='227'
              cy='103.06'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='233'
              cy='103.06'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='239'
              cy='103.06'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='245'
              cy='103.06'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='5'
              cy='132.22'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='11'
              cy='132.22'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='17'
              cy='132.22'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='23'
              cy='132.22'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M30 132.22C30 132.772 29.5523 133.22 29 133.22C28.4477 133.22 28 132.772 28 132.22C28 131.667 28.4477 131.22 29 131.22C29.5523 131.22 30 131.667 30 132.22Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M36 132.22C36 132.772 35.5523 133.22 35 133.22C34.4477 133.22 34 132.772 34 132.22C34 131.667 34.4477 131.22 35 131.22C35.5523 131.22 36 131.667 36 132.22Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M42 132.22C42 132.772 41.5523 133.22 41 133.22C40.4477 133.22 40 132.772 40 132.22C40 131.667 40.4477 131.22 41 131.22C41.5523 131.22 42 131.667 42 132.22Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M48 132.22C48 132.772 47.5523 133.22 47 133.22C46.4477 133.22 46 132.772 46 132.22C46 131.667 46.4477 131.22 47 131.22C47.5523 131.22 48 131.667 48 132.22Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M54 132.22C54 132.772 53.5523 133.22 53 133.22C52.4477 133.22 52 132.772 52 132.22C52 131.667 52.4477 131.22 53 131.22C53.5523 131.22 54 131.667 54 132.22Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M60 132.22C60 132.772 59.5523 133.22 59 133.22C58.4477 133.22 58 132.772 58 132.22C58 131.667 58.4477 131.22 59 131.22C59.5523 131.22 60 131.667 60 132.22Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M66 132.22C66 132.772 65.5523 133.22 65 133.22C64.4477 133.22 64 132.772 64 132.22C64 131.667 64.4477 131.22 65 131.22C65.5523 131.22 66 131.667 66 132.22Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M72 132.22C72 132.772 71.5523 133.22 71 133.22C70.4477 133.22 70 132.772 70 132.22C70 131.667 70.4477 131.22 71 131.22C71.5523 131.22 72 131.667 72 132.22Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M78 132.22C78 132.772 77.5523 133.22 77 133.22C76.4477 133.22 76 132.772 76 132.22C76 131.667 76.4477 131.22 77 131.22C77.5523 131.22 78 131.667 78 132.22Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M84 132.22C84 132.772 83.5523 133.22 83 133.22C82.4477 133.22 82 132.772 82 132.22C82 131.667 82.4477 131.22 83 131.22C83.5523 131.22 84 131.667 84 132.22Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M90 132.22C90 132.772 89.5523 133.22 89 133.22C88.4477 133.22 88 132.772 88 132.22C88 131.667 88.4477 131.22 89 131.22C89.5523 131.22 90 131.667 90 132.22Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M96 132.22C96 132.772 95.5523 133.22 95 133.22C94.4477 133.22 94 132.772 94 132.22C94 131.667 94.4477 131.22 95 131.22C95.5523 131.22 96 131.667 96 132.22Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M102 132.22C102 132.772 101.552 133.22 101 133.22C100.448 133.22 100 132.772 100 132.22C100 131.667 100.448 131.22 101 131.22C101.552 131.22 102 131.667 102 132.22Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M108 132.22C108 132.772 107.552 133.22 107 133.22C106.448 133.22 106 132.772 106 132.22C106 131.667 106.448 131.22 107 131.22C107.552 131.22 108 131.667 108 132.22Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M114 132.22C114 132.772 113.552 133.22 113 133.22C112.448 133.22 112 132.772 112 132.22C112 131.667 112.448 131.22 113 131.22C113.552 131.22 114 131.667 114 132.22Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M120 132.22C120 132.772 119.552 133.22 119 133.22C118.448 133.22 118 132.772 118 132.22C118 131.667 118.448 131.22 119 131.22C119.552 131.22 120 131.667 120 132.22Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M126 132.22C126 132.772 125.552 133.22 125 133.22C124.448 133.22 124 132.772 124 132.22C124 131.667 124.448 131.22 125 131.22C125.552 131.22 126 131.667 126 132.22Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M132 132.22C132 132.772 131.552 133.22 131 133.22C130.448 133.22 130 132.772 130 132.22C130 131.667 130.448 131.22 131 131.22C131.552 131.22 132 131.667 132 132.22Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M138 132.22C138 132.772 137.552 133.22 137 133.22C136.448 133.22 136 132.772 136 132.22C136 131.667 136.448 131.22 137 131.22C137.552 131.22 138 131.667 138 132.22Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M144 132.22C144 132.772 143.552 133.22 143 133.22C142.448 133.22 142 132.772 142 132.22C142 131.667 142.448 131.22 143 131.22C143.552 131.22 144 131.667 144 132.22Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='149'
              cy='132.22'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='155'
              cy='132.22'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='161'
              cy='132.22'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='167'
              cy='132.22'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='173'
              cy='132.22'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='179'
              cy='132.22'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='185'
              cy='132.22'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='191'
              cy='132.22'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='197'
              cy='132.22'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='203'
              cy='132.22'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='209'
              cy='132.22'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='215'
              cy='132.22'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='221'
              cy='132.22'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='227'
              cy='132.22'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='233'
              cy='132.22'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='239'
              cy='132.22'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='245'
              cy='132.22'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='2.64502'
              cy='8.29004'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='8.64502'
              cy='8.29004'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='14.645'
              cy='8.29004'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='20.645'
              cy='8.29004'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='26.645'
              cy='8.29004'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M33.645 8.29004C33.645 8.84232 33.1973 9.29004 32.645 9.29004C32.0927 9.29004 31.645 8.84232 31.645 8.29004C31.645 7.73775 32.0927 7.29004 32.645 7.29004C33.1973 7.29004 33.645 7.73775 33.645 8.29004Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M39.645 8.29004C39.645 8.84232 39.1973 9.29004 38.645 9.29004C38.0927 9.29004 37.645 8.84232 37.645 8.29004C37.645 7.73775 38.0927 7.29004 38.645 7.29004C39.1973 7.29004 39.645 7.73775 39.645 8.29004Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M45.645 8.29004C45.645 8.84232 45.1973 9.29004 44.645 9.29004C44.0927 9.29004 43.645 8.84232 43.645 8.29004C43.645 7.73775 44.0927 7.29004 44.645 7.29004C45.1973 7.29004 45.645 7.73775 45.645 8.29004Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M51.645 8.29004C51.645 8.84232 51.1973 9.29004 50.645 9.29004C50.0927 9.29004 49.645 8.84232 49.645 8.29004C49.645 7.73775 50.0927 7.29004 50.645 7.29004C51.1973 7.29004 51.645 7.73775 51.645 8.29004Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M57.645 8.29004C57.645 8.84232 57.1973 9.29004 56.645 9.29004C56.0927 9.29004 55.645 8.84232 55.645 8.29004C55.645 7.73775 56.0927 7.29004 56.645 7.29004C57.1973 7.29004 57.645 7.73775 57.645 8.29004Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M63.645 8.29004C63.645 8.84232 63.1973 9.29004 62.645 9.29004C62.0927 9.29004 61.645 8.84232 61.645 8.29004C61.645 7.73775 62.0927 7.29004 62.645 7.29004C63.1973 7.29004 63.645 7.73775 63.645 8.29004Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M69.645 8.29004C69.645 8.84232 69.1973 9.29004 68.645 9.29004C68.0927 9.29004 67.645 8.84232 67.645 8.29004C67.645 7.73775 68.0927 7.29004 68.645 7.29004C69.1973 7.29004 69.645 7.73775 69.645 8.29004Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M75.645 8.29004C75.645 8.84232 75.1973 9.29004 74.645 9.29004C74.0927 9.29004 73.645 8.84232 73.645 8.29004C73.645 7.73775 74.0927 7.29004 74.645 7.29004C75.1973 7.29004 75.645 7.73775 75.645 8.29004Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M81.645 8.29004C81.645 8.84232 81.1973 9.29004 80.645 9.29004C80.0927 9.29004 79.645 8.84232 79.645 8.29004C79.645 7.73775 80.0927 7.29004 80.645 7.29004C81.1973 7.29004 81.645 7.73775 81.645 8.29004Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M87.645 8.29004C87.645 8.84232 87.1973 9.29004 86.645 9.29004C86.0927 9.29004 85.645 8.84232 85.645 8.29004C85.645 7.73775 86.0927 7.29004 86.645 7.29004C87.1973 7.29004 87.645 7.73775 87.645 8.29004Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M93.645 8.29004C93.645 8.84232 93.1973 9.29004 92.645 9.29004C92.0927 9.29004 91.645 8.84232 91.645 8.29004C91.645 7.73775 92.0927 7.29004 92.645 7.29004C93.1973 7.29004 93.645 7.73775 93.645 8.29004Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M99.645 8.29004C99.645 8.84232 99.1973 9.29004 98.645 9.29004C98.0927 9.29004 97.645 8.84232 97.645 8.29004C97.645 7.73775 98.0927 7.29004 98.645 7.29004C99.1973 7.29004 99.645 7.73775 99.645 8.29004Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M105.645 8.29004C105.645 8.84232 105.197 9.29004 104.645 9.29004C104.093 9.29004 103.645 8.84232 103.645 8.29004C103.645 7.73775 104.093 7.29004 104.645 7.29004C105.197 7.29004 105.645 7.73775 105.645 8.29004Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M111.645 8.29004C111.645 8.84232 111.197 9.29004 110.645 9.29004C110.093 9.29004 109.645 8.84232 109.645 8.29004C109.645 7.73775 110.093 7.29004 110.645 7.29004C111.197 7.29004 111.645 7.73775 111.645 8.29004Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M117.645 8.29004C117.645 8.84232 117.197 9.29004 116.645 9.29004C116.093 9.29004 115.645 8.84232 115.645 8.29004C115.645 7.73775 116.093 7.29004 116.645 7.29004C117.197 7.29004 117.645 7.73775 117.645 8.29004Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M123.645 8.29004C123.645 8.84232 123.197 9.29004 122.645 9.29004C122.093 9.29004 121.645 8.84232 121.645 8.29004C121.645 7.73775 122.093 7.29004 122.645 7.29004C123.197 7.29004 123.645 7.73775 123.645 8.29004Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M129.645 8.29004C129.645 8.84232 129.197 9.29004 128.645 9.29004C128.093 9.29004 127.645 8.84232 127.645 8.29004C127.645 7.73775 128.093 7.29004 128.645 7.29004C129.197 7.29004 129.645 7.73775 129.645 8.29004Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M135.645 8.29004C135.645 8.84232 135.197 9.29004 134.645 9.29004C134.093 9.29004 133.645 8.84232 133.645 8.29004C133.645 7.73775 134.093 7.29004 134.645 7.29004C135.197 7.29004 135.645 7.73775 135.645 8.29004Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M141.645 8.29004C141.645 8.84232 141.197 9.29004 140.645 9.29004C140.093 9.29004 139.645 8.84232 139.645 8.29004C139.645 7.73775 140.093 7.29004 140.645 7.29004C141.197 7.29004 141.645 7.73775 141.645 8.29004Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M147.645 8.29004C147.645 8.84232 147.197 9.29004 146.645 9.29004C146.093 9.29004 145.645 8.84232 145.645 8.29004C145.645 7.73775 146.093 7.29004 146.645 7.29004C147.197 7.29004 147.645 7.73775 147.645 8.29004Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='152.645'
              cy='8.29004'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='158.645'
              cy='8.29004'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='164.645'
              cy='8.29004'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='170.645'
              cy='8.29004'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='176.645'
              cy='8.29004'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='182.645'
              cy='8.29004'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='188.645'
              cy='8.29004'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='194.645'
              cy='8.29004'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='200.645'
              cy='8.29004'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='206.645'
              cy='8.29004'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='212.645'
              cy='8.29004'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='218.645'
              cy='8.29004'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='224.645'
              cy='8.29004'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='230.645'
              cy='8.29004'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='236.645'
              cy='8.29004'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='242.645'
              cy='8.29004'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='248.645'
              cy='8.29004'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='2.64502'
              cy='154.09'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='8.64502'
              cy='154.09'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='14.645'
              cy='154.09'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='20.645'
              cy='154.09'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='26.645'
              cy='154.09'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M33.645 154.09C33.645 154.642 33.1973 155.09 32.645 155.09C32.0927 155.09 31.645 154.642 31.645 154.09C31.645 153.538 32.0927 153.09 32.645 153.09C33.1973 153.09 33.645 153.538 33.645 154.09Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M39.645 154.09C39.645 154.642 39.1973 155.09 38.645 155.09C38.0927 155.09 37.645 154.642 37.645 154.09C37.645 153.538 38.0927 153.09 38.645 153.09C39.1973 153.09 39.645 153.538 39.645 154.09Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M45.645 154.09C45.645 154.642 45.1973 155.09 44.645 155.09C44.0927 155.09 43.645 154.642 43.645 154.09C43.645 153.538 44.0927 153.09 44.645 153.09C45.1973 153.09 45.645 153.538 45.645 154.09Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M51.645 154.09C51.645 154.642 51.1973 155.09 50.645 155.09C50.0927 155.09 49.645 154.642 49.645 154.09C49.645 153.538 50.0927 153.09 50.645 153.09C51.1973 153.09 51.645 153.538 51.645 154.09Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M57.645 154.09C57.645 154.642 57.1973 155.09 56.645 155.09C56.0927 155.09 55.645 154.642 55.645 154.09C55.645 153.538 56.0927 153.09 56.645 153.09C57.1973 153.09 57.645 153.538 57.645 154.09Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M63.645 154.09C63.645 154.642 63.1973 155.09 62.645 155.09C62.0927 155.09 61.645 154.642 61.645 154.09C61.645 153.538 62.0927 153.09 62.645 153.09C63.1973 153.09 63.645 153.538 63.645 154.09Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M69.645 154.09C69.645 154.642 69.1973 155.09 68.645 155.09C68.0927 155.09 67.645 154.642 67.645 154.09C67.645 153.538 68.0927 153.09 68.645 153.09C69.1973 153.09 69.645 153.538 69.645 154.09Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M75.645 154.09C75.645 154.642 75.1973 155.09 74.645 155.09C74.0927 155.09 73.645 154.642 73.645 154.09C73.645 153.538 74.0927 153.09 74.645 153.09C75.1973 153.09 75.645 153.538 75.645 154.09Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M81.645 154.09C81.645 154.642 81.1973 155.09 80.645 155.09C80.0927 155.09 79.645 154.642 79.645 154.09C79.645 153.538 80.0927 153.09 80.645 153.09C81.1973 153.09 81.645 153.538 81.645 154.09Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M87.645 154.09C87.645 154.642 87.1973 155.09 86.645 155.09C86.0927 155.09 85.645 154.642 85.645 154.09C85.645 153.538 86.0927 153.09 86.645 153.09C87.1973 153.09 87.645 153.538 87.645 154.09Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M93.645 154.09C93.645 154.642 93.1973 155.09 92.645 155.09C92.0927 155.09 91.645 154.642 91.645 154.09C91.645 153.538 92.0927 153.09 92.645 153.09C93.1973 153.09 93.645 153.538 93.645 154.09Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M99.645 154.09C99.645 154.642 99.1973 155.09 98.645 155.09C98.0927 155.09 97.645 154.642 97.645 154.09C97.645 153.538 98.0927 153.09 98.645 153.09C99.1973 153.09 99.645 153.538 99.645 154.09Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M105.645 154.09C105.645 154.642 105.197 155.09 104.645 155.09C104.093 155.09 103.645 154.642 103.645 154.09C103.645 153.538 104.093 153.09 104.645 153.09C105.197 153.09 105.645 153.538 105.645 154.09Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M111.645 154.09C111.645 154.642 111.197 155.09 110.645 155.09C110.093 155.09 109.645 154.642 109.645 154.09C109.645 153.538 110.093 153.09 110.645 153.09C111.197 153.09 111.645 153.538 111.645 154.09Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M117.645 154.09C117.645 154.642 117.197 155.09 116.645 155.09C116.093 155.09 115.645 154.642 115.645 154.09C115.645 153.538 116.093 153.09 116.645 153.09C117.197 153.09 117.645 153.538 117.645 154.09Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M123.645 154.09C123.645 154.642 123.197 155.09 122.645 155.09C122.093 155.09 121.645 154.642 121.645 154.09C121.645 153.538 122.093 153.09 122.645 153.09C123.197 153.09 123.645 153.538 123.645 154.09Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M129.645 154.09C129.645 154.642 129.197 155.09 128.645 155.09C128.093 155.09 127.645 154.642 127.645 154.09C127.645 153.538 128.093 153.09 128.645 153.09C129.197 153.09 129.645 153.538 129.645 154.09Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M135.645 154.09C135.645 154.642 135.197 155.09 134.645 155.09C134.093 155.09 133.645 154.642 133.645 154.09C133.645 153.538 134.093 153.09 134.645 153.09C135.197 153.09 135.645 153.538 135.645 154.09Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M141.645 154.09C141.645 154.642 141.197 155.09 140.645 155.09C140.093 155.09 139.645 154.642 139.645 154.09C139.645 153.538 140.093 153.09 140.645 153.09C141.197 153.09 141.645 153.538 141.645 154.09Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M147.645 154.09C147.645 154.642 147.197 155.09 146.645 155.09C146.093 155.09 145.645 154.642 145.645 154.09C145.645 153.538 146.093 153.09 146.645 153.09C147.197 153.09 147.645 153.538 147.645 154.09Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='152.645'
              cy='154.09'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='158.645'
              cy='154.09'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='164.645'
              cy='154.09'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='170.645'
              cy='154.09'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='176.645'
              cy='154.09'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='182.645'
              cy='154.09'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='188.645'
              cy='154.09'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='194.645'
              cy='154.09'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='200.645'
              cy='154.09'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='206.645'
              cy='154.09'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='212.645'
              cy='154.09'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='218.645'
              cy='154.09'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='224.645'
              cy='154.09'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='230.645'
              cy='154.09'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='236.645'
              cy='154.09'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='242.645'
              cy='154.09'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='248.645'
              cy='154.09'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='2.64502'
              cy='37.4502'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='8.64502'
              cy='37.4502'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='14.645'
              cy='37.4502'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='20.645'
              cy='37.4502'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='26.645'
              cy='37.4502'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M33.645 37.4502C33.645 38.0025 33.1973 38.4502 32.645 38.4502C32.0927 38.4502 31.645 38.0025 31.645 37.4502C31.645 36.8979 32.0927 36.4502 32.645 36.4502C33.1973 36.4502 33.645 36.8979 33.645 37.4502Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M39.645 37.4502C39.645 38.0025 39.1973 38.4502 38.645 38.4502C38.0927 38.4502 37.645 38.0025 37.645 37.4502C37.645 36.8979 38.0927 36.4502 38.645 36.4502C39.1973 36.4502 39.645 36.8979 39.645 37.4502Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M45.645 37.4502C45.645 38.0025 45.1973 38.4502 44.645 38.4502C44.0927 38.4502 43.645 38.0025 43.645 37.4502C43.645 36.8979 44.0927 36.4502 44.645 36.4502C45.1973 36.4502 45.645 36.8979 45.645 37.4502Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M51.645 37.4502C51.645 38.0025 51.1973 38.4502 50.645 38.4502C50.0927 38.4502 49.645 38.0025 49.645 37.4502C49.645 36.8979 50.0927 36.4502 50.645 36.4502C51.1973 36.4502 51.645 36.8979 51.645 37.4502Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M57.645 37.4502C57.645 38.0025 57.1973 38.4502 56.645 38.4502C56.0927 38.4502 55.645 38.0025 55.645 37.4502C55.645 36.8979 56.0927 36.4502 56.645 36.4502C57.1973 36.4502 57.645 36.8979 57.645 37.4502Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M63.645 37.4502C63.645 38.0025 63.1973 38.4502 62.645 38.4502C62.0927 38.4502 61.645 38.0025 61.645 37.4502C61.645 36.8979 62.0927 36.4502 62.645 36.4502C63.1973 36.4502 63.645 36.8979 63.645 37.4502Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M69.645 37.4502C69.645 38.0025 69.1973 38.4502 68.645 38.4502C68.0927 38.4502 67.645 38.0025 67.645 37.4502C67.645 36.8979 68.0927 36.4502 68.645 36.4502C69.1973 36.4502 69.645 36.8979 69.645 37.4502Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M75.645 37.4502C75.645 38.0025 75.1973 38.4502 74.645 38.4502C74.0927 38.4502 73.645 38.0025 73.645 37.4502C73.645 36.8979 74.0927 36.4502 74.645 36.4502C75.1973 36.4502 75.645 36.8979 75.645 37.4502Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M81.645 37.4502C81.645 38.0025 81.1973 38.4502 80.645 38.4502C80.0927 38.4502 79.645 38.0025 79.645 37.4502C79.645 36.8979 80.0927 36.4502 80.645 36.4502C81.1973 36.4502 81.645 36.8979 81.645 37.4502Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M87.645 37.4502C87.645 38.0025 87.1973 38.4502 86.645 38.4502C86.0927 38.4502 85.645 38.0025 85.645 37.4502C85.645 36.8979 86.0927 36.4502 86.645 36.4502C87.1973 36.4502 87.645 36.8979 87.645 37.4502Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M93.645 37.4502C93.645 38.0025 93.1973 38.4502 92.645 38.4502C92.0927 38.4502 91.645 38.0025 91.645 37.4502C91.645 36.8979 92.0927 36.4502 92.645 36.4502C93.1973 36.4502 93.645 36.8979 93.645 37.4502Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M99.645 37.4502C99.645 38.0025 99.1973 38.4502 98.645 38.4502C98.0927 38.4502 97.645 38.0025 97.645 37.4502C97.645 36.8979 98.0927 36.4502 98.645 36.4502C99.1973 36.4502 99.645 36.8979 99.645 37.4502Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M105.645 37.4502C105.645 38.0025 105.197 38.4502 104.645 38.4502C104.093 38.4502 103.645 38.0025 103.645 37.4502C103.645 36.8979 104.093 36.4502 104.645 36.4502C105.197 36.4502 105.645 36.8979 105.645 37.4502Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M111.645 37.4502C111.645 38.0025 111.197 38.4502 110.645 38.4502C110.093 38.4502 109.645 38.0025 109.645 37.4502C109.645 36.8979 110.093 36.4502 110.645 36.4502C111.197 36.4502 111.645 36.8979 111.645 37.4502Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M117.645 37.4502C117.645 38.0025 117.197 38.4502 116.645 38.4502C116.093 38.4502 115.645 38.0025 115.645 37.4502C115.645 36.8979 116.093 36.4502 116.645 36.4502C117.197 36.4502 117.645 36.8979 117.645 37.4502Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M123.645 37.4502C123.645 38.0025 123.197 38.4502 122.645 38.4502C122.093 38.4502 121.645 38.0025 121.645 37.4502C121.645 36.8979 122.093 36.4502 122.645 36.4502C123.197 36.4502 123.645 36.8979 123.645 37.4502Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M129.645 37.4502C129.645 38.0025 129.197 38.4502 128.645 38.4502C128.093 38.4502 127.645 38.0025 127.645 37.4502C127.645 36.8979 128.093 36.4502 128.645 36.4502C129.197 36.4502 129.645 36.8979 129.645 37.4502Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M135.645 37.4502C135.645 38.0025 135.197 38.4502 134.645 38.4502C134.093 38.4502 133.645 38.0025 133.645 37.4502C133.645 36.8979 134.093 36.4502 134.645 36.4502C135.197 36.4502 135.645 36.8979 135.645 37.4502Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M141.645 37.4502C141.645 38.0025 141.197 38.4502 140.645 38.4502C140.093 38.4502 139.645 38.0025 139.645 37.4502C139.645 36.8979 140.093 36.4502 140.645 36.4502C141.197 36.4502 141.645 36.8979 141.645 37.4502Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M147.645 37.4502C147.645 38.0025 147.197 38.4502 146.645 38.4502C146.093 38.4502 145.645 38.0025 145.645 37.4502C145.645 36.8979 146.093 36.4502 146.645 36.4502C147.197 36.4502 147.645 36.8979 147.645 37.4502Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='152.645'
              cy='37.4502'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='158.645'
              cy='37.4502'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='164.645'
              cy='37.4502'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='170.645'
              cy='37.4502'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='176.645'
              cy='37.4502'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='182.645'
              cy='37.4502'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='188.645'
              cy='37.4502'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='194.645'
              cy='37.4502'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='200.645'
              cy='37.4502'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='206.645'
              cy='37.4502'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='212.645'
              cy='37.4502'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='218.645'
              cy='37.4502'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='224.645'
              cy='37.4502'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='230.645'
              cy='37.4502'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='236.645'
              cy='37.4502'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='242.645'
              cy='37.4502'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='248.645'
              cy='37.4502'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='2.64502'
              cy='183.25'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='8.64502'
              cy='183.25'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='14.645'
              cy='183.25'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='20.645'
              cy='183.25'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='26.645'
              cy='183.25'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M33.645 183.25C33.645 183.802 33.1973 184.25 32.645 184.25C32.0927 184.25 31.645 183.802 31.645 183.25C31.645 182.698 32.0927 182.25 32.645 182.25C33.1973 182.25 33.645 182.698 33.645 183.25Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M39.645 183.25C39.645 183.802 39.1973 184.25 38.645 184.25C38.0927 184.25 37.645 183.802 37.645 183.25C37.645 182.698 38.0927 182.25 38.645 182.25C39.1973 182.25 39.645 182.698 39.645 183.25Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M45.645 183.25C45.645 183.802 45.1973 184.25 44.645 184.25C44.0927 184.25 43.645 183.802 43.645 183.25C43.645 182.698 44.0927 182.25 44.645 182.25C45.1973 182.25 45.645 182.698 45.645 183.25Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M51.645 183.25C51.645 183.802 51.1973 184.25 50.645 184.25C50.0927 184.25 49.645 183.802 49.645 183.25C49.645 182.698 50.0927 182.25 50.645 182.25C51.1973 182.25 51.645 182.698 51.645 183.25Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M57.645 183.25C57.645 183.802 57.1973 184.25 56.645 184.25C56.0927 184.25 55.645 183.802 55.645 183.25C55.645 182.698 56.0927 182.25 56.645 182.25C57.1973 182.25 57.645 182.698 57.645 183.25Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M63.645 183.25C63.645 183.802 63.1973 184.25 62.645 184.25C62.0927 184.25 61.645 183.802 61.645 183.25C61.645 182.698 62.0927 182.25 62.645 182.25C63.1973 182.25 63.645 182.698 63.645 183.25Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M69.645 183.25C69.645 183.802 69.1973 184.25 68.645 184.25C68.0927 184.25 67.645 183.802 67.645 183.25C67.645 182.698 68.0927 182.25 68.645 182.25C69.1973 182.25 69.645 182.698 69.645 183.25Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M75.645 183.25C75.645 183.802 75.1973 184.25 74.645 184.25C74.0927 184.25 73.645 183.802 73.645 183.25C73.645 182.698 74.0927 182.25 74.645 182.25C75.1973 182.25 75.645 182.698 75.645 183.25Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M81.645 183.25C81.645 183.802 81.1973 184.25 80.645 184.25C80.0927 184.25 79.645 183.802 79.645 183.25C79.645 182.698 80.0927 182.25 80.645 182.25C81.1973 182.25 81.645 182.698 81.645 183.25Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M87.645 183.25C87.645 183.802 87.1973 184.25 86.645 184.25C86.0927 184.25 85.645 183.802 85.645 183.25C85.645 182.698 86.0927 182.25 86.645 182.25C87.1973 182.25 87.645 182.698 87.645 183.25Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M93.645 183.25C93.645 183.802 93.1973 184.25 92.645 184.25C92.0927 184.25 91.645 183.802 91.645 183.25C91.645 182.698 92.0927 182.25 92.645 182.25C93.1973 182.25 93.645 182.698 93.645 183.25Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M99.645 183.25C99.645 183.802 99.1973 184.25 98.645 184.25C98.0927 184.25 97.645 183.802 97.645 183.25C97.645 182.698 98.0927 182.25 98.645 182.25C99.1973 182.25 99.645 182.698 99.645 183.25Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M105.645 183.25C105.645 183.802 105.197 184.25 104.645 184.25C104.093 184.25 103.645 183.802 103.645 183.25C103.645 182.698 104.093 182.25 104.645 182.25C105.197 182.25 105.645 182.698 105.645 183.25Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M111.645 183.25C111.645 183.802 111.197 184.25 110.645 184.25C110.093 184.25 109.645 183.802 109.645 183.25C109.645 182.698 110.093 182.25 110.645 182.25C111.197 182.25 111.645 182.698 111.645 183.25Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M117.645 183.25C117.645 183.802 117.197 184.25 116.645 184.25C116.093 184.25 115.645 183.802 115.645 183.25C115.645 182.698 116.093 182.25 116.645 182.25C117.197 182.25 117.645 182.698 117.645 183.25Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M123.645 183.25C123.645 183.802 123.197 184.25 122.645 184.25C122.093 184.25 121.645 183.802 121.645 183.25C121.645 182.698 122.093 182.25 122.645 182.25C123.197 182.25 123.645 182.698 123.645 183.25Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M129.645 183.25C129.645 183.802 129.197 184.25 128.645 184.25C128.093 184.25 127.645 183.802 127.645 183.25C127.645 182.698 128.093 182.25 128.645 182.25C129.197 182.25 129.645 182.698 129.645 183.25Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M135.645 183.25C135.645 183.802 135.197 184.25 134.645 184.25C134.093 184.25 133.645 183.802 133.645 183.25C133.645 182.698 134.093 182.25 134.645 182.25C135.197 182.25 135.645 182.698 135.645 183.25Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M141.645 183.25C141.645 183.802 141.197 184.25 140.645 184.25C140.093 184.25 139.645 183.802 139.645 183.25C139.645 182.698 140.093 182.25 140.645 182.25C141.197 182.25 141.645 182.698 141.645 183.25Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M147.645 183.25C147.645 183.802 147.197 184.25 146.645 184.25C146.093 184.25 145.645 183.802 145.645 183.25C145.645 182.698 146.093 182.25 146.645 182.25C147.197 182.25 147.645 182.698 147.645 183.25Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='152.645'
              cy='183.25'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='158.645'
              cy='183.25'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='164.645'
              cy='183.25'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='170.645'
              cy='183.25'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='176.645'
              cy='183.25'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='182.645'
              cy='183.25'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='188.645'
              cy='183.25'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='194.645'
              cy='183.25'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='200.645'
              cy='183.25'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='206.645'
              cy='183.25'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='212.645'
              cy='183.25'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='218.645'
              cy='183.25'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='224.645'
              cy='183.25'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='230.645'
              cy='183.25'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='236.645'
              cy='183.25'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='242.645'
              cy='183.25'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='248.645'
              cy='183.25'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='2.64502'
              cy='66.6104'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='8.64502'
              cy='66.6104'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='14.645'
              cy='66.6104'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='20.645'
              cy='66.6104'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='26.645'
              cy='66.6104'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M33.645 66.6104C33.645 67.1626 33.1973 67.6104 32.645 67.6104C32.0927 67.6104 31.645 67.1626 31.645 66.6104C31.645 66.0581 32.0927 65.6104 32.645 65.6104C33.1973 65.6104 33.645 66.0581 33.645 66.6104Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M39.645 66.6104C39.645 67.1626 39.1973 67.6104 38.645 67.6104C38.0927 67.6104 37.645 67.1626 37.645 66.6104C37.645 66.0581 38.0927 65.6104 38.645 65.6104C39.1973 65.6104 39.645 66.0581 39.645 66.6104Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M45.645 66.6104C45.645 67.1626 45.1973 67.6104 44.645 67.6104C44.0927 67.6104 43.645 67.1626 43.645 66.6104C43.645 66.0581 44.0927 65.6104 44.645 65.6104C45.1973 65.6104 45.645 66.0581 45.645 66.6104Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M51.645 66.6104C51.645 67.1626 51.1973 67.6104 50.645 67.6104C50.0927 67.6104 49.645 67.1626 49.645 66.6104C49.645 66.0581 50.0927 65.6104 50.645 65.6104C51.1973 65.6104 51.645 66.0581 51.645 66.6104Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M57.645 66.6104C57.645 67.1626 57.1973 67.6104 56.645 67.6104C56.0927 67.6104 55.645 67.1626 55.645 66.6104C55.645 66.0581 56.0927 65.6104 56.645 65.6104C57.1973 65.6104 57.645 66.0581 57.645 66.6104Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M63.645 66.6104C63.645 67.1626 63.1973 67.6104 62.645 67.6104C62.0927 67.6104 61.645 67.1626 61.645 66.6104C61.645 66.0581 62.0927 65.6104 62.645 65.6104C63.1973 65.6104 63.645 66.0581 63.645 66.6104Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M69.645 66.6104C69.645 67.1626 69.1973 67.6104 68.645 67.6104C68.0927 67.6104 67.645 67.1626 67.645 66.6104C67.645 66.0581 68.0927 65.6104 68.645 65.6104C69.1973 65.6104 69.645 66.0581 69.645 66.6104Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M75.645 66.6104C75.645 67.1626 75.1973 67.6104 74.645 67.6104C74.0927 67.6104 73.645 67.1626 73.645 66.6104C73.645 66.0581 74.0927 65.6104 74.645 65.6104C75.1973 65.6104 75.645 66.0581 75.645 66.6104Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M81.645 66.6104C81.645 67.1626 81.1973 67.6104 80.645 67.6104C80.0927 67.6104 79.645 67.1626 79.645 66.6104C79.645 66.0581 80.0927 65.6104 80.645 65.6104C81.1973 65.6104 81.645 66.0581 81.645 66.6104Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M87.645 66.6104C87.645 67.1626 87.1973 67.6104 86.645 67.6104C86.0927 67.6104 85.645 67.1626 85.645 66.6104C85.645 66.0581 86.0927 65.6104 86.645 65.6104C87.1973 65.6104 87.645 66.0581 87.645 66.6104Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M93.645 66.6104C93.645 67.1626 93.1973 67.6104 92.645 67.6104C92.0927 67.6104 91.645 67.1626 91.645 66.6104C91.645 66.0581 92.0927 65.6104 92.645 65.6104C93.1973 65.6104 93.645 66.0581 93.645 66.6104Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M99.645 66.6104C99.645 67.1626 99.1973 67.6104 98.645 67.6104C98.0927 67.6104 97.645 67.1626 97.645 66.6104C97.645 66.0581 98.0927 65.6104 98.645 65.6104C99.1973 65.6104 99.645 66.0581 99.645 66.6104Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M105.645 66.6104C105.645 67.1626 105.197 67.6104 104.645 67.6104C104.093 67.6104 103.645 67.1626 103.645 66.6104C103.645 66.0581 104.093 65.6104 104.645 65.6104C105.197 65.6104 105.645 66.0581 105.645 66.6104Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M111.645 66.6104C111.645 67.1626 111.197 67.6104 110.645 67.6104C110.093 67.6104 109.645 67.1626 109.645 66.6104C109.645 66.0581 110.093 65.6104 110.645 65.6104C111.197 65.6104 111.645 66.0581 111.645 66.6104Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M117.645 66.6104C117.645 67.1626 117.197 67.6104 116.645 67.6104C116.093 67.6104 115.645 67.1626 115.645 66.6104C115.645 66.0581 116.093 65.6104 116.645 65.6104C117.197 65.6104 117.645 66.0581 117.645 66.6104Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M123.645 66.6104C123.645 67.1626 123.197 67.6104 122.645 67.6104C122.093 67.6104 121.645 67.1626 121.645 66.6104C121.645 66.0581 122.093 65.6104 122.645 65.6104C123.197 65.6104 123.645 66.0581 123.645 66.6104Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M129.645 66.6104C129.645 67.1626 129.197 67.6104 128.645 67.6104C128.093 67.6104 127.645 67.1626 127.645 66.6104C127.645 66.0581 128.093 65.6104 128.645 65.6104C129.197 65.6104 129.645 66.0581 129.645 66.6104Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M135.645 66.6104C135.645 67.1626 135.197 67.6104 134.645 67.6104C134.093 67.6104 133.645 67.1626 133.645 66.6104C133.645 66.0581 134.093 65.6104 134.645 65.6104C135.197 65.6104 135.645 66.0581 135.645 66.6104Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M141.645 66.6104C141.645 67.1626 141.197 67.6104 140.645 67.6104C140.093 67.6104 139.645 67.1626 139.645 66.6104C139.645 66.0581 140.093 65.6104 140.645 65.6104C141.197 65.6104 141.645 66.0581 141.645 66.6104Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M147.645 66.6104C147.645 67.1626 147.197 67.6104 146.645 67.6104C146.093 67.6104 145.645 67.1626 145.645 66.6104C145.645 66.0581 146.093 65.6104 146.645 65.6104C147.197 65.6104 147.645 66.0581 147.645 66.6104Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='152.645'
              cy='66.6104'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='158.645'
              cy='66.6104'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='164.645'
              cy='66.6104'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='170.645'
              cy='66.6104'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='176.645'
              cy='66.6104'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='182.645'
              cy='66.6104'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='188.645'
              cy='66.6104'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='194.645'
              cy='66.6104'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='200.645'
              cy='66.6104'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='206.645'
              cy='66.6104'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='212.645'
              cy='66.6104'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='218.645'
              cy='66.6104'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='224.645'
              cy='66.6104'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='230.645'
              cy='66.6104'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='236.645'
              cy='66.6104'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='242.645'
              cy='66.6104'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='248.645'
              cy='66.6104'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='2.64502'
              cy='212.41'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='8.64502'
              cy='212.41'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='14.645'
              cy='212.41'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='20.645'
              cy='212.41'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='26.645'
              cy='212.41'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M33.645 212.41C33.645 212.962 33.1973 213.41 32.645 213.41C32.0927 213.41 31.645 212.962 31.645 212.41C31.645 211.858 32.0927 211.41 32.645 211.41C33.1973 211.41 33.645 211.858 33.645 212.41Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M39.645 212.41C39.645 212.962 39.1973 213.41 38.645 213.41C38.0927 213.41 37.645 212.962 37.645 212.41C37.645 211.858 38.0927 211.41 38.645 211.41C39.1973 211.41 39.645 211.858 39.645 212.41Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M45.645 212.41C45.645 212.962 45.1973 213.41 44.645 213.41C44.0927 213.41 43.645 212.962 43.645 212.41C43.645 211.858 44.0927 211.41 44.645 211.41C45.1973 211.41 45.645 211.858 45.645 212.41Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M51.645 212.41C51.645 212.962 51.1973 213.41 50.645 213.41C50.0927 213.41 49.645 212.962 49.645 212.41C49.645 211.858 50.0927 211.41 50.645 211.41C51.1973 211.41 51.645 211.858 51.645 212.41Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M57.645 212.41C57.645 212.962 57.1973 213.41 56.645 213.41C56.0927 213.41 55.645 212.962 55.645 212.41C55.645 211.858 56.0927 211.41 56.645 211.41C57.1973 211.41 57.645 211.858 57.645 212.41Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M63.645 212.41C63.645 212.962 63.1973 213.41 62.645 213.41C62.0927 213.41 61.645 212.962 61.645 212.41C61.645 211.858 62.0927 211.41 62.645 211.41C63.1973 211.41 63.645 211.858 63.645 212.41Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M69.645 212.41C69.645 212.962 69.1973 213.41 68.645 213.41C68.0927 213.41 67.645 212.962 67.645 212.41C67.645 211.858 68.0927 211.41 68.645 211.41C69.1973 211.41 69.645 211.858 69.645 212.41Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M75.645 212.41C75.645 212.962 75.1973 213.41 74.645 213.41C74.0927 213.41 73.645 212.962 73.645 212.41C73.645 211.858 74.0927 211.41 74.645 211.41C75.1973 211.41 75.645 211.858 75.645 212.41Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M81.645 212.41C81.645 212.962 81.1973 213.41 80.645 213.41C80.0927 213.41 79.645 212.962 79.645 212.41C79.645 211.858 80.0927 211.41 80.645 211.41C81.1973 211.41 81.645 211.858 81.645 212.41Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M87.645 212.41C87.645 212.962 87.1973 213.41 86.645 213.41C86.0927 213.41 85.645 212.962 85.645 212.41C85.645 211.858 86.0927 211.41 86.645 211.41C87.1973 211.41 87.645 211.858 87.645 212.41Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M93.645 212.41C93.645 212.962 93.1973 213.41 92.645 213.41C92.0927 213.41 91.645 212.962 91.645 212.41C91.645 211.858 92.0927 211.41 92.645 211.41C93.1973 211.41 93.645 211.858 93.645 212.41Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M99.645 212.41C99.645 212.962 99.1973 213.41 98.645 213.41C98.0927 213.41 97.645 212.962 97.645 212.41C97.645 211.858 98.0927 211.41 98.645 211.41C99.1973 211.41 99.645 211.858 99.645 212.41Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M105.645 212.41C105.645 212.962 105.197 213.41 104.645 213.41C104.093 213.41 103.645 212.962 103.645 212.41C103.645 211.858 104.093 211.41 104.645 211.41C105.197 211.41 105.645 211.858 105.645 212.41Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M111.645 212.41C111.645 212.962 111.197 213.41 110.645 213.41C110.093 213.41 109.645 212.962 109.645 212.41C109.645 211.858 110.093 211.41 110.645 211.41C111.197 211.41 111.645 211.858 111.645 212.41Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M117.645 212.41C117.645 212.962 117.197 213.41 116.645 213.41C116.093 213.41 115.645 212.962 115.645 212.41C115.645 211.858 116.093 211.41 116.645 211.41C117.197 211.41 117.645 211.858 117.645 212.41Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M123.645 212.41C123.645 212.962 123.197 213.41 122.645 213.41C122.093 213.41 121.645 212.962 121.645 212.41C121.645 211.858 122.093 211.41 122.645 211.41C123.197 211.41 123.645 211.858 123.645 212.41Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M129.645 212.41C129.645 212.962 129.197 213.41 128.645 213.41C128.093 213.41 127.645 212.962 127.645 212.41C127.645 211.858 128.093 211.41 128.645 211.41C129.197 211.41 129.645 211.858 129.645 212.41Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M135.645 212.41C135.645 212.962 135.197 213.41 134.645 213.41C134.093 213.41 133.645 212.962 133.645 212.41C133.645 211.858 134.093 211.41 134.645 211.41C135.197 211.41 135.645 211.858 135.645 212.41Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M141.645 212.41C141.645 212.962 141.197 213.41 140.645 213.41C140.093 213.41 139.645 212.962 139.645 212.41C139.645 211.858 140.093 211.41 140.645 211.41C141.197 211.41 141.645 211.858 141.645 212.41Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M147.645 212.41C147.645 212.962 147.197 213.41 146.645 213.41C146.093 213.41 145.645 212.962 145.645 212.41C145.645 211.858 146.093 211.41 146.645 211.41C147.197 211.41 147.645 211.858 147.645 212.41Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='152.645'
              cy='212.41'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='158.645'
              cy='212.41'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='164.645'
              cy='212.41'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='170.645'
              cy='212.41'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='176.645'
              cy='212.41'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='182.645'
              cy='212.41'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='188.645'
              cy='212.41'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='194.645'
              cy='212.41'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='200.645'
              cy='212.41'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='206.645'
              cy='212.41'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='212.645'
              cy='212.41'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='218.645'
              cy='212.41'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='224.645'
              cy='212.41'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='230.645'
              cy='212.41'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='236.645'
              cy='212.41'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='242.645'
              cy='212.41'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='248.645'
              cy='212.41'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='2.64502'
              cy='95.7695'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='8.64502'
              cy='95.7695'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='14.645'
              cy='95.7695'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='20.645'
              cy='95.7695'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='26.645'
              cy='95.7695'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M33.645 95.7695C33.645 96.3218 33.1973 96.7695 32.645 96.7695C32.0927 96.7695 31.645 96.3218 31.645 95.7695C31.645 95.2172 32.0927 94.7695 32.645 94.7695C33.1973 94.7695 33.645 95.2172 33.645 95.7695Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M39.645 95.7695C39.645 96.3218 39.1973 96.7695 38.645 96.7695C38.0927 96.7695 37.645 96.3218 37.645 95.7695C37.645 95.2172 38.0927 94.7695 38.645 94.7695C39.1973 94.7695 39.645 95.2172 39.645 95.7695Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M45.645 95.7695C45.645 96.3218 45.1973 96.7695 44.645 96.7695C44.0927 96.7695 43.645 96.3218 43.645 95.7695C43.645 95.2172 44.0927 94.7695 44.645 94.7695C45.1973 94.7695 45.645 95.2172 45.645 95.7695Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M51.645 95.7695C51.645 96.3218 51.1973 96.7695 50.645 96.7695C50.0927 96.7695 49.645 96.3218 49.645 95.7695C49.645 95.2172 50.0927 94.7695 50.645 94.7695C51.1973 94.7695 51.645 95.2172 51.645 95.7695Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M57.645 95.7695C57.645 96.3218 57.1973 96.7695 56.645 96.7695C56.0927 96.7695 55.645 96.3218 55.645 95.7695C55.645 95.2172 56.0927 94.7695 56.645 94.7695C57.1973 94.7695 57.645 95.2172 57.645 95.7695Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M63.645 95.7695C63.645 96.3218 63.1973 96.7695 62.645 96.7695C62.0927 96.7695 61.645 96.3218 61.645 95.7695C61.645 95.2172 62.0927 94.7695 62.645 94.7695C63.1973 94.7695 63.645 95.2172 63.645 95.7695Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M69.645 95.7695C69.645 96.3218 69.1973 96.7695 68.645 96.7695C68.0927 96.7695 67.645 96.3218 67.645 95.7695C67.645 95.2172 68.0927 94.7695 68.645 94.7695C69.1973 94.7695 69.645 95.2172 69.645 95.7695Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M75.645 95.7695C75.645 96.3218 75.1973 96.7695 74.645 96.7695C74.0927 96.7695 73.645 96.3218 73.645 95.7695C73.645 95.2172 74.0927 94.7695 74.645 94.7695C75.1973 94.7695 75.645 95.2172 75.645 95.7695Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M81.645 95.7695C81.645 96.3218 81.1973 96.7695 80.645 96.7695C80.0927 96.7695 79.645 96.3218 79.645 95.7695C79.645 95.2172 80.0927 94.7695 80.645 94.7695C81.1973 94.7695 81.645 95.2172 81.645 95.7695Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M87.645 95.7695C87.645 96.3218 87.1973 96.7695 86.645 96.7695C86.0927 96.7695 85.645 96.3218 85.645 95.7695C85.645 95.2172 86.0927 94.7695 86.645 94.7695C87.1973 94.7695 87.645 95.2172 87.645 95.7695Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M93.645 95.7695C93.645 96.3218 93.1973 96.7695 92.645 96.7695C92.0927 96.7695 91.645 96.3218 91.645 95.7695C91.645 95.2172 92.0927 94.7695 92.645 94.7695C93.1973 94.7695 93.645 95.2172 93.645 95.7695Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M99.645 95.7695C99.645 96.3218 99.1973 96.7695 98.645 96.7695C98.0927 96.7695 97.645 96.3218 97.645 95.7695C97.645 95.2172 98.0927 94.7695 98.645 94.7695C99.1973 94.7695 99.645 95.2172 99.645 95.7695Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M105.645 95.7695C105.645 96.3218 105.197 96.7695 104.645 96.7695C104.093 96.7695 103.645 96.3218 103.645 95.7695C103.645 95.2172 104.093 94.7695 104.645 94.7695C105.197 94.7695 105.645 95.2172 105.645 95.7695Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M111.645 95.7695C111.645 96.3218 111.197 96.7695 110.645 96.7695C110.093 96.7695 109.645 96.3218 109.645 95.7695C109.645 95.2172 110.093 94.7695 110.645 94.7695C111.197 94.7695 111.645 95.2172 111.645 95.7695Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M117.645 95.7695C117.645 96.3218 117.197 96.7695 116.645 96.7695C116.093 96.7695 115.645 96.3218 115.645 95.7695C115.645 95.2172 116.093 94.7695 116.645 94.7695C117.197 94.7695 117.645 95.2172 117.645 95.7695Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M123.645 95.7695C123.645 96.3218 123.197 96.7695 122.645 96.7695C122.093 96.7695 121.645 96.3218 121.645 95.7695C121.645 95.2172 122.093 94.7695 122.645 94.7695C123.197 94.7695 123.645 95.2172 123.645 95.7695Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M129.645 95.7695C129.645 96.3218 129.197 96.7695 128.645 96.7695C128.093 96.7695 127.645 96.3218 127.645 95.7695C127.645 95.2172 128.093 94.7695 128.645 94.7695C129.197 94.7695 129.645 95.2172 129.645 95.7695Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M135.645 95.7695C135.645 96.3218 135.197 96.7695 134.645 96.7695C134.093 96.7695 133.645 96.3218 133.645 95.7695C133.645 95.2172 134.093 94.7695 134.645 94.7695C135.197 94.7695 135.645 95.2172 135.645 95.7695Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M141.645 95.7695C141.645 96.3218 141.197 96.7695 140.645 96.7695C140.093 96.7695 139.645 96.3218 139.645 95.7695C139.645 95.2172 140.093 94.7695 140.645 94.7695C141.197 94.7695 141.645 95.2172 141.645 95.7695Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M147.645 95.7695C147.645 96.3218 147.197 96.7695 146.645 96.7695C146.093 96.7695 145.645 96.3218 145.645 95.7695C145.645 95.2172 146.093 94.7695 146.645 94.7695C147.197 94.7695 147.645 95.2172 147.645 95.7695Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='152.645'
              cy='95.7695'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='158.645'
              cy='95.7695'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='164.645'
              cy='95.7695'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='170.645'
              cy='95.7695'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='176.645'
              cy='95.7695'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='182.645'
              cy='95.7695'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='188.645'
              cy='95.7695'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='194.645'
              cy='95.7695'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='200.645'
              cy='95.7695'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='206.645'
              cy='95.7695'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='212.645'
              cy='95.7695'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='218.645'
              cy='95.7695'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='224.645'
              cy='95.7695'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='230.645'
              cy='95.7695'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='236.645'
              cy='95.7695'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='242.645'
              cy='95.7695'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='248.645'
              cy='95.7695'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='2.64502'
              cy='241.57'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='8.64502'
              cy='241.57'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='14.645'
              cy='241.57'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='20.645'
              cy='241.57'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='26.645'
              cy='241.57'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M33.645 241.57C33.645 242.123 33.1973 242.57 32.645 242.57C32.0927 242.57 31.645 242.123 31.645 241.57C31.645 241.018 32.0927 240.57 32.645 240.57C33.1973 240.57 33.645 241.018 33.645 241.57Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M39.645 241.57C39.645 242.123 39.1973 242.57 38.645 242.57C38.0927 242.57 37.645 242.123 37.645 241.57C37.645 241.018 38.0927 240.57 38.645 240.57C39.1973 240.57 39.645 241.018 39.645 241.57Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M45.645 241.57C45.645 242.123 45.1973 242.57 44.645 242.57C44.0927 242.57 43.645 242.123 43.645 241.57C43.645 241.018 44.0927 240.57 44.645 240.57C45.1973 240.57 45.645 241.018 45.645 241.57Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M51.645 241.57C51.645 242.123 51.1973 242.57 50.645 242.57C50.0927 242.57 49.645 242.123 49.645 241.57C49.645 241.018 50.0927 240.57 50.645 240.57C51.1973 240.57 51.645 241.018 51.645 241.57Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M57.645 241.57C57.645 242.123 57.1973 242.57 56.645 242.57C56.0927 242.57 55.645 242.123 55.645 241.57C55.645 241.018 56.0927 240.57 56.645 240.57C57.1973 240.57 57.645 241.018 57.645 241.57Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M63.645 241.57C63.645 242.123 63.1973 242.57 62.645 242.57C62.0927 242.57 61.645 242.123 61.645 241.57C61.645 241.018 62.0927 240.57 62.645 240.57C63.1973 240.57 63.645 241.018 63.645 241.57Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M69.645 241.57C69.645 242.123 69.1973 242.57 68.645 242.57C68.0927 242.57 67.645 242.123 67.645 241.57C67.645 241.018 68.0927 240.57 68.645 240.57C69.1973 240.57 69.645 241.018 69.645 241.57Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M75.645 241.57C75.645 242.123 75.1973 242.57 74.645 242.57C74.0927 242.57 73.645 242.123 73.645 241.57C73.645 241.018 74.0927 240.57 74.645 240.57C75.1973 240.57 75.645 241.018 75.645 241.57Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M81.645 241.57C81.645 242.123 81.1973 242.57 80.645 242.57C80.0927 242.57 79.645 242.123 79.645 241.57C79.645 241.018 80.0927 240.57 80.645 240.57C81.1973 240.57 81.645 241.018 81.645 241.57Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M87.645 241.57C87.645 242.123 87.1973 242.57 86.645 242.57C86.0927 242.57 85.645 242.123 85.645 241.57C85.645 241.018 86.0927 240.57 86.645 240.57C87.1973 240.57 87.645 241.018 87.645 241.57Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M93.645 241.57C93.645 242.123 93.1973 242.57 92.645 242.57C92.0927 242.57 91.645 242.123 91.645 241.57C91.645 241.018 92.0927 240.57 92.645 240.57C93.1973 240.57 93.645 241.018 93.645 241.57Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M99.645 241.57C99.645 242.123 99.1973 242.57 98.645 242.57C98.0927 242.57 97.645 242.123 97.645 241.57C97.645 241.018 98.0927 240.57 98.645 240.57C99.1973 240.57 99.645 241.018 99.645 241.57Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M105.645 241.57C105.645 242.123 105.197 242.57 104.645 242.57C104.093 242.57 103.645 242.123 103.645 241.57C103.645 241.018 104.093 240.57 104.645 240.57C105.197 240.57 105.645 241.018 105.645 241.57Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M111.645 241.57C111.645 242.123 111.197 242.57 110.645 242.57C110.093 242.57 109.645 242.123 109.645 241.57C109.645 241.018 110.093 240.57 110.645 240.57C111.197 240.57 111.645 241.018 111.645 241.57Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M117.645 241.57C117.645 242.123 117.197 242.57 116.645 242.57C116.093 242.57 115.645 242.123 115.645 241.57C115.645 241.018 116.093 240.57 116.645 240.57C117.197 240.57 117.645 241.018 117.645 241.57Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M123.645 241.57C123.645 242.123 123.197 242.57 122.645 242.57C122.093 242.57 121.645 242.123 121.645 241.57C121.645 241.018 122.093 240.57 122.645 240.57C123.197 240.57 123.645 241.018 123.645 241.57Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M129.645 241.57C129.645 242.123 129.197 242.57 128.645 242.57C128.093 242.57 127.645 242.123 127.645 241.57C127.645 241.018 128.093 240.57 128.645 240.57C129.197 240.57 129.645 241.018 129.645 241.57Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M135.645 241.57C135.645 242.123 135.197 242.57 134.645 242.57C134.093 242.57 133.645 242.123 133.645 241.57C133.645 241.018 134.093 240.57 134.645 240.57C135.197 240.57 135.645 241.018 135.645 241.57Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M141.645 241.57C141.645 242.123 141.197 242.57 140.645 242.57C140.093 242.57 139.645 242.123 139.645 241.57C139.645 241.018 140.093 240.57 140.645 240.57C141.197 240.57 141.645 241.018 141.645 241.57Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M147.645 241.57C147.645 242.123 147.197 242.57 146.645 242.57C146.093 242.57 145.645 242.123 145.645 241.57C145.645 241.018 146.093 240.57 146.645 240.57C147.197 240.57 147.645 241.018 147.645 241.57Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='152.645'
              cy='241.57'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='158.645'
              cy='241.57'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='164.645'
              cy='241.57'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='170.645'
              cy='241.57'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='176.645'
              cy='241.57'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='182.645'
              cy='241.57'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='188.645'
              cy='241.57'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='194.645'
              cy='241.57'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='200.645'
              cy='241.57'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='206.645'
              cy='241.57'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='212.645'
              cy='241.57'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='218.645'
              cy='241.57'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='224.645'
              cy='241.57'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='230.645'
              cy='241.57'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='236.645'
              cy='241.57'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='242.645'
              cy='241.57'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='248.645'
              cy='241.57'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='2.64502'
              cy='124.93'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='8.64502'
              cy='124.93'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='14.645'
              cy='124.93'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='20.645'
              cy='124.93'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='26.645'
              cy='124.93'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M33.645 124.93C33.645 125.482 33.1973 125.93 32.645 125.93C32.0927 125.93 31.645 125.482 31.645 124.93C31.645 124.377 32.0927 123.93 32.645 123.93C33.1973 123.93 33.645 124.377 33.645 124.93Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M39.645 124.93C39.645 125.482 39.1973 125.93 38.645 125.93C38.0927 125.93 37.645 125.482 37.645 124.93C37.645 124.377 38.0927 123.93 38.645 123.93C39.1973 123.93 39.645 124.377 39.645 124.93Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M45.645 124.93C45.645 125.482 45.1973 125.93 44.645 125.93C44.0927 125.93 43.645 125.482 43.645 124.93C43.645 124.377 44.0927 123.93 44.645 123.93C45.1973 123.93 45.645 124.377 45.645 124.93Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M51.645 124.93C51.645 125.482 51.1973 125.93 50.645 125.93C50.0927 125.93 49.645 125.482 49.645 124.93C49.645 124.377 50.0927 123.93 50.645 123.93C51.1973 123.93 51.645 124.377 51.645 124.93Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M57.645 124.93C57.645 125.482 57.1973 125.93 56.645 125.93C56.0927 125.93 55.645 125.482 55.645 124.93C55.645 124.377 56.0927 123.93 56.645 123.93C57.1973 123.93 57.645 124.377 57.645 124.93Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M63.645 124.93C63.645 125.482 63.1973 125.93 62.645 125.93C62.0927 125.93 61.645 125.482 61.645 124.93C61.645 124.377 62.0927 123.93 62.645 123.93C63.1973 123.93 63.645 124.377 63.645 124.93Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M69.645 124.93C69.645 125.482 69.1973 125.93 68.645 125.93C68.0927 125.93 67.645 125.482 67.645 124.93C67.645 124.377 68.0927 123.93 68.645 123.93C69.1973 123.93 69.645 124.377 69.645 124.93Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M75.645 124.93C75.645 125.482 75.1973 125.93 74.645 125.93C74.0927 125.93 73.645 125.482 73.645 124.93C73.645 124.377 74.0927 123.93 74.645 123.93C75.1973 123.93 75.645 124.377 75.645 124.93Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M81.645 124.93C81.645 125.482 81.1973 125.93 80.645 125.93C80.0927 125.93 79.645 125.482 79.645 124.93C79.645 124.377 80.0927 123.93 80.645 123.93C81.1973 123.93 81.645 124.377 81.645 124.93Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M87.645 124.93C87.645 125.482 87.1973 125.93 86.645 125.93C86.0927 125.93 85.645 125.482 85.645 124.93C85.645 124.377 86.0927 123.93 86.645 123.93C87.1973 123.93 87.645 124.377 87.645 124.93Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M93.645 124.93C93.645 125.482 93.1973 125.93 92.645 125.93C92.0927 125.93 91.645 125.482 91.645 124.93C91.645 124.377 92.0927 123.93 92.645 123.93C93.1973 123.93 93.645 124.377 93.645 124.93Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M99.645 124.93C99.645 125.482 99.1973 125.93 98.645 125.93C98.0927 125.93 97.645 125.482 97.645 124.93C97.645 124.377 98.0927 123.93 98.645 123.93C99.1973 123.93 99.645 124.377 99.645 124.93Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M105.645 124.93C105.645 125.482 105.197 125.93 104.645 125.93C104.093 125.93 103.645 125.482 103.645 124.93C103.645 124.377 104.093 123.93 104.645 123.93C105.197 123.93 105.645 124.377 105.645 124.93Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M111.645 124.93C111.645 125.482 111.197 125.93 110.645 125.93C110.093 125.93 109.645 125.482 109.645 124.93C109.645 124.377 110.093 123.93 110.645 123.93C111.197 123.93 111.645 124.377 111.645 124.93Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M117.645 124.93C117.645 125.482 117.197 125.93 116.645 125.93C116.093 125.93 115.645 125.482 115.645 124.93C115.645 124.377 116.093 123.93 116.645 123.93C117.197 123.93 117.645 124.377 117.645 124.93Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M123.645 124.93C123.645 125.482 123.197 125.93 122.645 125.93C122.093 125.93 121.645 125.482 121.645 124.93C121.645 124.377 122.093 123.93 122.645 123.93C123.197 123.93 123.645 124.377 123.645 124.93Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M129.645 124.93C129.645 125.482 129.197 125.93 128.645 125.93C128.093 125.93 127.645 125.482 127.645 124.93C127.645 124.377 128.093 123.93 128.645 123.93C129.197 123.93 129.645 124.377 129.645 124.93Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M135.645 124.93C135.645 125.482 135.197 125.93 134.645 125.93C134.093 125.93 133.645 125.482 133.645 124.93C133.645 124.377 134.093 123.93 134.645 123.93C135.197 123.93 135.645 124.377 135.645 124.93Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M141.645 124.93C141.645 125.482 141.197 125.93 140.645 125.93C140.093 125.93 139.645 125.482 139.645 124.93C139.645 124.377 140.093 123.93 140.645 123.93C141.197 123.93 141.645 124.377 141.645 124.93Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M147.645 124.93C147.645 125.482 147.197 125.93 146.645 125.93C146.093 125.93 145.645 125.482 145.645 124.93C145.645 124.377 146.093 123.93 146.645 123.93C147.197 123.93 147.645 124.377 147.645 124.93Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='152.645'
              cy='124.93'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='158.645'
              cy='124.93'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='164.645'
              cy='124.93'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='170.645'
              cy='124.93'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='176.645'
              cy='124.93'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='182.645'
              cy='124.93'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='188.645'
              cy='124.93'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='194.645'
              cy='124.93'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='200.645'
              cy='124.93'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='206.645'
              cy='124.93'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='212.645'
              cy='124.93'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='218.645'
              cy='124.93'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='224.645'
              cy='124.93'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='230.645'
              cy='124.93'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='236.645'
              cy='124.93'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='242.645'
              cy='124.93'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='248.645'
              cy='124.93'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='2.64502'
              cy='22.8701'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='8.64502'
              cy='22.8701'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='14.645'
              cy='22.8701'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='20.645'
              cy='22.8701'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='26.645'
              cy='22.8701'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M33.645 22.8701C33.645 23.4224 33.1973 23.8701 32.645 23.8701C32.0927 23.8701 31.645 23.4224 31.645 22.8701C31.645 22.3178 32.0927 21.8701 32.645 21.8701C33.1973 21.8701 33.645 22.3178 33.645 22.8701Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M39.645 22.8701C39.645 23.4224 39.1973 23.8701 38.645 23.8701C38.0927 23.8701 37.645 23.4224 37.645 22.8701C37.645 22.3178 38.0927 21.8701 38.645 21.8701C39.1973 21.8701 39.645 22.3178 39.645 22.8701Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M45.645 22.8701C45.645 23.4224 45.1973 23.8701 44.645 23.8701C44.0927 23.8701 43.645 23.4224 43.645 22.8701C43.645 22.3178 44.0927 21.8701 44.645 21.8701C45.1973 21.8701 45.645 22.3178 45.645 22.8701Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M51.645 22.8701C51.645 23.4224 51.1973 23.8701 50.645 23.8701C50.0927 23.8701 49.645 23.4224 49.645 22.8701C49.645 22.3178 50.0927 21.8701 50.645 21.8701C51.1973 21.8701 51.645 22.3178 51.645 22.8701Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M57.645 22.8701C57.645 23.4224 57.1973 23.8701 56.645 23.8701C56.0927 23.8701 55.645 23.4224 55.645 22.8701C55.645 22.3178 56.0927 21.8701 56.645 21.8701C57.1973 21.8701 57.645 22.3178 57.645 22.8701Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M63.645 22.8701C63.645 23.4224 63.1973 23.8701 62.645 23.8701C62.0927 23.8701 61.645 23.4224 61.645 22.8701C61.645 22.3178 62.0927 21.8701 62.645 21.8701C63.1973 21.8701 63.645 22.3178 63.645 22.8701Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M69.645 22.8701C69.645 23.4224 69.1973 23.8701 68.645 23.8701C68.0927 23.8701 67.645 23.4224 67.645 22.8701C67.645 22.3178 68.0927 21.8701 68.645 21.8701C69.1973 21.8701 69.645 22.3178 69.645 22.8701Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M75.645 22.8701C75.645 23.4224 75.1973 23.8701 74.645 23.8701C74.0927 23.8701 73.645 23.4224 73.645 22.8701C73.645 22.3178 74.0927 21.8701 74.645 21.8701C75.1973 21.8701 75.645 22.3178 75.645 22.8701Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M81.645 22.8701C81.645 23.4224 81.1973 23.8701 80.645 23.8701C80.0927 23.8701 79.645 23.4224 79.645 22.8701C79.645 22.3178 80.0927 21.8701 80.645 21.8701C81.1973 21.8701 81.645 22.3178 81.645 22.8701Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M87.645 22.8701C87.645 23.4224 87.1973 23.8701 86.645 23.8701C86.0927 23.8701 85.645 23.4224 85.645 22.8701C85.645 22.3178 86.0927 21.8701 86.645 21.8701C87.1973 21.8701 87.645 22.3178 87.645 22.8701Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M93.645 22.8701C93.645 23.4224 93.1973 23.8701 92.645 23.8701C92.0927 23.8701 91.645 23.4224 91.645 22.8701C91.645 22.3178 92.0927 21.8701 92.645 21.8701C93.1973 21.8701 93.645 22.3178 93.645 22.8701Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M99.645 22.8701C99.645 23.4224 99.1973 23.8701 98.645 23.8701C98.0927 23.8701 97.645 23.4224 97.645 22.8701C97.645 22.3178 98.0927 21.8701 98.645 21.8701C99.1973 21.8701 99.645 22.3178 99.645 22.8701Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M105.645 22.8701C105.645 23.4224 105.197 23.8701 104.645 23.8701C104.093 23.8701 103.645 23.4224 103.645 22.8701C103.645 22.3178 104.093 21.8701 104.645 21.8701C105.197 21.8701 105.645 22.3178 105.645 22.8701Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M111.645 22.8701C111.645 23.4224 111.197 23.8701 110.645 23.8701C110.093 23.8701 109.645 23.4224 109.645 22.8701C109.645 22.3178 110.093 21.8701 110.645 21.8701C111.197 21.8701 111.645 22.3178 111.645 22.8701Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M117.645 22.8701C117.645 23.4224 117.197 23.8701 116.645 23.8701C116.093 23.8701 115.645 23.4224 115.645 22.8701C115.645 22.3178 116.093 21.8701 116.645 21.8701C117.197 21.8701 117.645 22.3178 117.645 22.8701Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M123.645 22.8701C123.645 23.4224 123.197 23.8701 122.645 23.8701C122.093 23.8701 121.645 23.4224 121.645 22.8701C121.645 22.3178 122.093 21.8701 122.645 21.8701C123.197 21.8701 123.645 22.3178 123.645 22.8701Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M129.645 22.8701C129.645 23.4224 129.197 23.8701 128.645 23.8701C128.093 23.8701 127.645 23.4224 127.645 22.8701C127.645 22.3178 128.093 21.8701 128.645 21.8701C129.197 21.8701 129.645 22.3178 129.645 22.8701Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M135.645 22.8701C135.645 23.4224 135.197 23.8701 134.645 23.8701C134.093 23.8701 133.645 23.4224 133.645 22.8701C133.645 22.3178 134.093 21.8701 134.645 21.8701C135.197 21.8701 135.645 22.3178 135.645 22.8701Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M141.645 22.8701C141.645 23.4224 141.197 23.8701 140.645 23.8701C140.093 23.8701 139.645 23.4224 139.645 22.8701C139.645 22.3178 140.093 21.8701 140.645 21.8701C141.197 21.8701 141.645 22.3178 141.645 22.8701Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M147.645 22.8701C147.645 23.4224 147.197 23.8701 146.645 23.8701C146.093 23.8701 145.645 23.4224 145.645 22.8701C145.645 22.3178 146.093 21.8701 146.645 21.8701C147.197 21.8701 147.645 22.3178 147.645 22.8701Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='152.645'
              cy='22.8701'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='158.645'
              cy='22.8701'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='164.645'
              cy='22.8701'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='170.645'
              cy='22.8701'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='176.645'
              cy='22.8701'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='182.645'
              cy='22.8701'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='188.645'
              cy='22.8701'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='194.645'
              cy='22.8701'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='200.645'
              cy='22.8701'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='206.645'
              cy='22.8701'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='212.645'
              cy='22.8701'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='218.645'
              cy='22.8701'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='224.645'
              cy='22.8701'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='230.645'
              cy='22.8701'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='236.645'
              cy='22.8701'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='242.645'
              cy='22.8701'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='248.645'
              cy='22.8701'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='2.64502'
              cy='168.67'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='8.64502'
              cy='168.67'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='14.645'
              cy='168.67'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='20.645'
              cy='168.67'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='26.645'
              cy='168.67'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M33.645 168.67C33.645 169.222 33.1973 169.67 32.645 169.67C32.0927 169.67 31.645 169.222 31.645 168.67C31.645 168.118 32.0927 167.67 32.645 167.67C33.1973 167.67 33.645 168.118 33.645 168.67Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M39.645 168.67C39.645 169.222 39.1973 169.67 38.645 169.67C38.0927 169.67 37.645 169.222 37.645 168.67C37.645 168.118 38.0927 167.67 38.645 167.67C39.1973 167.67 39.645 168.118 39.645 168.67Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M45.645 168.67C45.645 169.222 45.1973 169.67 44.645 169.67C44.0927 169.67 43.645 169.222 43.645 168.67C43.645 168.118 44.0927 167.67 44.645 167.67C45.1973 167.67 45.645 168.118 45.645 168.67Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M51.645 168.67C51.645 169.222 51.1973 169.67 50.645 169.67C50.0927 169.67 49.645 169.222 49.645 168.67C49.645 168.118 50.0927 167.67 50.645 167.67C51.1973 167.67 51.645 168.118 51.645 168.67Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M57.645 168.67C57.645 169.222 57.1973 169.67 56.645 169.67C56.0927 169.67 55.645 169.222 55.645 168.67C55.645 168.118 56.0927 167.67 56.645 167.67C57.1973 167.67 57.645 168.118 57.645 168.67Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M63.645 168.67C63.645 169.222 63.1973 169.67 62.645 169.67C62.0927 169.67 61.645 169.222 61.645 168.67C61.645 168.118 62.0927 167.67 62.645 167.67C63.1973 167.67 63.645 168.118 63.645 168.67Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M69.645 168.67C69.645 169.222 69.1973 169.67 68.645 169.67C68.0927 169.67 67.645 169.222 67.645 168.67C67.645 168.118 68.0927 167.67 68.645 167.67C69.1973 167.67 69.645 168.118 69.645 168.67Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M75.645 168.67C75.645 169.222 75.1973 169.67 74.645 169.67C74.0927 169.67 73.645 169.222 73.645 168.67C73.645 168.118 74.0927 167.67 74.645 167.67C75.1973 167.67 75.645 168.118 75.645 168.67Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M81.645 168.67C81.645 169.222 81.1973 169.67 80.645 169.67C80.0927 169.67 79.645 169.222 79.645 168.67C79.645 168.118 80.0927 167.67 80.645 167.67C81.1973 167.67 81.645 168.118 81.645 168.67Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M87.645 168.67C87.645 169.222 87.1973 169.67 86.645 169.67C86.0927 169.67 85.645 169.222 85.645 168.67C85.645 168.118 86.0927 167.67 86.645 167.67C87.1973 167.67 87.645 168.118 87.645 168.67Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M93.645 168.67C93.645 169.222 93.1973 169.67 92.645 169.67C92.0927 169.67 91.645 169.222 91.645 168.67C91.645 168.118 92.0927 167.67 92.645 167.67C93.1973 167.67 93.645 168.118 93.645 168.67Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M99.645 168.67C99.645 169.222 99.1973 169.67 98.645 169.67C98.0927 169.67 97.645 169.222 97.645 168.67C97.645 168.118 98.0927 167.67 98.645 167.67C99.1973 167.67 99.645 168.118 99.645 168.67Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M105.645 168.67C105.645 169.222 105.197 169.67 104.645 169.67C104.093 169.67 103.645 169.222 103.645 168.67C103.645 168.118 104.093 167.67 104.645 167.67C105.197 167.67 105.645 168.118 105.645 168.67Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M111.645 168.67C111.645 169.222 111.197 169.67 110.645 169.67C110.093 169.67 109.645 169.222 109.645 168.67C109.645 168.118 110.093 167.67 110.645 167.67C111.197 167.67 111.645 168.118 111.645 168.67Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M117.645 168.67C117.645 169.222 117.197 169.67 116.645 169.67C116.093 169.67 115.645 169.222 115.645 168.67C115.645 168.118 116.093 167.67 116.645 167.67C117.197 167.67 117.645 168.118 117.645 168.67Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M123.645 168.67C123.645 169.222 123.197 169.67 122.645 169.67C122.093 169.67 121.645 169.222 121.645 168.67C121.645 168.118 122.093 167.67 122.645 167.67C123.197 167.67 123.645 168.118 123.645 168.67Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M129.645 168.67C129.645 169.222 129.197 169.67 128.645 169.67C128.093 169.67 127.645 169.222 127.645 168.67C127.645 168.118 128.093 167.67 128.645 167.67C129.197 167.67 129.645 168.118 129.645 168.67Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M135.645 168.67C135.645 169.222 135.197 169.67 134.645 169.67C134.093 169.67 133.645 169.222 133.645 168.67C133.645 168.118 134.093 167.67 134.645 167.67C135.197 167.67 135.645 168.118 135.645 168.67Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M141.645 168.67C141.645 169.222 141.197 169.67 140.645 169.67C140.093 169.67 139.645 169.222 139.645 168.67C139.645 168.118 140.093 167.67 140.645 167.67C141.197 167.67 141.645 168.118 141.645 168.67Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M147.645 168.67C147.645 169.222 147.197 169.67 146.645 169.67C146.093 169.67 145.645 169.222 145.645 168.67C145.645 168.118 146.093 167.67 146.645 167.67C147.197 167.67 147.645 168.118 147.645 168.67Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='152.645'
              cy='168.67'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='158.645'
              cy='168.67'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='164.645'
              cy='168.67'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='170.645'
              cy='168.67'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='176.645'
              cy='168.67'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='182.645'
              cy='168.67'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='188.645'
              cy='168.67'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='194.645'
              cy='168.67'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='200.645'
              cy='168.67'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='206.645'
              cy='168.67'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='212.645'
              cy='168.67'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='218.645'
              cy='168.67'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='224.645'
              cy='168.67'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='230.645'
              cy='168.67'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='236.645'
              cy='168.67'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='242.645'
              cy='168.67'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='248.645'
              cy='168.67'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='2.64502'
              cy='52.0303'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='8.64502'
              cy='52.0303'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='14.645'
              cy='52.0303'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='20.645'
              cy='52.0303'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='26.645'
              cy='52.0303'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M33.645 52.0303C33.645 52.5826 33.1973 53.0303 32.645 53.0303C32.0927 53.0303 31.645 52.5826 31.645 52.0303C31.645 51.478 32.0927 51.0303 32.645 51.0303C33.1973 51.0303 33.645 51.478 33.645 52.0303Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M39.645 52.0303C39.645 52.5826 39.1973 53.0303 38.645 53.0303C38.0927 53.0303 37.645 52.5826 37.645 52.0303C37.645 51.478 38.0927 51.0303 38.645 51.0303C39.1973 51.0303 39.645 51.478 39.645 52.0303Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M45.645 52.0303C45.645 52.5826 45.1973 53.0303 44.645 53.0303C44.0927 53.0303 43.645 52.5826 43.645 52.0303C43.645 51.478 44.0927 51.0303 44.645 51.0303C45.1973 51.0303 45.645 51.478 45.645 52.0303Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M51.645 52.0303C51.645 52.5826 51.1973 53.0303 50.645 53.0303C50.0927 53.0303 49.645 52.5826 49.645 52.0303C49.645 51.478 50.0927 51.0303 50.645 51.0303C51.1973 51.0303 51.645 51.478 51.645 52.0303Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M57.645 52.0303C57.645 52.5826 57.1973 53.0303 56.645 53.0303C56.0927 53.0303 55.645 52.5826 55.645 52.0303C55.645 51.478 56.0927 51.0303 56.645 51.0303C57.1973 51.0303 57.645 51.478 57.645 52.0303Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M63.645 52.0303C63.645 52.5826 63.1973 53.0303 62.645 53.0303C62.0927 53.0303 61.645 52.5826 61.645 52.0303C61.645 51.478 62.0927 51.0303 62.645 51.0303C63.1973 51.0303 63.645 51.478 63.645 52.0303Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M69.645 52.0303C69.645 52.5826 69.1973 53.0303 68.645 53.0303C68.0927 53.0303 67.645 52.5826 67.645 52.0303C67.645 51.478 68.0927 51.0303 68.645 51.0303C69.1973 51.0303 69.645 51.478 69.645 52.0303Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M75.645 52.0303C75.645 52.5826 75.1973 53.0303 74.645 53.0303C74.0927 53.0303 73.645 52.5826 73.645 52.0303C73.645 51.478 74.0927 51.0303 74.645 51.0303C75.1973 51.0303 75.645 51.478 75.645 52.0303Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M81.645 52.0303C81.645 52.5826 81.1973 53.0303 80.645 53.0303C80.0927 53.0303 79.645 52.5826 79.645 52.0303C79.645 51.478 80.0927 51.0303 80.645 51.0303C81.1973 51.0303 81.645 51.478 81.645 52.0303Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M87.645 52.0303C87.645 52.5826 87.1973 53.0303 86.645 53.0303C86.0927 53.0303 85.645 52.5826 85.645 52.0303C85.645 51.478 86.0927 51.0303 86.645 51.0303C87.1973 51.0303 87.645 51.478 87.645 52.0303Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M93.645 52.0303C93.645 52.5826 93.1973 53.0303 92.645 53.0303C92.0927 53.0303 91.645 52.5826 91.645 52.0303C91.645 51.478 92.0927 51.0303 92.645 51.0303C93.1973 51.0303 93.645 51.478 93.645 52.0303Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M99.645 52.0303C99.645 52.5826 99.1973 53.0303 98.645 53.0303C98.0927 53.0303 97.645 52.5826 97.645 52.0303C97.645 51.478 98.0927 51.0303 98.645 51.0303C99.1973 51.0303 99.645 51.478 99.645 52.0303Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M105.645 52.0303C105.645 52.5826 105.197 53.0303 104.645 53.0303C104.093 53.0303 103.645 52.5826 103.645 52.0303C103.645 51.478 104.093 51.0303 104.645 51.0303C105.197 51.0303 105.645 51.478 105.645 52.0303Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M111.645 52.0303C111.645 52.5826 111.197 53.0303 110.645 53.0303C110.093 53.0303 109.645 52.5826 109.645 52.0303C109.645 51.478 110.093 51.0303 110.645 51.0303C111.197 51.0303 111.645 51.478 111.645 52.0303Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M117.645 52.0303C117.645 52.5826 117.197 53.0303 116.645 53.0303C116.093 53.0303 115.645 52.5826 115.645 52.0303C115.645 51.478 116.093 51.0303 116.645 51.0303C117.197 51.0303 117.645 51.478 117.645 52.0303Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M123.645 52.0303C123.645 52.5826 123.197 53.0303 122.645 53.0303C122.093 53.0303 121.645 52.5826 121.645 52.0303C121.645 51.478 122.093 51.0303 122.645 51.0303C123.197 51.0303 123.645 51.478 123.645 52.0303Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M129.645 52.0303C129.645 52.5826 129.197 53.0303 128.645 53.0303C128.093 53.0303 127.645 52.5826 127.645 52.0303C127.645 51.478 128.093 51.0303 128.645 51.0303C129.197 51.0303 129.645 51.478 129.645 52.0303Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M135.645 52.0303C135.645 52.5826 135.197 53.0303 134.645 53.0303C134.093 53.0303 133.645 52.5826 133.645 52.0303C133.645 51.478 134.093 51.0303 134.645 51.0303C135.197 51.0303 135.645 51.478 135.645 52.0303Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M141.645 52.0303C141.645 52.5826 141.197 53.0303 140.645 53.0303C140.093 53.0303 139.645 52.5826 139.645 52.0303C139.645 51.478 140.093 51.0303 140.645 51.0303C141.197 51.0303 141.645 51.478 141.645 52.0303Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M147.645 52.0303C147.645 52.5826 147.197 53.0303 146.645 53.0303C146.093 53.0303 145.645 52.5826 145.645 52.0303C145.645 51.478 146.093 51.0303 146.645 51.0303C147.197 51.0303 147.645 51.478 147.645 52.0303Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='152.645'
              cy='52.0303'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='158.645'
              cy='52.0303'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='164.645'
              cy='52.0303'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='170.645'
              cy='52.0303'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='176.645'
              cy='52.0303'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='182.645'
              cy='52.0303'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='188.645'
              cy='52.0303'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='194.645'
              cy='52.0303'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='200.645'
              cy='52.0303'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='206.645'
              cy='52.0303'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='212.645'
              cy='52.0303'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='218.645'
              cy='52.0303'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='224.645'
              cy='52.0303'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='230.645'
              cy='52.0303'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='236.645'
              cy='52.0303'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='242.645'
              cy='52.0303'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='248.645'
              cy='52.0303'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='2.64502'
              cy='197.83'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='8.64502'
              cy='197.83'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='14.645'
              cy='197.83'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='20.645'
              cy='197.83'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='26.645'
              cy='197.83'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M33.645 197.83C33.645 198.382 33.1973 198.83 32.645 198.83C32.0927 198.83 31.645 198.382 31.645 197.83C31.645 197.278 32.0927 196.83 32.645 196.83C33.1973 196.83 33.645 197.278 33.645 197.83Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M39.645 197.83C39.645 198.382 39.1973 198.83 38.645 198.83C38.0927 198.83 37.645 198.382 37.645 197.83C37.645 197.278 38.0927 196.83 38.645 196.83C39.1973 196.83 39.645 197.278 39.645 197.83Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M45.645 197.83C45.645 198.382 45.1973 198.83 44.645 198.83C44.0927 198.83 43.645 198.382 43.645 197.83C43.645 197.278 44.0927 196.83 44.645 196.83C45.1973 196.83 45.645 197.278 45.645 197.83Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M51.645 197.83C51.645 198.382 51.1973 198.83 50.645 198.83C50.0927 198.83 49.645 198.382 49.645 197.83C49.645 197.278 50.0927 196.83 50.645 196.83C51.1973 196.83 51.645 197.278 51.645 197.83Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M57.645 197.83C57.645 198.382 57.1973 198.83 56.645 198.83C56.0927 198.83 55.645 198.382 55.645 197.83C55.645 197.278 56.0927 196.83 56.645 196.83C57.1973 196.83 57.645 197.278 57.645 197.83Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M63.645 197.83C63.645 198.382 63.1973 198.83 62.645 198.83C62.0927 198.83 61.645 198.382 61.645 197.83C61.645 197.278 62.0927 196.83 62.645 196.83C63.1973 196.83 63.645 197.278 63.645 197.83Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M69.645 197.83C69.645 198.382 69.1973 198.83 68.645 198.83C68.0927 198.83 67.645 198.382 67.645 197.83C67.645 197.278 68.0927 196.83 68.645 196.83C69.1973 196.83 69.645 197.278 69.645 197.83Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M75.645 197.83C75.645 198.382 75.1973 198.83 74.645 198.83C74.0927 198.83 73.645 198.382 73.645 197.83C73.645 197.278 74.0927 196.83 74.645 196.83C75.1973 196.83 75.645 197.278 75.645 197.83Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M81.645 197.83C81.645 198.382 81.1973 198.83 80.645 198.83C80.0927 198.83 79.645 198.382 79.645 197.83C79.645 197.278 80.0927 196.83 80.645 196.83C81.1973 196.83 81.645 197.278 81.645 197.83Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M87.645 197.83C87.645 198.382 87.1973 198.83 86.645 198.83C86.0927 198.83 85.645 198.382 85.645 197.83C85.645 197.278 86.0927 196.83 86.645 196.83C87.1973 196.83 87.645 197.278 87.645 197.83Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M93.645 197.83C93.645 198.382 93.1973 198.83 92.645 198.83C92.0927 198.83 91.645 198.382 91.645 197.83C91.645 197.278 92.0927 196.83 92.645 196.83C93.1973 196.83 93.645 197.278 93.645 197.83Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M99.645 197.83C99.645 198.382 99.1973 198.83 98.645 198.83C98.0927 198.83 97.645 198.382 97.645 197.83C97.645 197.278 98.0927 196.83 98.645 196.83C99.1973 196.83 99.645 197.278 99.645 197.83Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M105.645 197.83C105.645 198.382 105.197 198.83 104.645 198.83C104.093 198.83 103.645 198.382 103.645 197.83C103.645 197.278 104.093 196.83 104.645 196.83C105.197 196.83 105.645 197.278 105.645 197.83Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M111.645 197.83C111.645 198.382 111.197 198.83 110.645 198.83C110.093 198.83 109.645 198.382 109.645 197.83C109.645 197.278 110.093 196.83 110.645 196.83C111.197 196.83 111.645 197.278 111.645 197.83Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M117.645 197.83C117.645 198.382 117.197 198.83 116.645 198.83C116.093 198.83 115.645 198.382 115.645 197.83C115.645 197.278 116.093 196.83 116.645 196.83C117.197 196.83 117.645 197.278 117.645 197.83Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M123.645 197.83C123.645 198.382 123.197 198.83 122.645 198.83C122.093 198.83 121.645 198.382 121.645 197.83C121.645 197.278 122.093 196.83 122.645 196.83C123.197 196.83 123.645 197.278 123.645 197.83Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M129.645 197.83C129.645 198.382 129.197 198.83 128.645 198.83C128.093 198.83 127.645 198.382 127.645 197.83C127.645 197.278 128.093 196.83 128.645 196.83C129.197 196.83 129.645 197.278 129.645 197.83Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M135.645 197.83C135.645 198.382 135.197 198.83 134.645 198.83C134.093 198.83 133.645 198.382 133.645 197.83C133.645 197.278 134.093 196.83 134.645 196.83C135.197 196.83 135.645 197.278 135.645 197.83Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M141.645 197.83C141.645 198.382 141.197 198.83 140.645 198.83C140.093 198.83 139.645 198.382 139.645 197.83C139.645 197.278 140.093 196.83 140.645 196.83C141.197 196.83 141.645 197.278 141.645 197.83Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M147.645 197.83C147.645 198.382 147.197 198.83 146.645 198.83C146.093 198.83 145.645 198.382 145.645 197.83C145.645 197.278 146.093 196.83 146.645 196.83C147.197 196.83 147.645 197.278 147.645 197.83Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='152.645'
              cy='197.83'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='158.645'
              cy='197.83'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='164.645'
              cy='197.83'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='170.645'
              cy='197.83'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='176.645'
              cy='197.83'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='182.645'
              cy='197.83'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='188.645'
              cy='197.83'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='194.645'
              cy='197.83'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='200.645'
              cy='197.83'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='206.645'
              cy='197.83'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='212.645'
              cy='197.83'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='218.645'
              cy='197.83'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='224.645'
              cy='197.83'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='230.645'
              cy='197.83'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='236.645'
              cy='197.83'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='242.645'
              cy='197.83'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='248.645'
              cy='197.83'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='2.64502'
              cy='81.1904'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='8.64502'
              cy='81.1904'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='14.645'
              cy='81.1904'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='20.645'
              cy='81.1904'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='26.645'
              cy='81.1904'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M33.645 81.1904C33.645 81.7427 33.1973 82.1904 32.645 82.1904C32.0927 82.1904 31.645 81.7427 31.645 81.1904C31.645 80.6381 32.0927 80.1904 32.645 80.1904C33.1973 80.1904 33.645 80.6381 33.645 81.1904Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M39.645 81.1904C39.645 81.7427 39.1973 82.1904 38.645 82.1904C38.0927 82.1904 37.645 81.7427 37.645 81.1904C37.645 80.6381 38.0927 80.1904 38.645 80.1904C39.1973 80.1904 39.645 80.6381 39.645 81.1904Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M45.645 81.1904C45.645 81.7427 45.1973 82.1904 44.645 82.1904C44.0927 82.1904 43.645 81.7427 43.645 81.1904C43.645 80.6381 44.0927 80.1904 44.645 80.1904C45.1973 80.1904 45.645 80.6381 45.645 81.1904Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M51.645 81.1904C51.645 81.7427 51.1973 82.1904 50.645 82.1904C50.0927 82.1904 49.645 81.7427 49.645 81.1904C49.645 80.6381 50.0927 80.1904 50.645 80.1904C51.1973 80.1904 51.645 80.6381 51.645 81.1904Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M57.645 81.1904C57.645 81.7427 57.1973 82.1904 56.645 82.1904C56.0927 82.1904 55.645 81.7427 55.645 81.1904C55.645 80.6381 56.0927 80.1904 56.645 80.1904C57.1973 80.1904 57.645 80.6381 57.645 81.1904Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M63.645 81.1904C63.645 81.7427 63.1973 82.1904 62.645 82.1904C62.0927 82.1904 61.645 81.7427 61.645 81.1904C61.645 80.6381 62.0927 80.1904 62.645 80.1904C63.1973 80.1904 63.645 80.6381 63.645 81.1904Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M69.645 81.1904C69.645 81.7427 69.1973 82.1904 68.645 82.1904C68.0927 82.1904 67.645 81.7427 67.645 81.1904C67.645 80.6381 68.0927 80.1904 68.645 80.1904C69.1973 80.1904 69.645 80.6381 69.645 81.1904Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M75.645 81.1904C75.645 81.7427 75.1973 82.1904 74.645 82.1904C74.0927 82.1904 73.645 81.7427 73.645 81.1904C73.645 80.6381 74.0927 80.1904 74.645 80.1904C75.1973 80.1904 75.645 80.6381 75.645 81.1904Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M81.645 81.1904C81.645 81.7427 81.1973 82.1904 80.645 82.1904C80.0927 82.1904 79.645 81.7427 79.645 81.1904C79.645 80.6381 80.0927 80.1904 80.645 80.1904C81.1973 80.1904 81.645 80.6381 81.645 81.1904Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M87.645 81.1904C87.645 81.7427 87.1973 82.1904 86.645 82.1904C86.0927 82.1904 85.645 81.7427 85.645 81.1904C85.645 80.6381 86.0927 80.1904 86.645 80.1904C87.1973 80.1904 87.645 80.6381 87.645 81.1904Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M93.645 81.1904C93.645 81.7427 93.1973 82.1904 92.645 82.1904C92.0927 82.1904 91.645 81.7427 91.645 81.1904C91.645 80.6381 92.0927 80.1904 92.645 80.1904C93.1973 80.1904 93.645 80.6381 93.645 81.1904Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M99.645 81.1904C99.645 81.7427 99.1973 82.1904 98.645 82.1904C98.0927 82.1904 97.645 81.7427 97.645 81.1904C97.645 80.6381 98.0927 80.1904 98.645 80.1904C99.1973 80.1904 99.645 80.6381 99.645 81.1904Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M105.645 81.1904C105.645 81.7427 105.197 82.1904 104.645 82.1904C104.093 82.1904 103.645 81.7427 103.645 81.1904C103.645 80.6381 104.093 80.1904 104.645 80.1904C105.197 80.1904 105.645 80.6381 105.645 81.1904Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M111.645 81.1904C111.645 81.7427 111.197 82.1904 110.645 82.1904C110.093 82.1904 109.645 81.7427 109.645 81.1904C109.645 80.6381 110.093 80.1904 110.645 80.1904C111.197 80.1904 111.645 80.6381 111.645 81.1904Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M117.645 81.1904C117.645 81.7427 117.197 82.1904 116.645 82.1904C116.093 82.1904 115.645 81.7427 115.645 81.1904C115.645 80.6381 116.093 80.1904 116.645 80.1904C117.197 80.1904 117.645 80.6381 117.645 81.1904Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M123.645 81.1904C123.645 81.7427 123.197 82.1904 122.645 82.1904C122.093 82.1904 121.645 81.7427 121.645 81.1904C121.645 80.6381 122.093 80.1904 122.645 80.1904C123.197 80.1904 123.645 80.6381 123.645 81.1904Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M129.645 81.1904C129.645 81.7427 129.197 82.1904 128.645 82.1904C128.093 82.1904 127.645 81.7427 127.645 81.1904C127.645 80.6381 128.093 80.1904 128.645 80.1904C129.197 80.1904 129.645 80.6381 129.645 81.1904Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M135.645 81.1904C135.645 81.7427 135.197 82.1904 134.645 82.1904C134.093 82.1904 133.645 81.7427 133.645 81.1904C133.645 80.6381 134.093 80.1904 134.645 80.1904C135.197 80.1904 135.645 80.6381 135.645 81.1904Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M141.645 81.1904C141.645 81.7427 141.197 82.1904 140.645 82.1904C140.093 82.1904 139.645 81.7427 139.645 81.1904C139.645 80.6381 140.093 80.1904 140.645 80.1904C141.197 80.1904 141.645 80.6381 141.645 81.1904Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M147.645 81.1904C147.645 81.7427 147.197 82.1904 146.645 82.1904C146.093 82.1904 145.645 81.7427 145.645 81.1904C145.645 80.6381 146.093 80.1904 146.645 80.1904C147.197 80.1904 147.645 80.6381 147.645 81.1904Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='152.645'
              cy='81.1904'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='158.645'
              cy='81.1904'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='164.645'
              cy='81.1904'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='170.645'
              cy='81.1904'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='176.645'
              cy='81.1904'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='182.645'
              cy='81.1904'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='188.645'
              cy='81.1904'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='194.645'
              cy='81.1904'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='200.645'
              cy='81.1904'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='206.645'
              cy='81.1904'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='212.645'
              cy='81.1904'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='218.645'
              cy='81.1904'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='224.645'
              cy='81.1904'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='230.645'
              cy='81.1904'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='236.645'
              cy='81.1904'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='242.645'
              cy='81.1904'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='248.645'
              cy='81.1904'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='2.64502'
              cy='226.99'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='8.64502'
              cy='226.99'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='14.645'
              cy='226.99'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='20.645'
              cy='226.99'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='26.645'
              cy='226.99'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M33.645 226.99C33.645 227.543 33.1973 227.99 32.645 227.99C32.0927 227.99 31.645 227.543 31.645 226.99C31.645 226.438 32.0927 225.99 32.645 225.99C33.1973 225.99 33.645 226.438 33.645 226.99Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M39.645 226.99C39.645 227.543 39.1973 227.99 38.645 227.99C38.0927 227.99 37.645 227.543 37.645 226.99C37.645 226.438 38.0927 225.99 38.645 225.99C39.1973 225.99 39.645 226.438 39.645 226.99Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M45.645 226.99C45.645 227.543 45.1973 227.99 44.645 227.99C44.0927 227.99 43.645 227.543 43.645 226.99C43.645 226.438 44.0927 225.99 44.645 225.99C45.1973 225.99 45.645 226.438 45.645 226.99Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M51.645 226.99C51.645 227.543 51.1973 227.99 50.645 227.99C50.0927 227.99 49.645 227.543 49.645 226.99C49.645 226.438 50.0927 225.99 50.645 225.99C51.1973 225.99 51.645 226.438 51.645 226.99Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M57.645 226.99C57.645 227.543 57.1973 227.99 56.645 227.99C56.0927 227.99 55.645 227.543 55.645 226.99C55.645 226.438 56.0927 225.99 56.645 225.99C57.1973 225.99 57.645 226.438 57.645 226.99Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M63.645 226.99C63.645 227.543 63.1973 227.99 62.645 227.99C62.0927 227.99 61.645 227.543 61.645 226.99C61.645 226.438 62.0927 225.99 62.645 225.99C63.1973 225.99 63.645 226.438 63.645 226.99Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M69.645 226.99C69.645 227.543 69.1973 227.99 68.645 227.99C68.0927 227.99 67.645 227.543 67.645 226.99C67.645 226.438 68.0927 225.99 68.645 225.99C69.1973 225.99 69.645 226.438 69.645 226.99Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M75.645 226.99C75.645 227.543 75.1973 227.99 74.645 227.99C74.0927 227.99 73.645 227.543 73.645 226.99C73.645 226.438 74.0927 225.99 74.645 225.99C75.1973 225.99 75.645 226.438 75.645 226.99Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M81.645 226.99C81.645 227.543 81.1973 227.99 80.645 227.99C80.0927 227.99 79.645 227.543 79.645 226.99C79.645 226.438 80.0927 225.99 80.645 225.99C81.1973 225.99 81.645 226.438 81.645 226.99Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M87.645 226.99C87.645 227.543 87.1973 227.99 86.645 227.99C86.0927 227.99 85.645 227.543 85.645 226.99C85.645 226.438 86.0927 225.99 86.645 225.99C87.1973 225.99 87.645 226.438 87.645 226.99Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M93.645 226.99C93.645 227.543 93.1973 227.99 92.645 227.99C92.0927 227.99 91.645 227.543 91.645 226.99C91.645 226.438 92.0927 225.99 92.645 225.99C93.1973 225.99 93.645 226.438 93.645 226.99Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M99.645 226.99C99.645 227.543 99.1973 227.99 98.645 227.99C98.0927 227.99 97.645 227.543 97.645 226.99C97.645 226.438 98.0927 225.99 98.645 225.99C99.1973 225.99 99.645 226.438 99.645 226.99Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M105.645 226.99C105.645 227.543 105.197 227.99 104.645 227.99C104.093 227.99 103.645 227.543 103.645 226.99C103.645 226.438 104.093 225.99 104.645 225.99C105.197 225.99 105.645 226.438 105.645 226.99Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M111.645 226.99C111.645 227.543 111.197 227.99 110.645 227.99C110.093 227.99 109.645 227.543 109.645 226.99C109.645 226.438 110.093 225.99 110.645 225.99C111.197 225.99 111.645 226.438 111.645 226.99Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M117.645 226.99C117.645 227.543 117.197 227.99 116.645 227.99C116.093 227.99 115.645 227.543 115.645 226.99C115.645 226.438 116.093 225.99 116.645 225.99C117.197 225.99 117.645 226.438 117.645 226.99Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M123.645 226.99C123.645 227.543 123.197 227.99 122.645 227.99C122.093 227.99 121.645 227.543 121.645 226.99C121.645 226.438 122.093 225.99 122.645 225.99C123.197 225.99 123.645 226.438 123.645 226.99Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M129.645 226.99C129.645 227.543 129.197 227.99 128.645 227.99C128.093 227.99 127.645 227.543 127.645 226.99C127.645 226.438 128.093 225.99 128.645 225.99C129.197 225.99 129.645 226.438 129.645 226.99Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M135.645 226.99C135.645 227.543 135.197 227.99 134.645 227.99C134.093 227.99 133.645 227.543 133.645 226.99C133.645 226.438 134.093 225.99 134.645 225.99C135.197 225.99 135.645 226.438 135.645 226.99Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M141.645 226.99C141.645 227.543 141.197 227.99 140.645 227.99C140.093 227.99 139.645 227.543 139.645 226.99C139.645 226.438 140.093 225.99 140.645 225.99C141.197 225.99 141.645 226.438 141.645 226.99Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M147.645 226.99C147.645 227.543 147.197 227.99 146.645 227.99C146.093 227.99 145.645 227.543 145.645 226.99C145.645 226.438 146.093 225.99 146.645 225.99C147.197 225.99 147.645 226.438 147.645 226.99Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='152.645'
              cy='226.99'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='158.645'
              cy='226.99'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='164.645'
              cy='226.99'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='170.645'
              cy='226.99'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='176.645'
              cy='226.99'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='182.645'
              cy='226.99'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='188.645'
              cy='226.99'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='194.645'
              cy='226.99'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='200.645'
              cy='226.99'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='206.645'
              cy='226.99'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='212.645'
              cy='226.99'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='218.645'
              cy='226.99'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='224.645'
              cy='226.99'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='230.645'
              cy='226.99'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='236.645'
              cy='226.99'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='242.645'
              cy='226.99'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='248.645'
              cy='226.99'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='2.64502'
              cy='110.35'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='8.64502'
              cy='110.35'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='14.645'
              cy='110.35'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='20.645'
              cy='110.35'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='26.645'
              cy='110.35'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M33.645 110.35C33.645 110.902 33.1973 111.35 32.645 111.35C32.0927 111.35 31.645 110.902 31.645 110.35C31.645 109.797 32.0927 109.35 32.645 109.35C33.1973 109.35 33.645 109.797 33.645 110.35Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M39.645 110.35C39.645 110.902 39.1973 111.35 38.645 111.35C38.0927 111.35 37.645 110.902 37.645 110.35C37.645 109.797 38.0927 109.35 38.645 109.35C39.1973 109.35 39.645 109.797 39.645 110.35Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M45.645 110.35C45.645 110.902 45.1973 111.35 44.645 111.35C44.0927 111.35 43.645 110.902 43.645 110.35C43.645 109.797 44.0927 109.35 44.645 109.35C45.1973 109.35 45.645 109.797 45.645 110.35Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M51.645 110.35C51.645 110.902 51.1973 111.35 50.645 111.35C50.0927 111.35 49.645 110.902 49.645 110.35C49.645 109.797 50.0927 109.35 50.645 109.35C51.1973 109.35 51.645 109.797 51.645 110.35Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M57.645 110.35C57.645 110.902 57.1973 111.35 56.645 111.35C56.0927 111.35 55.645 110.902 55.645 110.35C55.645 109.797 56.0927 109.35 56.645 109.35C57.1973 109.35 57.645 109.797 57.645 110.35Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M63.645 110.35C63.645 110.902 63.1973 111.35 62.645 111.35C62.0927 111.35 61.645 110.902 61.645 110.35C61.645 109.797 62.0927 109.35 62.645 109.35C63.1973 109.35 63.645 109.797 63.645 110.35Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M69.645 110.35C69.645 110.902 69.1973 111.35 68.645 111.35C68.0927 111.35 67.645 110.902 67.645 110.35C67.645 109.797 68.0927 109.35 68.645 109.35C69.1973 109.35 69.645 109.797 69.645 110.35Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M75.645 110.35C75.645 110.902 75.1973 111.35 74.645 111.35C74.0927 111.35 73.645 110.902 73.645 110.35C73.645 109.797 74.0927 109.35 74.645 109.35C75.1973 109.35 75.645 109.797 75.645 110.35Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M81.645 110.35C81.645 110.902 81.1973 111.35 80.645 111.35C80.0927 111.35 79.645 110.902 79.645 110.35C79.645 109.797 80.0927 109.35 80.645 109.35C81.1973 109.35 81.645 109.797 81.645 110.35Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M87.645 110.35C87.645 110.902 87.1973 111.35 86.645 111.35C86.0927 111.35 85.645 110.902 85.645 110.35C85.645 109.797 86.0927 109.35 86.645 109.35C87.1973 109.35 87.645 109.797 87.645 110.35Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M93.645 110.35C93.645 110.902 93.1973 111.35 92.645 111.35C92.0927 111.35 91.645 110.902 91.645 110.35C91.645 109.797 92.0927 109.35 92.645 109.35C93.1973 109.35 93.645 109.797 93.645 110.35Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M99.645 110.35C99.645 110.902 99.1973 111.35 98.645 111.35C98.0927 111.35 97.645 110.902 97.645 110.35C97.645 109.797 98.0927 109.35 98.645 109.35C99.1973 109.35 99.645 109.797 99.645 110.35Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M105.645 110.35C105.645 110.902 105.197 111.35 104.645 111.35C104.093 111.35 103.645 110.902 103.645 110.35C103.645 109.797 104.093 109.35 104.645 109.35C105.197 109.35 105.645 109.797 105.645 110.35Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M111.645 110.35C111.645 110.902 111.197 111.35 110.645 111.35C110.093 111.35 109.645 110.902 109.645 110.35C109.645 109.797 110.093 109.35 110.645 109.35C111.197 109.35 111.645 109.797 111.645 110.35Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M117.645 110.35C117.645 110.902 117.197 111.35 116.645 111.35C116.093 111.35 115.645 110.902 115.645 110.35C115.645 109.797 116.093 109.35 116.645 109.35C117.197 109.35 117.645 109.797 117.645 110.35Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M123.645 110.35C123.645 110.902 123.197 111.35 122.645 111.35C122.093 111.35 121.645 110.902 121.645 110.35C121.645 109.797 122.093 109.35 122.645 109.35C123.197 109.35 123.645 109.797 123.645 110.35Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M129.645 110.35C129.645 110.902 129.197 111.35 128.645 111.35C128.093 111.35 127.645 110.902 127.645 110.35C127.645 109.797 128.093 109.35 128.645 109.35C129.197 109.35 129.645 109.797 129.645 110.35Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M135.645 110.35C135.645 110.902 135.197 111.35 134.645 111.35C134.093 111.35 133.645 110.902 133.645 110.35C133.645 109.797 134.093 109.35 134.645 109.35C135.197 109.35 135.645 109.797 135.645 110.35Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M141.645 110.35C141.645 110.902 141.197 111.35 140.645 111.35C140.093 111.35 139.645 110.902 139.645 110.35C139.645 109.797 140.093 109.35 140.645 109.35C141.197 109.35 141.645 109.797 141.645 110.35Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M147.645 110.35C147.645 110.902 147.197 111.35 146.645 111.35C146.093 111.35 145.645 110.902 145.645 110.35C145.645 109.797 146.093 109.35 146.645 109.35C147.197 109.35 147.645 109.797 147.645 110.35Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='152.645'
              cy='110.35'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='158.645'
              cy='110.35'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='164.645'
              cy='110.35'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='170.645'
              cy='110.35'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='176.645'
              cy='110.35'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='182.645'
              cy='110.35'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='188.645'
              cy='110.35'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='194.645'
              cy='110.35'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='200.645'
              cy='110.35'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='206.645'
              cy='110.35'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='212.645'
              cy='110.35'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='218.645'
              cy='110.35'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='224.645'
              cy='110.35'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='230.645'
              cy='110.35'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='236.645'
              cy='110.35'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='242.645'
              cy='110.35'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='248.645'
              cy='110.35'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='2.64502'
              cy='139.51'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='8.64502'
              cy='139.51'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='14.645'
              cy='139.51'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='20.645'
              cy='139.51'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='26.645'
              cy='139.51'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M33.645 139.51C33.645 140.062 33.1973 140.51 32.645 140.51C32.0927 140.51 31.645 140.062 31.645 139.51C31.645 138.957 32.0927 138.51 32.645 138.51C33.1973 138.51 33.645 138.957 33.645 139.51Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M39.645 139.51C39.645 140.062 39.1973 140.51 38.645 140.51C38.0927 140.51 37.645 140.062 37.645 139.51C37.645 138.957 38.0927 138.51 38.645 138.51C39.1973 138.51 39.645 138.957 39.645 139.51Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M45.645 139.51C45.645 140.062 45.1973 140.51 44.645 140.51C44.0927 140.51 43.645 140.062 43.645 139.51C43.645 138.957 44.0927 138.51 44.645 138.51C45.1973 138.51 45.645 138.957 45.645 139.51Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M51.645 139.51C51.645 140.062 51.1973 140.51 50.645 140.51C50.0927 140.51 49.645 140.062 49.645 139.51C49.645 138.957 50.0927 138.51 50.645 138.51C51.1973 138.51 51.645 138.957 51.645 139.51Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M57.645 139.51C57.645 140.062 57.1973 140.51 56.645 140.51C56.0927 140.51 55.645 140.062 55.645 139.51C55.645 138.957 56.0927 138.51 56.645 138.51C57.1973 138.51 57.645 138.957 57.645 139.51Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M63.645 139.51C63.645 140.062 63.1973 140.51 62.645 140.51C62.0927 140.51 61.645 140.062 61.645 139.51C61.645 138.957 62.0927 138.51 62.645 138.51C63.1973 138.51 63.645 138.957 63.645 139.51Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M69.645 139.51C69.645 140.062 69.1973 140.51 68.645 140.51C68.0927 140.51 67.645 140.062 67.645 139.51C67.645 138.957 68.0927 138.51 68.645 138.51C69.1973 138.51 69.645 138.957 69.645 139.51Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M75.645 139.51C75.645 140.062 75.1973 140.51 74.645 140.51C74.0927 140.51 73.645 140.062 73.645 139.51C73.645 138.957 74.0927 138.51 74.645 138.51C75.1973 138.51 75.645 138.957 75.645 139.51Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M81.645 139.51C81.645 140.062 81.1973 140.51 80.645 140.51C80.0927 140.51 79.645 140.062 79.645 139.51C79.645 138.957 80.0927 138.51 80.645 138.51C81.1973 138.51 81.645 138.957 81.645 139.51Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M87.645 139.51C87.645 140.062 87.1973 140.51 86.645 140.51C86.0927 140.51 85.645 140.062 85.645 139.51C85.645 138.957 86.0927 138.51 86.645 138.51C87.1973 138.51 87.645 138.957 87.645 139.51Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M93.645 139.51C93.645 140.062 93.1973 140.51 92.645 140.51C92.0927 140.51 91.645 140.062 91.645 139.51C91.645 138.957 92.0927 138.51 92.645 138.51C93.1973 138.51 93.645 138.957 93.645 139.51Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M99.645 139.51C99.645 140.062 99.1973 140.51 98.645 140.51C98.0927 140.51 97.645 140.062 97.645 139.51C97.645 138.957 98.0927 138.51 98.645 138.51C99.1973 138.51 99.645 138.957 99.645 139.51Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M105.645 139.51C105.645 140.062 105.197 140.51 104.645 140.51C104.093 140.51 103.645 140.062 103.645 139.51C103.645 138.957 104.093 138.51 104.645 138.51C105.197 138.51 105.645 138.957 105.645 139.51Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M111.645 139.51C111.645 140.062 111.197 140.51 110.645 140.51C110.093 140.51 109.645 140.062 109.645 139.51C109.645 138.957 110.093 138.51 110.645 138.51C111.197 138.51 111.645 138.957 111.645 139.51Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M117.645 139.51C117.645 140.062 117.197 140.51 116.645 140.51C116.093 140.51 115.645 140.062 115.645 139.51C115.645 138.957 116.093 138.51 116.645 138.51C117.197 138.51 117.645 138.957 117.645 139.51Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M123.645 139.51C123.645 140.062 123.197 140.51 122.645 140.51C122.093 140.51 121.645 140.062 121.645 139.51C121.645 138.957 122.093 138.51 122.645 138.51C123.197 138.51 123.645 138.957 123.645 139.51Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M129.645 139.51C129.645 140.062 129.197 140.51 128.645 140.51C128.093 140.51 127.645 140.062 127.645 139.51C127.645 138.957 128.093 138.51 128.645 138.51C129.197 138.51 129.645 138.957 129.645 139.51Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M135.645 139.51C135.645 140.062 135.197 140.51 134.645 140.51C134.093 140.51 133.645 140.062 133.645 139.51C133.645 138.957 134.093 138.51 134.645 138.51C135.197 138.51 135.645 138.957 135.645 139.51Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M141.645 139.51C141.645 140.062 141.197 140.51 140.645 140.51C140.093 140.51 139.645 140.062 139.645 139.51C139.645 138.957 140.093 138.51 140.645 138.51C141.197 138.51 141.645 138.957 141.645 139.51Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <path
              d='M147.645 139.51C147.645 140.062 147.197 140.51 146.645 140.51C146.093 140.51 145.645 140.062 145.645 139.51C145.645 138.957 146.093 138.51 146.645 138.51C147.197 138.51 147.645 138.957 147.645 139.51Z'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='152.645'
              cy='139.51'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='158.645'
              cy='139.51'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='164.645'
              cy='139.51'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='170.645'
              cy='139.51'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='176.645'
              cy='139.51'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='182.645'
              cy='139.51'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='188.645'
              cy='139.51'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='194.645'
              cy='139.51'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='200.645'
              cy='139.51'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='206.645'
              cy='139.51'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='212.645'
              cy='139.51'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='218.645'
              cy='139.51'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='224.645'
              cy='139.51'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='230.645'
              cy='139.51'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='236.645'
              cy='139.51'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='242.645'
              cy='139.51'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
            <circle
              cx='248.645'
              cy='139.51'
              r='1'
              fill='var(--illustrationsSharedNeutral800)'
            />
          </g>
        </g>
        <path
          d='M98.6143 42.3343L98.4426 42.4118L98.3647 42.5833L96.0049 47.7799L93.6969 42.5869L93.6191 42.4118L93.4441 42.3335L88.225 40L93.4441 37.6665L93.6191 37.5882L93.6969 37.4131L96.0049 32.2201L98.3647 37.4167L98.4426 37.5882L98.6143 37.6657L103.785 40L98.6143 42.3343Z'
          fill='var(--illustrationsSharedPrimary500)'
          stroke='var(--illustrationsSharedNeutral800)'
        />
        <path
          d='M219.211 155.919L219.039 155.997L218.961 156.168L215.505 163.78L212.124 156.172L212.046 155.997L211.871 155.919L204.225 152.5L211.871 149.081L212.046 149.003L212.124 148.828L215.505 141.22L218.961 148.832L219.039 149.003L219.211 149.081L226.785 152.5L219.211 155.919Z'
          fill='var(--illustrationsSharedPrimary500)'
          stroke='var(--illustrationsSharedNeutral800)'
        />
        <path
          d='M67.2787 193.915L66.9877 193.988L66.9149 194.279L65.5 199.938L64.0851 194.279L64.0123 193.988L63.7213 193.915L58.0616 192.5L63.7213 191.085L64.0123 191.012L64.0851 190.721L65.5 185.062L66.9149 190.721L66.9877 191.012L67.2787 191.085L72.9384 192.5L67.2787 193.915Z'
          fill='var(--illustrationsSharedPrimary500)'
          stroke='var(--illustrationsSharedNeutral800)'
        />
        <path
          d='M126 124.5V65.5L173.5 80.5C174.167 80.6667 175.6 81.1 176 81.5C176.4 81.9 176.5 83.3333 176.5 84V121L126 124.5Z'
          fill='var(--illustrationsSharedPrimary600)'
        />
        <path
          d='M122.5 124V66C106.333 71.1667 73.7001 81.6 72.5001 82C71.3001 82.4 71.3334 84.5 71.5001 85.5L72.0001 92.5L122.5 124Z'
          fill='var(--illustrationsSharedPrimary600)'
        />
        <path
          d='M121 126L71.5001 96L72.5001 134.5L78.5 147L121 126Z'
          fill='var(--illustrationsSharedPrimary600)'
        />
        <path
          d='M81 150L122.5 129.5L123 193.5C117.333 189.167 104.6 179.2 99 174C93.4 168.8 84.6667 155.833 81 150Z'
          fill='var(--illustrationsSharedPrimary600)'
        />
        <path
          d='M126 192.5V131L150.5 172C144.1 180.4 131.5 189.167 126 192.5Z'
          fill='var(--illustrationsSharedPrimary600)'
        />
        <path
          d='M153.5 169C167.9 155.4 174.5 133 176 124L128 127.5L153.5 169Z'
          fill='var(--illustrationsSharedPrimary600)'
        />
        <mask
          id='mask1_610_14333'
          style={{ maskType: 'alpha' }}
          maskUnits='userSpaceOnUse'
          x='73'
          y='66'
          width='52'
          height='126'
        >
          <path
            d='M124.5 192V66L74.8974 81.6747C74.0655 81.9376 73.5 82.7093 73.5 83.5818V121.671C73.5 128.178 75.0034 134.597 77.8928 140.428C86.3458 157.484 98.6332 172.352 113.792 183.867L124.5 192Z'
            fill='#454654'
          />
        </mask>
        <g mask='url(#mask1_610_14333)'>
          <path
            d='M124 127.5L66.5 155.5L124 196.5V127.5Z'
            fill='var(--illustrationsSharedPrimary500)'
          />
        </g>
        <mask
          id='mask2_610_14333'
          style={{ maskType: 'alpha' }}
          maskUnits='userSpaceOnUse'
          x='72'
          y='65'
          width='52'
          height='126'
        >
          <path
            d='M123.5 191V65L73.8974 80.6747C73.0655 80.9376 72.5 81.7093 72.5 82.5818V120.671C72.5 127.178 74.0034 133.597 76.8928 139.428C85.3458 156.484 97.6332 171.352 112.792 182.867L123.5 191Z'
            fill='var(--illustrationsSharedPrimary500)'
          />
        </mask>
        <g mask='url(#mask2_610_14333)'>
          <path
            d='M65.5 90L122 124.5L64.5 152.5L65.5 90Z'
            fill='var(--illustrationsSharedPrimary500)'
          />
        </g>
        <mask
          id='mask3_610_14333'
          style={{ maskType: 'alpha' }}
          maskUnits='userSpaceOnUse'
          x='72'
          y='64'
          width='52'
          height='126'
        >
          <path
            d='M123.5 190V64L73.9988 79.6426C73.1192 79.9206 72.5438 80.7642 72.606 81.6846L75.1066 118.679C75.3676 122.541 76.1607 126.349 77.4633 129.994C85.4775 152.42 99.8422 172.03 118.807 186.435L123.5 190Z'
            fill='var(--illustrationsSharedPrimary500)'
          />
        </mask>
        <g mask='url(#mask3_610_14333)'>
          <path
            d='M66 86L124 122.5V58.5L122.5 54.5L72 70.5L66 86Z'
            fill='var(--illustrationsSharedPrimary500)'
          />
        </g>
        <mask
          id='mask4_610_14333'
          style={{ maskType: 'alpha' }}
          maskUnits='userSpaceOnUse'
          x='126'
          y='65'
          width='52'
          height='126'
        >
          <path
            d='M126.5 191V65L176.103 80.6747C176.935 80.9376 177.5 81.7093 177.5 82.5818V120.671C177.5 127.178 175.997 133.597 173.107 139.428C164.654 156.484 152.367 171.352 137.208 182.867L126.5 191Z'
            fill='#454654'
          />
        </mask>
        <g mask='url(#mask4_610_14333)'>
          <path
            d='M127 128.5L156 177L127 195V128.5Z'
            fill='var(--illustrationsSharedPrimary500)'
          />
        </g>
        <mask
          id='mask5_610_14333'
          style={{ maskType: 'alpha' }}
          maskUnits='userSpaceOnUse'
          x='127'
          y='64'
          width='52'
          height='126'
        >
          <path
            d='M127.5 190V64L177.103 79.6747C177.935 79.9376 178.5 80.7093 178.5 81.5818V119.671C178.5 126.178 176.997 132.597 174.107 138.428C165.654 155.484 153.367 170.352 138.208 181.867L127.5 190Z'
            fill='var(--illustrationsSharedPrimary500)'
          />
        </mask>
        <g mask='url(#mask5_610_14333)'>
          <path
            d='M183.5 121.5L129 125.5L158.5 175L173.5 160.5L183.5 121.5Z'
            fill='var(--illustrationsSharedPrimary500)'
          />
        </g>
        <mask
          id='mask6_610_14333'
          style={{ maskType: 'alpha' }}
          maskUnits='userSpaceOnUse'
          x='126'
          y='63'
          width='52'
          height='126'
        >
          <path
            d='M126.5 189V63L176.103 78.6747C176.935 78.9376 177.5 79.7093 177.5 80.5818V118.671C177.5 125.178 175.997 131.597 173.107 137.428C164.654 154.484 152.367 169.352 137.208 180.867L126.5 189Z'
            fill='var(--illustrationsSharedPrimary500)'
          />
        </mask>
        <g mask='url(#mask6_610_14333)'>
          <path
            d='M185 118L127.5 123L127 59.5L186.5 62.5L185 118Z'
            fill='var(--illustrationsSharedPrimary500)'
          />
        </g>
        <path
          d='M127 122.5V63.5L174.5 78.5C175.167 78.6667 176.6 79.1 177 79.5C177.4 79.9 177.5 81.3333 177.5 82V119L127 122.5Z'
          stroke='var(--illustrationsSharedNeutral800)'
        />
        <path
          d='M123.5 122V64C107.333 69.1667 74.7001 79.6 73.5001 80C72.3001 80.4 72.3334 82.5 72.5001 83.5L73.0001 90.5L123.5 122Z'
          stroke='var(--illustrationsSharedNeutral800)'
        />
        <path
          d='M122 124L72.5001 94L73.5001 132.5L79.5 145L122 124Z'
          stroke='var(--illustrationsSharedNeutral800)'
        />
        <path
          d='M82 148L123.5 127.5L124 191.5C118.333 187.167 105.6 177.2 100 172C94.4 166.8 85.6667 153.833 82 148Z'
          stroke='var(--illustrationsSharedNeutral800)'
        />
        <path
          d='M127 190.5V129L151.5 170C145.1 178.4 132.5 187.167 127 190.5Z'
          stroke='var(--illustrationsSharedNeutral800)'
        />
        <path
          d='M154.5 167C168.9 153.4 175.5 131 177 122L129 125.5L154.5 167Z'
          stroke='var(--illustrationsSharedNeutral800)'
        />
        <rect x='44.5' y='137' width='9' height='35' rx='1' fill='white' />
        <rect x='65.5' y='121' width='9' height='35' rx='1' fill='white' />
        <rect x='86.5' y='97' width='9' height='35' rx='1' fill='white' />
        <rect x='107.5' y='121' width='9' height='35' rx='1' fill='white' />
        <rect x='128.5' y='137' width='9' height='35' rx='1' fill='white' />
        <rect x='149.5' y='121' width='9' height='35' rx='1' fill='white' />
        <rect x='170.5' y='113' width='9' height='35' rx='1' fill='white' />
        <rect x='191.5' y='81' width='9' height='35' rx='1' fill='white' />
        <rect
          x='46.5'
          y='135'
          width='9'
          height='35'
          rx='1'
          fill='var(--illustrationsSharedSuccess400)'
        />
        <rect
          x='67.5'
          y='119'
          width='9'
          height='35'
          rx='1'
          fill='var(--illustrationsSharedSuccess400)'
        />
        <rect
          x='88.5'
          y='95'
          width='9'
          height='35'
          rx='1'
          fill='var(--illustrationsSharedDestructive500)'
        />
        <rect
          x='109.5'
          y='119'
          width='9'
          height='35'
          rx='1'
          fill='var(--illustrationsSharedDestructive500)'
        />
        <rect
          x='130.5'
          y='135'
          width='9'
          height='35'
          rx='1'
          fill='var(--illustrationsSharedDestructive500)'
        />
        <rect
          x='151.5'
          y='119'
          width='9'
          height='35'
          rx='1'
          fill='var(--illustrationsSharedSuccess400)'
        />
        <rect
          x='172.5'
          y='111'
          width='9'
          height='35'
          rx='1'
          fill='var(--illustrationsSharedSuccess400)'
        />
        <rect
          x='193.5'
          y='79'
          width='9'
          height='35'
          rx='1'
          fill='var(--illustrationsSharedSuccess400)'
        />
        <rect
          x='46'
          y='136.5'
          width='8'
          height='34'
          rx='0.5'
          stroke='var(--illustrationsSharedNeutral800)'
        />
        <rect
          x='67'
          y='120.5'
          width='8'
          height='34'
          rx='0.5'
          stroke='var(--illustrationsSharedNeutral800)'
        />
        <rect
          x='88'
          y='96.5'
          width='8'
          height='34'
          rx='0.5'
          stroke='var(--illustrationsSharedNeutral800)'
        />
        <rect
          x='109'
          y='120.5'
          width='8'
          height='34'
          rx='0.5'
          stroke='var(--illustrationsSharedNeutral800)'
        />
        <rect
          x='130'
          y='136.5'
          width='8'
          height='34'
          rx='0.5'
          stroke='var(--illustrationsSharedNeutral800)'
        />
        <rect
          x='151'
          y='120.5'
          width='8'
          height='34'
          rx='0.5'
          stroke='var(--illustrationsSharedNeutral800)'
        />
        <rect
          x='172'
          y='112.5'
          width='8'
          height='34'
          rx='0.5'
          stroke='var(--illustrationsSharedNeutral800)'
        />
        <rect
          x='193'
          y='80.5'
          width='8'
          height='34'
          rx='0.5'
          stroke='var(--illustrationsSharedNeutral800)'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_610_14333'
          x1='124.5'
          y1='0'
          x2='124.5'
          y2='248'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor='var(--illustrationsSharedPrimary500)'
            stopOpacity='0.18'
          />
          <stop
            offset='1'
            stopColor='var(--illustrationsSharedPrimary500)'
            stopOpacity='0'
          />
        </linearGradient>
        <clipPath id='clip0_610_14333'>
          <rect
            width='248'
            height='248'
            fill='white'
            transform='translate(0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}
