import { Button, CustomModal } from 'components'
import { useTranslation } from 'hooks/useTranslation'

export const BankConnectionError = ({
  close,
}: {
  close: () => void
}): JSX.Element => {
  const { t } = useTranslation()
  return (
    <CustomModal show size='small'>
      <CustomModal.Header text={t('banksConnection.error.title')} />
      <CustomModal.Content>
        <p>{t('banksConnection.error.message')}</p>
      </CustomModal.Content>
      <CustomModal.Footer>
        <Button text={t('common.ok')} onClick={close} buttonType='primary' />
      </CustomModal.Footer>
    </CustomModal>
  )
}
