import { Action, combineReducers } from '@reduxjs/toolkit'
import loginReducer from '../screens/login/reducers'
import resetPasswordReducer from '../screens/resetPassword/reducers'
import codeEnterReducer from '../screens/resetPassword/codeEnter/reducers'
import homePortfolioReducer from './commonReducers/portfolio/reducers'
import homePromotionsReducer from '../screens/home/promotions/reducers'
import profileReducer from '../screens/profile/reducers'
import profileTransactionsReducer from '../screens/transactions/reducers'
import collectionReducer from '../screens/collections/reducers'
import searchReducer from './commonReducers/search/search.reducers'
import filtersReducer from '../features/discover/filters.reducers'
import searchDiscoverReducer from '../features/discover/search.reducers'
import companyInfoReducer from '../screens/company/companyInfo/reducers'
import companyPositionsReducer from '../screens/company/companyPosition/reducers'
import companyStocksReducer from '../screens/company/reducers'
import transactionDetailsReducer from '../screens/transactions/transactionDetails/reducers'
import limitOrderPriceLimitsReducer from './commonReducers/limitOrderPriceLimits/reducers'
import transactionPdfReducer from '../screens/transactions/transactionPDF/reducers'
import addMoneyReducer from '../features/moneyOperations/topup/reducers'
import bankTransferReducer from '../features/moneyOperations/banktransfer/reducers'
import selectAccountReducer from '../features/moneyOperations/accountSelection/reducers'
import cashoutReducer from '../features/moneyOperations/cashout/reducers'
import collectionDetailsReducer from '../screens/collections/collection/reducers'
import bankSelectionReducer from '../features/moneyOperations/bankConnection/steps/selectNewBank/reducers'
import banksConnectionReducer from '../features/moneyOperations/bankConnection/steps/truelayerPermissions/reducers'
import moneyOperationsReducer from '../features/moneyOperations/reducers'
import {
  buySellReducer,
  paymentMethodsReducer,
  onboardingStatusReducer,
  resetStoreState,
  savingsPlansReducer,
  userBalancesReducer,
  appConfigReducer,
  currenciesExchangesReducer,
  commonUrlsReducer,
  tipRanksReducer,
} from './commonReducers'
import { watchlistReducer } from '../screens/watchlist'
import { notificationsReducer } from './commonReducers/notifications'
import { translationsReducer } from './commonReducers/translations'
import { brokerTierReducer } from './commonReducers/brokerTier'
import { userSettingsReducer } from './commonReducers/userSettings'
import { portfolioValuationReducer } from './commonReducers/portfolioValuation'
import manualTransferReducer from '../features/moneyOperations/manualTransferNewAccount/reducers'

const appReducer = combineReducers({
  login: loginReducer,
  resetPassword: resetPasswordReducer,
  investments: homePortfolioReducer,
  promotions: homePromotionsReducer,
  profile: profileReducer,
  cashout: cashoutReducer,
  collections: collectionReducer,
  collectionDetails: collectionDetailsReducer,
  codeEnter: codeEnterReducer,
  currenciesExchanges: currenciesExchangesReducer,
  translations: translationsReducer,
  search: searchReducer,
  discover: searchDiscoverReducer,
  discoverFilters: filtersReducer,
  companyInfo: companyInfoReducer,
  companyPosition: companyPositionsReducer,
  companyStocks: companyStocksReducer,
  commonUrls: commonUrlsReducer,
  limitOrderPriceLimits: limitOrderPriceLimitsReducer,
  transactions: profileTransactionsReducer,
  transactionDetail: transactionDetailsReducer,
  onboardingStatus: onboardingStatusReducer,
  paymentMethods: paymentMethodsReducer,
  config: appConfigReducer,
  buySell: buySellReducer,
  addMoney: addMoneyReducer,
  savingsPlans: savingsPlansReducer,
  userBalance: userBalancesReducer,
  bankTransfer: bankTransferReducer,
  transactionPdf: transactionPdfReducer,
  selectAccount: selectAccountReducer,
  bankSelection: bankSelectionReducer,
  notifications: notificationsReducer,
  banksConnection: banksConnectionReducer,
  watchlist: watchlistReducer,
  moneyOperations: moneyOperationsReducer,
  brokerTier: brokerTierReducer,
  userSettings: userSettingsReducer,
  manualBankTransfer: manualTransferReducer,
  portfolioValuation: portfolioValuationReducer,
  tipRank: tipRanksReducer,
})

export const rootReducer = (
  state: ReturnType<typeof appReducer> | undefined,
  action: Action,
): ReturnType<typeof appReducer> => {
  // Clear all data in redux store to initial.
  if (action.type === resetStoreState.type) {
    state = undefined
  }

  return appReducer(state, action)
}

export type RootState = ReturnType<typeof rootReducer>
