import { ChartContainerProps } from './tradeViewChartContainer.props'
import { ResolutionString } from '../../../charting_library/charting_library'

const lightColor = {
  actionPrimaryInitial: '#449cff',
  iconPositiveInitial: '#127f42',
  iconNegativeInitial: '#f7034d',
  iconNeutralSoft: '#D1D5D6',
  iconNeutralMedium: '#8E9699',
  surfaceCanvasPrimaryInitial: '#FFFFFF', //neutral050
  textNeutralStrong: '#202124',
}

const darkColor = {
  actionPrimaryInitial: '#449cff',
  iconPositiveInitial: '#25D272',
  iconNegativeInitial: '#fd5369',
  iconNeutralSoft: '#BCC1C2',
  iconNeutralMedium: '#D1D5D6',
  surfaceCanvasPrimaryInitial: '#202124', // neutral900
  textNeutralStrong: '#FFFFFF',
}
export const tradeViewColorTheme = {
  light: {
    backgroundColor: lightColor.surfaceCanvasPrimaryInitial,
    barDownColor: lightColor.iconNegativeInitial,
    barUpColor: lightColor.iconPositiveInitial,
    color: lightColor.actionPrimaryInitial,
    textColor: lightColor.textNeutralStrong,
    volumeBarColor: lightColor.iconNeutralSoft,
    crossHairColor: lightColor.iconNeutralMedium,
    crossHairBgColor: lightColor.iconNeutralSoft,
  },
  dark: {
    backgroundColor: darkColor.surfaceCanvasPrimaryInitial,
    barDownColor: darkColor.iconNegativeInitial,
    barUpColor: darkColor.iconPositiveInitial,
    color: darkColor.actionPrimaryInitial,
    textColor: darkColor.textNeutralStrong,
    volumeBarColor: darkColor.iconNeutralSoft,
    crossHairColor: darkColor.iconNeutralMedium,
    crossHairBgColor: darkColor.iconNeutralSoft,
  },
}

export const defaultProps: Omit<ChartContainerProps, 'container'> = {
  symbol: 'AAPL',
  interval: 'D' as ResolutionString,
  datafeedUrl: 'https://demo_feed.tradingview.com',
  libraryPath: '/charting_library/',
  chartsStorageUrl: 'https://saveload.tradingview.com',
  chartsStorageApiVersion: '1.1',
  clientId: 'tradingview.com',
  userId: 'public_user_id',
  fullscreen: false,
  autosize: true,
  studiesOverrides: {},
}
