import React, { useRef, useEffect, useState } from 'react'
import * as d3 from 'd3'
import './barChart.styles.scss'
import { Spacer, Text } from 'components'
import { useTranslation } from 'hooks/useTranslation'
import { formatMoney } from '@utils/helpers'
import { getFormatLang } from '@utils/langs'
import { EarningTab } from 'interfaces'

interface barData {
  quarter: number
  estimated: number
  reported: number
  year: number
}
interface BarChartProps {
  data: {
    quarter: number
    estimated: number
    reported: number
    year: number
    currency: string
  }[]
  height: number
  legendFirst: string
  legendSecond: string
  activeTab: EarningTab
}

const BarChart: React.FC<BarChartProps> = ({
  data,
  height,
  legendFirst,
  legendSecond,
  activeTab,
}) => {
  const svgRef = useRef<SVGSVGElement | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [popoverContent, setPopoverContent] = useState<{
    quarter: number
    estimated: number
    reported: number
    year: number
  } | null>(null)
  const { t } = useTranslation()

  useEffect(() => {
    if (!svgRef.current || !containerRef.current) return

    const containerWidth = containerRef.current.offsetWidth
    const maxVisibleQuarters = 6
    const totalQuarters = data.length
    const shouldScroll = totalQuarters > maxVisibleQuarters

    // Set the width of the chart dynamically based on the number of quarters
    const chartWidth = shouldScroll
      ? containerWidth * (totalQuarters / maxVisibleQuarters)
      : containerWidth

    // Clear any previous svg elements
    d3.select(svgRef.current).selectAll('*').remove()

    // Set up the SVG canvas dimensions
    const svg = d3
      .select(svgRef.current)
      .attr('width', chartWidth)
      .attr('height', height)

    // Set margins and chart dimensions with increased left margin for Y-axis labels
    const margin = { top: 20, right: 30, bottom: 80, left: 50 } // Increased bottom margin for labels
    const innerChartWidth = chartWidth - margin.left - margin.right
    const chartHeight = height - margin.top - margin.bottom

    // Create the scales
    const x0 = d3
      .scaleBand()
      .domain(data.map((d) => `${d.quarter} ${d.year}`))
      .range([margin.left, innerChartWidth + margin.left])
      .padding(0.2)

    const x1 = d3
      .scaleBand()
      .domain(['estimated', 'reported'])
      .range([0, x0.bandwidth()])
      .padding(0.05)
      .paddingInner(0.2)
    // Determine the maximum value for the Y-axis and round up to the nearest whole number
    const yValues = d3.max(data, (d) =>
      Math.max(d.estimated as number, d.reported as number),
    )! as number
    const maxY = Math.ceil(yValues)

    // Create a scale for the Y-axis using the max value
    const y = d3
      .scaleLinear()
      .domain([0, maxY])
      .nice()
      .range([chartHeight + margin.top, margin.top])

    // Add the y-axis grid lines with only 4 intervals
    svg
      .append('g')
      .attr('class', 'grid')
      .attr('transform', `translate(${margin.left + 20},0)`)
      .call(
        d3
          .axisLeft(y)
          .ticks(4) // Ensure only 4 intervals (grid lines)
          .tickSize(-innerChartWidth)
          .tickFormat(() => ''),
      )
      .selectAll('.tick line')
      .attr('stroke', '#e0e0e0')

    // Remove the Y-axis main line
    svg.selectAll('.grid .domain').remove()

    // Create groups for each quarter
    const quarterGroups = svg
      .selectAll('.quarter-group')
      .data(data)
      .enter()
      .append('g')
      .attr('class', 'quarter-group')
      .attr('transform', (d) => `translate(${x0(d.quarter + ' ' + d.year)}, 0)`)
      .on('mouseover', (_event, d: barData) => {
        setPopoverContent(d)
      })
      .on('mouseout', () => {
        setPopoverContent(null)
      })

    // Create bars for estimated and reported EPS
    quarterGroups
      .selectAll('.bar')
      .data((d) => [
        { key: 'estimated', value: d.estimated },
        { key: 'reported', value: d.reported },
      ])
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('x', (d) => x1(d.key)!)
      .attr('y', (d) => y(d.value))
      .attr('width', x1.bandwidth())
      .attr('height', (d) => chartHeight + margin.top - y(d.value))
      .attr('fill', (d) =>
        d.key === 'estimated'
          ? 'var(--illustrationsPrimary40)'
          : 'var(--illustrationsPrimary20)',
      )
      .attr('rx', 4)

    // Add custom quarter labels
    svg
      .selectAll('.quarter-label')
      .data(data)
      .enter()
      .append('text')
      .attr('class', 'quarter-label')
      .attr('x', (d) => x0(d.quarter + ' ' + d.year)! + x0.bandwidth() / 2)
      .attr('y', chartHeight + margin.top + 20)
      .attr('text-anchor', 'middle')
      .attr('class', 'bodyRegular')
      .style('fill', 'var(--textNeutralMedium)')
      .text((d) => `Q${d.quarter} ${d.year}`)
      .on('mouseover', function (_event, d: barData) {
        setPopoverContent(d)

        // Get the text element's bounding box to calculate the hover box size
        const bbox = d3.select(this).node()!.getBBox()

        // Add a background rectangle (hover box) behind the hovered label
        svg
          .insert('rect', '.quarter-label') // Insert rectangle before text
          .attr('class', 'quarter-label__hover-background')
          .attr('x', bbox.x - 5) // Add padding to the rectangle
          .attr('y', bbox.y - 3)
          .attr('width', bbox.width + 10)
          .attr('height', bbox.height + 6)
          .attr('fill', 'var(--actionPrimarySubtleInitial)') // Background color of the hover box
          .attr('rx', 4)
          .attr('ry', 4)
          .style('filter', 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2))') // Elevation effect

        // Bring the hovered label to the front
        d3.select(this).raise()
      })
      .on('mouseout', function () {
        setPopoverContent(null)

        // Remove the hover box on mouse out
        svg.selectAll('.quarter-label__hover-background').remove()
      })

    // Change the color of the X-axis line to green
    svg
      .select('.x-axis')
      .selectAll('path') // Select the x-axis line
      .attr('stroke', 'var(--strokeNeutralStrongInitial)') // Set the stroke color to green

    // Add y-axis for ticks with custom formatting for dollar sign prefix
    svg
      .append('g')
      .attr('transform', `translate(${margin.left + 20},0)`)
      .attr('class', 'bodyRegular')
      .call(
        d3
          .axisLeft(y)
          .tickFormat(
            (d) =>
              `${formatMoney(
                d as number,
                data[0].currency,
                2,
                getFormatLang(),
                true,
              )}`,
          )
          .ticks(5),
      )
      .call((g) => g.selectAll('.tick line').remove()) // Remove Y-axis line
      .select('.domain')
      .remove() // Remove Y-axis line

    // Create a legend
    const legendData = [
      {
        label: legendFirst,
        color: 'var(--illustrationsPrimary40)',
      },
      {
        label: legendSecond,
        color: 'var(--illustrationsPrimary20)',
      },
    ]

    const legend = svg
      .append('g')
      .attr('class', 'legend')
      .attr(
        'transform',
        `translate(${margin.left}, ${chartHeight + margin.top + 40})`,
      )

    const legendItem = '.legend-item'

    legend
      .selectAll(legendItem)
      .data(legendData)
      .enter()
      .append('g')
      .attr('class', 'legend-item')
      .attr(
        'transform',
        (_d, i) =>
          `translate(${
            i * (activeTab === EarningTab.earnings ? 120 : 150)
          }, 0)`,
      )

    legend
      .selectAll(legendItem)
      .append('circle')
      .attr('cx', 4)
      .attr('cy', 4)
      .attr('r', 4)
      .attr('fill', (d) => d.color)

    legend
      .selectAll(legendItem)
      .append('text')
      .attr('x', 12)
      .attr('y', 10)
      .attr('class', 'bodyRegular')
      .attr('fill', 'var(--textNeutralStrong)')
      .text((d) => d.label)
  }, [data, height, t, legendFirst, legendSecond, activeTab])

  return (
    <>
      <div className='bar-chart-container' ref={containerRef}>
        <svg ref={svgRef}></svg>
      </div>
      {popoverContent && (
        <div className='bar-chart-popover'>
          <Text preset='bodyRegular'>
            Q{popoverContent.quarter} {popoverContent.year}
          </Text>
          <Spacer preset='tiny' />
          <div className='flex bar-chart__indicator__item  d-flex align-center'>
            <div className='bar-chart__indicator__item__circle bar-chart__indicator__item__circle--blue'></div>
            <Text preset='bodyRegular'>
              {legendFirst}:{' '}
              <b>
                {formatMoney(
                  popoverContent.estimated,
                  data[0].currency,
                  2,
                  getFormatLang(),
                  true,
                )}
              </b>
            </Text>
          </div>
          <div className='flex bar-chart__indicator__item  d-flex align-center'>
            <div className='bar-chart__indicator__item__circle bar-chart__indicator__item__circle--skyblue'></div>
            <Text preset='bodyRegular'>
              {legendSecond}:{' '}
              <b>
                {formatMoney(
                  popoverContent.reported,
                  data[0].currency,
                  2,
                  getFormatLang(),
                  true,
                )}
              </b>
            </Text>
          </div>
        </div>
      )}
    </>
  )
}

export default BarChart
