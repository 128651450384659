export const theme = 'dark'

export const changeTheme = (mode: string = theme): void => {
  document.getElementsByTagName('body')[0].setAttribute('data-theme', mode)
  if (localStorage.getItem('heySettings')) {
    const settings = JSON.parse(localStorage.getItem('heySettings') || '')
    settings.theme = mode
    localStorage.setItem('heySettings', JSON.stringify(settings))
  } else {
    const settings = { theme: mode }
    localStorage.setItem('heySettings', JSON.stringify(settings))
  }
}

export const getTheme = (): string => {
  if (localStorage.getItem('heySettings')) {
    const settings = JSON.parse(localStorage.getItem('heySettings') || '')
    return settings.theme || 'dark'
  } else {
    return theme
  }
}
