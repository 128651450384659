import { CloseIcon } from '@assets/icons'
import { Button, CustomModal, Spacer, Text } from 'components'
import { useTranslation } from 'hooks/useTranslation'

const AboutEarning = ({ close }: { close: () => void }): JSX.Element => {
  const { t } = useTranslation()
  return (
    <CustomModal
      size='big'
      height='fullHeight'
      show
      placeOn='right'
      dismissable
      onClose={close}
    >
      <CustomModal.Header>
        <Button
          onClick={() => close()}
          buttonType='link'
          autoWidth
          content={<CloseIcon color='iconPrimaryInitial' size={16} />}
          shape='square'
        />
      </CustomModal.Header>
      <CustomModal.Content>
        <Spacer preset='small' />
        <div className='d-flex d-flex-col w-100'>
          <Text
            preset='headline1'
            text={t('companyDetails.earning.info.title')}
          />
          <Spacer preset='small' />

          <div className='w-100'>
            <Text preset='subtitle2' color='primary'>
              {t('companyDetails.earning.info.earnings')}
            </Text>
            <ul className='default_list'>
              <li>
                <Text preset='bodyRegular'>
                  {t('companyDetails.earning.info.earningsReferTo')}
                </Text>
              </li>
              <li>
                <Text preset='bodyRegular'>
                  {t('companyDetails.earning.info.upcomingEarnings')}
                </Text>
              </li>
            </ul>
          </div>

          <Spacer preset='small' />
          <div className='w-100'>
            <Text preset='subtitle2' color='primary'>
              {t('companyDetails.earning.info.revenue')}
            </Text>
            <ul className='default_list'>
              <li>
                <Text preset='bodyRegular'>
                  {t('companyDetails.earning.info.revenueIncome')}
                </Text>
              </li>
            </ul>
          </div>
          <Spacer preset='small' />
        </div>
      </CustomModal.Content>
      <CustomModal.Footer>
        <Button onClick={close} text={t('common.ok')} />
      </CustomModal.Footer>
    </CustomModal>
  )
}

export default AboutEarning
