import {
  AnyAction,
  createAction,
  createReducer,
  PayloadAction,
} from '@reduxjs/toolkit'
import { DecodedTokenPayload, Login2FAResult, LoginResult } from '@services'
import { AuthState } from '@interfaces'
import { setToken, TokenPayload } from './action'
import {
  doLogout,
  fetch2fa,
  verify2fa,
  recoverToken,
  setCredentials,
} from './thunk'
import jwtDecode from 'jwt-decode'

export enum LoginError {
  InvalidCredentials = 'invalidCredentials',
  InvalidLoginOPT = 'invalidLoginOPT',
  LogoutError = 'logoutError',
}

const initialState: AuthState = {
  token: '',
  refreshToken: '',
  isLoading: false,
  isLoadingLogin: false,
  error: null,
  id: '',
  expiration: {
    seconds: null,
    petitionDate: null,
  },
  email: null,
  password: null,
  claims: [],
}

const authAction = (action: AnyAction): boolean =>
  action.type.includes(fetch2fa.fulfilled.toString())

export const clearErrors = createAction('clearErrors')
export const clearSecondsForExpiration = createAction(
  'clearSecondsForExpiration',
)
export const clearCredentials = createAction('clearCredentials')

export const reducers = createReducer(initialState, (builder) => {
  builder
    .addCase(setCredentials, (acc: AuthState, { payload }) => {
      acc.email = payload.email
      acc.password = payload.password
    })
    .addCase(clearCredentials.type, (acc) => {
      acc.email = null
      acc.password = null
    })
    .addCase(recoverToken.pending, (acc: AuthState) => {
      acc.isLoading = true
    })
    .addCase(recoverToken.fulfilled, (acc: AuthState, action: AnyAction) => {
      acc.token = action.payload.accessToken
      acc.refreshToken = action.payload.renewalToken
      acc.id = action.payload.id
      acc.isLoading = false
    })
    .addCase(fetch2fa.pending, (acc: AuthState) => {
      acc.isLoadingLogin = true
    })
    .addCase(fetch2fa.rejected, (acc: AuthState) => {
      acc.isLoadingLogin = false
      acc.error = LoginError.InvalidCredentials
    })
    .addCase(verify2fa.pending, (acc: AuthState) => {
      acc.isLoadingLogin = true
    })
    .addCase(verify2fa.fulfilled, (acc, action: PayloadAction<LoginResult>) => {
      const { accessToken, id } = action.payload
      acc.isLoadingLogin = false
      acc.isLoading = false
      acc.token = accessToken
      const decodedToken: DecodedTokenPayload = jwtDecode(accessToken)
      acc.claims = decodedToken?.claims ? decodedToken.claims : []
      acc.error = null
      acc.id = id
      acc.password = null
    })
    .addCase(verify2fa.rejected, (acc: AuthState) => {
      acc.isLoadingLogin = false
      acc.error = LoginError.InvalidLoginOPT
    })
    .addCase(doLogout.pending, (acc: AuthState) => {
      acc.isLoading = true
    })
    .addCase(doLogout.fulfilled, (acc: AuthState) => {
      acc.isLoading = false
      acc.token = null
      acc.refreshToken = null
      acc.error = null
    })
    .addCase(doLogout.rejected, (acc: AuthState) => {
      acc.isLoading = false
      acc.error = LoginError.LogoutError
      acc.token = null
      acc.refreshToken = null
    })
    .addCase(
      setToken.type,
      (acc: AuthState, action: PayloadAction<TokenPayload>) => {
        const { token } = action.payload
        acc.token = token
      },
    )
    .addCase(clearErrors.type, (acc) => {
      acc.error = null
    })
    .addCase(clearSecondsForExpiration.type, (acc) => {
      acc.expiration.seconds = null
      acc.expiration.petitionDate = null
    })
    .addMatcher(authAction, (acc, action: PayloadAction<Login2FAResult>) => {
      const { secondsForChallengeExpiration } = action.payload
      acc.isLoadingLogin = false
      acc.expiration.seconds = secondsForChallengeExpiration
      acc.expiration.petitionDate = Date.now()
      acc.error = null
    })
})

export default reducers
