import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

export const useAssetIsOnPortfolio = (assetId: string): boolean => {
  const { investments } = useSelector((state: RootState) => state)

  return useMemo(() => {
    return investments?.portfolios?.some((portfolio) =>
      portfolio?.positions?.some((p) => p.id === assetId),
    )
  }, [assetId, investments?.portfolios])
}
