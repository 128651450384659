import { createReducer } from '@reduxjs/toolkit'
import { PaymentMethodsState } from '@interfaces'
import {
  fetchPaymentMethods,
  removePaymentMethod,
  setDefaultPaymentMethod,
  removeManualTransfer,
} from './thunk'

const initialState: PaymentMethodsState = {
  paymentMethods: [],
  loading: false,
  error: null,
}

export const paymentMethodsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchPaymentMethods.pending, (acc) => {
      acc.loading = true
      acc.error = null
    })
    .addCase(fetchPaymentMethods.fulfilled, (acc, { payload }) => {
      acc.paymentMethods = payload?.paymentsMethods || []
      acc.loading = false
    })
    .addCase(fetchPaymentMethods.rejected, (acc, { error }) => {
      acc.loading = false
      acc.error = error.message || ''
    })
    .addCase(removePaymentMethod.fulfilled, (acc, action) => {
      const {
        meta: { arg },
      } = action

      acc.paymentMethods = acc.paymentMethods?.filter(
        (method) => method.id !== arg?.id,
      )
    })
    .addCase(removeManualTransfer.fulfilled, (acc, action) => {
      const {
        meta: { arg },
      } = action
      acc.paymentMethods = acc.paymentMethods?.filter(
        (method) => method.id !== arg,
      )
    })
    .addCase(setDefaultPaymentMethod.fulfilled, (acc, action) => {
      const {
        meta: { arg },
      } = action
      acc.paymentMethods = acc.paymentMethods?.map((method) => ({
        ...method,
        default: method.id === arg,
      }))
    })
})
