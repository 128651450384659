/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import './company.styles.scss'
import '../home/home.styles.scss'
import '../../features/investment/investment.styles.scss'
import { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  // fetchAnalystRating,
  fetchAssessmentEvaluation,
  // fetchBullBears,
  // fetchEarnings,
  // fetchForecast,
  // fetchNews,
  // resetTipRank,
  RootState,
} from '@store'
import { AssetManagerCode } from '@interfaces'
import { fetchAssetLiquidity, fetchWatchlistStockState } from './thunk'
import { fetchCompanyInfo } from './companyInfo/thunk'
import { fetchInvestments } from '../../store/commonReducers/portfolio/thunk'
import {
  fetchCompanyInvestments,
  clearAssetPosition,
} from './companyPosition/thunk'
import { useParams } from 'react-router-dom'
import { BigSpinner, Show } from '@components'
import { trackingService } from '@services'
import { CompanyDetailsEvents } from '@utils/eventTracker/eventKeys'
import { stringBuilder } from 'components/utils'
import { useAssetIsOnPortfolio } from 'hooks/useAssetIsOnPortfolio'
import { CompanyMainContent } from './companyMainContent/companyMainContent'

export default function Company(): JSX.Element {
  const id = useParams().id || ''
  const dispatch = useDispatch()

  const { companyInfo, companyStocks, investments } = useSelector(
    (state: RootState) => state,
  )

  const assetIsOnUserPortfolio = useAssetIsOnPortfolio(id)
  const [showInfo, setShowInfo] = useState(false)

  // useEffect(() => {
  //   if (!id) return
  //   dispatch(fetchAnalystRating({ assetId: id }))
  //   dispatch(fetchForecast({ assetId: id }))
  //   dispatch(fetchBullBears({ assetId: id }))
  //   dispatch(fetchNews({ assetId: id }))
  //   dispatch(fetchEarnings({ assetId: id }))

  //   return () => {
  //     dispatch(resetTipRank())
  //   }
  // }, [id, dispatch])

  useEffect(() => {
    handleCompanyViewTracking()
    dispatch(fetchAssessmentEvaluation())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!id) return

    // If user portfolio is not loaded, load it
    if (!investments.id) dispatch(fetchInvestments())

    dispatch(fetchAssetLiquidity({ ticker: id }))
    dispatch(fetchCompanyInfo({ ticker: id }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  // If the asset is on user's portfolio, we need to load the asset position
  useEffect(() => {
    dispatch(
      assetIsOnUserPortfolio
        ? fetchCompanyInvestments({ ticker: id })
        : clearAssetPosition(),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investments, id])

  // Handle 'like/watchlist' state
  useEffect(() => {
    if (!companyStocks.id) return
    dispatch(fetchWatchlistStockState(companyStocks.id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyStocks.id])

  const handleCompanyViewTracking = (): void => {
    const { symbol, type, assetManager } = companyStocks

    if (symbol && type && assetManager) {
      trackingService.trackEvent({
        event: CompanyDetailsEvents.visited,
        props: {
          assetGroup: type,
          assetManager: assetManager.code,
          ticker: symbol,
          id: id,
          isBlackrock:
            type === 'SAVING_PLAN' &&
            assetManager.code === AssetManagerCode.blk,
        },
      })
    }
  }

  const companyClasses = useMemo(() => {
    return stringBuilder([
      ['company-details', true],
      ['company-details--opened', showInfo],
    ])
  }, [showInfo])

  return (
    <div className={companyClasses}>
      <Show
        when={!companyInfo.loading}
        fallback={
          <div className='container home__loader --fade-in-animation'>
            <BigSpinner />
          </div>
        }
      >
        <CompanyMainContent
          showInfo={showInfo}
          setShowInfo={() => setShowInfo((p) => !p)}
        />
      </Show>
    </div>
  )
}
