import { createAsyncThunk } from '@reduxjs/toolkit'
import { getPortfolioBalance } from '@services'
import { Balances } from '@interfaces'

export const fetchUserBalances = createAsyncThunk<Balances>(
  'fetchUserBalances',
  async () => {
    return getPortfolioBalance()
  },
)
