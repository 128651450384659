import './loader.styles.scss'
import { ModalLoaderProps } from './loader.props'
import { BigSpinner } from '@components'

export function ModalLoader({
  className,
  text,
}: ModalLoaderProps): JSX.Element {
  const modalLoaderStyle = `modalLoader ${className}`

  return (
    <div className={modalLoaderStyle}>
      <div className='modalLoader__content d-flex d-flex-col align-center justify-center'>
        <BigSpinner>
          <div className='headline4 text--primary mt-3'>{text}</div>
        </BigSpinner>
      </div>
    </div>
  )
}
