import { useTranslation } from '@hooks'
import { Button, CustomModal, Spacer, Text } from '@components'

export const BuyInfoModalForSale = ({
  close,
}: {
  close: () => void
}): JSX.Element => {
  const { t } = useTranslation()
  return (
    <CustomModal
      show={true}
      theme='lighter'
      size='small'
      showBackgrop
      dismissable
    >
      <CustomModal.Header text={t('buySellFlow.infoAssets.title')} />
      <CustomModal.Content>
        <Spacer preset='tiny' />
        <Text
          preset='paragraph2'
          text={t('buySellFlow.infoAssets.text')}
          align='center'
        />
        <Spacer preset='tiny' />
      </CustomModal.Content>
      <CustomModal.Footer>
        <Button text={t('common.ok')} onClick={close} buttonType='primary' />
      </CustomModal.Footer>
    </CustomModal>
  )
}
