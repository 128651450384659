import './selectNewBank.style.scss'
import { Avatar, CustomModal, GoBack, SearchBox } from '@components'
import { BankAggregationItem } from '@interfaces'
import { BankLogo, SpinnerIcon } from '@assets/svg'
import { useSelector, useDispatch } from 'react-redux'
import { useState, useCallback, useEffect } from 'react'
import { RootState } from 'store'
import { useTranslation } from '@hooks'
import { requestBanksList, clearBanksList } from './thunk'

export interface SelectNewBankProps {
  nextStep: (bankSelected: BankAggregationItem) => void
  prevStep: () => void
  onClose: () => void
}

export function SelectNewBank({
  nextStep,
  onClose,
  prevStep,
}: SelectNewBankProps): JSX.Element {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const bankSelections = useSelector((state: RootState) => state.bankSelection)

  const [banks, setBanks] = useState(bankSelections.bankList)
  const [searchTerm, setSearchTerm] = useState('')

  const getBanksList = useCallback((): void => {
    dispatch(requestBanksList())
  }, [dispatch])

  const queryResults = (queryText: string): void => {
    const sanitized: string = queryText.replace(/^\s+/g, '')
    setSearchTerm(sanitized)
  }

  const clearInput = (): void => {
    setSearchTerm('')
  }

  useEffect(() => {
    getBanksList()
    return () => {
      dispatch(clearBanksList())
    }
  }, [getBanksList, dispatch])

  useEffect(() => {
    setSearchTerm('')
    setBanks(bankSelections.bankList)
  }, [bankSelections.bankList])

  useEffect(() => {
    if (bankSelections.bankList && searchTerm) {
      const filtered: BankAggregationItem[] =
        bankSelections.bankList.filter((b) =>
          b?.displayName.toLowerCase().includes(searchTerm.toLowerCase()),
        ) || []
      setBanks(filtered)
    } else if (bankSelections.bankList) {
      setBanks(bankSelections.bankList)
    }
  }, [searchTerm, bankSelections.bankList])

  return (
    <>
      <CustomModal.Header
        text={t('banksConnection.titleBanks')}
        linkText={t('common.cancel')}
        className='subtitle3 dark--label-title'
        onClick={onClose}
      >
        <GoBack
          goBack={() => {
            prevStep()
          }}
        />
      </CustomModal.Header>
      <CustomModal.Content>
        <div className='text-left headline1 mt-2 mb-1'>
          {t('bankSelection.title')}
        </div>
        <div className='paragraph2 secondary--text text-left mb-5'>
          {t('bankSelection.description')}
        </div>
        <SearchBox.Input
          onChange={(value: string) => queryResults(value)}
          textFieldProps={{
            clearInput,
            size: 'small',
            type: 'text',
            inputProps: {
              value: searchTerm,
              placeholder: t('home.search_block.search'),
              name: 'searching',
            },
          }}
        />
        <div className='list__banks mt-2'>
          {bankSelections.isLoading && (
            <div className='text-center'>
              <SpinnerIcon className='spinner spinner--orange' />
            </div>
          )}
          {searchTerm && banks && banks.length === 0 && (
            <div className='list__banks__not__found d-flex d-flex-col align-center mt-6'>
              <Avatar image={<BankLogo />} roundness='full' size='large' />
              <div className='headline3 mt-3 mb-2'>
                {t('search.notFoundSelectBank', { query: searchTerm })}
              </div>
              <p className='paragraph2 secondary--text'>
                {t('search.tryAgain')}
              </p>
            </div>
          )}
          {banks?.length > 0 &&
            banks?.map((bank) => {
              return (
                <div
                  className='list__banks__item d-flex align-center cursor-pointer'
                  onClick={() => {
                    nextStep(bank)
                  }}
                  aria-hidden
                  key={bank.providerId}
                >
                  <div className='mr-2'>
                    <Avatar
                      image={bank.logoUrl}
                      roundness='full'
                      size='large'
                      objectFit='cover'
                    />
                  </div>
                  <div className='list__banks__item__name'>
                    {bank.displayName}
                  </div>
                </div>
              )
            })}
        </div>
      </CustomModal.Content>
    </>
  )
}
