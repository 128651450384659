import './readOnlyTextField.styles.scss'
import { ReactNode, useMemo } from 'react'
import { stringBuilder } from '@components/utils'
import { TextPresets, Text } from '../text/text'

export interface ReadOnlyTextFieldProps {
  name?: string
  label: string
  value: string | React.ReactNode
  children?: React.ReactNode
  textStyle?: TextPresets
  onLeftClick?: () => void
  onRightClick?: () => void
  size?: 'small' | 'normal' | 'large' | 'huge'
  left?: ReactNode
  right?: ReactNode
}

export const ReadOnlyTextField = ({
  name,
  label,
  value,
  children,
  textStyle,
  onLeftClick,
  onRightClick,
  size,
  left,
  right,
}: ReadOnlyTextFieldProps): JSX.Element => {
  const textPreset: TextPresets = textStyle || 'paragraph1'

  const valueIsString = value && typeof value === 'string'

  const wrapperFieldStyle = useMemo(
    () =>
      stringBuilder([
        ['readonly-textfield', true],
        ['--size-small', size === 'small'],
        ['--size-normal', size === 'normal' || !size],
        ['--size-large', size === 'large'],
        ['--size-huge', (!!children && !size) || size === 'huge'],
      ]),
    [children, size],
  )

  return (
    <>
      <div className={wrapperFieldStyle}>
        {left && (
          <div
            className='readonly-textfield__cell --left-cell align-center'
            onClick={onLeftClick ? onLeftClick : null}
            aria-hidden
          >
            {left}
          </div>
        )}
        <div className='readonly-textfield__cell d-flex d-flex-col justify-center'>
          {label && (
            <label className={`readonly-textfield__label`} htmlFor={name}>
              {label}
            </label>
          )}
          {value && valueIsString ? (
            <Text preset={textPreset} color='primary'>
              {value}
            </Text>
          ) : (
            value
          )}
        </div>
        {right && (
          <div
            className={`readonly-textfield__cell --right-cell align-center ${
              onRightClick ? 'cursor-pointer' : ''
            }`}
            onClick={onRightClick ? onRightClick : null}
            aria-hidden
          >
            {right}
          </div>
        )}
      </div>
    </>
  )
}
