import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import {
  cancelOrder,
  getDepositTransaction,
  getDividendTransaction,
  getOrderDetail,
  getWithdrawalTransaction,
} from '@services'
import {
  OrderTransaction,
  TransactionDetailType,
  TransactionType,
} from '@interfaces'

const detailMethods = {
  [TransactionType.deposit]: getDepositTransaction,
  [TransactionType.withdrawal]: getWithdrawalTransaction,
  [TransactionType.dividendCash]: getDividendTransaction,
  [TransactionType.dividendShares]: getDividendTransaction,
}

export const fetchTransactionDetail = createAsyncThunk<
  TransactionDetailType,
  { id: string; type: TransactionType }
>('getTransactionDetail', ({ id, type }) => {
  const method = detailMethods[type]

  return method(id, type)
})

export const fetchOrderDetail = createAsyncThunk<
  OrderTransaction,
  { id: string }
>('getOrderDetail', async ({ id }) => {
  return await getOrderDetail(id)
})

export const setCancelOrder = createAsyncThunk<
  OrderTransaction,
  { id: string }
>('setCancelOrder', async ({ id }) => {
  return await cancelOrder(id)
})

export const clearTransactionDetail = createAction('clearTransactionDetail')

export const clearCancelledOrder = createAction('clearCancelledOrder')
