import { FiltersResponse } from '@interfaces/searchFilters'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { getTransactionsFilters } from '@services'
import { TransactionFiltersStatusParam } from 'interfaces'

export const clearTransactionsFilters = createAction('clearTransactionsFilters')
export const clearTransactionsFiltersSelection = createAction(
  'clearTransactionsFiltersSelection',
)
export const createTransactionsFiltersSelection = createAction(
  'createTransactionsFiltersSelection',
)

export const setTransactionsFilterFocused = createAction<string>(
  'setTransactionsFilterFocused',
)

export interface UpdateFilterActionPayload {
  id: string
  type: 'toggle' | 'reset' | 'selectAll'
}

export const updateTransactionsFilter = createAction<UpdateFilterActionPayload>(
  'updateTransactionsFilter',
)

export const fetchTransactionsFilters = createAsyncThunk<
  FiltersResponse,
  { status: TransactionFiltersStatusParam }
>('fetchTransactionsFilters', async ({ status }) => {
  return getTransactionsFilters(status)
})
