import { createReducer, createAction } from '@reduxjs/toolkit'
import { doSendVerificationCode, fetchVerificationCode } from './thunk'

interface verificationState {
  loading: boolean
  error: string | null
  resetPassword: boolean
}

const initialState: verificationState = {
  loading: false,
  error: null,
  resetPassword: false,
}

export const clearErrors = createAction('clearErrors')

export const reducers = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchVerificationCode.pending, (acc) => {
      acc.loading = true
      acc.error = null
    })
    .addCase(fetchVerificationCode.fulfilled, (acc) => {
      acc.loading = false
      acc.error = null
    })
    .addCase(fetchVerificationCode.rejected, (acc, { error }) => {
      acc.loading = false
      acc.error = error.message || ''
    })
    .addCase(doSendVerificationCode.pending, (acc) => {
      acc.loading = true
      acc.error = null
    })
    .addCase(doSendVerificationCode.fulfilled, (acc) => {
      acc.loading = false
      acc.error = null
      acc.resetPassword = true
    })
    .addCase(doSendVerificationCode.rejected, (acc, { error }) => {
      acc.loading = false
      acc.error = error.message || ''
      acc.resetPassword = false
    })
    .addCase(clearErrors.type, (acc) => {
      acc.error = null
      acc.resetPassword = false
    })
})

export default reducers
