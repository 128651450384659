import { useOnEscKeypress } from 'hooks/useOnEscKeypress'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { DropdownState } from '../dropdown.types'

const CLOSING_TIME = 140

/**
 * This hook goal is to reuse the logic of the dropdown panel.
 */
export const useDropdownState = (): {
  dropdownState: DropdownState
  openDropdown: () => void
  startDropdownClosing: () => void
  dropdownClasses: string
} => {
  const [dropdownState, setDropdownState] = useState(DropdownState.CLOSED)

  const openDropdown = (): void => {
    setDropdownState(DropdownState.OPENED)
  }

  const startDropdownClosing = useCallback((): void => {
    if (dropdownState === DropdownState.OPENED) {
      setDropdownState(DropdownState.CLOSING)
    }
  }, [dropdownState])

  useEffect(() => {
    let id

    // If closing then schedule close
    if (dropdownState === DropdownState.CLOSING) {
      id = setTimeout(() => {
        setDropdownState(DropdownState.CLOSED)
      }, CLOSING_TIME)
    }

    return () => {
      if (id) clearTimeout(id as number)
    }
  }, [dropdownState])

  /**
   * Start dropdown closing on ESC key press
   */
  useOnEscKeypress(startDropdownClosing)

  const dropdownClasses = useMemo(() => {
    switch (dropdownState) {
      case DropdownState.OPENED:
        return '--opened'
      case DropdownState.CLOSING:
        return '--closing'
      default:
        return ''
    }
  }, [dropdownState])

  return {
    dropdownState,
    openDropdown,
    startDropdownClosing,
    dropdownClasses,
  }
}
