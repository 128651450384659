import { ScrollingCarousel } from '@trendyol-js/react-carousel'
import { PendingTransactionItem, Show, Spacer, Text } from 'components'
import { ReactComponent as RightIcon } from '@assets/svg/next.svg'
import { ReactComponent as LeftIcon } from '@assets/svg/back.svg'
import { NotificationMessageType, OrderTransaction } from 'interfaces'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'hooks/useNavigation'
import { useOnboardingCompleted } from 'hooks/useOnboardingCompleted'
import { useState } from 'react'
import { CancelOrderModal } from '@shared/modals'
import { addNotification } from '@store/commonReducers/notifications'

export const PendingTransactions = (): JSX.Element => {
  const { t } = useTranslation()
  const { navigate } = useNavigate()
  const { transactions } = useSelector((state: RootState) => state)
  const onboardingCompleted = useOnboardingCompleted()
  const dispatch = useDispatch()

  const [cancelOrderTransaction, setCancelOrderTransaction] =
    useState<OrderTransaction | null>(null)

  const handleCancelOrder = (): void => {
    setCancelOrderTransaction(null)
    dispatch(
      addNotification({
        message: t('transactions.cancellingOrder'),
        type: NotificationMessageType.positive,
      }),
    )
  }

  const items = transactions?.byStatus['PENDING']?.rawData

  return (
    <Show when={onboardingCompleted && items?.length > 0}>
      <div className='home__widget'>
        <div className='home__widget__portfolio'>
          <Spacer preset='medium' />
          <div className='d-flex align-center justify-space-between'>
            <Text
              preset='headline3'
              text={t('transactions.pendingTransactions')}
            />
            {items?.length > 4 ? (
              <div
                className='cursor-pointer'
                aria-hidden
                onClick={() => navigate('/transactions/pending')}
              >
                <Text
                  preset='paragraph2'
                  text={t('common.viewAll')}
                  color='iconPrimaryInitial'
                />
              </div>
            ) : null}
          </div>
          <Spacer preset='small' />
          <ScrollingCarousel
            className={`carousel`}
            rightIcon={
              <RightIcon
                fill='var(--textNeutralStrong)'
                className='rightIcon'
              />
            }
            leftIcon={<LeftIcon fill='var(--textNeutralStrong)' />}
          >
            {items?.map((i) => (
              <div
                key={`transactions-${i?.asset?.id}-${i?.id}`}
                className='d-flex'
              >
                <PendingTransactionItem
                  transaction={i}
                  onCancel={() => setCancelOrderTransaction(i)}
                />
                <Spacer preset='smaller' />
              </div>
            ))}
          </ScrollingCarousel>
        </div>
      </div>
      <Show when={!!cancelOrderTransaction?.id}>
        <CancelOrderModal
          close={() => setCancelOrderTransaction(null)}
          handleCancelOrder={handleCancelOrder}
        />
      </Show>
    </Show>
  )
}
