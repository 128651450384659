import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import {
  getAssessmentEvaluation,
  getAssessmentQuestions,
  getSavingsPlans,
  saveAssessmentAnswers,
} from '@services'

import {
  AssessmentAnswers,
  AssessmentEvaluation,
  AssessmentQuestionnaire,
  SavingsPlan,
} from '@interfaces'

export const fetchAllSavingsPlans = createAsyncThunk<SavingsPlan[]>(
  'fetchAllSavingsPlans',
  () => getSavingsPlans(),
)

export const fetchSavingsPlan = createAsyncThunk<SavingsPlan[], string | null>(
  'fetchSavingsPlans',
  async (assetManager) => {
    return getSavingsPlans(assetManager)
  },
)

export const fetchSavingsPlansAssessment =
  createAsyncThunk<AssessmentQuestionnaire>(
    'fetchSavingsPlansAssessment',
    async () => getAssessmentQuestions('savingPlans'),
  )

export const saveSavingsPlansAssessment = createAsyncThunk<
  AssessmentEvaluation,
  AssessmentAnswers
>('saveSavingsPlansAssessment', async (answers: AssessmentAnswers) =>
  saveAssessmentAnswers('savingPlans', answers),
)

export const fetchAssessmentEvaluation = createAsyncThunk<AssessmentEvaluation>(
  'fetchAssessmentEvaluation',
  async () => {
    return getAssessmentEvaluation('savingPlans')
  },
)

export const clearSavingPlans = createAction('clearSavingPlans')

export const setShowAssessmentCard = createAction<boolean>(
  'setShowAssessmentCard',
)

export const checkAssessmentAnswer = createAction<CheckAssessmentAnswer>(
  'checkAssessmentAnswer',
)

export interface CheckAssessmentAnswer {
  newValue: boolean
  optionId: string
  idx: number
}
