/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import { useOnChangeMoneyForm, useTranslation } from '@hooks'
import {
  CustomModal,
  TextField,
  Button,
  Spacer,
  Card,
  Text,
  ModalLoader,
} from '@components'
import { useCallback, useEffect, useState } from 'react'
import {
  buildQueryParams,
  formatCurrencyToSymbol,
  formatMoney,
  sanitizeNumber,
  whatDecimalSeparator,
} from '@utils/helpers'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserBalances, RootState } from '@store'
import { fetchPaymentMethods } from '@store/commonReducers/paymentMethods/thunk'
import {
  fetchInfoTopUp,
  fetchPaymentMethodStatus,
  setSelectedPaymentMethod,
  clearSelectedBank,
  clearTopUpPaymentUrl,
  fetchValidCreditCards,
  clearRecurringPaymentOptions,
  fetchCreditCardFees,
  setCardFlowType,
} from '../topup/thunk'
import {
  ToAmount,
  OperationTypeEnum,
  MoneyOperationTypeEnum,
  CardFlowType,
  OperationConfirmationStatus,
  PaymentMethodTypeEnum,
} from '@interfaces'
import { trackingService } from '@services'
import { CashoutEvents, TopUpEvents } from '@utils/eventTracker/eventKeys'
import { ChangePaymentMethod, ReconnectBank } from '@shared/modals'
import { useTopUpTypeSelector } from '../shared/useTopUpTypeSelector.hooks'
import { SelectedPaymentMethodItem } from '../shared/selectedPaymentMethodItem'
import {
  clearAggregationLink,
  requestAggregationAuthLink,
} from '../bankConnection/steps/truelayerPermissions/thunk'
import { InfoIcon } from '@assets/icons'
import { AboutManualTransfer } from '@shared/modals/aboutManualTransfer/aboutManualTransfer'
import {
  addInputAmount,
  manualTransferDeposit,
  manualTransferWithdraw,
} from '../manualTransferNewAccount/thunk'
import { setMoneyOperationStep } from '../thunk'
import { MoneyOperationsSteps } from '../moneyOperations.props'
const windowUrl = window.location

interface SelectAmountProps {
  nextStep: (amount: number, paymentType: PaymentMethodTypeEnum) => void
  values?: ToAmount
  onTransfer: () => void
  onCancel?: () => void
  type: MoneyOperationTypeEnum
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export function SelectAmount({
  nextStep,
  onTransfer,
  onCancel,
  values,
  type,
}: SelectAmountProps): JSX.Element {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [disabled, setDisabled] = useState(true)
  const [loading, setLoading] = useState(false)
  const [isShown, setIsShown] = useState(false)
  const [isAboutManualTransferShown, setIsAboutManualTransferShown] =
    useState(false)
  const [isShownToken, setIsShownToken] = useState(false)
  const [linkToPSD, setLinkToPSD] = useState('')

  const {
    selector,
    typeSelectorModal,
    recurringTopUpSummary,
    recurringPaymentModal,
  } = useTopUpTypeSelector()

  const cancelActions = (): void => {
    dispatch(clearTopUpPaymentUrl())
    dispatch(clearAggregationLink())
    dispatch(clearSelectedBank())
    setIsShownToken(false)
    if (onCancel) onCancel()
  }

  const { paymentMethods, addMoney, userBalance, banksConnection, config } =
    useSelector((state: RootState) => state)
  const {
    loading: loader,
    withdrawalManualTransferEnded,
    inputAmount,
    error,
  } = useSelector((state: RootState) => state.manualBankTransfer)

  const paymentType = addMoney?.selectedPaymentMethod?.type
  const selectedMethodIsBank = paymentType === 'BANK'
  const selectedMethodIsCard = paymentType === 'CARD'
  const selectMethodIsManualTransfer =
    paymentType === PaymentMethodTypeEnum.MANUAL_TRANSFER

  const isTopUp = type === MoneyOperationTypeEnum.addMoney

  const methods = paymentMethods?.paymentMethods

  const defaultMethod = methods?.find((pm) => pm?.default) || methods[0]

  const handleTokenPSD = (): void => {
    if (linkToPSD) {
      window.localStorage.setItem('urlFrom', windowUrl.pathname)
      window.localStorage.setItem('redirectTopUp', 'true')
      window.location.replace(linkToPSD)
    }
  }

  const decimalSeparator = whatDecimalSeparator()

  const cashAvailable = isTopUp
    ? formatMoney(
        userBalance?.cashAvailable?.amount,
        userBalance.cashAvailable.currency,
      )
    : formatMoney(
        userBalance?.maxWithdrawal?.amount,
        userBalance.maxWithdrawal.currency,
      )

  useEffect(() => {
    dispatch(clearSelectedBank())
    dispatch(clearAggregationLink())
    dispatch(clearTopUpPaymentUrl())
    dispatch(clearRecurringPaymentOptions())
    dispatch(fetchValidCreditCards())
    dispatch(fetchInfoTopUp())
    dispatch(fetchPaymentMethods())
    dispatch(fetchUserBalances())
    dispatch(fetchCreditCardFees())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (defaultMethod) {
      dispatch(setSelectedPaymentMethod(defaultMethod))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTopUp, paymentMethods?.paymentMethods])

  const schemaReset = yup.object().shape({
    amount: yup.string().required(t('common.fieldRequired')),
  })

  const chooseAmountForm = useForm({
    resolver: yupResolver(schemaReset),
    criteriaMode: 'all',
    mode: 'onChange',
  })

  const setFocus = (name = 'amount'): void => {
    const el: HTMLInputElement = document.querySelector(`[name="${name}"]`)
    el?.focus()
  }

  const watching = chooseAmountForm.watch()

  const minimumNotPass = (value: number): boolean => {
    if (isNaN(value)) return true
    const numberTo = value
    if (type === MoneyOperationTypeEnum.cashout) {
      return (
        numberTo > (userBalance?.maxWithdrawal?.amount || 0) ||
        isNaN(numberTo) ||
        numberTo == 0
      )
    }
    return numberTo <= 0
  }
  const trackContinueClicked = useCallback(() => {
    const segmentKey =
      values?.operationType === OperationTypeEnum.cashout
        ? CashoutEvents.continueClicked
        : TopUpEvents.continueClicked
    trackingService.trackEvent({
      event: segmentKey,
    })
  }, [values?.operationType])

  const checkMethod = (): void => {
    // If selected payment method is a bank we need to check PSD bank connection status
    if (
      selectedMethodIsBank &&
      addMoney.selectedPaymentMethod?.bankDetails?.providerId
    ) {
      dispatch(fetchPaymentMethodStatus(addMoney.selectedPaymentMethod.id))

      // Here we need to return as the expiratio of the bank is being checked
      // and we dont want to execute nextStep function.
      return
    }

    // If the selected payment method is not a bank
    // we can continue
    trackContinueClicked()

    if (selectedMethodIsCard) {
      dispatch(setCardFlowType(CardFlowType.STOREDCARD))
    }

    if (selectMethodIsManualTransfer) {
      if (isTopUp) {
        dispatch(
          manualTransferDeposit({
            paymentMethodId: addMoney.selectedPaymentMethod.id,
            bankName:
              addMoney.selectedPaymentMethod.manualTransferDetails?.bankName,
            accountNumber:
              addMoney.selectedPaymentMethod.manualTransferDetails
                ?.accountNumber,
            amount: inputAmount,
            currency: userBalance.balance.currency,
          }),
        )
      } else {
        dispatch(
          manualTransferWithdraw({
            paymentMethodId: addMoney.selectedPaymentMethod.id,
            bankName:
              addMoney.selectedPaymentMethod.manualTransferDetails?.bankName,
            accountNumber:
              addMoney.selectedPaymentMethod.manualTransferDetails
                ?.accountNumber,
            amount: inputAmount,
            currency: userBalance.balance.currency,
          }),
        )
      }
    }
  }

  const onChangeTopUpForm = useOnChangeMoneyForm(
    chooseAmountForm,
    'amount',
    2,
    (value) => dispatch(addInputAmount(value)),
  )

  useEffect(() => {
    // Only run this is selected payment method is a bank
    if (addMoney.paymentMethodExpired) {
      setLinkToPSD(banksConnection.aggregationAuthUrl)
    }
  }, [addMoney.paymentMethodExpired, banksConnection])

  useEffect(() => {
    // Only run this is selected payment method is a bank
    if (
      selectedMethodIsBank &&
      addMoney.paymentMethodExpired &&
      addMoney.selectedPaymentMethod?.bankDetails?.providerId
    ) {
      const operationType = isTopUp
        ? OperationTypeEnum.addMoney
        : OperationTypeEnum.cashout

      const successParamsObj = {
        operation: operationType,
        status: OperationConfirmationStatus.success,
        method: PaymentMethodTypeEnum.BANK,
      }

      const cancelParamsObj = {
        ...successParamsObj,
        status: OperationConfirmationStatus.cancelled,
      }

      const failParamsObj = {
        ...successParamsObj,
        status: OperationConfirmationStatus.error,
      }

      const baseUrl = `${windowUrl.origin}/confirmation`

      const dataTo = {
        providerId: addMoney.selectedPaymentMethod?.bankDetails?.providerId,
        scopes: addMoney.selectedPaymentMethod?.bankDetails?.scopes,
        successRedirectUrl: `${baseUrl}${buildQueryParams(successParamsObj)}`,
        cancelRedirectUrl: `${baseUrl}${buildQueryParams(cancelParamsObj)}`,
        failRedirectUrl: `${baseUrl}${buildQueryParams(failParamsObj)}`,
      }
      setIsShownToken(true)
      dispatch(requestAggregationAuthLink(dataTo))
    }
  }, [
    addMoney.paymentMethodExpired,
    addMoney.selectedPaymentMethod,
    dispatch,
    isTopUp,
    selectedMethodIsBank,
  ])

  // This use effect is to continue to the next step once the
  // expired bank has been reconnected
  useEffect(() => {
    if (addMoney.paymentMethodExpired === false) {
      trackContinueClicked()
      nextStep(
        sanitizeNumber(chooseAmountForm.getValues().amount as string),
        paymentType,
      )
    }
  }, [
    addMoney.paymentMethodExpired,
    chooseAmountForm,
    nextStep,
    paymentType,
    trackContinueClicked,
  ])

  useEffect(() => {
    if (
      Object.values(chooseAmountForm.getValues()).includes('') === false &&
      Object.keys(chooseAmountForm?.formState?.errors).length == 0 &&
      paymentMethods.paymentMethods.length > 0
    ) {
      setDisabled(false)
      setLoading(true)
    } else setDisabled(true)
    setLoading(false)
    setFocus()
  }, [watching, chooseAmountForm, paymentMethods])

  useEffect(() => {
    if (error) {
      dispatch(setMoneyOperationStep(MoneyOperationsSteps.failedCashout))
    }
    if (withdrawalManualTransferEnded) {
      nextStep(
        sanitizeNumber(chooseAmountForm.getValues().amount as string),
        paymentType,
      )
    }
  }, [
    withdrawalManualTransferEnded,
    nextStep,
    paymentType,
    chooseAmountForm,
    error,
    dispatch,
  ])

  return (
    <>
      {loader && (
        <>
          <ModalLoader
            text={t('manualTransfer.bankAccount.loaderTitle')}
            className='background_transparent'
          />
        </>
      )}
      <CustomModal.Header
        text={isTopUp ? t('addMoney.header') : t('withdrawMoney.header')}
        linkText={t('common.cancel')}
        className='subtitle2 dark--label-title'
        onClick={cancelActions}
      />
      <CustomModal.Content className='d-flex d-flex-col justify-content-start'>
        {isTopUp &&
          selectedMethodIsCard &&
          config.features?.recurringPayments && (
            <div className='d-flex justify-end'>{selector}</div>
          )}
        <div className='headline1 primary--text mt-5 mb-5'>
          {isTopUp ? t('addMoney.title') : t('withdrawMoney.selectAmount')}
        </div>
        <div className='d-flex d-flex-col'>
          <TextField
            theme='subtle'
            right={
              <Text
                preset='subtitle2'
                text={formatCurrencyToSymbol(userBalance.balance.currency)}
              />
            }
            style='text-right headline1 bradius-2'
            register={chooseAmountForm.register}
            inputProps={{
              placeholder: `0${decimalSeparator}00`,
              name: 'amount',
              disabled: paymentMethods.loading,
              onChange: onChangeTopUpForm,
              value: chooseAmountForm?.getValues()?.amount,
            }}
          >
            <div className='buy__checkout__container d-flex align-center secondary--text text-left pa-2'>
              <span className='caption tertiary--text ml-2 nowrap'>
                <span>
                  {isTopUp
                    ? t('addMoney.available')
                    : t('cashout.availableCash')}
                </span>
                <p>{cashAvailable}</p>
              </span>
            </div>
          </TextField>

          {!paymentMethods.loading && addMoney?.selectedPaymentMethod ? (
            <div className='--fade-in-animation-fast'>
              <SelectedPaymentMethodItem
                onChangeClick={() => setIsShown(true)}
              />
              {selectedMethodIsCard && recurringTopUpSummary}
              {isTopUp && selectedMethodIsBank && (
                <>
                  <Spacer preset='medium' />
                  <Card>
                    <ul className='default_list'>
                      <li>
                        <Text
                          text={t('addMoney.afterThePayment')}
                          preset='paragraph2'
                          color='secondary'
                        />
                      </li>
                      <li>
                        <Text
                          text={t('addMoney.forSecurityReasons')}
                          preset='paragraph2'
                          color='secondary'
                        />
                      </li>
                      <li>
                        <Text
                          text={t('addMoney.yourData')}
                          preset='paragraph2'
                          color='secondary'
                        />
                      </li>
                    </ul>
                  </Card>
                  <Spacer preset='smaller' />
                </>
              )}
            </div>
          ) : (
            <>
              <Spacer preset='small' />
              <Button
                text={t('addMoney.selectPaymentMethod')}
                type='submit'
                onClick={onTransfer}
                disabled={paymentMethods.loading}
                textPreset='subtitle2'
              />
              <Spacer preset='large' />
            </>
          )}

          {isTopUp && (
            <div className='w-100 pa-2 bg-secondary-base bradius-2 d-flex align-start'>
              <i className='d-flex mr-1'>
                <InfoIcon color='secondary' />
              </i>
              <div className='d-flex-col align-start flex '>
                <Text preset='bodyRegular' color='secondary'>
                  {t('addMoney.legal')}
                </Text>

                <div className='d-flex align-end flex justify-end'>
                  <Button
                    onClick={() => setIsAboutManualTransferShown(true)}
                    autoWidth
                    text={t('addMoney.learnMore')}
                    buttonType='link'
                    textPreset='paragraph2'
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </CustomModal.Content>

      {typeSelectorModal && (
        <CustomModal.OverlayContent>
          {typeSelectorModal}
        </CustomModal.OverlayContent>
      )}

      {recurringPaymentModal && (
        <CustomModal.OverlayContent>
          {recurringPaymentModal}
        </CustomModal.OverlayContent>
      )}

      {isShown && (
        <CustomModal.OverlayContent>
          <ChangePaymentMethod
            methods={methods}
            show={isShown}
            onClose={() => setIsShown(false)}
            onTransfer={() => {
              setIsShown(false)
              onTransfer()
            }}
          />
        </CustomModal.OverlayContent>
      )}

      {isAboutManualTransferShown && (
        <CustomModal.OverlayContent>
          <AboutManualTransfer
            show={isAboutManualTransferShown}
            onClose={() => setIsAboutManualTransferShown(false)}
            onTransfer={() => setIsAboutManualTransferShown(false)}
          />
        </CustomModal.OverlayContent>
      )}

      {isShownToken && (
        <CustomModal.OverlayContent>
          <ReconnectBank
            onClose={cancelActions}
            show={isShownToken}
            onAccept={handleTokenPSD}
          />
        </CustomModal.OverlayContent>
      )}

      <CustomModal.Footer>
        <Button
          text={
            isTopUp
              ? t('addMoney.registerOrder')
              : t('withdrawMoney.confirmWithdrawal')
          }
          loading={
            paymentMethods.loading || loading || addMoney.loadingMethodStatus
          }
          disabled={
            disabled ||
            minimumNotPass(
              sanitizeNumber(chooseAmountForm.getValues().amount as string),
            )
          }
          type='submit'
          size='big'
          onClick={checkMethod}
        />
      </CustomModal.Footer>
    </>
  )
}
