import { BasicLink } from './../basicLink/basicLink'
import { t } from 'i18next'
import './calendar.styles.scss'

export const CalendarHeader = ({
  onCancel,
  headerTitle,
  headerSubtitle,
}: {
  headerTitle: string
  headerSubtitle: string
  onCancel: () => void
}): JSX.Element => {
  return (
    <div className='calendar__row calendar__header-container'>
      <div className='d-flex d-flex-col align-start'>
        <div className='paragraph2'>{headerTitle}</div>
        <div className='paragraph2 tertiary--text'>{headerSubtitle}</div>
      </div>
      <BasicLink
        onClick={onCancel}
        text={t('common.cancel')}
        className='paragraph2 text-right'
      />
    </div>
  )
}
