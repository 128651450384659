import { TabBarProps, TabItem } from './buttonedTabBarPrimary.props'
import './buttonedTabBarPrimary.styles.scss'

export function ButtonedTabBarPrimary<T>({
  tabsStyle,
  tabs,
  activeTab,
  setActiveTab,
  loading = false,
  width,
}: TabBarProps<T>): JSX.Element {
  const handleClick = (id: T): void => {
    if (id && tabs?.length && !loading) {
      setActiveTab(id)
    }
  }

  const getItem = (item: TabItem<T>): JSX.Element => {
    return (
      <button
        key={(item.id as string)?.toString()}
        className={`buttoned-tab-bar-primary__tab
        ${width === 'full' ? 'w-100' : 'width-fit-content'}
        ${
          activeTab === item.id ? 'buttoned-tab-bar-primary__tab--active' : ''
        }`}
        onClick={() => handleClick(item.id)}
      >
        {item.title}
      </button>
    )
  }

  return (
    <div
      className={`buttoned-tab-bar-primary ${
        width === 'full' ? 'w-100' : 'width-fit-content'
      }`}
    >
      <div className={`buttoned-tab-bar-primary__tabs ${tabsStyle as string}`}>
        {tabs?.length ? tabs.map((t: TabItem<T>) => getItem(t)) : null}
      </div>
    </div>
  )
}
