import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState, fetchAssets } from 'store'

/**
 * This hook load the initial data used in the searchbar and in the discover page (all assets tab).
 * Data is only loaded if it hasn't been loaded yet.
 */
export const useInitialSearchLoad = (): void => {
  const dispatch = useDispatch()

  const search = useSelector((state: RootState) => state?.search)
  const { token } = useSelector((state: RootState) => state?.login)

  // Initial data loading for header searchbar
  useEffect(() => {
    if (!search?.initialData?.length && !search?.loading && token) {
      dispatch(
        fetchAssets({
          query: null,
          type: 'initial',
          origin: null,
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
