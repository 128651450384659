import {
  UIIcon,
  UIQuaternaryColorProp,
  getIconColorSVGFill,
} from 'components/utils'

export const GrowthIcon = ({
  size = 24,
  color = 'actionPrimaryInitial',
  quaternaryColor = 'iconNeutralSoft',
  secondaryColor = 'iconNeutralSoft',
  tertiaryColor = 'transparent',
}: UIIcon & UIQuaternaryColorProp): JSX.Element => {
  return (
    <svg width={size} height={size} viewBox='0 0 49 48' fill='none'>
      <circle
        cx='24.5'
        cy='24'
        r='23'
        fill={getIconColorSVGFill(tertiaryColor)}
        stroke={getIconColorSVGFill(secondaryColor)}
        strokeWidth='2'
      />
      <path
        d='M38.5652 18.7874C39.6174 21.6265 39.7857 24.7175 39.0481 27.6541C38.3105 30.5907 36.7015 33.2353 34.4326 35.2402C32.1638 37.2451 29.3413 38.5165 26.3362 38.8871C23.3312 39.2578 20.2844 38.7104 17.5963 37.3168C14.9083 35.9233 12.7049 33.7489 11.2759 31.0796C9.8468 28.4103 9.25902 25.371 9.58979 22.3613C9.92055 19.3516 11.1544 16.5125 13.129 14.2173C15.1037 11.922 17.7267 10.2781 20.6533 9.50159L24 24.5001L38.5652 18.7874Z'
        fill={getIconColorSVGFill(color)}
      />
      <path
        d='M21.7123 9.26134C24.9718 8.64483 28.3438 9.12621 31.3006 10.6302C34.2573 12.1341 36.6322 14.5758 38.0535 17.5732L24.9999 23L21.7123 9.26134Z'
        fill={getIconColorSVGFill(quaternaryColor)}
      />
    </svg>
  )
}
