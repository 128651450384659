import './selectorInput.styles.scss'
import { SelectorProps } from './selector.props'
import { ReactComponent as ChevronDown } from '@assets/svg/chevronDown.svg'

export function SelectorInput({
  label,
  selection,
  description,
  disabled,
  onClick,
  containerClassName,
  size = 'normal',
}: SelectorProps): JSX.Element {
  return (
    <div
      className={`selector ${containerClassName} ${
        disabled ? '--disabled' : ''
      } --size-${size}`}
      aria-hidden='true'
      role='button'
      onClick={!disabled ? onClick : null}
    >
      <p className='selector__label paragraph3'>{label}</p>
      <div className='text-right w-100'>
        <p className='paragraph2 --capitalize'>{selection}</p>
        {description && (
          <p className='paragraph3 tertiary--text'>{description}</p>
        )}
      </div>

      <ChevronDown className='selector__icon ml-1 icon--medium' />
    </div>
  )
}
