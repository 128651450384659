import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import { CollectionItemProps, CollectionsState } from '@interfaces'
import { fetchCollections } from './thunk'

const initialState: CollectionsState = {
  collections: [],
  error: null,
  loading: false,
}

export const reducers = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchCollections.pending, (acc) => {
      acc.loading = true
      acc.error = null
    })
    .addCase(
      fetchCollections.fulfilled,
      (acc, action: PayloadAction<CollectionItemProps[]>) => {
        acc.collections = action?.payload?.filter((c) => c?.stocks > 0)
        acc.loading = false
        acc.error = null
      },
    )
    .addCase(fetchCollections.rejected, (acc, { error }) => {
      acc.loading = false
      acc.error = error.message || ''
    })
})

export default reducers
