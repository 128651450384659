import { UIIcon, UIIconColorProps, getIconColorSVGFill } from 'components/utils'

export const UndoIcon = ({
  color,
  size = 24,
}: UIIcon & UIIconColorProps): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.5 12C9.85 12 7.45 12.99 5.6 14.6L3.71 12.71C3.08 12.08 2 12.52 2 13.41V19C2 19.55 2.45 20 3 20H8.59C9.48 20 9.93 18.92 9.3 18.29L7.39 16.38C8.78 15.22 10.55 14.5 12.51 14.5C15.67 14.5 18.4 16.34 19.7 19C19.97 19.56 20.61 19.84 21.2 19.64C21.91 19.41 22.27 18.6 21.95 17.92C20.23 14.42 16.65 12 12.5 12Z'
        fill={color ? getIconColorSVGFill(color) : 'currentColor'}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 9C9 6.235 11.235 4 14 4C16.765 4 19 6.235 19 9C19 11.765 16.765 14 14 14C11.235 14 9 11.765 9 9ZM15.445 11.15C15.64 11.345 15.955 11.345 16.15 11.15C16.34 10.955 16.34 10.635 16.15 10.445L14.705 9.00001L16.15 7.55501C16.345 7.36001 16.345 7.04501 16.15 6.85001C15.955 6.65501 15.64 6.65501 15.445 6.85001L14 8.29501L12.555 6.85001C12.36 6.65501 12.045 6.65501 11.85 6.85001C11.7564 6.94343 11.7038 7.07026 11.7038 7.20251C11.7038 7.33477 11.7564 7.4616 11.85 7.55501L13.295 9.00001L11.85 10.445C11.7564 10.5384 11.7038 10.6653 11.7038 10.7975C11.7038 10.9298 11.7564 11.0566 11.85 11.15C12.045 11.345 12.36 11.345 12.555 11.15L14 9.70501L15.445 11.15Z'
        fill={color ? getIconColorSVGFill(color) : 'currentColor'}
      />
    </svg>
  )
}
