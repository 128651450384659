/* eslint-disable @typescript-eslint/no-floating-promises */
window.global ||= window
import { AnalyticsBrowser } from '@segment/analytics-next'
import { HeyTradeEventProps } from '@utils/eventTracker/eventParams'
import { environment } from '../../config'

class TrackingService {
  analytics: AnalyticsBrowser
  private writeKey: string = environment?.segmentKey

  constructor() {
    this.analytics = AnalyticsBrowser.load({
      writeKey: this.writeKey,
    })
  }

  trackEvent = ({ event, props }: HeyTradeEventProps): void => {
    this.analytics
      ?.track(event, {
        client: 'web',
        ...props,
      })
      .finally(() => {
        // Do nothing
      })
  }

  trackLink = (
    link: HTMLElement | null,
    event: string,
    eventDescription?: string,
    eventProperties = {},
  ): void => {
    this.analytics
      ?.trackClick(link, event, {
        client: 'web',
        eventDescription: eventDescription as string,
        ...eventProperties,
      })
      .finally(() => {
        // Do nothing
      })
  }

  trackForm = (
    form: HTMLFormElement | null,
    event: string,
    eventDescription?: string,
    eventProperties = {},
  ): void => {
    this.analytics
      ?.trackSubmit(form, event, {
        client: 'web',
        eventDescription: eventDescription as string,
        ...eventProperties,
      })
      .finally(() => {
        // Do nothing
      })
  }

  trackPage = (page: string): void => {
    this.analytics
      ?.page(`[Navigation]`, { client: 'web', page: page })
      .finally(() => {
        // Do nothing
      })
  }

  identifyUser = (userId: string, email: string): void => {
    this.analytics?.identify(userId, { email, client: 'web' }).finally(() => {
      // Do nothing
    })
  }
}

export const trackingService = new TrackingService()
