import { Money } from './money'
import { OrderTransactionStatusEnum } from './orderTransaction'
import { OrderTypeEnum } from './orderType'

export interface PreOrderRequest {
  shares: number
  type: OrderOperationTypeEnum
  assetId: string
  method: OrderTypeEnum
  price?: number
  expirationDate?: string
}

export interface OrderRequest {
  preOrderId: string
}

export interface CommissionsData {
  forexFees: {
    amount: Money
    rate: number
  }
  custodyFees: {
    amount: Money
    rate: number
  }
  financialInstrumentFees: {
    amount: Money
    rate: number
  }
  thirdPartyPayments: {
    amount: Money
    rate: number
  }
  executionFees: {
    amount: Money
    rate: number
  }
  totalCommission: Money
}

export interface PreOrderData {
  id: string
  asset: {
    id: string
    name: string
    isin: string
    ticker: string
    market: string
    currency: string
  }
  shares: number
  type: OrderOperationTypeEnum
  method: OrderTypeEnum
  expirationDate: string
  cashAvailableForInvesting: Money
  contingenciesBuffer: number
  contingenciesBufferAmount: Money
  enoughCashForOrder: true
  fees: Money
  forexRate: number
  netAmount: Money
  netAmountInPortfoliosCurrency: Money
  requiredNetAmount: Money
  grossAmount: Money
  lockedCash: Money
  marketTaxes: Money
  marketTaxesInfoUrl: string
  price: Money
  commissions: CommissionsData
  totalCost: Money
}

export enum PreOrderModalType {
  taxes = 'TAXES',
  info = 'INFO',
  balance = 'BALANCE',
}

export interface OrderData {
  id: string
  asset: {
    id: string
    name: string
    isin: string
    ticker: string
    market: string
    currency: string
  }
  shares: number
  type: OrderOperationTypeEnum
  method: OrderTypeEnum
  expirationDate?: string
  creationDate: string
  estimatedPrice?: Money
  forexRate: number
  status: OrderTransactionStatusEnum
  calculations: {
    contingenciesBuffer: number
    fees: Money
    grossAmount: Money
    lockedCash: Money
    marketTaxes: Money
    totalCost: Money
  }
}

export enum OrderOperationTypeEnum {
  buy = 'BUY',
  sell = 'SELL',
}

export enum MoneyOperationTypeEnum {
  addMoney = 'ADD_MONEY',
  cashout = 'CASH_OUT',
  buy = 'BUY',
  sell = 'SELL',
}

export enum OperationTypeEnum {
  buy = 'BUY',
  sell = 'SELL',
  addMoney = 'ADD_MONEY',
  cashout = 'CASH_OUT',
  psd2BankLink = 'psd2BankLink',
}

export enum OperationConfirmationStatus {
  success = 'success',
  cancelled = 'cancelled',
  error = 'error',
}

export enum PaymentMethodTypeEnum {
  CARD = 'CARD',
  BANK = 'BANK',
  TRANSFER = 'TRANSFER',
  MANUAL_TRANSFER = 'MANUAL_TRANSFER',
}

export interface BankPaymentReviewDetails {
  concept: string
  amount: number
  currency: string
}
export interface CardPaymentReviewDetails {
  amount: number
  currency: string
}

export interface TopUpReviewDetails {
  method: PaymentMethodTypeEnum
  cardTopUpDetails: CardPaymentReviewDetails
  bankTopUpDetails: BankPaymentReviewDetails
}

export interface SetLimitOrderPayload {
  shares: number
  price: number
  expirationDate: string
}

export interface SetMarketOrderPayload {
  shares: number
}
