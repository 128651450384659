import { Cancel } from '@assets/icons'
import { Button, CustomModal, Spacer, Text } from '@components'
import { useTranslation } from '@hooks'

export const FailedCashoutModal = ({
  onCloseReset,
}: {
  onCloseReset: () => void
}): JSX.Element => {
  const { t } = useTranslation()

  return (
    <>
      <CustomModal.Header linkText={t('common.close')} onClick={onCloseReset} />
      <CustomModal.Content>
        <div className='d-flex d-flex-col align-center justify-center h-100 '>
          <Cancel color='negative' size={64} />
          <Spacer preset='smaller' />
          <Text
            text={t('common.somethingWentWrong')}
            preset='headline3'
            align='center'
          />
          <Spacer preset='smaller' />
          <Text
            preset='paragraph1'
            text={t('bankTransfer.cashout.errorMessage')}
            align='center'
          />
        </div>
      </CustomModal.Content>
      <CustomModal.Footer>
        <Button
          text={t('common.close')}
          onClick={onCloseReset}
          buttonType='primary'
        />
      </CustomModal.Footer>
    </>
  )
}
