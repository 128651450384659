import './themes.style.scss'
import { useTranslation } from '@hooks'
import { GoBack } from '@components'
import { changeTheme, getTheme } from '@utils/theme'
import { useState, useEffect } from 'react'
import { ListElement } from '../components/listElement'
import { useNavigate } from '@hooks'
import { routes } from 'navigation'
import { MoonIcon, SunIcon } from '@assets/icons'

export default function Themes(): JSX.Element {
  const { t } = useTranslation()
  const { navigate } = useNavigate()
  const [themeSwitch, setThemeSwitch] = useState(getTheme())

  const handleChange = (newTheme: string): void => {
    setThemeSwitch(newTheme)
  }

  useEffect(() => {
    changeTheme(themeSwitch)
  }, [themeSwitch])

  return (
    <div className='profile__widget theme__screen --fade-in-animation'>
      <div className='profile__widget__header'>
        <GoBack goBack={() => navigate(routes.auth.profile)} />
        <div className='title mt-3'>{t('themes.header')}</div>
      </div>
      <div className='profile__widget__content mt-5'>
        <div className='list__menu'>
          <ListElement
            title={t('themes.dark')}
            icon={<MoonIcon color='iconNeutralMedium' size={20} />}
            onClick={() => handleChange('dark')}
            target='_blank'
          />
          <ListElement
            title={t('themes.light')}
            icon={<SunIcon color='iconNeutralMedium' size={20} />}
            onClick={() => handleChange('light')}
            target='_blank'
          />
        </div>
      </div>
    </div>
  )
}
