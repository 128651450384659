import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import { randomId } from '@utils/helpers'
import {
  NewNotificationMessage,
  NotificationMessage,
  NotificationsState,
} from '@interfaces'
import { addNotification, removeNotification } from './thunk'

const initialState: NotificationsState = {
  notifications: [],
}

export const notificationsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      addNotification,
      (
        acc: NotificationsState,
        action: PayloadAction<NewNotificationMessage>,
      ) => {
        const id = randomId()

        const notification: NotificationMessage = {
          ...action.payload,
          id,
          removeAfter: action.payload.removeAfter
            ? action.payload.removeAfter
            : 4000,
        }

        acc.notifications.push(notification)
      },
    )
    .addCase(
      removeNotification,
      (acc: NotificationsState, action: PayloadAction<{ id: string }>) => {
        const { id } = action.payload
        acc.notifications = acc.notifications.filter((n) => n?.id !== id)
      },
    )
})
