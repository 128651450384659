import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import {
  BuySellTransaction,
  OrderTransaction,
  ReducerState,
  TransactionDetailType,
} from '@interfaces'
import {
  clearCancelledOrder,
  clearTransactionDetail,
  fetchOrderDetail,
  fetchTransactionDetail,
  setCancelOrder,
} from './thunk'
import { mapOrderTransaction } from '@utils/mapOrderTransaction'

export type TransactionDetailState = ReducerState & {
  transactionDetail: TransactionDetailType
  loadingCancelOrder: boolean
  cancelledOrder: boolean
}

const initialState: TransactionDetailState = {
  loading: true,
  error: null,
  transactionDetail: null,
  loadingCancelOrder: null,
  cancelledOrder: null,
}

const reducers = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchTransactionDetail.pending, (acc) => {
      acc.loading = true
      acc.error = null
    })
    .addCase(
      fetchTransactionDetail.fulfilled,
      (acc, action: PayloadAction<TransactionDetailType>) => {
        acc.loading = false
        acc.transactionDetail = action.payload
        acc.error = null
      },
    )
    .addCase(fetchOrderDetail.pending, (acc) => {
      acc.loading = true
      acc.error = null
    })
    .addCase(
      fetchOrderDetail.fulfilled,
      (acc, { payload }: PayloadAction<OrderTransaction>) => {
        const orderDetail: BuySellTransaction = mapOrderTransaction(payload)
        acc.loading = false
        acc.transactionDetail = orderDetail
        acc.error = null
      },
    )
    .addCase(fetchTransactionDetail.rejected, (acc, { error }) => {
      acc.error = error.message
      acc.loading = false
    })
    .addCase(setCancelOrder.pending, (acc) => {
      acc.loadingCancelOrder = true
      acc.error = null
    })
    .addCase(setCancelOrder.rejected, (acc, { error }) => {
      acc.error = error.message
      acc.loadingCancelOrder = false
    })
    .addCase(
      setCancelOrder.fulfilled,
      (acc, { payload }: PayloadAction<OrderTransaction>) => {
        const orderDetail: BuySellTransaction = mapOrderTransaction(payload)
        acc.loadingCancelOrder = false
        acc.transactionDetail = orderDetail
        acc.error = null
        acc.cancelledOrder = true
      },
    )
    .addCase(clearCancelledOrder, (acc) => {
      acc.cancelledOrder = null
    })
    .addCase(clearTransactionDetail, (acc) => {
      acc.loading = true
      acc.error = null
      acc.loadingCancelOrder = null
      acc.transactionDetail = null
    })
})

export default reducers
