import './calendar.styles.scss'
import { Button } from './../button/button'
import { useTranslation } from '@hooks'

export const CalendarFooter = ({
  onRestore,
  onSubmit,
  disabled,
}: {
  disabled: boolean
  onRestore: () => void
  onSubmit: () => void
}): JSX.Element => {
  const { t } = useTranslation()
  return (
    <div className='calendar__row calendar__footer-container d-flex justify-space-between'>
      <div aria-hidden onClick={onRestore}>
        <span aria-hidden onClick={onRestore}>
          <Button
            text={t('calendar.reset')}
            buttonType='link'
            onClick={onRestore}
            textPreset='paragraph2'
          />
        </span>
      </div>
      <div className='w-40 d-flex'>
        <Button
          text={t('calendar.set')}
          type='submit'
          onClick={onSubmit}
          disabled={disabled}
          textPreset='paragraph1'
        />
      </div>
    </div>
  )
}
