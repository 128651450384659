import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { getStocks } from '@services'
import { SearchParams, SearchState } from '@interfaces'

export const clearAssetsListDiscover = createAction('clearAssetsListDiscover')

export const fetchStocksDiscover = createAsyncThunk<SearchState, SearchParams>(
  'fetchStocksDiscover',
  async (data: SearchParams) => {
    const { query, page, assetTypes, selectedFilters } = data
    return await getStocks(query, page, assetTypes, selectedFilters)
  },
)
