import {
  UIIcon,
  UIQuaternaryColorProp,
  getIconColorSVGFill,
} from 'components/utils'

export const ConservativeIcon = ({
  size = 24,
  color = 'actionPrimaryInitial',
  quaternaryColor = 'iconNeutralSoft',
  secondaryColor = 'iconNeutralSoft',
  tertiaryColor = 'transparent',
}: UIIcon & UIQuaternaryColorProp): JSX.Element => {
  return (
    <svg width={size} height={size} viewBox='0 0 49 48' fill='none'>
      <circle
        cx='24.5'
        cy='24'
        r='23'
        fill={getIconColorSVGFill(tertiaryColor)}
        stroke={getIconColorSVGFill(secondaryColor)}
        strokeWidth='2'
      />
      <path
        d='M23.4352 9.03784C21.1853 9.19796 19.0005 9.86342 17.0433 10.9847C15.0862 12.106 13.4071 13.6542 12.1311 15.5142C10.855 17.3741 10.0149 19.4979 9.67314 21.7275C9.33142 23.957 9.49691 26.235 10.1573 28.3917L25 24.0002L23.4352 9.03784Z'
        fill={getIconColorSVGFill(color)}
      />
      <path
        d='M10.6605 29.7852C11.7002 32.2724 13.391 34.4334 15.5549 36.041C17.7189 37.6486 20.2761 38.6433 22.9576 38.9205C25.6391 39.1977 28.3457 38.7471 30.7927 37.6162C33.2398 36.4853 35.3369 34.7159 36.8634 32.4939C38.3899 30.272 39.2894 27.6798 39.4673 24.9899C39.6452 22.3001 39.0949 19.612 37.8744 17.2084C36.6538 14.8048 34.808 12.7745 32.5312 11.3312C30.2544 9.88782 27.6307 9.08472 24.9361 9.00635L26.5001 25L10.6605 29.7852Z'
        fill={getIconColorSVGFill(quaternaryColor)}
      />
    </svg>
  )
}
