import { useEffect, useRef, useState } from 'react'

export interface ModalContentProps {
  show?: boolean
  type?: string
  className?: string
  children?: React.ReactNode
}

export function ModalContent({
  className,
  children,
}: ModalContentProps): JSX.Element {
  const ref = useRef(null)
  const [scrollHeight, setScrollHeight] = useState(0)
  const [modalHeight, setModalHeight] = useState(0)

  const scrollVisible = scrollHeight > modalHeight

  const setHeights = (): void => {
    setScrollHeight(ref?.current?.scrollHeight as number)
    setModalHeight(ref?.current?.clientHeight as number)
  }

  // For when window size is resized
  useEffect(() => {
    setHeights()
    window.addEventListener('resize', setHeights)

    return () => {
      window.removeEventListener('resize', setHeights)
    }
  }, [])

  const modalContentStyle = `modal-content__content ${className as string} ${
    scrollVisible ? 'modal-content__scrollFix' : 'px-3'
  }`

  return (
    <div className={modalContentStyle} ref={ref}>
      {children}
    </div>
  )
}
