import { useTranslation, usePreOrderSections } from '@hooks'
import {
  CustomModal,
  GoBack,
  Button,
  Modal,
  SummaryCard,
  SummaryCardProps,
  Show,
  BigSpinner,
} from '@components'
import { useMemo, useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { resetOrder, sendOrderData } from '@store'
import {
  OrderOperationTypeEnum,
  OrderTypeEnum,
  PreOrderModalType,
} from '@interfaces'
import { useNavigate } from '@hooks'
import { trackingService } from '@services'
import { ReactComponent as ImportantIcon } from '@assets/svg/important.svg'
import { OrderSummaryCard } from 'components/orderSummaryCard/orderSummaryCard'
import { OrderSummaryCardProps } from 'components/orderSummaryCard/orderSummaryCard.props'
import { formatCurrency } from '@utils/helpers'
import {
  BuySellEvents,
  LimitOrderEvents,
  TopUpEvents,
} from '@utils/eventTracker/eventKeys'
import { BalanceInfoModal } from '@shared/modals'

export function AssetOperationReview(props: {
  nextStep: () => void
  prevStep: () => void
  onClose?: () => void
  onTopUp?: () => void
}): JSX.Element {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { navigate } = useNavigate()
  const [selected, setSelected] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isConfirmed, setConfirmed] = useState(false)
  const { buySell, companyPosition, userBalance, companyStocks } = useSelector(
    (state: RootState) => state,
  )

  const realAbbreviation = companyStocks?.symbol

  const [modalType, setModalType] = useState<PreOrderModalType>()
  const [isShownModal, setIsShownModal] = useState<boolean>(false)
  const [infoModalTitle, setInfoModalTitle] = useState<string>()
  const [infoModalContent, setInfoModalContent] = useState<string>()

  const checkout = buySell?.checkout
  const availableForSaleShares =
    companyPosition?.shares - companyPosition?.lockedShares
  const isBuy = checkout?.type === OrderOperationTypeEnum.buy

  const showBalanceModal = (): void => {
    setModalType(PreOrderModalType.balance)
    setIsShownModal(true)
  }

  const showTaxesModal = (): void => {
    setModalType(PreOrderModalType.taxes)
    setIsShownModal(true)
  }

  const showInfoModal = (title: string, content: string): void => {
    setModalType(PreOrderModalType.info)
    setInfoModalTitle(title)
    setInfoModalContent(content)
    setIsShownModal(true)
  }

  const cancelActions = (): void => {
    dispatch(resetOrder())
    props.prevStep()
  }

  const sections = usePreOrderSections(
    checkout,
    availableForSaleShares,
    showBalanceModal,
    showTaxesModal,
    showInfoModal,
  )

  const orderSummaryCardInfo = useMemo((): OrderSummaryCardProps => {
    const availableShares =
      companyPosition?.shares - companyPosition?.lockedShares
    return {
      logoUrl: companyStocks?.logoUrl,
      orderType: checkout?.type,
      orderMethod: checkout?.method,
      ticker: realAbbreviation,
      amount: checkout?.shares,
      total: formatCurrency(isBuy ? checkout?.lockedCash : checkout?.totalCost),
      estimatedTotal: isBuy ? formatCurrency(checkout?.totalCost) : null,
      available: isBuy
        ? formatCurrency(checkout?.cashAvailableForInvesting)
        : String(availableShares),
    }
  }, [
    checkout,
    companyPosition?.lockedShares,
    companyPosition?.shares,
    companyStocks?.logoUrl,
    isBuy,
    realAbbreviation,
  ])

  const readCarefullyRows = [
    t('buySellFlow.limitReadCarefully.firstRow', {
      contingenciesBuffer: checkout?.contingenciesBuffer * 100,
    }),
    t('buySellFlow.limitReadCarefully.secondRow'),
    t('buySellFlow.limitReadCarefully.thirdRow'),
    t('buySellFlow.limitReadCarefully.forthRow'),
  ]

  const isMarketOpen = companyStocks?.marketStatus?.status === 'OPEN'

  if (!isMarketOpen) {
    readCarefullyRows.splice(0, 0, t('buySellFlow.marketClosedReadCarefully'))
  }

  const confirmOrderText = t('buySellFlow.confirmOrder')

  const closeModal = (): void => {
    setIsShownModal(false)
  }

  const renderSection = (
    section: SummaryCardProps,
    idx: number,
  ): JSX.Element => {
    return <SummaryCard key={idx} {...section} className='mb-3' />
  }

  const renderPreOrderModal = (): JSX.Element => {
    switch (modalType) {
      case PreOrderModalType.taxes:
        return (
          <Modal
            className='mb-4'
            show={isShownModal}
            type='invalid'
            onButton={closeModal}
            text={t('buySellFlow.summary.taxesInfo.title')}
            buttonText={t('common.ok')}
          >
            <div className='d-flex d-flex-col align-center w-100'>
              <object
                data={checkout?.marketTaxesInfoUrl}
                height='200'
                width='100%'
                title='taxes'
              ></object>
            </div>
          </Modal>
        )

      case PreOrderModalType.balance:
        return (
          <BalanceInfoModal
            opts={{ showBackgrop: false }}
            close={() => setIsShownModal(false)}
          />
        )

      case PreOrderModalType.info:
        return (
          <Modal
            className='backdrop--none'
            text={infoModalTitle}
            show={isShownModal}
            buttonText={t('common.ok')}
            onButton={closeModal}
          >
            {infoModalContent}
          </Modal>
        )

      default:
        return (
          <Modal
            className='mb-4'
            show={isShownModal}
            type='error'
            onButton={closeModal}
          />
        )
    }
  }

  const sendOrder = useCallback(() => {
    if (checkout?.method === OrderTypeEnum.limit) {
      trackingService.trackEvent({
        event: LimitOrderEvents.confirmedPreorder,
      })
    }
    dispatch(
      sendOrderData({
        preOrderId: checkout?.id,
      }),
    )
  }, [dispatch, checkout])

  const handleTopUp = (): void => {
    props?.onTopUp && props.onTopUp()
    trackingService.trackEvent({
      event: TopUpEvents.modalOpened,
      props: {
        origin: 'buySellFlow',
        currentBalance: userBalance.cashActive.amount,
        totalCost: checkout?.totalCost?.amount,
      },
    })
  }

  const handleSelect = (): void => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      setSelected(true)
      trackingService.trackEvent({
        event: BuySellEvents.confirmOrder,
        props: {
          type: checkout?.type,
          method: checkout?.method,
          shares: checkout?.shares,
          ticker: realAbbreviation,
          id: companyStocks?.id,
          assetGroup: companyStocks?.type,
          totalCost: checkout?.totalCost?.amount,
        },
      })
    }, 1000)
  }

  const handleConfirmation = (): void => {
    setConfirmed(true)
    trackingService.trackEvent({
      event: BuySellEvents.sendOrder,
      props: {
        type: checkout?.type,
        method: checkout?.method,
        shares: checkout?.shares,
        ticker: realAbbreviation,
        id: companyStocks?.id,
        assetGroup: companyStocks?.type,
        totalCost: checkout?.totalCost?.amount,
      },
    })
  }

  useEffect(() => {
    if (!buySell.isOrderSuccess && isConfirmed === true) {
      sendOrder()
    }
    // In this case I don't want  react-hooks/exhaustive-deps
    // because I just need to check the isOrderSuccess variable
    // so that the useEffect isn't be fired more than once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buySell.isOrderSuccess, isConfirmed])

  useEffect(() => {
    if (isConfirmed && buySell.sendOrderError) {
      props.nextStep()
    }
  }, [buySell.sendOrderError, isConfirmed, props])

  useEffect(() => {
    if (isConfirmed && buySell.isOrderSuccess) {
      props.nextStep()
    }
  }, [
    buySell.isOrderSuccess,
    navigate,
    buySell.sendOrderError,
    buySell.isLoading,
    props,
    buySell.error,
    isConfirmed,
  ])

  return (
    <>
      <>
        <Show
          when={!buySell.isLoading}
          fallback={
            <div className='d-flex h-100 align-center'>
              <BigSpinner>
                <div className='headline4 text--primary mt-3'>
                  {t('buySellFlow.sendingOrder')}
                </div>
              </BigSpinner>
            </div>
          }
        >
          <>
            <CustomModal.Header
              text={t('buySellFlow.reviewTransaction')}
              linkText={t('common.cancel')}
              className='subtitle3 dark--label-title'
              onClick={props.onClose}
            >
              <GoBack
                goBack={() => {
                  cancelActions()
                }}
              />
            </CustomModal.Header>
            <CustomModal.Content className='d-flex-col'>
              <OrderSummaryCard {...orderSummaryCardInfo} />
              <div className='text-left'>
                {sections?.length
                  ? sections.map((s: SummaryCardProps, idx: number) => {
                      return renderSection(s, idx)
                    })
                  : null}
              </div>
              <div className='bg-secondary-base text-left secondary--text bradius-2'>
                <div className='d-flex align-center paragraph2 py-2 mx-2'>
                  <ImportantIcon className='mr-1' />
                  <p>{t('bankTransfer.cashout.followSteps')}</p>
                </div>
                {readCarefullyRows.map((e: string) => {
                  return (
                    <p
                      key={e}
                      className='text-secondary paragraph3 py-2 mx-2 border-top-default'
                    >
                      {e}
                    </p>
                  )
                })}
              </div>
            </CustomModal.Content>
            <CustomModal.Footer className='d-flex d-flex-col'>
              {checkout?.enoughCashForOrder ? (
                selected ? (
                  <div className='d-flex d-flex-row w-100'>
                    <div className='w-70 mr-1'>
                      <Button
                        text={confirmOrderText}
                        type='submit'
                        size='big'
                        onClick={handleConfirmation}
                      />
                    </div>
                    <div className='w-30 ml-1'>
                      <Button
                        text={t('common.cancel')}
                        type='submit'
                        size='big'
                        onClick={cancelActions}
                        buttonType='ghost--negative'
                      />
                    </div>
                  </div>
                ) : (
                  <div className='d-flex d-flex-col w-100'>
                    <Button
                      text={confirmOrderText}
                      type='submit'
                      size='big'
                      loading={loading}
                      onClick={handleSelect}
                    />
                  </div>
                )
              ) : (
                <div className='d-flex d-flex-row w-100'>
                  <div className='w-40 mr-2'>
                    <Button
                      text={t('buySellFlow.topUp')}
                      type='submit'
                      size='big'
                      onClick={handleTopUp}
                    />
                  </div>
                  <div className='w-60'>
                    <Button
                      text={t('buySellFlow.confirmPurchase')}
                      type='submit'
                      size='big'
                      disabled={true}
                    />
                  </div>
                </div>
              )}
            </CustomModal.Footer>
          </>
        </Show>
      </>
      {isShownModal && renderPreOrderModal()}
    </>
  )
}
