import './basicLink.styles.scss'
import { BasicLinkProps } from './basicLink.props'
import { Link } from 'react-router-dom'

export function BasicLink({
  text,
  url,
  type,
  className,
  children,
  color,
  contentSize = 'small',
  onClick,
  disabled = false,
}: BasicLinkProps): JSX.Element {
  const actualUrl = url ? url : (url = '/')
  const basicLinkColor = disabled
    ? 'link--disabled'
    : `link--${color as string}`

  const basicLinkStyle = `link ${color || disabled ? basicLinkColor : ''} ${
    className as string
  } ${
    contentSize != 'big'
      ? contentSize != 'small'
        ? 'smallText'
        : ''
      : 'bigText'
  }`
  return (
    <>
      {type == 'link' ? (
        <div className={basicLinkStyle}>
          <Link onClick={onClick} to={actualUrl} aria-disabled={disabled}>
            {text}
          </Link>
        </div>
      ) : (
        <button className={basicLinkStyle} onClick={onClick}>
          {text || children}
        </button>
      )}
    </>
  )
}
