import { Money } from './money'
import { CommissionsData, OrderOperationTypeEnum } from './orderData'
import {
  OrderAsset,
  OrderCancellationReason,
  OrderTransactionChildStatusEnum,
  OrderTransactionStatusEnum,
} from './orderTransaction'
import { OrderTypeEnum } from './orderType'

export enum TransactionType {
  withdrawal = 'WITHDRAWAL',
  deposit = 'DEPOSIT',
  sell = 'SELL',
  buy = 'BUY',
  dividendCash = 'DIVIDEND_CASH',
  dividendShares = 'DIVIDEND_SHARES',
}

export enum NotCancellableReason {
  marketOrder = 'MARKET_ORDER',
  marketClosed = 'MARKET_CLOSED',
  orderFinalized = 'ORDER_FINALIZED',
}

export interface Transaction {
  id: string
  title: string
  type: TransactionType
  logo: string
  date: string
  amount: Money
  ticker: string
  orderDetails: {
    type: OrderOperationTypeEnum
    status: OrderTransactionStatusEnum
    statusText: string
    ticker: string
    childStatus: OrderTransactionChildStatusEnum
    childStatusText: string
    method: OrderTypeEnum
    shares: {
      executed: number
      requested: number
    }
    sharePrice: Money
    isCancellable?: boolean
    notCancellableReason?: NotCancellableReason
    lockedCash?: Money
    reason?: OrderCancellationReason
    reasonText?: string
  }
  dividendSharesDetails?: {
    ticker: string
    shares: number
  }
}

export interface TransactionDetail {
  title: string
  ticker?: string
  type: TransactionType
  logo: string
  date: string
  amount: Money
  status: OrderTransactionStatusEnum
  documentId: string
}

export interface BuySellTransaction extends TransactionDetail {
  shares: {
    executed: number
    requested: number
  }
  asset: OrderAsset
  taxes: Money
  fees: Money
  totalCost: Money
  netAmount: Money
  netAmountInPortfoliosCurrency: Money
  exchangeRateAverage: number
  orderMethod: OrderTypeEnum
  childStatus: string
  childStatusText: string
  reason: OrderCancellationReason
  reasonText: string
  creationDate?: string
  estimatedPrice?: Money
  executedPrice: Money
  expirationDate?: string
  grossAmount?: Money
  lockedCash?: Money
  assetCurrency?: string
  statusText?: string
  contingenciesBuffer?: number
  contingenciesBufferAmount?: Money
  requiredNetAmount?: Money
  isCancellable?: boolean
  notCancellableReason?: NotCancellableReason
  commissions: CommissionsData
}

export interface DepositWithdrawalTransaction extends TransactionDetail {
  fees: Money
  account: string
}

export interface DividendTransaction extends TransactionDetail {
  sharesQuantity: number
}

export type TransactionDetailType =
  | BuySellTransaction
  | DepositWithdrawalTransaction
  | DividendTransaction
