export enum SortKey {
  name = 'name',
  position = 'position',
  totalPnl = 'totalPnl',
  price = 'price',
}

export type dataType = {
  key: SortKey
  label: string
}

export const tableHead: dataType[] = [
  { key: SortKey.name, label: 'common.name' },
  { key: SortKey.position, label: 'companyDetails.position' },
  { key: SortKey.totalPnl, label: 'common.totalPnl' },
  { key: SortKey.price, label: 'buySellFlow.summary.price' },
]
