import _ from 'lodash'
import { LoginResult } from './authApi'

export interface AppSessionStorageData {
  user?: LoginResult
  flags?: {
    hideSearchBlackrockBanner?: boolean
  }
}

export class SessionStorageService {
  private key = 'htsessionstorage'

  public getSessionState(): AppSessionStorageData | null {
    try {
      const state = sessionStorage.getItem(this.key)
      return state ? JSON.parse(state) : null
    } catch (error) {
      return null
    }
  }

  public setSessionState(update: AppSessionStorageData): void {
    sessionStorage.setItem(this.key, JSON.stringify(update))
  }

  public updateSessionState(update: Partial<AppSessionStorageData>): void {
    const currentState = this.getSessionState()
    const updated = _.merge(currentState, update)
    sessionStorage.setItem(this.key, JSON.stringify(updated))
  }

  clear(): void {
    sessionStorage.clear()
  }
}

export const sessionStorageService = new SessionStorageService()
