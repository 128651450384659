import { UIIcon, UIIconColorProps, getIconColorSVGFill } from 'components/utils'

export const PieIcon = ({
  color,
  size = 24,
}: UIIcon & UIIconColorProps): JSX.Element => {
  return (
    <svg width={size} height={size} viewBox='0 0 20 20' fill='none'>
      <path
        d='M9 3.08V0C4 0.5 0 4.81 0 10C0 15.19 4 19.5 9 20V16.92C6 16.44 3 13.52 3 10C3 6.48 6 3.56 9 3.08ZM16.97 9H20C19.53 4 16 0.47 11 0V3.08C14 3.51 16.54 6 16.97 9ZM11 16.92V20C16 19.53 19.53 16 20 11H16.97C16.54 14 14 16.49 11 16.92Z'
        fill={color ? getIconColorSVGFill(color) : 'currentColor'}
      />
    </svg>
  )
}
