import _ from 'lodash'

export interface AppLocalStorageData {
  hideAmounts?: boolean
  hideDiversificationCardOnPortfolio?: boolean
}

export class LocalStorageService {
  private key = 'htlocalstorage'

  public getLocalState(): AppLocalStorageData | null {
    try {
      const state = localStorage.getItem(this.key)
      return state ? JSON.parse(state) : null
    } catch (error) {
      return null
    }
  }

  public setLocalState(update: AppLocalStorageData): void {
    localStorage.setItem(this.key, JSON.stringify(update))
  }

  public updateLocalState(update: Partial<AppLocalStorageData>): void {
    const currentState = this.getLocalState()
    const updated = _.merge(currentState, update)
    localStorage.setItem(this.key, JSON.stringify(updated))
  }

  clear(): void {
    localStorage.clear()
  }
}

export const localStorageService = new LocalStorageService()
