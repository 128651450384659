import { ReactNode } from 'react'

export interface ShowProps {
  when: boolean
  fallback?: JSX.Element
  children: ReactNode
}

export const Show = (props: ShowProps): JSX.Element => {
  return <>{props.when ? props?.children : props?.fallback || null}</>
}
