import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import { PortfolioValuation, ReducerState } from '@interfaces'
import { fetchProfileValuation } from './thunk'

export interface IValuation {
  valuation: PortfolioValuation
}
const initialState: IValuation & ReducerState = {
  loading: false,
  error: null,
  valuation: [],
  hardLoading: false,
}

export const portfolioValuationReducer = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(fetchProfileValuation.pending, (acc: ReducerState) => {
        acc.loading = true
        acc.error = null
      })
      .addCase(
        fetchProfileValuation.fulfilled,
        (acc, action: PayloadAction<PortfolioValuation>) => {
          const { payload } = action
          acc.valuation = payload
          acc.error = null
          acc.loading = false
        },
      )
      .addCase(fetchProfileValuation.rejected, (acc, { error }) => {
        acc.loading = false
        acc.error = error.message
      })
  },
)
