import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import {
  Login2FAResult,
  login2fa,
  Login2FAData,
  LoginData,
  LoginResult,
  login,
  trackingService,
} from '@services'
import { promiseWrapper } from '@utils/helpers'
import { sessionStorageService } from 'services/session-storage'

export const setCredentials = createAction<{ email: string; password: string }>(
  'setCredentials',
)

export const fetch2fa = createAsyncThunk<Login2FAResult, LoginData>(
  'login2fa',
  async (data: LoginData) => {
    return await login2fa(data.email, data.password)
  },
)

export const verify2fa = createAsyncThunk<LoginResult, Login2FAData>(
  'login',
  async (data: Login2FAData) => {
    const res = await login(data?.email, data?.challenge)
    if (res.accessToken) {
      await promiseWrapper(() => {
        sessionStorageService.setSessionState({ user: res })
      })
      trackingService.identifyUser(res.id, data.email)
    }
    return res
  },
)

export const recoverToken = createAsyncThunk<LoginResult>(
  'recoverToken',
  async () => {
    return await promiseWrapper(() => {
      return sessionStorageService.getSessionState()?.user
    })
  },
)

export const doLogout = createAsyncThunk<void>('logout', (): void => {
  sessionStorageService.clear()
})
