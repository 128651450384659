import { Button, CustomModal, Spacer, Text } from '@components'
import { useTranslation } from '@hooks'

export const OutOfHoursOrderModal = ({
  close,
}: {
  close: () => void
}): JSX.Element => {
  const { t } = useTranslation()

  return (
    <CustomModal
      size='small'
      show={true}
      onClose={close}
      dismissable
      theme='lighter'
    >
      <CustomModal.Header
        text={t('companyDetails.setAnOutOfHoursOrderTitle')}
      />
      <CustomModal.Content>
        <div className='d-flex d-flex-col w-100'>
          <Text
            align='center'
            preset='paragraph2'
            text={t('companyDetails.setAnOutOfHoursOrderDescI')}
          />
          <Spacer preset='small' />
          <Text
            align='center'
            preset='paragraph2'
            text={t('companyDetails.setAnOutOfHoursOrderDescII')}
          />
        </div>
      </CustomModal.Content>
      <CustomModal.Footer>
        <Button onClick={() => close()} text={t('common.close')} />
      </CustomModal.Footer>
    </CustomModal>
  )
}
