import { PortfolioBalance, PortfolioValuation } from '../interfaces'
import { api } from '@utils/api/api'

export async function getPortfolioBalance(): Promise<PortfolioBalance> {
  return api.get('/api/v1/portfolio/balance')
}

export async function getPortfolioValuationApi(
  params,
): Promise<PortfolioValuation> {
  return api.get(
    `/api/v1/portfolio/${params.portfolioId}/valuation?date=${params.portfolioValuationDate}`,
  )
}
