import { routes } from 'navigation'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useNavigate } from '@hooks'
import { useDispatch } from 'react-redux'
import { setMoneyOperationStep } from './thunk'
import { MoneyOperationsSteps } from './moneyOperations.props'
import {
  OperationConfirmationStatus,
  OperationTypeEnum,
  PaymentMethodTypeEnum,
} from '@interfaces'

export function ConfirmationPage(): JSX.Element {
  const { navigate } = useNavigate()
  const dispatch = useDispatch()
  const searchParams = useLocation().search

  useEffect(() => {
    const urlParams = new URLSearchParams(searchParams)
    const urlFrom = window?.localStorage?.getItem('urlFrom')
    const operation = urlParams.get('operation') as OperationTypeEnum
    const status = urlParams.get('status') as OperationConfirmationStatus
    const method = urlParams.get('method') as PaymentMethodTypeEnum
    const isSuccess = status === OperationConfirmationStatus.success

    const succesfulPSDLink =
      operation === OperationTypeEnum.psd2BankLink && isSuccess

    const topUpWithBankMethod =
      operation === OperationTypeEnum.addMoney &&
      method === PaymentMethodTypeEnum.BANK

    const topUpWithCardMethod =
      operation === OperationTypeEnum.addMoney &&
      method === PaymentMethodTypeEnum.CARD

    const cashOutWithBankMethod =
      operation === OperationTypeEnum.cashout &&
      method === PaymentMethodTypeEnum.BANK

    const cashOutWithCardMethod =
      operation === OperationTypeEnum.cashout &&
      method === PaymentMethodTypeEnum.CARD

    if (cashOutWithBankMethod && isSuccess) {
      dispatch(setMoneyOperationStep(MoneyOperationsSteps.completedCashout))
    }

    if (succesfulPSDLink) {
      dispatch(setMoneyOperationStep(MoneyOperationsSteps.bankConnectionResult))
    }

    if (topUpWithBankMethod && isSuccess) {
      dispatch(setMoneyOperationStep(MoneyOperationsSteps.paymentSubmitted))
    }

    if (topUpWithCardMethod || cashOutWithCardMethod) {
      dispatch(setMoneyOperationStep(MoneyOperationsSteps.completedCardPayment))
    }

    navigate({
      pathname: urlFrom || routes.auth.home,
      search: searchParams,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <></>
}
