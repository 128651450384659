import './container.styles.scss'
import { stringBuilder } from 'components/utils'
import { useMemo } from 'react'

export const Container = ({
  children,
  size,
}: {
  children: JSX.Element
  size: 'large' | 'normal'
}): JSX.Element => {
  const classes = useMemo(() => {
    return stringBuilder([
      ['custom-container', true],
      [`--size-${size}`, !!size],
    ])
  }, [size])

  return <div className={classes}>{children}</div>
}
