import './filtersContainer.styles.scss'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button } from '@components'
import { FilterValue } from '@interfaces'
import { RawTreeNode } from 'tr33'
import { FilterDropdownContent } from '../filterDropdownContent/filterDropdownContent'
import { DropdownState } from 'components/dropdown'
import { useDropdownState } from 'components/dropdown/hooks/useDropdownState'
import { FilterButton } from '../filterButton/filterButton'
import { Cancel } from '@assets/icons'
import { useClickOutsideElem } from 'hooks/useClickOutsideElem'
import { FiltersCheckboxOptions } from '../filterDropdownContent/filtersCheckboxOptions'
import { FiltersContainerProps } from './filtersContainer.props'
import { findNode, getTrees } from '@utils/filters'

export const FiltersContainer = ({
  filters,
  handleResetAllFilters,
  handleOnClearFilter,
  handleOnPress,
  disabled = false,
  isLoading = false,
  focusedFilter,
  onClose,
  onGoBack,
  focusFilter,
  toggleFilter,
  filtersCustomizations,
}: FiltersContainerProps): JSX.Element => {
  const buttonsContainerRef = useRef<HTMLDivElement>(null)

  const { dropdownClasses, dropdownState, openDropdown, startDropdownClosing } =
    useDropdownState()

  const [dropdownLeftPosition, setDropdownLeftPosition] = useState(0)
  const [
    dropdownLeftPositionFromWindowLeft,
    setDropdownLeftPositionFromWindowLeft,
  ] = useState(0)

  const getActiveFilters = useCallback(
    (filter: RawTreeNode<FilterValue>): number => {
      const hasChildren = filter?.children?.length

      if (!hasChildren) {
        return filter?.value?.checked ? 1 : 0
      }
      return filter?.children?.reduce((acc, i) => acc + getActiveFilters(i), 0)
    },
    [],
  )

  const someFilterActive = useMemo(() => {
    return filters?.some((f) => getActiveFilters(f) > 0)
  }, [filters, getActiveFilters])

  const updatePosition = (buttonLeftPosition: number): void => {
    const containerRef = buttonsContainerRef?.current?.getBoundingClientRect()
    setDropdownLeftPosition(buttonLeftPosition - containerRef?.left)
    setDropdownLeftPositionFromWindowLeft(buttonLeftPosition)
  }

  useClickOutsideElem(['filters__item', 'filters-dropdown'], () => {
    if (dropdownState === DropdownState.OPENED) {
      startDropdownClosing()
      onClose()
    }
  })

  const focusedFilterRootId: string = useMemo(() => {
    if (!focusedFilter?.id) return null
    const trees = getTrees(filters)
    const node = findNode(trees, focusedFilter?.id)

    let found = null
    let currentNode = node

    while (!found) {
      const parentId = currentNode?.getParentId()
      if (!parentId) found = currentNode?.id()
      currentNode = trees?.find((f) => f?.find(parentId))
    }

    return found
  }, [filters, focusedFilter?.id])

  const activePanelIdx = filters?.findIndex(
    (i) => i?.id === focusedFilterRootId,
  )

  useEffect(() => {
    if (!focusedFilter?.id && dropdownState === DropdownState.OPENED) {
      startDropdownClosing()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusedFilter])

  return (
    <div className='filters d-flex align-center'>
      <div className='d-flex d-flex-col'>
        <div className='d-flex' ref={buttonsContainerRef}>
          {filters?.map((f) => (
            <div className='filters__item' key={f?.id}>
              <FilterButton
                activeFiltersNumber={getActiveFilters(f)}
                isActive={
                  getActiveFilters(f) > 0 ||
                  filtersCustomizations?.[f?.id]?.active
                }
                title={filtersCustomizations?.[f?.id]?.title || f?.value?.name}
                disabled={isLoading || disabled}
                handleOnClearFilter={() => handleOnClearFilter(f?.id)}
                onPress={(buttonLeftPosition) => {
                  updatePosition(buttonLeftPosition)
                  handleOnPress(f)
                  if (
                    dropdownState === DropdownState.OPENED &&
                    f?.id !== focusedFilter.id
                  ) {
                    openDropdown()
                  } else {
                    if (dropdownState === DropdownState.CLOSED) openDropdown()
                    if (dropdownState === DropdownState.OPENED)
                      startDropdownClosing()
                  }
                }}
              />
            </div>
          ))}
          {someFilterActive ? (
            <div className='filters__item'>
              <Button
                buttonType='secondary'
                shape='square'
                size='small'
                autoWidth
                onClick={() => {
                  handleResetAllFilters()
                  startDropdownClosing()
                }}
                left={<Cancel size={20} color='iconPrimaryInitial' />}
              />
            </div>
          ) : null}
        </div>
        <div className='position-relative'>
          <FilterDropdownContent
            filters={filters}
            focusedFilter={focusedFilter}
            dropdownState={dropdownState}
            dropdownClasses={dropdownClasses}
            dropdownLeftPosition={{
              fromButton: dropdownLeftPosition,
              fromWindowLeftSide: dropdownLeftPositionFromWindowLeft,
            }}
            activePanelIdx={activePanelIdx}
            key={`filter-dropdown`}
          >
            {filters?.map((filter) => {
              if (filtersCustomizations?.[filter?.id]?.component) {
                return filtersCustomizations?.[filter?.id]?.component
              } else {
                return (
                  <FiltersCheckboxOptions
                    onGoBack={onGoBack}
                    filter={filter}
                    onClose={() => {
                      startDropdownClosing()
                      onClose()
                    }}
                    focusedFilter={focusedFilter}
                    focusedFilterRootId={focusedFilterRootId}
                    key={`filter-panel-${filter?.id}`}
                    onReset={() => handleOnClearFilter(focusedFilterRootId)}
                    focusFilter={focusFilter}
                    toggleFilter={toggleFilter}
                  />
                )
              }
            })}
          </FilterDropdownContent>
        </div>
      </div>
    </div>
  )
}
