import { Avatar, Spacer, Text, TextField } from '@components'
import { BuyLimitOrderIcon } from '@assets/icons/buyLimitOrderIcon'
import { ChangeEvent, useEffect, useMemo } from 'react'
import { ChevronDown, InfoIcon } from '@assets/icons'
import {
  backEndFormat,
  formatCurrency,
  formatMoney,
  numOfDaysBetween,
  sanitizeNumber,
} from '@utils/helpers'
import { getFormatLang, getLang } from '@utils/langs'
import { LimitOrderPriceLimitsState, OrderOperationTypeEnum } from '@interfaces'
import { OperationCardBase } from '../operationCardBase'
import { RootState, setLimitOrderDetails } from '@store'
import { SellLimitOrderIcon } from '@assets/icons/sellLimitOrderIcon'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useOnChangeMoneyForm, useTranslation } from '@hooks'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import format from 'date-fns/format'
import { addDays } from 'date-fns'
import { formatInputNumber } from '@utils/formatting'

export const LimitOrderCard = ({
  type,
  openCalendar,
  showLimitBuyInfoModal,
  showLimitSellInfoModal,
  showAvailableInfoModal,
  focusOnInit,
  disabled,
}: {
  type: OrderOperationTypeEnum
  openCalendar: () => void
  showLimitBuyInfoModal: () => void
  showLimitSellInfoModal: () => void
  showAvailableInfoModal: () => void
  focusOnInit: boolean
  disabled?: boolean
}): JSX.Element => {
  const { t } = useTranslation()
  const lang = getFormatLang()
  const maxDecimalsLimitOrder = 4
  const dispatch = useDispatch()

  const translateReq = t('common.fieldRequired')

  const { companyStocks, userBalance, companyPosition, buySell } = useSelector(
    (state: RootState) => state,
  )

  const orderOperationType = buySell?.orderUserInput?.orderOperationType

  const isBuy = orderOperationType === OrderOperationTypeEnum.buy

  const expirationDate = format(
    new Date(addDays(new Date(), 30)),
    backEndFormat,
  )

  useEffect(() => {
    dispatch(
      setLimitOrderDetails({
        shares: buySell?.orderUserInput?.limitOrder?.shares,
        price: buySell?.orderUserInput?.limitOrder?.price,
        expirationDate: expirationDate,
      }),
    )
    // On component mount set default date on state
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const maxShares = companyPosition?.shares - companyPosition?.lockedShares

  const { isLoading }: LimitOrderPriceLimitsState = useSelector(
    (state: RootState) => state?.limitOrderPriceLimits,
  )

  const dateSelectionText = useMemo(() => {
    const daysBetween = numOfDaysBetween(
      new Date(),
      new Date(
        buySell?.orderUserInput?.limitOrder?.expirationDate || expirationDate,
      ),
    )

    if (daysBetween === 0) {
      return t('date.today')
    }

    return `${daysBetween.toString()} ${
      daysBetween === 1 ? t('common.day') : t('common.days')
    }`
  }, [buySell?.orderUserInput?.limitOrder?.expirationDate, expirationDate, t])

  const schemaReset = yup.object().shape({
    shares: yup.string().required(translateReq),
    limitPrice: yup.string().required(translateReq),
  })
  const limitOperationForm = useForm({
    resolver: yupResolver(schemaReset),
    criteriaMode: 'all',
    mode: 'onChange',
  })

  const decimalSeparator = getLang() == 'en-US' ? '.' : ','
  const decimalsAmount = companyStocks?.price?.amount
    ?.toString()
    .split('.')[1]?.length

  const onChangeLimitForm = useOnChangeMoneyForm(
    limitOperationForm,
    'limitPrice',
    decimalsAmount > maxDecimalsLimitOrder
      ? maxDecimalsLimitOrder
      : decimalsAmount,
    (v) => {
      dispatch(
        setLimitOrderDetails({
          price: Number(v),
          shares: buySell?.orderUserInput?.limitOrder?.shares,
          expirationDate: buySell?.orderUserInput?.limitOrder?.expirationDate,
        }),
      )
    },
  )

  const onChangeSharesForm = async (
    e: ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    const sanitized = sanitizeNumber(e?.target?.value)

    const validValue = sanitized && !isNaN(Number(sanitized))
    const value = formatInputNumber(
      parseInt(sanitized?.toString())?.toString(),
      lang,
      0,
    )

    limitOperationForm.setValue('shares', validValue ? value : 0)

    dispatch(
      setLimitOrderDetails({
        shares: sanitized,
        price: buySell?.orderUserInput?.limitOrder?.price,
        expirationDate: buySell?.orderUserInput?.limitOrder?.expirationDate,
      }),
    )

    await limitOperationForm.trigger('shares')
  }

  // When limit order details are reset
  // Reset form value too
  useEffect(() => {
    const details = buySell.orderUserInput.limitOrder
    if (details?.price === null || details?.price === undefined) {
      limitOperationForm.setValue('limitPrice', null)
    }
    if (!details?.shares) {
      limitOperationForm.setValue('shares', null)
    }
    if (!details?.expirationDate) {
      limitOperationForm.setValue('expirationDate', expirationDate)
    }
  }, [buySell.orderUserInput.limitOrder, expirationDate, limitOperationForm])

  useEffect(() => {
    if (focusOnInit) {
      const el: HTMLInputElement = document.querySelector(`[name="shares"]`)
      el?.focus()
    }
  }, [focusOnInit])

  return (
    <OperationCardBase
      image={
        <Avatar
          roundness='full'
          size='mediumPlus'
          bgColor='primaryBase'
          image={
            type === OrderOperationTypeEnum.buy ? (
              <BuyLimitOrderIcon color='actionPrimaryInitial' />
            ) : (
              <SellLimitOrderIcon color='actionPrimaryInitial' />
            )
          }
        />
      }
      title={
        <div className='d-flex align-center' aria-hidden>
          <Text
            text={t('buySellFlow.limitOrders.limit')}
            preset='paragraph2'
            textStyle='capitalize'
          />
          <Spacer preset='tiny' />
          <div
            className='cursor-pointer d-flex align-center'
            onClick={() => {
              isBuy ? showLimitBuyInfoModal() : showLimitSellInfoModal()
            }}
            aria-hidden
          >
            <InfoIcon size={16} color='tertiary' />
          </div>
        </div>
      }
      subtitle={t('buySellFlow.limitOrders.buyLimitDesc')}
      form={
        <>
          <Spacer preset='tiny' />
          <div className='d-flex align-center'>
            <div className='w-55'>
              <Text
                preset='paragraph2'
                textStyle='uppercase'
                text={t('common.amount')}
              />
              {!isBuy && (
                <Text
                  preset='paragraph3'
                  text={`${t('buySellFlow.summary.available')} ${maxShares} ${
                    companyStocks?.symbol
                  }`}
                  color='tertiary'
                />
              )}
            </div>
            <div className='d-flex w-45'>
              <TextField
                size='small'
                theme='subtle'
                style='text-right bigText'
                right={companyStocks.symbol}
                register={limitOperationForm.register}
                inputProps={{
                  name: 'shares',
                  placeholder: '0',
                  disabled: isLoading || disabled,
                  onChange: onChangeSharesForm,
                  value: limitOperationForm.getValues()?.shares,
                }}
              />
            </div>
          </div>
          <Spacer preset='small' />
          <Spacer preset='tiny' />
          <div className='d-flex align-center'>
            <div className='w-55'>
              <Text
                preset='paragraph2'
                textStyle='uppercase'
                text={t('buySellFlow.limitPrice')}
              />
              <Text
                preset='paragraph3'
                color='tertiary'
                text={`${companyStocks?.symbol} ${formatCurrency(
                  companyStocks?.price,
                )}`}
              />
            </div>
            <div className='d-flex w-45'>
              <TextField
                size='small'
                theme='subtle'
                style='text-right paragraph5'
                register={limitOperationForm.register}
                right={
                  <Text
                    preset='caption'
                    text={companyStocks?.price?.currency}
                  />
                }
                inputProps={{
                  placeholder: `0${decimalSeparator}00`,
                  name: 'limitPrice',
                  onChange: onChangeLimitForm,
                  disabled: isLoading || disabled,
                  value: limitOperationForm.getValues()?.limitPrice,
                }}
              />
            </div>
          </div>
          <Spacer preset='small' />
          <div className='d-flex align-center'>
            <div className='w-55'>
              <Text
                preset='paragraph2'
                textStyle='uppercase'
                text={t('buySellFlow.timeInForce')}
              />
            </div>
            <div
              className='d-flex w-45 justify-end cursor-pointer'
              onClick={openCalendar}
              aria-hidden
            >
              <Text
                text={dateSelectionText}
                preset='paragraph2'
                color='iconPrimaryInitial'
              />
              <ChevronDown color='actionPrimaryInitial' />
            </div>
          </div>
          <Spacer preset='small' />
          <div className='d-flex align-center'>
            <div className='w-55'>
              <Text
                preset='paragraph2'
                textStyle='uppercase'
                text={t('common.estimatedTotal')}
              />
              <div className='d-flex'>
                <div
                  className='cursor-pointer d-flex align-center'
                  onClick={() => showAvailableInfoModal()}
                  aria-hidden
                >
                  <InfoIcon size={16} color='tertiary' />
                </div>
                <Spacer preset='tiny' />
                <Text
                  preset='paragraph3'
                  text={`${t('common.available')}: ${formatMoney(
                    userBalance?.cashAvailable?.amount,
                    userBalance?.cashAvailable?.currency,
                  )}`}
                  color='tertiary'
                />
              </div>
            </div>
            <div className='d-flex w-45 justify-end cursor-pointer'>
              <Text
                text={formatCurrency({
                  amount:
                    (buySell?.orderUserInput?.limitOrder?.shares || 0) *
                    (buySell?.orderUserInput?.limitOrder?.price || 0),
                  currency: companyStocks?.price.currency,
                })}
                preset='bigText'
                align='right'
              />
            </div>
          </div>
        </>
      }
    />
  )
}
