import {
  UIIcon,
  UIQuaternaryColorProp,
  getIconColorSVGFill,
} from 'components/utils'

export const ModerateIcon = ({
  size = 24,
  color = 'actionPrimaryInitial',
  quaternaryColor = 'iconNeutralSoft',
  secondaryColor = 'iconNeutralSoft',
  tertiaryColor = 'transparent',
}: UIIcon & UIQuaternaryColorProp): JSX.Element => {
  return (
    <svg width={size} height={size} viewBox='0 0 49 48' fill='none'>
      <circle
        cx='24.5'
        cy='24'
        r='23'
        fill={getIconColorSVGFill(tertiaryColor)}
        stroke={getIconColorSVGFill(secondaryColor)}
        strokeWidth='2'
      />
      <path
        d='M22.4237 9.14441C20.3018 9.44098 18.2681 10.1887 16.4594 11.3371C14.6507 12.4856 13.109 14.0082 11.9381 15.8025C10.7673 17.5967 9.99433 19.621 9.67138 21.739C9.34844 23.857 9.48297 26.0197 10.0659 28.0814C10.6489 30.143 11.6667 32.0559 13.051 33.6912C14.4352 35.3265 16.1537 36.6463 18.0908 37.5618C20.0278 38.4772 22.1385 38.9671 24.2808 38.9984C26.4231 39.0297 28.5472 38.6017 30.5102 37.7433L24.5 24.4999L22.4237 9.14441Z'
        fill={getIconColorSVGFill(color)}
      />
      <path
        d='M31.9448 37.0221C34.8441 35.3646 37.1073 32.7869 38.3758 29.6975C39.6443 26.6082 39.8456 23.1838 38.9477 19.9671C38.0498 16.7504 36.1041 13.9254 33.4189 11.9396C30.7337 9.95387 27.4627 8.92101 24.1241 9.00471L26 24L31.9448 37.0221Z'
        fill={getIconColorSVGFill(quaternaryColor)}
      />
    </svg>
  )
}
