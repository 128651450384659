import { Card, Spacer, Text } from 'components'
import { VectorDown, VectorUp } from '@assets/icons'
import '../analystRatings.styles.scss'
import AdvanceLineChart from 'components/advanceLineChart/advanceLineChart'
import { useTranslation } from 'hooks/useTranslation'
import { useAppSelector } from 'store'
import { formatCurrency } from '@utils/helpers'
import _ from 'lodash'
import { format } from 'date-fns'

const ForecastCard = (): JSX.Element => {
  const { t } = useTranslation()
  const {
    priceTarget,
    priceTargetCurrencyCode,
    estimatedPriceChangePercentage,
    lastPrice,
    highPriceTarget,
    lowPriceTarget,
    monthlyClosingPrices,
  } = useAppSelector((state) => state.tipRank)
  const { price } = useAppSelector((state) => state.companyStocks)

  // mapping data with date and value
  const transformedData: { date: string; value: number }[] = _.map(
    monthlyClosingPrices,
    (item) =>
      _.assign({}, item, {
        value: item.amount,
        date: format(new Date(item.date), 'yyyy-MM-dd'),
      }),
  )

  // Current price added in the chart
  const finalData = _.concat(transformedData, {
    date: format(new Date(), 'yyyy-MM-dd'),
    value: Number(price.amount),
  })

  const growthIndicator = (price: number): JSX.Element => {
    const isPositive = price > 0
    return (
      <div className='d-flex align-center justify-center'>
        {isPositive ? (
          <VectorUp color='positive' size={14} />
        ) : (
          <VectorDown color='negative' size={14} />
        )}
        <Text
          text={`${price}% ${
            isPositive
              ? t('companyDetails.analystRating.upside')
              : t('companyDetails.analystRating.downside')
          }`}
          preset='subtitle3'
          color={isPositive ? 'positive' : 'negative'}
        />
      </div>
    )
  }
  return (
    <Card key='account-type' width='full' aria-hidden padding='smaller'>
      <div className='d-flex align-center justify-center d-flex-col forecast-card'>
        <Spacer preset='smaller' />
        <Text
          text={t('companyDetails.analystRating.averagePriceTarget')}
          preset='bodyRegular'
        />
        <Spacer preset='tiny' />
        <Text
          text={formatCurrency({
            amount: priceTarget,
            currency: priceTargetCurrencyCode,
          })}
          preset='headline2'
        />
        {growthIndicator(estimatedPriceChangePercentage)}
        <Spacer preset='small' />
        <AdvanceLineChart
          data={finalData}
          startDate={format(new Date(monthlyClosingPrices[0].date), 'MMM yyyy')}
          endDate={format(new Date(), 'MMM yyyy')}
          currency={priceTargetCurrencyCode}
          highValue={highPriceTarget}
          avgValue={priceTarget}
          lowValue={lowPriceTarget}
        />
        <Spacer preset='small' />

        <div className='d-flex align-center d-flex-col average-rating__indicator'>
          <div className=' d-flex align-center w-100 justify-space-between'>
            <div className='flex average-rating__indicator__item d-flex align-center'>
              <div className='average-rating__indicator__item__circle average-rating__indicator__item__circle--blue'></div>
              <Text
                text={t('companyDetails.analystRating.highest')}
                preset='bodyRegular'
              />
            </div>
            <Text
              text={formatCurrency({
                amount: highPriceTarget,
                currency: priceTargetCurrencyCode,
              })}
              preset='bodyRegular'
            />
          </div>
          <div className=' d-flex align-center w-100 justify-space-between'>
            <div className='flex average-rating__indicator__item  d-flex align-center'>
              <div className='average-rating__indicator__item__circle average-rating__indicator__item__circle__black'></div>
              <Text
                text={t('companyDetails.analystRating.averagePriceTarget')}
                preset='bodyRegular'
              />
            </div>
            <Text
              text={formatCurrency({
                amount: priceTarget,
                currency: priceTargetCurrencyCode,
              })}
              preset='bodyRegular'
            />
          </div>
          <div className=' d-flex align-center w-100 justify-space-between'>
            <div className='flex average-rating__indicator__item d-flex align-center'>
              <div className='average-rating__indicator__item__circle average-rating__indicator__item__circle__red'></div>
              <Text
                text={t('companyDetails.analystRating.lowest')}
                preset='bodyRegular'
              />
            </div>
            <Text
              text={formatCurrency({
                amount: lowPriceTarget,
                currency: priceTargetCurrencyCode,
              })}
              preset='bodyRegular'
            />
          </div>
        </div>
      </div>
      <Spacer preset='smallPlus' />
      <Text preset='bodyRegular' align='center'>
        {t('companyDetails.analystRating.averagePriceTargetDescription', {
          percentage: estimatedPriceChangePercentage,
          value: lastPrice,
        })}
      </Text>
    </Card>
  )
}

export default ForecastCard
