export enum TextColorsEnum {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  positive = 'positive',
  negative = 'negative',
  iconPrimaryInitial = 'iconPrimaryInitial',
  iconPositiveInitial = 'iconPositiveInitial',
  warning = 'warning',
  surfaceCanvasPrimaryInitial = 'surfaceCanvasPrimaryInitial',
  surfacePositiveSubtleInitial = 'surfacePositiveSubtleInitial',
  labelNavigationTitle = 'labelNavigationTitle',
  inherit = 'inherit',
}
type TextColorPresets = keyof typeof TextColorsEnum

export interface UITextColorProps {
  color?: TextColorPresets
}
