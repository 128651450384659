import {
  Divider,
  SearchBox,
  Show,
  Spacer,
  StickyElementsWrapper,
  TabBar,
  Text,
} from '@components'
import './discover.styles.scss'
import {
  AssetManagerCode,
  AssetType,
  DiscoverTab,
  SelectedFilters,
} from '@interfaces'
import { RootState, fetchAssets } from 'store'
import { BuySellEvents, TabClickedEvents } from '@utils/eventTracker/eventKeys'
import { TabItem } from 'components/tabBar/tabBar.props'
import { trackingService } from '@services'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  useBuyAndSellActions,
  useSearchBlockTitle,
  useTranslation,
} from '@hooks'
import SavingPlan from './components/savingPlan/savingPlan'
import {
  clearFiltersSelection,
  createFiltersSelection,
  fetchSearchFilters,
  selectCurrentFilters,
  setAssetTypes,
  setFocusedFilter,
  updateFilter,
} from 'features/discover/filters.thunk'
import {
  clearAssetsListDiscover,
  fetchStocksDiscover,
} from 'features/discover/search.thunk'
import ResultsContainer from './components/resultsContainer/resultsContainer'
import { Container, SearchResultsCard } from '@shared/components'
import { ChevronRight } from '@assets/icons'
import { FiltersContainer } from '@shared/components/filters/filtersContainer/filtersContainer'
import { useInitialSearchLoad } from 'hooks/useInitialSearchLoad'

export default function Discover(): JSX.Element {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const config = useSelector((state: RootState) => state.config)
  const mode = useParams().mode || DiscoverTab.all

  const discoverState = useSelector((state: RootState) => state.discover)
  const filtersState = useSelector((state: RootState) => state?.discoverFilters)
  const [tabs, setTabs] = useState<Array<TabItem>>()
  const [query, setQuery] = useState<string>(null)

  const [opened, setOpened] = useState(false)

  useInitialSearchLoad()

  const [activeTab, setActiveTab] = useState<DiscoverTab>(
    mode.toUpperCase() as DiscoverTab,
  )

  const [page, setPage] = useState(0)

  const assetTypeTitle = useMemo(
    () => tabs?.find((t) => (t.id as DiscoverTab) === activeTab)?.title,
    [activeTab, tabs],
  )

  const assetType: AssetType | null = useMemo(() => {
    switch (activeTab) {
      case DiscoverTab.stocks:
        return AssetType.stock
      case DiscoverTab.etfs:
        return AssetType.etf
      case DiscoverTab.blk:
        return AssetType.savingPlan
      default:
        return null
    }
  }, [activeTab])

  const assetTypes: string = useMemo(() => {
    switch (activeTab) {
      case DiscoverTab.stocks:
        return `${AssetType.stock}`
      case DiscoverTab.etfs:
        return `${AssetType.etf}`
      default:
        return null
    }
  }, [activeTab])

  useEffect(() => {
    const filtersNotLoadedYet = !filtersState?.filtersResponse
    if (filtersNotLoadedYet) {
      dispatch(fetchSearchFilters())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (assetType) {
      dispatch(setAssetTypes([assetType]))
      dispatch(selectCurrentFilters())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetType, filtersState?.filtersResponse])

  const discoverHandlerPressed = (tabPressed: string): void => {
    trackingService.trackEvent({
      event: TabClickedEvents.discover,
      props: {
        tab: tabPressed,
        origin: activeTab,
      },
    })
  }

  const handleTabPressed = (tabPressed: DiscoverTab): void => {
    // This allows to change the url without reloading the discover component
    window.history.replaceState(
      null,
      null,
      `/discover/${tabPressed.toLowerCase()}`,
    )
    setActiveTab(tabPressed as DiscoverTab)
    setPage(0)
    discoverHandlerPressed(tabPressed)
  }

  const search = (
    page: number,
    assetTypes: string,
    query: string,
    selectedFilters: SelectedFilters,
  ): void => {
    if (activeTab === DiscoverTab.all) {
      if (!query) return // Initial data has already been loaded

      dispatch(
        fetchAssets({
          query,
          type: 'search',
          origin: 'discoverSearch',
        }),
      )
    }

    if (activeTab === DiscoverTab.stocks || activeTab === DiscoverTab.etfs) {
      dispatch(
        fetchStocksDiscover({
          query,
          page,
          assetTypes,
          selectedFilters,
        }),
      )
    }
  }

  useEffect(() => {
    if (!discoverState?.loading) {
      search(page, assetTypes, query, filtersState?.selected)
    }

    // Adding search to the dependency array causes a double request to the API
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, query, assetTypes, filtersState?.selected])

  useEffect(() => {
    const tabs = [
      {
        title: t('discover.allAssets'),
        id: DiscoverTab.all,
      },
      {
        title: t('common.stocks'),
        id: DiscoverTab.stocks,
      },
      {
        title: t('common.etfs'),
        id: DiscoverTab.etfs,
      },
    ]

    setTabs(tabs)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config.features])

  const showAssetsView =
    activeTab === DiscoverTab.stocks || activeTab === DiscoverTab.etfs

  const handleFilterClear = (id: string): void => {
    dispatch(updateFilter({ id, type: 'reset' }))
    setPage(0)
    dispatch(createFiltersSelection())
  }

  const handleResetAllFilters = (): void => {
    filtersState?.filters?.forEach((f) => {
      dispatch(updateFilter({ id: f?.id, type: 'reset' }))
    })
    dispatch(clearFiltersSelection())
    dispatch(createFiltersSelection())
    setPage(0)
  }

  const { buy, modals } = useBuyAndSellActions()

  const onBuyClick = (p: { id: string; type: AssetType }): void => {
    trackingService.trackEvent({
      event: BuySellEvents.flowStarted,
      props: { origin, type: 'buy' },
    })
    buy({
      id: p?.id,
      origin,
      type: p?.type,
    })
  }

  const searchIsAllowed = assetType !== AssetType.savingPlan

  const showFilters = [AssetType.etf, AssetType.stock].includes(assetType)

  const searchState = useSelector((state: RootState) => state.search)
  const data =
    query?.length > 0
      ? searchState?.discoverSearchData
      : searchState?.initialData

  const { format } = useSearchBlockTitle()

  const selectTab = (assetType: AssetType): void => {
    switch (assetType) {
      case AssetType.stock:
        setActiveTab(DiscoverTab.stocks)
        break
      case AssetType.etf:
        setActiveTab(DiscoverTab.etfs)
        break
      case AssetType.savingPlan:
        setActiveTab(DiscoverTab.blk)
        break
    }
  }

  const clearSearch = (): void => {
    setPage(0)
    setQuery(null)
    dispatch(clearAssetsListDiscover())
  }

  return (
    <>
      <div className='discover__header'>
        <Spacer preset='mediumPlus' />
        <div className='home__widget__portfolio__title'>
          <Text preset='hero' text={t('common.discover')} />
        </div>
      </div>
      <StickyElementsWrapper startPoint={56} zIndex={'3'}>
        <div className='discover__header' data-sticky>
          <Spacer preset='smallPlus' />
          <div className='discover__search'>
            <SearchBox>
              <SearchBox.Input
                onChange={(v: string) => {
                  if (v !== query) {
                    setPage(0)
                    setQuery(v)
                  }
                }}
                onClear={clearSearch}
                textFieldProps={{
                  size: 'small',
                  style: `paragraph2`,
                  inputProps: {
                    name: 'search',
                    value: query,
                    placeholder: t('discover.searchPlaceholder'),
                    disabled: !searchIsAllowed,
                  },
                }}
              />
            </SearchBox>
          </div>
          <Spacer preset='smaller' />
        </div>
        <div className='discover__tabs' data-sticky>
          <Container size='normal'>
            <TabBar
              extraMarginOnSides
              tabs={tabs}
              activeTab={activeTab}
              setActiveTab={handleTabPressed}
              loading={opened}
            />
          </Container>
          <Divider />
        </div>
        <div className='filters-container d-flex align-center' data-sticky>
          <Show when={!!(showFilters && filtersState?.filters)}>
            <FiltersContainer
              filters={filtersState?.filters}
              handleOnPress={(filter) => {
                dispatch(setFocusedFilter(filter.id))
                setOpened((prev) => !prev)
              }}
              handleOnClearFilter={handleFilterClear}
              handleResetAllFilters={handleResetAllFilters}
              disabled={filtersState?.loading}
              isLoading={filtersState?.loading}
              focusedFilter={filtersState?.focusedFilter}
              onClose={() => {
                dispatch(createFiltersSelection())
                dispatch(setFocusedFilter(null))
                setOpened(false)
              }}
              onGoBack={() => {
                dispatch(
                  setFocusedFilter(filtersState?.focusedFilter?.parentId),
                )
              }}
              focusFilter={(id) => {
                dispatch(setFocusedFilter(id))
              }}
              toggleFilter={(id) =>
                dispatch(
                  updateFilter({
                    id,
                    type: 'toggle',
                  }),
                )
              }
            />
            <Spacer preset='small' />
            <Text
              preset='paragraph2'
              text={`${t('common.nAssetsTypeAvailable', {
                count: discoverState?.totalItems,
                assetsType: assetTypeTitle,
              })}`}
              color='secondary'
            />
          </Show>
        </div>
      </StickyElementsWrapper>
      <div className='discover__content --fade-in-animation-slow'>
        <Show when={activeTab === DiscoverTab.all}>
          {data?.map((b) =>
            b?.items?.length ? (
              <React.Fragment key={`block-${b?.type}`}>
                <Spacer preset='medium' />
                <div className='d-flex w-100 justify-space-between'>
                  <Text text={format(b?.type)} preset='subtitle3' />
                  <div
                    className='d-flex cursor-pointer'
                    aria-hidden
                    onClick={() => selectTab(b?.type)}
                  >
                    <Text
                      text={b?.totalItems?.toString()}
                      preset='paragraph2'
                      color='iconPrimaryInitial'
                    />
                    <Spacer preset='extraTiny' />
                    <ChevronRight size={22} color='iconPrimaryInitial' />
                  </div>
                </div>
                <Spacer preset='tinyPlus' />
                <SearchResultsCard
                  results={b.items}
                  assetType={b?.type}
                  onBuy={(id, assetType) => {
                    onBuyClick({ id, type: assetType })
                  }}
                />
              </React.Fragment>
            ) : null,
          )}
        </Show>
        <Show when={showAssetsView}>
          <ResultsContainer
            activeTab={activeTab}
            query={discoverState?.query}
            paginationLoader={discoverState?.paginationLoader}
            loading={discoverState?.loading}
            totalPages={discoverState?.totalPages}
            currentPage={discoverState?.currentPage}
            data={discoverState?.data}
            handleNextPage={() => {
              setPage((prev) => prev + 1)
            }}
          />
        </Show>
        <Show when={activeTab && activeTab === DiscoverTab.blk}>
          <SavingPlan visiblePlans={[AssetManagerCode.blk]} />
        </Show>
        <Spacer preset='huge' />
        <Spacer preset='huge' />
      </div>
      {modals}
    </>
  )
}
