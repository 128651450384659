import { Avatar, Button, Card, Divider, Spacer, Text } from 'components'
import { BrokerTierStatus } from './brokerTierStatus'
import { ProTierTbc } from '@assets/illustrations'
import { BrokerTier, BrokerTierEnum, BrokerTierUserData } from '@interfaces'
import { useTranslation } from 'hooks/useTranslation'
import { CheckIcon, LockClosedIcon, LockOpenIcon } from '@assets/icons'

export const BrokerTierInfoCard = ({
  moreInfo,
  openGlobalPosition,
  completedOrdersThisMonth,
  totalPortfolio,
  tier,
}: {
  moreInfo: () => void
  openGlobalPosition: () => void
} & BrokerTierUserData &
  BrokerTier): JSX.Element => {
  const { t } = useTranslation()
  const liteExplanation = (
    <Text preset='subtitle1' color='secondary' align='center'>
      {t('brokerTier.liteTierYourAreA')}
    </Text>
  )

  const proExplanation = (
    <Text preset='subtitle1' color='secondary' align='center'>
      {t('brokerTier.proTierYourAreA')}
    </Text>
  )

  const isPro = tier === BrokerTierEnum.pro
  const iconSize = 18

  return (
    <Card width='full'>
      <div className='d-flex align-center'>
        <ProTierTbc width={94} height={36} />
      </div>
      <Spacer preset='smaller' />
      <Divider />
      <Spacer preset='tiny' />
      <div className='d-flex align-center w-100 justify-center'>
        <div className='d-flex w-25 d-flex-col align-center'>
          <Spacer preset='tinyPlus' />
          <div className='d-flex align-center'>
            <div className='d-flex align-center'>
              <Avatar
                image={
                  !isPro ? (
                    <CheckIcon size={iconSize} color='positive' />
                  ) : (
                    <LockOpenIcon size={iconSize} color='tertiary' />
                  )
                }
                bordered
                bgColor='primaryBase'
                roundness='full'
                size='small'
              />
              <Spacer preset='tinyPlus' />
              <Text
                text='Basic'
                preset='paragraph2'
                color={!isPro ? 'positive' : 'primary'}
              />
            </div>
            <Spacer preset='smaller' />
            <div className='mb-1'>
              <Text text='...' preset='headline2' color='tertiary' />
            </div>
            <Spacer preset='smaller' />
            <div className='d-flex align-center'>
              <Text
                text='Pro'
                preset='paragraph2'
                color={isPro ? 'positive' : 'primary'}
              />
              <Spacer preset='tinyPlus' />
              <Avatar
                image={
                  isPro ? (
                    <CheckIcon size={iconSize} color='positive' />
                  ) : (
                    <LockClosedIcon size={iconSize} color='tertiary' />
                  )
                }
                bordered
                bgColor='primaryBase'
                roundness='full'
                size='small'
              />
            </div>
          </div>
          <Spacer preset='tinyPlus' />
          {isPro ? proExplanation : liteExplanation}
          <Spacer preset='tinyPlus' />
          <Button
            autoWidth
            buttonType='link'
            textPreset='paragraph2'
            text={t('brokerTier.moreAboutPro')}
            onClick={moreInfo}
          />
        </div>
        <Spacer preset='medium' />
        <div className='d-flex w-85 justify-center'>
          <BrokerTierStatus
            tier={tier}
            openGlobalPosition={openGlobalPosition}
            completedOrdersThisMonth={completedOrdersThisMonth}
            totalPortfolio={totalPortfolio}
          />
        </div>
      </div>
    </Card>
  )
}
