import { ReactNode, useCallback, useEffect, useRef } from 'react'

interface IntersectionObserverCompProps {
  handler: () => void
  options?: IntersectionObserverInit
  children?: ReactNode
}

/**
 * Reusable component to handle IntersectionObserver
 * @param props
 * @returns
 */
export const IntersectionObserverComp = (
  props: IntersectionObserverCompProps,
): JSX.Element => {
  const elem = useRef<HTMLDivElement>(null)

  const handleObserver = useCallback(
    (entities: Array<IntersectionObserverEntry>): void => {
      const target = entities[0]
      if (target.isIntersecting) {
        props?.handler()
      }
    },
    [props],
  )

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, props?.options)

    if (elem.current) {
      observer.observe(elem.current as Element)
    }

    return () => {
      if (observer && elem.current) observer?.unobserve(elem?.current)
    }
  }, [handleObserver, props?.options])

  return <div ref={elem}>{props?.children}</div>
}
