const supportEmail = 'support@tbccapital.ge'

const urls = {
  privacy:
    'https://investing.tbccapital.ge/assets/files/Privacy%20Policy%20en.pdf',
  terms:
    'https://investing.tbccapital.ge/assets/files/Individuals%20en%20TBC%20Capital%20Master%20Agreement.pdf',
  security: 'https://investing.tbccapital.ge/assets/files/anti-bribery-en.pdf',
}

const companyPrivacyPolicyUrl = {
  en: urls.privacy,
  ka: urls.privacy,
  ru: urls.privacy,
}

const companyTerms = urls.terms

const companySecurityPolicyUrl = {
  en: urls.security,
  ka: urls.security,
  ru: urls.security,
}

const privacyPolicyUrl: { [key: string]: string } = {
  es: 'https://truelayer.com/es-es/legal/aviso-de-privacidad',
  en: 'https://truelayer.com/legal/privacy',
}

const termsUrl: { [key: string]: string } = {
  es: 'https://truelayer.com/es-es/legal/condiciones-del-servicio-para-el-usuario-final',
  en: 'https://truelayer.com/legal/enduser_tos',
}

const policies = { privacyPolicyUrl, termsUrl }

export {
  policies,
  supportEmail,
  companyPrivacyPolicyUrl,
  companySecurityPolicyUrl,
  companyTerms,
}
