import { Card } from 'components/card/card'
import { Avatar } from 'components/avatar/avatar'
import { Spacer } from 'components/spacer'
import { Text } from 'components/text/text'
import { Button } from 'components/button/button'
import { CloseIcon } from '@assets/icons'
import {
  OrderOperationTypeEnum,
  OrderTransaction,
  OrderTypeEnum,
} from 'interfaces'
import './pendingTransactionItem.styles.scss'
import { formatCurrency, formatDate } from '@utils/helpers'
import { useMemo } from 'react'
import { useTranslation } from 'hooks/useTranslation'
import { useNavigate } from 'hooks/useNavigation'

export function PendingTransactionItem({
  transaction,
  onCancel,
}: {
  transaction: OrderTransaction
  onCancel: () => void
}): JSX.Element {
  const { t } = useTranslation()
  const { navigate } = useNavigate()

  const type = useMemo(() => {
    if (transaction.type === OrderOperationTypeEnum.buy) {
      if (transaction.method === OrderTypeEnum.limit) {
        return t('buySellFlow.limitOrders.buyLimit')
      }

      if (transaction.method === OrderTypeEnum.market) {
        return t('buySellFlow.limitOrders.buyMarket')
      }
    } else {
      if (transaction.method === OrderTypeEnum.limit) {
        return t('buySellFlow.limitOrders.sellLimit')
      }

      if (transaction.method === OrderTypeEnum.market) {
        return t('buySellFlow.limitOrders.sellMarket')
      }
    }
  }, [t, transaction.method, transaction.type])

  return (
    <div className={`pending-transaction-item`}>
      <Card
        padding='smaller'
        width='full'
        onClick={() => navigate(`/company/${transaction?.asset?.id}`)}
      >
        <Spacer preset='tiny' />
        <Avatar
          image={transaction?.asset?.logoUrl}
          roundness='full'
          size='large'
        />
        <Spacer preset='smaller' />
        <Text
          preset='paragraph2'
          text={`${transaction?.shares?.requested} ${
            transaction?.asset?.ticker
          } ${formatCurrency(transaction?.calculations?.lockedCash)}`}
          color='secondary'
        />
        <Spacer preset='tinyPlus' />
        <Text
          preset='paragraph2'
          text={type}
          color={
            transaction?.type === OrderOperationTypeEnum.buy
              ? 'positive'
              : 'negative'
          }
        />
        <Spacer preset='smaller' />
        <div className='w-100 d-flex justify-space-between align-center'>
          <div>
            <Text
              preset='paragraph2'
              text={`${formatDate(transaction?.expirationDate, {
                dateStyle: 'short',
              })}`}
              color='tertiary'
            />
          </div>
          {transaction?.isCancellable && (
            <Button
              size='tiny'
              autoWidth
              buttonType='dark--negative'
              shape='square'
              left={<CloseIcon size={12} color='negative' />}
              onClick={onCancel}
            />
          )}
        </div>
      </Card>
    </div>
  )
}
