export const SearchEmpty = (): JSX.Element => {
  return (
    <svg
      width='119'
      height='118'
      viewBox='0 0 119 118'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        cx='57.9277'
        cy='57'
        r='57'
        fill='var(--illustrationsNeutral25)'
      />
      <mask
        id='mask0_10556_277498'
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='115'
        height='114'
      >
        <circle
          cx='57.9277'
          cy='57'
          r='57'
          fill='var(--illustrationsSharedNeutral900)'
        />
      </mask>
      <g mask='url(#mask0_10556_277498)'>
        <circle
          cx='1.92773'
          cy='4'
          r='1'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <circle
          cx='7.92773'
          cy='4'
          r='1'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M14.9277 4C14.9277 4.55228 14.48 5 13.9277 5C13.3754 5 12.9277 4.55228 12.9277 4C12.9277 3.44772 13.3754 3 13.9277 3C14.48 3 14.9277 3.44772 14.9277 4Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M20.9277 4C20.9277 4.55228 20.48 5 19.9277 5C19.3754 5 18.9277 4.55228 18.9277 4C18.9277 3.44772 19.3754 3 19.9277 3C20.48 3 20.9277 3.44772 20.9277 4Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M26.9277 4C26.9277 4.55228 26.48 5 25.9277 5C25.3754 5 24.9277 4.55228 24.9277 4C24.9277 3.44772 25.3754 3 25.9277 3C26.48 3 26.9277 3.44772 26.9277 4Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M32.9277 4C32.9277 4.55228 32.48 5 31.9277 5C31.3754 5 30.9277 4.55228 30.9277 4C30.9277 3.44772 31.3754 3 31.9277 3C32.48 3 32.9277 3.44772 32.9277 4Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M38.9277 4C38.9277 4.55228 38.48 5 37.9277 5C37.3754 5 36.9277 4.55228 36.9277 4C36.9277 3.44772 37.3754 3 37.9277 3C38.48 3 38.9277 3.44772 38.9277 4Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M44.9277 4C44.9277 4.55228 44.48 5 43.9277 5C43.3754 5 42.9277 4.55228 42.9277 4C42.9277 3.44772 43.3754 3 43.9277 3C44.48 3 44.9277 3.44772 44.9277 4Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M50.9277 4C50.9277 4.55228 50.48 5 49.9277 5C49.3754 5 48.9277 4.55228 48.9277 4C48.9277 3.44772 49.3754 3 49.9277 3C50.48 3 50.9277 3.44772 50.9277 4Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M56.9277 4C56.9277 4.55228 56.48 5 55.9277 5C55.3754 5 54.9277 4.55228 54.9277 4C54.9277 3.44772 55.3754 3 55.9277 3C56.48 3 56.9277 3.44772 56.9277 4Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M62.9277 4C62.9277 4.55228 62.48 5 61.9277 5C61.3754 5 60.9277 4.55228 60.9277 4C60.9277 3.44772 61.3754 3 61.9277 3C62.48 3 62.9277 3.44772 62.9277 4Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M68.9277 4C68.9277 4.55228 68.48 5 67.9277 5C67.3754 5 66.9277 4.55228 66.9277 4C66.9277 3.44772 67.3754 3 67.9277 3C68.48 3 68.9277 3.44772 68.9277 4Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M74.9277 4C74.9277 4.55228 74.48 5 73.9277 5C73.3754 5 72.9277 4.55228 72.9277 4C72.9277 3.44772 73.3754 3 73.9277 3C74.48 3 74.9277 3.44772 74.9277 4Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M80.9277 4C80.9277 4.55228 80.48 5 79.9277 5C79.3754 5 78.9277 4.55228 78.9277 4C78.9277 3.44772 79.3754 3 79.9277 3C80.48 3 80.9277 3.44772 80.9277 4Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M86.9277 4C86.9277 4.55228 86.48 5 85.9277 5C85.3754 5 84.9277 4.55228 84.9277 4C84.9277 3.44772 85.3754 3 85.9277 3C86.48 3 86.9277 3.44772 86.9277 4Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M92.9277 4C92.9277 4.55228 92.48 5 91.9277 5C91.3754 5 90.9277 4.55228 90.9277 4C90.9277 3.44772 91.3754 3 91.9277 3C92.48 3 92.9277 3.44772 92.9277 4Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M98.9277 4C98.9277 4.55228 98.48 5 97.9277 5C97.3754 5 96.9277 4.55228 96.9277 4C96.9277 3.44772 97.3754 3 97.9277 3C98.48 3 98.9277 3.44772 98.9277 4Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M104.928 4C104.928 4.55228 104.48 5 103.928 5C103.375 5 102.928 4.55228 102.928 4C102.928 3.44772 103.375 3 103.928 3C104.48 3 104.928 3.44772 104.928 4Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M110.928 4C110.928 4.55228 110.48 5 109.928 5C109.375 5 108.928 4.55228 108.928 4C108.928 3.44772 109.375 3 109.928 3C110.48 3 110.928 3.44772 110.928 4Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <circle
          cx='1.92773'
          cy='28'
          r='1'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <circle
          cx='7.92773'
          cy='28'
          r='1'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M14.9277 28C14.9277 28.5523 14.48 29 13.9277 29C13.3754 29 12.9277 28.5523 12.9277 28C12.9277 27.4477 13.3754 27 13.9277 27C14.48 27 14.9277 27.4477 14.9277 28Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M20.9277 28C20.9277 28.5523 20.48 29 19.9277 29C19.3754 29 18.9277 28.5523 18.9277 28C18.9277 27.4477 19.3754 27 19.9277 27C20.48 27 20.9277 27.4477 20.9277 28Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M26.9277 28C26.9277 28.5523 26.48 29 25.9277 29C25.3754 29 24.9277 28.5523 24.9277 28C24.9277 27.4477 25.3754 27 25.9277 27C26.48 27 26.9277 27.4477 26.9277 28Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M32.9277 28C32.9277 28.5523 32.48 29 31.9277 29C31.3754 29 30.9277 28.5523 30.9277 28C30.9277 27.4477 31.3754 27 31.9277 27C32.48 27 32.9277 27.4477 32.9277 28Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M38.9277 28C38.9277 28.5523 38.48 29 37.9277 29C37.3754 29 36.9277 28.5523 36.9277 28C36.9277 27.4477 37.3754 27 37.9277 27C38.48 27 38.9277 27.4477 38.9277 28Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M44.9277 28C44.9277 28.5523 44.48 29 43.9277 29C43.3754 29 42.9277 28.5523 42.9277 28C42.9277 27.4477 43.3754 27 43.9277 27C44.48 27 44.9277 27.4477 44.9277 28Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M50.9277 28C50.9277 28.5523 50.48 29 49.9277 29C49.3754 29 48.9277 28.5523 48.9277 28C48.9277 27.4477 49.3754 27 49.9277 27C50.48 27 50.9277 27.4477 50.9277 28Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M56.9277 28C56.9277 28.5523 56.48 29 55.9277 29C55.3754 29 54.9277 28.5523 54.9277 28C54.9277 27.4477 55.3754 27 55.9277 27C56.48 27 56.9277 27.4477 56.9277 28Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M62.9277 28C62.9277 28.5523 62.48 29 61.9277 29C61.3754 29 60.9277 28.5523 60.9277 28C60.9277 27.4477 61.3754 27 61.9277 27C62.48 27 62.9277 27.4477 62.9277 28Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M68.9277 28C68.9277 28.5523 68.48 29 67.9277 29C67.3754 29 66.9277 28.5523 66.9277 28C66.9277 27.4477 67.3754 27 67.9277 27C68.48 27 68.9277 27.4477 68.9277 28Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M74.9277 28C74.9277 28.5523 74.48 29 73.9277 29C73.3754 29 72.9277 28.5523 72.9277 28C72.9277 27.4477 73.3754 27 73.9277 27C74.48 27 74.9277 27.4477 74.9277 28Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M80.9277 28C80.9277 28.5523 80.48 29 79.9277 29C79.3754 29 78.9277 28.5523 78.9277 28C78.9277 27.4477 79.3754 27 79.9277 27C80.48 27 80.9277 27.4477 80.9277 28Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M86.9277 28C86.9277 28.5523 86.48 29 85.9277 29C85.3754 29 84.9277 28.5523 84.9277 28C84.9277 27.4477 85.3754 27 85.9277 27C86.48 27 86.9277 27.4477 86.9277 28Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M92.9277 28C92.9277 28.5523 92.48 29 91.9277 29C91.3754 29 90.9277 28.5523 90.9277 28C90.9277 27.4477 91.3754 27 91.9277 27C92.48 27 92.9277 27.4477 92.9277 28Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M98.9277 28C98.9277 28.5523 98.48 29 97.9277 29C97.3754 29 96.9277 28.5523 96.9277 28C96.9277 27.4477 97.3754 27 97.9277 27C98.48 27 98.9277 27.4477 98.9277 28Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M104.928 28C104.928 28.5523 104.48 29 103.928 29C103.375 29 102.928 28.5523 102.928 28C102.928 27.4477 103.375 27 103.928 27C104.48 27 104.928 27.4477 104.928 28Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M110.928 28C110.928 28.5523 110.48 29 109.928 29C109.375 29 108.928 28.5523 108.928 28C108.928 27.4477 109.375 27 109.928 27C110.48 27 110.928 27.4477 110.928 28Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <circle
          cx='1.92773'
          cy='52'
          r='1'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <circle
          cx='7.92773'
          cy='52'
          r='1'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M14.9277 52C14.9277 52.5523 14.48 53 13.9277 53C13.3754 53 12.9277 52.5523 12.9277 52C12.9277 51.4477 13.3754 51 13.9277 51C14.48 51 14.9277 51.4477 14.9277 52Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M20.9277 52C20.9277 52.5523 20.48 53 19.9277 53C19.3754 53 18.9277 52.5523 18.9277 52C18.9277 51.4477 19.3754 51 19.9277 51C20.48 51 20.9277 51.4477 20.9277 52Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M26.9277 52C26.9277 52.5523 26.48 53 25.9277 53C25.3754 53 24.9277 52.5523 24.9277 52C24.9277 51.4477 25.3754 51 25.9277 51C26.48 51 26.9277 51.4477 26.9277 52Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M32.9277 52C32.9277 52.5523 32.48 53 31.9277 53C31.3754 53 30.9277 52.5523 30.9277 52C30.9277 51.4477 31.3754 51 31.9277 51C32.48 51 32.9277 51.4477 32.9277 52Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M38.9277 52C38.9277 52.5523 38.48 53 37.9277 53C37.3754 53 36.9277 52.5523 36.9277 52C36.9277 51.4477 37.3754 51 37.9277 51C38.48 51 38.9277 51.4477 38.9277 52Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M44.9277 52C44.9277 52.5523 44.48 53 43.9277 53C43.3754 53 42.9277 52.5523 42.9277 52C42.9277 51.4477 43.3754 51 43.9277 51C44.48 51 44.9277 51.4477 44.9277 52Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M50.9277 52C50.9277 52.5523 50.48 53 49.9277 53C49.3754 53 48.9277 52.5523 48.9277 52C48.9277 51.4477 49.3754 51 49.9277 51C50.48 51 50.9277 51.4477 50.9277 52Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M56.9277 52C56.9277 52.5523 56.48 53 55.9277 53C55.3754 53 54.9277 52.5523 54.9277 52C54.9277 51.4477 55.3754 51 55.9277 51C56.48 51 56.9277 51.4477 56.9277 52Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M62.9277 52C62.9277 52.5523 62.48 53 61.9277 53C61.3754 53 60.9277 52.5523 60.9277 52C60.9277 51.4477 61.3754 51 61.9277 51C62.48 51 62.9277 51.4477 62.9277 52Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M68.9277 52C68.9277 52.5523 68.48 53 67.9277 53C67.3754 53 66.9277 52.5523 66.9277 52C66.9277 51.4477 67.3754 51 67.9277 51C68.48 51 68.9277 51.4477 68.9277 52Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M74.9277 52C74.9277 52.5523 74.48 53 73.9277 53C73.3754 53 72.9277 52.5523 72.9277 52C72.9277 51.4477 73.3754 51 73.9277 51C74.48 51 74.9277 51.4477 74.9277 52Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M80.9277 52C80.9277 52.5523 80.48 53 79.9277 53C79.3754 53 78.9277 52.5523 78.9277 52C78.9277 51.4477 79.3754 51 79.9277 51C80.48 51 80.9277 51.4477 80.9277 52Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M86.9277 52C86.9277 52.5523 86.48 53 85.9277 53C85.3754 53 84.9277 52.5523 84.9277 52C84.9277 51.4477 85.3754 51 85.9277 51C86.48 51 86.9277 51.4477 86.9277 52Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M92.9277 52C92.9277 52.5523 92.48 53 91.9277 53C91.3754 53 90.9277 52.5523 90.9277 52C90.9277 51.4477 91.3754 51 91.9277 51C92.48 51 92.9277 51.4477 92.9277 52Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M98.9277 52C98.9277 52.5523 98.48 53 97.9277 53C97.3754 53 96.9277 52.5523 96.9277 52C96.9277 51.4477 97.3754 51 97.9277 51C98.48 51 98.9277 51.4477 98.9277 52Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M104.928 52C104.928 52.5523 104.48 53 103.928 53C103.375 53 102.928 52.5523 102.928 52C102.928 51.4477 103.375 51 103.928 51C104.48 51 104.928 51.4477 104.928 52Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M110.928 52C110.928 52.5523 110.48 53 109.928 53C109.375 53 108.928 52.5523 108.928 52C108.928 51.4477 109.375 51 109.928 51C110.48 51 110.928 51.4477 110.928 52Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <circle
          cx='1.92773'
          cy='76'
          r='1'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <circle
          cx='7.92773'
          cy='76'
          r='1'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M14.9277 76C14.9277 76.5523 14.48 77 13.9277 77C13.3754 77 12.9277 76.5523 12.9277 76C12.9277 75.4477 13.3754 75 13.9277 75C14.48 75 14.9277 75.4477 14.9277 76Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M20.9277 76C20.9277 76.5523 20.48 77 19.9277 77C19.3754 77 18.9277 76.5523 18.9277 76C18.9277 75.4477 19.3754 75 19.9277 75C20.48 75 20.9277 75.4477 20.9277 76Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M26.9277 76C26.9277 76.5523 26.48 77 25.9277 77C25.3754 77 24.9277 76.5523 24.9277 76C24.9277 75.4477 25.3754 75 25.9277 75C26.48 75 26.9277 75.4477 26.9277 76Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M32.9277 76C32.9277 76.5523 32.48 77 31.9277 77C31.3754 77 30.9277 76.5523 30.9277 76C30.9277 75.4477 31.3754 75 31.9277 75C32.48 75 32.9277 75.4477 32.9277 76Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M38.9277 76C38.9277 76.5523 38.48 77 37.9277 77C37.3754 77 36.9277 76.5523 36.9277 76C36.9277 75.4477 37.3754 75 37.9277 75C38.48 75 38.9277 75.4477 38.9277 76Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M44.9277 76C44.9277 76.5523 44.48 77 43.9277 77C43.3754 77 42.9277 76.5523 42.9277 76C42.9277 75.4477 43.3754 75 43.9277 75C44.48 75 44.9277 75.4477 44.9277 76Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M50.9277 76C50.9277 76.5523 50.48 77 49.9277 77C49.3754 77 48.9277 76.5523 48.9277 76C48.9277 75.4477 49.3754 75 49.9277 75C50.48 75 50.9277 75.4477 50.9277 76Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M56.9277 76C56.9277 76.5523 56.48 77 55.9277 77C55.3754 77 54.9277 76.5523 54.9277 76C54.9277 75.4477 55.3754 75 55.9277 75C56.48 75 56.9277 75.4477 56.9277 76Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M62.9277 76C62.9277 76.5523 62.48 77 61.9277 77C61.3754 77 60.9277 76.5523 60.9277 76C60.9277 75.4477 61.3754 75 61.9277 75C62.48 75 62.9277 75.4477 62.9277 76Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M68.9277 76C68.9277 76.5523 68.48 77 67.9277 77C67.3754 77 66.9277 76.5523 66.9277 76C66.9277 75.4477 67.3754 75 67.9277 75C68.48 75 68.9277 75.4477 68.9277 76Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M74.9277 76C74.9277 76.5523 74.48 77 73.9277 77C73.3754 77 72.9277 76.5523 72.9277 76C72.9277 75.4477 73.3754 75 73.9277 75C74.48 75 74.9277 75.4477 74.9277 76Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M80.9277 76C80.9277 76.5523 80.48 77 79.9277 77C79.3754 77 78.9277 76.5523 78.9277 76C78.9277 75.4477 79.3754 75 79.9277 75C80.48 75 80.9277 75.4477 80.9277 76Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M86.9277 76C86.9277 76.5523 86.48 77 85.9277 77C85.3754 77 84.9277 76.5523 84.9277 76C84.9277 75.4477 85.3754 75 85.9277 75C86.48 75 86.9277 75.4477 86.9277 76Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M92.9277 76C92.9277 76.5523 92.48 77 91.9277 77C91.3754 77 90.9277 76.5523 90.9277 76C90.9277 75.4477 91.3754 75 91.9277 75C92.48 75 92.9277 75.4477 92.9277 76Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M98.9277 76C98.9277 76.5523 98.48 77 97.9277 77C97.3754 77 96.9277 76.5523 96.9277 76C96.9277 75.4477 97.3754 75 97.9277 75C98.48 75 98.9277 75.4477 98.9277 76Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M104.928 76C104.928 76.5523 104.48 77 103.928 77C103.375 77 102.928 76.5523 102.928 76C102.928 75.4477 103.375 75 103.928 75C104.48 75 104.928 75.4477 104.928 76Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M110.928 76C110.928 76.5523 110.48 77 109.928 77C109.375 77 108.928 76.5523 108.928 76C108.928 75.4477 109.375 75 109.928 75C110.48 75 110.928 75.4477 110.928 76Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <circle
          cx='1.92773'
          cy='100'
          r='1'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <circle
          cx='7.92773'
          cy='100'
          r='1'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M14.9277 100C14.9277 100.552 14.48 101 13.9277 101C13.3754 101 12.9277 100.552 12.9277 100C12.9277 99.4477 13.3754 99 13.9277 99C14.48 99 14.9277 99.4477 14.9277 100Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M20.9277 100C20.9277 100.552 20.48 101 19.9277 101C19.3754 101 18.9277 100.552 18.9277 100C18.9277 99.4477 19.3754 99 19.9277 99C20.48 99 20.9277 99.4477 20.9277 100Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M26.9277 100C26.9277 100.552 26.48 101 25.9277 101C25.3754 101 24.9277 100.552 24.9277 100C24.9277 99.4477 25.3754 99 25.9277 99C26.48 99 26.9277 99.4477 26.9277 100Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M32.9277 100C32.9277 100.552 32.48 101 31.9277 101C31.3754 101 30.9277 100.552 30.9277 100C30.9277 99.4477 31.3754 99 31.9277 99C32.48 99 32.9277 99.4477 32.9277 100Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M38.9277 100C38.9277 100.552 38.48 101 37.9277 101C37.3754 101 36.9277 100.552 36.9277 100C36.9277 99.4477 37.3754 99 37.9277 99C38.48 99 38.9277 99.4477 38.9277 100Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M44.9277 100C44.9277 100.552 44.48 101 43.9277 101C43.3754 101 42.9277 100.552 42.9277 100C42.9277 99.4477 43.3754 99 43.9277 99C44.48 99 44.9277 99.4477 44.9277 100Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M50.9277 100C50.9277 100.552 50.48 101 49.9277 101C49.3754 101 48.9277 100.552 48.9277 100C48.9277 99.4477 49.3754 99 49.9277 99C50.48 99 50.9277 99.4477 50.9277 100Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M56.9277 100C56.9277 100.552 56.48 101 55.9277 101C55.3754 101 54.9277 100.552 54.9277 100C54.9277 99.4477 55.3754 99 55.9277 99C56.48 99 56.9277 99.4477 56.9277 100Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M62.9277 100C62.9277 100.552 62.48 101 61.9277 101C61.3754 101 60.9277 100.552 60.9277 100C60.9277 99.4477 61.3754 99 61.9277 99C62.48 99 62.9277 99.4477 62.9277 100Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M68.9277 100C68.9277 100.552 68.48 101 67.9277 101C67.3754 101 66.9277 100.552 66.9277 100C66.9277 99.4477 67.3754 99 67.9277 99C68.48 99 68.9277 99.4477 68.9277 100Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M74.9277 100C74.9277 100.552 74.48 101 73.9277 101C73.3754 101 72.9277 100.552 72.9277 100C72.9277 99.4477 73.3754 99 73.9277 99C74.48 99 74.9277 99.4477 74.9277 100Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M80.9277 100C80.9277 100.552 80.48 101 79.9277 101C79.3754 101 78.9277 100.552 78.9277 100C78.9277 99.4477 79.3754 99 79.9277 99C80.48 99 80.9277 99.4477 80.9277 100Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M86.9277 100C86.9277 100.552 86.48 101 85.9277 101C85.3754 101 84.9277 100.552 84.9277 100C84.9277 99.4477 85.3754 99 85.9277 99C86.48 99 86.9277 99.4477 86.9277 100Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M92.9277 100C92.9277 100.552 92.48 101 91.9277 101C91.3754 101 90.9277 100.552 90.9277 100C90.9277 99.4477 91.3754 99 91.9277 99C92.48 99 92.9277 99.4477 92.9277 100Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M98.9277 100C98.9277 100.552 98.48 101 97.9277 101C97.3754 101 96.9277 100.552 96.9277 100C96.9277 99.4477 97.3754 99 97.9277 99C98.48 99 98.9277 99.4477 98.9277 100Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M104.928 100C104.928 100.552 104.48 101 103.928 101C103.375 101 102.928 100.552 102.928 100C102.928 99.4477 103.375 99 103.928 99C104.48 99 104.928 99.4477 104.928 100Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M110.928 100C110.928 100.552 110.48 101 109.928 101C109.375 101 108.928 100.552 108.928 100C108.928 99.4477 109.375 99 109.928 99C110.48 99 110.928 99.4477 110.928 100Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <circle
          cx='1.92773'
          cy='16'
          r='1'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <circle
          cx='7.92773'
          cy='16'
          r='1'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M14.9277 16C14.9277 16.5523 14.48 17 13.9277 17C13.3754 17 12.9277 16.5523 12.9277 16C12.9277 15.4477 13.3754 15 13.9277 15C14.48 15 14.9277 15.4477 14.9277 16Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M20.9277 16C20.9277 16.5523 20.48 17 19.9277 17C19.3754 17 18.9277 16.5523 18.9277 16C18.9277 15.4477 19.3754 15 19.9277 15C20.48 15 20.9277 15.4477 20.9277 16Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M26.9277 16C26.9277 16.5523 26.48 17 25.9277 17C25.3754 17 24.9277 16.5523 24.9277 16C24.9277 15.4477 25.3754 15 25.9277 15C26.48 15 26.9277 15.4477 26.9277 16Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M32.9277 16C32.9277 16.5523 32.48 17 31.9277 17C31.3754 17 30.9277 16.5523 30.9277 16C30.9277 15.4477 31.3754 15 31.9277 15C32.48 15 32.9277 15.4477 32.9277 16Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M38.9277 16C38.9277 16.5523 38.48 17 37.9277 17C37.3754 17 36.9277 16.5523 36.9277 16C36.9277 15.4477 37.3754 15 37.9277 15C38.48 15 38.9277 15.4477 38.9277 16Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M44.9277 16C44.9277 16.5523 44.48 17 43.9277 17C43.3754 17 42.9277 16.5523 42.9277 16C42.9277 15.4477 43.3754 15 43.9277 15C44.48 15 44.9277 15.4477 44.9277 16Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M50.9277 16C50.9277 16.5523 50.48 17 49.9277 17C49.3754 17 48.9277 16.5523 48.9277 16C48.9277 15.4477 49.3754 15 49.9277 15C50.48 15 50.9277 15.4477 50.9277 16Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M56.9277 16C56.9277 16.5523 56.48 17 55.9277 17C55.3754 17 54.9277 16.5523 54.9277 16C54.9277 15.4477 55.3754 15 55.9277 15C56.48 15 56.9277 15.4477 56.9277 16Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M62.9277 16C62.9277 16.5523 62.48 17 61.9277 17C61.3754 17 60.9277 16.5523 60.9277 16C60.9277 15.4477 61.3754 15 61.9277 15C62.48 15 62.9277 15.4477 62.9277 16Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M68.9277 16C68.9277 16.5523 68.48 17 67.9277 17C67.3754 17 66.9277 16.5523 66.9277 16C66.9277 15.4477 67.3754 15 67.9277 15C68.48 15 68.9277 15.4477 68.9277 16Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M74.9277 16C74.9277 16.5523 74.48 17 73.9277 17C73.3754 17 72.9277 16.5523 72.9277 16C72.9277 15.4477 73.3754 15 73.9277 15C74.48 15 74.9277 15.4477 74.9277 16Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M80.9277 16C80.9277 16.5523 80.48 17 79.9277 17C79.3754 17 78.9277 16.5523 78.9277 16C78.9277 15.4477 79.3754 15 79.9277 15C80.48 15 80.9277 15.4477 80.9277 16Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M86.9277 16C86.9277 16.5523 86.48 17 85.9277 17C85.3754 17 84.9277 16.5523 84.9277 16C84.9277 15.4477 85.3754 15 85.9277 15C86.48 15 86.9277 15.4477 86.9277 16Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M92.9277 16C92.9277 16.5523 92.48 17 91.9277 17C91.3754 17 90.9277 16.5523 90.9277 16C90.9277 15.4477 91.3754 15 91.9277 15C92.48 15 92.9277 15.4477 92.9277 16Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M98.9277 16C98.9277 16.5523 98.48 17 97.9277 17C97.3754 17 96.9277 16.5523 96.9277 16C96.9277 15.4477 97.3754 15 97.9277 15C98.48 15 98.9277 15.4477 98.9277 16Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M104.928 16C104.928 16.5523 104.48 17 103.928 17C103.375 17 102.928 16.5523 102.928 16C102.928 15.4477 103.375 15 103.928 15C104.48 15 104.928 15.4477 104.928 16Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M110.928 16C110.928 16.5523 110.48 17 109.928 17C109.375 17 108.928 16.5523 108.928 16C108.928 15.4477 109.375 15 109.928 15C110.48 15 110.928 15.4477 110.928 16Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <circle
          cx='1.92773'
          cy='40'
          r='1'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <circle
          cx='7.92773'
          cy='40'
          r='1'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M14.9277 40C14.9277 40.5523 14.48 41 13.9277 41C13.3754 41 12.9277 40.5523 12.9277 40C12.9277 39.4477 13.3754 39 13.9277 39C14.48 39 14.9277 39.4477 14.9277 40Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M20.9277 40C20.9277 40.5523 20.48 41 19.9277 41C19.3754 41 18.9277 40.5523 18.9277 40C18.9277 39.4477 19.3754 39 19.9277 39C20.48 39 20.9277 39.4477 20.9277 40Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M26.9277 40C26.9277 40.5523 26.48 41 25.9277 41C25.3754 41 24.9277 40.5523 24.9277 40C24.9277 39.4477 25.3754 39 25.9277 39C26.48 39 26.9277 39.4477 26.9277 40Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M32.9277 40C32.9277 40.5523 32.48 41 31.9277 41C31.3754 41 30.9277 40.5523 30.9277 40C30.9277 39.4477 31.3754 39 31.9277 39C32.48 39 32.9277 39.4477 32.9277 40Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M38.9277 40C38.9277 40.5523 38.48 41 37.9277 41C37.3754 41 36.9277 40.5523 36.9277 40C36.9277 39.4477 37.3754 39 37.9277 39C38.48 39 38.9277 39.4477 38.9277 40Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M44.9277 40C44.9277 40.5523 44.48 41 43.9277 41C43.3754 41 42.9277 40.5523 42.9277 40C42.9277 39.4477 43.3754 39 43.9277 39C44.48 39 44.9277 39.4477 44.9277 40Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M50.9277 40C50.9277 40.5523 50.48 41 49.9277 41C49.3754 41 48.9277 40.5523 48.9277 40C48.9277 39.4477 49.3754 39 49.9277 39C50.48 39 50.9277 39.4477 50.9277 40Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M56.9277 40C56.9277 40.5523 56.48 41 55.9277 41C55.3754 41 54.9277 40.5523 54.9277 40C54.9277 39.4477 55.3754 39 55.9277 39C56.48 39 56.9277 39.4477 56.9277 40Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M62.9277 40C62.9277 40.5523 62.48 41 61.9277 41C61.3754 41 60.9277 40.5523 60.9277 40C60.9277 39.4477 61.3754 39 61.9277 39C62.48 39 62.9277 39.4477 62.9277 40Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M68.9277 40C68.9277 40.5523 68.48 41 67.9277 41C67.3754 41 66.9277 40.5523 66.9277 40C66.9277 39.4477 67.3754 39 67.9277 39C68.48 39 68.9277 39.4477 68.9277 40Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M74.9277 40C74.9277 40.5523 74.48 41 73.9277 41C73.3754 41 72.9277 40.5523 72.9277 40C72.9277 39.4477 73.3754 39 73.9277 39C74.48 39 74.9277 39.4477 74.9277 40Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M80.9277 40C80.9277 40.5523 80.48 41 79.9277 41C79.3754 41 78.9277 40.5523 78.9277 40C78.9277 39.4477 79.3754 39 79.9277 39C80.48 39 80.9277 39.4477 80.9277 40Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M86.9277 40C86.9277 40.5523 86.48 41 85.9277 41C85.3754 41 84.9277 40.5523 84.9277 40C84.9277 39.4477 85.3754 39 85.9277 39C86.48 39 86.9277 39.4477 86.9277 40Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M92.9277 40C92.9277 40.5523 92.48 41 91.9277 41C91.3754 41 90.9277 40.5523 90.9277 40C90.9277 39.4477 91.3754 39 91.9277 39C92.48 39 92.9277 39.4477 92.9277 40Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M98.9277 40C98.9277 40.5523 98.48 41 97.9277 41C97.3754 41 96.9277 40.5523 96.9277 40C96.9277 39.4477 97.3754 39 97.9277 39C98.48 39 98.9277 39.4477 98.9277 40Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M104.928 40C104.928 40.5523 104.48 41 103.928 41C103.375 41 102.928 40.5523 102.928 40C102.928 39.4477 103.375 39 103.928 39C104.48 39 104.928 39.4477 104.928 40Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M110.928 40C110.928 40.5523 110.48 41 109.928 41C109.375 41 108.928 40.5523 108.928 40C108.928 39.4477 109.375 39 109.928 39C110.48 39 110.928 39.4477 110.928 40Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <circle
          cx='1.92773'
          cy='64'
          r='1'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <circle
          cx='7.92773'
          cy='64'
          r='1'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M14.9277 64C14.9277 64.5523 14.48 65 13.9277 65C13.3754 65 12.9277 64.5523 12.9277 64C12.9277 63.4477 13.3754 63 13.9277 63C14.48 63 14.9277 63.4477 14.9277 64Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M20.9277 64C20.9277 64.5523 20.48 65 19.9277 65C19.3754 65 18.9277 64.5523 18.9277 64C18.9277 63.4477 19.3754 63 19.9277 63C20.48 63 20.9277 63.4477 20.9277 64Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M26.9277 64C26.9277 64.5523 26.48 65 25.9277 65C25.3754 65 24.9277 64.5523 24.9277 64C24.9277 63.4477 25.3754 63 25.9277 63C26.48 63 26.9277 63.4477 26.9277 64Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M32.9277 64C32.9277 64.5523 32.48 65 31.9277 65C31.3754 65 30.9277 64.5523 30.9277 64C30.9277 63.4477 31.3754 63 31.9277 63C32.48 63 32.9277 63.4477 32.9277 64Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M38.9277 64C38.9277 64.5523 38.48 65 37.9277 65C37.3754 65 36.9277 64.5523 36.9277 64C36.9277 63.4477 37.3754 63 37.9277 63C38.48 63 38.9277 63.4477 38.9277 64Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M44.9277 64C44.9277 64.5523 44.48 65 43.9277 65C43.3754 65 42.9277 64.5523 42.9277 64C42.9277 63.4477 43.3754 63 43.9277 63C44.48 63 44.9277 63.4477 44.9277 64Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M50.9277 64C50.9277 64.5523 50.48 65 49.9277 65C49.3754 65 48.9277 64.5523 48.9277 64C48.9277 63.4477 49.3754 63 49.9277 63C50.48 63 50.9277 63.4477 50.9277 64Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M56.9277 64C56.9277 64.5523 56.48 65 55.9277 65C55.3754 65 54.9277 64.5523 54.9277 64C54.9277 63.4477 55.3754 63 55.9277 63C56.48 63 56.9277 63.4477 56.9277 64Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M62.9277 64C62.9277 64.5523 62.48 65 61.9277 65C61.3754 65 60.9277 64.5523 60.9277 64C60.9277 63.4477 61.3754 63 61.9277 63C62.48 63 62.9277 63.4477 62.9277 64Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M68.9277 64C68.9277 64.5523 68.48 65 67.9277 65C67.3754 65 66.9277 64.5523 66.9277 64C66.9277 63.4477 67.3754 63 67.9277 63C68.48 63 68.9277 63.4477 68.9277 64Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M74.9277 64C74.9277 64.5523 74.48 65 73.9277 65C73.3754 65 72.9277 64.5523 72.9277 64C72.9277 63.4477 73.3754 63 73.9277 63C74.48 63 74.9277 63.4477 74.9277 64Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M80.9277 64C80.9277 64.5523 80.48 65 79.9277 65C79.3754 65 78.9277 64.5523 78.9277 64C78.9277 63.4477 79.3754 63 79.9277 63C80.48 63 80.9277 63.4477 80.9277 64Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M86.9277 64C86.9277 64.5523 86.48 65 85.9277 65C85.3754 65 84.9277 64.5523 84.9277 64C84.9277 63.4477 85.3754 63 85.9277 63C86.48 63 86.9277 63.4477 86.9277 64Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M92.9277 64C92.9277 64.5523 92.48 65 91.9277 65C91.3754 65 90.9277 64.5523 90.9277 64C90.9277 63.4477 91.3754 63 91.9277 63C92.48 63 92.9277 63.4477 92.9277 64Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M98.9277 64C98.9277 64.5523 98.48 65 97.9277 65C97.3754 65 96.9277 64.5523 96.9277 64C96.9277 63.4477 97.3754 63 97.9277 63C98.48 63 98.9277 63.4477 98.9277 64Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M104.928 64C104.928 64.5523 104.48 65 103.928 65C103.375 65 102.928 64.5523 102.928 64C102.928 63.4477 103.375 63 103.928 63C104.48 63 104.928 63.4477 104.928 64Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M110.928 64C110.928 64.5523 110.48 65 109.928 65C109.375 65 108.928 64.5523 108.928 64C108.928 63.4477 109.375 63 109.928 63C110.48 63 110.928 63.4477 110.928 64Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <circle
          cx='1.92773'
          cy='88'
          r='1'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <circle
          cx='7.92773'
          cy='88'
          r='1'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M14.9277 88C14.9277 88.5523 14.48 89 13.9277 89C13.3754 89 12.9277 88.5523 12.9277 88C12.9277 87.4477 13.3754 87 13.9277 87C14.48 87 14.9277 87.4477 14.9277 88Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M20.9277 88C20.9277 88.5523 20.48 89 19.9277 89C19.3754 89 18.9277 88.5523 18.9277 88C18.9277 87.4477 19.3754 87 19.9277 87C20.48 87 20.9277 87.4477 20.9277 88Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M26.9277 88C26.9277 88.5523 26.48 89 25.9277 89C25.3754 89 24.9277 88.5523 24.9277 88C24.9277 87.4477 25.3754 87 25.9277 87C26.48 87 26.9277 87.4477 26.9277 88Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M32.9277 88C32.9277 88.5523 32.48 89 31.9277 89C31.3754 89 30.9277 88.5523 30.9277 88C30.9277 87.4477 31.3754 87 31.9277 87C32.48 87 32.9277 87.4477 32.9277 88Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M38.9277 88C38.9277 88.5523 38.48 89 37.9277 89C37.3754 89 36.9277 88.5523 36.9277 88C36.9277 87.4477 37.3754 87 37.9277 87C38.48 87 38.9277 87.4477 38.9277 88Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M44.9277 88C44.9277 88.5523 44.48 89 43.9277 89C43.3754 89 42.9277 88.5523 42.9277 88C42.9277 87.4477 43.3754 87 43.9277 87C44.48 87 44.9277 87.4477 44.9277 88Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M50.9277 88C50.9277 88.5523 50.48 89 49.9277 89C49.3754 89 48.9277 88.5523 48.9277 88C48.9277 87.4477 49.3754 87 49.9277 87C50.48 87 50.9277 87.4477 50.9277 88Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M56.9277 88C56.9277 88.5523 56.48 89 55.9277 89C55.3754 89 54.9277 88.5523 54.9277 88C54.9277 87.4477 55.3754 87 55.9277 87C56.48 87 56.9277 87.4477 56.9277 88Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M62.9277 88C62.9277 88.5523 62.48 89 61.9277 89C61.3754 89 60.9277 88.5523 60.9277 88C60.9277 87.4477 61.3754 87 61.9277 87C62.48 87 62.9277 87.4477 62.9277 88Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M68.9277 88C68.9277 88.5523 68.48 89 67.9277 89C67.3754 89 66.9277 88.5523 66.9277 88C66.9277 87.4477 67.3754 87 67.9277 87C68.48 87 68.9277 87.4477 68.9277 88Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M74.9277 88C74.9277 88.5523 74.48 89 73.9277 89C73.3754 89 72.9277 88.5523 72.9277 88C72.9277 87.4477 73.3754 87 73.9277 87C74.48 87 74.9277 87.4477 74.9277 88Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M80.9277 88C80.9277 88.5523 80.48 89 79.9277 89C79.3754 89 78.9277 88.5523 78.9277 88C78.9277 87.4477 79.3754 87 79.9277 87C80.48 87 80.9277 87.4477 80.9277 88Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M86.9277 88C86.9277 88.5523 86.48 89 85.9277 89C85.3754 89 84.9277 88.5523 84.9277 88C84.9277 87.4477 85.3754 87 85.9277 87C86.48 87 86.9277 87.4477 86.9277 88Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M92.9277 88C92.9277 88.5523 92.48 89 91.9277 89C91.3754 89 90.9277 88.5523 90.9277 88C90.9277 87.4477 91.3754 87 91.9277 87C92.48 87 92.9277 87.4477 92.9277 88Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M98.9277 88C98.9277 88.5523 98.48 89 97.9277 89C97.3754 89 96.9277 88.5523 96.9277 88C96.9277 87.4477 97.3754 87 97.9277 87C98.48 87 98.9277 87.4477 98.9277 88Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M104.928 88C104.928 88.5523 104.48 89 103.928 89C103.375 89 102.928 88.5523 102.928 88C102.928 87.4477 103.375 87 103.928 87C104.48 87 104.928 87.4477 104.928 88Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M110.928 88C110.928 88.5523 110.48 89 109.928 89C109.375 89 108.928 88.5523 108.928 88C108.928 87.4477 109.375 87 109.928 87C110.48 87 110.928 87.4477 110.928 88Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <circle
          cx='1.92773'
          cy='112'
          r='1'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <circle
          cx='7.92773'
          cy='112'
          r='1'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M14.9277 112C14.9277 112.552 14.48 113 13.9277 113C13.3754 113 12.9277 112.552 12.9277 112C12.9277 111.448 13.3754 111 13.9277 111C14.48 111 14.9277 111.448 14.9277 112Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M20.9277 112C20.9277 112.552 20.48 113 19.9277 113C19.3754 113 18.9277 112.552 18.9277 112C18.9277 111.448 19.3754 111 19.9277 111C20.48 111 20.9277 111.448 20.9277 112Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M26.9277 112C26.9277 112.552 26.48 113 25.9277 113C25.3754 113 24.9277 112.552 24.9277 112C24.9277 111.448 25.3754 111 25.9277 111C26.48 111 26.9277 111.448 26.9277 112Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M32.9277 112C32.9277 112.552 32.48 113 31.9277 113C31.3754 113 30.9277 112.552 30.9277 112C30.9277 111.448 31.3754 111 31.9277 111C32.48 111 32.9277 111.448 32.9277 112Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M38.9277 112C38.9277 112.552 38.48 113 37.9277 113C37.3754 113 36.9277 112.552 36.9277 112C36.9277 111.448 37.3754 111 37.9277 111C38.48 111 38.9277 111.448 38.9277 112Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M44.9277 112C44.9277 112.552 44.48 113 43.9277 113C43.3754 113 42.9277 112.552 42.9277 112C42.9277 111.448 43.3754 111 43.9277 111C44.48 111 44.9277 111.448 44.9277 112Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M50.9277 112C50.9277 112.552 50.48 113 49.9277 113C49.3754 113 48.9277 112.552 48.9277 112C48.9277 111.448 49.3754 111 49.9277 111C50.48 111 50.9277 111.448 50.9277 112Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M56.9277 112C56.9277 112.552 56.48 113 55.9277 113C55.3754 113 54.9277 112.552 54.9277 112C54.9277 111.448 55.3754 111 55.9277 111C56.48 111 56.9277 111.448 56.9277 112Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M62.9277 112C62.9277 112.552 62.48 113 61.9277 113C61.3754 113 60.9277 112.552 60.9277 112C60.9277 111.448 61.3754 111 61.9277 111C62.48 111 62.9277 111.448 62.9277 112Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M68.9277 112C68.9277 112.552 68.48 113 67.9277 113C67.3754 113 66.9277 112.552 66.9277 112C66.9277 111.448 67.3754 111 67.9277 111C68.48 111 68.9277 111.448 68.9277 112Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M74.9277 112C74.9277 112.552 74.48 113 73.9277 113C73.3754 113 72.9277 112.552 72.9277 112C72.9277 111.448 73.3754 111 73.9277 111C74.48 111 74.9277 111.448 74.9277 112Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M80.9277 112C80.9277 112.552 80.48 113 79.9277 113C79.3754 113 78.9277 112.552 78.9277 112C78.9277 111.448 79.3754 111 79.9277 111C80.48 111 80.9277 111.448 80.9277 112Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M86.9277 112C86.9277 112.552 86.48 113 85.9277 113C85.3754 113 84.9277 112.552 84.9277 112C84.9277 111.448 85.3754 111 85.9277 111C86.48 111 86.9277 111.448 86.9277 112Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M92.9277 112C92.9277 112.552 92.48 113 91.9277 113C91.3754 113 90.9277 112.552 90.9277 112C90.9277 111.448 91.3754 111 91.9277 111C92.48 111 92.9277 111.448 92.9277 112Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M98.9277 112C98.9277 112.552 98.48 113 97.9277 113C97.3754 113 96.9277 112.552 96.9277 112C96.9277 111.448 97.3754 111 97.9277 111C98.48 111 98.9277 111.448 98.9277 112Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M104.928 112C104.928 112.552 104.48 113 103.928 113C103.375 113 102.928 112.552 102.928 112C102.928 111.448 103.375 111 103.928 111C104.48 111 104.928 111.448 104.928 112Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M110.928 112C110.928 112.552 110.48 113 109.928 113C109.375 113 108.928 112.552 108.928 112C108.928 111.448 109.375 111 109.928 111C110.48 111 110.928 111.448 110.928 112Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <circle
          cx='4.92773'
          cy='10'
          r='1'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <circle
          cx='10.9277'
          cy='10'
          r='1'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M17.9277 10C17.9277 10.5523 17.48 11 16.9277 11C16.3754 11 15.9277 10.5523 15.9277 10C15.9277 9.44772 16.3754 9 16.9277 9C17.48 9 17.9277 9.44772 17.9277 10Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M23.9277 10C23.9277 10.5523 23.48 11 22.9277 11C22.3754 11 21.9277 10.5523 21.9277 10C21.9277 9.44772 22.3754 9 22.9277 9C23.48 9 23.9277 9.44772 23.9277 10Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M29.9277 10C29.9277 10.5523 29.48 11 28.9277 11C28.3754 11 27.9277 10.5523 27.9277 10C27.9277 9.44772 28.3754 9 28.9277 9C29.48 9 29.9277 9.44772 29.9277 10Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M35.9277 10C35.9277 10.5523 35.48 11 34.9277 11C34.3754 11 33.9277 10.5523 33.9277 10C33.9277 9.44772 34.3754 9 34.9277 9C35.48 9 35.9277 9.44772 35.9277 10Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M41.9277 10C41.9277 10.5523 41.48 11 40.9277 11C40.3754 11 39.9277 10.5523 39.9277 10C39.9277 9.44772 40.3754 9 40.9277 9C41.48 9 41.9277 9.44772 41.9277 10Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M47.9277 10C47.9277 10.5523 47.48 11 46.9277 11C46.3754 11 45.9277 10.5523 45.9277 10C45.9277 9.44772 46.3754 9 46.9277 9C47.48 9 47.9277 9.44772 47.9277 10Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M53.9277 10C53.9277 10.5523 53.48 11 52.9277 11C52.3754 11 51.9277 10.5523 51.9277 10C51.9277 9.44772 52.3754 9 52.9277 9C53.48 9 53.9277 9.44772 53.9277 10Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M59.9277 10C59.9277 10.5523 59.48 11 58.9277 11C58.3754 11 57.9277 10.5523 57.9277 10C57.9277 9.44772 58.3754 9 58.9277 9C59.48 9 59.9277 9.44772 59.9277 10Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M65.9277 10C65.9277 10.5523 65.48 11 64.9277 11C64.3754 11 63.9277 10.5523 63.9277 10C63.9277 9.44772 64.3754 9 64.9277 9C65.48 9 65.9277 9.44772 65.9277 10Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M71.9277 10C71.9277 10.5523 71.48 11 70.9277 11C70.3754 11 69.9277 10.5523 69.9277 10C69.9277 9.44772 70.3754 9 70.9277 9C71.48 9 71.9277 9.44772 71.9277 10Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M77.9277 10C77.9277 10.5523 77.48 11 76.9277 11C76.3754 11 75.9277 10.5523 75.9277 10C75.9277 9.44772 76.3754 9 76.9277 9C77.48 9 77.9277 9.44772 77.9277 10Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M83.9277 10C83.9277 10.5523 83.48 11 82.9277 11C82.3754 11 81.9277 10.5523 81.9277 10C81.9277 9.44772 82.3754 9 82.9277 9C83.48 9 83.9277 9.44772 83.9277 10Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M89.9277 10C89.9277 10.5523 89.48 11 88.9277 11C88.3754 11 87.9277 10.5523 87.9277 10C87.9277 9.44772 88.3754 9 88.9277 9C89.48 9 89.9277 9.44772 89.9277 10Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M95.9277 10C95.9277 10.5523 95.48 11 94.9277 11C94.3754 11 93.9277 10.5523 93.9277 10C93.9277 9.44772 94.3754 9 94.9277 9C95.48 9 95.9277 9.44772 95.9277 10Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M101.928 10C101.928 10.5523 101.48 11 100.928 11C100.375 11 99.9277 10.5523 99.9277 10C99.9277 9.44772 100.375 9 100.928 9C101.48 9 101.928 9.44772 101.928 10Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M107.928 10C107.928 10.5523 107.48 11 106.928 11C106.375 11 105.928 10.5523 105.928 10C105.928 9.44772 106.375 9 106.928 9C107.48 9 107.928 9.44772 107.928 10Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M113.928 10C113.928 10.5523 113.48 11 112.928 11C112.375 11 111.928 10.5523 111.928 10C111.928 9.44772 112.375 9 112.928 9C113.48 9 113.928 9.44772 113.928 10Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <circle
          cx='4.92773'
          cy='34'
          r='1'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <circle
          cx='10.9277'
          cy='34'
          r='1'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M17.9277 34C17.9277 34.5523 17.48 35 16.9277 35C16.3754 35 15.9277 34.5523 15.9277 34C15.9277 33.4477 16.3754 33 16.9277 33C17.48 33 17.9277 33.4477 17.9277 34Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M23.9277 34C23.9277 34.5523 23.48 35 22.9277 35C22.3754 35 21.9277 34.5523 21.9277 34C21.9277 33.4477 22.3754 33 22.9277 33C23.48 33 23.9277 33.4477 23.9277 34Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M29.9277 34C29.9277 34.5523 29.48 35 28.9277 35C28.3754 35 27.9277 34.5523 27.9277 34C27.9277 33.4477 28.3754 33 28.9277 33C29.48 33 29.9277 33.4477 29.9277 34Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M35.9277 34C35.9277 34.5523 35.48 35 34.9277 35C34.3754 35 33.9277 34.5523 33.9277 34C33.9277 33.4477 34.3754 33 34.9277 33C35.48 33 35.9277 33.4477 35.9277 34Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M41.9277 34C41.9277 34.5523 41.48 35 40.9277 35C40.3754 35 39.9277 34.5523 39.9277 34C39.9277 33.4477 40.3754 33 40.9277 33C41.48 33 41.9277 33.4477 41.9277 34Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M47.9277 34C47.9277 34.5523 47.48 35 46.9277 35C46.3754 35 45.9277 34.5523 45.9277 34C45.9277 33.4477 46.3754 33 46.9277 33C47.48 33 47.9277 33.4477 47.9277 34Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M53.9277 34C53.9277 34.5523 53.48 35 52.9277 35C52.3754 35 51.9277 34.5523 51.9277 34C51.9277 33.4477 52.3754 33 52.9277 33C53.48 33 53.9277 33.4477 53.9277 34Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M59.9277 34C59.9277 34.5523 59.48 35 58.9277 35C58.3754 35 57.9277 34.5523 57.9277 34C57.9277 33.4477 58.3754 33 58.9277 33C59.48 33 59.9277 33.4477 59.9277 34Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M65.9277 34C65.9277 34.5523 65.48 35 64.9277 35C64.3754 35 63.9277 34.5523 63.9277 34C63.9277 33.4477 64.3754 33 64.9277 33C65.48 33 65.9277 33.4477 65.9277 34Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M71.9277 34C71.9277 34.5523 71.48 35 70.9277 35C70.3754 35 69.9277 34.5523 69.9277 34C69.9277 33.4477 70.3754 33 70.9277 33C71.48 33 71.9277 33.4477 71.9277 34Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M77.9277 34C77.9277 34.5523 77.48 35 76.9277 35C76.3754 35 75.9277 34.5523 75.9277 34C75.9277 33.4477 76.3754 33 76.9277 33C77.48 33 77.9277 33.4477 77.9277 34Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M83.9277 34C83.9277 34.5523 83.48 35 82.9277 35C82.3754 35 81.9277 34.5523 81.9277 34C81.9277 33.4477 82.3754 33 82.9277 33C83.48 33 83.9277 33.4477 83.9277 34Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M89.9277 34C89.9277 34.5523 89.48 35 88.9277 35C88.3754 35 87.9277 34.5523 87.9277 34C87.9277 33.4477 88.3754 33 88.9277 33C89.48 33 89.9277 33.4477 89.9277 34Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M95.9277 34C95.9277 34.5523 95.48 35 94.9277 35C94.3754 35 93.9277 34.5523 93.9277 34C93.9277 33.4477 94.3754 33 94.9277 33C95.48 33 95.9277 33.4477 95.9277 34Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M101.928 34C101.928 34.5523 101.48 35 100.928 35C100.375 35 99.9277 34.5523 99.9277 34C99.9277 33.4477 100.375 33 100.928 33C101.48 33 101.928 33.4477 101.928 34Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M107.928 34C107.928 34.5523 107.48 35 106.928 35C106.375 35 105.928 34.5523 105.928 34C105.928 33.4477 106.375 33 106.928 33C107.48 33 107.928 33.4477 107.928 34Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M113.928 34C113.928 34.5523 113.48 35 112.928 35C112.375 35 111.928 34.5523 111.928 34C111.928 33.4477 112.375 33 112.928 33C113.48 33 113.928 33.4477 113.928 34Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <circle
          cx='4.92773'
          cy='58'
          r='1'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <circle
          cx='10.9277'
          cy='58'
          r='1'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M17.9277 58C17.9277 58.5523 17.48 59 16.9277 59C16.3754 59 15.9277 58.5523 15.9277 58C15.9277 57.4477 16.3754 57 16.9277 57C17.48 57 17.9277 57.4477 17.9277 58Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M23.9277 58C23.9277 58.5523 23.48 59 22.9277 59C22.3754 59 21.9277 58.5523 21.9277 58C21.9277 57.4477 22.3754 57 22.9277 57C23.48 57 23.9277 57.4477 23.9277 58Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M29.9277 58C29.9277 58.5523 29.48 59 28.9277 59C28.3754 59 27.9277 58.5523 27.9277 58C27.9277 57.4477 28.3754 57 28.9277 57C29.48 57 29.9277 57.4477 29.9277 58Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M35.9277 58C35.9277 58.5523 35.48 59 34.9277 59C34.3754 59 33.9277 58.5523 33.9277 58C33.9277 57.4477 34.3754 57 34.9277 57C35.48 57 35.9277 57.4477 35.9277 58Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M41.9277 58C41.9277 58.5523 41.48 59 40.9277 59C40.3754 59 39.9277 58.5523 39.9277 58C39.9277 57.4477 40.3754 57 40.9277 57C41.48 57 41.9277 57.4477 41.9277 58Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M47.9277 58C47.9277 58.5523 47.48 59 46.9277 59C46.3754 59 45.9277 58.5523 45.9277 58C45.9277 57.4477 46.3754 57 46.9277 57C47.48 57 47.9277 57.4477 47.9277 58Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M53.9277 58C53.9277 58.5523 53.48 59 52.9277 59C52.3754 59 51.9277 58.5523 51.9277 58C51.9277 57.4477 52.3754 57 52.9277 57C53.48 57 53.9277 57.4477 53.9277 58Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M59.9277 58C59.9277 58.5523 59.48 59 58.9277 59C58.3754 59 57.9277 58.5523 57.9277 58C57.9277 57.4477 58.3754 57 58.9277 57C59.48 57 59.9277 57.4477 59.9277 58Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M65.9277 58C65.9277 58.5523 65.48 59 64.9277 59C64.3754 59 63.9277 58.5523 63.9277 58C63.9277 57.4477 64.3754 57 64.9277 57C65.48 57 65.9277 57.4477 65.9277 58Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M71.9277 58C71.9277 58.5523 71.48 59 70.9277 59C70.3754 59 69.9277 58.5523 69.9277 58C69.9277 57.4477 70.3754 57 70.9277 57C71.48 57 71.9277 57.4477 71.9277 58Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M77.9277 58C77.9277 58.5523 77.48 59 76.9277 59C76.3754 59 75.9277 58.5523 75.9277 58C75.9277 57.4477 76.3754 57 76.9277 57C77.48 57 77.9277 57.4477 77.9277 58Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M83.9277 58C83.9277 58.5523 83.48 59 82.9277 59C82.3754 59 81.9277 58.5523 81.9277 58C81.9277 57.4477 82.3754 57 82.9277 57C83.48 57 83.9277 57.4477 83.9277 58Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M89.9277 58C89.9277 58.5523 89.48 59 88.9277 59C88.3754 59 87.9277 58.5523 87.9277 58C87.9277 57.4477 88.3754 57 88.9277 57C89.48 57 89.9277 57.4477 89.9277 58Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M95.9277 58C95.9277 58.5523 95.48 59 94.9277 59C94.3754 59 93.9277 58.5523 93.9277 58C93.9277 57.4477 94.3754 57 94.9277 57C95.48 57 95.9277 57.4477 95.9277 58Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M101.928 58C101.928 58.5523 101.48 59 100.928 59C100.375 59 99.9277 58.5523 99.9277 58C99.9277 57.4477 100.375 57 100.928 57C101.48 57 101.928 57.4477 101.928 58Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M107.928 58C107.928 58.5523 107.48 59 106.928 59C106.375 59 105.928 58.5523 105.928 58C105.928 57.4477 106.375 57 106.928 57C107.48 57 107.928 57.4477 107.928 58Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M113.928 58C113.928 58.5523 113.48 59 112.928 59C112.375 59 111.928 58.5523 111.928 58C111.928 57.4477 112.375 57 112.928 57C113.48 57 113.928 57.4477 113.928 58Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <circle
          cx='4.92773'
          cy='82'
          r='1'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <circle
          cx='10.9277'
          cy='82'
          r='1'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M17.9277 82C17.9277 82.5523 17.48 83 16.9277 83C16.3754 83 15.9277 82.5523 15.9277 82C15.9277 81.4477 16.3754 81 16.9277 81C17.48 81 17.9277 81.4477 17.9277 82Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M23.9277 82C23.9277 82.5523 23.48 83 22.9277 83C22.3754 83 21.9277 82.5523 21.9277 82C21.9277 81.4477 22.3754 81 22.9277 81C23.48 81 23.9277 81.4477 23.9277 82Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M29.9277 82C29.9277 82.5523 29.48 83 28.9277 83C28.3754 83 27.9277 82.5523 27.9277 82C27.9277 81.4477 28.3754 81 28.9277 81C29.48 81 29.9277 81.4477 29.9277 82Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M35.9277 82C35.9277 82.5523 35.48 83 34.9277 83C34.3754 83 33.9277 82.5523 33.9277 82C33.9277 81.4477 34.3754 81 34.9277 81C35.48 81 35.9277 81.4477 35.9277 82Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M41.9277 82C41.9277 82.5523 41.48 83 40.9277 83C40.3754 83 39.9277 82.5523 39.9277 82C39.9277 81.4477 40.3754 81 40.9277 81C41.48 81 41.9277 81.4477 41.9277 82Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M47.9277 82C47.9277 82.5523 47.48 83 46.9277 83C46.3754 83 45.9277 82.5523 45.9277 82C45.9277 81.4477 46.3754 81 46.9277 81C47.48 81 47.9277 81.4477 47.9277 82Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M53.9277 82C53.9277 82.5523 53.48 83 52.9277 83C52.3754 83 51.9277 82.5523 51.9277 82C51.9277 81.4477 52.3754 81 52.9277 81C53.48 81 53.9277 81.4477 53.9277 82Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M59.9277 82C59.9277 82.5523 59.48 83 58.9277 83C58.3754 83 57.9277 82.5523 57.9277 82C57.9277 81.4477 58.3754 81 58.9277 81C59.48 81 59.9277 81.4477 59.9277 82Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M65.9277 82C65.9277 82.5523 65.48 83 64.9277 83C64.3754 83 63.9277 82.5523 63.9277 82C63.9277 81.4477 64.3754 81 64.9277 81C65.48 81 65.9277 81.4477 65.9277 82Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M71.9277 82C71.9277 82.5523 71.48 83 70.9277 83C70.3754 83 69.9277 82.5523 69.9277 82C69.9277 81.4477 70.3754 81 70.9277 81C71.48 81 71.9277 81.4477 71.9277 82Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M77.9277 82C77.9277 82.5523 77.48 83 76.9277 83C76.3754 83 75.9277 82.5523 75.9277 82C75.9277 81.4477 76.3754 81 76.9277 81C77.48 81 77.9277 81.4477 77.9277 82Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M83.9277 82C83.9277 82.5523 83.48 83 82.9277 83C82.3754 83 81.9277 82.5523 81.9277 82C81.9277 81.4477 82.3754 81 82.9277 81C83.48 81 83.9277 81.4477 83.9277 82Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M89.9277 82C89.9277 82.5523 89.48 83 88.9277 83C88.3754 83 87.9277 82.5523 87.9277 82C87.9277 81.4477 88.3754 81 88.9277 81C89.48 81 89.9277 81.4477 89.9277 82Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M95.9277 82C95.9277 82.5523 95.48 83 94.9277 83C94.3754 83 93.9277 82.5523 93.9277 82C93.9277 81.4477 94.3754 81 94.9277 81C95.48 81 95.9277 81.4477 95.9277 82Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M101.928 82C101.928 82.5523 101.48 83 100.928 83C100.375 83 99.9277 82.5523 99.9277 82C99.9277 81.4477 100.375 81 100.928 81C101.48 81 101.928 81.4477 101.928 82Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M107.928 82C107.928 82.5523 107.48 83 106.928 83C106.375 83 105.928 82.5523 105.928 82C105.928 81.4477 106.375 81 106.928 81C107.48 81 107.928 81.4477 107.928 82Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M113.928 82C113.928 82.5523 113.48 83 112.928 83C112.375 83 111.928 82.5523 111.928 82C111.928 81.4477 112.375 81 112.928 81C113.48 81 113.928 81.4477 113.928 82Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <circle
          cx='4.92773'
          cy='106'
          r='1'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <circle
          cx='10.9277'
          cy='106'
          r='1'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M17.9277 106C17.9277 106.552 17.48 107 16.9277 107C16.3754 107 15.9277 106.552 15.9277 106C15.9277 105.448 16.3754 105 16.9277 105C17.48 105 17.9277 105.448 17.9277 106Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M23.9277 106C23.9277 106.552 23.48 107 22.9277 107C22.3754 107 21.9277 106.552 21.9277 106C21.9277 105.448 22.3754 105 22.9277 105C23.48 105 23.9277 105.448 23.9277 106Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M29.9277 106C29.9277 106.552 29.48 107 28.9277 107C28.3754 107 27.9277 106.552 27.9277 106C27.9277 105.448 28.3754 105 28.9277 105C29.48 105 29.9277 105.448 29.9277 106Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M35.9277 106C35.9277 106.552 35.48 107 34.9277 107C34.3754 107 33.9277 106.552 33.9277 106C33.9277 105.448 34.3754 105 34.9277 105C35.48 105 35.9277 105.448 35.9277 106Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M41.9277 106C41.9277 106.552 41.48 107 40.9277 107C40.3754 107 39.9277 106.552 39.9277 106C39.9277 105.448 40.3754 105 40.9277 105C41.48 105 41.9277 105.448 41.9277 106Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M47.9277 106C47.9277 106.552 47.48 107 46.9277 107C46.3754 107 45.9277 106.552 45.9277 106C45.9277 105.448 46.3754 105 46.9277 105C47.48 105 47.9277 105.448 47.9277 106Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M53.9277 106C53.9277 106.552 53.48 107 52.9277 107C52.3754 107 51.9277 106.552 51.9277 106C51.9277 105.448 52.3754 105 52.9277 105C53.48 105 53.9277 105.448 53.9277 106Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M59.9277 106C59.9277 106.552 59.48 107 58.9277 107C58.3754 107 57.9277 106.552 57.9277 106C57.9277 105.448 58.3754 105 58.9277 105C59.48 105 59.9277 105.448 59.9277 106Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M65.9277 106C65.9277 106.552 65.48 107 64.9277 107C64.3754 107 63.9277 106.552 63.9277 106C63.9277 105.448 64.3754 105 64.9277 105C65.48 105 65.9277 105.448 65.9277 106Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M71.9277 106C71.9277 106.552 71.48 107 70.9277 107C70.3754 107 69.9277 106.552 69.9277 106C69.9277 105.448 70.3754 105 70.9277 105C71.48 105 71.9277 105.448 71.9277 106Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M77.9277 106C77.9277 106.552 77.48 107 76.9277 107C76.3754 107 75.9277 106.552 75.9277 106C75.9277 105.448 76.3754 105 76.9277 105C77.48 105 77.9277 105.448 77.9277 106Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M83.9277 106C83.9277 106.552 83.48 107 82.9277 107C82.3754 107 81.9277 106.552 81.9277 106C81.9277 105.448 82.3754 105 82.9277 105C83.48 105 83.9277 105.448 83.9277 106Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M89.9277 106C89.9277 106.552 89.48 107 88.9277 107C88.3754 107 87.9277 106.552 87.9277 106C87.9277 105.448 88.3754 105 88.9277 105C89.48 105 89.9277 105.448 89.9277 106Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M95.9277 106C95.9277 106.552 95.48 107 94.9277 107C94.3754 107 93.9277 106.552 93.9277 106C93.9277 105.448 94.3754 105 94.9277 105C95.48 105 95.9277 105.448 95.9277 106Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M101.928 106C101.928 106.552 101.48 107 100.928 107C100.375 107 99.9277 106.552 99.9277 106C99.9277 105.448 100.375 105 100.928 105C101.48 105 101.928 105.448 101.928 106Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M107.928 106C107.928 106.552 107.48 107 106.928 107C106.375 107 105.928 106.552 105.928 106C105.928 105.448 106.375 105 106.928 105C107.48 105 107.928 105.448 107.928 106Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M113.928 106C113.928 106.552 113.48 107 112.928 107C112.375 107 111.928 106.552 111.928 106C111.928 105.448 112.375 105 112.928 105C113.48 105 113.928 105.448 113.928 106Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <circle
          cx='4.92773'
          cy='22'
          r='1'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <circle
          cx='10.9277'
          cy='22'
          r='1'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M17.9277 22C17.9277 22.5523 17.48 23 16.9277 23C16.3754 23 15.9277 22.5523 15.9277 22C15.9277 21.4477 16.3754 21 16.9277 21C17.48 21 17.9277 21.4477 17.9277 22Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M23.9277 22C23.9277 22.5523 23.48 23 22.9277 23C22.3754 23 21.9277 22.5523 21.9277 22C21.9277 21.4477 22.3754 21 22.9277 21C23.48 21 23.9277 21.4477 23.9277 22Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M29.9277 22C29.9277 22.5523 29.48 23 28.9277 23C28.3754 23 27.9277 22.5523 27.9277 22C27.9277 21.4477 28.3754 21 28.9277 21C29.48 21 29.9277 21.4477 29.9277 22Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M35.9277 22C35.9277 22.5523 35.48 23 34.9277 23C34.3754 23 33.9277 22.5523 33.9277 22C33.9277 21.4477 34.3754 21 34.9277 21C35.48 21 35.9277 21.4477 35.9277 22Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M41.9277 22C41.9277 22.5523 41.48 23 40.9277 23C40.3754 23 39.9277 22.5523 39.9277 22C39.9277 21.4477 40.3754 21 40.9277 21C41.48 21 41.9277 21.4477 41.9277 22Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M47.9277 22C47.9277 22.5523 47.48 23 46.9277 23C46.3754 23 45.9277 22.5523 45.9277 22C45.9277 21.4477 46.3754 21 46.9277 21C47.48 21 47.9277 21.4477 47.9277 22Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M53.9277 22C53.9277 22.5523 53.48 23 52.9277 23C52.3754 23 51.9277 22.5523 51.9277 22C51.9277 21.4477 52.3754 21 52.9277 21C53.48 21 53.9277 21.4477 53.9277 22Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M59.9277 22C59.9277 22.5523 59.48 23 58.9277 23C58.3754 23 57.9277 22.5523 57.9277 22C57.9277 21.4477 58.3754 21 58.9277 21C59.48 21 59.9277 21.4477 59.9277 22Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M65.9277 22C65.9277 22.5523 65.48 23 64.9277 23C64.3754 23 63.9277 22.5523 63.9277 22C63.9277 21.4477 64.3754 21 64.9277 21C65.48 21 65.9277 21.4477 65.9277 22Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M71.9277 22C71.9277 22.5523 71.48 23 70.9277 23C70.3754 23 69.9277 22.5523 69.9277 22C69.9277 21.4477 70.3754 21 70.9277 21C71.48 21 71.9277 21.4477 71.9277 22Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M77.9277 22C77.9277 22.5523 77.48 23 76.9277 23C76.3754 23 75.9277 22.5523 75.9277 22C75.9277 21.4477 76.3754 21 76.9277 21C77.48 21 77.9277 21.4477 77.9277 22Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M83.9277 22C83.9277 22.5523 83.48 23 82.9277 23C82.3754 23 81.9277 22.5523 81.9277 22C81.9277 21.4477 82.3754 21 82.9277 21C83.48 21 83.9277 21.4477 83.9277 22Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M89.9277 22C89.9277 22.5523 89.48 23 88.9277 23C88.3754 23 87.9277 22.5523 87.9277 22C87.9277 21.4477 88.3754 21 88.9277 21C89.48 21 89.9277 21.4477 89.9277 22Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M95.9277 22C95.9277 22.5523 95.48 23 94.9277 23C94.3754 23 93.9277 22.5523 93.9277 22C93.9277 21.4477 94.3754 21 94.9277 21C95.48 21 95.9277 21.4477 95.9277 22Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M101.928 22C101.928 22.5523 101.48 23 100.928 23C100.375 23 99.9277 22.5523 99.9277 22C99.9277 21.4477 100.375 21 100.928 21C101.48 21 101.928 21.4477 101.928 22Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M107.928 22C107.928 22.5523 107.48 23 106.928 23C106.375 23 105.928 22.5523 105.928 22C105.928 21.4477 106.375 21 106.928 21C107.48 21 107.928 21.4477 107.928 22Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M113.928 22C113.928 22.5523 113.48 23 112.928 23C112.375 23 111.928 22.5523 111.928 22C111.928 21.4477 112.375 21 112.928 21C113.48 21 113.928 21.4477 113.928 22Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <circle
          cx='4.92773'
          cy='46'
          r='1'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <circle
          cx='10.9277'
          cy='46'
          r='1'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M17.9277 46C17.9277 46.5523 17.48 47 16.9277 47C16.3754 47 15.9277 46.5523 15.9277 46C15.9277 45.4477 16.3754 45 16.9277 45C17.48 45 17.9277 45.4477 17.9277 46Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M23.9277 46C23.9277 46.5523 23.48 47 22.9277 47C22.3754 47 21.9277 46.5523 21.9277 46C21.9277 45.4477 22.3754 45 22.9277 45C23.48 45 23.9277 45.4477 23.9277 46Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M29.9277 46C29.9277 46.5523 29.48 47 28.9277 47C28.3754 47 27.9277 46.5523 27.9277 46C27.9277 45.4477 28.3754 45 28.9277 45C29.48 45 29.9277 45.4477 29.9277 46Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M35.9277 46C35.9277 46.5523 35.48 47 34.9277 47C34.3754 47 33.9277 46.5523 33.9277 46C33.9277 45.4477 34.3754 45 34.9277 45C35.48 45 35.9277 45.4477 35.9277 46Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M41.9277 46C41.9277 46.5523 41.48 47 40.9277 47C40.3754 47 39.9277 46.5523 39.9277 46C39.9277 45.4477 40.3754 45 40.9277 45C41.48 45 41.9277 45.4477 41.9277 46Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M47.9277 46C47.9277 46.5523 47.48 47 46.9277 47C46.3754 47 45.9277 46.5523 45.9277 46C45.9277 45.4477 46.3754 45 46.9277 45C47.48 45 47.9277 45.4477 47.9277 46Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M53.9277 46C53.9277 46.5523 53.48 47 52.9277 47C52.3754 47 51.9277 46.5523 51.9277 46C51.9277 45.4477 52.3754 45 52.9277 45C53.48 45 53.9277 45.4477 53.9277 46Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M59.9277 46C59.9277 46.5523 59.48 47 58.9277 47C58.3754 47 57.9277 46.5523 57.9277 46C57.9277 45.4477 58.3754 45 58.9277 45C59.48 45 59.9277 45.4477 59.9277 46Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M65.9277 46C65.9277 46.5523 65.48 47 64.9277 47C64.3754 47 63.9277 46.5523 63.9277 46C63.9277 45.4477 64.3754 45 64.9277 45C65.48 45 65.9277 45.4477 65.9277 46Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M71.9277 46C71.9277 46.5523 71.48 47 70.9277 47C70.3754 47 69.9277 46.5523 69.9277 46C69.9277 45.4477 70.3754 45 70.9277 45C71.48 45 71.9277 45.4477 71.9277 46Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M77.9277 46C77.9277 46.5523 77.48 47 76.9277 47C76.3754 47 75.9277 46.5523 75.9277 46C75.9277 45.4477 76.3754 45 76.9277 45C77.48 45 77.9277 45.4477 77.9277 46Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M83.9277 46C83.9277 46.5523 83.48 47 82.9277 47C82.3754 47 81.9277 46.5523 81.9277 46C81.9277 45.4477 82.3754 45 82.9277 45C83.48 45 83.9277 45.4477 83.9277 46Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M89.9277 46C89.9277 46.5523 89.48 47 88.9277 47C88.3754 47 87.9277 46.5523 87.9277 46C87.9277 45.4477 88.3754 45 88.9277 45C89.48 45 89.9277 45.4477 89.9277 46Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M95.9277 46C95.9277 46.5523 95.48 47 94.9277 47C94.3754 47 93.9277 46.5523 93.9277 46C93.9277 45.4477 94.3754 45 94.9277 45C95.48 45 95.9277 45.4477 95.9277 46Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M101.928 46C101.928 46.5523 101.48 47 100.928 47C100.375 47 99.9277 46.5523 99.9277 46C99.9277 45.4477 100.375 45 100.928 45C101.48 45 101.928 45.4477 101.928 46Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M107.928 46C107.928 46.5523 107.48 47 106.928 47C106.375 47 105.928 46.5523 105.928 46C105.928 45.4477 106.375 45 106.928 45C107.48 45 107.928 45.4477 107.928 46Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M113.928 46C113.928 46.5523 113.48 47 112.928 47C112.375 47 111.928 46.5523 111.928 46C111.928 45.4477 112.375 45 112.928 45C113.48 45 113.928 45.4477 113.928 46Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <circle
          cx='4.92773'
          cy='70'
          r='1'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <circle
          cx='10.9277'
          cy='70'
          r='1'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M17.9277 70C17.9277 70.5523 17.48 71 16.9277 71C16.3754 71 15.9277 70.5523 15.9277 70C15.9277 69.4477 16.3754 69 16.9277 69C17.48 69 17.9277 69.4477 17.9277 70Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M23.9277 70C23.9277 70.5523 23.48 71 22.9277 71C22.3754 71 21.9277 70.5523 21.9277 70C21.9277 69.4477 22.3754 69 22.9277 69C23.48 69 23.9277 69.4477 23.9277 70Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M29.9277 70C29.9277 70.5523 29.48 71 28.9277 71C28.3754 71 27.9277 70.5523 27.9277 70C27.9277 69.4477 28.3754 69 28.9277 69C29.48 69 29.9277 69.4477 29.9277 70Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M35.9277 70C35.9277 70.5523 35.48 71 34.9277 71C34.3754 71 33.9277 70.5523 33.9277 70C33.9277 69.4477 34.3754 69 34.9277 69C35.48 69 35.9277 69.4477 35.9277 70Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M41.9277 70C41.9277 70.5523 41.48 71 40.9277 71C40.3754 71 39.9277 70.5523 39.9277 70C39.9277 69.4477 40.3754 69 40.9277 69C41.48 69 41.9277 69.4477 41.9277 70Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M47.9277 70C47.9277 70.5523 47.48 71 46.9277 71C46.3754 71 45.9277 70.5523 45.9277 70C45.9277 69.4477 46.3754 69 46.9277 69C47.48 69 47.9277 69.4477 47.9277 70Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M53.9277 70C53.9277 70.5523 53.48 71 52.9277 71C52.3754 71 51.9277 70.5523 51.9277 70C51.9277 69.4477 52.3754 69 52.9277 69C53.48 69 53.9277 69.4477 53.9277 70Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M59.9277 70C59.9277 70.5523 59.48 71 58.9277 71C58.3754 71 57.9277 70.5523 57.9277 70C57.9277 69.4477 58.3754 69 58.9277 69C59.48 69 59.9277 69.4477 59.9277 70Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M65.9277 70C65.9277 70.5523 65.48 71 64.9277 71C64.3754 71 63.9277 70.5523 63.9277 70C63.9277 69.4477 64.3754 69 64.9277 69C65.48 69 65.9277 69.4477 65.9277 70Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M71.9277 70C71.9277 70.5523 71.48 71 70.9277 71C70.3754 71 69.9277 70.5523 69.9277 70C69.9277 69.4477 70.3754 69 70.9277 69C71.48 69 71.9277 69.4477 71.9277 70Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M77.9277 70C77.9277 70.5523 77.48 71 76.9277 71C76.3754 71 75.9277 70.5523 75.9277 70C75.9277 69.4477 76.3754 69 76.9277 69C77.48 69 77.9277 69.4477 77.9277 70Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M83.9277 70C83.9277 70.5523 83.48 71 82.9277 71C82.3754 71 81.9277 70.5523 81.9277 70C81.9277 69.4477 82.3754 69 82.9277 69C83.48 69 83.9277 69.4477 83.9277 70Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M89.9277 70C89.9277 70.5523 89.48 71 88.9277 71C88.3754 71 87.9277 70.5523 87.9277 70C87.9277 69.4477 88.3754 69 88.9277 69C89.48 69 89.9277 69.4477 89.9277 70Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M95.9277 70C95.9277 70.5523 95.48 71 94.9277 71C94.3754 71 93.9277 70.5523 93.9277 70C93.9277 69.4477 94.3754 69 94.9277 69C95.48 69 95.9277 69.4477 95.9277 70Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M101.928 70C101.928 70.5523 101.48 71 100.928 71C100.375 71 99.9277 70.5523 99.9277 70C99.9277 69.4477 100.375 69 100.928 69C101.48 69 101.928 69.4477 101.928 70Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M107.928 70C107.928 70.5523 107.48 71 106.928 71C106.375 71 105.928 70.5523 105.928 70C105.928 69.4477 106.375 69 106.928 69C107.48 69 107.928 69.4477 107.928 70Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M113.928 70C113.928 70.5523 113.48 71 112.928 71C112.375 71 111.928 70.5523 111.928 70C111.928 69.4477 112.375 69 112.928 69C113.48 69 113.928 69.4477 113.928 70Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <circle
          cx='4.92773'
          cy='94'
          r='1'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <circle
          cx='10.9277'
          cy='94'
          r='1'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M17.9277 94C17.9277 94.5523 17.48 95 16.9277 95C16.3754 95 15.9277 94.5523 15.9277 94C15.9277 93.4477 16.3754 93 16.9277 93C17.48 93 17.9277 93.4477 17.9277 94Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M23.9277 94C23.9277 94.5523 23.48 95 22.9277 95C22.3754 95 21.9277 94.5523 21.9277 94C21.9277 93.4477 22.3754 93 22.9277 93C23.48 93 23.9277 93.4477 23.9277 94Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M29.9277 94C29.9277 94.5523 29.48 95 28.9277 95C28.3754 95 27.9277 94.5523 27.9277 94C27.9277 93.4477 28.3754 93 28.9277 93C29.48 93 29.9277 93.4477 29.9277 94Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M35.9277 94C35.9277 94.5523 35.48 95 34.9277 95C34.3754 95 33.9277 94.5523 33.9277 94C33.9277 93.4477 34.3754 93 34.9277 93C35.48 93 35.9277 93.4477 35.9277 94Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M41.9277 94C41.9277 94.5523 41.48 95 40.9277 95C40.3754 95 39.9277 94.5523 39.9277 94C39.9277 93.4477 40.3754 93 40.9277 93C41.48 93 41.9277 93.4477 41.9277 94Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M47.9277 94C47.9277 94.5523 47.48 95 46.9277 95C46.3754 95 45.9277 94.5523 45.9277 94C45.9277 93.4477 46.3754 93 46.9277 93C47.48 93 47.9277 93.4477 47.9277 94Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M53.9277 94C53.9277 94.5523 53.48 95 52.9277 95C52.3754 95 51.9277 94.5523 51.9277 94C51.9277 93.4477 52.3754 93 52.9277 93C53.48 93 53.9277 93.4477 53.9277 94Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M59.9277 94C59.9277 94.5523 59.48 95 58.9277 95C58.3754 95 57.9277 94.5523 57.9277 94C57.9277 93.4477 58.3754 93 58.9277 93C59.48 93 59.9277 93.4477 59.9277 94Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M65.9277 94C65.9277 94.5523 65.48 95 64.9277 95C64.3754 95 63.9277 94.5523 63.9277 94C63.9277 93.4477 64.3754 93 64.9277 93C65.48 93 65.9277 93.4477 65.9277 94Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M71.9277 94C71.9277 94.5523 71.48 95 70.9277 95C70.3754 95 69.9277 94.5523 69.9277 94C69.9277 93.4477 70.3754 93 70.9277 93C71.48 93 71.9277 93.4477 71.9277 94Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M77.9277 94C77.9277 94.5523 77.48 95 76.9277 95C76.3754 95 75.9277 94.5523 75.9277 94C75.9277 93.4477 76.3754 93 76.9277 93C77.48 93 77.9277 93.4477 77.9277 94Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M83.9277 94C83.9277 94.5523 83.48 95 82.9277 95C82.3754 95 81.9277 94.5523 81.9277 94C81.9277 93.4477 82.3754 93 82.9277 93C83.48 93 83.9277 93.4477 83.9277 94Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M89.9277 94C89.9277 94.5523 89.48 95 88.9277 95C88.3754 95 87.9277 94.5523 87.9277 94C87.9277 93.4477 88.3754 93 88.9277 93C89.48 93 89.9277 93.4477 89.9277 94Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M95.9277 94C95.9277 94.5523 95.48 95 94.9277 95C94.3754 95 93.9277 94.5523 93.9277 94C93.9277 93.4477 94.3754 93 94.9277 93C95.48 93 95.9277 93.4477 95.9277 94Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M101.928 94C101.928 94.5523 101.48 95 100.928 95C100.375 95 99.9277 94.5523 99.9277 94C99.9277 93.4477 100.375 93 100.928 93C101.48 93 101.928 93.4477 101.928 94Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M107.928 94C107.928 94.5523 107.48 95 106.928 95C106.375 95 105.928 94.5523 105.928 94C105.928 93.4477 106.375 93 106.928 93C107.48 93 107.928 93.4477 107.928 94Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
        <path
          d='M113.928 94C113.928 94.5523 113.48 95 112.928 95C112.375 95 111.928 94.5523 111.928 94C111.928 93.4477 112.375 93 112.928 93C113.48 93 113.928 93.4477 113.928 94Z'
          fill='var(--illustrationsSharedNeutral600)'
        />
      </g>
      <rect
        x='80.5294'
        y='84.6019'
        width='5.86121'
        height='19.2332'
        transform='rotate(-45 80.5294 84.6019)'
        fill='var(--illustrationsSharedNeutral600)'
        stroke='var(--illustrationsSharedNeutral900)'
      />
      <circle
        cx='56.3797'
        cy='58.596'
        r='39.452'
        fill='var(--illustrationsSharedSuccess400)'
      />
      <circle cx='58.095' cy='56.8802' r='38.8802' fill='white' />
      <circle
        cx='58.0949'
        cy='56.8802'
        r='34.3061'
        fill='var(--illustrationsNeutral40)'
      />
      <mask id='path-368-inside-1_10556_277498' fill='white'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M58.0951 94.6168C78.9365 94.6168 95.8317 77.7215 95.8317 56.8801C95.8317 36.0387 78.9365 19.1434 58.0951 19.1434C37.2537 19.1434 20.3584 36.0387 20.3584 56.8801C20.3584 77.7215 37.2537 94.6168 58.0951 94.6168ZM58.0951 87.7555C75.1471 87.7555 88.9705 73.932 88.9705 56.88C88.9705 39.828 75.1471 26.0045 58.0951 26.0045C41.043 26.0045 27.2196 39.828 27.2196 56.88C27.2196 73.932 41.043 87.7555 58.0951 87.7555Z'
        />
      </mask>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M58.0951 94.6168C78.9365 94.6168 95.8317 77.7215 95.8317 56.8801C95.8317 36.0387 78.9365 19.1434 58.0951 19.1434C37.2537 19.1434 20.3584 36.0387 20.3584 56.8801C20.3584 77.7215 37.2537 94.6168 58.0951 94.6168ZM58.0951 87.7555C75.1471 87.7555 88.9705 73.932 88.9705 56.88C88.9705 39.828 75.1471 26.0045 58.0951 26.0045C41.043 26.0045 27.2196 39.828 27.2196 56.88C27.2196 73.932 41.043 87.7555 58.0951 87.7555Z'
        fill='var(--illustrationsSharedNeutral600)'
      />
      <path
        d='M94.8317 56.8801C94.8317 77.1692 78.3842 93.6168 58.0951 93.6168V95.6168C79.4887 95.6168 96.8317 78.2738 96.8317 56.8801H94.8317ZM58.0951 20.1434C78.3842 20.1434 94.8317 36.591 94.8317 56.8801H96.8317C96.8317 35.4864 79.4887 18.1434 58.0951 18.1434V20.1434ZM21.3584 56.8801C21.3584 36.591 37.806 20.1434 58.0951 20.1434V18.1434C36.7014 18.1434 19.3584 35.4864 19.3584 56.8801H21.3584ZM58.0951 93.6168C37.806 93.6168 21.3584 77.1692 21.3584 56.8801H19.3584C19.3584 78.2738 36.7014 95.6168 58.0951 95.6168V93.6168ZM87.9705 56.88C87.9705 73.3798 74.5948 86.7555 58.0951 86.7555V88.7555C75.6994 88.7555 89.9705 74.4843 89.9705 56.88H87.9705ZM58.0951 27.0045C74.5948 27.0045 87.9705 40.3802 87.9705 56.88H89.9705C89.9705 39.2757 75.6994 25.0045 58.0951 25.0045V27.0045ZM28.2196 56.88C28.2196 40.3802 41.5953 27.0045 58.0951 27.0045V25.0045C40.4907 25.0045 26.2196 39.2757 26.2196 56.88H28.2196ZM58.0951 86.7555C41.5953 86.7555 28.2196 73.3798 28.2196 56.88H26.2196C26.2196 74.4843 40.4907 88.7555 58.0951 88.7555V86.7555Z'
        fill='var(--illustrationsSharedNeutral900)'
        mask='url(#path-368-inside-1_10556_277498)'
      />
      <rect
        x='34.5808'
        y='47.088'
        width='74.4733'
        height='18.4401'
        rx='3.5'
        fill='var(--illustrationsNeutral40)'
        stroke='var(--illustrationsNeutral20)'
      />
      <rect
        x='39.635'
        y='52.1431'
        width='8.33147'
        height='8.33147'
        rx='4.16574'
        stroke='var(--illustrationsSharedPrimary500)'
      />
      <rect
        x='41.718'
        y='54.2258'
        width='4.16574'
        height='4.16574'
        rx='2.08287'
        fill='var(--illustrationsSharedNeutral600)'
      />
      <rect
        x='52.1323'
        y='52.8383'
        width='20.8287'
        height='2.77716'
        rx='1.38858'
        fill='var(--illustrationsSharedNeutral600)'
      />
      <rect
        x='52.1323'
        y='57.698'
        width='10.4143'
        height='2.08287'
        rx='1.04143'
        fill='var(--illustrationsSharedNeutral600)'
      />
      <rect
        x='97.261'
        y='52.8372'
        width='6.94289'
        height='2.77716'
        rx='1.38858'
        fill='var(--illustrationsSharedNeutral600)'
      />
      <rect
        x='91.7068'
        y='57.697'
        width='12.4972'
        height='2.08287'
        rx='1.04143'
        fill='var(--illustrationsSuccess20)'
      />
      <path
        d='M98.4804 90.8246L90.8965 98.4086L109.074 116.586C109.855 117.367 111.121 117.367 111.903 116.586L116.658 111.831C117.439 111.05 117.439 109.783 116.658 109.002L98.4804 90.8246Z'
        fill='var(--illustrationsSharedPrimary500)'
        stroke='var(--illustrationsSharedNeutral900)'
      />
    </svg>
  )
}
