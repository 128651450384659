import { CloseIcon, FilterIcon, GlobalSearchIcon } from '@assets/icons'
import { SearchGlobal } from '@assets/illustrations'
import { Button, CustomModal, Spacer, Text } from '@components'
import { useNavigate, useTranslation } from '@hooks'

export const SearchImprovedModal = ({
  close,
}: {
  close: () => void
}): JSX.Element => {
  const { t } = useTranslation()
  const { navigate } = useNavigate()
  const items = [
    {
      icon: <GlobalSearchIcon />,
      title: t('promotions.newSearch.section1.title'),
      description: t('promotions.newSearch.section1.text'),
    },
    {
      icon: <FilterIcon />,
      title: t('promotions.newSearch.section2.title'),
      description: t('promotions.newSearch.section2.text'),
    },
  ]

  return (
    <CustomModal
      size='big'
      height='fullHeight'
      show
      placeOn='right'
      dismissable
      onClose={close}
    >
      <CustomModal.Header>
        <Button
          onClick={close}
          buttonType='link'
          autoWidth
          content={<CloseIcon color='iconPrimaryInitial' size={16} />}
          shape='square'
        />
      </CustomModal.Header>
      <CustomModal.Content>
        <Spacer preset='medium' />
        <SearchGlobal />
        <Spacer preset='large' />
        <div className='d-flex d-flex-col justify-center align-center'>
          <div className=' w-80'>
            <Text
              preset='headline1'
              text={t('promotions.newSearch.headerTitle')}
              align='center'
            />
          </div>
          <Spacer preset='smallPlus' />
          <Text
            preset='paragraph1'
            text={t('promotions.newSearch.headerSubtitle')}
            align='center'
          ></Text>
        </div>
        <Spacer preset='medium' />
        {items && items.length > 0
          ? items?.map((i) => {
              return (
                <div
                  className='d-flex d-flex-row'
                  style={{
                    flexDirection: 'row',
                  }}
                  key={`promo-item-${i?.title}`}
                >
                  <div className='align-self-center'>{i?.icon}</div>
                  <Spacer preset='tiny'></Spacer>
                  <div className='w-100'>
                    <Spacer preset='tinyPlus' />
                    <Text preset='paragraph1' text={i?.title} color='primary' />
                    <Spacer preset='tinyPlus' />
                    <Text
                      preset='paragraph2'
                      text={i?.description}
                      color='secondary'
                    />
                    <Spacer preset='medium' />
                  </div>
                </div>
              )
            })
          : null}
      </CustomModal.Content>
      <CustomModal.Footer>
        <Button
          onClick={() => {
            navigate('/discover/all')
          }}
          text={t('portfolio.make_first_investment')}
        />
      </CustomModal.Footer>
    </CustomModal>
  )
}
