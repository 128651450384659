import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { getOrderTransactions, getTransactions } from '@services'
import {
  OrderTransactions,
  Transactions,
  TransactionsParams,
  TransactionsTab,
} from '@interfaces'

export const fetchTransactions = createAsyncThunk<
  Transactions,
  Omit<TransactionsParams, 'statuses'>
>('fetchTransactions', async (data: TransactionsParams) => {
  const { page, size, selectedFilters } = data
  return getTransactions(page, size, selectedFilters)
})

export const fetchOrderTransactions = createAsyncThunk<
  OrderTransactions,
  TransactionsParams
>('fetchOrderTransactions', async (data: TransactionsParams) => {
  const { page, size, statuses, selectedFilters } = data
  return await getOrderTransactions(page, size, statuses, selectedFilters)
})

export const fetchHomePendingTransactions = createAsyncThunk<
  OrderTransactions,
  TransactionsParams
>('fetchHomePendingTransactions', async (data: TransactionsParams) => {
  const { page, size, statuses, selectedFilters } = data
  return await getOrderTransactions(page, size, statuses, selectedFilters)
})

export const setTransactionsScrollPosition = createAction<{ position: number }>(
  'setTransactionsScrollPosition',
)

export const clearTransactionsList = createAction('clearTransactionsList')

export const setTransactionsTab = createAction<{
  tab: TransactionsTab
}>('setTransactionsTab')
