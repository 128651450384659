import { createAsyncThunk } from '@reduxjs/toolkit'
import { getInvestments } from '@services'
import { Investments } from '@interfaces'

export const fetchInvestments = createAsyncThunk<Investments>(
  'fetchInvestments',
  async () => {
    return await getInvestments()
  },
)
