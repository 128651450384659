import {
  Avatar,
  Button,
  CustomModal,
  Spacer,
  SummaryCard,
  Text,
} from '@components'
import { ReactComponent as SuccessIcon } from '@assets/svg/success.svg'
import { ReactComponent as FailIcon } from '@assets/svg/fail.svg'
import { useLocation } from 'react-router-dom'
import { useTranslation } from '@hooks'
import { useDispatch, useSelector } from 'react-redux'
import { clearMoneyOperationsState } from '../thunk'
import {
  CardPaymentSummaryFlow,
  OperationConfirmationStatus,
} from '@interfaces'
import { formatCurrency } from '@utils/helpers'
import { ReceiptIcon, RestoreIcon } from '@assets/icons'
import {
  clearCreditCardPayment,
  clearRecurringPaymentOptions,
  clearSelectedBank,
  clearTopUpInfo,
  clearTopUpPaymentUrl,
  clearTopUpType,
} from '../topup/thunk'
import { RootState } from '@store'

export function CompletedCardPayment({
  onClose,
}: {
  onClose: () => void
}): JSX.Element {
  const queryParams = useLocation().search
  const urlParams = new URLSearchParams(queryParams)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const paymentInfo = useSelector(
    (state: RootState) => state?.addMoney?.cardPayment?.paymentInfo,
  )

  const details = JSON.parse(
    localStorage.getItem('cardPaymentSummaryFlow'),
  ) as CardPaymentSummaryFlow

  const close = (): void => {
    localStorage.removeItem('cardPaymentSummaryFlow')
    dispatch(clearMoneyOperationsState())
    dispatch(clearTopUpPaymentUrl())
    dispatch(clearTopUpInfo())
    dispatch(clearSelectedBank())
    dispatch(clearCreditCardPayment())
    dispatch(clearTopUpType())
    dispatch(clearRecurringPaymentOptions())
    onClose()
  }

  const isSuccess =
    (urlParams?.get('status') as OperationConfirmationStatus) ===
      OperationConfirmationStatus.success || paymentInfo?.id

  const isRecurring = details?.isReccurring

  const title = isRecurring
    ? t('addCreditCard.recurrentTopUpSuccess')
    : t('addCreditCard.topUpSuccess')

  return (
    <>
      <CustomModal.Header onClick={close} linkText={t('common.close')} />
      <CustomModal.Content className='d-flex d-flex-col align-center justify-center'>
        {isSuccess ? (
          <>
            <Spacer preset='small' />
            <SuccessIcon className='topup__bigLogo b-0' />
            <Spacer preset='smaller' />
            <Text text={title} preset='headline2' />
            <Spacer preset='tiny' />
            <Text
              text={formatCurrency(details?.amount)}
              preset='headline1'
              color='positive'
            />
            <Spacer preset='smaller' />
            <SummaryCard
              className='w-100'
              id='card-payment-submitted-summary'
              data={[
                ...(isRecurring
                  ? [
                      {
                        label: t('recurringPayments.period'),
                        value: t('common.fromToDate', {
                          from: details?.startingDate,
                          to: details?.expirationDate,
                        }),
                      },
                      {
                        label: t('recurringPayments.periodicity.periodicity'),
                        value: details?.periodicity?.title,
                      },
                    ]
                  : []),
                {
                  label: t('confirmPayment.fees', {
                    feesPercent: paymentInfo?.fee?.percentage,
                  }),
                  labelSubText: t('recurringPayments.perTransaction'),
                  value: formatCurrency(details?.feesAmount),
                },
                {
                  label: t('common.totalCost'),
                  value: formatCurrency(details?.total),
                },
                ...(isRecurring
                  ? [
                      {
                        labelChildren: (
                          <>
                            <Avatar
                              roundness='full'
                              size='mediumPlus'
                              image={
                                <RestoreIcon
                                  size={26}
                                  color='iconNeutralSoft'
                                />
                              }
                              bgColor='primaryBase'
                            />
                            <Spacer preset='smaller' />
                            <Text
                              color='secondary'
                              preset='caption'
                              text={t('recurringPayments.topUpSuccessNote')}
                            />
                          </>
                        ),
                      },
                    ]
                  : [
                      {
                        labelChildren: (
                          <>
                            <Avatar
                              roundness='full'
                              size='mediumPlus'
                              image={
                                <ReceiptIcon
                                  size={26}
                                  color='iconNeutralSoft'
                                />
                              }
                              bgColor='primaryBase'
                            />
                            <Spacer preset='smaller' />
                            <Text
                              color='secondary'
                              preset='caption'
                              text={t('buySellFlow.totalOnHoldDisclaimer')}
                            />
                          </>
                        ),
                      },
                    ]),
              ]}
            />
          </>
        ) : (
          <>
            <Spacer preset='enormous' />
            <Spacer preset='enormous' />
            <Spacer preset='enormous' />
            <FailIcon className='topup__extraLogo' />
            <Spacer preset='small' />
            <Text text={t('addCreditCard.topUpError')} preset='headline2' />
            <Spacer preset='small' />
            <Text
              text={t('addCreditCard.topUpErrorDesc')}
              preset='paragraph2'
              align='center'
            />
            <Spacer preset='enormous' />
            <Spacer preset='enormous' />
            <Spacer preset='enormous' />
            <Spacer preset='enormous' />
          </>
        )}
      </CustomModal.Content>
      <CustomModal.Footer>
        <Button
          text={isSuccess ? t('common.continue') : t('common.close')}
          type='submit'
          size='big'
          onClick={close}
        />
      </CustomModal.Footer>
    </>
  )
}
