import { CustomModal, Button } from '@components'
import { useTranslation } from '@hooks'
import { ReactComponent as SuccessIcon } from '@assets/svg/load_success.svg'
import { ReactComponent as FailIcon } from '@assets/svg/fail.svg'
import { OperationConfirmationStatus } from '@interfaces'

interface BankConnectionResultProps {
  onClose: () => void
  show?: boolean
  type?: OperationConfirmationStatus
}

export function BankConnectionResult({
  onClose,
  show,
  type,
}: BankConnectionResultProps): JSX.Element {
  const { t } = useTranslation()

  const responses: {
    [key: string]: {
      icon: JSX.Element
      message: string
      title: string
      type: string
    }
  } = {
    [OperationConfirmationStatus.success]: {
      icon: <SuccessIcon width='90' height='90' />,
      message: t('banksConnection.responses.success'),
      title: '',
      type: 'success',
    },
    [OperationConfirmationStatus.cancelled]: {
      icon: <FailIcon width='64' height='64' />,
      message: t('banksConnection.responses.cancellation.message'),
      title: t('banksConnection.responses.cancellation.title'),
      type: 'cancellation',
    },
    [OperationConfirmationStatus.error]: {
      icon: <FailIcon width='64' height='64' />,
      message: t('banksConnection.responses.error.message'),
      title: t('banksConnection.responses.error.title'),
      type: 'error',
    },
  }

  const item = responses[type || OperationConfirmationStatus.error]

  return (
    <CustomModal show={show} dismissable onClose={onClose} height='fullHeight'>
      <CustomModal.Header
        text={t('banksConnection.instantBankConnection')}
        linkText={t('common.cancel')}
        className='subtitle3 dark--label-title'
        onClick={onClose}
      ></CustomModal.Header>
      <CustomModal.Content className='d-flex d-flex-col justify-center align-center'>
        {item?.icon}
        {item?.title && (
          <span className='headline4 ma-2'>
            {responses[type || 'f']?.title}
          </span>
        )}
        <span className='paragraph2 ma-2'>{item?.message}</span>
      </CustomModal.Content>
      <CustomModal.Footer>
        <Button
          text={t('common.close')}
          type='submit'
          size='big'
          loading={false}
          onClick={onClose}
        />
      </CustomModal.Footer>
    </CustomModal>
  )
}
