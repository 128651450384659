import './spacer.styles.scss'
import { SpacerPresets } from './types'

export interface SpacerProps {
  preset: SpacerPresets
}

export function Spacer({ preset }: SpacerProps): JSX.Element {
  return <div className={`spacer--spacing-${preset?.toString()}`}></div>
}
