import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import { ReducerState } from '@interfaces'
import {
  fetchAnalystRating,
  fetchBullBears,
  fetchEarnings,
  fetchForecast,
  fetchNews,
  resetTipRank,
} from './thunk'
import {
  AnalystRatings,
  BullishBearish,
  Earning,
  Earnings,
  Forecast,
  GetAllNews,
} from '@interfaces/companyOverview'

export interface AllNewsData {
  newsData: GetAllNews[]
  newsFetched: boolean
}

export interface BullBearsData {
  bullBears: BullishBearish[]
  bullBearsFetched: boolean
}

const initialState: ReducerState &
  AnalystRatings &
  Forecast &
  Earnings &
  AllNewsData &
  BullBearsData = {
  totalAnalysts: null,
  buy: null,
  sell: null,
  hold: null,
  consensus: null,
  analystRatingsFetched: false,
  lowPriceTarget: null,
  highPriceTarget: null,
  priceTarget: null,
  estimatedPriceChangePercentage: null,
  lastPrice: null,
  priceTargetCurrencyCode: null,
  monthlyClosingPrices: [],
  forecastFetched: false,
  newsData: [],
  newsFetched: false,
  bullBears: [],
  bullBearsFetched: false,
  loading: false,
  error: null,
  earnings: [],
  earningsLoading: false,
  earningsError: null,
  earningsFetched: false,
}

export const tipRanksReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchAnalystRating.pending, (acc) => {
      acc.loading = true
      acc.error = null
      acc.totalAnalysts = null
      acc.analystRatingsFetched = false
    })
    .addCase(
      fetchAnalystRating.fulfilled,
      (acc, action: PayloadAction<AnalystRatings>) => {
        const { payload } = action
        acc.loading = false
        acc.error = null
        acc.totalAnalysts = payload.totalAnalysts
        acc.buy = payload.buy
        acc.sell = payload.sell
        acc.hold = payload.hold
        acc.consensus = payload.consensus
        acc.analystRatingsFetched = true
      },
    )
    .addCase(fetchAnalystRating.rejected, (acc, { error }) => {
      acc.loading = false
      acc.error = error.message
      acc.analystRatingsFetched = true
    })
    .addCase(fetchForecast.pending, (acc) => {
      acc.loading = true
      acc.error = null
      acc.priceTargetCurrencyCode = null
      acc.monthlyClosingPrices = []
      acc.forecastFetched = false
    })
    .addCase(
      fetchForecast.fulfilled,
      (acc, action: PayloadAction<Forecast>) => {
        const { payload } = action
        acc.loading = false
        acc.error = null
        acc.lowPriceTarget = payload.lowPriceTarget
        acc.highPriceTarget = payload.highPriceTarget
        acc.priceTarget = payload.priceTarget
        acc.estimatedPriceChangePercentage =
          payload.estimatedPriceChangePercentage
        acc.lastPrice = payload.lastPrice
        acc.priceTargetCurrencyCode = payload.priceTargetCurrencyCode
        acc.monthlyClosingPrices = payload.monthlyClosingPrices
        acc.forecastFetched = true
      },
    )
    .addCase(fetchForecast.rejected, (acc, { error }) => {
      acc.loading = false
      acc.error = error.message
      acc.forecastFetched = true
    })
    .addCase(fetchEarnings.pending, (acc) => {
      acc.earningsLoading = true
      acc.earningsError = null
      acc.earnings = []
      acc.earningsFetched = false
    })
    .addCase(
      fetchEarnings.fulfilled,
      (acc, action: PayloadAction<Earning[]>) => {
        const { payload } = action
        acc.earningsLoading = false
        acc.earningsError = null
        acc.earnings = payload
        acc.earningsFetched = true
      },
    )
    .addCase(fetchEarnings.rejected, (acc, { error }) => {
      acc.earningsLoading = false
      acc.earningsError = error.message
      acc.earningsFetched = true
    })

    .addCase(fetchNews.pending, (acc) => {
      acc.loading = true
      acc.error = null
      acc.newsData = []
      acc.newsFetched = false
    })
    .addCase(
      fetchNews.fulfilled,
      (acc, action: PayloadAction<GetAllNews[]>) => {
        const { payload } = action
        acc.loading = false
        acc.error = null
        acc.newsData = payload
        acc.newsFetched = true
      },
    )
    .addCase(fetchNews.rejected, (acc, { error }) => {
      acc.loading = false
      acc.error = error.message
      acc.newsFetched = true
    })

    .addCase(fetchBullBears.pending, (acc) => {
      acc.loading = true
      acc.error = null
      acc.bullBears = []
      acc.bullBearsFetched = false
    })
    .addCase(
      fetchBullBears.fulfilled,
      (acc, action: PayloadAction<BullishBearish[]>) => {
        const { payload } = action
        acc.loading = false
        acc.error = null
        acc.bullBears = payload
        acc.bullBearsFetched = true
      },
    )
    .addCase(fetchBullBears.rejected, (acc, { error }) => {
      acc.loading = false
      acc.error = error.message
      acc.bullBearsFetched = true
    })

    .addCase(resetTipRank, (acc) => {
      acc.priceTargetCurrencyCode = null
      acc.monthlyClosingPrices = []
      acc.totalAnalysts = null
      acc.earnings = []
      acc.newsData = []
      acc.bullBears = []
      acc.analystRatingsFetched = false
      acc.forecastFetched = false
      acc.earningsFetched = false
      acc.newsFetched = false
      acc.bullBearsFetched = false
    })
})
