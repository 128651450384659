/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  SymbolInfo,
  configurationData,
  symbolInfoMapper,
} from './datafeed.helper'

export const onReady = (callback): void => {
  setTimeout(() => callback(configurationData))
}

export const searchSymbols = (): void => {
  return
}

export const unsubscribeBars = (): void => {
  return
}
export const subscribeBars = (): void => {
  return
}

export const datafeedNew = (chartData, symbolInfo: SymbolInfo) => {
  return {
    onReady: onReady,
    searchSymbols: searchSymbols,
    resolveSymbol: (_, onSymbolResolvedCallback) => {
      const combineInfo = symbolInfoMapper(symbolInfo)
      onSymbolResolvedCallback(combineInfo)
    },
    getBars: (
      _symbolInfo,
      _resolution,
      periodParams,
      onHistoryCallback,
      onErrorCallback,
    ) => {
      const { from, to } = periodParams
      try {
        let bars = []
        let index = 0
        chartData.forEach((bar): void => {
          if (bar.time >= from && bar.time < to) {
            bars = [
              ...bars,
              {
                time: bar.time * 1000,
                low: bar.low,
                high: bar.high,
                open: bar.open,
                close: bar.close,
                volume: bar.volume,
              },
            ]
            index = index + 1
          }
        })

        onHistoryCallback(bars, {
          noData: false,
        })
      } catch (error) {
        onErrorCallback(error)
      }
    },
    subscribeBars: subscribeBars,
    unsubscribeBars: unsubscribeBars,
  }
}
