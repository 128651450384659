import { TbccLogo, TbccLogoKa } from '@assets/svg'
import { getLang } from '@utils/langs'

type mainLogoProps = {
  className?: string
}
const MainLogo = ({ className }: mainLogoProps): JSX.Element => {
  return (
    <>
      {getLang() !== 'ka' ? (
        <TbccLogo className={className} />
      ) : (
        <TbccLogoKa className={className} />
      )}
    </>
  )
}

export default MainLogo
