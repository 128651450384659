import { BasicLink, Button, CustomModal, Spacer, Text } from '@components'
import { useTranslation } from '@hooks'

export const CancelOrderModal = ({
  handleCancelOrder,
  close,
}: {
  handleCancelOrder: () => void
  close: () => void
}): JSX.Element => {
  const { t } = useTranslation()

  return (
    <CustomModal show={true} theme='lighter' size='small'>
      <CustomModal.Header text={t('transactions.cancelLimitOrder')} />
      <CustomModal.Content>
        <Spacer preset='tiny' />
        <Text
          preset='paragraph1'
          text={t('transactions.confirmCancelLimitOrder')}
          align='center'
        />
        <Spacer preset='tiny' />
      </CustomModal.Content>
      <CustomModal.Footer>
        <div className='d-flex d-flex-col w-100'>
          <Button
            text={t('transactions.confirmCancelBtn')}
            onClick={handleCancelOrder}
            buttonType='negative'
          />
          <BasicLink
            text={t('common.no')}
            type='action'
            className='w-100 mt-3'
            onClick={close}
          />
        </div>
      </CustomModal.Footer>
    </CustomModal>
  )
}
