import './truelayerPermissions.style.scss'
import { CustomModal, GoBack, Button } from '@components'
import { useTranslation } from '@hooks'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { RootState } from 'store'
import {
  BankAggregationItem,
  OperationConfirmationStatus,
  OperationTypeEnum,
} from '@interfaces'
import { policies } from '@utils/externalUrls'
import { getLang } from '@utils/langs'
import { requestAggregationAuthLink, clearAggregationLink } from './thunk'
import {
  LogoIcon,
  GuardIcon,
  InfoIcon,
  DataIcon,
  SecureIcon,
  RevokeIcon,
  LogoTLIcon,
  ChevronIconUp,
} from '@assets/svg'
import { BankConnectionError } from '@shared/modals'

const windowUrl = window.location

interface TruelayerPermissionsProps {
  prevStep: () => void
  bankSelected: BankAggregationItem
  onClose: () => void
}

export function TruelayerPermissions({
  bankSelected,
  onClose,
  prevStep,
}: TruelayerPermissionsProps): JSX.Element {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const defLang = getLang().split('-')[0]
  const [urlTo, setUrlTo] = useState('')
  const { banksConnection, addMoney } = useSelector((state: RootState) => state)
  const cancelActions = (): void => {
    dispatch(clearAggregationLink())
    onClose()
  }

  const faqsList = [
    {
      icon: <DataIcon width='20px' height='20px' />,
      title: t('banksConnection.info.dataSharingTitle'),
      text: (
        <div>
          <span>{t('banksConnection.info.dataSharingTruelayer')}</span>
          <ul className='default_list mt-1 mb-1'>
            <li>{t('banksConnection.info.fullName')}</li>
            <li>{t('banksConnection.info.accountAdress')}</li>
            <li>{t('banksConnection.info.transactions')}</li>
          </ul>
          <span>{t('banksConnection.info.dataSharing')}</span>
        </div>
      ),
    },
    {
      icon: <InfoIcon width='20px' height='20px' />,
      title: t('banksConnection.info.howItWorksTitle'),
      text: (
        <div>
          <ul className='default_list mt-1 mb-1'>
            <li>
              {t('banksConnection.info.howItWorksSubtitle1', {
                bankname: addMoney?.selectedPaymentMethod?.name,
              })}
            </li>
            <li>{t('banksConnection.info.howItWorksSubtitle2')}</li>
            <li>{t('banksConnection.info.howItWorksSubtitle3')}</li>
            <li>{t('banksConnection.info.howItWorksSubtitle4')}</li>
          </ul>
        </div>
      ),
    },
    {
      icon: <SecureIcon width='20px' height='20px' />,
      title: t('banksConnection.info.dataUsageTitle'),
      text: (
        <div>
          <span>{t('banksConnection.info.dataUsageTitleSubtitle')}</span>
          <ul className='default_list mt-1 mb-1'>
            <li>{t('banksConnection.info.dataUsageTitleSubtitle1')}</li>
            <li>{t('banksConnection.info.dataUsageTitleSubtitle2')}</li>
            <li>{t('banksConnection.info.dataUsageTitleSubtitle3')}</li>
          </ul>
          <span>{t('banksConnection.info.dataUsageTitleSubtitleAfter')}</span>
        </div>
      ),
    },
    {
      icon: <RevokeIcon width='20px' height='20px' />,
      title: t('banksConnection.info.revokeAndRenewTitle'),
      text: t('banksConnection.info.revokeAndRenewSubtitle'),
    },
  ]
  const [faqOpened, setFaqOpened] = useState(null as null | number)

  const handleSelect = (): void => {
    // Ensure redirection is in the same tab
    window.location.replace(urlTo)
    //nextStep()
  }

  const changeFaq = (index: number): void => {
    if (index === faqOpened) {
      setFaqOpened(null)
    } else {
      setFaqOpened(index)
    }
  }

  useEffect(() => {
    if (bankSelected.providerId) {
      const dataTo = {
        providerId: bankSelected.providerId,
        scopes: bankSelected.scopes,
        successRedirectUrl: `${windowUrl.origin}/confirmation?operation=${
          OperationTypeEnum.psd2BankLink as string
        }&status=${OperationConfirmationStatus.success as string}`,
        cancelRedirectUrl: `${windowUrl.origin}/confirmation?operation=${
          OperationTypeEnum.psd2BankLink as string
        }&status=${OperationConfirmationStatus.cancelled as string}`,
        failRedirectUrl: `${windowUrl.origin}/confirmation?operation=${
          OperationTypeEnum.psd2BankLink as string
        }&status=${OperationConfirmationStatus.error as string}`,
      }
      dispatch(requestAggregationAuthLink(dataTo))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!banksConnection.error && banksConnection.aggregationAuthUrl) {
      setUrlTo(banksConnection.aggregationAuthUrl)
      window.localStorage.setItem('urlFrom', windowUrl.pathname)
      window.localStorage.setItem('redirectTopUp', 'true')
    }
  }, [banksConnection])

  return (
    <>
      <CustomModal.Header
        text={t('banksConnection.instantBankConnectionShort')}
        linkText={t('common.cancel')}
        className='subtitle3 dark--label-title'
        onClick={cancelActions}
      >
        <GoBack
          goBack={() => {
            dispatch(clearAggregationLink())
            prevStep()
          }}
        />
      </CustomModal.Header>
      <CustomModal.Content>
        <div className='connected__banks__icons mt-3'>
          <LogoIcon />
          <div className='guard__icon '>
            <GuardIcon />
          </div>
          <img src={bankSelected.logoUrl} alt={bankSelected.displayName} />
        </div>
        <div className='connected__banks__header text-left mt-6'>
          <div className='headline2 mb-1'>
            {t('banksConnection.info.title')}
          </div>
          <div className='paragraph2 secondary--text'>
            {t('banksConnection.info.yourSecurity')}
          </div>
        </div>
        <div className='connected__banks__faqs mt-3 text-left'>
          {faqsList.map((faq, ind) => {
            return (
              <div
                className={`connected__banks__faqs__item cursor-pointer ${
                  faqOpened == ind
                    ? 'connected__banks__faqs__item--selected'
                    : ''
                }`}
                key={ind}
                aria-hidden={true}
                onClick={() => {
                  changeFaq(ind)
                }}
              >
                <div className='faq__item__title headline3 d-flex justify-space-between align-center'>
                  <div className='pre__partd d-flex align-center'>
                    {faq.icon}
                    <span className='ml-1'>{faq.title}</span>
                  </div>
                  <div className='post__part d-flex align-center'>
                    <ChevronIconUp className='arrow__faq' />
                  </div>
                </div>
                <div className='faq__item__text paragraph2 secondary--text'>
                  {faq.text}
                </div>
              </div>
            )
          })}
        </div>
        <div className='mt-5 d-flex align-center justify-center paragraph3 secondary--text'>
          {t('addMoney.securedPartOne')} <LogoTLIcon className='ml-1' />
        </div>
      </CustomModal.Content>
      {banksConnection?.error && (
        <CustomModal.OverlayContent>
          <BankConnectionError close={cancelActions} />
        </CustomModal.OverlayContent>
      )}
      <CustomModal.Footer className='d-flex d-flex-col'>
        <div className='subfooter__text paragraph2 text-left mb-3'>
          <span>{t('addMoney.byContinuingShort')}</span>{' '}
          <a href={policies.termsUrl[defLang]} target='_blank' rel='noreferrer'>
            {t('addMoney.endUserTermsOfService')}
          </a>{' '}
          <span>{t('common.and')}</span>{' '}
          <a
            href={policies.privacyPolicyUrl[defLang]}
            target='_blank'
            rel='noreferrer'
          >
            {t('addMoney.privacyPolicy')}
          </a>
        </div>
        <Button
          text={t('codeEnter.permissionAllowText')}
          type='submit'
          size='big'
          loading={banksConnection.isLoading}
          disabled={
            banksConnection.isLoading || !banksConnection.aggregationAuthUrl
          }
          onClick={() => handleSelect()}
        />
      </CustomModal.Footer>
    </>
  )
}
