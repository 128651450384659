import { Button, CustomModal, Divider, Spacer } from '@components'
import { useTranslation } from '@hooks'
import { RootState, useAppSelector } from '@store'

export const FxRateModal = ({ close }: { close: () => void }): JSX.Element => {
  const { t } = useTranslation()
  const { currenciesExchanges } = useAppSelector(
    (state: RootState) => state.currenciesExchanges,
  )

  return (
    <CustomModal size='small' show={true} onClose={close} dismissable>
      <CustomModal.Header text={t('forexFees.title')} />
      <CustomModal.Content>
        <div className='d-flex d-flex-col w-100'>
          {currenciesExchanges?.exchanges?.map((exchange, idx) => {
            return (
              <div key={`${idx}-${exchange?.source}`}>
                <Spacer preset='smaller' />
                <div className='d-flex d-flex-row paragraph2 justify-space-between'>
                  <p> {`1 ${exchange?.source}`}</p>
                  <p>
                    {exchange?.rate} {currenciesExchanges?.target}
                  </p>
                </div>
                <Spacer preset='smaller' />
                <Divider />
              </div>
            )
          })}
          <p className='mt-2 mb-1 paragraph2 secondary--text'>
            {t('forexFees.description', {
              cacheDuration: currenciesExchanges.cacheDurationInSeconds / 60,
            })}
          </p>
        </div>
      </CustomModal.Content>
      <CustomModal.Footer>
        <Button onClick={() => close()} text={t('common.close')} />
      </CustomModal.Footer>
    </CustomModal>
  )
}
