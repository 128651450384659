import { SavingsPlan } from '@interfaces'
import { api } from '@utils/api/api'

export const getSavingsPlans = (
  assetManager?: string,
): Promise<SavingsPlan[]> => {
  if (!assetManager) {
    return api.get('/api/v1/assets/saving-plans')
  }
  return api.get(`/api/v1/assets/saving-plans?assetManager=${assetManager}`)
}
