/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import './login.styles.scss'
import { useState, useEffect } from 'react'
import { useNavigate, useTranslation } from '@hooks'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { Link } from 'react-router-dom'
import { FieldValues, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { fetch2fa, setCredentials, verify2fa } from './thunk'
import {
  LoginError,
  clearCredentials,
  clearErrors,
  clearSecondsForExpiration,
} from './reducers'
import { ReactComponent as LogoIcon } from '@assets/svg/logo_icon.svg'
import { Button, TextField, Header } from '@components'
import VerificationWidget from 'features/verificationWidget/verificationWidget'
import { Download } from '@features'
import { maskEmail } from '@utils/helpers'
import { trackingService } from '@services'
import { InvalidCredentials, InvalidOTPCode } from '@shared/modals'
import { routes } from 'navigation'
import { emailValidation, passwordValidation } from '@utils/validate'

export default function Login(): JSX.Element {
  const { t } = useTranslation()
  const auth = useSelector((state: RootState) => state.login)

  const { expiration, error, isLoadingLogin, email, password } = useSelector(
    (state: RootState) => state.login,
  )

  const formSchema = Yup.object().shape({
    email: emailValidation,
    password: passwordValidation(false),
  })

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { isValid },
    reset,
    trigger,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(formSchema),
    defaultValues: { email, password } as FieldValues,
  })
  const watching = watch()

  const onSubmit = (): void => {
    const { email, password } = getValues()
    dispatch(setCredentials({ email: email, password: password }))
    dispatch(fetch2fa({ email, password }))
    reset()
  }

  const date = new Date()
  const year = date.getFullYear()
  const dispatch = useDispatch()
  const { navigate } = useNavigate()

  const [type, setType] = useState('')

  function closeModal(): void {
    dispatch(clearErrors())
  }

  useEffect(() => {
    if (!auth?.isLoading && auth?.token) {
      navigate(routes.auth.home, {
        replace: true,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.token, auth?.isLoading])

  useEffect(() => {
    setType(error ? error : null)
  }, [error, navigate, watch, watching])

  const handleCodeVerification = (code: number[]): void => {
    if (!code || !email) return

    dispatch(verify2fa({ email: email, challenge: code.join('') }))
  }

  const handleResend = (): void => {
    const { email, password } = getValues()
    dispatch(fetch2fa({ email, password }))
  }

  const handleCancel = (): void => {
    dispatch(clearSecondsForExpiration())
    dispatch(clearCredentials())
  }

  const loginText = 'login.login'
  const showInputCredentials = !email || !expiration?.seconds

  useEffect(() => {
    trackingService.trackLink(
      document.getElementById('reset-password-link'),
      '[Reset password] Clicked Reset Password Link',
    )
    trackingService.trackForm(
      document.getElementById('login-form') as HTMLFormElement,
      '[Login] Login Form Submitted',
    )
  }, [])

  return (
    <>
      <main className='login'>
        <Header simple />
        <div className='container login__wrapper'>
          {showInputCredentials ? (
            <div className='login__widget'>
              <div className='login__widget__header pa-3'>
                <span className='login__widget__header__title'>
                  {t(loginText)}
                </span>
              </div>
              <div className='login__widget__content'>
                <div className='login__widget__content__elements px-3 pt-5 pb-4'>
                  <LogoIcon fill='var(--logoIcon)' className='mb-4' />
                  <h2 className='login__widget__content__title mb-5'>
                    {t('login.welcome')}
                  </h2>
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    autoComplete='off'
                    id='login-form'
                  >
                    <div className='login__widget__content__input mb-3'>
                      <TextField
                        register={register}
                        style='paragraph1'
                        inputProps={{
                          label: t('login.email'),
                          required: true,
                          name: 'email',
                          value: getValues('email'),
                          onChange: async (e) => {
                            setValue('email', e.target.value)
                            await trigger('email')
                          },
                        }}
                      />
                    </div>
                    <div className='login__widget__content__input mb-3'>
                      <TextField
                        type='password'
                        register={register}
                        style='paragraph1'
                        inputProps={{
                          label: t('login.password'),
                          required: true,
                          value: getValues('password'),
                          name: 'password',
                          onChange: async (e) => {
                            setValue('password', e.target.value)
                            await trigger('password')
                          },
                        }}
                      />
                    </div>
                    <div className='mb-3'>
                      <Button
                        text={t(loginText)}
                        loading={isLoadingLogin}
                        disabled={!isValid || isLoadingLogin}
                        type='submit'
                        size='big'
                      />
                    </div>
                  </form>
                  <div className='login__widget__content__link'>
                    <Link to='/resetPassword' id='reset-password-link'>
                      {t('login.forgottenPassword')}
                    </Link>
                  </div>
                </div>
              </div>
              {/* <div className='login__widget__footer pa-3'>
                <span className='secondary--text mr-1'>
                  {t('login.dontHaveAccount?')}
                </span>
                <BasicLink
                  text={t('login.signUp')}
                  url='/signup'
                  type={'link'}
                />
              </div> */}
            </div>
          ) : (
            <VerificationWidget
              receiver={maskEmail(email)}
              title={t(loginText)}
              expiration={
                expiration as { seconds: number; petitionDate: number }
              }
              loading={isLoadingLogin}
              onCancel={handleCancel}
              onGoBack={handleCancel}
              onSubmit={handleCodeVerification}
              onResend={handleResend}
            />
          )}
          <Download />
          <div className='login__after_widget mt-5'>
            <span className='tertiary--text'>
              &copy;{year} {t('common.copyright')}
            </span>
          </div>
        </div>
      </main>
      {type && type === LoginError.InvalidCredentials && (
        <InvalidCredentials close={closeModal} />
      )}
      {type && type === LoginError.InvalidLoginOPT && (
        <InvalidOTPCode close={closeModal} onCase='login' />
      )}
    </>
  )
}
