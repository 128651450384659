import { createAsyncThunk } from '@reduxjs/toolkit'
import { getPortfolioValuationApi } from '@services'
import { PortfolioValuation, PortfolioValuationParam } from '@interfaces'

export const fetchProfileValuation = createAsyncThunk<
  PortfolioValuation,
  PortfolioValuationParam
>('fetchProfileValuation', async (data) => {
  return getPortfolioValuationApi(data)
})
