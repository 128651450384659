enum BackgroundColorsEnum {
  banner1 = 'banner1',
  banner2 = 'banner2',
  banner3 = 'banner3',
  banner4 = 'banner4',
  banner5 = 'banner5',
  banner6 = 'banner6',
  banner7 = 'banner7',
  banner8 = 'banner8',
  primaryBase = 'primaryBase',
  secondaryBase = 'secondaryBase',
  statusNegative = 'statusNegative',
  statusPositive = 'statusPositive',
  statusWarning = 'statusWarning',
  tertiaryBase = 'tertiaryBase',
  transparent = 'transparent',
}

type BackgroundColorsPreset = keyof typeof BackgroundColorsEnum

export interface UIBackgroundColorProps {
  bgColor?: BackgroundColorsPreset
}

export function getBgColorClass(bgColor: BackgroundColorsPreset): string {
  switch (bgColor) {
    case 'banner1':
      return 'bg-banner1'
    case 'banner2':
      return 'bg-banner2'
    case 'banner3':
      return 'bg-banner3'
    case 'banner4':
      return 'bg-banner4'
    case 'banner5':
      return 'bg-banner5'
    case 'banner6':
      return 'bg-banner6'
    case 'banner7':
      return 'bg-banner7'
    case 'banner8':
      return 'bg-banner8'
    case 'primaryBase':
      return 'bg-primary-base'
    case 'secondaryBase':
      return 'bg-secondary-base'
    case 'statusNegative':
      return 'bg-status-negative'
    case 'statusPositive':
      return 'bg-status-positive'
    case 'statusWarning':
      return 'bg-status-warning'
    case 'tertiaryBase':
      return 'bg-tertiary-base'
    case 'transparent':
      return 'bg-transparent'
    default:
      return ''
  }
}
