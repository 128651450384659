import './productCard.styles.scss'
import { ReactNode } from 'react'
import { ProductCardProps } from './productCard.props'
import {
  ETFsIllustration,
  SavingPlansIllustration,
  StocksIllustration,
} from '@assets/illustrations'
import { DiscoverTab } from '@interfaces'

export function ProductCard({
  id,
  title,
  width = 220,
  onClick,
}: ProductCardProps): JSX.Element {
  const getIllustrations = (
    assetTypeId: DiscoverTab,
  ): {
    illustration: ReactNode
  } => {
    switch (assetTypeId) {
      case DiscoverTab.blk:
        return {
          illustration: <ETFsIllustration props={{ width: width }} />,
        }
      case DiscoverTab.etfs:
        return {
          illustration: <SavingPlansIllustration props={{ width: width }} />,
        }
      default:
        return {
          illustration: <StocksIllustration props={{ width: width }} />,
        }
    }
  }

  const illustrations = getIllustrations(id)

  return (
    <div className={`productCard cursor-pointer`} onClick={onClick} aria-hidden>
      <div className='productCard__illustration'>
        {illustrations.illustration}
      </div>
      <div className={`productCard__text`}>
        <p className='body'>{title}</p>
      </div>
    </div>
  )
}
