import { RootState } from '@store'
import { useSelector } from 'react-redux'
import { useTranslation } from '@hooks'
import { Avatar, Divider, Spacer, TabBar } from '@components'
import { AssetIcon } from '@assets/icons'
import { useEffect, useState } from 'react'
import { CompanyOverviewTab } from '@interfaces/companyOverview'
import { useCompanyTipRanksList } from 'hooks/useCompanyTipRanksList'

export const CompanyOverview = ({
  activeTab,
  setActiveTab,
}: {
  activeTab: CompanyOverviewTab
  setActiveTab: (tab: CompanyOverviewTab) => void
}): JSX.Element => {
  const { t } = useTranslation()
  const [showTipRanksTabs, setShowTipRanksTabs] = useState<boolean>(false)
  const { companyStocks, companyPosition, companyInfo, tipRank } = useSelector(
    (state: RootState) => state,
  )

  const {
    newsFetched,
    analystRatingsFetched,
    bullBearsFetched,
    earningsFetched,
    forecastFetched,
  } = tipRank

  const handleTabPressed = (tabPressed: CompanyOverviewTab): void => {
    setActiveTab(tabPressed as CompanyOverviewTab)
  }
  const { tipsRankTabList } = useCompanyTipRanksList()

  useEffect(() => {
    if (
      newsFetched &&
      analystRatingsFetched &&
      bullBearsFetched &&
      earningsFetched &&
      forecastFetched &&
      tipsRankTabList.length > 1
    ) {
      setShowTipRanksTabs(true)
    } else {
      setShowTipRanksTabs(false)
    }
  }, [
    newsFetched,
    analystRatingsFetched,
    bullBearsFetched,
    earningsFetched,
    forecastFetched,
    tipsRankTabList,
  ])

  return (
    <div className='company-details__widget__graph__description w-100'>
      <div className='company-details__widget__graph__description__info'>
        <div className='company-details__widget__graph__description__info__general'>
          <Avatar
            image={companyStocks?.logoUrl}
            bordered={!companyStocks?.logoUrl}
            roundness='full'
            fallback={<AssetIcon size={22} />}
            size='huge'
          />
          <Spacer preset='smaller' />
          <div className='company-details__widget__graph__description__info__general__name d-flex d-flex-col'>
            <span className='company-details__widget__graph__description__info__important mr-2'>
              {companyStocks?.companyName}
            </span>
            <Spacer preset='tiny' />
            <p className='mr-2'>
              {companyStocks?.symbol}
              {' · '}
              {companyStocks?.type}
              {companyPosition?.ticker ? ` · ${companyPosition?.ticker}` : ''}
            </p>
          </div>
        </div>
        <div className='company-details__widget__graph__description__info__sector ml-3'>
          <span>{t('companyDetails.sector')}</span>
          <Spacer preset='tiny' />
          <p>{companyInfo?.data[2]?.elements[0]?.value}</p>
        </div>
      </div>
      {showTipRanksTabs && (
        <div>
          <TabBar
            extraMarginOnSides
            tabs={tipsRankTabList}
            activeTab={activeTab}
            setActiveTab={handleTabPressed}
          />
          <Divider />
        </div>
      )}
    </div>
  )
}
