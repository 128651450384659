import { useCallback, useMemo } from 'react'
import { MonthsMatrix } from './types'
import { isDayDisabled } from './isDayDisabled'
import { isNil } from 'lodash'

const thisDayFn = (
  selectedYear: number,
  selectedMonth: number,
  currentMonthCounter: number,
): string => {
  return !isNil(selectedYear) && !isNil(selectedMonth)
    ? new Date(selectedYear, selectedMonth, currentMonthCounter + 1, 0, 0, 0, 0)
        ?.toISOString()
        ?.substring(0, 10)
    : null
}

export const useBuildCalendarMatrix = ({
  selectedMonth,
  selectedDates = [],
  selectedYear,
  nDays,
  firstDay,
  allowSatAndSun,
  maxDate,
  minDate,
  getPreviousMonth,
}: {
  getPreviousMonth: () => number
  selectedMonth: number
  selectedYear: number
  selectedDates: string[]
  nDays: number[]
  firstDay: number
  minDate: string
  maxDate: string
  allowSatAndSun: boolean
}): MonthsMatrix => {
  const buildMatrixColumns = useCallback(
    (
      row: number,
      matrix: MonthsMatrix,
      currentMonthCounter: number,
      nextMonthCounter: number,
      prevMonthCounter: number,
      minDate: string,
      maxDate: string,
      allowSatAndSun: boolean,
    ) => {
      const maxDays = nDays[selectedMonth]

      for (let col = 0; col < 7; col++) {
        // All items are initialized as null
        matrix[row][col] = null

        // We fill previous month days
        if (row === 0 && col < firstDay) {
          matrix[row][col] = {
            key: `calendar-day_${row}_${col}`,
            day: prevMonthCounter++,
            disabled: true,
            selected: false,
          }
        }
        // We fill next month days
        if (row !== 0 && currentMonthCounter > maxDays) {
          matrix[row][col] = {
            key: `calendar-day_${row}_${col}`,
            day: nextMonthCounter++,
            disabled: true,
            selected: false,
          }
        }

        // First row, we fill only the data after first day
        // Rest of the rows are filled until we complete the month
        if (
          (row === 0 && col >= firstDay) ||
          (row > 0 && currentMonthCounter <= maxDays)
        ) {
          const disabledDate = isDayDisabled({
            allowSatAndSun,
            col,
            currentMonthCounter,
            maxDate,
            minDate,
            selectedMonth,
            selectedYear,
          })

          const thisDay = thisDayFn(
            selectedYear,
            selectedMonth,
            currentMonthCounter,
          )

          // Fill in rows only after the first day of the month
          matrix[row][col] = {
            key: `calendar-day_${row}_${col}`,
            day: currentMonthCounter++,
            disabled: disabledDate,
            selected:
              !disabledDate &&
              selectedDates?.some((i) => i?.substring(0, 10) === thisDay),
          }
        }
      }

      return {
        matrix,
        currentMonthCounter,
        nextMonthCounter,
        prevMonthCounter,
        allowSatAndSun,
        maxDate,
        minDate,
      }
    },
    [nDays, selectedMonth, firstDay, selectedYear, selectedDates],
  )

  return useMemo((): MonthsMatrix => {
    let matrix = [] as MonthsMatrix
    let currentMonthCounter = 1
    let nextMonthCounter = 1
    let prevMonthCounter = getPreviousMonth()

    for (let row = 0; row < 6; row++) {
      // Initializes a row
      matrix[row] = []
      const newRow = buildMatrixColumns(
        row,
        matrix,
        currentMonthCounter,
        nextMonthCounter,
        prevMonthCounter,
        minDate,
        maxDate,
        allowSatAndSun,
      )
      matrix = newRow.matrix
      currentMonthCounter = newRow.currentMonthCounter
      nextMonthCounter = newRow.nextMonthCounter
      prevMonthCounter = newRow.prevMonthCounter
    }

    return matrix
  }, [allowSatAndSun, buildMatrixColumns, getPreviousMonth, maxDate, minDate])
}
