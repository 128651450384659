import { useTranslation } from '@hooks'
import { OrderTypeEnum } from '@interfaces'
import { RootState } from '@store'
import { TabItem } from 'components/tabBar/tabBar.props'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

export const useOrderTypeTabs = ({
  onDisabledTabClick,
}: {
  onDisabledTabClick: (id: string) => void
}): TabItem[] => {
  const { t } = useTranslation()

  const { config, buySell, companyStocks } = useSelector(
    (state: RootState) => state,
  )

  return useMemo(() => {
    const tabs: TabItem[] = []

    if (buySell?.orderTypes?.includes(OrderTypeEnum.market)) {
      tabs.push({
        title: t('buySellFlow.limitOrders.market'),
        id: OrderTypeEnum.market,
        disabled: companyStocks?.marketStatus?.status === 'CLOSED',
        onDisabledTabClick: () => onDisabledTabClick(OrderTypeEnum.market),
      })
    }

    if (
      config?.features?.limitOrders &&
      buySell?.orderTypes?.includes(OrderTypeEnum.limit)
    ) {
      tabs.push({
        title: t('buySellFlow.limitOrders.limit'),
        id: OrderTypeEnum.limit,
      })
    }

    return tabs
  }, [
    buySell?.orderTypes,
    companyStocks?.marketStatus?.status,
    config?.features?.limitOrders,
    onDisabledTabClick,
    t,
  ])
}
