import {
  ArrowSortDefault,
  ArrowSortDown,
  ArrowSortUp,
  AssetIcon,
} from '@assets/icons'
import { BuySellEvents } from '@utils/eventTracker/eventKeys'
import { formatMoney } from '@utils/helpers'
import { Avatar, Button, CustomTable, Spacer, Text } from 'components'
import { StockPerformance } from 'features'
import { useBuyAndSellActions } from 'hooks/useBuyAndSellActions'
import { useNavigate } from 'hooks/useNavigation'
import { useTranslation } from 'hooks/useTranslation'
import { Portfolio, Stock } from 'interfaces'
import { trackingService } from 'services'
import { SortKey, tableHead } from './portfolioAssetsTable.constant'
import { useState } from 'react'

enum typeSortOrder {
  asc = 'asc',
  desc = 'desc',
}

export const PortfolioAssetsTable = ({
  portfolio,
}: {
  portfolio: Portfolio
}): JSX.Element => {
  const { t } = useTranslation()
  const { navigate } = useNavigate()
  const [sortKey, setSortKey] = useState<SortKey>(SortKey.name)
  const [sortOrder, setSortOrder] = useState<typeSortOrder>(typeSortOrder.asc)

  const handleSort = (key: SortKey): void => {
    if (sortKey === key) {
      setSortOrder(
        sortOrder === typeSortOrder.asc
          ? typeSortOrder.desc
          : typeSortOrder.asc,
      )
    } else {
      setSortKey(key)
      setSortOrder(typeSortOrder.asc)
    }
  }

  const { buy, sell, modals } = useBuyAndSellActions()

  const onBuyClick = (p: Stock): void => {
    trackingService.trackEvent({
      event: BuySellEvents.flowStarted,
      props: { origin, type: 'buy' },
    })
    buy({
      id: p?.id,
      origin,
      type: p?.type,
    })
  }

  const onSellClick = (p: Stock): void => {
    trackingService.trackEvent({
      event: BuySellEvents.flowStarted,
      props: { origin, type: 'sell' },
    })
    sell({
      id: p?.id,
      origin,
      type: p?.type,
    })
  }

  const sortValueChecker = (a, b): number => {
    if (a < b) return sortOrder === typeSortOrder.asc ? -1 : 1
    if (a > b) return sortOrder === typeSortOrder.asc ? 1 : -1
  }
  const sortData = [...portfolio.positions].sort((a, b) => {
    if (sortKey === SortKey.name) {
      return sortValueChecker(a['name'], b['name'])
    } else if (sortKey === SortKey.position) {
      return sortValueChecker(
        a['currentValue']['amount'],
        b['currentValue']['amount'],
      )
    } else if (sortKey === SortKey.totalPnl) {
      return sortValueChecker(
        a['totalEarnings']['amount'],
        b['totalEarnings']['amount'],
      )
    } else if (sortKey === SortKey.price) {
      return sortValueChecker(a['price']['amount'], b['price']['amount'])
    }
    return 0
  })

  return (
    <>
      <CustomTable
        preset='table1'
        loading={false}
        rows={sortData.map((p) => (
          <CustomTable.Row
            key={`portfolio-row-${p?.id}-${p?.ticker}`}
            onClick={() => {
              navigate(`/company/${p?.id}`)
            }}
          >
            <CustomTable.Cell flexDirection='row' cellMaxWidth='270px'>
              <Avatar
                image={p?.logoUrl}
                roundness='full'
                fallback={<AssetIcon size={22} />}
                size='large'
              />
              <Spacer preset='smaller' />
              <div>
                <Text preset='paragraph2' text={p?.name} />
                <Text preset='paragraph2' color='tertiary' text={p?.ticker} />
              </div>
            </CustomTable.Cell>
            <CustomTable.Cell>
              <Text
                preset='paragraph2'
                align='right'
                text={formatMoney(
                  p?.currentValue?.amount,
                  p?.currentValue?.currency,
                )}
              />
              <Text
                preset='paragraph2'
                align='right'
                text={`${p?.shares} ${t('common.shares')}`}
              />
            </CustomTable.Cell>
            <CustomTable.Cell>
              <div className='d-flex justify-end'>
                <StockPerformance
                  performanceAmount={p?.totalEarnings}
                  performancePercentage={p?.totalPerformance}
                  plusSymbol={true}
                />
              </div>
            </CustomTable.Cell>
            <CustomTable.Cell>
              {formatMoney(p?.price?.amount, p?.price?.currency)}
            </CustomTable.Cell>
            <CustomTable.Cell flexDirection='column' align='right'>
              <Button
                id='row-buy-button'
                text={t('common.buy')}
                loading={false}
                onClick={() => onBuyClick(p)}
                size='smaller'
                type='button'
                textPreset='subtitle2'
              />
              <Spacer preset='tiny' />
              <Button
                id='row-sell-button'
                text={t('common.sell')}
                loading={false}
                onClick={() => onSellClick(p)}
                size='smaller'
                type='button'
                buttonType='ghost'
                textPreset='subtitle2'
              />
            </CustomTable.Cell>
          </CustomTable.Row>
        ))}
        header={
          <CustomTable.Header>
            <>
              {tableHead.map((head, index) => (
                <CustomTable.HeaderCell
                  onClick={() => {
                    handleSort(head.key)
                  }}
                  key={index}
                  flexDirection='row'
                  items='center'
                >
                  <Text
                    preset='subtitle2'
                    text={t(head.label)}
                    color='tertiary'
                  />
                  {sortKey === head.key ? (
                    <>
                      {sortOrder === typeSortOrder.asc ? (
                        <ArrowSortUp size={16} />
                      ) : (
                        <ArrowSortDown size={16} />
                      )}
                    </>
                  ) : (
                    <ArrowSortDefault size={16} />
                  )}
                </CustomTable.HeaderCell>
              ))}
            </>
            <CustomTable.HeaderCell>
              <span className='d-none'>Buy/Sell</span>
            </CustomTable.HeaderCell>
          </CustomTable.Header>
        }
      />
      {modals}
    </>
  )
}
