import { useTranslation } from 'react-i18next'
import { CustomModal, GoBack, ModalLoader, Spacer, Text } from '@components'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { resetOrder } from '@store'
import { BankIcon, DateRangeIcon, NoFees, StocksUpIcon } from '@assets/icons'
import { UIIcon, UIIconColorProps } from 'components/utils'
import { MoneyOperationTypeEnum } from '@interfaces'

export interface PaymentMethodOption {
  onClick: () => void
  icon: React.ReactNode
  title: string
  description: string
  tags: Array<{
    icon: React.ReactNode
    label: string
  }>
}

export interface PaymentTypeSelectorProps {
  title: string
  prevStep: () => void
  onClose?: () => void
  onTransfer: () => void
  onCreditCard?: () => void
  onConnection: () => void
  type: MoneyOperationTypeEnum
}

export function PaymentTypeSelector({
  title,
  prevStep,
  onClose,
  onTransfer,
}: PaymentTypeSelectorProps): JSX.Element {
  const { t } = useTranslation()
  const [loading] = useState(true)
  const dispatch = useDispatch()

  const cancelActions = (): void => {
    dispatch(resetOrder())
    prevStep()
  }

  const iconProps: UIIcon & UIIconColorProps = {
    size: 26,
    color: 'secondary',
  }

  // temporarily comments //
  // const instantDepositLiteral = t('banksConnection.instantDeposit')
  const noFeesLiteral = t('fees.noFees')

  const options: Array<PaymentMethodOption> = [
    // {
    //   onClick: onConnection,
    //   icon: <BankIcon size={24} color='iconPrimaryInitial' />,
    //   title: t('addMoney.psdConnection'),
    //   description: t('addMoney.psdConnectionText'),
    //   tags: [
    //     {
    //       icon: <NoFees {...iconProps} />,
    //       label: noFeesLiteral,
    //     },
    //     {
    //       icon: <BoltIcon {...iconProps} />,
    //       label: instantDepositLiteral,
    //     },
    //   ],
    // },
    {
      onClick: onTransfer,
      icon: <StocksUpIcon size={24} color='iconPrimaryInitial' />,
      title: t('bankTransfer.cashout.manualTransfer'),
      description: t('bankTransfer.cashout.manualTransferDescription'),
      tags: [
        {
          icon: <NoFees {...iconProps} />,
          label: noFeesLiteral,
        },
        {
          icon: <DateRangeIcon {...iconProps} />,
          label: t('addMoney.shortBankTransfer'),
        },
      ],
    },
  ]

  // if (features?.creditCard && type === MoneyOperationTypeEnum.addMoney) {
  //   options?.splice(1, 0, {
  //     onClick: onCreditCard,
  //     icon: <PaymentsIcon size={24} color='iconPrimaryInitial' />,
  //     title: 'Credit/Debit card',
  //     description: 'Use your Visa or Master Card securely',
  //     tags: [
  //       {
  //         icon: <NoFees {...iconProps} />,
  //         label: t('fees.noFees'),
  //       },
  //       {
  //         icon: <BoltIcon {...iconProps} />,
  //         label: instantDepositLiteral,
  //       },
  //     ],
  //   })
  // }

  const renderOption = (
    option: PaymentMethodOption,
    idx: number,
  ): JSX.Element => {
    const lastElement = idx === options?.length - 1
    return (
      <div key={`${option?.title}-${idx}`}>
        <div
          className='d-flex align-center bg-secondary-base border-radius-default pa-2 cursor-pointer'
          onClick={option.onClick}
          aria-hidden
        >
          <div className='logo mr-2'>
            <BankIcon />
          </div>
          <div className='d-flex d-flex-col w-100 flex'>
            <Text preset='headline3' color='primary' text={option?.title} />
            <Text
              preset='paragraph2'
              color='secondary'
              text={option?.description}
            />
            <Spacer preset='smaller' />
            <div className='d-flex d-flex-row align-center justify-start w-100'>
              {option?.tags?.map((t, idx) => (
                <div
                  className='d-flex align-center w-40'
                  key={`tag-${option.title}-${idx}`}
                >
                  <span className='d-flex align-center justify-center mr-1'>
                    {t?.icon}
                  </span>
                  <Text preset='paragraph2' color='secondary' text={t?.label} />
                </div>
              ))}
            </div>
          </div>
        </div>
        {!lastElement ? <Spacer preset='smaller' /> : null}
      </div>
    )
  }

  return (
    <>
      {loading ? (
        <>
          <CustomModal.Header
            text={title}
            linkText={t('common.cancel')}
            className='subtitle2 dark--label-title'
            onClick={onClose}
          >
            <GoBack goBack={cancelActions} />
          </CustomModal.Header>
          <CustomModal.Content className='d-flex-col'>
            <Spacer preset='tiny' />
            <Text
              preset='headline1'
              color='primary'
              text={t('addMoney.selectPaymentMethod')}
            />
            <Spacer preset='small' />
            {options?.map((o, idx: number) => renderOption(o, idx))}
          </CustomModal.Content>
        </>
      ) : (
        <ModalLoader text={t('banksConnection.connectAccount')} />
      )}
    </>
  )
}
