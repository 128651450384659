import './summaryCard.styles.scss'
import { SummaryCardProps } from './summaryCard.props'

export function SummaryCard({
  id,
  className,
  overTitle,
  data,
}: SummaryCardProps): JSX.Element {
  const wrapperStyle = `summaryCard ${className as string}`

  return (
    <div className={`${wrapperStyle}`} key={`summaryCard-${id}`}>
      {overTitle ? (
        <p className='secondary--text paragraph2'>{overTitle}</p>
      ) : null}
      <div className='summaryCard__container'>
        {data?.map((row, idx) => {
          const withValue =
            row?.valueSubText ||
            row?.value ||
            row?.valueSubTextChildren ||
            row?.valueChildren

          return (
            <div className='summaryRow' key={`summaryCard-${id}-${idx}`}>
              <div
                className={`summaryRow__labelContainer ${
                  withValue ? '' : 'w-100'
                }`}
              >
                <div className='summaryRow__label d-flex align-center'>
                  <p className='paragraph2'>{row?.label}</p>
                  {row?.labelChildren}
                </div>

                {row.labelSubText ? (
                  <div className='summaryRow__labelSub d-flex align-center'>
                    <p className='paragraph3 tertiary--text'>
                      {row?.labelSubText}
                    </p>
                    {row?.labelSubTextChildren}
                  </div>
                ) : null}
              </div>
              {withValue ? (
                <div className='summaryRow__valueContainer text-right'>
                  <div className='summaryRow__value d-flex align-center'>
                    <p className='paragraph2 text-right'>{row?.value}</p>
                    {row?.valueChildren}
                  </div>
                  {row?.valueSubText ? (
                    <div className='summaryRow__valueSub d-flex align-center'>
                      <p className='paragraph3 text-right tertiary--text'>
                        {row?.valueSubText}
                      </p>
                      {row?.valueSubTextChildren}
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          )
        })}
      </div>
    </div>
  )
}
