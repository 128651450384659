import { BasicLink, Button, CustomModal, Spacer, Text } from '@components'
import { useTranslation } from '@hooks'

export const InactiveSessionModal = ({
  closeInactive,
  logOut,
  timerTo,
}: {
  closeInactive: () => void
  logOut: () => void
  timerTo: number
}): JSX.Element => {
  const { t } = useTranslation()

  return (
    <CustomModal show={true} theme='lighter' size='small'>
      <CustomModal.Header text={t('login.sessionExpired')} />
      <CustomModal.Content>
        <Spacer preset='tiny' />
        <Text
          preset='paragraph1'
          text={t('login.loggedOutIn', { seconds: timerTo })}
          align='center'
        />
        <Spacer preset='tiny' />
      </CustomModal.Content>
      <CustomModal.Footer>
        <div className='d-flex d-flex-col w-100'>
          <Button
            text={t('login.signedIn')}
            onClick={closeInactive}
            buttonType='primary'
          />
          <BasicLink
            text={t('login.signedOut')}
            type='action'
            className='w-100 mt-3'
            onClick={logOut}
          />
        </div>
      </CustomModal.Footer>
    </CustomModal>
  )
}
