import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import {
  BrokerTier,
  BrokerTierEnum,
  BrokerTierUserData,
  ReducerState,
} from '@interfaces'
import {
  fetchBrokerTier,
  fetchBrokerTierUserData,
  resetActiveLiteAndProInfo,
  setLiteAndProInfoInactive,
  setLiteAndProInfoNotInterested,
} from './thunk'

export type BrokerTierState = BrokerTier &
  BrokerTierUserData &
  ReducerState & {
    infoActive: boolean
    infoNotInterested: boolean
  }

const initialState: BrokerTierState = {
  tier: BrokerTierEnum.lite,
  totalPortfolio: null,
  completedOrdersThisMonth: null,
  loading: false,
  error: null,
  infoActive: true,
  infoNotInterested: false,
}

export const brokerTierReducer = createReducer(initialState, (builder) => {
  builder

    .addCase(
      fetchBrokerTier.fulfilled,
      (acc, { payload }: PayloadAction<BrokerTier>) => {
        acc.loading = false
        acc.error = null
        acc.tier = payload.tier || BrokerTierEnum.lite
      },
    )
    .addCase(
      fetchBrokerTierUserData.fulfilled,
      (acc, { payload }: PayloadAction<BrokerTierUserData>) => {
        acc.loading = false
        acc.error = null
        acc.totalPortfolio = payload.totalPortfolio
        acc.completedOrdersThisMonth = payload.completedOrdersThisMonth
      },
    )
    .addCase(setLiteAndProInfoInactive, (acc) => {
      acc.infoActive = false
    })
    .addCase(setLiteAndProInfoNotInterested, (acc) => {
      acc.infoNotInterested = true
    })
    .addCase(resetActiveLiteAndProInfo, (acc) => {
      if (!acc.infoNotInterested) {
        acc.infoActive = true
      }
    })
    .addMatcher(
      (action) =>
        action == fetchBrokerTierUserData.pending ||
        action == fetchBrokerTier.pending,
      (acc) => {
        acc.loading = true
        acc.error = null
        acc.tier = BrokerTierEnum.lite
        acc.completedOrdersThisMonth = null
        acc.totalPortfolio = null
      },
    )
    .addMatcher(
      (action) =>
        action.type == fetchBrokerTierUserData.rejected ||
        action.type == fetchBrokerTier.rejected,

      (acc, { error }) => {
        acc.loading = false
        acc.error = error.message
        acc.tier = BrokerTierEnum.lite
        acc.completedOrdersThisMonth = null
        acc.totalPortfolio = null
      },
    )
})
