import { createReducer } from '@reduxjs/toolkit'
import { PromotionsStatus } from '@interfaces'
import {
  fetchPromotions,
  setPromotionInactive,
  clearInactivePromotions,
} from './thunk'

const initialState: PromotionsStatus = {
  error: null,
  loading: false,
  promotions: [],
}

export const reducers = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchPromotions.pending, (acc) => {
      acc.loading = true
      acc.error = null
    })
    .addCase(fetchPromotions.fulfilled, (acc, { payload }) => {
      acc.loading = false
      acc.error = null
      acc.promotions = payload.reduce((prev, curr) => {
        const existingPromo = acc.promotions.find((p) => p.id === curr.id)
        if (existingPromo) {
          return [
            ...prev,
            {
              ...curr,
              notInterested: existingPromo.notInterested,
              active: existingPromo.active ? curr.active : existingPromo.active,
            },
          ]
        }
        return [...prev, curr]
      }, [])
    })
    .addCase(fetchPromotions.rejected, (acc, { error }) => {
      acc.loading = false
      acc.error = error.message
    })
    .addCase(setPromotionInactive, (acc, { payload }) => {
      acc.promotions = acc.promotions.map((p) => {
        if (p.id === payload) {
          return { ...p, active: false }
        }
        return p
      })
    })
    .addCase(clearInactivePromotions, (acc) => {
      acc.promotions = acc.promotions.filter((p) => p.notInterested)
    })
})

export default reducers
