import { Money } from './money'

export enum BrokerTierEnum {
  pro = 'TPR',
  lite = 'TBA',
}

export interface BrokerTier {
  tier: BrokerTierEnum
}

export interface BrokerTierUserData {
  totalPortfolio: Money
  completedOrdersThisMonth: number
}
