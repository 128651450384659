import './help.style.scss'
import { useTranslation, useNavigate } from '@hooks'
import { ListElement } from '@screens'
import { GoBack } from '@components'
import { supportEmail } from '@utils/externalUrls'
import { EmailIcon } from '@assets/icons'
import { UIIcon, UIIconColorProps } from '@components/utils'

export default function Help(): JSX.Element {
  const { t } = useTranslation()
  const { navigate } = useNavigate()

  const commonIconProps: UIIcon & UIIconColorProps = {
    size: 20,
    color: 'iconNeutralMedium',
  }

  return (
    <div className='profile__widget help__screen --fade-in-animation'>
      <div className='profile__widget__header'>
        <GoBack goBack={() => navigate(-1)} />
        <div className='title mt-3'>{t('profile.help')}</div>
      </div>
      <div className='profile__widget__content mt-5'>
        <div className='list__menu'>
          <ListElement
            title={t('help.send_message')}
            subtitle={`${t('help.send_message_to')} ${supportEmail}`}
            icon={<EmailIcon {...commonIconProps} />}
            to={`mailto:${supportEmail}`}
            target='_blank'
          />
        </div>
      </div>
    </div>
  )
}
