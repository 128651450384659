import './filtersOption.styles.scss'
import { Avatar, ExternalSvg, Text } from '@components'
import { useMemo } from 'react'
import { FilterOptionProps } from '@interfaces'
import { CheckInCircleIcon, ChevronRight } from '@assets/icons'

export const FilterOption = ({
  logo,
  name,
  checked,
  onClick,
  hasChildren,
  width,
  animateFadeIn,
}: FilterOptionProps): JSX.Element => {
  const logoIsString = logo && typeof logo === 'string'

  const logoElem = useMemo(() => {
    if (!logo) return null
    const logosIsSVG = logoIsString && logo?.includes('.svg')

    if (logoIsString) {
      return logosIsSVG ? (
        <ExternalSvg url={logo} />
      ) : (
        <Avatar image={logo} roundness='full' size='fluid' fallback={null} />
      )
    }

    return logo
  }, [logo, logoIsString])

  return (
    <div
      className={`filter-option cursor-pointer d-flex ${
        checked ? '--active' : ''
      } ${animateFadeIn ? '--fade-in-animation-fast' : ''} ${
        width === 'fluid' ? '--width-fluid' : '--width-fixed'
      }`}
      aria-hidden='true'
      onClick={onClick}
    >
      {logo && (
        <div className='filter-option__icon d-flex align-center justify-center'>
          {logoElem}
          {checked && (
            <div className='filter-option__icon-check'>
              <CheckInCircleIcon
                color='iconPrimaryInitial'
                size={17}
                secondaryColor='surfaceCanvasPrimaryInitial'
              />
            </div>
          )}
        </div>
      )}
      <div className='filter-option__title'>
        <Text
          preset='paragraph2'
          text={name}
          color={!checked ? 'secondary' : 'iconPrimaryInitial'}
        />
      </div>
      <div className='filter-option__chevron d-flex align-center ml-auto'>
        {hasChildren ? <ChevronRight size={24} /> : null}
      </div>
    </div>
  )
}
