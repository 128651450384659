import { createReducer } from '@reduxjs/toolkit'
import { updateUserSettings } from './thunk'

export interface UserSettingsState {
  hideAmounts: boolean
}

const initialState: UserSettingsState = {
  hideAmounts: false,
}

export const userSettingsReducer = createReducer(initialState, (builder) => {
  builder.addCase(updateUserSettings, (acc: UserSettingsState, { payload }) => {
    const data = payload as Partial<UserSettingsState>
    acc.hideAmounts = data?.hideAmounts
  })
})
