import './operationCardBase.styles.scss'
import { Avatar, Card, Divider, Spacer, Text } from '@components'
import { ReactNode } from 'react'

export const OperationCardBase = ({
  image,
  title,
  subtitle,
  form,
  right,
}: {
  image?: ReactNode
  form?: ReactNode
  title: string | ReactNode
  subtitle?: string | ReactNode
  right?: ReactNode
}): JSX.Element => {
  return (
    <Card bgColor='secondaryBase' padding='none' width='full'>
      <div className={`operation-summary-card ${form ? '--opened' : ''}`}>
        <div className='operation-summary-card__header --fade-in-animation-slow d-flex align-center'>
          {image && typeof image === 'string' ? (
            <Avatar
              roundness='full'
              size='mediumPlus'
              bgColor='primaryBase'
              image={image}
            />
          ) : (
            image
          )}
          {image && <Spacer preset='smaller' />}
          <div>
            {title && typeof title === 'string' ? (
              <Text text={title} preset='paragraph2' textStyle='capitalize' />
            ) : (
              title
            )}
            {subtitle && typeof subtitle === 'string' ? (
              <Text text={subtitle} preset='paragraph2' color='tertiary' />
            ) : (
              subtitle
            )}
          </div>
          {right && <div className='ml-auto d-flex align-center'>{right}</div>}
        </div>
        {form && <Divider />}
        <div className='operation-summary-card__content --fade-in-animation-slow'>
          {form}
        </div>
      </div>
    </Card>
  )
}
