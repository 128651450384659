import { UIIcon, UIIconColorProps, getIconColorSVGFill } from 'components/utils'

export const NoFees = ({
  color,
  size = 24,
}: UIIcon & UIIconColorProps): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 23 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <ellipse
        cx='10.125'
        cy='8.70834'
        rx='1.375'
        ry='1.375'
        fill={color ? getIconColorSVGFill(color) : 'currentColor'}
      />
      <rect
        x='19.3433'
        y='5.8797'
        width='1.83333'
        height='18.3333'
        transform='rotate(45 19.3433 5.8797)'
        fill={color ? getIconColorSVGFill(color) : 'currentColor'}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.15244 13.2686C4.71816 12.8343 4.54315 12.2315 4.64038 11.6676L5.68173 5.54163C5.75294 5.12272 6.0811 4.79457 6.5 4.72336L12.626 3.682C13.1899 3.58477 13.7927 3.75978 14.227 4.19406L16.3297 6.3006L15.0352 7.59505L12.9306 5.49043L8.08867 6.31157C7.66946 6.38267 7.34104 6.71108 7.26995 7.13029L6.4488 11.9722L8.55343 14.0769L7.25898 15.3713L5.15244 13.2686ZM8.55652 16.6665L12.2889 20.3921C13.0019 21.1051 14.1622 21.1116 14.8752 20.3986L21.357 13.9168C22.07 13.2038 22.0635 12.0435 21.3505 11.3305L17.6249 7.59815L16.3316 8.89142L20.0606 12.6204L13.5788 19.1022L9.84979 15.3732L8.55652 16.6665Z'
        fill={color ? getIconColorSVGFill(color) : 'currentColor'}
      />
    </svg>
  )
}
