import { SVGProps } from 'react'

export const StocksIllustration = ({
  props,
}: {
  props?: SVGProps<SVGSVGElement>
}): JSX.Element => {
  return (
    <svg
      width='282'
      viewBox='0 0 282 120'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <mask
        id='mask0_4147_22517'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='282'
        height='120'
      >
        <path
          d='M0 8C0 3.58172 3.58172 0 8 0H274C278.418 0 282 3.58172 282 8V120H0V8Z'
          fill='#2A3B4F'
        />
      </mask>
      <g mask='url(#mask0_4147_22517)'>
        <path
          d='M0 8C0 3.58172 3.58172 0 8 0H274C278.418 0 282 3.58172 282 8V120H0V8Z'
          fill='#2A3B4F'
        />
        <g opacity='0.89'>
          <path
            d='M2 4C2 4.55228 1.55228 5 1 5C0.447715 5 0 4.55228 0 4C0 3.44772 0.447715 3 1 3C1.55228 3 2 3.44772 2 4Z'
            fill='#174B92'
          />
          <path
            d='M8 4C8 4.55228 7.55228 5 7 5C6.44772 5 6 4.55228 6 4C6 3.44772 6.44772 3 7 3C7.55228 3 8 3.44772 8 4Z'
            fill='#174B92'
          />
          <circle cx='13' cy='4' r='1' fill='#174B92' />
          <circle cx='19' cy='4' r='1' fill='#174B92' />
          <circle cx='25' cy='4' r='1' fill='#174B92' />
          <circle cx='31' cy='4' r='1' fill='#174B92' />
          <circle cx='37' cy='4' r='1' fill='#174B92' />
          <circle cx='43' cy='4' r='1' fill='#174B92' />
          <circle cx='49' cy='4' r='1' fill='#174B92' />
          <circle cx='55' cy='4' r='1' fill='#174B92' />
          <circle cx='61' cy='4' r='1' fill='#174B92' />
          <circle cx='67' cy='4' r='1' fill='#174B92' />
          <circle cx='73' cy='4' r='1' fill='#174B92' />
          <circle cx='79' cy='4' r='1' fill='#174B92' />
          <circle cx='85' cy='4' r='1' fill='#174B92' />
          <circle cx='91' cy='4' r='1' fill='#174B92' />
          <circle cx='97' cy='4' r='1' fill='#174B92' />
          <circle cx='103' cy='4' r='1' fill='#174B92' />
          <circle cx='109' cy='4' r='1' fill='#174B92' />
          <path
            d='M2 30.335C2 30.8872 1.55228 31.335 1 31.335C0.447715 31.335 0 30.8872 0 30.335C0 29.7827 0.447715 29.335 1 29.335C1.55228 29.335 2 29.7827 2 30.335Z'
            fill='#174B92'
          />
          <path
            d='M8 30.335C8 30.8872 7.55228 31.335 7 31.335C6.44772 31.335 6 30.8872 6 30.335C6 29.7827 6.44772 29.335 7 29.335C7.55228 29.335 8 29.7827 8 30.335Z'
            fill='#174B92'
          />
          <circle cx='13' cy='30.335' r='1' fill='#174B92' />
          <circle cx='19' cy='30.335' r='1' fill='#174B92' />
          <circle cx='25' cy='30.335' r='1' fill='#174B92' />
          <circle cx='31' cy='30.335' r='1' fill='#174B92' />
          <circle cx='37' cy='30.335' r='1' fill='#174B92' />
          <circle cx='43' cy='30.335' r='1' fill='#174B92' />
          <circle cx='49' cy='30.335' r='1' fill='#174B92' />
          <circle cx='55' cy='30.335' r='1' fill='#174B92' />
          <circle cx='61' cy='30.335' r='1' fill='#174B92' />
          <circle cx='67' cy='30.335' r='1' fill='#174B92' />
          <circle cx='73' cy='30.335' r='1' fill='#174B92' />
          <circle cx='79' cy='30.335' r='1' fill='#174B92' />
          <circle cx='85' cy='30.335' r='1' fill='#174B92' />
          <circle cx='91' cy='30.335' r='1' fill='#174B92' />
          <circle cx='97' cy='30.335' r='1' fill='#174B92' />
          <circle cx='103' cy='30.335' r='1' fill='#174B92' />
          <circle cx='109' cy='30.335' r='1' fill='#174B92' />
          <path
            d='M2 56.6689C2 57.2212 1.55228 57.6689 1 57.6689C0.447715 57.6689 0 57.2212 0 56.6689C0 56.1167 0.447715 55.6689 1 55.6689C1.55228 55.6689 2 56.1167 2 56.6689Z'
            fill='#174B92'
          />
          <path
            d='M8 56.6689C8 57.2212 7.55228 57.6689 7 57.6689C6.44772 57.6689 6 57.2212 6 56.6689C6 56.1167 6.44772 55.6689 7 55.6689C7.55228 55.6689 8 56.1167 8 56.6689Z'
            fill='#174B92'
          />
          <circle cx='13' cy='56.6689' r='1' fill='#174B92' />
          <circle cx='19' cy='56.6689' r='1' fill='#174B92' />
          <circle cx='25' cy='56.6689' r='1' fill='#174B92' />
          <circle cx='31' cy='56.6689' r='1' fill='#174B92' />
          <circle cx='37' cy='56.6689' r='1' fill='#174B92' />
          <circle cx='43' cy='56.6689' r='1' fill='#174B92' />
          <circle cx='49' cy='56.6689' r='1' fill='#174B92' />
          <circle cx='55' cy='56.6689' r='1' fill='#174B92' />
          <circle cx='61' cy='56.6689' r='1' fill='#174B92' />
          <circle cx='67' cy='56.6689' r='1' fill='#174B92' />
          <circle cx='73' cy='56.6689' r='1' fill='#174B92' />
          <circle cx='79' cy='56.6689' r='1' fill='#174B92' />
          <circle cx='85' cy='56.6689' r='1' fill='#174B92' />
          <circle cx='91' cy='56.6689' r='1' fill='#174B92' />
          <circle cx='97' cy='56.6689' r='1' fill='#174B92' />
          <circle cx='103' cy='56.6689' r='1' fill='#174B92' />
          <circle cx='109' cy='56.6689' r='1' fill='#174B92' />
          <path
            d='M2 83.0039C2 83.5562 1.55228 84.0039 1 84.0039C0.447715 84.0039 0 83.5562 0 83.0039C0 82.4516 0.447715 82.0039 1 82.0039C1.55228 82.0039 2 82.4516 2 83.0039Z'
            fill='#174B92'
          />
          <path
            d='M8 83.0039C8 83.5562 7.55228 84.0039 7 84.0039C6.44772 84.0039 6 83.5562 6 83.0039C6 82.4516 6.44772 82.0039 7 82.0039C7.55228 82.0039 8 82.4516 8 83.0039Z'
            fill='#174B92'
          />
          <circle cx='13' cy='83.0039' r='1' fill='#174B92' />
          <circle cx='19' cy='83.0039' r='1' fill='#174B92' />
          <circle cx='25' cy='83.0039' r='1' fill='#174B92' />
          <circle cx='31' cy='83.0039' r='1' fill='#174B92' />
          <circle cx='37' cy='83.0039' r='1' fill='#174B92' />
          <circle cx='43' cy='83.0039' r='1' fill='#174B92' />
          <circle cx='49' cy='83.0039' r='1' fill='#174B92' />
          <circle cx='55' cy='83.0039' r='1' fill='#174B92' />
          <circle cx='61' cy='83.0039' r='1' fill='#174B92' />
          <circle cx='67' cy='83.0039' r='1' fill='#174B92' />
          <circle cx='73' cy='83.0039' r='1' fill='#174B92' />
          <circle cx='79' cy='83.0039' r='1' fill='#174B92' />
          <circle cx='85' cy='83.0039' r='1' fill='#174B92' />
          <circle cx='91' cy='83.0039' r='1' fill='#174B92' />
          <circle cx='97' cy='83.0039' r='1' fill='#174B92' />
          <circle cx='103' cy='83.0039' r='1' fill='#174B92' />
          <circle cx='109' cy='83.0039' r='1' fill='#174B92' />
          <path
            d='M2 109.339C2 109.891 1.55228 110.339 1 110.339C0.447715 110.339 0 109.891 0 109.339C0 108.787 0.447715 108.339 1 108.339C1.55228 108.339 2 108.787 2 109.339Z'
            fill='#174B92'
          />
          <path
            d='M8 109.339C8 109.891 7.55228 110.339 7 110.339C6.44772 110.339 6 109.891 6 109.339C6 108.787 6.44772 108.339 7 108.339C7.55228 108.339 8 108.787 8 109.339Z'
            fill='#174B92'
          />
          <circle cx='13' cy='109.339' r='1' fill='#174B92' />
          <circle cx='19' cy='109.339' r='1' fill='#174B92' />
          <circle cx='25' cy='109.339' r='1' fill='#174B92' />
          <circle cx='31' cy='109.339' r='1' fill='#174B92' />
          <circle cx='37' cy='109.339' r='1' fill='#174B92' />
          <circle cx='43' cy='109.339' r='1' fill='#174B92' />
          <circle cx='49' cy='109.339' r='1' fill='#174B92' />
          <circle cx='55' cy='109.339' r='1' fill='#174B92' />
          <circle cx='61' cy='109.339' r='1' fill='#174B92' />
          <circle cx='67' cy='109.339' r='1' fill='#174B92' />
          <circle cx='73' cy='109.339' r='1' fill='#174B92' />
          <circle cx='79' cy='109.339' r='1' fill='#174B92' />
          <circle cx='85' cy='109.339' r='1' fill='#174B92' />
          <circle cx='91' cy='109.339' r='1' fill='#174B92' />
          <circle cx='97' cy='109.339' r='1' fill='#174B92' />
          <circle cx='103' cy='109.339' r='1' fill='#174B92' />
          <circle cx='109' cy='109.339' r='1' fill='#174B92' />
          <path
            d='M2 17.167C2 17.7193 1.55228 18.167 1 18.167C0.447715 18.167 0 17.7193 0 17.167C0 16.6147 0.447715 16.167 1 16.167C1.55228 16.167 2 16.6147 2 17.167Z'
            fill='#174B92'
          />
          <path
            d='M8 17.167C8 17.7193 7.55228 18.167 7 18.167C6.44772 18.167 6 17.7193 6 17.167C6 16.6147 6.44772 16.167 7 16.167C7.55228 16.167 8 16.6147 8 17.167Z'
            fill='#174B92'
          />
          <circle cx='13' cy='17.167' r='1' fill='#174B92' />
          <circle cx='19' cy='17.167' r='1' fill='#174B92' />
          <circle cx='25' cy='17.167' r='1' fill='#174B92' />
          <circle cx='31' cy='17.167' r='1' fill='#174B92' />
          <circle cx='37' cy='17.167' r='1' fill='#174B92' />
          <circle cx='43' cy='17.167' r='1' fill='#174B92' />
          <circle cx='49' cy='17.167' r='1' fill='#174B92' />
          <circle cx='55' cy='17.167' r='1' fill='#174B92' />
          <circle cx='61' cy='17.167' r='1' fill='#174B92' />
          <circle cx='67' cy='17.167' r='1' fill='#174B92' />
          <circle cx='73' cy='17.167' r='1' fill='#174B92' />
          <circle cx='79' cy='17.167' r='1' fill='#174B92' />
          <circle cx='85' cy='17.167' r='1' fill='#174B92' />
          <circle cx='91' cy='17.167' r='1' fill='#174B92' />
          <circle cx='97' cy='17.167' r='1' fill='#174B92' />
          <circle cx='103' cy='17.167' r='1' fill='#174B92' />
          <circle cx='109' cy='17.167' r='1' fill='#174B92' />
          <path
            d='M2 43.502C2 44.0542 1.55228 44.502 1 44.502C0.447715 44.502 0 44.0542 0 43.502C0 42.9497 0.447715 42.502 1 42.502C1.55228 42.502 2 42.9497 2 43.502Z'
            fill='#174B92'
          />
          <path
            d='M8 43.502C8 44.0542 7.55228 44.502 7 44.502C6.44772 44.502 6 44.0542 6 43.502C6 42.9497 6.44772 42.502 7 42.502C7.55228 42.502 8 42.9497 8 43.502Z'
            fill='#174B92'
          />
          <circle cx='13' cy='43.502' r='1' fill='#174B92' />
          <circle cx='19' cy='43.502' r='1' fill='#174B92' />
          <circle cx='25' cy='43.502' r='1' fill='#174B92' />
          <circle cx='31' cy='43.502' r='1' fill='#174B92' />
          <circle cx='37' cy='43.502' r='1' fill='#174B92' />
          <circle cx='43' cy='43.502' r='1' fill='#174B92' />
          <circle cx='49' cy='43.502' r='1' fill='#174B92' />
          <circle cx='55' cy='43.502' r='1' fill='#174B92' />
          <circle cx='61' cy='43.502' r='1' fill='#174B92' />
          <circle cx='67' cy='43.502' r='1' fill='#174B92' />
          <circle cx='73' cy='43.502' r='1' fill='#174B92' />
          <circle cx='79' cy='43.502' r='1' fill='#174B92' />
          <circle cx='85' cy='43.502' r='1' fill='#174B92' />
          <circle cx='91' cy='43.502' r='1' fill='#174B92' />
          <circle cx='97' cy='43.502' r='1' fill='#174B92' />
          <circle cx='103' cy='43.502' r='1' fill='#174B92' />
          <circle cx='109' cy='43.502' r='1' fill='#174B92' />
          <path
            d='M2 69.8369C2 70.3892 1.55228 70.8369 1 70.8369C0.447715 70.8369 0 70.3892 0 69.8369C0 69.2846 0.447715 68.8369 1 68.8369C1.55228 68.8369 2 69.2846 2 69.8369Z'
            fill='#174B92'
          />
          <path
            d='M8 69.8369C8 70.3892 7.55228 70.8369 7 70.8369C6.44772 70.8369 6 70.3892 6 69.8369C6 69.2846 6.44772 68.8369 7 68.8369C7.55228 68.8369 8 69.2846 8 69.8369Z'
            fill='#174B92'
          />
          <circle cx='13' cy='69.8369' r='1' fill='#174B92' />
          <circle cx='19' cy='69.8369' r='1' fill='#174B92' />
          <circle cx='25' cy='69.8369' r='1' fill='#174B92' />
          <circle cx='31' cy='69.8369' r='1' fill='#174B92' />
          <circle cx='37' cy='69.8369' r='1' fill='#174B92' />
          <circle cx='43' cy='69.8369' r='1' fill='#174B92' />
          <circle cx='49' cy='69.8369' r='1' fill='#174B92' />
          <circle cx='55' cy='69.8369' r='1' fill='#174B92' />
          <circle cx='61' cy='69.8369' r='1' fill='#174B92' />
          <circle cx='67' cy='69.8369' r='1' fill='#174B92' />
          <circle cx='73' cy='69.8369' r='1' fill='#174B92' />
          <circle cx='79' cy='69.8369' r='1' fill='#174B92' />
          <circle cx='85' cy='69.8369' r='1' fill='#174B92' />
          <circle cx='91' cy='69.8369' r='1' fill='#174B92' />
          <circle cx='97' cy='69.8369' r='1' fill='#174B92' />
          <circle cx='103' cy='69.8369' r='1' fill='#174B92' />
          <circle cx='109' cy='69.8369' r='1' fill='#174B92' />
          <path
            d='M2 96.1709C2 96.7232 1.55228 97.1709 1 97.1709C0.447715 97.1709 0 96.7232 0 96.1709C0 95.6186 0.447715 95.1709 1 95.1709C1.55228 95.1709 2 95.6186 2 96.1709Z'
            fill='#174B92'
          />
          <path
            d='M8 96.1709C8 96.7232 7.55228 97.1709 7 97.1709C6.44772 97.1709 6 96.7232 6 96.1709C6 95.6186 6.44772 95.1709 7 95.1709C7.55228 95.1709 8 95.6186 8 96.1709Z'
            fill='#174B92'
          />
          <circle cx='13' cy='96.1709' r='1' fill='#174B92' />
          <circle cx='19' cy='96.1709' r='1' fill='#174B92' />
          <circle cx='25' cy='96.1709' r='1' fill='#174B92' />
          <circle cx='31' cy='96.1709' r='1' fill='#174B92' />
          <circle cx='37' cy='96.1709' r='1' fill='#174B92' />
          <circle cx='43' cy='96.1709' r='1' fill='#174B92' />
          <circle cx='49' cy='96.1709' r='1' fill='#174B92' />
          <circle cx='55' cy='96.1709' r='1' fill='#174B92' />
          <circle cx='61' cy='96.1709' r='1' fill='#174B92' />
          <circle cx='67' cy='96.1709' r='1' fill='#174B92' />
          <circle cx='73' cy='96.1709' r='1' fill='#174B92' />
          <circle cx='79' cy='96.1709' r='1' fill='#174B92' />
          <circle cx='85' cy='96.1709' r='1' fill='#174B92' />
          <circle cx='91' cy='96.1709' r='1' fill='#174B92' />
          <circle cx='97' cy='96.1709' r='1' fill='#174B92' />
          <circle cx='103' cy='96.1709' r='1' fill='#174B92' />
          <circle cx='109' cy='96.1709' r='1' fill='#174B92' />
          <path
            d='M5.29199 10.584C5.29199 11.1363 4.84428 11.584 4.29199 11.584C3.73971 11.584 3.29199 11.1363 3.29199 10.584C3.29199 10.0317 3.73971 9.58398 4.29199 9.58398C4.84428 9.58398 5.29199 10.0317 5.29199 10.584Z'
            fill='#174B92'
          />
          <path
            d='M11.292 10.584C11.292 11.1363 10.8443 11.584 10.292 11.584C9.73971 11.584 9.29199 11.1363 9.29199 10.584C9.29199 10.0317 9.73971 9.58398 10.292 9.58398C10.8443 9.58398 11.292 10.0317 11.292 10.584Z'
            fill='#174B92'
          />
          <circle cx='16.292' cy='10.584' r='1' fill='#174B92' />
          <circle cx='22.292' cy='10.584' r='1' fill='#174B92' />
          <circle cx='28.292' cy='10.584' r='1' fill='#174B92' />
          <circle cx='34.292' cy='10.584' r='1' fill='#174B92' />
          <circle cx='40.292' cy='10.584' r='1' fill='#174B92' />
          <circle cx='46.292' cy='10.584' r='1' fill='#174B92' />
          <circle cx='52.292' cy='10.584' r='1' fill='#174B92' />
          <circle cx='58.292' cy='10.584' r='1' fill='#174B92' />
          <circle cx='64.292' cy='10.584' r='1' fill='#174B92' />
          <circle cx='70.292' cy='10.584' r='1' fill='#174B92' />
          <circle cx='76.292' cy='10.584' r='1' fill='#174B92' />
          <circle cx='82.292' cy='10.584' r='1' fill='#174B92' />
          <circle cx='88.292' cy='10.584' r='1' fill='#174B92' />
          <circle cx='94.292' cy='10.584' r='1' fill='#174B92' />
          <circle cx='100.292' cy='10.584' r='1' fill='#174B92' />
          <circle cx='106.292' cy='10.584' r='1' fill='#174B92' />
          <circle cx='112.292' cy='10.584' r='1' fill='#174B92' />
          <path
            d='M5.29199 36.918C5.29199 37.4703 4.84428 37.918 4.29199 37.918C3.73971 37.918 3.29199 37.4703 3.29199 36.918C3.29199 36.3657 3.73971 35.918 4.29199 35.918C4.84428 35.918 5.29199 36.3657 5.29199 36.918Z'
            fill='#174B92'
          />
          <path
            d='M11.292 36.918C11.292 37.4703 10.8443 37.918 10.292 37.918C9.73971 37.918 9.29199 37.4703 9.29199 36.918C9.29199 36.3657 9.73971 35.918 10.292 35.918C10.8443 35.918 11.292 36.3657 11.292 36.918Z'
            fill='#174B92'
          />
          <circle cx='16.292' cy='36.918' r='1' fill='#174B92' />
          <circle cx='22.292' cy='36.918' r='1' fill='#174B92' />
          <circle cx='28.292' cy='36.918' r='1' fill='#174B92' />
          <circle cx='34.292' cy='36.918' r='1' fill='#174B92' />
          <circle cx='40.292' cy='36.918' r='1' fill='#174B92' />
          <circle cx='46.292' cy='36.918' r='1' fill='#174B92' />
          <circle cx='52.292' cy='36.918' r='1' fill='#174B92' />
          <circle cx='58.292' cy='36.918' r='1' fill='#174B92' />
          <circle cx='64.292' cy='36.918' r='1' fill='#174B92' />
          <circle cx='70.292' cy='36.918' r='1' fill='#174B92' />
          <circle cx='76.292' cy='36.918' r='1' fill='#174B92' />
          <circle cx='82.292' cy='36.918' r='1' fill='#174B92' />
          <circle cx='88.292' cy='36.918' r='1' fill='#174B92' />
          <circle cx='94.292' cy='36.918' r='1' fill='#174B92' />
          <circle cx='100.292' cy='36.918' r='1' fill='#174B92' />
          <circle cx='106.292' cy='36.918' r='1' fill='#174B92' />
          <circle cx='112.292' cy='36.918' r='1' fill='#174B92' />
          <path
            d='M5.29199 63.2529C5.29199 63.8052 4.84428 64.2529 4.29199 64.2529C3.73971 64.2529 3.29199 63.8052 3.29199 63.2529C3.29199 62.7006 3.73971 62.2529 4.29199 62.2529C4.84428 62.2529 5.29199 62.7006 5.29199 63.2529Z'
            fill='#174B92'
          />
          <path
            d='M11.292 63.2529C11.292 63.8052 10.8443 64.2529 10.292 64.2529C9.73971 64.2529 9.29199 63.8052 9.29199 63.2529C9.29199 62.7006 9.73971 62.2529 10.292 62.2529C10.8443 62.2529 11.292 62.7006 11.292 63.2529Z'
            fill='#174B92'
          />
          <circle cx='16.292' cy='63.2529' r='1' fill='#174B92' />
          <circle cx='22.292' cy='63.2529' r='1' fill='#174B92' />
          <circle cx='28.292' cy='63.2529' r='1' fill='#174B92' />
          <circle cx='34.292' cy='63.2529' r='1' fill='#174B92' />
          <circle cx='40.292' cy='63.2529' r='1' fill='#174B92' />
          <circle cx='46.292' cy='63.2529' r='1' fill='#174B92' />
          <circle cx='52.292' cy='63.2529' r='1' fill='#174B92' />
          <circle cx='58.292' cy='63.2529' r='1' fill='#174B92' />
          <circle cx='64.292' cy='63.2529' r='1' fill='#174B92' />
          <circle cx='70.292' cy='63.2529' r='1' fill='#174B92' />
          <circle cx='76.292' cy='63.2529' r='1' fill='#174B92' />
          <circle cx='82.292' cy='63.2529' r='1' fill='#174B92' />
          <circle cx='88.292' cy='63.2529' r='1' fill='#174B92' />
          <circle cx='94.292' cy='63.2529' r='1' fill='#174B92' />
          <circle cx='100.292' cy='63.2529' r='1' fill='#174B92' />
          <circle cx='106.292' cy='63.2529' r='1' fill='#174B92' />
          <circle cx='112.292' cy='63.2529' r='1' fill='#174B92' />
          <path
            d='M5.29199 89.5879C5.29199 90.1402 4.84428 90.5879 4.29199 90.5879C3.73971 90.5879 3.29199 90.1402 3.29199 89.5879C3.29199 89.0356 3.73971 88.5879 4.29199 88.5879C4.84428 88.5879 5.29199 89.0356 5.29199 89.5879Z'
            fill='#174B92'
          />
          <path
            d='M11.292 89.5879C11.292 90.1402 10.8443 90.5879 10.292 90.5879C9.73971 90.5879 9.29199 90.1402 9.29199 89.5879C9.29199 89.0356 9.73971 88.5879 10.292 88.5879C10.8443 88.5879 11.292 89.0356 11.292 89.5879Z'
            fill='#174B92'
          />
          <circle cx='16.292' cy='89.5879' r='1' fill='#174B92' />
          <circle cx='22.292' cy='89.5879' r='1' fill='#174B92' />
          <circle cx='28.292' cy='89.5879' r='1' fill='#174B92' />
          <circle cx='34.292' cy='89.5879' r='1' fill='#174B92' />
          <circle cx='40.292' cy='89.5879' r='1' fill='#174B92' />
          <circle cx='46.292' cy='89.5879' r='1' fill='#174B92' />
          <circle cx='52.292' cy='89.5879' r='1' fill='#174B92' />
          <circle cx='58.292' cy='89.5879' r='1' fill='#174B92' />
          <circle cx='64.292' cy='89.5879' r='1' fill='#174B92' />
          <circle cx='70.292' cy='89.5879' r='1' fill='#174B92' />
          <circle cx='76.292' cy='89.5879' r='1' fill='#174B92' />
          <circle cx='82.292' cy='89.5879' r='1' fill='#174B92' />
          <circle cx='88.292' cy='89.5879' r='1' fill='#174B92' />
          <circle cx='94.292' cy='89.5879' r='1' fill='#174B92' />
          <circle cx='100.292' cy='89.5879' r='1' fill='#174B92' />
          <circle cx='106.292' cy='89.5879' r='1' fill='#174B92' />
          <circle cx='112.292' cy='89.5879' r='1' fill='#174B92' />
          <path
            d='M5.29199 115.922C5.29199 116.474 4.84428 116.922 4.29199 116.922C3.73971 116.922 3.29199 116.474 3.29199 115.922C3.29199 115.37 3.73971 114.922 4.29199 114.922C4.84428 114.922 5.29199 115.37 5.29199 115.922Z'
            fill='#174B92'
          />
          <path
            d='M11.292 115.922C11.292 116.474 10.8443 116.922 10.292 116.922C9.73971 116.922 9.29199 116.474 9.29199 115.922C9.29199 115.37 9.73971 114.922 10.292 114.922C10.8443 114.922 11.292 115.37 11.292 115.922Z'
            fill='#174B92'
          />
          <circle cx='16.292' cy='115.922' r='1' fill='#174B92' />
          <circle cx='22.292' cy='115.922' r='1' fill='#174B92' />
          <circle cx='28.292' cy='115.922' r='1' fill='#174B92' />
          <circle cx='34.292' cy='115.922' r='1' fill='#174B92' />
          <circle cx='40.292' cy='115.922' r='1' fill='#174B92' />
          <circle cx='46.292' cy='115.922' r='1' fill='#174B92' />
          <circle cx='52.292' cy='115.922' r='1' fill='#174B92' />
          <circle cx='58.292' cy='115.922' r='1' fill='#174B92' />
          <circle cx='64.292' cy='115.922' r='1' fill='#174B92' />
          <circle cx='70.292' cy='115.922' r='1' fill='#174B92' />
          <circle cx='76.292' cy='115.922' r='1' fill='#174B92' />
          <circle cx='82.292' cy='115.922' r='1' fill='#174B92' />
          <circle cx='88.292' cy='115.922' r='1' fill='#174B92' />
          <circle cx='94.292' cy='115.922' r='1' fill='#174B92' />
          <circle cx='100.292' cy='115.922' r='1' fill='#174B92' />
          <circle cx='106.292' cy='115.922' r='1' fill='#174B92' />
          <circle cx='112.292' cy='115.922' r='1' fill='#174B92' />
          <path
            d='M5.29199 23.751C5.29199 24.3033 4.84428 24.751 4.29199 24.751C3.73971 24.751 3.29199 24.3033 3.29199 23.751C3.29199 23.1987 3.73971 22.751 4.29199 22.751C4.84428 22.751 5.29199 23.1987 5.29199 23.751Z'
            fill='#174B92'
          />
          <path
            d='M11.292 23.751C11.292 24.3033 10.8443 24.751 10.292 24.751C9.73971 24.751 9.29199 24.3033 9.29199 23.751C9.29199 23.1987 9.73971 22.751 10.292 22.751C10.8443 22.751 11.292 23.1987 11.292 23.751Z'
            fill='#174B92'
          />
          <circle cx='16.292' cy='23.751' r='1' fill='#174B92' />
          <circle cx='22.292' cy='23.751' r='1' fill='#174B92' />
          <circle cx='28.292' cy='23.751' r='1' fill='#174B92' />
          <circle cx='34.292' cy='23.751' r='1' fill='#174B92' />
          <circle cx='40.292' cy='23.751' r='1' fill='#174B92' />
          <circle cx='46.292' cy='23.751' r='1' fill='#174B92' />
          <circle cx='52.292' cy='23.751' r='1' fill='#174B92' />
          <circle cx='58.292' cy='23.751' r='1' fill='#174B92' />
          <circle cx='64.292' cy='23.751' r='1' fill='#174B92' />
          <circle cx='70.292' cy='23.751' r='1' fill='#174B92' />
          <circle cx='76.292' cy='23.751' r='1' fill='#174B92' />
          <circle cx='82.292' cy='23.751' r='1' fill='#174B92' />
          <circle cx='88.292' cy='23.751' r='1' fill='#174B92' />
          <circle cx='94.292' cy='23.751' r='1' fill='#174B92' />
          <circle cx='100.292' cy='23.751' r='1' fill='#174B92' />
          <circle cx='106.292' cy='23.751' r='1' fill='#174B92' />
          <circle cx='112.292' cy='23.751' r='1' fill='#174B92' />
          <path
            d='M5.29199 50.0859C5.29199 50.6382 4.84428 51.0859 4.29199 51.0859C3.73971 51.0859 3.29199 50.6382 3.29199 50.0859C3.29199 49.5337 3.73971 49.0859 4.29199 49.0859C4.84428 49.0859 5.29199 49.5337 5.29199 50.0859Z'
            fill='#174B92'
          />
          <path
            d='M11.292 50.0859C11.292 50.6382 10.8443 51.0859 10.292 51.0859C9.73971 51.0859 9.29199 50.6382 9.29199 50.0859C9.29199 49.5337 9.73971 49.0859 10.292 49.0859C10.8443 49.0859 11.292 49.5337 11.292 50.0859Z'
            fill='#174B92'
          />
          <circle cx='16.292' cy='50.0859' r='1' fill='#174B92' />
          <circle cx='22.292' cy='50.0859' r='1' fill='#174B92' />
          <circle cx='28.292' cy='50.0859' r='1' fill='#174B92' />
          <circle cx='34.292' cy='50.0859' r='1' fill='#174B92' />
          <circle cx='40.292' cy='50.0859' r='1' fill='#174B92' />
          <circle cx='46.292' cy='50.0859' r='1' fill='#174B92' />
          <circle cx='52.292' cy='50.0859' r='1' fill='#174B92' />
          <circle cx='58.292' cy='50.0859' r='1' fill='#174B92' />
          <circle cx='64.292' cy='50.0859' r='1' fill='#174B92' />
          <circle cx='70.292' cy='50.0859' r='1' fill='#174B92' />
          <circle cx='76.292' cy='50.0859' r='1' fill='#174B92' />
          <circle cx='82.292' cy='50.0859' r='1' fill='#174B92' />
          <circle cx='88.292' cy='50.0859' r='1' fill='#174B92' />
          <circle cx='94.292' cy='50.0859' r='1' fill='#174B92' />
          <circle cx='100.292' cy='50.0859' r='1' fill='#174B92' />
          <circle cx='106.292' cy='50.0859' r='1' fill='#174B92' />
          <circle cx='112.292' cy='50.0859' r='1' fill='#174B92' />
          <path
            d='M5.29199 76.4199C5.29199 76.9722 4.84428 77.4199 4.29199 77.4199C3.73971 77.4199 3.29199 76.9722 3.29199 76.4199C3.29199 75.8676 3.73971 75.4199 4.29199 75.4199C4.84428 75.4199 5.29199 75.8676 5.29199 76.4199Z'
            fill='#174B92'
          />
          <path
            d='M11.292 76.4199C11.292 76.9722 10.8443 77.4199 10.292 77.4199C9.73971 77.4199 9.29199 76.9722 9.29199 76.4199C9.29199 75.8676 9.73971 75.4199 10.292 75.4199C10.8443 75.4199 11.292 75.8676 11.292 76.4199Z'
            fill='#174B92'
          />
          <circle cx='16.292' cy='76.4199' r='1' fill='#174B92' />
          <circle cx='22.292' cy='76.4199' r='1' fill='#174B92' />
          <circle cx='28.292' cy='76.4199' r='1' fill='#174B92' />
          <circle cx='34.292' cy='76.4199' r='1' fill='#174B92' />
          <circle cx='40.292' cy='76.4199' r='1' fill='#174B92' />
          <circle cx='46.292' cy='76.4199' r='1' fill='#174B92' />
          <circle cx='52.292' cy='76.4199' r='1' fill='#174B92' />
          <circle cx='58.292' cy='76.4199' r='1' fill='#174B92' />
          <circle cx='64.292' cy='76.4199' r='1' fill='#174B92' />
          <circle cx='70.292' cy='76.4199' r='1' fill='#174B92' />
          <circle cx='76.292' cy='76.4199' r='1' fill='#174B92' />
          <circle cx='82.292' cy='76.4199' r='1' fill='#174B92' />
          <circle cx='88.292' cy='76.4199' r='1' fill='#174B92' />
          <circle cx='94.292' cy='76.4199' r='1' fill='#174B92' />
          <circle cx='100.292' cy='76.4199' r='1' fill='#174B92' />
          <circle cx='106.292' cy='76.4199' r='1' fill='#174B92' />
          <circle cx='112.292' cy='76.4199' r='1' fill='#174B92' />
          <path
            d='M5.29199 102.755C5.29199 103.307 4.84428 103.755 4.29199 103.755C3.73971 103.755 3.29199 103.307 3.29199 102.755C3.29199 102.203 3.73971 101.755 4.29199 101.755C4.84428 101.755 5.29199 102.203 5.29199 102.755Z'
            fill='#174B92'
          />
          <path
            d='M11.292 102.755C11.292 103.307 10.8443 103.755 10.292 103.755C9.73971 103.755 9.29199 103.307 9.29199 102.755C9.29199 102.203 9.73971 101.755 10.292 101.755C10.8443 101.755 11.292 102.203 11.292 102.755Z'
            fill='#174B92'
          />
          <circle cx='16.292' cy='102.755' r='1' fill='#174B92' />
          <circle cx='22.292' cy='102.755' r='1' fill='#174B92' />
          <circle cx='28.292' cy='102.755' r='1' fill='#174B92' />
          <circle cx='34.292' cy='102.755' r='1' fill='#174B92' />
          <circle cx='40.292' cy='102.755' r='1' fill='#174B92' />
          <circle cx='46.292' cy='102.755' r='1' fill='#174B92' />
          <circle cx='52.292' cy='102.755' r='1' fill='#174B92' />
          <circle cx='58.292' cy='102.755' r='1' fill='#174B92' />
          <circle cx='64.292' cy='102.755' r='1' fill='#174B92' />
          <circle cx='70.292' cy='102.755' r='1' fill='#174B92' />
          <circle cx='76.292' cy='102.755' r='1' fill='#174B92' />
          <circle cx='82.292' cy='102.755' r='1' fill='#174B92' />
          <circle cx='88.292' cy='102.755' r='1' fill='#174B92' />
          <circle cx='94.292' cy='102.755' r='1' fill='#174B92' />
          <circle cx='100.292' cy='102.755' r='1' fill='#174B92' />
          <circle cx='106.292' cy='102.755' r='1' fill='#174B92' />
          <circle cx='112.292' cy='102.755' r='1' fill='#174B92' />
        </g>
        <path
          opacity='0.3'
          d='M136.506 118.394C149.234 128.371 220.64 107.316 245.449 95.3509L237.899 84.9022C217.135 98.2159 179.921 101.081 195.292 89.2839C227.41 64.6336 198.258 62.0272 198.258 53.2191C198.258 38.9758 245.27 26.2102 253 23.0082L247.876 14.0763C235.382 17.0536 204.299 26.1091 179.921 38.5127C149.449 54.0171 125.18 64.8475 102.528 59.9602C79.8764 55.0729 87.1573 28.2325 68.5506 14.0762C53.6652 2.75122 30.5281 -4.58564 -2.9101 0.807221L-11 30.805C-2.73034 30.805 7.60676 20.3564 30.5281 24.7381C60.0876 30.3887 37.5393 59.7917 61.809 81.7002C86.0787 103.609 169.135 65.3085 179.921 77.2739C190.708 89.2393 120.596 105.923 136.506 118.394Z'
          fill='#174B92'
        />
        <rect
          x='118.22'
          y='96.3076'
          width='4.30156'
          height='22.6923'
          rx='1'
          fill='#4BB5FC'
        />
        <rect
          x='128.257'
          y='85.9336'
          width='4.30156'
          height='22.6923'
          rx='1'
          fill='#4BB5FC'
        />
        <rect
          x='138.294'
          y='70.374'
          width='4.30156'
          height='22.6923'
          rx='1'
          fill='#4BB5FC'
        />
        <rect
          x='148.331'
          y='85.9336'
          width='4.30156'
          height='22.6923'
          rx='1'
          fill='#4BB5FC'
        />
        <rect
          x='158.367'
          y='96.3076'
          width='4.30156'
          height='22.6923'
          rx='1'
          fill='#4BB5FC'
        />
        <rect
          x='168.404'
          y='85.9336'
          width='4.30156'
          height='22.6923'
          rx='1'
          fill='#4BB5FC'
        />
        <rect
          x='178.441'
          y='80.7471'
          width='4.30156'
          height='22.6923'
          rx='1'
          fill='#4BB5FC'
        />
        <rect
          x='188.479'
          y='60'
          width='4.30156'
          height='22.6923'
          rx='1'
          fill='#4BB5FC'
        />
        <path
          d='M86.5 43L88.5367 38.515L93 36.5L88.5367 34.485L86.5 30L84.5067 34.485L80 36.5L84.5067 38.515L86.5 43Z'
          fill='#F4F4F4'
        />
        <path
          d='M160.5 88L161.597 85.585L164 84.5L161.597 83.415L160.5 81L159.427 83.415L157 84.5L159.427 85.585L160.5 88Z'
          fill='#F4F4F4'
        />
        <rect x='228' width='54' height='120' fill='#256AB5' />
        <path d='M0 0L94 120H0V0Z' fill='url(#paint0_linear_4147_22517)' />
        <path
          d='M243.5 18L245.223 14.205L249 12.5L245.223 10.795L243.5 7L241.813 10.795L238 12.5L241.813 14.205L243.5 18Z'
          fill='#4BB5FC'
        />
        <path
          d='M55.2377 25.1557L45.8716 16.3857L17.2939 56.2954L27.7658 63.0021C31.9556 65.6855 36.943 66.8411 41.886 66.274C47.6756 65.6097 52.9517 62.6357 56.5175 58.0264L57.7431 56.4421C61.6048 51.4504 63.2779 45.1065 62.3804 38.8596C61.6266 33.6125 59.1072 28.7789 55.2377 25.1557Z'
          fill='url(#paint1_linear_4147_22517)'
        />
        <path
          d='M51.1431 21.045L45.8716 16.3857L17.2939 56.2954L24.2178 59.8216C28.2882 61.8946 32.8791 62.7225 37.4172 62.2019C43.6442 61.4874 49.3188 58.2887 53.1541 53.3312L54.0822 52.1315C57.8647 47.2421 59.5036 41.0282 58.6245 34.9093C57.854 29.5468 55.2024 24.6328 51.1431 21.045Z'
          fill='#2DA08D'
        />
        <path
          d='M53.1765 34.9036C53.1765 48.1173 42.6037 58.8072 29.5882 58.8072C16.5728 58.8072 6 48.1173 6 34.9036C6 21.69 16.5728 11 29.5882 11C42.6037 11 53.1765 21.69 53.1765 34.9036Z'
          fill='#CBF5E9'
          stroke='#2DA08D'
          strokeWidth='2'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M32.6261 24.1334C34.5743 22.5569 36.5307 23.0721 36.5307 23.0721C36.5307 23.0721 36.3116 24.9857 34.4253 26.3782C32.4078 27.868 30.8006 27.1054 30.8006 27.1054C30.8006 27.1054 30.895 25.537 32.6261 24.1334Z'
          fill='#242631'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M30.6429 28.4036C31.366 28.5968 32.9996 27.9087 34.7472 28.3756C37.757 29.1798 38.3117 31.7516 38.3117 31.7516C38.3117 31.7516 35.6481 32.3803 34.8084 35.4012C33.86 38.8133 36.4163 40.7573 36.4163 40.7573C36.4163 40.7573 32.7401 46.1934 30.0148 45.4653C28.7627 45.1307 28.0387 43.983 26.7196 43.6305C25.4005 43.2781 23.7889 43.8358 22.9218 43.6041C20.4342 42.9394 18.8694 36.4323 20.1353 31.8781C21.4012 27.3239 24.6934 25.7536 27.1876 26.4201C28.808 26.853 29.7935 28.1728 30.6372 28.3983L30.6429 28.4036Z'
          fill='#242631'
        />
        <path
          d='M29.5882 60.3072C43.4501 60.3072 54.6765 48.9276 54.6765 34.9036C54.6765 20.8796 43.4501 9.5 29.5882 9.5C15.7264 9.5 4.5 20.8796 4.5 34.9036C4.5 48.9276 15.7264 60.3072 29.5882 60.3072Z'
          stroke='#242631'
        />
        <path
          d='M186.515 43.2859L169.588 58.9414L138.412 14.8238L156.724 1.85271C161.295 -1.38507 166.902 -2.81839 172.466 -2.17151C178.584 -1.46029 184.15 1.70625 187.887 6.60152L189.502 8.71685C193.691 14.2045 195.505 21.1433 194.535 27.979C193.703 33.8521 190.87 39.2583 186.515 43.2859Z'
          fill='url(#paint2_linear_4147_22517)'
        />
        <path
          d='M175.274 53.8488L169.588 58.9414L138.412 14.8238L145.961 10.9281C150.402 8.63615 155.425 7.72025 160.389 8.29737C167.163 9.08487 173.326 12.591 177.464 18.0112L178.527 19.404C182.669 24.8283 184.461 31.6871 183.503 38.444C182.66 44.388 179.746 49.8435 175.274 53.8488Z'
          fill='#FE8589'
        />
        <path
          d='M177.647 38.4706C177.647 23.7942 166.061 11.9412 151.824 11.9412C137.586 11.9412 126 23.7942 126 38.4706C126 53.147 137.586 65 151.824 65C166.061 65 177.647 53.147 177.647 38.4706Z'
          fill='#FECDD2'
          stroke='#FE8589'
          strokeWidth='2'
        />
        <path
          d='M157.994 50.4706L151.269 31.9128C154.123 30.265 155.202 30.0597 156.069 31.2593C156.069 31.2593 157.572 29.4413 157.699 27.433C152.927 27.8616 149.097 29.9665 149.097 29.9665L148.44 33.9295L148.443 33.9276L144.681 32.5116C144.681 32.5116 140.943 34.7763 138.185 38.6951C139.987 39.5856 142.314 39.1963 142.314 39.1963C141.707 37.8422 142.426 37.0136 145.256 35.3755L157.992 50.4674L157.994 50.4706Z'
          fill='#242631'
        />
        <path
          d='M146.441 30.4688C149.471 28.6898 153.242 27.1688 157.708 26.666C157.689 25.5345 157.591 25.084 157.591 25.084C152.804 25.7897 148.846 27.3714 145.694 29.1744C142.557 31.0029 139.211 33.6376 136.203 37.4325C136.203 37.4325 136.645 37.7995 137.515 38.3243C140.185 34.7117 143.386 32.2031 146.441 30.4688Z'
          fill='#242631'
        />
        <path
          d='M151.824 10.4412C166.926 10.4412 179.147 23.0027 179.147 38.4706C179.147 53.9385 166.926 66.5 151.824 66.5C136.721 66.5 124.5 53.9385 124.5 38.4706C124.5 23.0027 136.721 10.4412 151.824 10.4412Z'
          stroke='#242631'
        />
        <rect
          x='200.22'
          y='45.3076'
          width='4.30156'
          height='22.6923'
          rx='1'
          fill='#4BB5FC'
        />
        <rect
          x='210.257'
          y='34.9336'
          width='4.30156'
          height='22.6923'
          rx='1'
          fill='#4BB5FC'
        />
        <rect
          x='220.294'
          y='19.374'
          width='4.30156'
          height='22.6923'
          rx='1'
          fill='#4BB5FC'
        />
        <rect
          x='230.331'
          y='34.9336'
          width='4.30156'
          height='22.6923'
          rx='1'
          fill='#4BB5FC'
        />
        <rect
          x='240.367'
          y='45.3076'
          width='4.30156'
          height='22.6923'
          rx='1'
          fill='#4BB5FC'
        />
        <rect
          x='250.404'
          y='34.9336'
          width='4.30156'
          height='22.6923'
          rx='1'
          fill='#4BB5FC'
        />
        <rect
          x='260.441'
          y='29.7471'
          width='4.30156'
          height='22.6923'
          rx='1'
          fill='#4BB5FC'
        />
        <rect
          x='270.479'
          y='9'
          width='4.30156'
          height='22.6923'
          rx='1'
          fill='#4BB5FC'
        />
        <circle cx='25.5' cy='110.5' r='1.5' fill='white' />
        <circle cx='9.5' cy='62.5' r='1.5' fill='white' />
        <circle cx='113.5' cy='14.5' r='1.5' fill='white' />
        <circle cx='208.5' cy='24.5' r='1.5' fill='white' />
        <path
          d='M195.536 78.6474L211.835 63L256 125.5L238.036 137.69C231.561 142.083 223.724 144.003 215.952 143.099C207.16 142.077 199.162 137.527 193.791 130.492L191.749 127.818C185.733 119.937 183.129 109.973 184.521 100.156C185.683 91.9562 189.562 84.383 195.536 78.6474Z'
          fill='url(#paint3_linear_4147_22517)'
        />
        <path
          d='M203.779 70.2146L211.835 63L256 125.5L245.306 131.019C239.015 134.266 231.898 135.563 224.865 134.746C215.269 133.63 206.539 128.663 200.676 120.984L199.17 119.011C193.303 111.327 190.764 101.61 192.121 92.0379C193.315 83.6174 197.444 75.8887 203.779 70.2146Z'
          fill='#368ED8'
        />
        <path
          d='M200 92C200 113.011 216.59 130 237 130C257.41 130 274 113.011 274 92C274 70.9885 257.41 54 237 54C216.59 54 200 70.9885 200 92Z'
          fill='white'
          stroke='#368ED8'
          strokeWidth='2'
        />
        <path
          d='M237 131.5C215.725 131.5 198.5 113.803 198.5 92C198.5 70.1971 215.725 52.5 237 52.5C258.275 52.5 275.5 70.1971 275.5 92C275.5 113.803 258.275 131.5 237 131.5Z'
          stroke='#242631'
        />
        <ellipse
          cx='244.458'
          cy='74.1482'
          rx='5.96491'
          ry='5.96491'
          transform='rotate(-30 244.458 74.1482)'
          fill='#0066FF'
        />
        <ellipse
          cx='232.303'
          cy='81.1657'
          rx='5.96491'
          ry='5.96491'
          transform='rotate(-30 232.303 81.1657)'
          fill='#0066FF'
        />
        <circle
          cx='239.32'
          cy='93.32'
          r='5.96491'
          transform='rotate(-30 239.32 93.32)'
          fill='#0066FF'
        />
        <circle
          cx='246.338'
          cy='105.475'
          r='5.96491'
          transform='rotate(-30 246.338 105.475)'
          fill='#0066FF'
        />
        <circle
          cx='220.148'
          cy='88.1833'
          r='5.96491'
          transform='rotate(-30 220.148 88.1833)'
          fill='#0066FF'
        />
        <path
          d='M66.534 111.103L80.2107 124.169L115.023 75.2232L100.088 64.9899C94.9784 61.4887 88.7754 59.9519 82.6219 60.6627C75.7197 61.46 69.4348 65.0193 65.2013 70.5287L63.5844 72.633C58.8481 78.7966 56.7971 86.6096 57.8953 94.3048C58.81 100.714 61.8524 106.631 66.534 111.103Z'
          fill='url(#paint4_linear_4147_22517)'
        />
        <path
          d='M73.8258 118.487L80.2107 124.169L115.023 75.2232L106.592 70.8999C101.633 68.3573 96.0315 67.3415 90.4956 67.981C82.9211 68.8559 76.024 72.762 71.3781 78.8081L70.2186 80.317C65.6023 86.3244 63.6033 93.9394 64.6736 101.44C65.6135 108.025 68.856 114.065 73.8258 118.487Z'
          fill='#FFCA47'
        />
        <path
          d='M71.0941 101.458C71.0941 85.1237 84.0751 71.9157 100.047 71.9157C116.019 71.9157 129 85.1237 129 101.458C129 117.792 116.019 131 100.047 131C84.0751 131 71.0941 117.792 71.0941 101.458Z'
          fill='#FFF2DA'
          stroke='#FFCA47'
          strokeWidth='2'
        />
        <path
          d='M100.047 70.4157C83.2192 70.4157 69.5941 84.3229 69.5941 101.458C69.5941 118.593 83.2192 132.5 100.047 132.5C116.875 132.5 130.5 118.593 130.5 101.458C130.5 84.3229 116.875 70.4157 100.047 70.4157Z'
          stroke='#242631'
        />
        <g clipPath='url(#clip0_4147_22517)'>
          <path
            d='M107.308 112.708C94.947 114.988 89.3132 108.8 85.6685 104.981C85.4438 104.742 84.9305 104.826 85.2056 105.388C86.1851 107.537 89.8039 113.051 96.0104 114.714C102.221 116.378 106.824 113.979 107.445 113.512C108.061 113.049 107.823 112.639 107.307 112.708L107.308 112.708ZM110.842 111.833C110.65 111.37 109.174 110.898 108.201 110.761C107.229 110.621 105.659 110.818 105.689 111.197C105.718 111.344 105.862 111.32 106.526 111.435C107.192 111.545 109.073 111.802 109.319 112.4C109.567 113.001 107.969 115.12 107.69 115.456C107.418 115.794 107.626 115.956 108.013 115.786C108.395 115.615 109.141 115.106 109.774 114.273C110.405 113.434 110.987 112.184 110.842 111.833L110.842 111.833Z'
            fill='#FF9900'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M102.789 100.087C102.422 101.456 102.151 102.607 101.133 103.638C100.31 104.476 99.2625 104.847 98.2741 104.582C96.9249 104.221 96.4145 102.982 96.821 101.465C97.6235 98.4702 100.453 98.6457 102.993 99.3264L102.789 100.087ZM104.038 109.601C103.75 109.747 103.41 109.672 103.185 109.463C102.278 108.182 102.191 107.677 101.796 106.58C99.3417 108.031 97.8201 108.253 95.3195 107.583C92.3641 106.791 90.5498 104.351 91.5283 100.698C92.2924 97.847 94.3598 96.3192 96.8131 95.9596C98.9459 95.6306 101.649 96.1961 103.748 96.5095L103.87 96.0547C104.093 95.2195 104.422 94.2483 104.127 93.3957C103.87 92.6331 103.121 92.1464 102.4 91.953C101.06 91.5942 99.6803 91.9606 99.0073 93.3058C98.8632 93.6063 98.5476 93.8554 98.2273 93.7855L94.9152 92.5061C94.6459 92.3649 94.3919 92.048 94.5888 91.6293C96.4816 87.7083 100.546 87.4626 103.887 88.3578C105.597 88.816 107.709 89.8692 108.711 91.5256C109.994 93.58 109.26 95.6663 108.639 97.9841L107.172 103.46C106.731 105.106 107.219 106.01 107.623 107.072C107.766 107.449 107.713 107.843 107.363 108.003C106.486 108.409 104.913 109.179 104.045 109.614L104.037 109.601'
            fill='black'
          />
          <path
            d='M107.308 112.708C94.947 114.988 89.3132 108.8 85.6685 104.981C85.4438 104.742 84.9305 104.826 85.2056 105.388C86.1851 107.537 89.8039 113.051 96.0104 114.714C102.221 116.378 106.824 113.979 107.445 113.512C108.061 113.049 107.823 112.639 107.307 112.708L107.308 112.708ZM110.842 111.833C110.65 111.37 109.174 110.898 108.201 110.761C107.229 110.621 105.659 110.818 105.689 111.197C105.718 111.344 105.862 111.32 106.526 111.435C107.192 111.545 109.073 111.802 109.319 112.4C109.567 113.001 107.969 115.12 107.69 115.456C107.418 115.794 107.626 115.956 108.013 115.786C108.395 115.615 109.141 115.106 109.774 114.273C110.405 113.434 110.987 112.184 110.842 111.833L110.842 111.833Z'
            fill='#FF9900'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M102.789 100.087C102.422 101.456 102.151 102.607 101.133 103.638C100.31 104.476 99.2625 104.847 98.2741 104.582C96.9249 104.221 96.4145 102.982 96.821 101.465C97.6235 98.4702 100.453 98.6457 102.993 99.3264L102.789 100.087ZM104.038 109.601C103.75 109.747 103.41 109.672 103.185 109.463C102.278 108.182 102.191 107.677 101.796 106.58C99.3417 108.031 97.8201 108.253 95.3195 107.583C92.3641 106.791 90.5498 104.351 91.5283 100.698C92.2924 97.847 94.3598 96.3192 96.8131 95.9596C98.9459 95.6306 101.649 96.1961 103.748 96.5095L103.87 96.0547C104.093 95.2195 104.422 94.2483 104.127 93.3957C103.87 92.6331 103.121 92.1464 102.4 91.953C101.06 91.5942 99.6803 91.9606 99.0073 93.3058C98.8632 93.6063 98.5476 93.8554 98.2273 93.7855L94.9152 92.5061C94.6459 92.3649 94.3919 92.048 94.5888 91.6293C96.4816 87.7083 100.546 87.4626 103.887 88.3578C105.597 88.816 107.709 89.8692 108.711 91.5256C109.994 93.58 109.26 95.6663 108.639 97.9841L107.172 103.46C106.731 105.106 107.219 106.01 107.623 107.072C107.766 107.449 107.713 107.843 107.363 108.003C106.486 108.409 104.913 109.179 104.045 109.614L104.037 109.601'
            fill='black'
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_4147_22517'
          x1='-33.5'
          y1='-10.5'
          x2='141.5'
          y2='191'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#256AB5' />
          <stop offset='1' stopColor='#242631' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_4147_22517'
          x1='29.2642'
          y1='43.205'
          x2='67.6099'
          y2='59.5312'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#2DA08D' />
          <stop offset='1' stopColor='#2DA08D' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_4147_22517'
          x1='164.883'
          y1='31.7649'
          x2='193.471'
          y2='7.76494'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FE8589' />
          <stop offset='1' stopColor='#FE8589' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_4147_22517'
          x1='254.5'
          y1='79.5'
          x2='172.5'
          y2='150.5'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.227825' stopColor='#368ED8' />
          <stop offset='1' stopColor='#4F9CDD' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_4147_22517'
          x1='95.7113'
          y1='99.5003'
          x2='47.0696'
          y2='62.7813'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFCA47' />
          <stop offset='1' stopColor='#FFCA47' stopOpacity='0' />
        </linearGradient>
        <clipPath id='clip0_4147_22517'>
          <rect
            width='28.0368'
            height='28.0368'
            fill='white'
            transform='translate(89.8525 84.5967) rotate(15)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}
