import { isDateValid } from '@utils/helpers'
import { Transaction, TransactionGroup } from 'interfaces'

export const groupTransactions = (
  prev: TransactionGroup<Transaction>[],
  added: Array<Transaction>,
): TransactionGroup<Transaction>[] => {
  let previous = prev || []

  const previousIds: Array<string> = previous?.reduce((acc, i) => {
    const ids = i?.data?.length ? i?.data?.map((i) => i?.id) : []
    return [...acc, ...ids]
  }, [])

  added = added?.filter((n) => !previousIds.includes(n?.id))

  for (let i = 0; i < added?.length; i++) {
    const item = added[i]

    const isValidDate = isDateValid(item?.date)
    if (!isValidDate) continue
    const date = new Date(item?.date)?.toISOString()?.substring(0, 10)
    const idx = previous?.findIndex((i) => i?.title === date)

    if (idx !== -1) {
      previous[idx].data = [...(previous[idx].data || []), item]
    } else {
      previous.push({
        title: date,
        data: [item],
      })
    }
  }

  previous = previous.map((p) => {
    return {
      ...p,
      data: p?.data?.sort(
        (a, b) =>
          new Date(b?.date as string).getTime() -
          new Date(a?.date as string).getTime(),
      ),
    }
  })

  return previous.sort(
    (a, b) =>
      new Date(b?.title as string).getTime() -
      new Date(a?.title as string).getTime(),
  )
}
