import { Button, CustomModal, Divider, Spacer, Text } from '@components'
import { useTranslation } from '@hooks'

export const KeyFiguresInfoModal = ({
  close,
}: {
  close: () => void
}): JSX.Element => {
  const { t } = useTranslation()

  const keyFigures = [
    {
      title: t('keyFigures.betaTitle'),
      description: t('keyFigures.betaText'),
    },
    {
      title: t('keyFigures.bookValueTitle'),
      description: t('keyFigures.bookValueText'),
    },
    {
      title: t('keyFigures.dividendYieldTitle'),
      description: t('keyFigures.dividendYieldText'),
    },
    {
      title: t('keyFigures.ebitdaTitle'),
      description: t('keyFigures.ebitdaText'),
    },
    {
      title: t('keyFigures.epsTitle'),
      description: t('keyFigures.epsText'),
    },
    {
      title: t('keyFigures.marketCapTitle'),
      description: t('keyFigures.marketCapText'),
    },
    {
      title: t('keyFigures.perTitle'),
      description: t('keyFigures.perText'),
    },
    {
      title: t('keyFigures.revenueTtmTitle'),
      description: t('keyFigures.revenueTtmText'),
    },
  ]

  return (
    <CustomModal size='big' show={true} theme='lighter' onClose={close}>
      <CustomModal.Header text={t('keyFigures.title')} />
      <CustomModal.Content>
        {keyFigures?.map((k, idx) => (
          <div key={`key-figure-${k?.title}`}>
            <Spacer preset='tiny' />
            <Text text={k?.title} preset='headline3' />
            <Spacer preset='tiny' />
            <Text text={k?.description} preset='paragraph2' color='secondary' />
            <Spacer preset='tiny' />
            {keyFigures[idx + 1] ? <Divider /> : null}
          </div>
        ))}
      </CustomModal.Content>
      <CustomModal.Footer>
        <Button text={t('common.ok')} onClick={() => close()} />
      </CustomModal.Footer>
    </CustomModal>
  )
}
