import { AssessmentIcon, AssessmentIncorrectIcon } from '@assets/svg'
import { useTranslation } from '@hooks'
import { Button, Card, Divider, Spacer, Text } from '@components'
import { useEffect, useState } from 'react'

export default function AssessmentCard({
  onNotNow,
  onStart,
  riskAssessmentIncorrect,
}: {
  onNotNow: () => void
  onStart: () => void
  riskAssessmentIncorrect: boolean | null
}): JSX.Element {
  const { t } = useTranslation()

  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [icon, setIcon] = useState(null)
  const [buttonText, setButtonText] = useState('')

  useEffect(() => {
    if (!riskAssessmentIncorrect) {
      setTitle(t('savingsPlans.title'))
      setDescription(t('savingsPlans.description'))
      setIcon(
        <AssessmentIcon
          className='assessmentCard__icon__svg'
          fill='var(--iconPrimaryInitial)'
        />,
      )
      setButtonText(t('common.start'))
    } else {
      setTitle(t('savingsPlans.notAppropiateTitle'))
      setDescription(t('savingsPlans.notAppropiateText'))
      setIcon(<AssessmentIncorrectIcon className='assessmentCard__icon__svg' />)
      setButtonText(t('savingsPlans.retake'))
    }
  }, [riskAssessmentIncorrect, t])

  return (
    <Card bgColor='transparent' bordered padding='small'>
      <div className='d-flex align-center --text-primary-base'>
        {icon}
        <Spacer preset='smaller' />
        <Text preset='smallText' text={title} color='primary' />
      </div>
      <Spacer preset='smaller' />
      <Divider />
      <Spacer preset='smaller' />
      <div className='d-flex justify-space-between'>
        <div className='w-60'>
          <Text preset='paragraph2' text={description} />
        </div>
        <div className='d-flex'>
          {riskAssessmentIncorrect ? null : (
            <>
              <Button
                text={t('common.notNow')}
                autoWidth
                size='smaller'
                textPreset='paragraph2'
                onClick={onNotNow}
              />
              <Spacer preset='smaller' />
            </>
          )}
          <Button
            text={buttonText}
            autoWidth
            size='smaller'
            textPreset='paragraph2'
            onClick={onStart}
          />
        </div>
      </div>
    </Card>
  )
}
