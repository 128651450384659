import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { getPromotions } from '@services'
import { Promotion } from '@interfaces'

export const fetchPromotions = createAsyncThunk<Promotion[], boolean>(
  'fetchPromotions',
  async (isTester) => {
    return await getPromotions(isTester)
  },
)

export const setPromotionInactive = createAction<string>('setPromotionInactive')

export const clearInactivePromotions = createAction('clearInactivePromotions')
