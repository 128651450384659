import { Request, Questionnaire, Copy } from './steps'
import { AccountDeletionProps } from './accountDeletion.props'
import './accountDeletion.styles.scss'
import { useState } from 'react'

export function AccountDeletion({
  show,
  onClose,
  onCancel,
}: AccountDeletionProps): JSX.Element {
  const [step, setStep] = useState(1)
  const [computeMailBody, setComputeMailBody] = useState('')

  const onCloseReset = (): void => {
    setStep(1)
    onClose()
  }

  const onEmail = (body: string): void => {
    setComputeMailBody(body)
    setStep(3)
  }

  const renderSteps = (step: number): JSX.Element => {
    switch (step) {
      case 1:
        return (
          <Request
            nextStep={() => setStep(2)}
            onCancel={onCancel}
            show={show}
            onClose={onCloseReset}
          />
        )
      case 2:
        return (
          <Questionnaire
            prevStep={() => setStep(1)}
            nextStep={onEmail}
            show={show}
            onClose={onCloseReset}
          />
        )
      case 3:
        return (
          <Copy
            prevStep={() => setStep(2)}
            show={show}
            onClose={onCloseReset}
            computeMail={computeMailBody}
          />
        )
      default:
        return <></>
    }
  }

  return <>{renderSteps(step)}</>
}
