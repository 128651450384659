import {
  EeuuAndUeIcons,
  EuroAndDollarIcons,
  ProTierTbc,
} from '@assets/illustrations'
import './brokerTier.styles.scss'
import { Button, Card, Spacer, Text } from 'components'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { useTranslation } from 'hooks/useTranslation'
import { BrokerTierEnum } from 'interfaces'

export const BrokerTier = ({
  openMoreInfo,
  openConfig,
}: {
  openMoreInfo: () => void
  openConfig: () => void
}): JSX.Element => {
  const { t } = useTranslation()
  const { brokerTier, profile } = useSelector((state: RootState) => state)

  const isPro: boolean = brokerTier?.tier === BrokerTierEnum.pro

  return (
    <div className='home__widget'>
      <div className='home__widget__broker-tier'>
        <div className='broker-tier'>
          <Spacer preset='smaller' />
          <div className='d-flex justify-space-between align-center'>
            <Text text={t('brokerTier.makeTheMost')} preset='headline3' />
            <Button
              autoWidth
              size='small'
              shape='square'
              left={<Text preset='headline1' text='...' color='primary' />}
              textPreset='headline2'
              buttonType='link'
              onClick={openConfig}
            />
          </div>
          <Spacer preset='smaller' />
          <div className='broker-tier__columns'>
            <Card
              key='account-type'
              width='full'
              aria-hidden
              onClick={openMoreInfo}
            >
              <div className='d-flex d-flex-col align-start'>
                <ProTierTbc width={105} height={41} />
                <Spacer preset='smaller' />
                <Text preset='paragraph2'>
                  {t('brokerTier.youCurrentlyHoldA', {
                    username:
                      profile?.userProfile?.name ||
                      profile?.userProfile?.fullName,
                    tier: isPro
                      ? t('brokerTier.tbccPro')
                      : t('brokerTier.tbccBasic'),
                  })}
                </Text>
                <Spacer preset='smaller' />
                <div className='cursor-pointer w-fit-content'>
                  <Text
                    preset='paragraph2'
                    text={t('common.learnMore')}
                    color='iconPrimaryInitial'
                  />
                </div>
              </div>
            </Card>
            <Card
              key='low-transaction-fees'
              width='full'
              aria-hidden
              onClick={openMoreInfo}
            >
              <EeuuAndUeIcons />
              <Spacer preset='smaller' />
              <Text text={t('brokerTier.enjoyLowFees')} preset='subtitle2' />
              <Spacer preset='smaller' />
              <Text preset='paragraph2'>
                {t('brokerTier.basisPointsEUandUS')}
              </Text>
            </Card>
            <Card
              key='competitive-exchange'
              width='full'
              aria-hidden
              onClick={openMoreInfo}
            >
              <EuroAndDollarIcons />
              <Spacer preset='smaller' />
              <Text
                text={t('brokerTier.currencyExchange')}
                preset='subtitle2'
              />
              <Spacer preset='smaller' />
              <Text
                text={t('brokerTier.currencyExchangeExplanation')}
                preset='paragraph2'
              />
            </Card>
          </div>
        </div>
      </div>
    </div>
  )
}
