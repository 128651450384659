import { SVGProps } from 'react'

export const ETFsIllustration = ({
  props,
}: {
  props?: SVGProps<SVGSVGElement>
}): JSX.Element => {
  return (
    <svg
      width='282'
      viewBox='0 0 282 120'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <mask
        id='mask0_4147_27158'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='282'
        height='120'
      >
        <path
          d='M0 8C0 3.58172 3.58172 0 8 0H274C278.418 0 282 3.58172 282 8V120H0V8Z'
          fill='#2A3B4F'
        />
      </mask>
      <g mask='url(#mask0_4147_27158)'>
        <path
          d='M0 8C0 3.58172 3.58172 0 8 0H274C278.418 0 282 3.58172 282 8V120H0V8Z'
          fill='#454654'
        />
        <g opacity='0.89'>
          <path
            d='M2 4C2 4.55228 1.55228 5 1 5C0.447715 5 0 4.55228 0 4C0 3.44772 0.447715 3 1 3C1.55228 3 2 3.44772 2 4Z'
            fill='#6F717C'
          />
          <path
            d='M8 4C8 4.55228 7.55228 5 7 5C6.44772 5 6 4.55228 6 4C6 3.44772 6.44772 3 7 3C7.55228 3 8 3.44772 8 4Z'
            fill='#6F717C'
          />
          <circle cx='13' cy='4' r='1' fill='#6F717C' />
          <circle cx='19' cy='4' r='1' fill='#6F717C' />
          <circle cx='25' cy='4' r='1' fill='#6F717C' />
          <circle cx='31' cy='4' r='1' fill='#6F717C' />
          <circle cx='37' cy='4' r='1' fill='#6F717C' />
          <circle cx='43' cy='4' r='1' fill='#6F717C' />
          <circle cx='49' cy='4' r='1' fill='#6F717C' />
          <circle cx='55' cy='4' r='1' fill='#6F717C' />
          <circle cx='61' cy='4' r='1' fill='#6F717C' />
          <circle cx='67' cy='4' r='1' fill='#6F717C' />
          <circle cx='73' cy='4' r='1' fill='#6F717C' />
          <circle cx='79' cy='4' r='1' fill='#6F717C' />
          <circle cx='85' cy='4' r='1' fill='#6F717C' />
          <circle cx='91' cy='4' r='1' fill='#6F717C' />
          <circle cx='97' cy='4' r='1' fill='#6F717C' />
          <circle cx='103' cy='4' r='1' fill='#6F717C' />
          <circle cx='109' cy='4' r='1' fill='#6F717C' />
          <path
            d='M2 30.335C2 30.8872 1.55228 31.335 1 31.335C0.447715 31.335 0 30.8872 0 30.335C0 29.7827 0.447715 29.335 1 29.335C1.55228 29.335 2 29.7827 2 30.335Z'
            fill='#6F717C'
          />
          <path
            d='M8 30.335C8 30.8872 7.55228 31.335 7 31.335C6.44772 31.335 6 30.8872 6 30.335C6 29.7827 6.44772 29.335 7 29.335C7.55228 29.335 8 29.7827 8 30.335Z'
            fill='#6F717C'
          />
          <circle cx='13' cy='30.335' r='1' fill='#6F717C' />
          <circle cx='19' cy='30.335' r='1' fill='#6F717C' />
          <circle cx='25' cy='30.335' r='1' fill='#6F717C' />
          <circle cx='31' cy='30.335' r='1' fill='#6F717C' />
          <circle cx='37' cy='30.335' r='1' fill='#6F717C' />
          <circle cx='43' cy='30.335' r='1' fill='#6F717C' />
          <circle cx='49' cy='30.335' r='1' fill='#6F717C' />
          <circle cx='55' cy='30.335' r='1' fill='#6F717C' />
          <circle cx='61' cy='30.335' r='1' fill='#6F717C' />
          <circle cx='67' cy='30.335' r='1' fill='#6F717C' />
          <circle cx='73' cy='30.335' r='1' fill='#6F717C' />
          <circle cx='79' cy='30.335' r='1' fill='#6F717C' />
          <circle cx='85' cy='30.335' r='1' fill='#6F717C' />
          <circle cx='91' cy='30.335' r='1' fill='#6F717C' />
          <circle cx='97' cy='30.335' r='1' fill='#6F717C' />
          <circle cx='103' cy='30.335' r='1' fill='#6F717C' />
          <circle cx='109' cy='30.335' r='1' fill='#6F717C' />
          <path
            d='M2 56.6689C2 57.2212 1.55228 57.6689 1 57.6689C0.447715 57.6689 0 57.2212 0 56.6689C0 56.1167 0.447715 55.6689 1 55.6689C1.55228 55.6689 2 56.1167 2 56.6689Z'
            fill='#6F717C'
          />
          <path
            d='M8 56.6689C8 57.2212 7.55228 57.6689 7 57.6689C6.44772 57.6689 6 57.2212 6 56.6689C6 56.1167 6.44772 55.6689 7 55.6689C7.55228 55.6689 8 56.1167 8 56.6689Z'
            fill='#6F717C'
          />
          <circle cx='13' cy='56.6689' r='1' fill='#6F717C' />
          <circle cx='19' cy='56.6689' r='1' fill='#6F717C' />
          <circle cx='25' cy='56.6689' r='1' fill='#6F717C' />
          <circle cx='31' cy='56.6689' r='1' fill='#6F717C' />
          <circle cx='37' cy='56.6689' r='1' fill='#6F717C' />
          <circle cx='43' cy='56.6689' r='1' fill='#6F717C' />
          <circle cx='49' cy='56.6689' r='1' fill='#6F717C' />
          <circle cx='55' cy='56.6689' r='1' fill='#6F717C' />
          <circle cx='61' cy='56.6689' r='1' fill='#6F717C' />
          <circle cx='67' cy='56.6689' r='1' fill='#6F717C' />
          <circle cx='73' cy='56.6689' r='1' fill='#6F717C' />
          <circle cx='79' cy='56.6689' r='1' fill='#6F717C' />
          <circle cx='85' cy='56.6689' r='1' fill='#6F717C' />
          <circle cx='91' cy='56.6689' r='1' fill='#6F717C' />
          <circle cx='97' cy='56.6689' r='1' fill='#6F717C' />
          <circle cx='103' cy='56.6689' r='1' fill='#6F717C' />
          <circle cx='109' cy='56.6689' r='1' fill='#6F717C' />
          <path
            d='M2 83.0039C2 83.5562 1.55228 84.0039 1 84.0039C0.447715 84.0039 0 83.5562 0 83.0039C0 82.4516 0.447715 82.0039 1 82.0039C1.55228 82.0039 2 82.4516 2 83.0039Z'
            fill='#6F717C'
          />
          <path
            d='M8 83.0039C8 83.5562 7.55228 84.0039 7 84.0039C6.44772 84.0039 6 83.5562 6 83.0039C6 82.4516 6.44772 82.0039 7 82.0039C7.55228 82.0039 8 82.4516 8 83.0039Z'
            fill='#6F717C'
          />
          <circle cx='13' cy='83.0039' r='1' fill='#6F717C' />
          <circle cx='19' cy='83.0039' r='1' fill='#6F717C' />
          <circle cx='25' cy='83.0039' r='1' fill='#6F717C' />
          <circle cx='31' cy='83.0039' r='1' fill='#6F717C' />
          <circle cx='37' cy='83.0039' r='1' fill='#6F717C' />
          <circle cx='43' cy='83.0039' r='1' fill='#6F717C' />
          <circle cx='49' cy='83.0039' r='1' fill='#6F717C' />
          <circle cx='55' cy='83.0039' r='1' fill='#6F717C' />
          <circle cx='61' cy='83.0039' r='1' fill='#6F717C' />
          <circle cx='67' cy='83.0039' r='1' fill='#6F717C' />
          <circle cx='73' cy='83.0039' r='1' fill='#6F717C' />
          <circle cx='79' cy='83.0039' r='1' fill='#6F717C' />
          <circle cx='85' cy='83.0039' r='1' fill='#6F717C' />
          <circle cx='91' cy='83.0039' r='1' fill='#6F717C' />
          <circle cx='97' cy='83.0039' r='1' fill='#6F717C' />
          <circle cx='103' cy='83.0039' r='1' fill='#6F717C' />
          <circle cx='109' cy='83.0039' r='1' fill='#6F717C' />
          <path
            d='M2 109.339C2 109.891 1.55228 110.339 1 110.339C0.447715 110.339 0 109.891 0 109.339C0 108.787 0.447715 108.339 1 108.339C1.55228 108.339 2 108.787 2 109.339Z'
            fill='#6F717C'
          />
          <path
            d='M8 109.339C8 109.891 7.55228 110.339 7 110.339C6.44772 110.339 6 109.891 6 109.339C6 108.787 6.44772 108.339 7 108.339C7.55228 108.339 8 108.787 8 109.339Z'
            fill='#6F717C'
          />
          <circle cx='13' cy='109.339' r='1' fill='#6F717C' />
          <circle cx='19' cy='109.339' r='1' fill='#6F717C' />
          <circle cx='25' cy='109.339' r='1' fill='#6F717C' />
          <circle cx='31' cy='109.339' r='1' fill='#6F717C' />
          <circle cx='37' cy='109.339' r='1' fill='#6F717C' />
          <circle cx='43' cy='109.339' r='1' fill='#6F717C' />
          <circle cx='49' cy='109.339' r='1' fill='#6F717C' />
          <circle cx='55' cy='109.339' r='1' fill='#6F717C' />
          <circle cx='61' cy='109.339' r='1' fill='#6F717C' />
          <circle cx='67' cy='109.339' r='1' fill='#6F717C' />
          <circle cx='73' cy='109.339' r='1' fill='#6F717C' />
          <circle cx='79' cy='109.339' r='1' fill='#6F717C' />
          <circle cx='85' cy='109.339' r='1' fill='#6F717C' />
          <circle cx='91' cy='109.339' r='1' fill='#6F717C' />
          <circle cx='97' cy='109.339' r='1' fill='#6F717C' />
          <circle cx='103' cy='109.339' r='1' fill='#6F717C' />
          <circle cx='109' cy='109.339' r='1' fill='#6F717C' />
          <path
            d='M2 17.167C2 17.7193 1.55228 18.167 1 18.167C0.447715 18.167 0 17.7193 0 17.167C0 16.6147 0.447715 16.167 1 16.167C1.55228 16.167 2 16.6147 2 17.167Z'
            fill='#6F717C'
          />
          <path
            d='M8 17.167C8 17.7193 7.55228 18.167 7 18.167C6.44772 18.167 6 17.7193 6 17.167C6 16.6147 6.44772 16.167 7 16.167C7.55228 16.167 8 16.6147 8 17.167Z'
            fill='#6F717C'
          />
          <circle cx='13' cy='17.167' r='1' fill='#6F717C' />
          <circle cx='19' cy='17.167' r='1' fill='#6F717C' />
          <circle cx='25' cy='17.167' r='1' fill='#6F717C' />
          <circle cx='31' cy='17.167' r='1' fill='#6F717C' />
          <circle cx='37' cy='17.167' r='1' fill='#6F717C' />
          <circle cx='43' cy='17.167' r='1' fill='#6F717C' />
          <circle cx='49' cy='17.167' r='1' fill='#6F717C' />
          <circle cx='55' cy='17.167' r='1' fill='#6F717C' />
          <circle cx='61' cy='17.167' r='1' fill='#6F717C' />
          <circle cx='67' cy='17.167' r='1' fill='#6F717C' />
          <circle cx='73' cy='17.167' r='1' fill='#6F717C' />
          <circle cx='79' cy='17.167' r='1' fill='#6F717C' />
          <circle cx='85' cy='17.167' r='1' fill='#6F717C' />
          <circle cx='91' cy='17.167' r='1' fill='#6F717C' />
          <circle cx='97' cy='17.167' r='1' fill='#6F717C' />
          <circle cx='103' cy='17.167' r='1' fill='#6F717C' />
          <circle cx='109' cy='17.167' r='1' fill='#6F717C' />
          <path
            d='M2 43.502C2 44.0542 1.55228 44.502 1 44.502C0.447715 44.502 0 44.0542 0 43.502C0 42.9497 0.447715 42.502 1 42.502C1.55228 42.502 2 42.9497 2 43.502Z'
            fill='#6F717C'
          />
          <path
            d='M8 43.502C8 44.0542 7.55228 44.502 7 44.502C6.44772 44.502 6 44.0542 6 43.502C6 42.9497 6.44772 42.502 7 42.502C7.55228 42.502 8 42.9497 8 43.502Z'
            fill='#6F717C'
          />
          <circle cx='13' cy='43.502' r='1' fill='#6F717C' />
          <circle cx='19' cy='43.502' r='1' fill='#6F717C' />
          <circle cx='25' cy='43.502' r='1' fill='#6F717C' />
          <circle cx='31' cy='43.502' r='1' fill='#6F717C' />
          <circle cx='37' cy='43.502' r='1' fill='#6F717C' />
          <circle cx='43' cy='43.502' r='1' fill='#6F717C' />
          <circle cx='49' cy='43.502' r='1' fill='#6F717C' />
          <circle cx='55' cy='43.502' r='1' fill='#6F717C' />
          <circle cx='61' cy='43.502' r='1' fill='#6F717C' />
          <circle cx='67' cy='43.502' r='1' fill='#6F717C' />
          <circle cx='73' cy='43.502' r='1' fill='#6F717C' />
          <circle cx='79' cy='43.502' r='1' fill='#6F717C' />
          <circle cx='85' cy='43.502' r='1' fill='#6F717C' />
          <circle cx='91' cy='43.502' r='1' fill='#6F717C' />
          <circle cx='97' cy='43.502' r='1' fill='#6F717C' />
          <circle cx='103' cy='43.502' r='1' fill='#6F717C' />
          <circle cx='109' cy='43.502' r='1' fill='#6F717C' />
          <path
            d='M2 69.8369C2 70.3892 1.55228 70.8369 1 70.8369C0.447715 70.8369 0 70.3892 0 69.8369C0 69.2846 0.447715 68.8369 1 68.8369C1.55228 68.8369 2 69.2846 2 69.8369Z'
            fill='#6F717C'
          />
          <path
            d='M8 69.8369C8 70.3892 7.55228 70.8369 7 70.8369C6.44772 70.8369 6 70.3892 6 69.8369C6 69.2846 6.44772 68.8369 7 68.8369C7.55228 68.8369 8 69.2846 8 69.8369Z'
            fill='#6F717C'
          />
          <circle cx='13' cy='69.8369' r='1' fill='#6F717C' />
          <circle cx='19' cy='69.8369' r='1' fill='#6F717C' />
          <circle cx='25' cy='69.8369' r='1' fill='#6F717C' />
          <circle cx='31' cy='69.8369' r='1' fill='#6F717C' />
          <circle cx='37' cy='69.8369' r='1' fill='#6F717C' />
          <circle cx='43' cy='69.8369' r='1' fill='#6F717C' />
          <circle cx='49' cy='69.8369' r='1' fill='#6F717C' />
          <circle cx='55' cy='69.8369' r='1' fill='#6F717C' />
          <circle cx='61' cy='69.8369' r='1' fill='#6F717C' />
          <circle cx='67' cy='69.8369' r='1' fill='#6F717C' />
          <circle cx='73' cy='69.8369' r='1' fill='#6F717C' />
          <circle cx='79' cy='69.8369' r='1' fill='#6F717C' />
          <circle cx='85' cy='69.8369' r='1' fill='#6F717C' />
          <circle cx='91' cy='69.8369' r='1' fill='#6F717C' />
          <circle cx='97' cy='69.8369' r='1' fill='#6F717C' />
          <circle cx='103' cy='69.8369' r='1' fill='#6F717C' />
          <circle cx='109' cy='69.8369' r='1' fill='#6F717C' />
          <path
            d='M2 96.1709C2 96.7232 1.55228 97.1709 1 97.1709C0.447715 97.1709 0 96.7232 0 96.1709C0 95.6186 0.447715 95.1709 1 95.1709C1.55228 95.1709 2 95.6186 2 96.1709Z'
            fill='#6F717C'
          />
          <path
            d='M8 96.1709C8 96.7232 7.55228 97.1709 7 97.1709C6.44772 97.1709 6 96.7232 6 96.1709C6 95.6186 6.44772 95.1709 7 95.1709C7.55228 95.1709 8 95.6186 8 96.1709Z'
            fill='#6F717C'
          />
          <circle cx='13' cy='96.1709' r='1' fill='#6F717C' />
          <circle cx='19' cy='96.1709' r='1' fill='#6F717C' />
          <circle cx='25' cy='96.1709' r='1' fill='#6F717C' />
          <circle cx='31' cy='96.1709' r='1' fill='#6F717C' />
          <circle cx='37' cy='96.1709' r='1' fill='#6F717C' />
          <circle cx='43' cy='96.1709' r='1' fill='#6F717C' />
          <circle cx='49' cy='96.1709' r='1' fill='#6F717C' />
          <circle cx='55' cy='96.1709' r='1' fill='#6F717C' />
          <circle cx='61' cy='96.1709' r='1' fill='#6F717C' />
          <circle cx='67' cy='96.1709' r='1' fill='#6F717C' />
          <circle cx='73' cy='96.1709' r='1' fill='#6F717C' />
          <circle cx='79' cy='96.1709' r='1' fill='#6F717C' />
          <circle cx='85' cy='96.1709' r='1' fill='#6F717C' />
          <circle cx='91' cy='96.1709' r='1' fill='#6F717C' />
          <circle cx='97' cy='96.1709' r='1' fill='#6F717C' />
          <circle cx='103' cy='96.1709' r='1' fill='#6F717C' />
          <circle cx='109' cy='96.1709' r='1' fill='#6F717C' />
          <path
            d='M5.29199 10.584C5.29199 11.1363 4.84428 11.584 4.29199 11.584C3.73971 11.584 3.29199 11.1363 3.29199 10.584C3.29199 10.0317 3.73971 9.58398 4.29199 9.58398C4.84428 9.58398 5.29199 10.0317 5.29199 10.584Z'
            fill='#6F717C'
          />
          <path
            d='M11.292 10.584C11.292 11.1363 10.8443 11.584 10.292 11.584C9.73971 11.584 9.29199 11.1363 9.29199 10.584C9.29199 10.0317 9.73971 9.58398 10.292 9.58398C10.8443 9.58398 11.292 10.0317 11.292 10.584Z'
            fill='#6F717C'
          />
          <circle cx='16.292' cy='10.584' r='1' fill='#6F717C' />
          <circle cx='22.292' cy='10.584' r='1' fill='#6F717C' />
          <circle cx='28.292' cy='10.584' r='1' fill='#6F717C' />
          <circle cx='34.292' cy='10.584' r='1' fill='#6F717C' />
          <circle cx='40.292' cy='10.584' r='1' fill='#6F717C' />
          <circle cx='46.292' cy='10.584' r='1' fill='#6F717C' />
          <circle cx='52.292' cy='10.584' r='1' fill='#6F717C' />
          <circle cx='58.292' cy='10.584' r='1' fill='#6F717C' />
          <circle cx='64.292' cy='10.584' r='1' fill='#6F717C' />
          <circle cx='70.292' cy='10.584' r='1' fill='#6F717C' />
          <circle cx='76.292' cy='10.584' r='1' fill='#6F717C' />
          <circle cx='82.292' cy='10.584' r='1' fill='#6F717C' />
          <circle cx='88.292' cy='10.584' r='1' fill='#6F717C' />
          <circle cx='94.292' cy='10.584' r='1' fill='#6F717C' />
          <circle cx='100.292' cy='10.584' r='1' fill='#6F717C' />
          <circle cx='106.292' cy='10.584' r='1' fill='#6F717C' />
          <circle cx='112.292' cy='10.584' r='1' fill='#6F717C' />
          <path
            d='M5.29199 36.918C5.29199 37.4703 4.84428 37.918 4.29199 37.918C3.73971 37.918 3.29199 37.4703 3.29199 36.918C3.29199 36.3657 3.73971 35.918 4.29199 35.918C4.84428 35.918 5.29199 36.3657 5.29199 36.918Z'
            fill='#6F717C'
          />
          <path
            d='M11.292 36.918C11.292 37.4703 10.8443 37.918 10.292 37.918C9.73971 37.918 9.29199 37.4703 9.29199 36.918C9.29199 36.3657 9.73971 35.918 10.292 35.918C10.8443 35.918 11.292 36.3657 11.292 36.918Z'
            fill='#6F717C'
          />
          <circle cx='16.292' cy='36.918' r='1' fill='#6F717C' />
          <circle cx='22.292' cy='36.918' r='1' fill='#6F717C' />
          <circle cx='28.292' cy='36.918' r='1' fill='#6F717C' />
          <circle cx='34.292' cy='36.918' r='1' fill='#6F717C' />
          <circle cx='40.292' cy='36.918' r='1' fill='#6F717C' />
          <circle cx='46.292' cy='36.918' r='1' fill='#6F717C' />
          <circle cx='52.292' cy='36.918' r='1' fill='#6F717C' />
          <circle cx='58.292' cy='36.918' r='1' fill='#6F717C' />
          <circle cx='64.292' cy='36.918' r='1' fill='#6F717C' />
          <circle cx='70.292' cy='36.918' r='1' fill='#6F717C' />
          <circle cx='76.292' cy='36.918' r='1' fill='#6F717C' />
          <circle cx='82.292' cy='36.918' r='1' fill='#6F717C' />
          <circle cx='88.292' cy='36.918' r='1' fill='#6F717C' />
          <circle cx='94.292' cy='36.918' r='1' fill='#6F717C' />
          <circle cx='100.292' cy='36.918' r='1' fill='#6F717C' />
          <circle cx='106.292' cy='36.918' r='1' fill='#6F717C' />
          <circle cx='112.292' cy='36.918' r='1' fill='#6F717C' />
          <path
            d='M5.29199 63.2529C5.29199 63.8052 4.84428 64.2529 4.29199 64.2529C3.73971 64.2529 3.29199 63.8052 3.29199 63.2529C3.29199 62.7006 3.73971 62.2529 4.29199 62.2529C4.84428 62.2529 5.29199 62.7006 5.29199 63.2529Z'
            fill='#6F717C'
          />
          <path
            d='M11.292 63.2529C11.292 63.8052 10.8443 64.2529 10.292 64.2529C9.73971 64.2529 9.29199 63.8052 9.29199 63.2529C9.29199 62.7006 9.73971 62.2529 10.292 62.2529C10.8443 62.2529 11.292 62.7006 11.292 63.2529Z'
            fill='#6F717C'
          />
          <circle cx='16.292' cy='63.2529' r='1' fill='#6F717C' />
          <circle cx='22.292' cy='63.2529' r='1' fill='#6F717C' />
          <circle cx='28.292' cy='63.2529' r='1' fill='#6F717C' />
          <circle cx='34.292' cy='63.2529' r='1' fill='#6F717C' />
          <circle cx='40.292' cy='63.2529' r='1' fill='#6F717C' />
          <circle cx='46.292' cy='63.2529' r='1' fill='#6F717C' />
          <circle cx='52.292' cy='63.2529' r='1' fill='#6F717C' />
          <circle cx='58.292' cy='63.2529' r='1' fill='#6F717C' />
          <circle cx='64.292' cy='63.2529' r='1' fill='#6F717C' />
          <circle cx='70.292' cy='63.2529' r='1' fill='#6F717C' />
          <circle cx='76.292' cy='63.2529' r='1' fill='#6F717C' />
          <circle cx='82.292' cy='63.2529' r='1' fill='#6F717C' />
          <circle cx='88.292' cy='63.2529' r='1' fill='#6F717C' />
          <circle cx='94.292' cy='63.2529' r='1' fill='#6F717C' />
          <circle cx='100.292' cy='63.2529' r='1' fill='#6F717C' />
          <circle cx='106.292' cy='63.2529' r='1' fill='#6F717C' />
          <circle cx='112.292' cy='63.2529' r='1' fill='#6F717C' />
          <path
            d='M5.29199 89.5879C5.29199 90.1402 4.84428 90.5879 4.29199 90.5879C3.73971 90.5879 3.29199 90.1402 3.29199 89.5879C3.29199 89.0356 3.73971 88.5879 4.29199 88.5879C4.84428 88.5879 5.29199 89.0356 5.29199 89.5879Z'
            fill='#6F717C'
          />
          <path
            d='M11.292 89.5879C11.292 90.1402 10.8443 90.5879 10.292 90.5879C9.73971 90.5879 9.29199 90.1402 9.29199 89.5879C9.29199 89.0356 9.73971 88.5879 10.292 88.5879C10.8443 88.5879 11.292 89.0356 11.292 89.5879Z'
            fill='#6F717C'
          />
          <circle cx='16.292' cy='89.5879' r='1' fill='#6F717C' />
          <circle cx='22.292' cy='89.5879' r='1' fill='#6F717C' />
          <circle cx='28.292' cy='89.5879' r='1' fill='#6F717C' />
          <circle cx='34.292' cy='89.5879' r='1' fill='#6F717C' />
          <circle cx='40.292' cy='89.5879' r='1' fill='#6F717C' />
          <circle cx='46.292' cy='89.5879' r='1' fill='#6F717C' />
          <circle cx='52.292' cy='89.5879' r='1' fill='#6F717C' />
          <circle cx='58.292' cy='89.5879' r='1' fill='#6F717C' />
          <circle cx='64.292' cy='89.5879' r='1' fill='#6F717C' />
          <circle cx='70.292' cy='89.5879' r='1' fill='#6F717C' />
          <circle cx='76.292' cy='89.5879' r='1' fill='#6F717C' />
          <circle cx='82.292' cy='89.5879' r='1' fill='#6F717C' />
          <circle cx='88.292' cy='89.5879' r='1' fill='#6F717C' />
          <circle cx='94.292' cy='89.5879' r='1' fill='#6F717C' />
          <circle cx='100.292' cy='89.5879' r='1' fill='#6F717C' />
          <circle cx='106.292' cy='89.5879' r='1' fill='#6F717C' />
          <circle cx='112.292' cy='89.5879' r='1' fill='#6F717C' />
          <path
            d='M5.29199 115.922C5.29199 116.474 4.84428 116.922 4.29199 116.922C3.73971 116.922 3.29199 116.474 3.29199 115.922C3.29199 115.37 3.73971 114.922 4.29199 114.922C4.84428 114.922 5.29199 115.37 5.29199 115.922Z'
            fill='#6F717C'
          />
          <path
            d='M11.292 115.922C11.292 116.474 10.8443 116.922 10.292 116.922C9.73971 116.922 9.29199 116.474 9.29199 115.922C9.29199 115.37 9.73971 114.922 10.292 114.922C10.8443 114.922 11.292 115.37 11.292 115.922Z'
            fill='#6F717C'
          />
          <circle cx='16.292' cy='115.922' r='1' fill='#6F717C' />
          <circle cx='22.292' cy='115.922' r='1' fill='#6F717C' />
          <circle cx='28.292' cy='115.922' r='1' fill='#6F717C' />
          <circle cx='34.292' cy='115.922' r='1' fill='#6F717C' />
          <circle cx='40.292' cy='115.922' r='1' fill='#6F717C' />
          <circle cx='46.292' cy='115.922' r='1' fill='#6F717C' />
          <circle cx='52.292' cy='115.922' r='1' fill='#6F717C' />
          <circle cx='58.292' cy='115.922' r='1' fill='#6F717C' />
          <circle cx='64.292' cy='115.922' r='1' fill='#6F717C' />
          <circle cx='70.292' cy='115.922' r='1' fill='#6F717C' />
          <circle cx='76.292' cy='115.922' r='1' fill='#6F717C' />
          <circle cx='82.292' cy='115.922' r='1' fill='#6F717C' />
          <circle cx='88.292' cy='115.922' r='1' fill='#6F717C' />
          <circle cx='94.292' cy='115.922' r='1' fill='#6F717C' />
          <circle cx='100.292' cy='115.922' r='1' fill='#6F717C' />
          <circle cx='106.292' cy='115.922' r='1' fill='#6F717C' />
          <circle cx='112.292' cy='115.922' r='1' fill='#6F717C' />
          <path
            d='M5.29199 23.751C5.29199 24.3033 4.84428 24.751 4.29199 24.751C3.73971 24.751 3.29199 24.3033 3.29199 23.751C3.29199 23.1987 3.73971 22.751 4.29199 22.751C4.84428 22.751 5.29199 23.1987 5.29199 23.751Z'
            fill='#6F717C'
          />
          <path
            d='M11.292 23.751C11.292 24.3033 10.8443 24.751 10.292 24.751C9.73971 24.751 9.29199 24.3033 9.29199 23.751C9.29199 23.1987 9.73971 22.751 10.292 22.751C10.8443 22.751 11.292 23.1987 11.292 23.751Z'
            fill='#6F717C'
          />
          <circle cx='16.292' cy='23.751' r='1' fill='#6F717C' />
          <circle cx='22.292' cy='23.751' r='1' fill='#6F717C' />
          <circle cx='28.292' cy='23.751' r='1' fill='#6F717C' />
          <circle cx='34.292' cy='23.751' r='1' fill='#6F717C' />
          <circle cx='40.292' cy='23.751' r='1' fill='#6F717C' />
          <circle cx='46.292' cy='23.751' r='1' fill='#6F717C' />
          <circle cx='52.292' cy='23.751' r='1' fill='#6F717C' />
          <circle cx='58.292' cy='23.751' r='1' fill='#6F717C' />
          <circle cx='64.292' cy='23.751' r='1' fill='#6F717C' />
          <circle cx='70.292' cy='23.751' r='1' fill='#6F717C' />
          <circle cx='76.292' cy='23.751' r='1' fill='#6F717C' />
          <circle cx='82.292' cy='23.751' r='1' fill='#6F717C' />
          <circle cx='88.292' cy='23.751' r='1' fill='#6F717C' />
          <circle cx='94.292' cy='23.751' r='1' fill='#6F717C' />
          <circle cx='100.292' cy='23.751' r='1' fill='#6F717C' />
          <circle cx='106.292' cy='23.751' r='1' fill='#6F717C' />
          <circle cx='112.292' cy='23.751' r='1' fill='#6F717C' />
          <path
            d='M5.29199 50.0859C5.29199 50.6382 4.84428 51.0859 4.29199 51.0859C3.73971 51.0859 3.29199 50.6382 3.29199 50.0859C3.29199 49.5337 3.73971 49.0859 4.29199 49.0859C4.84428 49.0859 5.29199 49.5337 5.29199 50.0859Z'
            fill='#6F717C'
          />
          <path
            d='M11.292 50.0859C11.292 50.6382 10.8443 51.0859 10.292 51.0859C9.73971 51.0859 9.29199 50.6382 9.29199 50.0859C9.29199 49.5337 9.73971 49.0859 10.292 49.0859C10.8443 49.0859 11.292 49.5337 11.292 50.0859Z'
            fill='#6F717C'
          />
          <circle cx='16.292' cy='50.0859' r='1' fill='#6F717C' />
          <circle cx='22.292' cy='50.0859' r='1' fill='#6F717C' />
          <circle cx='28.292' cy='50.0859' r='1' fill='#6F717C' />
          <circle cx='34.292' cy='50.0859' r='1' fill='#6F717C' />
          <circle cx='40.292' cy='50.0859' r='1' fill='#6F717C' />
          <circle cx='46.292' cy='50.0859' r='1' fill='#6F717C' />
          <circle cx='52.292' cy='50.0859' r='1' fill='#6F717C' />
          <circle cx='58.292' cy='50.0859' r='1' fill='#6F717C' />
          <circle cx='64.292' cy='50.0859' r='1' fill='#6F717C' />
          <circle cx='70.292' cy='50.0859' r='1' fill='#6F717C' />
          <circle cx='76.292' cy='50.0859' r='1' fill='#6F717C' />
          <circle cx='82.292' cy='50.0859' r='1' fill='#6F717C' />
          <circle cx='88.292' cy='50.0859' r='1' fill='#6F717C' />
          <circle cx='94.292' cy='50.0859' r='1' fill='#6F717C' />
          <circle cx='100.292' cy='50.0859' r='1' fill='#6F717C' />
          <circle cx='106.292' cy='50.0859' r='1' fill='#6F717C' />
          <circle cx='112.292' cy='50.0859' r='1' fill='#6F717C' />
          <path
            d='M5.29199 76.4199C5.29199 76.9722 4.84428 77.4199 4.29199 77.4199C3.73971 77.4199 3.29199 76.9722 3.29199 76.4199C3.29199 75.8676 3.73971 75.4199 4.29199 75.4199C4.84428 75.4199 5.29199 75.8676 5.29199 76.4199Z'
            fill='#6F717C'
          />
          <path
            d='M11.292 76.4199C11.292 76.9722 10.8443 77.4199 10.292 77.4199C9.73971 77.4199 9.29199 76.9722 9.29199 76.4199C9.29199 75.8676 9.73971 75.4199 10.292 75.4199C10.8443 75.4199 11.292 75.8676 11.292 76.4199Z'
            fill='#6F717C'
          />
          <circle cx='16.292' cy='76.4199' r='1' fill='#6F717C' />
          <circle cx='22.292' cy='76.4199' r='1' fill='#6F717C' />
          <circle cx='28.292' cy='76.4199' r='1' fill='#6F717C' />
          <circle cx='34.292' cy='76.4199' r='1' fill='#6F717C' />
          <circle cx='40.292' cy='76.4199' r='1' fill='#6F717C' />
          <circle cx='46.292' cy='76.4199' r='1' fill='#6F717C' />
          <circle cx='52.292' cy='76.4199' r='1' fill='#6F717C' />
          <circle cx='58.292' cy='76.4199' r='1' fill='#6F717C' />
          <circle cx='64.292' cy='76.4199' r='1' fill='#6F717C' />
          <circle cx='70.292' cy='76.4199' r='1' fill='#6F717C' />
          <circle cx='76.292' cy='76.4199' r='1' fill='#6F717C' />
          <circle cx='82.292' cy='76.4199' r='1' fill='#6F717C' />
          <circle cx='88.292' cy='76.4199' r='1' fill='#6F717C' />
          <circle cx='94.292' cy='76.4199' r='1' fill='#6F717C' />
          <circle cx='100.292' cy='76.4199' r='1' fill='#6F717C' />
          <circle cx='106.292' cy='76.4199' r='1' fill='#6F717C' />
          <circle cx='112.292' cy='76.4199' r='1' fill='#6F717C' />
          <path
            d='M5.29199 102.755C5.29199 103.307 4.84428 103.755 4.29199 103.755C3.73971 103.755 3.29199 103.307 3.29199 102.755C3.29199 102.203 3.73971 101.755 4.29199 101.755C4.84428 101.755 5.29199 102.203 5.29199 102.755Z'
            fill='#6F717C'
          />
          <path
            d='M11.292 102.755C11.292 103.307 10.8443 103.755 10.292 103.755C9.73971 103.755 9.29199 103.307 9.29199 102.755C9.29199 102.203 9.73971 101.755 10.292 101.755C10.8443 101.755 11.292 102.203 11.292 102.755Z'
            fill='#6F717C'
          />
          <circle cx='16.292' cy='102.755' r='1' fill='#6F717C' />
          <circle cx='22.292' cy='102.755' r='1' fill='#6F717C' />
          <circle cx='28.292' cy='102.755' r='1' fill='#6F717C' />
          <circle cx='34.292' cy='102.755' r='1' fill='#6F717C' />
          <circle cx='40.292' cy='102.755' r='1' fill='#6F717C' />
          <circle cx='46.292' cy='102.755' r='1' fill='#6F717C' />
          <circle cx='52.292' cy='102.755' r='1' fill='#6F717C' />
          <circle cx='58.292' cy='102.755' r='1' fill='#6F717C' />
          <circle cx='64.292' cy='102.755' r='1' fill='#6F717C' />
          <circle cx='70.292' cy='102.755' r='1' fill='#6F717C' />
          <circle cx='76.292' cy='102.755' r='1' fill='#6F717C' />
          <circle cx='82.292' cy='102.755' r='1' fill='#6F717C' />
          <circle cx='88.292' cy='102.755' r='1' fill='#6F717C' />
          <circle cx='94.292' cy='102.755' r='1' fill='#6F717C' />
          <circle cx='100.292' cy='102.755' r='1' fill='#6F717C' />
          <circle cx='106.292' cy='102.755' r='1' fill='#6F717C' />
          <circle cx='112.292' cy='102.755' r='1' fill='#6F717C' />
        </g>
        <path
          opacity='0.3'
          d='M136.506 118.394C149.234 128.371 220.64 107.316 245.449 95.3509L237.899 84.9022C217.135 98.2159 179.921 101.081 195.292 89.2839C227.41 64.6336 198.258 62.0272 198.258 53.2191C198.258 38.9758 245.27 26.2102 253 23.0082L247.876 14.0763C235.382 17.0536 204.299 26.1091 179.921 38.5127C149.449 54.0171 125.18 64.8475 102.528 59.9602C79.8764 55.0729 87.1573 28.2325 68.5506 14.0762C53.6652 2.75122 30.5281 -4.58564 -2.9101 0.807221L-11 30.805C-2.73034 30.805 7.60676 20.3564 30.5281 24.7381C60.0876 30.3887 37.5393 59.7917 61.809 81.7002C86.0787 103.609 169.135 65.3085 179.921 77.2739C190.708 89.2393 120.596 105.923 136.506 118.394Z'
          fill='#6F717C'
        />
        <rect
          x='118.22'
          y='96.3076'
          width='4.30156'
          height='22.6923'
          rx='1'
          fill='#9C9DA5'
        />
        <rect
          x='128.257'
          y='85.9336'
          width='4.30156'
          height='22.6923'
          rx='1'
          fill='#9C9DA5'
        />
        <rect
          x='138.294'
          y='70.374'
          width='4.30156'
          height='22.6923'
          rx='1'
          fill='#9C9DA5'
        />
        <rect
          x='148.331'
          y='85.9336'
          width='4.30156'
          height='22.6923'
          rx='1'
          fill='#9C9DA5'
        />
        <rect
          x='158.367'
          y='96.3076'
          width='4.30156'
          height='22.6923'
          rx='1'
          fill='#9C9DA5'
        />
        <rect
          x='168.404'
          y='85.9336'
          width='4.30156'
          height='22.6923'
          rx='1'
          fill='#9C9DA5'
        />
        <rect
          x='178.441'
          y='80.7471'
          width='4.30156'
          height='22.6923'
          rx='1'
          fill='#9C9DA5'
        />
        <rect
          x='188.479'
          y='60'
          width='4.30156'
          height='22.6923'
          rx='1'
          fill='#9C9DA5'
        />
        <path
          d='M86.5 43L88.5367 38.515L93 36.5L88.5367 34.485L86.5 30L84.5067 34.485L80 36.5L84.5067 38.515L86.5 43Z'
          fill='#F4F4F4'
        />
        <path
          d='M160.5 88L161.597 85.585L164 84.5L161.597 83.415L160.5 81L159.427 83.415L157 84.5L159.427 85.585L160.5 88Z'
          fill='#F4F4F4'
        />
        <rect x='228' width='54' height='120' fill='#6F717C' />
        <path d='M0 0L94 120H0V0Z' fill='url(#paint0_linear_4147_27158)' />
        <path
          d='M243.5 18L245.223 14.205L249 12.5L245.223 10.795L243.5 7L241.813 10.795L238 12.5L241.813 14.205L243.5 18Z'
          fill='#9C9DA5'
        />
        <rect
          x='200.22'
          y='45.3076'
          width='4.30156'
          height='22.6923'
          rx='1'
          fill='#9C9DA5'
        />
        <rect
          x='210.257'
          y='34.9336'
          width='4.30156'
          height='22.6923'
          rx='1'
          fill='#9C9DA5'
        />
        <rect
          x='220.294'
          y='19.374'
          width='4.30156'
          height='22.6923'
          rx='1'
          fill='#9C9DA5'
        />
        <rect
          x='230.331'
          y='34.9336'
          width='4.30156'
          height='22.6923'
          rx='1'
          fill='#9C9DA5'
        />
        <rect
          x='240.367'
          y='45.3076'
          width='4.30156'
          height='22.6923'
          rx='1'
          fill='#9C9DA5'
        />
        <rect
          x='250.404'
          y='34.9336'
          width='4.30156'
          height='22.6923'
          rx='1'
          fill='#9C9DA5'
        />
        <rect
          x='260.441'
          y='29.7471'
          width='4.30156'
          height='22.6923'
          rx='1'
          fill='#9C9DA5'
        />
        <rect
          x='270.479'
          y='9'
          width='4.30156'
          height='22.6923'
          rx='1'
          fill='#9C9DA5'
        />
        <circle cx='25.5' cy='110.5' r='1.5' fill='white' />
        <circle cx='9.5' cy='62.5' r='1.5' fill='white' />
        <circle cx='113.5' cy='14.5' r='1.5' fill='white' />
        <circle cx='208.5' cy='24.5' r='1.5' fill='white' />
        <path
          d='M137.768 22.2321C143.602 28.066 144.202 38.2231 140.195 50.1121C136.199 61.9683 127.666 75.3837 115.525 87.525C103.384 99.6662 89.9683 108.2 78.1121 112.195C66.223 116.202 56.066 115.602 50.232 109.768C44.398 103.934 43.798 93.7771 47.8048 81.888C51.8005 70.0318 60.3339 56.6164 72.4751 44.4752C84.6163 32.3339 98.0317 23.8006 109.888 19.8048C121.777 15.798 131.934 16.3981 137.768 22.2321Z'
          stroke='white'
        />
        <path
          d='M50.232 22.2321C44.3981 28.066 43.798 38.2231 47.8048 50.1121C51.8005 61.9683 60.3339 75.3837 72.4751 87.525C84.6164 99.6662 98.0317 108.2 109.888 112.195C121.777 116.202 131.934 115.602 137.768 109.768C143.602 103.934 144.202 93.7771 140.195 81.888C136.2 70.0318 127.666 56.6164 115.525 44.4752C103.384 32.3339 89.9683 23.8006 78.1121 19.8048C66.223 15.798 56.066 16.3981 50.232 22.2321Z'
          stroke='white'
        />
        <circle cx='116.174' cy='18.5581' r='4.12544' fill='white' />
        <circle cx='50.1674' cy='110.348' r='4.12544' fill='white' />
        <circle cx='137.833' cy='110.349' r='2.06272' fill='white' />
        <path
          d='M115.558 35.6559L101.165 22L57 84.5L73.1935 95.0092C79.6579 99.2045 87.3834 101.013 95.0383 100.123C103.96 99.0858 112.078 94.4677 117.528 87.3284L119.485 84.7651C125.476 76.9182 128.069 66.9964 126.683 57.222C125.515 48.9837 121.594 41.383 115.558 35.6559Z'
          fill='url(#paint1_linear_4147_27158)'
        />
        <path
          d='M109.197 29.2522L101.165 22.0342L57 84.7471L67.6923 90.284C73.984 93.542 81.1062 94.8443 88.1437 94.0233C97.7326 92.9047 106.453 87.9318 112.299 80.2489L113.824 78.2447C119.696 70.5269 122.237 60.7809 120.88 51.1786C119.685 42.7235 115.549 34.9599 109.197 29.2522Z'
          fill='#6F717C'
        />
        <path
          d='M113 51.1329C113 72.221 96.407 89.2658 76 89.2658C55.593 89.2658 39 72.221 39 51.1329C39 30.0448 55.593 13 76 13C96.407 13 113 30.0448 113 51.1329Z'
          fill='#242631'
          stroke='#6F717C'
          strokeWidth='2'
        />
        <path
          d='M76 90.7658C97.2767 90.7658 114.5 73.0076 114.5 51.1329C114.5 29.2582 97.2767 11.5 76 11.5C54.7233 11.5 37.5 29.2582 37.5 51.1329C37.5 73.0076 54.7233 90.7658 76 90.7658Z'
          fill='#242631'
          stroke='white'
        />
        <path
          d='M106.608 62.194C106.311 62.2041 106.147 62.3843 106.075 62.652C105.999 62.9366 106.055 63.095 106.312 63.2986C106.454 63.3366 106.597 63.4019 106.757 63.3728L106.799 63.2139C106.749 63.2005 106.676 63.2077 106.663 63.1235C106.651 63.0666 106.638 63.018 106.634 62.9634C106.623 62.9065 106.581 62.8953 106.531 62.8819C106.456 62.8618 106.457 62.9249 106.446 62.9667C106.435 63.0085 106.427 63.0693 106.361 63.0515C106.269 63.0269 106.32 62.9689 106.323 62.9249C106.344 62.8495 106.366 62.7659 106.386 62.6905C106.421 62.5924 106.397 62.4155 106.504 62.4172C106.648 62.4111 106.838 62.4351 106.915 62.6169C106.931 62.6214 106.94 62.6236 106.956 62.6281L107.03 62.3519C107.03 62.3519 106.964 62.2984 106.927 62.2705C106.819 62.2415 106.719 62.2147 106.611 62.1857L106.608 62.194Z'
          fill='white'
        />
        <path
          d='M106.659 62.1723C106.659 62.1723 106.627 62.1907 106.608 62.1946L106.659 62.1723Z'
          fill='white'
        />
        <path
          d='M106.869 62.82C106.78 62.8858 106.718 62.95 106.762 63.0872C106.778 63.1274 106.765 63.1419 106.746 63.1458C106.766 63.1692 106.789 63.1843 106.799 63.2139L106.904 62.8206L106.883 62.8328L106.867 62.8284L106.869 62.82Z'
          fill='white'
        />
        <path
          d='M106.67 62.1305C106.67 62.1305 106.663 62.1556 106.658 62.1723C106.667 62.1745 106.683 62.179 106.692 62.1812C106.767 62.2013 106.838 62.2024 106.908 62.2392C106.956 62.261 107.01 62.2934 107.021 62.3503L107.042 62.275C106.928 62.1996 106.819 62.1076 106.661 62.1282L106.67 62.1305Z'
          fill='white'
        />
        <path
          d='M106.501 63.3942C106.501 63.3942 106.584 63.4165 106.609 63.4589C106.65 63.4701 106.684 63.479 106.725 63.4902L106.757 63.373C106.668 63.4389 106.582 63.4249 106.501 63.3942Z'
          fill='white'
        />
        <path
          d='M106.872 62.8119C106.872 62.8119 106.897 62.8186 106.905 62.8208L106.957 62.6283C106.957 62.6283 106.929 62.63 106.911 62.6339C106.895 62.6925 106.879 62.7511 106.872 62.8119Z'
          fill='white'
        />
        <path
          d='M82.6229 53.5249C82.5976 53.4195 82.5717 53.3498 82.7229 53.3185C83.847 53.0547 84.5892 52.3836 84.9252 51.2628C85.2808 50.1024 84.9556 48.9839 84.0044 48.2357C83.5403 47.8692 83.0091 47.6192 82.4366 47.4569C81.1873 47.1221 79.9462 46.7896 78.6969 46.4548C78.5719 46.4213 78.5235 46.4353 78.4877 46.5692C77.7276 49.406 76.9674 52.2427 76.2051 55.0879C76.167 55.2302 76.2102 55.2687 76.3435 55.3044C76.9099 55.4561 77.4762 55.6079 78.0426 55.7597C78.1925 55.7998 78.2515 55.7798 78.2941 55.6208C78.5189 54.7482 78.7582 53.8886 78.9914 53.0183C79.0318 52.8676 79.0846 52.837 79.224 52.8833C79.48 52.9608 79.742 53.049 80.0069 53.0931C80.2635 53.1349 80.3616 53.2688 80.4199 53.5176C80.6454 54.4749 80.8982 55.4306 81.1532 56.3779C81.1808 56.475 81.166 56.5966 81.3076 56.6345C82.0656 56.8376 82.8152 57.0385 83.6065 57.2505C83.5911 57.1746 83.5834 57.1367 83.5757 57.0988C83.2601 55.911 82.9446 54.7233 82.629 53.5355L82.6229 53.5249ZM81.4991 51.5556C80.9936 51.5098 80.5156 51.3279 80.0219 51.2046C79.8637 51.1622 79.627 51.1795 79.5645 51.0462C79.5142 50.934 79.6202 50.7382 79.6606 50.5876C79.7211 50.3617 79.7839 50.1274 79.8444 49.9014C79.9386 49.55 80.0328 49.1985 80.127 48.847C80.1449 48.7801 80.1523 48.7193 80.2522 48.7461C80.8724 48.9302 81.515 49.0306 82.1151 49.2901C82.7567 49.5606 82.9701 50.0304 82.8058 50.677C82.6443 51.2795 82.1423 51.6203 81.4968 51.564L81.4991 51.5556Z'
          fill='white'
        />
        <path
          d='M50.7848 43.4789C50.8489 43.4064 50.9283 43.4098 50.9956 43.3919C51.2881 43.2999 51.5645 43.1677 51.7984 42.9613C52.7783 42.1027 52.7466 40.4888 51.7307 39.6156C51.2156 39.1727 50.6111 38.93 49.9697 38.7581C48.862 38.4613 47.7542 38.1645 46.6464 37.8676C46.4904 37.8169 46.4436 37.8582 46.4033 38.0088C45.6454 40.8372 44.8898 43.6573 44.1319 46.4857C44.0915 46.6363 44.1159 46.6787 44.2658 46.7189C45.4485 47.0358 46.6313 47.3527 47.8057 47.6674C48.1888 47.7701 48.5726 47.837 48.9615 47.8515C49.911 47.8728 50.7401 47.6106 51.2533 46.7615C51.7619 45.9292 51.899 45.0511 51.39 44.1523C51.2378 43.8873 51.0155 43.6842 50.772 43.4934L50.7848 43.4789ZM47.6357 40.1059C47.6649 39.9971 47.711 39.9915 47.8026 40.0161C48.0858 40.092 48.3773 40.1701 48.7188 40.2616C49.0058 40.3564 49.3775 40.4022 49.6988 40.569C50.1466 40.7966 50.3302 41.1777 50.1985 41.6357C50.0707 42.1127 49.7084 42.3654 49.2173 42.3324C49.0501 42.3235 48.8979 42.2917 48.7397 42.2493C48.2649 42.1221 47.7902 41.9949 47.3154 41.8677C47.2155 41.8409 47.1677 41.8191 47.2014 41.6936C47.3426 41.1664 47.4839 40.6392 47.6251 40.112L47.6357 40.1059ZM48.3643 45.9157C47.6672 45.8186 47.0022 45.5686 46.323 45.4046C46.2398 45.3823 46.2343 45.336 46.2606 45.2713C46.413 44.7022 46.5655 44.1332 46.7157 43.5725C46.7449 43.4637 46.8032 43.4794 46.8782 43.4995C47.1863 43.582 47.4945 43.6646 47.861 43.7628C48.1625 43.8705 48.5635 43.9062 48.9182 44.0819C49.3971 44.3269 49.5906 44.7375 49.4394 45.2351C49.2988 45.7266 48.8987 45.9871 48.3666 45.9073L48.3643 45.9157Z'
          fill='white'
        />
        <path
          d='M74.4281 50.5584C74.3868 50.4128 74.4153 50.3397 74.5466 50.2493C75.2088 49.8438 75.8588 49.417 76.5148 49.0009C76.7519 48.8491 76.9784 48.7035 77.2388 48.5311C76.4892 48.3303 75.7895 48.1428 75.0815 47.9531C75.0066 47.933 74.9582 47.947 74.8925 47.9922C74.0456 48.5545 73.2008 49.1084 72.3561 49.6624C72.2481 49.7321 72.1296 49.808 71.9771 49.9106L72.0466 49.6512C72.4435 48.17 72.8404 46.6889 73.2372 45.2077C73.2753 45.0655 73.2661 45.0002 73.0995 44.9556C72.5558 44.8189 72.0144 44.6738 71.4753 44.5204C71.3254 44.4802 71.2664 44.5003 71.2238 44.6593C70.9189 45.7973 70.6117 46.9438 70.3067 48.0818C69.8583 49.7555 69.4076 51.4375 68.9592 53.1111C68.9143 53.2784 68.9448 53.3314 69.1136 53.3677C69.6655 53.5066 70.213 53.6623 70.7544 53.8074C70.8877 53.8431 70.93 53.8185 70.9575 53.6824C71.0696 53.264 71.1817 52.8456 71.2938 52.4272C71.3275 52.3017 71.3855 52.2186 71.4934 52.1488C71.8106 51.9647 72.1216 51.77 72.4243 51.5731C72.564 51.485 72.5884 51.5274 72.6236 51.6624C72.8504 52.5483 73.0856 53.4365 73.3207 54.3246C73.3499 54.449 73.4031 54.5171 73.5386 54.5444C74.1072 54.6878 74.6714 54.8479 75.2377 54.9997L75.6292 55.1046C75.6238 55.0583 75.6305 55.0332 75.6266 55.0142C75.2306 53.5269 74.8369 52.0312 74.4326 50.5417L74.4281 50.5584Z'
          fill='white'
        />
        <path
          d='M104.029 58.4628C103.991 58.3361 104.017 58.2714 104.125 58.2017C104.682 57.8575 105.232 57.5027 105.782 57.1479C106.127 56.9264 106.47 56.7133 106.849 56.4651C106.1 56.2642 105.4 56.0767 104.7 55.8893C104.617 55.8669 104.562 55.906 104.496 55.9512C103.759 56.4354 103.014 56.9174 102.278 57.4016C102.062 57.5411 101.846 57.6805 101.579 57.8423L101.646 57.5913C102.046 56.1017 102.445 54.6122 102.842 53.131C102.88 52.9888 102.868 52.9319 102.708 52.8978C102.172 52.7634 101.639 52.6205 101.1 52.4671C100.934 52.4225 100.875 52.4426 100.827 52.6183C100.522 53.7921 100.197 54.9697 99.8835 56.1413C99.444 57.7814 99.0023 59.43 98.5628 61.0701C98.5225 61.2207 98.5468 61.2631 98.6967 61.3033C99.257 61.4445 99.8045 61.6001 100.363 61.7497C100.479 61.7809 100.528 61.767 100.561 61.6414C100.645 61.2961 100.759 60.9681 100.835 60.6205C100.899 60.3148 101.028 60.0984 101.324 59.9622C101.621 59.8178 101.888 59.6203 102.189 59.4317C102.206 59.4992 102.222 59.5394 102.23 59.5773C102.471 60.4761 102.704 61.3726 102.943 62.2797C102.959 62.3556 102.953 62.4438 103.061 62.4728C103.786 62.6669 104.502 62.8589 105.227 63.053C105.259 62.9989 105.233 62.9649 105.225 62.927C104.829 61.4396 104.441 59.9545 104.035 58.4734L104.029 58.4628Z'
          fill='white'
        />
        <path
          d='M61.7476 49.8066C61.2404 49.7335 61.1652 49.6147 61.2975 49.121C61.5111 48.2903 61.7831 47.4753 61.9384 46.629C62.0797 45.8686 61.9163 45.179 61.3848 44.5971C60.9027 44.0644 60.2738 43.7793 59.5931 43.5879C58.5314 43.2855 57.4723 43.3066 56.4395 43.7295C56.3194 43.778 56.2726 43.8193 56.3518 43.9571C56.5725 44.366 56.7804 44.7895 56.9823 45.2023C57.0386 45.325 57.0864 45.3468 57.2193 45.2838C57.8139 45.0305 58.4152 44.9853 59.0347 45.2052C59.7853 45.4691 60.0688 46.1101 59.752 46.8593C59.6649 46.818 59.5861 46.779 59.499 46.7377C58.7253 46.36 57.9331 46.0849 57.054 46.1005C55.7082 46.1256 54.8113 47.1409 54.9715 48.4753C55.0711 49.3361 55.6423 49.8031 56.3907 50.0754C57.3095 50.4112 58.2218 50.4046 59.122 49.9103C59.1937 50.3421 59.3969 50.6836 59.7803 50.885C60.282 51.145 60.8179 51.2437 61.3875 51.217C61.4775 51.2142 61.5304 51.1835 61.5573 51.0831C61.6582 50.7065 61.7591 50.3299 61.8622 49.945C61.8936 49.8278 61.8437 49.8145 61.7498 49.7983L61.7476 49.8066ZM59.3936 48.2969C59.2642 48.9798 58.612 49.1817 58.086 49.1125C57.8188 49.0768 57.5628 48.9993 57.3549 48.809C57.1326 48.6059 57.072 48.3655 57.1399 48.0787C57.2184 47.7859 57.3872 47.5889 57.6775 47.5053C57.9088 47.4417 58.1426 47.4685 58.3781 47.5226C58.7279 47.6163 59.0476 47.7558 59.3712 47.9142C59.4583 47.9555 59.4887 48.0085 59.4535 48.1067C59.4272 48.1714 59.4176 48.2406 59.4019 48.2992L59.3936 48.2969Z'
          fill='white'
        />
        <path
          d='M89.4559 51.5802C87.6106 50.8705 85.4361 51.4896 84.65 53.5571C84.2061 54.7476 84.2795 55.9064 85.0515 56.9563C85.7051 57.8489 86.6174 58.3087 87.6983 58.4727C89.5595 58.7562 91.2256 57.6689 91.7016 55.8592C91.9377 54.9448 91.8781 54.0678 91.4139 53.2349C90.9679 52.4338 90.2974 51.9043 89.4475 51.5779L89.4559 51.5802ZM87.1552 56.4348C86.5078 56.0191 86.2656 55.2905 86.4898 54.4537C86.9136 52.8721 88.5006 52.813 89.305 53.5757C89.8544 54.0906 89.8951 54.7383 89.6969 55.4117C89.283 56.7896 87.9869 56.9625 87.1552 56.4348Z'
          fill='white'
        />
        <path
          d='M65.9187 47.3011C66.7782 47.0919 67.6166 47.5946 67.8335 48.4509C67.8665 48.5943 67.9098 48.6328 68.0632 48.5932C68.578 48.471 69.0989 48.3595 69.6198 48.2479C69.7415 48.2267 69.7716 48.181 69.7402 48.0649C69.6598 47.7653 69.5605 47.4697 69.4106 47.1963C68.9076 46.3081 68.0913 45.8562 67.1046 45.5739C66.8881 45.5159 66.6254 45.4634 66.3537 45.4444C65.0999 45.3596 64.0572 45.7529 63.304 46.7978C62.877 47.3919 62.6599 48.0692 62.5737 48.7905C62.4488 49.923 62.7342 50.9233 63.6248 51.6642C64.6247 52.4971 65.8058 52.7867 67.0898 52.5926C67.7401 52.4978 68.2814 52.1765 68.7376 51.7067C68.8379 51.5991 68.8641 51.5344 68.7322 51.4272C68.3839 51.1276 68.0485 50.8135 67.7109 50.5078C67.4885 50.3047 67.4885 50.3047 67.2486 50.5005C66.2978 51.2503 64.8255 50.7482 64.6515 49.5984C64.548 48.9518 64.7095 48.3493 65.108 47.8282C65.3136 47.5605 65.5846 47.3819 65.9271 47.3033L65.9187 47.3011Z'
          fill='white'
        />
        <path
          d='M95.2778 55.3375C96.1723 54.9314 97.224 55.4374 97.4662 56.3992C97.5014 56.5343 97.5341 56.5789 97.6875 56.5393C98.2023 56.4171 98.7232 56.3056 99.2441 56.194C99.3386 56.1745 99.3976 56.1544 99.3684 56.03C99.2402 55.4754 99.0003 54.9717 98.6018 54.5599C97.9095 53.8453 97.0301 53.5289 96.0719 53.4067C95.2599 53.3057 94.4977 53.4513 93.8077 53.8942C93.012 54.3985 92.5757 55.1606 92.3223 56.1062C92.2977 56.1982 92.2656 56.3511 92.2336 56.504C92.2067 56.6044 92.1987 56.7009 92.1884 56.8058C92.0696 58.1821 92.5566 59.2632 93.755 59.988C94.5229 60.4538 95.3815 60.648 96.2755 60.6095C97.0985 60.5699 97.8071 60.2576 98.3786 59.6573C98.4766 59.558 98.4818 59.5056 98.3748 59.4051C98.0288 59.0972 97.685 58.7808 97.3391 58.4729C97.1908 58.3375 97.041 58.3302 96.8896 58.4511C96.0151 59.1495 94.6385 58.7897 94.3303 57.7743C94.1093 57.0334 94.2909 56.3556 94.7557 55.7537C94.8928 55.5752 95.0709 55.4435 95.2756 55.3459L95.2778 55.3375Z'
          fill='white'
        />
        <path
          d='M55.9609 40.3546C55.4028 40.205 54.8364 40.0533 54.2784 39.9037C54.1534 39.8703 54.1111 39.8948 54.0775 40.0203C53.3174 42.8571 52.5595 45.6856 51.7994 48.5224C51.7613 48.6646 51.7728 48.7215 51.9311 48.7639C52.483 48.9028 53.0328 49.0501 53.5719 49.2036C53.7302 49.246 53.7769 49.2047 53.8173 49.0541C54.194 47.6482 54.5729 46.234 54.9496 44.8282C55.3263 43.4223 55.7097 41.9914 56.0887 40.5772C56.1246 40.4433 56.1025 40.3925 55.9692 40.3568L55.9609 40.3546Z'
          fill='white'
        />
        <path
          d='M106.314 63.3265C106.079 63.2367 105.94 62.9572 106.015 62.6453C106.098 62.3357 106.295 62.1644 106.617 62.1611C106.626 62.1633 106.642 62.1678 106.651 62.17C106.655 62.1533 106.641 62.1405 106.662 62.1282C106.32 62.0724 106.059 62.2448 105.963 62.6046C105.864 62.9728 105.993 63.2584 106.326 63.3834C106.326 63.3834 106.33 63.3667 106.332 63.3583C106.316 63.3538 106.31 63.3432 106.304 63.3326L106.314 63.3265Z'
          fill='white'
        />
        <path
          d='M106.32 63.3368L106.337 63.3412C106.331 63.3306 106.319 63.3094 106.312 63.2988C106.308 63.3156 106.306 63.3239 106.304 63.3323C106.304 63.3323 106.304 63.3323 106.312 63.3345L106.32 63.3368Z'
          fill='white'
        />
        <path
          d='M106.349 63.3625C106.349 63.3625 106.349 63.3625 106.346 63.3709C106.346 63.3709 106.336 63.377 106.327 63.3748C106.41 63.4328 106.504 63.449 106.602 63.4484C106.53 63.3843 106.425 63.4099 106.34 63.3603L106.349 63.3625Z'
          fill='white'
        />
        <path
          d='M106.712 62.9756C106.726 62.8897 106.783 62.8422 106.857 62.7993C106.857 62.7993 106.857 62.7993 106.865 62.8015C106.831 62.7295 106.847 62.671 106.912 62.6258C106.657 62.4677 106.494 62.5345 106.421 62.826C106.421 62.826 106.408 62.8763 106.41 62.9036C106.593 62.8182 106.649 62.8422 106.716 63.0576C106.723 63.0325 106.724 62.9968 106.724 62.9611L106.712 62.9756ZM106.594 62.7825C106.498 62.7747 106.501 62.7307 106.515 62.6447C106.539 62.5527 106.573 62.5259 106.671 62.5611C106.746 62.5811 106.814 62.6263 106.792 62.71C106.765 62.8104 106.685 62.8071 106.594 62.7825Z'
          fill='white'
        />
        <path
          d='M106.747 63.1462C106.747 63.1462 106.712 63.1099 106.71 63.0826C106.71 63.0826 106.712 63.0742 106.706 63.0636C106.699 63.0887 106.695 63.1055 106.655 63.1216C106.68 63.1283 106.722 63.1395 106.747 63.1462Z'
          fill='white'
        />
        <path
          d='M106.656 62.1807C106.741 62.2304 106.842 62.2214 106.925 62.2795C106.865 62.2008 106.775 62.2036 106.7 62.1835C106.692 62.1813 106.675 62.1768 106.667 62.1746C106.667 62.1746 106.667 62.1746 106.665 62.1829L106.656 62.1807Z'
          fill='white'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_4147_27158'
          x1='-33.5'
          y1='-10.5'
          x2='141.5'
          y2='191'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#525360' />
          <stop offset='1' stopColor='#242631' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_4147_27158'
          x1='75.4995'
          y1='64'
          x2='135'
          y2='89'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#6F717C' />
          <stop offset='1' stopColor='#6F717C' stopOpacity='0' />
        </linearGradient>
      </defs>
    </svg>
  )
}
