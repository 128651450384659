export const OrdersWhenMarketClosedIllustration = (): JSX.Element => {
  return (
    <svg
      width={204}
      height={204}
      viewBox='0 0 204 204'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_671_26676)'>
        <circle
          cx={102}
          cy={102}
          r={102}
          fill='url(#paint0_linear_671_26676)'
        />
        <g opacity={0.89}>
          <circle cx={5} cy={4} r={1} fill='#303341' />
          <path
            d='M12 4C12 4.55228 11.5523 5 11 5C10.4477 5 10 4.55228 10 4C10 3.44772 10.4477 3 11 3C11.5523 3 12 3.44772 12 4Z'
            fill='#303341'
          />
          <path
            d='M18 4C18 4.55228 17.5523 5 17 5C16.4477 5 16 4.55228 16 4C16 3.44772 16.4477 3 17 3C17.5523 3 18 3.44772 18 4Z'
            fill='#303341'
          />
          <path
            d='M24 4C24 4.55228 23.5523 5 23 5C22.4477 5 22 4.55228 22 4C22 3.44772 22.4477 3 23 3C23.5523 3 24 3.44772 24 4Z'
            fill='#303341'
          />
          <path
            d='M30 4C30 4.55228 29.5523 5 29 5C28.4477 5 28 4.55228 28 4C28 3.44772 28.4477 3 29 3C29.5523 3 30 3.44772 30 4Z'
            fill='#303341'
          />
          <path
            d='M36 4C36 4.55228 35.5523 5 35 5C34.4477 5 34 4.55228 34 4C34 3.44772 34.4477 3 35 3C35.5523 3 36 3.44772 36 4Z'
            fill='#303341'
          />
          <path
            d='M42 4C42 4.55228 41.5523 5 41 5C40.4477 5 40 4.55228 40 4C40 3.44772 40.4477 3 41 3C41.5523 3 42 3.44772 42 4Z'
            fill='#303341'
          />
          <path
            d='M48 4C48 4.55228 47.5523 5 47 5C46.4477 5 46 4.55228 46 4C46 3.44772 46.4477 3 47 3C47.5523 3 48 3.44772 48 4Z'
            fill='#303341'
          />
          <path
            d='M54 4C54 4.55228 53.5523 5 53 5C52.4477 5 52 4.55228 52 4C52 3.44772 52.4477 3 53 3C53.5523 3 54 3.44772 54 4Z'
            fill='#303341'
          />
          <path
            d='M60 4C60 4.55228 59.5523 5 59 5C58.4477 5 58 4.55228 58 4C58 3.44772 58.4477 3 59 3C59.5523 3 60 3.44772 60 4Z'
            fill='#303341'
          />
          <path
            d='M66 4C66 4.55228 65.5523 5 65 5C64.4477 5 64 4.55228 64 4C64 3.44772 64.4477 3 65 3C65.5523 3 66 3.44772 66 4Z'
            fill='#303341'
          />
          <path
            d='M72 4C72 4.55228 71.5523 5 71 5C70.4477 5 70 4.55228 70 4C70 3.44772 70.4477 3 71 3C71.5523 3 72 3.44772 72 4Z'
            fill='#303341'
          />
          <path
            d='M78 4C78 4.55228 77.5523 5 77 5C76.4477 5 76 4.55228 76 4C76 3.44772 76.4477 3 77 3C77.5523 3 78 3.44772 78 4Z'
            fill='#303341'
          />
          <path
            d='M84 4C84 4.55228 83.5523 5 83 5C82.4477 5 82 4.55228 82 4C82 3.44772 82.4477 3 83 3C83.5523 3 84 3.44772 84 4Z'
            fill='#303341'
          />
          <path
            d='M90 4C90 4.55228 89.5523 5 89 5C88.4477 5 88 4.55228 88 4C88 3.44772 88.4477 3 89 3C89.5523 3 90 3.44772 90 4Z'
            fill='#303341'
          />
          <path
            d='M96 4C96 4.55228 95.5523 5 95 5C94.4477 5 94 4.55228 94 4C94 3.44772 94.4477 3 95 3C95.5523 3 96 3.44772 96 4Z'
            fill='#303341'
          />
          <path
            d='M102 4C102 4.55228 101.552 5 101 5C100.448 5 100 4.55228 100 4C100 3.44772 100.448 3 101 3C101.552 3 102 3.44772 102 4Z'
            fill='#303341'
          />
          <path
            d='M108 4C108 4.55228 107.552 5 107 5C106.448 5 106 4.55228 106 4C106 3.44772 106.448 3 107 3C107.552 3 108 3.44772 108 4Z'
            fill='#303341'
          />
          <path
            d='M114 4C114 4.55228 113.552 5 113 5C112.448 5 112 4.55228 112 4C112 3.44772 112.448 3 113 3C113.552 3 114 3.44772 114 4Z'
            fill='#303341'
          />
          <path
            d='M120 4C120 4.55228 119.552 5 119 5C118.448 5 118 4.55228 118 4C118 3.44772 118.448 3 119 3C119.552 3 120 3.44772 120 4Z'
            fill='#303341'
          />
          <path
            d='M126 4C126 4.55228 125.552 5 125 5C124.448 5 124 4.55228 124 4C124 3.44772 124.448 3 125 3C125.552 3 126 3.44772 126 4Z'
            fill='#303341'
          />
          <circle cx={131} cy={4} r={1} fill='#303341' />
          <circle cx={137} cy={4} r={1} fill='#303341' />
          <circle cx={143} cy={4} r={1} fill='#303341' />
          <circle cx={149} cy={4} r={1} fill='#303341' />
          <circle cx={155} cy={4} r={1} fill='#303341' />
          <circle cx={161} cy={4} r={1} fill='#303341' />
          <circle cx={167} cy={4} r={1} fill='#303341' />
          <circle cx={173} cy={4} r={1} fill='#303341' />
          <circle cx={179} cy={4} r={1} fill='#303341' />
          <circle cx={185} cy={4} r={1} fill='#303341' />
          <circle cx={191} cy={4} r={1} fill='#303341' />
          <circle cx={197} cy={4} r={1} fill='#303341' />
          <circle cx={203} cy={4} r={1} fill='#303341' />
          <circle cx={5} cy={124} r={1} fill='#303341' />
          <path
            d='M12 124C12 124.552 11.5523 125 11 125C10.4477 125 10 124.552 10 124C10 123.448 10.4477 123 11 123C11.5523 123 12 123.448 12 124Z'
            fill='#303341'
          />
          <path
            d='M18 124C18 124.552 17.5523 125 17 125C16.4477 125 16 124.552 16 124C16 123.448 16.4477 123 17 123C17.5523 123 18 123.448 18 124Z'
            fill='#303341'
          />
          <path
            d='M24 124C24 124.552 23.5523 125 23 125C22.4477 125 22 124.552 22 124C22 123.448 22.4477 123 23 123C23.5523 123 24 123.448 24 124Z'
            fill='#303341'
          />
          <path
            d='M30 124C30 124.552 29.5523 125 29 125C28.4477 125 28 124.552 28 124C28 123.448 28.4477 123 29 123C29.5523 123 30 123.448 30 124Z'
            fill='#303341'
          />
          <path
            d='M36 124C36 124.552 35.5523 125 35 125C34.4477 125 34 124.552 34 124C34 123.448 34.4477 123 35 123C35.5523 123 36 123.448 36 124Z'
            fill='#303341'
          />
          <path
            d='M42 124C42 124.552 41.5523 125 41 125C40.4477 125 40 124.552 40 124C40 123.448 40.4477 123 41 123C41.5523 123 42 123.448 42 124Z'
            fill='#303341'
          />
          <path
            d='M48 124C48 124.552 47.5523 125 47 125C46.4477 125 46 124.552 46 124C46 123.448 46.4477 123 47 123C47.5523 123 48 123.448 48 124Z'
            fill='#303341'
          />
          <path
            d='M54 124C54 124.552 53.5523 125 53 125C52.4477 125 52 124.552 52 124C52 123.448 52.4477 123 53 123C53.5523 123 54 123.448 54 124Z'
            fill='#303341'
          />
          <path
            d='M60 124C60 124.552 59.5523 125 59 125C58.4477 125 58 124.552 58 124C58 123.448 58.4477 123 59 123C59.5523 123 60 123.448 60 124Z'
            fill='#303341'
          />
          <path
            d='M66 124C66 124.552 65.5523 125 65 125C64.4477 125 64 124.552 64 124C64 123.448 64.4477 123 65 123C65.5523 123 66 123.448 66 124Z'
            fill='#303341'
          />
          <path
            d='M72 124C72 124.552 71.5523 125 71 125C70.4477 125 70 124.552 70 124C70 123.448 70.4477 123 71 123C71.5523 123 72 123.448 72 124Z'
            fill='#303341'
          />
          <path
            d='M78 124C78 124.552 77.5523 125 77 125C76.4477 125 76 124.552 76 124C76 123.448 76.4477 123 77 123C77.5523 123 78 123.448 78 124Z'
            fill='#303341'
          />
          <path
            d='M84 124C84 124.552 83.5523 125 83 125C82.4477 125 82 124.552 82 124C82 123.448 82.4477 123 83 123C83.5523 123 84 123.448 84 124Z'
            fill='#303341'
          />
          <path
            d='M90 124C90 124.552 89.5523 125 89 125C88.4477 125 88 124.552 88 124C88 123.448 88.4477 123 89 123C89.5523 123 90 123.448 90 124Z'
            fill='#303341'
          />
          <path
            d='M96 124C96 124.552 95.5523 125 95 125C94.4477 125 94 124.552 94 124C94 123.448 94.4477 123 95 123C95.5523 123 96 123.448 96 124Z'
            fill='#303341'
          />
          <path
            d='M102 124C102 124.552 101.552 125 101 125C100.448 125 100 124.552 100 124C100 123.448 100.448 123 101 123C101.552 123 102 123.448 102 124Z'
            fill='#303341'
          />
          <path
            d='M108 124C108 124.552 107.552 125 107 125C106.448 125 106 124.552 106 124C106 123.448 106.448 123 107 123C107.552 123 108 123.448 108 124Z'
            fill='#303341'
          />
          <path
            d='M114 124C114 124.552 113.552 125 113 125C112.448 125 112 124.552 112 124C112 123.448 112.448 123 113 123C113.552 123 114 123.448 114 124Z'
            fill='#303341'
          />
          <path
            d='M120 124C120 124.552 119.552 125 119 125C118.448 125 118 124.552 118 124C118 123.448 118.448 123 119 123C119.552 123 120 123.448 120 124Z'
            fill='#303341'
          />
          <path
            d='M126 124C126 124.552 125.552 125 125 125C124.448 125 124 124.552 124 124C124 123.448 124.448 123 125 123C125.552 123 126 123.448 126 124Z'
            fill='#303341'
          />
          <circle cx={131} cy={124} r={1} fill='#303341' />
          <circle cx={137} cy={124} r={1} fill='#303341' />
          <circle cx={143} cy={124} r={1} fill='#303341' />
          <circle cx={149} cy={124} r={1} fill='#303341' />
          <circle cx={155} cy={124} r={1} fill='#303341' />
          <circle cx={161} cy={124} r={1} fill='#303341' />
          <circle cx={167} cy={124} r={1} fill='#303341' />
          <circle cx={173} cy={124} r={1} fill='#303341' />
          <circle cx={179} cy={124} r={1} fill='#303341' />
          <circle cx={185} cy={124} r={1} fill='#303341' />
          <circle cx={191} cy={124} r={1} fill='#303341' />
          <circle cx={197} cy={124} r={1} fill='#303341' />
          <circle cx={203} cy={124} r={1} fill='#303341' />
          <circle cx={5} cy={28} r={1} fill='#303341' />
          <path
            d='M12 28C12 28.5523 11.5523 29 11 29C10.4477 29 10 28.5523 10 28C10 27.4477 10.4477 27 11 27C11.5523 27 12 27.4477 12 28Z'
            fill='#303341'
          />
          <path
            d='M18 28C18 28.5523 17.5523 29 17 29C16.4477 29 16 28.5523 16 28C16 27.4477 16.4477 27 17 27C17.5523 27 18 27.4477 18 28Z'
            fill='#303341'
          />
          <path
            d='M24 28C24 28.5523 23.5523 29 23 29C22.4477 29 22 28.5523 22 28C22 27.4477 22.4477 27 23 27C23.5523 27 24 27.4477 24 28Z'
            fill='#303341'
          />
          <path
            d='M30 28C30 28.5523 29.5523 29 29 29C28.4477 29 28 28.5523 28 28C28 27.4477 28.4477 27 29 27C29.5523 27 30 27.4477 30 28Z'
            fill='#303341'
          />
          <path
            d='M36 28C36 28.5523 35.5523 29 35 29C34.4477 29 34 28.5523 34 28C34 27.4477 34.4477 27 35 27C35.5523 27 36 27.4477 36 28Z'
            fill='#303341'
          />
          <path
            d='M42 28C42 28.5523 41.5523 29 41 29C40.4477 29 40 28.5523 40 28C40 27.4477 40.4477 27 41 27C41.5523 27 42 27.4477 42 28Z'
            fill='#303341'
          />
          <path
            d='M48 28C48 28.5523 47.5523 29 47 29C46.4477 29 46 28.5523 46 28C46 27.4477 46.4477 27 47 27C47.5523 27 48 27.4477 48 28Z'
            fill='#303341'
          />
          <path
            d='M54 28C54 28.5523 53.5523 29 53 29C52.4477 29 52 28.5523 52 28C52 27.4477 52.4477 27 53 27C53.5523 27 54 27.4477 54 28Z'
            fill='#303341'
          />
          <path
            d='M60 28C60 28.5523 59.5523 29 59 29C58.4477 29 58 28.5523 58 28C58 27.4477 58.4477 27 59 27C59.5523 27 60 27.4477 60 28Z'
            fill='#303341'
          />
          <path
            d='M66 28C66 28.5523 65.5523 29 65 29C64.4477 29 64 28.5523 64 28C64 27.4477 64.4477 27 65 27C65.5523 27 66 27.4477 66 28Z'
            fill='#303341'
          />
          <path
            d='M72 28C72 28.5523 71.5523 29 71 29C70.4477 29 70 28.5523 70 28C70 27.4477 70.4477 27 71 27C71.5523 27 72 27.4477 72 28Z'
            fill='#303341'
          />
          <path
            d='M78 28C78 28.5523 77.5523 29 77 29C76.4477 29 76 28.5523 76 28C76 27.4477 76.4477 27 77 27C77.5523 27 78 27.4477 78 28Z'
            fill='#303341'
          />
          <path
            d='M84 28C84 28.5523 83.5523 29 83 29C82.4477 29 82 28.5523 82 28C82 27.4477 82.4477 27 83 27C83.5523 27 84 27.4477 84 28Z'
            fill='#303341'
          />
          <path
            d='M90 28C90 28.5523 89.5523 29 89 29C88.4477 29 88 28.5523 88 28C88 27.4477 88.4477 27 89 27C89.5523 27 90 27.4477 90 28Z'
            fill='#303341'
          />
          <path
            d='M96 28C96 28.5523 95.5523 29 95 29C94.4477 29 94 28.5523 94 28C94 27.4477 94.4477 27 95 27C95.5523 27 96 27.4477 96 28Z'
            fill='#303341'
          />
          <path
            d='M102 28C102 28.5523 101.552 29 101 29C100.448 29 100 28.5523 100 28C100 27.4477 100.448 27 101 27C101.552 27 102 27.4477 102 28Z'
            fill='#303341'
          />
          <path
            d='M108 28C108 28.5523 107.552 29 107 29C106.448 29 106 28.5523 106 28C106 27.4477 106.448 27 107 27C107.552 27 108 27.4477 108 28Z'
            fill='#303341'
          />
          <path
            d='M114 28C114 28.5523 113.552 29 113 29C112.448 29 112 28.5523 112 28C112 27.4477 112.448 27 113 27C113.552 27 114 27.4477 114 28Z'
            fill='#303341'
          />
          <path
            d='M120 28C120 28.5523 119.552 29 119 29C118.448 29 118 28.5523 118 28C118 27.4477 118.448 27 119 27C119.552 27 120 27.4477 120 28Z'
            fill='#303341'
          />
          <path
            d='M126 28C126 28.5523 125.552 29 125 29C124.448 29 124 28.5523 124 28C124 27.4477 124.448 27 125 27C125.552 27 126 27.4477 126 28Z'
            fill='#303341'
          />
          <circle cx={131} cy={28} r={1} fill='#303341' />
          <circle cx={137} cy={28} r={1} fill='#303341' />
          <circle cx={143} cy={28} r={1} fill='#303341' />
          <circle cx={149} cy={28} r={1} fill='#303341' />
          <circle cx={155} cy={28} r={1} fill='#303341' />
          <circle cx={161} cy={28} r={1} fill='#303341' />
          <circle cx={167} cy={28} r={1} fill='#303341' />
          <circle cx={173} cy={28} r={1} fill='#303341' />
          <circle cx={179} cy={28} r={1} fill='#303341' />
          <circle cx={185} cy={28} r={1} fill='#303341' />
          <circle cx={191} cy={28} r={1} fill='#303341' />
          <circle cx={197} cy={28} r={1} fill='#303341' />
          <circle cx={203} cy={28} r={1} fill='#303341' />
          <circle cx={5} cy={148} r={1} fill='#303341' />
          <path
            d='M12 148C12 148.552 11.5523 149 11 149C10.4477 149 10 148.552 10 148C10 147.448 10.4477 147 11 147C11.5523 147 12 147.448 12 148Z'
            fill='#303341'
          />
          <path
            d='M18 148C18 148.552 17.5523 149 17 149C16.4477 149 16 148.552 16 148C16 147.448 16.4477 147 17 147C17.5523 147 18 147.448 18 148Z'
            fill='#303341'
          />
          <path
            d='M24 148C24 148.552 23.5523 149 23 149C22.4477 149 22 148.552 22 148C22 147.448 22.4477 147 23 147C23.5523 147 24 147.448 24 148Z'
            fill='#303341'
          />
          <path
            d='M30 148C30 148.552 29.5523 149 29 149C28.4477 149 28 148.552 28 148C28 147.448 28.4477 147 29 147C29.5523 147 30 147.448 30 148Z'
            fill='#303341'
          />
          <path
            d='M36 148C36 148.552 35.5523 149 35 149C34.4477 149 34 148.552 34 148C34 147.448 34.4477 147 35 147C35.5523 147 36 147.448 36 148Z'
            fill='#303341'
          />
          <path
            d='M42 148C42 148.552 41.5523 149 41 149C40.4477 149 40 148.552 40 148C40 147.448 40.4477 147 41 147C41.5523 147 42 147.448 42 148Z'
            fill='#303341'
          />
          <path
            d='M48 148C48 148.552 47.5523 149 47 149C46.4477 149 46 148.552 46 148C46 147.448 46.4477 147 47 147C47.5523 147 48 147.448 48 148Z'
            fill='#303341'
          />
          <path
            d='M54 148C54 148.552 53.5523 149 53 149C52.4477 149 52 148.552 52 148C52 147.448 52.4477 147 53 147C53.5523 147 54 147.448 54 148Z'
            fill='#303341'
          />
          <path
            d='M60 148C60 148.552 59.5523 149 59 149C58.4477 149 58 148.552 58 148C58 147.448 58.4477 147 59 147C59.5523 147 60 147.448 60 148Z'
            fill='#303341'
          />
          <path
            d='M66 148C66 148.552 65.5523 149 65 149C64.4477 149 64 148.552 64 148C64 147.448 64.4477 147 65 147C65.5523 147 66 147.448 66 148Z'
            fill='#303341'
          />
          <path
            d='M72 148C72 148.552 71.5523 149 71 149C70.4477 149 70 148.552 70 148C70 147.448 70.4477 147 71 147C71.5523 147 72 147.448 72 148Z'
            fill='#303341'
          />
          <path
            d='M78 148C78 148.552 77.5523 149 77 149C76.4477 149 76 148.552 76 148C76 147.448 76.4477 147 77 147C77.5523 147 78 147.448 78 148Z'
            fill='#303341'
          />
          <path
            d='M84 148C84 148.552 83.5523 149 83 149C82.4477 149 82 148.552 82 148C82 147.448 82.4477 147 83 147C83.5523 147 84 147.448 84 148Z'
            fill='#303341'
          />
          <path
            d='M90 148C90 148.552 89.5523 149 89 149C88.4477 149 88 148.552 88 148C88 147.448 88.4477 147 89 147C89.5523 147 90 147.448 90 148Z'
            fill='#303341'
          />
          <path
            d='M96 148C96 148.552 95.5523 149 95 149C94.4477 149 94 148.552 94 148C94 147.448 94.4477 147 95 147C95.5523 147 96 147.448 96 148Z'
            fill='#303341'
          />
          <path
            d='M102 148C102 148.552 101.552 149 101 149C100.448 149 100 148.552 100 148C100 147.448 100.448 147 101 147C101.552 147 102 147.448 102 148Z'
            fill='#303341'
          />
          <path
            d='M108 148C108 148.552 107.552 149 107 149C106.448 149 106 148.552 106 148C106 147.448 106.448 147 107 147C107.552 147 108 147.448 108 148Z'
            fill='#303341'
          />
          <path
            d='M114 148C114 148.552 113.552 149 113 149C112.448 149 112 148.552 112 148C112 147.448 112.448 147 113 147C113.552 147 114 147.448 114 148Z'
            fill='#303341'
          />
          <path
            d='M120 148C120 148.552 119.552 149 119 149C118.448 149 118 148.552 118 148C118 147.448 118.448 147 119 147C119.552 147 120 147.448 120 148Z'
            fill='#303341'
          />
          <path
            d='M126 148C126 148.552 125.552 149 125 149C124.448 149 124 148.552 124 148C124 147.448 124.448 147 125 147C125.552 147 126 147.448 126 148Z'
            fill='#303341'
          />
          <circle cx={131} cy={148} r={1} fill='#303341' />
          <circle cx={137} cy={148} r={1} fill='#303341' />
          <circle cx={143} cy={148} r={1} fill='#303341' />
          <circle cx={149} cy={148} r={1} fill='#303341' />
          <circle cx={155} cy={148} r={1} fill='#303341' />
          <circle cx={161} cy={148} r={1} fill='#303341' />
          <circle cx={167} cy={148} r={1} fill='#303341' />
          <circle cx={173} cy={148} r={1} fill='#303341' />
          <circle cx={179} cy={148} r={1} fill='#303341' />
          <circle cx={185} cy={148} r={1} fill='#303341' />
          <circle cx={191} cy={148} r={1} fill='#303341' />
          <circle cx={197} cy={148} r={1} fill='#303341' />
          <circle cx={203} cy={148} r={1} fill='#303341' />
          <circle cx={5} cy={52} r={1} fill='#303341' />
          <path
            d='M12 52C12 52.5523 11.5523 53 11 53C10.4477 53 10 52.5523 10 52C10 51.4477 10.4477 51 11 51C11.5523 51 12 51.4477 12 52Z'
            fill='#303341'
          />
          <path
            d='M18 52C18 52.5523 17.5523 53 17 53C16.4477 53 16 52.5523 16 52C16 51.4477 16.4477 51 17 51C17.5523 51 18 51.4477 18 52Z'
            fill='#303341'
          />
          <path
            d='M24 52C24 52.5523 23.5523 53 23 53C22.4477 53 22 52.5523 22 52C22 51.4477 22.4477 51 23 51C23.5523 51 24 51.4477 24 52Z'
            fill='#303341'
          />
          <path
            d='M30 52C30 52.5523 29.5523 53 29 53C28.4477 53 28 52.5523 28 52C28 51.4477 28.4477 51 29 51C29.5523 51 30 51.4477 30 52Z'
            fill='#303341'
          />
          <path
            d='M36 52C36 52.5523 35.5523 53 35 53C34.4477 53 34 52.5523 34 52C34 51.4477 34.4477 51 35 51C35.5523 51 36 51.4477 36 52Z'
            fill='#303341'
          />
          <path
            d='M42 52C42 52.5523 41.5523 53 41 53C40.4477 53 40 52.5523 40 52C40 51.4477 40.4477 51 41 51C41.5523 51 42 51.4477 42 52Z'
            fill='#303341'
          />
          <path
            d='M48 52C48 52.5523 47.5523 53 47 53C46.4477 53 46 52.5523 46 52C46 51.4477 46.4477 51 47 51C47.5523 51 48 51.4477 48 52Z'
            fill='#303341'
          />
          <path
            d='M54 52C54 52.5523 53.5523 53 53 53C52.4477 53 52 52.5523 52 52C52 51.4477 52.4477 51 53 51C53.5523 51 54 51.4477 54 52Z'
            fill='#303341'
          />
          <path
            d='M60 52C60 52.5523 59.5523 53 59 53C58.4477 53 58 52.5523 58 52C58 51.4477 58.4477 51 59 51C59.5523 51 60 51.4477 60 52Z'
            fill='#303341'
          />
          <path
            d='M66 52C66 52.5523 65.5523 53 65 53C64.4477 53 64 52.5523 64 52C64 51.4477 64.4477 51 65 51C65.5523 51 66 51.4477 66 52Z'
            fill='#303341'
          />
          <path
            d='M72 52C72 52.5523 71.5523 53 71 53C70.4477 53 70 52.5523 70 52C70 51.4477 70.4477 51 71 51C71.5523 51 72 51.4477 72 52Z'
            fill='#303341'
          />
          <path
            d='M78 52C78 52.5523 77.5523 53 77 53C76.4477 53 76 52.5523 76 52C76 51.4477 76.4477 51 77 51C77.5523 51 78 51.4477 78 52Z'
            fill='#303341'
          />
          <path
            d='M84 52C84 52.5523 83.5523 53 83 53C82.4477 53 82 52.5523 82 52C82 51.4477 82.4477 51 83 51C83.5523 51 84 51.4477 84 52Z'
            fill='#303341'
          />
          <path
            d='M90 52C90 52.5523 89.5523 53 89 53C88.4477 53 88 52.5523 88 52C88 51.4477 88.4477 51 89 51C89.5523 51 90 51.4477 90 52Z'
            fill='#303341'
          />
          <path
            d='M96 52C96 52.5523 95.5523 53 95 53C94.4477 53 94 52.5523 94 52C94 51.4477 94.4477 51 95 51C95.5523 51 96 51.4477 96 52Z'
            fill='#303341'
          />
          <path
            d='M102 52C102 52.5523 101.552 53 101 53C100.448 53 100 52.5523 100 52C100 51.4477 100.448 51 101 51C101.552 51 102 51.4477 102 52Z'
            fill='#303341'
          />
          <path
            d='M108 52C108 52.5523 107.552 53 107 53C106.448 53 106 52.5523 106 52C106 51.4477 106.448 51 107 51C107.552 51 108 51.4477 108 52Z'
            fill='#303341'
          />
          <path
            d='M114 52C114 52.5523 113.552 53 113 53C112.448 53 112 52.5523 112 52C112 51.4477 112.448 51 113 51C113.552 51 114 51.4477 114 52Z'
            fill='#303341'
          />
          <path
            d='M120 52C120 52.5523 119.552 53 119 53C118.448 53 118 52.5523 118 52C118 51.4477 118.448 51 119 51C119.552 51 120 51.4477 120 52Z'
            fill='#303341'
          />
          <path
            d='M126 52C126 52.5523 125.552 53 125 53C124.448 53 124 52.5523 124 52C124 51.4477 124.448 51 125 51C125.552 51 126 51.4477 126 52Z'
            fill='#303341'
          />
          <circle cx={131} cy={52} r={1} fill='#303341' />
          <circle cx={137} cy={52} r={1} fill='#303341' />
          <circle cx={143} cy={52} r={1} fill='#303341' />
          <circle cx={149} cy={52} r={1} fill='#303341' />
          <circle cx={155} cy={52} r={1} fill='#303341' />
          <circle cx={161} cy={52} r={1} fill='#303341' />
          <circle cx={167} cy={52} r={1} fill='#303341' />
          <circle cx={173} cy={52} r={1} fill='#303341' />
          <circle cx={179} cy={52} r={1} fill='#303341' />
          <circle cx={185} cy={52} r={1} fill='#303341' />
          <circle cx={191} cy={52} r={1} fill='#303341' />
          <circle cx={197} cy={52} r={1} fill='#303341' />
          <circle cx={203} cy={52} r={1} fill='#303341' />
          <circle cx={5} cy={172} r={1} fill='#303341' />
          <path
            d='M12 172C12 172.552 11.5523 173 11 173C10.4477 173 10 172.552 10 172C10 171.448 10.4477 171 11 171C11.5523 171 12 171.448 12 172Z'
            fill='#303341'
          />
          <path
            d='M18 172C18 172.552 17.5523 173 17 173C16.4477 173 16 172.552 16 172C16 171.448 16.4477 171 17 171C17.5523 171 18 171.448 18 172Z'
            fill='#303341'
          />
          <path
            d='M24 172C24 172.552 23.5523 173 23 173C22.4477 173 22 172.552 22 172C22 171.448 22.4477 171 23 171C23.5523 171 24 171.448 24 172Z'
            fill='#303341'
          />
          <path
            d='M30 172C30 172.552 29.5523 173 29 173C28.4477 173 28 172.552 28 172C28 171.448 28.4477 171 29 171C29.5523 171 30 171.448 30 172Z'
            fill='#303341'
          />
          <path
            d='M36 172C36 172.552 35.5523 173 35 173C34.4477 173 34 172.552 34 172C34 171.448 34.4477 171 35 171C35.5523 171 36 171.448 36 172Z'
            fill='#303341'
          />
          <path
            d='M42 172C42 172.552 41.5523 173 41 173C40.4477 173 40 172.552 40 172C40 171.448 40.4477 171 41 171C41.5523 171 42 171.448 42 172Z'
            fill='#303341'
          />
          <path
            d='M48 172C48 172.552 47.5523 173 47 173C46.4477 173 46 172.552 46 172C46 171.448 46.4477 171 47 171C47.5523 171 48 171.448 48 172Z'
            fill='#303341'
          />
          <path
            d='M54 172C54 172.552 53.5523 173 53 173C52.4477 173 52 172.552 52 172C52 171.448 52.4477 171 53 171C53.5523 171 54 171.448 54 172Z'
            fill='#303341'
          />
          <path
            d='M60 172C60 172.552 59.5523 173 59 173C58.4477 173 58 172.552 58 172C58 171.448 58.4477 171 59 171C59.5523 171 60 171.448 60 172Z'
            fill='#303341'
          />
          <path
            d='M66 172C66 172.552 65.5523 173 65 173C64.4477 173 64 172.552 64 172C64 171.448 64.4477 171 65 171C65.5523 171 66 171.448 66 172Z'
            fill='#303341'
          />
          <path
            d='M72 172C72 172.552 71.5523 173 71 173C70.4477 173 70 172.552 70 172C70 171.448 70.4477 171 71 171C71.5523 171 72 171.448 72 172Z'
            fill='#303341'
          />
          <path
            d='M78 172C78 172.552 77.5523 173 77 173C76.4477 173 76 172.552 76 172C76 171.448 76.4477 171 77 171C77.5523 171 78 171.448 78 172Z'
            fill='#303341'
          />
          <path
            d='M84 172C84 172.552 83.5523 173 83 173C82.4477 173 82 172.552 82 172C82 171.448 82.4477 171 83 171C83.5523 171 84 171.448 84 172Z'
            fill='#303341'
          />
          <path
            d='M90 172C90 172.552 89.5523 173 89 173C88.4477 173 88 172.552 88 172C88 171.448 88.4477 171 89 171C89.5523 171 90 171.448 90 172Z'
            fill='#303341'
          />
          <path
            d='M96 172C96 172.552 95.5523 173 95 173C94.4477 173 94 172.552 94 172C94 171.448 94.4477 171 95 171C95.5523 171 96 171.448 96 172Z'
            fill='#303341'
          />
          <path
            d='M102 172C102 172.552 101.552 173 101 173C100.448 173 100 172.552 100 172C100 171.448 100.448 171 101 171C101.552 171 102 171.448 102 172Z'
            fill='#303341'
          />
          <path
            d='M108 172C108 172.552 107.552 173 107 173C106.448 173 106 172.552 106 172C106 171.448 106.448 171 107 171C107.552 171 108 171.448 108 172Z'
            fill='#303341'
          />
          <path
            d='M114 172C114 172.552 113.552 173 113 173C112.448 173 112 172.552 112 172C112 171.448 112.448 171 113 171C113.552 171 114 171.448 114 172Z'
            fill='#303341'
          />
          <path
            d='M120 172C120 172.552 119.552 173 119 173C118.448 173 118 172.552 118 172C118 171.448 118.448 171 119 171C119.552 171 120 171.448 120 172Z'
            fill='#303341'
          />
          <path
            d='M126 172C126 172.552 125.552 173 125 173C124.448 173 124 172.552 124 172C124 171.448 124.448 171 125 171C125.552 171 126 171.448 126 172Z'
            fill='#303341'
          />
          <circle cx={131} cy={172} r={1} fill='#303341' />
          <circle cx={137} cy={172} r={1} fill='#303341' />
          <circle cx={143} cy={172} r={1} fill='#303341' />
          <circle cx={149} cy={172} r={1} fill='#303341' />
          <circle cx={155} cy={172} r={1} fill='#303341' />
          <circle cx={161} cy={172} r={1} fill='#303341' />
          <circle cx={167} cy={172} r={1} fill='#303341' />
          <circle cx={173} cy={172} r={1} fill='#303341' />
          <circle cx={179} cy={172} r={1} fill='#303341' />
          <circle cx={185} cy={172} r={1} fill='#303341' />
          <circle cx={191} cy={172} r={1} fill='#303341' />
          <circle cx={197} cy={172} r={1} fill='#303341' />
          <circle cx={203} cy={172} r={1} fill='#303341' />
          <circle cx={5} cy={76} r={1} fill='#303341' />
          <path
            d='M12 76C12 76.5523 11.5523 77 11 77C10.4477 77 10 76.5523 10 76C10 75.4477 10.4477 75 11 75C11.5523 75 12 75.4477 12 76Z'
            fill='#303341'
          />
          <path
            d='M18 76C18 76.5523 17.5523 77 17 77C16.4477 77 16 76.5523 16 76C16 75.4477 16.4477 75 17 75C17.5523 75 18 75.4477 18 76Z'
            fill='#303341'
          />
          <path
            d='M24 76C24 76.5523 23.5523 77 23 77C22.4477 77 22 76.5523 22 76C22 75.4477 22.4477 75 23 75C23.5523 75 24 75.4477 24 76Z'
            fill='#303341'
          />
          <path
            d='M30 76C30 76.5523 29.5523 77 29 77C28.4477 77 28 76.5523 28 76C28 75.4477 28.4477 75 29 75C29.5523 75 30 75.4477 30 76Z'
            fill='#303341'
          />
          <path
            d='M36 76C36 76.5523 35.5523 77 35 77C34.4477 77 34 76.5523 34 76C34 75.4477 34.4477 75 35 75C35.5523 75 36 75.4477 36 76Z'
            fill='#303341'
          />
          <path
            d='M42 76C42 76.5523 41.5523 77 41 77C40.4477 77 40 76.5523 40 76C40 75.4477 40.4477 75 41 75C41.5523 75 42 75.4477 42 76Z'
            fill='#303341'
          />
          <path
            d='M48 76C48 76.5523 47.5523 77 47 77C46.4477 77 46 76.5523 46 76C46 75.4477 46.4477 75 47 75C47.5523 75 48 75.4477 48 76Z'
            fill='#303341'
          />
          <path
            d='M54 76C54 76.5523 53.5523 77 53 77C52.4477 77 52 76.5523 52 76C52 75.4477 52.4477 75 53 75C53.5523 75 54 75.4477 54 76Z'
            fill='#303341'
          />
          <path
            d='M60 76C60 76.5523 59.5523 77 59 77C58.4477 77 58 76.5523 58 76C58 75.4477 58.4477 75 59 75C59.5523 75 60 75.4477 60 76Z'
            fill='#303341'
          />
          <path
            d='M66 76C66 76.5523 65.5523 77 65 77C64.4477 77 64 76.5523 64 76C64 75.4477 64.4477 75 65 75C65.5523 75 66 75.4477 66 76Z'
            fill='#303341'
          />
          <path
            d='M72 76C72 76.5523 71.5523 77 71 77C70.4477 77 70 76.5523 70 76C70 75.4477 70.4477 75 71 75C71.5523 75 72 75.4477 72 76Z'
            fill='#303341'
          />
          <path
            d='M78 76C78 76.5523 77.5523 77 77 77C76.4477 77 76 76.5523 76 76C76 75.4477 76.4477 75 77 75C77.5523 75 78 75.4477 78 76Z'
            fill='#303341'
          />
          <path
            d='M84 76C84 76.5523 83.5523 77 83 77C82.4477 77 82 76.5523 82 76C82 75.4477 82.4477 75 83 75C83.5523 75 84 75.4477 84 76Z'
            fill='#303341'
          />
          <path
            d='M90 76C90 76.5523 89.5523 77 89 77C88.4477 77 88 76.5523 88 76C88 75.4477 88.4477 75 89 75C89.5523 75 90 75.4477 90 76Z'
            fill='#303341'
          />
          <path
            d='M96 76C96 76.5523 95.5523 77 95 77C94.4477 77 94 76.5523 94 76C94 75.4477 94.4477 75 95 75C95.5523 75 96 75.4477 96 76Z'
            fill='#303341'
          />
          <path
            d='M102 76C102 76.5523 101.552 77 101 77C100.448 77 100 76.5523 100 76C100 75.4477 100.448 75 101 75C101.552 75 102 75.4477 102 76Z'
            fill='#303341'
          />
          <path
            d='M108 76C108 76.5523 107.552 77 107 77C106.448 77 106 76.5523 106 76C106 75.4477 106.448 75 107 75C107.552 75 108 75.4477 108 76Z'
            fill='#303341'
          />
          <path
            d='M114 76C114 76.5523 113.552 77 113 77C112.448 77 112 76.5523 112 76C112 75.4477 112.448 75 113 75C113.552 75 114 75.4477 114 76Z'
            fill='#303341'
          />
          <path
            d='M120 76C120 76.5523 119.552 77 119 77C118.448 77 118 76.5523 118 76C118 75.4477 118.448 75 119 75C119.552 75 120 75.4477 120 76Z'
            fill='#303341'
          />
          <path
            d='M126 76C126 76.5523 125.552 77 125 77C124.448 77 124 76.5523 124 76C124 75.4477 124.448 75 125 75C125.552 75 126 75.4477 126 76Z'
            fill='#303341'
          />
          <circle cx={131} cy={76} r={1} fill='#303341' />
          <circle cx={137} cy={76} r={1} fill='#303341' />
          <circle cx={143} cy={76} r={1} fill='#303341' />
          <circle cx={149} cy={76} r={1} fill='#303341' />
          <circle cx={155} cy={76} r={1} fill='#303341' />
          <circle cx={161} cy={76} r={1} fill='#303341' />
          <circle cx={167} cy={76} r={1} fill='#303341' />
          <circle cx={173} cy={76} r={1} fill='#303341' />
          <circle cx={179} cy={76} r={1} fill='#303341' />
          <circle cx={185} cy={76} r={1} fill='#303341' />
          <circle cx={191} cy={76} r={1} fill='#303341' />
          <circle cx={197} cy={76} r={1} fill='#303341' />
          <circle cx={203} cy={76} r={1} fill='#303341' />
          <circle cx={5} cy={196} r={1} fill='#303341' />
          <path
            d='M12 196C12 196.552 11.5523 197 11 197C10.4477 197 10 196.552 10 196C10 195.448 10.4477 195 11 195C11.5523 195 12 195.448 12 196Z'
            fill='#303341'
          />
          <path
            d='M18 196C18 196.552 17.5523 197 17 197C16.4477 197 16 196.552 16 196C16 195.448 16.4477 195 17 195C17.5523 195 18 195.448 18 196Z'
            fill='#303341'
          />
          <path
            d='M24 196C24 196.552 23.5523 197 23 197C22.4477 197 22 196.552 22 196C22 195.448 22.4477 195 23 195C23.5523 195 24 195.448 24 196Z'
            fill='#303341'
          />
          <path
            d='M30 196C30 196.552 29.5523 197 29 197C28.4477 197 28 196.552 28 196C28 195.448 28.4477 195 29 195C29.5523 195 30 195.448 30 196Z'
            fill='#303341'
          />
          <path
            d='M36 196C36 196.552 35.5523 197 35 197C34.4477 197 34 196.552 34 196C34 195.448 34.4477 195 35 195C35.5523 195 36 195.448 36 196Z'
            fill='#303341'
          />
          <path
            d='M42 196C42 196.552 41.5523 197 41 197C40.4477 197 40 196.552 40 196C40 195.448 40.4477 195 41 195C41.5523 195 42 195.448 42 196Z'
            fill='#303341'
          />
          <path
            d='M48 196C48 196.552 47.5523 197 47 197C46.4477 197 46 196.552 46 196C46 195.448 46.4477 195 47 195C47.5523 195 48 195.448 48 196Z'
            fill='#303341'
          />
          <path
            d='M54 196C54 196.552 53.5523 197 53 197C52.4477 197 52 196.552 52 196C52 195.448 52.4477 195 53 195C53.5523 195 54 195.448 54 196Z'
            fill='#303341'
          />
          <path
            d='M60 196C60 196.552 59.5523 197 59 197C58.4477 197 58 196.552 58 196C58 195.448 58.4477 195 59 195C59.5523 195 60 195.448 60 196Z'
            fill='#303341'
          />
          <path
            d='M66 196C66 196.552 65.5523 197 65 197C64.4477 197 64 196.552 64 196C64 195.448 64.4477 195 65 195C65.5523 195 66 195.448 66 196Z'
            fill='#303341'
          />
          <path
            d='M72 196C72 196.552 71.5523 197 71 197C70.4477 197 70 196.552 70 196C70 195.448 70.4477 195 71 195C71.5523 195 72 195.448 72 196Z'
            fill='#303341'
          />
          <path
            d='M78 196C78 196.552 77.5523 197 77 197C76.4477 197 76 196.552 76 196C76 195.448 76.4477 195 77 195C77.5523 195 78 195.448 78 196Z'
            fill='#303341'
          />
          <path
            d='M84 196C84 196.552 83.5523 197 83 197C82.4477 197 82 196.552 82 196C82 195.448 82.4477 195 83 195C83.5523 195 84 195.448 84 196Z'
            fill='#303341'
          />
          <path
            d='M90 196C90 196.552 89.5523 197 89 197C88.4477 197 88 196.552 88 196C88 195.448 88.4477 195 89 195C89.5523 195 90 195.448 90 196Z'
            fill='#303341'
          />
          <path
            d='M96 196C96 196.552 95.5523 197 95 197C94.4477 197 94 196.552 94 196C94 195.448 94.4477 195 95 195C95.5523 195 96 195.448 96 196Z'
            fill='#303341'
          />
          <path
            d='M102 196C102 196.552 101.552 197 101 197C100.448 197 100 196.552 100 196C100 195.448 100.448 195 101 195C101.552 195 102 195.448 102 196Z'
            fill='#303341'
          />
          <path
            d='M108 196C108 196.552 107.552 197 107 197C106.448 197 106 196.552 106 196C106 195.448 106.448 195 107 195C107.552 195 108 195.448 108 196Z'
            fill='#303341'
          />
          <path
            d='M114 196C114 196.552 113.552 197 113 197C112.448 197 112 196.552 112 196C112 195.448 112.448 195 113 195C113.552 195 114 195.448 114 196Z'
            fill='#303341'
          />
          <path
            d='M120 196C120 196.552 119.552 197 119 197C118.448 197 118 196.552 118 196C118 195.448 118.448 195 119 195C119.552 195 120 195.448 120 196Z'
            fill='#303341'
          />
          <path
            d='M126 196C126 196.552 125.552 197 125 197C124.448 197 124 196.552 124 196C124 195.448 124.448 195 125 195C125.552 195 126 195.448 126 196Z'
            fill='#303341'
          />
          <circle cx={131} cy={196} r={1} fill='#303341' />
          <circle cx={137} cy={196} r={1} fill='#303341' />
          <circle cx={143} cy={196} r={1} fill='#303341' />
          <circle cx={149} cy={196} r={1} fill='#303341' />
          <circle cx={155} cy={196} r={1} fill='#303341' />
          <circle cx={161} cy={196} r={1} fill='#303341' />
          <circle cx={167} cy={196} r={1} fill='#303341' />
          <circle cx={173} cy={196} r={1} fill='#303341' />
          <circle cx={179} cy={196} r={1} fill='#303341' />
          <circle cx={185} cy={196} r={1} fill='#303341' />
          <circle cx={191} cy={196} r={1} fill='#303341' />
          <circle cx={197} cy={196} r={1} fill='#303341' />
          <circle cx={203} cy={196} r={1} fill='#303341' />
          <circle cx={5} cy={100} r={1} fill='#303341' />
          <path
            d='M12 100C12 100.552 11.5523 101 11 101C10.4477 101 10 100.552 10 100C10 99.4477 10.4477 99 11 99C11.5523 99 12 99.4477 12 100Z'
            fill='#303341'
          />
          <path
            d='M18 100C18 100.552 17.5523 101 17 101C16.4477 101 16 100.552 16 100C16 99.4477 16.4477 99 17 99C17.5523 99 18 99.4477 18 100Z'
            fill='#303341'
          />
          <path
            d='M24 100C24 100.552 23.5523 101 23 101C22.4477 101 22 100.552 22 100C22 99.4477 22.4477 99 23 99C23.5523 99 24 99.4477 24 100Z'
            fill='#303341'
          />
          <path
            d='M30 100C30 100.552 29.5523 101 29 101C28.4477 101 28 100.552 28 100C28 99.4477 28.4477 99 29 99C29.5523 99 30 99.4477 30 100Z'
            fill='#303341'
          />
          <path
            d='M36 100C36 100.552 35.5523 101 35 101C34.4477 101 34 100.552 34 100C34 99.4477 34.4477 99 35 99C35.5523 99 36 99.4477 36 100Z'
            fill='#303341'
          />
          <path
            d='M42 100C42 100.552 41.5523 101 41 101C40.4477 101 40 100.552 40 100C40 99.4477 40.4477 99 41 99C41.5523 99 42 99.4477 42 100Z'
            fill='#303341'
          />
          <path
            d='M48 100C48 100.552 47.5523 101 47 101C46.4477 101 46 100.552 46 100C46 99.4477 46.4477 99 47 99C47.5523 99 48 99.4477 48 100Z'
            fill='#303341'
          />
          <path
            d='M54 100C54 100.552 53.5523 101 53 101C52.4477 101 52 100.552 52 100C52 99.4477 52.4477 99 53 99C53.5523 99 54 99.4477 54 100Z'
            fill='#303341'
          />
          <path
            d='M60 100C60 100.552 59.5523 101 59 101C58.4477 101 58 100.552 58 100C58 99.4477 58.4477 99 59 99C59.5523 99 60 99.4477 60 100Z'
            fill='#303341'
          />
          <path
            d='M66 100C66 100.552 65.5523 101 65 101C64.4477 101 64 100.552 64 100C64 99.4477 64.4477 99 65 99C65.5523 99 66 99.4477 66 100Z'
            fill='#303341'
          />
          <path
            d='M72 100C72 100.552 71.5523 101 71 101C70.4477 101 70 100.552 70 100C70 99.4477 70.4477 99 71 99C71.5523 99 72 99.4477 72 100Z'
            fill='#303341'
          />
          <path
            d='M78 100C78 100.552 77.5523 101 77 101C76.4477 101 76 100.552 76 100C76 99.4477 76.4477 99 77 99C77.5523 99 78 99.4477 78 100Z'
            fill='#303341'
          />
          <path
            d='M84 100C84 100.552 83.5523 101 83 101C82.4477 101 82 100.552 82 100C82 99.4477 82.4477 99 83 99C83.5523 99 84 99.4477 84 100Z'
            fill='#303341'
          />
          <path
            d='M90 100C90 100.552 89.5523 101 89 101C88.4477 101 88 100.552 88 100C88 99.4477 88.4477 99 89 99C89.5523 99 90 99.4477 90 100Z'
            fill='#303341'
          />
          <path
            d='M96 100C96 100.552 95.5523 101 95 101C94.4477 101 94 100.552 94 100C94 99.4477 94.4477 99 95 99C95.5523 99 96 99.4477 96 100Z'
            fill='#303341'
          />
          <path
            d='M102 100C102 100.552 101.552 101 101 101C100.448 101 100 100.552 100 100C100 99.4477 100.448 99 101 99C101.552 99 102 99.4477 102 100Z'
            fill='#303341'
          />
          <path
            d='M108 100C108 100.552 107.552 101 107 101C106.448 101 106 100.552 106 100C106 99.4477 106.448 99 107 99C107.552 99 108 99.4477 108 100Z'
            fill='#303341'
          />
          <path
            d='M114 100C114 100.552 113.552 101 113 101C112.448 101 112 100.552 112 100C112 99.4477 112.448 99 113 99C113.552 99 114 99.4477 114 100Z'
            fill='#303341'
          />
          <path
            d='M120 100C120 100.552 119.552 101 119 101C118.448 101 118 100.552 118 100C118 99.4477 118.448 99 119 99C119.552 99 120 99.4477 120 100Z'
            fill='#303341'
          />
          <path
            d='M126 100C126 100.552 125.552 101 125 101C124.448 101 124 100.552 124 100C124 99.4477 124.448 99 125 99C125.552 99 126 99.4477 126 100Z'
            fill='#303341'
          />
          <circle cx={131} cy={100} r={1} fill='#303341' />
          <circle cx={137} cy={100} r={1} fill='#303341' />
          <circle cx={143} cy={100} r={1} fill='#303341' />
          <circle cx={149} cy={100} r={1} fill='#303341' />
          <circle cx={155} cy={100} r={1} fill='#303341' />
          <circle cx={161} cy={100} r={1} fill='#303341' />
          <circle cx={167} cy={100} r={1} fill='#303341' />
          <circle cx={173} cy={100} r={1} fill='#303341' />
          <circle cx={179} cy={100} r={1} fill='#303341' />
          <circle cx={185} cy={100} r={1} fill='#303341' />
          <circle cx={191} cy={100} r={1} fill='#303341' />
          <circle cx={197} cy={100} r={1} fill='#303341' />
          <circle cx={203} cy={100} r={1} fill='#303341' />
          <circle cx={5} cy={16} r={1} fill='#303341' />
          <path
            d='M12 16C12 16.5523 11.5523 17 11 17C10.4477 17 10 16.5523 10 16C10 15.4477 10.4477 15 11 15C11.5523 15 12 15.4477 12 16Z'
            fill='#303341'
          />
          <path
            d='M18 16C18 16.5523 17.5523 17 17 17C16.4477 17 16 16.5523 16 16C16 15.4477 16.4477 15 17 15C17.5523 15 18 15.4477 18 16Z'
            fill='#303341'
          />
          <path
            d='M24 16C24 16.5523 23.5523 17 23 17C22.4477 17 22 16.5523 22 16C22 15.4477 22.4477 15 23 15C23.5523 15 24 15.4477 24 16Z'
            fill='#303341'
          />
          <path
            d='M30 16C30 16.5523 29.5523 17 29 17C28.4477 17 28 16.5523 28 16C28 15.4477 28.4477 15 29 15C29.5523 15 30 15.4477 30 16Z'
            fill='#303341'
          />
          <path
            d='M36 16C36 16.5523 35.5523 17 35 17C34.4477 17 34 16.5523 34 16C34 15.4477 34.4477 15 35 15C35.5523 15 36 15.4477 36 16Z'
            fill='#303341'
          />
          <path
            d='M42 16C42 16.5523 41.5523 17 41 17C40.4477 17 40 16.5523 40 16C40 15.4477 40.4477 15 41 15C41.5523 15 42 15.4477 42 16Z'
            fill='#303341'
          />
          <path
            d='M48 16C48 16.5523 47.5523 17 47 17C46.4477 17 46 16.5523 46 16C46 15.4477 46.4477 15 47 15C47.5523 15 48 15.4477 48 16Z'
            fill='#303341'
          />
          <path
            d='M54 16C54 16.5523 53.5523 17 53 17C52.4477 17 52 16.5523 52 16C52 15.4477 52.4477 15 53 15C53.5523 15 54 15.4477 54 16Z'
            fill='#303341'
          />
          <path
            d='M60 16C60 16.5523 59.5523 17 59 17C58.4477 17 58 16.5523 58 16C58 15.4477 58.4477 15 59 15C59.5523 15 60 15.4477 60 16Z'
            fill='#303341'
          />
          <path
            d='M66 16C66 16.5523 65.5523 17 65 17C64.4477 17 64 16.5523 64 16C64 15.4477 64.4477 15 65 15C65.5523 15 66 15.4477 66 16Z'
            fill='#303341'
          />
          <path
            d='M72 16C72 16.5523 71.5523 17 71 17C70.4477 17 70 16.5523 70 16C70 15.4477 70.4477 15 71 15C71.5523 15 72 15.4477 72 16Z'
            fill='#303341'
          />
          <path
            d='M78 16C78 16.5523 77.5523 17 77 17C76.4477 17 76 16.5523 76 16C76 15.4477 76.4477 15 77 15C77.5523 15 78 15.4477 78 16Z'
            fill='#303341'
          />
          <path
            d='M84 16C84 16.5523 83.5523 17 83 17C82.4477 17 82 16.5523 82 16C82 15.4477 82.4477 15 83 15C83.5523 15 84 15.4477 84 16Z'
            fill='#303341'
          />
          <path
            d='M90 16C90 16.5523 89.5523 17 89 17C88.4477 17 88 16.5523 88 16C88 15.4477 88.4477 15 89 15C89.5523 15 90 15.4477 90 16Z'
            fill='#303341'
          />
          <path
            d='M96 16C96 16.5523 95.5523 17 95 17C94.4477 17 94 16.5523 94 16C94 15.4477 94.4477 15 95 15C95.5523 15 96 15.4477 96 16Z'
            fill='#303341'
          />
          <path
            d='M102 16C102 16.5523 101.552 17 101 17C100.448 17 100 16.5523 100 16C100 15.4477 100.448 15 101 15C101.552 15 102 15.4477 102 16Z'
            fill='#303341'
          />
          <path
            d='M108 16C108 16.5523 107.552 17 107 17C106.448 17 106 16.5523 106 16C106 15.4477 106.448 15 107 15C107.552 15 108 15.4477 108 16Z'
            fill='#303341'
          />
          <path
            d='M114 16C114 16.5523 113.552 17 113 17C112.448 17 112 16.5523 112 16C112 15.4477 112.448 15 113 15C113.552 15 114 15.4477 114 16Z'
            fill='#303341'
          />
          <path
            d='M120 16C120 16.5523 119.552 17 119 17C118.448 17 118 16.5523 118 16C118 15.4477 118.448 15 119 15C119.552 15 120 15.4477 120 16Z'
            fill='#303341'
          />
          <path
            d='M126 16C126 16.5523 125.552 17 125 17C124.448 17 124 16.5523 124 16C124 15.4477 124.448 15 125 15C125.552 15 126 15.4477 126 16Z'
            fill='#303341'
          />
          <circle cx={131} cy={16} r={1} fill='#303341' />
          <circle cx={137} cy={16} r={1} fill='#303341' />
          <circle cx={143} cy={16} r={1} fill='#303341' />
          <circle cx={149} cy={16} r={1} fill='#303341' />
          <circle cx={155} cy={16} r={1} fill='#303341' />
          <circle cx={161} cy={16} r={1} fill='#303341' />
          <circle cx={167} cy={16} r={1} fill='#303341' />
          <circle cx={173} cy={16} r={1} fill='#303341' />
          <circle cx={179} cy={16} r={1} fill='#303341' />
          <circle cx={185} cy={16} r={1} fill='#303341' />
          <circle cx={191} cy={16} r={1} fill='#303341' />
          <circle cx={197} cy={16} r={1} fill='#303341' />
          <circle cx={203} cy={16} r={1} fill='#303341' />
          <circle cx={5} cy={136} r={1} fill='#303341' />
          <path
            d='M12 136C12 136.552 11.5523 137 11 137C10.4477 137 10 136.552 10 136C10 135.448 10.4477 135 11 135C11.5523 135 12 135.448 12 136Z'
            fill='#303341'
          />
          <path
            d='M18 136C18 136.552 17.5523 137 17 137C16.4477 137 16 136.552 16 136C16 135.448 16.4477 135 17 135C17.5523 135 18 135.448 18 136Z'
            fill='#303341'
          />
          <path
            d='M24 136C24 136.552 23.5523 137 23 137C22.4477 137 22 136.552 22 136C22 135.448 22.4477 135 23 135C23.5523 135 24 135.448 24 136Z'
            fill='#303341'
          />
          <path
            d='M30 136C30 136.552 29.5523 137 29 137C28.4477 137 28 136.552 28 136C28 135.448 28.4477 135 29 135C29.5523 135 30 135.448 30 136Z'
            fill='#303341'
          />
          <path
            d='M36 136C36 136.552 35.5523 137 35 137C34.4477 137 34 136.552 34 136C34 135.448 34.4477 135 35 135C35.5523 135 36 135.448 36 136Z'
            fill='#303341'
          />
          <path
            d='M42 136C42 136.552 41.5523 137 41 137C40.4477 137 40 136.552 40 136C40 135.448 40.4477 135 41 135C41.5523 135 42 135.448 42 136Z'
            fill='#303341'
          />
          <path
            d='M48 136C48 136.552 47.5523 137 47 137C46.4477 137 46 136.552 46 136C46 135.448 46.4477 135 47 135C47.5523 135 48 135.448 48 136Z'
            fill='#303341'
          />
          <path
            d='M54 136C54 136.552 53.5523 137 53 137C52.4477 137 52 136.552 52 136C52 135.448 52.4477 135 53 135C53.5523 135 54 135.448 54 136Z'
            fill='#303341'
          />
          <path
            d='M60 136C60 136.552 59.5523 137 59 137C58.4477 137 58 136.552 58 136C58 135.448 58.4477 135 59 135C59.5523 135 60 135.448 60 136Z'
            fill='#303341'
          />
          <path
            d='M66 136C66 136.552 65.5523 137 65 137C64.4477 137 64 136.552 64 136C64 135.448 64.4477 135 65 135C65.5523 135 66 135.448 66 136Z'
            fill='#303341'
          />
          <path
            d='M72 136C72 136.552 71.5523 137 71 137C70.4477 137 70 136.552 70 136C70 135.448 70.4477 135 71 135C71.5523 135 72 135.448 72 136Z'
            fill='#303341'
          />
          <path
            d='M78 136C78 136.552 77.5523 137 77 137C76.4477 137 76 136.552 76 136C76 135.448 76.4477 135 77 135C77.5523 135 78 135.448 78 136Z'
            fill='#303341'
          />
          <path
            d='M84 136C84 136.552 83.5523 137 83 137C82.4477 137 82 136.552 82 136C82 135.448 82.4477 135 83 135C83.5523 135 84 135.448 84 136Z'
            fill='#303341'
          />
          <path
            d='M90 136C90 136.552 89.5523 137 89 137C88.4477 137 88 136.552 88 136C88 135.448 88.4477 135 89 135C89.5523 135 90 135.448 90 136Z'
            fill='#303341'
          />
          <path
            d='M96 136C96 136.552 95.5523 137 95 137C94.4477 137 94 136.552 94 136C94 135.448 94.4477 135 95 135C95.5523 135 96 135.448 96 136Z'
            fill='#303341'
          />
          <path
            d='M102 136C102 136.552 101.552 137 101 137C100.448 137 100 136.552 100 136C100 135.448 100.448 135 101 135C101.552 135 102 135.448 102 136Z'
            fill='#303341'
          />
          <path
            d='M108 136C108 136.552 107.552 137 107 137C106.448 137 106 136.552 106 136C106 135.448 106.448 135 107 135C107.552 135 108 135.448 108 136Z'
            fill='#303341'
          />
          <path
            d='M114 136C114 136.552 113.552 137 113 137C112.448 137 112 136.552 112 136C112 135.448 112.448 135 113 135C113.552 135 114 135.448 114 136Z'
            fill='#303341'
          />
          <path
            d='M120 136C120 136.552 119.552 137 119 137C118.448 137 118 136.552 118 136C118 135.448 118.448 135 119 135C119.552 135 120 135.448 120 136Z'
            fill='#303341'
          />
          <path
            d='M126 136C126 136.552 125.552 137 125 137C124.448 137 124 136.552 124 136C124 135.448 124.448 135 125 135C125.552 135 126 135.448 126 136Z'
            fill='#303341'
          />
          <circle cx={131} cy={136} r={1} fill='#303341' />
          <circle cx={137} cy={136} r={1} fill='#303341' />
          <circle cx={143} cy={136} r={1} fill='#303341' />
          <circle cx={149} cy={136} r={1} fill='#303341' />
          <circle cx={155} cy={136} r={1} fill='#303341' />
          <circle cx={161} cy={136} r={1} fill='#303341' />
          <circle cx={167} cy={136} r={1} fill='#303341' />
          <circle cx={173} cy={136} r={1} fill='#303341' />
          <circle cx={179} cy={136} r={1} fill='#303341' />
          <circle cx={185} cy={136} r={1} fill='#303341' />
          <circle cx={191} cy={136} r={1} fill='#303341' />
          <circle cx={197} cy={136} r={1} fill='#303341' />
          <circle cx={203} cy={136} r={1} fill='#303341' />
          <circle cx={5} cy={40} r={1} fill='#303341' />
          <path
            d='M12 40C12 40.5523 11.5523 41 11 41C10.4477 41 10 40.5523 10 40C10 39.4477 10.4477 39 11 39C11.5523 39 12 39.4477 12 40Z'
            fill='#303341'
          />
          <path
            d='M18 40C18 40.5523 17.5523 41 17 41C16.4477 41 16 40.5523 16 40C16 39.4477 16.4477 39 17 39C17.5523 39 18 39.4477 18 40Z'
            fill='#303341'
          />
          <path
            d='M24 40C24 40.5523 23.5523 41 23 41C22.4477 41 22 40.5523 22 40C22 39.4477 22.4477 39 23 39C23.5523 39 24 39.4477 24 40Z'
            fill='#303341'
          />
          <path
            d='M30 40C30 40.5523 29.5523 41 29 41C28.4477 41 28 40.5523 28 40C28 39.4477 28.4477 39 29 39C29.5523 39 30 39.4477 30 40Z'
            fill='#303341'
          />
          <path
            d='M36 40C36 40.5523 35.5523 41 35 41C34.4477 41 34 40.5523 34 40C34 39.4477 34.4477 39 35 39C35.5523 39 36 39.4477 36 40Z'
            fill='#303341'
          />
          <path
            d='M42 40C42 40.5523 41.5523 41 41 41C40.4477 41 40 40.5523 40 40C40 39.4477 40.4477 39 41 39C41.5523 39 42 39.4477 42 40Z'
            fill='#303341'
          />
          <path
            d='M48 40C48 40.5523 47.5523 41 47 41C46.4477 41 46 40.5523 46 40C46 39.4477 46.4477 39 47 39C47.5523 39 48 39.4477 48 40Z'
            fill='#303341'
          />
          <path
            d='M54 40C54 40.5523 53.5523 41 53 41C52.4477 41 52 40.5523 52 40C52 39.4477 52.4477 39 53 39C53.5523 39 54 39.4477 54 40Z'
            fill='#303341'
          />
          <path
            d='M60 40C60 40.5523 59.5523 41 59 41C58.4477 41 58 40.5523 58 40C58 39.4477 58.4477 39 59 39C59.5523 39 60 39.4477 60 40Z'
            fill='#303341'
          />
          <path
            d='M66 40C66 40.5523 65.5523 41 65 41C64.4477 41 64 40.5523 64 40C64 39.4477 64.4477 39 65 39C65.5523 39 66 39.4477 66 40Z'
            fill='#303341'
          />
          <path
            d='M72 40C72 40.5523 71.5523 41 71 41C70.4477 41 70 40.5523 70 40C70 39.4477 70.4477 39 71 39C71.5523 39 72 39.4477 72 40Z'
            fill='#303341'
          />
          <path
            d='M78 40C78 40.5523 77.5523 41 77 41C76.4477 41 76 40.5523 76 40C76 39.4477 76.4477 39 77 39C77.5523 39 78 39.4477 78 40Z'
            fill='#303341'
          />
          <path
            d='M84 40C84 40.5523 83.5523 41 83 41C82.4477 41 82 40.5523 82 40C82 39.4477 82.4477 39 83 39C83.5523 39 84 39.4477 84 40Z'
            fill='#303341'
          />
          <path
            d='M90 40C90 40.5523 89.5523 41 89 41C88.4477 41 88 40.5523 88 40C88 39.4477 88.4477 39 89 39C89.5523 39 90 39.4477 90 40Z'
            fill='#303341'
          />
          <path
            d='M96 40C96 40.5523 95.5523 41 95 41C94.4477 41 94 40.5523 94 40C94 39.4477 94.4477 39 95 39C95.5523 39 96 39.4477 96 40Z'
            fill='#303341'
          />
          <path
            d='M102 40C102 40.5523 101.552 41 101 41C100.448 41 100 40.5523 100 40C100 39.4477 100.448 39 101 39C101.552 39 102 39.4477 102 40Z'
            fill='#303341'
          />
          <path
            d='M108 40C108 40.5523 107.552 41 107 41C106.448 41 106 40.5523 106 40C106 39.4477 106.448 39 107 39C107.552 39 108 39.4477 108 40Z'
            fill='#303341'
          />
          <path
            d='M114 40C114 40.5523 113.552 41 113 41C112.448 41 112 40.5523 112 40C112 39.4477 112.448 39 113 39C113.552 39 114 39.4477 114 40Z'
            fill='#303341'
          />
          <path
            d='M120 40C120 40.5523 119.552 41 119 41C118.448 41 118 40.5523 118 40C118 39.4477 118.448 39 119 39C119.552 39 120 39.4477 120 40Z'
            fill='#303341'
          />
          <path
            d='M126 40C126 40.5523 125.552 41 125 41C124.448 41 124 40.5523 124 40C124 39.4477 124.448 39 125 39C125.552 39 126 39.4477 126 40Z'
            fill='#303341'
          />
          <circle cx={131} cy={40} r={1} fill='#303341' />
          <circle cx={137} cy={40} r={1} fill='#303341' />
          <circle cx={143} cy={40} r={1} fill='#303341' />
          <circle cx={149} cy={40} r={1} fill='#303341' />
          <circle cx={155} cy={40} r={1} fill='#303341' />
          <circle cx={161} cy={40} r={1} fill='#303341' />
          <circle cx={167} cy={40} r={1} fill='#303341' />
          <circle cx={173} cy={40} r={1} fill='#303341' />
          <circle cx={179} cy={40} r={1} fill='#303341' />
          <circle cx={185} cy={40} r={1} fill='#303341' />
          <circle cx={191} cy={40} r={1} fill='#303341' />
          <circle cx={197} cy={40} r={1} fill='#303341' />
          <circle cx={203} cy={40} r={1} fill='#303341' />
          <circle cx={5} cy={160} r={1} fill='#303341' />
          <path
            d='M12 160C12 160.552 11.5523 161 11 161C10.4477 161 10 160.552 10 160C10 159.448 10.4477 159 11 159C11.5523 159 12 159.448 12 160Z'
            fill='#303341'
          />
          <path
            d='M18 160C18 160.552 17.5523 161 17 161C16.4477 161 16 160.552 16 160C16 159.448 16.4477 159 17 159C17.5523 159 18 159.448 18 160Z'
            fill='#303341'
          />
          <path
            d='M24 160C24 160.552 23.5523 161 23 161C22.4477 161 22 160.552 22 160C22 159.448 22.4477 159 23 159C23.5523 159 24 159.448 24 160Z'
            fill='#303341'
          />
          <path
            d='M30 160C30 160.552 29.5523 161 29 161C28.4477 161 28 160.552 28 160C28 159.448 28.4477 159 29 159C29.5523 159 30 159.448 30 160Z'
            fill='#303341'
          />
          <path
            d='M36 160C36 160.552 35.5523 161 35 161C34.4477 161 34 160.552 34 160C34 159.448 34.4477 159 35 159C35.5523 159 36 159.448 36 160Z'
            fill='#303341'
          />
          <path
            d='M42 160C42 160.552 41.5523 161 41 161C40.4477 161 40 160.552 40 160C40 159.448 40.4477 159 41 159C41.5523 159 42 159.448 42 160Z'
            fill='#303341'
          />
          <path
            d='M48 160C48 160.552 47.5523 161 47 161C46.4477 161 46 160.552 46 160C46 159.448 46.4477 159 47 159C47.5523 159 48 159.448 48 160Z'
            fill='#303341'
          />
          <path
            d='M54 160C54 160.552 53.5523 161 53 161C52.4477 161 52 160.552 52 160C52 159.448 52.4477 159 53 159C53.5523 159 54 159.448 54 160Z'
            fill='#303341'
          />
          <path
            d='M60 160C60 160.552 59.5523 161 59 161C58.4477 161 58 160.552 58 160C58 159.448 58.4477 159 59 159C59.5523 159 60 159.448 60 160Z'
            fill='#303341'
          />
          <path
            d='M66 160C66 160.552 65.5523 161 65 161C64.4477 161 64 160.552 64 160C64 159.448 64.4477 159 65 159C65.5523 159 66 159.448 66 160Z'
            fill='#303341'
          />
          <path
            d='M72 160C72 160.552 71.5523 161 71 161C70.4477 161 70 160.552 70 160C70 159.448 70.4477 159 71 159C71.5523 159 72 159.448 72 160Z'
            fill='#303341'
          />
          <path
            d='M78 160C78 160.552 77.5523 161 77 161C76.4477 161 76 160.552 76 160C76 159.448 76.4477 159 77 159C77.5523 159 78 159.448 78 160Z'
            fill='#303341'
          />
          <path
            d='M84 160C84 160.552 83.5523 161 83 161C82.4477 161 82 160.552 82 160C82 159.448 82.4477 159 83 159C83.5523 159 84 159.448 84 160Z'
            fill='#303341'
          />
          <path
            d='M90 160C90 160.552 89.5523 161 89 161C88.4477 161 88 160.552 88 160C88 159.448 88.4477 159 89 159C89.5523 159 90 159.448 90 160Z'
            fill='#303341'
          />
          <path
            d='M96 160C96 160.552 95.5523 161 95 161C94.4477 161 94 160.552 94 160C94 159.448 94.4477 159 95 159C95.5523 159 96 159.448 96 160Z'
            fill='#303341'
          />
          <path
            d='M102 160C102 160.552 101.552 161 101 161C100.448 161 100 160.552 100 160C100 159.448 100.448 159 101 159C101.552 159 102 159.448 102 160Z'
            fill='#303341'
          />
          <path
            d='M108 160C108 160.552 107.552 161 107 161C106.448 161 106 160.552 106 160C106 159.448 106.448 159 107 159C107.552 159 108 159.448 108 160Z'
            fill='#303341'
          />
          <path
            d='M114 160C114 160.552 113.552 161 113 161C112.448 161 112 160.552 112 160C112 159.448 112.448 159 113 159C113.552 159 114 159.448 114 160Z'
            fill='#303341'
          />
          <path
            d='M120 160C120 160.552 119.552 161 119 161C118.448 161 118 160.552 118 160C118 159.448 118.448 159 119 159C119.552 159 120 159.448 120 160Z'
            fill='#303341'
          />
          <path
            d='M126 160C126 160.552 125.552 161 125 161C124.448 161 124 160.552 124 160C124 159.448 124.448 159 125 159C125.552 159 126 159.448 126 160Z'
            fill='#303341'
          />
          <circle cx={131} cy={160} r={1} fill='#303341' />
          <circle cx={137} cy={160} r={1} fill='#303341' />
          <circle cx={143} cy={160} r={1} fill='#303341' />
          <circle cx={149} cy={160} r={1} fill='#303341' />
          <circle cx={155} cy={160} r={1} fill='#303341' />
          <circle cx={161} cy={160} r={1} fill='#303341' />
          <circle cx={167} cy={160} r={1} fill='#303341' />
          <circle cx={173} cy={160} r={1} fill='#303341' />
          <circle cx={179} cy={160} r={1} fill='#303341' />
          <circle cx={185} cy={160} r={1} fill='#303341' />
          <circle cx={191} cy={160} r={1} fill='#303341' />
          <circle cx={197} cy={160} r={1} fill='#303341' />
          <circle cx={203} cy={160} r={1} fill='#303341' />
          <circle cx={5} cy={64} r={1} fill='#303341' />
          <path
            d='M12 64C12 64.5523 11.5523 65 11 65C10.4477 65 10 64.5523 10 64C10 63.4477 10.4477 63 11 63C11.5523 63 12 63.4477 12 64Z'
            fill='#303341'
          />
          <path
            d='M18 64C18 64.5523 17.5523 65 17 65C16.4477 65 16 64.5523 16 64C16 63.4477 16.4477 63 17 63C17.5523 63 18 63.4477 18 64Z'
            fill='#303341'
          />
          <path
            d='M24 64C24 64.5523 23.5523 65 23 65C22.4477 65 22 64.5523 22 64C22 63.4477 22.4477 63 23 63C23.5523 63 24 63.4477 24 64Z'
            fill='#303341'
          />
          <path
            d='M30 64C30 64.5523 29.5523 65 29 65C28.4477 65 28 64.5523 28 64C28 63.4477 28.4477 63 29 63C29.5523 63 30 63.4477 30 64Z'
            fill='#303341'
          />
          <path
            d='M36 64C36 64.5523 35.5523 65 35 65C34.4477 65 34 64.5523 34 64C34 63.4477 34.4477 63 35 63C35.5523 63 36 63.4477 36 64Z'
            fill='#303341'
          />
          <path
            d='M42 64C42 64.5523 41.5523 65 41 65C40.4477 65 40 64.5523 40 64C40 63.4477 40.4477 63 41 63C41.5523 63 42 63.4477 42 64Z'
            fill='#303341'
          />
          <path
            d='M48 64C48 64.5523 47.5523 65 47 65C46.4477 65 46 64.5523 46 64C46 63.4477 46.4477 63 47 63C47.5523 63 48 63.4477 48 64Z'
            fill='#303341'
          />
          <path
            d='M54 64C54 64.5523 53.5523 65 53 65C52.4477 65 52 64.5523 52 64C52 63.4477 52.4477 63 53 63C53.5523 63 54 63.4477 54 64Z'
            fill='#303341'
          />
          <path
            d='M60 64C60 64.5523 59.5523 65 59 65C58.4477 65 58 64.5523 58 64C58 63.4477 58.4477 63 59 63C59.5523 63 60 63.4477 60 64Z'
            fill='#303341'
          />
          <path
            d='M66 64C66 64.5523 65.5523 65 65 65C64.4477 65 64 64.5523 64 64C64 63.4477 64.4477 63 65 63C65.5523 63 66 63.4477 66 64Z'
            fill='#303341'
          />
          <path
            d='M72 64C72 64.5523 71.5523 65 71 65C70.4477 65 70 64.5523 70 64C70 63.4477 70.4477 63 71 63C71.5523 63 72 63.4477 72 64Z'
            fill='#303341'
          />
          <path
            d='M78 64C78 64.5523 77.5523 65 77 65C76.4477 65 76 64.5523 76 64C76 63.4477 76.4477 63 77 63C77.5523 63 78 63.4477 78 64Z'
            fill='#303341'
          />
          <path
            d='M84 64C84 64.5523 83.5523 65 83 65C82.4477 65 82 64.5523 82 64C82 63.4477 82.4477 63 83 63C83.5523 63 84 63.4477 84 64Z'
            fill='#303341'
          />
          <path
            d='M90 64C90 64.5523 89.5523 65 89 65C88.4477 65 88 64.5523 88 64C88 63.4477 88.4477 63 89 63C89.5523 63 90 63.4477 90 64Z'
            fill='#303341'
          />
          <path
            d='M96 64C96 64.5523 95.5523 65 95 65C94.4477 65 94 64.5523 94 64C94 63.4477 94.4477 63 95 63C95.5523 63 96 63.4477 96 64Z'
            fill='#303341'
          />
          <path
            d='M102 64C102 64.5523 101.552 65 101 65C100.448 65 100 64.5523 100 64C100 63.4477 100.448 63 101 63C101.552 63 102 63.4477 102 64Z'
            fill='#303341'
          />
          <path
            d='M108 64C108 64.5523 107.552 65 107 65C106.448 65 106 64.5523 106 64C106 63.4477 106.448 63 107 63C107.552 63 108 63.4477 108 64Z'
            fill='#303341'
          />
          <path
            d='M114 64C114 64.5523 113.552 65 113 65C112.448 65 112 64.5523 112 64C112 63.4477 112.448 63 113 63C113.552 63 114 63.4477 114 64Z'
            fill='#303341'
          />
          <path
            d='M120 64C120 64.5523 119.552 65 119 65C118.448 65 118 64.5523 118 64C118 63.4477 118.448 63 119 63C119.552 63 120 63.4477 120 64Z'
            fill='#303341'
          />
          <path
            d='M126 64C126 64.5523 125.552 65 125 65C124.448 65 124 64.5523 124 64C124 63.4477 124.448 63 125 63C125.552 63 126 63.4477 126 64Z'
            fill='#303341'
          />
          <circle cx={131} cy={64} r={1} fill='#303341' />
          <circle cx={137} cy={64} r={1} fill='#303341' />
          <circle cx={143} cy={64} r={1} fill='#303341' />
          <circle cx={149} cy={64} r={1} fill='#303341' />
          <circle cx={155} cy={64} r={1} fill='#303341' />
          <circle cx={161} cy={64} r={1} fill='#303341' />
          <circle cx={167} cy={64} r={1} fill='#303341' />
          <circle cx={173} cy={64} r={1} fill='#303341' />
          <circle cx={179} cy={64} r={1} fill='#303341' />
          <circle cx={185} cy={64} r={1} fill='#303341' />
          <circle cx={191} cy={64} r={1} fill='#303341' />
          <circle cx={197} cy={64} r={1} fill='#303341' />
          <circle cx={203} cy={64} r={1} fill='#303341' />
          <circle cx={5} cy={184} r={1} fill='#303341' />
          <path
            d='M12 184C12 184.552 11.5523 185 11 185C10.4477 185 10 184.552 10 184C10 183.448 10.4477 183 11 183C11.5523 183 12 183.448 12 184Z'
            fill='#303341'
          />
          <path
            d='M18 184C18 184.552 17.5523 185 17 185C16.4477 185 16 184.552 16 184C16 183.448 16.4477 183 17 183C17.5523 183 18 183.448 18 184Z'
            fill='#303341'
          />
          <path
            d='M24 184C24 184.552 23.5523 185 23 185C22.4477 185 22 184.552 22 184C22 183.448 22.4477 183 23 183C23.5523 183 24 183.448 24 184Z'
            fill='#303341'
          />
          <path
            d='M30 184C30 184.552 29.5523 185 29 185C28.4477 185 28 184.552 28 184C28 183.448 28.4477 183 29 183C29.5523 183 30 183.448 30 184Z'
            fill='#303341'
          />
          <path
            d='M36 184C36 184.552 35.5523 185 35 185C34.4477 185 34 184.552 34 184C34 183.448 34.4477 183 35 183C35.5523 183 36 183.448 36 184Z'
            fill='#303341'
          />
          <path
            d='M42 184C42 184.552 41.5523 185 41 185C40.4477 185 40 184.552 40 184C40 183.448 40.4477 183 41 183C41.5523 183 42 183.448 42 184Z'
            fill='#303341'
          />
          <path
            d='M48 184C48 184.552 47.5523 185 47 185C46.4477 185 46 184.552 46 184C46 183.448 46.4477 183 47 183C47.5523 183 48 183.448 48 184Z'
            fill='#303341'
          />
          <path
            d='M54 184C54 184.552 53.5523 185 53 185C52.4477 185 52 184.552 52 184C52 183.448 52.4477 183 53 183C53.5523 183 54 183.448 54 184Z'
            fill='#303341'
          />
          <path
            d='M60 184C60 184.552 59.5523 185 59 185C58.4477 185 58 184.552 58 184C58 183.448 58.4477 183 59 183C59.5523 183 60 183.448 60 184Z'
            fill='#303341'
          />
          <path
            d='M66 184C66 184.552 65.5523 185 65 185C64.4477 185 64 184.552 64 184C64 183.448 64.4477 183 65 183C65.5523 183 66 183.448 66 184Z'
            fill='#303341'
          />
          <path
            d='M72 184C72 184.552 71.5523 185 71 185C70.4477 185 70 184.552 70 184C70 183.448 70.4477 183 71 183C71.5523 183 72 183.448 72 184Z'
            fill='#303341'
          />
          <path
            d='M78 184C78 184.552 77.5523 185 77 185C76.4477 185 76 184.552 76 184C76 183.448 76.4477 183 77 183C77.5523 183 78 183.448 78 184Z'
            fill='#303341'
          />
          <path
            d='M84 184C84 184.552 83.5523 185 83 185C82.4477 185 82 184.552 82 184C82 183.448 82.4477 183 83 183C83.5523 183 84 183.448 84 184Z'
            fill='#303341'
          />
          <path
            d='M90 184C90 184.552 89.5523 185 89 185C88.4477 185 88 184.552 88 184C88 183.448 88.4477 183 89 183C89.5523 183 90 183.448 90 184Z'
            fill='#303341'
          />
          <path
            d='M96 184C96 184.552 95.5523 185 95 185C94.4477 185 94 184.552 94 184C94 183.448 94.4477 183 95 183C95.5523 183 96 183.448 96 184Z'
            fill='#303341'
          />
          <path
            d='M102 184C102 184.552 101.552 185 101 185C100.448 185 100 184.552 100 184C100 183.448 100.448 183 101 183C101.552 183 102 183.448 102 184Z'
            fill='#303341'
          />
          <path
            d='M108 184C108 184.552 107.552 185 107 185C106.448 185 106 184.552 106 184C106 183.448 106.448 183 107 183C107.552 183 108 183.448 108 184Z'
            fill='#303341'
          />
          <path
            d='M114 184C114 184.552 113.552 185 113 185C112.448 185 112 184.552 112 184C112 183.448 112.448 183 113 183C113.552 183 114 183.448 114 184Z'
            fill='#303341'
          />
          <path
            d='M120 184C120 184.552 119.552 185 119 185C118.448 185 118 184.552 118 184C118 183.448 118.448 183 119 183C119.552 183 120 183.448 120 184Z'
            fill='#303341'
          />
          <path
            d='M126 184C126 184.552 125.552 185 125 185C124.448 185 124 184.552 124 184C124 183.448 124.448 183 125 183C125.552 183 126 183.448 126 184Z'
            fill='#303341'
          />
          <circle cx={131} cy={184} r={1} fill='#303341' />
          <circle cx={137} cy={184} r={1} fill='#303341' />
          <circle cx={143} cy={184} r={1} fill='#303341' />
          <circle cx={149} cy={184} r={1} fill='#303341' />
          <circle cx={155} cy={184} r={1} fill='#303341' />
          <circle cx={161} cy={184} r={1} fill='#303341' />
          <circle cx={167} cy={184} r={1} fill='#303341' />
          <circle cx={173} cy={184} r={1} fill='#303341' />
          <circle cx={179} cy={184} r={1} fill='#303341' />
          <circle cx={185} cy={184} r={1} fill='#303341' />
          <circle cx={191} cy={184} r={1} fill='#303341' />
          <circle cx={197} cy={184} r={1} fill='#303341' />
          <circle cx={203} cy={184} r={1} fill='#303341' />
          <circle cx={5} cy={88} r={1} fill='#303341' />
          <path
            d='M12 88C12 88.5523 11.5523 89 11 89C10.4477 89 10 88.5523 10 88C10 87.4477 10.4477 87 11 87C11.5523 87 12 87.4477 12 88Z'
            fill='#303341'
          />
          <path
            d='M18 88C18 88.5523 17.5523 89 17 89C16.4477 89 16 88.5523 16 88C16 87.4477 16.4477 87 17 87C17.5523 87 18 87.4477 18 88Z'
            fill='#303341'
          />
          <path
            d='M24 88C24 88.5523 23.5523 89 23 89C22.4477 89 22 88.5523 22 88C22 87.4477 22.4477 87 23 87C23.5523 87 24 87.4477 24 88Z'
            fill='#303341'
          />
          <path
            d='M30 88C30 88.5523 29.5523 89 29 89C28.4477 89 28 88.5523 28 88C28 87.4477 28.4477 87 29 87C29.5523 87 30 87.4477 30 88Z'
            fill='#303341'
          />
          <path
            d='M36 88C36 88.5523 35.5523 89 35 89C34.4477 89 34 88.5523 34 88C34 87.4477 34.4477 87 35 87C35.5523 87 36 87.4477 36 88Z'
            fill='#303341'
          />
          <path
            d='M42 88C42 88.5523 41.5523 89 41 89C40.4477 89 40 88.5523 40 88C40 87.4477 40.4477 87 41 87C41.5523 87 42 87.4477 42 88Z'
            fill='#303341'
          />
          <path
            d='M48 88C48 88.5523 47.5523 89 47 89C46.4477 89 46 88.5523 46 88C46 87.4477 46.4477 87 47 87C47.5523 87 48 87.4477 48 88Z'
            fill='#303341'
          />
          <path
            d='M54 88C54 88.5523 53.5523 89 53 89C52.4477 89 52 88.5523 52 88C52 87.4477 52.4477 87 53 87C53.5523 87 54 87.4477 54 88Z'
            fill='#303341'
          />
          <path
            d='M60 88C60 88.5523 59.5523 89 59 89C58.4477 89 58 88.5523 58 88C58 87.4477 58.4477 87 59 87C59.5523 87 60 87.4477 60 88Z'
            fill='#303341'
          />
          <path
            d='M66 88C66 88.5523 65.5523 89 65 89C64.4477 89 64 88.5523 64 88C64 87.4477 64.4477 87 65 87C65.5523 87 66 87.4477 66 88Z'
            fill='#303341'
          />
          <path
            d='M72 88C72 88.5523 71.5523 89 71 89C70.4477 89 70 88.5523 70 88C70 87.4477 70.4477 87 71 87C71.5523 87 72 87.4477 72 88Z'
            fill='#303341'
          />
          <path
            d='M78 88C78 88.5523 77.5523 89 77 89C76.4477 89 76 88.5523 76 88C76 87.4477 76.4477 87 77 87C77.5523 87 78 87.4477 78 88Z'
            fill='#303341'
          />
          <path
            d='M84 88C84 88.5523 83.5523 89 83 89C82.4477 89 82 88.5523 82 88C82 87.4477 82.4477 87 83 87C83.5523 87 84 87.4477 84 88Z'
            fill='#303341'
          />
          <path
            d='M90 88C90 88.5523 89.5523 89 89 89C88.4477 89 88 88.5523 88 88C88 87.4477 88.4477 87 89 87C89.5523 87 90 87.4477 90 88Z'
            fill='#303341'
          />
          <path
            d='M96 88C96 88.5523 95.5523 89 95 89C94.4477 89 94 88.5523 94 88C94 87.4477 94.4477 87 95 87C95.5523 87 96 87.4477 96 88Z'
            fill='#303341'
          />
          <path
            d='M102 88C102 88.5523 101.552 89 101 89C100.448 89 100 88.5523 100 88C100 87.4477 100.448 87 101 87C101.552 87 102 87.4477 102 88Z'
            fill='#303341'
          />
          <path
            d='M108 88C108 88.5523 107.552 89 107 89C106.448 89 106 88.5523 106 88C106 87.4477 106.448 87 107 87C107.552 87 108 87.4477 108 88Z'
            fill='#303341'
          />
          <path
            d='M114 88C114 88.5523 113.552 89 113 89C112.448 89 112 88.5523 112 88C112 87.4477 112.448 87 113 87C113.552 87 114 87.4477 114 88Z'
            fill='#303341'
          />
          <path
            d='M120 88C120 88.5523 119.552 89 119 89C118.448 89 118 88.5523 118 88C118 87.4477 118.448 87 119 87C119.552 87 120 87.4477 120 88Z'
            fill='#303341'
          />
          <path
            d='M126 88C126 88.5523 125.552 89 125 89C124.448 89 124 88.5523 124 88C124 87.4477 124.448 87 125 87C125.552 87 126 87.4477 126 88Z'
            fill='#303341'
          />
          <circle cx={131} cy={88} r={1} fill='#303341' />
          <circle cx={137} cy={88} r={1} fill='#303341' />
          <circle cx={143} cy={88} r={1} fill='#303341' />
          <circle cx={149} cy={88} r={1} fill='#303341' />
          <circle cx={155} cy={88} r={1} fill='#303341' />
          <circle cx={161} cy={88} r={1} fill='#303341' />
          <circle cx={167} cy={88} r={1} fill='#303341' />
          <circle cx={173} cy={88} r={1} fill='#303341' />
          <circle cx={179} cy={88} r={1} fill='#303341' />
          <circle cx={185} cy={88} r={1} fill='#303341' />
          <circle cx={191} cy={88} r={1} fill='#303341' />
          <circle cx={197} cy={88} r={1} fill='#303341' />
          <circle cx={203} cy={88} r={1} fill='#303341' />
          <circle cx={5} cy={112} r={1} fill='#303341' />
          <path
            d='M12 112C12 112.552 11.5523 113 11 113C10.4477 113 10 112.552 10 112C10 111.448 10.4477 111 11 111C11.5523 111 12 111.448 12 112Z'
            fill='#303341'
          />
          <path
            d='M18 112C18 112.552 17.5523 113 17 113C16.4477 113 16 112.552 16 112C16 111.448 16.4477 111 17 111C17.5523 111 18 111.448 18 112Z'
            fill='#303341'
          />
          <path
            d='M24 112C24 112.552 23.5523 113 23 113C22.4477 113 22 112.552 22 112C22 111.448 22.4477 111 23 111C23.5523 111 24 111.448 24 112Z'
            fill='#303341'
          />
          <path
            d='M30 112C30 112.552 29.5523 113 29 113C28.4477 113 28 112.552 28 112C28 111.448 28.4477 111 29 111C29.5523 111 30 111.448 30 112Z'
            fill='#303341'
          />
          <path
            d='M36 112C36 112.552 35.5523 113 35 113C34.4477 113 34 112.552 34 112C34 111.448 34.4477 111 35 111C35.5523 111 36 111.448 36 112Z'
            fill='#303341'
          />
          <path
            d='M42 112C42 112.552 41.5523 113 41 113C40.4477 113 40 112.552 40 112C40 111.448 40.4477 111 41 111C41.5523 111 42 111.448 42 112Z'
            fill='#303341'
          />
          <path
            d='M48 112C48 112.552 47.5523 113 47 113C46.4477 113 46 112.552 46 112C46 111.448 46.4477 111 47 111C47.5523 111 48 111.448 48 112Z'
            fill='#303341'
          />
          <path
            d='M54 112C54 112.552 53.5523 113 53 113C52.4477 113 52 112.552 52 112C52 111.448 52.4477 111 53 111C53.5523 111 54 111.448 54 112Z'
            fill='#303341'
          />
          <path
            d='M60 112C60 112.552 59.5523 113 59 113C58.4477 113 58 112.552 58 112C58 111.448 58.4477 111 59 111C59.5523 111 60 111.448 60 112Z'
            fill='#303341'
          />
          <path
            d='M66 112C66 112.552 65.5523 113 65 113C64.4477 113 64 112.552 64 112C64 111.448 64.4477 111 65 111C65.5523 111 66 111.448 66 112Z'
            fill='#303341'
          />
          <path
            d='M72 112C72 112.552 71.5523 113 71 113C70.4477 113 70 112.552 70 112C70 111.448 70.4477 111 71 111C71.5523 111 72 111.448 72 112Z'
            fill='#303341'
          />
          <path
            d='M78 112C78 112.552 77.5523 113 77 113C76.4477 113 76 112.552 76 112C76 111.448 76.4477 111 77 111C77.5523 111 78 111.448 78 112Z'
            fill='#303341'
          />
          <path
            d='M84 112C84 112.552 83.5523 113 83 113C82.4477 113 82 112.552 82 112C82 111.448 82.4477 111 83 111C83.5523 111 84 111.448 84 112Z'
            fill='#303341'
          />
          <path
            d='M90 112C90 112.552 89.5523 113 89 113C88.4477 113 88 112.552 88 112C88 111.448 88.4477 111 89 111C89.5523 111 90 111.448 90 112Z'
            fill='#303341'
          />
          <path
            d='M96 112C96 112.552 95.5523 113 95 113C94.4477 113 94 112.552 94 112C94 111.448 94.4477 111 95 111C95.5523 111 96 111.448 96 112Z'
            fill='#303341'
          />
          <path
            d='M102 112C102 112.552 101.552 113 101 113C100.448 113 100 112.552 100 112C100 111.448 100.448 111 101 111C101.552 111 102 111.448 102 112Z'
            fill='#303341'
          />
          <path
            d='M108 112C108 112.552 107.552 113 107 113C106.448 113 106 112.552 106 112C106 111.448 106.448 111 107 111C107.552 111 108 111.448 108 112Z'
            fill='#303341'
          />
          <path
            d='M114 112C114 112.552 113.552 113 113 113C112.448 113 112 112.552 112 112C112 111.448 112.448 111 113 111C113.552 111 114 111.448 114 112Z'
            fill='#303341'
          />
          <path
            d='M120 112C120 112.552 119.552 113 119 113C118.448 113 118 112.552 118 112C118 111.448 118.448 111 119 111C119.552 111 120 111.448 120 112Z'
            fill='#303341'
          />
          <path
            d='M126 112C126 112.552 125.552 113 125 113C124.448 113 124 112.552 124 112C124 111.448 124.448 111 125 111C125.552 111 126 111.448 126 112Z'
            fill='#303341'
          />
          <circle cx={131} cy={112} r={1} fill='#303341' />
          <circle cx={137} cy={112} r={1} fill='#303341' />
          <circle cx={143} cy={112} r={1} fill='#303341' />
          <circle cx={149} cy={112} r={1} fill='#303341' />
          <circle cx={155} cy={112} r={1} fill='#303341' />
          <circle cx={161} cy={112} r={1} fill='#303341' />
          <circle cx={167} cy={112} r={1} fill='#303341' />
          <circle cx={173} cy={112} r={1} fill='#303341' />
          <circle cx={179} cy={112} r={1} fill='#303341' />
          <circle cx={185} cy={112} r={1} fill='#303341' />
          <circle cx={191} cy={112} r={1} fill='#303341' />
          <circle cx={197} cy={112} r={1} fill='#303341' />
          <circle cx={203} cy={112} r={1} fill='#303341' />
          <circle cx={2} cy={10} r={1} fill='#303341' />
          <circle cx={8} cy={10} r={1} fill='#303341' />
          <path
            d='M15 10C15 10.5523 14.5523 11 14 11C13.4477 11 13 10.5523 13 10C13 9.44772 13.4477 9 14 9C14.5523 9 15 9.44772 15 10Z'
            fill='#303341'
          />
          <path
            d='M21 10C21 10.5523 20.5523 11 20 11C19.4477 11 19 10.5523 19 10C19 9.44772 19.4477 9 20 9C20.5523 9 21 9.44772 21 10Z'
            fill='#303341'
          />
          <path
            d='M27 10C27 10.5523 26.5523 11 26 11C25.4477 11 25 10.5523 25 10C25 9.44772 25.4477 9 26 9C26.5523 9 27 9.44772 27 10Z'
            fill='#303341'
          />
          <path
            d='M33 10C33 10.5523 32.5523 11 32 11C31.4477 11 31 10.5523 31 10C31 9.44772 31.4477 9 32 9C32.5523 9 33 9.44772 33 10Z'
            fill='#303341'
          />
          <path
            d='M39 10C39 10.5523 38.5523 11 38 11C37.4477 11 37 10.5523 37 10C37 9.44772 37.4477 9 38 9C38.5523 9 39 9.44772 39 10Z'
            fill='#303341'
          />
          <path
            d='M45 10C45 10.5523 44.5523 11 44 11C43.4477 11 43 10.5523 43 10C43 9.44772 43.4477 9 44 9C44.5523 9 45 9.44772 45 10Z'
            fill='#303341'
          />
          <path
            d='M51 10C51 10.5523 50.5523 11 50 11C49.4477 11 49 10.5523 49 10C49 9.44772 49.4477 9 50 9C50.5523 9 51 9.44772 51 10Z'
            fill='#303341'
          />
          <path
            d='M57 10C57 10.5523 56.5523 11 56 11C55.4477 11 55 10.5523 55 10C55 9.44772 55.4477 9 56 9C56.5523 9 57 9.44772 57 10Z'
            fill='#303341'
          />
          <path
            d='M63 10C63 10.5523 62.5523 11 62 11C61.4477 11 61 10.5523 61 10C61 9.44772 61.4477 9 62 9C62.5523 9 63 9.44772 63 10Z'
            fill='#303341'
          />
          <path
            d='M69 10C69 10.5523 68.5523 11 68 11C67.4477 11 67 10.5523 67 10C67 9.44772 67.4477 9 68 9C68.5523 9 69 9.44772 69 10Z'
            fill='#303341'
          />
          <path
            d='M75 10C75 10.5523 74.5523 11 74 11C73.4477 11 73 10.5523 73 10C73 9.44772 73.4477 9 74 9C74.5523 9 75 9.44772 75 10Z'
            fill='#303341'
          />
          <path
            d='M81 10C81 10.5523 80.5523 11 80 11C79.4477 11 79 10.5523 79 10C79 9.44772 79.4477 9 80 9C80.5523 9 81 9.44772 81 10Z'
            fill='#303341'
          />
          <path
            d='M87 10C87 10.5523 86.5523 11 86 11C85.4477 11 85 10.5523 85 10C85 9.44772 85.4477 9 86 9C86.5523 9 87 9.44772 87 10Z'
            fill='#303341'
          />
          <path
            d='M93 10C93 10.5523 92.5523 11 92 11C91.4477 11 91 10.5523 91 10C91 9.44772 91.4477 9 92 9C92.5523 9 93 9.44772 93 10Z'
            fill='#303341'
          />
          <path
            d='M99 10C99 10.5523 98.5523 11 98 11C97.4477 11 97 10.5523 97 10C97 9.44772 97.4477 9 98 9C98.5523 9 99 9.44772 99 10Z'
            fill='#303341'
          />
          <path
            d='M105 10C105 10.5523 104.552 11 104 11C103.448 11 103 10.5523 103 10C103 9.44772 103.448 9 104 9C104.552 9 105 9.44772 105 10Z'
            fill='#303341'
          />
          <path
            d='M111 10C111 10.5523 110.552 11 110 11C109.448 11 109 10.5523 109 10C109 9.44772 109.448 9 110 9C110.552 9 111 9.44772 111 10Z'
            fill='#303341'
          />
          <path
            d='M117 10C117 10.5523 116.552 11 116 11C115.448 11 115 10.5523 115 10C115 9.44772 115.448 9 116 9C116.552 9 117 9.44772 117 10Z'
            fill='#303341'
          />
          <path
            d='M123 10C123 10.5523 122.552 11 122 11C121.448 11 121 10.5523 121 10C121 9.44772 121.448 9 122 9C122.552 9 123 9.44772 123 10Z'
            fill='#303341'
          />
          <path
            d='M129 10C129 10.5523 128.552 11 128 11C127.448 11 127 10.5523 127 10C127 9.44772 127.448 9 128 9C128.552 9 129 9.44772 129 10Z'
            fill='#303341'
          />
          <circle cx={134} cy={10} r={1} fill='#303341' />
          <circle cx={140} cy={10} r={1} fill='#303341' />
          <circle cx={146} cy={10} r={1} fill='#303341' />
          <circle cx={152} cy={10} r={1} fill='#303341' />
          <circle cx={158} cy={10} r={1} fill='#303341' />
          <circle cx={164} cy={10} r={1} fill='#303341' />
          <circle cx={170} cy={10} r={1} fill='#303341' />
          <circle cx={176} cy={10} r={1} fill='#303341' />
          <circle cx={182} cy={10} r={1} fill='#303341' />
          <circle cx={188} cy={10} r={1} fill='#303341' />
          <circle cx={194} cy={10} r={1} fill='#303341' />
          <circle cx={200} cy={10} r={1} fill='#303341' />
          <circle cx={2} cy={130} r={1} fill='#303341' />
          <circle cx={8} cy={130} r={1} fill='#303341' />
          <path
            d='M15 130C15 130.552 14.5523 131 14 131C13.4477 131 13 130.552 13 130C13 129.448 13.4477 129 14 129C14.5523 129 15 129.448 15 130Z'
            fill='#303341'
          />
          <path
            d='M21 130C21 130.552 20.5523 131 20 131C19.4477 131 19 130.552 19 130C19 129.448 19.4477 129 20 129C20.5523 129 21 129.448 21 130Z'
            fill='#303341'
          />
          <path
            d='M27 130C27 130.552 26.5523 131 26 131C25.4477 131 25 130.552 25 130C25 129.448 25.4477 129 26 129C26.5523 129 27 129.448 27 130Z'
            fill='#303341'
          />
          <path
            d='M33 130C33 130.552 32.5523 131 32 131C31.4477 131 31 130.552 31 130C31 129.448 31.4477 129 32 129C32.5523 129 33 129.448 33 130Z'
            fill='#303341'
          />
          <path
            d='M39 130C39 130.552 38.5523 131 38 131C37.4477 131 37 130.552 37 130C37 129.448 37.4477 129 38 129C38.5523 129 39 129.448 39 130Z'
            fill='#303341'
          />
          <path
            d='M45 130C45 130.552 44.5523 131 44 131C43.4477 131 43 130.552 43 130C43 129.448 43.4477 129 44 129C44.5523 129 45 129.448 45 130Z'
            fill='#303341'
          />
          <path
            d='M51 130C51 130.552 50.5523 131 50 131C49.4477 131 49 130.552 49 130C49 129.448 49.4477 129 50 129C50.5523 129 51 129.448 51 130Z'
            fill='#303341'
          />
          <path
            d='M57 130C57 130.552 56.5523 131 56 131C55.4477 131 55 130.552 55 130C55 129.448 55.4477 129 56 129C56.5523 129 57 129.448 57 130Z'
            fill='#303341'
          />
          <path
            d='M63 130C63 130.552 62.5523 131 62 131C61.4477 131 61 130.552 61 130C61 129.448 61.4477 129 62 129C62.5523 129 63 129.448 63 130Z'
            fill='#303341'
          />
          <path
            d='M69 130C69 130.552 68.5523 131 68 131C67.4477 131 67 130.552 67 130C67 129.448 67.4477 129 68 129C68.5523 129 69 129.448 69 130Z'
            fill='#303341'
          />
          <path
            d='M75 130C75 130.552 74.5523 131 74 131C73.4477 131 73 130.552 73 130C73 129.448 73.4477 129 74 129C74.5523 129 75 129.448 75 130Z'
            fill='#303341'
          />
          <path
            d='M81 130C81 130.552 80.5523 131 80 131C79.4477 131 79 130.552 79 130C79 129.448 79.4477 129 80 129C80.5523 129 81 129.448 81 130Z'
            fill='#303341'
          />
          <path
            d='M87 130C87 130.552 86.5523 131 86 131C85.4477 131 85 130.552 85 130C85 129.448 85.4477 129 86 129C86.5523 129 87 129.448 87 130Z'
            fill='#303341'
          />
          <path
            d='M93 130C93 130.552 92.5523 131 92 131C91.4477 131 91 130.552 91 130C91 129.448 91.4477 129 92 129C92.5523 129 93 129.448 93 130Z'
            fill='#303341'
          />
          <path
            d='M99 130C99 130.552 98.5523 131 98 131C97.4477 131 97 130.552 97 130C97 129.448 97.4477 129 98 129C98.5523 129 99 129.448 99 130Z'
            fill='#303341'
          />
          <path
            d='M105 130C105 130.552 104.552 131 104 131C103.448 131 103 130.552 103 130C103 129.448 103.448 129 104 129C104.552 129 105 129.448 105 130Z'
            fill='#303341'
          />
          <path
            d='M111 130C111 130.552 110.552 131 110 131C109.448 131 109 130.552 109 130C109 129.448 109.448 129 110 129C110.552 129 111 129.448 111 130Z'
            fill='#303341'
          />
          <path
            d='M117 130C117 130.552 116.552 131 116 131C115.448 131 115 130.552 115 130C115 129.448 115.448 129 116 129C116.552 129 117 129.448 117 130Z'
            fill='#303341'
          />
          <path
            d='M123 130C123 130.552 122.552 131 122 131C121.448 131 121 130.552 121 130C121 129.448 121.448 129 122 129C122.552 129 123 129.448 123 130Z'
            fill='#303341'
          />
          <path
            d='M129 130C129 130.552 128.552 131 128 131C127.448 131 127 130.552 127 130C127 129.448 127.448 129 128 129C128.552 129 129 129.448 129 130Z'
            fill='#303341'
          />
          <circle cx={134} cy={130} r={1} fill='#303341' />
          <circle cx={140} cy={130} r={1} fill='#303341' />
          <circle cx={146} cy={130} r={1} fill='#303341' />
          <circle cx={152} cy={130} r={1} fill='#303341' />
          <circle cx={158} cy={130} r={1} fill='#303341' />
          <circle cx={164} cy={130} r={1} fill='#303341' />
          <circle cx={170} cy={130} r={1} fill='#303341' />
          <circle cx={176} cy={130} r={1} fill='#303341' />
          <circle cx={182} cy={130} r={1} fill='#303341' />
          <circle cx={188} cy={130} r={1} fill='#303341' />
          <circle cx={194} cy={130} r={1} fill='#303341' />
          <circle cx={200} cy={130} r={1} fill='#303341' />
          <circle cx={2} cy={34} r={1} fill='#303341' />
          <circle cx={8} cy={34} r={1} fill='#303341' />
          <path
            d='M15 34C15 34.5523 14.5523 35 14 35C13.4477 35 13 34.5523 13 34C13 33.4477 13.4477 33 14 33C14.5523 33 15 33.4477 15 34Z'
            fill='#303341'
          />
          <path
            d='M21 34C21 34.5523 20.5523 35 20 35C19.4477 35 19 34.5523 19 34C19 33.4477 19.4477 33 20 33C20.5523 33 21 33.4477 21 34Z'
            fill='#303341'
          />
          <path
            d='M27 34C27 34.5523 26.5523 35 26 35C25.4477 35 25 34.5523 25 34C25 33.4477 25.4477 33 26 33C26.5523 33 27 33.4477 27 34Z'
            fill='#303341'
          />
          <path
            d='M33 34C33 34.5523 32.5523 35 32 35C31.4477 35 31 34.5523 31 34C31 33.4477 31.4477 33 32 33C32.5523 33 33 33.4477 33 34Z'
            fill='#303341'
          />
          <path
            d='M39 34C39 34.5523 38.5523 35 38 35C37.4477 35 37 34.5523 37 34C37 33.4477 37.4477 33 38 33C38.5523 33 39 33.4477 39 34Z'
            fill='#303341'
          />
          <path
            d='M45 34C45 34.5523 44.5523 35 44 35C43.4477 35 43 34.5523 43 34C43 33.4477 43.4477 33 44 33C44.5523 33 45 33.4477 45 34Z'
            fill='#303341'
          />
          <path
            d='M51 34C51 34.5523 50.5523 35 50 35C49.4477 35 49 34.5523 49 34C49 33.4477 49.4477 33 50 33C50.5523 33 51 33.4477 51 34Z'
            fill='#303341'
          />
          <path
            d='M57 34C57 34.5523 56.5523 35 56 35C55.4477 35 55 34.5523 55 34C55 33.4477 55.4477 33 56 33C56.5523 33 57 33.4477 57 34Z'
            fill='#303341'
          />
          <path
            d='M63 34C63 34.5523 62.5523 35 62 35C61.4477 35 61 34.5523 61 34C61 33.4477 61.4477 33 62 33C62.5523 33 63 33.4477 63 34Z'
            fill='#303341'
          />
          <path
            d='M69 34C69 34.5523 68.5523 35 68 35C67.4477 35 67 34.5523 67 34C67 33.4477 67.4477 33 68 33C68.5523 33 69 33.4477 69 34Z'
            fill='#303341'
          />
          <path
            d='M75 34C75 34.5523 74.5523 35 74 35C73.4477 35 73 34.5523 73 34C73 33.4477 73.4477 33 74 33C74.5523 33 75 33.4477 75 34Z'
            fill='#303341'
          />
          <path
            d='M81 34C81 34.5523 80.5523 35 80 35C79.4477 35 79 34.5523 79 34C79 33.4477 79.4477 33 80 33C80.5523 33 81 33.4477 81 34Z'
            fill='#303341'
          />
          <path
            d='M87 34C87 34.5523 86.5523 35 86 35C85.4477 35 85 34.5523 85 34C85 33.4477 85.4477 33 86 33C86.5523 33 87 33.4477 87 34Z'
            fill='#303341'
          />
          <path
            d='M93 34C93 34.5523 92.5523 35 92 35C91.4477 35 91 34.5523 91 34C91 33.4477 91.4477 33 92 33C92.5523 33 93 33.4477 93 34Z'
            fill='#303341'
          />
          <path
            d='M99 34C99 34.5523 98.5523 35 98 35C97.4477 35 97 34.5523 97 34C97 33.4477 97.4477 33 98 33C98.5523 33 99 33.4477 99 34Z'
            fill='#303341'
          />
          <path
            d='M105 34C105 34.5523 104.552 35 104 35C103.448 35 103 34.5523 103 34C103 33.4477 103.448 33 104 33C104.552 33 105 33.4477 105 34Z'
            fill='#303341'
          />
          <path
            d='M111 34C111 34.5523 110.552 35 110 35C109.448 35 109 34.5523 109 34C109 33.4477 109.448 33 110 33C110.552 33 111 33.4477 111 34Z'
            fill='#303341'
          />
          <path
            d='M117 34C117 34.5523 116.552 35 116 35C115.448 35 115 34.5523 115 34C115 33.4477 115.448 33 116 33C116.552 33 117 33.4477 117 34Z'
            fill='#303341'
          />
          <path
            d='M123 34C123 34.5523 122.552 35 122 35C121.448 35 121 34.5523 121 34C121 33.4477 121.448 33 122 33C122.552 33 123 33.4477 123 34Z'
            fill='#303341'
          />
          <path
            d='M129 34C129 34.5523 128.552 35 128 35C127.448 35 127 34.5523 127 34C127 33.4477 127.448 33 128 33C128.552 33 129 33.4477 129 34Z'
            fill='#303341'
          />
          <circle cx={134} cy={34} r={1} fill='#303341' />
          <circle cx={140} cy={34} r={1} fill='#303341' />
          <circle cx={146} cy={34} r={1} fill='#303341' />
          <circle cx={152} cy={34} r={1} fill='#303341' />
          <circle cx={158} cy={34} r={1} fill='#303341' />
          <circle cx={164} cy={34} r={1} fill='#303341' />
          <circle cx={170} cy={34} r={1} fill='#303341' />
          <circle cx={176} cy={34} r={1} fill='#303341' />
          <circle cx={182} cy={34} r={1} fill='#303341' />
          <circle cx={188} cy={34} r={1} fill='#303341' />
          <circle cx={194} cy={34} r={1} fill='#303341' />
          <circle cx={200} cy={34} r={1} fill='#303341' />
          <circle cx={2} cy={154} r={1} fill='#303341' />
          <circle cx={8} cy={154} r={1} fill='#303341' />
          <path
            d='M15 154C15 154.552 14.5523 155 14 155C13.4477 155 13 154.552 13 154C13 153.448 13.4477 153 14 153C14.5523 153 15 153.448 15 154Z'
            fill='#303341'
          />
          <path
            d='M21 154C21 154.552 20.5523 155 20 155C19.4477 155 19 154.552 19 154C19 153.448 19.4477 153 20 153C20.5523 153 21 153.448 21 154Z'
            fill='#303341'
          />
          <path
            d='M27 154C27 154.552 26.5523 155 26 155C25.4477 155 25 154.552 25 154C25 153.448 25.4477 153 26 153C26.5523 153 27 153.448 27 154Z'
            fill='#303341'
          />
          <path
            d='M33 154C33 154.552 32.5523 155 32 155C31.4477 155 31 154.552 31 154C31 153.448 31.4477 153 32 153C32.5523 153 33 153.448 33 154Z'
            fill='#303341'
          />
          <path
            d='M39 154C39 154.552 38.5523 155 38 155C37.4477 155 37 154.552 37 154C37 153.448 37.4477 153 38 153C38.5523 153 39 153.448 39 154Z'
            fill='#303341'
          />
          <path
            d='M45 154C45 154.552 44.5523 155 44 155C43.4477 155 43 154.552 43 154C43 153.448 43.4477 153 44 153C44.5523 153 45 153.448 45 154Z'
            fill='#303341'
          />
          <path
            d='M51 154C51 154.552 50.5523 155 50 155C49.4477 155 49 154.552 49 154C49 153.448 49.4477 153 50 153C50.5523 153 51 153.448 51 154Z'
            fill='#303341'
          />
          <path
            d='M57 154C57 154.552 56.5523 155 56 155C55.4477 155 55 154.552 55 154C55 153.448 55.4477 153 56 153C56.5523 153 57 153.448 57 154Z'
            fill='#303341'
          />
          <path
            d='M63 154C63 154.552 62.5523 155 62 155C61.4477 155 61 154.552 61 154C61 153.448 61.4477 153 62 153C62.5523 153 63 153.448 63 154Z'
            fill='#303341'
          />
          <path
            d='M69 154C69 154.552 68.5523 155 68 155C67.4477 155 67 154.552 67 154C67 153.448 67.4477 153 68 153C68.5523 153 69 153.448 69 154Z'
            fill='#303341'
          />
          <path
            d='M75 154C75 154.552 74.5523 155 74 155C73.4477 155 73 154.552 73 154C73 153.448 73.4477 153 74 153C74.5523 153 75 153.448 75 154Z'
            fill='#303341'
          />
          <path
            d='M81 154C81 154.552 80.5523 155 80 155C79.4477 155 79 154.552 79 154C79 153.448 79.4477 153 80 153C80.5523 153 81 153.448 81 154Z'
            fill='#303341'
          />
          <path
            d='M87 154C87 154.552 86.5523 155 86 155C85.4477 155 85 154.552 85 154C85 153.448 85.4477 153 86 153C86.5523 153 87 153.448 87 154Z'
            fill='#303341'
          />
          <path
            d='M93 154C93 154.552 92.5523 155 92 155C91.4477 155 91 154.552 91 154C91 153.448 91.4477 153 92 153C92.5523 153 93 153.448 93 154Z'
            fill='#303341'
          />
          <path
            d='M99 154C99 154.552 98.5523 155 98 155C97.4477 155 97 154.552 97 154C97 153.448 97.4477 153 98 153C98.5523 153 99 153.448 99 154Z'
            fill='#303341'
          />
          <path
            d='M105 154C105 154.552 104.552 155 104 155C103.448 155 103 154.552 103 154C103 153.448 103.448 153 104 153C104.552 153 105 153.448 105 154Z'
            fill='#303341'
          />
          <path
            d='M111 154C111 154.552 110.552 155 110 155C109.448 155 109 154.552 109 154C109 153.448 109.448 153 110 153C110.552 153 111 153.448 111 154Z'
            fill='#303341'
          />
          <path
            d='M117 154C117 154.552 116.552 155 116 155C115.448 155 115 154.552 115 154C115 153.448 115.448 153 116 153C116.552 153 117 153.448 117 154Z'
            fill='#303341'
          />
          <path
            d='M123 154C123 154.552 122.552 155 122 155C121.448 155 121 154.552 121 154C121 153.448 121.448 153 122 153C122.552 153 123 153.448 123 154Z'
            fill='#303341'
          />
          <path
            d='M129 154C129 154.552 128.552 155 128 155C127.448 155 127 154.552 127 154C127 153.448 127.448 153 128 153C128.552 153 129 153.448 129 154Z'
            fill='#303341'
          />
          <circle cx={134} cy={154} r={1} fill='#303341' />
          <circle cx={140} cy={154} r={1} fill='#303341' />
          <circle cx={146} cy={154} r={1} fill='#303341' />
          <circle cx={152} cy={154} r={1} fill='#303341' />
          <circle cx={158} cy={154} r={1} fill='#303341' />
          <circle cx={164} cy={154} r={1} fill='#303341' />
          <circle cx={170} cy={154} r={1} fill='#303341' />
          <circle cx={176} cy={154} r={1} fill='#303341' />
          <circle cx={182} cy={154} r={1} fill='#303341' />
          <circle cx={188} cy={154} r={1} fill='#303341' />
          <circle cx={194} cy={154} r={1} fill='#303341' />
          <circle cx={200} cy={154} r={1} fill='#303341' />
          <circle cx={2} cy={58} r={1} fill='#303341' />
          <circle cx={8} cy={58} r={1} fill='#303341' />
          <path
            d='M15 58C15 58.5523 14.5523 59 14 59C13.4477 59 13 58.5523 13 58C13 57.4477 13.4477 57 14 57C14.5523 57 15 57.4477 15 58Z'
            fill='#303341'
          />
          <path
            d='M21 58C21 58.5523 20.5523 59 20 59C19.4477 59 19 58.5523 19 58C19 57.4477 19.4477 57 20 57C20.5523 57 21 57.4477 21 58Z'
            fill='#303341'
          />
          <path
            d='M27 58C27 58.5523 26.5523 59 26 59C25.4477 59 25 58.5523 25 58C25 57.4477 25.4477 57 26 57C26.5523 57 27 57.4477 27 58Z'
            fill='#303341'
          />
          <path
            d='M33 58C33 58.5523 32.5523 59 32 59C31.4477 59 31 58.5523 31 58C31 57.4477 31.4477 57 32 57C32.5523 57 33 57.4477 33 58Z'
            fill='#303341'
          />
          <path
            d='M39 58C39 58.5523 38.5523 59 38 59C37.4477 59 37 58.5523 37 58C37 57.4477 37.4477 57 38 57C38.5523 57 39 57.4477 39 58Z'
            fill='#303341'
          />
          <path
            d='M45 58C45 58.5523 44.5523 59 44 59C43.4477 59 43 58.5523 43 58C43 57.4477 43.4477 57 44 57C44.5523 57 45 57.4477 45 58Z'
            fill='#303341'
          />
          <path
            d='M51 58C51 58.5523 50.5523 59 50 59C49.4477 59 49 58.5523 49 58C49 57.4477 49.4477 57 50 57C50.5523 57 51 57.4477 51 58Z'
            fill='#303341'
          />
          <path
            d='M57 58C57 58.5523 56.5523 59 56 59C55.4477 59 55 58.5523 55 58C55 57.4477 55.4477 57 56 57C56.5523 57 57 57.4477 57 58Z'
            fill='#303341'
          />
          <path
            d='M63 58C63 58.5523 62.5523 59 62 59C61.4477 59 61 58.5523 61 58C61 57.4477 61.4477 57 62 57C62.5523 57 63 57.4477 63 58Z'
            fill='#303341'
          />
          <path
            d='M69 58C69 58.5523 68.5523 59 68 59C67.4477 59 67 58.5523 67 58C67 57.4477 67.4477 57 68 57C68.5523 57 69 57.4477 69 58Z'
            fill='#303341'
          />
          <path
            d='M75 58C75 58.5523 74.5523 59 74 59C73.4477 59 73 58.5523 73 58C73 57.4477 73.4477 57 74 57C74.5523 57 75 57.4477 75 58Z'
            fill='#303341'
          />
          <path
            d='M81 58C81 58.5523 80.5523 59 80 59C79.4477 59 79 58.5523 79 58C79 57.4477 79.4477 57 80 57C80.5523 57 81 57.4477 81 58Z'
            fill='#303341'
          />
          <path
            d='M87 58C87 58.5523 86.5523 59 86 59C85.4477 59 85 58.5523 85 58C85 57.4477 85.4477 57 86 57C86.5523 57 87 57.4477 87 58Z'
            fill='#303341'
          />
          <path
            d='M93 58C93 58.5523 92.5523 59 92 59C91.4477 59 91 58.5523 91 58C91 57.4477 91.4477 57 92 57C92.5523 57 93 57.4477 93 58Z'
            fill='#303341'
          />
          <path
            d='M99 58C99 58.5523 98.5523 59 98 59C97.4477 59 97 58.5523 97 58C97 57.4477 97.4477 57 98 57C98.5523 57 99 57.4477 99 58Z'
            fill='#303341'
          />
          <path
            d='M105 58C105 58.5523 104.552 59 104 59C103.448 59 103 58.5523 103 58C103 57.4477 103.448 57 104 57C104.552 57 105 57.4477 105 58Z'
            fill='#303341'
          />
          <path
            d='M111 58C111 58.5523 110.552 59 110 59C109.448 59 109 58.5523 109 58C109 57.4477 109.448 57 110 57C110.552 57 111 57.4477 111 58Z'
            fill='#303341'
          />
          <path
            d='M117 58C117 58.5523 116.552 59 116 59C115.448 59 115 58.5523 115 58C115 57.4477 115.448 57 116 57C116.552 57 117 57.4477 117 58Z'
            fill='#303341'
          />
          <path
            d='M123 58C123 58.5523 122.552 59 122 59C121.448 59 121 58.5523 121 58C121 57.4477 121.448 57 122 57C122.552 57 123 57.4477 123 58Z'
            fill='#303341'
          />
          <path
            d='M129 58C129 58.5523 128.552 59 128 59C127.448 59 127 58.5523 127 58C127 57.4477 127.448 57 128 57C128.552 57 129 57.4477 129 58Z'
            fill='#303341'
          />
          <circle cx={134} cy={58} r={1} fill='#303341' />
          <circle cx={140} cy={58} r={1} fill='#303341' />
          <circle cx={146} cy={58} r={1} fill='#303341' />
          <circle cx={152} cy={58} r={1} fill='#303341' />
          <circle cx={158} cy={58} r={1} fill='#303341' />
          <circle cx={164} cy={58} r={1} fill='#303341' />
          <circle cx={170} cy={58} r={1} fill='#303341' />
          <circle cx={176} cy={58} r={1} fill='#303341' />
          <circle cx={182} cy={58} r={1} fill='#303341' />
          <circle cx={188} cy={58} r={1} fill='#303341' />
          <circle cx={194} cy={58} r={1} fill='#303341' />
          <circle cx={200} cy={58} r={1} fill='#303341' />
          <circle cx={2} cy={178} r={1} fill='#303341' />
          <circle cx={8} cy={178} r={1} fill='#303341' />
          <path
            d='M15 178C15 178.552 14.5523 179 14 179C13.4477 179 13 178.552 13 178C13 177.448 13.4477 177 14 177C14.5523 177 15 177.448 15 178Z'
            fill='#303341'
          />
          <path
            d='M21 178C21 178.552 20.5523 179 20 179C19.4477 179 19 178.552 19 178C19 177.448 19.4477 177 20 177C20.5523 177 21 177.448 21 178Z'
            fill='#303341'
          />
          <path
            d='M27 178C27 178.552 26.5523 179 26 179C25.4477 179 25 178.552 25 178C25 177.448 25.4477 177 26 177C26.5523 177 27 177.448 27 178Z'
            fill='#303341'
          />
          <path
            d='M33 178C33 178.552 32.5523 179 32 179C31.4477 179 31 178.552 31 178C31 177.448 31.4477 177 32 177C32.5523 177 33 177.448 33 178Z'
            fill='#303341'
          />
          <path
            d='M39 178C39 178.552 38.5523 179 38 179C37.4477 179 37 178.552 37 178C37 177.448 37.4477 177 38 177C38.5523 177 39 177.448 39 178Z'
            fill='#303341'
          />
          <path
            d='M45 178C45 178.552 44.5523 179 44 179C43.4477 179 43 178.552 43 178C43 177.448 43.4477 177 44 177C44.5523 177 45 177.448 45 178Z'
            fill='#303341'
          />
          <path
            d='M51 178C51 178.552 50.5523 179 50 179C49.4477 179 49 178.552 49 178C49 177.448 49.4477 177 50 177C50.5523 177 51 177.448 51 178Z'
            fill='#303341'
          />
          <path
            d='M57 178C57 178.552 56.5523 179 56 179C55.4477 179 55 178.552 55 178C55 177.448 55.4477 177 56 177C56.5523 177 57 177.448 57 178Z'
            fill='#303341'
          />
          <path
            d='M63 178C63 178.552 62.5523 179 62 179C61.4477 179 61 178.552 61 178C61 177.448 61.4477 177 62 177C62.5523 177 63 177.448 63 178Z'
            fill='#303341'
          />
          <path
            d='M69 178C69 178.552 68.5523 179 68 179C67.4477 179 67 178.552 67 178C67 177.448 67.4477 177 68 177C68.5523 177 69 177.448 69 178Z'
            fill='#303341'
          />
          <path
            d='M75 178C75 178.552 74.5523 179 74 179C73.4477 179 73 178.552 73 178C73 177.448 73.4477 177 74 177C74.5523 177 75 177.448 75 178Z'
            fill='#303341'
          />
          <path
            d='M81 178C81 178.552 80.5523 179 80 179C79.4477 179 79 178.552 79 178C79 177.448 79.4477 177 80 177C80.5523 177 81 177.448 81 178Z'
            fill='#303341'
          />
          <path
            d='M87 178C87 178.552 86.5523 179 86 179C85.4477 179 85 178.552 85 178C85 177.448 85.4477 177 86 177C86.5523 177 87 177.448 87 178Z'
            fill='#303341'
          />
          <path
            d='M93 178C93 178.552 92.5523 179 92 179C91.4477 179 91 178.552 91 178C91 177.448 91.4477 177 92 177C92.5523 177 93 177.448 93 178Z'
            fill='#303341'
          />
          <path
            d='M99 178C99 178.552 98.5523 179 98 179C97.4477 179 97 178.552 97 178C97 177.448 97.4477 177 98 177C98.5523 177 99 177.448 99 178Z'
            fill='#303341'
          />
          <path
            d='M105 178C105 178.552 104.552 179 104 179C103.448 179 103 178.552 103 178C103 177.448 103.448 177 104 177C104.552 177 105 177.448 105 178Z'
            fill='#303341'
          />
          <path
            d='M111 178C111 178.552 110.552 179 110 179C109.448 179 109 178.552 109 178C109 177.448 109.448 177 110 177C110.552 177 111 177.448 111 178Z'
            fill='#303341'
          />
          <path
            d='M117 178C117 178.552 116.552 179 116 179C115.448 179 115 178.552 115 178C115 177.448 115.448 177 116 177C116.552 177 117 177.448 117 178Z'
            fill='#303341'
          />
          <path
            d='M123 178C123 178.552 122.552 179 122 179C121.448 179 121 178.552 121 178C121 177.448 121.448 177 122 177C122.552 177 123 177.448 123 178Z'
            fill='#303341'
          />
          <path
            d='M129 178C129 178.552 128.552 179 128 179C127.448 179 127 178.552 127 178C127 177.448 127.448 177 128 177C128.552 177 129 177.448 129 178Z'
            fill='#303341'
          />
          <circle cx={134} cy={178} r={1} fill='#303341' />
          <circle cx={140} cy={178} r={1} fill='#303341' />
          <circle cx={146} cy={178} r={1} fill='#303341' />
          <circle cx={152} cy={178} r={1} fill='#303341' />
          <circle cx={158} cy={178} r={1} fill='#303341' />
          <circle cx={164} cy={178} r={1} fill='#303341' />
          <circle cx={170} cy={178} r={1} fill='#303341' />
          <circle cx={176} cy={178} r={1} fill='#303341' />
          <circle cx={182} cy={178} r={1} fill='#303341' />
          <circle cx={188} cy={178} r={1} fill='#303341' />
          <circle cx={194} cy={178} r={1} fill='#303341' />
          <circle cx={200} cy={178} r={1} fill='#303341' />
          <circle cx={2} cy={82} r={1} fill='#303341' />
          <circle cx={8} cy={82} r={1} fill='#303341' />
          <path
            d='M15 82C15 82.5523 14.5523 83 14 83C13.4477 83 13 82.5523 13 82C13 81.4477 13.4477 81 14 81C14.5523 81 15 81.4477 15 82Z'
            fill='#303341'
          />
          <path
            d='M21 82C21 82.5523 20.5523 83 20 83C19.4477 83 19 82.5523 19 82C19 81.4477 19.4477 81 20 81C20.5523 81 21 81.4477 21 82Z'
            fill='#303341'
          />
          <path
            d='M27 82C27 82.5523 26.5523 83 26 83C25.4477 83 25 82.5523 25 82C25 81.4477 25.4477 81 26 81C26.5523 81 27 81.4477 27 82Z'
            fill='#303341'
          />
          <path
            d='M33 82C33 82.5523 32.5523 83 32 83C31.4477 83 31 82.5523 31 82C31 81.4477 31.4477 81 32 81C32.5523 81 33 81.4477 33 82Z'
            fill='#303341'
          />
          <path
            d='M39 82C39 82.5523 38.5523 83 38 83C37.4477 83 37 82.5523 37 82C37 81.4477 37.4477 81 38 81C38.5523 81 39 81.4477 39 82Z'
            fill='#303341'
          />
          <path
            d='M45 82C45 82.5523 44.5523 83 44 83C43.4477 83 43 82.5523 43 82C43 81.4477 43.4477 81 44 81C44.5523 81 45 81.4477 45 82Z'
            fill='#303341'
          />
          <path
            d='M51 82C51 82.5523 50.5523 83 50 83C49.4477 83 49 82.5523 49 82C49 81.4477 49.4477 81 50 81C50.5523 81 51 81.4477 51 82Z'
            fill='#303341'
          />
          <path
            d='M57 82C57 82.5523 56.5523 83 56 83C55.4477 83 55 82.5523 55 82C55 81.4477 55.4477 81 56 81C56.5523 81 57 81.4477 57 82Z'
            fill='#303341'
          />
          <path
            d='M63 82C63 82.5523 62.5523 83 62 83C61.4477 83 61 82.5523 61 82C61 81.4477 61.4477 81 62 81C62.5523 81 63 81.4477 63 82Z'
            fill='#303341'
          />
          <path
            d='M69 82C69 82.5523 68.5523 83 68 83C67.4477 83 67 82.5523 67 82C67 81.4477 67.4477 81 68 81C68.5523 81 69 81.4477 69 82Z'
            fill='#303341'
          />
          <path
            d='M75 82C75 82.5523 74.5523 83 74 83C73.4477 83 73 82.5523 73 82C73 81.4477 73.4477 81 74 81C74.5523 81 75 81.4477 75 82Z'
            fill='#303341'
          />
          <path
            d='M81 82C81 82.5523 80.5523 83 80 83C79.4477 83 79 82.5523 79 82C79 81.4477 79.4477 81 80 81C80.5523 81 81 81.4477 81 82Z'
            fill='#303341'
          />
          <path
            d='M87 82C87 82.5523 86.5523 83 86 83C85.4477 83 85 82.5523 85 82C85 81.4477 85.4477 81 86 81C86.5523 81 87 81.4477 87 82Z'
            fill='#303341'
          />
          <path
            d='M93 82C93 82.5523 92.5523 83 92 83C91.4477 83 91 82.5523 91 82C91 81.4477 91.4477 81 92 81C92.5523 81 93 81.4477 93 82Z'
            fill='#303341'
          />
          <path
            d='M99 82C99 82.5523 98.5523 83 98 83C97.4477 83 97 82.5523 97 82C97 81.4477 97.4477 81 98 81C98.5523 81 99 81.4477 99 82Z'
            fill='#303341'
          />
          <path
            d='M105 82C105 82.5523 104.552 83 104 83C103.448 83 103 82.5523 103 82C103 81.4477 103.448 81 104 81C104.552 81 105 81.4477 105 82Z'
            fill='#303341'
          />
          <path
            d='M111 82C111 82.5523 110.552 83 110 83C109.448 83 109 82.5523 109 82C109 81.4477 109.448 81 110 81C110.552 81 111 81.4477 111 82Z'
            fill='#303341'
          />
          <path
            d='M117 82C117 82.5523 116.552 83 116 83C115.448 83 115 82.5523 115 82C115 81.4477 115.448 81 116 81C116.552 81 117 81.4477 117 82Z'
            fill='#303341'
          />
          <path
            d='M123 82C123 82.5523 122.552 83 122 83C121.448 83 121 82.5523 121 82C121 81.4477 121.448 81 122 81C122.552 81 123 81.4477 123 82Z'
            fill='#303341'
          />
          <path
            d='M129 82C129 82.5523 128.552 83 128 83C127.448 83 127 82.5523 127 82C127 81.4477 127.448 81 128 81C128.552 81 129 81.4477 129 82Z'
            fill='#303341'
          />
          <circle cx={134} cy={82} r={1} fill='#303341' />
          <circle cx={140} cy={82} r={1} fill='#303341' />
          <circle cx={146} cy={82} r={1} fill='#303341' />
          <circle cx={152} cy={82} r={1} fill='#303341' />
          <circle cx={158} cy={82} r={1} fill='#303341' />
          <circle cx={164} cy={82} r={1} fill='#303341' />
          <circle cx={170} cy={82} r={1} fill='#303341' />
          <circle cx={176} cy={82} r={1} fill='#303341' />
          <circle cx={182} cy={82} r={1} fill='#303341' />
          <circle cx={188} cy={82} r={1} fill='#303341' />
          <circle cx={194} cy={82} r={1} fill='#303341' />
          <circle cx={200} cy={82} r={1} fill='#303341' />
          <circle cx={2} cy={202} r={1} fill='#303341' />
          <circle cx={8} cy={202} r={1} fill='#303341' />
          <path
            d='M15 202C15 202.552 14.5523 203 14 203C13.4477 203 13 202.552 13 202C13 201.448 13.4477 201 14 201C14.5523 201 15 201.448 15 202Z'
            fill='#303341'
          />
          <path
            d='M21 202C21 202.552 20.5523 203 20 203C19.4477 203 19 202.552 19 202C19 201.448 19.4477 201 20 201C20.5523 201 21 201.448 21 202Z'
            fill='#303341'
          />
          <path
            d='M27 202C27 202.552 26.5523 203 26 203C25.4477 203 25 202.552 25 202C25 201.448 25.4477 201 26 201C26.5523 201 27 201.448 27 202Z'
            fill='#303341'
          />
          <path
            d='M33 202C33 202.552 32.5523 203 32 203C31.4477 203 31 202.552 31 202C31 201.448 31.4477 201 32 201C32.5523 201 33 201.448 33 202Z'
            fill='#303341'
          />
          <path
            d='M39 202C39 202.552 38.5523 203 38 203C37.4477 203 37 202.552 37 202C37 201.448 37.4477 201 38 201C38.5523 201 39 201.448 39 202Z'
            fill='#303341'
          />
          <path
            d='M45 202C45 202.552 44.5523 203 44 203C43.4477 203 43 202.552 43 202C43 201.448 43.4477 201 44 201C44.5523 201 45 201.448 45 202Z'
            fill='#303341'
          />
          <path
            d='M51 202C51 202.552 50.5523 203 50 203C49.4477 203 49 202.552 49 202C49 201.448 49.4477 201 50 201C50.5523 201 51 201.448 51 202Z'
            fill='#303341'
          />
          <path
            d='M57 202C57 202.552 56.5523 203 56 203C55.4477 203 55 202.552 55 202C55 201.448 55.4477 201 56 201C56.5523 201 57 201.448 57 202Z'
            fill='#303341'
          />
          <path
            d='M63 202C63 202.552 62.5523 203 62 203C61.4477 203 61 202.552 61 202C61 201.448 61.4477 201 62 201C62.5523 201 63 201.448 63 202Z'
            fill='#303341'
          />
          <path
            d='M69 202C69 202.552 68.5523 203 68 203C67.4477 203 67 202.552 67 202C67 201.448 67.4477 201 68 201C68.5523 201 69 201.448 69 202Z'
            fill='#303341'
          />
          <path
            d='M75 202C75 202.552 74.5523 203 74 203C73.4477 203 73 202.552 73 202C73 201.448 73.4477 201 74 201C74.5523 201 75 201.448 75 202Z'
            fill='#303341'
          />
          <path
            d='M81 202C81 202.552 80.5523 203 80 203C79.4477 203 79 202.552 79 202C79 201.448 79.4477 201 80 201C80.5523 201 81 201.448 81 202Z'
            fill='#303341'
          />
          <path
            d='M87 202C87 202.552 86.5523 203 86 203C85.4477 203 85 202.552 85 202C85 201.448 85.4477 201 86 201C86.5523 201 87 201.448 87 202Z'
            fill='#303341'
          />
          <path
            d='M93 202C93 202.552 92.5523 203 92 203C91.4477 203 91 202.552 91 202C91 201.448 91.4477 201 92 201C92.5523 201 93 201.448 93 202Z'
            fill='#303341'
          />
          <path
            d='M99 202C99 202.552 98.5523 203 98 203C97.4477 203 97 202.552 97 202C97 201.448 97.4477 201 98 201C98.5523 201 99 201.448 99 202Z'
            fill='#303341'
          />
          <path
            d='M105 202C105 202.552 104.552 203 104 203C103.448 203 103 202.552 103 202C103 201.448 103.448 201 104 201C104.552 201 105 201.448 105 202Z'
            fill='#303341'
          />
          <path
            d='M111 202C111 202.552 110.552 203 110 203C109.448 203 109 202.552 109 202C109 201.448 109.448 201 110 201C110.552 201 111 201.448 111 202Z'
            fill='#303341'
          />
          <path
            d='M117 202C117 202.552 116.552 203 116 203C115.448 203 115 202.552 115 202C115 201.448 115.448 201 116 201C116.552 201 117 201.448 117 202Z'
            fill='#303341'
          />
          <path
            d='M123 202C123 202.552 122.552 203 122 203C121.448 203 121 202.552 121 202C121 201.448 121.448 201 122 201C122.552 201 123 201.448 123 202Z'
            fill='#303341'
          />
          <path
            d='M129 202C129 202.552 128.552 203 128 203C127.448 203 127 202.552 127 202C127 201.448 127.448 201 128 201C128.552 201 129 201.448 129 202Z'
            fill='#303341'
          />
          <circle cx={134} cy={202} r={1} fill='#303341' />
          <circle cx={140} cy={202} r={1} fill='#303341' />
          <circle cx={146} cy={202} r={1} fill='#303341' />
          <circle cx={152} cy={202} r={1} fill='#303341' />
          <circle cx={158} cy={202} r={1} fill='#303341' />
          <circle cx={164} cy={202} r={1} fill='#303341' />
          <circle cx={170} cy={202} r={1} fill='#303341' />
          <circle cx={176} cy={202} r={1} fill='#303341' />
          <circle cx={182} cy={202} r={1} fill='#303341' />
          <circle cx={188} cy={202} r={1} fill='#303341' />
          <circle cx={194} cy={202} r={1} fill='#303341' />
          <circle cx={200} cy={202} r={1} fill='#303341' />
          <circle cx={2} cy={106} r={1} fill='#303341' />
          <circle cx={8} cy={106} r={1} fill='#303341' />
          <path
            d='M15 106C15 106.552 14.5523 107 14 107C13.4477 107 13 106.552 13 106C13 105.448 13.4477 105 14 105C14.5523 105 15 105.448 15 106Z'
            fill='#303341'
          />
          <path
            d='M21 106C21 106.552 20.5523 107 20 107C19.4477 107 19 106.552 19 106C19 105.448 19.4477 105 20 105C20.5523 105 21 105.448 21 106Z'
            fill='#303341'
          />
          <path
            d='M27 106C27 106.552 26.5523 107 26 107C25.4477 107 25 106.552 25 106C25 105.448 25.4477 105 26 105C26.5523 105 27 105.448 27 106Z'
            fill='#303341'
          />
          <path
            d='M33 106C33 106.552 32.5523 107 32 107C31.4477 107 31 106.552 31 106C31 105.448 31.4477 105 32 105C32.5523 105 33 105.448 33 106Z'
            fill='#303341'
          />
          <path
            d='M39 106C39 106.552 38.5523 107 38 107C37.4477 107 37 106.552 37 106C37 105.448 37.4477 105 38 105C38.5523 105 39 105.448 39 106Z'
            fill='#303341'
          />
          <path
            d='M45 106C45 106.552 44.5523 107 44 107C43.4477 107 43 106.552 43 106C43 105.448 43.4477 105 44 105C44.5523 105 45 105.448 45 106Z'
            fill='#303341'
          />
          <path
            d='M51 106C51 106.552 50.5523 107 50 107C49.4477 107 49 106.552 49 106C49 105.448 49.4477 105 50 105C50.5523 105 51 105.448 51 106Z'
            fill='#303341'
          />
          <path
            d='M57 106C57 106.552 56.5523 107 56 107C55.4477 107 55 106.552 55 106C55 105.448 55.4477 105 56 105C56.5523 105 57 105.448 57 106Z'
            fill='#303341'
          />
          <path
            d='M63 106C63 106.552 62.5523 107 62 107C61.4477 107 61 106.552 61 106C61 105.448 61.4477 105 62 105C62.5523 105 63 105.448 63 106Z'
            fill='#303341'
          />
          <path
            d='M69 106C69 106.552 68.5523 107 68 107C67.4477 107 67 106.552 67 106C67 105.448 67.4477 105 68 105C68.5523 105 69 105.448 69 106Z'
            fill='#303341'
          />
          <path
            d='M75 106C75 106.552 74.5523 107 74 107C73.4477 107 73 106.552 73 106C73 105.448 73.4477 105 74 105C74.5523 105 75 105.448 75 106Z'
            fill='#303341'
          />
          <path
            d='M81 106C81 106.552 80.5523 107 80 107C79.4477 107 79 106.552 79 106C79 105.448 79.4477 105 80 105C80.5523 105 81 105.448 81 106Z'
            fill='#303341'
          />
          <path
            d='M87 106C87 106.552 86.5523 107 86 107C85.4477 107 85 106.552 85 106C85 105.448 85.4477 105 86 105C86.5523 105 87 105.448 87 106Z'
            fill='#303341'
          />
          <path
            d='M93 106C93 106.552 92.5523 107 92 107C91.4477 107 91 106.552 91 106C91 105.448 91.4477 105 92 105C92.5523 105 93 105.448 93 106Z'
            fill='#303341'
          />
          <path
            d='M99 106C99 106.552 98.5523 107 98 107C97.4477 107 97 106.552 97 106C97 105.448 97.4477 105 98 105C98.5523 105 99 105.448 99 106Z'
            fill='#303341'
          />
          <path
            d='M105 106C105 106.552 104.552 107 104 107C103.448 107 103 106.552 103 106C103 105.448 103.448 105 104 105C104.552 105 105 105.448 105 106Z'
            fill='#303341'
          />
          <path
            d='M111 106C111 106.552 110.552 107 110 107C109.448 107 109 106.552 109 106C109 105.448 109.448 105 110 105C110.552 105 111 105.448 111 106Z'
            fill='#303341'
          />
          <path
            d='M117 106C117 106.552 116.552 107 116 107C115.448 107 115 106.552 115 106C115 105.448 115.448 105 116 105C116.552 105 117 105.448 117 106Z'
            fill='#303341'
          />
          <path
            d='M123 106C123 106.552 122.552 107 122 107C121.448 107 121 106.552 121 106C121 105.448 121.448 105 122 105C122.552 105 123 105.448 123 106Z'
            fill='#303341'
          />
          <path
            d='M129 106C129 106.552 128.552 107 128 107C127.448 107 127 106.552 127 106C127 105.448 127.448 105 128 105C128.552 105 129 105.448 129 106Z'
            fill='#303341'
          />
          <circle cx={134} cy={106} r={1} fill='#303341' />
          <circle cx={140} cy={106} r={1} fill='#303341' />
          <circle cx={146} cy={106} r={1} fill='#303341' />
          <circle cx={152} cy={106} r={1} fill='#303341' />
          <circle cx={158} cy={106} r={1} fill='#303341' />
          <circle cx={164} cy={106} r={1} fill='#303341' />
          <circle cx={170} cy={106} r={1} fill='#303341' />
          <circle cx={176} cy={106} r={1} fill='#303341' />
          <circle cx={182} cy={106} r={1} fill='#303341' />
          <circle cx={188} cy={106} r={1} fill='#303341' />
          <circle cx={194} cy={106} r={1} fill='#303341' />
          <circle cx={200} cy={106} r={1} fill='#303341' />
          <circle cx={2} cy={22} r={1} fill='#303341' />
          <circle cx={8} cy={22} r={1} fill='#303341' />
          <path
            d='M15 22C15 22.5523 14.5523 23 14 23C13.4477 23 13 22.5523 13 22C13 21.4477 13.4477 21 14 21C14.5523 21 15 21.4477 15 22Z'
            fill='#303341'
          />
          <path
            d='M21 22C21 22.5523 20.5523 23 20 23C19.4477 23 19 22.5523 19 22C19 21.4477 19.4477 21 20 21C20.5523 21 21 21.4477 21 22Z'
            fill='#303341'
          />
          <path
            d='M27 22C27 22.5523 26.5523 23 26 23C25.4477 23 25 22.5523 25 22C25 21.4477 25.4477 21 26 21C26.5523 21 27 21.4477 27 22Z'
            fill='#303341'
          />
          <path
            d='M33 22C33 22.5523 32.5523 23 32 23C31.4477 23 31 22.5523 31 22C31 21.4477 31.4477 21 32 21C32.5523 21 33 21.4477 33 22Z'
            fill='#303341'
          />
          <path
            d='M39 22C39 22.5523 38.5523 23 38 23C37.4477 23 37 22.5523 37 22C37 21.4477 37.4477 21 38 21C38.5523 21 39 21.4477 39 22Z'
            fill='#303341'
          />
          <path
            d='M45 22C45 22.5523 44.5523 23 44 23C43.4477 23 43 22.5523 43 22C43 21.4477 43.4477 21 44 21C44.5523 21 45 21.4477 45 22Z'
            fill='#303341'
          />
          <path
            d='M51 22C51 22.5523 50.5523 23 50 23C49.4477 23 49 22.5523 49 22C49 21.4477 49.4477 21 50 21C50.5523 21 51 21.4477 51 22Z'
            fill='#303341'
          />
          <path
            d='M57 22C57 22.5523 56.5523 23 56 23C55.4477 23 55 22.5523 55 22C55 21.4477 55.4477 21 56 21C56.5523 21 57 21.4477 57 22Z'
            fill='#303341'
          />
          <path
            d='M63 22C63 22.5523 62.5523 23 62 23C61.4477 23 61 22.5523 61 22C61 21.4477 61.4477 21 62 21C62.5523 21 63 21.4477 63 22Z'
            fill='#303341'
          />
          <path
            d='M69 22C69 22.5523 68.5523 23 68 23C67.4477 23 67 22.5523 67 22C67 21.4477 67.4477 21 68 21C68.5523 21 69 21.4477 69 22Z'
            fill='#303341'
          />
          <path
            d='M75 22C75 22.5523 74.5523 23 74 23C73.4477 23 73 22.5523 73 22C73 21.4477 73.4477 21 74 21C74.5523 21 75 21.4477 75 22Z'
            fill='#303341'
          />
          <path
            d='M81 22C81 22.5523 80.5523 23 80 23C79.4477 23 79 22.5523 79 22C79 21.4477 79.4477 21 80 21C80.5523 21 81 21.4477 81 22Z'
            fill='#303341'
          />
          <path
            d='M87 22C87 22.5523 86.5523 23 86 23C85.4477 23 85 22.5523 85 22C85 21.4477 85.4477 21 86 21C86.5523 21 87 21.4477 87 22Z'
            fill='#303341'
          />
          <path
            d='M93 22C93 22.5523 92.5523 23 92 23C91.4477 23 91 22.5523 91 22C91 21.4477 91.4477 21 92 21C92.5523 21 93 21.4477 93 22Z'
            fill='#303341'
          />
          <path
            d='M99 22C99 22.5523 98.5523 23 98 23C97.4477 23 97 22.5523 97 22C97 21.4477 97.4477 21 98 21C98.5523 21 99 21.4477 99 22Z'
            fill='#303341'
          />
          <path
            d='M105 22C105 22.5523 104.552 23 104 23C103.448 23 103 22.5523 103 22C103 21.4477 103.448 21 104 21C104.552 21 105 21.4477 105 22Z'
            fill='#303341'
          />
          <path
            d='M111 22C111 22.5523 110.552 23 110 23C109.448 23 109 22.5523 109 22C109 21.4477 109.448 21 110 21C110.552 21 111 21.4477 111 22Z'
            fill='#303341'
          />
          <path
            d='M117 22C117 22.5523 116.552 23 116 23C115.448 23 115 22.5523 115 22C115 21.4477 115.448 21 116 21C116.552 21 117 21.4477 117 22Z'
            fill='#303341'
          />
          <path
            d='M123 22C123 22.5523 122.552 23 122 23C121.448 23 121 22.5523 121 22C121 21.4477 121.448 21 122 21C122.552 21 123 21.4477 123 22Z'
            fill='#303341'
          />
          <path
            d='M129 22C129 22.5523 128.552 23 128 23C127.448 23 127 22.5523 127 22C127 21.4477 127.448 21 128 21C128.552 21 129 21.4477 129 22Z'
            fill='#303341'
          />
          <circle cx={134} cy={22} r={1} fill='#303341' />
          <circle cx={140} cy={22} r={1} fill='#303341' />
          <circle cx={146} cy={22} r={1} fill='#303341' />
          <circle cx={152} cy={22} r={1} fill='#303341' />
          <circle cx={158} cy={22} r={1} fill='#303341' />
          <circle cx={164} cy={22} r={1} fill='#303341' />
          <circle cx={170} cy={22} r={1} fill='#303341' />
          <circle cx={176} cy={22} r={1} fill='#303341' />
          <circle cx={182} cy={22} r={1} fill='#303341' />
          <circle cx={188} cy={22} r={1} fill='#303341' />
          <circle cx={194} cy={22} r={1} fill='#303341' />
          <circle cx={200} cy={22} r={1} fill='#303341' />
          <circle cx={2} cy={142} r={1} fill='#303341' />
          <circle cx={8} cy={142} r={1} fill='#303341' />
          <path
            d='M15 142C15 142.552 14.5523 143 14 143C13.4477 143 13 142.552 13 142C13 141.448 13.4477 141 14 141C14.5523 141 15 141.448 15 142Z'
            fill='#303341'
          />
          <path
            d='M21 142C21 142.552 20.5523 143 20 143C19.4477 143 19 142.552 19 142C19 141.448 19.4477 141 20 141C20.5523 141 21 141.448 21 142Z'
            fill='#303341'
          />
          <path
            d='M27 142C27 142.552 26.5523 143 26 143C25.4477 143 25 142.552 25 142C25 141.448 25.4477 141 26 141C26.5523 141 27 141.448 27 142Z'
            fill='#303341'
          />
          <path
            d='M33 142C33 142.552 32.5523 143 32 143C31.4477 143 31 142.552 31 142C31 141.448 31.4477 141 32 141C32.5523 141 33 141.448 33 142Z'
            fill='#303341'
          />
          <path
            d='M39 142C39 142.552 38.5523 143 38 143C37.4477 143 37 142.552 37 142C37 141.448 37.4477 141 38 141C38.5523 141 39 141.448 39 142Z'
            fill='#303341'
          />
          <path
            d='M45 142C45 142.552 44.5523 143 44 143C43.4477 143 43 142.552 43 142C43 141.448 43.4477 141 44 141C44.5523 141 45 141.448 45 142Z'
            fill='#303341'
          />
          <path
            d='M51 142C51 142.552 50.5523 143 50 143C49.4477 143 49 142.552 49 142C49 141.448 49.4477 141 50 141C50.5523 141 51 141.448 51 142Z'
            fill='#303341'
          />
          <path
            d='M57 142C57 142.552 56.5523 143 56 143C55.4477 143 55 142.552 55 142C55 141.448 55.4477 141 56 141C56.5523 141 57 141.448 57 142Z'
            fill='#303341'
          />
          <path
            d='M63 142C63 142.552 62.5523 143 62 143C61.4477 143 61 142.552 61 142C61 141.448 61.4477 141 62 141C62.5523 141 63 141.448 63 142Z'
            fill='#303341'
          />
          <path
            d='M69 142C69 142.552 68.5523 143 68 143C67.4477 143 67 142.552 67 142C67 141.448 67.4477 141 68 141C68.5523 141 69 141.448 69 142Z'
            fill='#303341'
          />
          <path
            d='M75 142C75 142.552 74.5523 143 74 143C73.4477 143 73 142.552 73 142C73 141.448 73.4477 141 74 141C74.5523 141 75 141.448 75 142Z'
            fill='#303341'
          />
          <path
            d='M81 142C81 142.552 80.5523 143 80 143C79.4477 143 79 142.552 79 142C79 141.448 79.4477 141 80 141C80.5523 141 81 141.448 81 142Z'
            fill='#303341'
          />
          <path
            d='M87 142C87 142.552 86.5523 143 86 143C85.4477 143 85 142.552 85 142C85 141.448 85.4477 141 86 141C86.5523 141 87 141.448 87 142Z'
            fill='#303341'
          />
          <path
            d='M93 142C93 142.552 92.5523 143 92 143C91.4477 143 91 142.552 91 142C91 141.448 91.4477 141 92 141C92.5523 141 93 141.448 93 142Z'
            fill='#303341'
          />
          <path
            d='M99 142C99 142.552 98.5523 143 98 143C97.4477 143 97 142.552 97 142C97 141.448 97.4477 141 98 141C98.5523 141 99 141.448 99 142Z'
            fill='#303341'
          />
          <path
            d='M105 142C105 142.552 104.552 143 104 143C103.448 143 103 142.552 103 142C103 141.448 103.448 141 104 141C104.552 141 105 141.448 105 142Z'
            fill='#303341'
          />
          <path
            d='M111 142C111 142.552 110.552 143 110 143C109.448 143 109 142.552 109 142C109 141.448 109.448 141 110 141C110.552 141 111 141.448 111 142Z'
            fill='#303341'
          />
          <path
            d='M117 142C117 142.552 116.552 143 116 143C115.448 143 115 142.552 115 142C115 141.448 115.448 141 116 141C116.552 141 117 141.448 117 142Z'
            fill='#303341'
          />
          <path
            d='M123 142C123 142.552 122.552 143 122 143C121.448 143 121 142.552 121 142C121 141.448 121.448 141 122 141C122.552 141 123 141.448 123 142Z'
            fill='#303341'
          />
          <path
            d='M129 142C129 142.552 128.552 143 128 143C127.448 143 127 142.552 127 142C127 141.448 127.448 141 128 141C128.552 141 129 141.448 129 142Z'
            fill='#303341'
          />
          <circle cx={134} cy={142} r={1} fill='#303341' />
          <circle cx={140} cy={142} r={1} fill='#303341' />
          <circle cx={146} cy={142} r={1} fill='#303341' />
          <circle cx={152} cy={142} r={1} fill='#303341' />
          <circle cx={158} cy={142} r={1} fill='#303341' />
          <circle cx={164} cy={142} r={1} fill='#303341' />
          <circle cx={170} cy={142} r={1} fill='#303341' />
          <circle cx={176} cy={142} r={1} fill='#303341' />
          <circle cx={182} cy={142} r={1} fill='#303341' />
          <circle cx={188} cy={142} r={1} fill='#303341' />
          <circle cx={194} cy={142} r={1} fill='#303341' />
          <circle cx={200} cy={142} r={1} fill='#303341' />
          <circle cx={2} cy={46} r={1} fill='#303341' />
          <circle cx={8} cy={46} r={1} fill='#303341' />
          <path
            d='M15 46C15 46.5523 14.5523 47 14 47C13.4477 47 13 46.5523 13 46C13 45.4477 13.4477 45 14 45C14.5523 45 15 45.4477 15 46Z'
            fill='#303341'
          />
          <path
            d='M21 46C21 46.5523 20.5523 47 20 47C19.4477 47 19 46.5523 19 46C19 45.4477 19.4477 45 20 45C20.5523 45 21 45.4477 21 46Z'
            fill='#303341'
          />
          <path
            d='M27 46C27 46.5523 26.5523 47 26 47C25.4477 47 25 46.5523 25 46C25 45.4477 25.4477 45 26 45C26.5523 45 27 45.4477 27 46Z'
            fill='#303341'
          />
          <path
            d='M33 46C33 46.5523 32.5523 47 32 47C31.4477 47 31 46.5523 31 46C31 45.4477 31.4477 45 32 45C32.5523 45 33 45.4477 33 46Z'
            fill='#303341'
          />
          <path
            d='M39 46C39 46.5523 38.5523 47 38 47C37.4477 47 37 46.5523 37 46C37 45.4477 37.4477 45 38 45C38.5523 45 39 45.4477 39 46Z'
            fill='#303341'
          />
          <path
            d='M45 46C45 46.5523 44.5523 47 44 47C43.4477 47 43 46.5523 43 46C43 45.4477 43.4477 45 44 45C44.5523 45 45 45.4477 45 46Z'
            fill='#303341'
          />
          <path
            d='M51 46C51 46.5523 50.5523 47 50 47C49.4477 47 49 46.5523 49 46C49 45.4477 49.4477 45 50 45C50.5523 45 51 45.4477 51 46Z'
            fill='#303341'
          />
          <path
            d='M57 46C57 46.5523 56.5523 47 56 47C55.4477 47 55 46.5523 55 46C55 45.4477 55.4477 45 56 45C56.5523 45 57 45.4477 57 46Z'
            fill='#303341'
          />
          <path
            d='M63 46C63 46.5523 62.5523 47 62 47C61.4477 47 61 46.5523 61 46C61 45.4477 61.4477 45 62 45C62.5523 45 63 45.4477 63 46Z'
            fill='#303341'
          />
          <path
            d='M69 46C69 46.5523 68.5523 47 68 47C67.4477 47 67 46.5523 67 46C67 45.4477 67.4477 45 68 45C68.5523 45 69 45.4477 69 46Z'
            fill='#303341'
          />
          <path
            d='M75 46C75 46.5523 74.5523 47 74 47C73.4477 47 73 46.5523 73 46C73 45.4477 73.4477 45 74 45C74.5523 45 75 45.4477 75 46Z'
            fill='#303341'
          />
          <path
            d='M81 46C81 46.5523 80.5523 47 80 47C79.4477 47 79 46.5523 79 46C79 45.4477 79.4477 45 80 45C80.5523 45 81 45.4477 81 46Z'
            fill='#303341'
          />
          <path
            d='M87 46C87 46.5523 86.5523 47 86 47C85.4477 47 85 46.5523 85 46C85 45.4477 85.4477 45 86 45C86.5523 45 87 45.4477 87 46Z'
            fill='#303341'
          />
          <path
            d='M93 46C93 46.5523 92.5523 47 92 47C91.4477 47 91 46.5523 91 46C91 45.4477 91.4477 45 92 45C92.5523 45 93 45.4477 93 46Z'
            fill='#303341'
          />
          <path
            d='M99 46C99 46.5523 98.5523 47 98 47C97.4477 47 97 46.5523 97 46C97 45.4477 97.4477 45 98 45C98.5523 45 99 45.4477 99 46Z'
            fill='#303341'
          />
          <path
            d='M105 46C105 46.5523 104.552 47 104 47C103.448 47 103 46.5523 103 46C103 45.4477 103.448 45 104 45C104.552 45 105 45.4477 105 46Z'
            fill='#303341'
          />
          <path
            d='M111 46C111 46.5523 110.552 47 110 47C109.448 47 109 46.5523 109 46C109 45.4477 109.448 45 110 45C110.552 45 111 45.4477 111 46Z'
            fill='#303341'
          />
          <path
            d='M117 46C117 46.5523 116.552 47 116 47C115.448 47 115 46.5523 115 46C115 45.4477 115.448 45 116 45C116.552 45 117 45.4477 117 46Z'
            fill='#303341'
          />
          <path
            d='M123 46C123 46.5523 122.552 47 122 47C121.448 47 121 46.5523 121 46C121 45.4477 121.448 45 122 45C122.552 45 123 45.4477 123 46Z'
            fill='#303341'
          />
          <path
            d='M129 46C129 46.5523 128.552 47 128 47C127.448 47 127 46.5523 127 46C127 45.4477 127.448 45 128 45C128.552 45 129 45.4477 129 46Z'
            fill='#303341'
          />
          <circle cx={134} cy={46} r={1} fill='#303341' />
          <circle cx={140} cy={46} r={1} fill='#303341' />
          <circle cx={146} cy={46} r={1} fill='#303341' />
          <circle cx={152} cy={46} r={1} fill='#303341' />
          <circle cx={158} cy={46} r={1} fill='#303341' />
          <circle cx={164} cy={46} r={1} fill='#303341' />
          <circle cx={170} cy={46} r={1} fill='#303341' />
          <circle cx={176} cy={46} r={1} fill='#303341' />
          <circle cx={182} cy={46} r={1} fill='#303341' />
          <circle cx={188} cy={46} r={1} fill='#303341' />
          <circle cx={194} cy={46} r={1} fill='#303341' />
          <circle cx={200} cy={46} r={1} fill='#303341' />
          <circle cx={2} cy={166} r={1} fill='#303341' />
          <circle cx={8} cy={166} r={1} fill='#303341' />
          <path
            d='M15 166C15 166.552 14.5523 167 14 167C13.4477 167 13 166.552 13 166C13 165.448 13.4477 165 14 165C14.5523 165 15 165.448 15 166Z'
            fill='#303341'
          />
          <path
            d='M21 166C21 166.552 20.5523 167 20 167C19.4477 167 19 166.552 19 166C19 165.448 19.4477 165 20 165C20.5523 165 21 165.448 21 166Z'
            fill='#303341'
          />
          <path
            d='M27 166C27 166.552 26.5523 167 26 167C25.4477 167 25 166.552 25 166C25 165.448 25.4477 165 26 165C26.5523 165 27 165.448 27 166Z'
            fill='#303341'
          />
          <path
            d='M33 166C33 166.552 32.5523 167 32 167C31.4477 167 31 166.552 31 166C31 165.448 31.4477 165 32 165C32.5523 165 33 165.448 33 166Z'
            fill='#303341'
          />
          <path
            d='M39 166C39 166.552 38.5523 167 38 167C37.4477 167 37 166.552 37 166C37 165.448 37.4477 165 38 165C38.5523 165 39 165.448 39 166Z'
            fill='#303341'
          />
          <path
            d='M45 166C45 166.552 44.5523 167 44 167C43.4477 167 43 166.552 43 166C43 165.448 43.4477 165 44 165C44.5523 165 45 165.448 45 166Z'
            fill='#303341'
          />
          <path
            d='M51 166C51 166.552 50.5523 167 50 167C49.4477 167 49 166.552 49 166C49 165.448 49.4477 165 50 165C50.5523 165 51 165.448 51 166Z'
            fill='#303341'
          />
          <path
            d='M57 166C57 166.552 56.5523 167 56 167C55.4477 167 55 166.552 55 166C55 165.448 55.4477 165 56 165C56.5523 165 57 165.448 57 166Z'
            fill='#303341'
          />
          <path
            d='M63 166C63 166.552 62.5523 167 62 167C61.4477 167 61 166.552 61 166C61 165.448 61.4477 165 62 165C62.5523 165 63 165.448 63 166Z'
            fill='#303341'
          />
          <path
            d='M69 166C69 166.552 68.5523 167 68 167C67.4477 167 67 166.552 67 166C67 165.448 67.4477 165 68 165C68.5523 165 69 165.448 69 166Z'
            fill='#303341'
          />
          <path
            d='M75 166C75 166.552 74.5523 167 74 167C73.4477 167 73 166.552 73 166C73 165.448 73.4477 165 74 165C74.5523 165 75 165.448 75 166Z'
            fill='#303341'
          />
          <path
            d='M81 166C81 166.552 80.5523 167 80 167C79.4477 167 79 166.552 79 166C79 165.448 79.4477 165 80 165C80.5523 165 81 165.448 81 166Z'
            fill='#303341'
          />
          <path
            d='M87 166C87 166.552 86.5523 167 86 167C85.4477 167 85 166.552 85 166C85 165.448 85.4477 165 86 165C86.5523 165 87 165.448 87 166Z'
            fill='#303341'
          />
          <path
            d='M93 166C93 166.552 92.5523 167 92 167C91.4477 167 91 166.552 91 166C91 165.448 91.4477 165 92 165C92.5523 165 93 165.448 93 166Z'
            fill='#303341'
          />
          <path
            d='M99 166C99 166.552 98.5523 167 98 167C97.4477 167 97 166.552 97 166C97 165.448 97.4477 165 98 165C98.5523 165 99 165.448 99 166Z'
            fill='#303341'
          />
          <path
            d='M105 166C105 166.552 104.552 167 104 167C103.448 167 103 166.552 103 166C103 165.448 103.448 165 104 165C104.552 165 105 165.448 105 166Z'
            fill='#303341'
          />
          <path
            d='M111 166C111 166.552 110.552 167 110 167C109.448 167 109 166.552 109 166C109 165.448 109.448 165 110 165C110.552 165 111 165.448 111 166Z'
            fill='#303341'
          />
          <path
            d='M117 166C117 166.552 116.552 167 116 167C115.448 167 115 166.552 115 166C115 165.448 115.448 165 116 165C116.552 165 117 165.448 117 166Z'
            fill='#303341'
          />
          <path
            d='M123 166C123 166.552 122.552 167 122 167C121.448 167 121 166.552 121 166C121 165.448 121.448 165 122 165C122.552 165 123 165.448 123 166Z'
            fill='#303341'
          />
          <path
            d='M129 166C129 166.552 128.552 167 128 167C127.448 167 127 166.552 127 166C127 165.448 127.448 165 128 165C128.552 165 129 165.448 129 166Z'
            fill='#303341'
          />
          <circle cx={134} cy={166} r={1} fill='#303341' />
          <circle cx={140} cy={166} r={1} fill='#303341' />
          <circle cx={146} cy={166} r={1} fill='#303341' />
          <circle cx={152} cy={166} r={1} fill='#303341' />
          <circle cx={158} cy={166} r={1} fill='#303341' />
          <circle cx={164} cy={166} r={1} fill='#303341' />
          <circle cx={170} cy={166} r={1} fill='#303341' />
          <circle cx={176} cy={166} r={1} fill='#303341' />
          <circle cx={182} cy={166} r={1} fill='#303341' />
          <circle cx={188} cy={166} r={1} fill='#303341' />
          <circle cx={194} cy={166} r={1} fill='#303341' />
          <circle cx={200} cy={166} r={1} fill='#303341' />
          <circle cx={2} cy={70} r={1} fill='#303341' />
          <circle cx={8} cy={70} r={1} fill='#303341' />
          <path
            d='M15 70C15 70.5523 14.5523 71 14 71C13.4477 71 13 70.5523 13 70C13 69.4477 13.4477 69 14 69C14.5523 69 15 69.4477 15 70Z'
            fill='#303341'
          />
          <path
            d='M21 70C21 70.5523 20.5523 71 20 71C19.4477 71 19 70.5523 19 70C19 69.4477 19.4477 69 20 69C20.5523 69 21 69.4477 21 70Z'
            fill='#303341'
          />
          <path
            d='M27 70C27 70.5523 26.5523 71 26 71C25.4477 71 25 70.5523 25 70C25 69.4477 25.4477 69 26 69C26.5523 69 27 69.4477 27 70Z'
            fill='#303341'
          />
          <path
            d='M33 70C33 70.5523 32.5523 71 32 71C31.4477 71 31 70.5523 31 70C31 69.4477 31.4477 69 32 69C32.5523 69 33 69.4477 33 70Z'
            fill='#303341'
          />
          <path
            d='M39 70C39 70.5523 38.5523 71 38 71C37.4477 71 37 70.5523 37 70C37 69.4477 37.4477 69 38 69C38.5523 69 39 69.4477 39 70Z'
            fill='#303341'
          />
          <path
            d='M45 70C45 70.5523 44.5523 71 44 71C43.4477 71 43 70.5523 43 70C43 69.4477 43.4477 69 44 69C44.5523 69 45 69.4477 45 70Z'
            fill='#303341'
          />
          <path
            d='M51 70C51 70.5523 50.5523 71 50 71C49.4477 71 49 70.5523 49 70C49 69.4477 49.4477 69 50 69C50.5523 69 51 69.4477 51 70Z'
            fill='#303341'
          />
          <path
            d='M57 70C57 70.5523 56.5523 71 56 71C55.4477 71 55 70.5523 55 70C55 69.4477 55.4477 69 56 69C56.5523 69 57 69.4477 57 70Z'
            fill='#303341'
          />
          <path
            d='M63 70C63 70.5523 62.5523 71 62 71C61.4477 71 61 70.5523 61 70C61 69.4477 61.4477 69 62 69C62.5523 69 63 69.4477 63 70Z'
            fill='#303341'
          />
          <path
            d='M69 70C69 70.5523 68.5523 71 68 71C67.4477 71 67 70.5523 67 70C67 69.4477 67.4477 69 68 69C68.5523 69 69 69.4477 69 70Z'
            fill='#303341'
          />
          <path
            d='M75 70C75 70.5523 74.5523 71 74 71C73.4477 71 73 70.5523 73 70C73 69.4477 73.4477 69 74 69C74.5523 69 75 69.4477 75 70Z'
            fill='#303341'
          />
          <path
            d='M81 70C81 70.5523 80.5523 71 80 71C79.4477 71 79 70.5523 79 70C79 69.4477 79.4477 69 80 69C80.5523 69 81 69.4477 81 70Z'
            fill='#303341'
          />
          <path
            d='M87 70C87 70.5523 86.5523 71 86 71C85.4477 71 85 70.5523 85 70C85 69.4477 85.4477 69 86 69C86.5523 69 87 69.4477 87 70Z'
            fill='#303341'
          />
          <path
            d='M93 70C93 70.5523 92.5523 71 92 71C91.4477 71 91 70.5523 91 70C91 69.4477 91.4477 69 92 69C92.5523 69 93 69.4477 93 70Z'
            fill='#303341'
          />
          <path
            d='M99 70C99 70.5523 98.5523 71 98 71C97.4477 71 97 70.5523 97 70C97 69.4477 97.4477 69 98 69C98.5523 69 99 69.4477 99 70Z'
            fill='#303341'
          />
          <path
            d='M105 70C105 70.5523 104.552 71 104 71C103.448 71 103 70.5523 103 70C103 69.4477 103.448 69 104 69C104.552 69 105 69.4477 105 70Z'
            fill='#303341'
          />
          <path
            d='M111 70C111 70.5523 110.552 71 110 71C109.448 71 109 70.5523 109 70C109 69.4477 109.448 69 110 69C110.552 69 111 69.4477 111 70Z'
            fill='#303341'
          />
          <path
            d='M117 70C117 70.5523 116.552 71 116 71C115.448 71 115 70.5523 115 70C115 69.4477 115.448 69 116 69C116.552 69 117 69.4477 117 70Z'
            fill='#303341'
          />
          <path
            d='M123 70C123 70.5523 122.552 71 122 71C121.448 71 121 70.5523 121 70C121 69.4477 121.448 69 122 69C122.552 69 123 69.4477 123 70Z'
            fill='#303341'
          />
          <path
            d='M129 70C129 70.5523 128.552 71 128 71C127.448 71 127 70.5523 127 70C127 69.4477 127.448 69 128 69C128.552 69 129 69.4477 129 70Z'
            fill='#303341'
          />
          <circle cx={134} cy={70} r={1} fill='#303341' />
          <circle cx={140} cy={70} r={1} fill='#303341' />
          <circle cx={146} cy={70} r={1} fill='#303341' />
          <circle cx={152} cy={70} r={1} fill='#303341' />
          <circle cx={158} cy={70} r={1} fill='#303341' />
          <circle cx={164} cy={70} r={1} fill='#303341' />
          <circle cx={170} cy={70} r={1} fill='#303341' />
          <circle cx={176} cy={70} r={1} fill='#303341' />
          <circle cx={182} cy={70} r={1} fill='#303341' />
          <circle cx={188} cy={70} r={1} fill='#303341' />
          <circle cx={194} cy={70} r={1} fill='#303341' />
          <circle cx={200} cy={70} r={1} fill='#303341' />
          <circle cx={2} cy={190} r={1} fill='#303341' />
          <circle cx={8} cy={190} r={1} fill='#303341' />
          <path
            d='M15 190C15 190.552 14.5523 191 14 191C13.4477 191 13 190.552 13 190C13 189.448 13.4477 189 14 189C14.5523 189 15 189.448 15 190Z'
            fill='#303341'
          />
          <path
            d='M21 190C21 190.552 20.5523 191 20 191C19.4477 191 19 190.552 19 190C19 189.448 19.4477 189 20 189C20.5523 189 21 189.448 21 190Z'
            fill='#303341'
          />
          <path
            d='M27 190C27 190.552 26.5523 191 26 191C25.4477 191 25 190.552 25 190C25 189.448 25.4477 189 26 189C26.5523 189 27 189.448 27 190Z'
            fill='#303341'
          />
          <path
            d='M33 190C33 190.552 32.5523 191 32 191C31.4477 191 31 190.552 31 190C31 189.448 31.4477 189 32 189C32.5523 189 33 189.448 33 190Z'
            fill='#303341'
          />
          <path
            d='M39 190C39 190.552 38.5523 191 38 191C37.4477 191 37 190.552 37 190C37 189.448 37.4477 189 38 189C38.5523 189 39 189.448 39 190Z'
            fill='#303341'
          />
          <path
            d='M45 190C45 190.552 44.5523 191 44 191C43.4477 191 43 190.552 43 190C43 189.448 43.4477 189 44 189C44.5523 189 45 189.448 45 190Z'
            fill='#303341'
          />
          <path
            d='M51 190C51 190.552 50.5523 191 50 191C49.4477 191 49 190.552 49 190C49 189.448 49.4477 189 50 189C50.5523 189 51 189.448 51 190Z'
            fill='#303341'
          />
          <path
            d='M57 190C57 190.552 56.5523 191 56 191C55.4477 191 55 190.552 55 190C55 189.448 55.4477 189 56 189C56.5523 189 57 189.448 57 190Z'
            fill='#303341'
          />
          <path
            d='M63 190C63 190.552 62.5523 191 62 191C61.4477 191 61 190.552 61 190C61 189.448 61.4477 189 62 189C62.5523 189 63 189.448 63 190Z'
            fill='#303341'
          />
          <path
            d='M69 190C69 190.552 68.5523 191 68 191C67.4477 191 67 190.552 67 190C67 189.448 67.4477 189 68 189C68.5523 189 69 189.448 69 190Z'
            fill='#303341'
          />
          <path
            d='M75 190C75 190.552 74.5523 191 74 191C73.4477 191 73 190.552 73 190C73 189.448 73.4477 189 74 189C74.5523 189 75 189.448 75 190Z'
            fill='#303341'
          />
          <path
            d='M81 190C81 190.552 80.5523 191 80 191C79.4477 191 79 190.552 79 190C79 189.448 79.4477 189 80 189C80.5523 189 81 189.448 81 190Z'
            fill='#303341'
          />
          <path
            d='M87 190C87 190.552 86.5523 191 86 191C85.4477 191 85 190.552 85 190C85 189.448 85.4477 189 86 189C86.5523 189 87 189.448 87 190Z'
            fill='#303341'
          />
          <path
            d='M93 190C93 190.552 92.5523 191 92 191C91.4477 191 91 190.552 91 190C91 189.448 91.4477 189 92 189C92.5523 189 93 189.448 93 190Z'
            fill='#303341'
          />
          <path
            d='M99 190C99 190.552 98.5523 191 98 191C97.4477 191 97 190.552 97 190C97 189.448 97.4477 189 98 189C98.5523 189 99 189.448 99 190Z'
            fill='#303341'
          />
          <path
            d='M105 190C105 190.552 104.552 191 104 191C103.448 191 103 190.552 103 190C103 189.448 103.448 189 104 189C104.552 189 105 189.448 105 190Z'
            fill='#303341'
          />
          <path
            d='M111 190C111 190.552 110.552 191 110 191C109.448 191 109 190.552 109 190C109 189.448 109.448 189 110 189C110.552 189 111 189.448 111 190Z'
            fill='#303341'
          />
          <path
            d='M117 190C117 190.552 116.552 191 116 191C115.448 191 115 190.552 115 190C115 189.448 115.448 189 116 189C116.552 189 117 189.448 117 190Z'
            fill='#303341'
          />
          <path
            d='M123 190C123 190.552 122.552 191 122 191C121.448 191 121 190.552 121 190C121 189.448 121.448 189 122 189C122.552 189 123 189.448 123 190Z'
            fill='#303341'
          />
          <path
            d='M129 190C129 190.552 128.552 191 128 191C127.448 191 127 190.552 127 190C127 189.448 127.448 189 128 189C128.552 189 129 189.448 129 190Z'
            fill='#303341'
          />
          <circle cx={134} cy={190} r={1} fill='#303341' />
          <circle cx={140} cy={190} r={1} fill='#303341' />
          <circle cx={146} cy={190} r={1} fill='#303341' />
          <circle cx={152} cy={190} r={1} fill='#303341' />
          <circle cx={158} cy={190} r={1} fill='#303341' />
          <circle cx={164} cy={190} r={1} fill='#303341' />
          <circle cx={170} cy={190} r={1} fill='#303341' />
          <circle cx={176} cy={190} r={1} fill='#303341' />
          <circle cx={182} cy={190} r={1} fill='#303341' />
          <circle cx={188} cy={190} r={1} fill='#303341' />
          <circle cx={194} cy={190} r={1} fill='#303341' />
          <circle cx={200} cy={190} r={1} fill='#303341' />
          <circle cx={2} cy={94} r={1} fill='#303341' />
          <circle cx={8} cy={94} r={1} fill='#303341' />
          <path
            d='M15 94C15 94.5523 14.5523 95 14 95C13.4477 95 13 94.5523 13 94C13 93.4477 13.4477 93 14 93C14.5523 93 15 93.4477 15 94Z'
            fill='#303341'
          />
          <path
            d='M21 94C21 94.5523 20.5523 95 20 95C19.4477 95 19 94.5523 19 94C19 93.4477 19.4477 93 20 93C20.5523 93 21 93.4477 21 94Z'
            fill='#303341'
          />
          <path
            d='M27 94C27 94.5523 26.5523 95 26 95C25.4477 95 25 94.5523 25 94C25 93.4477 25.4477 93 26 93C26.5523 93 27 93.4477 27 94Z'
            fill='#303341'
          />
          <path
            d='M33 94C33 94.5523 32.5523 95 32 95C31.4477 95 31 94.5523 31 94C31 93.4477 31.4477 93 32 93C32.5523 93 33 93.4477 33 94Z'
            fill='#303341'
          />
          <path
            d='M39 94C39 94.5523 38.5523 95 38 95C37.4477 95 37 94.5523 37 94C37 93.4477 37.4477 93 38 93C38.5523 93 39 93.4477 39 94Z'
            fill='#303341'
          />
          <path
            d='M45 94C45 94.5523 44.5523 95 44 95C43.4477 95 43 94.5523 43 94C43 93.4477 43.4477 93 44 93C44.5523 93 45 93.4477 45 94Z'
            fill='#303341'
          />
          <path
            d='M51 94C51 94.5523 50.5523 95 50 95C49.4477 95 49 94.5523 49 94C49 93.4477 49.4477 93 50 93C50.5523 93 51 93.4477 51 94Z'
            fill='#303341'
          />
          <path
            d='M57 94C57 94.5523 56.5523 95 56 95C55.4477 95 55 94.5523 55 94C55 93.4477 55.4477 93 56 93C56.5523 93 57 93.4477 57 94Z'
            fill='#303341'
          />
          <path
            d='M63 94C63 94.5523 62.5523 95 62 95C61.4477 95 61 94.5523 61 94C61 93.4477 61.4477 93 62 93C62.5523 93 63 93.4477 63 94Z'
            fill='#303341'
          />
          <path
            d='M69 94C69 94.5523 68.5523 95 68 95C67.4477 95 67 94.5523 67 94C67 93.4477 67.4477 93 68 93C68.5523 93 69 93.4477 69 94Z'
            fill='#303341'
          />
          <path
            d='M75 94C75 94.5523 74.5523 95 74 95C73.4477 95 73 94.5523 73 94C73 93.4477 73.4477 93 74 93C74.5523 93 75 93.4477 75 94Z'
            fill='#303341'
          />
          <path
            d='M81 94C81 94.5523 80.5523 95 80 95C79.4477 95 79 94.5523 79 94C79 93.4477 79.4477 93 80 93C80.5523 93 81 93.4477 81 94Z'
            fill='#303341'
          />
          <path
            d='M87 94C87 94.5523 86.5523 95 86 95C85.4477 95 85 94.5523 85 94C85 93.4477 85.4477 93 86 93C86.5523 93 87 93.4477 87 94Z'
            fill='#303341'
          />
          <path
            d='M93 94C93 94.5523 92.5523 95 92 95C91.4477 95 91 94.5523 91 94C91 93.4477 91.4477 93 92 93C92.5523 93 93 93.4477 93 94Z'
            fill='#303341'
          />
          <path
            d='M99 94C99 94.5523 98.5523 95 98 95C97.4477 95 97 94.5523 97 94C97 93.4477 97.4477 93 98 93C98.5523 93 99 93.4477 99 94Z'
            fill='#303341'
          />
          <path
            d='M105 94C105 94.5523 104.552 95 104 95C103.448 95 103 94.5523 103 94C103 93.4477 103.448 93 104 93C104.552 93 105 93.4477 105 94Z'
            fill='#303341'
          />
          <path
            d='M111 94C111 94.5523 110.552 95 110 95C109.448 95 109 94.5523 109 94C109 93.4477 109.448 93 110 93C110.552 93 111 93.4477 111 94Z'
            fill='#303341'
          />
          <path
            d='M117 94C117 94.5523 116.552 95 116 95C115.448 95 115 94.5523 115 94C115 93.4477 115.448 93 116 93C116.552 93 117 93.4477 117 94Z'
            fill='#303341'
          />
          <path
            d='M123 94C123 94.5523 122.552 95 122 95C121.448 95 121 94.5523 121 94C121 93.4477 121.448 93 122 93C122.552 93 123 93.4477 123 94Z'
            fill='#303341'
          />
          <path
            d='M129 94C129 94.5523 128.552 95 128 95C127.448 95 127 94.5523 127 94C127 93.4477 127.448 93 128 93C128.552 93 129 93.4477 129 94Z'
            fill='#303341'
          />
          <circle cx={134} cy={94} r={1} fill='#303341' />
          <circle cx={140} cy={94} r={1} fill='#303341' />
          <circle cx={146} cy={94} r={1} fill='#303341' />
          <circle cx={152} cy={94} r={1} fill='#303341' />
          <circle cx={158} cy={94} r={1} fill='#303341' />
          <circle cx={164} cy={94} r={1} fill='#303341' />
          <circle cx={170} cy={94} r={1} fill='#303341' />
          <circle cx={176} cy={94} r={1} fill='#303341' />
          <circle cx={182} cy={94} r={1} fill='#303341' />
          <circle cx={188} cy={94} r={1} fill='#303341' />
          <circle cx={194} cy={94} r={1} fill='#303341' />
          <circle cx={200} cy={94} r={1} fill='#303341' />
          <circle cx={2} cy={118} r={1} fill='#303341' />
          <circle cx={8} cy={118} r={1} fill='#303341' />
          <path
            d='M15 118C15 118.552 14.5523 119 14 119C13.4477 119 13 118.552 13 118C13 117.448 13.4477 117 14 117C14.5523 117 15 117.448 15 118Z'
            fill='#303341'
          />
          <path
            d='M21 118C21 118.552 20.5523 119 20 119C19.4477 119 19 118.552 19 118C19 117.448 19.4477 117 20 117C20.5523 117 21 117.448 21 118Z'
            fill='#303341'
          />
          <path
            d='M27 118C27 118.552 26.5523 119 26 119C25.4477 119 25 118.552 25 118C25 117.448 25.4477 117 26 117C26.5523 117 27 117.448 27 118Z'
            fill='#303341'
          />
          <path
            d='M33 118C33 118.552 32.5523 119 32 119C31.4477 119 31 118.552 31 118C31 117.448 31.4477 117 32 117C32.5523 117 33 117.448 33 118Z'
            fill='#303341'
          />
          <path
            d='M39 118C39 118.552 38.5523 119 38 119C37.4477 119 37 118.552 37 118C37 117.448 37.4477 117 38 117C38.5523 117 39 117.448 39 118Z'
            fill='#303341'
          />
          <path
            d='M45 118C45 118.552 44.5523 119 44 119C43.4477 119 43 118.552 43 118C43 117.448 43.4477 117 44 117C44.5523 117 45 117.448 45 118Z'
            fill='#303341'
          />
          <path
            d='M51 118C51 118.552 50.5523 119 50 119C49.4477 119 49 118.552 49 118C49 117.448 49.4477 117 50 117C50.5523 117 51 117.448 51 118Z'
            fill='#303341'
          />
          <path
            d='M57 118C57 118.552 56.5523 119 56 119C55.4477 119 55 118.552 55 118C55 117.448 55.4477 117 56 117C56.5523 117 57 117.448 57 118Z'
            fill='#303341'
          />
          <path
            d='M63 118C63 118.552 62.5523 119 62 119C61.4477 119 61 118.552 61 118C61 117.448 61.4477 117 62 117C62.5523 117 63 117.448 63 118Z'
            fill='#303341'
          />
          <path
            d='M69 118C69 118.552 68.5523 119 68 119C67.4477 119 67 118.552 67 118C67 117.448 67.4477 117 68 117C68.5523 117 69 117.448 69 118Z'
            fill='#303341'
          />
          <path
            d='M75 118C75 118.552 74.5523 119 74 119C73.4477 119 73 118.552 73 118C73 117.448 73.4477 117 74 117C74.5523 117 75 117.448 75 118Z'
            fill='#303341'
          />
          <path
            d='M81 118C81 118.552 80.5523 119 80 119C79.4477 119 79 118.552 79 118C79 117.448 79.4477 117 80 117C80.5523 117 81 117.448 81 118Z'
            fill='#303341'
          />
          <path
            d='M87 118C87 118.552 86.5523 119 86 119C85.4477 119 85 118.552 85 118C85 117.448 85.4477 117 86 117C86.5523 117 87 117.448 87 118Z'
            fill='#303341'
          />
          <path
            d='M93 118C93 118.552 92.5523 119 92 119C91.4477 119 91 118.552 91 118C91 117.448 91.4477 117 92 117C92.5523 117 93 117.448 93 118Z'
            fill='#303341'
          />
          <path
            d='M99 118C99 118.552 98.5523 119 98 119C97.4477 119 97 118.552 97 118C97 117.448 97.4477 117 98 117C98.5523 117 99 117.448 99 118Z'
            fill='#303341'
          />
          <path
            d='M105 118C105 118.552 104.552 119 104 119C103.448 119 103 118.552 103 118C103 117.448 103.448 117 104 117C104.552 117 105 117.448 105 118Z'
            fill='#303341'
          />
          <path
            d='M111 118C111 118.552 110.552 119 110 119C109.448 119 109 118.552 109 118C109 117.448 109.448 117 110 117C110.552 117 111 117.448 111 118Z'
            fill='#303341'
          />
          <path
            d='M117 118C117 118.552 116.552 119 116 119C115.448 119 115 118.552 115 118C115 117.448 115.448 117 116 117C116.552 117 117 117.448 117 118Z'
            fill='#303341'
          />
          <path
            d='M123 118C123 118.552 122.552 119 122 119C121.448 119 121 118.552 121 118C121 117.448 121.448 117 122 117C122.552 117 123 117.448 123 118Z'
            fill='#303341'
          />
          <path
            d='M129 118C129 118.552 128.552 119 128 119C127.448 119 127 118.552 127 118C127 117.448 127.448 117 128 117C128.552 117 129 117.448 129 118Z'
            fill='#303341'
          />
          <circle cx={134} cy={118} r={1} fill='#303341' />
          <circle cx={140} cy={118} r={1} fill='#303341' />
          <circle cx={146} cy={118} r={1} fill='#303341' />
          <circle cx={152} cy={118} r={1} fill='#303341' />
          <circle cx={158} cy={118} r={1} fill='#303341' />
          <circle cx={164} cy={118} r={1} fill='#303341' />
          <circle cx={170} cy={118} r={1} fill='#303341' />
          <circle cx={176} cy={118} r={1} fill='#303341' />
          <circle cx={182} cy={118} r={1} fill='#303341' />
          <circle cx={188} cy={118} r={1} fill='#303341' />
          <circle cx={194} cy={118} r={1} fill='#303341' />
          <circle cx={200} cy={118} r={1} fill='#303341' />
        </g>
        <rect x={41} y={66} width={125} height={61} rx={10} fill='#F4F4F4' />
        <rect
          x={42.5}
          y={70.5}
          width={117}
          height={53}
          rx={8.5}
          fill='#FF9B63'
        />
        <rect
          x={42.5}
          y={70.5}
          width={117}
          height={53}
          rx={8.5}
          stroke='#242631'
          strokeWidth={3}
        />
        <path
          d='M58.8864 109V105.455L67.1705 97.7841C67.875 97.1023 68.4659 96.4886 68.9432 95.9432C69.428 95.3977 69.7955 94.8636 70.0455 94.3409C70.2955 93.8106 70.4205 93.2386 70.4205 92.625C70.4205 91.9432 70.2652 91.3561 69.9545 90.8636C69.6439 90.3636 69.2197 89.9811 68.6818 89.7159C68.1439 89.4432 67.5341 89.3068 66.8523 89.3068C66.1402 89.3068 65.5189 89.4508 64.9886 89.7386C64.4583 90.0265 64.0492 90.4394 63.7614 90.9773C63.4735 91.5152 63.3295 92.1553 63.3295 92.8977H58.6591C58.6591 91.375 59.0038 90.053 59.6932 88.9318C60.3826 87.8106 61.3485 86.9432 62.5909 86.3295C63.8333 85.7159 65.2652 85.4091 66.8864 85.4091C68.553 85.4091 70.0038 85.7045 71.2386 86.2955C72.4811 86.8788 73.447 87.6894 74.1364 88.7273C74.8258 89.7652 75.1705 90.9545 75.1705 92.2955C75.1705 93.1742 74.9962 94.0417 74.6477 94.8977C74.3068 95.7538 73.697 96.7045 72.8182 97.75C71.9394 98.7879 70.7008 100.034 69.1023 101.489L65.7045 104.818V104.977H75.4773V109H58.8864ZM78.1903 104.909V101.034L87.9062 85.7273H91.2472V91.0909H89.2699L83.1449 100.784V100.966H96.9517V104.909H78.1903ZM89.3608 109V103.727L89.4517 102.011V85.7273H94.0653V109H89.3608ZM117.818 84.6364L110.318 112.5H106.148L113.648 84.6364H117.818ZM128.432 109L138.08 89.9091V89.75H126.841V85.7273H143.17V89.8068L133.511 109H128.432Z'
          fill='#242631'
        />
        <circle cx={101.5} cy={35.5} r={10.5} fill='white' />
        <line
          x1={91.8639}
          y1={38.2262}
          x2={47.8639}
          y2={69.2262}
          stroke='#F4F4F4'
          strokeWidth={3}
        />
        <path d='M108.5 38.9997L152 67.4999' stroke='white' strokeWidth={3} />
        <line
          x1={92.8602}
          y1={39.2289}
          x2={46.8603}
          y2={71.4281}
          stroke='#242631'
          strokeWidth={3}
        />
        <line
          x1={107.814}
          y1={40.7399}
          x2={155.814}
          y2={71.7399}
          stroke='#242631'
          strokeWidth={3}
        />
        <circle
          cx={100.5}
          cy={36.5}
          r={8}
          fill='#FF9B63'
          stroke='#242631'
          strokeWidth={3}
        />
        <rect x={71} y={115} width={68} height={60} rx={30} fill='#F4F4F4' />
        <rect
          x={74.6914}
          y={117.639}
          width={58.7136}
          height={55.4266}
          rx={27.7133}
          fill='#FF9B63'
          stroke='#242631'
        />
        <mask
          id='mask0_671_26676'
          style={{
            maskType: 'alpha',
          }}
          maskUnits='userSpaceOnUse'
          x={74}
          y={117}
          width={60}
          height={57}
        >
          <rect
            x={74.6895}
            y={117.639}
            width={58.7136}
            height={55.4266}
            rx={27.7133}
            fill='#FF9B63'
            stroke='#242631'
          />
        </mask>
        <g mask='url(#mask0_671_26676)' />
        <mask
          id='mask1_671_26676'
          style={{
            maskType: 'alpha',
          }}
          maskUnits='userSpaceOnUse'
          x={74}
          y={117}
          width={60}
          height={57}
        >
          <rect
            x={74.6895}
            y={117.639}
            width={58.7136}
            height={55.4266}
            rx={27.7133}
            fill='#FF9B63'
            stroke='#242631'
          />
        </mask>
        <g mask='url(#mask1_671_26676)'>
          <rect
            width={82}
            height={6}
            transform='matrix(1 0 0 -1 95.0059 126.426)'
            fill='#242631'
          />
          <rect x={95.0059} y={129.426} width={82} height={62} fill='#242631' />
        </g>
        <path
          d='M127.428 145.352C127.428 160.391 115.357 172.565 100.488 172.565C85.6192 172.565 73.5488 160.391 73.5488 145.352C73.5488 130.313 85.6192 118.139 100.488 118.139C115.357 118.139 127.428 130.313 127.428 145.352Z'
          fill='#FF9B63'
          stroke='#242631'
          strokeWidth={2}
        />
        <path
          d='M123.5 145C123.5 157.989 113.193 168.5 100.5 168.5C87.8073 168.5 77.5 157.989 77.5 145C77.5 132.011 87.8073 121.5 100.5 121.5C113.193 121.5 123.5 132.011 123.5 145Z'
          fill='#63F3BE'
          stroke='white'
        />
        <path
          d='M120.305 145.626C120.305 156.898 111.408 165.992 100.488 165.992C89.5669 165.992 80.6699 156.898 80.6699 145.626C80.6699 134.354 89.5669 125.261 100.488 125.261C111.408 125.261 120.305 134.354 120.305 145.626Z'
          fill='#63F3BE'
          stroke='#242631'
          strokeWidth={2}
        />
        <path
          d='M97.293 150.009L110.917 133.446'
          stroke='white'
          strokeLinecap='round'
        />
        <path
          d='M103.834 148.591L91.2782 137.905'
          stroke='white'
          strokeWidth={2}
          strokeLinecap='round'
        />
        <circle
          cx={100.697}
          cy={146.026}
          r={1.77364}
          fill='#242631'
          stroke='white'
        />
        <path
          d='M97.8906 148.711L109.38 134.743'
          stroke='#242631'
          strokeLinecap='round'
        />
        <path
          d='M103.406 147.516L92.8177 138.504'
          stroke='#242631'
          strokeWidth={2}
          strokeLinecap='round'
        />
        <circle cx={100.761} cy={145.352} r={1.91741} fill='#242631' />
        <path
          d='M101.035 126L101.035 128.739'
          stroke='#242631'
          strokeWidth={2}
          strokeLinecap='round'
        />
        <path
          d='M101.035 163L101.035 165.739'
          stroke='#242631'
          strokeWidth={2}
          strokeLinecap='round'
        />
        <path
          d='M81 145.626H83.1913'
          stroke='#242631'
          strokeWidth={2}
          strokeLinecap='round'
        />
        <path
          d='M118 145.626H120.191'
          stroke='#242631'
          strokeWidth={2}
          strokeLinecap='round'
        />
        <path
          d='M114.752 159.752L113 158'
          stroke='#242631'
          strokeWidth={2}
          strokeLinecap='round'
        />
        <path
          d='M88.752 133.752L86.9996 132'
          stroke='#242631'
          strokeWidth={2}
          strokeLinecap='round'
        />
        <path
          d='M113 133.752L114.752 132'
          stroke='#242631'
          strokeWidth={2}
          strokeLinecap='round'
        />
        <path
          d='M87 159.752L88.7523 158'
          stroke='#242631'
          strokeWidth={2}
          strokeLinecap='round'
        />
        <path
          d='M26.0421 145.189L19.0003 130.438L27.4292 134.69L37.312 110.003L63.5223 127.516L72.4072 115.382L76.4591 118.168C76.4591 118.168 67.5146 130.872 65.9614 133.203L40.2503 116.092L31.9193 136.974L40.416 141.262L26.0322 145.205L26.0421 145.189Z'
          fill='#FF9B63'
        />
        <path
          d='M27.404 150.217L20.493 135.736L28.7642 139.912L38.4635 115.681L64.1906 132.867L72.9123 120.951L76.8882 123.687C76.8882 123.687 68.1099 136.162 66.5933 138.444L41.3572 121.645L33.1813 142.136L41.5273 146.343L27.4069 150.206L27.404 150.217Z'
          fill='#F4F4F4'
        />
        <path
          d='M24.9179 146.845L17.8761 132.093L26.305 136.346L36.1878 111.659L62.4051 129.168L71.29 117.034L75.3419 119.819C75.3419 119.819 66.4016 132.53 64.8443 134.854L39.1331 117.743L30.8021 138.625L39.2988 142.913L24.9151 146.856L24.9179 146.845Z'
          fill='#FC3561'
        />
        <path
          d='M25.8234 146.032L18.7815 131.28L27.2104 135.533L37.0932 110.846L63.3035 128.359L72.1884 116.225L76.2404 119.01C76.2404 119.01 67.2959 131.715 65.7427 134.046L40.0316 116.934L31.7006 137.816L40.1972 142.104L25.8135 146.047L25.8234 146.032Z'
          stroke='#242631'
          strokeWidth={1.5}
          strokeMiterlimit={10}
        />
        <path
          d='M173.48 47.5208L176.42 64.0544L169.236 57.5384L152.699 79.2821L131.533 54.7727L119.416 64.3586L116.166 60.4923C116.166 60.4923 128.497 50.3579 130.672 48.4744L151.454 72.4494L165.414 54.0491L158.173 47.4795L173.494 47.5084L173.48 47.5208Z'
          fill='#FF9B63'
        />
        <path
          d='M172.686 43.006L175.57 59.2358L168.521 52.8386L152.291 74.1816L131.514 50.1277L119.619 59.5423L116.43 55.7457C116.43 55.7457 128.534 45.7927 130.66 43.9483L151.056 67.4839L164.755 49.4291L157.641 42.9804L172.68 43.0162L172.686 43.006Z'
          fill='#F4F4F4'
        />
        <path
          d='M175.045 46.1882L177.984 62.7219L170.8 56.2058L154.263 77.9496L133.089 53.4423L120.973 63.0282L117.722 59.162C117.722 59.162 130.051 49.0195 132.229 47.144L153.01 71.119L166.97 52.7187L159.729 46.1491L175.051 46.178L175.045 46.1882Z'
          fill='#63F3BE'
        />
        <path
          d='M173.928 46.7463L176.867 63.2799L169.683 56.7638L153.146 78.5076L131.98 53.9982L119.864 63.5841L116.613 59.7178C116.613 59.7178 128.944 49.5834 131.12 47.6998L151.901 71.6749L165.861 53.2746L158.62 46.705L173.942 46.7339L173.928 46.7463Z'
          stroke='#242631'
          strokeWidth={1.5}
          strokeMiterlimit={10}
        />
        <path
          d='M18 117L19.88 112.86L24 111L19.88 109.14L18 105L16.16 109.14L12 111L16.16 112.86L18 117Z'
          fill='#FF9B63'
        />
        <path
          d='M85 25L86.5667 21.55L90 20L86.5667 18.45L85 15L83.4667 18.45L80 20L83.4667 21.55L85 25Z'
          fill='#FF9B63'
        />
        <path
          d='M193 111L194.88 106.86L199 105L194.88 103.14L193 99L191.16 103.14L187 105L191.16 106.86L193 111Z'
          fill='#FF9B63'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_671_26676'
          x1={102}
          y1={0}
          x2={102}
          y2={204}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF9B63' stopOpacity={0.37} />
          <stop offset={1} stopColor='#F5F5F5' stopOpacity={0} />
        </linearGradient>
        <clipPath id='clip0_671_26676'>
          <rect width={204} height={204} rx={102} fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
