import { PaymentAuthLink } from '@interfaces'
import { api } from '@utils/api/api'

export interface BankCashoutData {
  accountId: string
  amount: number
  currency: string
}

export interface AddMoneyData {
  accountId: string
  amount: number
  currency: string
  redirectUrl: string
}

export async function getAuthLinkPayment(
  data: AddMoneyData,
): Promise<PaymentAuthLink> {
  return await api.post('/api/v2/payments/top-up/payment', data)
}
