export type StyleConditionTuple = [string, boolean]

/**
 * Utility to build big strings based on conditionals. Its perfect to create class prop strings
 * @param styles
 * @returns
 */
export const stringBuilder = (styles: Array<StyleConditionTuple>): string => {
  return styles.reduce((acc: string, i: StyleConditionTuple, idx: number) => {
    const [styleClass, value] = i
    const space = idx === 0 ? '' : ' '
    return value ? `${acc}${space}${styleClass}` : acc
  }, '')
}
