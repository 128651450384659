import { useTranslation } from '@hooks'
import {
  ListType,
  passwordRequirementTypes,
} from '../../../interfaces/typePasswordRequirement'
import { Fragment } from 'react'
import RequirementListItem from '../../../components/requirementListItem/requirementListItem'

interface IPasswordRequirement {
  id: string
  type: passwordRequirementTypes
  text: string
  regex: RegExp
}
interface PasswordRequirementsProps {
  password: string
  email: string
  validationErrors?: string[]
}

const PasswordRequirements = ({
  password,
  email,
  validationErrors = [],
}: PasswordRequirementsProps): JSX.Element => {
  const { t } = useTranslation()
  const passwordRequirements: IPasswordRequirement[] = [
    {
      id: '1',
      type: passwordRequirementTypes.MIN_LENGTH_VALIDATION,
      text: t('forgotPassword.MIN_LENGTH_VALIDATION'),
      regex: /.{8,}/,
    },
    {
      id: '2',
      type: passwordRequirementTypes.UPPER_CASE_VALIDATION,
      text: t('forgotPassword.UPPER_CASE_VALIDATION'),
      regex: /[A-Z]/,
    },
    {
      id: '3',
      type: passwordRequirementTypes.LOWER_CASE_VALIDATION,
      text: t('forgotPassword.LOWER_CASE_VALIDATION'),
      regex: /[a-z]/,
    },
    {
      id: '4',
      type: passwordRequirementTypes.NUMBER_VALIDATION,
      text: t('forgotPassword.NUMBER_VALIDATION'),
      regex: /\d/,
    },
    {
      id: '5',
      type: passwordRequirementTypes.SPECIAL_CHARACTER_VALIDATION,
      text: t('forgotPassword.SPECIAL_CHARACTER_VALIDATION'),
      regex: /[^a-zA-Z0-9]/,
    },
    {
      id: '6',
      type: passwordRequirementTypes.USERNAME_INCLUSION_VALIDATION,
      text: t('forgotPassword.USERNAME_INCLUSION_VALIDATION'),
      regex: new RegExp(`^(?!.*${email.split('@')[0]}).*$`),
    },
  ]

  const UserNameValidation = ({
    isValid,
    requirement,
  }: {
    isValid: boolean
    requirement: IPasswordRequirement
  }): JSX.Element => {
    return (
      <RequirementListItem
        listType={
          isValid
            ? password
              ? ListType.SUCCESS
              : ListType.NORMAL
            : ListType.ERROR
        }
        text={requirement.text}
      />
    )
  }
  return (
    <>
      {passwordRequirements.map((requirement) => {
        const isValid = requirement.regex.test(String(password))
        const hasError = validationErrors.includes(requirement.type)
        if (hasError) {
          return (
            <RequirementListItem
              key={requirement.id}
              listType={ListType.ERROR}
              text={requirement.text}
            />
          )
        }
        return (
          <Fragment key={requirement.id}>
            {requirement.type ===
            passwordRequirementTypes.USERNAME_INCLUSION_VALIDATION ? (
              <UserNameValidation isValid={isValid} requirement={requirement} />
            ) : (
              <RequirementListItem
                listType={
                  password && isValid ? ListType.SUCCESS : ListType.NORMAL
                }
                text={requirement.text}
              />
            )}
          </Fragment>
        )
      })}
    </>
  )
}

export default PasswordRequirements
