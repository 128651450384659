import { ChevronRight } from '@assets/icons'
import { ListElementProps } from './listElement.props'

export function ListElement({
  title,
  subtitle,
  target,
  to,
  icon,
  onClick,
}: ListElementProps): JSX.Element {
  const arrow = <ChevronRight color={'iconPrimaryInitial'} size={36} />

  return (
    <>
      {to && to !== '' ? (
        <a
          href={to}
          target={target}
          className='list__menu__element d-flex justify-space-between'
          onClick={onClick}
          aria-hidden='true'
        >
          <div className='list__menu__element__title d-flex align-center'>
            <div className='icon d-flex align-center justify-center'>
              {icon}
            </div>
            <div className='title__wrapper ml-3'>
              <div className='title mb-1'>{title}</div>
              <span className='subtitle'>{subtitle}</span>
            </div>
          </div>
          <div className='list__menu__element__append d-flex align-center'>
            {arrow}
          </div>
        </a>
      ) : (
        <div
          className='list__menu__element d-flex justify-space-between'
          onClick={onClick}
          aria-hidden='true'
        >
          <div className='list__menu__element__title d-flex align-center'>
            <div className='icon d-flex align-center justify-center'>
              {icon}
            </div>
            <div className='title__wrapper ml-3'>
              <div className='title'>{title}</div>
              {subtitle ? (
                <span className='subtitle mt-1'>{subtitle}</span>
              ) : null}
            </div>
          </div>
          <div className='list__menu__element__append d-flex align-center'>
            {arrow}
          </div>
        </div>
      )}
    </>
  )
}
