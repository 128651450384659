export enum TopUpEvents {
  modalOpened = '[TopUp] Modal opened', // Unused on Mobile yet
  selectBankAccount = '[TopUp] Select bank account', // Unused on Mobile yet
  continueAfterSelection = '[TopUp] Clicked on Continue button after selecting an account', // Unused on Mobile yet
  continueClicked = '[TopUp] Clicked on Continue button', // Unused on Mobile yet
  doneClicked = '[TopUp] Clicked on Done button', // Unused on Mobile yet
  paymentSubmitted = '[TopUp] Payment submitted', // Unused on Mobile yet
  redirectedToBank = '[TopUp] Clicked on Continue to be redirected to a bank', // Unused on Mobile yet
  requested = '[TopUp] Requested top up', // Unused on WebApp
  creditCard = '[TopUp] Credit card initiated', // Unused on WebApp
  creditCardRecurring = '[TopUp] Recurring credit card initiated', // Unused on WebApp
}

export enum CashoutEvents {
  continueClicked = '[Cashout] Clicked on Continue button', // Unused on Mobile yet
  selectBankAccount = '[Cashout] Select bank account', // Unused on Mobile yet
  continueAfterSelection = '[Cashout] Clicked on Continue button after selecting an account', // Unused on Mobile yet
  modalOpened = '[Cashout] Opened cashout modal', // Unused on Mobile yet
}

export enum LimitOrderEvents {
  flowStarted = '[LimitOrders] Limit order flow started',
  priceAggressive = '[LimitOrders] Price too aggressive',
  pricePassive = '[LimitOrders] Price too passive',
  bufferModal = '[LimitOrders] Buffer info opened in preorder',
  confirmedPreorder = '[LimitOrders] Limit order confirmed by the user',
  detailOpened = '[LimitOrders] Limit order detail opened',
  cancelMarketClosed = '[LimitOrders] User tried to cancel an order while market was closed',
}

export enum BuySellEvents {
  flowStarted = '[BuySellFlow] Flow started', // Unused on Mobile yet
  goToPreOrder = '[BuySellFlow] Clicked on Continue to PreOrder', // Unused on Mobile yet
  confirmOrder = '[BuySellFlow] Clicked on Confirm Order button', // Unused on Mobile yet
  sendOrder = '[BuySellFlow] Clicked on Send Order button', // Unused on Mobile yet
  orderSentFE = '[BuySellFlow] Order Confirmed - FE',
  etfOpened = '[BuySellFlow] Opened ETF',
}

export enum CompanyDetailsEvents {
  opened = '[CompanyDetails] Opened Company Details', // Unused on Mobile yet
  visited = '[CompanyDetails] Company visited',
  info = '[CompanyDetails] Opened info section',
}

export enum CollectionEvents {
  viewAll = '[Collections] Clicked on View all', // Unused on Mobile yet
  clickedCollection = '[Collections] User clicked on collection', // Unused on WebApp
}

export enum WatchlistEvents {
  viewAll = '[Watchlist] Clicked on View all', // Unused on Mobile yet
}

export enum LogInEvents {
  submit = '[Login] Login Form Submitted', // Unused on Mobile yet
  biometricsChanged = '[Login] Set new biometrics value', // Unused on WebApp
  biometricsNotEnabled = '[Login] Not enabled biometrics', // Unused on WebApp
  biometricsSuccess = '[Login] Succeeded to authenticate with biometrics', // Unused on WebApp
  biometricsFailure = '[Login] Failed to authenticate with biometrics', // Unused on WebApp
}

export enum ResetPasswordEvents {
  flowStarted = '[Reset password] Clicked Reset Password Link', // Unused on Mobile yet
  formSubmitted = '[Reset password] Reset password Form Submitted', // Unused on Mobile yet
  backToLogin = '[Reset password] Clicked Back to login after reset Link', // Unused on Mobile yet
}

export enum PromotionalBannerEvents {
  clickedBanner = '[PromotionalBanners] User clicked on banner', // Unused on WebApp
  clickedThreeDots = '[PromotionalBanners] User clicked on three dots action', // Unused on WebApp
  clickedAction = '[PromotionalBanners] User clicked action under three dots menu', // Unused on WebApp
  promoInfoClosed = '[PromotionalBanners] User clicked close button on Promo Info Screen', // Unused on WebApp
  promoInfoClickedCTA = '[PromotionalBanners] User clicked CTA button on Promo Info Screen', // Unused on WebApp
}

export enum NavigationEvents {
  menuItemClicked = '[MenuNavigation] Item Clicked', // Unused on Mobile yet
}

export enum APIEvents {
  apiCalled = '[API Request] Called API',
}

export enum NotificationEvents {
  oneSignal = 'OneSignal', // Unused on WebApp
  opened = '[Notifications] Notification opened', // Unused on WebApp
  received = '[Notifications] Notification received', // Unused on WebApp
  initialized = '[Notifications] Notifications initialized', // Unused on WebApp
  subscribed = '[Notifications] Notifications subscribed', // Unused on WebApp
  userSet = '[Notifications] User set on OneSignal', // Unused on WebApp
}

export enum TabClickedEvents {
  discover = '[TabClicked] Discover tab clicked', // Unused on WebApp
  transactions = '[TabClicked] Transactions tab clicked', // Unused on WebApp
}

export enum PSD2Events {
  continueToBank = '[PSD2] Continue to bank - TL', // Unused on WebApp
  navigateToTrueLayer = '[PSD2] Navigating to TL', // Unused on WebApp
  userSawInfo = '[PSD2]: User see the info about how the confirmation works', // Unused on WebApp
  connectedBank = '[PSD2] Connect bank', // Unused on WebApp
}

export enum HomeScreenEvents {
  button = '[Home] Portfolio menu item clicked', // Unused on WebApp
  moreOptions = '[Home] More options item clicked', // Unused on WebApp
}

export enum OnboardingEvents {
  educativeVeriff = '[Onboarding] Start button clicked in the educative Veriff screen', // Unused on WebApp
  educativeVeriffAbandoned = '[Onboarding] Veriff step abandoned in educative screen', // Unused on WebApp
  emailVerified = '[Onboarding] Sign up completed', // Unused on WebApp
  contractSigned = '[Onboarding] Contract signed', // Unused on WebApp
  veriffAbandoned = '[Onboarding] Veriff step abandoned', // Unused on WebApp
}

export enum TransactionsEvents {
  filterApplied = '[Transactions] Filter applied',
}
