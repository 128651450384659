import { MoneyOperationsSteps } from '@features'
import {
  AssetType,
  OnboardingStatus,
  OrderOperationTypeEnum,
} from '@interfaces'
import AssessmentModal, {
  AssessmentModalMode,
} from '@screens/discover/components/assessmentModal/assessmentModal'
import { trackingService } from '@services'
import { IncompleteAssessmentModal } from '@shared/modals/incompleteAssessmentModal/incompleteAssessmentModal'
import { RootState, setOperationStockId, setOrderOperationType } from '@store'
import { BuySellEvents } from '@utils/eventTracker/eventKeys'
import { isSavingAsset } from '@utils/helpers'
import { setMoneyOperationStep } from 'features/moneyOperations/thunk'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { OnboardingRequiredModal } from '@shared/modals'

type BuySellActionProps = { id: string; type: AssetType; origin: string }

export interface BuySellActions {
  buy: (props: BuySellActionProps) => void
  sell: (props: BuySellActionProps) => void
  modals: JSX.Element
}

export function useBuyAndSellActions(): BuySellActions {
  const dispatch = useDispatch()

  const [incompleteAssestmentModal, setIncompleteAssestmentModal] =
    useState(false)
  const [koAssestmentResultModal, setKoAssestmentResultModal] = useState(false)
  const [onboardingModal, setOnboardingModal] = useState(false)

  const { onboardingStatus, savingsPlans } = useSelector(
    (state: RootState) => state,
  )

  const onBoardingCompleted =
    onboardingStatus?.onboarding?.onboardingStatus ==
    OnboardingStatus.onboardingCompleted

  const buy = ({ id, type }: BuySellActionProps): void => {
    //We store the asset and operation being processed in global state.
    // This allows us to return the user to the previous step if they need to complete a test before continuing
    dispatch(setOperationStockId(id))
    dispatch(setOrderOperationType(OrderOperationTypeEnum.buy))
    const isSaving = isSavingAsset(type)

    // If asset is saving plan and assetment is undone
    const testUndone = isSaving && !savingsPlans?.riskAssessmentEvaluation
    if (testUndone) {
      setIncompleteAssestmentModal(true)
      return
    }

    // If asset is saving plan and assetment is not passed
    const testNotPassed =
      isSaving && !savingsPlans?.riskAssessmentEvaluation?.testPassed

    if (testNotPassed) {
      setKoAssestmentResultModal(true)
      return
    }

    if (!onBoardingCompleted) {
      setOnboardingModal(true)
      return
    }

    trackingService.trackEvent({
      event: BuySellEvents.flowStarted,
      props: { origin, type: 'buy' },
    })

    dispatch(setOperationStockId(id))
    dispatch(setOrderOperationType(OrderOperationTypeEnum.buy))
    dispatch(setMoneyOperationStep(MoneyOperationsSteps.operateAsset))
  }

  const sell = ({ id }: BuySellActionProps): void => {
    trackingService.trackEvent({
      event: BuySellEvents.flowStarted,
      props: { origin, type: 'sell' },
    })
    dispatch(setOperationStockId(id))
    dispatch(setOrderOperationType(OrderOperationTypeEnum.sell))
    dispatch(setMoneyOperationStep(MoneyOperationsSteps.operateAsset))
  }

  const onStartTrading = (): void => {
    setKoAssestmentResultModal(false)
    setIncompleteAssestmentModal(false)
    dispatch(setMoneyOperationStep(MoneyOperationsSteps.operateAsset))
  }

  return {
    buy,
    sell,
    modals: (
      <>
        {incompleteAssestmentModal && (
          <IncompleteAssessmentModal
            onStartTrading={onStartTrading}
            close={() => setIncompleteAssestmentModal(false)}
          />
        )}
        {koAssestmentResultModal && (
          <AssessmentModal
            onClose={() => {
              setKoAssestmentResultModal(false)
            }}
            onStartTrading={onStartTrading}
            step={AssessmentModalMode.results}
          />
        )}
        {onboardingModal && (
          <OnboardingRequiredModal close={() => setOnboardingModal(false)} />
        )}
      </>
    ),
  }
}
