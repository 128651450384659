import './grid.styles.scss'
import { useEffect, useState } from 'react'
import { GridProps } from './grid.props'

export function Grid({ numberOfColumns, data }: GridProps): JSX.Element {
  const [rowedData, setRowedData] = useState(null)

  useEffect(() => {
    const resultData = []
    data.forEach((ele, idx) => {
      const row = Math.floor(idx / numberOfColumns)
      if (!resultData[row]) {
        resultData[row] = []
      }
      resultData[row].push(ele)
    })
    setRowedData(resultData)
  }, [data, numberOfColumns])

  return (
    <table className='w-100'>
      <tbody className='w-100 justify-center'>
        {rowedData?.length
          ? rowedData.map((row, index) => (
              <tr key={index} className='w-100 gridRow d-flex mb-2'>
                {Array.from({ length: numberOfColumns }, (_, i) => (
                  <td className='gridRow__element d-flex' key={i}>
                    {row[i]}
                  </td>
                ))}
              </tr>
            ))
          : null}
      </tbody>
    </table>
  )
}
