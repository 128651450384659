import './textField.styles.scss'
import { ChangeEvent, FocusEvent, useMemo, useRef, useState } from 'react'
import { stringBuilder } from '@components/utils'
import { Text } from './../text/text'
import { Spacer } from './../spacer'
import { FieldProps, InputTheme } from './textField.props'

export const TextField = (props: FieldProps): JSX.Element => {
  const style = props.style || 'paragraph1'
  const type = props.type || 'text'
  const name = props.inputProps?.name
  const label = props.inputProps?.label
  const theme: InputTheme = props.theme || 'normal'

  const inputRef = useRef<HTMLInputElement>()

  const [focused, setFocused] = useState<boolean>()

  const [lastestValue, setLastestValue] = useState('')

  const wrapperFieldStyle = useMemo(
    () =>
      stringBuilder([
        ['input-wrapper', true],
        ['--has-value', !!props.inputProps?.value || !!lastestValue],
        ['--with-focus', focused],
        ['--has-label', !!label],
        ['--size-small', props.size === 'small'],
        ['--size-normal', props.size === 'normal' || !props.size],
        ['--size-large', props.size === 'large'],
        [
          '--size-huge',
          (!!props.children && !props.size) || props.size === 'huge',
        ],
        ['--theme-subtle', theme === 'subtle'],
        ['--theme-normal', theme === 'normal'],
      ]),
    [
      props.inputProps?.value,
      props.size,
      props.children,
      lastestValue,
      focused,
      label,
      theme,
    ],
  )

  const textFieldStyle = useMemo(() => {
    return stringBuilder([
      ['input-wrapper__element', !props.fieldType],
      [props.fieldType, !!props.fieldType],
      ['--with-left-content', !!props.left],
      ['--with-right-content', !!props.right],
      [style, !!style],
    ])
  }, [props.fieldType, props.left, props.right, style])

  const nameTo = props.register
    ? props.register(name || '', { required: true })
    : undefined

  const commonInputProps = {
    id: name,
    autoComplete: 'off',
    className: textFieldStyle,
    type,
  }

  const allInputProps = {
    ...commonInputProps,
    ...props.inputProps,
    ...nameTo,
    // If we provide an onchage prop we will use it over the onchage funcion from the form
    onChange: (e: ChangeEvent<HTMLInputElement>) => {
      if (nameTo?.onChange) {
        nameTo
          ?.onChange(e)
          .then(() => {
            // Do nothing
          })
          .catch(() => {
            // Do nothing
          })
      }
      if (props.inputProps?.onChange) {
        props.inputProps?.onChange(e)
      }
      setLastestValue(e?.target?.value)
    },
    onFocus: (e: FocusEvent<HTMLInputElement, Element>) => {
      setFocused(true)
      if (props?.inputProps?.onFocus) {
        props?.inputProps?.onFocus(e)
      }
    },
    // This is to make sure we keep onBlur function call for the form if provided
    onBlur: (e: FocusEvent<HTMLInputElement>) => {
      setFocused(false)
      if (props?.inputProps?.onBlur) {
        props?.inputProps?.onBlur(e)
      }
      if (nameTo?.onBlur) {
        nameTo
          .onBlur(e)
          .then(() => {
            // Do nothing
          })
          .catch(() => {
            // Do nothing
          })
      }
    },
  }

  return (
    <>
      <div
        className={wrapperFieldStyle}
        aria-hidden
        onClick={() => {
          if (inputRef?.current) {
            inputRef?.current?.focus()
          }
        }}
      >
        {props.children && (
          <div className='input-wrapper__children-content'>
            {props.children}
          </div>
        )}
        {props.left && (
          <div
            className={`input-wrapper__cell --left-cell ${
              props.onLeftClick ? 'cursor-pointer' : ''
            }`}
            onClick={props.onLeftClick ? props.onLeftClick : null}
            aria-hidden
          >
            {props.left}
          </div>
        )}
        <div className='d-flex d-flex-col w-100'>
          <div className='d-flex align-center h-100'>
            <div
              data-testid={'text-field'}
              className='d-flex d-flex-col  align-start input-wrapper__cell'
            >
              <input {...allInputProps} ref={inputRef} />
              {label && (
                <label
                  className={`input-wrapper__label ${style}`}
                  htmlFor={name}
                >
                  {label}
                </label>
              )}
            </div>
            {props.right && (
              <div
                className={`input-wrapper__cell --width-fit-content ${
                  props.onRightClick ? 'cursor-pointer' : ''
                }`}
                onClick={props.onRightClick ? props.onRightClick : null}
                aria-hidden
              >
                {props.right}
              </div>
            )}
          </div>
          {props.bottomAnnotation && (
            <div className='input-wrapper__cell justify-end input-wrapper__annotation-text'>
              {props.bottomAnnotation}
            </div>
          )}
        </div>
      </div>
      {props.errors && (
        <>
          <Spacer preset='tiny' />
          <Text preset='caption' text={props.errors} color='tertiary' />
        </>
      )}
    </>
  )
}
