import { AnyAction, createReducer, PayloadAction } from '@reduxjs/toolkit'
import {
  AssetType,
  AssetTypeData,
  GlobalSearchState,
  SearchAssetsState,
} from '@interfaces'
import { clearQuery, fetchAssets } from './search.thunk'

export const mapSearchResponseToArr = (payload: SearchAssetsState) => {
  return Object.keys(payload).map((k: AssetType) => {
    const item = payload[k] as AssetTypeData
    return {
      items: item?.items || [],
      totalItems: item?.totalItems,
      type: k,
    }
  })
}

const rejectedAction = (action: AnyAction): boolean =>
  action.type.includes(fetchAssets.rejected.toString())

const initialState: GlobalSearchState = {
  initialData: [],
  headerSearchData: [],
  discoverSearchData: [],
  loading: false,
  error: null,
  query: null,
  type: 'initial',
  origin: null,
}

export const reducers = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchAssets.pending, (acc, action) => {
      acc.error = null
      acc.query = action?.meta?.arg?.query
      acc.loading = true
      acc.type = action?.meta?.arg?.type || null
      acc.origin = action?.meta?.arg?.origin || null
    })
    .addCase(
      fetchAssets.fulfilled,
      (acc, action: PayloadAction<SearchAssetsState>) => {
        const payload = action?.payload
        if (payload) {
          const data = mapSearchResponseToArr(payload)

          if (acc.type === 'initial') {
            acc.initialData = data
          } else {
            if (acc.origin === 'discoverSearch') {
              acc.discoverSearchData = data
            }
            if (acc.origin === 'headerSearch') {
              acc.headerSearchData = data
            }
          }
        }
        acc.loading = false
      },
    )
    .addCase(clearQuery, (acc) => {
      acc.headerSearchData = []
      acc.discoverSearchData = []
      acc.origin = null
      acc.loading = false
      acc.query = null
      acc.error = null
    })
    .addMatcher(rejectedAction, (acc, { error }) => {
      acc.error = error.message
      acc.loading = false
    })
})

export default reducers
