import {
  stringBuilder,
  UIBackgroundColorProps,
  getBgColorClass,
} from 'components/utils'
import './avatar.styles.scss'
import { useMemo } from 'react'

export enum AvatarRoundnessEnum {
  none = 'none',
  tiny = 'tiny',
  small = 'small',
  medium = 'medium',
  large = 'large',
  huge = 'huge',
  full = 'full',
}

export enum AvatarSizeEnum {
  small = 'small',
  medium = 'medium',
  'mediumPlus' = 'mediumPlus',
  large = 'large',
  huge = 'huge',
  massive = 'massive',
  fluid = 'fluid',
}

export enum ObjectFitEnum {
  cover = 'cover',
  contain = 'contain',
}

export type AvatarSizePreset = keyof typeof AvatarSizeEnum
export type AvatarRoundnessPreset = keyof typeof AvatarRoundnessEnum
export type ObjectFitPreset = keyof typeof ObjectFitEnum

export interface AvatarProps {
  image: React.ReactNode | string
  fallback?: React.ReactNode | string
  size?: AvatarSizePreset
  roundness?: AvatarRoundnessPreset
  imgAlt?: string
  objectFit?: ObjectFitPreset
  bordered?: boolean
}

export const Avatar = ({
  image,
  fallback,
  size = 'small',
  roundness = 'small',
  imgAlt = '',
  objectFit = 'cover',
  bgColor = 'transparent',
  bordered = false,
}: AvatarProps & UIBackgroundColorProps): JSX.Element => {
  const contentIsURL = typeof image === 'string'
  const fallbackIsURL = typeof fallback === 'string'

  const getImageOrReactNode = (
    item: string | React.ReactNode,
  ): React.ReactNode => {
    return item ? (
      typeof item === 'string' ? (
        <img src={item} alt={imgAlt} className='--fade-in-animation-fast' />
      ) : (
        item
      )
    ) : null
  }

  const imageElem = getImageOrReactNode(image)
  const fallbackElem = getImageOrReactNode(fallback)

  const classes = useMemo(() => {
    return stringBuilder([
      ['avatar', true],
      ['--size-small', size === 'small'],
      ['--size-medium', size === 'medium'],
      ['--size-medium-plus', size === 'mediumPlus'],
      ['--size-large', size === 'large'],
      ['--size-huge', size === 'huge'],
      ['--size-massive', size === 'massive'],
      ['--size-fluid', size === 'fluid'],
      ['--roundness-none', roundness === 'none'],
      ['--roundness-tiny', roundness === 'tiny'],
      ['--roundness-small', roundness === 'small'],
      ['--roundness-medium', roundness === 'medium'],
      ['--roundness-large', roundness === 'large'],
      ['--roundness-huge', roundness === 'huge'],
      ['--roundness-full', roundness === 'full'],
      ['--object-fit-cover', objectFit === 'cover'],
      ['--object-fit-contain', objectFit === 'contain'],
      ['--bordered', bordered],
      [getBgColorClass(bgColor), !!bgColor],
    ])
  }, [bgColor, objectFit, roundness, size, bordered])

  return (
    <div className={`${classes}`}>
      {fallback ? (
        <div
          className={`avatar__cont ${
            !fallbackIsURL ? '--no-image-content' : ''
          }`}
        >
          {fallbackElem}
        </div>
      ) : null}
      {image ? (
        <div
          className={`avatar__cont ${
            !contentIsURL ? '--no-image-content' : ''
          }`}
        >
          {imageElem}
        </div>
      ) : null}
    </div>
  )
}
