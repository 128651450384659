import { TabBarProps, TabItem } from './buttonedTabBar.props'
import './buttonedTabBar.styles.scss'

export function ButtonedTabs<T>({
  tabsStyle,
  tabs,
  activeTab,
  setActiveTab,
  loading = false,
}: TabBarProps<T>): JSX.Element {
  const handleClick = (id: T): void => {
    if (id && tabs?.length && !loading) {
      setActiveTab(id)
    }
  }

  const getItem = (item: TabItem<T>): JSX.Element => {
    return (
      <button
        key={(item.id as string)?.toString()}
        className={`buttoned-tab-bar__tab ${
          activeTab === item.id ? 'buttoned-tab-bar__tab--active' : ''
        }`}
        onClick={() => handleClick(item.id)}
      >
        {item.title}
      </button>
    )
  }

  return (
    <div className='buttoned-tab-bar'>
      <div className={`buttoned-tab-bar__tabs ${tabsStyle as string}`}>
        {tabs?.length ? tabs.map((t: TabItem<T>) => getItem(t)) : null}
      </div>
    </div>
  )
}
