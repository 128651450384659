import { UIIcon, UIIconColorProps, getIconColorSVGFill } from 'components/utils'

export const RecurringPaymentIcon = ({
  color,
  size = 24,
}: UIIcon & UIIconColorProps): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 21 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2 20C1.45 20 0.979 19.8043 0.587 19.413C0.195667 19.021 0 18.55 0 18V4C0 3.45 0.195667 2.97933 0.587 2.588C0.979 2.196 1.45 2 2 2H3V0H5V2H13V0H15V2H16C16.55 2 17.021 2.196 17.413 2.588C17.8043 2.97933 18 3.45 18 4V10H16V8H2V18H9V20H2ZM16 22C14.7833 22 13.7207 21.621 12.812 20.863C11.904 20.1043 11.3333 19.15 11.1 18H12.65C12.8667 18.7333 13.2793 19.3333 13.888 19.8C14.496 20.2667 15.2 20.5 16 20.5C16.9667 20.5 17.7917 20.1583 18.475 19.475C19.1583 18.7917 19.5 17.9667 19.5 17C19.5 16.0333 19.1583 15.2083 18.475 14.525C17.7917 13.8417 16.9667 13.5 16 13.5C15.5167 13.5 15.0667 13.5873 14.65 13.762C14.2333 13.9373 13.8667 14.1833 13.55 14.5H15V16H11V12H12.5V13.425C12.95 12.9917 13.475 12.6457 14.075 12.387C14.675 12.129 15.3167 12 16 12C17.3833 12 18.5627 12.4877 19.538 13.463C20.5127 14.4377 21 15.6167 21 17C21 18.3833 20.5127 19.5627 19.538 20.538C18.5627 21.5127 17.3833 22 16 22ZM2 6H16V4H2V6ZM2 6V4V6Z'
        fill={color ? getIconColorSVGFill(color) : 'currentColor'}
      />
    </svg>
  )
}
