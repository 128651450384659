import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import { CollectionDetails, ReducerState, CompanyStocks } from '@interfaces'
import { fetchCollectionDetails, fetchCollectionDetailsVirtual } from './thunk'

// 💩 = ExtraCollectionDetails is for virtual pagination
export interface ExtraCollectionDetails {
  totalItems: number
  currentPage: number
  totalPages: number
  paginationLoader: boolean
  size: number
  virtualShow: CompanyStocks[]
}

const initialState: ReducerState & CollectionDetails & ExtraCollectionDetails =
  {
    loading: false,
    error: null,
    stocks: [],
    stocksDetails: [],
    title: '',
    description: '',
    icon: '',
    background: '',
    // Virtual pagination
    currentPage: 0,
    totalItems: 0,
    totalPages: 0,
    paginationLoader: false,
    size: 20,
    virtualShow: [],
  }

export default createReducer(initialState, (builder) =>
  builder
    .addCase(fetchCollectionDetailsVirtual.pending, (acc) => {
      acc.paginationLoader = true
      acc.error = null
    })
    .addCase(
      fetchCollectionDetailsVirtual.fulfilled,
      (acc, { meta: { arg } }) => {
        const page = arg
        acc.virtualShow = [
          ...acc.virtualShow,
          ...acc.stocksDetails.slice(
            page * acc.size,
            page * acc.size + acc.size,
          ),
        ]
        acc.paginationLoader = false
        acc.error = null
      },
    )

    .addCase(fetchCollectionDetails.pending, (acc) => {
      acc.loading = true
      acc.error = null
    })
    .addCase(
      fetchCollectionDetails.fulfilled,
      (acc, action: PayloadAction<CollectionDetails>) => {
        const { stocks, title, background, description, icon, stocksDetails } =
          action.payload
        acc.stocks = stocks
        acc.title = title
        acc.background = background
        acc.description = description
        acc.stocksDetails = stocksDetails
        acc.totalItems = stocksDetails.length
        acc.totalPages = Math.ceil(stocksDetails.length / acc.size)
        acc.virtualShow = stocksDetails.slice(0, acc.size)
        acc.icon = icon
        acc.loading = false
      },
    )
    .addCase(fetchCollectionDetails.rejected, (acc, { error }) => {
      acc.loading = false
      acc.error = error.message || ''
    }),
)
