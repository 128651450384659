import { createReducer } from '@reduxjs/toolkit'
import { MoneyOperationsSteps } from './moneyOperations.props'
import {
  clearMoneyOperationsState,
  goToPrevStep,
  setMoneyOperationStep,
  setOperationType,
} from './thunk'
import { MoneyOperationTypeEnum } from '@interfaces'

export interface MoneyOperationsState {
  prevStep: MoneyOperationsSteps | null
  currentStep: MoneyOperationsSteps | null
  stepsHistory: MoneyOperationsSteps[]
  operationType?: MoneyOperationTypeEnum | null
}

const initialState: MoneyOperationsState = {
  stepsHistory: [],
  currentStep: null,
  prevStep: null,
  operationType: null,
}

const reducers = createReducer(initialState, (builder) => {
  builder
    .addCase(setOperationType, (acc, { payload }) => {
      acc.operationType = payload
    })
    .addCase(goToPrevStep, (acc) => {
      acc.stepsHistory.pop()
      acc.prevStep = acc.stepsHistory[acc.stepsHistory?.length - 2]
      acc.currentStep = acc.stepsHistory[acc.stepsHistory?.length - 1]
    })
    .addCase(setMoneyOperationStep, (acc, { payload }) => {
      acc.prevStep = acc.currentStep
      acc.currentStep = payload
      acc.stepsHistory.push(payload)
    })
    .addCase(clearMoneyOperationsState, (acc) => {
      acc.currentStep = initialState.currentStep
      acc.prevStep = initialState.prevStep
      acc.stepsHistory = initialState.stepsHistory
      acc.operationType = initialState.operationType
    })
})

export default reducers
