import { supportEmail } from './externalUrls'

export function contactSupport(subject?: string, body?: string): string {
  let mailTo = `mailto:${supportEmail}`

  if (subject) {
    mailTo += `?subject=${subject}`
  }
  if (body) {
    mailTo += `&body=${body}`
  }

  return mailTo
}
