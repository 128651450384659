export const typography = {
  usd: 'feesStructure.USD',
  cad: 'feesStructure.CAD',
  eur: 'feesStructure.EUR',
  minFee: 'feesStructure.minFee',
  newYorkExchange: 'feesStructure.newYorkExchange',
  northAmerica: 'feesStructure.northAmerica',
  feesPerShare: 'feesStructure.feesPerShare',
  restWorld: 'feesStructure.restWorld',
  feePerTransactionAmount: 'feesStructure.feePerTransactionAmount',
  torontoStockExchange: 'feesStructure.torontoStockExchange',
  budapestStockExchange: 'feesStructure.budapestStockExchange',
  HUF: 'feesStructure.HUF',
  deutscheBörse: 'feesStructure.deutscheBörse',
  johannesburgStockExchanget: 'feesStructure.johannesburgStockExchanget',
  ZAR: 'feesStructure.ZAR',
  londonStockExchange: 'feesStructure.londonStockExchange',
  GBP: 'feesStructure.GBP',
  londonStockExchangeIOB: 'feesStructure.londonStockExchangeIOB',
  NASDAQ_OMX_Copenhagen: 'feesStructure.NASDAQ_OMX_Copenhagen',
  DKK: 'feesStructure.DKK',
  NASDAQ_OMX_Stockholm: 'feesStructure.NASDAQ_OMX_Stockholm',
  SEK: 'feesStructure.SEK',
  NASDAQ_OMX_Helsinki: 'feesStructure.NASDAQ_OMX_Helsinki',
  osloStockExchange: 'feesStructure.osloStockExchange',
  NOK: 'feesStructure.NOK',
  pragueStockExchange: 'feesStructure.pragueStockExchange',
  CZK: 'feesStructure.CZK',
  SIX_Swiss_Exchange: 'feesStructure.SIX_Swiss_Exchange',
  CHF: 'feesStructure.CHF',
  warsawStockExchange: 'feesStructure.warsawStockExchange',
  PLN: 'feesStructure.PLN',
  australianSecuritiesExchange: 'feesStructure.australianSecuritiesExchange',
  AUD: 'feesStructure.AUD',
  hongKongExchanges: 'feesStructure.hongKongExchanges',
  HKD: 'feesStructure.HKD',
  singaporeExchange: 'feesStructure.singaporeExchange',
  SGD: 'feesStructure.SGD',
  tokyoStockExchange: 'feesStructure.tokyoStockExchange',
  JPY: 'feesStructure.JPY',
  safekeeping: 'feesStructure.safekeeping',
  monthly: 'feesStructure.monthly',
  yearly: 'feesStructure.yearly',
  foreign: 'feesStructure.foreign',
  foreignDetails: 'feesStructure.foreignDetails',
  local: 'feesStructure.local',
  exchangeRates: 'feesStructure.exchangeRates',
  currencyExchange: 'feesStructure.currencyExchange',
  exchangeRatesDetails: 'feesStructure.exchangeRatesDetails',
}
