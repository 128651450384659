import '@screens/login/login.styles.scss'
import '@screens/signup/signup.styles.scss'
import '@screens/resetPassword/resetPassword.styles.scss'
import './download.styles.scss'
import { useTranslation } from '@hooks'
import { ReactComponent as LogoIcon } from '@assets/svg/logo_icon.svg'
import { ReactComponent as AppStoreIcon } from '@assets/svg/appstore.svg'
import { ReactComponent as GooglePlayIcon } from '@assets/svg/googleplay.svg'

export function Download(): JSX.Element {
  const { t } = useTranslation()
  return (
    <div className='login__widget download reset__widget__elements py-5 px-3'>
      <LogoIcon fill='var(--logoIcon)' className='mb-4' />
      <h2 className='signup__widget__elements__info__title mb-1'>
        {t('signup.phoneRegistration.download')}
      </h2>
      <p className='signup__widget__elements__info__description mb-4'>
        {t('screen.sizeLimited.optional')}
      </p>
      <div className='download__content__icons'>
        <AppStoreIcon className='mb-4' />
        <GooglePlayIcon />
      </div>
    </div>
  )
}
