import { createAsyncThunk } from '@reduxjs/toolkit'
import { getCollectionDetails } from '@services'
import { CollectionDetails } from '@interfaces'

// 💩 Virtual pagination action
export const fetchCollectionDetailsVirtual = createAsyncThunk<void, number>(
  'fetchCollectionDetailsVirtual',
  (page) => {
    return new Promise((resolve) => {
      if (page > 0) {
        resolve()
      }
    })
  },
)

export const fetchCollectionDetails = createAsyncThunk<
  CollectionDetails,
  string
>('fetchCollectionDetails', async (collectionId) => {
  return getCollectionDetails(collectionId)
})
