import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import { resources } from './i18n/index.js'
import LanguageDetector from 'i18next-browser-languagedetector'

const options = {
  resources,
  debug: false,
  // lng: 'es', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
  // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
  // if you're using a language detector, do not define the lng option
  fallbackLng: 'en-US',
  fallbackNS: 'translation',
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
}

if (localStorage.getItem('heySettings')) {
  const settings = JSON.parse(localStorage.getItem('heySettings') || '')
  if (settings.lang) {
    options.lng = settings.lang
  }
}

const i18n = i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init(options)

export default i18n
