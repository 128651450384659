import './collectionItem.styles.scss'
import { CollectionItemProps } from '@interfaces'
import { Link } from 'react-router-dom'
import { Sort } from '@assets/svg'
import { t } from 'i18next'

export function CollectionItem({
  className,
  id,
  description,
  icon,
  stocks,
}: CollectionItemProps): JSX.Element {
  return (
    <Link
      to={`/collections/${id}`}
      key={id}
      className={`${className} collectionItem border-radius-default d-flex`}
    >
      <img className='collectionItem__image' src={icon} alt={description} />
      <div className='collectionItem__title text-left pa-2'>
        <p className='subtitle3'>{description}</p>
        <div className='d-flex d-flex-row align-center'>
          <Sort className='collectionItem__icon' />
          <p className='paragraph2'>
            {stocks}
            {t('collections.shares')}
          </p>
        </div>
      </div>
    </Link>
  )
}
