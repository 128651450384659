import {
  sanitizeNumber,
  whatDecimalSeparator,
  whatThousandsSeparator,
} from './helpers'
import { getFormatLang } from './langs'

export const trimDecimalsManually = (
  number: string,
  decimalsAmount: number,
  decimalsSeparator: string,
): string => {
  let newNumber = number
  const splittedNumber = number.split(new RegExp(`\\${decimalsSeparator}`))

  const hasDecimals = splittedNumber[1]
  if (hasDecimals) {
    const decimals = splittedNumber[1].slice(0, decimalsAmount)
    newNumber = `${splittedNumber[0]}${decimalsSeparator}${decimals}`
  }

  return newNumber
}

export const formatInputNumber = (
  number: string,
  lang: string,
  decimalAmount = 2,
): string => {
  if (!number) return number

  const thousandsSeparator = whatThousandsSeparator(lang)
  const decimalsSeparator = whatDecimalSeparator(lang)

  const firstCharacterIsNotANumber =
    number.length === 1 && !new RegExp(`[0-9]`).test(number)

  // If first character is different to a number return empty string
  if (firstCharacterIsNotANumber) return ''

  // Count how many times the decimal character appears in the string
  const decimalIsRepeated =
    (number?.match(new RegExp(`\\${decimalsSeparator}`, 'g'))?.length || 0) > 1

  // If first character is different to a number return empty string
  const lastCharDifferentToAllowed = new RegExp(
    `[^0-9${decimalsSeparator};]+$`,
  ).test(number)

  const lastCharacterIsDecimalsSep = new RegExp(`\\${decimalsSeparator}$`).test(
    number,
  )

  if (lastCharDifferentToAllowed || decimalIsRepeated)
    return number.slice(0, -1)

  if (lastCharacterIsDecimalsSep) return number

  const valWithoutThousandsSep = number?.replace(
    new RegExp(`\\${thousandsSeparator}`, 'g'),
    '',
  )

  const trimmed = trimDecimalsManually(
    valWithoutThousandsSep,
    decimalAmount,
    decimalsSeparator,
  )

  const hasDecimalSeparatorAtEnd = new RegExp(
    `0-9\\${decimalsSeparator}$`,
  ).test(trimmed)

  const firstDecimalIsZero = new RegExp(`\\${decimalsSeparator}0+$`).test(
    trimmed,
  )

  if (hasDecimalSeparatorAtEnd || firstDecimalIsZero) return trimmed

  return Intl.NumberFormat(getFormatLang(lang), {
    maximumFractionDigits: decimalAmount,
    useGrouping: true,
  }).format(sanitizeNumber(trimmed, thousandsSeparator, decimalsSeparator))
}
