import { createAction } from '@reduxjs/toolkit'
import { MoneyOperationsSteps } from './moneyOperations.props'
import { MoneyOperationTypeEnum } from '@interfaces'

export const setMoneyOperationStep = createAction<MoneyOperationsSteps>(
  'setMoneyOperationStep',
)

export const goToPrevStep = createAction<MoneyOperationsSteps>('goToPrevStep')
export const setOperationType =
  createAction<MoneyOperationTypeEnum>('setOperationType')

export const clearMoneyOperationsState = createAction('clearState')
