import './carousel.styles.scss'
import { CarouselProps } from './carousel.props'
import { ScrollingCarousel } from '@trendyol-js/react-carousel'
import { ReactComponent as RightIcon } from '@assets/svg/next.svg'
import { ReactComponent as LeftIcon } from '@assets/svg/back.svg'
import { CollectionItem } from 'components/collectionItem/collectionItem'

export function Carousel({
  className,
  collections,
}: CarouselProps): JSX.Element {
  const style = className || ''

  return (
    <ScrollingCarousel
      className={`carousel ${style}`}
      rightIcon={
        <RightIcon fill='var(--textNeutralStrong)' className='rightIcon' />
      }
      leftIcon={<LeftIcon fill='var(--textNeutralStrong)' />}
    >
      {collections?.map((slide, i) => (
        <CollectionItem {...slide} key={i} className='collectionItem--small' />
      ))}
    </ScrollingCarousel>
  )
}
