import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import { Balances, BalanceState } from '@interfaces'
import { fetchUserBalances } from './thunk'

const initialState: BalanceState = {
  balance: null,
  cashAvailable: null,
  cashActive: null,
  maxWithdrawal: null,
  lockedCash: null,
  loading: false,
  error: null,
}

export const userBalancesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchUserBalances.pending, (acc: BalanceState) => {
      acc.loading = true
      acc.error = null
    })
    .addCase(
      fetchUserBalances.fulfilled,
      (acc, action: PayloadAction<Balances>) => {
        const { payload } = action

        acc.cashAvailable = payload.cash_available
        acc.cashActive = payload.cash_active
        acc.maxWithdrawal = payload.max_withdrawal
        acc.lockedCash = payload.locked_cash
        acc.balance = payload.balance
        acc.error = null
        acc.loading = false
      },
    )
    .addCase(fetchUserBalances.rejected, (acc, { error }) => {
      acc.loading = false
      acc.error = error.message
    })
})
