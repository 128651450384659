import './langs.styles.scss'
import { changeLang, getLang, Language, languages } from '@utils/langs'
import { ReactComponent as LangIcon } from '@assets/svg/profile/language.svg'
import { useDispatch } from 'react-redux'
import { fetchTranslations } from '@store/commonReducers/translations'
import i18next from 'i18next'

export default function Langs(): JSX.Element {
  const dispatch = useDispatch()

  const handleClick = (lang: Language): void => {
    i18next
      .changeLanguage(lang.code)
      .then(() => {
        changeLang(lang.code)
        dispatch(fetchTranslations(lang.apiLanguage))
      })
      .catch(() => {
        // Do nothing
      })
  }

  return (
    <div className='header__langs'>
      <LangIcon fill='var(--iconNeutralStrong)' />
      <div className='wrapper__langs'>
        <span className='lang__element lang__element--selected'>
          {getLang().slice(0, 2)}
        </span>
        <div className='list__langs__wrapper d-flex d-flex-col align-center justify-center'>
          {languages.map((lang) => {
            return (
              <button
                className={`lang__element`}
                key={lang.code}
                onClick={() => handleClick(lang)}
              >
                {lang.nativeName}
              </button>
            )
          })}
        </div>
      </div>
    </div>
  )
}
