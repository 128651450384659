import * as d3 from 'd3'

export interface CreatePieProps {
  container: HTMLDivElement
  width: number
  data: Array<number>
  type?: 'full' | 'semi'
  colorScheme: Array<string>
  innerRadius?: number
  padAngle?: number
  strokeColor?: string
  strokeSize?: number
  margin?: number
  innerElem?: string
}

export const createPie = ({
  container,
  width,
  data,
  type = 'full',
  colorScheme = [],
  innerRadius = 0,
  padAngle = 0,
  strokeSize = 0,
  strokeColor,
  margin = 0,
}: CreatePieProps): void => {
  const color = d3.scaleOrdinal(colorScheme || [])
  const radius = width / 2 - margin

  innerRadius = innerRadius > 1 ? 1 : innerRadius
  innerRadius = innerRadius < 0 ? 0 : innerRadius

  const height = type === 'full' ? width : width / 2

  const pie = d3
    .pie()
    .padAngle(padAngle)
    .startAngle(type === 'full' ? 0 : Math.PI)
    .value((d) => d as number)

  const svg = d3
    .create('svg')
    .attr('width', width)
    .attr('height', height)
    .attr('viewBox', [-width / 2, -height / 2, width, height])

  svg
    .append('g')
    .selectAll()
    .data(pie(data))
    .join('path')
    .attr(
      'd',
      d3
        .arc()
        .innerRadius(innerRadius * radius)
        .outerRadius(radius) as unknown as number,
    )
    .attr('id', 'item')
    .attr(
      'transform',
      `rotate(${type === 'full' ? '0' : '90'} 0 0) translate(${
        type === 'full' ? 0 : radius / 2
      } 0)`,
    )
    .attr('fill', (d: unknown) => color(d as string))
    .attr(
      'stroke',
      strokeColor?.length ? strokeColor : (d) => color(d as unknown as string),
    )
    .style('stroke-width', `${strokeSize}px`)

  const chart = container.querySelector('#chart')
  const node = svg?.node()
  chart.innerHTML = '' // it is necessary to clean the content
  if (chart && node) {
    chart.append(node as Node)
  }
}
