import { UIIcon, UIIconColorProps, getIconColorSVGFill } from 'components/utils'

export const CashoutIcon = ({
  color,
  size = 24,
}: UIIcon & UIIconColorProps): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 14 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 6.5H11.59C12.48 6.5 12.92 7.58 12.29 8.21L7.7 12.8C7.31 13.19 6.68 13.19 6.29 12.8L1.7 8.21C1.07 7.58 1.52 6.5 2.41 6.5H4V1.5C4 0.95 4.45 0.5 5 0.5H9C9.55 0.5 10 0.95 10 1.5V6.5ZM1 17.5C0.45 17.5 0 17.05 0 16.5C0 15.95 0.45 15.5 1 15.5H13C13.55 15.5 14 15.95 14 16.5C14 17.05 13.55 17.5 13 17.5H1Z'
        fill={color ? getIconColorSVGFill(color) : 'currentColor'}
      />
    </svg>
  )
}
