import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { getAgregationBanksList } from '@services'
import { BankAggregationItem } from '@interfaces'

export const clearBanksList = createAction('clearBanksList')

export const requestBanksList = createAsyncThunk<BankAggregationItem[]>(
  'requestBanksList',
  async () => {
    return getAgregationBanksList()
  },
)
