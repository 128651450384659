import { PayloadAction, createReducer } from '@reduxjs/toolkit'
import {
  clearTopUpPaymentUrl,
  fetchInfoTopUp,
  getTopUpPaymentUrl,
  clearTopUpInfo,
  fetchPaymentMethodStatus,
  setSelectedPaymentMethod,
  clearSelectedBank,
  clearRecurringPaymentOptions,
  setRecurringPaymentOptions,
  setTopUpType,
  clearTopUpType,
  setCardDetails,
  fetchValidCreditCards,
  fetchCreditCardFees,
  sendCardPayment,
  sendRecurringCardPayment,
  clearCreditCardPayment,
  setCardFlowType,
} from './thunk'
import {
  TopUpInfo,
  PaymentMethod,
  CreditCardPaymentInfo,
  CreditCardScheme,
  PeriodicityEnum,
  TopUpPaymentType,
  Money,
  CreditCardFee,
  CardFlowType,
} from '@interfaces'
import { BasicOption } from '@shared/modals'

export interface RecurringPaymentConfig {
  startingDate: BasicOption<string>
  periodicity: BasicOption<PeriodicityEnum>
  expiration: BasicOption<string>
  paymentsCount: number
}

export interface CardDetails {
  creditCardType: string
  expDate: string
  amount: Money
  cardToken: string
  last4: string
  rememberCard: boolean
}

export interface TopUpState {
  topUpInfo: TopUpInfo | null
  loading: boolean
  error: string | null
  url: string | null
  concept: string | null
  paymentMethodExpired: boolean | null
  recurringTopUpConfig: RecurringPaymentConfig
  topUpType: TopUpPaymentType
  loadingMethodStatus: boolean
  selectedPaymentMethod: PaymentMethod | undefined
  cardPayment: {
    flowType: CardFlowType
    cardPaymentFees: CreditCardFee[]
    cardDetails: CardDetails
    paymentInfo: CreditCardPaymentInfo | null
    supportedCards: CreditCardScheme[] | null
  }
}

const initialState: TopUpState = {
  topUpInfo: null,
  loading: false,
  error: null,
  url: null,
  concept: null,
  paymentMethodExpired: null,
  loadingMethodStatus: false,
  selectedPaymentMethod: undefined,
  topUpType: TopUpPaymentType.SINGLE,
  recurringTopUpConfig: {
    startingDate: {
      title: null,
      description: null,
      value: null,
    },
    periodicity: {
      title: null,
      description: null,
      value: null,
    },
    expiration: {
      title: null,
      description: null,
      value: null,
    },
    paymentsCount: null,
  },
  cardPayment: {
    flowType: null,
    cardPaymentFees: [],
    cardDetails: {
      creditCardType: null,
      expDate: null,
      amount: null,
      cardToken: null,
      last4: null,
      rememberCard: null,
    },
    paymentInfo: {
      id: null,
      externalId: null,
      status: 'Pending',
      amount: { amount: null, currency: null },
      fee: {
        percentage: 0,
      },
      totalAmount: { amount: 0, currency: null },
      card: {
        scheme: null,
        last4: null,
      },
      redirectUrl: null,
    },
    supportedCards: null,
  },
}

const reducers = createReducer(initialState, (builder) => {
  builder
    .addCase(sendCardPayment.pending, (acc) => {
      acc.loading = true
      acc.error = null
    })
    .addCase(sendCardPayment.rejected, (acc, { error }) => {
      acc.loading = false
      acc.error = error?.message
    })
    .addCase(
      sendCardPayment.fulfilled,
      (acc, action: PayloadAction<CreditCardPaymentInfo>) => {
        acc.loading = false
        acc.error = null
        const { payload } = action
        acc.cardPayment.paymentInfo.id = payload.id
        acc.cardPayment.paymentInfo.externalId = payload.externalId
        acc.cardPayment.paymentInfo.status = payload.status
        acc.cardPayment.paymentInfo.amount = payload.amount
        acc.cardPayment.paymentInfo.fee = payload.fee
        acc.cardPayment.paymentInfo.totalAmount = payload.totalAmount
        acc.cardPayment.paymentInfo.card = payload.card
        acc.cardPayment.paymentInfo.redirectUrl = payload.redirectUrl
      },
    )
    .addCase(sendRecurringCardPayment.pending, (acc) => {
      acc.loading = true
      acc.error = null
    })
    .addCase(sendRecurringCardPayment.rejected, (acc, { error }) => {
      acc.loading = false
      acc.error = error?.message
    })
    .addCase(
      sendRecurringCardPayment.fulfilled,
      (acc, action: PayloadAction<CreditCardPaymentInfo>) => {
        acc.loading = false
        acc.error = null
        const { payload } = action
        acc.cardPayment.paymentInfo.id = payload.id
        acc.cardPayment.paymentInfo.externalId = payload.externalId
        acc.cardPayment.paymentInfo.redirectUrl = payload.redirectUrl
      },
    )
    .addCase(fetchInfoTopUp.fulfilled, (acc, { payload }) => {
      acc.topUpInfo = payload
      acc.loading = false
      acc.error = null
    })
    .addCase(fetchInfoTopUp.rejected, (acc, { error }) => {
      acc.loading = false
      acc.error = error.message || ''
    })
    .addCase(fetchInfoTopUp.pending, (acc) => {
      acc.loading = true
      acc.error = null
    })
    .addCase(getTopUpPaymentUrl.pending, (acc) => {
      acc.loading = true
      acc.error = null
    })
    .addCase(getTopUpPaymentUrl.fulfilled, (acc, { payload }) => {
      acc.url = payload.paymentUrl
      acc.concept = payload.concept
      acc.loading = false
      acc.error = null
    })
    .addCase(getTopUpPaymentUrl.rejected, (acc, { error }) => {
      acc.loading = false
      acc.error = error.message || ''
    })
    .addCase(fetchPaymentMethodStatus.pending, (acc) => {
      acc.paymentMethodExpired = null
      acc.loadingMethodStatus = true
      acc.error = null
    })
    .addCase(fetchPaymentMethodStatus.fulfilled, (acc) => {
      acc.paymentMethodExpired = false
      acc.loadingMethodStatus = false
      acc.error = null
    })
    .addCase(fetchPaymentMethodStatus.rejected, (acc, { error }) => {
      const methodExpired = error?.message?.includes('403') ? true : null
      acc.paymentMethodExpired = methodExpired
      acc.loadingMethodStatus = false
      acc.error = error?.message?.includes('403')
        ? null
        : error?.message || null
    })
    .addCase(clearTopUpPaymentUrl, (acc) => {
      acc.url = null
      acc.concept = null
      acc.error = null
      acc.paymentMethodExpired = null
      window.localStorage.removeItem('urlFrom')
      window.localStorage.removeItem('redirectTopUp')
    })
    .addCase(setCardFlowType, (acc, { payload }) => {
      acc.cardPayment.flowType = payload
    })
    .addCase(clearTopUpInfo, (acc) => {
      acc.topUpInfo = null
      acc.error = null
    })
    .addCase(setSelectedPaymentMethod, (acc, { payload }) => {
      acc.selectedPaymentMethod = payload
    })
    .addCase(clearSelectedBank, (acc) => {
      acc.selectedPaymentMethod = undefined
    })
    .addCase(clearCreditCardPayment, (acc) => {
      acc.cardPayment = {
        ...initialState.cardPayment,
      }
    })
    .addCase(setTopUpType, (acc, { payload }) => {
      acc.topUpType = payload
    })
    .addCase(clearTopUpType, (acc) => {
      acc.topUpType = TopUpPaymentType.SINGLE
    })
    .addCase(setRecurringPaymentOptions, (acc, { payload }) => {
      acc.recurringTopUpConfig = payload
    })
    .addCase(clearRecurringPaymentOptions, (acc) => {
      acc.recurringTopUpConfig = null
    })
    .addCase(setCardDetails, (acc, { payload }) => {
      acc.cardPayment.cardDetails = payload
    })
    .addCase(fetchValidCreditCards.pending, (acc) => {
      acc.loading = true
      acc.error = null
    })
    .addCase(fetchValidCreditCards.fulfilled, (acc, { payload }) => {
      acc.loading = false
      acc.error = null
      acc.cardPayment.supportedCards = payload
    })
    .addCase(fetchValidCreditCards.rejected, (acc, { error }) => {
      acc.loading = false
      acc.error = error.message
    })
    .addCase(fetchCreditCardFees.pending, (acc, { payload }) => {
      acc.cardPayment.cardPaymentFees = payload
      acc.loading = true
      acc.error = null
    })
    .addCase(fetchCreditCardFees.rejected, (acc, { error }) => {
      acc.loading = false
      acc.error = error.message
    })
    .addCase(fetchCreditCardFees.fulfilled, (acc, { payload }) => {
      acc.cardPayment.cardPaymentFees = payload
      acc.loading = false
      acc.error = null
    })
})

export default reducers
