import './balancesSummary.styles.scss'
import { useState } from 'react'
import { BasicLink, Show } from '@components'
import { useTranslation } from '@hooks'
import { useBalancesInfo } from '../../../../hooks'
import { formatMoney } from '@utils/helpers'
import { BalanceInfoModal } from '@shared/modals'

export function BalancesSummary(): JSX.Element {
  const { t } = useTranslation()
  const { cashAvailable, lockedCash } = useBalancesInfo()

  const [showBalanceInfoModal, setShowBalanceInfoModal] = useState(false)

  return (
    <>
      <div className='balancesSummary --fade-in-animation'>
        <div className='balancesSummary__row mb-2'>
          <h1 className='paragraph5'> {t('balanceInfo.title')}</h1>
          <BasicLink
            text={t('common.moreInfo')}
            className='paragraph2'
            onClick={() => setShowBalanceInfoModal(true)}
          />
        </div>

        <div className='balancesSummary__row'>
          <div>
            <p className='caption2'>
              {formatMoney(cashAvailable?.amount, cashAvailable?.currency)}
            </p>

            <p className='paragraph3 secondary--text'>
              {t('balanceInfo.availableForInvesting')}
            </p>
          </div>

          <div className='text-right'>
            <p className='caption2'>
              {formatMoney(
                lockedCash?.amount as number,
                lockedCash?.currency as string,
              )}
            </p>

            <p className='paragraph3 secondary--text'>
              {t('balanceInfo.onHold')}
            </p>
          </div>
        </div>
      </div>
      <Show when={showBalanceInfoModal}>
        <BalanceInfoModal close={() => setShowBalanceInfoModal(false)} />
      </Show>
    </>
  )
}
