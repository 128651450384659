import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import { AggregationAuthLink } from '@interfaces'
import { clearAggregationLink, requestAggregationAuthLink } from './thunk'

const initialState: {
  isLoading: boolean
  error: string | null | undefined
  aggregationAuthUrl: string | null
} = {
  isLoading: false,
  error: null,
  aggregationAuthUrl: null,
}

export const reducers = createReducer(initialState, (builder) => {
  builder
    .addCase(requestAggregationAuthLink.pending, (acc) => {
      acc.isLoading = true
      acc.error = null
      acc.aggregationAuthUrl = null
    })
    .addCase(
      requestAggregationAuthLink.fulfilled,
      (acc, action: PayloadAction<AggregationAuthLink>) => {
        const { payload } = action
        acc.isLoading = false
        acc.error = null
        acc.aggregationAuthUrl = payload?.authUrl
      },
    )
    .addCase(requestAggregationAuthLink.rejected, (acc, { error }) => {
      acc.isLoading = false
      acc.error = error.message
      acc.aggregationAuthUrl = null
    })
    .addCase(clearAggregationLink, (acc) => {
      acc.isLoading = false
      acc.error = null
      acc.aggregationAuthUrl = null
    })
})

export default reducers
