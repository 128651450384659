import { UIIcon, UIIconColorProps, getIconColorSVGFill } from 'components/utils'

export const BankIcon = ({
  color,
  size = 24,
}: UIIcon & UIIconColorProps): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.67 6.02125L11.57 1.86125C12.15 1.55125 12.85 1.55125 13.43 1.86125L21.33 6.02125C21.74 6.23125 22 6.66125 22 7.12125C22 7.81125 21.44 8.37125 20.76 8.37125H4.25C3.56 8.37125 3 7.81125 3 7.12125C3 6.66125 3.26 6.23125 3.67 6.02125ZM5 11.8713V15.8713C5 16.7013 5.67 17.3713 6.5 17.3713C7.33 17.3713 8 16.7013 8 15.8713V11.8713C8 11.0413 7.33 10.3713 6.5 10.3713C5.67 10.3713 5 11.0413 5 11.8713ZM11 11.8713V15.8713C11 16.7013 11.67 17.3713 12.5 17.3713C13.33 17.3713 14 16.7013 14 15.8713V11.8713C14 11.0413 13.33 10.3713 12.5 10.3713C11.67 10.3713 11 11.0413 11 11.8713ZM22 20.8713C22 21.7013 21.33 22.3713 20.5 22.3713H4.5C3.67 22.3713 3 21.7013 3 20.8713C3 20.0413 3.67 19.3713 4.5 19.3713H20.5C21.33 19.3713 22 20.0413 22 20.8713ZM17 11.8713V15.8713C17 16.7013 17.67 17.3713 18.5 17.3713C19.33 17.3713 20 16.7013 20 15.8713V11.8713C20 11.0413 19.33 10.3713 18.5 10.3713C17.67 10.3713 17 11.0413 17 11.8713Z'
        fill={color ? getIconColorSVGFill(color) : 'currentColor'}
      />
    </svg>
  )
}
