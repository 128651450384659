import { formatNameInTwoCharacters } from '@utils/helpers'
import { Avatar, Spacer, Text } from 'components'
import { useTranslation } from 'hooks/useTranslation'
import { BrokerTierEnum } from 'interfaces'

export const ProfileHeader = ({
  name,
  email,
  tier,
  moreInfo,
}: {
  name: string
  email: string
  tier: BrokerTierEnum
  moreInfo: () => void
}): JSX.Element => {
  const { t } = useTranslation()
  return (
    <div className='d-flex'>
      <div className='w-100'>
        <Text
          preset='headline2'
          text={name ? name : t('common.incompleteProfile')}
        />
        <Spacer preset='extraTiny' />
        <Text preset='paragraph2' text={email} color='tertiary' />
        <Spacer preset='tiny' />
        <div
          className='w-fit-content cursor-pointer'
          onClick={moreInfo}
          aria-hidden
        >
          {tier === BrokerTierEnum.pro ? (
            <Text
              preset='subtitle2'
              text='TBC Capital Pro'
              color='iconPrimaryInitial'
            />
          ) : (
            <Text
              preset='subtitle2'
              text='TBC Capital Basic'
              color='iconPrimaryInitial'
            />
          )}
        </div>
      </div>
      <div className='w-100 d-flex justify-end'>
        <Avatar
          roundness='full'
          size='large'
          image={
            <Text
              preset='headline2'
              text={formatNameInTwoCharacters(name)}
              color='primary'
            />
          }
          bgColor='tertiaryBase'
        />
      </div>
    </div>
  )
}
