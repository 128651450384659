import { availablePeriods } from '@screens/company/companyMainContent/companyMainContent.constant'
import { Chart } from 'components/chart/chart'
import { FormattedChartData } from 'components/chart/chart.props'
import { ChartItem, PeriodEnum } from 'interfaces'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { useParams } from 'react-router-dom'
import { fetchCompanyStocks } from '@screens/company/thunk'
import { ReactComponent as AlertIcon } from '@assets/svg/alert.svg'
import { StockPerformance } from '@features'
import { useTranslation } from '@hooks'
import { Spacer, Text } from '@components'
import { InfoIcon, TimerIcon } from '@assets/icons'
import { getLang } from '@utils/langs'
import { formatMoney } from '@utils/helpers'

const Overview = ({
  // period,
  openLowLiquidityModal,
  showOutOfHoursModal,
}: {
  // period: PeriodEnum
  openLowLiquidityModal: () => void
  showOutOfHoursModal?: () => void
}): JSX.Element => {
  const [mapChart, setMapChart] = useState<FormattedChartData>(null)
  const [graphPeriod, setGraphPeriod] = useState(PeriodEnum.day)
  const id = useParams().id || ''
  const dispatch = useDispatch()
  const locale = getLang()
  const { t } = useTranslation()

  const { companyStocks } = useSelector((state: RootState) => state)

  const companyPrice = formatMoney(
    companyStocks?.price?.amount,
    companyStocks?.price?.currency,
  )

  const isMarketOpen = companyStocks?.marketStatus?.status === 'OPEN'

  useEffect(() => {
    dispatch(fetchCompanyStocks({ period: graphPeriod, company: id }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphPeriod, id])

  useEffect(() => {
    if (companyStocks?.chart?.length > 0 && !companyStocks.loading) {
      const listing = companyStocks?.chart.map((cs: ChartItem) => {
        const mapDate = new Date(cs?.dateTime)?.getTime() / 1000
        return {
          time: mapDate,
          value: cs?.close?.amount,
          close: cs?.close?.amount,
          open: cs?.open?.amount,
          high: cs?.high?.amount,
          low: cs?.low?.amount,
          volume: cs?.volume,
        }
      })
      setMapChart({ chartData: listing })
    }
  }, [graphPeriod, setMapChart, companyStocks])
  return (
    <>
      <div className='company-details__widget__graph__description__stock pt-2 w-100 d-flex d-flex-col pb-1'>
        <div className='d-flex d-flex-col'>
          <div className='d-flex align-center'>
            <div className='company-details__widget__graph__description__info__important mr-2'>
              {companyPrice}
            </div>
            <div className='mr-2'>
              <StockPerformance
                performanceAmount={companyStocks?.increaseMoney}
                performancePercentage={companyStocks?.increasePercentage}
                noWrap={true}
                plusSymbol={true}
              />
            </div>
            <div className='overview__period d-flex align-center'>
              <div>·</div>
              <Spacer preset='smaller' />
              {t(`companyDetails.${graphPeriod?.toLocaleLowerCase()}`)}
            </div>
            {isMarketOpen && (
              <div className='d-flex align-center'>
                <TimerIcon color='positive' size={16} />
                <Spacer preset='tiny' />
                <Text
                  preset='paragraph2'
                  color='positive'
                  text={t(`common.marketOpen`)}
                />
                <Spacer preset='tiny' />
                <div>·</div>
                <Spacer preset='tiny' />
                <Text
                  textStyle='capitalize'
                  preset='paragraph2'
                  color='tertiary'
                  text={t(`common.closesAt`, {
                    closingTime: new Date(
                      companyStocks?.marketStatus?.closingTime,
                    ).toLocaleString(locale, {
                      hour: '2-digit',
                      minute: '2-digit',
                    }),
                  })}
                />
              </div>
            )}
          </div>
          {companyStocks.isLowLiquidity ? (
            <div
              className='d-inline-flex d-flex-col cursor-pointer w-fit-content'
              onClick={openLowLiquidityModal}
              aria-hidden
            >
              <div className='d-inline-flex d-flex-row paragraph2 orange--text align-center'>
                <p>{t('companyDetails.isLowLiquidityTitle')}</p>
                <AlertIcon className='ml-1 icon--smaller align-self-center' />
              </div>
            </div>
          ) : null}
        </div>
        {!isMarketOpen && (
          <div className='d-flex d-flex-col' aria-hidden>
            <div className='d-flex align-center'>
              <TimerIcon color='negative' size={16} />
              <Spacer preset='tiny' />
              <Text
                preset='paragraph2'
                color='negative'
                text={t(`common.marketClosed`)}
              />
            </div>
            <Spacer preset='extraTiny' />
            <div
              className='d-flex align-center cursor-pointer'
              aria-hidden
              onClick={showOutOfHoursModal}
            >
              <div className='cursor-pointer d-flex align-center'>
                <InfoIcon size={16} color='tertiary' />
              </div>
              <Spacer preset='tiny' />
              <Text
                color='tertiary'
                preset='paragraph3'
                text={t('companyDetails.setAnOutOfHoursOrderTitle')}
              />
            </div>
          </div>
        )}
      </div>
      <Spacer preset='small' />
      <div className='company-details__widget__graph__calculation'>
        {mapChart && (
          <Chart
            additionalFeature={true}
            selectedPeriod={graphPeriod}
            onPeriodChange={(p: PeriodEnum) => setGraphPeriod(p)}
            loading={companyStocks.loading}
            data={mapChart}
            symbolInfo={companyStocks}
            availablePeriods={availablePeriods}
          />
        )}
      </div>
    </>
  )
}

export default Overview
