import { UIIcon, UIIconColorProps, getIconColorSVGFill } from 'components/utils'

export const AnniversaryIcon = ({
  color,
  size = 24,
}: UIIcon & UIIconColorProps): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2 22L7 8L16 17L2 22ZM5.3 18.7L12.35 16.2L7.8 11.65L5.3 18.7ZM14.55 12.55L13.5 11.5L19.1 5.9C19.6333 5.36667 20.275 5.1 21.025 5.1C21.775 5.1 22.4167 5.36667 22.95 5.9L23.55 6.5L22.5 7.55L21.9 6.95C21.6667 6.71667 21.375 6.6 21.025 6.6C20.675 6.6 20.3833 6.71667 20.15 6.95L14.55 12.55ZM10.55 8.55L9.5 7.5L10.1 6.9C10.3333 6.66667 10.45 6.38333 10.45 6.05C10.45 5.71667 10.3333 5.43333 10.1 5.2L9.45 4.55L10.5 3.5L11.15 4.15C11.6833 4.68333 11.95 5.31667 11.95 6.05C11.95 6.78333 11.6833 7.41667 11.15 7.95L10.55 8.55ZM12.55 10.55L11.5 9.5L15.1 5.9C15.3333 5.66667 15.45 5.375 15.45 5.025C15.45 4.675 15.3333 4.38333 15.1 4.15L13.5 2.55L14.55 1.5L16.15 3.1C16.6833 3.63333 16.95 4.275 16.95 5.025C16.95 5.775 16.6833 6.41667 16.15 6.95L12.55 10.55ZM16.55 14.55L15.5 13.5L17.1 11.9C17.6333 11.3667 18.275 11.1 19.025 11.1C19.775 11.1 20.4167 11.3667 20.95 11.9L22.55 13.5L21.5 14.55L19.9 12.95C19.6667 12.7167 19.375 12.6 19.025 12.6C18.675 12.6 18.3833 12.7167 18.15 12.95L16.55 14.55Z'
        fill={color ? getIconColorSVGFill(color) : 'currentColor'}
      />
    </svg>
  )
}
