export const GlobalAssetManager = (): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='248'
      height='248'
      fill='none'
      viewBox='0 0 248 248'
    >
      <circle
        cx='124'
        cy='124'
        r='124'
        fill='var(--illustrationsNeutral25)'
      ></circle>
      <mask
        id='mask0_10556_277465'
        style={{ maskType: 'alpha' }}
        width='248'
        height='248'
        x='0'
        y='0'
        maskUnits='userSpaceOnUse'
      >
        <circle cx='124' cy='124' r='124' fill='#FF9B63'></circle>
      </mask>
      <g
        fill='var(--illustrationsSharedNeutral600)'
        mask='url(#mask0_10556_277465)'
      >
        <circle cx='4' cy='1' r='1'></circle>
        <circle cx='10' cy='1' r='1'></circle>
        <circle cx='16' cy='1' r='1'></circle>
        <circle cx='22' cy='1' r='1'></circle>
        <path d='M29 1a1 1 0 11-2 0 1 1 0 012 0zM35 1a1 1 0 11-2 0 1 1 0 012 0zM41 1a1 1 0 11-2 0 1 1 0 012 0zM47 1a1 1 0 11-2 0 1 1 0 012 0zM53 1a1 1 0 11-2 0 1 1 0 012 0zM59 1a1 1 0 11-2 0 1 1 0 012 0zM65 1a1 1 0 11-2 0 1 1 0 012 0zM71 1a1 1 0 11-2 0 1 1 0 012 0zM77 1a1 1 0 11-2 0 1 1 0 012 0zM83 1a1 1 0 11-2 0 1 1 0 012 0zM89 1a1 1 0 11-2 0 1 1 0 012 0zM95 1a1 1 0 11-2 0 1 1 0 012 0zM101 1a1 1 0 11-2 0 1 1 0 012 0zM107 1a1 1 0 11-2 0 1 1 0 012 0zM113 1a1 1 0 11-2 0 1 1 0 012 0zM119 1a1 1 0 11-2 0 1 1 0 012 0zM125 1a1 1 0 11-2 0 1 1 0 012 0zM131 1a1 1 0 11-2 0 1 1 0 012 0zM137 1a1 1 0 11-2 0 1 1 0 012 0zM143 1a1 1 0 11-2 0 1 1 0 012 0z'></path>
        <circle cx='148' cy='1' r='1'></circle>
        <circle cx='154' cy='1' r='1'></circle>
        <circle cx='160' cy='1' r='1'></circle>
        <circle cx='166' cy='1' r='1'></circle>
        <circle cx='172' cy='1' r='1'></circle>
        <circle cx='178' cy='1' r='1'></circle>
        <circle cx='184' cy='1' r='1'></circle>
        <circle cx='190' cy='1' r='1'></circle>
        <circle cx='196' cy='1' r='1'></circle>
        <circle cx='202' cy='1' r='1'></circle>
        <circle cx='208' cy='1' r='1'></circle>
        <circle cx='214' cy='1' r='1'></circle>
        <circle cx='220' cy='1' r='1'></circle>
        <circle cx='226' cy='1' r='1'></circle>
        <circle cx='232' cy='1' r='1'></circle>
        <circle cx='238' cy='1' r='1'></circle>
        <circle cx='244' cy='1' r='1'></circle>
        <circle cx='4' cy='146.8' r='1'></circle>
        <circle cx='10' cy='146.8' r='1'></circle>
        <circle cx='16' cy='146.8' r='1'></circle>
        <circle cx='22' cy='146.8' r='1'></circle>
        <path d='M29 146.8a1 1 0 11-2 0 1 1 0 012 0zM35 146.8a1 1 0 11-2 0 1 1 0 012 0zM41 146.8a1 1 0 11-2 0 1 1 0 012 0zM47 146.8a1 1 0 11-2 0 1 1 0 012 0zM53 146.8a1 1 0 11-2 0 1 1 0 012 0zM59 146.8a1 1 0 11-2 0 1 1 0 012 0zM65 146.8a1 1 0 11-2 0 1 1 0 012 0zM71 146.8a1 1 0 11-2 0 1 1 0 012 0zM77 146.8a1 1 0 11-2 0 1 1 0 012 0zM83 146.8a1 1 0 11-2 0 1 1 0 012 0zM89 146.8a1 1 0 11-2 0 1 1 0 012 0zM95 146.8a1 1 0 11-2 0 1 1 0 012 0zM101 146.8a1 1 0 11-2 0 1 1 0 012 0zM107 146.8a1 1 0 11-2 0 1 1 0 012 0zM113 146.8a1 1 0 11-2 0 1 1 0 012 0zM119 146.8a1 1 0 11-2 0 1 1 0 012 0zM125 146.8a1 1 0 11-2 0 1 1 0 012 0zM131 146.8a1 1 0 11-2 0 1 1 0 012 0zM137 146.8a1 1 0 11-2 0 1 1 0 012 0zM143 146.8a1 1 0 11-2 0 1 1 0 012 0z'></path>
        <circle cx='148' cy='146.8' r='1'></circle>
        <circle cx='154' cy='146.8' r='1'></circle>
        <circle cx='160' cy='146.8' r='1'></circle>
        <circle cx='166' cy='146.8' r='1'></circle>
        <circle cx='172' cy='146.8' r='1'></circle>
        <circle cx='178' cy='146.8' r='1'></circle>
        <circle cx='184' cy='146.8' r='1'></circle>
        <circle cx='190' cy='146.8' r='1'></circle>
        <circle cx='196' cy='146.8' r='1'></circle>
        <circle cx='202' cy='146.8' r='1'></circle>
        <circle cx='208' cy='146.8' r='1'></circle>
        <circle cx='214' cy='146.8' r='1'></circle>
        <circle cx='220' cy='146.8' r='1'></circle>
        <circle cx='226' cy='146.8' r='1'></circle>
        <circle cx='232' cy='146.8' r='1'></circle>
        <circle cx='238' cy='146.8' r='1'></circle>
        <circle cx='244' cy='146.8' r='1'></circle>
        <circle cx='4' cy='30.16' r='1'></circle>
        <circle cx='10' cy='30.16' r='1'></circle>
        <circle cx='16' cy='30.16' r='1'></circle>
        <circle cx='22' cy='30.16' r='1'></circle>
        <path d='M29 30.16a1 1 0 11-2 0 1 1 0 012 0zM35 30.16a1 1 0 11-2 0 1 1 0 012 0zM41 30.16a1 1 0 11-2 0 1 1 0 012 0zM47 30.16a1 1 0 11-2 0 1 1 0 012 0zM53 30.16a1 1 0 11-2 0 1 1 0 012 0zM59 30.16a1 1 0 11-2 0 1 1 0 012 0zM65 30.16a1 1 0 11-2 0 1 1 0 012 0zM71 30.16a1 1 0 11-2 0 1 1 0 012 0zM77 30.16a1 1 0 11-2 0 1 1 0 012 0zM83 30.16a1 1 0 11-2 0 1 1 0 012 0zM89 30.16a1 1 0 11-2 0 1 1 0 012 0zM95 30.16a1 1 0 11-2 0 1 1 0 012 0zM101 30.16a1 1 0 11-2 0 1 1 0 012 0zM107 30.16a1 1 0 11-2 0 1 1 0 012 0zM113 30.16a1 1 0 11-2 0 1 1 0 012 0zM119 30.16a1 1 0 11-2 0 1 1 0 012 0zM125 30.16a1 1 0 11-2 0 1 1 0 012 0zM131 30.16a1 1 0 11-2 0 1 1 0 012 0zM137 30.16a1 1 0 11-2 0 1 1 0 012 0zM143 30.16a1 1 0 11-2 0 1 1 0 012 0z'></path>
        <circle cx='148' cy='30.16' r='1'></circle>
        <circle cx='154' cy='30.16' r='1'></circle>
        <circle cx='160' cy='30.16' r='1'></circle>
        <circle cx='166' cy='30.16' r='1'></circle>
        <circle cx='172' cy='30.16' r='1'></circle>
        <circle cx='178' cy='30.16' r='1'></circle>
        <circle cx='184' cy='30.16' r='1'></circle>
        <circle cx='190' cy='30.16' r='1'></circle>
        <circle cx='196' cy='30.16' r='1'></circle>
        <circle cx='202' cy='30.16' r='1'></circle>
        <circle cx='208' cy='30.16' r='1'></circle>
        <circle cx='214' cy='30.16' r='1'></circle>
        <circle cx='220' cy='30.16' r='1'></circle>
        <circle cx='226' cy='30.16' r='1'></circle>
        <circle cx='232' cy='30.16' r='1'></circle>
        <circle cx='238' cy='30.16' r='1'></circle>
        <circle cx='244' cy='30.16' r='1'></circle>
        <circle cx='4' cy='175.96' r='1'></circle>
        <circle cx='10' cy='175.96' r='1'></circle>
        <circle cx='16' cy='175.96' r='1'></circle>
        <circle cx='22' cy='175.96' r='1'></circle>
        <path d='M29 175.96a1 1 0 11-2 0 1 1 0 012 0zM35 175.96a1 1 0 11-2 0 1 1 0 012 0zM41 175.96a1 1 0 11-2 0 1 1 0 012 0zM47 175.96a1 1 0 11-2 0 1 1 0 012 0zM53 175.96a1 1 0 11-2 0 1 1 0 012 0zM59 175.96a1 1 0 11-2 0 1 1 0 012 0zM65 175.96a1 1 0 11-2 0 1 1 0 012 0zM71 175.96a1 1 0 11-2 0 1 1 0 012 0zM77 175.96a1 1 0 11-2 0 1 1 0 012 0zM83 175.96a1 1 0 11-2 0 1 1 0 012 0zM89 175.96a1 1 0 11-2 0 1 1 0 012 0zM95 175.96a1 1 0 11-2 0 1 1 0 012 0zM101 175.96a1 1 0 11-2 0 1 1 0 012 0zM107 175.96a1 1 0 11-2 0 1 1 0 012 0zM113 175.96a1 1 0 11-2 0 1 1 0 012 0zM119 175.96a1 1 0 11-2 0 1 1 0 012 0zM125 175.96a1 1 0 11-2 0 1 1 0 012 0zM131 175.96a1 1 0 11-2 0 1 1 0 012 0zM137 175.96a1 1 0 11-2 0 1 1 0 012 0zM143 175.96a1 1 0 11-2 0 1 1 0 012 0z'></path>
        <circle cx='148' cy='175.96' r='1'></circle>
        <circle cx='154' cy='175.96' r='1'></circle>
        <circle cx='160' cy='175.96' r='1'></circle>
        <circle cx='166' cy='175.96' r='1'></circle>
        <circle cx='172' cy='175.96' r='1'></circle>
        <circle cx='178' cy='175.96' r='1'></circle>
        <circle cx='184' cy='175.96' r='1'></circle>
        <circle cx='190' cy='175.96' r='1'></circle>
        <circle cx='196' cy='175.96' r='1'></circle>
        <circle cx='202' cy='175.96' r='1'></circle>
        <circle cx='208' cy='175.96' r='1'></circle>
        <circle cx='214' cy='175.96' r='1'></circle>
        <circle cx='220' cy='175.96' r='1'></circle>
        <circle cx='226' cy='175.96' r='1'></circle>
        <circle cx='232' cy='175.96' r='1'></circle>
        <circle cx='238' cy='175.96' r='1'></circle>
        <circle cx='244' cy='175.96' r='1'></circle>
        <circle cx='4' cy='59.32' r='1'></circle>
        <circle cx='10' cy='59.32' r='1'></circle>
        <circle cx='16' cy='59.32' r='1'></circle>
        <circle cx='22' cy='59.32' r='1'></circle>
        <path d='M29 59.32a1 1 0 11-2 0 1 1 0 012 0zM35 59.32a1 1 0 11-2 0 1 1 0 012 0zM41 59.32a1 1 0 11-2 0 1 1 0 012 0zM47 59.32a1 1 0 11-2 0 1 1 0 012 0zM53 59.32a1 1 0 11-2 0 1 1 0 012 0zM59 59.32a1 1 0 11-2 0 1 1 0 012 0zM65 59.32a1 1 0 11-2 0 1 1 0 012 0zM71 59.32a1 1 0 11-2 0 1 1 0 012 0zM77 59.32a1 1 0 11-2 0 1 1 0 012 0zM83 59.32a1 1 0 11-2 0 1 1 0 012 0zM89 59.32a1 1 0 11-2 0 1 1 0 012 0zM95 59.32a1 1 0 11-2 0 1 1 0 012 0zM101 59.32a1 1 0 11-2 0 1 1 0 012 0zM107 59.32a1 1 0 11-2 0 1 1 0 012 0zM113 59.32a1 1 0 11-2 0 1 1 0 012 0zM119 59.32a1 1 0 11-2 0 1 1 0 012 0zM125 59.32a1 1 0 11-2 0 1 1 0 012 0zM131 59.32a1 1 0 11-2 0 1 1 0 012 0zM137 59.32a1 1 0 11-2 0 1 1 0 012 0zM143 59.32a1 1 0 11-2 0 1 1 0 012 0z'></path>
        <circle cx='148' cy='59.32' r='1'></circle>
        <circle cx='154' cy='59.32' r='1'></circle>
        <circle cx='160' cy='59.32' r='1'></circle>
        <circle cx='166' cy='59.32' r='1'></circle>
        <circle cx='172' cy='59.32' r='1'></circle>
        <circle cx='178' cy='59.32' r='1'></circle>
        <circle cx='184' cy='59.32' r='1'></circle>
        <circle cx='190' cy='59.32' r='1'></circle>
        <circle cx='196' cy='59.32' r='1'></circle>
        <circle cx='202' cy='59.32' r='1'></circle>
        <circle cx='208' cy='59.32' r='1'></circle>
        <circle cx='214' cy='59.32' r='1'></circle>
        <circle cx='220' cy='59.32' r='1'></circle>
        <circle cx='226' cy='59.32' r='1'></circle>
        <circle cx='232' cy='59.32' r='1'></circle>
        <circle cx='238' cy='59.32' r='1'></circle>
        <circle cx='244' cy='59.32' r='1'></circle>
        <circle cx='4' cy='205.12' r='1'></circle>
        <circle cx='10' cy='205.12' r='1'></circle>
        <circle cx='16' cy='205.12' r='1'></circle>
        <circle cx='22' cy='205.12' r='1'></circle>
        <path d='M29 205.12a1 1 0 11-2 0 1 1 0 012 0zM35 205.12a1 1 0 11-2 0 1 1 0 012 0zM41 205.12a1 1 0 11-2 0 1 1 0 012 0zM47 205.12a1 1 0 11-2 0 1 1 0 012 0zM53 205.12a1 1 0 11-2 0 1 1 0 012 0zM59 205.12a1 1 0 11-2 0 1 1 0 012 0zM65 205.12a1 1 0 11-2 0 1 1 0 012 0zM71 205.12a1 1 0 11-2 0 1 1 0 012 0zM77 205.12a1 1 0 11-2 0 1 1 0 012 0zM83 205.12a1 1 0 11-2 0 1 1 0 012 0zM89 205.12a1 1 0 11-2 0 1 1 0 012 0zM95 205.12a1 1 0 11-2 0 1 1 0 012 0zM101 205.12a1 1 0 11-2 0 1 1 0 012 0zM107 205.12a1 1 0 11-2 0 1 1 0 012 0zM113 205.12a1 1 0 11-2 0 1 1 0 012 0zM119 205.12a1 1 0 11-2 0 1 1 0 012 0zM125 205.12a1 1 0 11-2 0 1 1 0 012 0zM131 205.12a1 1 0 11-2 0 1 1 0 012 0zM137 205.12a1 1 0 11-2 0 1 1 0 012 0zM143 205.12a1 1 0 11-2 0 1 1 0 012 0z'></path>
        <circle cx='148' cy='205.12' r='1'></circle>
        <circle cx='154' cy='205.12' r='1'></circle>
        <circle cx='160' cy='205.12' r='1'></circle>
        <circle cx='166' cy='205.12' r='1'></circle>
        <circle cx='172' cy='205.12' r='1'></circle>
        <circle cx='178' cy='205.12' r='1'></circle>
        <circle cx='184' cy='205.12' r='1'></circle>
        <circle cx='190' cy='205.12' r='1'></circle>
        <circle cx='196' cy='205.12' r='1'></circle>
        <circle cx='202' cy='205.12' r='1'></circle>
        <circle cx='208' cy='205.12' r='1'></circle>
        <circle cx='214' cy='205.12' r='1'></circle>
        <circle cx='220' cy='205.12' r='1'></circle>
        <circle cx='226' cy='205.12' r='1'></circle>
        <circle cx='232' cy='205.12' r='1'></circle>
        <circle cx='238' cy='205.12' r='1'></circle>
        <circle cx='244' cy='205.12' r='1'></circle>
        <circle cx='4' cy='88.48' r='1'></circle>
        <circle cx='10' cy='88.48' r='1'></circle>
        <circle cx='16' cy='88.48' r='1'></circle>
        <circle cx='22' cy='88.48' r='1'></circle>
        <path d='M29 88.48a1 1 0 11-2 0 1 1 0 012 0zM35 88.48a1 1 0 11-2 0 1 1 0 012 0zM41 88.48a1 1 0 11-2 0 1 1 0 012 0zM47 88.48a1 1 0 11-2 0 1 1 0 012 0zM53 88.48a1 1 0 11-2 0 1 1 0 012 0zM59 88.48a1 1 0 11-2 0 1 1 0 012 0zM65 88.48a1 1 0 11-2 0 1 1 0 012 0zM71 88.48a1 1 0 11-2 0 1 1 0 012 0zM77 88.48a1 1 0 11-2 0 1 1 0 012 0zM83 88.48a1 1 0 11-2 0 1 1 0 012 0zM89 88.48a1 1 0 11-2 0 1 1 0 012 0zM95 88.48a1 1 0 11-2 0 1 1 0 012 0zM101 88.48a1 1 0 11-2 0 1 1 0 012 0zM107 88.48a1 1 0 11-2 0 1 1 0 012 0zM113 88.48a1 1 0 11-2 0 1 1 0 012 0zM119 88.48a1 1 0 11-2 0 1 1 0 012 0zM125 88.48a1 1 0 11-2 0 1 1 0 012 0zM131 88.48a1 1 0 11-2 0 1 1 0 012 0zM137 88.48a1 1 0 11-2 0 1 1 0 012 0zM143 88.48a1 1 0 11-2 0 1 1 0 012 0z'></path>
        <circle cx='148' cy='88.48' r='1'></circle>
        <circle cx='154' cy='88.48' r='1'></circle>
        <circle cx='160' cy='88.48' r='1'></circle>
        <circle cx='166' cy='88.48' r='1'></circle>
        <circle cx='172' cy='88.48' r='1'></circle>
        <circle cx='178' cy='88.48' r='1'></circle>
        <circle cx='184' cy='88.48' r='1'></circle>
        <circle cx='190' cy='88.48' r='1'></circle>
        <circle cx='196' cy='88.48' r='1'></circle>
        <circle cx='202' cy='88.48' r='1'></circle>
        <circle cx='208' cy='88.48' r='1'></circle>
        <circle cx='214' cy='88.48' r='1'></circle>
        <circle cx='220' cy='88.48' r='1'></circle>
        <circle cx='226' cy='88.48' r='1'></circle>
        <circle cx='232' cy='88.48' r='1'></circle>
        <circle cx='238' cy='88.48' r='1'></circle>
        <circle cx='244' cy='88.48' r='1'></circle>
        <circle cx='4' cy='234.28' r='1'></circle>
        <circle cx='10' cy='234.28' r='1'></circle>
        <circle cx='16' cy='234.28' r='1'></circle>
        <circle cx='22' cy='234.28' r='1'></circle>
        <path d='M29 234.28a1 1 0 11-2 0 1 1 0 012 0zM35 234.28a1 1 0 11-2 0 1 1 0 012 0zM41 234.28a1 1 0 11-2 0 1 1 0 012 0zM47 234.28a1 1 0 11-2 0 1 1 0 012 0zM53 234.28a1 1 0 11-2 0 1 1 0 012 0zM59 234.28a1 1 0 11-2 0 1 1 0 012 0zM65 234.28a1 1 0 11-2 0 1 1 0 012 0zM71 234.28a1 1 0 11-2 0 1 1 0 012 0zM77 234.28a1 1 0 11-2 0 1 1 0 012 0zM83 234.28a1 1 0 11-2 0 1 1 0 012 0zM89 234.28a1 1 0 11-2 0 1 1 0 012 0zM95 234.28a1 1 0 11-2 0 1 1 0 012 0zM101 234.28a1 1 0 11-2 0 1 1 0 012 0zM107 234.28a1 1 0 11-2 0 1 1 0 012 0zM113 234.28a1 1 0 11-2 0 1 1 0 012 0zM119 234.28a1 1 0 11-2 0 1 1 0 012 0zM125 234.28a1 1 0 11-2 0 1 1 0 012 0zM131 234.28a1 1 0 11-2 0 1 1 0 012 0zM137 234.28a1 1 0 11-2 0 1 1 0 012 0zM143 234.28a1 1 0 11-2 0 1 1 0 012 0z'></path>
        <circle cx='148' cy='234.28' r='1'></circle>
        <circle cx='154' cy='234.28' r='1'></circle>
        <circle cx='160' cy='234.28' r='1'></circle>
        <circle cx='166' cy='234.28' r='1'></circle>
        <circle cx='172' cy='234.28' r='1'></circle>
        <circle cx='178' cy='234.28' r='1'></circle>
        <circle cx='184' cy='234.28' r='1'></circle>
        <circle cx='190' cy='234.28' r='1'></circle>
        <circle cx='196' cy='234.28' r='1'></circle>
        <circle cx='202' cy='234.28' r='1'></circle>
        <circle cx='208' cy='234.28' r='1'></circle>
        <circle cx='214' cy='234.28' r='1'></circle>
        <circle cx='220' cy='234.28' r='1'></circle>
        <circle cx='226' cy='234.28' r='1'></circle>
        <circle cx='232' cy='234.28' r='1'></circle>
        <circle cx='238' cy='234.28' r='1'></circle>
        <circle cx='244' cy='234.28' r='1'></circle>
        <circle cx='4' cy='117.64' r='1'></circle>
        <circle cx='10' cy='117.64' r='1'></circle>
        <circle cx='16' cy='117.64' r='1'></circle>
        <circle cx='22' cy='117.64' r='1'></circle>
        <path d='M29 117.64a1 1 0 11-2 0 1 1 0 012 0zM35 117.64a1 1 0 11-2 0 1 1 0 012 0zM41 117.64a1 1 0 11-2 0 1 1 0 012 0zM47 117.64a1 1 0 11-2 0 1 1 0 012 0zM53 117.64a1 1 0 11-2 0 1 1 0 012 0zM59 117.64a1 1 0 11-2 0 1 1 0 012 0zM65 117.64a1 1 0 11-2 0 1 1 0 012 0zM71 117.64a1 1 0 11-2 0 1 1 0 012 0zM77 117.64a1 1 0 11-2 0 1 1 0 012 0zM83 117.64a1 1 0 11-2 0 1 1 0 012 0zM89 117.64a1 1 0 11-2 0 1 1 0 012 0zM95 117.64a1 1 0 11-2 0 1 1 0 012 0zM101 117.64a1 1 0 11-2 0 1 1 0 012 0zM107 117.64a1 1 0 11-2 0 1 1 0 012 0zM113 117.64a1 1 0 11-2 0 1 1 0 012 0zM119 117.64a1 1 0 11-2 0 1 1 0 012 0zM125 117.64a1 1 0 11-2 0 1 1 0 012 0zM131 117.64a1 1 0 11-2 0 1 1 0 012 0zM137 117.64a1 1 0 11-2 0 1 1 0 012 0zM143 117.64a1 1 0 11-2 0 1 1 0 012 0z'></path>
        <circle cx='148' cy='117.64' r='1'></circle>
        <circle cx='154' cy='117.64' r='1'></circle>
        <circle cx='160' cy='117.64' r='1'></circle>
        <circle cx='166' cy='117.64' r='1'></circle>
        <circle cx='172' cy='117.64' r='1'></circle>
        <circle cx='178' cy='117.64' r='1'></circle>
        <circle cx='184' cy='117.64' r='1'></circle>
        <circle cx='190' cy='117.64' r='1'></circle>
        <circle cx='196' cy='117.64' r='1'></circle>
        <circle cx='202' cy='117.64' r='1'></circle>
        <circle cx='208' cy='117.64' r='1'></circle>
        <circle cx='214' cy='117.64' r='1'></circle>
        <circle cx='220' cy='117.64' r='1'></circle>
        <circle cx='226' cy='117.64' r='1'></circle>
        <circle cx='232' cy='117.64' r='1'></circle>
        <circle cx='238' cy='117.64' r='1'></circle>
        <circle cx='244' cy='117.64' r='1'></circle>
        <circle cx='4' cy='15.58' r='1'></circle>
        <circle cx='10' cy='15.58' r='1'></circle>
        <circle cx='16' cy='15.58' r='1'></circle>
        <circle cx='22' cy='15.58' r='1'></circle>
        <path d='M29 15.58a1 1 0 11-2 0 1 1 0 012 0zM35 15.58a1 1 0 11-2 0 1 1 0 012 0zM41 15.58a1 1 0 11-2 0 1 1 0 012 0zM47 15.58a1 1 0 11-2 0 1 1 0 012 0zM53 15.58a1 1 0 11-2 0 1 1 0 012 0zM59 15.58a1 1 0 11-2 0 1 1 0 012 0zM65 15.58a1 1 0 11-2 0 1 1 0 012 0zM71 15.58a1 1 0 11-2 0 1 1 0 012 0zM77 15.58a1 1 0 11-2 0 1 1 0 012 0zM83 15.58a1 1 0 11-2 0 1 1 0 012 0zM89 15.58a1 1 0 11-2 0 1 1 0 012 0zM95 15.58a1 1 0 11-2 0 1 1 0 012 0zM101 15.58a1 1 0 11-2 0 1 1 0 012 0zM107 15.58a1 1 0 11-2 0 1 1 0 012 0zM113 15.58a1 1 0 11-2 0 1 1 0 012 0zM119 15.58a1 1 0 11-2 0 1 1 0 012 0zM125 15.58a1 1 0 11-2 0 1 1 0 012 0zM131 15.58a1 1 0 11-2 0 1 1 0 012 0zM137 15.58a1 1 0 11-2 0 1 1 0 012 0zM143 15.58a1 1 0 11-2 0 1 1 0 012 0z'></path>
        <circle cx='148' cy='15.58' r='1'></circle>
        <circle cx='154' cy='15.58' r='1'></circle>
        <circle cx='160' cy='15.58' r='1'></circle>
        <circle cx='166' cy='15.58' r='1'></circle>
        <circle cx='172' cy='15.58' r='1'></circle>
        <circle cx='178' cy='15.58' r='1'></circle>
        <circle cx='184' cy='15.58' r='1'></circle>
        <circle cx='190' cy='15.58' r='1'></circle>
        <circle cx='196' cy='15.58' r='1'></circle>
        <circle cx='202' cy='15.58' r='1'></circle>
        <circle cx='208' cy='15.58' r='1'></circle>
        <circle cx='214' cy='15.58' r='1'></circle>
        <circle cx='220' cy='15.58' r='1'></circle>
        <circle cx='226' cy='15.58' r='1'></circle>
        <circle cx='232' cy='15.58' r='1'></circle>
        <circle cx='238' cy='15.58' r='1'></circle>
        <circle cx='244' cy='15.58' r='1'></circle>
        <circle cx='4' cy='161.38' r='1'></circle>
        <circle cx='10' cy='161.38' r='1'></circle>
        <circle cx='16' cy='161.38' r='1'></circle>
        <circle cx='22' cy='161.38' r='1'></circle>
        <path d='M29 161.38a1 1 0 11-2 0 1 1 0 012 0zM35 161.38a1 1 0 11-2 0 1 1 0 012 0zM41 161.38a1 1 0 11-2 0 1 1 0 012 0zM47 161.38a1 1 0 11-2 0 1 1 0 012 0zM53 161.38a1 1 0 11-2 0 1 1 0 012 0zM59 161.38a1 1 0 11-2 0 1 1 0 012 0zM65 161.38a1 1 0 11-2 0 1 1 0 012 0zM71 161.38a1 1 0 11-2 0 1 1 0 012 0zM77 161.38a1 1 0 11-2 0 1 1 0 012 0zM83 161.38a1 1 0 11-2 0 1 1 0 012 0zM89 161.38a1 1 0 11-2 0 1 1 0 012 0zM95 161.38a1 1 0 11-2 0 1 1 0 012 0zM101 161.38a1 1 0 11-2 0 1 1 0 012 0zM107 161.38a1 1 0 11-2 0 1 1 0 012 0zM113 161.38a1 1 0 11-2 0 1 1 0 012 0zM119 161.38a1 1 0 11-2 0 1 1 0 012 0zM125 161.38a1 1 0 11-2 0 1 1 0 012 0zM131 161.38a1 1 0 11-2 0 1 1 0 012 0zM137 161.38a1 1 0 11-2 0 1 1 0 012 0zM143 161.38a1 1 0 11-2 0 1 1 0 012 0z'></path>
        <circle cx='148' cy='161.38' r='1'></circle>
        <circle cx='154' cy='161.38' r='1'></circle>
        <circle cx='160' cy='161.38' r='1'></circle>
        <circle cx='166' cy='161.38' r='1'></circle>
        <circle cx='172' cy='161.38' r='1'></circle>
        <circle cx='178' cy='161.38' r='1'></circle>
        <circle cx='184' cy='161.38' r='1'></circle>
        <circle cx='190' cy='161.38' r='1'></circle>
        <circle cx='196' cy='161.38' r='1'></circle>
        <circle cx='202' cy='161.38' r='1'></circle>
        <circle cx='208' cy='161.38' r='1'></circle>
        <circle cx='214' cy='161.38' r='1'></circle>
        <circle cx='220' cy='161.38' r='1'></circle>
        <circle cx='226' cy='161.38' r='1'></circle>
        <circle cx='232' cy='161.38' r='1'></circle>
        <circle cx='238' cy='161.38' r='1'></circle>
        <circle cx='244' cy='161.38' r='1'></circle>
        <circle cx='4' cy='44.74' r='1'></circle>
        <circle cx='10' cy='44.74' r='1'></circle>
        <circle cx='16' cy='44.74' r='1'></circle>
        <circle cx='22' cy='44.74' r='1'></circle>
        <path d='M29 44.74a1 1 0 11-2 0 1 1 0 012 0zM35 44.74a1 1 0 11-2 0 1 1 0 012 0zM41 44.74a1 1 0 11-2 0 1 1 0 012 0zM47 44.74a1 1 0 11-2 0 1 1 0 012 0zM53 44.74a1 1 0 11-2 0 1 1 0 012 0zM59 44.74a1 1 0 11-2 0 1 1 0 012 0zM65 44.74a1 1 0 11-2 0 1 1 0 012 0zM71 44.74a1 1 0 11-2 0 1 1 0 012 0zM77 44.74a1 1 0 11-2 0 1 1 0 012 0zM83 44.74a1 1 0 11-2 0 1 1 0 012 0zM89 44.74a1 1 0 11-2 0 1 1 0 012 0zM95 44.74a1 1 0 11-2 0 1 1 0 012 0zM101 44.74a1 1 0 11-2 0 1 1 0 012 0zM107 44.74a1 1 0 11-2 0 1 1 0 012 0zM113 44.74a1 1 0 11-2 0 1 1 0 012 0zM119 44.74a1 1 0 11-2 0 1 1 0 012 0zM125 44.74a1 1 0 11-2 0 1 1 0 012 0zM131 44.74a1 1 0 11-2 0 1 1 0 012 0zM137 44.74a1 1 0 11-2 0 1 1 0 012 0zM143 44.74a1 1 0 11-2 0 1 1 0 012 0z'></path>
        <circle cx='148' cy='44.74' r='1'></circle>
        <circle cx='154' cy='44.74' r='1'></circle>
        <circle cx='160' cy='44.74' r='1'></circle>
        <circle cx='166' cy='44.74' r='1'></circle>
        <circle cx='172' cy='44.74' r='1'></circle>
        <circle cx='178' cy='44.74' r='1'></circle>
        <circle cx='184' cy='44.74' r='1'></circle>
        <circle cx='190' cy='44.74' r='1'></circle>
        <circle cx='196' cy='44.74' r='1'></circle>
        <circle cx='202' cy='44.74' r='1'></circle>
        <circle cx='208' cy='44.74' r='1'></circle>
        <circle cx='214' cy='44.74' r='1'></circle>
        <circle cx='220' cy='44.74' r='1'></circle>
        <circle cx='226' cy='44.74' r='1'></circle>
        <circle cx='232' cy='44.74' r='1'></circle>
        <circle cx='238' cy='44.74' r='1'></circle>
        <circle cx='244' cy='44.74' r='1'></circle>
        <circle cx='4' cy='190.54' r='1'></circle>
        <circle cx='10' cy='190.54' r='1'></circle>
        <circle cx='16' cy='190.54' r='1'></circle>
        <circle cx='22' cy='190.54' r='1'></circle>
        <path d='M29 190.54a1 1 0 11-2 0 1 1 0 012 0zM35 190.54a1 1 0 11-2 0 1 1 0 012 0zM41 190.54a1 1 0 11-2 0 1 1 0 012 0zM47 190.54a1 1 0 11-2 0 1 1 0 012 0zM53 190.54a1 1 0 11-2 0 1 1 0 012 0zM59 190.54a1 1 0 11-2 0 1 1 0 012 0zM65 190.54a1 1 0 11-2 0 1 1 0 012 0zM71 190.54a1 1 0 11-2 0 1 1 0 012 0zM77 190.54a1 1 0 11-2 0 1 1 0 012 0zM83 190.54a1 1 0 11-2 0 1 1 0 012 0zM89 190.54a1 1 0 11-2 0 1 1 0 012 0zM95 190.54a1 1 0 11-2 0 1 1 0 012 0zM101 190.54a1 1 0 11-2 0 1 1 0 012 0zM107 190.54a1 1 0 11-2 0 1 1 0 012 0zM113 190.54a1 1 0 11-2 0 1 1 0 012 0zM119 190.54a1 1 0 11-2 0 1 1 0 012 0zM125 190.54a1 1 0 11-2 0 1 1 0 012 0zM131 190.54a1 1 0 11-2 0 1 1 0 012 0zM137 190.54a1 1 0 11-2 0 1 1 0 012 0zM143 190.54a1 1 0 11-2 0 1 1 0 012 0z'></path>
        <circle cx='148' cy='190.54' r='1'></circle>
        <circle cx='154' cy='190.54' r='1'></circle>
        <circle cx='160' cy='190.54' r='1'></circle>
        <circle cx='166' cy='190.54' r='1'></circle>
        <circle cx='172' cy='190.54' r='1'></circle>
        <circle cx='178' cy='190.54' r='1'></circle>
        <circle cx='184' cy='190.54' r='1'></circle>
        <circle cx='190' cy='190.54' r='1'></circle>
        <circle cx='196' cy='190.54' r='1'></circle>
        <circle cx='202' cy='190.54' r='1'></circle>
        <circle cx='208' cy='190.54' r='1'></circle>
        <circle cx='214' cy='190.54' r='1'></circle>
        <circle cx='220' cy='190.54' r='1'></circle>
        <circle cx='226' cy='190.54' r='1'></circle>
        <circle cx='232' cy='190.54' r='1'></circle>
        <circle cx='238' cy='190.54' r='1'></circle>
        <circle cx='244' cy='190.54' r='1'></circle>
        <circle cx='4' cy='73.9' r='1'></circle>
        <circle cx='10' cy='73.9' r='1'></circle>
        <circle cx='16' cy='73.9' r='1'></circle>
        <circle cx='22' cy='73.9' r='1'></circle>
        <path d='M29 73.9a1 1 0 11-2 0 1 1 0 012 0zM35 73.9a1 1 0 11-2 0 1 1 0 012 0zM41 73.9a1 1 0 11-2 0 1 1 0 012 0zM47 73.9a1 1 0 11-2 0 1 1 0 012 0zM53 73.9a1 1 0 11-2 0 1 1 0 012 0zM59 73.9a1 1 0 11-2 0 1 1 0 012 0zM65 73.9a1 1 0 11-2 0 1 1 0 012 0zM71 73.9a1 1 0 11-2 0 1 1 0 012 0zM77 73.9a1 1 0 11-2 0 1 1 0 012 0zM83 73.9a1 1 0 11-2 0 1 1 0 012 0zM89 73.9a1 1 0 11-2 0 1 1 0 012 0zM95 73.9a1 1 0 11-2 0 1 1 0 012 0zM101 73.9a1 1 0 11-2 0 1 1 0 012 0zM107 73.9a1 1 0 11-2 0 1 1 0 012 0zM113 73.9a1 1 0 11-2 0 1 1 0 012 0zM119 73.9a1 1 0 11-2 0 1 1 0 012 0zM125 73.9a1 1 0 11-2 0 1 1 0 012 0zM131 73.9a1 1 0 11-2 0 1 1 0 012 0zM137 73.9a1 1 0 11-2 0 1 1 0 012 0zM143 73.9a1 1 0 11-2 0 1 1 0 012 0z'></path>
        <circle cx='148' cy='73.9' r='1'></circle>
        <circle cx='154' cy='73.9' r='1'></circle>
        <circle cx='160' cy='73.9' r='1'></circle>
        <circle cx='166' cy='73.9' r='1'></circle>
        <circle cx='172' cy='73.9' r='1'></circle>
        <circle cx='178' cy='73.9' r='1'></circle>
        <circle cx='184' cy='73.9' r='1'></circle>
        <circle cx='190' cy='73.9' r='1'></circle>
        <circle cx='196' cy='73.9' r='1'></circle>
        <circle cx='202' cy='73.9' r='1'></circle>
        <circle cx='208' cy='73.9' r='1'></circle>
        <circle cx='214' cy='73.9' r='1'></circle>
        <circle cx='220' cy='73.9' r='1'></circle>
        <circle cx='226' cy='73.9' r='1'></circle>
        <circle cx='232' cy='73.9' r='1'></circle>
        <circle cx='238' cy='73.9' r='1'></circle>
        <circle cx='244' cy='73.9' r='1'></circle>
        <circle cx='4' cy='219.7' r='1'></circle>
        <circle cx='10' cy='219.7' r='1'></circle>
        <circle cx='16' cy='219.7' r='1'></circle>
        <circle cx='22' cy='219.7' r='1'></circle>
        <path d='M29 219.7a1 1 0 11-2 0 1 1 0 012 0zM35 219.7a1 1 0 11-2 0 1 1 0 012 0zM41 219.7a1 1 0 11-2 0 1 1 0 012 0zM47 219.7a1 1 0 11-2 0 1 1 0 012 0zM53 219.7a1 1 0 11-2 0 1 1 0 012 0zM59 219.7a1 1 0 11-2 0 1 1 0 012 0zM65 219.7a1 1 0 11-2 0 1 1 0 012 0zM71 219.7a1 1 0 11-2 0 1 1 0 012 0zM77 219.7a1 1 0 11-2 0 1 1 0 012 0zM83 219.7a1 1 0 11-2 0 1 1 0 012 0zM89 219.7a1 1 0 11-2 0 1 1 0 012 0zM95 219.7a1 1 0 11-2 0 1 1 0 012 0zM101 219.7a1 1 0 11-2 0 1 1 0 012 0zM107 219.7a1 1 0 11-2 0 1 1 0 012 0zM113 219.7a1 1 0 11-2 0 1 1 0 012 0zM119 219.7a1 1 0 11-2 0 1 1 0 012 0zM125 219.7a1 1 0 11-2 0 1 1 0 012 0zM131 219.7a1 1 0 11-2 0 1 1 0 012 0zM137 219.7a1 1 0 11-2 0 1 1 0 012 0zM143 219.7a1 1 0 11-2 0 1 1 0 012 0z'></path>
        <circle cx='148' cy='219.7' r='1'></circle>
        <circle cx='154' cy='219.7' r='1'></circle>
        <circle cx='160' cy='219.7' r='1'></circle>
        <circle cx='166' cy='219.7' r='1'></circle>
        <circle cx='172' cy='219.7' r='1'></circle>
        <circle cx='178' cy='219.7' r='1'></circle>
        <circle cx='184' cy='219.7' r='1'></circle>
        <circle cx='190' cy='219.7' r='1'></circle>
        <circle cx='196' cy='219.7' r='1'></circle>
        <circle cx='202' cy='219.7' r='1'></circle>
        <circle cx='208' cy='219.7' r='1'></circle>
        <circle cx='214' cy='219.7' r='1'></circle>
        <circle cx='220' cy='219.7' r='1'></circle>
        <circle cx='226' cy='219.7' r='1'></circle>
        <circle cx='232' cy='219.7' r='1'></circle>
        <circle cx='238' cy='219.7' r='1'></circle>
        <circle cx='244' cy='219.7' r='1'></circle>
        <circle cx='4' cy='103.06' r='1'></circle>
        <circle cx='10' cy='103.06' r='1'></circle>
        <circle cx='16' cy='103.06' r='1'></circle>
        <circle cx='22' cy='103.06' r='1'></circle>
        <path d='M29 103.06a1 1 0 11-2 0 1 1 0 012 0zM35 103.06a1 1 0 11-2 0 1 1 0 012 0zM41 103.06a1 1 0 11-2 0 1 1 0 012 0zM47 103.06a1 1 0 11-2 0 1 1 0 012 0zM53 103.06a1 1 0 11-2 0 1 1 0 012 0zM59 103.06a1 1 0 11-2 0 1 1 0 012 0zM65 103.06a1 1 0 11-2 0 1 1 0 012 0zM71 103.06a1 1 0 11-2 0 1 1 0 012 0zM77 103.06a1 1 0 11-2 0 1 1 0 012 0zM83 103.06a1 1 0 11-2 0 1 1 0 012 0zM89 103.06a1 1 0 11-2 0 1 1 0 012 0zM95 103.06a1 1 0 11-2 0 1 1 0 012 0zM101 103.06a1 1 0 11-2 0 1 1 0 012 0zM107 103.06a1 1 0 11-2 0 1 1 0 012 0zM113 103.06a1 1 0 11-2 0 1 1 0 012 0zM119 103.06a1 1 0 11-2 0 1 1 0 012 0zM125 103.06a1 1 0 11-2 0 1 1 0 012 0zM131 103.06a1 1 0 11-2 0 1 1 0 012 0zM137 103.06a1 1 0 11-2 0 1 1 0 012 0zM143 103.06a1 1 0 11-2 0 1 1 0 012 0z'></path>
        <circle cx='148' cy='103.06' r='1'></circle>
        <circle cx='154' cy='103.06' r='1'></circle>
        <circle cx='160' cy='103.06' r='1'></circle>
        <circle cx='166' cy='103.06' r='1'></circle>
        <circle cx='172' cy='103.06' r='1'></circle>
        <circle cx='178' cy='103.06' r='1'></circle>
        <circle cx='184' cy='103.06' r='1'></circle>
        <circle cx='190' cy='103.06' r='1'></circle>
        <circle cx='196' cy='103.06' r='1'></circle>
        <circle cx='202' cy='103.06' r='1'></circle>
        <circle cx='208' cy='103.06' r='1'></circle>
        <circle cx='214' cy='103.06' r='1'></circle>
        <circle cx='220' cy='103.06' r='1'></circle>
        <circle cx='226' cy='103.06' r='1'></circle>
        <circle cx='232' cy='103.06' r='1'></circle>
        <circle cx='238' cy='103.06' r='1'></circle>
        <circle cx='244' cy='103.06' r='1'></circle>
        <circle cx='4' cy='132.22' r='1'></circle>
        <circle cx='10' cy='132.22' r='1'></circle>
        <circle cx='16' cy='132.22' r='1'></circle>
        <circle cx='22' cy='132.22' r='1'></circle>
        <path d='M29 132.22a1 1 0 11-2 0 1 1 0 012 0zM35 132.22a1 1 0 11-2 0 1 1 0 012 0zM41 132.22a1 1 0 11-2 0 1 1 0 012 0zM47 132.22a1 1 0 11-2 0 1 1 0 012 0zM53 132.22a1 1 0 11-2 0 1 1 0 012 0zM59 132.22a1 1 0 11-2 0 1 1 0 012 0zM65 132.22a1 1 0 11-2 0 1 1 0 012 0zM71 132.22a1 1 0 11-2 0 1 1 0 012 0zM77 132.22a1 1 0 11-2 0 1 1 0 012 0zM83 132.22a1 1 0 11-2 0 1 1 0 012 0zM89 132.22a1 1 0 11-2 0 1 1 0 012 0zM95 132.22a1 1 0 11-2 0 1 1 0 012 0zM101 132.22a1 1 0 11-2 0 1 1 0 012 0zM107 132.22a1 1 0 11-2 0 1 1 0 012 0zM113 132.22a1 1 0 11-2 0 1 1 0 012 0zM119 132.22a1 1 0 11-2 0 1 1 0 012 0zM125 132.22a1 1 0 11-2 0 1 1 0 012 0zM131 132.22a1 1 0 11-2 0 1 1 0 012 0zM137 132.22a1 1 0 11-2 0 1 1 0 012 0zM143 132.22a1 1 0 11-2 0 1 1 0 012 0z'></path>
        <circle cx='148' cy='132.22' r='1'></circle>
        <circle cx='154' cy='132.22' r='1'></circle>
        <circle cx='160' cy='132.22' r='1'></circle>
        <circle cx='166' cy='132.22' r='1'></circle>
        <circle cx='172' cy='132.22' r='1'></circle>
        <circle cx='178' cy='132.22' r='1'></circle>
        <circle cx='184' cy='132.22' r='1'></circle>
        <circle cx='190' cy='132.22' r='1'></circle>
        <circle cx='196' cy='132.22' r='1'></circle>
        <circle cx='202' cy='132.22' r='1'></circle>
        <circle cx='208' cy='132.22' r='1'></circle>
        <circle cx='214' cy='132.22' r='1'></circle>
        <circle cx='220' cy='132.22' r='1'></circle>
        <circle cx='226' cy='132.22' r='1'></circle>
        <circle cx='232' cy='132.22' r='1'></circle>
        <circle cx='238' cy='132.22' r='1'></circle>
        <circle cx='244' cy='132.22' r='1'></circle>
        <circle cx='1.645' cy='8.29' r='1'></circle>
        <circle cx='7.645' cy='8.29' r='1'></circle>
        <circle cx='13.645' cy='8.29' r='1'></circle>
        <circle cx='19.645' cy='8.29' r='1'></circle>
        <circle cx='25.645' cy='8.29' r='1'></circle>
        <path d='M32.645 8.29a1 1 0 11-2 0 1 1 0 012 0zM38.645 8.29a1 1 0 11-2 0 1 1 0 012 0zM44.645 8.29a1 1 0 11-2 0 1 1 0 012 0zM50.645 8.29a1 1 0 11-2 0 1 1 0 012 0zM56.645 8.29a1 1 0 11-2 0 1 1 0 012 0zM62.645 8.29a1 1 0 11-2 0 1 1 0 012 0zM68.645 8.29a1 1 0 11-2 0 1 1 0 012 0zM74.645 8.29a1 1 0 11-2 0 1 1 0 012 0zM80.645 8.29a1 1 0 11-2 0 1 1 0 012 0zM86.645 8.29a1 1 0 11-2 0 1 1 0 012 0zM92.645 8.29a1 1 0 11-2 0 1 1 0 012 0zM98.645 8.29a1 1 0 11-2 0 1 1 0 012 0zM104.645 8.29a1 1 0 11-2 0 1 1 0 012 0zM110.645 8.29a1 1 0 11-2 0 1 1 0 012 0zM116.645 8.29a1 1 0 11-2 0 1 1 0 012 0zM122.645 8.29a1 1 0 11-2 0 1 1 0 012 0zM128.645 8.29a1 1 0 11-2 0 1 1 0 012 0zM134.645 8.29a1 1 0 11-2 0 1 1 0 012 0zM140.645 8.29a1 1 0 11-2 0 1 1 0 012 0zM146.645 8.29a1 1 0 11-2 0 1 1 0 012 0z'></path>
        <circle cx='151.645' cy='8.29' r='1'></circle>
        <circle cx='157.645' cy='8.29' r='1'></circle>
        <circle cx='163.645' cy='8.29' r='1'></circle>
        <circle cx='169.645' cy='8.29' r='1'></circle>
        <circle cx='175.645' cy='8.29' r='1'></circle>
        <circle cx='181.645' cy='8.29' r='1'></circle>
        <circle cx='187.645' cy='8.29' r='1'></circle>
        <circle cx='193.645' cy='8.29' r='1'></circle>
        <circle cx='199.645' cy='8.29' r='1'></circle>
        <circle cx='205.645' cy='8.29' r='1'></circle>
        <circle cx='211.645' cy='8.29' r='1'></circle>
        <circle cx='217.645' cy='8.29' r='1'></circle>
        <circle cx='223.645' cy='8.29' r='1'></circle>
        <circle cx='229.645' cy='8.29' r='1'></circle>
        <circle cx='235.645' cy='8.29' r='1'></circle>
        <circle cx='241.645' cy='8.29' r='1'></circle>
        <circle cx='247.645' cy='8.29' r='1'></circle>
        <circle cx='1.645' cy='154.09' r='1'></circle>
        <circle cx='7.645' cy='154.09' r='1'></circle>
        <circle cx='13.645' cy='154.09' r='1'></circle>
        <circle cx='19.645' cy='154.09' r='1'></circle>
        <circle cx='25.645' cy='154.09' r='1'></circle>
        <path d='M32.645 154.09a1 1 0 11-2 0 1 1 0 012 0zM38.645 154.09a1 1 0 11-2 0 1 1 0 012 0zM44.645 154.09a1 1 0 11-2 0 1 1 0 012 0zM50.645 154.09a1 1 0 11-2 0 1 1 0 012 0zM56.645 154.09a1 1 0 11-2 0 1 1 0 012 0zM62.645 154.09a1 1 0 11-2 0 1 1 0 012 0zM68.645 154.09a1 1 0 11-2 0 1 1 0 012 0zM74.645 154.09a1 1 0 11-2 0 1 1 0 012 0zM80.645 154.09a1 1 0 11-2 0 1 1 0 012 0zM86.645 154.09a1 1 0 11-2 0 1 1 0 012 0zM92.645 154.09a1 1 0 11-2 0 1 1 0 012 0zM98.645 154.09a1 1 0 11-2 0 1 1 0 012 0zM104.645 154.09a1 1 0 11-2 0 1 1 0 012 0zM110.645 154.09a1 1 0 11-2 0 1 1 0 012 0zM116.645 154.09a1 1 0 11-2 0 1 1 0 012 0zM122.645 154.09a1 1 0 11-2 0 1 1 0 012 0zM128.645 154.09a1 1 0 11-2 0 1 1 0 012 0zM134.645 154.09a1 1 0 11-2 0 1 1 0 012 0zM140.645 154.09a1 1 0 11-2 0 1 1 0 012 0zM146.645 154.09a1 1 0 11-2 0 1 1 0 012 0z'></path>
        <circle cx='151.645' cy='154.09' r='1'></circle>
        <circle cx='157.645' cy='154.09' r='1'></circle>
        <circle cx='163.645' cy='154.09' r='1'></circle>
        <circle cx='169.645' cy='154.09' r='1'></circle>
        <circle cx='175.645' cy='154.09' r='1'></circle>
        <circle cx='181.645' cy='154.09' r='1'></circle>
        <circle cx='187.645' cy='154.09' r='1'></circle>
        <circle cx='193.645' cy='154.09' r='1'></circle>
        <circle cx='199.645' cy='154.09' r='1'></circle>
        <circle cx='205.645' cy='154.09' r='1'></circle>
        <circle cx='211.645' cy='154.09' r='1'></circle>
        <circle cx='217.645' cy='154.09' r='1'></circle>
        <circle cx='223.645' cy='154.09' r='1'></circle>
        <circle cx='229.645' cy='154.09' r='1'></circle>
        <circle cx='235.645' cy='154.09' r='1'></circle>
        <circle cx='241.645' cy='154.09' r='1'></circle>
        <circle cx='247.645' cy='154.09' r='1'></circle>
        <circle cx='1.645' cy='37.45' r='1'></circle>
        <circle cx='7.645' cy='37.45' r='1'></circle>
        <circle cx='13.645' cy='37.45' r='1'></circle>
        <circle cx='19.645' cy='37.45' r='1'></circle>
        <circle cx='25.645' cy='37.45' r='1'></circle>
        <path d='M32.645 37.45a1 1 0 11-2 0 1 1 0 012 0zM38.645 37.45a1 1 0 11-2 0 1 1 0 012 0zM44.645 37.45a1 1 0 11-2 0 1 1 0 012 0zM50.645 37.45a1 1 0 11-2 0 1 1 0 012 0zM56.645 37.45a1 1 0 11-2 0 1 1 0 012 0zM62.645 37.45a1 1 0 11-2 0 1 1 0 012 0zM68.645 37.45a1 1 0 11-2 0 1 1 0 012 0zM74.645 37.45a1 1 0 11-2 0 1 1 0 012 0zM80.645 37.45a1 1 0 11-2 0 1 1 0 012 0zM86.645 37.45a1 1 0 11-2 0 1 1 0 012 0zM92.645 37.45a1 1 0 11-2 0 1 1 0 012 0zM98.645 37.45a1 1 0 11-2 0 1 1 0 012 0zM104.645 37.45a1 1 0 11-2 0 1 1 0 012 0zM110.645 37.45a1 1 0 11-2 0 1 1 0 012 0zM116.645 37.45a1 1 0 11-2 0 1 1 0 012 0zM122.645 37.45a1 1 0 11-2 0 1 1 0 012 0zM128.645 37.45a1 1 0 11-2 0 1 1 0 012 0zM134.645 37.45a1 1 0 11-2 0 1 1 0 012 0zM140.645 37.45a1 1 0 11-2 0 1 1 0 012 0zM146.645 37.45a1 1 0 11-2 0 1 1 0 012 0z'></path>
        <circle cx='151.645' cy='37.45' r='1'></circle>
        <circle cx='157.645' cy='37.45' r='1'></circle>
        <circle cx='163.645' cy='37.45' r='1'></circle>
        <circle cx='169.645' cy='37.45' r='1'></circle>
        <circle cx='175.645' cy='37.45' r='1'></circle>
        <circle cx='181.645' cy='37.45' r='1'></circle>
        <circle cx='187.645' cy='37.45' r='1'></circle>
        <circle cx='193.645' cy='37.45' r='1'></circle>
        <circle cx='199.645' cy='37.45' r='1'></circle>
        <circle cx='205.645' cy='37.45' r='1'></circle>
        <circle cx='211.645' cy='37.45' r='1'></circle>
        <circle cx='217.645' cy='37.45' r='1'></circle>
        <circle cx='223.645' cy='37.45' r='1'></circle>
        <circle cx='229.645' cy='37.45' r='1'></circle>
        <circle cx='235.645' cy='37.45' r='1'></circle>
        <circle cx='241.645' cy='37.45' r='1'></circle>
        <circle cx='247.645' cy='37.45' r='1'></circle>
        <circle cx='1.645' cy='183.25' r='1'></circle>
        <circle cx='7.645' cy='183.25' r='1'></circle>
        <circle cx='13.645' cy='183.25' r='1'></circle>
        <circle cx='19.645' cy='183.25' r='1'></circle>
        <circle cx='25.645' cy='183.25' r='1'></circle>
        <path d='M32.645 183.25a1 1 0 11-2 0 1 1 0 012 0zM38.645 183.25a1 1 0 11-2 0 1 1 0 012 0zM44.645 183.25a1 1 0 11-2 0 1 1 0 012 0zM50.645 183.25a1 1 0 11-2 0 1 1 0 012 0zM56.645 183.25a1 1 0 11-2 0 1 1 0 012 0zM62.645 183.25a1 1 0 11-2 0 1 1 0 012 0zM68.645 183.25a1 1 0 11-2 0 1 1 0 012 0zM74.645 183.25a1 1 0 11-2 0 1 1 0 012 0zM80.645 183.25a1 1 0 11-2 0 1 1 0 012 0zM86.645 183.25a1 1 0 11-2 0 1 1 0 012 0zM92.645 183.25a1 1 0 11-2 0 1 1 0 012 0zM98.645 183.25a1 1 0 11-2 0 1 1 0 012 0zM104.645 183.25a1 1 0 11-2 0 1 1 0 012 0zM110.645 183.25a1 1 0 11-2 0 1 1 0 012 0zM116.645 183.25a1 1 0 11-2 0 1 1 0 012 0zM122.645 183.25a1 1 0 11-2 0 1 1 0 012 0zM128.645 183.25a1 1 0 11-2 0 1 1 0 012 0zM134.645 183.25a1 1 0 11-2 0 1 1 0 012 0zM140.645 183.25a1 1 0 11-2 0 1 1 0 012 0zM146.645 183.25a1 1 0 11-2 0 1 1 0 012 0z'></path>
        <circle cx='151.645' cy='183.25' r='1'></circle>
        <circle cx='157.645' cy='183.25' r='1'></circle>
        <circle cx='163.645' cy='183.25' r='1'></circle>
        <circle cx='169.645' cy='183.25' r='1'></circle>
        <circle cx='175.645' cy='183.25' r='1'></circle>
        <circle cx='181.645' cy='183.25' r='1'></circle>
        <circle cx='187.645' cy='183.25' r='1'></circle>
        <circle cx='193.645' cy='183.25' r='1'></circle>
        <circle cx='199.645' cy='183.25' r='1'></circle>
        <circle cx='205.645' cy='183.25' r='1'></circle>
        <circle cx='211.645' cy='183.25' r='1'></circle>
        <circle cx='217.645' cy='183.25' r='1'></circle>
        <circle cx='223.645' cy='183.25' r='1'></circle>
        <circle cx='229.645' cy='183.25' r='1'></circle>
        <circle cx='235.645' cy='183.25' r='1'></circle>
        <circle cx='241.645' cy='183.25' r='1'></circle>
        <circle cx='247.645' cy='183.25' r='1'></circle>
        <circle cx='1.645' cy='66.61' r='1'></circle>
        <circle cx='7.645' cy='66.61' r='1'></circle>
        <circle cx='13.645' cy='66.61' r='1'></circle>
        <circle cx='19.645' cy='66.61' r='1'></circle>
        <circle cx='25.645' cy='66.61' r='1'></circle>
        <path d='M32.645 66.61a1 1 0 11-2 0 1 1 0 012 0zM38.645 66.61a1 1 0 11-2 0 1 1 0 012 0zM44.645 66.61a1 1 0 11-2 0 1 1 0 012 0zM50.645 66.61a1 1 0 11-2 0 1 1 0 012 0zM56.645 66.61a1 1 0 11-2 0 1 1 0 012 0zM62.645 66.61a1 1 0 11-2 0 1 1 0 012 0zM68.645 66.61a1 1 0 11-2 0 1 1 0 012 0zM74.645 66.61a1 1 0 11-2 0 1 1 0 012 0zM80.645 66.61a1 1 0 11-2 0 1 1 0 012 0zM86.645 66.61a1 1 0 11-2 0 1 1 0 012 0zM92.645 66.61a1 1 0 11-2 0 1 1 0 012 0zM98.645 66.61a1 1 0 11-2 0 1 1 0 012 0zM104.645 66.61a1 1 0 11-2 0 1 1 0 012 0zM110.645 66.61a1 1 0 11-2 0 1 1 0 012 0zM116.645 66.61a1 1 0 11-2 0 1 1 0 012 0zM122.645 66.61a1 1 0 11-2 0 1 1 0 012 0zM128.645 66.61a1 1 0 11-2 0 1 1 0 012 0zM134.645 66.61a1 1 0 11-2 0 1 1 0 012 0zM140.645 66.61a1 1 0 11-2 0 1 1 0 012 0zM146.645 66.61a1 1 0 11-2 0 1 1 0 012 0z'></path>
        <circle cx='151.645' cy='66.61' r='1'></circle>
        <circle cx='157.645' cy='66.61' r='1'></circle>
        <circle cx='163.645' cy='66.61' r='1'></circle>
        <circle cx='169.645' cy='66.61' r='1'></circle>
        <circle cx='175.645' cy='66.61' r='1'></circle>
        <circle cx='181.645' cy='66.61' r='1'></circle>
        <circle cx='187.645' cy='66.61' r='1'></circle>
        <circle cx='193.645' cy='66.61' r='1'></circle>
        <circle cx='199.645' cy='66.61' r='1'></circle>
        <circle cx='205.645' cy='66.61' r='1'></circle>
        <circle cx='211.645' cy='66.61' r='1'></circle>
        <circle cx='217.645' cy='66.61' r='1'></circle>
        <circle cx='223.645' cy='66.61' r='1'></circle>
        <circle cx='229.645' cy='66.61' r='1'></circle>
        <circle cx='235.645' cy='66.61' r='1'></circle>
        <circle cx='241.645' cy='66.61' r='1'></circle>
        <circle cx='247.645' cy='66.61' r='1'></circle>
        <circle cx='1.645' cy='212.41' r='1'></circle>
        <circle cx='7.645' cy='212.41' r='1'></circle>
        <circle cx='13.645' cy='212.41' r='1'></circle>
        <circle cx='19.645' cy='212.41' r='1'></circle>
        <circle cx='25.645' cy='212.41' r='1'></circle>
        <path d='M32.645 212.41a1 1 0 11-2 0 1 1 0 012 0zM38.645 212.41a1 1 0 11-2 0 1 1 0 012 0zM44.645 212.41a1 1 0 11-2 0 1 1 0 012 0zM50.645 212.41a1 1 0 11-2 0 1 1 0 012 0zM56.645 212.41a1 1 0 11-2 0 1 1 0 012 0zM62.645 212.41a1 1 0 11-2 0 1 1 0 012 0zM68.645 212.41a1 1 0 11-2 0 1 1 0 012 0zM74.645 212.41a1 1 0 11-2 0 1 1 0 012 0zM80.645 212.41a1 1 0 11-2 0 1 1 0 012 0zM86.645 212.41a1 1 0 11-2 0 1 1 0 012 0zM92.645 212.41a1 1 0 11-2 0 1 1 0 012 0zM98.645 212.41a1 1 0 11-2 0 1 1 0 012 0zM104.645 212.41a1 1 0 11-2 0 1 1 0 012 0zM110.645 212.41a1 1 0 11-2 0 1 1 0 012 0zM116.645 212.41a1 1 0 11-2 0 1 1 0 012 0zM122.645 212.41a1 1 0 11-2 0 1 1 0 012 0zM128.645 212.41a1 1 0 11-2 0 1 1 0 012 0zM134.645 212.41a1 1 0 11-2 0 1 1 0 012 0zM140.645 212.41a1 1 0 11-2 0 1 1 0 012 0zM146.645 212.41a1 1 0 11-2 0 1 1 0 012 0z'></path>
        <circle cx='151.645' cy='212.41' r='1'></circle>
        <circle cx='157.645' cy='212.41' r='1'></circle>
        <circle cx='163.645' cy='212.41' r='1'></circle>
        <circle cx='169.645' cy='212.41' r='1'></circle>
        <circle cx='175.645' cy='212.41' r='1'></circle>
        <circle cx='181.645' cy='212.41' r='1'></circle>
        <circle cx='187.645' cy='212.41' r='1'></circle>
        <circle cx='193.645' cy='212.41' r='1'></circle>
        <circle cx='199.645' cy='212.41' r='1'></circle>
        <circle cx='205.645' cy='212.41' r='1'></circle>
        <circle cx='211.645' cy='212.41' r='1'></circle>
        <circle cx='217.645' cy='212.41' r='1'></circle>
        <circle cx='223.645' cy='212.41' r='1'></circle>
        <circle cx='229.645' cy='212.41' r='1'></circle>
        <circle cx='235.645' cy='212.41' r='1'></circle>
        <circle cx='241.645' cy='212.41' r='1'></circle>
        <circle cx='247.645' cy='212.41' r='1'></circle>
        <circle cx='1.645' cy='95.77' r='1'></circle>
        <circle cx='7.645' cy='95.77' r='1'></circle>
        <circle cx='13.645' cy='95.77' r='1'></circle>
        <circle cx='19.645' cy='95.77' r='1'></circle>
        <circle cx='25.645' cy='95.77' r='1'></circle>
        <path d='M32.645 95.77a1 1 0 11-2 0 1 1 0 012 0zM38.645 95.77a1 1 0 11-2 0 1 1 0 012 0zM44.645 95.77a1 1 0 11-2 0 1 1 0 012 0zM50.645 95.77a1 1 0 11-2 0 1 1 0 012 0zM56.645 95.77a1 1 0 11-2 0 1 1 0 012 0zM62.645 95.77a1 1 0 11-2 0 1 1 0 012 0zM68.645 95.77a1 1 0 11-2 0 1 1 0 012 0zM74.645 95.77a1 1 0 11-2 0 1 1 0 012 0zM80.645 95.77a1 1 0 11-2 0 1 1 0 012 0zM86.645 95.77a1 1 0 11-2 0 1 1 0 012 0zM92.645 95.77a1 1 0 11-2 0 1 1 0 012 0zM98.645 95.77a1 1 0 11-2 0 1 1 0 012 0zM104.645 95.77a1 1 0 11-2 0 1 1 0 012 0zM110.645 95.77a1 1 0 11-2 0 1 1 0 012 0zM116.645 95.77a1 1 0 11-2 0 1 1 0 012 0zM122.645 95.77a1 1 0 11-2 0 1 1 0 012 0zM128.645 95.77a1 1 0 11-2 0 1 1 0 012 0zM134.645 95.77a1 1 0 11-2 0 1 1 0 012 0zM140.645 95.77a1 1 0 11-2 0 1 1 0 012 0zM146.645 95.77a1 1 0 11-2 0 1 1 0 012 0z'></path>
        <circle cx='151.645' cy='95.77' r='1'></circle>
        <circle cx='157.645' cy='95.77' r='1'></circle>
        <circle cx='163.645' cy='95.77' r='1'></circle>
        <circle cx='169.645' cy='95.77' r='1'></circle>
        <circle cx='175.645' cy='95.77' r='1'></circle>
        <circle cx='181.645' cy='95.77' r='1'></circle>
        <circle cx='187.645' cy='95.77' r='1'></circle>
        <circle cx='193.645' cy='95.77' r='1'></circle>
        <circle cx='199.645' cy='95.77' r='1'></circle>
        <circle cx='205.645' cy='95.77' r='1'></circle>
        <circle cx='211.645' cy='95.77' r='1'></circle>
        <circle cx='217.645' cy='95.77' r='1'></circle>
        <circle cx='223.645' cy='95.77' r='1'></circle>
        <circle cx='229.645' cy='95.77' r='1'></circle>
        <circle cx='235.645' cy='95.77' r='1'></circle>
        <circle cx='241.645' cy='95.77' r='1'></circle>
        <circle cx='247.645' cy='95.77' r='1'></circle>
        <circle cx='1.645' cy='241.57' r='1'></circle>
        <circle cx='7.645' cy='241.57' r='1'></circle>
        <circle cx='13.645' cy='241.57' r='1'></circle>
        <circle cx='19.645' cy='241.57' r='1'></circle>
        <circle cx='25.645' cy='241.57' r='1'></circle>
        <path d='M32.645 241.57a1 1 0 11-2 0 1 1 0 012 0zM38.645 241.57a1 1 0 11-2 0 1 1 0 012 0zM44.645 241.57a1 1 0 11-2 0 1 1 0 012 0zM50.645 241.57a1 1 0 11-2 0 1 1 0 012 0zM56.645 241.57a1 1 0 11-2 0 1 1 0 012 0zM62.645 241.57a1 1 0 11-2 0 1 1 0 012 0zM68.645 241.57a1 1 0 11-2 0 1 1 0 012 0zM74.645 241.57a1 1 0 11-2 0 1 1 0 012 0zM80.645 241.57a1 1 0 11-2 0 1 1 0 012 0zM86.645 241.57a1 1 0 11-2 0 1 1 0 012 0zM92.645 241.57a1 1 0 11-2 0 1 1 0 012 0zM98.645 241.57a1 1 0 11-2 0 1 1 0 012 0zM104.645 241.57a1 1 0 11-2 0 1 1 0 012 0zM110.645 241.57a1 1 0 11-2 0 1 1 0 012 0zM116.645 241.57a1 1 0 11-2 0 1 1 0 012 0zM122.645 241.57a1 1 0 11-2 0 1 1 0 012 0zM128.645 241.57a1 1 0 11-2 0 1 1 0 012 0zM134.645 241.57a1 1 0 11-2 0 1 1 0 012 0zM140.645 241.57a1 1 0 11-2 0 1 1 0 012 0zM146.645 241.57a1 1 0 11-2 0 1 1 0 012 0z'></path>
        <circle cx='151.645' cy='241.57' r='1'></circle>
        <circle cx='157.645' cy='241.57' r='1'></circle>
        <circle cx='163.645' cy='241.57' r='1'></circle>
        <circle cx='169.645' cy='241.57' r='1'></circle>
        <circle cx='175.645' cy='241.57' r='1'></circle>
        <circle cx='181.645' cy='241.57' r='1'></circle>
        <circle cx='187.645' cy='241.57' r='1'></circle>
        <circle cx='193.645' cy='241.57' r='1'></circle>
        <circle cx='199.645' cy='241.57' r='1'></circle>
        <circle cx='205.645' cy='241.57' r='1'></circle>
        <circle cx='211.645' cy='241.57' r='1'></circle>
        <circle cx='217.645' cy='241.57' r='1'></circle>
        <circle cx='223.645' cy='241.57' r='1'></circle>
        <circle cx='229.645' cy='241.57' r='1'></circle>
        <circle cx='235.645' cy='241.57' r='1'></circle>
        <circle cx='241.645' cy='241.57' r='1'></circle>
        <circle cx='247.645' cy='241.57' r='1'></circle>
        <circle cx='1.645' cy='124.93' r='1'></circle>
        <circle cx='7.645' cy='124.93' r='1'></circle>
        <circle cx='13.645' cy='124.93' r='1'></circle>
        <circle cx='19.645' cy='124.93' r='1'></circle>
        <circle cx='25.645' cy='124.93' r='1'></circle>
        <path d='M32.645 124.93a1 1 0 11-2 0 1 1 0 012 0zM38.645 124.93a1 1 0 11-2 0 1 1 0 012 0zM44.645 124.93a1 1 0 11-2 0 1 1 0 012 0zM50.645 124.93a1 1 0 11-2 0 1 1 0 012 0zM56.645 124.93a1 1 0 11-2 0 1 1 0 012 0zM62.645 124.93a1 1 0 11-2 0 1 1 0 012 0zM68.645 124.93a1 1 0 11-2 0 1 1 0 012 0zM74.645 124.93a1 1 0 11-2 0 1 1 0 012 0zM80.645 124.93a1 1 0 11-2 0 1 1 0 012 0zM86.645 124.93a1 1 0 11-2 0 1 1 0 012 0zM92.645 124.93a1 1 0 11-2 0 1 1 0 012 0zM98.645 124.93a1 1 0 11-2 0 1 1 0 012 0zM104.645 124.93a1 1 0 11-2 0 1 1 0 012 0zM110.645 124.93a1 1 0 11-2 0 1 1 0 012 0zM116.645 124.93a1 1 0 11-2 0 1 1 0 012 0zM122.645 124.93a1 1 0 11-2 0 1 1 0 012 0zM128.645 124.93a1 1 0 11-2 0 1 1 0 012 0zM134.645 124.93a1 1 0 11-2 0 1 1 0 012 0zM140.645 124.93a1 1 0 11-2 0 1 1 0 012 0zM146.645 124.93a1 1 0 11-2 0 1 1 0 012 0z'></path>
        <circle cx='151.645' cy='124.93' r='1'></circle>
        <circle cx='157.645' cy='124.93' r='1'></circle>
        <circle cx='163.645' cy='124.93' r='1'></circle>
        <circle cx='169.645' cy='124.93' r='1'></circle>
        <circle cx='175.645' cy='124.93' r='1'></circle>
        <circle cx='181.645' cy='124.93' r='1'></circle>
        <circle cx='187.645' cy='124.93' r='1'></circle>
        <circle cx='193.645' cy='124.93' r='1'></circle>
        <circle cx='199.645' cy='124.93' r='1'></circle>
        <circle cx='205.645' cy='124.93' r='1'></circle>
        <circle cx='211.645' cy='124.93' r='1'></circle>
        <circle cx='217.645' cy='124.93' r='1'></circle>
        <circle cx='223.645' cy='124.93' r='1'></circle>
        <circle cx='229.645' cy='124.93' r='1'></circle>
        <circle cx='235.645' cy='124.93' r='1'></circle>
        <circle cx='241.645' cy='124.93' r='1'></circle>
        <circle cx='247.645' cy='124.93' r='1'></circle>
        <circle cx='1.645' cy='22.87' r='1'></circle>
        <circle cx='7.645' cy='22.87' r='1'></circle>
        <circle cx='13.645' cy='22.87' r='1'></circle>
        <circle cx='19.645' cy='22.87' r='1'></circle>
        <circle cx='25.645' cy='22.87' r='1'></circle>
        <path d='M32.645 22.87a1 1 0 11-2 0 1 1 0 012 0zM38.645 22.87a1 1 0 11-2 0 1 1 0 012 0zM44.645 22.87a1 1 0 11-2 0 1 1 0 012 0zM50.645 22.87a1 1 0 11-2 0 1 1 0 012 0zM56.645 22.87a1 1 0 11-2 0 1 1 0 012 0zM62.645 22.87a1 1 0 11-2 0 1 1 0 012 0zM68.645 22.87a1 1 0 11-2 0 1 1 0 012 0zM74.645 22.87a1 1 0 11-2 0 1 1 0 012 0zM80.645 22.87a1 1 0 11-2 0 1 1 0 012 0zM86.645 22.87a1 1 0 11-2 0 1 1 0 012 0zM92.645 22.87a1 1 0 11-2 0 1 1 0 012 0zM98.645 22.87a1 1 0 11-2 0 1 1 0 012 0zM104.645 22.87a1 1 0 11-2 0 1 1 0 012 0zM110.645 22.87a1 1 0 11-2 0 1 1 0 012 0zM116.645 22.87a1 1 0 11-2 0 1 1 0 012 0zM122.645 22.87a1 1 0 11-2 0 1 1 0 012 0zM128.645 22.87a1 1 0 11-2 0 1 1 0 012 0zM134.645 22.87a1 1 0 11-2 0 1 1 0 012 0zM140.645 22.87a1 1 0 11-2 0 1 1 0 012 0zM146.645 22.87a1 1 0 11-2 0 1 1 0 012 0z'></path>
        <circle cx='151.645' cy='22.87' r='1'></circle>
        <circle cx='157.645' cy='22.87' r='1'></circle>
        <circle cx='163.645' cy='22.87' r='1'></circle>
        <circle cx='169.645' cy='22.87' r='1'></circle>
        <circle cx='175.645' cy='22.87' r='1'></circle>
        <circle cx='181.645' cy='22.87' r='1'></circle>
        <circle cx='187.645' cy='22.87' r='1'></circle>
        <circle cx='193.645' cy='22.87' r='1'></circle>
        <circle cx='199.645' cy='22.87' r='1'></circle>
        <circle cx='205.645' cy='22.87' r='1'></circle>
        <circle cx='211.645' cy='22.87' r='1'></circle>
        <circle cx='217.645' cy='22.87' r='1'></circle>
        <circle cx='223.645' cy='22.87' r='1'></circle>
        <circle cx='229.645' cy='22.87' r='1'></circle>
        <circle cx='235.645' cy='22.87' r='1'></circle>
        <circle cx='241.645' cy='22.87' r='1'></circle>
        <circle cx='247.645' cy='22.87' r='1'></circle>
        <circle cx='1.645' cy='168.67' r='1'></circle>
        <circle cx='7.645' cy='168.67' r='1'></circle>
        <circle cx='13.645' cy='168.67' r='1'></circle>
        <circle cx='19.645' cy='168.67' r='1'></circle>
        <circle cx='25.645' cy='168.67' r='1'></circle>
        <path d='M32.645 168.67a1 1 0 11-2 0 1 1 0 012 0zM38.645 168.67a1 1 0 11-2 0 1 1 0 012 0zM44.645 168.67a1 1 0 11-2 0 1 1 0 012 0zM50.645 168.67a1 1 0 11-2 0 1 1 0 012 0zM56.645 168.67a1 1 0 11-2 0 1 1 0 012 0zM62.645 168.67a1 1 0 11-2 0 1 1 0 012 0zM68.645 168.67a1 1 0 11-2 0 1 1 0 012 0zM74.645 168.67a1 1 0 11-2 0 1 1 0 012 0zM80.645 168.67a1 1 0 11-2 0 1 1 0 012 0zM86.645 168.67a1 1 0 11-2 0 1 1 0 012 0zM92.645 168.67a1 1 0 11-2 0 1 1 0 012 0zM98.645 168.67a1 1 0 11-2 0 1 1 0 012 0zM104.645 168.67a1 1 0 11-2 0 1 1 0 012 0zM110.645 168.67a1 1 0 11-2 0 1 1 0 012 0zM116.645 168.67a1 1 0 11-2 0 1 1 0 012 0zM122.645 168.67a1 1 0 11-2 0 1 1 0 012 0zM128.645 168.67a1 1 0 11-2 0 1 1 0 012 0zM134.645 168.67a1 1 0 11-2 0 1 1 0 012 0zM140.645 168.67a1 1 0 11-2 0 1 1 0 012 0zM146.645 168.67a1 1 0 11-2 0 1 1 0 012 0z'></path>
        <circle cx='151.645' cy='168.67' r='1'></circle>
        <circle cx='157.645' cy='168.67' r='1'></circle>
        <circle cx='163.645' cy='168.67' r='1'></circle>
        <circle cx='169.645' cy='168.67' r='1'></circle>
        <circle cx='175.645' cy='168.67' r='1'></circle>
        <circle cx='181.645' cy='168.67' r='1'></circle>
        <circle cx='187.645' cy='168.67' r='1'></circle>
        <circle cx='193.645' cy='168.67' r='1'></circle>
        <circle cx='199.645' cy='168.67' r='1'></circle>
        <circle cx='205.645' cy='168.67' r='1'></circle>
        <circle cx='211.645' cy='168.67' r='1'></circle>
        <circle cx='217.645' cy='168.67' r='1'></circle>
        <circle cx='223.645' cy='168.67' r='1'></circle>
        <circle cx='229.645' cy='168.67' r='1'></circle>
        <circle cx='235.645' cy='168.67' r='1'></circle>
        <circle cx='241.645' cy='168.67' r='1'></circle>
        <circle cx='247.645' cy='168.67' r='1'></circle>
        <circle cx='1.645' cy='52.03' r='1'></circle>
        <circle cx='7.645' cy='52.03' r='1'></circle>
        <circle cx='13.645' cy='52.03' r='1'></circle>
        <circle cx='19.645' cy='52.03' r='1'></circle>
        <circle cx='25.645' cy='52.03' r='1'></circle>
        <path d='M32.645 52.03a1 1 0 11-2 0 1 1 0 012 0zM38.645 52.03a1 1 0 11-2 0 1 1 0 012 0zM44.645 52.03a1 1 0 11-2 0 1 1 0 012 0zM50.645 52.03a1 1 0 11-2 0 1 1 0 012 0zM56.645 52.03a1 1 0 11-2 0 1 1 0 012 0zM62.645 52.03a1 1 0 11-2 0 1 1 0 012 0zM68.645 52.03a1 1 0 11-2 0 1 1 0 012 0zM74.645 52.03a1 1 0 11-2 0 1 1 0 012 0zM80.645 52.03a1 1 0 11-2 0 1 1 0 012 0zM86.645 52.03a1 1 0 11-2 0 1 1 0 012 0zM92.645 52.03a1 1 0 11-2 0 1 1 0 012 0zM98.645 52.03a1 1 0 11-2 0 1 1 0 012 0zM104.645 52.03a1 1 0 11-2 0 1 1 0 012 0zM110.645 52.03a1 1 0 11-2 0 1 1 0 012 0zM116.645 52.03a1 1 0 11-2 0 1 1 0 012 0zM122.645 52.03a1 1 0 11-2 0 1 1 0 012 0zM128.645 52.03a1 1 0 11-2 0 1 1 0 012 0zM134.645 52.03a1 1 0 11-2 0 1 1 0 012 0zM140.645 52.03a1 1 0 11-2 0 1 1 0 012 0zM146.645 52.03a1 1 0 11-2 0 1 1 0 012 0z'></path>
        <circle cx='151.645' cy='52.03' r='1'></circle>
        <circle cx='157.645' cy='52.03' r='1'></circle>
        <circle cx='163.645' cy='52.03' r='1'></circle>
        <circle cx='169.645' cy='52.03' r='1'></circle>
        <circle cx='175.645' cy='52.03' r='1'></circle>
        <circle cx='181.645' cy='52.03' r='1'></circle>
        <circle cx='187.645' cy='52.03' r='1'></circle>
        <circle cx='193.645' cy='52.03' r='1'></circle>
        <circle cx='199.645' cy='52.03' r='1'></circle>
        <circle cx='205.645' cy='52.03' r='1'></circle>
        <circle cx='211.645' cy='52.03' r='1'></circle>
        <circle cx='217.645' cy='52.03' r='1'></circle>
        <circle cx='223.645' cy='52.03' r='1'></circle>
        <circle cx='229.645' cy='52.03' r='1'></circle>
        <circle cx='235.645' cy='52.03' r='1'></circle>
        <circle cx='241.645' cy='52.03' r='1'></circle>
        <circle cx='247.645' cy='52.03' r='1'></circle>
        <circle cx='1.645' cy='197.83' r='1'></circle>
        <circle cx='7.645' cy='197.83' r='1'></circle>
        <circle cx='13.645' cy='197.83' r='1'></circle>
        <circle cx='19.645' cy='197.83' r='1'></circle>
        <circle cx='25.645' cy='197.83' r='1'></circle>
        <path d='M32.645 197.83a1 1 0 11-2 0 1 1 0 012 0zM38.645 197.83a1 1 0 11-2 0 1 1 0 012 0zM44.645 197.83a1 1 0 11-2 0 1 1 0 012 0zM50.645 197.83a1 1 0 11-2 0 1 1 0 012 0zM56.645 197.83a1 1 0 11-2 0 1 1 0 012 0zM62.645 197.83a1 1 0 11-2 0 1 1 0 012 0zM68.645 197.83a1 1 0 11-2 0 1 1 0 012 0zM74.645 197.83a1 1 0 11-2 0 1 1 0 012 0zM80.645 197.83a1 1 0 11-2 0 1 1 0 012 0zM86.645 197.83a1 1 0 11-2 0 1 1 0 012 0zM92.645 197.83a1 1 0 11-2 0 1 1 0 012 0zM98.645 197.83a1 1 0 11-2 0 1 1 0 012 0zM104.645 197.83a1 1 0 11-2 0 1 1 0 012 0zM110.645 197.83a1 1 0 11-2 0 1 1 0 012 0zM116.645 197.83a1 1 0 11-2 0 1 1 0 012 0zM122.645 197.83a1 1 0 11-2 0 1 1 0 012 0zM128.645 197.83a1 1 0 11-2 0 1 1 0 012 0zM134.645 197.83a1 1 0 11-2 0 1 1 0 012 0zM140.645 197.83a1 1 0 11-2 0 1 1 0 012 0zM146.645 197.83a1 1 0 11-2 0 1 1 0 012 0z'></path>
        <circle cx='151.645' cy='197.83' r='1'></circle>
        <circle cx='157.645' cy='197.83' r='1'></circle>
        <circle cx='163.645' cy='197.83' r='1'></circle>
        <circle cx='169.645' cy='197.83' r='1'></circle>
        <circle cx='175.645' cy='197.83' r='1'></circle>
        <circle cx='181.645' cy='197.83' r='1'></circle>
        <circle cx='187.645' cy='197.83' r='1'></circle>
        <circle cx='193.645' cy='197.83' r='1'></circle>
        <circle cx='199.645' cy='197.83' r='1'></circle>
        <circle cx='205.645' cy='197.83' r='1'></circle>
        <circle cx='211.645' cy='197.83' r='1'></circle>
        <circle cx='217.645' cy='197.83' r='1'></circle>
        <circle cx='223.645' cy='197.83' r='1'></circle>
        <circle cx='229.645' cy='197.83' r='1'></circle>
        <circle cx='235.645' cy='197.83' r='1'></circle>
        <circle cx='241.645' cy='197.83' r='1'></circle>
        <circle cx='247.645' cy='197.83' r='1'></circle>
        <circle cx='1.645' cy='81.19' r='1'></circle>
        <circle cx='7.645' cy='81.19' r='1'></circle>
        <circle cx='13.645' cy='81.19' r='1'></circle>
        <circle cx='19.645' cy='81.19' r='1'></circle>
        <circle cx='25.645' cy='81.19' r='1'></circle>
        <path d='M32.645 81.19a1 1 0 11-2 0 1 1 0 012 0zM38.645 81.19a1 1 0 11-2 0 1 1 0 012 0zM44.645 81.19a1 1 0 11-2 0 1 1 0 012 0zM50.645 81.19a1 1 0 11-2 0 1 1 0 012 0zM56.645 81.19a1 1 0 11-2 0 1 1 0 012 0zM62.645 81.19a1 1 0 11-2 0 1 1 0 012 0zM68.645 81.19a1 1 0 11-2 0 1 1 0 012 0zM74.645 81.19a1 1 0 11-2 0 1 1 0 012 0zM80.645 81.19a1 1 0 11-2 0 1 1 0 012 0zM86.645 81.19a1 1 0 11-2 0 1 1 0 012 0zM92.645 81.19a1 1 0 11-2 0 1 1 0 012 0zM98.645 81.19a1 1 0 11-2 0 1 1 0 012 0zM104.645 81.19a1 1 0 11-2 0 1 1 0 012 0zM110.645 81.19a1 1 0 11-2 0 1 1 0 012 0zM116.645 81.19a1 1 0 11-2 0 1 1 0 012 0zM122.645 81.19a1 1 0 11-2 0 1 1 0 012 0zM128.645 81.19a1 1 0 11-2 0 1 1 0 012 0zM134.645 81.19a1 1 0 11-2 0 1 1 0 012 0zM140.645 81.19a1 1 0 11-2 0 1 1 0 012 0zM146.645 81.19a1 1 0 11-2 0 1 1 0 012 0z'></path>
        <circle cx='151.645' cy='81.19' r='1'></circle>
        <circle cx='157.645' cy='81.19' r='1'></circle>
        <circle cx='163.645' cy='81.19' r='1'></circle>
        <circle cx='169.645' cy='81.19' r='1'></circle>
        <circle cx='175.645' cy='81.19' r='1'></circle>
        <circle cx='181.645' cy='81.19' r='1'></circle>
        <circle cx='187.645' cy='81.19' r='1'></circle>
        <circle cx='193.645' cy='81.19' r='1'></circle>
        <circle cx='199.645' cy='81.19' r='1'></circle>
        <circle cx='205.645' cy='81.19' r='1'></circle>
        <circle cx='211.645' cy='81.19' r='1'></circle>
        <circle cx='217.645' cy='81.19' r='1'></circle>
        <circle cx='223.645' cy='81.19' r='1'></circle>
        <circle cx='229.645' cy='81.19' r='1'></circle>
        <circle cx='235.645' cy='81.19' r='1'></circle>
        <circle cx='241.645' cy='81.19' r='1'></circle>
        <circle cx='247.645' cy='81.19' r='1'></circle>
        <circle cx='1.645' cy='226.99' r='1'></circle>
        <circle cx='7.645' cy='226.99' r='1'></circle>
        <circle cx='13.645' cy='226.99' r='1'></circle>
        <circle cx='19.645' cy='226.99' r='1'></circle>
        <circle cx='25.645' cy='226.99' r='1'></circle>
        <path d='M32.645 226.99a1 1 0 11-2 0 1 1 0 012 0zM38.645 226.99a1 1 0 11-2 0 1 1 0 012 0zM44.645 226.99a1 1 0 11-2 0 1 1 0 012 0zM50.645 226.99a1 1 0 11-2 0 1 1 0 012 0zM56.645 226.99a1 1 0 11-2 0 1 1 0 012 0zM62.645 226.99a1 1 0 11-2 0 1 1 0 012 0zM68.645 226.99a1 1 0 11-2 0 1 1 0 012 0zM74.645 226.99a1 1 0 11-2 0 1 1 0 012 0zM80.645 226.99a1 1 0 11-2 0 1 1 0 012 0zM86.645 226.99a1 1 0 11-2 0 1 1 0 012 0zM92.645 226.99a1 1 0 11-2 0 1 1 0 012 0zM98.645 226.99a1 1 0 11-2 0 1 1 0 012 0zM104.645 226.99a1 1 0 11-2 0 1 1 0 012 0zM110.645 226.99a1 1 0 11-2 0 1 1 0 012 0zM116.645 226.99a1 1 0 11-2 0 1 1 0 012 0zM122.645 226.99a1 1 0 11-2 0 1 1 0 012 0zM128.645 226.99a1 1 0 11-2 0 1 1 0 012 0zM134.645 226.99a1 1 0 11-2 0 1 1 0 012 0zM140.645 226.99a1 1 0 11-2 0 1 1 0 012 0zM146.645 226.99a1 1 0 11-2 0 1 1 0 012 0z'></path>
        <circle cx='151.645' cy='226.99' r='1'></circle>
        <circle cx='157.645' cy='226.99' r='1'></circle>
        <circle cx='163.645' cy='226.99' r='1'></circle>
        <circle cx='169.645' cy='226.99' r='1'></circle>
        <circle cx='175.645' cy='226.99' r='1'></circle>
        <circle cx='181.645' cy='226.99' r='1'></circle>
        <circle cx='187.645' cy='226.99' r='1'></circle>
        <circle cx='193.645' cy='226.99' r='1'></circle>
        <circle cx='199.645' cy='226.99' r='1'></circle>
        <circle cx='205.645' cy='226.99' r='1'></circle>
        <circle cx='211.645' cy='226.99' r='1'></circle>
        <circle cx='217.645' cy='226.99' r='1'></circle>
        <circle cx='223.645' cy='226.99' r='1'></circle>
        <circle cx='229.645' cy='226.99' r='1'></circle>
        <circle cx='235.645' cy='226.99' r='1'></circle>
        <circle cx='241.645' cy='226.99' r='1'></circle>
        <circle cx='247.645' cy='226.99' r='1'></circle>
        <circle cx='1.645' cy='110.35' r='1'></circle>
        <circle cx='7.645' cy='110.35' r='1'></circle>
        <circle cx='13.645' cy='110.35' r='1'></circle>
        <circle cx='19.645' cy='110.35' r='1'></circle>
        <circle cx='25.645' cy='110.35' r='1'></circle>
        <path d='M32.645 110.35a1 1 0 11-2 0 1 1 0 012 0zM38.645 110.35a1 1 0 11-2 0 1 1 0 012 0zM44.645 110.35a1 1 0 11-2 0 1 1 0 012 0zM50.645 110.35a1 1 0 11-2 0 1 1 0 012 0zM56.645 110.35a1 1 0 11-2 0 1 1 0 012 0zM62.645 110.35a1 1 0 11-2 0 1 1 0 012 0zM68.645 110.35a1 1 0 11-2 0 1 1 0 012 0zM74.645 110.35a1 1 0 11-2 0 1 1 0 012 0zM80.645 110.35a1 1 0 11-2 0 1 1 0 012 0zM86.645 110.35a1 1 0 11-2 0 1 1 0 012 0zM92.645 110.35a1 1 0 11-2 0 1 1 0 012 0zM98.645 110.35a1 1 0 11-2 0 1 1 0 012 0zM104.645 110.35a1 1 0 11-2 0 1 1 0 012 0zM110.645 110.35a1 1 0 11-2 0 1 1 0 012 0zM116.645 110.35a1 1 0 11-2 0 1 1 0 012 0zM122.645 110.35a1 1 0 11-2 0 1 1 0 012 0zM128.645 110.35a1 1 0 11-2 0 1 1 0 012 0zM134.645 110.35a1 1 0 11-2 0 1 1 0 012 0zM140.645 110.35a1 1 0 11-2 0 1 1 0 012 0zM146.645 110.35a1 1 0 11-2 0 1 1 0 012 0z'></path>
        <circle cx='151.645' cy='110.35' r='1'></circle>
        <circle cx='157.645' cy='110.35' r='1'></circle>
        <circle cx='163.645' cy='110.35' r='1'></circle>
        <circle cx='169.645' cy='110.35' r='1'></circle>
        <circle cx='175.645' cy='110.35' r='1'></circle>
        <circle cx='181.645' cy='110.35' r='1'></circle>
        <circle cx='187.645' cy='110.35' r='1'></circle>
        <circle cx='193.645' cy='110.35' r='1'></circle>
        <circle cx='199.645' cy='110.35' r='1'></circle>
        <circle cx='205.645' cy='110.35' r='1'></circle>
        <circle cx='211.645' cy='110.35' r='1'></circle>
        <circle cx='217.645' cy='110.35' r='1'></circle>
        <circle cx='223.645' cy='110.35' r='1'></circle>
        <circle cx='229.645' cy='110.35' r='1'></circle>
        <circle cx='235.645' cy='110.35' r='1'></circle>
        <circle cx='241.645' cy='110.35' r='1'></circle>
        <circle cx='247.645' cy='110.35' r='1'></circle>
        <circle cx='1.645' cy='139.51' r='1'></circle>
        <circle cx='7.645' cy='139.51' r='1'></circle>
        <circle cx='13.645' cy='139.51' r='1'></circle>
        <circle cx='19.645' cy='139.51' r='1'></circle>
        <circle cx='25.645' cy='139.51' r='1'></circle>
        <path d='M32.645 139.51a1 1 0 11-2 0 1 1 0 012 0zM38.645 139.51a1 1 0 11-2 0 1 1 0 012 0zM44.645 139.51a1 1 0 11-2 0 1 1 0 012 0zM50.645 139.51a1 1 0 11-2 0 1 1 0 012 0zM56.645 139.51a1 1 0 11-2 0 1 1 0 012 0zM62.645 139.51a1 1 0 11-2 0 1 1 0 012 0zM68.645 139.51a1 1 0 11-2 0 1 1 0 012 0zM74.645 139.51a1 1 0 11-2 0 1 1 0 012 0zM80.645 139.51a1 1 0 11-2 0 1 1 0 012 0zM86.645 139.51a1 1 0 11-2 0 1 1 0 012 0zM92.645 139.51a1 1 0 11-2 0 1 1 0 012 0zM98.645 139.51a1 1 0 11-2 0 1 1 0 012 0zM104.645 139.51a1 1 0 11-2 0 1 1 0 012 0zM110.645 139.51a1 1 0 11-2 0 1 1 0 012 0zM116.645 139.51a1 1 0 11-2 0 1 1 0 012 0zM122.645 139.51a1 1 0 11-2 0 1 1 0 012 0zM128.645 139.51a1 1 0 11-2 0 1 1 0 012 0zM134.645 139.51a1 1 0 11-2 0 1 1 0 012 0zM140.645 139.51a1 1 0 11-2 0 1 1 0 012 0zM146.645 139.51a1 1 0 11-2 0 1 1 0 012 0z'></path>
        <circle cx='151.645' cy='139.51' r='1'></circle>
        <circle cx='157.645' cy='139.51' r='1'></circle>
        <circle cx='163.645' cy='139.51' r='1'></circle>
        <circle cx='169.645' cy='139.51' r='1'></circle>
        <circle cx='175.645' cy='139.51' r='1'></circle>
        <circle cx='181.645' cy='139.51' r='1'></circle>
        <circle cx='187.645' cy='139.51' r='1'></circle>
        <circle cx='193.645' cy='139.51' r='1'></circle>
        <circle cx='199.645' cy='139.51' r='1'></circle>
        <circle cx='205.645' cy='139.51' r='1'></circle>
        <circle cx='211.645' cy='139.51' r='1'></circle>
        <circle cx='217.645' cy='139.51' r='1'></circle>
        <circle cx='223.645' cy='139.51' r='1'></circle>
        <circle cx='229.645' cy='139.51' r='1'></circle>
        <circle cx='235.645' cy='139.51' r='1'></circle>
        <circle cx='241.645' cy='139.51' r='1'></circle>
        <circle cx='247.645' cy='139.51' r='1'></circle>
      </g>
      <path
        fill='var(--illustrationsSharedPrimary500)'
        stroke='var(--illustrationsSharedNeutral900)'
        d='M82.644 33.37l-.171.077-.078.171-1.89 4.162-1.848-4.158-.078-.175-.175-.079-4.179-1.868 4.18-1.869.174-.078.078-.175 1.848-4.158 1.89 4.162.078.171.171.078 4.14 1.869-4.14 1.87zM224.271 97.99l-.172.077-.078.171-2.516 5.542-2.461-5.538-.078-.175-.175-.078-5.566-2.489 5.566-2.489.175-.078.078-.175 2.461-5.538 2.516 5.542.078.171.172.078 5.514 2.489-5.514 2.49zM36.274 169.516l-.29.076-.07.291L34 177.858l-1.914-7.975-.07-.291-.29-.076-7.742-2.016 7.742-2.016.29-.076.07-.291L34 157.142l1.914 7.975.07.291.29.076 7.742 2.016-7.742 2.016z'
      ></path>
      <circle
        cx='124.5'
        cy='123.5'
        r='79.5'
        fill='var(--illustrationsSharedPrimary600)'
      ></circle>
      <mask
        id='mask1_10556_277465'
        style={{ maskType: 'alpha' }}
        width='159'
        height='159'
        x='45'
        y='44'
        maskUnits='userSpaceOnUse'
      >
        <circle
          cx='124.5'
          cy='123.5'
          r='79'
          fill='#453838'
          stroke='#242631'
        ></circle>
      </mask>
      <g mask='url(#mask1_10556_277465)'>
        <path
          fill='var(--illustrationsSharedPrimary500)'
          d='M124.809 61.772l-.219-.547a1.258 1.258 0 011.168-1.725h.863a2.12 2.12 0 011.5.621l.818.818a1.5 1.5 0 01-1.06 2.561h-.518a2.748 2.748 0 01-2.552-1.728zM132.774 66.531l-.228-.302a1.05 1.05 0 011.68-1.26l.228.303a1.05 1.05 0 01-1.68 1.26zM156.943 55.443l-1.572-1.572a1.683 1.683 0 011.19-2.871h2.614c.859 0 1.693.286 2.371.814l1.819 1.414a1.828 1.828 0 01-1.122 3.272h-2.748a3.61 3.61 0 01-2.552-1.057zM88.164 72.828l-.595-1.19a2.163 2.163 0 01-.118-.284l-.146-.44a1.834 1.834 0 013.243-1.632l2.706 3.867c.162.23.267.496.307.776l.099.692a1.392 1.392 0 01-2.362 1.181l-1.54-1.54a2.256 2.256 0 00-.585-.422 2.256 2.256 0 01-1.01-1.008zM69.04 83.233l-.656.039a10.79 10.79 0 00-6.728 2.883l-3.337 3.114a7.096 7.096 0 00-1.505 8.36l1.73 3.46a7.797 7.797 0 01.804 4.042l-.133 1.857a4.82 4.82 0 01-7.254 3.813l-1.201-.707a7.663 7.663 0 01-3.422-8.913L48.5 97.5l1.397-5.587a28 28 0 015.905-11.432l9.16-10.686a7.091 7.091 0 018.111-1.931l.517.215a7.052 7.052 0 012.672 11.066l-.7.827a9.257 9.257 0 01-6.522 3.261z'
        ></path>
        <path
          fill='var(--illustrationsSharedPrimary500)'
          d='M60.11 92.085l1.044-1.566a1.166 1.166 0 012.12.838l-.218 1.304a2.287 2.287 0 01-.21.646l-.745 1.49a1.187 1.187 0 01-2.212-.242l-.122-.488c-.17-.68-.045-1.4.343-1.982zM89.07 133.042l-3.793-2.276a21.148 21.148 0 00-8.145-2.836l-5.72-.746a4.183 4.183 0 01-2.419-1.191 4.187 4.187 0 00-3.581-1.18l-3.187.478a9.97 9.97 0 00-7.132 4.836l-.136.233a8.725 8.725 0 00-1.146 3.53l-.11 1.097a5.648 5.648 0 00.921 3.696c.574.861.9 1.864.944 2.898l.228 5.466a18.17 18.17 0 0010.376 15.665L71 165a14.584 14.584 0 017.627 9.508L79.5 178l3.707 10.659c.194.557.293 1.142.293 1.731a5.27 5.27 0 002.076 4.192l1.149.875c3.88 2.957 9.47 1.884 11.98-2.3l.103-.17a8.366 8.366 0 001.192-4.304v-7.726a18.64 18.64 0 019.052-15.988l2.528-1.517a13.083 13.083 0 006.233-12.989l-.099-.727a14.977 14.977 0 00-4.556-8.864l-.39-.369A16.497 16.497 0 00101.441 136h-1.692a20.748 20.748 0 01-10.678-2.958zM82.232 63.412l-4.223-2.599a3.817 3.817 0 01-.336-6.27l8.869-6.865a14.488 14.488 0 017.82-2.995l51.311-3.724c3.118-.226 5.547 2.662 4.788 5.695a4.593 4.593 0 01-2.092 2.825l-4.362 2.617a16.87 16.87 0 01-8.68 2.404h-5.874a10.949 10.949 0 00-10.927 11.654l.044.674a11.777 11.777 0 01-10.578 12.473l-.151.015a12.367 12.367 0 01-11.033-4.765L96 73.5l-3.823-5.735a7.333 7.333 0 00-6.102-3.265 7.333 7.333 0 01-3.843-1.088zM160.27 61.797l9.23-3.297a11.429 11.429 0 0113.141 3.825l18.493 24.551a27.997 27.997 0 015.623 16.039l1.002 34.74a28.002 28.002 0 01-4.127 15.459l-11.075 18.036a5.11 5.11 0 01-7.968.939l-3.499-3.499a24.718 24.718 0 00-4.614-3.629l-13.846-8.442a23.11 23.11 0 01-6.904-6.482 23.12 23.12 0 00-11.166-8.515L130.5 136.5l-10.393-5.196a13.855 13.855 0 01-7.245-15.752l1.876-7.506c.821-3.28.586-6.736-.67-9.875l-.429-1.074a12.747 12.747 0 014.498-15.156l4.102-2.887a27.115 27.115 0 0112.767-4.791l4.354-.459a19.926 19.926 0 0012.763-6.53 19.923 19.923 0 018.147-5.477z'
        ></path>
        <path
          stroke='var(--illustrationsSharedNeutral900)'
          d='M124.809 61.772l-.219-.547a1.258 1.258 0 011.168-1.725h.863a2.12 2.12 0 011.5.621l.818.818a1.5 1.5 0 01-1.06 2.561h-.518a2.748 2.748 0 01-2.552-1.728zM132.774 66.531l-.228-.302a1.05 1.05 0 011.68-1.26l.228.303a1.05 1.05 0 01-1.68 1.26zM156.943 55.443l-1.572-1.572a1.683 1.683 0 011.19-2.871h2.614c.859 0 1.693.286 2.371.814l1.819 1.414a1.828 1.828 0 01-1.122 3.272h-2.748a3.61 3.61 0 01-2.552-1.057zM88.164 72.828l-.595-1.19a2.163 2.163 0 01-.118-.284l-.146-.44a1.834 1.834 0 013.243-1.632l2.706 3.867c.162.23.267.496.307.776l.099.692a1.392 1.392 0 01-2.362 1.181l-1.54-1.54a2.256 2.256 0 00-.585-.422 2.256 2.256 0 01-1.01-1.008zM69.04 83.233l-.656.039a10.79 10.79 0 00-6.728 2.883l-3.337 3.114a7.096 7.096 0 00-1.505 8.36l1.73 3.46a7.797 7.797 0 01.804 4.042l-.133 1.857a4.82 4.82 0 01-7.254 3.813l-1.201-.707a7.663 7.663 0 01-3.422-8.913L48.5 97.5l1.397-5.587a28 28 0 015.905-11.432l9.16-10.686a7.091 7.091 0 018.111-1.931l.517.215a7.052 7.052 0 012.672 11.066l-.7.827a9.257 9.257 0 01-6.522 3.261z'
        ></path>
        <path
          stroke='var(--illustrationsSharedNeutral900)'
          d='M60.11 92.085l1.044-1.566a1.166 1.166 0 012.12.838l-.218 1.304a2.287 2.287 0 01-.21.646l-.745 1.49a1.187 1.187 0 01-2.212-.242l-.122-.488c-.17-.68-.045-1.4.343-1.982zM89.07 133.042l-3.793-2.276a21.148 21.148 0 00-8.145-2.836l-5.72-.746a4.183 4.183 0 01-2.419-1.191 4.187 4.187 0 00-3.581-1.18l-3.187.478a9.97 9.97 0 00-7.132 4.836l-.136.233a8.725 8.725 0 00-1.146 3.53l-.11 1.097a5.648 5.648 0 00.921 3.696c.574.861.9 1.864.944 2.898l.228 5.466a18.17 18.17 0 0010.376 15.665L71 165a14.584 14.584 0 017.627 9.508L79.5 178l3.707 10.659c.194.557.293 1.142.293 1.731a5.27 5.27 0 002.076 4.192l1.149.875c3.88 2.957 9.47 1.884 11.98-2.3l.103-.17a8.366 8.366 0 001.192-4.304v-7.726a18.64 18.64 0 019.052-15.988l2.528-1.517a13.083 13.083 0 006.233-12.989l-.099-.727a14.977 14.977 0 00-4.556-8.864l-.39-.369A16.497 16.497 0 00101.441 136h-1.692a20.748 20.748 0 01-10.678-2.958zM82.232 63.412l-4.223-2.599a3.817 3.817 0 01-.336-6.27l8.869-6.865a14.488 14.488 0 017.82-2.995l51.311-3.724c3.118-.226 5.547 2.662 4.788 5.695a4.593 4.593 0 01-2.092 2.825l-4.362 2.617a16.87 16.87 0 01-8.68 2.404h-5.874a10.949 10.949 0 00-10.927 11.654l.044.674a11.777 11.777 0 01-10.578 12.473l-.151.015a12.367 12.367 0 01-11.033-4.765L96 73.5l-3.823-5.735a7.333 7.333 0 00-6.102-3.265 7.333 7.333 0 01-3.843-1.088zM160.27 61.797l9.23-3.297a11.429 11.429 0 0113.141 3.825l18.493 24.551a27.997 27.997 0 015.623 16.039l1.002 34.74a28.002 28.002 0 01-4.127 15.459l-11.075 18.036a5.11 5.11 0 01-7.968.939l-3.499-3.499a24.718 24.718 0 00-4.614-3.629l-13.846-8.442a23.11 23.11 0 01-6.904-6.482 23.12 23.12 0 00-11.166-8.515L130.5 136.5l-10.393-5.196a13.855 13.855 0 01-7.245-15.752l1.876-7.506c.821-3.28.586-6.736-.67-9.875l-.429-1.074a12.747 12.747 0 014.498-15.156l4.102-2.887a27.115 27.115 0 0112.767-4.791l4.354-.459a19.926 19.926 0 0012.763-6.53 19.923 19.923 0 018.147-5.477z'
        ></path>
      </g>
      <g filter='url(#filter0_d_10556_277465)'>
        <path
          fill='var(--illustrationsSharedDestructive800)'
          stroke='var(--illustrationsSharedNeutral900)'
          d='M197.338 51.832a34.266 34.266 0 018.478 22.247l-7.686 3.585-26.122-3.708-3.392-29.669 7.632-3.83a34.263 34.263 0 0121.09 11.375z'
        ></path>
        <path
          fill='var(--illustrationsNeutral30)'
          d='M199.5 77.941a35.001 35.001 0 00-30.362-34.69L164.5 77.94h35z'
        ></path>
        <path
          fill='var(--illustrationsSharedDestructive500)'
          d='M198.5 78.941c0-8.237-2.946-16.193-8.291-22.392-5.345-6.198-12.725-10.216-20.77-11.308L165 78.941h33.5z'
        ></path>
        <mask
          id='path-1423-inside-1_10556_277465'
          fill='var(--illustrationsNeutral30)'
        >
          <path d='M198.206 78.18a34.763 34.763 0 00-30.155-34.456l-4.606 34.456h34.761z'></path>
        </mask>
        <path
          stroke='var(--illustrationsSharedNeutral900)'
          strokeWidth='2'
          d='M198.206 78.18a34.763 34.763 0 00-30.155-34.456l-4.606 34.456h34.761z'
          mask='url(#path-1423-inside-1_10556_277465)'
        ></path>
      </g>
      <g filter='url(#filter1_d_10556_277465)'>
        <path
          fill='var(--illustrationsSharedTertiary700)'
          stroke='var(--illustrationsSharedNeutral900)'
          d='M220.95 133.277a36.502 36.502 0 01-8.503 17.871l-28.368-17.213 1.331-6.457 33.498-13.804a36.496 36.496 0 012.042 19.603z'
        ></path>
        <path
          fill='var(--illustrationsNeutral30)'
          d='M212.089 157.855a37.002 37.002 0 006.639-38.772l-34.21 14.097 27.571 24.675z'
        ></path>
        <path
          fill='var(--illustrationsSharedTertiary500)'
          d='M211.089 158.855a37.002 37.002 0 006.639-38.772l-34.21 14.097 27.571 24.675z'
        ></path>
        <mask
          id='path-1427-inside-2_10556_277465'
          fill='var(--illustrationsNeutral30)'
        >
          <path d='M211.089 157.855a37.002 37.002 0 006.639-38.772l-34.21 14.097 27.571 24.675z'></path>
        </mask>
        <path
          stroke='var(--illustrationsSharedNeutral900)'
          strokeWidth='2'
          d='M211.089 157.855a37.002 37.002 0 006.639-38.772l-34.21 14.097 27.571 24.675z'
          mask='url(#path-1427-inside-2_10556_277465)'
        ></path>
      </g>
      <g filter='url(#filter2_d_10556_277465)'>
        <path
          fill='var(--illustrationsSharedPrimary800)'
          stroke='var(--illustrationsSharedNeutral900)'
          d='M28.182 102.153a30.108 30.108 0 017.834-10.236l18.895 23.118-1.338 5.681-17.029 17.623a30.115 30.115 0 01-11.196-23.112 30.108 30.108 0 012.834-13.074z'
        ></path>
        <mask
          id='path-1429-inside-3_10556_277465'
          fill='var(--illustrationsNeutral30)'
        >
          <path d='M34.658 98.264a30.607 30.607 0 00.504 47.803l18.866-24.103-19.37-23.7z'></path>
        </mask>
        <path
          fill='var(--illustrationsNeutral30)'
          stroke='var(--illustrationsSharedNeutral900)'
          strokeWidth='2'
          d='M34.658 98.264a30.607 30.607 0 00.504 47.803l18.866-24.103-19.37-23.7z'
          mask='url(#path-1429-inside-3_10556_277465)'
        ></path>
        <path
          fill='var(--illustrationsSharedWarning500)'
          stroke='var(--illustrationsSharedNeutral900)'
          d='M27.612 109.56a29.37 29.37 0 017.627-9.971l17.126 21.418-17.453 22.785c-3.228-2.592-5.158-4.996-7.032-8.791a29.366 29.366 0 01-.268-25.441z'
        ></path>
        <mask
          id='path-1431-inside-4_10556_277465'
          fill='var(--illustrationsNeutral30)'
        >
          <path d='M34.658 97.265a30.605 30.605 0 00-8.076 37.25 30.605 30.605 0 008.58 10.552l18.866-24.103-19.37-23.7z'></path>
        </mask>
        <path
          stroke='var(--illustrationsSharedNeutral900)'
          strokeWidth='2'
          d='M34.658 97.265a30.605 30.605 0 00-8.076 37.25 30.605 30.605 0 008.58 10.552l18.866-24.103-19.37-23.7z'
          mask='url(#path-1431-inside-4_10556_277465)'
        ></path>
      </g>
      <mask
        id='mask2_10556_277465'
        style={{ maskType: 'alpha' }}
        width='248'
        height='248'
        x='0'
        y='0'
        maskUnits='userSpaceOnUse'
      >
        <circle cx='124' cy='124' r='124' fill='#303341'></circle>
      </mask>
      <g mask='url(#mask2_10556_277465)'>
        <path
          fill='var(--illustrationsNeutral30)'
          d='M79 140c0-7.732 6.268-14 14-14h59c7.732 0 14 6.268 14 14v140c0 7.732-6.268 14-14 14H93c-7.732 0-14-6.268-14-14V140z'
        ></path>
        <path
          fill='var(--illustrationsSharedSuccess400)'
          d='M80.331 139.997c.034-7.732 6.33-13.973 14.061-13.939l57.973.253c7.732.033 13.973 6.329 13.939 14.061l-.609 139.814c-.034 7.732-6.329 13.973-14.061 13.939l-57.973-.252c-7.732-.034-13.972-6.329-13.939-14.061l.61-139.815z'
        ></path>
        <path
          stroke='var(--illustrationsSharedNeutral900)'
          d='M81.5 142c0-7.456 6.044-13.5 13.5-13.5h57c7.456 0 13.5 6.044 13.5 13.5v137.696c0 7.456-6.044 13.5-13.5 13.5H95c-7.456 0-13.5-6.044-13.5-13.5V142z'
        ></path>
        <path
          fill='var(--illustrationsNeutral30)'
          d='M85.304 143.304c0-6.075 4.925-11 11-11h4.724a4 4 0 013.943 3.325l.031.183a4 4 0 003.943 3.326h29.434c1.96 0 3.607-1.47 3.829-3.417a3.854 3.854 0 013.829-3.417h4.659c6.075 0 11 4.925 11 11v135.088c0 6.076-4.925 11-11 11H96.304c-6.075 0-11-4.924-11-11V143.304z'
        ></path>
        <path
          fill='var(--illustrationsNeutral40)'
          d='M85 144c0-6.075 4.925-11 11-11h4.885a4 4 0 013.941 3.316l.027.155a4 4 0 003.941 3.316h29.754a3.835 3.835 0 003.809-3.394 3.834 3.834 0 013.809-3.393H151c6.075 0 11 4.925 11 11v134c0 6.075-4.925 11-11 11H96c-6.075 0-11-4.925-11-11V144z'
        ></path>
        <rect
          width='24.747'
          height='2.152'
          x='111.126'
          y='133.38'
          fill='var(--illustrationsNeutral30)'
          rx='1.076'
        ></rect>
        <path
          stroke='var(--illustrationsSharedPrimary500)'
          strokeLinecap='round'
          strokeWidth='3'
          d='M94.098 196.365h12.499c.265 0 .52.106.707.293l4.978 4.978a1 1 0 001.549-.168l8.874-13.866a1 1 0 01.842-.461h8.868c.271 0 .53-.11.719-.304l18.768-19.374'
        ></path>
        <path
          stroke='var(--illustrationsSharedPrimary500)'
          strokeLinecap='round'
          strokeWidth='3'
          d='M152.517 174.842v-7.794a.2.2 0 00-.2-.2h-7.794'
        ></path>
        <rect
          width='13.529'
          height='34.437'
          x='140.219'
          y='193.911'
          fill='var(--illustrationsSharedPrimary500)'
          rx='1.5'
        ></rect>
        <rect
          width='13.529'
          height='28.287'
          x='124.23'
          y='200.061'
          fill='#FD909E'
          rx='1.5'
        ></rect>
        <rect
          width='13.529'
          height='12.299'
          x='108.241'
          y='216.049'
          fill='var(--illustrationsSharedSuccess700)'
          rx='1.5'
        ></rect>
        <rect
          width='13.529'
          height='19.678'
          x='92.253'
          y='208.67'
          fill='var(--illustrationsWarning20)'
          rx='1.5'
        ></rect>
      </g>
      <g filter='url(#filter3_d_10556_277465)'>
        <path
          fill='var(--illustrationsSharedSuccess700)'
          stroke='var(--illustrationsSharedNeutral900)'
          d='M122.281 58.809a30.986 30.986 0 0116.126 10.082l-.826 5.618-23.027 13.858-30.776 2.323a30.987 30.987 0 0138.503-31.881z'
        ></path>
        <path
          fill='var(--illustrationsNeutral30)'
          d='M137.198 74.749c-4.253-5.227-10.034-8.931-16.508-10.576a30.976 30.976 0 00-19.506 1.424c-6.201 2.57-11.451 7.08-14.992 12.876a32.727 32.727 0 00-4.705 19.369l31.418-2.509 24.293-20.584z'
        ></path>
        <path
          fill='var(--illustrationsSharedSuccess400)'
          d='M136.732 75.866a30.798 30.798 0 00-35.241-8.88 30.801 30.801 0 00-19.17 30.876l30.714-2.319 23.697-19.677z'
        ></path>
        <mask
          id='path-1448-inside-5_10556_277465'
          fill='var(--illustrationsNeutral30)'
        >
          <path d='M138.036 74.85a31.484 31.484 0 00-50.974 3.506 31.487 31.487 0 00-4.647 18.98l31.397-2.371 24.224-20.115z'></path>
        </mask>
        <path
          stroke='var(--illustrationsSharedNeutral900)'
          strokeWidth='2'
          d='M138.036 74.85a31.484 31.484 0 00-50.974 3.506 31.487 31.487 0 00-4.647 18.98l31.397-2.371 24.224-20.115z'
          mask='url(#path-1448-inside-5_10556_277465)'
        ></path>
      </g>
      <defs>
        <filter
          id='filter0_d_10556_277465'
          width='50.873'
          height='47'
          x='159.445'
          y='38.941'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feColorMatrix
            in='SourceAlpha'
            result='hardAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          ></feColorMatrix>
          <feOffset dy='3'></feOffset>
          <feGaussianBlur stdDeviation='2'></feGaussianBlur>
          <feComposite in2='hardAlpha' operator='out'></feComposite>
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'></feColorMatrix>
          <feBlend
            in2='BackgroundImageFix'
            result='effect1_dropShadow_10556_277465'
          ></feBlend>
          <feBlend
            in='SourceGraphic'
            in2='effect1_dropShadow_10556_277465'
            result='shape'
          ></feBlend>
        </filter>
        <filter
          id='filter1_d_10556_277465'
          width='46.456'
          height='53.835'
          x='179.518'
          y='112.02'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feColorMatrix
            in='SourceAlpha'
            result='hardAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          ></feColorMatrix>
          <feOffset dy='3'></feOffset>
          <feGaussianBlur stdDeviation='2'></feGaussianBlur>
          <feComposite in2='hardAlpha' operator='out'></feComposite>
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'></feColorMatrix>
          <feBlend
            in2='BackgroundImageFix'
            result='effect1_dropShadow_10556_277465'
          ></feBlend>
          <feBlend
            in='SourceGraphic'
            in2='effect1_dropShadow_10556_277465'
            result='shape'
          ></feBlend>
        </filter>
        <filter
          id='filter2_d_10556_277465'
          width='40.035'
          height='62.857'
          x='19.419'
          y='90.21'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feColorMatrix
            in='SourceAlpha'
            result='hardAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          ></feColorMatrix>
          <feOffset dy='3'></feOffset>
          <feGaussianBlur stdDeviation='2'></feGaussianBlur>
          <feComposite in2='hardAlpha' operator='out'></feComposite>
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'></feColorMatrix>
          <feBlend
            in2='BackgroundImageFix'
            result='effect1_dropShadow_10556_277465'
          ></feBlend>
          <feBlend
            in='SourceGraphic'
            in2='effect1_dropShadow_10556_277465'
            result='shape'
          ></feBlend>
        </filter>
        <filter
          id='filter3_d_10556_277465'
          width='65.53'
          height='48.492'
          x='77.404'
          y='56.37'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feColorMatrix
            in='SourceAlpha'
            result='hardAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          ></feColorMatrix>
          <feOffset dy='3'></feOffset>
          <feGaussianBlur stdDeviation='2'></feGaussianBlur>
          <feComposite in2='hardAlpha' operator='out'></feComposite>
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'></feColorMatrix>
          <feBlend
            in2='BackgroundImageFix'
            result='effect1_dropShadow_10556_277465'
          ></feBlend>
          <feBlend
            in='SourceGraphic'
            in2='effect1_dropShadow_10556_277465'
            result='shape'
          ></feBlend>
        </filter>
      </defs>
    </svg>
  )
}
