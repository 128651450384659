import { UIIcon, UIIconColorProps, getIconColorSVGFill } from 'components/utils'

export const ChevronRight = ({
  size = 24,
  color,
}: UIIcon & UIIconColorProps): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ transform: 'rotate(90deg)' }}
    >
      <path
        d='M11.2951 9.00002L6.7051 13.59C6.3151 13.98 6.3151 14.61 6.7051 15C7.0951 15.39 7.7251 15.39 8.1151 15L12.0051 11.12L15.8851 15C16.2751 15.39 16.9051 15.39 17.2951 15C17.6851 14.61 17.6851 13.98 17.2951 13.59L12.7051 9.00002C12.3251 8.61002 11.6851 8.61002 11.2951 9.00002Z'
        fill={color ? getIconColorSVGFill(color) : 'currentColor'}
      />
    </svg>
  )
}
