import { UIIcon, UIIconColorProps, getIconColorSVGFill } from 'components/utils'

export const LockClosedIcon = ({
  color,
  size = 24,
}: UIIcon & UIIconColorProps): JSX.Element => {
  return (
    <svg width={size} height={size} viewBox='0 0 17 22' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.04 7.12988H14.04C15.14 7.12988 16.04 8.02988 16.04 9.12988V19.1299C16.04 20.2299 15.14 21.1299 14.04 21.1299H2.04004C0.940039 21.1299 0.0400391 20.2299 0.0400391 19.1299V9.12988C0.0400391 8.02988 0.940039 7.12988 2.04004 7.12988H3.04004V5.12988C3.04004 2.36988 5.28004 0.129883 8.04004 0.129883C10.8 0.129883 13.04 2.36988 13.04 5.12988V7.12988ZM8.04004 2.12988C6.38004 2.12988 5.04004 3.46988 5.04004 5.12988V7.12988H11.04V5.12988C11.04 3.46988 9.70004 2.12988 8.04004 2.12988ZM3.04004 19.1299C2.49004 19.1299 2.04004 18.6799 2.04004 18.1299V10.1299C2.04004 9.57988 2.49004 9.12988 3.04004 9.12988H13.04C13.59 9.12988 14.04 9.57988 14.04 10.1299V18.1299C14.04 18.6799 13.59 19.1299 13.04 19.1299H3.04004ZM10.04 14.1299C10.04 15.2299 9.14004 16.1299 8.04004 16.1299C6.94004 16.1299 6.04004 15.2299 6.04004 14.1299C6.04004 13.0299 6.94004 12.1299 8.04004 12.1299C9.14004 12.1299 10.04 13.0299 10.04 14.1299Z'
        fill={color ? getIconColorSVGFill(color) : 'currentColor'}
      />
    </svg>
  )
}
