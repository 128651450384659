import { useTranslation } from '@hooks'
import {
  CustomModal,
  BasicLink,
  GoBack,
  Card,
  Spacer,
  Text,
  Divider,
} from '@components'
import { UserProfileInfo } from '@interfaces'
import { useState } from 'react'
import { supportEmail } from '@utils/externalUrls'
import { contactSupport } from '@utils/contactSupport'
import { ImportantIcon } from '@assets/icons'

export function Copy(props: {
  values?: UserProfileInfo
  prevStep: () => void
  onClose?: () => void
  show?: boolean
  computeMail: string
}): JSX.Element {
  const { t } = useTranslation()
  const [defaultCopyText, setDefaultCopyText] = useState('')
  const cancelActions = (): void => {
    props.prevStep()
  }
  const copy = t('common.copy')
  const copied = t('common.copied')
  const copiedStyle = (text: string): string => {
    return `${
      defaultCopyText === text ? 'positive--text' : 'primary-negative--text'
    }`
  }
  const emailInfo = [
    {
      label: t('accountDeletion.mailConfirmation.structure.to'),
      value: supportEmail,
    },
    {
      label: t('accountDeletion.mailConfirmation.structure.subject'),
      value: t('accountDeletion.title'),
    },
    {
      label: t('accountDeletion.mailConfirmation.structure.body'),
      value: '',
    },
  ]
  return (
    <CustomModal show={props.show}>
      <CustomModal.Header
        linkText={t('common.close')}
        className='subtitle3 dark--label-title'
        onClick={props.onClose}
      >
        <GoBack
          goBack={() => {
            cancelActions()
          }}
        />
      </CustomModal.Header>
      <CustomModal.Content className='modalContent__content pa-3 d-flex d-flex-col'>
        <div className='d-flex d-flex-col justify-start mt-2 headline1 primary-text text-left mb-5'>
          <span className='headline1 primary-text text-left'>
            {t('accountDeletion.mailConfirmation.title')}
            <p className='space text-left mt-1 secondary--text paragraph2'>
              {t('accountDeletion.mailConfirmation.subtitle')}
            </p>
          </span>
          <span className='paragraph2 my-3 primary-text text-left'>
            <a
              href={contactSupport(
                t('accountDeletion.title'),
                props.computeMail,
              )}
              target='_blank'
              rel='noreferrer'
            >
              {t('accountDeletion.mailConfirmation.link')}
            </a>
          </span>

          <div className='email__underline paragraph2 primary-text'>
            <span>{t('common.or').toLowerCase()}</span>
          </div>
        </div>
        <div className='d-flex d-flex-col justify-start primary-text paragraph2'>
          <div className='paragraph5 primary-text text-left mb-1'>
            {t('accountDeletion.mailConfirmation.manual')}
          </div>

          <div className='infoCard__content__noBackground w-100'>
            {emailInfo.map((ele, i: number) => {
              return (
                <div
                  key={i}
                  className='infoCard__content__item paragraph2 w-100'
                >
                  <div className='w-70 d-flex d-flex-row word-break-word secondary--text text-left'>
                    {ele.label}
                    <p className='primary--text ml-1'>{ele.value}</p>
                  </div>
                  <div className='w-30 text-right'>
                    <span className={`${copiedStyle(ele.label)}`}>
                      {copied}
                    </span>
                    <BasicLink
                      text={copy}
                      className='paragraph2'
                      onClick={async () => {
                        await navigator.clipboard.writeText(
                          i === emailInfo.length - 1
                            ? props.computeMail
                            : ele.value,
                        )
                        setDefaultCopyText(ele.label)
                      }}
                    />
                  </div>
                </div>
              )
            })}
          </div>
          <div className='d-flex justify-start w-100'>
            <p className='space text-left'>{props.computeMail}</p>
          </div>
        </div>

        <Card>
          <div className='d-flex d-flex-row align-center'>
            <ImportantIcon color='secondary' />
            <Spacer preset='tiny' />
            <Text
              text={t('accountDeletion.questionnaire.reminder.title')}
              preset='paragraph2'
              color='secondary'
            />
          </div>
          <Spacer preset='smaller' />
          <Divider />
          <Spacer preset='smaller' />
          <Text
            text={t('accountDeletion.questionnaire.reminder.email')}
            preset='paragraph3'
            color='tertiary'
          />
          <Spacer preset='smaller' />
          <Divider />
          <Spacer preset='smaller' />
          <Text
            text={t('accountDeletion.questionnaire.reminder.time')}
            preset='paragraph3'
            color='tertiary'
          />
          <Spacer preset='smaller' />
          <Divider />
          <Spacer preset='smaller' />
          <Text
            text={t('accountDeletion.questionnaire.reminder.confirmation')}
            preset='paragraph3'
            color='tertiary'
          />
          <Spacer preset='smaller' />
          <Divider />
          <Spacer preset='smaller' />
          <Text
            text={t('accountDeletion.questionnaire.reminder.noUndo')}
            preset='paragraph3'
            color='tertiary'
          />
        </Card>
      </CustomModal.Content>
    </CustomModal>
  )
}
