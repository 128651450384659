import { useTranslation } from '@hooks'
import {
  CustomModal,
  GoBack,
  Button,
  TextField,
  Spacer,
  Divider,
} from '@components'
import { useCallback, useState } from 'react'

interface LeavingOption {
  label: string
  checked: boolean
}

export function Questionnaire(props: {
  nextStep: (body: string) => void
  prevStep: () => void
  show?: boolean
  onClose: () => void
  computeEmail?: () => string
}): JSX.Element {
  const { t } = useTranslation()
  const cancelActions = (): void => {
    props.prevStep()
  }

  const leavingOptions: LeavingOption[] = [
    {
      label: t('accountDeletion.questionnaire.options.notInvesting'),
      checked: false,
    },
    {
      label: t('accountDeletion.questionnaire.options.otherBroker'),
      checked: false,
    },
    {
      label: t('accountDeletion.questionnaire.options.unHappyWithService'),
      checked: false,
    },
    {
      label: t('accountDeletion.questionnaire.options.other'),
      checked: false,
    },
  ]

  const [leavingStates, setLeavingStates] = useState(leavingOptions)

  const [tellUsMore, setTellUsMore] = useState('')

  const [isDisabled, setIsDisabled] = useState(true)

  const checkIsDisabled = useCallback(
    (checkLeavingStates: LeavingOption[]): void => {
      const disabled = checkLeavingStates.every((o) => !o.checked)
      setIsDisabled(disabled)
    },
    [],
  )

  const updateLeavingStates = useCallback(
    (label: string): void => {
      const newState = leavingStates.map((option) => ({
        ...option,
        checked: label === option.label ? !option.checked : option.checked,
      }))
      setLeavingStates(newState)
      checkIsDisabled(newState)
    },
    [checkIsDisabled, leavingStates],
  )

  const OptionItem = useCallback(
    ({ label, checked }: LeavingOption): JSX.Element => (
      <>
        <Spacer preset='smaller' />
        <div className='d-flex justify-start align-center'>
          <div className='d-flex align-center'>
            <input
              type='checkbox'
              className='mr-2'
              defaultChecked={checked}
              onChange={() => updateLeavingStates(label)}
            />
            {label}
          </div>
        </div>
        <Spacer preset='smaller' />
        <Divider />
      </>
    ),
    [updateLeavingStates],
  )

  const computeMailBody = (): string => {
    const reasons = leavingStates
      .filter((option) => option.checked)
      .map((option) => option.label)

    return `${t('accountDeletion.questionnaire.mail.greeting')}\n${t(
      'accountDeletion.questionnaire.mail.explanation',
    )}\n\n${t('accountDeletion.questionnaire.mail.whyLeaving')}\n${reasons.join(
      '\n',
    )}\n\n${t('accountDeletion.questionnaire.placeholder')}\n${tellUsMore}\n\n`
  }

  return (
    <CustomModal show={props.show}>
      <CustomModal.Header
        linkText={t('common.cancel')}
        className='subtitle3 dark--label-title'
        onClick={props.onClose}
      >
        <GoBack goBack={cancelActions} />
      </CustomModal.Header>
      <CustomModal.Content className='d-flex-col'>
        <div className='d-flex d-flex-col justify-start mb-2'>
          <span className='paragraph5 primary-text text-left mb-1'>
            {t('accountDeletion.questionnaire.title')}
          </span>
          <p className='paragraph2 tertiary--text text-justify'>
            {t('accountDeletion.questionnaire.subtitle')}
          </p>
        </div>
        <div className='paragraph2 mb-4'>
          {leavingStates.map((item, ind) => {
            return (
              <OptionItem key={ind} label={item.label} checked={item.checked} />
            )
          })}
        </div>
        <TextField
          style='paragraph2'
          inputProps={{
            name: 'more',
            label: t('accountDeletion.questionnaire.placeholder'),
            onChange: (e) => {
              setTellUsMore(e.target.value)
            },
          }}
        />
      </CustomModal.Content>
      <CustomModal.Footer>
        <Button
          text={t('common.continue')}
          type='submit'
          buttonType='negative'
          size='big'
          disabled={isDisabled}
          onClick={() => props.nextStep(computeMailBody())}
        />
      </CustomModal.Footer>
    </CustomModal>
  )
}
