import { CustomModal, Button, Modal } from '@components'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserBalances, RootState } from '@store'
import { useEffect, useState } from 'react'
import { OnboardingStatus } from '@interfaces'
import { useTranslation } from '@hooks'
import { ProhibitedIcon, UndoIcon } from '@assets/svg'

export function Request(props: {
  nextStep: () => void
  onClose: () => void
  onCancel: () => void
  show?: boolean
}): JSX.Element {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [modals, setModals] = useState(false)
  const { investments, onboardingStatus, userBalance } = useSelector(
    (state: RootState) => state,
  )

  const onBoardingCompleted =
    onboardingStatus.onboarding?.onboardingStatus ==
    OnboardingStatus.onboardingCompleted

  const ableToDeleteAccount = (): boolean => {
    if (
      (userBalance?.balance?.amount * 100 +
        userBalance?.cashAvailable?.amount * 100 +
        userBalance?.cashActive?.amount * 100 ===
        0 &&
        investments?.portfolios?.length === 0) ||
      !onBoardingCompleted
    ) {
      return true
    }
    return false
  }
  const onConfirmation = (): void => {
    if (ableToDeleteAccount()) {
      props.nextStep()
    } else {
      setModals(true)
    }
  }

  useEffect(() => {
    dispatch(fetchUserBalances())
  }, [dispatch])

  return (
    <>
      <CustomModal show={props.show}>
        <CustomModal.Header
          linkText={t('common.cancel')}
          className='subtitle3 dark--label-title'
          onClick={props.onClose}
        />
        <CustomModal.Content className='d-flex d-flex-col delete-account'>
          <div className='d-flex d-flex-col justify-start mb-2'>
            <span className='paragraph5 primary-text text-left mb-1'>
              {t('accountDeletion.title')}
            </span>
            <p className='paragraph2 secondary--text text-justify mb-2'>
              {t('accountDeletion.subtitle')}
            </p>
          </div>
          <div className='d-flex d-flex-col'>
            <div className='d-flex text-left align-center secondary--text paragraph2 mb-2'>
              <ProhibitedIcon className='mr-1' />
              {t('accountDeletion.removeAccess')}
            </div>
            <div className='d-flex text-left align-center secondary--text paragraph2 mb-2'>
              <UndoIcon className='mr-1' />
              {t('accountDeletion.noUndo')}
            </div>
          </div>
        </CustomModal.Content>
        <CustomModal.Footer className='d-flex d-flex-col align-center d-flex-col justify-center'>
          <div className='d-flex d-flex-col justify-start paragraph5 primary-text text-left'>
            {t('accountDeletion.areYouSure')}
          </div>
          <div className='d-flex d-flex-col justify-start mb-2 w-100 mt-3'>
            <Button
              text={t('accountDeletion.noAnswer')}
              type='submit'
              size='big'
              onClick={() => {
                setModals(false)
                props.onCancel()
              }}
            />
            <div className='mt-2'>
              <Button
                text={t('accountDeletion.yesAnswer')}
                type='submit'
                size='big'
                onClick={() => onConfirmation()}
                buttonType='ghost--negative'
              />
            </div>
          </div>
        </CustomModal.Footer>
      </CustomModal>
      {modals && (
        <Modal
          show={modals}
          onButton={() => setModals(false)}
          type='accountDeletion'
        />
      )}
    </>
  )
}
