import '../login/login.styles.scss'
import './signup.styles.scss'
import { LogoIcon } from '@assets/svg'
import { useTranslation } from '@hooks'
import { BasicLink, Header } from '@components'
import { Download } from '@features'
import { ReactComponent as PhoneIconLight } from '@assets/svg/phoneCompany.svg'
import { ReactComponent as PhoneIconDark } from '@assets/svg/phoneCompanyDark.svg'
import { getTheme } from '@utils/theme'

export default function Signup(): JSX.Element {
  const { t } = useTranslation()
  const date = new Date()
  const year = date.getFullYear()
  const theme = getTheme()

  return (
    <main className='login signup'>
      <Header simple />
      <div className='container login__wrapper'>
        <div className='login__widget'>
          <div className='login__widget__header pa-3'>
            <h1 className='login__widget__header__title'>
              {t('login.signUp')}
            </h1>
          </div>
          <div className='login__widget__content pb-3 pr-3 pt-5'>
            <div className='signup__widget__elements'>
              {theme === 'light' ? <PhoneIconLight /> : <PhoneIconDark />}
              <div className='signup__widget__elements__info pl-3 pt-2'>
                <LogoIcon className='mb-3' width={53} height={48} />
                <h2 className='signup__widget__elements__info__title mb-1'>
                  {t('signup.phoneRegistration.download')}
                </h2>
                <p className='signup__widget__elements__info__description mb-5'>
                  {t('signup.phoneRegistration.security')}
                </p>
              </div>
            </div>
          </div>
          <div className='login__widget__footer pa-3'>
            <span className='secondary--text mr-1'>
              {t('signup.AlreadyHaveAnAccount?')}{' '}
            </span>
            <BasicLink
              text={t('login.login')}
              url='/login'
              type={'link'}
              className=''
            />
          </div>
        </div>
        <Download />
        <div className='login__after_widget mt-8'>
          <span className='tertiary--text'>
            &copy;{year} {t('common.copyright')}
          </span>
        </div>
      </div>
    </main>
  )
}
