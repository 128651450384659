import { Card, Spacer, Text } from 'components'
import DonutChart from 'components/donutChart/donutChart'
import '../analystRatings.styles.scss'
import { useTranslation } from 'hooks/useTranslation'
import { useAppSelector } from 'store'

const RatingCard = (): JSX.Element => {
  const { buy, hold, sell, consensus, totalAnalysts } = useAppSelector(
    (state) => state.tipRank,
  )
  const { symbol } = useAppSelector((state) => state.companyStocks)
  const { t } = useTranslation()
  const pieValue = [
    { label: t('common.buy'), value: buy },
    { label: t('common.hold'), value: hold },
    { label: t('common.sell'), value: sell },
  ]
  return (
    <Card key='rating-card' width='full' aria-hidden padding='smaller'>
      <div className='d-flex align-center justify-center d-flex-col'>
        <Spacer preset='massive' />
        <DonutChart
          data={pieValue}
          innerRadius={48}
          outerRadius={64}
          gapSize={0.02}
        />
        <Spacer preset='smaller' />
        <Spacer preset='smallMinus' />
        <Text text={consensus} preset='headline2' />
        <Spacer preset='small' />
        <div className='d-flex align-center justify-center analyst-rating__indicator'>
          <div className='analyst-rating__indicator__item d-flex align-center justify-center'>
            <div className='analyst-rating__indicator__item__circle analyst-rating__indicator__item__circle--actionPrimaryInitial'></div>
            <Text text={`${buy} ${t('common.buy')}`} preset='bodyRegular' />
          </div>
          <div className='analyst-rating__indicator__item d-flex align-center justify-center'>
            <div className='analyst-rating__indicator__item__circle analyst-rating__indicator__item__circle--actionPrimaryHover'></div>

            <Text text={`${hold} ${t('common.hold')}`} preset='bodyRegular' />
          </div>
          <div className='analyst-rating__indicator__item d-flex align-center justify-center'>
            <div className='analyst-rating__indicator__item__circle analyst-rating__indicator__item__circle--actionPrimaryDisabled'></div>

            <Text text={`${sell} ${t('common.sell')}`} preset='bodyRegular' />
          </div>
        </div>
      </div>
      <Spacer preset='smallPlus' />
      <Text preset='bodyRegular' align='center'>
        {t('companyDetails.analystRating.baseOn', {
          rating: totalAnalysts,
          companyName: symbol,
        })}
      </Text>
    </Card>
  )
}

export default RatingCard
