import { createAsyncThunk } from '@reduxjs/toolkit'
import { getCompanyInfo, getCryptoCompanyInfo } from '@services'
import { CompanyInfo } from '@interfaces'

interface CompanyInfoBody {
  ticker: string
}

export const fetchCompanyInfo = createAsyncThunk<CompanyInfo, CompanyInfoBody>(
  'fetchCompanyInfo',
  ({ ticker }) => getCompanyInfo(ticker),
)

export const fetchCryptoCompanyInfo = createAsyncThunk<
  CompanyInfo,
  CompanyInfoBody
>('fetchCryptoCompanyInfo', ({ ticker }) => getCryptoCompanyInfo(ticker))
