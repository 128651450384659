export interface LimitPriceDetail {
  rate: number
  lowerLimit: number
  upperLimit: number
}

export interface CompanyOperationsAllowed {
  assetId: string
  market: string
  allowedOrderMethods: OrderTypeEnum[]
  priceLimit: {
    aggressive: LimitPriceDetail[]
    passive: LimitPriceDetail[]
  }
}

export enum OrderTypeEnum {
  market = 'MARKET',
  limit = 'LIMIT',
  stop = 'STOP',
}
