import { getTheme } from '@utils/theme'

export const SearchGlobal = (): JSX.Element => {
  const theme = getTheme()
  return theme === 'dark' ? (
    <svg
      width='204'
      height='204'
      viewBox='0 0 204 204'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <ellipse
        cx='101.029'
        cy='98.5419'
        rx='97.5876'
        ry='98.5419'
        fill='url(#paint0_linear_6970_52958)'
      />
      <mask
        id='mask0_6970_52958'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='201'
        height='203'
      >
        <ellipse
          cx='100.28'
          cy='101.26'
          rx='100.28'
          ry='101.26'
          fill='#D9D9D9'
        />
      </mask>
      <g mask='url(#mask0_6970_52958)'>
        <circle cx='-0.561523' cy='3.97852' r='1' fill='#242631' />
        <circle cx='5.43848' cy='3.97852' r='1' fill='#242631' />
        <path
          d='M12.4385 3.97852C12.4385 4.5308 11.9908 4.97852 11.4385 4.97852C10.8862 4.97852 10.4385 4.5308 10.4385 3.97852C10.4385 3.42623 10.8862 2.97852 11.4385 2.97852C11.9908 2.97852 12.4385 3.42623 12.4385 3.97852Z'
          fill='#242631'
        />
        <path
          d='M18.4385 3.97852C18.4385 4.5308 17.9908 4.97852 17.4385 4.97852C16.8862 4.97852 16.4385 4.5308 16.4385 3.97852C16.4385 3.42623 16.8862 2.97852 17.4385 2.97852C17.9908 2.97852 18.4385 3.42623 18.4385 3.97852Z'
          fill='#242631'
        />
        <path
          d='M24.4385 3.97852C24.4385 4.5308 23.9908 4.97852 23.4385 4.97852C22.8862 4.97852 22.4385 4.5308 22.4385 3.97852C22.4385 3.42623 22.8862 2.97852 23.4385 2.97852C23.9908 2.97852 24.4385 3.42623 24.4385 3.97852Z'
          fill='#242631'
        />
        <path
          d='M30.4385 3.97852C30.4385 4.5308 29.9908 4.97852 29.4385 4.97852C28.8862 4.97852 28.4385 4.5308 28.4385 3.97852C28.4385 3.42623 28.8862 2.97852 29.4385 2.97852C29.9908 2.97852 30.4385 3.42623 30.4385 3.97852Z'
          fill='#242631'
        />
        <path
          d='M36.4385 3.97852C36.4385 4.5308 35.9908 4.97852 35.4385 4.97852C34.8862 4.97852 34.4385 4.5308 34.4385 3.97852C34.4385 3.42623 34.8862 2.97852 35.4385 2.97852C35.9908 2.97852 36.4385 3.42623 36.4385 3.97852Z'
          fill='#242631'
        />
        <path
          d='M42.4385 3.97852C42.4385 4.5308 41.9908 4.97852 41.4385 4.97852C40.8862 4.97852 40.4385 4.5308 40.4385 3.97852C40.4385 3.42623 40.8862 2.97852 41.4385 2.97852C41.9908 2.97852 42.4385 3.42623 42.4385 3.97852Z'
          fill='#242631'
        />
        <path
          d='M48.4385 3.97852C48.4385 4.5308 47.9908 4.97852 47.4385 4.97852C46.8862 4.97852 46.4385 4.5308 46.4385 3.97852C46.4385 3.42623 46.8862 2.97852 47.4385 2.97852C47.9908 2.97852 48.4385 3.42623 48.4385 3.97852Z'
          fill='#242631'
        />
        <path
          d='M54.4385 3.97852C54.4385 4.5308 53.9908 4.97852 53.4385 4.97852C52.8862 4.97852 52.4385 4.5308 52.4385 3.97852C52.4385 3.42623 52.8862 2.97852 53.4385 2.97852C53.9908 2.97852 54.4385 3.42623 54.4385 3.97852Z'
          fill='#242631'
        />
        <path
          d='M60.4385 3.97852C60.4385 4.5308 59.9908 4.97852 59.4385 4.97852C58.8862 4.97852 58.4385 4.5308 58.4385 3.97852C58.4385 3.42623 58.8862 2.97852 59.4385 2.97852C59.9908 2.97852 60.4385 3.42623 60.4385 3.97852Z'
          fill='#242631'
        />
        <path
          d='M66.4385 3.97852C66.4385 4.5308 65.9908 4.97852 65.4385 4.97852C64.8862 4.97852 64.4385 4.5308 64.4385 3.97852C64.4385 3.42623 64.8862 2.97852 65.4385 2.97852C65.9908 2.97852 66.4385 3.42623 66.4385 3.97852Z'
          fill='#242631'
        />
        <path
          d='M72.4385 3.97852C72.4385 4.5308 71.9908 4.97852 71.4385 4.97852C70.8862 4.97852 70.4385 4.5308 70.4385 3.97852C70.4385 3.42623 70.8862 2.97852 71.4385 2.97852C71.9908 2.97852 72.4385 3.42623 72.4385 3.97852Z'
          fill='#242631'
        />
        <path
          d='M78.4385 3.97852C78.4385 4.5308 77.9908 4.97852 77.4385 4.97852C76.8862 4.97852 76.4385 4.5308 76.4385 3.97852C76.4385 3.42623 76.8862 2.97852 77.4385 2.97852C77.9908 2.97852 78.4385 3.42623 78.4385 3.97852Z'
          fill='#242631'
        />
        <path
          d='M84.4385 3.97852C84.4385 4.5308 83.9908 4.97852 83.4385 4.97852C82.8862 4.97852 82.4385 4.5308 82.4385 3.97852C82.4385 3.42623 82.8862 2.97852 83.4385 2.97852C83.9908 2.97852 84.4385 3.42623 84.4385 3.97852Z'
          fill='#242631'
        />
        <path
          d='M90.4385 3.97852C90.4385 4.5308 89.9908 4.97852 89.4385 4.97852C88.8862 4.97852 88.4385 4.5308 88.4385 3.97852C88.4385 3.42623 88.8862 2.97852 89.4385 2.97852C89.9908 2.97852 90.4385 3.42623 90.4385 3.97852Z'
          fill='#242631'
        />
        <path
          d='M96.4385 3.97852C96.4385 4.5308 95.9908 4.97852 95.4385 4.97852C94.8862 4.97852 94.4385 4.5308 94.4385 3.97852C94.4385 3.42623 94.8862 2.97852 95.4385 2.97852C95.9908 2.97852 96.4385 3.42623 96.4385 3.97852Z'
          fill='#242631'
        />
        <path
          d='M102.438 3.97852C102.438 4.5308 101.991 4.97852 101.438 4.97852C100.886 4.97852 100.438 4.5308 100.438 3.97852C100.438 3.42623 100.886 2.97852 101.438 2.97852C101.991 2.97852 102.438 3.42623 102.438 3.97852Z'
          fill='#242631'
        />
        <path
          d='M108.438 3.97852C108.438 4.5308 107.991 4.97852 107.438 4.97852C106.886 4.97852 106.438 4.5308 106.438 3.97852C106.438 3.42623 106.886 2.97852 107.438 2.97852C107.991 2.97852 108.438 3.42623 108.438 3.97852Z'
          fill='#242631'
        />
        <path
          d='M114.438 3.97852C114.438 4.5308 113.991 4.97852 113.438 4.97852C112.886 4.97852 112.438 4.5308 112.438 3.97852C112.438 3.42623 112.886 2.97852 113.438 2.97852C113.991 2.97852 114.438 3.42623 114.438 3.97852Z'
          fill='#242631'
        />
        <path
          d='M120.438 3.97852C120.438 4.5308 119.991 4.97852 119.438 4.97852C118.886 4.97852 118.438 4.5308 118.438 3.97852C118.438 3.42623 118.886 2.97852 119.438 2.97852C119.991 2.97852 120.438 3.42623 120.438 3.97852Z'
          fill='#242631'
        />
        <path
          d='M126.438 3.97852C126.438 4.5308 125.991 4.97852 125.438 4.97852C124.886 4.97852 124.438 4.5308 124.438 3.97852C124.438 3.42623 124.886 2.97852 125.438 2.97852C125.991 2.97852 126.438 3.42623 126.438 3.97852Z'
          fill='#242631'
        />
        <circle cx='131.438' cy='3.97852' r='1' fill='#242631' />
        <circle cx='137.438' cy='3.97852' r='1' fill='#242631' />
        <circle cx='143.438' cy='3.97852' r='1' fill='#242631' />
        <circle cx='149.438' cy='3.97852' r='1' fill='#242631' />
        <circle cx='155.438' cy='3.97852' r='1' fill='#242631' />
        <circle cx='161.438' cy='3.97852' r='1' fill='#242631' />
        <circle cx='167.438' cy='3.97852' r='1' fill='#242631' />
        <circle cx='173.438' cy='3.97852' r='1' fill='#242631' />
        <circle cx='179.438' cy='3.97852' r='1' fill='#242631' />
        <circle cx='185.438' cy='3.97852' r='1' fill='#242631' />
        <circle cx='191.438' cy='3.97852' r='1' fill='#242631' />
        <circle cx='197.438' cy='3.97852' r='1' fill='#242631' />
        <circle cx='-0.561523' cy='123.107' r='1' fill='#242631' />
        <circle cx='5.43848' cy='123.107' r='1' fill='#242631' />
        <path
          d='M12.4385 123.107C12.4385 123.66 11.9908 124.107 11.4385 124.107C10.8862 124.107 10.4385 123.66 10.4385 123.107C10.4385 122.555 10.8862 122.107 11.4385 122.107C11.9908 122.107 12.4385 122.555 12.4385 123.107Z'
          fill='#242631'
        />
        <path
          d='M18.4385 123.107C18.4385 123.66 17.9908 124.107 17.4385 124.107C16.8862 124.107 16.4385 123.66 16.4385 123.107C16.4385 122.555 16.8862 122.107 17.4385 122.107C17.9908 122.107 18.4385 122.555 18.4385 123.107Z'
          fill='#242631'
        />
        <path
          d='M24.4385 123.107C24.4385 123.66 23.9908 124.107 23.4385 124.107C22.8862 124.107 22.4385 123.66 22.4385 123.107C22.4385 122.555 22.8862 122.107 23.4385 122.107C23.9908 122.107 24.4385 122.555 24.4385 123.107Z'
          fill='#242631'
        />
        <path
          d='M30.4385 123.107C30.4385 123.66 29.9908 124.107 29.4385 124.107C28.8862 124.107 28.4385 123.66 28.4385 123.107C28.4385 122.555 28.8862 122.107 29.4385 122.107C29.9908 122.107 30.4385 122.555 30.4385 123.107Z'
          fill='#242631'
        />
        <path
          d='M36.4385 123.107C36.4385 123.66 35.9908 124.107 35.4385 124.107C34.8862 124.107 34.4385 123.66 34.4385 123.107C34.4385 122.555 34.8862 122.107 35.4385 122.107C35.9908 122.107 36.4385 122.555 36.4385 123.107Z'
          fill='#242631'
        />
        <path
          d='M42.4385 123.107C42.4385 123.66 41.9908 124.107 41.4385 124.107C40.8862 124.107 40.4385 123.66 40.4385 123.107C40.4385 122.555 40.8862 122.107 41.4385 122.107C41.9908 122.107 42.4385 122.555 42.4385 123.107Z'
          fill='#242631'
        />
        <path
          d='M48.4385 123.107C48.4385 123.66 47.9908 124.107 47.4385 124.107C46.8862 124.107 46.4385 123.66 46.4385 123.107C46.4385 122.555 46.8862 122.107 47.4385 122.107C47.9908 122.107 48.4385 122.555 48.4385 123.107Z'
          fill='#242631'
        />
        <path
          d='M54.4385 123.107C54.4385 123.66 53.9908 124.107 53.4385 124.107C52.8862 124.107 52.4385 123.66 52.4385 123.107C52.4385 122.555 52.8862 122.107 53.4385 122.107C53.9908 122.107 54.4385 122.555 54.4385 123.107Z'
          fill='#242631'
        />
        <path
          d='M60.4385 123.107C60.4385 123.66 59.9908 124.107 59.4385 124.107C58.8862 124.107 58.4385 123.66 58.4385 123.107C58.4385 122.555 58.8862 122.107 59.4385 122.107C59.9908 122.107 60.4385 122.555 60.4385 123.107Z'
          fill='#242631'
        />
        <path
          d='M66.4385 123.107C66.4385 123.66 65.9908 124.107 65.4385 124.107C64.8862 124.107 64.4385 123.66 64.4385 123.107C64.4385 122.555 64.8862 122.107 65.4385 122.107C65.9908 122.107 66.4385 122.555 66.4385 123.107Z'
          fill='#242631'
        />
        <path
          d='M72.4385 123.107C72.4385 123.66 71.9908 124.107 71.4385 124.107C70.8862 124.107 70.4385 123.66 70.4385 123.107C70.4385 122.555 70.8862 122.107 71.4385 122.107C71.9908 122.107 72.4385 122.555 72.4385 123.107Z'
          fill='#242631'
        />
        <path
          d='M78.4385 123.107C78.4385 123.66 77.9908 124.107 77.4385 124.107C76.8862 124.107 76.4385 123.66 76.4385 123.107C76.4385 122.555 76.8862 122.107 77.4385 122.107C77.9908 122.107 78.4385 122.555 78.4385 123.107Z'
          fill='#242631'
        />
        <path
          d='M84.4385 123.107C84.4385 123.66 83.9908 124.107 83.4385 124.107C82.8862 124.107 82.4385 123.66 82.4385 123.107C82.4385 122.555 82.8862 122.107 83.4385 122.107C83.9908 122.107 84.4385 122.555 84.4385 123.107Z'
          fill='#242631'
        />
        <path
          d='M90.4385 123.107C90.4385 123.66 89.9908 124.107 89.4385 124.107C88.8862 124.107 88.4385 123.66 88.4385 123.107C88.4385 122.555 88.8862 122.107 89.4385 122.107C89.9908 122.107 90.4385 122.555 90.4385 123.107Z'
          fill='#242631'
        />
        <path
          d='M96.4385 123.107C96.4385 123.66 95.9908 124.107 95.4385 124.107C94.8862 124.107 94.4385 123.66 94.4385 123.107C94.4385 122.555 94.8862 122.107 95.4385 122.107C95.9908 122.107 96.4385 122.555 96.4385 123.107Z'
          fill='#242631'
        />
        <path
          d='M102.438 123.107C102.438 123.66 101.991 124.107 101.438 124.107C100.886 124.107 100.438 123.66 100.438 123.107C100.438 122.555 100.886 122.107 101.438 122.107C101.991 122.107 102.438 122.555 102.438 123.107Z'
          fill='#242631'
        />
        <path
          d='M108.438 123.107C108.438 123.66 107.991 124.107 107.438 124.107C106.886 124.107 106.438 123.66 106.438 123.107C106.438 122.555 106.886 122.107 107.438 122.107C107.991 122.107 108.438 122.555 108.438 123.107Z'
          fill='#242631'
        />
        <path
          d='M114.438 123.107C114.438 123.66 113.991 124.107 113.438 124.107C112.886 124.107 112.438 123.66 112.438 123.107C112.438 122.555 112.886 122.107 113.438 122.107C113.991 122.107 114.438 122.555 114.438 123.107Z'
          fill='#242631'
        />
        <path
          d='M120.438 123.107C120.438 123.66 119.991 124.107 119.438 124.107C118.886 124.107 118.438 123.66 118.438 123.107C118.438 122.555 118.886 122.107 119.438 122.107C119.991 122.107 120.438 122.555 120.438 123.107Z'
          fill='#242631'
        />
        <path
          d='M126.438 123.107C126.438 123.66 125.991 124.107 125.438 124.107C124.886 124.107 124.438 123.66 124.438 123.107C124.438 122.555 124.886 122.107 125.438 122.107C125.991 122.107 126.438 122.555 126.438 123.107Z'
          fill='#242631'
        />
        <circle cx='131.438' cy='123.107' r='1' fill='#242631' />
        <circle cx='137.438' cy='123.107' r='1' fill='#242631' />
        <circle cx='143.438' cy='123.107' r='1' fill='#242631' />
        <circle cx='149.438' cy='123.107' r='1' fill='#242631' />
        <circle cx='155.438' cy='123.107' r='1' fill='#242631' />
        <circle cx='161.438' cy='123.107' r='1' fill='#242631' />
        <circle cx='167.438' cy='123.107' r='1' fill='#242631' />
        <circle cx='173.438' cy='123.107' r='1' fill='#242631' />
        <circle cx='179.438' cy='123.107' r='1' fill='#242631' />
        <circle cx='185.438' cy='123.107' r='1' fill='#242631' />
        <circle cx='191.438' cy='123.107' r='1' fill='#242631' />
        <circle cx='197.438' cy='123.107' r='1' fill='#242631' />
        <circle cx='-0.561523' cy='27.8047' r='1' fill='#242631' />
        <circle cx='5.43848' cy='27.8047' r='1' fill='#242631' />
        <path
          d='M12.4385 27.8047C12.4385 28.357 11.9908 28.8047 11.4385 28.8047C10.8862 28.8047 10.4385 28.357 10.4385 27.8047C10.4385 27.2524 10.8862 26.8047 11.4385 26.8047C11.9908 26.8047 12.4385 27.2524 12.4385 27.8047Z'
          fill='#242631'
        />
        <path
          d='M18.4385 27.8047C18.4385 28.357 17.9908 28.8047 17.4385 28.8047C16.8862 28.8047 16.4385 28.357 16.4385 27.8047C16.4385 27.2524 16.8862 26.8047 17.4385 26.8047C17.9908 26.8047 18.4385 27.2524 18.4385 27.8047Z'
          fill='#242631'
        />
        <path
          d='M24.4385 27.8047C24.4385 28.357 23.9908 28.8047 23.4385 28.8047C22.8862 28.8047 22.4385 28.357 22.4385 27.8047C22.4385 27.2524 22.8862 26.8047 23.4385 26.8047C23.9908 26.8047 24.4385 27.2524 24.4385 27.8047Z'
          fill='#242631'
        />
        <path
          d='M30.4385 27.8047C30.4385 28.357 29.9908 28.8047 29.4385 28.8047C28.8862 28.8047 28.4385 28.357 28.4385 27.8047C28.4385 27.2524 28.8862 26.8047 29.4385 26.8047C29.9908 26.8047 30.4385 27.2524 30.4385 27.8047Z'
          fill='#242631'
        />
        <path
          d='M36.4385 27.8047C36.4385 28.357 35.9908 28.8047 35.4385 28.8047C34.8862 28.8047 34.4385 28.357 34.4385 27.8047C34.4385 27.2524 34.8862 26.8047 35.4385 26.8047C35.9908 26.8047 36.4385 27.2524 36.4385 27.8047Z'
          fill='#242631'
        />
        <path
          d='M42.4385 27.8047C42.4385 28.357 41.9908 28.8047 41.4385 28.8047C40.8862 28.8047 40.4385 28.357 40.4385 27.8047C40.4385 27.2524 40.8862 26.8047 41.4385 26.8047C41.9908 26.8047 42.4385 27.2524 42.4385 27.8047Z'
          fill='#242631'
        />
        <path
          d='M48.4385 27.8047C48.4385 28.357 47.9908 28.8047 47.4385 28.8047C46.8862 28.8047 46.4385 28.357 46.4385 27.8047C46.4385 27.2524 46.8862 26.8047 47.4385 26.8047C47.9908 26.8047 48.4385 27.2524 48.4385 27.8047Z'
          fill='#242631'
        />
        <path
          d='M54.4385 27.8047C54.4385 28.357 53.9908 28.8047 53.4385 28.8047C52.8862 28.8047 52.4385 28.357 52.4385 27.8047C52.4385 27.2524 52.8862 26.8047 53.4385 26.8047C53.9908 26.8047 54.4385 27.2524 54.4385 27.8047Z'
          fill='#242631'
        />
        <path
          d='M60.4385 27.8047C60.4385 28.357 59.9908 28.8047 59.4385 28.8047C58.8862 28.8047 58.4385 28.357 58.4385 27.8047C58.4385 27.2524 58.8862 26.8047 59.4385 26.8047C59.9908 26.8047 60.4385 27.2524 60.4385 27.8047Z'
          fill='#242631'
        />
        <path
          d='M66.4385 27.8047C66.4385 28.357 65.9908 28.8047 65.4385 28.8047C64.8862 28.8047 64.4385 28.357 64.4385 27.8047C64.4385 27.2524 64.8862 26.8047 65.4385 26.8047C65.9908 26.8047 66.4385 27.2524 66.4385 27.8047Z'
          fill='#242631'
        />
        <path
          d='M72.4385 27.8047C72.4385 28.357 71.9908 28.8047 71.4385 28.8047C70.8862 28.8047 70.4385 28.357 70.4385 27.8047C70.4385 27.2524 70.8862 26.8047 71.4385 26.8047C71.9908 26.8047 72.4385 27.2524 72.4385 27.8047Z'
          fill='#242631'
        />
        <path
          d='M78.4385 27.8047C78.4385 28.357 77.9908 28.8047 77.4385 28.8047C76.8862 28.8047 76.4385 28.357 76.4385 27.8047C76.4385 27.2524 76.8862 26.8047 77.4385 26.8047C77.9908 26.8047 78.4385 27.2524 78.4385 27.8047Z'
          fill='#242631'
        />
        <path
          d='M84.4385 27.8047C84.4385 28.357 83.9908 28.8047 83.4385 28.8047C82.8862 28.8047 82.4385 28.357 82.4385 27.8047C82.4385 27.2524 82.8862 26.8047 83.4385 26.8047C83.9908 26.8047 84.4385 27.2524 84.4385 27.8047Z'
          fill='#242631'
        />
        <path
          d='M90.4385 27.8047C90.4385 28.357 89.9908 28.8047 89.4385 28.8047C88.8862 28.8047 88.4385 28.357 88.4385 27.8047C88.4385 27.2524 88.8862 26.8047 89.4385 26.8047C89.9908 26.8047 90.4385 27.2524 90.4385 27.8047Z'
          fill='#242631'
        />
        <path
          d='M96.4385 27.8047C96.4385 28.357 95.9908 28.8047 95.4385 28.8047C94.8862 28.8047 94.4385 28.357 94.4385 27.8047C94.4385 27.2524 94.8862 26.8047 95.4385 26.8047C95.9908 26.8047 96.4385 27.2524 96.4385 27.8047Z'
          fill='#242631'
        />
        <path
          d='M102.438 27.8047C102.438 28.357 101.991 28.8047 101.438 28.8047C100.886 28.8047 100.438 28.357 100.438 27.8047C100.438 27.2524 100.886 26.8047 101.438 26.8047C101.991 26.8047 102.438 27.2524 102.438 27.8047Z'
          fill='#242631'
        />
        <path
          d='M108.438 27.8047C108.438 28.357 107.991 28.8047 107.438 28.8047C106.886 28.8047 106.438 28.357 106.438 27.8047C106.438 27.2524 106.886 26.8047 107.438 26.8047C107.991 26.8047 108.438 27.2524 108.438 27.8047Z'
          fill='#242631'
        />
        <path
          d='M114.438 27.8047C114.438 28.357 113.991 28.8047 113.438 28.8047C112.886 28.8047 112.438 28.357 112.438 27.8047C112.438 27.2524 112.886 26.8047 113.438 26.8047C113.991 26.8047 114.438 27.2524 114.438 27.8047Z'
          fill='#242631'
        />
        <path
          d='M120.438 27.8047C120.438 28.357 119.991 28.8047 119.438 28.8047C118.886 28.8047 118.438 28.357 118.438 27.8047C118.438 27.2524 118.886 26.8047 119.438 26.8047C119.991 26.8047 120.438 27.2524 120.438 27.8047Z'
          fill='#242631'
        />
        <path
          d='M126.438 27.8047C126.438 28.357 125.991 28.8047 125.438 28.8047C124.886 28.8047 124.438 28.357 124.438 27.8047C124.438 27.2524 124.886 26.8047 125.438 26.8047C125.991 26.8047 126.438 27.2524 126.438 27.8047Z'
          fill='#242631'
        />
        <circle cx='131.438' cy='27.8047' r='1' fill='#242631' />
        <circle cx='137.438' cy='27.8047' r='1' fill='#242631' />
        <circle cx='143.438' cy='27.8047' r='1' fill='#242631' />
        <circle cx='149.438' cy='27.8047' r='1' fill='#242631' />
        <circle cx='155.438' cy='27.8047' r='1' fill='#242631' />
        <circle cx='161.438' cy='27.8047' r='1' fill='#242631' />
        <circle cx='167.438' cy='27.8047' r='1' fill='#242631' />
        <circle cx='173.438' cy='27.8047' r='1' fill='#242631' />
        <circle cx='179.438' cy='27.8047' r='1' fill='#242631' />
        <circle cx='185.438' cy='27.8047' r='1' fill='#242631' />
        <circle cx='191.438' cy='27.8047' r='1' fill='#242631' />
        <circle cx='197.438' cy='27.8047' r='1' fill='#242631' />
        <circle cx='-0.561523' cy='146.934' r='1' fill='#242631' />
        <circle cx='5.43848' cy='146.934' r='1' fill='#242631' />
        <path
          d='M12.4385 146.934C12.4385 147.486 11.9908 147.934 11.4385 147.934C10.8862 147.934 10.4385 147.486 10.4385 146.934C10.4385 146.381 10.8862 145.934 11.4385 145.934C11.9908 145.934 12.4385 146.381 12.4385 146.934Z'
          fill='#242631'
        />
        <path
          d='M18.4385 146.934C18.4385 147.486 17.9908 147.934 17.4385 147.934C16.8862 147.934 16.4385 147.486 16.4385 146.934C16.4385 146.381 16.8862 145.934 17.4385 145.934C17.9908 145.934 18.4385 146.381 18.4385 146.934Z'
          fill='#242631'
        />
        <path
          d='M24.4385 146.934C24.4385 147.486 23.9908 147.934 23.4385 147.934C22.8862 147.934 22.4385 147.486 22.4385 146.934C22.4385 146.381 22.8862 145.934 23.4385 145.934C23.9908 145.934 24.4385 146.381 24.4385 146.934Z'
          fill='#242631'
        />
        <path
          d='M30.4385 146.934C30.4385 147.486 29.9908 147.934 29.4385 147.934C28.8862 147.934 28.4385 147.486 28.4385 146.934C28.4385 146.381 28.8862 145.934 29.4385 145.934C29.9908 145.934 30.4385 146.381 30.4385 146.934Z'
          fill='#242631'
        />
        <path
          d='M36.4385 146.934C36.4385 147.486 35.9908 147.934 35.4385 147.934C34.8862 147.934 34.4385 147.486 34.4385 146.934C34.4385 146.381 34.8862 145.934 35.4385 145.934C35.9908 145.934 36.4385 146.381 36.4385 146.934Z'
          fill='#242631'
        />
        <path
          d='M42.4385 146.934C42.4385 147.486 41.9908 147.934 41.4385 147.934C40.8862 147.934 40.4385 147.486 40.4385 146.934C40.4385 146.381 40.8862 145.934 41.4385 145.934C41.9908 145.934 42.4385 146.381 42.4385 146.934Z'
          fill='#242631'
        />
        <path
          d='M48.4385 146.934C48.4385 147.486 47.9908 147.934 47.4385 147.934C46.8862 147.934 46.4385 147.486 46.4385 146.934C46.4385 146.381 46.8862 145.934 47.4385 145.934C47.9908 145.934 48.4385 146.381 48.4385 146.934Z'
          fill='#242631'
        />
        <path
          d='M54.4385 146.934C54.4385 147.486 53.9908 147.934 53.4385 147.934C52.8862 147.934 52.4385 147.486 52.4385 146.934C52.4385 146.381 52.8862 145.934 53.4385 145.934C53.9908 145.934 54.4385 146.381 54.4385 146.934Z'
          fill='#242631'
        />
        <path
          d='M60.4385 146.934C60.4385 147.486 59.9908 147.934 59.4385 147.934C58.8862 147.934 58.4385 147.486 58.4385 146.934C58.4385 146.381 58.8862 145.934 59.4385 145.934C59.9908 145.934 60.4385 146.381 60.4385 146.934Z'
          fill='#242631'
        />
        <path
          d='M66.4385 146.934C66.4385 147.486 65.9908 147.934 65.4385 147.934C64.8862 147.934 64.4385 147.486 64.4385 146.934C64.4385 146.381 64.8862 145.934 65.4385 145.934C65.9908 145.934 66.4385 146.381 66.4385 146.934Z'
          fill='#242631'
        />
        <path
          d='M72.4385 146.934C72.4385 147.486 71.9908 147.934 71.4385 147.934C70.8862 147.934 70.4385 147.486 70.4385 146.934C70.4385 146.381 70.8862 145.934 71.4385 145.934C71.9908 145.934 72.4385 146.381 72.4385 146.934Z'
          fill='#242631'
        />
        <path
          d='M78.4385 146.934C78.4385 147.486 77.9908 147.934 77.4385 147.934C76.8862 147.934 76.4385 147.486 76.4385 146.934C76.4385 146.381 76.8862 145.934 77.4385 145.934C77.9908 145.934 78.4385 146.381 78.4385 146.934Z'
          fill='#242631'
        />
        <path
          d='M84.4385 146.934C84.4385 147.486 83.9908 147.934 83.4385 147.934C82.8862 147.934 82.4385 147.486 82.4385 146.934C82.4385 146.381 82.8862 145.934 83.4385 145.934C83.9908 145.934 84.4385 146.381 84.4385 146.934Z'
          fill='#242631'
        />
        <path
          d='M90.4385 146.934C90.4385 147.486 89.9908 147.934 89.4385 147.934C88.8862 147.934 88.4385 147.486 88.4385 146.934C88.4385 146.381 88.8862 145.934 89.4385 145.934C89.9908 145.934 90.4385 146.381 90.4385 146.934Z'
          fill='#242631'
        />
        <path
          d='M96.4385 146.934C96.4385 147.486 95.9908 147.934 95.4385 147.934C94.8862 147.934 94.4385 147.486 94.4385 146.934C94.4385 146.381 94.8862 145.934 95.4385 145.934C95.9908 145.934 96.4385 146.381 96.4385 146.934Z'
          fill='#242631'
        />
        <path
          d='M102.438 146.934C102.438 147.486 101.991 147.934 101.438 147.934C100.886 147.934 100.438 147.486 100.438 146.934C100.438 146.381 100.886 145.934 101.438 145.934C101.991 145.934 102.438 146.381 102.438 146.934Z'
          fill='#242631'
        />
        <path
          d='M108.438 146.934C108.438 147.486 107.991 147.934 107.438 147.934C106.886 147.934 106.438 147.486 106.438 146.934C106.438 146.381 106.886 145.934 107.438 145.934C107.991 145.934 108.438 146.381 108.438 146.934Z'
          fill='#242631'
        />
        <path
          d='M114.438 146.934C114.438 147.486 113.991 147.934 113.438 147.934C112.886 147.934 112.438 147.486 112.438 146.934C112.438 146.381 112.886 145.934 113.438 145.934C113.991 145.934 114.438 146.381 114.438 146.934Z'
          fill='#242631'
        />
        <path
          d='M120.438 146.934C120.438 147.486 119.991 147.934 119.438 147.934C118.886 147.934 118.438 147.486 118.438 146.934C118.438 146.381 118.886 145.934 119.438 145.934C119.991 145.934 120.438 146.381 120.438 146.934Z'
          fill='#242631'
        />
        <path
          d='M126.438 146.934C126.438 147.486 125.991 147.934 125.438 147.934C124.886 147.934 124.438 147.486 124.438 146.934C124.438 146.381 124.886 145.934 125.438 145.934C125.991 145.934 126.438 146.381 126.438 146.934Z'
          fill='#242631'
        />
        <circle cx='131.438' cy='146.934' r='1' fill='#242631' />
        <circle cx='137.438' cy='146.934' r='1' fill='#242631' />
        <circle cx='143.438' cy='146.934' r='1' fill='#242631' />
        <circle cx='149.438' cy='146.934' r='1' fill='#242631' />
        <circle cx='155.438' cy='146.934' r='1' fill='#242631' />
        <circle cx='161.438' cy='146.934' r='1' fill='#242631' />
        <circle cx='167.438' cy='146.934' r='1' fill='#242631' />
        <circle cx='173.438' cy='146.934' r='1' fill='#242631' />
        <circle cx='179.438' cy='146.934' r='1' fill='#242631' />
        <circle cx='185.438' cy='146.934' r='1' fill='#242631' />
        <circle cx='191.438' cy='146.934' r='1' fill='#242631' />
        <circle cx='197.438' cy='146.934' r='1' fill='#242631' />
        <circle cx='-0.561523' cy='51.6309' r='1' fill='#242631' />
        <circle cx='5.43848' cy='51.6309' r='1' fill='#242631' />
        <path
          d='M12.4385 51.6309C12.4385 52.1831 11.9908 52.6309 11.4385 52.6309C10.8862 52.6309 10.4385 52.1831 10.4385 51.6309C10.4385 51.0786 10.8862 50.6309 11.4385 50.6309C11.9908 50.6309 12.4385 51.0786 12.4385 51.6309Z'
          fill='#242631'
        />
        <path
          d='M18.4385 51.6309C18.4385 52.1831 17.9908 52.6309 17.4385 52.6309C16.8862 52.6309 16.4385 52.1831 16.4385 51.6309C16.4385 51.0786 16.8862 50.6309 17.4385 50.6309C17.9908 50.6309 18.4385 51.0786 18.4385 51.6309Z'
          fill='#242631'
        />
        <path
          d='M24.4385 51.6309C24.4385 52.1831 23.9908 52.6309 23.4385 52.6309C22.8862 52.6309 22.4385 52.1831 22.4385 51.6309C22.4385 51.0786 22.8862 50.6309 23.4385 50.6309C23.9908 50.6309 24.4385 51.0786 24.4385 51.6309Z'
          fill='#242631'
        />
        <path
          d='M30.4385 51.6309C30.4385 52.1831 29.9908 52.6309 29.4385 52.6309C28.8862 52.6309 28.4385 52.1831 28.4385 51.6309C28.4385 51.0786 28.8862 50.6309 29.4385 50.6309C29.9908 50.6309 30.4385 51.0786 30.4385 51.6309Z'
          fill='#242631'
        />
        <path
          d='M36.4385 51.6309C36.4385 52.1831 35.9908 52.6309 35.4385 52.6309C34.8862 52.6309 34.4385 52.1831 34.4385 51.6309C34.4385 51.0786 34.8862 50.6309 35.4385 50.6309C35.9908 50.6309 36.4385 51.0786 36.4385 51.6309Z'
          fill='#242631'
        />
        <path
          d='M42.4385 51.6309C42.4385 52.1831 41.9908 52.6309 41.4385 52.6309C40.8862 52.6309 40.4385 52.1831 40.4385 51.6309C40.4385 51.0786 40.8862 50.6309 41.4385 50.6309C41.9908 50.6309 42.4385 51.0786 42.4385 51.6309Z'
          fill='#242631'
        />
        <path
          d='M48.4385 51.6309C48.4385 52.1831 47.9908 52.6309 47.4385 52.6309C46.8862 52.6309 46.4385 52.1831 46.4385 51.6309C46.4385 51.0786 46.8862 50.6309 47.4385 50.6309C47.9908 50.6309 48.4385 51.0786 48.4385 51.6309Z'
          fill='#242631'
        />
        <path
          d='M54.4385 51.6309C54.4385 52.1831 53.9908 52.6309 53.4385 52.6309C52.8862 52.6309 52.4385 52.1831 52.4385 51.6309C52.4385 51.0786 52.8862 50.6309 53.4385 50.6309C53.9908 50.6309 54.4385 51.0786 54.4385 51.6309Z'
          fill='#242631'
        />
        <path
          d='M60.4385 51.6309C60.4385 52.1831 59.9908 52.6309 59.4385 52.6309C58.8862 52.6309 58.4385 52.1831 58.4385 51.6309C58.4385 51.0786 58.8862 50.6309 59.4385 50.6309C59.9908 50.6309 60.4385 51.0786 60.4385 51.6309Z'
          fill='#242631'
        />
        <path
          d='M66.4385 51.6309C66.4385 52.1831 65.9908 52.6309 65.4385 52.6309C64.8862 52.6309 64.4385 52.1831 64.4385 51.6309C64.4385 51.0786 64.8862 50.6309 65.4385 50.6309C65.9908 50.6309 66.4385 51.0786 66.4385 51.6309Z'
          fill='#242631'
        />
        <path
          d='M72.4385 51.6309C72.4385 52.1831 71.9908 52.6309 71.4385 52.6309C70.8862 52.6309 70.4385 52.1831 70.4385 51.6309C70.4385 51.0786 70.8862 50.6309 71.4385 50.6309C71.9908 50.6309 72.4385 51.0786 72.4385 51.6309Z'
          fill='#242631'
        />
        <path
          d='M78.4385 51.6309C78.4385 52.1831 77.9908 52.6309 77.4385 52.6309C76.8862 52.6309 76.4385 52.1831 76.4385 51.6309C76.4385 51.0786 76.8862 50.6309 77.4385 50.6309C77.9908 50.6309 78.4385 51.0786 78.4385 51.6309Z'
          fill='#242631'
        />
        <path
          d='M84.4385 51.6309C84.4385 52.1831 83.9908 52.6309 83.4385 52.6309C82.8862 52.6309 82.4385 52.1831 82.4385 51.6309C82.4385 51.0786 82.8862 50.6309 83.4385 50.6309C83.9908 50.6309 84.4385 51.0786 84.4385 51.6309Z'
          fill='#242631'
        />
        <path
          d='M90.4385 51.6309C90.4385 52.1831 89.9908 52.6309 89.4385 52.6309C88.8862 52.6309 88.4385 52.1831 88.4385 51.6309C88.4385 51.0786 88.8862 50.6309 89.4385 50.6309C89.9908 50.6309 90.4385 51.0786 90.4385 51.6309Z'
          fill='#242631'
        />
        <path
          d='M96.4385 51.6309C96.4385 52.1831 95.9908 52.6309 95.4385 52.6309C94.8862 52.6309 94.4385 52.1831 94.4385 51.6309C94.4385 51.0786 94.8862 50.6309 95.4385 50.6309C95.9908 50.6309 96.4385 51.0786 96.4385 51.6309Z'
          fill='#242631'
        />
        <path
          d='M102.438 51.6309C102.438 52.1831 101.991 52.6309 101.438 52.6309C100.886 52.6309 100.438 52.1831 100.438 51.6309C100.438 51.0786 100.886 50.6309 101.438 50.6309C101.991 50.6309 102.438 51.0786 102.438 51.6309Z'
          fill='#242631'
        />
        <path
          d='M108.438 51.6309C108.438 52.1831 107.991 52.6309 107.438 52.6309C106.886 52.6309 106.438 52.1831 106.438 51.6309C106.438 51.0786 106.886 50.6309 107.438 50.6309C107.991 50.6309 108.438 51.0786 108.438 51.6309Z'
          fill='#242631'
        />
        <path
          d='M114.438 51.6309C114.438 52.1831 113.991 52.6309 113.438 52.6309C112.886 52.6309 112.438 52.1831 112.438 51.6309C112.438 51.0786 112.886 50.6309 113.438 50.6309C113.991 50.6309 114.438 51.0786 114.438 51.6309Z'
          fill='#242631'
        />
        <path
          d='M120.438 51.6309C120.438 52.1831 119.991 52.6309 119.438 52.6309C118.886 52.6309 118.438 52.1831 118.438 51.6309C118.438 51.0786 118.886 50.6309 119.438 50.6309C119.991 50.6309 120.438 51.0786 120.438 51.6309Z'
          fill='#242631'
        />
        <path
          d='M126.438 51.6309C126.438 52.1831 125.991 52.6309 125.438 52.6309C124.886 52.6309 124.438 52.1831 124.438 51.6309C124.438 51.0786 124.886 50.6309 125.438 50.6309C125.991 50.6309 126.438 51.0786 126.438 51.6309Z'
          fill='#242631'
        />
        <circle cx='131.438' cy='51.6309' r='1' fill='#242631' />
        <circle cx='137.438' cy='51.6309' r='1' fill='#242631' />
        <circle cx='143.438' cy='51.6309' r='1' fill='#242631' />
        <circle cx='149.438' cy='51.6309' r='1' fill='#242631' />
        <circle cx='155.438' cy='51.6309' r='1' fill='#242631' />
        <circle cx='161.438' cy='51.6309' r='1' fill='#242631' />
        <circle cx='167.438' cy='51.6309' r='1' fill='#242631' />
        <circle cx='173.438' cy='51.6309' r='1' fill='#242631' />
        <circle cx='179.438' cy='51.6309' r='1' fill='#242631' />
        <circle cx='185.438' cy='51.6309' r='1' fill='#242631' />
        <circle cx='191.438' cy='51.6309' r='1' fill='#242631' />
        <circle cx='197.438' cy='51.6309' r='1' fill='#242631' />
        <circle cx='-0.561523' cy='170.76' r='1' fill='#242631' />
        <circle cx='5.43848' cy='170.76' r='1' fill='#242631' />
        <path
          d='M12.4385 170.76C12.4385 171.312 11.9908 171.76 11.4385 171.76C10.8862 171.76 10.4385 171.312 10.4385 170.76C10.4385 170.207 10.8862 169.76 11.4385 169.76C11.9908 169.76 12.4385 170.207 12.4385 170.76Z'
          fill='#242631'
        />
        <path
          d='M18.4385 170.76C18.4385 171.312 17.9908 171.76 17.4385 171.76C16.8862 171.76 16.4385 171.312 16.4385 170.76C16.4385 170.207 16.8862 169.76 17.4385 169.76C17.9908 169.76 18.4385 170.207 18.4385 170.76Z'
          fill='#242631'
        />
        <path
          d='M24.4385 170.76C24.4385 171.312 23.9908 171.76 23.4385 171.76C22.8862 171.76 22.4385 171.312 22.4385 170.76C22.4385 170.207 22.8862 169.76 23.4385 169.76C23.9908 169.76 24.4385 170.207 24.4385 170.76Z'
          fill='#242631'
        />
        <path
          d='M30.4385 170.76C30.4385 171.312 29.9908 171.76 29.4385 171.76C28.8862 171.76 28.4385 171.312 28.4385 170.76C28.4385 170.207 28.8862 169.76 29.4385 169.76C29.9908 169.76 30.4385 170.207 30.4385 170.76Z'
          fill='#242631'
        />
        <path
          d='M36.4385 170.76C36.4385 171.312 35.9908 171.76 35.4385 171.76C34.8862 171.76 34.4385 171.312 34.4385 170.76C34.4385 170.207 34.8862 169.76 35.4385 169.76C35.9908 169.76 36.4385 170.207 36.4385 170.76Z'
          fill='#242631'
        />
        <path
          d='M42.4385 170.76C42.4385 171.312 41.9908 171.76 41.4385 171.76C40.8862 171.76 40.4385 171.312 40.4385 170.76C40.4385 170.207 40.8862 169.76 41.4385 169.76C41.9908 169.76 42.4385 170.207 42.4385 170.76Z'
          fill='#242631'
        />
        <path
          d='M48.4385 170.76C48.4385 171.312 47.9908 171.76 47.4385 171.76C46.8862 171.76 46.4385 171.312 46.4385 170.76C46.4385 170.207 46.8862 169.76 47.4385 169.76C47.9908 169.76 48.4385 170.207 48.4385 170.76Z'
          fill='#242631'
        />
        <path
          d='M54.4385 170.76C54.4385 171.312 53.9908 171.76 53.4385 171.76C52.8862 171.76 52.4385 171.312 52.4385 170.76C52.4385 170.207 52.8862 169.76 53.4385 169.76C53.9908 169.76 54.4385 170.207 54.4385 170.76Z'
          fill='#242631'
        />
        <path
          d='M60.4385 170.76C60.4385 171.312 59.9908 171.76 59.4385 171.76C58.8862 171.76 58.4385 171.312 58.4385 170.76C58.4385 170.207 58.8862 169.76 59.4385 169.76C59.9908 169.76 60.4385 170.207 60.4385 170.76Z'
          fill='#242631'
        />
        <path
          d='M66.4385 170.76C66.4385 171.312 65.9908 171.76 65.4385 171.76C64.8862 171.76 64.4385 171.312 64.4385 170.76C64.4385 170.207 64.8862 169.76 65.4385 169.76C65.9908 169.76 66.4385 170.207 66.4385 170.76Z'
          fill='#242631'
        />
        <path
          d='M72.4385 170.76C72.4385 171.312 71.9908 171.76 71.4385 171.76C70.8862 171.76 70.4385 171.312 70.4385 170.76C70.4385 170.207 70.8862 169.76 71.4385 169.76C71.9908 169.76 72.4385 170.207 72.4385 170.76Z'
          fill='#242631'
        />
        <path
          d='M78.4385 170.76C78.4385 171.312 77.9908 171.76 77.4385 171.76C76.8862 171.76 76.4385 171.312 76.4385 170.76C76.4385 170.207 76.8862 169.76 77.4385 169.76C77.9908 169.76 78.4385 170.207 78.4385 170.76Z'
          fill='#242631'
        />
        <path
          d='M84.4385 170.76C84.4385 171.312 83.9908 171.76 83.4385 171.76C82.8862 171.76 82.4385 171.312 82.4385 170.76C82.4385 170.207 82.8862 169.76 83.4385 169.76C83.9908 169.76 84.4385 170.207 84.4385 170.76Z'
          fill='#242631'
        />
        <path
          d='M90.4385 170.76C90.4385 171.312 89.9908 171.76 89.4385 171.76C88.8862 171.76 88.4385 171.312 88.4385 170.76C88.4385 170.207 88.8862 169.76 89.4385 169.76C89.9908 169.76 90.4385 170.207 90.4385 170.76Z'
          fill='#242631'
        />
        <path
          d='M96.4385 170.76C96.4385 171.312 95.9908 171.76 95.4385 171.76C94.8862 171.76 94.4385 171.312 94.4385 170.76C94.4385 170.207 94.8862 169.76 95.4385 169.76C95.9908 169.76 96.4385 170.207 96.4385 170.76Z'
          fill='#242631'
        />
        <path
          d='M102.438 170.76C102.438 171.312 101.991 171.76 101.438 171.76C100.886 171.76 100.438 171.312 100.438 170.76C100.438 170.207 100.886 169.76 101.438 169.76C101.991 169.76 102.438 170.207 102.438 170.76Z'
          fill='#242631'
        />
        <path
          d='M108.438 170.76C108.438 171.312 107.991 171.76 107.438 171.76C106.886 171.76 106.438 171.312 106.438 170.76C106.438 170.207 106.886 169.76 107.438 169.76C107.991 169.76 108.438 170.207 108.438 170.76Z'
          fill='#242631'
        />
        <path
          d='M114.438 170.76C114.438 171.312 113.991 171.76 113.438 171.76C112.886 171.76 112.438 171.312 112.438 170.76C112.438 170.207 112.886 169.76 113.438 169.76C113.991 169.76 114.438 170.207 114.438 170.76Z'
          fill='#242631'
        />
        <path
          d='M120.438 170.76C120.438 171.312 119.991 171.76 119.438 171.76C118.886 171.76 118.438 171.312 118.438 170.76C118.438 170.207 118.886 169.76 119.438 169.76C119.991 169.76 120.438 170.207 120.438 170.76Z'
          fill='#242631'
        />
        <path
          d='M126.438 170.76C126.438 171.312 125.991 171.76 125.438 171.76C124.886 171.76 124.438 171.312 124.438 170.76C124.438 170.207 124.886 169.76 125.438 169.76C125.991 169.76 126.438 170.207 126.438 170.76Z'
          fill='#242631'
        />
        <circle cx='131.438' cy='170.76' r='1' fill='#242631' />
        <circle cx='137.438' cy='170.76' r='1' fill='#242631' />
        <circle cx='143.438' cy='170.76' r='1' fill='#242631' />
        <circle cx='149.438' cy='170.76' r='1' fill='#242631' />
        <circle cx='155.438' cy='170.76' r='1' fill='#242631' />
        <circle cx='161.438' cy='170.76' r='1' fill='#242631' />
        <circle cx='167.438' cy='170.76' r='1' fill='#242631' />
        <circle cx='173.438' cy='170.76' r='1' fill='#242631' />
        <circle cx='179.438' cy='170.76' r='1' fill='#242631' />
        <circle cx='185.438' cy='170.76' r='1' fill='#242631' />
        <circle cx='191.438' cy='170.76' r='1' fill='#242631' />
        <circle cx='197.438' cy='170.76' r='1' fill='#242631' />
        <circle cx='-0.561523' cy='75.457' r='1' fill='#242631' />
        <circle cx='5.43848' cy='75.457' r='1' fill='#242631' />
        <path
          d='M12.4385 75.457C12.4385 76.0093 11.9908 76.457 11.4385 76.457C10.8862 76.457 10.4385 76.0093 10.4385 75.457C10.4385 74.9047 10.8862 74.457 11.4385 74.457C11.9908 74.457 12.4385 74.9047 12.4385 75.457Z'
          fill='#242631'
        />
        <path
          d='M18.4385 75.457C18.4385 76.0093 17.9908 76.457 17.4385 76.457C16.8862 76.457 16.4385 76.0093 16.4385 75.457C16.4385 74.9047 16.8862 74.457 17.4385 74.457C17.9908 74.457 18.4385 74.9047 18.4385 75.457Z'
          fill='#242631'
        />
        <path
          d='M24.4385 75.457C24.4385 76.0093 23.9908 76.457 23.4385 76.457C22.8862 76.457 22.4385 76.0093 22.4385 75.457C22.4385 74.9047 22.8862 74.457 23.4385 74.457C23.9908 74.457 24.4385 74.9047 24.4385 75.457Z'
          fill='#242631'
        />
        <path
          d='M30.4385 75.457C30.4385 76.0093 29.9908 76.457 29.4385 76.457C28.8862 76.457 28.4385 76.0093 28.4385 75.457C28.4385 74.9047 28.8862 74.457 29.4385 74.457C29.9908 74.457 30.4385 74.9047 30.4385 75.457Z'
          fill='#242631'
        />
        <path
          d='M36.4385 75.457C36.4385 76.0093 35.9908 76.457 35.4385 76.457C34.8862 76.457 34.4385 76.0093 34.4385 75.457C34.4385 74.9047 34.8862 74.457 35.4385 74.457C35.9908 74.457 36.4385 74.9047 36.4385 75.457Z'
          fill='#242631'
        />
        <path
          d='M42.4385 75.457C42.4385 76.0093 41.9908 76.457 41.4385 76.457C40.8862 76.457 40.4385 76.0093 40.4385 75.457C40.4385 74.9047 40.8862 74.457 41.4385 74.457C41.9908 74.457 42.4385 74.9047 42.4385 75.457Z'
          fill='#242631'
        />
        <path
          d='M48.4385 75.457C48.4385 76.0093 47.9908 76.457 47.4385 76.457C46.8862 76.457 46.4385 76.0093 46.4385 75.457C46.4385 74.9047 46.8862 74.457 47.4385 74.457C47.9908 74.457 48.4385 74.9047 48.4385 75.457Z'
          fill='#242631'
        />
        <path
          d='M54.4385 75.457C54.4385 76.0093 53.9908 76.457 53.4385 76.457C52.8862 76.457 52.4385 76.0093 52.4385 75.457C52.4385 74.9047 52.8862 74.457 53.4385 74.457C53.9908 74.457 54.4385 74.9047 54.4385 75.457Z'
          fill='#242631'
        />
        <path
          d='M60.4385 75.457C60.4385 76.0093 59.9908 76.457 59.4385 76.457C58.8862 76.457 58.4385 76.0093 58.4385 75.457C58.4385 74.9047 58.8862 74.457 59.4385 74.457C59.9908 74.457 60.4385 74.9047 60.4385 75.457Z'
          fill='#242631'
        />
        <path
          d='M66.4385 75.457C66.4385 76.0093 65.9908 76.457 65.4385 76.457C64.8862 76.457 64.4385 76.0093 64.4385 75.457C64.4385 74.9047 64.8862 74.457 65.4385 74.457C65.9908 74.457 66.4385 74.9047 66.4385 75.457Z'
          fill='#242631'
        />
        <path
          d='M72.4385 75.457C72.4385 76.0093 71.9908 76.457 71.4385 76.457C70.8862 76.457 70.4385 76.0093 70.4385 75.457C70.4385 74.9047 70.8862 74.457 71.4385 74.457C71.9908 74.457 72.4385 74.9047 72.4385 75.457Z'
          fill='#242631'
        />
        <path
          d='M78.4385 75.457C78.4385 76.0093 77.9908 76.457 77.4385 76.457C76.8862 76.457 76.4385 76.0093 76.4385 75.457C76.4385 74.9047 76.8862 74.457 77.4385 74.457C77.9908 74.457 78.4385 74.9047 78.4385 75.457Z'
          fill='#242631'
        />
        <path
          d='M84.4385 75.457C84.4385 76.0093 83.9908 76.457 83.4385 76.457C82.8862 76.457 82.4385 76.0093 82.4385 75.457C82.4385 74.9047 82.8862 74.457 83.4385 74.457C83.9908 74.457 84.4385 74.9047 84.4385 75.457Z'
          fill='#242631'
        />
        <path
          d='M90.4385 75.457C90.4385 76.0093 89.9908 76.457 89.4385 76.457C88.8862 76.457 88.4385 76.0093 88.4385 75.457C88.4385 74.9047 88.8862 74.457 89.4385 74.457C89.9908 74.457 90.4385 74.9047 90.4385 75.457Z'
          fill='#242631'
        />
        <path
          d='M96.4385 75.457C96.4385 76.0093 95.9908 76.457 95.4385 76.457C94.8862 76.457 94.4385 76.0093 94.4385 75.457C94.4385 74.9047 94.8862 74.457 95.4385 74.457C95.9908 74.457 96.4385 74.9047 96.4385 75.457Z'
          fill='#242631'
        />
        <path
          d='M102.438 75.457C102.438 76.0093 101.991 76.457 101.438 76.457C100.886 76.457 100.438 76.0093 100.438 75.457C100.438 74.9047 100.886 74.457 101.438 74.457C101.991 74.457 102.438 74.9047 102.438 75.457Z'
          fill='#242631'
        />
        <path
          d='M108.438 75.457C108.438 76.0093 107.991 76.457 107.438 76.457C106.886 76.457 106.438 76.0093 106.438 75.457C106.438 74.9047 106.886 74.457 107.438 74.457C107.991 74.457 108.438 74.9047 108.438 75.457Z'
          fill='#242631'
        />
        <path
          d='M114.438 75.457C114.438 76.0093 113.991 76.457 113.438 76.457C112.886 76.457 112.438 76.0093 112.438 75.457C112.438 74.9047 112.886 74.457 113.438 74.457C113.991 74.457 114.438 74.9047 114.438 75.457Z'
          fill='#242631'
        />
        <path
          d='M120.438 75.457C120.438 76.0093 119.991 76.457 119.438 76.457C118.886 76.457 118.438 76.0093 118.438 75.457C118.438 74.9047 118.886 74.457 119.438 74.457C119.991 74.457 120.438 74.9047 120.438 75.457Z'
          fill='#242631'
        />
        <path
          d='M126.438 75.457C126.438 76.0093 125.991 76.457 125.438 76.457C124.886 76.457 124.438 76.0093 124.438 75.457C124.438 74.9047 124.886 74.457 125.438 74.457C125.991 74.457 126.438 74.9047 126.438 75.457Z'
          fill='#242631'
        />
        <circle cx='131.438' cy='75.457' r='1' fill='#242631' />
        <circle cx='137.438' cy='75.457' r='1' fill='#242631' />
        <circle cx='143.438' cy='75.457' r='1' fill='#242631' />
        <circle cx='149.438' cy='75.457' r='1' fill='#242631' />
        <circle cx='155.438' cy='75.457' r='1' fill='#242631' />
        <circle cx='161.438' cy='75.457' r='1' fill='#242631' />
        <circle cx='167.438' cy='75.457' r='1' fill='#242631' />
        <circle cx='173.438' cy='75.457' r='1' fill='#242631' />
        <circle cx='179.438' cy='75.457' r='1' fill='#242631' />
        <circle cx='185.438' cy='75.457' r='1' fill='#242631' />
        <circle cx='191.438' cy='75.457' r='1' fill='#242631' />
        <circle cx='197.438' cy='75.457' r='1' fill='#242631' />
        <circle cx='-0.561523' cy='194.586' r='1' fill='#242631' />
        <circle cx='5.43848' cy='194.586' r='1' fill='#242631' />
        <path
          d='M12.4385 194.586C12.4385 195.138 11.9908 195.586 11.4385 195.586C10.8862 195.586 10.4385 195.138 10.4385 194.586C10.4385 194.034 10.8862 193.586 11.4385 193.586C11.9908 193.586 12.4385 194.034 12.4385 194.586Z'
          fill='#242631'
        />
        <path
          d='M18.4385 194.586C18.4385 195.138 17.9908 195.586 17.4385 195.586C16.8862 195.586 16.4385 195.138 16.4385 194.586C16.4385 194.034 16.8862 193.586 17.4385 193.586C17.9908 193.586 18.4385 194.034 18.4385 194.586Z'
          fill='#242631'
        />
        <path
          d='M24.4385 194.586C24.4385 195.138 23.9908 195.586 23.4385 195.586C22.8862 195.586 22.4385 195.138 22.4385 194.586C22.4385 194.034 22.8862 193.586 23.4385 193.586C23.9908 193.586 24.4385 194.034 24.4385 194.586Z'
          fill='#242631'
        />
        <path
          d='M30.4385 194.586C30.4385 195.138 29.9908 195.586 29.4385 195.586C28.8862 195.586 28.4385 195.138 28.4385 194.586C28.4385 194.034 28.8862 193.586 29.4385 193.586C29.9908 193.586 30.4385 194.034 30.4385 194.586Z'
          fill='#242631'
        />
        <path
          d='M36.4385 194.586C36.4385 195.138 35.9908 195.586 35.4385 195.586C34.8862 195.586 34.4385 195.138 34.4385 194.586C34.4385 194.034 34.8862 193.586 35.4385 193.586C35.9908 193.586 36.4385 194.034 36.4385 194.586Z'
          fill='#242631'
        />
        <path
          d='M42.4385 194.586C42.4385 195.138 41.9908 195.586 41.4385 195.586C40.8862 195.586 40.4385 195.138 40.4385 194.586C40.4385 194.034 40.8862 193.586 41.4385 193.586C41.9908 193.586 42.4385 194.034 42.4385 194.586Z'
          fill='#242631'
        />
        <path
          d='M48.4385 194.586C48.4385 195.138 47.9908 195.586 47.4385 195.586C46.8862 195.586 46.4385 195.138 46.4385 194.586C46.4385 194.034 46.8862 193.586 47.4385 193.586C47.9908 193.586 48.4385 194.034 48.4385 194.586Z'
          fill='#242631'
        />
        <path
          d='M54.4385 194.586C54.4385 195.138 53.9908 195.586 53.4385 195.586C52.8862 195.586 52.4385 195.138 52.4385 194.586C52.4385 194.034 52.8862 193.586 53.4385 193.586C53.9908 193.586 54.4385 194.034 54.4385 194.586Z'
          fill='#242631'
        />
        <path
          d='M60.4385 194.586C60.4385 195.138 59.9908 195.586 59.4385 195.586C58.8862 195.586 58.4385 195.138 58.4385 194.586C58.4385 194.034 58.8862 193.586 59.4385 193.586C59.9908 193.586 60.4385 194.034 60.4385 194.586Z'
          fill='#242631'
        />
        <path
          d='M66.4385 194.586C66.4385 195.138 65.9908 195.586 65.4385 195.586C64.8862 195.586 64.4385 195.138 64.4385 194.586C64.4385 194.034 64.8862 193.586 65.4385 193.586C65.9908 193.586 66.4385 194.034 66.4385 194.586Z'
          fill='#242631'
        />
        <path
          d='M72.4385 194.586C72.4385 195.138 71.9908 195.586 71.4385 195.586C70.8862 195.586 70.4385 195.138 70.4385 194.586C70.4385 194.034 70.8862 193.586 71.4385 193.586C71.9908 193.586 72.4385 194.034 72.4385 194.586Z'
          fill='#242631'
        />
        <path
          d='M78.4385 194.586C78.4385 195.138 77.9908 195.586 77.4385 195.586C76.8862 195.586 76.4385 195.138 76.4385 194.586C76.4385 194.034 76.8862 193.586 77.4385 193.586C77.9908 193.586 78.4385 194.034 78.4385 194.586Z'
          fill='#242631'
        />
        <path
          d='M84.4385 194.586C84.4385 195.138 83.9908 195.586 83.4385 195.586C82.8862 195.586 82.4385 195.138 82.4385 194.586C82.4385 194.034 82.8862 193.586 83.4385 193.586C83.9908 193.586 84.4385 194.034 84.4385 194.586Z'
          fill='#242631'
        />
        <path
          d='M90.4385 194.586C90.4385 195.138 89.9908 195.586 89.4385 195.586C88.8862 195.586 88.4385 195.138 88.4385 194.586C88.4385 194.034 88.8862 193.586 89.4385 193.586C89.9908 193.586 90.4385 194.034 90.4385 194.586Z'
          fill='#242631'
        />
        <path
          d='M96.4385 194.586C96.4385 195.138 95.9908 195.586 95.4385 195.586C94.8862 195.586 94.4385 195.138 94.4385 194.586C94.4385 194.034 94.8862 193.586 95.4385 193.586C95.9908 193.586 96.4385 194.034 96.4385 194.586Z'
          fill='#242631'
        />
        <path
          d='M102.438 194.586C102.438 195.138 101.991 195.586 101.438 195.586C100.886 195.586 100.438 195.138 100.438 194.586C100.438 194.034 100.886 193.586 101.438 193.586C101.991 193.586 102.438 194.034 102.438 194.586Z'
          fill='#242631'
        />
        <path
          d='M108.438 194.586C108.438 195.138 107.991 195.586 107.438 195.586C106.886 195.586 106.438 195.138 106.438 194.586C106.438 194.034 106.886 193.586 107.438 193.586C107.991 193.586 108.438 194.034 108.438 194.586Z'
          fill='#242631'
        />
        <path
          d='M114.438 194.586C114.438 195.138 113.991 195.586 113.438 195.586C112.886 195.586 112.438 195.138 112.438 194.586C112.438 194.034 112.886 193.586 113.438 193.586C113.991 193.586 114.438 194.034 114.438 194.586Z'
          fill='#242631'
        />
        <path
          d='M120.438 194.586C120.438 195.138 119.991 195.586 119.438 195.586C118.886 195.586 118.438 195.138 118.438 194.586C118.438 194.034 118.886 193.586 119.438 193.586C119.991 193.586 120.438 194.034 120.438 194.586Z'
          fill='#242631'
        />
        <path
          d='M126.438 194.586C126.438 195.138 125.991 195.586 125.438 195.586C124.886 195.586 124.438 195.138 124.438 194.586C124.438 194.034 124.886 193.586 125.438 193.586C125.991 193.586 126.438 194.034 126.438 194.586Z'
          fill='#242631'
        />
        <circle cx='131.438' cy='194.586' r='1' fill='#242631' />
        <circle cx='137.438' cy='194.586' r='1' fill='#242631' />
        <circle cx='143.438' cy='194.586' r='1' fill='#242631' />
        <circle cx='149.438' cy='194.586' r='1' fill='#242631' />
        <circle cx='155.438' cy='194.586' r='1' fill='#242631' />
        <circle cx='161.438' cy='194.586' r='1' fill='#242631' />
        <circle cx='167.438' cy='194.586' r='1' fill='#242631' />
        <circle cx='173.438' cy='194.586' r='1' fill='#242631' />
        <circle cx='179.438' cy='194.586' r='1' fill='#242631' />
        <circle cx='185.438' cy='194.586' r='1' fill='#242631' />
        <circle cx='191.438' cy='194.586' r='1' fill='#242631' />
        <circle cx='197.438' cy='194.586' r='1' fill='#242631' />
        <circle cx='-0.561523' cy='99.2812' r='1' fill='#242631' />
        <circle cx='5.43848' cy='99.2812' r='1' fill='#242631' />
        <path
          d='M12.4385 99.2812C12.4385 99.8335 11.9908 100.281 11.4385 100.281C10.8862 100.281 10.4385 99.8335 10.4385 99.2812C10.4385 98.729 10.8862 98.2812 11.4385 98.2812C11.9908 98.2812 12.4385 98.729 12.4385 99.2812Z'
          fill='#242631'
        />
        <path
          d='M18.4385 99.2812C18.4385 99.8335 17.9908 100.281 17.4385 100.281C16.8862 100.281 16.4385 99.8335 16.4385 99.2812C16.4385 98.729 16.8862 98.2812 17.4385 98.2812C17.9908 98.2812 18.4385 98.729 18.4385 99.2812Z'
          fill='#242631'
        />
        <path
          d='M24.4385 99.2812C24.4385 99.8335 23.9908 100.281 23.4385 100.281C22.8862 100.281 22.4385 99.8335 22.4385 99.2812C22.4385 98.729 22.8862 98.2812 23.4385 98.2812C23.9908 98.2812 24.4385 98.729 24.4385 99.2812Z'
          fill='#242631'
        />
        <path
          d='M30.4385 99.2812C30.4385 99.8335 29.9908 100.281 29.4385 100.281C28.8862 100.281 28.4385 99.8335 28.4385 99.2812C28.4385 98.729 28.8862 98.2812 29.4385 98.2812C29.9908 98.2812 30.4385 98.729 30.4385 99.2812Z'
          fill='#242631'
        />
        <path
          d='M36.4385 99.2812C36.4385 99.8335 35.9908 100.281 35.4385 100.281C34.8862 100.281 34.4385 99.8335 34.4385 99.2812C34.4385 98.729 34.8862 98.2812 35.4385 98.2812C35.9908 98.2812 36.4385 98.729 36.4385 99.2812Z'
          fill='#242631'
        />
        <path
          d='M42.4385 99.2812C42.4385 99.8335 41.9908 100.281 41.4385 100.281C40.8862 100.281 40.4385 99.8335 40.4385 99.2812C40.4385 98.729 40.8862 98.2812 41.4385 98.2812C41.9908 98.2812 42.4385 98.729 42.4385 99.2812Z'
          fill='#242631'
        />
        <path
          d='M48.4385 99.2812C48.4385 99.8335 47.9908 100.281 47.4385 100.281C46.8862 100.281 46.4385 99.8335 46.4385 99.2812C46.4385 98.729 46.8862 98.2812 47.4385 98.2812C47.9908 98.2812 48.4385 98.729 48.4385 99.2812Z'
          fill='#242631'
        />
        <path
          d='M54.4385 99.2812C54.4385 99.8335 53.9908 100.281 53.4385 100.281C52.8862 100.281 52.4385 99.8335 52.4385 99.2812C52.4385 98.729 52.8862 98.2812 53.4385 98.2812C53.9908 98.2812 54.4385 98.729 54.4385 99.2812Z'
          fill='#242631'
        />
        <path
          d='M60.4385 99.2812C60.4385 99.8335 59.9908 100.281 59.4385 100.281C58.8862 100.281 58.4385 99.8335 58.4385 99.2812C58.4385 98.729 58.8862 98.2812 59.4385 98.2812C59.9908 98.2812 60.4385 98.729 60.4385 99.2812Z'
          fill='#242631'
        />
        <path
          d='M66.4385 99.2812C66.4385 99.8335 65.9908 100.281 65.4385 100.281C64.8862 100.281 64.4385 99.8335 64.4385 99.2812C64.4385 98.729 64.8862 98.2812 65.4385 98.2812C65.9908 98.2812 66.4385 98.729 66.4385 99.2812Z'
          fill='#242631'
        />
        <path
          d='M72.4385 99.2812C72.4385 99.8335 71.9908 100.281 71.4385 100.281C70.8862 100.281 70.4385 99.8335 70.4385 99.2812C70.4385 98.729 70.8862 98.2812 71.4385 98.2812C71.9908 98.2812 72.4385 98.729 72.4385 99.2812Z'
          fill='#242631'
        />
        <path
          d='M78.4385 99.2812C78.4385 99.8335 77.9908 100.281 77.4385 100.281C76.8862 100.281 76.4385 99.8335 76.4385 99.2812C76.4385 98.729 76.8862 98.2812 77.4385 98.2812C77.9908 98.2812 78.4385 98.729 78.4385 99.2812Z'
          fill='#242631'
        />
        <path
          d='M84.4385 99.2812C84.4385 99.8335 83.9908 100.281 83.4385 100.281C82.8862 100.281 82.4385 99.8335 82.4385 99.2812C82.4385 98.729 82.8862 98.2812 83.4385 98.2812C83.9908 98.2812 84.4385 98.729 84.4385 99.2812Z'
          fill='#242631'
        />
        <path
          d='M90.4385 99.2812C90.4385 99.8335 89.9908 100.281 89.4385 100.281C88.8862 100.281 88.4385 99.8335 88.4385 99.2812C88.4385 98.729 88.8862 98.2812 89.4385 98.2812C89.9908 98.2812 90.4385 98.729 90.4385 99.2812Z'
          fill='#242631'
        />
        <path
          d='M96.4385 99.2812C96.4385 99.8335 95.9908 100.281 95.4385 100.281C94.8862 100.281 94.4385 99.8335 94.4385 99.2812C94.4385 98.729 94.8862 98.2812 95.4385 98.2812C95.9908 98.2812 96.4385 98.729 96.4385 99.2812Z'
          fill='#242631'
        />
        <path
          d='M102.438 99.2812C102.438 99.8335 101.991 100.281 101.438 100.281C100.886 100.281 100.438 99.8335 100.438 99.2812C100.438 98.729 100.886 98.2812 101.438 98.2812C101.991 98.2812 102.438 98.729 102.438 99.2812Z'
          fill='#242631'
        />
        <path
          d='M108.438 99.2812C108.438 99.8335 107.991 100.281 107.438 100.281C106.886 100.281 106.438 99.8335 106.438 99.2812C106.438 98.729 106.886 98.2812 107.438 98.2812C107.991 98.2812 108.438 98.729 108.438 99.2812Z'
          fill='#242631'
        />
        <path
          d='M114.438 99.2812C114.438 99.8335 113.991 100.281 113.438 100.281C112.886 100.281 112.438 99.8335 112.438 99.2812C112.438 98.729 112.886 98.2812 113.438 98.2812C113.991 98.2812 114.438 98.729 114.438 99.2812Z'
          fill='#242631'
        />
        <path
          d='M120.438 99.2812C120.438 99.8335 119.991 100.281 119.438 100.281C118.886 100.281 118.438 99.8335 118.438 99.2812C118.438 98.729 118.886 98.2812 119.438 98.2812C119.991 98.2812 120.438 98.729 120.438 99.2812Z'
          fill='#242631'
        />
        <path
          d='M126.438 99.2812C126.438 99.8335 125.991 100.281 125.438 100.281C124.886 100.281 124.438 99.8335 124.438 99.2812C124.438 98.729 124.886 98.2812 125.438 98.2812C125.991 98.2812 126.438 98.729 126.438 99.2812Z'
          fill='#242631'
        />
        <circle cx='131.438' cy='99.2812' r='1' fill='#242631' />
        <circle cx='137.438' cy='99.2812' r='1' fill='#242631' />
        <circle cx='143.438' cy='99.2812' r='1' fill='#242631' />
        <circle cx='149.438' cy='99.2812' r='1' fill='#242631' />
        <circle cx='155.438' cy='99.2812' r='1' fill='#242631' />
        <circle cx='161.438' cy='99.2812' r='1' fill='#242631' />
        <circle cx='167.438' cy='99.2812' r='1' fill='#242631' />
        <circle cx='173.438' cy='99.2812' r='1' fill='#242631' />
        <circle cx='179.438' cy='99.2812' r='1' fill='#242631' />
        <circle cx='185.438' cy='99.2812' r='1' fill='#242631' />
        <circle cx='191.438' cy='99.2812' r='1' fill='#242631' />
        <circle cx='197.438' cy='99.2812' r='1' fill='#242631' />
        <circle cx='-0.561523' cy='15.8906' r='1' fill='#242631' />
        <circle cx='5.43848' cy='15.8906' r='1' fill='#242631' />
        <path
          d='M12.4385 15.8906C12.4385 16.4429 11.9908 16.8906 11.4385 16.8906C10.8862 16.8906 10.4385 16.4429 10.4385 15.8906C10.4385 15.3383 10.8862 14.8906 11.4385 14.8906C11.9908 14.8906 12.4385 15.3383 12.4385 15.8906Z'
          fill='#242631'
        />
        <path
          d='M18.4385 15.8906C18.4385 16.4429 17.9908 16.8906 17.4385 16.8906C16.8862 16.8906 16.4385 16.4429 16.4385 15.8906C16.4385 15.3383 16.8862 14.8906 17.4385 14.8906C17.9908 14.8906 18.4385 15.3383 18.4385 15.8906Z'
          fill='#242631'
        />
        <path
          d='M24.4385 15.8906C24.4385 16.4429 23.9908 16.8906 23.4385 16.8906C22.8862 16.8906 22.4385 16.4429 22.4385 15.8906C22.4385 15.3383 22.8862 14.8906 23.4385 14.8906C23.9908 14.8906 24.4385 15.3383 24.4385 15.8906Z'
          fill='#242631'
        />
        <path
          d='M30.4385 15.8906C30.4385 16.4429 29.9908 16.8906 29.4385 16.8906C28.8862 16.8906 28.4385 16.4429 28.4385 15.8906C28.4385 15.3383 28.8862 14.8906 29.4385 14.8906C29.9908 14.8906 30.4385 15.3383 30.4385 15.8906Z'
          fill='#242631'
        />
        <path
          d='M36.4385 15.8906C36.4385 16.4429 35.9908 16.8906 35.4385 16.8906C34.8862 16.8906 34.4385 16.4429 34.4385 15.8906C34.4385 15.3383 34.8862 14.8906 35.4385 14.8906C35.9908 14.8906 36.4385 15.3383 36.4385 15.8906Z'
          fill='#242631'
        />
        <path
          d='M42.4385 15.8906C42.4385 16.4429 41.9908 16.8906 41.4385 16.8906C40.8862 16.8906 40.4385 16.4429 40.4385 15.8906C40.4385 15.3383 40.8862 14.8906 41.4385 14.8906C41.9908 14.8906 42.4385 15.3383 42.4385 15.8906Z'
          fill='#242631'
        />
        <path
          d='M48.4385 15.8906C48.4385 16.4429 47.9908 16.8906 47.4385 16.8906C46.8862 16.8906 46.4385 16.4429 46.4385 15.8906C46.4385 15.3383 46.8862 14.8906 47.4385 14.8906C47.9908 14.8906 48.4385 15.3383 48.4385 15.8906Z'
          fill='#242631'
        />
        <path
          d='M54.4385 15.8906C54.4385 16.4429 53.9908 16.8906 53.4385 16.8906C52.8862 16.8906 52.4385 16.4429 52.4385 15.8906C52.4385 15.3383 52.8862 14.8906 53.4385 14.8906C53.9908 14.8906 54.4385 15.3383 54.4385 15.8906Z'
          fill='#242631'
        />
        <path
          d='M60.4385 15.8906C60.4385 16.4429 59.9908 16.8906 59.4385 16.8906C58.8862 16.8906 58.4385 16.4429 58.4385 15.8906C58.4385 15.3383 58.8862 14.8906 59.4385 14.8906C59.9908 14.8906 60.4385 15.3383 60.4385 15.8906Z'
          fill='#242631'
        />
        <path
          d='M66.4385 15.8906C66.4385 16.4429 65.9908 16.8906 65.4385 16.8906C64.8862 16.8906 64.4385 16.4429 64.4385 15.8906C64.4385 15.3383 64.8862 14.8906 65.4385 14.8906C65.9908 14.8906 66.4385 15.3383 66.4385 15.8906Z'
          fill='#242631'
        />
        <path
          d='M72.4385 15.8906C72.4385 16.4429 71.9908 16.8906 71.4385 16.8906C70.8862 16.8906 70.4385 16.4429 70.4385 15.8906C70.4385 15.3383 70.8862 14.8906 71.4385 14.8906C71.9908 14.8906 72.4385 15.3383 72.4385 15.8906Z'
          fill='#242631'
        />
        <path
          d='M78.4385 15.8906C78.4385 16.4429 77.9908 16.8906 77.4385 16.8906C76.8862 16.8906 76.4385 16.4429 76.4385 15.8906C76.4385 15.3383 76.8862 14.8906 77.4385 14.8906C77.9908 14.8906 78.4385 15.3383 78.4385 15.8906Z'
          fill='#242631'
        />
        <path
          d='M84.4385 15.8906C84.4385 16.4429 83.9908 16.8906 83.4385 16.8906C82.8862 16.8906 82.4385 16.4429 82.4385 15.8906C82.4385 15.3383 82.8862 14.8906 83.4385 14.8906C83.9908 14.8906 84.4385 15.3383 84.4385 15.8906Z'
          fill='#242631'
        />
        <path
          d='M90.4385 15.8906C90.4385 16.4429 89.9908 16.8906 89.4385 16.8906C88.8862 16.8906 88.4385 16.4429 88.4385 15.8906C88.4385 15.3383 88.8862 14.8906 89.4385 14.8906C89.9908 14.8906 90.4385 15.3383 90.4385 15.8906Z'
          fill='#242631'
        />
        <path
          d='M96.4385 15.8906C96.4385 16.4429 95.9908 16.8906 95.4385 16.8906C94.8862 16.8906 94.4385 16.4429 94.4385 15.8906C94.4385 15.3383 94.8862 14.8906 95.4385 14.8906C95.9908 14.8906 96.4385 15.3383 96.4385 15.8906Z'
          fill='#242631'
        />
        <path
          d='M102.438 15.8906C102.438 16.4429 101.991 16.8906 101.438 16.8906C100.886 16.8906 100.438 16.4429 100.438 15.8906C100.438 15.3383 100.886 14.8906 101.438 14.8906C101.991 14.8906 102.438 15.3383 102.438 15.8906Z'
          fill='#242631'
        />
        <path
          d='M108.438 15.8906C108.438 16.4429 107.991 16.8906 107.438 16.8906C106.886 16.8906 106.438 16.4429 106.438 15.8906C106.438 15.3383 106.886 14.8906 107.438 14.8906C107.991 14.8906 108.438 15.3383 108.438 15.8906Z'
          fill='#242631'
        />
        <path
          d='M114.438 15.8906C114.438 16.4429 113.991 16.8906 113.438 16.8906C112.886 16.8906 112.438 16.4429 112.438 15.8906C112.438 15.3383 112.886 14.8906 113.438 14.8906C113.991 14.8906 114.438 15.3383 114.438 15.8906Z'
          fill='#242631'
        />
        <path
          d='M120.438 15.8906C120.438 16.4429 119.991 16.8906 119.438 16.8906C118.886 16.8906 118.438 16.4429 118.438 15.8906C118.438 15.3383 118.886 14.8906 119.438 14.8906C119.991 14.8906 120.438 15.3383 120.438 15.8906Z'
          fill='#242631'
        />
        <path
          d='M126.438 15.8906C126.438 16.4429 125.991 16.8906 125.438 16.8906C124.886 16.8906 124.438 16.4429 124.438 15.8906C124.438 15.3383 124.886 14.8906 125.438 14.8906C125.991 14.8906 126.438 15.3383 126.438 15.8906Z'
          fill='#242631'
        />
        <circle cx='131.438' cy='15.8906' r='1' fill='#242631' />
        <circle cx='137.438' cy='15.8906' r='1' fill='#242631' />
        <circle cx='143.438' cy='15.8906' r='1' fill='#242631' />
        <circle cx='149.438' cy='15.8906' r='1' fill='#242631' />
        <circle cx='155.438' cy='15.8906' r='1' fill='#242631' />
        <circle cx='161.438' cy='15.8906' r='1' fill='#242631' />
        <circle cx='167.438' cy='15.8906' r='1' fill='#242631' />
        <circle cx='173.438' cy='15.8906' r='1' fill='#242631' />
        <circle cx='179.438' cy='15.8906' r='1' fill='#242631' />
        <circle cx='185.438' cy='15.8906' r='1' fill='#242631' />
        <circle cx='191.438' cy='15.8906' r='1' fill='#242631' />
        <circle cx='197.438' cy='15.8906' r='1' fill='#242631' />
        <circle cx='-0.561523' cy='135.021' r='1' fill='#242631' />
        <circle cx='5.43848' cy='135.021' r='1' fill='#242631' />
        <path
          d='M12.4385 135.021C12.4385 135.574 11.9908 136.021 11.4385 136.021C10.8862 136.021 10.4385 135.574 10.4385 135.021C10.4385 134.469 10.8862 134.021 11.4385 134.021C11.9908 134.021 12.4385 134.469 12.4385 135.021Z'
          fill='#242631'
        />
        <path
          d='M18.4385 135.021C18.4385 135.574 17.9908 136.021 17.4385 136.021C16.8862 136.021 16.4385 135.574 16.4385 135.021C16.4385 134.469 16.8862 134.021 17.4385 134.021C17.9908 134.021 18.4385 134.469 18.4385 135.021Z'
          fill='#242631'
        />
        <path
          d='M24.4385 135.021C24.4385 135.574 23.9908 136.021 23.4385 136.021C22.8862 136.021 22.4385 135.574 22.4385 135.021C22.4385 134.469 22.8862 134.021 23.4385 134.021C23.9908 134.021 24.4385 134.469 24.4385 135.021Z'
          fill='#242631'
        />
        <path
          d='M30.4385 135.021C30.4385 135.574 29.9908 136.021 29.4385 136.021C28.8862 136.021 28.4385 135.574 28.4385 135.021C28.4385 134.469 28.8862 134.021 29.4385 134.021C29.9908 134.021 30.4385 134.469 30.4385 135.021Z'
          fill='#242631'
        />
        <path
          d='M36.4385 135.021C36.4385 135.574 35.9908 136.021 35.4385 136.021C34.8862 136.021 34.4385 135.574 34.4385 135.021C34.4385 134.469 34.8862 134.021 35.4385 134.021C35.9908 134.021 36.4385 134.469 36.4385 135.021Z'
          fill='#242631'
        />
        <path
          d='M42.4385 135.021C42.4385 135.574 41.9908 136.021 41.4385 136.021C40.8862 136.021 40.4385 135.574 40.4385 135.021C40.4385 134.469 40.8862 134.021 41.4385 134.021C41.9908 134.021 42.4385 134.469 42.4385 135.021Z'
          fill='#242631'
        />
        <path
          d='M48.4385 135.021C48.4385 135.574 47.9908 136.021 47.4385 136.021C46.8862 136.021 46.4385 135.574 46.4385 135.021C46.4385 134.469 46.8862 134.021 47.4385 134.021C47.9908 134.021 48.4385 134.469 48.4385 135.021Z'
          fill='#242631'
        />
        <path
          d='M54.4385 135.021C54.4385 135.574 53.9908 136.021 53.4385 136.021C52.8862 136.021 52.4385 135.574 52.4385 135.021C52.4385 134.469 52.8862 134.021 53.4385 134.021C53.9908 134.021 54.4385 134.469 54.4385 135.021Z'
          fill='#242631'
        />
        <path
          d='M60.4385 135.021C60.4385 135.574 59.9908 136.021 59.4385 136.021C58.8862 136.021 58.4385 135.574 58.4385 135.021C58.4385 134.469 58.8862 134.021 59.4385 134.021C59.9908 134.021 60.4385 134.469 60.4385 135.021Z'
          fill='#242631'
        />
        <path
          d='M66.4385 135.021C66.4385 135.574 65.9908 136.021 65.4385 136.021C64.8862 136.021 64.4385 135.574 64.4385 135.021C64.4385 134.469 64.8862 134.021 65.4385 134.021C65.9908 134.021 66.4385 134.469 66.4385 135.021Z'
          fill='#242631'
        />
        <path
          d='M72.4385 135.021C72.4385 135.574 71.9908 136.021 71.4385 136.021C70.8862 136.021 70.4385 135.574 70.4385 135.021C70.4385 134.469 70.8862 134.021 71.4385 134.021C71.9908 134.021 72.4385 134.469 72.4385 135.021Z'
          fill='#242631'
        />
        <path
          d='M78.4385 135.021C78.4385 135.574 77.9908 136.021 77.4385 136.021C76.8862 136.021 76.4385 135.574 76.4385 135.021C76.4385 134.469 76.8862 134.021 77.4385 134.021C77.9908 134.021 78.4385 134.469 78.4385 135.021Z'
          fill='#242631'
        />
        <path
          d='M84.4385 135.021C84.4385 135.574 83.9908 136.021 83.4385 136.021C82.8862 136.021 82.4385 135.574 82.4385 135.021C82.4385 134.469 82.8862 134.021 83.4385 134.021C83.9908 134.021 84.4385 134.469 84.4385 135.021Z'
          fill='#242631'
        />
        <path
          d='M90.4385 135.021C90.4385 135.574 89.9908 136.021 89.4385 136.021C88.8862 136.021 88.4385 135.574 88.4385 135.021C88.4385 134.469 88.8862 134.021 89.4385 134.021C89.9908 134.021 90.4385 134.469 90.4385 135.021Z'
          fill='#242631'
        />
        <path
          d='M96.4385 135.021C96.4385 135.574 95.9908 136.021 95.4385 136.021C94.8862 136.021 94.4385 135.574 94.4385 135.021C94.4385 134.469 94.8862 134.021 95.4385 134.021C95.9908 134.021 96.4385 134.469 96.4385 135.021Z'
          fill='#242631'
        />
        <path
          d='M102.438 135.021C102.438 135.574 101.991 136.021 101.438 136.021C100.886 136.021 100.438 135.574 100.438 135.021C100.438 134.469 100.886 134.021 101.438 134.021C101.991 134.021 102.438 134.469 102.438 135.021Z'
          fill='#242631'
        />
        <path
          d='M108.438 135.021C108.438 135.574 107.991 136.021 107.438 136.021C106.886 136.021 106.438 135.574 106.438 135.021C106.438 134.469 106.886 134.021 107.438 134.021C107.991 134.021 108.438 134.469 108.438 135.021Z'
          fill='#242631'
        />
        <path
          d='M114.438 135.021C114.438 135.574 113.991 136.021 113.438 136.021C112.886 136.021 112.438 135.574 112.438 135.021C112.438 134.469 112.886 134.021 113.438 134.021C113.991 134.021 114.438 134.469 114.438 135.021Z'
          fill='#242631'
        />
        <path
          d='M120.438 135.021C120.438 135.574 119.991 136.021 119.438 136.021C118.886 136.021 118.438 135.574 118.438 135.021C118.438 134.469 118.886 134.021 119.438 134.021C119.991 134.021 120.438 134.469 120.438 135.021Z'
          fill='#242631'
        />
        <path
          d='M126.438 135.021C126.438 135.574 125.991 136.021 125.438 136.021C124.886 136.021 124.438 135.574 124.438 135.021C124.438 134.469 124.886 134.021 125.438 134.021C125.991 134.021 126.438 134.469 126.438 135.021Z'
          fill='#242631'
        />
        <circle cx='131.438' cy='135.021' r='1' fill='#242631' />
        <circle cx='137.438' cy='135.021' r='1' fill='#242631' />
        <circle cx='143.438' cy='135.021' r='1' fill='#242631' />
        <circle cx='149.438' cy='135.021' r='1' fill='#242631' />
        <circle cx='155.438' cy='135.021' r='1' fill='#242631' />
        <circle cx='161.438' cy='135.021' r='1' fill='#242631' />
        <circle cx='167.438' cy='135.021' r='1' fill='#242631' />
        <circle cx='173.438' cy='135.021' r='1' fill='#242631' />
        <circle cx='179.438' cy='135.021' r='1' fill='#242631' />
        <circle cx='185.438' cy='135.021' r='1' fill='#242631' />
        <circle cx='191.438' cy='135.021' r='1' fill='#242631' />
        <circle cx='197.438' cy='135.021' r='1' fill='#242631' />
        <circle cx='-0.561523' cy='39.7168' r='1' fill='#242631' />
        <circle cx='5.43848' cy='39.7168' r='1' fill='#242631' />
        <path
          d='M12.4385 39.7168C12.4385 40.2691 11.9908 40.7168 11.4385 40.7168C10.8862 40.7168 10.4385 40.2691 10.4385 39.7168C10.4385 39.1645 10.8862 38.7168 11.4385 38.7168C11.9908 38.7168 12.4385 39.1645 12.4385 39.7168Z'
          fill='#242631'
        />
        <path
          d='M18.4385 39.7168C18.4385 40.2691 17.9908 40.7168 17.4385 40.7168C16.8862 40.7168 16.4385 40.2691 16.4385 39.7168C16.4385 39.1645 16.8862 38.7168 17.4385 38.7168C17.9908 38.7168 18.4385 39.1645 18.4385 39.7168Z'
          fill='#242631'
        />
        <path
          d='M24.4385 39.7168C24.4385 40.2691 23.9908 40.7168 23.4385 40.7168C22.8862 40.7168 22.4385 40.2691 22.4385 39.7168C22.4385 39.1645 22.8862 38.7168 23.4385 38.7168C23.9908 38.7168 24.4385 39.1645 24.4385 39.7168Z'
          fill='#242631'
        />
        <path
          d='M30.4385 39.7168C30.4385 40.2691 29.9908 40.7168 29.4385 40.7168C28.8862 40.7168 28.4385 40.2691 28.4385 39.7168C28.4385 39.1645 28.8862 38.7168 29.4385 38.7168C29.9908 38.7168 30.4385 39.1645 30.4385 39.7168Z'
          fill='#242631'
        />
        <path
          d='M36.4385 39.7168C36.4385 40.2691 35.9908 40.7168 35.4385 40.7168C34.8862 40.7168 34.4385 40.2691 34.4385 39.7168C34.4385 39.1645 34.8862 38.7168 35.4385 38.7168C35.9908 38.7168 36.4385 39.1645 36.4385 39.7168Z'
          fill='#242631'
        />
        <path
          d='M42.4385 39.7168C42.4385 40.2691 41.9908 40.7168 41.4385 40.7168C40.8862 40.7168 40.4385 40.2691 40.4385 39.7168C40.4385 39.1645 40.8862 38.7168 41.4385 38.7168C41.9908 38.7168 42.4385 39.1645 42.4385 39.7168Z'
          fill='#242631'
        />
        <path
          d='M48.4385 39.7168C48.4385 40.2691 47.9908 40.7168 47.4385 40.7168C46.8862 40.7168 46.4385 40.2691 46.4385 39.7168C46.4385 39.1645 46.8862 38.7168 47.4385 38.7168C47.9908 38.7168 48.4385 39.1645 48.4385 39.7168Z'
          fill='#242631'
        />
        <path
          d='M54.4385 39.7168C54.4385 40.2691 53.9908 40.7168 53.4385 40.7168C52.8862 40.7168 52.4385 40.2691 52.4385 39.7168C52.4385 39.1645 52.8862 38.7168 53.4385 38.7168C53.9908 38.7168 54.4385 39.1645 54.4385 39.7168Z'
          fill='#242631'
        />
        <path
          d='M60.4385 39.7168C60.4385 40.2691 59.9908 40.7168 59.4385 40.7168C58.8862 40.7168 58.4385 40.2691 58.4385 39.7168C58.4385 39.1645 58.8862 38.7168 59.4385 38.7168C59.9908 38.7168 60.4385 39.1645 60.4385 39.7168Z'
          fill='#242631'
        />
        <path
          d='M66.4385 39.7168C66.4385 40.2691 65.9908 40.7168 65.4385 40.7168C64.8862 40.7168 64.4385 40.2691 64.4385 39.7168C64.4385 39.1645 64.8862 38.7168 65.4385 38.7168C65.9908 38.7168 66.4385 39.1645 66.4385 39.7168Z'
          fill='#242631'
        />
        <path
          d='M72.4385 39.7168C72.4385 40.2691 71.9908 40.7168 71.4385 40.7168C70.8862 40.7168 70.4385 40.2691 70.4385 39.7168C70.4385 39.1645 70.8862 38.7168 71.4385 38.7168C71.9908 38.7168 72.4385 39.1645 72.4385 39.7168Z'
          fill='#242631'
        />
        <path
          d='M78.4385 39.7168C78.4385 40.2691 77.9908 40.7168 77.4385 40.7168C76.8862 40.7168 76.4385 40.2691 76.4385 39.7168C76.4385 39.1645 76.8862 38.7168 77.4385 38.7168C77.9908 38.7168 78.4385 39.1645 78.4385 39.7168Z'
          fill='#242631'
        />
        <path
          d='M84.4385 39.7168C84.4385 40.2691 83.9908 40.7168 83.4385 40.7168C82.8862 40.7168 82.4385 40.2691 82.4385 39.7168C82.4385 39.1645 82.8862 38.7168 83.4385 38.7168C83.9908 38.7168 84.4385 39.1645 84.4385 39.7168Z'
          fill='#242631'
        />
        <path
          d='M90.4385 39.7168C90.4385 40.2691 89.9908 40.7168 89.4385 40.7168C88.8862 40.7168 88.4385 40.2691 88.4385 39.7168C88.4385 39.1645 88.8862 38.7168 89.4385 38.7168C89.9908 38.7168 90.4385 39.1645 90.4385 39.7168Z'
          fill='#242631'
        />
        <path
          d='M96.4385 39.7168C96.4385 40.2691 95.9908 40.7168 95.4385 40.7168C94.8862 40.7168 94.4385 40.2691 94.4385 39.7168C94.4385 39.1645 94.8862 38.7168 95.4385 38.7168C95.9908 38.7168 96.4385 39.1645 96.4385 39.7168Z'
          fill='#242631'
        />
        <path
          d='M102.438 39.7168C102.438 40.2691 101.991 40.7168 101.438 40.7168C100.886 40.7168 100.438 40.2691 100.438 39.7168C100.438 39.1645 100.886 38.7168 101.438 38.7168C101.991 38.7168 102.438 39.1645 102.438 39.7168Z'
          fill='#242631'
        />
        <path
          d='M108.438 39.7168C108.438 40.2691 107.991 40.7168 107.438 40.7168C106.886 40.7168 106.438 40.2691 106.438 39.7168C106.438 39.1645 106.886 38.7168 107.438 38.7168C107.991 38.7168 108.438 39.1645 108.438 39.7168Z'
          fill='#242631'
        />
        <path
          d='M114.438 39.7168C114.438 40.2691 113.991 40.7168 113.438 40.7168C112.886 40.7168 112.438 40.2691 112.438 39.7168C112.438 39.1645 112.886 38.7168 113.438 38.7168C113.991 38.7168 114.438 39.1645 114.438 39.7168Z'
          fill='#242631'
        />
        <path
          d='M120.438 39.7168C120.438 40.2691 119.991 40.7168 119.438 40.7168C118.886 40.7168 118.438 40.2691 118.438 39.7168C118.438 39.1645 118.886 38.7168 119.438 38.7168C119.991 38.7168 120.438 39.1645 120.438 39.7168Z'
          fill='#242631'
        />
        <path
          d='M126.438 39.7168C126.438 40.2691 125.991 40.7168 125.438 40.7168C124.886 40.7168 124.438 40.2691 124.438 39.7168C124.438 39.1645 124.886 38.7168 125.438 38.7168C125.991 38.7168 126.438 39.1645 126.438 39.7168Z'
          fill='#242631'
        />
        <circle cx='131.438' cy='39.7168' r='1' fill='#242631' />
        <circle cx='137.438' cy='39.7168' r='1' fill='#242631' />
        <circle cx='143.438' cy='39.7168' r='1' fill='#242631' />
        <circle cx='149.438' cy='39.7168' r='1' fill='#242631' />
        <circle cx='155.438' cy='39.7168' r='1' fill='#242631' />
        <circle cx='161.438' cy='39.7168' r='1' fill='#242631' />
        <circle cx='167.438' cy='39.7168' r='1' fill='#242631' />
        <circle cx='173.438' cy='39.7168' r='1' fill='#242631' />
        <circle cx='179.438' cy='39.7168' r='1' fill='#242631' />
        <circle cx='185.438' cy='39.7168' r='1' fill='#242631' />
        <circle cx='191.438' cy='39.7168' r='1' fill='#242631' />
        <circle cx='197.438' cy='39.7168' r='1' fill='#242631' />
        <circle cx='-0.561523' cy='158.848' r='1' fill='#242631' />
        <circle cx='5.43848' cy='158.848' r='1' fill='#242631' />
        <path
          d='M12.4385 158.848C12.4385 159.4 11.9908 159.848 11.4385 159.848C10.8862 159.848 10.4385 159.4 10.4385 158.848C10.4385 158.295 10.8862 157.848 11.4385 157.848C11.9908 157.848 12.4385 158.295 12.4385 158.848Z'
          fill='#242631'
        />
        <path
          d='M18.4385 158.848C18.4385 159.4 17.9908 159.848 17.4385 159.848C16.8862 159.848 16.4385 159.4 16.4385 158.848C16.4385 158.295 16.8862 157.848 17.4385 157.848C17.9908 157.848 18.4385 158.295 18.4385 158.848Z'
          fill='#242631'
        />
        <path
          d='M24.4385 158.848C24.4385 159.4 23.9908 159.848 23.4385 159.848C22.8862 159.848 22.4385 159.4 22.4385 158.848C22.4385 158.295 22.8862 157.848 23.4385 157.848C23.9908 157.848 24.4385 158.295 24.4385 158.848Z'
          fill='#242631'
        />
        <path
          d='M30.4385 158.848C30.4385 159.4 29.9908 159.848 29.4385 159.848C28.8862 159.848 28.4385 159.4 28.4385 158.848C28.4385 158.295 28.8862 157.848 29.4385 157.848C29.9908 157.848 30.4385 158.295 30.4385 158.848Z'
          fill='#242631'
        />
        <path
          d='M36.4385 158.848C36.4385 159.4 35.9908 159.848 35.4385 159.848C34.8862 159.848 34.4385 159.4 34.4385 158.848C34.4385 158.295 34.8862 157.848 35.4385 157.848C35.9908 157.848 36.4385 158.295 36.4385 158.848Z'
          fill='#242631'
        />
        <path
          d='M42.4385 158.848C42.4385 159.4 41.9908 159.848 41.4385 159.848C40.8862 159.848 40.4385 159.4 40.4385 158.848C40.4385 158.295 40.8862 157.848 41.4385 157.848C41.9908 157.848 42.4385 158.295 42.4385 158.848Z'
          fill='#242631'
        />
        <path
          d='M48.4385 158.848C48.4385 159.4 47.9908 159.848 47.4385 159.848C46.8862 159.848 46.4385 159.4 46.4385 158.848C46.4385 158.295 46.8862 157.848 47.4385 157.848C47.9908 157.848 48.4385 158.295 48.4385 158.848Z'
          fill='#242631'
        />
        <path
          d='M54.4385 158.848C54.4385 159.4 53.9908 159.848 53.4385 159.848C52.8862 159.848 52.4385 159.4 52.4385 158.848C52.4385 158.295 52.8862 157.848 53.4385 157.848C53.9908 157.848 54.4385 158.295 54.4385 158.848Z'
          fill='#242631'
        />
        <path
          d='M60.4385 158.848C60.4385 159.4 59.9908 159.848 59.4385 159.848C58.8862 159.848 58.4385 159.4 58.4385 158.848C58.4385 158.295 58.8862 157.848 59.4385 157.848C59.9908 157.848 60.4385 158.295 60.4385 158.848Z'
          fill='#242631'
        />
        <path
          d='M66.4385 158.848C66.4385 159.4 65.9908 159.848 65.4385 159.848C64.8862 159.848 64.4385 159.4 64.4385 158.848C64.4385 158.295 64.8862 157.848 65.4385 157.848C65.9908 157.848 66.4385 158.295 66.4385 158.848Z'
          fill='#242631'
        />
        <path
          d='M72.4385 158.848C72.4385 159.4 71.9908 159.848 71.4385 159.848C70.8862 159.848 70.4385 159.4 70.4385 158.848C70.4385 158.295 70.8862 157.848 71.4385 157.848C71.9908 157.848 72.4385 158.295 72.4385 158.848Z'
          fill='#242631'
        />
        <path
          d='M78.4385 158.848C78.4385 159.4 77.9908 159.848 77.4385 159.848C76.8862 159.848 76.4385 159.4 76.4385 158.848C76.4385 158.295 76.8862 157.848 77.4385 157.848C77.9908 157.848 78.4385 158.295 78.4385 158.848Z'
          fill='#242631'
        />
        <path
          d='M84.4385 158.848C84.4385 159.4 83.9908 159.848 83.4385 159.848C82.8862 159.848 82.4385 159.4 82.4385 158.848C82.4385 158.295 82.8862 157.848 83.4385 157.848C83.9908 157.848 84.4385 158.295 84.4385 158.848Z'
          fill='#242631'
        />
        <path
          d='M90.4385 158.848C90.4385 159.4 89.9908 159.848 89.4385 159.848C88.8862 159.848 88.4385 159.4 88.4385 158.848C88.4385 158.295 88.8862 157.848 89.4385 157.848C89.9908 157.848 90.4385 158.295 90.4385 158.848Z'
          fill='#242631'
        />
        <path
          d='M96.4385 158.848C96.4385 159.4 95.9908 159.848 95.4385 159.848C94.8862 159.848 94.4385 159.4 94.4385 158.848C94.4385 158.295 94.8862 157.848 95.4385 157.848C95.9908 157.848 96.4385 158.295 96.4385 158.848Z'
          fill='#242631'
        />
        <path
          d='M102.438 158.848C102.438 159.4 101.991 159.848 101.438 159.848C100.886 159.848 100.438 159.4 100.438 158.848C100.438 158.295 100.886 157.848 101.438 157.848C101.991 157.848 102.438 158.295 102.438 158.848Z'
          fill='#242631'
        />
        <path
          d='M108.438 158.848C108.438 159.4 107.991 159.848 107.438 159.848C106.886 159.848 106.438 159.4 106.438 158.848C106.438 158.295 106.886 157.848 107.438 157.848C107.991 157.848 108.438 158.295 108.438 158.848Z'
          fill='#242631'
        />
        <path
          d='M114.438 158.848C114.438 159.4 113.991 159.848 113.438 159.848C112.886 159.848 112.438 159.4 112.438 158.848C112.438 158.295 112.886 157.848 113.438 157.848C113.991 157.848 114.438 158.295 114.438 158.848Z'
          fill='#242631'
        />
        <path
          d='M120.438 158.848C120.438 159.4 119.991 159.848 119.438 159.848C118.886 159.848 118.438 159.4 118.438 158.848C118.438 158.295 118.886 157.848 119.438 157.848C119.991 157.848 120.438 158.295 120.438 158.848Z'
          fill='#242631'
        />
        <path
          d='M126.438 158.848C126.438 159.4 125.991 159.848 125.438 159.848C124.886 159.848 124.438 159.4 124.438 158.848C124.438 158.295 124.886 157.848 125.438 157.848C125.991 157.848 126.438 158.295 126.438 158.848Z'
          fill='#242631'
        />
        <circle cx='131.438' cy='158.848' r='1' fill='#242631' />
        <circle cx='137.438' cy='158.848' r='1' fill='#242631' />
        <circle cx='143.438' cy='158.848' r='1' fill='#242631' />
        <circle cx='149.438' cy='158.848' r='1' fill='#242631' />
        <circle cx='155.438' cy='158.848' r='1' fill='#242631' />
        <circle cx='161.438' cy='158.848' r='1' fill='#242631' />
        <circle cx='167.438' cy='158.848' r='1' fill='#242631' />
        <circle cx='173.438' cy='158.848' r='1' fill='#242631' />
        <circle cx='179.438' cy='158.848' r='1' fill='#242631' />
        <circle cx='185.438' cy='158.848' r='1' fill='#242631' />
        <circle cx='191.438' cy='158.848' r='1' fill='#242631' />
        <circle cx='197.438' cy='158.848' r='1' fill='#242631' />
        <circle cx='-0.561523' cy='63.543' r='1' fill='#242631' />
        <circle cx='5.43848' cy='63.543' r='1' fill='#242631' />
        <path
          d='M12.4385 63.543C12.4385 64.0953 11.9908 64.543 11.4385 64.543C10.8862 64.543 10.4385 64.0953 10.4385 63.543C10.4385 62.9907 10.8862 62.543 11.4385 62.543C11.9908 62.543 12.4385 62.9907 12.4385 63.543Z'
          fill='#242631'
        />
        <path
          d='M18.4385 63.543C18.4385 64.0953 17.9908 64.543 17.4385 64.543C16.8862 64.543 16.4385 64.0953 16.4385 63.543C16.4385 62.9907 16.8862 62.543 17.4385 62.543C17.9908 62.543 18.4385 62.9907 18.4385 63.543Z'
          fill='#242631'
        />
        <path
          d='M24.4385 63.543C24.4385 64.0953 23.9908 64.543 23.4385 64.543C22.8862 64.543 22.4385 64.0953 22.4385 63.543C22.4385 62.9907 22.8862 62.543 23.4385 62.543C23.9908 62.543 24.4385 62.9907 24.4385 63.543Z'
          fill='#242631'
        />
        <path
          d='M30.4385 63.543C30.4385 64.0953 29.9908 64.543 29.4385 64.543C28.8862 64.543 28.4385 64.0953 28.4385 63.543C28.4385 62.9907 28.8862 62.543 29.4385 62.543C29.9908 62.543 30.4385 62.9907 30.4385 63.543Z'
          fill='#242631'
        />
        <path
          d='M36.4385 63.543C36.4385 64.0953 35.9908 64.543 35.4385 64.543C34.8862 64.543 34.4385 64.0953 34.4385 63.543C34.4385 62.9907 34.8862 62.543 35.4385 62.543C35.9908 62.543 36.4385 62.9907 36.4385 63.543Z'
          fill='#242631'
        />
        <path
          d='M42.4385 63.543C42.4385 64.0953 41.9908 64.543 41.4385 64.543C40.8862 64.543 40.4385 64.0953 40.4385 63.543C40.4385 62.9907 40.8862 62.543 41.4385 62.543C41.9908 62.543 42.4385 62.9907 42.4385 63.543Z'
          fill='#242631'
        />
        <path
          d='M48.4385 63.543C48.4385 64.0953 47.9908 64.543 47.4385 64.543C46.8862 64.543 46.4385 64.0953 46.4385 63.543C46.4385 62.9907 46.8862 62.543 47.4385 62.543C47.9908 62.543 48.4385 62.9907 48.4385 63.543Z'
          fill='#242631'
        />
        <path
          d='M54.4385 63.543C54.4385 64.0953 53.9908 64.543 53.4385 64.543C52.8862 64.543 52.4385 64.0953 52.4385 63.543C52.4385 62.9907 52.8862 62.543 53.4385 62.543C53.9908 62.543 54.4385 62.9907 54.4385 63.543Z'
          fill='#242631'
        />
        <path
          d='M60.4385 63.543C60.4385 64.0953 59.9908 64.543 59.4385 64.543C58.8862 64.543 58.4385 64.0953 58.4385 63.543C58.4385 62.9907 58.8862 62.543 59.4385 62.543C59.9908 62.543 60.4385 62.9907 60.4385 63.543Z'
          fill='#242631'
        />
        <path
          d='M66.4385 63.543C66.4385 64.0953 65.9908 64.543 65.4385 64.543C64.8862 64.543 64.4385 64.0953 64.4385 63.543C64.4385 62.9907 64.8862 62.543 65.4385 62.543C65.9908 62.543 66.4385 62.9907 66.4385 63.543Z'
          fill='#242631'
        />
        <path
          d='M72.4385 63.543C72.4385 64.0953 71.9908 64.543 71.4385 64.543C70.8862 64.543 70.4385 64.0953 70.4385 63.543C70.4385 62.9907 70.8862 62.543 71.4385 62.543C71.9908 62.543 72.4385 62.9907 72.4385 63.543Z'
          fill='#242631'
        />
        <path
          d='M78.4385 63.543C78.4385 64.0953 77.9908 64.543 77.4385 64.543C76.8862 64.543 76.4385 64.0953 76.4385 63.543C76.4385 62.9907 76.8862 62.543 77.4385 62.543C77.9908 62.543 78.4385 62.9907 78.4385 63.543Z'
          fill='#242631'
        />
        <path
          d='M84.4385 63.543C84.4385 64.0953 83.9908 64.543 83.4385 64.543C82.8862 64.543 82.4385 64.0953 82.4385 63.543C82.4385 62.9907 82.8862 62.543 83.4385 62.543C83.9908 62.543 84.4385 62.9907 84.4385 63.543Z'
          fill='#242631'
        />
        <path
          d='M90.4385 63.543C90.4385 64.0953 89.9908 64.543 89.4385 64.543C88.8862 64.543 88.4385 64.0953 88.4385 63.543C88.4385 62.9907 88.8862 62.543 89.4385 62.543C89.9908 62.543 90.4385 62.9907 90.4385 63.543Z'
          fill='#242631'
        />
        <path
          d='M96.4385 63.543C96.4385 64.0953 95.9908 64.543 95.4385 64.543C94.8862 64.543 94.4385 64.0953 94.4385 63.543C94.4385 62.9907 94.8862 62.543 95.4385 62.543C95.9908 62.543 96.4385 62.9907 96.4385 63.543Z'
          fill='#242631'
        />
        <path
          d='M102.438 63.543C102.438 64.0953 101.991 64.543 101.438 64.543C100.886 64.543 100.438 64.0953 100.438 63.543C100.438 62.9907 100.886 62.543 101.438 62.543C101.991 62.543 102.438 62.9907 102.438 63.543Z'
          fill='#242631'
        />
        <path
          d='M108.438 63.543C108.438 64.0953 107.991 64.543 107.438 64.543C106.886 64.543 106.438 64.0953 106.438 63.543C106.438 62.9907 106.886 62.543 107.438 62.543C107.991 62.543 108.438 62.9907 108.438 63.543Z'
          fill='#242631'
        />
        <path
          d='M114.438 63.543C114.438 64.0953 113.991 64.543 113.438 64.543C112.886 64.543 112.438 64.0953 112.438 63.543C112.438 62.9907 112.886 62.543 113.438 62.543C113.991 62.543 114.438 62.9907 114.438 63.543Z'
          fill='#242631'
        />
        <path
          d='M120.438 63.543C120.438 64.0953 119.991 64.543 119.438 64.543C118.886 64.543 118.438 64.0953 118.438 63.543C118.438 62.9907 118.886 62.543 119.438 62.543C119.991 62.543 120.438 62.9907 120.438 63.543Z'
          fill='#242631'
        />
        <path
          d='M126.438 63.543C126.438 64.0953 125.991 64.543 125.438 64.543C124.886 64.543 124.438 64.0953 124.438 63.543C124.438 62.9907 124.886 62.543 125.438 62.543C125.991 62.543 126.438 62.9907 126.438 63.543Z'
          fill='#242631'
        />
        <circle cx='131.438' cy='63.543' r='1' fill='#242631' />
        <circle cx='137.438' cy='63.543' r='1' fill='#242631' />
        <circle cx='143.438' cy='63.543' r='1' fill='#242631' />
        <circle cx='149.438' cy='63.543' r='1' fill='#242631' />
        <circle cx='155.438' cy='63.543' r='1' fill='#242631' />
        <circle cx='161.438' cy='63.543' r='1' fill='#242631' />
        <circle cx='167.438' cy='63.543' r='1' fill='#242631' />
        <circle cx='173.438' cy='63.543' r='1' fill='#242631' />
        <circle cx='179.438' cy='63.543' r='1' fill='#242631' />
        <circle cx='185.438' cy='63.543' r='1' fill='#242631' />
        <circle cx='191.438' cy='63.543' r='1' fill='#242631' />
        <circle cx='197.438' cy='63.543' r='1' fill='#242631' />
        <circle cx='-0.561523' cy='182.672' r='1' fill='#242631' />
        <circle cx='5.43848' cy='182.672' r='1' fill='#242631' />
        <path
          d='M12.4385 182.672C12.4385 183.224 11.9908 183.672 11.4385 183.672C10.8862 183.672 10.4385 183.224 10.4385 182.672C10.4385 182.12 10.8862 181.672 11.4385 181.672C11.9908 181.672 12.4385 182.12 12.4385 182.672Z'
          fill='#242631'
        />
        <path
          d='M18.4385 182.672C18.4385 183.224 17.9908 183.672 17.4385 183.672C16.8862 183.672 16.4385 183.224 16.4385 182.672C16.4385 182.12 16.8862 181.672 17.4385 181.672C17.9908 181.672 18.4385 182.12 18.4385 182.672Z'
          fill='#242631'
        />
        <path
          d='M24.4385 182.672C24.4385 183.224 23.9908 183.672 23.4385 183.672C22.8862 183.672 22.4385 183.224 22.4385 182.672C22.4385 182.12 22.8862 181.672 23.4385 181.672C23.9908 181.672 24.4385 182.12 24.4385 182.672Z'
          fill='#242631'
        />
        <path
          d='M30.4385 182.672C30.4385 183.224 29.9908 183.672 29.4385 183.672C28.8862 183.672 28.4385 183.224 28.4385 182.672C28.4385 182.12 28.8862 181.672 29.4385 181.672C29.9908 181.672 30.4385 182.12 30.4385 182.672Z'
          fill='#242631'
        />
        <path
          d='M36.4385 182.672C36.4385 183.224 35.9908 183.672 35.4385 183.672C34.8862 183.672 34.4385 183.224 34.4385 182.672C34.4385 182.12 34.8862 181.672 35.4385 181.672C35.9908 181.672 36.4385 182.12 36.4385 182.672Z'
          fill='#242631'
        />
        <path
          d='M42.4385 182.672C42.4385 183.224 41.9908 183.672 41.4385 183.672C40.8862 183.672 40.4385 183.224 40.4385 182.672C40.4385 182.12 40.8862 181.672 41.4385 181.672C41.9908 181.672 42.4385 182.12 42.4385 182.672Z'
          fill='#242631'
        />
        <path
          d='M48.4385 182.672C48.4385 183.224 47.9908 183.672 47.4385 183.672C46.8862 183.672 46.4385 183.224 46.4385 182.672C46.4385 182.12 46.8862 181.672 47.4385 181.672C47.9908 181.672 48.4385 182.12 48.4385 182.672Z'
          fill='#242631'
        />
        <path
          d='M54.4385 182.672C54.4385 183.224 53.9908 183.672 53.4385 183.672C52.8862 183.672 52.4385 183.224 52.4385 182.672C52.4385 182.12 52.8862 181.672 53.4385 181.672C53.9908 181.672 54.4385 182.12 54.4385 182.672Z'
          fill='#242631'
        />
        <path
          d='M60.4385 182.672C60.4385 183.224 59.9908 183.672 59.4385 183.672C58.8862 183.672 58.4385 183.224 58.4385 182.672C58.4385 182.12 58.8862 181.672 59.4385 181.672C59.9908 181.672 60.4385 182.12 60.4385 182.672Z'
          fill='#242631'
        />
        <path
          d='M66.4385 182.672C66.4385 183.224 65.9908 183.672 65.4385 183.672C64.8862 183.672 64.4385 183.224 64.4385 182.672C64.4385 182.12 64.8862 181.672 65.4385 181.672C65.9908 181.672 66.4385 182.12 66.4385 182.672Z'
          fill='#242631'
        />
        <path
          d='M72.4385 182.672C72.4385 183.224 71.9908 183.672 71.4385 183.672C70.8862 183.672 70.4385 183.224 70.4385 182.672C70.4385 182.12 70.8862 181.672 71.4385 181.672C71.9908 181.672 72.4385 182.12 72.4385 182.672Z'
          fill='#242631'
        />
        <path
          d='M78.4385 182.672C78.4385 183.224 77.9908 183.672 77.4385 183.672C76.8862 183.672 76.4385 183.224 76.4385 182.672C76.4385 182.12 76.8862 181.672 77.4385 181.672C77.9908 181.672 78.4385 182.12 78.4385 182.672Z'
          fill='#242631'
        />
        <path
          d='M84.4385 182.672C84.4385 183.224 83.9908 183.672 83.4385 183.672C82.8862 183.672 82.4385 183.224 82.4385 182.672C82.4385 182.12 82.8862 181.672 83.4385 181.672C83.9908 181.672 84.4385 182.12 84.4385 182.672Z'
          fill='#242631'
        />
        <path
          d='M90.4385 182.672C90.4385 183.224 89.9908 183.672 89.4385 183.672C88.8862 183.672 88.4385 183.224 88.4385 182.672C88.4385 182.12 88.8862 181.672 89.4385 181.672C89.9908 181.672 90.4385 182.12 90.4385 182.672Z'
          fill='#242631'
        />
        <path
          d='M96.4385 182.672C96.4385 183.224 95.9908 183.672 95.4385 183.672C94.8862 183.672 94.4385 183.224 94.4385 182.672C94.4385 182.12 94.8862 181.672 95.4385 181.672C95.9908 181.672 96.4385 182.12 96.4385 182.672Z'
          fill='#242631'
        />
        <path
          d='M102.438 182.672C102.438 183.224 101.991 183.672 101.438 183.672C100.886 183.672 100.438 183.224 100.438 182.672C100.438 182.12 100.886 181.672 101.438 181.672C101.991 181.672 102.438 182.12 102.438 182.672Z'
          fill='#242631'
        />
        <path
          d='M108.438 182.672C108.438 183.224 107.991 183.672 107.438 183.672C106.886 183.672 106.438 183.224 106.438 182.672C106.438 182.12 106.886 181.672 107.438 181.672C107.991 181.672 108.438 182.12 108.438 182.672Z'
          fill='#242631'
        />
        <path
          d='M114.438 182.672C114.438 183.224 113.991 183.672 113.438 183.672C112.886 183.672 112.438 183.224 112.438 182.672C112.438 182.12 112.886 181.672 113.438 181.672C113.991 181.672 114.438 182.12 114.438 182.672Z'
          fill='#242631'
        />
        <path
          d='M120.438 182.672C120.438 183.224 119.991 183.672 119.438 183.672C118.886 183.672 118.438 183.224 118.438 182.672C118.438 182.12 118.886 181.672 119.438 181.672C119.991 181.672 120.438 182.12 120.438 182.672Z'
          fill='#242631'
        />
        <path
          d='M126.438 182.672C126.438 183.224 125.991 183.672 125.438 183.672C124.886 183.672 124.438 183.224 124.438 182.672C124.438 182.12 124.886 181.672 125.438 181.672C125.991 181.672 126.438 182.12 126.438 182.672Z'
          fill='#242631'
        />
        <circle cx='131.438' cy='182.672' r='1' fill='#242631' />
        <circle cx='137.438' cy='182.672' r='1' fill='#242631' />
        <circle cx='143.438' cy='182.672' r='1' fill='#242631' />
        <circle cx='149.438' cy='182.672' r='1' fill='#242631' />
        <circle cx='155.438' cy='182.672' r='1' fill='#242631' />
        <circle cx='161.438' cy='182.672' r='1' fill='#242631' />
        <circle cx='167.438' cy='182.672' r='1' fill='#242631' />
        <circle cx='173.438' cy='182.672' r='1' fill='#242631' />
        <circle cx='179.438' cy='182.672' r='1' fill='#242631' />
        <circle cx='185.438' cy='182.672' r='1' fill='#242631' />
        <circle cx='191.438' cy='182.672' r='1' fill='#242631' />
        <circle cx='197.438' cy='182.672' r='1' fill='#242631' />
        <circle cx='-0.561523' cy='87.3691' r='1' fill='#242631' />
        <circle cx='5.43848' cy='87.3691' r='1' fill='#242631' />
        <path
          d='M12.4385 87.3691C12.4385 87.9214 11.9908 88.3691 11.4385 88.3691C10.8862 88.3691 10.4385 87.9214 10.4385 87.3691C10.4385 86.8169 10.8862 86.3691 11.4385 86.3691C11.9908 86.3691 12.4385 86.8169 12.4385 87.3691Z'
          fill='#242631'
        />
        <path
          d='M18.4385 87.3691C18.4385 87.9214 17.9908 88.3691 17.4385 88.3691C16.8862 88.3691 16.4385 87.9214 16.4385 87.3691C16.4385 86.8169 16.8862 86.3691 17.4385 86.3691C17.9908 86.3691 18.4385 86.8169 18.4385 87.3691Z'
          fill='#242631'
        />
        <path
          d='M24.4385 87.3691C24.4385 87.9214 23.9908 88.3691 23.4385 88.3691C22.8862 88.3691 22.4385 87.9214 22.4385 87.3691C22.4385 86.8169 22.8862 86.3691 23.4385 86.3691C23.9908 86.3691 24.4385 86.8169 24.4385 87.3691Z'
          fill='#242631'
        />
        <path
          d='M30.4385 87.3691C30.4385 87.9214 29.9908 88.3691 29.4385 88.3691C28.8862 88.3691 28.4385 87.9214 28.4385 87.3691C28.4385 86.8169 28.8862 86.3691 29.4385 86.3691C29.9908 86.3691 30.4385 86.8169 30.4385 87.3691Z'
          fill='#242631'
        />
        <path
          d='M36.4385 87.3691C36.4385 87.9214 35.9908 88.3691 35.4385 88.3691C34.8862 88.3691 34.4385 87.9214 34.4385 87.3691C34.4385 86.8169 34.8862 86.3691 35.4385 86.3691C35.9908 86.3691 36.4385 86.8169 36.4385 87.3691Z'
          fill='#242631'
        />
        <path
          d='M42.4385 87.3691C42.4385 87.9214 41.9908 88.3691 41.4385 88.3691C40.8862 88.3691 40.4385 87.9214 40.4385 87.3691C40.4385 86.8169 40.8862 86.3691 41.4385 86.3691C41.9908 86.3691 42.4385 86.8169 42.4385 87.3691Z'
          fill='#242631'
        />
        <path
          d='M48.4385 87.3691C48.4385 87.9214 47.9908 88.3691 47.4385 88.3691C46.8862 88.3691 46.4385 87.9214 46.4385 87.3691C46.4385 86.8169 46.8862 86.3691 47.4385 86.3691C47.9908 86.3691 48.4385 86.8169 48.4385 87.3691Z'
          fill='#242631'
        />
        <path
          d='M54.4385 87.3691C54.4385 87.9214 53.9908 88.3691 53.4385 88.3691C52.8862 88.3691 52.4385 87.9214 52.4385 87.3691C52.4385 86.8169 52.8862 86.3691 53.4385 86.3691C53.9908 86.3691 54.4385 86.8169 54.4385 87.3691Z'
          fill='#242631'
        />
        <path
          d='M60.4385 87.3691C60.4385 87.9214 59.9908 88.3691 59.4385 88.3691C58.8862 88.3691 58.4385 87.9214 58.4385 87.3691C58.4385 86.8169 58.8862 86.3691 59.4385 86.3691C59.9908 86.3691 60.4385 86.8169 60.4385 87.3691Z'
          fill='#242631'
        />
        <path
          d='M66.4385 87.3691C66.4385 87.9214 65.9908 88.3691 65.4385 88.3691C64.8862 88.3691 64.4385 87.9214 64.4385 87.3691C64.4385 86.8169 64.8862 86.3691 65.4385 86.3691C65.9908 86.3691 66.4385 86.8169 66.4385 87.3691Z'
          fill='#242631'
        />
        <path
          d='M72.4385 87.3691C72.4385 87.9214 71.9908 88.3691 71.4385 88.3691C70.8862 88.3691 70.4385 87.9214 70.4385 87.3691C70.4385 86.8169 70.8862 86.3691 71.4385 86.3691C71.9908 86.3691 72.4385 86.8169 72.4385 87.3691Z'
          fill='#242631'
        />
        <path
          d='M78.4385 87.3691C78.4385 87.9214 77.9908 88.3691 77.4385 88.3691C76.8862 88.3691 76.4385 87.9214 76.4385 87.3691C76.4385 86.8169 76.8862 86.3691 77.4385 86.3691C77.9908 86.3691 78.4385 86.8169 78.4385 87.3691Z'
          fill='#242631'
        />
        <path
          d='M84.4385 87.3691C84.4385 87.9214 83.9908 88.3691 83.4385 88.3691C82.8862 88.3691 82.4385 87.9214 82.4385 87.3691C82.4385 86.8169 82.8862 86.3691 83.4385 86.3691C83.9908 86.3691 84.4385 86.8169 84.4385 87.3691Z'
          fill='#242631'
        />
        <path
          d='M90.4385 87.3691C90.4385 87.9214 89.9908 88.3691 89.4385 88.3691C88.8862 88.3691 88.4385 87.9214 88.4385 87.3691C88.4385 86.8169 88.8862 86.3691 89.4385 86.3691C89.9908 86.3691 90.4385 86.8169 90.4385 87.3691Z'
          fill='#242631'
        />
        <path
          d='M96.4385 87.3691C96.4385 87.9214 95.9908 88.3691 95.4385 88.3691C94.8862 88.3691 94.4385 87.9214 94.4385 87.3691C94.4385 86.8169 94.8862 86.3691 95.4385 86.3691C95.9908 86.3691 96.4385 86.8169 96.4385 87.3691Z'
          fill='#242631'
        />
        <path
          d='M102.438 87.3691C102.438 87.9214 101.991 88.3691 101.438 88.3691C100.886 88.3691 100.438 87.9214 100.438 87.3691C100.438 86.8169 100.886 86.3691 101.438 86.3691C101.991 86.3691 102.438 86.8169 102.438 87.3691Z'
          fill='#242631'
        />
        <path
          d='M108.438 87.3691C108.438 87.9214 107.991 88.3691 107.438 88.3691C106.886 88.3691 106.438 87.9214 106.438 87.3691C106.438 86.8169 106.886 86.3691 107.438 86.3691C107.991 86.3691 108.438 86.8169 108.438 87.3691Z'
          fill='#242631'
        />
        <path
          d='M114.438 87.3691C114.438 87.9214 113.991 88.3691 113.438 88.3691C112.886 88.3691 112.438 87.9214 112.438 87.3691C112.438 86.8169 112.886 86.3691 113.438 86.3691C113.991 86.3691 114.438 86.8169 114.438 87.3691Z'
          fill='#242631'
        />
        <path
          d='M120.438 87.3691C120.438 87.9214 119.991 88.3691 119.438 88.3691C118.886 88.3691 118.438 87.9214 118.438 87.3691C118.438 86.8169 118.886 86.3691 119.438 86.3691C119.991 86.3691 120.438 86.8169 120.438 87.3691Z'
          fill='#242631'
        />
        <path
          d='M126.438 87.3691C126.438 87.9214 125.991 88.3691 125.438 88.3691C124.886 88.3691 124.438 87.9214 124.438 87.3691C124.438 86.8169 124.886 86.3691 125.438 86.3691C125.991 86.3691 126.438 86.8169 126.438 87.3691Z'
          fill='#242631'
        />
        <circle cx='131.438' cy='87.3691' r='1' fill='#242631' />
        <circle cx='137.438' cy='87.3691' r='1' fill='#242631' />
        <circle cx='143.438' cy='87.3691' r='1' fill='#242631' />
        <circle cx='149.438' cy='87.3691' r='1' fill='#242631' />
        <circle cx='155.438' cy='87.3691' r='1' fill='#242631' />
        <circle cx='161.438' cy='87.3691' r='1' fill='#242631' />
        <circle cx='167.438' cy='87.3691' r='1' fill='#242631' />
        <circle cx='173.438' cy='87.3691' r='1' fill='#242631' />
        <circle cx='179.438' cy='87.3691' r='1' fill='#242631' />
        <circle cx='185.438' cy='87.3691' r='1' fill='#242631' />
        <circle cx='191.438' cy='87.3691' r='1' fill='#242631' />
        <circle cx='197.438' cy='87.3691' r='1' fill='#242631' />
        <circle cx='-0.561523' cy='111.195' r='1' fill='#242631' />
        <circle cx='5.43848' cy='111.195' r='1' fill='#242631' />
        <path
          d='M12.4385 111.195C12.4385 111.748 11.9908 112.195 11.4385 112.195C10.8862 112.195 10.4385 111.748 10.4385 111.195C10.4385 110.643 10.8862 110.195 11.4385 110.195C11.9908 110.195 12.4385 110.643 12.4385 111.195Z'
          fill='#242631'
        />
        <path
          d='M18.4385 111.195C18.4385 111.748 17.9908 112.195 17.4385 112.195C16.8862 112.195 16.4385 111.748 16.4385 111.195C16.4385 110.643 16.8862 110.195 17.4385 110.195C17.9908 110.195 18.4385 110.643 18.4385 111.195Z'
          fill='#242631'
        />
        <path
          d='M24.4385 111.195C24.4385 111.748 23.9908 112.195 23.4385 112.195C22.8862 112.195 22.4385 111.748 22.4385 111.195C22.4385 110.643 22.8862 110.195 23.4385 110.195C23.9908 110.195 24.4385 110.643 24.4385 111.195Z'
          fill='#242631'
        />
        <path
          d='M30.4385 111.195C30.4385 111.748 29.9908 112.195 29.4385 112.195C28.8862 112.195 28.4385 111.748 28.4385 111.195C28.4385 110.643 28.8862 110.195 29.4385 110.195C29.9908 110.195 30.4385 110.643 30.4385 111.195Z'
          fill='#242631'
        />
        <path
          d='M36.4385 111.195C36.4385 111.748 35.9908 112.195 35.4385 112.195C34.8862 112.195 34.4385 111.748 34.4385 111.195C34.4385 110.643 34.8862 110.195 35.4385 110.195C35.9908 110.195 36.4385 110.643 36.4385 111.195Z'
          fill='#242631'
        />
        <path
          d='M42.4385 111.195C42.4385 111.748 41.9908 112.195 41.4385 112.195C40.8862 112.195 40.4385 111.748 40.4385 111.195C40.4385 110.643 40.8862 110.195 41.4385 110.195C41.9908 110.195 42.4385 110.643 42.4385 111.195Z'
          fill='#242631'
        />
        <path
          d='M48.4385 111.195C48.4385 111.748 47.9908 112.195 47.4385 112.195C46.8862 112.195 46.4385 111.748 46.4385 111.195C46.4385 110.643 46.8862 110.195 47.4385 110.195C47.9908 110.195 48.4385 110.643 48.4385 111.195Z'
          fill='#242631'
        />
        <path
          d='M54.4385 111.195C54.4385 111.748 53.9908 112.195 53.4385 112.195C52.8862 112.195 52.4385 111.748 52.4385 111.195C52.4385 110.643 52.8862 110.195 53.4385 110.195C53.9908 110.195 54.4385 110.643 54.4385 111.195Z'
          fill='#242631'
        />
        <path
          d='M60.4385 111.195C60.4385 111.748 59.9908 112.195 59.4385 112.195C58.8862 112.195 58.4385 111.748 58.4385 111.195C58.4385 110.643 58.8862 110.195 59.4385 110.195C59.9908 110.195 60.4385 110.643 60.4385 111.195Z'
          fill='#242631'
        />
        <path
          d='M66.4385 111.195C66.4385 111.748 65.9908 112.195 65.4385 112.195C64.8862 112.195 64.4385 111.748 64.4385 111.195C64.4385 110.643 64.8862 110.195 65.4385 110.195C65.9908 110.195 66.4385 110.643 66.4385 111.195Z'
          fill='#242631'
        />
        <path
          d='M72.4385 111.195C72.4385 111.748 71.9908 112.195 71.4385 112.195C70.8862 112.195 70.4385 111.748 70.4385 111.195C70.4385 110.643 70.8862 110.195 71.4385 110.195C71.9908 110.195 72.4385 110.643 72.4385 111.195Z'
          fill='#242631'
        />
        <path
          d='M78.4385 111.195C78.4385 111.748 77.9908 112.195 77.4385 112.195C76.8862 112.195 76.4385 111.748 76.4385 111.195C76.4385 110.643 76.8862 110.195 77.4385 110.195C77.9908 110.195 78.4385 110.643 78.4385 111.195Z'
          fill='#242631'
        />
        <path
          d='M84.4385 111.195C84.4385 111.748 83.9908 112.195 83.4385 112.195C82.8862 112.195 82.4385 111.748 82.4385 111.195C82.4385 110.643 82.8862 110.195 83.4385 110.195C83.9908 110.195 84.4385 110.643 84.4385 111.195Z'
          fill='#242631'
        />
        <path
          d='M90.4385 111.195C90.4385 111.748 89.9908 112.195 89.4385 112.195C88.8862 112.195 88.4385 111.748 88.4385 111.195C88.4385 110.643 88.8862 110.195 89.4385 110.195C89.9908 110.195 90.4385 110.643 90.4385 111.195Z'
          fill='#242631'
        />
        <path
          d='M96.4385 111.195C96.4385 111.748 95.9908 112.195 95.4385 112.195C94.8862 112.195 94.4385 111.748 94.4385 111.195C94.4385 110.643 94.8862 110.195 95.4385 110.195C95.9908 110.195 96.4385 110.643 96.4385 111.195Z'
          fill='#242631'
        />
        <path
          d='M102.438 111.195C102.438 111.748 101.991 112.195 101.438 112.195C100.886 112.195 100.438 111.748 100.438 111.195C100.438 110.643 100.886 110.195 101.438 110.195C101.991 110.195 102.438 110.643 102.438 111.195Z'
          fill='#242631'
        />
        <path
          d='M108.438 111.195C108.438 111.748 107.991 112.195 107.438 112.195C106.886 112.195 106.438 111.748 106.438 111.195C106.438 110.643 106.886 110.195 107.438 110.195C107.991 110.195 108.438 110.643 108.438 111.195Z'
          fill='#242631'
        />
        <path
          d='M114.438 111.195C114.438 111.748 113.991 112.195 113.438 112.195C112.886 112.195 112.438 111.748 112.438 111.195C112.438 110.643 112.886 110.195 113.438 110.195C113.991 110.195 114.438 110.643 114.438 111.195Z'
          fill='#242631'
        />
        <path
          d='M120.438 111.195C120.438 111.748 119.991 112.195 119.438 112.195C118.886 112.195 118.438 111.748 118.438 111.195C118.438 110.643 118.886 110.195 119.438 110.195C119.991 110.195 120.438 110.643 120.438 111.195Z'
          fill='#242631'
        />
        <path
          d='M126.438 111.195C126.438 111.748 125.991 112.195 125.438 112.195C124.886 112.195 124.438 111.748 124.438 111.195C124.438 110.643 124.886 110.195 125.438 110.195C125.991 110.195 126.438 110.643 126.438 111.195Z'
          fill='#242631'
        />
        <circle cx='131.438' cy='111.195' r='1' fill='#242631' />
        <circle cx='137.438' cy='111.195' r='1' fill='#242631' />
        <circle cx='143.438' cy='111.195' r='1' fill='#242631' />
        <circle cx='149.438' cy='111.195' r='1' fill='#242631' />
        <circle cx='155.438' cy='111.195' r='1' fill='#242631' />
        <circle cx='161.438' cy='111.195' r='1' fill='#242631' />
        <circle cx='167.438' cy='111.195' r='1' fill='#242631' />
        <circle cx='173.438' cy='111.195' r='1' fill='#242631' />
        <circle cx='179.438' cy='111.195' r='1' fill='#242631' />
        <circle cx='185.438' cy='111.195' r='1' fill='#242631' />
        <circle cx='191.438' cy='111.195' r='1' fill='#242631' />
        <circle cx='197.438' cy='111.195' r='1' fill='#242631' />
        <circle cx='2.3877' cy='9.93555' r='1' fill='#242631' />
        <circle cx='8.3877' cy='9.93555' r='1' fill='#242631' />
        <path
          d='M15.3877 9.93555C15.3877 10.4878 14.94 10.9355 14.3877 10.9355C13.8354 10.9355 13.3877 10.4878 13.3877 9.93555C13.3877 9.38326 13.8354 8.93555 14.3877 8.93555C14.94 8.93555 15.3877 9.38326 15.3877 9.93555Z'
          fill='#242631'
        />
        <path
          d='M21.3877 9.93555C21.3877 10.4878 20.94 10.9355 20.3877 10.9355C19.8354 10.9355 19.3877 10.4878 19.3877 9.93555C19.3877 9.38326 19.8354 8.93555 20.3877 8.93555C20.94 8.93555 21.3877 9.38326 21.3877 9.93555Z'
          fill='#242631'
        />
        <path
          d='M27.3877 9.93555C27.3877 10.4878 26.94 10.9355 26.3877 10.9355C25.8354 10.9355 25.3877 10.4878 25.3877 9.93555C25.3877 9.38326 25.8354 8.93555 26.3877 8.93555C26.94 8.93555 27.3877 9.38326 27.3877 9.93555Z'
          fill='#242631'
        />
        <path
          d='M33.3877 9.93555C33.3877 10.4878 32.94 10.9355 32.3877 10.9355C31.8354 10.9355 31.3877 10.4878 31.3877 9.93555C31.3877 9.38326 31.8354 8.93555 32.3877 8.93555C32.94 8.93555 33.3877 9.38326 33.3877 9.93555Z'
          fill='#242631'
        />
        <path
          d='M39.3877 9.93555C39.3877 10.4878 38.94 10.9355 38.3877 10.9355C37.8354 10.9355 37.3877 10.4878 37.3877 9.93555C37.3877 9.38326 37.8354 8.93555 38.3877 8.93555C38.94 8.93555 39.3877 9.38326 39.3877 9.93555Z'
          fill='#242631'
        />
        <path
          d='M45.3877 9.93555C45.3877 10.4878 44.94 10.9355 44.3877 10.9355C43.8354 10.9355 43.3877 10.4878 43.3877 9.93555C43.3877 9.38326 43.8354 8.93555 44.3877 8.93555C44.94 8.93555 45.3877 9.38326 45.3877 9.93555Z'
          fill='#242631'
        />
        <path
          d='M51.3877 9.93555C51.3877 10.4878 50.94 10.9355 50.3877 10.9355C49.8354 10.9355 49.3877 10.4878 49.3877 9.93555C49.3877 9.38326 49.8354 8.93555 50.3877 8.93555C50.94 8.93555 51.3877 9.38326 51.3877 9.93555Z'
          fill='#242631'
        />
        <path
          d='M57.3877 9.93555C57.3877 10.4878 56.94 10.9355 56.3877 10.9355C55.8354 10.9355 55.3877 10.4878 55.3877 9.93555C55.3877 9.38326 55.8354 8.93555 56.3877 8.93555C56.94 8.93555 57.3877 9.38326 57.3877 9.93555Z'
          fill='#242631'
        />
        <path
          d='M63.3877 9.93555C63.3877 10.4878 62.94 10.9355 62.3877 10.9355C61.8354 10.9355 61.3877 10.4878 61.3877 9.93555C61.3877 9.38326 61.8354 8.93555 62.3877 8.93555C62.94 8.93555 63.3877 9.38326 63.3877 9.93555Z'
          fill='#242631'
        />
        <path
          d='M69.3877 9.93555C69.3877 10.4878 68.94 10.9355 68.3877 10.9355C67.8354 10.9355 67.3877 10.4878 67.3877 9.93555C67.3877 9.38326 67.8354 8.93555 68.3877 8.93555C68.94 8.93555 69.3877 9.38326 69.3877 9.93555Z'
          fill='#242631'
        />
        <path
          d='M75.3877 9.93555C75.3877 10.4878 74.94 10.9355 74.3877 10.9355C73.8354 10.9355 73.3877 10.4878 73.3877 9.93555C73.3877 9.38326 73.8354 8.93555 74.3877 8.93555C74.94 8.93555 75.3877 9.38326 75.3877 9.93555Z'
          fill='#242631'
        />
        <path
          d='M81.3877 9.93555C81.3877 10.4878 80.94 10.9355 80.3877 10.9355C79.8354 10.9355 79.3877 10.4878 79.3877 9.93555C79.3877 9.38326 79.8354 8.93555 80.3877 8.93555C80.94 8.93555 81.3877 9.38326 81.3877 9.93555Z'
          fill='#242631'
        />
        <path
          d='M87.3877 9.93555C87.3877 10.4878 86.94 10.9355 86.3877 10.9355C85.8354 10.9355 85.3877 10.4878 85.3877 9.93555C85.3877 9.38326 85.8354 8.93555 86.3877 8.93555C86.94 8.93555 87.3877 9.38326 87.3877 9.93555Z'
          fill='#242631'
        />
        <path
          d='M93.3877 9.93555C93.3877 10.4878 92.94 10.9355 92.3877 10.9355C91.8354 10.9355 91.3877 10.4878 91.3877 9.93555C91.3877 9.38326 91.8354 8.93555 92.3877 8.93555C92.94 8.93555 93.3877 9.38326 93.3877 9.93555Z'
          fill='#242631'
        />
        <path
          d='M99.3877 9.93555C99.3877 10.4878 98.94 10.9355 98.3877 10.9355C97.8354 10.9355 97.3877 10.4878 97.3877 9.93555C97.3877 9.38326 97.8354 8.93555 98.3877 8.93555C98.94 8.93555 99.3877 9.38326 99.3877 9.93555Z'
          fill='#242631'
        />
        <path
          d='M105.388 9.93555C105.388 10.4878 104.94 10.9355 104.388 10.9355C103.835 10.9355 103.388 10.4878 103.388 9.93555C103.388 9.38326 103.835 8.93555 104.388 8.93555C104.94 8.93555 105.388 9.38326 105.388 9.93555Z'
          fill='#242631'
        />
        <path
          d='M111.388 9.93555C111.388 10.4878 110.94 10.9355 110.388 10.9355C109.835 10.9355 109.388 10.4878 109.388 9.93555C109.388 9.38326 109.835 8.93555 110.388 8.93555C110.94 8.93555 111.388 9.38326 111.388 9.93555Z'
          fill='#242631'
        />
        <path
          d='M117.388 9.93555C117.388 10.4878 116.94 10.9355 116.388 10.9355C115.835 10.9355 115.388 10.4878 115.388 9.93555C115.388 9.38326 115.835 8.93555 116.388 8.93555C116.94 8.93555 117.388 9.38326 117.388 9.93555Z'
          fill='#242631'
        />
        <path
          d='M123.388 9.93555C123.388 10.4878 122.94 10.9355 122.388 10.9355C121.835 10.9355 121.388 10.4878 121.388 9.93555C121.388 9.38326 121.835 8.93555 122.388 8.93555C122.94 8.93555 123.388 9.38326 123.388 9.93555Z'
          fill='#242631'
        />
        <path
          d='M129.388 9.93555C129.388 10.4878 128.94 10.9355 128.388 10.9355C127.835 10.9355 127.388 10.4878 127.388 9.93555C127.388 9.38326 127.835 8.93555 128.388 8.93555C128.94 8.93555 129.388 9.38326 129.388 9.93555Z'
          fill='#242631'
        />
        <circle cx='134.388' cy='9.93555' r='1' fill='#242631' />
        <circle cx='140.388' cy='9.93555' r='1' fill='#242631' />
        <circle cx='146.388' cy='9.93555' r='1' fill='#242631' />
        <circle cx='152.388' cy='9.93555' r='1' fill='#242631' />
        <circle cx='158.388' cy='9.93555' r='1' fill='#242631' />
        <circle cx='164.388' cy='9.93555' r='1' fill='#242631' />
        <circle cx='170.388' cy='9.93555' r='1' fill='#242631' />
        <circle cx='176.388' cy='9.93555' r='1' fill='#242631' />
        <circle cx='182.388' cy='9.93555' r='1' fill='#242631' />
        <circle cx='188.388' cy='9.93555' r='1' fill='#242631' />
        <circle cx='194.388' cy='9.93555' r='1' fill='#242631' />
        <circle cx='200.388' cy='9.93555' r='1' fill='#242631' />
        <circle cx='2.3877' cy='129.064' r='1' fill='#242631' />
        <circle cx='8.3877' cy='129.064' r='1' fill='#242631' />
        <path
          d='M15.3877 129.064C15.3877 129.617 14.94 130.064 14.3877 130.064C13.8354 130.064 13.3877 129.617 13.3877 129.064C13.3877 128.512 13.8354 128.064 14.3877 128.064C14.94 128.064 15.3877 128.512 15.3877 129.064Z'
          fill='#242631'
        />
        <path
          d='M21.3877 129.064C21.3877 129.617 20.94 130.064 20.3877 130.064C19.8354 130.064 19.3877 129.617 19.3877 129.064C19.3877 128.512 19.8354 128.064 20.3877 128.064C20.94 128.064 21.3877 128.512 21.3877 129.064Z'
          fill='#242631'
        />
        <path
          d='M27.3877 129.064C27.3877 129.617 26.94 130.064 26.3877 130.064C25.8354 130.064 25.3877 129.617 25.3877 129.064C25.3877 128.512 25.8354 128.064 26.3877 128.064C26.94 128.064 27.3877 128.512 27.3877 129.064Z'
          fill='#242631'
        />
        <path
          d='M33.3877 129.064C33.3877 129.617 32.94 130.064 32.3877 130.064C31.8354 130.064 31.3877 129.617 31.3877 129.064C31.3877 128.512 31.8354 128.064 32.3877 128.064C32.94 128.064 33.3877 128.512 33.3877 129.064Z'
          fill='#242631'
        />
        <path
          d='M39.3877 129.064C39.3877 129.617 38.94 130.064 38.3877 130.064C37.8354 130.064 37.3877 129.617 37.3877 129.064C37.3877 128.512 37.8354 128.064 38.3877 128.064C38.94 128.064 39.3877 128.512 39.3877 129.064Z'
          fill='#242631'
        />
        <path
          d='M45.3877 129.064C45.3877 129.617 44.94 130.064 44.3877 130.064C43.8354 130.064 43.3877 129.617 43.3877 129.064C43.3877 128.512 43.8354 128.064 44.3877 128.064C44.94 128.064 45.3877 128.512 45.3877 129.064Z'
          fill='#242631'
        />
        <path
          d='M51.3877 129.064C51.3877 129.617 50.94 130.064 50.3877 130.064C49.8354 130.064 49.3877 129.617 49.3877 129.064C49.3877 128.512 49.8354 128.064 50.3877 128.064C50.94 128.064 51.3877 128.512 51.3877 129.064Z'
          fill='#242631'
        />
        <path
          d='M57.3877 129.064C57.3877 129.617 56.94 130.064 56.3877 130.064C55.8354 130.064 55.3877 129.617 55.3877 129.064C55.3877 128.512 55.8354 128.064 56.3877 128.064C56.94 128.064 57.3877 128.512 57.3877 129.064Z'
          fill='#242631'
        />
        <path
          d='M63.3877 129.064C63.3877 129.617 62.94 130.064 62.3877 130.064C61.8354 130.064 61.3877 129.617 61.3877 129.064C61.3877 128.512 61.8354 128.064 62.3877 128.064C62.94 128.064 63.3877 128.512 63.3877 129.064Z'
          fill='#242631'
        />
        <path
          d='M69.3877 129.064C69.3877 129.617 68.94 130.064 68.3877 130.064C67.8354 130.064 67.3877 129.617 67.3877 129.064C67.3877 128.512 67.8354 128.064 68.3877 128.064C68.94 128.064 69.3877 128.512 69.3877 129.064Z'
          fill='#242631'
        />
        <path
          d='M75.3877 129.064C75.3877 129.617 74.94 130.064 74.3877 130.064C73.8354 130.064 73.3877 129.617 73.3877 129.064C73.3877 128.512 73.8354 128.064 74.3877 128.064C74.94 128.064 75.3877 128.512 75.3877 129.064Z'
          fill='#242631'
        />
        <path
          d='M81.3877 129.064C81.3877 129.617 80.94 130.064 80.3877 130.064C79.8354 130.064 79.3877 129.617 79.3877 129.064C79.3877 128.512 79.8354 128.064 80.3877 128.064C80.94 128.064 81.3877 128.512 81.3877 129.064Z'
          fill='#242631'
        />
        <path
          d='M87.3877 129.064C87.3877 129.617 86.94 130.064 86.3877 130.064C85.8354 130.064 85.3877 129.617 85.3877 129.064C85.3877 128.512 85.8354 128.064 86.3877 128.064C86.94 128.064 87.3877 128.512 87.3877 129.064Z'
          fill='#242631'
        />
        <path
          d='M93.3877 129.064C93.3877 129.617 92.94 130.064 92.3877 130.064C91.8354 130.064 91.3877 129.617 91.3877 129.064C91.3877 128.512 91.8354 128.064 92.3877 128.064C92.94 128.064 93.3877 128.512 93.3877 129.064Z'
          fill='#242631'
        />
        <path
          d='M99.3877 129.064C99.3877 129.617 98.94 130.064 98.3877 130.064C97.8354 130.064 97.3877 129.617 97.3877 129.064C97.3877 128.512 97.8354 128.064 98.3877 128.064C98.94 128.064 99.3877 128.512 99.3877 129.064Z'
          fill='#242631'
        />
        <path
          d='M105.388 129.064C105.388 129.617 104.94 130.064 104.388 130.064C103.835 130.064 103.388 129.617 103.388 129.064C103.388 128.512 103.835 128.064 104.388 128.064C104.94 128.064 105.388 128.512 105.388 129.064Z'
          fill='#242631'
        />
        <path
          d='M111.388 129.064C111.388 129.617 110.94 130.064 110.388 130.064C109.835 130.064 109.388 129.617 109.388 129.064C109.388 128.512 109.835 128.064 110.388 128.064C110.94 128.064 111.388 128.512 111.388 129.064Z'
          fill='#242631'
        />
        <path
          d='M117.388 129.064C117.388 129.617 116.94 130.064 116.388 130.064C115.835 130.064 115.388 129.617 115.388 129.064C115.388 128.512 115.835 128.064 116.388 128.064C116.94 128.064 117.388 128.512 117.388 129.064Z'
          fill='#242631'
        />
        <path
          d='M123.388 129.064C123.388 129.617 122.94 130.064 122.388 130.064C121.835 130.064 121.388 129.617 121.388 129.064C121.388 128.512 121.835 128.064 122.388 128.064C122.94 128.064 123.388 128.512 123.388 129.064Z'
          fill='#242631'
        />
        <path
          d='M129.388 129.064C129.388 129.617 128.94 130.064 128.388 130.064C127.835 130.064 127.388 129.617 127.388 129.064C127.388 128.512 127.835 128.064 128.388 128.064C128.94 128.064 129.388 128.512 129.388 129.064Z'
          fill='#242631'
        />
        <circle cx='134.388' cy='129.064' r='1' fill='#242631' />
        <circle cx='140.388' cy='129.064' r='1' fill='#242631' />
        <circle cx='146.388' cy='129.064' r='1' fill='#242631' />
        <circle cx='152.388' cy='129.064' r='1' fill='#242631' />
        <circle cx='158.388' cy='129.064' r='1' fill='#242631' />
        <circle cx='164.388' cy='129.064' r='1' fill='#242631' />
        <circle cx='170.388' cy='129.064' r='1' fill='#242631' />
        <circle cx='176.388' cy='129.064' r='1' fill='#242631' />
        <circle cx='182.388' cy='129.064' r='1' fill='#242631' />
        <circle cx='188.388' cy='129.064' r='1' fill='#242631' />
        <circle cx='194.388' cy='129.064' r='1' fill='#242631' />
        <circle cx='200.388' cy='129.064' r='1' fill='#242631' />
        <circle cx='2.3877' cy='33.7617' r='1' fill='#242631' />
        <circle cx='8.3877' cy='33.7617' r='1' fill='#242631' />
        <path
          d='M15.3877 33.7617C15.3877 34.314 14.94 34.7617 14.3877 34.7617C13.8354 34.7617 13.3877 34.314 13.3877 33.7617C13.3877 33.2094 13.8354 32.7617 14.3877 32.7617C14.94 32.7617 15.3877 33.2094 15.3877 33.7617Z'
          fill='#242631'
        />
        <path
          d='M21.3877 33.7617C21.3877 34.314 20.94 34.7617 20.3877 34.7617C19.8354 34.7617 19.3877 34.314 19.3877 33.7617C19.3877 33.2094 19.8354 32.7617 20.3877 32.7617C20.94 32.7617 21.3877 33.2094 21.3877 33.7617Z'
          fill='#242631'
        />
        <path
          d='M27.3877 33.7617C27.3877 34.314 26.94 34.7617 26.3877 34.7617C25.8354 34.7617 25.3877 34.314 25.3877 33.7617C25.3877 33.2094 25.8354 32.7617 26.3877 32.7617C26.94 32.7617 27.3877 33.2094 27.3877 33.7617Z'
          fill='#242631'
        />
        <path
          d='M33.3877 33.7617C33.3877 34.314 32.94 34.7617 32.3877 34.7617C31.8354 34.7617 31.3877 34.314 31.3877 33.7617C31.3877 33.2094 31.8354 32.7617 32.3877 32.7617C32.94 32.7617 33.3877 33.2094 33.3877 33.7617Z'
          fill='#242631'
        />
        <path
          d='M39.3877 33.7617C39.3877 34.314 38.94 34.7617 38.3877 34.7617C37.8354 34.7617 37.3877 34.314 37.3877 33.7617C37.3877 33.2094 37.8354 32.7617 38.3877 32.7617C38.94 32.7617 39.3877 33.2094 39.3877 33.7617Z'
          fill='#242631'
        />
        <path
          d='M45.3877 33.7617C45.3877 34.314 44.94 34.7617 44.3877 34.7617C43.8354 34.7617 43.3877 34.314 43.3877 33.7617C43.3877 33.2094 43.8354 32.7617 44.3877 32.7617C44.94 32.7617 45.3877 33.2094 45.3877 33.7617Z'
          fill='#242631'
        />
        <path
          d='M51.3877 33.7617C51.3877 34.314 50.94 34.7617 50.3877 34.7617C49.8354 34.7617 49.3877 34.314 49.3877 33.7617C49.3877 33.2094 49.8354 32.7617 50.3877 32.7617C50.94 32.7617 51.3877 33.2094 51.3877 33.7617Z'
          fill='#242631'
        />
        <path
          d='M57.3877 33.7617C57.3877 34.314 56.94 34.7617 56.3877 34.7617C55.8354 34.7617 55.3877 34.314 55.3877 33.7617C55.3877 33.2094 55.8354 32.7617 56.3877 32.7617C56.94 32.7617 57.3877 33.2094 57.3877 33.7617Z'
          fill='#242631'
        />
        <path
          d='M63.3877 33.7617C63.3877 34.314 62.94 34.7617 62.3877 34.7617C61.8354 34.7617 61.3877 34.314 61.3877 33.7617C61.3877 33.2094 61.8354 32.7617 62.3877 32.7617C62.94 32.7617 63.3877 33.2094 63.3877 33.7617Z'
          fill='#242631'
        />
        <path
          d='M69.3877 33.7617C69.3877 34.314 68.94 34.7617 68.3877 34.7617C67.8354 34.7617 67.3877 34.314 67.3877 33.7617C67.3877 33.2094 67.8354 32.7617 68.3877 32.7617C68.94 32.7617 69.3877 33.2094 69.3877 33.7617Z'
          fill='#242631'
        />
        <path
          d='M75.3877 33.7617C75.3877 34.314 74.94 34.7617 74.3877 34.7617C73.8354 34.7617 73.3877 34.314 73.3877 33.7617C73.3877 33.2094 73.8354 32.7617 74.3877 32.7617C74.94 32.7617 75.3877 33.2094 75.3877 33.7617Z'
          fill='#242631'
        />
        <path
          d='M81.3877 33.7617C81.3877 34.314 80.94 34.7617 80.3877 34.7617C79.8354 34.7617 79.3877 34.314 79.3877 33.7617C79.3877 33.2094 79.8354 32.7617 80.3877 32.7617C80.94 32.7617 81.3877 33.2094 81.3877 33.7617Z'
          fill='#242631'
        />
        <path
          d='M87.3877 33.7617C87.3877 34.314 86.94 34.7617 86.3877 34.7617C85.8354 34.7617 85.3877 34.314 85.3877 33.7617C85.3877 33.2094 85.8354 32.7617 86.3877 32.7617C86.94 32.7617 87.3877 33.2094 87.3877 33.7617Z'
          fill='#242631'
        />
        <path
          d='M93.3877 33.7617C93.3877 34.314 92.94 34.7617 92.3877 34.7617C91.8354 34.7617 91.3877 34.314 91.3877 33.7617C91.3877 33.2094 91.8354 32.7617 92.3877 32.7617C92.94 32.7617 93.3877 33.2094 93.3877 33.7617Z'
          fill='#242631'
        />
        <path
          d='M99.3877 33.7617C99.3877 34.314 98.94 34.7617 98.3877 34.7617C97.8354 34.7617 97.3877 34.314 97.3877 33.7617C97.3877 33.2094 97.8354 32.7617 98.3877 32.7617C98.94 32.7617 99.3877 33.2094 99.3877 33.7617Z'
          fill='#242631'
        />
        <path
          d='M105.388 33.7617C105.388 34.314 104.94 34.7617 104.388 34.7617C103.835 34.7617 103.388 34.314 103.388 33.7617C103.388 33.2094 103.835 32.7617 104.388 32.7617C104.94 32.7617 105.388 33.2094 105.388 33.7617Z'
          fill='#242631'
        />
        <path
          d='M111.388 33.7617C111.388 34.314 110.94 34.7617 110.388 34.7617C109.835 34.7617 109.388 34.314 109.388 33.7617C109.388 33.2094 109.835 32.7617 110.388 32.7617C110.94 32.7617 111.388 33.2094 111.388 33.7617Z'
          fill='#242631'
        />
        <path
          d='M117.388 33.7617C117.388 34.314 116.94 34.7617 116.388 34.7617C115.835 34.7617 115.388 34.314 115.388 33.7617C115.388 33.2094 115.835 32.7617 116.388 32.7617C116.94 32.7617 117.388 33.2094 117.388 33.7617Z'
          fill='#242631'
        />
        <path
          d='M123.388 33.7617C123.388 34.314 122.94 34.7617 122.388 34.7617C121.835 34.7617 121.388 34.314 121.388 33.7617C121.388 33.2094 121.835 32.7617 122.388 32.7617C122.94 32.7617 123.388 33.2094 123.388 33.7617Z'
          fill='#242631'
        />
        <path
          d='M129.388 33.7617C129.388 34.314 128.94 34.7617 128.388 34.7617C127.835 34.7617 127.388 34.314 127.388 33.7617C127.388 33.2094 127.835 32.7617 128.388 32.7617C128.94 32.7617 129.388 33.2094 129.388 33.7617Z'
          fill='#242631'
        />
        <circle cx='134.388' cy='33.7617' r='1' fill='#242631' />
        <circle cx='140.388' cy='33.7617' r='1' fill='#242631' />
        <circle cx='146.388' cy='33.7617' r='1' fill='#242631' />
        <circle cx='152.388' cy='33.7617' r='1' fill='#242631' />
        <circle cx='158.388' cy='33.7617' r='1' fill='#242631' />
        <circle cx='164.388' cy='33.7617' r='1' fill='#242631' />
        <circle cx='170.388' cy='33.7617' r='1' fill='#242631' />
        <circle cx='176.388' cy='33.7617' r='1' fill='#242631' />
        <circle cx='182.388' cy='33.7617' r='1' fill='#242631' />
        <circle cx='188.388' cy='33.7617' r='1' fill='#242631' />
        <circle cx='194.388' cy='33.7617' r='1' fill='#242631' />
        <circle cx='200.388' cy='33.7617' r='1' fill='#242631' />
        <circle cx='2.3877' cy='152.891' r='1' fill='#242631' />
        <circle cx='8.3877' cy='152.891' r='1' fill='#242631' />
        <path
          d='M15.3877 152.891C15.3877 153.443 14.94 153.891 14.3877 153.891C13.8354 153.891 13.3877 153.443 13.3877 152.891C13.3877 152.338 13.8354 151.891 14.3877 151.891C14.94 151.891 15.3877 152.338 15.3877 152.891Z'
          fill='#242631'
        />
        <path
          d='M21.3877 152.891C21.3877 153.443 20.94 153.891 20.3877 153.891C19.8354 153.891 19.3877 153.443 19.3877 152.891C19.3877 152.338 19.8354 151.891 20.3877 151.891C20.94 151.891 21.3877 152.338 21.3877 152.891Z'
          fill='#242631'
        />
        <path
          d='M27.3877 152.891C27.3877 153.443 26.94 153.891 26.3877 153.891C25.8354 153.891 25.3877 153.443 25.3877 152.891C25.3877 152.338 25.8354 151.891 26.3877 151.891C26.94 151.891 27.3877 152.338 27.3877 152.891Z'
          fill='#242631'
        />
        <path
          d='M33.3877 152.891C33.3877 153.443 32.94 153.891 32.3877 153.891C31.8354 153.891 31.3877 153.443 31.3877 152.891C31.3877 152.338 31.8354 151.891 32.3877 151.891C32.94 151.891 33.3877 152.338 33.3877 152.891Z'
          fill='#242631'
        />
        <path
          d='M39.3877 152.891C39.3877 153.443 38.94 153.891 38.3877 153.891C37.8354 153.891 37.3877 153.443 37.3877 152.891C37.3877 152.338 37.8354 151.891 38.3877 151.891C38.94 151.891 39.3877 152.338 39.3877 152.891Z'
          fill='#242631'
        />
        <path
          d='M45.3877 152.891C45.3877 153.443 44.94 153.891 44.3877 153.891C43.8354 153.891 43.3877 153.443 43.3877 152.891C43.3877 152.338 43.8354 151.891 44.3877 151.891C44.94 151.891 45.3877 152.338 45.3877 152.891Z'
          fill='#242631'
        />
        <path
          d='M51.3877 152.891C51.3877 153.443 50.94 153.891 50.3877 153.891C49.8354 153.891 49.3877 153.443 49.3877 152.891C49.3877 152.338 49.8354 151.891 50.3877 151.891C50.94 151.891 51.3877 152.338 51.3877 152.891Z'
          fill='#242631'
        />
        <path
          d='M57.3877 152.891C57.3877 153.443 56.94 153.891 56.3877 153.891C55.8354 153.891 55.3877 153.443 55.3877 152.891C55.3877 152.338 55.8354 151.891 56.3877 151.891C56.94 151.891 57.3877 152.338 57.3877 152.891Z'
          fill='#242631'
        />
        <path
          d='M63.3877 152.891C63.3877 153.443 62.94 153.891 62.3877 153.891C61.8354 153.891 61.3877 153.443 61.3877 152.891C61.3877 152.338 61.8354 151.891 62.3877 151.891C62.94 151.891 63.3877 152.338 63.3877 152.891Z'
          fill='#242631'
        />
        <path
          d='M69.3877 152.891C69.3877 153.443 68.94 153.891 68.3877 153.891C67.8354 153.891 67.3877 153.443 67.3877 152.891C67.3877 152.338 67.8354 151.891 68.3877 151.891C68.94 151.891 69.3877 152.338 69.3877 152.891Z'
          fill='#242631'
        />
        <path
          d='M75.3877 152.891C75.3877 153.443 74.94 153.891 74.3877 153.891C73.8354 153.891 73.3877 153.443 73.3877 152.891C73.3877 152.338 73.8354 151.891 74.3877 151.891C74.94 151.891 75.3877 152.338 75.3877 152.891Z'
          fill='#242631'
        />
        <path
          d='M81.3877 152.891C81.3877 153.443 80.94 153.891 80.3877 153.891C79.8354 153.891 79.3877 153.443 79.3877 152.891C79.3877 152.338 79.8354 151.891 80.3877 151.891C80.94 151.891 81.3877 152.338 81.3877 152.891Z'
          fill='#242631'
        />
        <path
          d='M87.3877 152.891C87.3877 153.443 86.94 153.891 86.3877 153.891C85.8354 153.891 85.3877 153.443 85.3877 152.891C85.3877 152.338 85.8354 151.891 86.3877 151.891C86.94 151.891 87.3877 152.338 87.3877 152.891Z'
          fill='#242631'
        />
        <path
          d='M93.3877 152.891C93.3877 153.443 92.94 153.891 92.3877 153.891C91.8354 153.891 91.3877 153.443 91.3877 152.891C91.3877 152.338 91.8354 151.891 92.3877 151.891C92.94 151.891 93.3877 152.338 93.3877 152.891Z'
          fill='#242631'
        />
        <path
          d='M99.3877 152.891C99.3877 153.443 98.94 153.891 98.3877 153.891C97.8354 153.891 97.3877 153.443 97.3877 152.891C97.3877 152.338 97.8354 151.891 98.3877 151.891C98.94 151.891 99.3877 152.338 99.3877 152.891Z'
          fill='#242631'
        />
        <path
          d='M105.388 152.891C105.388 153.443 104.94 153.891 104.388 153.891C103.835 153.891 103.388 153.443 103.388 152.891C103.388 152.338 103.835 151.891 104.388 151.891C104.94 151.891 105.388 152.338 105.388 152.891Z'
          fill='#242631'
        />
        <path
          d='M111.388 152.891C111.388 153.443 110.94 153.891 110.388 153.891C109.835 153.891 109.388 153.443 109.388 152.891C109.388 152.338 109.835 151.891 110.388 151.891C110.94 151.891 111.388 152.338 111.388 152.891Z'
          fill='#242631'
        />
        <path
          d='M117.388 152.891C117.388 153.443 116.94 153.891 116.388 153.891C115.835 153.891 115.388 153.443 115.388 152.891C115.388 152.338 115.835 151.891 116.388 151.891C116.94 151.891 117.388 152.338 117.388 152.891Z'
          fill='#242631'
        />
        <path
          d='M123.388 152.891C123.388 153.443 122.94 153.891 122.388 153.891C121.835 153.891 121.388 153.443 121.388 152.891C121.388 152.338 121.835 151.891 122.388 151.891C122.94 151.891 123.388 152.338 123.388 152.891Z'
          fill='#242631'
        />
        <path
          d='M129.388 152.891C129.388 153.443 128.94 153.891 128.388 153.891C127.835 153.891 127.388 153.443 127.388 152.891C127.388 152.338 127.835 151.891 128.388 151.891C128.94 151.891 129.388 152.338 129.388 152.891Z'
          fill='#242631'
        />
        <circle cx='134.388' cy='152.891' r='1' fill='#242631' />
        <circle cx='140.388' cy='152.891' r='1' fill='#242631' />
        <circle cx='146.388' cy='152.891' r='1' fill='#242631' />
        <circle cx='152.388' cy='152.891' r='1' fill='#242631' />
        <circle cx='158.388' cy='152.891' r='1' fill='#242631' />
        <circle cx='164.388' cy='152.891' r='1' fill='#242631' />
        <circle cx='170.388' cy='152.891' r='1' fill='#242631' />
        <circle cx='176.388' cy='152.891' r='1' fill='#242631' />
        <circle cx='182.388' cy='152.891' r='1' fill='#242631' />
        <circle cx='188.388' cy='152.891' r='1' fill='#242631' />
        <circle cx='194.388' cy='152.891' r='1' fill='#242631' />
        <circle cx='200.388' cy='152.891' r='1' fill='#242631' />
        <circle cx='2.3877' cy='57.5859' r='1' fill='#242631' />
        <circle cx='8.3877' cy='57.5859' r='1' fill='#242631' />
        <path
          d='M15.3877 57.5859C15.3877 58.1382 14.94 58.5859 14.3877 58.5859C13.8354 58.5859 13.3877 58.1382 13.3877 57.5859C13.3877 57.0337 13.8354 56.5859 14.3877 56.5859C14.94 56.5859 15.3877 57.0337 15.3877 57.5859Z'
          fill='#242631'
        />
        <path
          d='M21.3877 57.5859C21.3877 58.1382 20.94 58.5859 20.3877 58.5859C19.8354 58.5859 19.3877 58.1382 19.3877 57.5859C19.3877 57.0337 19.8354 56.5859 20.3877 56.5859C20.94 56.5859 21.3877 57.0337 21.3877 57.5859Z'
          fill='#242631'
        />
        <path
          d='M27.3877 57.5859C27.3877 58.1382 26.94 58.5859 26.3877 58.5859C25.8354 58.5859 25.3877 58.1382 25.3877 57.5859C25.3877 57.0337 25.8354 56.5859 26.3877 56.5859C26.94 56.5859 27.3877 57.0337 27.3877 57.5859Z'
          fill='#242631'
        />
        <path
          d='M33.3877 57.5859C33.3877 58.1382 32.94 58.5859 32.3877 58.5859C31.8354 58.5859 31.3877 58.1382 31.3877 57.5859C31.3877 57.0337 31.8354 56.5859 32.3877 56.5859C32.94 56.5859 33.3877 57.0337 33.3877 57.5859Z'
          fill='#242631'
        />
        <path
          d='M39.3877 57.5859C39.3877 58.1382 38.94 58.5859 38.3877 58.5859C37.8354 58.5859 37.3877 58.1382 37.3877 57.5859C37.3877 57.0337 37.8354 56.5859 38.3877 56.5859C38.94 56.5859 39.3877 57.0337 39.3877 57.5859Z'
          fill='#242631'
        />
        <path
          d='M45.3877 57.5859C45.3877 58.1382 44.94 58.5859 44.3877 58.5859C43.8354 58.5859 43.3877 58.1382 43.3877 57.5859C43.3877 57.0337 43.8354 56.5859 44.3877 56.5859C44.94 56.5859 45.3877 57.0337 45.3877 57.5859Z'
          fill='#242631'
        />
        <path
          d='M51.3877 57.5859C51.3877 58.1382 50.94 58.5859 50.3877 58.5859C49.8354 58.5859 49.3877 58.1382 49.3877 57.5859C49.3877 57.0337 49.8354 56.5859 50.3877 56.5859C50.94 56.5859 51.3877 57.0337 51.3877 57.5859Z'
          fill='#242631'
        />
        <path
          d='M57.3877 57.5859C57.3877 58.1382 56.94 58.5859 56.3877 58.5859C55.8354 58.5859 55.3877 58.1382 55.3877 57.5859C55.3877 57.0337 55.8354 56.5859 56.3877 56.5859C56.94 56.5859 57.3877 57.0337 57.3877 57.5859Z'
          fill='#242631'
        />
        <path
          d='M63.3877 57.5859C63.3877 58.1382 62.94 58.5859 62.3877 58.5859C61.8354 58.5859 61.3877 58.1382 61.3877 57.5859C61.3877 57.0337 61.8354 56.5859 62.3877 56.5859C62.94 56.5859 63.3877 57.0337 63.3877 57.5859Z'
          fill='#242631'
        />
        <path
          d='M69.3877 57.5859C69.3877 58.1382 68.94 58.5859 68.3877 58.5859C67.8354 58.5859 67.3877 58.1382 67.3877 57.5859C67.3877 57.0337 67.8354 56.5859 68.3877 56.5859C68.94 56.5859 69.3877 57.0337 69.3877 57.5859Z'
          fill='#242631'
        />
        <path
          d='M75.3877 57.5859C75.3877 58.1382 74.94 58.5859 74.3877 58.5859C73.8354 58.5859 73.3877 58.1382 73.3877 57.5859C73.3877 57.0337 73.8354 56.5859 74.3877 56.5859C74.94 56.5859 75.3877 57.0337 75.3877 57.5859Z'
          fill='#242631'
        />
        <path
          d='M81.3877 57.5859C81.3877 58.1382 80.94 58.5859 80.3877 58.5859C79.8354 58.5859 79.3877 58.1382 79.3877 57.5859C79.3877 57.0337 79.8354 56.5859 80.3877 56.5859C80.94 56.5859 81.3877 57.0337 81.3877 57.5859Z'
          fill='#242631'
        />
        <path
          d='M87.3877 57.5859C87.3877 58.1382 86.94 58.5859 86.3877 58.5859C85.8354 58.5859 85.3877 58.1382 85.3877 57.5859C85.3877 57.0337 85.8354 56.5859 86.3877 56.5859C86.94 56.5859 87.3877 57.0337 87.3877 57.5859Z'
          fill='#242631'
        />
        <path
          d='M93.3877 57.5859C93.3877 58.1382 92.94 58.5859 92.3877 58.5859C91.8354 58.5859 91.3877 58.1382 91.3877 57.5859C91.3877 57.0337 91.8354 56.5859 92.3877 56.5859C92.94 56.5859 93.3877 57.0337 93.3877 57.5859Z'
          fill='#242631'
        />
        <path
          d='M99.3877 57.5859C99.3877 58.1382 98.94 58.5859 98.3877 58.5859C97.8354 58.5859 97.3877 58.1382 97.3877 57.5859C97.3877 57.0337 97.8354 56.5859 98.3877 56.5859C98.94 56.5859 99.3877 57.0337 99.3877 57.5859Z'
          fill='#242631'
        />
        <path
          d='M105.388 57.5859C105.388 58.1382 104.94 58.5859 104.388 58.5859C103.835 58.5859 103.388 58.1382 103.388 57.5859C103.388 57.0337 103.835 56.5859 104.388 56.5859C104.94 56.5859 105.388 57.0337 105.388 57.5859Z'
          fill='#242631'
        />
        <path
          d='M111.388 57.5859C111.388 58.1382 110.94 58.5859 110.388 58.5859C109.835 58.5859 109.388 58.1382 109.388 57.5859C109.388 57.0337 109.835 56.5859 110.388 56.5859C110.94 56.5859 111.388 57.0337 111.388 57.5859Z'
          fill='#242631'
        />
        <path
          d='M117.388 57.5859C117.388 58.1382 116.94 58.5859 116.388 58.5859C115.835 58.5859 115.388 58.1382 115.388 57.5859C115.388 57.0337 115.835 56.5859 116.388 56.5859C116.94 56.5859 117.388 57.0337 117.388 57.5859Z'
          fill='#242631'
        />
        <path
          d='M123.388 57.5859C123.388 58.1382 122.94 58.5859 122.388 58.5859C121.835 58.5859 121.388 58.1382 121.388 57.5859C121.388 57.0337 121.835 56.5859 122.388 56.5859C122.94 56.5859 123.388 57.0337 123.388 57.5859Z'
          fill='#242631'
        />
        <path
          d='M129.388 57.5859C129.388 58.1382 128.94 58.5859 128.388 58.5859C127.835 58.5859 127.388 58.1382 127.388 57.5859C127.388 57.0337 127.835 56.5859 128.388 56.5859C128.94 56.5859 129.388 57.0337 129.388 57.5859Z'
          fill='#242631'
        />
        <circle cx='134.388' cy='57.5859' r='1' fill='#242631' />
        <circle cx='140.388' cy='57.5859' r='1' fill='#242631' />
        <circle cx='146.388' cy='57.5859' r='1' fill='#242631' />
        <circle cx='152.388' cy='57.5859' r='1' fill='#242631' />
        <circle cx='158.388' cy='57.5859' r='1' fill='#242631' />
        <circle cx='164.388' cy='57.5859' r='1' fill='#242631' />
        <circle cx='170.388' cy='57.5859' r='1' fill='#242631' />
        <circle cx='176.388' cy='57.5859' r='1' fill='#242631' />
        <circle cx='182.388' cy='57.5859' r='1' fill='#242631' />
        <circle cx='188.388' cy='57.5859' r='1' fill='#242631' />
        <circle cx='194.388' cy='57.5859' r='1' fill='#242631' />
        <circle cx='200.388' cy='57.5859' r='1' fill='#242631' />
        <circle cx='2.3877' cy='176.717' r='1' fill='#242631' />
        <circle cx='8.3877' cy='176.717' r='1' fill='#242631' />
        <path
          d='M15.3877 176.717C15.3877 177.269 14.94 177.717 14.3877 177.717C13.8354 177.717 13.3877 177.269 13.3877 176.717C13.3877 176.165 13.8354 175.717 14.3877 175.717C14.94 175.717 15.3877 176.165 15.3877 176.717Z'
          fill='#242631'
        />
        <path
          d='M21.3877 176.717C21.3877 177.269 20.94 177.717 20.3877 177.717C19.8354 177.717 19.3877 177.269 19.3877 176.717C19.3877 176.165 19.8354 175.717 20.3877 175.717C20.94 175.717 21.3877 176.165 21.3877 176.717Z'
          fill='#242631'
        />
        <path
          d='M27.3877 176.717C27.3877 177.269 26.94 177.717 26.3877 177.717C25.8354 177.717 25.3877 177.269 25.3877 176.717C25.3877 176.165 25.8354 175.717 26.3877 175.717C26.94 175.717 27.3877 176.165 27.3877 176.717Z'
          fill='#242631'
        />
        <path
          d='M33.3877 176.717C33.3877 177.269 32.94 177.717 32.3877 177.717C31.8354 177.717 31.3877 177.269 31.3877 176.717C31.3877 176.165 31.8354 175.717 32.3877 175.717C32.94 175.717 33.3877 176.165 33.3877 176.717Z'
          fill='#242631'
        />
        <path
          d='M39.3877 176.717C39.3877 177.269 38.94 177.717 38.3877 177.717C37.8354 177.717 37.3877 177.269 37.3877 176.717C37.3877 176.165 37.8354 175.717 38.3877 175.717C38.94 175.717 39.3877 176.165 39.3877 176.717Z'
          fill='#242631'
        />
        <path
          d='M45.3877 176.717C45.3877 177.269 44.94 177.717 44.3877 177.717C43.8354 177.717 43.3877 177.269 43.3877 176.717C43.3877 176.165 43.8354 175.717 44.3877 175.717C44.94 175.717 45.3877 176.165 45.3877 176.717Z'
          fill='#242631'
        />
        <path
          d='M51.3877 176.717C51.3877 177.269 50.94 177.717 50.3877 177.717C49.8354 177.717 49.3877 177.269 49.3877 176.717C49.3877 176.165 49.8354 175.717 50.3877 175.717C50.94 175.717 51.3877 176.165 51.3877 176.717Z'
          fill='#242631'
        />
        <path
          d='M57.3877 176.717C57.3877 177.269 56.94 177.717 56.3877 177.717C55.8354 177.717 55.3877 177.269 55.3877 176.717C55.3877 176.165 55.8354 175.717 56.3877 175.717C56.94 175.717 57.3877 176.165 57.3877 176.717Z'
          fill='#242631'
        />
        <path
          d='M63.3877 176.717C63.3877 177.269 62.94 177.717 62.3877 177.717C61.8354 177.717 61.3877 177.269 61.3877 176.717C61.3877 176.165 61.8354 175.717 62.3877 175.717C62.94 175.717 63.3877 176.165 63.3877 176.717Z'
          fill='#242631'
        />
        <path
          d='M69.3877 176.717C69.3877 177.269 68.94 177.717 68.3877 177.717C67.8354 177.717 67.3877 177.269 67.3877 176.717C67.3877 176.165 67.8354 175.717 68.3877 175.717C68.94 175.717 69.3877 176.165 69.3877 176.717Z'
          fill='#242631'
        />
        <path
          d='M75.3877 176.717C75.3877 177.269 74.94 177.717 74.3877 177.717C73.8354 177.717 73.3877 177.269 73.3877 176.717C73.3877 176.165 73.8354 175.717 74.3877 175.717C74.94 175.717 75.3877 176.165 75.3877 176.717Z'
          fill='#242631'
        />
        <path
          d='M81.3877 176.717C81.3877 177.269 80.94 177.717 80.3877 177.717C79.8354 177.717 79.3877 177.269 79.3877 176.717C79.3877 176.165 79.8354 175.717 80.3877 175.717C80.94 175.717 81.3877 176.165 81.3877 176.717Z'
          fill='#242631'
        />
        <path
          d='M87.3877 176.717C87.3877 177.269 86.94 177.717 86.3877 177.717C85.8354 177.717 85.3877 177.269 85.3877 176.717C85.3877 176.165 85.8354 175.717 86.3877 175.717C86.94 175.717 87.3877 176.165 87.3877 176.717Z'
          fill='#242631'
        />
        <path
          d='M93.3877 176.717C93.3877 177.269 92.94 177.717 92.3877 177.717C91.8354 177.717 91.3877 177.269 91.3877 176.717C91.3877 176.165 91.8354 175.717 92.3877 175.717C92.94 175.717 93.3877 176.165 93.3877 176.717Z'
          fill='#242631'
        />
        <path
          d='M99.3877 176.717C99.3877 177.269 98.94 177.717 98.3877 177.717C97.8354 177.717 97.3877 177.269 97.3877 176.717C97.3877 176.165 97.8354 175.717 98.3877 175.717C98.94 175.717 99.3877 176.165 99.3877 176.717Z'
          fill='#242631'
        />
        <path
          d='M105.388 176.717C105.388 177.269 104.94 177.717 104.388 177.717C103.835 177.717 103.388 177.269 103.388 176.717C103.388 176.165 103.835 175.717 104.388 175.717C104.94 175.717 105.388 176.165 105.388 176.717Z'
          fill='#242631'
        />
        <path
          d='M111.388 176.717C111.388 177.269 110.94 177.717 110.388 177.717C109.835 177.717 109.388 177.269 109.388 176.717C109.388 176.165 109.835 175.717 110.388 175.717C110.94 175.717 111.388 176.165 111.388 176.717Z'
          fill='#242631'
        />
        <path
          d='M117.388 176.717C117.388 177.269 116.94 177.717 116.388 177.717C115.835 177.717 115.388 177.269 115.388 176.717C115.388 176.165 115.835 175.717 116.388 175.717C116.94 175.717 117.388 176.165 117.388 176.717Z'
          fill='#242631'
        />
        <path
          d='M123.388 176.717C123.388 177.269 122.94 177.717 122.388 177.717C121.835 177.717 121.388 177.269 121.388 176.717C121.388 176.165 121.835 175.717 122.388 175.717C122.94 175.717 123.388 176.165 123.388 176.717Z'
          fill='#242631'
        />
        <path
          d='M129.388 176.717C129.388 177.269 128.94 177.717 128.388 177.717C127.835 177.717 127.388 177.269 127.388 176.717C127.388 176.165 127.835 175.717 128.388 175.717C128.94 175.717 129.388 176.165 129.388 176.717Z'
          fill='#242631'
        />
        <circle cx='134.388' cy='176.717' r='1' fill='#242631' />
        <circle cx='140.388' cy='176.717' r='1' fill='#242631' />
        <circle cx='146.388' cy='176.717' r='1' fill='#242631' />
        <circle cx='152.388' cy='176.717' r='1' fill='#242631' />
        <circle cx='158.388' cy='176.717' r='1' fill='#242631' />
        <circle cx='164.388' cy='176.717' r='1' fill='#242631' />
        <circle cx='170.388' cy='176.717' r='1' fill='#242631' />
        <circle cx='176.388' cy='176.717' r='1' fill='#242631' />
        <circle cx='182.388' cy='176.717' r='1' fill='#242631' />
        <circle cx='188.388' cy='176.717' r='1' fill='#242631' />
        <circle cx='194.388' cy='176.717' r='1' fill='#242631' />
        <circle cx='200.388' cy='176.717' r='1' fill='#242631' />
        <circle cx='2.3877' cy='81.4121' r='1' fill='#242631' />
        <circle cx='8.3877' cy='81.4121' r='1' fill='#242631' />
        <path
          d='M15.3877 81.4121C15.3877 81.9644 14.94 82.4121 14.3877 82.4121C13.8354 82.4121 13.3877 81.9644 13.3877 81.4121C13.3877 80.8598 13.8354 80.4121 14.3877 80.4121C14.94 80.4121 15.3877 80.8598 15.3877 81.4121Z'
          fill='#242631'
        />
        <path
          d='M21.3877 81.4121C21.3877 81.9644 20.94 82.4121 20.3877 82.4121C19.8354 82.4121 19.3877 81.9644 19.3877 81.4121C19.3877 80.8598 19.8354 80.4121 20.3877 80.4121C20.94 80.4121 21.3877 80.8598 21.3877 81.4121Z'
          fill='#242631'
        />
        <path
          d='M27.3877 81.4121C27.3877 81.9644 26.94 82.4121 26.3877 82.4121C25.8354 82.4121 25.3877 81.9644 25.3877 81.4121C25.3877 80.8598 25.8354 80.4121 26.3877 80.4121C26.94 80.4121 27.3877 80.8598 27.3877 81.4121Z'
          fill='#242631'
        />
        <path
          d='M33.3877 81.4121C33.3877 81.9644 32.94 82.4121 32.3877 82.4121C31.8354 82.4121 31.3877 81.9644 31.3877 81.4121C31.3877 80.8598 31.8354 80.4121 32.3877 80.4121C32.94 80.4121 33.3877 80.8598 33.3877 81.4121Z'
          fill='#242631'
        />
        <path
          d='M39.3877 81.4121C39.3877 81.9644 38.94 82.4121 38.3877 82.4121C37.8354 82.4121 37.3877 81.9644 37.3877 81.4121C37.3877 80.8598 37.8354 80.4121 38.3877 80.4121C38.94 80.4121 39.3877 80.8598 39.3877 81.4121Z'
          fill='#242631'
        />
        <path
          d='M45.3877 81.4121C45.3877 81.9644 44.94 82.4121 44.3877 82.4121C43.8354 82.4121 43.3877 81.9644 43.3877 81.4121C43.3877 80.8598 43.8354 80.4121 44.3877 80.4121C44.94 80.4121 45.3877 80.8598 45.3877 81.4121Z'
          fill='#242631'
        />
        <path
          d='M51.3877 81.4121C51.3877 81.9644 50.94 82.4121 50.3877 82.4121C49.8354 82.4121 49.3877 81.9644 49.3877 81.4121C49.3877 80.8598 49.8354 80.4121 50.3877 80.4121C50.94 80.4121 51.3877 80.8598 51.3877 81.4121Z'
          fill='#242631'
        />
        <path
          d='M57.3877 81.4121C57.3877 81.9644 56.94 82.4121 56.3877 82.4121C55.8354 82.4121 55.3877 81.9644 55.3877 81.4121C55.3877 80.8598 55.8354 80.4121 56.3877 80.4121C56.94 80.4121 57.3877 80.8598 57.3877 81.4121Z'
          fill='#242631'
        />
        <path
          d='M63.3877 81.4121C63.3877 81.9644 62.94 82.4121 62.3877 82.4121C61.8354 82.4121 61.3877 81.9644 61.3877 81.4121C61.3877 80.8598 61.8354 80.4121 62.3877 80.4121C62.94 80.4121 63.3877 80.8598 63.3877 81.4121Z'
          fill='#242631'
        />
        <path
          d='M69.3877 81.4121C69.3877 81.9644 68.94 82.4121 68.3877 82.4121C67.8354 82.4121 67.3877 81.9644 67.3877 81.4121C67.3877 80.8598 67.8354 80.4121 68.3877 80.4121C68.94 80.4121 69.3877 80.8598 69.3877 81.4121Z'
          fill='#242631'
        />
        <path
          d='M75.3877 81.4121C75.3877 81.9644 74.94 82.4121 74.3877 82.4121C73.8354 82.4121 73.3877 81.9644 73.3877 81.4121C73.3877 80.8598 73.8354 80.4121 74.3877 80.4121C74.94 80.4121 75.3877 80.8598 75.3877 81.4121Z'
          fill='#242631'
        />
        <path
          d='M81.3877 81.4121C81.3877 81.9644 80.94 82.4121 80.3877 82.4121C79.8354 82.4121 79.3877 81.9644 79.3877 81.4121C79.3877 80.8598 79.8354 80.4121 80.3877 80.4121C80.94 80.4121 81.3877 80.8598 81.3877 81.4121Z'
          fill='#242631'
        />
        <path
          d='M87.3877 81.4121C87.3877 81.9644 86.94 82.4121 86.3877 82.4121C85.8354 82.4121 85.3877 81.9644 85.3877 81.4121C85.3877 80.8598 85.8354 80.4121 86.3877 80.4121C86.94 80.4121 87.3877 80.8598 87.3877 81.4121Z'
          fill='#242631'
        />
        <path
          d='M93.3877 81.4121C93.3877 81.9644 92.94 82.4121 92.3877 82.4121C91.8354 82.4121 91.3877 81.9644 91.3877 81.4121C91.3877 80.8598 91.8354 80.4121 92.3877 80.4121C92.94 80.4121 93.3877 80.8598 93.3877 81.4121Z'
          fill='#242631'
        />
        <path
          d='M99.3877 81.4121C99.3877 81.9644 98.94 82.4121 98.3877 82.4121C97.8354 82.4121 97.3877 81.9644 97.3877 81.4121C97.3877 80.8598 97.8354 80.4121 98.3877 80.4121C98.94 80.4121 99.3877 80.8598 99.3877 81.4121Z'
          fill='#242631'
        />
        <path
          d='M105.388 81.4121C105.388 81.9644 104.94 82.4121 104.388 82.4121C103.835 82.4121 103.388 81.9644 103.388 81.4121C103.388 80.8598 103.835 80.4121 104.388 80.4121C104.94 80.4121 105.388 80.8598 105.388 81.4121Z'
          fill='#242631'
        />
        <path
          d='M111.388 81.4121C111.388 81.9644 110.94 82.4121 110.388 82.4121C109.835 82.4121 109.388 81.9644 109.388 81.4121C109.388 80.8598 109.835 80.4121 110.388 80.4121C110.94 80.4121 111.388 80.8598 111.388 81.4121Z'
          fill='#242631'
        />
        <path
          d='M117.388 81.4121C117.388 81.9644 116.94 82.4121 116.388 82.4121C115.835 82.4121 115.388 81.9644 115.388 81.4121C115.388 80.8598 115.835 80.4121 116.388 80.4121C116.94 80.4121 117.388 80.8598 117.388 81.4121Z'
          fill='#242631'
        />
        <path
          d='M123.388 81.4121C123.388 81.9644 122.94 82.4121 122.388 82.4121C121.835 82.4121 121.388 81.9644 121.388 81.4121C121.388 80.8598 121.835 80.4121 122.388 80.4121C122.94 80.4121 123.388 80.8598 123.388 81.4121Z'
          fill='#242631'
        />
        <path
          d='M129.388 81.4121C129.388 81.9644 128.94 82.4121 128.388 82.4121C127.835 82.4121 127.388 81.9644 127.388 81.4121C127.388 80.8598 127.835 80.4121 128.388 80.4121C128.94 80.4121 129.388 80.8598 129.388 81.4121Z'
          fill='#242631'
        />
        <circle cx='134.388' cy='81.4121' r='1' fill='#242631' />
        <circle cx='140.388' cy='81.4121' r='1' fill='#242631' />
        <circle cx='146.388' cy='81.4121' r='1' fill='#242631' />
        <circle cx='152.388' cy='81.4121' r='1' fill='#242631' />
        <circle cx='158.388' cy='81.4121' r='1' fill='#242631' />
        <circle cx='164.388' cy='81.4121' r='1' fill='#242631' />
        <circle cx='170.388' cy='81.4121' r='1' fill='#242631' />
        <circle cx='176.388' cy='81.4121' r='1' fill='#242631' />
        <circle cx='182.388' cy='81.4121' r='1' fill='#242631' />
        <circle cx='188.388' cy='81.4121' r='1' fill='#242631' />
        <circle cx='194.388' cy='81.4121' r='1' fill='#242631' />
        <circle cx='200.388' cy='81.4121' r='1' fill='#242631' />
        <circle cx='2.3877' cy='200.543' r='1' fill='#242631' />
        <circle cx='8.3877' cy='200.543' r='1' fill='#242631' />
        <path
          d='M15.3877 200.543C15.3877 201.095 14.94 201.543 14.3877 201.543C13.8354 201.543 13.3877 201.095 13.3877 200.543C13.3877 199.991 13.8354 199.543 14.3877 199.543C14.94 199.543 15.3877 199.991 15.3877 200.543Z'
          fill='#242631'
        />
        <path
          d='M21.3877 200.543C21.3877 201.095 20.94 201.543 20.3877 201.543C19.8354 201.543 19.3877 201.095 19.3877 200.543C19.3877 199.991 19.8354 199.543 20.3877 199.543C20.94 199.543 21.3877 199.991 21.3877 200.543Z'
          fill='#242631'
        />
        <path
          d='M27.3877 200.543C27.3877 201.095 26.94 201.543 26.3877 201.543C25.8354 201.543 25.3877 201.095 25.3877 200.543C25.3877 199.991 25.8354 199.543 26.3877 199.543C26.94 199.543 27.3877 199.991 27.3877 200.543Z'
          fill='#242631'
        />
        <path
          d='M33.3877 200.543C33.3877 201.095 32.94 201.543 32.3877 201.543C31.8354 201.543 31.3877 201.095 31.3877 200.543C31.3877 199.991 31.8354 199.543 32.3877 199.543C32.94 199.543 33.3877 199.991 33.3877 200.543Z'
          fill='#242631'
        />
        <path
          d='M39.3877 200.543C39.3877 201.095 38.94 201.543 38.3877 201.543C37.8354 201.543 37.3877 201.095 37.3877 200.543C37.3877 199.991 37.8354 199.543 38.3877 199.543C38.94 199.543 39.3877 199.991 39.3877 200.543Z'
          fill='#242631'
        />
        <path
          d='M45.3877 200.543C45.3877 201.095 44.94 201.543 44.3877 201.543C43.8354 201.543 43.3877 201.095 43.3877 200.543C43.3877 199.991 43.8354 199.543 44.3877 199.543C44.94 199.543 45.3877 199.991 45.3877 200.543Z'
          fill='#242631'
        />
        <path
          d='M51.3877 200.543C51.3877 201.095 50.94 201.543 50.3877 201.543C49.8354 201.543 49.3877 201.095 49.3877 200.543C49.3877 199.991 49.8354 199.543 50.3877 199.543C50.94 199.543 51.3877 199.991 51.3877 200.543Z'
          fill='#242631'
        />
        <path
          d='M57.3877 200.543C57.3877 201.095 56.94 201.543 56.3877 201.543C55.8354 201.543 55.3877 201.095 55.3877 200.543C55.3877 199.991 55.8354 199.543 56.3877 199.543C56.94 199.543 57.3877 199.991 57.3877 200.543Z'
          fill='#242631'
        />
        <path
          d='M63.3877 200.543C63.3877 201.095 62.94 201.543 62.3877 201.543C61.8354 201.543 61.3877 201.095 61.3877 200.543C61.3877 199.991 61.8354 199.543 62.3877 199.543C62.94 199.543 63.3877 199.991 63.3877 200.543Z'
          fill='#242631'
        />
        <path
          d='M69.3877 200.543C69.3877 201.095 68.94 201.543 68.3877 201.543C67.8354 201.543 67.3877 201.095 67.3877 200.543C67.3877 199.991 67.8354 199.543 68.3877 199.543C68.94 199.543 69.3877 199.991 69.3877 200.543Z'
          fill='#242631'
        />
        <path
          d='M75.3877 200.543C75.3877 201.095 74.94 201.543 74.3877 201.543C73.8354 201.543 73.3877 201.095 73.3877 200.543C73.3877 199.991 73.8354 199.543 74.3877 199.543C74.94 199.543 75.3877 199.991 75.3877 200.543Z'
          fill='#242631'
        />
        <path
          d='M81.3877 200.543C81.3877 201.095 80.94 201.543 80.3877 201.543C79.8354 201.543 79.3877 201.095 79.3877 200.543C79.3877 199.991 79.8354 199.543 80.3877 199.543C80.94 199.543 81.3877 199.991 81.3877 200.543Z'
          fill='#242631'
        />
        <path
          d='M87.3877 200.543C87.3877 201.095 86.94 201.543 86.3877 201.543C85.8354 201.543 85.3877 201.095 85.3877 200.543C85.3877 199.991 85.8354 199.543 86.3877 199.543C86.94 199.543 87.3877 199.991 87.3877 200.543Z'
          fill='#242631'
        />
        <path
          d='M93.3877 200.543C93.3877 201.095 92.94 201.543 92.3877 201.543C91.8354 201.543 91.3877 201.095 91.3877 200.543C91.3877 199.991 91.8354 199.543 92.3877 199.543C92.94 199.543 93.3877 199.991 93.3877 200.543Z'
          fill='#242631'
        />
        <path
          d='M99.3877 200.543C99.3877 201.095 98.94 201.543 98.3877 201.543C97.8354 201.543 97.3877 201.095 97.3877 200.543C97.3877 199.991 97.8354 199.543 98.3877 199.543C98.94 199.543 99.3877 199.991 99.3877 200.543Z'
          fill='#242631'
        />
        <path
          d='M105.388 200.543C105.388 201.095 104.94 201.543 104.388 201.543C103.835 201.543 103.388 201.095 103.388 200.543C103.388 199.991 103.835 199.543 104.388 199.543C104.94 199.543 105.388 199.991 105.388 200.543Z'
          fill='#242631'
        />
        <path
          d='M111.388 200.543C111.388 201.095 110.94 201.543 110.388 201.543C109.835 201.543 109.388 201.095 109.388 200.543C109.388 199.991 109.835 199.543 110.388 199.543C110.94 199.543 111.388 199.991 111.388 200.543Z'
          fill='#242631'
        />
        <path
          d='M117.388 200.543C117.388 201.095 116.94 201.543 116.388 201.543C115.835 201.543 115.388 201.095 115.388 200.543C115.388 199.991 115.835 199.543 116.388 199.543C116.94 199.543 117.388 199.991 117.388 200.543Z'
          fill='#242631'
        />
        <path
          d='M123.388 200.543C123.388 201.095 122.94 201.543 122.388 201.543C121.835 201.543 121.388 201.095 121.388 200.543C121.388 199.991 121.835 199.543 122.388 199.543C122.94 199.543 123.388 199.991 123.388 200.543Z'
          fill='#242631'
        />
        <path
          d='M129.388 200.543C129.388 201.095 128.94 201.543 128.388 201.543C127.835 201.543 127.388 201.095 127.388 200.543C127.388 199.991 127.835 199.543 128.388 199.543C128.94 199.543 129.388 199.991 129.388 200.543Z'
          fill='#242631'
        />
        <circle cx='134.388' cy='200.543' r='1' fill='#242631' />
        <circle cx='140.388' cy='200.543' r='1' fill='#242631' />
        <circle cx='146.388' cy='200.543' r='1' fill='#242631' />
        <circle cx='152.388' cy='200.543' r='1' fill='#242631' />
        <circle cx='158.388' cy='200.543' r='1' fill='#242631' />
        <circle cx='164.388' cy='200.543' r='1' fill='#242631' />
        <circle cx='170.388' cy='200.543' r='1' fill='#242631' />
        <circle cx='176.388' cy='200.543' r='1' fill='#242631' />
        <circle cx='182.388' cy='200.543' r='1' fill='#242631' />
        <circle cx='188.388' cy='200.543' r='1' fill='#242631' />
        <circle cx='194.388' cy='200.543' r='1' fill='#242631' />
        <circle cx='200.388' cy='200.543' r='1' fill='#242631' />
        <circle cx='2.3877' cy='105.238' r='1' fill='#242631' />
        <circle cx='8.3877' cy='105.238' r='1' fill='#242631' />
        <path
          d='M15.3877 105.238C15.3877 105.791 14.94 106.238 14.3877 106.238C13.8354 106.238 13.3877 105.791 13.3877 105.238C13.3877 104.686 13.8354 104.238 14.3877 104.238C14.94 104.238 15.3877 104.686 15.3877 105.238Z'
          fill='#242631'
        />
        <path
          d='M21.3877 105.238C21.3877 105.791 20.94 106.238 20.3877 106.238C19.8354 106.238 19.3877 105.791 19.3877 105.238C19.3877 104.686 19.8354 104.238 20.3877 104.238C20.94 104.238 21.3877 104.686 21.3877 105.238Z'
          fill='#242631'
        />
        <path
          d='M27.3877 105.238C27.3877 105.791 26.94 106.238 26.3877 106.238C25.8354 106.238 25.3877 105.791 25.3877 105.238C25.3877 104.686 25.8354 104.238 26.3877 104.238C26.94 104.238 27.3877 104.686 27.3877 105.238Z'
          fill='#242631'
        />
        <path
          d='M33.3877 105.238C33.3877 105.791 32.94 106.238 32.3877 106.238C31.8354 106.238 31.3877 105.791 31.3877 105.238C31.3877 104.686 31.8354 104.238 32.3877 104.238C32.94 104.238 33.3877 104.686 33.3877 105.238Z'
          fill='#242631'
        />
        <path
          d='M39.3877 105.238C39.3877 105.791 38.94 106.238 38.3877 106.238C37.8354 106.238 37.3877 105.791 37.3877 105.238C37.3877 104.686 37.8354 104.238 38.3877 104.238C38.94 104.238 39.3877 104.686 39.3877 105.238Z'
          fill='#242631'
        />
        <path
          d='M45.3877 105.238C45.3877 105.791 44.94 106.238 44.3877 106.238C43.8354 106.238 43.3877 105.791 43.3877 105.238C43.3877 104.686 43.8354 104.238 44.3877 104.238C44.94 104.238 45.3877 104.686 45.3877 105.238Z'
          fill='#242631'
        />
        <path
          d='M51.3877 105.238C51.3877 105.791 50.94 106.238 50.3877 106.238C49.8354 106.238 49.3877 105.791 49.3877 105.238C49.3877 104.686 49.8354 104.238 50.3877 104.238C50.94 104.238 51.3877 104.686 51.3877 105.238Z'
          fill='#242631'
        />
        <path
          d='M57.3877 105.238C57.3877 105.791 56.94 106.238 56.3877 106.238C55.8354 106.238 55.3877 105.791 55.3877 105.238C55.3877 104.686 55.8354 104.238 56.3877 104.238C56.94 104.238 57.3877 104.686 57.3877 105.238Z'
          fill='#242631'
        />
        <path
          d='M63.3877 105.238C63.3877 105.791 62.94 106.238 62.3877 106.238C61.8354 106.238 61.3877 105.791 61.3877 105.238C61.3877 104.686 61.8354 104.238 62.3877 104.238C62.94 104.238 63.3877 104.686 63.3877 105.238Z'
          fill='#242631'
        />
        <path
          d='M69.3877 105.238C69.3877 105.791 68.94 106.238 68.3877 106.238C67.8354 106.238 67.3877 105.791 67.3877 105.238C67.3877 104.686 67.8354 104.238 68.3877 104.238C68.94 104.238 69.3877 104.686 69.3877 105.238Z'
          fill='#242631'
        />
        <path
          d='M75.3877 105.238C75.3877 105.791 74.94 106.238 74.3877 106.238C73.8354 106.238 73.3877 105.791 73.3877 105.238C73.3877 104.686 73.8354 104.238 74.3877 104.238C74.94 104.238 75.3877 104.686 75.3877 105.238Z'
          fill='#242631'
        />
        <path
          d='M81.3877 105.238C81.3877 105.791 80.94 106.238 80.3877 106.238C79.8354 106.238 79.3877 105.791 79.3877 105.238C79.3877 104.686 79.8354 104.238 80.3877 104.238C80.94 104.238 81.3877 104.686 81.3877 105.238Z'
          fill='#242631'
        />
        <path
          d='M87.3877 105.238C87.3877 105.791 86.94 106.238 86.3877 106.238C85.8354 106.238 85.3877 105.791 85.3877 105.238C85.3877 104.686 85.8354 104.238 86.3877 104.238C86.94 104.238 87.3877 104.686 87.3877 105.238Z'
          fill='#242631'
        />
        <path
          d='M93.3877 105.238C93.3877 105.791 92.94 106.238 92.3877 106.238C91.8354 106.238 91.3877 105.791 91.3877 105.238C91.3877 104.686 91.8354 104.238 92.3877 104.238C92.94 104.238 93.3877 104.686 93.3877 105.238Z'
          fill='#242631'
        />
        <path
          d='M99.3877 105.238C99.3877 105.791 98.94 106.238 98.3877 106.238C97.8354 106.238 97.3877 105.791 97.3877 105.238C97.3877 104.686 97.8354 104.238 98.3877 104.238C98.94 104.238 99.3877 104.686 99.3877 105.238Z'
          fill='#242631'
        />
        <path
          d='M105.388 105.238C105.388 105.791 104.94 106.238 104.388 106.238C103.835 106.238 103.388 105.791 103.388 105.238C103.388 104.686 103.835 104.238 104.388 104.238C104.94 104.238 105.388 104.686 105.388 105.238Z'
          fill='#242631'
        />
        <path
          d='M111.388 105.238C111.388 105.791 110.94 106.238 110.388 106.238C109.835 106.238 109.388 105.791 109.388 105.238C109.388 104.686 109.835 104.238 110.388 104.238C110.94 104.238 111.388 104.686 111.388 105.238Z'
          fill='#242631'
        />
        <path
          d='M117.388 105.238C117.388 105.791 116.94 106.238 116.388 106.238C115.835 106.238 115.388 105.791 115.388 105.238C115.388 104.686 115.835 104.238 116.388 104.238C116.94 104.238 117.388 104.686 117.388 105.238Z'
          fill='#242631'
        />
        <path
          d='M123.388 105.238C123.388 105.791 122.94 106.238 122.388 106.238C121.835 106.238 121.388 105.791 121.388 105.238C121.388 104.686 121.835 104.238 122.388 104.238C122.94 104.238 123.388 104.686 123.388 105.238Z'
          fill='#242631'
        />
        <path
          d='M129.388 105.238C129.388 105.791 128.94 106.238 128.388 106.238C127.835 106.238 127.388 105.791 127.388 105.238C127.388 104.686 127.835 104.238 128.388 104.238C128.94 104.238 129.388 104.686 129.388 105.238Z'
          fill='#242631'
        />
        <circle cx='134.388' cy='105.238' r='1' fill='#242631' />
        <circle cx='140.388' cy='105.238' r='1' fill='#242631' />
        <circle cx='146.388' cy='105.238' r='1' fill='#242631' />
        <circle cx='152.388' cy='105.238' r='1' fill='#242631' />
        <circle cx='158.388' cy='105.238' r='1' fill='#242631' />
        <circle cx='164.388' cy='105.238' r='1' fill='#242631' />
        <circle cx='170.388' cy='105.238' r='1' fill='#242631' />
        <circle cx='176.388' cy='105.238' r='1' fill='#242631' />
        <circle cx='182.388' cy='105.238' r='1' fill='#242631' />
        <circle cx='188.388' cy='105.238' r='1' fill='#242631' />
        <circle cx='194.388' cy='105.238' r='1' fill='#242631' />
        <circle cx='200.388' cy='105.238' r='1' fill='#242631' />
        <circle cx='2.3877' cy='21.8477' r='1' fill='#242631' />
        <circle cx='8.3877' cy='21.8477' r='1' fill='#242631' />
        <path
          d='M15.3877 21.8477C15.3877 22.3999 14.94 22.8477 14.3877 22.8477C13.8354 22.8477 13.3877 22.3999 13.3877 21.8477C13.3877 21.2954 13.8354 20.8477 14.3877 20.8477C14.94 20.8477 15.3877 21.2954 15.3877 21.8477Z'
          fill='#242631'
        />
        <path
          d='M21.3877 21.8477C21.3877 22.3999 20.94 22.8477 20.3877 22.8477C19.8354 22.8477 19.3877 22.3999 19.3877 21.8477C19.3877 21.2954 19.8354 20.8477 20.3877 20.8477C20.94 20.8477 21.3877 21.2954 21.3877 21.8477Z'
          fill='#242631'
        />
        <path
          d='M27.3877 21.8477C27.3877 22.3999 26.94 22.8477 26.3877 22.8477C25.8354 22.8477 25.3877 22.3999 25.3877 21.8477C25.3877 21.2954 25.8354 20.8477 26.3877 20.8477C26.94 20.8477 27.3877 21.2954 27.3877 21.8477Z'
          fill='#242631'
        />
        <path
          d='M33.3877 21.8477C33.3877 22.3999 32.94 22.8477 32.3877 22.8477C31.8354 22.8477 31.3877 22.3999 31.3877 21.8477C31.3877 21.2954 31.8354 20.8477 32.3877 20.8477C32.94 20.8477 33.3877 21.2954 33.3877 21.8477Z'
          fill='#242631'
        />
        <path
          d='M39.3877 21.8477C39.3877 22.3999 38.94 22.8477 38.3877 22.8477C37.8354 22.8477 37.3877 22.3999 37.3877 21.8477C37.3877 21.2954 37.8354 20.8477 38.3877 20.8477C38.94 20.8477 39.3877 21.2954 39.3877 21.8477Z'
          fill='#242631'
        />
        <path
          d='M45.3877 21.8477C45.3877 22.3999 44.94 22.8477 44.3877 22.8477C43.8354 22.8477 43.3877 22.3999 43.3877 21.8477C43.3877 21.2954 43.8354 20.8477 44.3877 20.8477C44.94 20.8477 45.3877 21.2954 45.3877 21.8477Z'
          fill='#242631'
        />
        <path
          d='M51.3877 21.8477C51.3877 22.3999 50.94 22.8477 50.3877 22.8477C49.8354 22.8477 49.3877 22.3999 49.3877 21.8477C49.3877 21.2954 49.8354 20.8477 50.3877 20.8477C50.94 20.8477 51.3877 21.2954 51.3877 21.8477Z'
          fill='#242631'
        />
        <path
          d='M57.3877 21.8477C57.3877 22.3999 56.94 22.8477 56.3877 22.8477C55.8354 22.8477 55.3877 22.3999 55.3877 21.8477C55.3877 21.2954 55.8354 20.8477 56.3877 20.8477C56.94 20.8477 57.3877 21.2954 57.3877 21.8477Z'
          fill='#242631'
        />
        <path
          d='M63.3877 21.8477C63.3877 22.3999 62.94 22.8477 62.3877 22.8477C61.8354 22.8477 61.3877 22.3999 61.3877 21.8477C61.3877 21.2954 61.8354 20.8477 62.3877 20.8477C62.94 20.8477 63.3877 21.2954 63.3877 21.8477Z'
          fill='#242631'
        />
        <path
          d='M69.3877 21.8477C69.3877 22.3999 68.94 22.8477 68.3877 22.8477C67.8354 22.8477 67.3877 22.3999 67.3877 21.8477C67.3877 21.2954 67.8354 20.8477 68.3877 20.8477C68.94 20.8477 69.3877 21.2954 69.3877 21.8477Z'
          fill='#242631'
        />
        <path
          d='M75.3877 21.8477C75.3877 22.3999 74.94 22.8477 74.3877 22.8477C73.8354 22.8477 73.3877 22.3999 73.3877 21.8477C73.3877 21.2954 73.8354 20.8477 74.3877 20.8477C74.94 20.8477 75.3877 21.2954 75.3877 21.8477Z'
          fill='#242631'
        />
        <path
          d='M81.3877 21.8477C81.3877 22.3999 80.94 22.8477 80.3877 22.8477C79.8354 22.8477 79.3877 22.3999 79.3877 21.8477C79.3877 21.2954 79.8354 20.8477 80.3877 20.8477C80.94 20.8477 81.3877 21.2954 81.3877 21.8477Z'
          fill='#242631'
        />
        <path
          d='M87.3877 21.8477C87.3877 22.3999 86.94 22.8477 86.3877 22.8477C85.8354 22.8477 85.3877 22.3999 85.3877 21.8477C85.3877 21.2954 85.8354 20.8477 86.3877 20.8477C86.94 20.8477 87.3877 21.2954 87.3877 21.8477Z'
          fill='#242631'
        />
        <path
          d='M93.3877 21.8477C93.3877 22.3999 92.94 22.8477 92.3877 22.8477C91.8354 22.8477 91.3877 22.3999 91.3877 21.8477C91.3877 21.2954 91.8354 20.8477 92.3877 20.8477C92.94 20.8477 93.3877 21.2954 93.3877 21.8477Z'
          fill='#242631'
        />
        <path
          d='M99.3877 21.8477C99.3877 22.3999 98.94 22.8477 98.3877 22.8477C97.8354 22.8477 97.3877 22.3999 97.3877 21.8477C97.3877 21.2954 97.8354 20.8477 98.3877 20.8477C98.94 20.8477 99.3877 21.2954 99.3877 21.8477Z'
          fill='#242631'
        />
        <path
          d='M105.388 21.8477C105.388 22.3999 104.94 22.8477 104.388 22.8477C103.835 22.8477 103.388 22.3999 103.388 21.8477C103.388 21.2954 103.835 20.8477 104.388 20.8477C104.94 20.8477 105.388 21.2954 105.388 21.8477Z'
          fill='#242631'
        />
        <path
          d='M111.388 21.8477C111.388 22.3999 110.94 22.8477 110.388 22.8477C109.835 22.8477 109.388 22.3999 109.388 21.8477C109.388 21.2954 109.835 20.8477 110.388 20.8477C110.94 20.8477 111.388 21.2954 111.388 21.8477Z'
          fill='#242631'
        />
        <path
          d='M117.388 21.8477C117.388 22.3999 116.94 22.8477 116.388 22.8477C115.835 22.8477 115.388 22.3999 115.388 21.8477C115.388 21.2954 115.835 20.8477 116.388 20.8477C116.94 20.8477 117.388 21.2954 117.388 21.8477Z'
          fill='#242631'
        />
        <path
          d='M123.388 21.8477C123.388 22.3999 122.94 22.8477 122.388 22.8477C121.835 22.8477 121.388 22.3999 121.388 21.8477C121.388 21.2954 121.835 20.8477 122.388 20.8477C122.94 20.8477 123.388 21.2954 123.388 21.8477Z'
          fill='#242631'
        />
        <path
          d='M129.388 21.8477C129.388 22.3999 128.94 22.8477 128.388 22.8477C127.835 22.8477 127.388 22.3999 127.388 21.8477C127.388 21.2954 127.835 20.8477 128.388 20.8477C128.94 20.8477 129.388 21.2954 129.388 21.8477Z'
          fill='#242631'
        />
        <circle cx='134.388' cy='21.8477' r='1' fill='#242631' />
        <circle cx='140.388' cy='21.8477' r='1' fill='#242631' />
        <circle cx='146.388' cy='21.8477' r='1' fill='#242631' />
        <circle cx='152.388' cy='21.8477' r='1' fill='#242631' />
        <circle cx='158.388' cy='21.8477' r='1' fill='#242631' />
        <circle cx='164.388' cy='21.8477' r='1' fill='#242631' />
        <circle cx='170.388' cy='21.8477' r='1' fill='#242631' />
        <circle cx='176.388' cy='21.8477' r='1' fill='#242631' />
        <circle cx='182.388' cy='21.8477' r='1' fill='#242631' />
        <circle cx='188.388' cy='21.8477' r='1' fill='#242631' />
        <circle cx='194.388' cy='21.8477' r='1' fill='#242631' />
        <circle cx='200.388' cy='21.8477' r='1' fill='#242631' />
        <circle cx='2.3877' cy='140.977' r='1' fill='#242631' />
        <circle cx='8.3877' cy='140.977' r='1' fill='#242631' />
        <path
          d='M15.3877 140.977C15.3877 141.529 14.94 141.977 14.3877 141.977C13.8354 141.977 13.3877 141.529 13.3877 140.977C13.3877 140.424 13.8354 139.977 14.3877 139.977C14.94 139.977 15.3877 140.424 15.3877 140.977Z'
          fill='#242631'
        />
        <path
          d='M21.3877 140.977C21.3877 141.529 20.94 141.977 20.3877 141.977C19.8354 141.977 19.3877 141.529 19.3877 140.977C19.3877 140.424 19.8354 139.977 20.3877 139.977C20.94 139.977 21.3877 140.424 21.3877 140.977Z'
          fill='#242631'
        />
        <path
          d='M27.3877 140.977C27.3877 141.529 26.94 141.977 26.3877 141.977C25.8354 141.977 25.3877 141.529 25.3877 140.977C25.3877 140.424 25.8354 139.977 26.3877 139.977C26.94 139.977 27.3877 140.424 27.3877 140.977Z'
          fill='#242631'
        />
        <path
          d='M33.3877 140.977C33.3877 141.529 32.94 141.977 32.3877 141.977C31.8354 141.977 31.3877 141.529 31.3877 140.977C31.3877 140.424 31.8354 139.977 32.3877 139.977C32.94 139.977 33.3877 140.424 33.3877 140.977Z'
          fill='#242631'
        />
        <path
          d='M39.3877 140.977C39.3877 141.529 38.94 141.977 38.3877 141.977C37.8354 141.977 37.3877 141.529 37.3877 140.977C37.3877 140.424 37.8354 139.977 38.3877 139.977C38.94 139.977 39.3877 140.424 39.3877 140.977Z'
          fill='#242631'
        />
        <path
          d='M45.3877 140.977C45.3877 141.529 44.94 141.977 44.3877 141.977C43.8354 141.977 43.3877 141.529 43.3877 140.977C43.3877 140.424 43.8354 139.977 44.3877 139.977C44.94 139.977 45.3877 140.424 45.3877 140.977Z'
          fill='#242631'
        />
        <path
          d='M51.3877 140.977C51.3877 141.529 50.94 141.977 50.3877 141.977C49.8354 141.977 49.3877 141.529 49.3877 140.977C49.3877 140.424 49.8354 139.977 50.3877 139.977C50.94 139.977 51.3877 140.424 51.3877 140.977Z'
          fill='#242631'
        />
        <path
          d='M57.3877 140.977C57.3877 141.529 56.94 141.977 56.3877 141.977C55.8354 141.977 55.3877 141.529 55.3877 140.977C55.3877 140.424 55.8354 139.977 56.3877 139.977C56.94 139.977 57.3877 140.424 57.3877 140.977Z'
          fill='#242631'
        />
        <path
          d='M63.3877 140.977C63.3877 141.529 62.94 141.977 62.3877 141.977C61.8354 141.977 61.3877 141.529 61.3877 140.977C61.3877 140.424 61.8354 139.977 62.3877 139.977C62.94 139.977 63.3877 140.424 63.3877 140.977Z'
          fill='#242631'
        />
        <path
          d='M69.3877 140.977C69.3877 141.529 68.94 141.977 68.3877 141.977C67.8354 141.977 67.3877 141.529 67.3877 140.977C67.3877 140.424 67.8354 139.977 68.3877 139.977C68.94 139.977 69.3877 140.424 69.3877 140.977Z'
          fill='#242631'
        />
        <path
          d='M75.3877 140.977C75.3877 141.529 74.94 141.977 74.3877 141.977C73.8354 141.977 73.3877 141.529 73.3877 140.977C73.3877 140.424 73.8354 139.977 74.3877 139.977C74.94 139.977 75.3877 140.424 75.3877 140.977Z'
          fill='#242631'
        />
        <path
          d='M81.3877 140.977C81.3877 141.529 80.94 141.977 80.3877 141.977C79.8354 141.977 79.3877 141.529 79.3877 140.977C79.3877 140.424 79.8354 139.977 80.3877 139.977C80.94 139.977 81.3877 140.424 81.3877 140.977Z'
          fill='#242631'
        />
        <path
          d='M87.3877 140.977C87.3877 141.529 86.94 141.977 86.3877 141.977C85.8354 141.977 85.3877 141.529 85.3877 140.977C85.3877 140.424 85.8354 139.977 86.3877 139.977C86.94 139.977 87.3877 140.424 87.3877 140.977Z'
          fill='#242631'
        />
        <path
          d='M93.3877 140.977C93.3877 141.529 92.94 141.977 92.3877 141.977C91.8354 141.977 91.3877 141.529 91.3877 140.977C91.3877 140.424 91.8354 139.977 92.3877 139.977C92.94 139.977 93.3877 140.424 93.3877 140.977Z'
          fill='#242631'
        />
        <path
          d='M99.3877 140.977C99.3877 141.529 98.94 141.977 98.3877 141.977C97.8354 141.977 97.3877 141.529 97.3877 140.977C97.3877 140.424 97.8354 139.977 98.3877 139.977C98.94 139.977 99.3877 140.424 99.3877 140.977Z'
          fill='#242631'
        />
        <path
          d='M105.388 140.977C105.388 141.529 104.94 141.977 104.388 141.977C103.835 141.977 103.388 141.529 103.388 140.977C103.388 140.424 103.835 139.977 104.388 139.977C104.94 139.977 105.388 140.424 105.388 140.977Z'
          fill='#242631'
        />
        <path
          d='M111.388 140.977C111.388 141.529 110.94 141.977 110.388 141.977C109.835 141.977 109.388 141.529 109.388 140.977C109.388 140.424 109.835 139.977 110.388 139.977C110.94 139.977 111.388 140.424 111.388 140.977Z'
          fill='#242631'
        />
        <path
          d='M117.388 140.977C117.388 141.529 116.94 141.977 116.388 141.977C115.835 141.977 115.388 141.529 115.388 140.977C115.388 140.424 115.835 139.977 116.388 139.977C116.94 139.977 117.388 140.424 117.388 140.977Z'
          fill='#242631'
        />
        <path
          d='M123.388 140.977C123.388 141.529 122.94 141.977 122.388 141.977C121.835 141.977 121.388 141.529 121.388 140.977C121.388 140.424 121.835 139.977 122.388 139.977C122.94 139.977 123.388 140.424 123.388 140.977Z'
          fill='#242631'
        />
        <path
          d='M129.388 140.977C129.388 141.529 128.94 141.977 128.388 141.977C127.835 141.977 127.388 141.529 127.388 140.977C127.388 140.424 127.835 139.977 128.388 139.977C128.94 139.977 129.388 140.424 129.388 140.977Z'
          fill='#242631'
        />
        <circle cx='134.388' cy='140.977' r='1' fill='#242631' />
        <circle cx='140.388' cy='140.977' r='1' fill='#242631' />
        <circle cx='146.388' cy='140.977' r='1' fill='#242631' />
        <circle cx='152.388' cy='140.977' r='1' fill='#242631' />
        <circle cx='158.388' cy='140.977' r='1' fill='#242631' />
        <circle cx='164.388' cy='140.977' r='1' fill='#242631' />
        <circle cx='170.388' cy='140.977' r='1' fill='#242631' />
        <circle cx='176.388' cy='140.977' r='1' fill='#242631' />
        <circle cx='182.388' cy='140.977' r='1' fill='#242631' />
        <circle cx='188.388' cy='140.977' r='1' fill='#242631' />
        <circle cx='194.388' cy='140.977' r='1' fill='#242631' />
        <circle cx='200.388' cy='140.977' r='1' fill='#242631' />
        <circle cx='2.3877' cy='45.6738' r='1' fill='#242631' />
        <circle cx='8.3877' cy='45.6738' r='1' fill='#242631' />
        <path
          d='M15.3877 45.6738C15.3877 46.2261 14.94 46.6738 14.3877 46.6738C13.8354 46.6738 13.3877 46.2261 13.3877 45.6738C13.3877 45.1215 13.8354 44.6738 14.3877 44.6738C14.94 44.6738 15.3877 45.1215 15.3877 45.6738Z'
          fill='#242631'
        />
        <path
          d='M21.3877 45.6738C21.3877 46.2261 20.94 46.6738 20.3877 46.6738C19.8354 46.6738 19.3877 46.2261 19.3877 45.6738C19.3877 45.1215 19.8354 44.6738 20.3877 44.6738C20.94 44.6738 21.3877 45.1215 21.3877 45.6738Z'
          fill='#242631'
        />
        <path
          d='M27.3877 45.6738C27.3877 46.2261 26.94 46.6738 26.3877 46.6738C25.8354 46.6738 25.3877 46.2261 25.3877 45.6738C25.3877 45.1215 25.8354 44.6738 26.3877 44.6738C26.94 44.6738 27.3877 45.1215 27.3877 45.6738Z'
          fill='#242631'
        />
        <path
          d='M33.3877 45.6738C33.3877 46.2261 32.94 46.6738 32.3877 46.6738C31.8354 46.6738 31.3877 46.2261 31.3877 45.6738C31.3877 45.1215 31.8354 44.6738 32.3877 44.6738C32.94 44.6738 33.3877 45.1215 33.3877 45.6738Z'
          fill='#242631'
        />
        <path
          d='M39.3877 45.6738C39.3877 46.2261 38.94 46.6738 38.3877 46.6738C37.8354 46.6738 37.3877 46.2261 37.3877 45.6738C37.3877 45.1215 37.8354 44.6738 38.3877 44.6738C38.94 44.6738 39.3877 45.1215 39.3877 45.6738Z'
          fill='#242631'
        />
        <path
          d='M45.3877 45.6738C45.3877 46.2261 44.94 46.6738 44.3877 46.6738C43.8354 46.6738 43.3877 46.2261 43.3877 45.6738C43.3877 45.1215 43.8354 44.6738 44.3877 44.6738C44.94 44.6738 45.3877 45.1215 45.3877 45.6738Z'
          fill='#242631'
        />
        <path
          d='M51.3877 45.6738C51.3877 46.2261 50.94 46.6738 50.3877 46.6738C49.8354 46.6738 49.3877 46.2261 49.3877 45.6738C49.3877 45.1215 49.8354 44.6738 50.3877 44.6738C50.94 44.6738 51.3877 45.1215 51.3877 45.6738Z'
          fill='#242631'
        />
        <path
          d='M57.3877 45.6738C57.3877 46.2261 56.94 46.6738 56.3877 46.6738C55.8354 46.6738 55.3877 46.2261 55.3877 45.6738C55.3877 45.1215 55.8354 44.6738 56.3877 44.6738C56.94 44.6738 57.3877 45.1215 57.3877 45.6738Z'
          fill='#242631'
        />
        <path
          d='M63.3877 45.6738C63.3877 46.2261 62.94 46.6738 62.3877 46.6738C61.8354 46.6738 61.3877 46.2261 61.3877 45.6738C61.3877 45.1215 61.8354 44.6738 62.3877 44.6738C62.94 44.6738 63.3877 45.1215 63.3877 45.6738Z'
          fill='#242631'
        />
        <path
          d='M69.3877 45.6738C69.3877 46.2261 68.94 46.6738 68.3877 46.6738C67.8354 46.6738 67.3877 46.2261 67.3877 45.6738C67.3877 45.1215 67.8354 44.6738 68.3877 44.6738C68.94 44.6738 69.3877 45.1215 69.3877 45.6738Z'
          fill='#242631'
        />
        <path
          d='M75.3877 45.6738C75.3877 46.2261 74.94 46.6738 74.3877 46.6738C73.8354 46.6738 73.3877 46.2261 73.3877 45.6738C73.3877 45.1215 73.8354 44.6738 74.3877 44.6738C74.94 44.6738 75.3877 45.1215 75.3877 45.6738Z'
          fill='#242631'
        />
        <path
          d='M81.3877 45.6738C81.3877 46.2261 80.94 46.6738 80.3877 46.6738C79.8354 46.6738 79.3877 46.2261 79.3877 45.6738C79.3877 45.1215 79.8354 44.6738 80.3877 44.6738C80.94 44.6738 81.3877 45.1215 81.3877 45.6738Z'
          fill='#242631'
        />
        <path
          d='M87.3877 45.6738C87.3877 46.2261 86.94 46.6738 86.3877 46.6738C85.8354 46.6738 85.3877 46.2261 85.3877 45.6738C85.3877 45.1215 85.8354 44.6738 86.3877 44.6738C86.94 44.6738 87.3877 45.1215 87.3877 45.6738Z'
          fill='#242631'
        />
        <path
          d='M93.3877 45.6738C93.3877 46.2261 92.94 46.6738 92.3877 46.6738C91.8354 46.6738 91.3877 46.2261 91.3877 45.6738C91.3877 45.1215 91.8354 44.6738 92.3877 44.6738C92.94 44.6738 93.3877 45.1215 93.3877 45.6738Z'
          fill='#242631'
        />
        <path
          d='M99.3877 45.6738C99.3877 46.2261 98.94 46.6738 98.3877 46.6738C97.8354 46.6738 97.3877 46.2261 97.3877 45.6738C97.3877 45.1215 97.8354 44.6738 98.3877 44.6738C98.94 44.6738 99.3877 45.1215 99.3877 45.6738Z'
          fill='#242631'
        />
        <path
          d='M105.388 45.6738C105.388 46.2261 104.94 46.6738 104.388 46.6738C103.835 46.6738 103.388 46.2261 103.388 45.6738C103.388 45.1215 103.835 44.6738 104.388 44.6738C104.94 44.6738 105.388 45.1215 105.388 45.6738Z'
          fill='#242631'
        />
        <path
          d='M111.388 45.6738C111.388 46.2261 110.94 46.6738 110.388 46.6738C109.835 46.6738 109.388 46.2261 109.388 45.6738C109.388 45.1215 109.835 44.6738 110.388 44.6738C110.94 44.6738 111.388 45.1215 111.388 45.6738Z'
          fill='#242631'
        />
        <path
          d='M117.388 45.6738C117.388 46.2261 116.94 46.6738 116.388 46.6738C115.835 46.6738 115.388 46.2261 115.388 45.6738C115.388 45.1215 115.835 44.6738 116.388 44.6738C116.94 44.6738 117.388 45.1215 117.388 45.6738Z'
          fill='#242631'
        />
        <path
          d='M123.388 45.6738C123.388 46.2261 122.94 46.6738 122.388 46.6738C121.835 46.6738 121.388 46.2261 121.388 45.6738C121.388 45.1215 121.835 44.6738 122.388 44.6738C122.94 44.6738 123.388 45.1215 123.388 45.6738Z'
          fill='#242631'
        />
        <path
          d='M129.388 45.6738C129.388 46.2261 128.94 46.6738 128.388 46.6738C127.835 46.6738 127.388 46.2261 127.388 45.6738C127.388 45.1215 127.835 44.6738 128.388 44.6738C128.94 44.6738 129.388 45.1215 129.388 45.6738Z'
          fill='#242631'
        />
        <circle cx='134.388' cy='45.6738' r='1' fill='#242631' />
        <circle cx='140.388' cy='45.6738' r='1' fill='#242631' />
        <circle cx='146.388' cy='45.6738' r='1' fill='#242631' />
        <circle cx='152.388' cy='45.6738' r='1' fill='#242631' />
        <circle cx='158.388' cy='45.6738' r='1' fill='#242631' />
        <circle cx='164.388' cy='45.6738' r='1' fill='#242631' />
        <circle cx='170.388' cy='45.6738' r='1' fill='#242631' />
        <circle cx='176.388' cy='45.6738' r='1' fill='#242631' />
        <circle cx='182.388' cy='45.6738' r='1' fill='#242631' />
        <circle cx='188.388' cy='45.6738' r='1' fill='#242631' />
        <circle cx='194.388' cy='45.6738' r='1' fill='#242631' />
        <circle cx='200.388' cy='45.6738' r='1' fill='#242631' />
        <circle cx='2.3877' cy='164.803' r='1' fill='#242631' />
        <circle cx='8.3877' cy='164.803' r='1' fill='#242631' />
        <path
          d='M15.3877 164.803C15.3877 165.355 14.94 165.803 14.3877 165.803C13.8354 165.803 13.3877 165.355 13.3877 164.803C13.3877 164.25 13.8354 163.803 14.3877 163.803C14.94 163.803 15.3877 164.25 15.3877 164.803Z'
          fill='#242631'
        />
        <path
          d='M21.3877 164.803C21.3877 165.355 20.94 165.803 20.3877 165.803C19.8354 165.803 19.3877 165.355 19.3877 164.803C19.3877 164.25 19.8354 163.803 20.3877 163.803C20.94 163.803 21.3877 164.25 21.3877 164.803Z'
          fill='#242631'
        />
        <path
          d='M27.3877 164.803C27.3877 165.355 26.94 165.803 26.3877 165.803C25.8354 165.803 25.3877 165.355 25.3877 164.803C25.3877 164.25 25.8354 163.803 26.3877 163.803C26.94 163.803 27.3877 164.25 27.3877 164.803Z'
          fill='#242631'
        />
        <path
          d='M33.3877 164.803C33.3877 165.355 32.94 165.803 32.3877 165.803C31.8354 165.803 31.3877 165.355 31.3877 164.803C31.3877 164.25 31.8354 163.803 32.3877 163.803C32.94 163.803 33.3877 164.25 33.3877 164.803Z'
          fill='#242631'
        />
        <path
          d='M39.3877 164.803C39.3877 165.355 38.94 165.803 38.3877 165.803C37.8354 165.803 37.3877 165.355 37.3877 164.803C37.3877 164.25 37.8354 163.803 38.3877 163.803C38.94 163.803 39.3877 164.25 39.3877 164.803Z'
          fill='#242631'
        />
        <path
          d='M45.3877 164.803C45.3877 165.355 44.94 165.803 44.3877 165.803C43.8354 165.803 43.3877 165.355 43.3877 164.803C43.3877 164.25 43.8354 163.803 44.3877 163.803C44.94 163.803 45.3877 164.25 45.3877 164.803Z'
          fill='#242631'
        />
        <path
          d='M51.3877 164.803C51.3877 165.355 50.94 165.803 50.3877 165.803C49.8354 165.803 49.3877 165.355 49.3877 164.803C49.3877 164.25 49.8354 163.803 50.3877 163.803C50.94 163.803 51.3877 164.25 51.3877 164.803Z'
          fill='#242631'
        />
        <path
          d='M57.3877 164.803C57.3877 165.355 56.94 165.803 56.3877 165.803C55.8354 165.803 55.3877 165.355 55.3877 164.803C55.3877 164.25 55.8354 163.803 56.3877 163.803C56.94 163.803 57.3877 164.25 57.3877 164.803Z'
          fill='#242631'
        />
        <path
          d='M63.3877 164.803C63.3877 165.355 62.94 165.803 62.3877 165.803C61.8354 165.803 61.3877 165.355 61.3877 164.803C61.3877 164.25 61.8354 163.803 62.3877 163.803C62.94 163.803 63.3877 164.25 63.3877 164.803Z'
          fill='#242631'
        />
        <path
          d='M69.3877 164.803C69.3877 165.355 68.94 165.803 68.3877 165.803C67.8354 165.803 67.3877 165.355 67.3877 164.803C67.3877 164.25 67.8354 163.803 68.3877 163.803C68.94 163.803 69.3877 164.25 69.3877 164.803Z'
          fill='#242631'
        />
        <path
          d='M75.3877 164.803C75.3877 165.355 74.94 165.803 74.3877 165.803C73.8354 165.803 73.3877 165.355 73.3877 164.803C73.3877 164.25 73.8354 163.803 74.3877 163.803C74.94 163.803 75.3877 164.25 75.3877 164.803Z'
          fill='#242631'
        />
        <path
          d='M81.3877 164.803C81.3877 165.355 80.94 165.803 80.3877 165.803C79.8354 165.803 79.3877 165.355 79.3877 164.803C79.3877 164.25 79.8354 163.803 80.3877 163.803C80.94 163.803 81.3877 164.25 81.3877 164.803Z'
          fill='#242631'
        />
        <path
          d='M87.3877 164.803C87.3877 165.355 86.94 165.803 86.3877 165.803C85.8354 165.803 85.3877 165.355 85.3877 164.803C85.3877 164.25 85.8354 163.803 86.3877 163.803C86.94 163.803 87.3877 164.25 87.3877 164.803Z'
          fill='#242631'
        />
        <path
          d='M93.3877 164.803C93.3877 165.355 92.94 165.803 92.3877 165.803C91.8354 165.803 91.3877 165.355 91.3877 164.803C91.3877 164.25 91.8354 163.803 92.3877 163.803C92.94 163.803 93.3877 164.25 93.3877 164.803Z'
          fill='#242631'
        />
        <path
          d='M99.3877 164.803C99.3877 165.355 98.94 165.803 98.3877 165.803C97.8354 165.803 97.3877 165.355 97.3877 164.803C97.3877 164.25 97.8354 163.803 98.3877 163.803C98.94 163.803 99.3877 164.25 99.3877 164.803Z'
          fill='#242631'
        />
        <path
          d='M105.388 164.803C105.388 165.355 104.94 165.803 104.388 165.803C103.835 165.803 103.388 165.355 103.388 164.803C103.388 164.25 103.835 163.803 104.388 163.803C104.94 163.803 105.388 164.25 105.388 164.803Z'
          fill='#242631'
        />
        <path
          d='M111.388 164.803C111.388 165.355 110.94 165.803 110.388 165.803C109.835 165.803 109.388 165.355 109.388 164.803C109.388 164.25 109.835 163.803 110.388 163.803C110.94 163.803 111.388 164.25 111.388 164.803Z'
          fill='#242631'
        />
        <path
          d='M117.388 164.803C117.388 165.355 116.94 165.803 116.388 165.803C115.835 165.803 115.388 165.355 115.388 164.803C115.388 164.25 115.835 163.803 116.388 163.803C116.94 163.803 117.388 164.25 117.388 164.803Z'
          fill='#242631'
        />
        <path
          d='M123.388 164.803C123.388 165.355 122.94 165.803 122.388 165.803C121.835 165.803 121.388 165.355 121.388 164.803C121.388 164.25 121.835 163.803 122.388 163.803C122.94 163.803 123.388 164.25 123.388 164.803Z'
          fill='#242631'
        />
        <path
          d='M129.388 164.803C129.388 165.355 128.94 165.803 128.388 165.803C127.835 165.803 127.388 165.355 127.388 164.803C127.388 164.25 127.835 163.803 128.388 163.803C128.94 163.803 129.388 164.25 129.388 164.803Z'
          fill='#242631'
        />
        <circle cx='134.388' cy='164.803' r='1' fill='#242631' />
        <circle cx='140.388' cy='164.803' r='1' fill='#242631' />
        <circle cx='146.388' cy='164.803' r='1' fill='#242631' />
        <circle cx='152.388' cy='164.803' r='1' fill='#242631' />
        <circle cx='158.388' cy='164.803' r='1' fill='#242631' />
        <circle cx='164.388' cy='164.803' r='1' fill='#242631' />
        <circle cx='170.388' cy='164.803' r='1' fill='#242631' />
        <circle cx='176.388' cy='164.803' r='1' fill='#242631' />
        <circle cx='182.388' cy='164.803' r='1' fill='#242631' />
        <circle cx='188.388' cy='164.803' r='1' fill='#242631' />
        <circle cx='194.388' cy='164.803' r='1' fill='#242631' />
        <circle cx='200.388' cy='164.803' r='1' fill='#242631' />
        <circle cx='2.3877' cy='69.5' r='1' fill='#242631' />
        <circle cx='8.3877' cy='69.5' r='1' fill='#242631' />
        <path
          d='M15.3877 69.5C15.3877 70.0523 14.94 70.5 14.3877 70.5C13.8354 70.5 13.3877 70.0523 13.3877 69.5C13.3877 68.9477 13.8354 68.5 14.3877 68.5C14.94 68.5 15.3877 68.9477 15.3877 69.5Z'
          fill='#242631'
        />
        <path
          d='M21.3877 69.5C21.3877 70.0523 20.94 70.5 20.3877 70.5C19.8354 70.5 19.3877 70.0523 19.3877 69.5C19.3877 68.9477 19.8354 68.5 20.3877 68.5C20.94 68.5 21.3877 68.9477 21.3877 69.5Z'
          fill='#242631'
        />
        <path
          d='M27.3877 69.5C27.3877 70.0523 26.94 70.5 26.3877 70.5C25.8354 70.5 25.3877 70.0523 25.3877 69.5C25.3877 68.9477 25.8354 68.5 26.3877 68.5C26.94 68.5 27.3877 68.9477 27.3877 69.5Z'
          fill='#242631'
        />
        <path
          d='M33.3877 69.5C33.3877 70.0523 32.94 70.5 32.3877 70.5C31.8354 70.5 31.3877 70.0523 31.3877 69.5C31.3877 68.9477 31.8354 68.5 32.3877 68.5C32.94 68.5 33.3877 68.9477 33.3877 69.5Z'
          fill='#242631'
        />
        <path
          d='M39.3877 69.5C39.3877 70.0523 38.94 70.5 38.3877 70.5C37.8354 70.5 37.3877 70.0523 37.3877 69.5C37.3877 68.9477 37.8354 68.5 38.3877 68.5C38.94 68.5 39.3877 68.9477 39.3877 69.5Z'
          fill='#242631'
        />
        <path
          d='M45.3877 69.5C45.3877 70.0523 44.94 70.5 44.3877 70.5C43.8354 70.5 43.3877 70.0523 43.3877 69.5C43.3877 68.9477 43.8354 68.5 44.3877 68.5C44.94 68.5 45.3877 68.9477 45.3877 69.5Z'
          fill='#242631'
        />
        <path
          d='M51.3877 69.5C51.3877 70.0523 50.94 70.5 50.3877 70.5C49.8354 70.5 49.3877 70.0523 49.3877 69.5C49.3877 68.9477 49.8354 68.5 50.3877 68.5C50.94 68.5 51.3877 68.9477 51.3877 69.5Z'
          fill='#242631'
        />
        <path
          d='M57.3877 69.5C57.3877 70.0523 56.94 70.5 56.3877 70.5C55.8354 70.5 55.3877 70.0523 55.3877 69.5C55.3877 68.9477 55.8354 68.5 56.3877 68.5C56.94 68.5 57.3877 68.9477 57.3877 69.5Z'
          fill='#242631'
        />
        <path
          d='M63.3877 69.5C63.3877 70.0523 62.94 70.5 62.3877 70.5C61.8354 70.5 61.3877 70.0523 61.3877 69.5C61.3877 68.9477 61.8354 68.5 62.3877 68.5C62.94 68.5 63.3877 68.9477 63.3877 69.5Z'
          fill='#242631'
        />
        <path
          d='M69.3877 69.5C69.3877 70.0523 68.94 70.5 68.3877 70.5C67.8354 70.5 67.3877 70.0523 67.3877 69.5C67.3877 68.9477 67.8354 68.5 68.3877 68.5C68.94 68.5 69.3877 68.9477 69.3877 69.5Z'
          fill='#242631'
        />
        <path
          d='M75.3877 69.5C75.3877 70.0523 74.94 70.5 74.3877 70.5C73.8354 70.5 73.3877 70.0523 73.3877 69.5C73.3877 68.9477 73.8354 68.5 74.3877 68.5C74.94 68.5 75.3877 68.9477 75.3877 69.5Z'
          fill='#242631'
        />
        <path
          d='M81.3877 69.5C81.3877 70.0523 80.94 70.5 80.3877 70.5C79.8354 70.5 79.3877 70.0523 79.3877 69.5C79.3877 68.9477 79.8354 68.5 80.3877 68.5C80.94 68.5 81.3877 68.9477 81.3877 69.5Z'
          fill='#242631'
        />
        <path
          d='M87.3877 69.5C87.3877 70.0523 86.94 70.5 86.3877 70.5C85.8354 70.5 85.3877 70.0523 85.3877 69.5C85.3877 68.9477 85.8354 68.5 86.3877 68.5C86.94 68.5 87.3877 68.9477 87.3877 69.5Z'
          fill='#242631'
        />
        <path
          d='M93.3877 69.5C93.3877 70.0523 92.94 70.5 92.3877 70.5C91.8354 70.5 91.3877 70.0523 91.3877 69.5C91.3877 68.9477 91.8354 68.5 92.3877 68.5C92.94 68.5 93.3877 68.9477 93.3877 69.5Z'
          fill='#242631'
        />
        <path
          d='M99.3877 69.5C99.3877 70.0523 98.94 70.5 98.3877 70.5C97.8354 70.5 97.3877 70.0523 97.3877 69.5C97.3877 68.9477 97.8354 68.5 98.3877 68.5C98.94 68.5 99.3877 68.9477 99.3877 69.5Z'
          fill='#242631'
        />
        <path
          d='M105.388 69.5C105.388 70.0523 104.94 70.5 104.388 70.5C103.835 70.5 103.388 70.0523 103.388 69.5C103.388 68.9477 103.835 68.5 104.388 68.5C104.94 68.5 105.388 68.9477 105.388 69.5Z'
          fill='#242631'
        />
        <path
          d='M111.388 69.5C111.388 70.0523 110.94 70.5 110.388 70.5C109.835 70.5 109.388 70.0523 109.388 69.5C109.388 68.9477 109.835 68.5 110.388 68.5C110.94 68.5 111.388 68.9477 111.388 69.5Z'
          fill='#242631'
        />
        <path
          d='M117.388 69.5C117.388 70.0523 116.94 70.5 116.388 70.5C115.835 70.5 115.388 70.0523 115.388 69.5C115.388 68.9477 115.835 68.5 116.388 68.5C116.94 68.5 117.388 68.9477 117.388 69.5Z'
          fill='#242631'
        />
        <path
          d='M123.388 69.5C123.388 70.0523 122.94 70.5 122.388 70.5C121.835 70.5 121.388 70.0523 121.388 69.5C121.388 68.9477 121.835 68.5 122.388 68.5C122.94 68.5 123.388 68.9477 123.388 69.5Z'
          fill='#242631'
        />
        <path
          d='M129.388 69.5C129.388 70.0523 128.94 70.5 128.388 70.5C127.835 70.5 127.388 70.0523 127.388 69.5C127.388 68.9477 127.835 68.5 128.388 68.5C128.94 68.5 129.388 68.9477 129.388 69.5Z'
          fill='#242631'
        />
        <circle cx='134.388' cy='69.5' r='1' fill='#242631' />
        <circle cx='140.388' cy='69.5' r='1' fill='#242631' />
        <circle cx='146.388' cy='69.5' r='1' fill='#242631' />
        <circle cx='152.388' cy='69.5' r='1' fill='#242631' />
        <circle cx='158.388' cy='69.5' r='1' fill='#242631' />
        <circle cx='164.388' cy='69.5' r='1' fill='#242631' />
        <circle cx='170.388' cy='69.5' r='1' fill='#242631' />
        <circle cx='176.388' cy='69.5' r='1' fill='#242631' />
        <circle cx='182.388' cy='69.5' r='1' fill='#242631' />
        <circle cx='188.388' cy='69.5' r='1' fill='#242631' />
        <circle cx='194.388' cy='69.5' r='1' fill='#242631' />
        <circle cx='200.388' cy='69.5' r='1' fill='#242631' />
        <circle cx='2.3877' cy='188.629' r='1' fill='#242631' />
        <circle cx='8.3877' cy='188.629' r='1' fill='#242631' />
        <path
          d='M15.3877 188.629C15.3877 189.181 14.94 189.629 14.3877 189.629C13.8354 189.629 13.3877 189.181 13.3877 188.629C13.3877 188.077 13.8354 187.629 14.3877 187.629C14.94 187.629 15.3877 188.077 15.3877 188.629Z'
          fill='#242631'
        />
        <path
          d='M21.3877 188.629C21.3877 189.181 20.94 189.629 20.3877 189.629C19.8354 189.629 19.3877 189.181 19.3877 188.629C19.3877 188.077 19.8354 187.629 20.3877 187.629C20.94 187.629 21.3877 188.077 21.3877 188.629Z'
          fill='#242631'
        />
        <path
          d='M27.3877 188.629C27.3877 189.181 26.94 189.629 26.3877 189.629C25.8354 189.629 25.3877 189.181 25.3877 188.629C25.3877 188.077 25.8354 187.629 26.3877 187.629C26.94 187.629 27.3877 188.077 27.3877 188.629Z'
          fill='#242631'
        />
        <path
          d='M33.3877 188.629C33.3877 189.181 32.94 189.629 32.3877 189.629C31.8354 189.629 31.3877 189.181 31.3877 188.629C31.3877 188.077 31.8354 187.629 32.3877 187.629C32.94 187.629 33.3877 188.077 33.3877 188.629Z'
          fill='#242631'
        />
        <path
          d='M39.3877 188.629C39.3877 189.181 38.94 189.629 38.3877 189.629C37.8354 189.629 37.3877 189.181 37.3877 188.629C37.3877 188.077 37.8354 187.629 38.3877 187.629C38.94 187.629 39.3877 188.077 39.3877 188.629Z'
          fill='#242631'
        />
        <path
          d='M45.3877 188.629C45.3877 189.181 44.94 189.629 44.3877 189.629C43.8354 189.629 43.3877 189.181 43.3877 188.629C43.3877 188.077 43.8354 187.629 44.3877 187.629C44.94 187.629 45.3877 188.077 45.3877 188.629Z'
          fill='#242631'
        />
        <path
          d='M51.3877 188.629C51.3877 189.181 50.94 189.629 50.3877 189.629C49.8354 189.629 49.3877 189.181 49.3877 188.629C49.3877 188.077 49.8354 187.629 50.3877 187.629C50.94 187.629 51.3877 188.077 51.3877 188.629Z'
          fill='#242631'
        />
        <path
          d='M57.3877 188.629C57.3877 189.181 56.94 189.629 56.3877 189.629C55.8354 189.629 55.3877 189.181 55.3877 188.629C55.3877 188.077 55.8354 187.629 56.3877 187.629C56.94 187.629 57.3877 188.077 57.3877 188.629Z'
          fill='#242631'
        />
        <path
          d='M63.3877 188.629C63.3877 189.181 62.94 189.629 62.3877 189.629C61.8354 189.629 61.3877 189.181 61.3877 188.629C61.3877 188.077 61.8354 187.629 62.3877 187.629C62.94 187.629 63.3877 188.077 63.3877 188.629Z'
          fill='#242631'
        />
        <path
          d='M69.3877 188.629C69.3877 189.181 68.94 189.629 68.3877 189.629C67.8354 189.629 67.3877 189.181 67.3877 188.629C67.3877 188.077 67.8354 187.629 68.3877 187.629C68.94 187.629 69.3877 188.077 69.3877 188.629Z'
          fill='#242631'
        />
        <path
          d='M75.3877 188.629C75.3877 189.181 74.94 189.629 74.3877 189.629C73.8354 189.629 73.3877 189.181 73.3877 188.629C73.3877 188.077 73.8354 187.629 74.3877 187.629C74.94 187.629 75.3877 188.077 75.3877 188.629Z'
          fill='#242631'
        />
        <path
          d='M81.3877 188.629C81.3877 189.181 80.94 189.629 80.3877 189.629C79.8354 189.629 79.3877 189.181 79.3877 188.629C79.3877 188.077 79.8354 187.629 80.3877 187.629C80.94 187.629 81.3877 188.077 81.3877 188.629Z'
          fill='#242631'
        />
        <path
          d='M87.3877 188.629C87.3877 189.181 86.94 189.629 86.3877 189.629C85.8354 189.629 85.3877 189.181 85.3877 188.629C85.3877 188.077 85.8354 187.629 86.3877 187.629C86.94 187.629 87.3877 188.077 87.3877 188.629Z'
          fill='#242631'
        />
        <path
          d='M93.3877 188.629C93.3877 189.181 92.94 189.629 92.3877 189.629C91.8354 189.629 91.3877 189.181 91.3877 188.629C91.3877 188.077 91.8354 187.629 92.3877 187.629C92.94 187.629 93.3877 188.077 93.3877 188.629Z'
          fill='#242631'
        />
        <path
          d='M99.3877 188.629C99.3877 189.181 98.94 189.629 98.3877 189.629C97.8354 189.629 97.3877 189.181 97.3877 188.629C97.3877 188.077 97.8354 187.629 98.3877 187.629C98.94 187.629 99.3877 188.077 99.3877 188.629Z'
          fill='#242631'
        />
        <path
          d='M105.388 188.629C105.388 189.181 104.94 189.629 104.388 189.629C103.835 189.629 103.388 189.181 103.388 188.629C103.388 188.077 103.835 187.629 104.388 187.629C104.94 187.629 105.388 188.077 105.388 188.629Z'
          fill='#242631'
        />
        <path
          d='M111.388 188.629C111.388 189.181 110.94 189.629 110.388 189.629C109.835 189.629 109.388 189.181 109.388 188.629C109.388 188.077 109.835 187.629 110.388 187.629C110.94 187.629 111.388 188.077 111.388 188.629Z'
          fill='#242631'
        />
        <path
          d='M117.388 188.629C117.388 189.181 116.94 189.629 116.388 189.629C115.835 189.629 115.388 189.181 115.388 188.629C115.388 188.077 115.835 187.629 116.388 187.629C116.94 187.629 117.388 188.077 117.388 188.629Z'
          fill='#242631'
        />
        <path
          d='M123.388 188.629C123.388 189.181 122.94 189.629 122.388 189.629C121.835 189.629 121.388 189.181 121.388 188.629C121.388 188.077 121.835 187.629 122.388 187.629C122.94 187.629 123.388 188.077 123.388 188.629Z'
          fill='#242631'
        />
        <path
          d='M129.388 188.629C129.388 189.181 128.94 189.629 128.388 189.629C127.835 189.629 127.388 189.181 127.388 188.629C127.388 188.077 127.835 187.629 128.388 187.629C128.94 187.629 129.388 188.077 129.388 188.629Z'
          fill='#242631'
        />
        <circle cx='134.388' cy='188.629' r='1' fill='#242631' />
        <circle cx='140.388' cy='188.629' r='1' fill='#242631' />
        <circle cx='146.388' cy='188.629' r='1' fill='#242631' />
        <circle cx='152.388' cy='188.629' r='1' fill='#242631' />
        <circle cx='158.388' cy='188.629' r='1' fill='#242631' />
        <circle cx='164.388' cy='188.629' r='1' fill='#242631' />
        <circle cx='170.388' cy='188.629' r='1' fill='#242631' />
        <circle cx='176.388' cy='188.629' r='1' fill='#242631' />
        <circle cx='182.388' cy='188.629' r='1' fill='#242631' />
        <circle cx='188.388' cy='188.629' r='1' fill='#242631' />
        <circle cx='194.388' cy='188.629' r='1' fill='#242631' />
        <circle cx='200.388' cy='188.629' r='1' fill='#242631' />
        <circle cx='2.3877' cy='93.3262' r='1' fill='#242631' />
        <circle cx='8.3877' cy='93.3262' r='1' fill='#242631' />
        <path
          d='M15.3877 93.3262C15.3877 93.8785 14.94 94.3262 14.3877 94.3262C13.8354 94.3262 13.3877 93.8785 13.3877 93.3262C13.3877 92.7739 13.8354 92.3262 14.3877 92.3262C14.94 92.3262 15.3877 92.7739 15.3877 93.3262Z'
          fill='#242631'
        />
        <path
          d='M21.3877 93.3262C21.3877 93.8785 20.94 94.3262 20.3877 94.3262C19.8354 94.3262 19.3877 93.8785 19.3877 93.3262C19.3877 92.7739 19.8354 92.3262 20.3877 92.3262C20.94 92.3262 21.3877 92.7739 21.3877 93.3262Z'
          fill='#242631'
        />
        <path
          d='M27.3877 93.3262C27.3877 93.8785 26.94 94.3262 26.3877 94.3262C25.8354 94.3262 25.3877 93.8785 25.3877 93.3262C25.3877 92.7739 25.8354 92.3262 26.3877 92.3262C26.94 92.3262 27.3877 92.7739 27.3877 93.3262Z'
          fill='#242631'
        />
        <path
          d='M33.3877 93.3262C33.3877 93.8785 32.94 94.3262 32.3877 94.3262C31.8354 94.3262 31.3877 93.8785 31.3877 93.3262C31.3877 92.7739 31.8354 92.3262 32.3877 92.3262C32.94 92.3262 33.3877 92.7739 33.3877 93.3262Z'
          fill='#242631'
        />
        <path
          d='M39.3877 93.3262C39.3877 93.8785 38.94 94.3262 38.3877 94.3262C37.8354 94.3262 37.3877 93.8785 37.3877 93.3262C37.3877 92.7739 37.8354 92.3262 38.3877 92.3262C38.94 92.3262 39.3877 92.7739 39.3877 93.3262Z'
          fill='#242631'
        />
        <path
          d='M45.3877 93.3262C45.3877 93.8785 44.94 94.3262 44.3877 94.3262C43.8354 94.3262 43.3877 93.8785 43.3877 93.3262C43.3877 92.7739 43.8354 92.3262 44.3877 92.3262C44.94 92.3262 45.3877 92.7739 45.3877 93.3262Z'
          fill='#242631'
        />
        <path
          d='M51.3877 93.3262C51.3877 93.8785 50.94 94.3262 50.3877 94.3262C49.8354 94.3262 49.3877 93.8785 49.3877 93.3262C49.3877 92.7739 49.8354 92.3262 50.3877 92.3262C50.94 92.3262 51.3877 92.7739 51.3877 93.3262Z'
          fill='#242631'
        />
        <path
          d='M57.3877 93.3262C57.3877 93.8785 56.94 94.3262 56.3877 94.3262C55.8354 94.3262 55.3877 93.8785 55.3877 93.3262C55.3877 92.7739 55.8354 92.3262 56.3877 92.3262C56.94 92.3262 57.3877 92.7739 57.3877 93.3262Z'
          fill='#242631'
        />
        <path
          d='M63.3877 93.3262C63.3877 93.8785 62.94 94.3262 62.3877 94.3262C61.8354 94.3262 61.3877 93.8785 61.3877 93.3262C61.3877 92.7739 61.8354 92.3262 62.3877 92.3262C62.94 92.3262 63.3877 92.7739 63.3877 93.3262Z'
          fill='#242631'
        />
        <path
          d='M69.3877 93.3262C69.3877 93.8785 68.94 94.3262 68.3877 94.3262C67.8354 94.3262 67.3877 93.8785 67.3877 93.3262C67.3877 92.7739 67.8354 92.3262 68.3877 92.3262C68.94 92.3262 69.3877 92.7739 69.3877 93.3262Z'
          fill='#242631'
        />
        <path
          d='M75.3877 93.3262C75.3877 93.8785 74.94 94.3262 74.3877 94.3262C73.8354 94.3262 73.3877 93.8785 73.3877 93.3262C73.3877 92.7739 73.8354 92.3262 74.3877 92.3262C74.94 92.3262 75.3877 92.7739 75.3877 93.3262Z'
          fill='#242631'
        />
        <path
          d='M81.3877 93.3262C81.3877 93.8785 80.94 94.3262 80.3877 94.3262C79.8354 94.3262 79.3877 93.8785 79.3877 93.3262C79.3877 92.7739 79.8354 92.3262 80.3877 92.3262C80.94 92.3262 81.3877 92.7739 81.3877 93.3262Z'
          fill='#242631'
        />
        <path
          d='M87.3877 93.3262C87.3877 93.8785 86.94 94.3262 86.3877 94.3262C85.8354 94.3262 85.3877 93.8785 85.3877 93.3262C85.3877 92.7739 85.8354 92.3262 86.3877 92.3262C86.94 92.3262 87.3877 92.7739 87.3877 93.3262Z'
          fill='#242631'
        />
        <path
          d='M93.3877 93.3262C93.3877 93.8785 92.94 94.3262 92.3877 94.3262C91.8354 94.3262 91.3877 93.8785 91.3877 93.3262C91.3877 92.7739 91.8354 92.3262 92.3877 92.3262C92.94 92.3262 93.3877 92.7739 93.3877 93.3262Z'
          fill='#242631'
        />
        <path
          d='M99.3877 93.3262C99.3877 93.8785 98.94 94.3262 98.3877 94.3262C97.8354 94.3262 97.3877 93.8785 97.3877 93.3262C97.3877 92.7739 97.8354 92.3262 98.3877 92.3262C98.94 92.3262 99.3877 92.7739 99.3877 93.3262Z'
          fill='#242631'
        />
        <path
          d='M105.388 93.3262C105.388 93.8785 104.94 94.3262 104.388 94.3262C103.835 94.3262 103.388 93.8785 103.388 93.3262C103.388 92.7739 103.835 92.3262 104.388 92.3262C104.94 92.3262 105.388 92.7739 105.388 93.3262Z'
          fill='#242631'
        />
        <path
          d='M111.388 93.3262C111.388 93.8785 110.94 94.3262 110.388 94.3262C109.835 94.3262 109.388 93.8785 109.388 93.3262C109.388 92.7739 109.835 92.3262 110.388 92.3262C110.94 92.3262 111.388 92.7739 111.388 93.3262Z'
          fill='#242631'
        />
        <path
          d='M117.388 93.3262C117.388 93.8785 116.94 94.3262 116.388 94.3262C115.835 94.3262 115.388 93.8785 115.388 93.3262C115.388 92.7739 115.835 92.3262 116.388 92.3262C116.94 92.3262 117.388 92.7739 117.388 93.3262Z'
          fill='#242631'
        />
        <path
          d='M123.388 93.3262C123.388 93.8785 122.94 94.3262 122.388 94.3262C121.835 94.3262 121.388 93.8785 121.388 93.3262C121.388 92.7739 121.835 92.3262 122.388 92.3262C122.94 92.3262 123.388 92.7739 123.388 93.3262Z'
          fill='#242631'
        />
        <path
          d='M129.388 93.3262C129.388 93.8785 128.94 94.3262 128.388 94.3262C127.835 94.3262 127.388 93.8785 127.388 93.3262C127.388 92.7739 127.835 92.3262 128.388 92.3262C128.94 92.3262 129.388 92.7739 129.388 93.3262Z'
          fill='#242631'
        />
        <circle cx='134.388' cy='93.3262' r='1' fill='#242631' />
        <circle cx='140.388' cy='93.3262' r='1' fill='#242631' />
        <circle cx='146.388' cy='93.3262' r='1' fill='#242631' />
        <circle cx='152.388' cy='93.3262' r='1' fill='#242631' />
        <circle cx='158.388' cy='93.3262' r='1' fill='#242631' />
        <circle cx='164.388' cy='93.3262' r='1' fill='#242631' />
        <circle cx='170.388' cy='93.3262' r='1' fill='#242631' />
        <circle cx='176.388' cy='93.3262' r='1' fill='#242631' />
        <circle cx='182.388' cy='93.3262' r='1' fill='#242631' />
        <circle cx='188.388' cy='93.3262' r='1' fill='#242631' />
        <circle cx='194.388' cy='93.3262' r='1' fill='#242631' />
        <circle cx='200.388' cy='93.3262' r='1' fill='#242631' />
        <circle cx='2.3877' cy='117.152' r='1' fill='#242631' />
        <circle cx='8.3877' cy='117.152' r='1' fill='#242631' />
        <path
          d='M15.3877 117.152C15.3877 117.705 14.94 118.152 14.3877 118.152C13.8354 118.152 13.3877 117.705 13.3877 117.152C13.3877 116.6 13.8354 116.152 14.3877 116.152C14.94 116.152 15.3877 116.6 15.3877 117.152Z'
          fill='#242631'
        />
        <path
          d='M21.3877 117.152C21.3877 117.705 20.94 118.152 20.3877 118.152C19.8354 118.152 19.3877 117.705 19.3877 117.152C19.3877 116.6 19.8354 116.152 20.3877 116.152C20.94 116.152 21.3877 116.6 21.3877 117.152Z'
          fill='#242631'
        />
        <path
          d='M27.3877 117.152C27.3877 117.705 26.94 118.152 26.3877 118.152C25.8354 118.152 25.3877 117.705 25.3877 117.152C25.3877 116.6 25.8354 116.152 26.3877 116.152C26.94 116.152 27.3877 116.6 27.3877 117.152Z'
          fill='#242631'
        />
        <path
          d='M33.3877 117.152C33.3877 117.705 32.94 118.152 32.3877 118.152C31.8354 118.152 31.3877 117.705 31.3877 117.152C31.3877 116.6 31.8354 116.152 32.3877 116.152C32.94 116.152 33.3877 116.6 33.3877 117.152Z'
          fill='#242631'
        />
        <path
          d='M39.3877 117.152C39.3877 117.705 38.94 118.152 38.3877 118.152C37.8354 118.152 37.3877 117.705 37.3877 117.152C37.3877 116.6 37.8354 116.152 38.3877 116.152C38.94 116.152 39.3877 116.6 39.3877 117.152Z'
          fill='#242631'
        />
        <path
          d='M45.3877 117.152C45.3877 117.705 44.94 118.152 44.3877 118.152C43.8354 118.152 43.3877 117.705 43.3877 117.152C43.3877 116.6 43.8354 116.152 44.3877 116.152C44.94 116.152 45.3877 116.6 45.3877 117.152Z'
          fill='#242631'
        />
        <path
          d='M51.3877 117.152C51.3877 117.705 50.94 118.152 50.3877 118.152C49.8354 118.152 49.3877 117.705 49.3877 117.152C49.3877 116.6 49.8354 116.152 50.3877 116.152C50.94 116.152 51.3877 116.6 51.3877 117.152Z'
          fill='#242631'
        />
        <path
          d='M57.3877 117.152C57.3877 117.705 56.94 118.152 56.3877 118.152C55.8354 118.152 55.3877 117.705 55.3877 117.152C55.3877 116.6 55.8354 116.152 56.3877 116.152C56.94 116.152 57.3877 116.6 57.3877 117.152Z'
          fill='#242631'
        />
        <path
          d='M63.3877 117.152C63.3877 117.705 62.94 118.152 62.3877 118.152C61.8354 118.152 61.3877 117.705 61.3877 117.152C61.3877 116.6 61.8354 116.152 62.3877 116.152C62.94 116.152 63.3877 116.6 63.3877 117.152Z'
          fill='#242631'
        />
        <path
          d='M69.3877 117.152C69.3877 117.705 68.94 118.152 68.3877 118.152C67.8354 118.152 67.3877 117.705 67.3877 117.152C67.3877 116.6 67.8354 116.152 68.3877 116.152C68.94 116.152 69.3877 116.6 69.3877 117.152Z'
          fill='#242631'
        />
        <path
          d='M75.3877 117.152C75.3877 117.705 74.94 118.152 74.3877 118.152C73.8354 118.152 73.3877 117.705 73.3877 117.152C73.3877 116.6 73.8354 116.152 74.3877 116.152C74.94 116.152 75.3877 116.6 75.3877 117.152Z'
          fill='#242631'
        />
        <path
          d='M81.3877 117.152C81.3877 117.705 80.94 118.152 80.3877 118.152C79.8354 118.152 79.3877 117.705 79.3877 117.152C79.3877 116.6 79.8354 116.152 80.3877 116.152C80.94 116.152 81.3877 116.6 81.3877 117.152Z'
          fill='#242631'
        />
        <path
          d='M87.3877 117.152C87.3877 117.705 86.94 118.152 86.3877 118.152C85.8354 118.152 85.3877 117.705 85.3877 117.152C85.3877 116.6 85.8354 116.152 86.3877 116.152C86.94 116.152 87.3877 116.6 87.3877 117.152Z'
          fill='#242631'
        />
        <path
          d='M93.3877 117.152C93.3877 117.705 92.94 118.152 92.3877 118.152C91.8354 118.152 91.3877 117.705 91.3877 117.152C91.3877 116.6 91.8354 116.152 92.3877 116.152C92.94 116.152 93.3877 116.6 93.3877 117.152Z'
          fill='#242631'
        />
        <path
          d='M99.3877 117.152C99.3877 117.705 98.94 118.152 98.3877 118.152C97.8354 118.152 97.3877 117.705 97.3877 117.152C97.3877 116.6 97.8354 116.152 98.3877 116.152C98.94 116.152 99.3877 116.6 99.3877 117.152Z'
          fill='#242631'
        />
        <path
          d='M105.388 117.152C105.388 117.705 104.94 118.152 104.388 118.152C103.835 118.152 103.388 117.705 103.388 117.152C103.388 116.6 103.835 116.152 104.388 116.152C104.94 116.152 105.388 116.6 105.388 117.152Z'
          fill='#242631'
        />
        <path
          d='M111.388 117.152C111.388 117.705 110.94 118.152 110.388 118.152C109.835 118.152 109.388 117.705 109.388 117.152C109.388 116.6 109.835 116.152 110.388 116.152C110.94 116.152 111.388 116.6 111.388 117.152Z'
          fill='#242631'
        />
        <path
          d='M117.388 117.152C117.388 117.705 116.94 118.152 116.388 118.152C115.835 118.152 115.388 117.705 115.388 117.152C115.388 116.6 115.835 116.152 116.388 116.152C116.94 116.152 117.388 116.6 117.388 117.152Z'
          fill='#242631'
        />
        <path
          d='M123.388 117.152C123.388 117.705 122.94 118.152 122.388 118.152C121.835 118.152 121.388 117.705 121.388 117.152C121.388 116.6 121.835 116.152 122.388 116.152C122.94 116.152 123.388 116.6 123.388 117.152Z'
          fill='#242631'
        />
        <path
          d='M129.388 117.152C129.388 117.705 128.94 118.152 128.388 118.152C127.835 118.152 127.388 117.705 127.388 117.152C127.388 116.6 127.835 116.152 128.388 116.152C128.94 116.152 129.388 116.6 129.388 117.152Z'
          fill='#242631'
        />
        <circle cx='134.388' cy='117.152' r='1' fill='#242631' />
        <circle cx='140.388' cy='117.152' r='1' fill='#242631' />
        <circle cx='146.388' cy='117.152' r='1' fill='#242631' />
        <circle cx='152.388' cy='117.152' r='1' fill='#242631' />
        <circle cx='158.388' cy='117.152' r='1' fill='#242631' />
        <circle cx='164.388' cy='117.152' r='1' fill='#242631' />
        <circle cx='170.388' cy='117.152' r='1' fill='#242631' />
        <circle cx='176.388' cy='117.152' r='1' fill='#242631' />
        <circle cx='182.388' cy='117.152' r='1' fill='#242631' />
        <circle cx='188.388' cy='117.152' r='1' fill='#242631' />
        <circle cx='194.388' cy='117.152' r='1' fill='#242631' />
        <circle cx='200.388' cy='117.152' r='1' fill='#242631' />
      </g>
      <rect
        width='11.8044'
        height='29.511'
        transform='matrix(0.703658 -0.710539 0.703658 0.710539 138.514 146.262)'
        fill='#6F717C'
      />
      <ellipse
        cx='98.3783'
        cy='101.302'
        rx='67.5443'
        ry='68.2048'
        fill='#89F7C4'
      />
      <ellipse
        cx='101.315'
        cy='98.3354'
        rx='66.5654'
        ry='67.2163'
        fill='white'
      />
      <circle cx='100.5' cy='97.5' r='54.5' fill='#303341' />
      <mask
        id='mask1_6970_52958'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='46'
        y='43'
        width='109'
        height='109'
      >
        <circle cx='100.5' cy='97.5' r='54' fill='#453838' stroke='#242631' />
      </mask>
      <g mask='url(#mask1_6970_52958)'>
        <path
          d='M100.712 55.1826L100.562 54.8076C100.521 54.7058 100.5 54.5971 100.5 54.4874C100.5 54.0112 100.886 53.6252 101.363 53.6252H101.955C102.34 53.6252 102.71 53.7784 102.983 54.0511L103.544 54.6119C103.945 55.0134 103.945 55.6645 103.544 56.0661C103.351 56.259 103.089 56.3673 102.817 56.3673H102.462C101.691 56.3673 100.998 55.8981 100.712 55.1826Z'
          fill='#FF9B63'
        />
        <path
          d='M106.172 58.4455L106.016 58.2378C105.802 57.9512 105.83 57.5502 106.083 57.2969C106.393 56.9872 106.905 57.0236 107.168 57.374L107.324 57.5817C107.539 57.8683 107.51 58.2693 107.257 58.5226C106.947 58.8323 106.435 58.7959 106.172 58.4455Z'
          fill='#FF9B63'
        />
        <path
          d='M122.741 50.8438L121.664 49.7665C121.168 49.2703 121.226 48.4497 121.787 48.0287C121.987 47.879 122.23 47.7981 122.479 47.7981H124.271C124.86 47.7981 125.432 47.9944 125.897 48.3559L127.144 49.3258C127.739 49.7887 127.794 50.6683 127.261 51.2014C127.026 51.4365 126.707 51.5685 126.374 51.5685H124.491C123.834 51.5685 123.205 51.3079 122.741 50.8438Z'
          fill='#FF9B63'
        />
        <path
          d='M75.5906 62.7616L75.1828 61.946C75.1514 61.8831 75.1244 61.8181 75.1022 61.7514L75.0018 61.4501C74.7303 60.6358 75.3364 59.795 76.1947 59.795C76.605 59.795 76.9896 59.9952 77.2249 60.3313L79.0802 62.9818C79.191 63.1401 79.263 63.3224 79.2904 63.5137L79.3582 63.9885C79.462 64.715 78.7409 65.2817 78.0595 65.0092C77.9396 64.9612 77.8306 64.8894 77.7393 64.798L76.6841 63.7429C76.5666 63.6253 76.4309 63.5275 76.2822 63.4532C75.9829 63.3035 75.7402 63.0609 75.5906 62.7616Z'
          fill='#FF9B63'
        />
        <path
          d='M62.4805 69.8949L62.0309 69.9214C60.3101 70.0226 58.6786 70.7217 57.4184 71.8979L55.1312 74.0326C54.5377 74.5865 54.0923 75.2802 53.8356 76.0503C53.4277 77.2741 53.5226 78.6098 54.0995 79.7636L55.2857 82.1361C55.7144 82.9935 55.9046 83.9504 55.8363 84.9066L55.7453 86.1799C55.6551 87.4428 56.3067 96.0292 55.1312 96.4994C54.2672 96.845 53.5469 92.0102 51.4416 89.4735C51.0355 88.9842 50.4969 88.6314 49.9487 88.309C47.8386 87.0677 46.8656 84.5336 47.6028 82.1991L48.3997 79.6755L49.3573 75.8451C50.0801 72.9541 51.466 70.2712 53.4053 68.0086L59.6848 60.6825C60.3693 59.8839 61.2944 59.3288 62.3212 59.1007C63.2993 58.8833 64.3207 58.9735 65.2455 59.3589L65.6 59.5066C67.064 60.1166 68.1357 61.4057 68.4681 62.9565C68.782 64.4214 68.3991 65.949 67.4314 67.0926L66.952 67.6592C65.8302 68.9849 64.2142 69.7929 62.4805 69.8949Z'
          fill='#FF9B63'
        />
        <path
          d='M56.359 75.9631L57.0744 74.8898C57.2227 74.6675 57.4722 74.534 57.7394 74.534C58.2332 74.534 58.6089 74.9774 58.5277 75.4645L58.3787 76.3582C58.353 76.5122 58.3045 76.6616 58.2346 76.8013L57.7238 77.823C57.505 78.2606 56.9521 78.4075 56.545 78.1361C56.3767 78.0238 56.2562 77.8529 56.2071 77.6566L56.1235 77.3222C56.007 76.8562 56.0925 76.3627 56.359 75.9631Z'
          fill='#FF9B63'
        />
        <path
          d='M76.2123 104.041L73.6117 102.481C71.9038 101.456 70.0031 100.794 68.0281 100.536L64.1067 100.025C63.479 99.9431 62.8961 99.6561 62.4485 99.2085C61.8047 98.5647 60.8937 98.2646 59.9933 98.3996L57.8085 98.7274C55.7607 99.0345 53.9626 100.254 52.9193 102.042L52.8262 102.202C52.3939 102.943 52.126 103.768 52.0406 104.622L51.9654 105.374C51.8762 106.266 52.0993 107.162 52.5966 107.908C52.9903 108.498 53.2141 109.186 53.2437 109.895L53.3998 113.642C53.4924 115.865 54.1787 118.022 55.3874 119.89C56.6453 121.834 58.4203 123.389 60.513 124.381L63.8243 125.949C66.435 127.254 68.3449 129.635 69.0528 132.467L69.6513 134.861L72.193 142.168C72.3257 142.55 72.3935 142.951 72.3935 143.355C72.3935 144.482 72.9198 145.545 73.8165 146.228L74.6043 146.828C77.2648 148.856 81.0966 148.12 82.8174 145.252L82.8876 145.135C83.4223 144.244 83.7048 143.224 83.7048 142.185V136.888C83.7048 135.1 84.0801 133.331 84.8064 131.697C85.8741 129.295 87.6564 127.28 89.9105 125.927L91.6433 124.888C92.7367 124.232 93.6777 123.35 94.4035 122.302C95.6795 120.459 96.2192 118.205 95.9163 115.983L95.8483 115.485C95.5316 113.162 94.4295 111.018 92.7253 109.408L92.4578 109.156C90.3586 107.173 87.5804 106.069 84.6929 106.069H83.5324C80.9537 106.069 78.4235 105.368 76.2123 104.041Z'
          fill='#FF9B63'
        />
        <path
          d='M71.5244 56.3071L68.6291 54.5253C68.3979 54.3831 68.1903 54.2058 68.0137 53.9997C67.0398 52.8635 67.2153 51.1436 68.3986 50.2275L74.4786 45.5204C76.0247 44.3234 77.8897 43.6094 79.8398 43.4679L115.015 40.9148C117.153 40.7596 118.818 42.7395 118.298 44.819C118.095 45.6291 117.579 46.3257 116.863 46.7553L113.873 48.5492C112.076 49.6277 110.019 50.1975 107.922 50.1975H103.896C103.01 50.1975 102.132 50.3542 101.301 50.6603C98.1828 51.809 96.1911 54.871 96.4051 58.187L96.4349 58.6489C96.6179 61.4853 95.2953 64.2089 92.9531 65.8192C91.833 66.5893 90.5365 67.064 89.1839 67.1993L89.0799 67.2097C86.1637 67.5013 83.3037 66.266 81.5167 63.943L80.9626 63.2226L78.3418 59.2913C77.4094 57.8928 75.8399 57.0528 74.1591 57.0528C73.2288 57.0528 72.3167 56.7947 71.5244 56.3071Z'
          fill='#FF9B63'
        />
        <path
          d='M126.903 53.8284L133.231 51.5685C134.942 50.927 136.767 50.9183 138.418 51.4582C141.392 52.4311 142.469 55.8158 142.907 58.9143L143.522 63.2664C143.557 63.5102 143.655 63.7399 143.794 63.943C145.613 66.5991 146.207 70.6762 145.307 74.4426C144.934 76.0056 144.617 77.6039 144.768 79.2038L144.774 79.2709C144.951 81.1375 145.687 82.9074 146.885 84.3491L147.617 85.2292C148.535 86.3334 149.037 87.724 149.037 89.16V89.8193C149.037 91.2025 148.431 92.5161 147.378 93.4131C145.959 94.6216 145.384 96.5515 145.91 98.3394L146.464 100.22C147.402 103.411 147.615 106.79 148.409 110.02C149.598 114.853 152.232 118.69 151.183 121.147C151.079 121.389 150.966 121.629 150.897 121.883L150.019 125.147C149.368 127.565 148.35 130.604 145.849 130.458C145.581 130.443 145.311 130.396 145.045 130.316C144.49 130.149 143.985 129.848 143.575 129.438L141.176 127.039C140.224 126.087 139.163 125.252 138.013 124.551L128.521 118.764C126.654 117.625 125.042 116.112 123.788 114.32C121.903 111.628 119.229 109.588 116.134 108.483L106.495 105.04L99.3701 101.478C96.9268 100.256 95.1278 98.0417 94.4327 95.4C94.0258 93.854 94.0158 92.2303 94.4035 90.6793L95.6898 85.5342C96.252 83.2853 96.0913 80.9164 95.2303 78.7641L94.9359 78.0279C94.2081 76.2086 94.1131 74.1975 94.666 72.3177C95.2231 70.4235 96.4053 68.7739 98.0199 67.6377L100.832 65.659C103.422 63.8365 106.434 62.7059 109.584 62.3743L112.569 62.0601C115.939 61.7053 119.058 60.1099 121.318 57.5839C122.837 55.8858 124.757 54.5947 126.903 53.8284Z'
          fill='#FF9B63'
        />
        <path
          d='M100.712 55.1826L100.562 54.8076C100.521 54.7058 100.5 54.5971 100.5 54.4874C100.5 54.0112 100.886 53.6252 101.363 53.6252H101.955C102.34 53.6252 102.71 53.7784 102.983 54.0511L103.544 54.6119C103.945 55.0134 103.945 55.6645 103.544 56.0661C103.351 56.259 103.089 56.3673 102.817 56.3673H102.462C101.691 56.3673 100.998 55.8981 100.712 55.1826Z'
          stroke='#242631'
        />
        <path
          d='M106.172 58.4455L106.016 58.2378C105.802 57.9512 105.83 57.5502 106.083 57.2969C106.393 56.9872 106.905 57.0236 107.168 57.374L107.324 57.5817C107.539 57.8683 107.51 58.2693 107.257 58.5226C106.947 58.8323 106.435 58.7959 106.172 58.4455Z'
          stroke='#242631'
        />
        <path
          d='M122.741 50.8438L121.664 49.7665C121.168 49.2703 121.226 48.4497 121.787 48.0287C121.987 47.879 122.23 47.7981 122.479 47.7981H124.271C124.86 47.7981 125.432 47.9944 125.897 48.3559L127.144 49.3258C127.739 49.7887 127.794 50.6683 127.261 51.2014C127.026 51.4365 126.707 51.5685 126.374 51.5685H124.491C123.834 51.5685 123.205 51.3079 122.741 50.8438Z'
          stroke='#242631'
        />
        <path
          d='M75.5906 62.7616L75.1828 61.946C75.1514 61.8831 75.1244 61.8181 75.1022 61.7514L75.0018 61.4501C74.7303 60.6358 75.3364 59.795 76.1947 59.795C76.605 59.795 76.9896 59.9952 77.2249 60.3313L79.0802 62.9818C79.191 63.1401 79.263 63.3224 79.2904 63.5137L79.3582 63.9885C79.462 64.715 78.7409 65.2817 78.0595 65.0092C77.9396 64.9612 77.8306 64.8894 77.7393 64.798L76.6841 63.7429C76.5666 63.6253 76.4309 63.5275 76.2822 63.4532C75.9829 63.3035 75.7402 63.0609 75.5906 62.7616Z'
          stroke='#242631'
        />
        <path
          d='M62.4805 69.8949L62.0309 69.9214C60.3101 70.0226 58.6786 70.7217 57.4184 71.8979L55.1312 74.0326C54.5377 74.5865 54.0923 75.2802 53.8356 76.0503C53.4277 77.2741 53.5226 78.6098 54.0995 79.7636L55.2857 82.1361C55.7144 82.9935 55.9046 83.9504 55.8363 84.9066L55.7453 86.1799C55.6551 87.4428 56.3067 96.0292 55.1312 96.4994C54.2672 96.845 53.5469 92.0102 51.4416 89.4735C51.0355 88.9842 50.4969 88.6314 49.9487 88.309C47.8386 87.0677 46.8656 84.5336 47.6028 82.1991L48.3997 79.6755L49.3573 75.8451C50.0801 72.9541 51.466 70.2712 53.4053 68.0086L59.6848 60.6825C60.3693 59.8839 61.2944 59.3288 62.3212 59.1007C63.2993 58.8833 64.3207 58.9735 65.2455 59.3589L65.6 59.5066C67.064 60.1166 68.1357 61.4057 68.4681 62.9565C68.782 64.4214 68.3991 65.949 67.4314 67.0926L66.952 67.6592C65.8302 68.9849 64.2142 69.7929 62.4805 69.8949Z'
          stroke='#242631'
        />
        <path
          d='M56.359 75.9631L57.0744 74.8898C57.2227 74.6675 57.4722 74.534 57.7394 74.534C58.2332 74.534 58.6089 74.9774 58.5277 75.4645L58.3787 76.3582C58.353 76.5122 58.3045 76.6616 58.2346 76.8013L57.7238 77.823C57.505 78.2606 56.9521 78.4075 56.545 78.1361C56.3767 78.0238 56.2562 77.8529 56.2071 77.6566L56.1235 77.3222C56.007 76.8562 56.0925 76.3627 56.359 75.9631Z'
          stroke='#242631'
        />
        <path
          d='M76.2123 104.041L73.6117 102.481C71.9038 101.456 70.0031 100.794 68.0281 100.536L64.1067 100.025C63.479 99.9431 62.8961 99.6561 62.4485 99.2085C61.8047 98.5647 60.8937 98.2646 59.9933 98.3996L57.8085 98.7274C55.7607 99.0345 53.9626 100.254 52.9193 102.042L52.8262 102.202C52.3939 102.943 52.126 103.768 52.0406 104.622L51.9654 105.374C51.8762 106.266 52.0993 107.162 52.5966 107.908C52.9903 108.498 53.2141 109.186 53.2437 109.895L53.3998 113.642C53.4924 115.865 54.1787 118.022 55.3874 119.89C56.6453 121.834 58.4203 123.389 60.513 124.381L63.8243 125.949C66.435 127.254 68.3449 129.635 69.0528 132.467L69.6513 134.861L72.193 142.168C72.3257 142.55 72.3935 142.951 72.3935 143.355C72.3935 144.482 72.9198 145.545 73.8165 146.228L74.6043 146.828C77.2648 148.856 81.0966 148.12 82.8174 145.252L82.8876 145.135C83.4223 144.244 83.7048 143.224 83.7048 142.185V136.888C83.7048 135.1 84.0801 133.331 84.8064 131.697C85.8741 129.295 87.6564 127.28 89.9105 125.927L91.6433 124.888C92.7367 124.232 93.6777 123.35 94.4035 122.302C95.6795 120.459 96.2192 118.205 95.9163 115.983L95.8483 115.485C95.5316 113.162 94.4295 111.018 92.7253 109.408L92.4578 109.156C90.3586 107.173 87.5804 106.069 84.6929 106.069H83.5324C80.9537 106.069 78.4235 105.368 76.2123 104.041Z'
          stroke='#242631'
        />
        <path
          d='M71.5244 56.3071L68.6291 54.5253C68.3979 54.3831 68.1903 54.2058 68.0137 53.9997C67.0398 52.8635 67.2153 51.1436 68.3986 50.2275L74.4786 45.5204C76.0247 44.3234 77.8897 43.6094 79.8398 43.4679L115.015 40.9148C117.153 40.7596 118.818 42.7395 118.298 44.819C118.095 45.6291 117.579 46.3257 116.863 46.7553L113.873 48.5492C112.076 49.6277 110.019 50.1975 107.922 50.1975H103.896C103.01 50.1975 102.132 50.3542 101.301 50.6603C98.1828 51.809 96.1911 54.871 96.4051 58.187L96.4349 58.6489C96.6179 61.4853 95.2953 64.2089 92.9531 65.8192C91.833 66.5893 90.5365 67.064 89.1839 67.1993L89.0799 67.2097C86.1637 67.5013 83.3037 66.266 81.5167 63.943L80.9626 63.2226L78.3418 59.2913C77.4094 57.8928 75.8399 57.0528 74.1591 57.0528C73.2288 57.0528 72.3167 56.7947 71.5244 56.3071Z'
          stroke='#242631'
        />
        <path
          d='M126.903 53.8284L133.231 51.5685C134.942 50.927 136.767 50.9183 138.418 51.4582C141.392 52.4311 142.469 55.8158 142.907 58.9143L143.522 63.2664C143.557 63.5102 143.655 63.7399 143.794 63.943C145.613 66.5991 146.207 70.6762 145.307 74.4426C144.934 76.0056 144.617 77.6039 144.768 79.2038L144.774 79.2709C144.951 81.1375 145.687 82.9074 146.885 84.3491L147.617 85.2292C148.535 86.3334 149.037 87.724 149.037 89.16V89.8193C149.037 91.2025 148.431 92.5161 147.378 93.4131C145.959 94.6216 145.384 96.5515 145.91 98.3394L146.464 100.22C147.402 103.411 147.615 106.79 148.409 110.02C149.598 114.853 152.232 118.69 151.183 121.147C151.079 121.389 150.966 121.629 150.897 121.883L150.019 125.147C149.368 127.565 148.35 130.604 145.849 130.458C145.581 130.443 145.311 130.396 145.045 130.316C144.49 130.149 143.985 129.848 143.575 129.438L141.176 127.039C140.224 126.087 139.163 125.252 138.013 124.551L128.521 118.764C126.654 117.625 125.042 116.112 123.788 114.32C121.903 111.628 119.229 109.588 116.134 108.483L106.495 105.04L99.3701 101.478C96.9268 100.256 95.1278 98.0417 94.4327 95.4C94.0258 93.854 94.0158 92.2303 94.4035 90.6793L95.6898 85.5342C96.252 83.2853 96.0913 80.9164 95.2303 78.7641L94.9359 78.0279C94.2081 76.2086 94.1131 74.1975 94.666 72.3177C95.2231 70.4235 96.4053 68.7739 98.0199 67.6377L100.832 65.659C103.422 63.8365 106.434 62.7059 109.584 62.3743L112.569 62.0601C115.939 61.7053 119.058 60.1099 121.318 57.5839C122.837 55.8858 124.757 54.5947 126.903 53.8284Z'
          stroke='#242631'
        />
        <path
          d='M98.7111 55.1826L98.5611 54.8076C98.5203 54.7058 98.4994 54.5971 98.4994 54.4874C98.4994 54.0112 98.8854 53.6252 99.3616 53.6252H99.9536C100.339 53.6252 100.709 53.7784 100.982 54.0511L101.543 54.6119C101.944 55.0134 101.944 55.6645 101.543 56.0661C101.35 56.259 101.088 56.3673 100.816 56.3673H100.461C99.6903 56.3673 98.9973 55.8981 98.7111 55.1826Z'
          stroke='white'
        />
        <path
          d='M104.171 58.4455L104.015 58.2378C103.801 57.9512 103.829 57.5502 104.082 57.2969C104.392 56.9872 104.904 57.0236 105.167 57.374L105.323 57.5817C105.538 57.8683 105.509 58.2693 105.256 58.5226C104.946 58.8323 104.434 58.7959 104.171 58.4455Z'
          stroke='white'
        />
        <path
          d='M120.74 50.8438L119.663 49.7665C119.167 49.2703 119.225 48.4497 119.786 48.0287C119.986 47.879 120.229 47.7981 120.478 47.7981H122.27C122.859 47.7981 123.431 47.9944 123.896 48.3559L125.143 49.3258C125.738 49.7887 125.793 50.6683 125.26 51.2014C125.025 51.4365 124.706 51.5685 124.373 51.5685H122.49C121.833 51.5685 121.204 51.3079 120.74 50.8438Z'
          stroke='white'
        />
        <path
          d='M73.5896 62.7616L73.1819 61.946C73.1504 61.8831 73.1235 61.8181 73.1012 61.7514L73.0008 61.4501C72.7294 60.6358 73.3354 59.795 74.1937 59.795C74.6041 59.795 74.9886 59.9952 75.2239 60.3313L77.0792 62.9818C77.1901 63.1401 77.2621 63.3224 77.2894 63.5137L77.3572 63.9885C77.461 64.715 76.7399 65.2817 76.0586 65.0092C75.9386 64.9612 75.8296 64.8894 75.7383 64.798L74.6831 63.7429C74.5656 63.6253 74.4299 63.5275 74.2812 63.4532C73.9819 63.3035 73.7393 63.0609 73.5896 62.7616Z'
          stroke='white'
        />
        <path
          d='M60.4796 69.8949L60.0299 69.9214C58.3091 70.0226 56.6777 70.7217 55.4175 71.8979L53.1302 74.0326C52.5367 74.5865 52.0913 75.2802 51.8346 76.0503C51.4267 77.2741 51.5216 78.6098 52.0985 79.7636L53.2847 82.1361C53.7134 82.9935 53.9036 83.9504 53.8353 84.9066L53.7444 86.1799C53.6542 87.4428 54.3057 96.0292 53.1302 96.4994C52.2662 96.845 51.5459 92.0102 49.4407 89.4735C49.0345 88.9842 48.4959 88.6314 47.9477 88.309V88.309C45.8376 87.0677 44.8646 84.5336 45.6018 82.1991L46.3988 79.6755L47.3564 75.8451C48.0791 72.9541 49.465 70.2712 51.4043 68.0086L57.6838 60.6825C58.3684 59.8839 59.2934 59.3288 60.3202 59.1007C61.2983 58.8833 62.3197 58.9735 63.2446 59.3589L63.5991 59.5066C65.063 60.1166 66.1348 61.4057 66.4671 62.9565C66.781 64.4214 66.3981 65.949 65.4304 67.0926L64.951 67.6592C63.8292 68.9849 62.2132 69.7929 60.4796 69.8949Z'
          stroke='white'
        />
        <path
          d='M54.358 75.9631L55.0735 74.8898C55.2217 74.6675 55.4712 74.534 55.7384 74.534C56.2322 74.534 56.6079 74.9774 56.5267 75.4645L56.3777 76.3582C56.3521 76.5122 56.3035 76.6616 56.2337 76.8013L55.7228 77.823C55.504 78.2606 54.9511 78.4075 54.5441 78.1361C54.3757 78.0238 54.2552 77.8529 54.2061 77.6566L54.1226 77.3222C54.0061 76.8562 54.0915 76.3627 54.358 75.9631Z'
          stroke='white'
        />
        <path
          d='M74.2113 104.041L71.6107 102.481C69.9028 101.456 68.0021 100.794 66.0271 100.536L62.1057 100.025C61.4781 99.9431 60.8951 99.6561 60.4475 99.2085C59.8037 98.5647 58.8927 98.2646 57.9924 98.3996L55.8076 98.7274C53.7598 99.0345 51.9617 100.254 50.9183 102.042L50.8252 102.202C50.3929 102.943 50.125 103.768 50.0397 104.622L49.9644 105.374C49.8752 106.266 50.0983 107.162 50.5956 107.908C50.9893 108.498 51.2131 109.186 51.2427 109.895L51.3988 113.642C51.4914 115.865 52.1777 118.022 53.3864 119.89C54.6443 121.834 56.4193 123.389 58.512 124.381L61.8233 125.949C64.434 127.254 66.3439 129.635 67.0519 132.467L67.6503 134.861L70.192 142.168C70.3247 142.55 70.3925 142.951 70.3925 143.355C70.3925 144.482 70.9188 145.545 71.8156 146.228L72.6033 146.828C75.2638 148.856 79.0956 148.12 80.8164 145.252L80.8867 145.135C81.4214 144.244 81.7038 143.224 81.7038 142.185V136.888C81.7038 135.1 82.0791 133.331 82.8055 131.697C83.8731 129.295 85.6554 127.28 87.9095 125.927L89.6423 124.888C90.7357 124.232 91.6767 123.35 92.4025 122.302C93.6785 120.459 94.2182 118.205 93.9153 115.983L93.8473 115.485C93.5306 113.162 92.4285 111.018 90.7243 109.408L90.4568 109.156C88.3576 107.173 85.5794 106.069 82.6919 106.069H81.5314C78.9528 106.069 76.4226 105.368 74.2113 104.041Z'
          stroke='white'
        />
        <path
          d='M69.5235 56.3071L66.6281 54.5253C66.3969 54.3831 66.1893 54.2058 66.0127 53.9997C65.0389 52.8635 65.2144 51.1436 66.3976 50.2275L72.4776 45.5204C74.0237 44.3234 75.8887 43.6094 77.8389 43.4679L113.014 40.9148C115.152 40.7596 116.817 42.7395 116.297 44.819C116.094 45.6291 115.578 46.3257 114.862 46.7553L111.872 48.5492C110.075 49.6277 108.018 50.1975 105.921 50.1975H101.895C101.009 50.1975 100.131 50.3542 99.2998 50.6603C96.1818 51.809 94.1901 54.871 94.4041 58.187L94.4339 58.6489C94.6169 61.4853 93.2944 64.2089 90.9522 65.8192C89.832 66.5893 88.5355 67.064 87.1829 67.1993L87.0789 67.2097C84.1627 67.5013 81.3027 66.266 79.5158 63.943L78.9617 63.2226L76.3408 59.2913C75.4085 57.8928 73.8389 57.0528 72.1581 57.0528C71.2278 57.0528 70.3158 56.7947 69.5235 56.3071Z'
          stroke='white'
        />
        <path
          d='M124.902 53.8284L131.23 51.5685C132.941 50.927 134.766 50.9183 136.417 51.4582C139.391 52.4311 140.468 55.8158 140.906 58.9143L141.568 63.6C141.572 63.625 141.582 63.6485 141.597 63.6688C143.083 65.6612 143.829 68.5554 143.708 71.5292C143.564 75.0655 142.711 78.6126 143.044 82.1361V82.1361L145.616 85.2292C146.534 86.3334 147.036 87.724 147.036 89.16V89.8193C147.036 91.2025 146.43 92.5161 145.377 93.4131V93.4131C143.958 94.6216 143.383 96.5515 143.909 98.3394L144.463 100.22L144.535 100.467C145.426 103.494 145.63 106.696 146.348 109.769C147.08 112.901 148.447 115.623 149.082 117.834C149.775 120.241 148.669 122.728 148.018 125.147V125.147C147.367 127.565 146.349 130.604 143.848 130.458C143.58 130.443 143.31 130.396 143.044 130.316C142.489 130.149 141.984 129.848 141.574 129.438L139.175 127.039C138.223 126.087 137.162 125.252 136.012 124.551L126.52 118.764C124.653 117.625 123.041 116.112 121.787 114.32C119.902 111.628 117.228 109.588 114.133 108.483L104.494 105.04L97.3692 101.478C94.9259 100.256 93.1269 98.0417 92.4317 95.4C92.0248 93.8539 92.0148 92.2303 92.4025 90.6793L93.6888 85.5342C94.2511 83.2853 94.0903 80.9164 93.2294 78.7641L92.9349 78.0279C92.2072 76.2086 92.1121 74.1975 92.665 72.3177C93.2221 70.4235 94.4043 68.7739 96.019 67.6377L98.8307 65.659C101.421 63.8365 104.433 62.7059 107.583 62.3743L110.568 62.0601C113.938 61.7053 117.057 60.1099 119.317 57.5839C120.836 55.8858 122.756 54.5947 124.902 53.8284Z'
          stroke='white'
        />
      </g>
      <mask id='path-1166-inside-1_6970_52958' fill='white'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M101.316 163.574C136.997 163.574 165.923 134.366 165.923 98.335C165.923 62.3043 136.997 33.0957 101.316 33.0957C65.6338 33.0957 36.708 62.3043 36.708 98.335C36.708 134.366 65.6338 163.574 101.316 163.574ZM101.315 151.713C130.51 151.713 154.176 127.815 154.176 98.3352C154.176 68.8556 130.51 44.9576 101.315 44.9576C72.1212 44.9576 48.4546 68.8556 48.4546 98.3352C48.4546 127.815 72.1212 151.713 101.315 151.713Z'
        />
      </mask>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M101.316 163.574C136.997 163.574 165.923 134.366 165.923 98.335C165.923 62.3043 136.997 33.0957 101.316 33.0957C65.6338 33.0957 36.708 62.3043 36.708 98.335C36.708 134.366 65.6338 163.574 101.316 163.574ZM101.315 151.713C130.51 151.713 154.176 127.815 154.176 98.3352C154.176 68.8556 130.51 44.9576 101.315 44.9576C72.1212 44.9576 48.4546 68.8556 48.4546 98.3352C48.4546 127.815 72.1212 151.713 101.315 151.713Z'
        fill='#6F717C'
      />
      <path
        d='M164.923 98.335C164.923 133.823 136.436 162.574 101.316 162.574V164.574C137.559 164.574 166.923 134.909 166.923 98.335H164.923ZM101.316 34.0957C136.436 34.0957 164.923 62.8474 164.923 98.335H166.923C166.923 61.7613 137.559 32.0957 101.316 32.0957V34.0957ZM37.708 98.335C37.708 62.8474 66.1952 34.0957 101.316 34.0957V32.0957C65.0724 32.0957 35.708 61.7613 35.708 98.335H37.708ZM101.316 162.574C66.1952 162.574 37.708 133.823 37.708 98.335H35.708C35.708 134.909 65.0724 164.574 101.316 164.574V162.574ZM153.176 98.3352C153.176 127.272 129.948 150.713 101.315 150.713V152.713C131.071 152.713 155.176 128.358 155.176 98.3352H153.176ZM101.315 45.9576C129.948 45.9576 153.176 69.3987 153.176 98.3352H155.176C155.176 68.3125 131.071 43.9576 101.315 43.9576V45.9576ZM49.4546 98.3352C49.4546 69.3987 72.6826 45.9576 101.315 45.9576V43.9576C71.5597 43.9576 47.4546 68.3125 47.4546 98.3352H49.4546ZM101.315 150.713C72.6826 150.713 49.4546 127.272 49.4546 98.3352H47.4546C47.4546 128.358 71.5597 152.713 101.315 152.713V150.713Z'
        fill='#242631'
        mask='url(#path-1166-inside-1_6970_52958)'
      />
      <rect
        x='60.7012'
        y='81.041'
        width='128.215'
        height='32.6081'
        rx='3.5'
        fill='#242631'
        stroke='#E5E5E6'
      />
      <rect
        x='69.71'
        y='90.1445'
        width='14.264'
        height='14.4035'
        rx='7.13201'
        stroke='#FF9B63'
      />
      <rect
        x='73.2764'
        y='93.7441'
        width='7.13201'
        height='7.20174'
        rx='3.566'
        fill='#6F717C'
      />
      <rect
        x='91.1064'
        y='91.3457'
        width='35.66'
        height='4.80116'
        rx='2.40058'
        fill='#6F717C'
      />
      <rect
        x='91.1064'
        y='99.748'
        width='17.83'
        height='3.60087'
        rx='1.80044'
        fill='#6F717C'
      />
      <rect
        x='168.369'
        y='91.3438'
        width='11.8867'
        height='4.80116'
        rx='2.40058'
        fill='#6F717C'
      />
      <rect
        x='158.86'
        y='99.7461'
        width='21.396'
        height='3.60087'
        rx='1.80044'
        fill='#89F7C4'
      />
      <path
        d='M170.458 157.018L157.474 170.129L189.609 202.578C190.386 203.363 191.646 203.363 192.423 202.578L202.593 192.309C203.37 191.524 203.37 190.252 202.593 189.467L170.458 157.018Z'
        fill='#FF9B63'
        stroke='#242631'
      />
      <defs>
        <linearGradient
          id='paint0_linear_6970_52958'
          x1='101.029'
          y1='0'
          x2='101.029'
          y2='197.084'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF9B63' stopOpacity='0.37' />
          <stop offset='1' stopColor='#F5F5F5' stopOpacity='0' />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg
      width='204'
      height='204'
      viewBox='0 0 204 204'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <ellipse
        cx='101.029'
        cy='98.542'
        rx='97.5876'
        ry='98.542'
        fill='url(#paint0_linear_6961_37080)'
      />
      <mask
        id='mask0_6961_37080'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='201'
        height='203'
      >
        <ellipse
          cx='100.28'
          cy='101.26'
          rx='100.28'
          ry='101.26'
          fill='#D9D9D9'
        />
      </mask>
      <g mask='url(#mask0_6961_37080)'>
        <g opacity='0.2'>
          <circle cx='-0.561523' cy='3.97852' r='1' fill='#242631' />
          <circle cx='5.43848' cy='3.97852' r='1' fill='#242631' />
          <path
            d='M12.4385 3.97852C12.4385 4.5308 11.9908 4.97852 11.4385 4.97852C10.8862 4.97852 10.4385 4.5308 10.4385 3.97852C10.4385 3.42623 10.8862 2.97852 11.4385 2.97852C11.9908 2.97852 12.4385 3.42623 12.4385 3.97852Z'
            fill='#242631'
          />
          <path
            d='M18.4385 3.97852C18.4385 4.5308 17.9908 4.97852 17.4385 4.97852C16.8862 4.97852 16.4385 4.5308 16.4385 3.97852C16.4385 3.42623 16.8862 2.97852 17.4385 2.97852C17.9908 2.97852 18.4385 3.42623 18.4385 3.97852Z'
            fill='#242631'
          />
          <path
            d='M24.4385 3.97852C24.4385 4.5308 23.9908 4.97852 23.4385 4.97852C22.8862 4.97852 22.4385 4.5308 22.4385 3.97852C22.4385 3.42623 22.8862 2.97852 23.4385 2.97852C23.9908 2.97852 24.4385 3.42623 24.4385 3.97852Z'
            fill='#242631'
          />
          <path
            d='M30.4385 3.97852C30.4385 4.5308 29.9908 4.97852 29.4385 4.97852C28.8862 4.97852 28.4385 4.5308 28.4385 3.97852C28.4385 3.42623 28.8862 2.97852 29.4385 2.97852C29.9908 2.97852 30.4385 3.42623 30.4385 3.97852Z'
            fill='#242631'
          />
          <path
            d='M36.4385 3.97852C36.4385 4.5308 35.9908 4.97852 35.4385 4.97852C34.8862 4.97852 34.4385 4.5308 34.4385 3.97852C34.4385 3.42623 34.8862 2.97852 35.4385 2.97852C35.9908 2.97852 36.4385 3.42623 36.4385 3.97852Z'
            fill='#242631'
          />
          <path
            d='M42.4385 3.97852C42.4385 4.5308 41.9908 4.97852 41.4385 4.97852C40.8862 4.97852 40.4385 4.5308 40.4385 3.97852C40.4385 3.42623 40.8862 2.97852 41.4385 2.97852C41.9908 2.97852 42.4385 3.42623 42.4385 3.97852Z'
            fill='#242631'
          />
          <path
            d='M48.4385 3.97852C48.4385 4.5308 47.9908 4.97852 47.4385 4.97852C46.8862 4.97852 46.4385 4.5308 46.4385 3.97852C46.4385 3.42623 46.8862 2.97852 47.4385 2.97852C47.9908 2.97852 48.4385 3.42623 48.4385 3.97852Z'
            fill='#242631'
          />
          <path
            d='M54.4385 3.97852C54.4385 4.5308 53.9908 4.97852 53.4385 4.97852C52.8862 4.97852 52.4385 4.5308 52.4385 3.97852C52.4385 3.42623 52.8862 2.97852 53.4385 2.97852C53.9908 2.97852 54.4385 3.42623 54.4385 3.97852Z'
            fill='#242631'
          />
          <path
            d='M60.4385 3.97852C60.4385 4.5308 59.9908 4.97852 59.4385 4.97852C58.8862 4.97852 58.4385 4.5308 58.4385 3.97852C58.4385 3.42623 58.8862 2.97852 59.4385 2.97852C59.9908 2.97852 60.4385 3.42623 60.4385 3.97852Z'
            fill='#242631'
          />
          <path
            d='M66.4385 3.97852C66.4385 4.5308 65.9908 4.97852 65.4385 4.97852C64.8862 4.97852 64.4385 4.5308 64.4385 3.97852C64.4385 3.42623 64.8862 2.97852 65.4385 2.97852C65.9908 2.97852 66.4385 3.42623 66.4385 3.97852Z'
            fill='#242631'
          />
          <path
            d='M72.4385 3.97852C72.4385 4.5308 71.9908 4.97852 71.4385 4.97852C70.8862 4.97852 70.4385 4.5308 70.4385 3.97852C70.4385 3.42623 70.8862 2.97852 71.4385 2.97852C71.9908 2.97852 72.4385 3.42623 72.4385 3.97852Z'
            fill='#242631'
          />
          <path
            d='M78.4385 3.97852C78.4385 4.5308 77.9908 4.97852 77.4385 4.97852C76.8862 4.97852 76.4385 4.5308 76.4385 3.97852C76.4385 3.42623 76.8862 2.97852 77.4385 2.97852C77.9908 2.97852 78.4385 3.42623 78.4385 3.97852Z'
            fill='#242631'
          />
          <path
            d='M84.4385 3.97852C84.4385 4.5308 83.9908 4.97852 83.4385 4.97852C82.8862 4.97852 82.4385 4.5308 82.4385 3.97852C82.4385 3.42623 82.8862 2.97852 83.4385 2.97852C83.9908 2.97852 84.4385 3.42623 84.4385 3.97852Z'
            fill='#242631'
          />
          <path
            d='M90.4385 3.97852C90.4385 4.5308 89.9908 4.97852 89.4385 4.97852C88.8862 4.97852 88.4385 4.5308 88.4385 3.97852C88.4385 3.42623 88.8862 2.97852 89.4385 2.97852C89.9908 2.97852 90.4385 3.42623 90.4385 3.97852Z'
            fill='#242631'
          />
          <path
            d='M96.4385 3.97852C96.4385 4.5308 95.9908 4.97852 95.4385 4.97852C94.8862 4.97852 94.4385 4.5308 94.4385 3.97852C94.4385 3.42623 94.8862 2.97852 95.4385 2.97852C95.9908 2.97852 96.4385 3.42623 96.4385 3.97852Z'
            fill='#242631'
          />
          <path
            d='M102.438 3.97852C102.438 4.5308 101.991 4.97852 101.438 4.97852C100.886 4.97852 100.438 4.5308 100.438 3.97852C100.438 3.42623 100.886 2.97852 101.438 2.97852C101.991 2.97852 102.438 3.42623 102.438 3.97852Z'
            fill='#242631'
          />
          <path
            d='M108.438 3.97852C108.438 4.5308 107.991 4.97852 107.438 4.97852C106.886 4.97852 106.438 4.5308 106.438 3.97852C106.438 3.42623 106.886 2.97852 107.438 2.97852C107.991 2.97852 108.438 3.42623 108.438 3.97852Z'
            fill='#242631'
          />
          <path
            d='M114.438 3.97852C114.438 4.5308 113.991 4.97852 113.438 4.97852C112.886 4.97852 112.438 4.5308 112.438 3.97852C112.438 3.42623 112.886 2.97852 113.438 2.97852C113.991 2.97852 114.438 3.42623 114.438 3.97852Z'
            fill='#242631'
          />
          <path
            d='M120.438 3.97852C120.438 4.5308 119.991 4.97852 119.438 4.97852C118.886 4.97852 118.438 4.5308 118.438 3.97852C118.438 3.42623 118.886 2.97852 119.438 2.97852C119.991 2.97852 120.438 3.42623 120.438 3.97852Z'
            fill='#242631'
          />
          <path
            d='M126.438 3.97852C126.438 4.5308 125.991 4.97852 125.438 4.97852C124.886 4.97852 124.438 4.5308 124.438 3.97852C124.438 3.42623 124.886 2.97852 125.438 2.97852C125.991 2.97852 126.438 3.42623 126.438 3.97852Z'
            fill='#242631'
          />
          <circle cx='131.438' cy='3.97852' r='1' fill='#242631' />
          <circle cx='137.438' cy='3.97852' r='1' fill='#242631' />
          <circle cx='143.438' cy='3.97852' r='1' fill='#242631' />
          <circle cx='149.438' cy='3.97852' r='1' fill='#242631' />
          <circle cx='155.438' cy='3.97852' r='1' fill='#242631' />
          <circle cx='161.438' cy='3.97852' r='1' fill='#242631' />
          <circle cx='167.438' cy='3.97852' r='1' fill='#242631' />
          <circle cx='173.438' cy='3.97852' r='1' fill='#242631' />
          <circle cx='179.438' cy='3.97852' r='1' fill='#242631' />
          <circle cx='185.438' cy='3.97852' r='1' fill='#242631' />
          <circle cx='191.438' cy='3.97852' r='1' fill='#242631' />
          <circle cx='197.438' cy='3.97852' r='1' fill='#242631' />
          <circle cx='-0.561523' cy='123.107' r='1' fill='#242631' />
          <circle cx='5.43848' cy='123.107' r='1' fill='#242631' />
          <path
            d='M12.4385 123.107C12.4385 123.66 11.9908 124.107 11.4385 124.107C10.8862 124.107 10.4385 123.66 10.4385 123.107C10.4385 122.555 10.8862 122.107 11.4385 122.107C11.9908 122.107 12.4385 122.555 12.4385 123.107Z'
            fill='#242631'
          />
          <path
            d='M18.4385 123.107C18.4385 123.66 17.9908 124.107 17.4385 124.107C16.8862 124.107 16.4385 123.66 16.4385 123.107C16.4385 122.555 16.8862 122.107 17.4385 122.107C17.9908 122.107 18.4385 122.555 18.4385 123.107Z'
            fill='#242631'
          />
          <path
            d='M24.4385 123.107C24.4385 123.66 23.9908 124.107 23.4385 124.107C22.8862 124.107 22.4385 123.66 22.4385 123.107C22.4385 122.555 22.8862 122.107 23.4385 122.107C23.9908 122.107 24.4385 122.555 24.4385 123.107Z'
            fill='#242631'
          />
          <path
            d='M30.4385 123.107C30.4385 123.66 29.9908 124.107 29.4385 124.107C28.8862 124.107 28.4385 123.66 28.4385 123.107C28.4385 122.555 28.8862 122.107 29.4385 122.107C29.9908 122.107 30.4385 122.555 30.4385 123.107Z'
            fill='#242631'
          />
          <path
            d='M36.4385 123.107C36.4385 123.66 35.9908 124.107 35.4385 124.107C34.8862 124.107 34.4385 123.66 34.4385 123.107C34.4385 122.555 34.8862 122.107 35.4385 122.107C35.9908 122.107 36.4385 122.555 36.4385 123.107Z'
            fill='#242631'
          />
          <path
            d='M42.4385 123.107C42.4385 123.66 41.9908 124.107 41.4385 124.107C40.8862 124.107 40.4385 123.66 40.4385 123.107C40.4385 122.555 40.8862 122.107 41.4385 122.107C41.9908 122.107 42.4385 122.555 42.4385 123.107Z'
            fill='#242631'
          />
          <path
            d='M48.4385 123.107C48.4385 123.66 47.9908 124.107 47.4385 124.107C46.8862 124.107 46.4385 123.66 46.4385 123.107C46.4385 122.555 46.8862 122.107 47.4385 122.107C47.9908 122.107 48.4385 122.555 48.4385 123.107Z'
            fill='#242631'
          />
          <path
            d='M54.4385 123.107C54.4385 123.66 53.9908 124.107 53.4385 124.107C52.8862 124.107 52.4385 123.66 52.4385 123.107C52.4385 122.555 52.8862 122.107 53.4385 122.107C53.9908 122.107 54.4385 122.555 54.4385 123.107Z'
            fill='#242631'
          />
          <path
            d='M60.4385 123.107C60.4385 123.66 59.9908 124.107 59.4385 124.107C58.8862 124.107 58.4385 123.66 58.4385 123.107C58.4385 122.555 58.8862 122.107 59.4385 122.107C59.9908 122.107 60.4385 122.555 60.4385 123.107Z'
            fill='#242631'
          />
          <path
            d='M66.4385 123.107C66.4385 123.66 65.9908 124.107 65.4385 124.107C64.8862 124.107 64.4385 123.66 64.4385 123.107C64.4385 122.555 64.8862 122.107 65.4385 122.107C65.9908 122.107 66.4385 122.555 66.4385 123.107Z'
            fill='#242631'
          />
          <path
            d='M72.4385 123.107C72.4385 123.66 71.9908 124.107 71.4385 124.107C70.8862 124.107 70.4385 123.66 70.4385 123.107C70.4385 122.555 70.8862 122.107 71.4385 122.107C71.9908 122.107 72.4385 122.555 72.4385 123.107Z'
            fill='#242631'
          />
          <path
            d='M78.4385 123.107C78.4385 123.66 77.9908 124.107 77.4385 124.107C76.8862 124.107 76.4385 123.66 76.4385 123.107C76.4385 122.555 76.8862 122.107 77.4385 122.107C77.9908 122.107 78.4385 122.555 78.4385 123.107Z'
            fill='#242631'
          />
          <path
            d='M84.4385 123.107C84.4385 123.66 83.9908 124.107 83.4385 124.107C82.8862 124.107 82.4385 123.66 82.4385 123.107C82.4385 122.555 82.8862 122.107 83.4385 122.107C83.9908 122.107 84.4385 122.555 84.4385 123.107Z'
            fill='#242631'
          />
          <path
            d='M90.4385 123.107C90.4385 123.66 89.9908 124.107 89.4385 124.107C88.8862 124.107 88.4385 123.66 88.4385 123.107C88.4385 122.555 88.8862 122.107 89.4385 122.107C89.9908 122.107 90.4385 122.555 90.4385 123.107Z'
            fill='#242631'
          />
          <path
            d='M96.4385 123.107C96.4385 123.66 95.9908 124.107 95.4385 124.107C94.8862 124.107 94.4385 123.66 94.4385 123.107C94.4385 122.555 94.8862 122.107 95.4385 122.107C95.9908 122.107 96.4385 122.555 96.4385 123.107Z'
            fill='#242631'
          />
          <path
            d='M102.438 123.107C102.438 123.66 101.991 124.107 101.438 124.107C100.886 124.107 100.438 123.66 100.438 123.107C100.438 122.555 100.886 122.107 101.438 122.107C101.991 122.107 102.438 122.555 102.438 123.107Z'
            fill='#242631'
          />
          <path
            d='M108.438 123.107C108.438 123.66 107.991 124.107 107.438 124.107C106.886 124.107 106.438 123.66 106.438 123.107C106.438 122.555 106.886 122.107 107.438 122.107C107.991 122.107 108.438 122.555 108.438 123.107Z'
            fill='#242631'
          />
          <path
            d='M114.438 123.107C114.438 123.66 113.991 124.107 113.438 124.107C112.886 124.107 112.438 123.66 112.438 123.107C112.438 122.555 112.886 122.107 113.438 122.107C113.991 122.107 114.438 122.555 114.438 123.107Z'
            fill='#242631'
          />
          <path
            d='M120.438 123.107C120.438 123.66 119.991 124.107 119.438 124.107C118.886 124.107 118.438 123.66 118.438 123.107C118.438 122.555 118.886 122.107 119.438 122.107C119.991 122.107 120.438 122.555 120.438 123.107Z'
            fill='#242631'
          />
          <path
            d='M126.438 123.107C126.438 123.66 125.991 124.107 125.438 124.107C124.886 124.107 124.438 123.66 124.438 123.107C124.438 122.555 124.886 122.107 125.438 122.107C125.991 122.107 126.438 122.555 126.438 123.107Z'
            fill='#242631'
          />
          <circle cx='131.438' cy='123.107' r='1' fill='#242631' />
          <circle cx='137.438' cy='123.107' r='1' fill='#242631' />
          <circle cx='143.438' cy='123.107' r='1' fill='#242631' />
          <circle cx='149.438' cy='123.107' r='1' fill='#242631' />
          <circle cx='155.438' cy='123.107' r='1' fill='#242631' />
          <circle cx='161.438' cy='123.107' r='1' fill='#242631' />
          <circle cx='167.438' cy='123.107' r='1' fill='#242631' />
          <circle cx='173.438' cy='123.107' r='1' fill='#242631' />
          <circle cx='179.438' cy='123.107' r='1' fill='#242631' />
          <circle cx='185.438' cy='123.107' r='1' fill='#242631' />
          <circle cx='191.438' cy='123.107' r='1' fill='#242631' />
          <circle cx='197.438' cy='123.107' r='1' fill='#242631' />
          <circle cx='-0.561523' cy='27.8047' r='1' fill='#242631' />
          <circle cx='5.43848' cy='27.8047' r='1' fill='#242631' />
          <path
            d='M12.4385 27.8047C12.4385 28.357 11.9908 28.8047 11.4385 28.8047C10.8862 28.8047 10.4385 28.357 10.4385 27.8047C10.4385 27.2524 10.8862 26.8047 11.4385 26.8047C11.9908 26.8047 12.4385 27.2524 12.4385 27.8047Z'
            fill='#242631'
          />
          <path
            d='M18.4385 27.8047C18.4385 28.357 17.9908 28.8047 17.4385 28.8047C16.8862 28.8047 16.4385 28.357 16.4385 27.8047C16.4385 27.2524 16.8862 26.8047 17.4385 26.8047C17.9908 26.8047 18.4385 27.2524 18.4385 27.8047Z'
            fill='#242631'
          />
          <path
            d='M24.4385 27.8047C24.4385 28.357 23.9908 28.8047 23.4385 28.8047C22.8862 28.8047 22.4385 28.357 22.4385 27.8047C22.4385 27.2524 22.8862 26.8047 23.4385 26.8047C23.9908 26.8047 24.4385 27.2524 24.4385 27.8047Z'
            fill='#242631'
          />
          <path
            d='M30.4385 27.8047C30.4385 28.357 29.9908 28.8047 29.4385 28.8047C28.8862 28.8047 28.4385 28.357 28.4385 27.8047C28.4385 27.2524 28.8862 26.8047 29.4385 26.8047C29.9908 26.8047 30.4385 27.2524 30.4385 27.8047Z'
            fill='#242631'
          />
          <path
            d='M36.4385 27.8047C36.4385 28.357 35.9908 28.8047 35.4385 28.8047C34.8862 28.8047 34.4385 28.357 34.4385 27.8047C34.4385 27.2524 34.8862 26.8047 35.4385 26.8047C35.9908 26.8047 36.4385 27.2524 36.4385 27.8047Z'
            fill='#242631'
          />
          <path
            d='M42.4385 27.8047C42.4385 28.357 41.9908 28.8047 41.4385 28.8047C40.8862 28.8047 40.4385 28.357 40.4385 27.8047C40.4385 27.2524 40.8862 26.8047 41.4385 26.8047C41.9908 26.8047 42.4385 27.2524 42.4385 27.8047Z'
            fill='#242631'
          />
          <path
            d='M48.4385 27.8047C48.4385 28.357 47.9908 28.8047 47.4385 28.8047C46.8862 28.8047 46.4385 28.357 46.4385 27.8047C46.4385 27.2524 46.8862 26.8047 47.4385 26.8047C47.9908 26.8047 48.4385 27.2524 48.4385 27.8047Z'
            fill='#242631'
          />
          <path
            d='M54.4385 27.8047C54.4385 28.357 53.9908 28.8047 53.4385 28.8047C52.8862 28.8047 52.4385 28.357 52.4385 27.8047C52.4385 27.2524 52.8862 26.8047 53.4385 26.8047C53.9908 26.8047 54.4385 27.2524 54.4385 27.8047Z'
            fill='#242631'
          />
          <path
            d='M60.4385 27.8047C60.4385 28.357 59.9908 28.8047 59.4385 28.8047C58.8862 28.8047 58.4385 28.357 58.4385 27.8047C58.4385 27.2524 58.8862 26.8047 59.4385 26.8047C59.9908 26.8047 60.4385 27.2524 60.4385 27.8047Z'
            fill='#242631'
          />
          <path
            d='M66.4385 27.8047C66.4385 28.357 65.9908 28.8047 65.4385 28.8047C64.8862 28.8047 64.4385 28.357 64.4385 27.8047C64.4385 27.2524 64.8862 26.8047 65.4385 26.8047C65.9908 26.8047 66.4385 27.2524 66.4385 27.8047Z'
            fill='#242631'
          />
          <path
            d='M72.4385 27.8047C72.4385 28.357 71.9908 28.8047 71.4385 28.8047C70.8862 28.8047 70.4385 28.357 70.4385 27.8047C70.4385 27.2524 70.8862 26.8047 71.4385 26.8047C71.9908 26.8047 72.4385 27.2524 72.4385 27.8047Z'
            fill='#242631'
          />
          <path
            d='M78.4385 27.8047C78.4385 28.357 77.9908 28.8047 77.4385 28.8047C76.8862 28.8047 76.4385 28.357 76.4385 27.8047C76.4385 27.2524 76.8862 26.8047 77.4385 26.8047C77.9908 26.8047 78.4385 27.2524 78.4385 27.8047Z'
            fill='#242631'
          />
          <path
            d='M84.4385 27.8047C84.4385 28.357 83.9908 28.8047 83.4385 28.8047C82.8862 28.8047 82.4385 28.357 82.4385 27.8047C82.4385 27.2524 82.8862 26.8047 83.4385 26.8047C83.9908 26.8047 84.4385 27.2524 84.4385 27.8047Z'
            fill='#242631'
          />
          <path
            d='M90.4385 27.8047C90.4385 28.357 89.9908 28.8047 89.4385 28.8047C88.8862 28.8047 88.4385 28.357 88.4385 27.8047C88.4385 27.2524 88.8862 26.8047 89.4385 26.8047C89.9908 26.8047 90.4385 27.2524 90.4385 27.8047Z'
            fill='#242631'
          />
          <path
            d='M96.4385 27.8047C96.4385 28.357 95.9908 28.8047 95.4385 28.8047C94.8862 28.8047 94.4385 28.357 94.4385 27.8047C94.4385 27.2524 94.8862 26.8047 95.4385 26.8047C95.9908 26.8047 96.4385 27.2524 96.4385 27.8047Z'
            fill='#242631'
          />
          <path
            d='M102.438 27.8047C102.438 28.357 101.991 28.8047 101.438 28.8047C100.886 28.8047 100.438 28.357 100.438 27.8047C100.438 27.2524 100.886 26.8047 101.438 26.8047C101.991 26.8047 102.438 27.2524 102.438 27.8047Z'
            fill='#242631'
          />
          <path
            d='M108.438 27.8047C108.438 28.357 107.991 28.8047 107.438 28.8047C106.886 28.8047 106.438 28.357 106.438 27.8047C106.438 27.2524 106.886 26.8047 107.438 26.8047C107.991 26.8047 108.438 27.2524 108.438 27.8047Z'
            fill='#242631'
          />
          <path
            d='M114.438 27.8047C114.438 28.357 113.991 28.8047 113.438 28.8047C112.886 28.8047 112.438 28.357 112.438 27.8047C112.438 27.2524 112.886 26.8047 113.438 26.8047C113.991 26.8047 114.438 27.2524 114.438 27.8047Z'
            fill='#242631'
          />
          <path
            d='M120.438 27.8047C120.438 28.357 119.991 28.8047 119.438 28.8047C118.886 28.8047 118.438 28.357 118.438 27.8047C118.438 27.2524 118.886 26.8047 119.438 26.8047C119.991 26.8047 120.438 27.2524 120.438 27.8047Z'
            fill='#242631'
          />
          <path
            d='M126.438 27.8047C126.438 28.357 125.991 28.8047 125.438 28.8047C124.886 28.8047 124.438 28.357 124.438 27.8047C124.438 27.2524 124.886 26.8047 125.438 26.8047C125.991 26.8047 126.438 27.2524 126.438 27.8047Z'
            fill='#242631'
          />
          <circle cx='131.438' cy='27.8047' r='1' fill='#242631' />
          <circle cx='137.438' cy='27.8047' r='1' fill='#242631' />
          <circle cx='143.438' cy='27.8047' r='1' fill='#242631' />
          <circle cx='149.438' cy='27.8047' r='1' fill='#242631' />
          <circle cx='155.438' cy='27.8047' r='1' fill='#242631' />
          <circle cx='161.438' cy='27.8047' r='1' fill='#242631' />
          <circle cx='167.438' cy='27.8047' r='1' fill='#242631' />
          <circle cx='173.438' cy='27.8047' r='1' fill='#242631' />
          <circle cx='179.438' cy='27.8047' r='1' fill='#242631' />
          <circle cx='185.438' cy='27.8047' r='1' fill='#242631' />
          <circle cx='191.438' cy='27.8047' r='1' fill='#242631' />
          <circle cx='197.438' cy='27.8047' r='1' fill='#242631' />
          <circle cx='-0.561523' cy='146.934' r='1' fill='#242631' />
          <circle cx='5.43848' cy='146.934' r='1' fill='#242631' />
          <path
            d='M12.4385 146.934C12.4385 147.486 11.9908 147.934 11.4385 147.934C10.8862 147.934 10.4385 147.486 10.4385 146.934C10.4385 146.381 10.8862 145.934 11.4385 145.934C11.9908 145.934 12.4385 146.381 12.4385 146.934Z'
            fill='#242631'
          />
          <path
            d='M18.4385 146.934C18.4385 147.486 17.9908 147.934 17.4385 147.934C16.8862 147.934 16.4385 147.486 16.4385 146.934C16.4385 146.381 16.8862 145.934 17.4385 145.934C17.9908 145.934 18.4385 146.381 18.4385 146.934Z'
            fill='#242631'
          />
          <path
            d='M24.4385 146.934C24.4385 147.486 23.9908 147.934 23.4385 147.934C22.8862 147.934 22.4385 147.486 22.4385 146.934C22.4385 146.381 22.8862 145.934 23.4385 145.934C23.9908 145.934 24.4385 146.381 24.4385 146.934Z'
            fill='#242631'
          />
          <path
            d='M30.4385 146.934C30.4385 147.486 29.9908 147.934 29.4385 147.934C28.8862 147.934 28.4385 147.486 28.4385 146.934C28.4385 146.381 28.8862 145.934 29.4385 145.934C29.9908 145.934 30.4385 146.381 30.4385 146.934Z'
            fill='#242631'
          />
          <path
            d='M36.4385 146.934C36.4385 147.486 35.9908 147.934 35.4385 147.934C34.8862 147.934 34.4385 147.486 34.4385 146.934C34.4385 146.381 34.8862 145.934 35.4385 145.934C35.9908 145.934 36.4385 146.381 36.4385 146.934Z'
            fill='#242631'
          />
          <path
            d='M42.4385 146.934C42.4385 147.486 41.9908 147.934 41.4385 147.934C40.8862 147.934 40.4385 147.486 40.4385 146.934C40.4385 146.381 40.8862 145.934 41.4385 145.934C41.9908 145.934 42.4385 146.381 42.4385 146.934Z'
            fill='#242631'
          />
          <path
            d='M48.4385 146.934C48.4385 147.486 47.9908 147.934 47.4385 147.934C46.8862 147.934 46.4385 147.486 46.4385 146.934C46.4385 146.381 46.8862 145.934 47.4385 145.934C47.9908 145.934 48.4385 146.381 48.4385 146.934Z'
            fill='#242631'
          />
          <path
            d='M54.4385 146.934C54.4385 147.486 53.9908 147.934 53.4385 147.934C52.8862 147.934 52.4385 147.486 52.4385 146.934C52.4385 146.381 52.8862 145.934 53.4385 145.934C53.9908 145.934 54.4385 146.381 54.4385 146.934Z'
            fill='#242631'
          />
          <path
            d='M60.4385 146.934C60.4385 147.486 59.9908 147.934 59.4385 147.934C58.8862 147.934 58.4385 147.486 58.4385 146.934C58.4385 146.381 58.8862 145.934 59.4385 145.934C59.9908 145.934 60.4385 146.381 60.4385 146.934Z'
            fill='#242631'
          />
          <path
            d='M66.4385 146.934C66.4385 147.486 65.9908 147.934 65.4385 147.934C64.8862 147.934 64.4385 147.486 64.4385 146.934C64.4385 146.381 64.8862 145.934 65.4385 145.934C65.9908 145.934 66.4385 146.381 66.4385 146.934Z'
            fill='#242631'
          />
          <path
            d='M72.4385 146.934C72.4385 147.486 71.9908 147.934 71.4385 147.934C70.8862 147.934 70.4385 147.486 70.4385 146.934C70.4385 146.381 70.8862 145.934 71.4385 145.934C71.9908 145.934 72.4385 146.381 72.4385 146.934Z'
            fill='#242631'
          />
          <path
            d='M78.4385 146.934C78.4385 147.486 77.9908 147.934 77.4385 147.934C76.8862 147.934 76.4385 147.486 76.4385 146.934C76.4385 146.381 76.8862 145.934 77.4385 145.934C77.9908 145.934 78.4385 146.381 78.4385 146.934Z'
            fill='#242631'
          />
          <path
            d='M84.4385 146.934C84.4385 147.486 83.9908 147.934 83.4385 147.934C82.8862 147.934 82.4385 147.486 82.4385 146.934C82.4385 146.381 82.8862 145.934 83.4385 145.934C83.9908 145.934 84.4385 146.381 84.4385 146.934Z'
            fill='#242631'
          />
          <path
            d='M90.4385 146.934C90.4385 147.486 89.9908 147.934 89.4385 147.934C88.8862 147.934 88.4385 147.486 88.4385 146.934C88.4385 146.381 88.8862 145.934 89.4385 145.934C89.9908 145.934 90.4385 146.381 90.4385 146.934Z'
            fill='#242631'
          />
          <path
            d='M96.4385 146.934C96.4385 147.486 95.9908 147.934 95.4385 147.934C94.8862 147.934 94.4385 147.486 94.4385 146.934C94.4385 146.381 94.8862 145.934 95.4385 145.934C95.9908 145.934 96.4385 146.381 96.4385 146.934Z'
            fill='#242631'
          />
          <path
            d='M102.438 146.934C102.438 147.486 101.991 147.934 101.438 147.934C100.886 147.934 100.438 147.486 100.438 146.934C100.438 146.381 100.886 145.934 101.438 145.934C101.991 145.934 102.438 146.381 102.438 146.934Z'
            fill='#242631'
          />
          <path
            d='M108.438 146.934C108.438 147.486 107.991 147.934 107.438 147.934C106.886 147.934 106.438 147.486 106.438 146.934C106.438 146.381 106.886 145.934 107.438 145.934C107.991 145.934 108.438 146.381 108.438 146.934Z'
            fill='#242631'
          />
          <path
            d='M114.438 146.934C114.438 147.486 113.991 147.934 113.438 147.934C112.886 147.934 112.438 147.486 112.438 146.934C112.438 146.381 112.886 145.934 113.438 145.934C113.991 145.934 114.438 146.381 114.438 146.934Z'
            fill='#242631'
          />
          <path
            d='M120.438 146.934C120.438 147.486 119.991 147.934 119.438 147.934C118.886 147.934 118.438 147.486 118.438 146.934C118.438 146.381 118.886 145.934 119.438 145.934C119.991 145.934 120.438 146.381 120.438 146.934Z'
            fill='#242631'
          />
          <path
            d='M126.438 146.934C126.438 147.486 125.991 147.934 125.438 147.934C124.886 147.934 124.438 147.486 124.438 146.934C124.438 146.381 124.886 145.934 125.438 145.934C125.991 145.934 126.438 146.381 126.438 146.934Z'
            fill='#242631'
          />
          <circle cx='131.438' cy='146.934' r='1' fill='#242631' />
          <circle cx='137.438' cy='146.934' r='1' fill='#242631' />
          <circle cx='143.438' cy='146.934' r='1' fill='#242631' />
          <circle cx='149.438' cy='146.934' r='1' fill='#242631' />
          <circle cx='155.438' cy='146.934' r='1' fill='#242631' />
          <circle cx='161.438' cy='146.934' r='1' fill='#242631' />
          <circle cx='167.438' cy='146.934' r='1' fill='#242631' />
          <circle cx='173.438' cy='146.934' r='1' fill='#242631' />
          <circle cx='179.438' cy='146.934' r='1' fill='#242631' />
          <circle cx='185.438' cy='146.934' r='1' fill='#242631' />
          <circle cx='191.438' cy='146.934' r='1' fill='#242631' />
          <circle cx='197.438' cy='146.934' r='1' fill='#242631' />
          <circle cx='-0.561523' cy='51.6309' r='1' fill='#242631' />
          <circle cx='5.43848' cy='51.6309' r='1' fill='#242631' />
          <path
            d='M12.4385 51.6309C12.4385 52.1831 11.9908 52.6309 11.4385 52.6309C10.8862 52.6309 10.4385 52.1831 10.4385 51.6309C10.4385 51.0786 10.8862 50.6309 11.4385 50.6309C11.9908 50.6309 12.4385 51.0786 12.4385 51.6309Z'
            fill='#242631'
          />
          <path
            d='M18.4385 51.6309C18.4385 52.1831 17.9908 52.6309 17.4385 52.6309C16.8862 52.6309 16.4385 52.1831 16.4385 51.6309C16.4385 51.0786 16.8862 50.6309 17.4385 50.6309C17.9908 50.6309 18.4385 51.0786 18.4385 51.6309Z'
            fill='#242631'
          />
          <path
            d='M24.4385 51.6309C24.4385 52.1831 23.9908 52.6309 23.4385 52.6309C22.8862 52.6309 22.4385 52.1831 22.4385 51.6309C22.4385 51.0786 22.8862 50.6309 23.4385 50.6309C23.9908 50.6309 24.4385 51.0786 24.4385 51.6309Z'
            fill='#242631'
          />
          <path
            d='M30.4385 51.6309C30.4385 52.1831 29.9908 52.6309 29.4385 52.6309C28.8862 52.6309 28.4385 52.1831 28.4385 51.6309C28.4385 51.0786 28.8862 50.6309 29.4385 50.6309C29.9908 50.6309 30.4385 51.0786 30.4385 51.6309Z'
            fill='#242631'
          />
          <path
            d='M36.4385 51.6309C36.4385 52.1831 35.9908 52.6309 35.4385 52.6309C34.8862 52.6309 34.4385 52.1831 34.4385 51.6309C34.4385 51.0786 34.8862 50.6309 35.4385 50.6309C35.9908 50.6309 36.4385 51.0786 36.4385 51.6309Z'
            fill='#242631'
          />
          <path
            d='M42.4385 51.6309C42.4385 52.1831 41.9908 52.6309 41.4385 52.6309C40.8862 52.6309 40.4385 52.1831 40.4385 51.6309C40.4385 51.0786 40.8862 50.6309 41.4385 50.6309C41.9908 50.6309 42.4385 51.0786 42.4385 51.6309Z'
            fill='#242631'
          />
          <path
            d='M48.4385 51.6309C48.4385 52.1831 47.9908 52.6309 47.4385 52.6309C46.8862 52.6309 46.4385 52.1831 46.4385 51.6309C46.4385 51.0786 46.8862 50.6309 47.4385 50.6309C47.9908 50.6309 48.4385 51.0786 48.4385 51.6309Z'
            fill='#242631'
          />
          <path
            d='M54.4385 51.6309C54.4385 52.1831 53.9908 52.6309 53.4385 52.6309C52.8862 52.6309 52.4385 52.1831 52.4385 51.6309C52.4385 51.0786 52.8862 50.6309 53.4385 50.6309C53.9908 50.6309 54.4385 51.0786 54.4385 51.6309Z'
            fill='#242631'
          />
          <path
            d='M60.4385 51.6309C60.4385 52.1831 59.9908 52.6309 59.4385 52.6309C58.8862 52.6309 58.4385 52.1831 58.4385 51.6309C58.4385 51.0786 58.8862 50.6309 59.4385 50.6309C59.9908 50.6309 60.4385 51.0786 60.4385 51.6309Z'
            fill='#242631'
          />
          <path
            d='M66.4385 51.6309C66.4385 52.1831 65.9908 52.6309 65.4385 52.6309C64.8862 52.6309 64.4385 52.1831 64.4385 51.6309C64.4385 51.0786 64.8862 50.6309 65.4385 50.6309C65.9908 50.6309 66.4385 51.0786 66.4385 51.6309Z'
            fill='#242631'
          />
          <path
            d='M72.4385 51.6309C72.4385 52.1831 71.9908 52.6309 71.4385 52.6309C70.8862 52.6309 70.4385 52.1831 70.4385 51.6309C70.4385 51.0786 70.8862 50.6309 71.4385 50.6309C71.9908 50.6309 72.4385 51.0786 72.4385 51.6309Z'
            fill='#242631'
          />
          <path
            d='M78.4385 51.6309C78.4385 52.1831 77.9908 52.6309 77.4385 52.6309C76.8862 52.6309 76.4385 52.1831 76.4385 51.6309C76.4385 51.0786 76.8862 50.6309 77.4385 50.6309C77.9908 50.6309 78.4385 51.0786 78.4385 51.6309Z'
            fill='#242631'
          />
          <path
            d='M84.4385 51.6309C84.4385 52.1831 83.9908 52.6309 83.4385 52.6309C82.8862 52.6309 82.4385 52.1831 82.4385 51.6309C82.4385 51.0786 82.8862 50.6309 83.4385 50.6309C83.9908 50.6309 84.4385 51.0786 84.4385 51.6309Z'
            fill='#242631'
          />
          <path
            d='M90.4385 51.6309C90.4385 52.1831 89.9908 52.6309 89.4385 52.6309C88.8862 52.6309 88.4385 52.1831 88.4385 51.6309C88.4385 51.0786 88.8862 50.6309 89.4385 50.6309C89.9908 50.6309 90.4385 51.0786 90.4385 51.6309Z'
            fill='#242631'
          />
          <path
            d='M96.4385 51.6309C96.4385 52.1831 95.9908 52.6309 95.4385 52.6309C94.8862 52.6309 94.4385 52.1831 94.4385 51.6309C94.4385 51.0786 94.8862 50.6309 95.4385 50.6309C95.9908 50.6309 96.4385 51.0786 96.4385 51.6309Z'
            fill='#242631'
          />
          <path
            d='M102.438 51.6309C102.438 52.1831 101.991 52.6309 101.438 52.6309C100.886 52.6309 100.438 52.1831 100.438 51.6309C100.438 51.0786 100.886 50.6309 101.438 50.6309C101.991 50.6309 102.438 51.0786 102.438 51.6309Z'
            fill='#242631'
          />
          <path
            d='M108.438 51.6309C108.438 52.1831 107.991 52.6309 107.438 52.6309C106.886 52.6309 106.438 52.1831 106.438 51.6309C106.438 51.0786 106.886 50.6309 107.438 50.6309C107.991 50.6309 108.438 51.0786 108.438 51.6309Z'
            fill='#242631'
          />
          <path
            d='M114.438 51.6309C114.438 52.1831 113.991 52.6309 113.438 52.6309C112.886 52.6309 112.438 52.1831 112.438 51.6309C112.438 51.0786 112.886 50.6309 113.438 50.6309C113.991 50.6309 114.438 51.0786 114.438 51.6309Z'
            fill='#242631'
          />
          <path
            d='M120.438 51.6309C120.438 52.1831 119.991 52.6309 119.438 52.6309C118.886 52.6309 118.438 52.1831 118.438 51.6309C118.438 51.0786 118.886 50.6309 119.438 50.6309C119.991 50.6309 120.438 51.0786 120.438 51.6309Z'
            fill='#242631'
          />
          <path
            d='M126.438 51.6309C126.438 52.1831 125.991 52.6309 125.438 52.6309C124.886 52.6309 124.438 52.1831 124.438 51.6309C124.438 51.0786 124.886 50.6309 125.438 50.6309C125.991 50.6309 126.438 51.0786 126.438 51.6309Z'
            fill='#242631'
          />
          <circle cx='131.438' cy='51.6309' r='1' fill='#242631' />
          <circle cx='137.438' cy='51.6309' r='1' fill='#242631' />
          <circle cx='143.438' cy='51.6309' r='1' fill='#242631' />
          <circle cx='149.438' cy='51.6309' r='1' fill='#242631' />
          <circle cx='155.438' cy='51.6309' r='1' fill='#242631' />
          <circle cx='161.438' cy='51.6309' r='1' fill='#242631' />
          <circle cx='167.438' cy='51.6309' r='1' fill='#242631' />
          <circle cx='173.438' cy='51.6309' r='1' fill='#242631' />
          <circle cx='179.438' cy='51.6309' r='1' fill='#242631' />
          <circle cx='185.438' cy='51.6309' r='1' fill='#242631' />
          <circle cx='191.438' cy='51.6309' r='1' fill='#242631' />
          <circle cx='197.438' cy='51.6309' r='1' fill='#242631' />
          <circle cx='-0.561523' cy='170.76' r='1' fill='#242631' />
          <circle cx='5.43848' cy='170.76' r='1' fill='#242631' />
          <path
            d='M12.4385 170.76C12.4385 171.312 11.9908 171.76 11.4385 171.76C10.8862 171.76 10.4385 171.312 10.4385 170.76C10.4385 170.207 10.8862 169.76 11.4385 169.76C11.9908 169.76 12.4385 170.207 12.4385 170.76Z'
            fill='#242631'
          />
          <path
            d='M18.4385 170.76C18.4385 171.312 17.9908 171.76 17.4385 171.76C16.8862 171.76 16.4385 171.312 16.4385 170.76C16.4385 170.207 16.8862 169.76 17.4385 169.76C17.9908 169.76 18.4385 170.207 18.4385 170.76Z'
            fill='#242631'
          />
          <path
            d='M24.4385 170.76C24.4385 171.312 23.9908 171.76 23.4385 171.76C22.8862 171.76 22.4385 171.312 22.4385 170.76C22.4385 170.207 22.8862 169.76 23.4385 169.76C23.9908 169.76 24.4385 170.207 24.4385 170.76Z'
            fill='#242631'
          />
          <path
            d='M30.4385 170.76C30.4385 171.312 29.9908 171.76 29.4385 171.76C28.8862 171.76 28.4385 171.312 28.4385 170.76C28.4385 170.207 28.8862 169.76 29.4385 169.76C29.9908 169.76 30.4385 170.207 30.4385 170.76Z'
            fill='#242631'
          />
          <path
            d='M36.4385 170.76C36.4385 171.312 35.9908 171.76 35.4385 171.76C34.8862 171.76 34.4385 171.312 34.4385 170.76C34.4385 170.207 34.8862 169.76 35.4385 169.76C35.9908 169.76 36.4385 170.207 36.4385 170.76Z'
            fill='#242631'
          />
          <path
            d='M42.4385 170.76C42.4385 171.312 41.9908 171.76 41.4385 171.76C40.8862 171.76 40.4385 171.312 40.4385 170.76C40.4385 170.207 40.8862 169.76 41.4385 169.76C41.9908 169.76 42.4385 170.207 42.4385 170.76Z'
            fill='#242631'
          />
          <path
            d='M48.4385 170.76C48.4385 171.312 47.9908 171.76 47.4385 171.76C46.8862 171.76 46.4385 171.312 46.4385 170.76C46.4385 170.207 46.8862 169.76 47.4385 169.76C47.9908 169.76 48.4385 170.207 48.4385 170.76Z'
            fill='#242631'
          />
          <path
            d='M54.4385 170.76C54.4385 171.312 53.9908 171.76 53.4385 171.76C52.8862 171.76 52.4385 171.312 52.4385 170.76C52.4385 170.207 52.8862 169.76 53.4385 169.76C53.9908 169.76 54.4385 170.207 54.4385 170.76Z'
            fill='#242631'
          />
          <path
            d='M60.4385 170.76C60.4385 171.312 59.9908 171.76 59.4385 171.76C58.8862 171.76 58.4385 171.312 58.4385 170.76C58.4385 170.207 58.8862 169.76 59.4385 169.76C59.9908 169.76 60.4385 170.207 60.4385 170.76Z'
            fill='#242631'
          />
          <path
            d='M66.4385 170.76C66.4385 171.312 65.9908 171.76 65.4385 171.76C64.8862 171.76 64.4385 171.312 64.4385 170.76C64.4385 170.207 64.8862 169.76 65.4385 169.76C65.9908 169.76 66.4385 170.207 66.4385 170.76Z'
            fill='#242631'
          />
          <path
            d='M72.4385 170.76C72.4385 171.312 71.9908 171.76 71.4385 171.76C70.8862 171.76 70.4385 171.312 70.4385 170.76C70.4385 170.207 70.8862 169.76 71.4385 169.76C71.9908 169.76 72.4385 170.207 72.4385 170.76Z'
            fill='#242631'
          />
          <path
            d='M78.4385 170.76C78.4385 171.312 77.9908 171.76 77.4385 171.76C76.8862 171.76 76.4385 171.312 76.4385 170.76C76.4385 170.207 76.8862 169.76 77.4385 169.76C77.9908 169.76 78.4385 170.207 78.4385 170.76Z'
            fill='#242631'
          />
          <path
            d='M84.4385 170.76C84.4385 171.312 83.9908 171.76 83.4385 171.76C82.8862 171.76 82.4385 171.312 82.4385 170.76C82.4385 170.207 82.8862 169.76 83.4385 169.76C83.9908 169.76 84.4385 170.207 84.4385 170.76Z'
            fill='#242631'
          />
          <path
            d='M90.4385 170.76C90.4385 171.312 89.9908 171.76 89.4385 171.76C88.8862 171.76 88.4385 171.312 88.4385 170.76C88.4385 170.207 88.8862 169.76 89.4385 169.76C89.9908 169.76 90.4385 170.207 90.4385 170.76Z'
            fill='#242631'
          />
          <path
            d='M96.4385 170.76C96.4385 171.312 95.9908 171.76 95.4385 171.76C94.8862 171.76 94.4385 171.312 94.4385 170.76C94.4385 170.207 94.8862 169.76 95.4385 169.76C95.9908 169.76 96.4385 170.207 96.4385 170.76Z'
            fill='#242631'
          />
          <path
            d='M102.438 170.76C102.438 171.312 101.991 171.76 101.438 171.76C100.886 171.76 100.438 171.312 100.438 170.76C100.438 170.207 100.886 169.76 101.438 169.76C101.991 169.76 102.438 170.207 102.438 170.76Z'
            fill='#242631'
          />
          <path
            d='M108.438 170.76C108.438 171.312 107.991 171.76 107.438 171.76C106.886 171.76 106.438 171.312 106.438 170.76C106.438 170.207 106.886 169.76 107.438 169.76C107.991 169.76 108.438 170.207 108.438 170.76Z'
            fill='#242631'
          />
          <path
            d='M114.438 170.76C114.438 171.312 113.991 171.76 113.438 171.76C112.886 171.76 112.438 171.312 112.438 170.76C112.438 170.207 112.886 169.76 113.438 169.76C113.991 169.76 114.438 170.207 114.438 170.76Z'
            fill='#242631'
          />
          <path
            d='M120.438 170.76C120.438 171.312 119.991 171.76 119.438 171.76C118.886 171.76 118.438 171.312 118.438 170.76C118.438 170.207 118.886 169.76 119.438 169.76C119.991 169.76 120.438 170.207 120.438 170.76Z'
            fill='#242631'
          />
          <path
            d='M126.438 170.76C126.438 171.312 125.991 171.76 125.438 171.76C124.886 171.76 124.438 171.312 124.438 170.76C124.438 170.207 124.886 169.76 125.438 169.76C125.991 169.76 126.438 170.207 126.438 170.76Z'
            fill='#242631'
          />
          <circle cx='131.438' cy='170.76' r='1' fill='#242631' />
          <circle cx='137.438' cy='170.76' r='1' fill='#242631' />
          <circle cx='143.438' cy='170.76' r='1' fill='#242631' />
          <circle cx='149.438' cy='170.76' r='1' fill='#242631' />
          <circle cx='155.438' cy='170.76' r='1' fill='#242631' />
          <circle cx='161.438' cy='170.76' r='1' fill='#242631' />
          <circle cx='167.438' cy='170.76' r='1' fill='#242631' />
          <circle cx='173.438' cy='170.76' r='1' fill='#242631' />
          <circle cx='179.438' cy='170.76' r='1' fill='#242631' />
          <circle cx='185.438' cy='170.76' r='1' fill='#242631' />
          <circle cx='191.438' cy='170.76' r='1' fill='#242631' />
          <circle cx='197.438' cy='170.76' r='1' fill='#242631' />
          <circle cx='-0.561523' cy='75.457' r='1' fill='#242631' />
          <circle cx='5.43848' cy='75.457' r='1' fill='#242631' />
          <path
            d='M12.4385 75.457C12.4385 76.0093 11.9908 76.457 11.4385 76.457C10.8862 76.457 10.4385 76.0093 10.4385 75.457C10.4385 74.9047 10.8862 74.457 11.4385 74.457C11.9908 74.457 12.4385 74.9047 12.4385 75.457Z'
            fill='#242631'
          />
          <path
            d='M18.4385 75.457C18.4385 76.0093 17.9908 76.457 17.4385 76.457C16.8862 76.457 16.4385 76.0093 16.4385 75.457C16.4385 74.9047 16.8862 74.457 17.4385 74.457C17.9908 74.457 18.4385 74.9047 18.4385 75.457Z'
            fill='#242631'
          />
          <path
            d='M24.4385 75.457C24.4385 76.0093 23.9908 76.457 23.4385 76.457C22.8862 76.457 22.4385 76.0093 22.4385 75.457C22.4385 74.9047 22.8862 74.457 23.4385 74.457C23.9908 74.457 24.4385 74.9047 24.4385 75.457Z'
            fill='#242631'
          />
          <path
            d='M30.4385 75.457C30.4385 76.0093 29.9908 76.457 29.4385 76.457C28.8862 76.457 28.4385 76.0093 28.4385 75.457C28.4385 74.9047 28.8862 74.457 29.4385 74.457C29.9908 74.457 30.4385 74.9047 30.4385 75.457Z'
            fill='#242631'
          />
          <path
            d='M36.4385 75.457C36.4385 76.0093 35.9908 76.457 35.4385 76.457C34.8862 76.457 34.4385 76.0093 34.4385 75.457C34.4385 74.9047 34.8862 74.457 35.4385 74.457C35.9908 74.457 36.4385 74.9047 36.4385 75.457Z'
            fill='#242631'
          />
          <path
            d='M42.4385 75.457C42.4385 76.0093 41.9908 76.457 41.4385 76.457C40.8862 76.457 40.4385 76.0093 40.4385 75.457C40.4385 74.9047 40.8862 74.457 41.4385 74.457C41.9908 74.457 42.4385 74.9047 42.4385 75.457Z'
            fill='#242631'
          />
          <path
            d='M48.4385 75.457C48.4385 76.0093 47.9908 76.457 47.4385 76.457C46.8862 76.457 46.4385 76.0093 46.4385 75.457C46.4385 74.9047 46.8862 74.457 47.4385 74.457C47.9908 74.457 48.4385 74.9047 48.4385 75.457Z'
            fill='#242631'
          />
          <path
            d='M54.4385 75.457C54.4385 76.0093 53.9908 76.457 53.4385 76.457C52.8862 76.457 52.4385 76.0093 52.4385 75.457C52.4385 74.9047 52.8862 74.457 53.4385 74.457C53.9908 74.457 54.4385 74.9047 54.4385 75.457Z'
            fill='#242631'
          />
          <path
            d='M60.4385 75.457C60.4385 76.0093 59.9908 76.457 59.4385 76.457C58.8862 76.457 58.4385 76.0093 58.4385 75.457C58.4385 74.9047 58.8862 74.457 59.4385 74.457C59.9908 74.457 60.4385 74.9047 60.4385 75.457Z'
            fill='#242631'
          />
          <path
            d='M66.4385 75.457C66.4385 76.0093 65.9908 76.457 65.4385 76.457C64.8862 76.457 64.4385 76.0093 64.4385 75.457C64.4385 74.9047 64.8862 74.457 65.4385 74.457C65.9908 74.457 66.4385 74.9047 66.4385 75.457Z'
            fill='#242631'
          />
          <path
            d='M72.4385 75.457C72.4385 76.0093 71.9908 76.457 71.4385 76.457C70.8862 76.457 70.4385 76.0093 70.4385 75.457C70.4385 74.9047 70.8862 74.457 71.4385 74.457C71.9908 74.457 72.4385 74.9047 72.4385 75.457Z'
            fill='#242631'
          />
          <path
            d='M78.4385 75.457C78.4385 76.0093 77.9908 76.457 77.4385 76.457C76.8862 76.457 76.4385 76.0093 76.4385 75.457C76.4385 74.9047 76.8862 74.457 77.4385 74.457C77.9908 74.457 78.4385 74.9047 78.4385 75.457Z'
            fill='#242631'
          />
          <path
            d='M84.4385 75.457C84.4385 76.0093 83.9908 76.457 83.4385 76.457C82.8862 76.457 82.4385 76.0093 82.4385 75.457C82.4385 74.9047 82.8862 74.457 83.4385 74.457C83.9908 74.457 84.4385 74.9047 84.4385 75.457Z'
            fill='#242631'
          />
          <path
            d='M90.4385 75.457C90.4385 76.0093 89.9908 76.457 89.4385 76.457C88.8862 76.457 88.4385 76.0093 88.4385 75.457C88.4385 74.9047 88.8862 74.457 89.4385 74.457C89.9908 74.457 90.4385 74.9047 90.4385 75.457Z'
            fill='#242631'
          />
          <path
            d='M96.4385 75.457C96.4385 76.0093 95.9908 76.457 95.4385 76.457C94.8862 76.457 94.4385 76.0093 94.4385 75.457C94.4385 74.9047 94.8862 74.457 95.4385 74.457C95.9908 74.457 96.4385 74.9047 96.4385 75.457Z'
            fill='#242631'
          />
          <path
            d='M102.438 75.457C102.438 76.0093 101.991 76.457 101.438 76.457C100.886 76.457 100.438 76.0093 100.438 75.457C100.438 74.9047 100.886 74.457 101.438 74.457C101.991 74.457 102.438 74.9047 102.438 75.457Z'
            fill='#242631'
          />
          <path
            d='M108.438 75.457C108.438 76.0093 107.991 76.457 107.438 76.457C106.886 76.457 106.438 76.0093 106.438 75.457C106.438 74.9047 106.886 74.457 107.438 74.457C107.991 74.457 108.438 74.9047 108.438 75.457Z'
            fill='#242631'
          />
          <path
            d='M114.438 75.457C114.438 76.0093 113.991 76.457 113.438 76.457C112.886 76.457 112.438 76.0093 112.438 75.457C112.438 74.9047 112.886 74.457 113.438 74.457C113.991 74.457 114.438 74.9047 114.438 75.457Z'
            fill='#242631'
          />
          <path
            d='M120.438 75.457C120.438 76.0093 119.991 76.457 119.438 76.457C118.886 76.457 118.438 76.0093 118.438 75.457C118.438 74.9047 118.886 74.457 119.438 74.457C119.991 74.457 120.438 74.9047 120.438 75.457Z'
            fill='#242631'
          />
          <path
            d='M126.438 75.457C126.438 76.0093 125.991 76.457 125.438 76.457C124.886 76.457 124.438 76.0093 124.438 75.457C124.438 74.9047 124.886 74.457 125.438 74.457C125.991 74.457 126.438 74.9047 126.438 75.457Z'
            fill='#242631'
          />
          <circle cx='131.438' cy='75.457' r='1' fill='#242631' />
          <circle cx='137.438' cy='75.457' r='1' fill='#242631' />
          <circle cx='143.438' cy='75.457' r='1' fill='#242631' />
          <circle cx='149.438' cy='75.457' r='1' fill='#242631' />
          <circle cx='155.438' cy='75.457' r='1' fill='#242631' />
          <circle cx='161.438' cy='75.457' r='1' fill='#242631' />
          <circle cx='167.438' cy='75.457' r='1' fill='#242631' />
          <circle cx='173.438' cy='75.457' r='1' fill='#242631' />
          <circle cx='179.438' cy='75.457' r='1' fill='#242631' />
          <circle cx='185.438' cy='75.457' r='1' fill='#242631' />
          <circle cx='191.438' cy='75.457' r='1' fill='#242631' />
          <circle cx='197.438' cy='75.457' r='1' fill='#242631' />
          <circle cx='-0.561523' cy='194.586' r='1' fill='#242631' />
          <circle cx='5.43848' cy='194.586' r='1' fill='#242631' />
          <path
            d='M12.4385 194.586C12.4385 195.138 11.9908 195.586 11.4385 195.586C10.8862 195.586 10.4385 195.138 10.4385 194.586C10.4385 194.034 10.8862 193.586 11.4385 193.586C11.9908 193.586 12.4385 194.034 12.4385 194.586Z'
            fill='#242631'
          />
          <path
            d='M18.4385 194.586C18.4385 195.138 17.9908 195.586 17.4385 195.586C16.8862 195.586 16.4385 195.138 16.4385 194.586C16.4385 194.034 16.8862 193.586 17.4385 193.586C17.9908 193.586 18.4385 194.034 18.4385 194.586Z'
            fill='#242631'
          />
          <path
            d='M24.4385 194.586C24.4385 195.138 23.9908 195.586 23.4385 195.586C22.8862 195.586 22.4385 195.138 22.4385 194.586C22.4385 194.034 22.8862 193.586 23.4385 193.586C23.9908 193.586 24.4385 194.034 24.4385 194.586Z'
            fill='#242631'
          />
          <path
            d='M30.4385 194.586C30.4385 195.138 29.9908 195.586 29.4385 195.586C28.8862 195.586 28.4385 195.138 28.4385 194.586C28.4385 194.034 28.8862 193.586 29.4385 193.586C29.9908 193.586 30.4385 194.034 30.4385 194.586Z'
            fill='#242631'
          />
          <path
            d='M36.4385 194.586C36.4385 195.138 35.9908 195.586 35.4385 195.586C34.8862 195.586 34.4385 195.138 34.4385 194.586C34.4385 194.034 34.8862 193.586 35.4385 193.586C35.9908 193.586 36.4385 194.034 36.4385 194.586Z'
            fill='#242631'
          />
          <path
            d='M42.4385 194.586C42.4385 195.138 41.9908 195.586 41.4385 195.586C40.8862 195.586 40.4385 195.138 40.4385 194.586C40.4385 194.034 40.8862 193.586 41.4385 193.586C41.9908 193.586 42.4385 194.034 42.4385 194.586Z'
            fill='#242631'
          />
          <path
            d='M48.4385 194.586C48.4385 195.138 47.9908 195.586 47.4385 195.586C46.8862 195.586 46.4385 195.138 46.4385 194.586C46.4385 194.034 46.8862 193.586 47.4385 193.586C47.9908 193.586 48.4385 194.034 48.4385 194.586Z'
            fill='#242631'
          />
          <path
            d='M54.4385 194.586C54.4385 195.138 53.9908 195.586 53.4385 195.586C52.8862 195.586 52.4385 195.138 52.4385 194.586C52.4385 194.034 52.8862 193.586 53.4385 193.586C53.9908 193.586 54.4385 194.034 54.4385 194.586Z'
            fill='#242631'
          />
          <path
            d='M60.4385 194.586C60.4385 195.138 59.9908 195.586 59.4385 195.586C58.8862 195.586 58.4385 195.138 58.4385 194.586C58.4385 194.034 58.8862 193.586 59.4385 193.586C59.9908 193.586 60.4385 194.034 60.4385 194.586Z'
            fill='#242631'
          />
          <path
            d='M66.4385 194.586C66.4385 195.138 65.9908 195.586 65.4385 195.586C64.8862 195.586 64.4385 195.138 64.4385 194.586C64.4385 194.034 64.8862 193.586 65.4385 193.586C65.9908 193.586 66.4385 194.034 66.4385 194.586Z'
            fill='#242631'
          />
          <path
            d='M72.4385 194.586C72.4385 195.138 71.9908 195.586 71.4385 195.586C70.8862 195.586 70.4385 195.138 70.4385 194.586C70.4385 194.034 70.8862 193.586 71.4385 193.586C71.9908 193.586 72.4385 194.034 72.4385 194.586Z'
            fill='#242631'
          />
          <path
            d='M78.4385 194.586C78.4385 195.138 77.9908 195.586 77.4385 195.586C76.8862 195.586 76.4385 195.138 76.4385 194.586C76.4385 194.034 76.8862 193.586 77.4385 193.586C77.9908 193.586 78.4385 194.034 78.4385 194.586Z'
            fill='#242631'
          />
          <path
            d='M84.4385 194.586C84.4385 195.138 83.9908 195.586 83.4385 195.586C82.8862 195.586 82.4385 195.138 82.4385 194.586C82.4385 194.034 82.8862 193.586 83.4385 193.586C83.9908 193.586 84.4385 194.034 84.4385 194.586Z'
            fill='#242631'
          />
          <path
            d='M90.4385 194.586C90.4385 195.138 89.9908 195.586 89.4385 195.586C88.8862 195.586 88.4385 195.138 88.4385 194.586C88.4385 194.034 88.8862 193.586 89.4385 193.586C89.9908 193.586 90.4385 194.034 90.4385 194.586Z'
            fill='#242631'
          />
          <path
            d='M96.4385 194.586C96.4385 195.138 95.9908 195.586 95.4385 195.586C94.8862 195.586 94.4385 195.138 94.4385 194.586C94.4385 194.034 94.8862 193.586 95.4385 193.586C95.9908 193.586 96.4385 194.034 96.4385 194.586Z'
            fill='#242631'
          />
          <path
            d='M102.438 194.586C102.438 195.138 101.991 195.586 101.438 195.586C100.886 195.586 100.438 195.138 100.438 194.586C100.438 194.034 100.886 193.586 101.438 193.586C101.991 193.586 102.438 194.034 102.438 194.586Z'
            fill='#242631'
          />
          <path
            d='M108.438 194.586C108.438 195.138 107.991 195.586 107.438 195.586C106.886 195.586 106.438 195.138 106.438 194.586C106.438 194.034 106.886 193.586 107.438 193.586C107.991 193.586 108.438 194.034 108.438 194.586Z'
            fill='#242631'
          />
          <path
            d='M114.438 194.586C114.438 195.138 113.991 195.586 113.438 195.586C112.886 195.586 112.438 195.138 112.438 194.586C112.438 194.034 112.886 193.586 113.438 193.586C113.991 193.586 114.438 194.034 114.438 194.586Z'
            fill='#242631'
          />
          <path
            d='M120.438 194.586C120.438 195.138 119.991 195.586 119.438 195.586C118.886 195.586 118.438 195.138 118.438 194.586C118.438 194.034 118.886 193.586 119.438 193.586C119.991 193.586 120.438 194.034 120.438 194.586Z'
            fill='#242631'
          />
          <path
            d='M126.438 194.586C126.438 195.138 125.991 195.586 125.438 195.586C124.886 195.586 124.438 195.138 124.438 194.586C124.438 194.034 124.886 193.586 125.438 193.586C125.991 193.586 126.438 194.034 126.438 194.586Z'
            fill='#242631'
          />
          <circle cx='131.438' cy='194.586' r='1' fill='#242631' />
          <circle cx='137.438' cy='194.586' r='1' fill='#242631' />
          <circle cx='143.438' cy='194.586' r='1' fill='#242631' />
          <circle cx='149.438' cy='194.586' r='1' fill='#242631' />
          <circle cx='155.438' cy='194.586' r='1' fill='#242631' />
          <circle cx='161.438' cy='194.586' r='1' fill='#242631' />
          <circle cx='167.438' cy='194.586' r='1' fill='#242631' />
          <circle cx='173.438' cy='194.586' r='1' fill='#242631' />
          <circle cx='179.438' cy='194.586' r='1' fill='#242631' />
          <circle cx='185.438' cy='194.586' r='1' fill='#242631' />
          <circle cx='191.438' cy='194.586' r='1' fill='#242631' />
          <circle cx='197.438' cy='194.586' r='1' fill='#242631' />
          <circle cx='-0.561523' cy='99.2812' r='1' fill='#242631' />
          <circle cx='5.43848' cy='99.2812' r='1' fill='#242631' />
          <path
            d='M12.4385 99.2812C12.4385 99.8335 11.9908 100.281 11.4385 100.281C10.8862 100.281 10.4385 99.8335 10.4385 99.2812C10.4385 98.729 10.8862 98.2812 11.4385 98.2812C11.9908 98.2812 12.4385 98.729 12.4385 99.2812Z'
            fill='#242631'
          />
          <path
            d='M18.4385 99.2812C18.4385 99.8335 17.9908 100.281 17.4385 100.281C16.8862 100.281 16.4385 99.8335 16.4385 99.2812C16.4385 98.729 16.8862 98.2812 17.4385 98.2812C17.9908 98.2812 18.4385 98.729 18.4385 99.2812Z'
            fill='#242631'
          />
          <path
            d='M24.4385 99.2812C24.4385 99.8335 23.9908 100.281 23.4385 100.281C22.8862 100.281 22.4385 99.8335 22.4385 99.2812C22.4385 98.729 22.8862 98.2812 23.4385 98.2812C23.9908 98.2812 24.4385 98.729 24.4385 99.2812Z'
            fill='#242631'
          />
          <path
            d='M30.4385 99.2812C30.4385 99.8335 29.9908 100.281 29.4385 100.281C28.8862 100.281 28.4385 99.8335 28.4385 99.2812C28.4385 98.729 28.8862 98.2812 29.4385 98.2812C29.9908 98.2812 30.4385 98.729 30.4385 99.2812Z'
            fill='#242631'
          />
          <path
            d='M36.4385 99.2812C36.4385 99.8335 35.9908 100.281 35.4385 100.281C34.8862 100.281 34.4385 99.8335 34.4385 99.2812C34.4385 98.729 34.8862 98.2812 35.4385 98.2812C35.9908 98.2812 36.4385 98.729 36.4385 99.2812Z'
            fill='#242631'
          />
          <path
            d='M42.4385 99.2812C42.4385 99.8335 41.9908 100.281 41.4385 100.281C40.8862 100.281 40.4385 99.8335 40.4385 99.2812C40.4385 98.729 40.8862 98.2812 41.4385 98.2812C41.9908 98.2812 42.4385 98.729 42.4385 99.2812Z'
            fill='#242631'
          />
          <path
            d='M48.4385 99.2812C48.4385 99.8335 47.9908 100.281 47.4385 100.281C46.8862 100.281 46.4385 99.8335 46.4385 99.2812C46.4385 98.729 46.8862 98.2812 47.4385 98.2812C47.9908 98.2812 48.4385 98.729 48.4385 99.2812Z'
            fill='#242631'
          />
          <path
            d='M54.4385 99.2812C54.4385 99.8335 53.9908 100.281 53.4385 100.281C52.8862 100.281 52.4385 99.8335 52.4385 99.2812C52.4385 98.729 52.8862 98.2812 53.4385 98.2812C53.9908 98.2812 54.4385 98.729 54.4385 99.2812Z'
            fill='#242631'
          />
          <path
            d='M60.4385 99.2812C60.4385 99.8335 59.9908 100.281 59.4385 100.281C58.8862 100.281 58.4385 99.8335 58.4385 99.2812C58.4385 98.729 58.8862 98.2812 59.4385 98.2812C59.9908 98.2812 60.4385 98.729 60.4385 99.2812Z'
            fill='#242631'
          />
          <path
            d='M66.4385 99.2812C66.4385 99.8335 65.9908 100.281 65.4385 100.281C64.8862 100.281 64.4385 99.8335 64.4385 99.2812C64.4385 98.729 64.8862 98.2812 65.4385 98.2812C65.9908 98.2812 66.4385 98.729 66.4385 99.2812Z'
            fill='#242631'
          />
          <path
            d='M72.4385 99.2812C72.4385 99.8335 71.9908 100.281 71.4385 100.281C70.8862 100.281 70.4385 99.8335 70.4385 99.2812C70.4385 98.729 70.8862 98.2812 71.4385 98.2812C71.9908 98.2812 72.4385 98.729 72.4385 99.2812Z'
            fill='#242631'
          />
          <path
            d='M78.4385 99.2812C78.4385 99.8335 77.9908 100.281 77.4385 100.281C76.8862 100.281 76.4385 99.8335 76.4385 99.2812C76.4385 98.729 76.8862 98.2812 77.4385 98.2812C77.9908 98.2812 78.4385 98.729 78.4385 99.2812Z'
            fill='#242631'
          />
          <path
            d='M84.4385 99.2812C84.4385 99.8335 83.9908 100.281 83.4385 100.281C82.8862 100.281 82.4385 99.8335 82.4385 99.2812C82.4385 98.729 82.8862 98.2812 83.4385 98.2812C83.9908 98.2812 84.4385 98.729 84.4385 99.2812Z'
            fill='#242631'
          />
          <path
            d='M90.4385 99.2812C90.4385 99.8335 89.9908 100.281 89.4385 100.281C88.8862 100.281 88.4385 99.8335 88.4385 99.2812C88.4385 98.729 88.8862 98.2812 89.4385 98.2812C89.9908 98.2812 90.4385 98.729 90.4385 99.2812Z'
            fill='#242631'
          />
          <path
            d='M96.4385 99.2812C96.4385 99.8335 95.9908 100.281 95.4385 100.281C94.8862 100.281 94.4385 99.8335 94.4385 99.2812C94.4385 98.729 94.8862 98.2812 95.4385 98.2812C95.9908 98.2812 96.4385 98.729 96.4385 99.2812Z'
            fill='#242631'
          />
          <path
            d='M102.438 99.2812C102.438 99.8335 101.991 100.281 101.438 100.281C100.886 100.281 100.438 99.8335 100.438 99.2812C100.438 98.729 100.886 98.2812 101.438 98.2812C101.991 98.2812 102.438 98.729 102.438 99.2812Z'
            fill='#242631'
          />
          <path
            d='M108.438 99.2812C108.438 99.8335 107.991 100.281 107.438 100.281C106.886 100.281 106.438 99.8335 106.438 99.2812C106.438 98.729 106.886 98.2812 107.438 98.2812C107.991 98.2812 108.438 98.729 108.438 99.2812Z'
            fill='#242631'
          />
          <path
            d='M114.438 99.2812C114.438 99.8335 113.991 100.281 113.438 100.281C112.886 100.281 112.438 99.8335 112.438 99.2812C112.438 98.729 112.886 98.2812 113.438 98.2812C113.991 98.2812 114.438 98.729 114.438 99.2812Z'
            fill='#242631'
          />
          <path
            d='M120.438 99.2812C120.438 99.8335 119.991 100.281 119.438 100.281C118.886 100.281 118.438 99.8335 118.438 99.2812C118.438 98.729 118.886 98.2812 119.438 98.2812C119.991 98.2812 120.438 98.729 120.438 99.2812Z'
            fill='#242631'
          />
          <path
            d='M126.438 99.2812C126.438 99.8335 125.991 100.281 125.438 100.281C124.886 100.281 124.438 99.8335 124.438 99.2812C124.438 98.729 124.886 98.2812 125.438 98.2812C125.991 98.2812 126.438 98.729 126.438 99.2812Z'
            fill='#242631'
          />
          <circle cx='131.438' cy='99.2812' r='1' fill='#242631' />
          <circle cx='137.438' cy='99.2812' r='1' fill='#242631' />
          <circle cx='143.438' cy='99.2812' r='1' fill='#242631' />
          <circle cx='149.438' cy='99.2812' r='1' fill='#242631' />
          <circle cx='155.438' cy='99.2812' r='1' fill='#242631' />
          <circle cx='161.438' cy='99.2812' r='1' fill='#242631' />
          <circle cx='167.438' cy='99.2812' r='1' fill='#242631' />
          <circle cx='173.438' cy='99.2812' r='1' fill='#242631' />
          <circle cx='179.438' cy='99.2812' r='1' fill='#242631' />
          <circle cx='185.438' cy='99.2812' r='1' fill='#242631' />
          <circle cx='191.438' cy='99.2812' r='1' fill='#242631' />
          <circle cx='197.438' cy='99.2812' r='1' fill='#242631' />
          <circle cx='-0.561523' cy='15.8906' r='1' fill='#242631' />
          <circle cx='5.43848' cy='15.8906' r='1' fill='#242631' />
          <path
            d='M12.4385 15.8906C12.4385 16.4429 11.9908 16.8906 11.4385 16.8906C10.8862 16.8906 10.4385 16.4429 10.4385 15.8906C10.4385 15.3383 10.8862 14.8906 11.4385 14.8906C11.9908 14.8906 12.4385 15.3383 12.4385 15.8906Z'
            fill='#242631'
          />
          <path
            d='M18.4385 15.8906C18.4385 16.4429 17.9908 16.8906 17.4385 16.8906C16.8862 16.8906 16.4385 16.4429 16.4385 15.8906C16.4385 15.3383 16.8862 14.8906 17.4385 14.8906C17.9908 14.8906 18.4385 15.3383 18.4385 15.8906Z'
            fill='#242631'
          />
          <path
            d='M24.4385 15.8906C24.4385 16.4429 23.9908 16.8906 23.4385 16.8906C22.8862 16.8906 22.4385 16.4429 22.4385 15.8906C22.4385 15.3383 22.8862 14.8906 23.4385 14.8906C23.9908 14.8906 24.4385 15.3383 24.4385 15.8906Z'
            fill='#242631'
          />
          <path
            d='M30.4385 15.8906C30.4385 16.4429 29.9908 16.8906 29.4385 16.8906C28.8862 16.8906 28.4385 16.4429 28.4385 15.8906C28.4385 15.3383 28.8862 14.8906 29.4385 14.8906C29.9908 14.8906 30.4385 15.3383 30.4385 15.8906Z'
            fill='#242631'
          />
          <path
            d='M36.4385 15.8906C36.4385 16.4429 35.9908 16.8906 35.4385 16.8906C34.8862 16.8906 34.4385 16.4429 34.4385 15.8906C34.4385 15.3383 34.8862 14.8906 35.4385 14.8906C35.9908 14.8906 36.4385 15.3383 36.4385 15.8906Z'
            fill='#242631'
          />
          <path
            d='M42.4385 15.8906C42.4385 16.4429 41.9908 16.8906 41.4385 16.8906C40.8862 16.8906 40.4385 16.4429 40.4385 15.8906C40.4385 15.3383 40.8862 14.8906 41.4385 14.8906C41.9908 14.8906 42.4385 15.3383 42.4385 15.8906Z'
            fill='#242631'
          />
          <path
            d='M48.4385 15.8906C48.4385 16.4429 47.9908 16.8906 47.4385 16.8906C46.8862 16.8906 46.4385 16.4429 46.4385 15.8906C46.4385 15.3383 46.8862 14.8906 47.4385 14.8906C47.9908 14.8906 48.4385 15.3383 48.4385 15.8906Z'
            fill='#242631'
          />
          <path
            d='M54.4385 15.8906C54.4385 16.4429 53.9908 16.8906 53.4385 16.8906C52.8862 16.8906 52.4385 16.4429 52.4385 15.8906C52.4385 15.3383 52.8862 14.8906 53.4385 14.8906C53.9908 14.8906 54.4385 15.3383 54.4385 15.8906Z'
            fill='#242631'
          />
          <path
            d='M60.4385 15.8906C60.4385 16.4429 59.9908 16.8906 59.4385 16.8906C58.8862 16.8906 58.4385 16.4429 58.4385 15.8906C58.4385 15.3383 58.8862 14.8906 59.4385 14.8906C59.9908 14.8906 60.4385 15.3383 60.4385 15.8906Z'
            fill='#242631'
          />
          <path
            d='M66.4385 15.8906C66.4385 16.4429 65.9908 16.8906 65.4385 16.8906C64.8862 16.8906 64.4385 16.4429 64.4385 15.8906C64.4385 15.3383 64.8862 14.8906 65.4385 14.8906C65.9908 14.8906 66.4385 15.3383 66.4385 15.8906Z'
            fill='#242631'
          />
          <path
            d='M72.4385 15.8906C72.4385 16.4429 71.9908 16.8906 71.4385 16.8906C70.8862 16.8906 70.4385 16.4429 70.4385 15.8906C70.4385 15.3383 70.8862 14.8906 71.4385 14.8906C71.9908 14.8906 72.4385 15.3383 72.4385 15.8906Z'
            fill='#242631'
          />
          <path
            d='M78.4385 15.8906C78.4385 16.4429 77.9908 16.8906 77.4385 16.8906C76.8862 16.8906 76.4385 16.4429 76.4385 15.8906C76.4385 15.3383 76.8862 14.8906 77.4385 14.8906C77.9908 14.8906 78.4385 15.3383 78.4385 15.8906Z'
            fill='#242631'
          />
          <path
            d='M84.4385 15.8906C84.4385 16.4429 83.9908 16.8906 83.4385 16.8906C82.8862 16.8906 82.4385 16.4429 82.4385 15.8906C82.4385 15.3383 82.8862 14.8906 83.4385 14.8906C83.9908 14.8906 84.4385 15.3383 84.4385 15.8906Z'
            fill='#242631'
          />
          <path
            d='M90.4385 15.8906C90.4385 16.4429 89.9908 16.8906 89.4385 16.8906C88.8862 16.8906 88.4385 16.4429 88.4385 15.8906C88.4385 15.3383 88.8862 14.8906 89.4385 14.8906C89.9908 14.8906 90.4385 15.3383 90.4385 15.8906Z'
            fill='#242631'
          />
          <path
            d='M96.4385 15.8906C96.4385 16.4429 95.9908 16.8906 95.4385 16.8906C94.8862 16.8906 94.4385 16.4429 94.4385 15.8906C94.4385 15.3383 94.8862 14.8906 95.4385 14.8906C95.9908 14.8906 96.4385 15.3383 96.4385 15.8906Z'
            fill='#242631'
          />
          <path
            d='M102.438 15.8906C102.438 16.4429 101.991 16.8906 101.438 16.8906C100.886 16.8906 100.438 16.4429 100.438 15.8906C100.438 15.3383 100.886 14.8906 101.438 14.8906C101.991 14.8906 102.438 15.3383 102.438 15.8906Z'
            fill='#242631'
          />
          <path
            d='M108.438 15.8906C108.438 16.4429 107.991 16.8906 107.438 16.8906C106.886 16.8906 106.438 16.4429 106.438 15.8906C106.438 15.3383 106.886 14.8906 107.438 14.8906C107.991 14.8906 108.438 15.3383 108.438 15.8906Z'
            fill='#242631'
          />
          <path
            d='M114.438 15.8906C114.438 16.4429 113.991 16.8906 113.438 16.8906C112.886 16.8906 112.438 16.4429 112.438 15.8906C112.438 15.3383 112.886 14.8906 113.438 14.8906C113.991 14.8906 114.438 15.3383 114.438 15.8906Z'
            fill='#242631'
          />
          <path
            d='M120.438 15.8906C120.438 16.4429 119.991 16.8906 119.438 16.8906C118.886 16.8906 118.438 16.4429 118.438 15.8906C118.438 15.3383 118.886 14.8906 119.438 14.8906C119.991 14.8906 120.438 15.3383 120.438 15.8906Z'
            fill='#242631'
          />
          <path
            d='M126.438 15.8906C126.438 16.4429 125.991 16.8906 125.438 16.8906C124.886 16.8906 124.438 16.4429 124.438 15.8906C124.438 15.3383 124.886 14.8906 125.438 14.8906C125.991 14.8906 126.438 15.3383 126.438 15.8906Z'
            fill='#242631'
          />
          <circle cx='131.438' cy='15.8906' r='1' fill='#242631' />
          <circle cx='137.438' cy='15.8906' r='1' fill='#242631' />
          <circle cx='143.438' cy='15.8906' r='1' fill='#242631' />
          <circle cx='149.438' cy='15.8906' r='1' fill='#242631' />
          <circle cx='155.438' cy='15.8906' r='1' fill='#242631' />
          <circle cx='161.438' cy='15.8906' r='1' fill='#242631' />
          <circle cx='167.438' cy='15.8906' r='1' fill='#242631' />
          <circle cx='173.438' cy='15.8906' r='1' fill='#242631' />
          <circle cx='179.438' cy='15.8906' r='1' fill='#242631' />
          <circle cx='185.438' cy='15.8906' r='1' fill='#242631' />
          <circle cx='191.438' cy='15.8906' r='1' fill='#242631' />
          <circle cx='197.438' cy='15.8906' r='1' fill='#242631' />
          <circle cx='-0.561523' cy='135.021' r='1' fill='#242631' />
          <circle cx='5.43848' cy='135.021' r='1' fill='#242631' />
          <path
            d='M12.4385 135.021C12.4385 135.574 11.9908 136.021 11.4385 136.021C10.8862 136.021 10.4385 135.574 10.4385 135.021C10.4385 134.469 10.8862 134.021 11.4385 134.021C11.9908 134.021 12.4385 134.469 12.4385 135.021Z'
            fill='#242631'
          />
          <path
            d='M18.4385 135.021C18.4385 135.574 17.9908 136.021 17.4385 136.021C16.8862 136.021 16.4385 135.574 16.4385 135.021C16.4385 134.469 16.8862 134.021 17.4385 134.021C17.9908 134.021 18.4385 134.469 18.4385 135.021Z'
            fill='#242631'
          />
          <path
            d='M24.4385 135.021C24.4385 135.574 23.9908 136.021 23.4385 136.021C22.8862 136.021 22.4385 135.574 22.4385 135.021C22.4385 134.469 22.8862 134.021 23.4385 134.021C23.9908 134.021 24.4385 134.469 24.4385 135.021Z'
            fill='#242631'
          />
          <path
            d='M30.4385 135.021C30.4385 135.574 29.9908 136.021 29.4385 136.021C28.8862 136.021 28.4385 135.574 28.4385 135.021C28.4385 134.469 28.8862 134.021 29.4385 134.021C29.9908 134.021 30.4385 134.469 30.4385 135.021Z'
            fill='#242631'
          />
          <path
            d='M36.4385 135.021C36.4385 135.574 35.9908 136.021 35.4385 136.021C34.8862 136.021 34.4385 135.574 34.4385 135.021C34.4385 134.469 34.8862 134.021 35.4385 134.021C35.9908 134.021 36.4385 134.469 36.4385 135.021Z'
            fill='#242631'
          />
          <path
            d='M42.4385 135.021C42.4385 135.574 41.9908 136.021 41.4385 136.021C40.8862 136.021 40.4385 135.574 40.4385 135.021C40.4385 134.469 40.8862 134.021 41.4385 134.021C41.9908 134.021 42.4385 134.469 42.4385 135.021Z'
            fill='#242631'
          />
          <path
            d='M48.4385 135.021C48.4385 135.574 47.9908 136.021 47.4385 136.021C46.8862 136.021 46.4385 135.574 46.4385 135.021C46.4385 134.469 46.8862 134.021 47.4385 134.021C47.9908 134.021 48.4385 134.469 48.4385 135.021Z'
            fill='#242631'
          />
          <path
            d='M54.4385 135.021C54.4385 135.574 53.9908 136.021 53.4385 136.021C52.8862 136.021 52.4385 135.574 52.4385 135.021C52.4385 134.469 52.8862 134.021 53.4385 134.021C53.9908 134.021 54.4385 134.469 54.4385 135.021Z'
            fill='#242631'
          />
          <path
            d='M60.4385 135.021C60.4385 135.574 59.9908 136.021 59.4385 136.021C58.8862 136.021 58.4385 135.574 58.4385 135.021C58.4385 134.469 58.8862 134.021 59.4385 134.021C59.9908 134.021 60.4385 134.469 60.4385 135.021Z'
            fill='#242631'
          />
          <path
            d='M66.4385 135.021C66.4385 135.574 65.9908 136.021 65.4385 136.021C64.8862 136.021 64.4385 135.574 64.4385 135.021C64.4385 134.469 64.8862 134.021 65.4385 134.021C65.9908 134.021 66.4385 134.469 66.4385 135.021Z'
            fill='#242631'
          />
          <path
            d='M72.4385 135.021C72.4385 135.574 71.9908 136.021 71.4385 136.021C70.8862 136.021 70.4385 135.574 70.4385 135.021C70.4385 134.469 70.8862 134.021 71.4385 134.021C71.9908 134.021 72.4385 134.469 72.4385 135.021Z'
            fill='#242631'
          />
          <path
            d='M78.4385 135.021C78.4385 135.574 77.9908 136.021 77.4385 136.021C76.8862 136.021 76.4385 135.574 76.4385 135.021C76.4385 134.469 76.8862 134.021 77.4385 134.021C77.9908 134.021 78.4385 134.469 78.4385 135.021Z'
            fill='#242631'
          />
          <path
            d='M84.4385 135.021C84.4385 135.574 83.9908 136.021 83.4385 136.021C82.8862 136.021 82.4385 135.574 82.4385 135.021C82.4385 134.469 82.8862 134.021 83.4385 134.021C83.9908 134.021 84.4385 134.469 84.4385 135.021Z'
            fill='#242631'
          />
          <path
            d='M90.4385 135.021C90.4385 135.574 89.9908 136.021 89.4385 136.021C88.8862 136.021 88.4385 135.574 88.4385 135.021C88.4385 134.469 88.8862 134.021 89.4385 134.021C89.9908 134.021 90.4385 134.469 90.4385 135.021Z'
            fill='#242631'
          />
          <path
            d='M96.4385 135.021C96.4385 135.574 95.9908 136.021 95.4385 136.021C94.8862 136.021 94.4385 135.574 94.4385 135.021C94.4385 134.469 94.8862 134.021 95.4385 134.021C95.9908 134.021 96.4385 134.469 96.4385 135.021Z'
            fill='#242631'
          />
          <path
            d='M102.438 135.021C102.438 135.574 101.991 136.021 101.438 136.021C100.886 136.021 100.438 135.574 100.438 135.021C100.438 134.469 100.886 134.021 101.438 134.021C101.991 134.021 102.438 134.469 102.438 135.021Z'
            fill='#242631'
          />
          <path
            d='M108.438 135.021C108.438 135.574 107.991 136.021 107.438 136.021C106.886 136.021 106.438 135.574 106.438 135.021C106.438 134.469 106.886 134.021 107.438 134.021C107.991 134.021 108.438 134.469 108.438 135.021Z'
            fill='#242631'
          />
          <path
            d='M114.438 135.021C114.438 135.574 113.991 136.021 113.438 136.021C112.886 136.021 112.438 135.574 112.438 135.021C112.438 134.469 112.886 134.021 113.438 134.021C113.991 134.021 114.438 134.469 114.438 135.021Z'
            fill='#242631'
          />
          <path
            d='M120.438 135.021C120.438 135.574 119.991 136.021 119.438 136.021C118.886 136.021 118.438 135.574 118.438 135.021C118.438 134.469 118.886 134.021 119.438 134.021C119.991 134.021 120.438 134.469 120.438 135.021Z'
            fill='#242631'
          />
          <path
            d='M126.438 135.021C126.438 135.574 125.991 136.021 125.438 136.021C124.886 136.021 124.438 135.574 124.438 135.021C124.438 134.469 124.886 134.021 125.438 134.021C125.991 134.021 126.438 134.469 126.438 135.021Z'
            fill='#242631'
          />
          <circle cx='131.438' cy='135.021' r='1' fill='#242631' />
          <circle cx='137.438' cy='135.021' r='1' fill='#242631' />
          <circle cx='143.438' cy='135.021' r='1' fill='#242631' />
          <circle cx='149.438' cy='135.021' r='1' fill='#242631' />
          <circle cx='155.438' cy='135.021' r='1' fill='#242631' />
          <circle cx='161.438' cy='135.021' r='1' fill='#242631' />
          <circle cx='167.438' cy='135.021' r='1' fill='#242631' />
          <circle cx='173.438' cy='135.021' r='1' fill='#242631' />
          <circle cx='179.438' cy='135.021' r='1' fill='#242631' />
          <circle cx='185.438' cy='135.021' r='1' fill='#242631' />
          <circle cx='191.438' cy='135.021' r='1' fill='#242631' />
          <circle cx='197.438' cy='135.021' r='1' fill='#242631' />
          <circle cx='-0.561523' cy='39.7168' r='1' fill='#242631' />
          <circle cx='5.43848' cy='39.7168' r='1' fill='#242631' />
          <path
            d='M12.4385 39.7168C12.4385 40.2691 11.9908 40.7168 11.4385 40.7168C10.8862 40.7168 10.4385 40.2691 10.4385 39.7168C10.4385 39.1645 10.8862 38.7168 11.4385 38.7168C11.9908 38.7168 12.4385 39.1645 12.4385 39.7168Z'
            fill='#242631'
          />
          <path
            d='M18.4385 39.7168C18.4385 40.2691 17.9908 40.7168 17.4385 40.7168C16.8862 40.7168 16.4385 40.2691 16.4385 39.7168C16.4385 39.1645 16.8862 38.7168 17.4385 38.7168C17.9908 38.7168 18.4385 39.1645 18.4385 39.7168Z'
            fill='#242631'
          />
          <path
            d='M24.4385 39.7168C24.4385 40.2691 23.9908 40.7168 23.4385 40.7168C22.8862 40.7168 22.4385 40.2691 22.4385 39.7168C22.4385 39.1645 22.8862 38.7168 23.4385 38.7168C23.9908 38.7168 24.4385 39.1645 24.4385 39.7168Z'
            fill='#242631'
          />
          <path
            d='M30.4385 39.7168C30.4385 40.2691 29.9908 40.7168 29.4385 40.7168C28.8862 40.7168 28.4385 40.2691 28.4385 39.7168C28.4385 39.1645 28.8862 38.7168 29.4385 38.7168C29.9908 38.7168 30.4385 39.1645 30.4385 39.7168Z'
            fill='#242631'
          />
          <path
            d='M36.4385 39.7168C36.4385 40.2691 35.9908 40.7168 35.4385 40.7168C34.8862 40.7168 34.4385 40.2691 34.4385 39.7168C34.4385 39.1645 34.8862 38.7168 35.4385 38.7168C35.9908 38.7168 36.4385 39.1645 36.4385 39.7168Z'
            fill='#242631'
          />
          <path
            d='M42.4385 39.7168C42.4385 40.2691 41.9908 40.7168 41.4385 40.7168C40.8862 40.7168 40.4385 40.2691 40.4385 39.7168C40.4385 39.1645 40.8862 38.7168 41.4385 38.7168C41.9908 38.7168 42.4385 39.1645 42.4385 39.7168Z'
            fill='#242631'
          />
          <path
            d='M48.4385 39.7168C48.4385 40.2691 47.9908 40.7168 47.4385 40.7168C46.8862 40.7168 46.4385 40.2691 46.4385 39.7168C46.4385 39.1645 46.8862 38.7168 47.4385 38.7168C47.9908 38.7168 48.4385 39.1645 48.4385 39.7168Z'
            fill='#242631'
          />
          <path
            d='M54.4385 39.7168C54.4385 40.2691 53.9908 40.7168 53.4385 40.7168C52.8862 40.7168 52.4385 40.2691 52.4385 39.7168C52.4385 39.1645 52.8862 38.7168 53.4385 38.7168C53.9908 38.7168 54.4385 39.1645 54.4385 39.7168Z'
            fill='#242631'
          />
          <path
            d='M60.4385 39.7168C60.4385 40.2691 59.9908 40.7168 59.4385 40.7168C58.8862 40.7168 58.4385 40.2691 58.4385 39.7168C58.4385 39.1645 58.8862 38.7168 59.4385 38.7168C59.9908 38.7168 60.4385 39.1645 60.4385 39.7168Z'
            fill='#242631'
          />
          <path
            d='M66.4385 39.7168C66.4385 40.2691 65.9908 40.7168 65.4385 40.7168C64.8862 40.7168 64.4385 40.2691 64.4385 39.7168C64.4385 39.1645 64.8862 38.7168 65.4385 38.7168C65.9908 38.7168 66.4385 39.1645 66.4385 39.7168Z'
            fill='#242631'
          />
          <path
            d='M72.4385 39.7168C72.4385 40.2691 71.9908 40.7168 71.4385 40.7168C70.8862 40.7168 70.4385 40.2691 70.4385 39.7168C70.4385 39.1645 70.8862 38.7168 71.4385 38.7168C71.9908 38.7168 72.4385 39.1645 72.4385 39.7168Z'
            fill='#242631'
          />
          <path
            d='M78.4385 39.7168C78.4385 40.2691 77.9908 40.7168 77.4385 40.7168C76.8862 40.7168 76.4385 40.2691 76.4385 39.7168C76.4385 39.1645 76.8862 38.7168 77.4385 38.7168C77.9908 38.7168 78.4385 39.1645 78.4385 39.7168Z'
            fill='#242631'
          />
          <path
            d='M84.4385 39.7168C84.4385 40.2691 83.9908 40.7168 83.4385 40.7168C82.8862 40.7168 82.4385 40.2691 82.4385 39.7168C82.4385 39.1645 82.8862 38.7168 83.4385 38.7168C83.9908 38.7168 84.4385 39.1645 84.4385 39.7168Z'
            fill='#242631'
          />
          <path
            d='M90.4385 39.7168C90.4385 40.2691 89.9908 40.7168 89.4385 40.7168C88.8862 40.7168 88.4385 40.2691 88.4385 39.7168C88.4385 39.1645 88.8862 38.7168 89.4385 38.7168C89.9908 38.7168 90.4385 39.1645 90.4385 39.7168Z'
            fill='#242631'
          />
          <path
            d='M96.4385 39.7168C96.4385 40.2691 95.9908 40.7168 95.4385 40.7168C94.8862 40.7168 94.4385 40.2691 94.4385 39.7168C94.4385 39.1645 94.8862 38.7168 95.4385 38.7168C95.9908 38.7168 96.4385 39.1645 96.4385 39.7168Z'
            fill='#242631'
          />
          <path
            d='M102.438 39.7168C102.438 40.2691 101.991 40.7168 101.438 40.7168C100.886 40.7168 100.438 40.2691 100.438 39.7168C100.438 39.1645 100.886 38.7168 101.438 38.7168C101.991 38.7168 102.438 39.1645 102.438 39.7168Z'
            fill='#242631'
          />
          <path
            d='M108.438 39.7168C108.438 40.2691 107.991 40.7168 107.438 40.7168C106.886 40.7168 106.438 40.2691 106.438 39.7168C106.438 39.1645 106.886 38.7168 107.438 38.7168C107.991 38.7168 108.438 39.1645 108.438 39.7168Z'
            fill='#242631'
          />
          <path
            d='M114.438 39.7168C114.438 40.2691 113.991 40.7168 113.438 40.7168C112.886 40.7168 112.438 40.2691 112.438 39.7168C112.438 39.1645 112.886 38.7168 113.438 38.7168C113.991 38.7168 114.438 39.1645 114.438 39.7168Z'
            fill='#242631'
          />
          <path
            d='M120.438 39.7168C120.438 40.2691 119.991 40.7168 119.438 40.7168C118.886 40.7168 118.438 40.2691 118.438 39.7168C118.438 39.1645 118.886 38.7168 119.438 38.7168C119.991 38.7168 120.438 39.1645 120.438 39.7168Z'
            fill='#242631'
          />
          <path
            d='M126.438 39.7168C126.438 40.2691 125.991 40.7168 125.438 40.7168C124.886 40.7168 124.438 40.2691 124.438 39.7168C124.438 39.1645 124.886 38.7168 125.438 38.7168C125.991 38.7168 126.438 39.1645 126.438 39.7168Z'
            fill='#242631'
          />
          <circle cx='131.438' cy='39.7168' r='1' fill='#242631' />
          <circle cx='137.438' cy='39.7168' r='1' fill='#242631' />
          <circle cx='143.438' cy='39.7168' r='1' fill='#242631' />
          <circle cx='149.438' cy='39.7168' r='1' fill='#242631' />
          <circle cx='155.438' cy='39.7168' r='1' fill='#242631' />
          <circle cx='161.438' cy='39.7168' r='1' fill='#242631' />
          <circle cx='167.438' cy='39.7168' r='1' fill='#242631' />
          <circle cx='173.438' cy='39.7168' r='1' fill='#242631' />
          <circle cx='179.438' cy='39.7168' r='1' fill='#242631' />
          <circle cx='185.438' cy='39.7168' r='1' fill='#242631' />
          <circle cx='191.438' cy='39.7168' r='1' fill='#242631' />
          <circle cx='197.438' cy='39.7168' r='1' fill='#242631' />
          <circle cx='-0.561523' cy='158.848' r='1' fill='#242631' />
          <circle cx='5.43848' cy='158.848' r='1' fill='#242631' />
          <path
            d='M12.4385 158.848C12.4385 159.4 11.9908 159.848 11.4385 159.848C10.8862 159.848 10.4385 159.4 10.4385 158.848C10.4385 158.295 10.8862 157.848 11.4385 157.848C11.9908 157.848 12.4385 158.295 12.4385 158.848Z'
            fill='#242631'
          />
          <path
            d='M18.4385 158.848C18.4385 159.4 17.9908 159.848 17.4385 159.848C16.8862 159.848 16.4385 159.4 16.4385 158.848C16.4385 158.295 16.8862 157.848 17.4385 157.848C17.9908 157.848 18.4385 158.295 18.4385 158.848Z'
            fill='#242631'
          />
          <path
            d='M24.4385 158.848C24.4385 159.4 23.9908 159.848 23.4385 159.848C22.8862 159.848 22.4385 159.4 22.4385 158.848C22.4385 158.295 22.8862 157.848 23.4385 157.848C23.9908 157.848 24.4385 158.295 24.4385 158.848Z'
            fill='#242631'
          />
          <path
            d='M30.4385 158.848C30.4385 159.4 29.9908 159.848 29.4385 159.848C28.8862 159.848 28.4385 159.4 28.4385 158.848C28.4385 158.295 28.8862 157.848 29.4385 157.848C29.9908 157.848 30.4385 158.295 30.4385 158.848Z'
            fill='#242631'
          />
          <path
            d='M36.4385 158.848C36.4385 159.4 35.9908 159.848 35.4385 159.848C34.8862 159.848 34.4385 159.4 34.4385 158.848C34.4385 158.295 34.8862 157.848 35.4385 157.848C35.9908 157.848 36.4385 158.295 36.4385 158.848Z'
            fill='#242631'
          />
          <path
            d='M42.4385 158.848C42.4385 159.4 41.9908 159.848 41.4385 159.848C40.8862 159.848 40.4385 159.4 40.4385 158.848C40.4385 158.295 40.8862 157.848 41.4385 157.848C41.9908 157.848 42.4385 158.295 42.4385 158.848Z'
            fill='#242631'
          />
          <path
            d='M48.4385 158.848C48.4385 159.4 47.9908 159.848 47.4385 159.848C46.8862 159.848 46.4385 159.4 46.4385 158.848C46.4385 158.295 46.8862 157.848 47.4385 157.848C47.9908 157.848 48.4385 158.295 48.4385 158.848Z'
            fill='#242631'
          />
          <path
            d='M54.4385 158.848C54.4385 159.4 53.9908 159.848 53.4385 159.848C52.8862 159.848 52.4385 159.4 52.4385 158.848C52.4385 158.295 52.8862 157.848 53.4385 157.848C53.9908 157.848 54.4385 158.295 54.4385 158.848Z'
            fill='#242631'
          />
          <path
            d='M60.4385 158.848C60.4385 159.4 59.9908 159.848 59.4385 159.848C58.8862 159.848 58.4385 159.4 58.4385 158.848C58.4385 158.295 58.8862 157.848 59.4385 157.848C59.9908 157.848 60.4385 158.295 60.4385 158.848Z'
            fill='#242631'
          />
          <path
            d='M66.4385 158.848C66.4385 159.4 65.9908 159.848 65.4385 159.848C64.8862 159.848 64.4385 159.4 64.4385 158.848C64.4385 158.295 64.8862 157.848 65.4385 157.848C65.9908 157.848 66.4385 158.295 66.4385 158.848Z'
            fill='#242631'
          />
          <path
            d='M72.4385 158.848C72.4385 159.4 71.9908 159.848 71.4385 159.848C70.8862 159.848 70.4385 159.4 70.4385 158.848C70.4385 158.295 70.8862 157.848 71.4385 157.848C71.9908 157.848 72.4385 158.295 72.4385 158.848Z'
            fill='#242631'
          />
          <path
            d='M78.4385 158.848C78.4385 159.4 77.9908 159.848 77.4385 159.848C76.8862 159.848 76.4385 159.4 76.4385 158.848C76.4385 158.295 76.8862 157.848 77.4385 157.848C77.9908 157.848 78.4385 158.295 78.4385 158.848Z'
            fill='#242631'
          />
          <path
            d='M84.4385 158.848C84.4385 159.4 83.9908 159.848 83.4385 159.848C82.8862 159.848 82.4385 159.4 82.4385 158.848C82.4385 158.295 82.8862 157.848 83.4385 157.848C83.9908 157.848 84.4385 158.295 84.4385 158.848Z'
            fill='#242631'
          />
          <path
            d='M90.4385 158.848C90.4385 159.4 89.9908 159.848 89.4385 159.848C88.8862 159.848 88.4385 159.4 88.4385 158.848C88.4385 158.295 88.8862 157.848 89.4385 157.848C89.9908 157.848 90.4385 158.295 90.4385 158.848Z'
            fill='#242631'
          />
          <path
            d='M96.4385 158.848C96.4385 159.4 95.9908 159.848 95.4385 159.848C94.8862 159.848 94.4385 159.4 94.4385 158.848C94.4385 158.295 94.8862 157.848 95.4385 157.848C95.9908 157.848 96.4385 158.295 96.4385 158.848Z'
            fill='#242631'
          />
          <path
            d='M102.438 158.848C102.438 159.4 101.991 159.848 101.438 159.848C100.886 159.848 100.438 159.4 100.438 158.848C100.438 158.295 100.886 157.848 101.438 157.848C101.991 157.848 102.438 158.295 102.438 158.848Z'
            fill='#242631'
          />
          <path
            d='M108.438 158.848C108.438 159.4 107.991 159.848 107.438 159.848C106.886 159.848 106.438 159.4 106.438 158.848C106.438 158.295 106.886 157.848 107.438 157.848C107.991 157.848 108.438 158.295 108.438 158.848Z'
            fill='#242631'
          />
          <path
            d='M114.438 158.848C114.438 159.4 113.991 159.848 113.438 159.848C112.886 159.848 112.438 159.4 112.438 158.848C112.438 158.295 112.886 157.848 113.438 157.848C113.991 157.848 114.438 158.295 114.438 158.848Z'
            fill='#242631'
          />
          <path
            d='M120.438 158.848C120.438 159.4 119.991 159.848 119.438 159.848C118.886 159.848 118.438 159.4 118.438 158.848C118.438 158.295 118.886 157.848 119.438 157.848C119.991 157.848 120.438 158.295 120.438 158.848Z'
            fill='#242631'
          />
          <path
            d='M126.438 158.848C126.438 159.4 125.991 159.848 125.438 159.848C124.886 159.848 124.438 159.4 124.438 158.848C124.438 158.295 124.886 157.848 125.438 157.848C125.991 157.848 126.438 158.295 126.438 158.848Z'
            fill='#242631'
          />
          <circle cx='131.438' cy='158.848' r='1' fill='#242631' />
          <circle cx='137.438' cy='158.848' r='1' fill='#242631' />
          <circle cx='143.438' cy='158.848' r='1' fill='#242631' />
          <circle cx='149.438' cy='158.848' r='1' fill='#242631' />
          <circle cx='155.438' cy='158.848' r='1' fill='#242631' />
          <circle cx='161.438' cy='158.848' r='1' fill='#242631' />
          <circle cx='167.438' cy='158.848' r='1' fill='#242631' />
          <circle cx='173.438' cy='158.848' r='1' fill='#242631' />
          <circle cx='179.438' cy='158.848' r='1' fill='#242631' />
          <circle cx='185.438' cy='158.848' r='1' fill='#242631' />
          <circle cx='191.438' cy='158.848' r='1' fill='#242631' />
          <circle cx='197.438' cy='158.848' r='1' fill='#242631' />
          <circle cx='-0.561523' cy='63.543' r='1' fill='#242631' />
          <circle cx='5.43848' cy='63.543' r='1' fill='#242631' />
          <path
            d='M12.4385 63.543C12.4385 64.0953 11.9908 64.543 11.4385 64.543C10.8862 64.543 10.4385 64.0953 10.4385 63.543C10.4385 62.9907 10.8862 62.543 11.4385 62.543C11.9908 62.543 12.4385 62.9907 12.4385 63.543Z'
            fill='#242631'
          />
          <path
            d='M18.4385 63.543C18.4385 64.0953 17.9908 64.543 17.4385 64.543C16.8862 64.543 16.4385 64.0953 16.4385 63.543C16.4385 62.9907 16.8862 62.543 17.4385 62.543C17.9908 62.543 18.4385 62.9907 18.4385 63.543Z'
            fill='#242631'
          />
          <path
            d='M24.4385 63.543C24.4385 64.0953 23.9908 64.543 23.4385 64.543C22.8862 64.543 22.4385 64.0953 22.4385 63.543C22.4385 62.9907 22.8862 62.543 23.4385 62.543C23.9908 62.543 24.4385 62.9907 24.4385 63.543Z'
            fill='#242631'
          />
          <path
            d='M30.4385 63.543C30.4385 64.0953 29.9908 64.543 29.4385 64.543C28.8862 64.543 28.4385 64.0953 28.4385 63.543C28.4385 62.9907 28.8862 62.543 29.4385 62.543C29.9908 62.543 30.4385 62.9907 30.4385 63.543Z'
            fill='#242631'
          />
          <path
            d='M36.4385 63.543C36.4385 64.0953 35.9908 64.543 35.4385 64.543C34.8862 64.543 34.4385 64.0953 34.4385 63.543C34.4385 62.9907 34.8862 62.543 35.4385 62.543C35.9908 62.543 36.4385 62.9907 36.4385 63.543Z'
            fill='#242631'
          />
          <path
            d='M42.4385 63.543C42.4385 64.0953 41.9908 64.543 41.4385 64.543C40.8862 64.543 40.4385 64.0953 40.4385 63.543C40.4385 62.9907 40.8862 62.543 41.4385 62.543C41.9908 62.543 42.4385 62.9907 42.4385 63.543Z'
            fill='#242631'
          />
          <path
            d='M48.4385 63.543C48.4385 64.0953 47.9908 64.543 47.4385 64.543C46.8862 64.543 46.4385 64.0953 46.4385 63.543C46.4385 62.9907 46.8862 62.543 47.4385 62.543C47.9908 62.543 48.4385 62.9907 48.4385 63.543Z'
            fill='#242631'
          />
          <path
            d='M54.4385 63.543C54.4385 64.0953 53.9908 64.543 53.4385 64.543C52.8862 64.543 52.4385 64.0953 52.4385 63.543C52.4385 62.9907 52.8862 62.543 53.4385 62.543C53.9908 62.543 54.4385 62.9907 54.4385 63.543Z'
            fill='#242631'
          />
          <path
            d='M60.4385 63.543C60.4385 64.0953 59.9908 64.543 59.4385 64.543C58.8862 64.543 58.4385 64.0953 58.4385 63.543C58.4385 62.9907 58.8862 62.543 59.4385 62.543C59.9908 62.543 60.4385 62.9907 60.4385 63.543Z'
            fill='#242631'
          />
          <path
            d='M66.4385 63.543C66.4385 64.0953 65.9908 64.543 65.4385 64.543C64.8862 64.543 64.4385 64.0953 64.4385 63.543C64.4385 62.9907 64.8862 62.543 65.4385 62.543C65.9908 62.543 66.4385 62.9907 66.4385 63.543Z'
            fill='#242631'
          />
          <path
            d='M72.4385 63.543C72.4385 64.0953 71.9908 64.543 71.4385 64.543C70.8862 64.543 70.4385 64.0953 70.4385 63.543C70.4385 62.9907 70.8862 62.543 71.4385 62.543C71.9908 62.543 72.4385 62.9907 72.4385 63.543Z'
            fill='#242631'
          />
          <path
            d='M78.4385 63.543C78.4385 64.0953 77.9908 64.543 77.4385 64.543C76.8862 64.543 76.4385 64.0953 76.4385 63.543C76.4385 62.9907 76.8862 62.543 77.4385 62.543C77.9908 62.543 78.4385 62.9907 78.4385 63.543Z'
            fill='#242631'
          />
          <path
            d='M84.4385 63.543C84.4385 64.0953 83.9908 64.543 83.4385 64.543C82.8862 64.543 82.4385 64.0953 82.4385 63.543C82.4385 62.9907 82.8862 62.543 83.4385 62.543C83.9908 62.543 84.4385 62.9907 84.4385 63.543Z'
            fill='#242631'
          />
          <path
            d='M90.4385 63.543C90.4385 64.0953 89.9908 64.543 89.4385 64.543C88.8862 64.543 88.4385 64.0953 88.4385 63.543C88.4385 62.9907 88.8862 62.543 89.4385 62.543C89.9908 62.543 90.4385 62.9907 90.4385 63.543Z'
            fill='#242631'
          />
          <path
            d='M96.4385 63.543C96.4385 64.0953 95.9908 64.543 95.4385 64.543C94.8862 64.543 94.4385 64.0953 94.4385 63.543C94.4385 62.9907 94.8862 62.543 95.4385 62.543C95.9908 62.543 96.4385 62.9907 96.4385 63.543Z'
            fill='#242631'
          />
          <path
            d='M102.438 63.543C102.438 64.0953 101.991 64.543 101.438 64.543C100.886 64.543 100.438 64.0953 100.438 63.543C100.438 62.9907 100.886 62.543 101.438 62.543C101.991 62.543 102.438 62.9907 102.438 63.543Z'
            fill='#242631'
          />
          <path
            d='M108.438 63.543C108.438 64.0953 107.991 64.543 107.438 64.543C106.886 64.543 106.438 64.0953 106.438 63.543C106.438 62.9907 106.886 62.543 107.438 62.543C107.991 62.543 108.438 62.9907 108.438 63.543Z'
            fill='#242631'
          />
          <path
            d='M114.438 63.543C114.438 64.0953 113.991 64.543 113.438 64.543C112.886 64.543 112.438 64.0953 112.438 63.543C112.438 62.9907 112.886 62.543 113.438 62.543C113.991 62.543 114.438 62.9907 114.438 63.543Z'
            fill='#242631'
          />
          <path
            d='M120.438 63.543C120.438 64.0953 119.991 64.543 119.438 64.543C118.886 64.543 118.438 64.0953 118.438 63.543C118.438 62.9907 118.886 62.543 119.438 62.543C119.991 62.543 120.438 62.9907 120.438 63.543Z'
            fill='#242631'
          />
          <path
            d='M126.438 63.543C126.438 64.0953 125.991 64.543 125.438 64.543C124.886 64.543 124.438 64.0953 124.438 63.543C124.438 62.9907 124.886 62.543 125.438 62.543C125.991 62.543 126.438 62.9907 126.438 63.543Z'
            fill='#242631'
          />
          <circle cx='131.438' cy='63.543' r='1' fill='#242631' />
          <circle cx='137.438' cy='63.543' r='1' fill='#242631' />
          <circle cx='143.438' cy='63.543' r='1' fill='#242631' />
          <circle cx='149.438' cy='63.543' r='1' fill='#242631' />
          <circle cx='155.438' cy='63.543' r='1' fill='#242631' />
          <circle cx='161.438' cy='63.543' r='1' fill='#242631' />
          <circle cx='167.438' cy='63.543' r='1' fill='#242631' />
          <circle cx='173.438' cy='63.543' r='1' fill='#242631' />
          <circle cx='179.438' cy='63.543' r='1' fill='#242631' />
          <circle cx='185.438' cy='63.543' r='1' fill='#242631' />
          <circle cx='191.438' cy='63.543' r='1' fill='#242631' />
          <circle cx='197.438' cy='63.543' r='1' fill='#242631' />
          <circle cx='-0.561523' cy='182.674' r='1' fill='#242631' />
          <circle cx='5.43848' cy='182.674' r='1' fill='#242631' />
          <path
            d='M12.4385 182.674C12.4385 183.226 11.9908 183.674 11.4385 183.674C10.8862 183.674 10.4385 183.226 10.4385 182.674C10.4385 182.122 10.8862 181.674 11.4385 181.674C11.9908 181.674 12.4385 182.122 12.4385 182.674Z'
            fill='#242631'
          />
          <path
            d='M18.4385 182.674C18.4385 183.226 17.9908 183.674 17.4385 183.674C16.8862 183.674 16.4385 183.226 16.4385 182.674C16.4385 182.122 16.8862 181.674 17.4385 181.674C17.9908 181.674 18.4385 182.122 18.4385 182.674Z'
            fill='#242631'
          />
          <path
            d='M24.4385 182.674C24.4385 183.226 23.9908 183.674 23.4385 183.674C22.8862 183.674 22.4385 183.226 22.4385 182.674C22.4385 182.122 22.8862 181.674 23.4385 181.674C23.9908 181.674 24.4385 182.122 24.4385 182.674Z'
            fill='#242631'
          />
          <path
            d='M30.4385 182.674C30.4385 183.226 29.9908 183.674 29.4385 183.674C28.8862 183.674 28.4385 183.226 28.4385 182.674C28.4385 182.122 28.8862 181.674 29.4385 181.674C29.9908 181.674 30.4385 182.122 30.4385 182.674Z'
            fill='#242631'
          />
          <path
            d='M36.4385 182.674C36.4385 183.226 35.9908 183.674 35.4385 183.674C34.8862 183.674 34.4385 183.226 34.4385 182.674C34.4385 182.122 34.8862 181.674 35.4385 181.674C35.9908 181.674 36.4385 182.122 36.4385 182.674Z'
            fill='#242631'
          />
          <path
            d='M42.4385 182.674C42.4385 183.226 41.9908 183.674 41.4385 183.674C40.8862 183.674 40.4385 183.226 40.4385 182.674C40.4385 182.122 40.8862 181.674 41.4385 181.674C41.9908 181.674 42.4385 182.122 42.4385 182.674Z'
            fill='#242631'
          />
          <path
            d='M48.4385 182.674C48.4385 183.226 47.9908 183.674 47.4385 183.674C46.8862 183.674 46.4385 183.226 46.4385 182.674C46.4385 182.122 46.8862 181.674 47.4385 181.674C47.9908 181.674 48.4385 182.122 48.4385 182.674Z'
            fill='#242631'
          />
          <path
            d='M54.4385 182.674C54.4385 183.226 53.9908 183.674 53.4385 183.674C52.8862 183.674 52.4385 183.226 52.4385 182.674C52.4385 182.122 52.8862 181.674 53.4385 181.674C53.9908 181.674 54.4385 182.122 54.4385 182.674Z'
            fill='#242631'
          />
          <path
            d='M60.4385 182.674C60.4385 183.226 59.9908 183.674 59.4385 183.674C58.8862 183.674 58.4385 183.226 58.4385 182.674C58.4385 182.122 58.8862 181.674 59.4385 181.674C59.9908 181.674 60.4385 182.122 60.4385 182.674Z'
            fill='#242631'
          />
          <path
            d='M66.4385 182.674C66.4385 183.226 65.9908 183.674 65.4385 183.674C64.8862 183.674 64.4385 183.226 64.4385 182.674C64.4385 182.122 64.8862 181.674 65.4385 181.674C65.9908 181.674 66.4385 182.122 66.4385 182.674Z'
            fill='#242631'
          />
          <path
            d='M72.4385 182.674C72.4385 183.226 71.9908 183.674 71.4385 183.674C70.8862 183.674 70.4385 183.226 70.4385 182.674C70.4385 182.122 70.8862 181.674 71.4385 181.674C71.9908 181.674 72.4385 182.122 72.4385 182.674Z'
            fill='#242631'
          />
          <path
            d='M78.4385 182.674C78.4385 183.226 77.9908 183.674 77.4385 183.674C76.8862 183.674 76.4385 183.226 76.4385 182.674C76.4385 182.122 76.8862 181.674 77.4385 181.674C77.9908 181.674 78.4385 182.122 78.4385 182.674Z'
            fill='#242631'
          />
          <path
            d='M84.4385 182.674C84.4385 183.226 83.9908 183.674 83.4385 183.674C82.8862 183.674 82.4385 183.226 82.4385 182.674C82.4385 182.122 82.8862 181.674 83.4385 181.674C83.9908 181.674 84.4385 182.122 84.4385 182.674Z'
            fill='#242631'
          />
          <path
            d='M90.4385 182.674C90.4385 183.226 89.9908 183.674 89.4385 183.674C88.8862 183.674 88.4385 183.226 88.4385 182.674C88.4385 182.122 88.8862 181.674 89.4385 181.674C89.9908 181.674 90.4385 182.122 90.4385 182.674Z'
            fill='#242631'
          />
          <path
            d='M96.4385 182.674C96.4385 183.226 95.9908 183.674 95.4385 183.674C94.8862 183.674 94.4385 183.226 94.4385 182.674C94.4385 182.122 94.8862 181.674 95.4385 181.674C95.9908 181.674 96.4385 182.122 96.4385 182.674Z'
            fill='#242631'
          />
          <path
            d='M102.438 182.674C102.438 183.226 101.991 183.674 101.438 183.674C100.886 183.674 100.438 183.226 100.438 182.674C100.438 182.122 100.886 181.674 101.438 181.674C101.991 181.674 102.438 182.122 102.438 182.674Z'
            fill='#242631'
          />
          <path
            d='M108.438 182.674C108.438 183.226 107.991 183.674 107.438 183.674C106.886 183.674 106.438 183.226 106.438 182.674C106.438 182.122 106.886 181.674 107.438 181.674C107.991 181.674 108.438 182.122 108.438 182.674Z'
            fill='#242631'
          />
          <path
            d='M114.438 182.674C114.438 183.226 113.991 183.674 113.438 183.674C112.886 183.674 112.438 183.226 112.438 182.674C112.438 182.122 112.886 181.674 113.438 181.674C113.991 181.674 114.438 182.122 114.438 182.674Z'
            fill='#242631'
          />
          <path
            d='M120.438 182.674C120.438 183.226 119.991 183.674 119.438 183.674C118.886 183.674 118.438 183.226 118.438 182.674C118.438 182.122 118.886 181.674 119.438 181.674C119.991 181.674 120.438 182.122 120.438 182.674Z'
            fill='#242631'
          />
          <path
            d='M126.438 182.674C126.438 183.226 125.991 183.674 125.438 183.674C124.886 183.674 124.438 183.226 124.438 182.674C124.438 182.122 124.886 181.674 125.438 181.674C125.991 181.674 126.438 182.122 126.438 182.674Z'
            fill='#242631'
          />
          <circle cx='131.438' cy='182.674' r='1' fill='#242631' />
          <circle cx='137.438' cy='182.674' r='1' fill='#242631' />
          <circle cx='143.438' cy='182.674' r='1' fill='#242631' />
          <circle cx='149.438' cy='182.674' r='1' fill='#242631' />
          <circle cx='155.438' cy='182.674' r='1' fill='#242631' />
          <circle cx='161.438' cy='182.674' r='1' fill='#242631' />
          <circle cx='167.438' cy='182.674' r='1' fill='#242631' />
          <circle cx='173.438' cy='182.674' r='1' fill='#242631' />
          <circle cx='179.438' cy='182.674' r='1' fill='#242631' />
          <circle cx='185.438' cy='182.674' r='1' fill='#242631' />
          <circle cx='191.438' cy='182.674' r='1' fill='#242631' />
          <circle cx='197.438' cy='182.674' r='1' fill='#242631' />
          <circle cx='-0.561523' cy='87.3691' r='1' fill='#242631' />
          <circle cx='5.43848' cy='87.3691' r='1' fill='#242631' />
          <path
            d='M12.4385 87.3691C12.4385 87.9214 11.9908 88.3691 11.4385 88.3691C10.8862 88.3691 10.4385 87.9214 10.4385 87.3691C10.4385 86.8169 10.8862 86.3691 11.4385 86.3691C11.9908 86.3691 12.4385 86.8169 12.4385 87.3691Z'
            fill='#242631'
          />
          <path
            d='M18.4385 87.3691C18.4385 87.9214 17.9908 88.3691 17.4385 88.3691C16.8862 88.3691 16.4385 87.9214 16.4385 87.3691C16.4385 86.8169 16.8862 86.3691 17.4385 86.3691C17.9908 86.3691 18.4385 86.8169 18.4385 87.3691Z'
            fill='#242631'
          />
          <path
            d='M24.4385 87.3691C24.4385 87.9214 23.9908 88.3691 23.4385 88.3691C22.8862 88.3691 22.4385 87.9214 22.4385 87.3691C22.4385 86.8169 22.8862 86.3691 23.4385 86.3691C23.9908 86.3691 24.4385 86.8169 24.4385 87.3691Z'
            fill='#242631'
          />
          <path
            d='M30.4385 87.3691C30.4385 87.9214 29.9908 88.3691 29.4385 88.3691C28.8862 88.3691 28.4385 87.9214 28.4385 87.3691C28.4385 86.8169 28.8862 86.3691 29.4385 86.3691C29.9908 86.3691 30.4385 86.8169 30.4385 87.3691Z'
            fill='#242631'
          />
          <path
            d='M36.4385 87.3691C36.4385 87.9214 35.9908 88.3691 35.4385 88.3691C34.8862 88.3691 34.4385 87.9214 34.4385 87.3691C34.4385 86.8169 34.8862 86.3691 35.4385 86.3691C35.9908 86.3691 36.4385 86.8169 36.4385 87.3691Z'
            fill='#242631'
          />
          <path
            d='M42.4385 87.3691C42.4385 87.9214 41.9908 88.3691 41.4385 88.3691C40.8862 88.3691 40.4385 87.9214 40.4385 87.3691C40.4385 86.8169 40.8862 86.3691 41.4385 86.3691C41.9908 86.3691 42.4385 86.8169 42.4385 87.3691Z'
            fill='#242631'
          />
          <path
            d='M48.4385 87.3691C48.4385 87.9214 47.9908 88.3691 47.4385 88.3691C46.8862 88.3691 46.4385 87.9214 46.4385 87.3691C46.4385 86.8169 46.8862 86.3691 47.4385 86.3691C47.9908 86.3691 48.4385 86.8169 48.4385 87.3691Z'
            fill='#242631'
          />
          <path
            d='M54.4385 87.3691C54.4385 87.9214 53.9908 88.3691 53.4385 88.3691C52.8862 88.3691 52.4385 87.9214 52.4385 87.3691C52.4385 86.8169 52.8862 86.3691 53.4385 86.3691C53.9908 86.3691 54.4385 86.8169 54.4385 87.3691Z'
            fill='#242631'
          />
          <path
            d='M60.4385 87.3691C60.4385 87.9214 59.9908 88.3691 59.4385 88.3691C58.8862 88.3691 58.4385 87.9214 58.4385 87.3691C58.4385 86.8169 58.8862 86.3691 59.4385 86.3691C59.9908 86.3691 60.4385 86.8169 60.4385 87.3691Z'
            fill='#242631'
          />
          <path
            d='M66.4385 87.3691C66.4385 87.9214 65.9908 88.3691 65.4385 88.3691C64.8862 88.3691 64.4385 87.9214 64.4385 87.3691C64.4385 86.8169 64.8862 86.3691 65.4385 86.3691C65.9908 86.3691 66.4385 86.8169 66.4385 87.3691Z'
            fill='#242631'
          />
          <path
            d='M72.4385 87.3691C72.4385 87.9214 71.9908 88.3691 71.4385 88.3691C70.8862 88.3691 70.4385 87.9214 70.4385 87.3691C70.4385 86.8169 70.8862 86.3691 71.4385 86.3691C71.9908 86.3691 72.4385 86.8169 72.4385 87.3691Z'
            fill='#242631'
          />
          <path
            d='M78.4385 87.3691C78.4385 87.9214 77.9908 88.3691 77.4385 88.3691C76.8862 88.3691 76.4385 87.9214 76.4385 87.3691C76.4385 86.8169 76.8862 86.3691 77.4385 86.3691C77.9908 86.3691 78.4385 86.8169 78.4385 87.3691Z'
            fill='#242631'
          />
          <path
            d='M84.4385 87.3691C84.4385 87.9214 83.9908 88.3691 83.4385 88.3691C82.8862 88.3691 82.4385 87.9214 82.4385 87.3691C82.4385 86.8169 82.8862 86.3691 83.4385 86.3691C83.9908 86.3691 84.4385 86.8169 84.4385 87.3691Z'
            fill='#242631'
          />
          <path
            d='M90.4385 87.3691C90.4385 87.9214 89.9908 88.3691 89.4385 88.3691C88.8862 88.3691 88.4385 87.9214 88.4385 87.3691C88.4385 86.8169 88.8862 86.3691 89.4385 86.3691C89.9908 86.3691 90.4385 86.8169 90.4385 87.3691Z'
            fill='#242631'
          />
          <path
            d='M96.4385 87.3691C96.4385 87.9214 95.9908 88.3691 95.4385 88.3691C94.8862 88.3691 94.4385 87.9214 94.4385 87.3691C94.4385 86.8169 94.8862 86.3691 95.4385 86.3691C95.9908 86.3691 96.4385 86.8169 96.4385 87.3691Z'
            fill='#242631'
          />
          <path
            d='M102.438 87.3691C102.438 87.9214 101.991 88.3691 101.438 88.3691C100.886 88.3691 100.438 87.9214 100.438 87.3691C100.438 86.8169 100.886 86.3691 101.438 86.3691C101.991 86.3691 102.438 86.8169 102.438 87.3691Z'
            fill='#242631'
          />
          <path
            d='M108.438 87.3691C108.438 87.9214 107.991 88.3691 107.438 88.3691C106.886 88.3691 106.438 87.9214 106.438 87.3691C106.438 86.8169 106.886 86.3691 107.438 86.3691C107.991 86.3691 108.438 86.8169 108.438 87.3691Z'
            fill='#242631'
          />
          <path
            d='M114.438 87.3691C114.438 87.9214 113.991 88.3691 113.438 88.3691C112.886 88.3691 112.438 87.9214 112.438 87.3691C112.438 86.8169 112.886 86.3691 113.438 86.3691C113.991 86.3691 114.438 86.8169 114.438 87.3691Z'
            fill='#242631'
          />
          <path
            d='M120.438 87.3691C120.438 87.9214 119.991 88.3691 119.438 88.3691C118.886 88.3691 118.438 87.9214 118.438 87.3691C118.438 86.8169 118.886 86.3691 119.438 86.3691C119.991 86.3691 120.438 86.8169 120.438 87.3691Z'
            fill='#242631'
          />
          <path
            d='M126.438 87.3691C126.438 87.9214 125.991 88.3691 125.438 88.3691C124.886 88.3691 124.438 87.9214 124.438 87.3691C124.438 86.8169 124.886 86.3691 125.438 86.3691C125.991 86.3691 126.438 86.8169 126.438 87.3691Z'
            fill='#242631'
          />
          <circle cx='131.438' cy='87.3691' r='1' fill='#242631' />
          <circle cx='137.438' cy='87.3691' r='1' fill='#242631' />
          <circle cx='143.438' cy='87.3691' r='1' fill='#242631' />
          <circle cx='149.438' cy='87.3691' r='1' fill='#242631' />
          <circle cx='155.438' cy='87.3691' r='1' fill='#242631' />
          <circle cx='161.438' cy='87.3691' r='1' fill='#242631' />
          <circle cx='167.438' cy='87.3691' r='1' fill='#242631' />
          <circle cx='173.438' cy='87.3691' r='1' fill='#242631' />
          <circle cx='179.438' cy='87.3691' r='1' fill='#242631' />
          <circle cx='185.438' cy='87.3691' r='1' fill='#242631' />
          <circle cx='191.438' cy='87.3691' r='1' fill='#242631' />
          <circle cx='197.438' cy='87.3691' r='1' fill='#242631' />
          <circle cx='-0.561523' cy='111.195' r='1' fill='#242631' />
          <circle cx='5.43848' cy='111.195' r='1' fill='#242631' />
          <path
            d='M12.4385 111.195C12.4385 111.748 11.9908 112.195 11.4385 112.195C10.8862 112.195 10.4385 111.748 10.4385 111.195C10.4385 110.643 10.8862 110.195 11.4385 110.195C11.9908 110.195 12.4385 110.643 12.4385 111.195Z'
            fill='#242631'
          />
          <path
            d='M18.4385 111.195C18.4385 111.748 17.9908 112.195 17.4385 112.195C16.8862 112.195 16.4385 111.748 16.4385 111.195C16.4385 110.643 16.8862 110.195 17.4385 110.195C17.9908 110.195 18.4385 110.643 18.4385 111.195Z'
            fill='#242631'
          />
          <path
            d='M24.4385 111.195C24.4385 111.748 23.9908 112.195 23.4385 112.195C22.8862 112.195 22.4385 111.748 22.4385 111.195C22.4385 110.643 22.8862 110.195 23.4385 110.195C23.9908 110.195 24.4385 110.643 24.4385 111.195Z'
            fill='#242631'
          />
          <path
            d='M30.4385 111.195C30.4385 111.748 29.9908 112.195 29.4385 112.195C28.8862 112.195 28.4385 111.748 28.4385 111.195C28.4385 110.643 28.8862 110.195 29.4385 110.195C29.9908 110.195 30.4385 110.643 30.4385 111.195Z'
            fill='#242631'
          />
          <path
            d='M36.4385 111.195C36.4385 111.748 35.9908 112.195 35.4385 112.195C34.8862 112.195 34.4385 111.748 34.4385 111.195C34.4385 110.643 34.8862 110.195 35.4385 110.195C35.9908 110.195 36.4385 110.643 36.4385 111.195Z'
            fill='#242631'
          />
          <path
            d='M42.4385 111.195C42.4385 111.748 41.9908 112.195 41.4385 112.195C40.8862 112.195 40.4385 111.748 40.4385 111.195C40.4385 110.643 40.8862 110.195 41.4385 110.195C41.9908 110.195 42.4385 110.643 42.4385 111.195Z'
            fill='#242631'
          />
          <path
            d='M48.4385 111.195C48.4385 111.748 47.9908 112.195 47.4385 112.195C46.8862 112.195 46.4385 111.748 46.4385 111.195C46.4385 110.643 46.8862 110.195 47.4385 110.195C47.9908 110.195 48.4385 110.643 48.4385 111.195Z'
            fill='#242631'
          />
          <path
            d='M54.4385 111.195C54.4385 111.748 53.9908 112.195 53.4385 112.195C52.8862 112.195 52.4385 111.748 52.4385 111.195C52.4385 110.643 52.8862 110.195 53.4385 110.195C53.9908 110.195 54.4385 110.643 54.4385 111.195Z'
            fill='#242631'
          />
          <path
            d='M60.4385 111.195C60.4385 111.748 59.9908 112.195 59.4385 112.195C58.8862 112.195 58.4385 111.748 58.4385 111.195C58.4385 110.643 58.8862 110.195 59.4385 110.195C59.9908 110.195 60.4385 110.643 60.4385 111.195Z'
            fill='#242631'
          />
          <path
            d='M66.4385 111.195C66.4385 111.748 65.9908 112.195 65.4385 112.195C64.8862 112.195 64.4385 111.748 64.4385 111.195C64.4385 110.643 64.8862 110.195 65.4385 110.195C65.9908 110.195 66.4385 110.643 66.4385 111.195Z'
            fill='#242631'
          />
          <path
            d='M72.4385 111.195C72.4385 111.748 71.9908 112.195 71.4385 112.195C70.8862 112.195 70.4385 111.748 70.4385 111.195C70.4385 110.643 70.8862 110.195 71.4385 110.195C71.9908 110.195 72.4385 110.643 72.4385 111.195Z'
            fill='#242631'
          />
          <path
            d='M78.4385 111.195C78.4385 111.748 77.9908 112.195 77.4385 112.195C76.8862 112.195 76.4385 111.748 76.4385 111.195C76.4385 110.643 76.8862 110.195 77.4385 110.195C77.9908 110.195 78.4385 110.643 78.4385 111.195Z'
            fill='#242631'
          />
          <path
            d='M84.4385 111.195C84.4385 111.748 83.9908 112.195 83.4385 112.195C82.8862 112.195 82.4385 111.748 82.4385 111.195C82.4385 110.643 82.8862 110.195 83.4385 110.195C83.9908 110.195 84.4385 110.643 84.4385 111.195Z'
            fill='#242631'
          />
          <path
            d='M90.4385 111.195C90.4385 111.748 89.9908 112.195 89.4385 112.195C88.8862 112.195 88.4385 111.748 88.4385 111.195C88.4385 110.643 88.8862 110.195 89.4385 110.195C89.9908 110.195 90.4385 110.643 90.4385 111.195Z'
            fill='#242631'
          />
          <path
            d='M96.4385 111.195C96.4385 111.748 95.9908 112.195 95.4385 112.195C94.8862 112.195 94.4385 111.748 94.4385 111.195C94.4385 110.643 94.8862 110.195 95.4385 110.195C95.9908 110.195 96.4385 110.643 96.4385 111.195Z'
            fill='#242631'
          />
          <path
            d='M102.438 111.195C102.438 111.748 101.991 112.195 101.438 112.195C100.886 112.195 100.438 111.748 100.438 111.195C100.438 110.643 100.886 110.195 101.438 110.195C101.991 110.195 102.438 110.643 102.438 111.195Z'
            fill='#242631'
          />
          <path
            d='M108.438 111.195C108.438 111.748 107.991 112.195 107.438 112.195C106.886 112.195 106.438 111.748 106.438 111.195C106.438 110.643 106.886 110.195 107.438 110.195C107.991 110.195 108.438 110.643 108.438 111.195Z'
            fill='#242631'
          />
          <path
            d='M114.438 111.195C114.438 111.748 113.991 112.195 113.438 112.195C112.886 112.195 112.438 111.748 112.438 111.195C112.438 110.643 112.886 110.195 113.438 110.195C113.991 110.195 114.438 110.643 114.438 111.195Z'
            fill='#242631'
          />
          <path
            d='M120.438 111.195C120.438 111.748 119.991 112.195 119.438 112.195C118.886 112.195 118.438 111.748 118.438 111.195C118.438 110.643 118.886 110.195 119.438 110.195C119.991 110.195 120.438 110.643 120.438 111.195Z'
            fill='#242631'
          />
          <path
            d='M126.438 111.195C126.438 111.748 125.991 112.195 125.438 112.195C124.886 112.195 124.438 111.748 124.438 111.195C124.438 110.643 124.886 110.195 125.438 110.195C125.991 110.195 126.438 110.643 126.438 111.195Z'
            fill='#242631'
          />
          <circle cx='131.438' cy='111.195' r='1' fill='#242631' />
          <circle cx='137.438' cy='111.195' r='1' fill='#242631' />
          <circle cx='143.438' cy='111.195' r='1' fill='#242631' />
          <circle cx='149.438' cy='111.195' r='1' fill='#242631' />
          <circle cx='155.438' cy='111.195' r='1' fill='#242631' />
          <circle cx='161.438' cy='111.195' r='1' fill='#242631' />
          <circle cx='167.438' cy='111.195' r='1' fill='#242631' />
          <circle cx='173.438' cy='111.195' r='1' fill='#242631' />
          <circle cx='179.438' cy='111.195' r='1' fill='#242631' />
          <circle cx='185.438' cy='111.195' r='1' fill='#242631' />
          <circle cx='191.438' cy='111.195' r='1' fill='#242631' />
          <circle cx='197.438' cy='111.195' r='1' fill='#242631' />
          <circle cx='2.3877' cy='9.93555' r='1' fill='#242631' />
          <circle cx='8.3877' cy='9.93555' r='1' fill='#242631' />
          <path
            d='M15.3877 9.93555C15.3877 10.4878 14.94 10.9355 14.3877 10.9355C13.8354 10.9355 13.3877 10.4878 13.3877 9.93555C13.3877 9.38326 13.8354 8.93555 14.3877 8.93555C14.94 8.93555 15.3877 9.38326 15.3877 9.93555Z'
            fill='#242631'
          />
          <path
            d='M21.3877 9.93555C21.3877 10.4878 20.94 10.9355 20.3877 10.9355C19.8354 10.9355 19.3877 10.4878 19.3877 9.93555C19.3877 9.38326 19.8354 8.93555 20.3877 8.93555C20.94 8.93555 21.3877 9.38326 21.3877 9.93555Z'
            fill='#242631'
          />
          <path
            d='M27.3877 9.93555C27.3877 10.4878 26.94 10.9355 26.3877 10.9355C25.8354 10.9355 25.3877 10.4878 25.3877 9.93555C25.3877 9.38326 25.8354 8.93555 26.3877 8.93555C26.94 8.93555 27.3877 9.38326 27.3877 9.93555Z'
            fill='#242631'
          />
          <path
            d='M33.3877 9.93555C33.3877 10.4878 32.94 10.9355 32.3877 10.9355C31.8354 10.9355 31.3877 10.4878 31.3877 9.93555C31.3877 9.38326 31.8354 8.93555 32.3877 8.93555C32.94 8.93555 33.3877 9.38326 33.3877 9.93555Z'
            fill='#242631'
          />
          <path
            d='M39.3877 9.93555C39.3877 10.4878 38.94 10.9355 38.3877 10.9355C37.8354 10.9355 37.3877 10.4878 37.3877 9.93555C37.3877 9.38326 37.8354 8.93555 38.3877 8.93555C38.94 8.93555 39.3877 9.38326 39.3877 9.93555Z'
            fill='#242631'
          />
          <path
            d='M45.3877 9.93555C45.3877 10.4878 44.94 10.9355 44.3877 10.9355C43.8354 10.9355 43.3877 10.4878 43.3877 9.93555C43.3877 9.38326 43.8354 8.93555 44.3877 8.93555C44.94 8.93555 45.3877 9.38326 45.3877 9.93555Z'
            fill='#242631'
          />
          <path
            d='M51.3877 9.93555C51.3877 10.4878 50.94 10.9355 50.3877 10.9355C49.8354 10.9355 49.3877 10.4878 49.3877 9.93555C49.3877 9.38326 49.8354 8.93555 50.3877 8.93555C50.94 8.93555 51.3877 9.38326 51.3877 9.93555Z'
            fill='#242631'
          />
          <path
            d='M57.3877 9.93555C57.3877 10.4878 56.94 10.9355 56.3877 10.9355C55.8354 10.9355 55.3877 10.4878 55.3877 9.93555C55.3877 9.38326 55.8354 8.93555 56.3877 8.93555C56.94 8.93555 57.3877 9.38326 57.3877 9.93555Z'
            fill='#242631'
          />
          <path
            d='M63.3877 9.93555C63.3877 10.4878 62.94 10.9355 62.3877 10.9355C61.8354 10.9355 61.3877 10.4878 61.3877 9.93555C61.3877 9.38326 61.8354 8.93555 62.3877 8.93555C62.94 8.93555 63.3877 9.38326 63.3877 9.93555Z'
            fill='#242631'
          />
          <path
            d='M69.3877 9.93555C69.3877 10.4878 68.94 10.9355 68.3877 10.9355C67.8354 10.9355 67.3877 10.4878 67.3877 9.93555C67.3877 9.38326 67.8354 8.93555 68.3877 8.93555C68.94 8.93555 69.3877 9.38326 69.3877 9.93555Z'
            fill='#242631'
          />
          <path
            d='M75.3877 9.93555C75.3877 10.4878 74.94 10.9355 74.3877 10.9355C73.8354 10.9355 73.3877 10.4878 73.3877 9.93555C73.3877 9.38326 73.8354 8.93555 74.3877 8.93555C74.94 8.93555 75.3877 9.38326 75.3877 9.93555Z'
            fill='#242631'
          />
          <path
            d='M81.3877 9.93555C81.3877 10.4878 80.94 10.9355 80.3877 10.9355C79.8354 10.9355 79.3877 10.4878 79.3877 9.93555C79.3877 9.38326 79.8354 8.93555 80.3877 8.93555C80.94 8.93555 81.3877 9.38326 81.3877 9.93555Z'
            fill='#242631'
          />
          <path
            d='M87.3877 9.93555C87.3877 10.4878 86.94 10.9355 86.3877 10.9355C85.8354 10.9355 85.3877 10.4878 85.3877 9.93555C85.3877 9.38326 85.8354 8.93555 86.3877 8.93555C86.94 8.93555 87.3877 9.38326 87.3877 9.93555Z'
            fill='#242631'
          />
          <path
            d='M93.3877 9.93555C93.3877 10.4878 92.94 10.9355 92.3877 10.9355C91.8354 10.9355 91.3877 10.4878 91.3877 9.93555C91.3877 9.38326 91.8354 8.93555 92.3877 8.93555C92.94 8.93555 93.3877 9.38326 93.3877 9.93555Z'
            fill='#242631'
          />
          <path
            d='M99.3877 9.93555C99.3877 10.4878 98.94 10.9355 98.3877 10.9355C97.8354 10.9355 97.3877 10.4878 97.3877 9.93555C97.3877 9.38326 97.8354 8.93555 98.3877 8.93555C98.94 8.93555 99.3877 9.38326 99.3877 9.93555Z'
            fill='#242631'
          />
          <path
            d='M105.388 9.93555C105.388 10.4878 104.94 10.9355 104.388 10.9355C103.835 10.9355 103.388 10.4878 103.388 9.93555C103.388 9.38326 103.835 8.93555 104.388 8.93555C104.94 8.93555 105.388 9.38326 105.388 9.93555Z'
            fill='#242631'
          />
          <path
            d='M111.388 9.93555C111.388 10.4878 110.94 10.9355 110.388 10.9355C109.835 10.9355 109.388 10.4878 109.388 9.93555C109.388 9.38326 109.835 8.93555 110.388 8.93555C110.94 8.93555 111.388 9.38326 111.388 9.93555Z'
            fill='#242631'
          />
          <path
            d='M117.388 9.93555C117.388 10.4878 116.94 10.9355 116.388 10.9355C115.835 10.9355 115.388 10.4878 115.388 9.93555C115.388 9.38326 115.835 8.93555 116.388 8.93555C116.94 8.93555 117.388 9.38326 117.388 9.93555Z'
            fill='#242631'
          />
          <path
            d='M123.388 9.93555C123.388 10.4878 122.94 10.9355 122.388 10.9355C121.835 10.9355 121.388 10.4878 121.388 9.93555C121.388 9.38326 121.835 8.93555 122.388 8.93555C122.94 8.93555 123.388 9.38326 123.388 9.93555Z'
            fill='#242631'
          />
          <path
            d='M129.388 9.93555C129.388 10.4878 128.94 10.9355 128.388 10.9355C127.835 10.9355 127.388 10.4878 127.388 9.93555C127.388 9.38326 127.835 8.93555 128.388 8.93555C128.94 8.93555 129.388 9.38326 129.388 9.93555Z'
            fill='#242631'
          />
          <circle cx='134.388' cy='9.93555' r='1' fill='#242631' />
          <circle cx='140.388' cy='9.93555' r='1' fill='#242631' />
          <circle cx='146.388' cy='9.93555' r='1' fill='#242631' />
          <circle cx='152.388' cy='9.93555' r='1' fill='#242631' />
          <circle cx='158.388' cy='9.93555' r='1' fill='#242631' />
          <circle cx='164.388' cy='9.93555' r='1' fill='#242631' />
          <circle cx='170.388' cy='9.93555' r='1' fill='#242631' />
          <circle cx='176.388' cy='9.93555' r='1' fill='#242631' />
          <circle cx='182.388' cy='9.93555' r='1' fill='#242631' />
          <circle cx='188.388' cy='9.93555' r='1' fill='#242631' />
          <circle cx='194.388' cy='9.93555' r='1' fill='#242631' />
          <circle cx='200.388' cy='9.93555' r='1' fill='#242631' />
          <circle cx='2.3877' cy='129.064' r='1' fill='#242631' />
          <circle cx='8.3877' cy='129.064' r='1' fill='#242631' />
          <path
            d='M15.3877 129.064C15.3877 129.617 14.94 130.064 14.3877 130.064C13.8354 130.064 13.3877 129.617 13.3877 129.064C13.3877 128.512 13.8354 128.064 14.3877 128.064C14.94 128.064 15.3877 128.512 15.3877 129.064Z'
            fill='#242631'
          />
          <path
            d='M21.3877 129.064C21.3877 129.617 20.94 130.064 20.3877 130.064C19.8354 130.064 19.3877 129.617 19.3877 129.064C19.3877 128.512 19.8354 128.064 20.3877 128.064C20.94 128.064 21.3877 128.512 21.3877 129.064Z'
            fill='#242631'
          />
          <path
            d='M27.3877 129.064C27.3877 129.617 26.94 130.064 26.3877 130.064C25.8354 130.064 25.3877 129.617 25.3877 129.064C25.3877 128.512 25.8354 128.064 26.3877 128.064C26.94 128.064 27.3877 128.512 27.3877 129.064Z'
            fill='#242631'
          />
          <path
            d='M33.3877 129.064C33.3877 129.617 32.94 130.064 32.3877 130.064C31.8354 130.064 31.3877 129.617 31.3877 129.064C31.3877 128.512 31.8354 128.064 32.3877 128.064C32.94 128.064 33.3877 128.512 33.3877 129.064Z'
            fill='#242631'
          />
          <path
            d='M39.3877 129.064C39.3877 129.617 38.94 130.064 38.3877 130.064C37.8354 130.064 37.3877 129.617 37.3877 129.064C37.3877 128.512 37.8354 128.064 38.3877 128.064C38.94 128.064 39.3877 128.512 39.3877 129.064Z'
            fill='#242631'
          />
          <path
            d='M45.3877 129.064C45.3877 129.617 44.94 130.064 44.3877 130.064C43.8354 130.064 43.3877 129.617 43.3877 129.064C43.3877 128.512 43.8354 128.064 44.3877 128.064C44.94 128.064 45.3877 128.512 45.3877 129.064Z'
            fill='#242631'
          />
          <path
            d='M51.3877 129.064C51.3877 129.617 50.94 130.064 50.3877 130.064C49.8354 130.064 49.3877 129.617 49.3877 129.064C49.3877 128.512 49.8354 128.064 50.3877 128.064C50.94 128.064 51.3877 128.512 51.3877 129.064Z'
            fill='#242631'
          />
          <path
            d='M57.3877 129.064C57.3877 129.617 56.94 130.064 56.3877 130.064C55.8354 130.064 55.3877 129.617 55.3877 129.064C55.3877 128.512 55.8354 128.064 56.3877 128.064C56.94 128.064 57.3877 128.512 57.3877 129.064Z'
            fill='#242631'
          />
          <path
            d='M63.3877 129.064C63.3877 129.617 62.94 130.064 62.3877 130.064C61.8354 130.064 61.3877 129.617 61.3877 129.064C61.3877 128.512 61.8354 128.064 62.3877 128.064C62.94 128.064 63.3877 128.512 63.3877 129.064Z'
            fill='#242631'
          />
          <path
            d='M69.3877 129.064C69.3877 129.617 68.94 130.064 68.3877 130.064C67.8354 130.064 67.3877 129.617 67.3877 129.064C67.3877 128.512 67.8354 128.064 68.3877 128.064C68.94 128.064 69.3877 128.512 69.3877 129.064Z'
            fill='#242631'
          />
          <path
            d='M75.3877 129.064C75.3877 129.617 74.94 130.064 74.3877 130.064C73.8354 130.064 73.3877 129.617 73.3877 129.064C73.3877 128.512 73.8354 128.064 74.3877 128.064C74.94 128.064 75.3877 128.512 75.3877 129.064Z'
            fill='#242631'
          />
          <path
            d='M81.3877 129.064C81.3877 129.617 80.94 130.064 80.3877 130.064C79.8354 130.064 79.3877 129.617 79.3877 129.064C79.3877 128.512 79.8354 128.064 80.3877 128.064C80.94 128.064 81.3877 128.512 81.3877 129.064Z'
            fill='#242631'
          />
          <path
            d='M87.3877 129.064C87.3877 129.617 86.94 130.064 86.3877 130.064C85.8354 130.064 85.3877 129.617 85.3877 129.064C85.3877 128.512 85.8354 128.064 86.3877 128.064C86.94 128.064 87.3877 128.512 87.3877 129.064Z'
            fill='#242631'
          />
          <path
            d='M93.3877 129.064C93.3877 129.617 92.94 130.064 92.3877 130.064C91.8354 130.064 91.3877 129.617 91.3877 129.064C91.3877 128.512 91.8354 128.064 92.3877 128.064C92.94 128.064 93.3877 128.512 93.3877 129.064Z'
            fill='#242631'
          />
          <path
            d='M99.3877 129.064C99.3877 129.617 98.94 130.064 98.3877 130.064C97.8354 130.064 97.3877 129.617 97.3877 129.064C97.3877 128.512 97.8354 128.064 98.3877 128.064C98.94 128.064 99.3877 128.512 99.3877 129.064Z'
            fill='#242631'
          />
          <path
            d='M105.388 129.064C105.388 129.617 104.94 130.064 104.388 130.064C103.835 130.064 103.388 129.617 103.388 129.064C103.388 128.512 103.835 128.064 104.388 128.064C104.94 128.064 105.388 128.512 105.388 129.064Z'
            fill='#242631'
          />
          <path
            d='M111.388 129.064C111.388 129.617 110.94 130.064 110.388 130.064C109.835 130.064 109.388 129.617 109.388 129.064C109.388 128.512 109.835 128.064 110.388 128.064C110.94 128.064 111.388 128.512 111.388 129.064Z'
            fill='#242631'
          />
          <path
            d='M117.388 129.064C117.388 129.617 116.94 130.064 116.388 130.064C115.835 130.064 115.388 129.617 115.388 129.064C115.388 128.512 115.835 128.064 116.388 128.064C116.94 128.064 117.388 128.512 117.388 129.064Z'
            fill='#242631'
          />
          <path
            d='M123.388 129.064C123.388 129.617 122.94 130.064 122.388 130.064C121.835 130.064 121.388 129.617 121.388 129.064C121.388 128.512 121.835 128.064 122.388 128.064C122.94 128.064 123.388 128.512 123.388 129.064Z'
            fill='#242631'
          />
          <path
            d='M129.388 129.064C129.388 129.617 128.94 130.064 128.388 130.064C127.835 130.064 127.388 129.617 127.388 129.064C127.388 128.512 127.835 128.064 128.388 128.064C128.94 128.064 129.388 128.512 129.388 129.064Z'
            fill='#242631'
          />
          <circle cx='134.388' cy='129.064' r='1' fill='#242631' />
          <circle cx='140.388' cy='129.064' r='1' fill='#242631' />
          <circle cx='146.388' cy='129.064' r='1' fill='#242631' />
          <circle cx='152.388' cy='129.064' r='1' fill='#242631' />
          <circle cx='158.388' cy='129.064' r='1' fill='#242631' />
          <circle cx='164.388' cy='129.064' r='1' fill='#242631' />
          <circle cx='170.388' cy='129.064' r='1' fill='#242631' />
          <circle cx='176.388' cy='129.064' r='1' fill='#242631' />
          <circle cx='182.388' cy='129.064' r='1' fill='#242631' />
          <circle cx='188.388' cy='129.064' r='1' fill='#242631' />
          <circle cx='194.388' cy='129.064' r='1' fill='#242631' />
          <circle cx='200.388' cy='129.064' r='1' fill='#242631' />
          <circle cx='2.3877' cy='33.7617' r='1' fill='#242631' />
          <circle cx='8.3877' cy='33.7617' r='1' fill='#242631' />
          <path
            d='M15.3877 33.7617C15.3877 34.314 14.94 34.7617 14.3877 34.7617C13.8354 34.7617 13.3877 34.314 13.3877 33.7617C13.3877 33.2094 13.8354 32.7617 14.3877 32.7617C14.94 32.7617 15.3877 33.2094 15.3877 33.7617Z'
            fill='#242631'
          />
          <path
            d='M21.3877 33.7617C21.3877 34.314 20.94 34.7617 20.3877 34.7617C19.8354 34.7617 19.3877 34.314 19.3877 33.7617C19.3877 33.2094 19.8354 32.7617 20.3877 32.7617C20.94 32.7617 21.3877 33.2094 21.3877 33.7617Z'
            fill='#242631'
          />
          <path
            d='M27.3877 33.7617C27.3877 34.314 26.94 34.7617 26.3877 34.7617C25.8354 34.7617 25.3877 34.314 25.3877 33.7617C25.3877 33.2094 25.8354 32.7617 26.3877 32.7617C26.94 32.7617 27.3877 33.2094 27.3877 33.7617Z'
            fill='#242631'
          />
          <path
            d='M33.3877 33.7617C33.3877 34.314 32.94 34.7617 32.3877 34.7617C31.8354 34.7617 31.3877 34.314 31.3877 33.7617C31.3877 33.2094 31.8354 32.7617 32.3877 32.7617C32.94 32.7617 33.3877 33.2094 33.3877 33.7617Z'
            fill='#242631'
          />
          <path
            d='M39.3877 33.7617C39.3877 34.314 38.94 34.7617 38.3877 34.7617C37.8354 34.7617 37.3877 34.314 37.3877 33.7617C37.3877 33.2094 37.8354 32.7617 38.3877 32.7617C38.94 32.7617 39.3877 33.2094 39.3877 33.7617Z'
            fill='#242631'
          />
          <path
            d='M45.3877 33.7617C45.3877 34.314 44.94 34.7617 44.3877 34.7617C43.8354 34.7617 43.3877 34.314 43.3877 33.7617C43.3877 33.2094 43.8354 32.7617 44.3877 32.7617C44.94 32.7617 45.3877 33.2094 45.3877 33.7617Z'
            fill='#242631'
          />
          <path
            d='M51.3877 33.7617C51.3877 34.314 50.94 34.7617 50.3877 34.7617C49.8354 34.7617 49.3877 34.314 49.3877 33.7617C49.3877 33.2094 49.8354 32.7617 50.3877 32.7617C50.94 32.7617 51.3877 33.2094 51.3877 33.7617Z'
            fill='#242631'
          />
          <path
            d='M57.3877 33.7617C57.3877 34.314 56.94 34.7617 56.3877 34.7617C55.8354 34.7617 55.3877 34.314 55.3877 33.7617C55.3877 33.2094 55.8354 32.7617 56.3877 32.7617C56.94 32.7617 57.3877 33.2094 57.3877 33.7617Z'
            fill='#242631'
          />
          <path
            d='M63.3877 33.7617C63.3877 34.314 62.94 34.7617 62.3877 34.7617C61.8354 34.7617 61.3877 34.314 61.3877 33.7617C61.3877 33.2094 61.8354 32.7617 62.3877 32.7617C62.94 32.7617 63.3877 33.2094 63.3877 33.7617Z'
            fill='#242631'
          />
          <path
            d='M69.3877 33.7617C69.3877 34.314 68.94 34.7617 68.3877 34.7617C67.8354 34.7617 67.3877 34.314 67.3877 33.7617C67.3877 33.2094 67.8354 32.7617 68.3877 32.7617C68.94 32.7617 69.3877 33.2094 69.3877 33.7617Z'
            fill='#242631'
          />
          <path
            d='M75.3877 33.7617C75.3877 34.314 74.94 34.7617 74.3877 34.7617C73.8354 34.7617 73.3877 34.314 73.3877 33.7617C73.3877 33.2094 73.8354 32.7617 74.3877 32.7617C74.94 32.7617 75.3877 33.2094 75.3877 33.7617Z'
            fill='#242631'
          />
          <path
            d='M81.3877 33.7617C81.3877 34.314 80.94 34.7617 80.3877 34.7617C79.8354 34.7617 79.3877 34.314 79.3877 33.7617C79.3877 33.2094 79.8354 32.7617 80.3877 32.7617C80.94 32.7617 81.3877 33.2094 81.3877 33.7617Z'
            fill='#242631'
          />
          <path
            d='M87.3877 33.7617C87.3877 34.314 86.94 34.7617 86.3877 34.7617C85.8354 34.7617 85.3877 34.314 85.3877 33.7617C85.3877 33.2094 85.8354 32.7617 86.3877 32.7617C86.94 32.7617 87.3877 33.2094 87.3877 33.7617Z'
            fill='#242631'
          />
          <path
            d='M93.3877 33.7617C93.3877 34.314 92.94 34.7617 92.3877 34.7617C91.8354 34.7617 91.3877 34.314 91.3877 33.7617C91.3877 33.2094 91.8354 32.7617 92.3877 32.7617C92.94 32.7617 93.3877 33.2094 93.3877 33.7617Z'
            fill='#242631'
          />
          <path
            d='M99.3877 33.7617C99.3877 34.314 98.94 34.7617 98.3877 34.7617C97.8354 34.7617 97.3877 34.314 97.3877 33.7617C97.3877 33.2094 97.8354 32.7617 98.3877 32.7617C98.94 32.7617 99.3877 33.2094 99.3877 33.7617Z'
            fill='#242631'
          />
          <path
            d='M105.388 33.7617C105.388 34.314 104.94 34.7617 104.388 34.7617C103.835 34.7617 103.388 34.314 103.388 33.7617C103.388 33.2094 103.835 32.7617 104.388 32.7617C104.94 32.7617 105.388 33.2094 105.388 33.7617Z'
            fill='#242631'
          />
          <path
            d='M111.388 33.7617C111.388 34.314 110.94 34.7617 110.388 34.7617C109.835 34.7617 109.388 34.314 109.388 33.7617C109.388 33.2094 109.835 32.7617 110.388 32.7617C110.94 32.7617 111.388 33.2094 111.388 33.7617Z'
            fill='#242631'
          />
          <path
            d='M117.388 33.7617C117.388 34.314 116.94 34.7617 116.388 34.7617C115.835 34.7617 115.388 34.314 115.388 33.7617C115.388 33.2094 115.835 32.7617 116.388 32.7617C116.94 32.7617 117.388 33.2094 117.388 33.7617Z'
            fill='#242631'
          />
          <path
            d='M123.388 33.7617C123.388 34.314 122.94 34.7617 122.388 34.7617C121.835 34.7617 121.388 34.314 121.388 33.7617C121.388 33.2094 121.835 32.7617 122.388 32.7617C122.94 32.7617 123.388 33.2094 123.388 33.7617Z'
            fill='#242631'
          />
          <path
            d='M129.388 33.7617C129.388 34.314 128.94 34.7617 128.388 34.7617C127.835 34.7617 127.388 34.314 127.388 33.7617C127.388 33.2094 127.835 32.7617 128.388 32.7617C128.94 32.7617 129.388 33.2094 129.388 33.7617Z'
            fill='#242631'
          />
          <circle cx='134.388' cy='33.7617' r='1' fill='#242631' />
          <circle cx='140.388' cy='33.7617' r='1' fill='#242631' />
          <circle cx='146.388' cy='33.7617' r='1' fill='#242631' />
          <circle cx='152.388' cy='33.7617' r='1' fill='#242631' />
          <circle cx='158.388' cy='33.7617' r='1' fill='#242631' />
          <circle cx='164.388' cy='33.7617' r='1' fill='#242631' />
          <circle cx='170.388' cy='33.7617' r='1' fill='#242631' />
          <circle cx='176.388' cy='33.7617' r='1' fill='#242631' />
          <circle cx='182.388' cy='33.7617' r='1' fill='#242631' />
          <circle cx='188.388' cy='33.7617' r='1' fill='#242631' />
          <circle cx='194.388' cy='33.7617' r='1' fill='#242631' />
          <circle cx='200.388' cy='33.7617' r='1' fill='#242631' />
          <circle cx='2.3877' cy='152.891' r='1' fill='#242631' />
          <circle cx='8.3877' cy='152.891' r='1' fill='#242631' />
          <path
            d='M15.3877 152.891C15.3877 153.443 14.94 153.891 14.3877 153.891C13.8354 153.891 13.3877 153.443 13.3877 152.891C13.3877 152.338 13.8354 151.891 14.3877 151.891C14.94 151.891 15.3877 152.338 15.3877 152.891Z'
            fill='#242631'
          />
          <path
            d='M21.3877 152.891C21.3877 153.443 20.94 153.891 20.3877 153.891C19.8354 153.891 19.3877 153.443 19.3877 152.891C19.3877 152.338 19.8354 151.891 20.3877 151.891C20.94 151.891 21.3877 152.338 21.3877 152.891Z'
            fill='#242631'
          />
          <path
            d='M27.3877 152.891C27.3877 153.443 26.94 153.891 26.3877 153.891C25.8354 153.891 25.3877 153.443 25.3877 152.891C25.3877 152.338 25.8354 151.891 26.3877 151.891C26.94 151.891 27.3877 152.338 27.3877 152.891Z'
            fill='#242631'
          />
          <path
            d='M33.3877 152.891C33.3877 153.443 32.94 153.891 32.3877 153.891C31.8354 153.891 31.3877 153.443 31.3877 152.891C31.3877 152.338 31.8354 151.891 32.3877 151.891C32.94 151.891 33.3877 152.338 33.3877 152.891Z'
            fill='#242631'
          />
          <path
            d='M39.3877 152.891C39.3877 153.443 38.94 153.891 38.3877 153.891C37.8354 153.891 37.3877 153.443 37.3877 152.891C37.3877 152.338 37.8354 151.891 38.3877 151.891C38.94 151.891 39.3877 152.338 39.3877 152.891Z'
            fill='#242631'
          />
          <path
            d='M45.3877 152.891C45.3877 153.443 44.94 153.891 44.3877 153.891C43.8354 153.891 43.3877 153.443 43.3877 152.891C43.3877 152.338 43.8354 151.891 44.3877 151.891C44.94 151.891 45.3877 152.338 45.3877 152.891Z'
            fill='#242631'
          />
          <path
            d='M51.3877 152.891C51.3877 153.443 50.94 153.891 50.3877 153.891C49.8354 153.891 49.3877 153.443 49.3877 152.891C49.3877 152.338 49.8354 151.891 50.3877 151.891C50.94 151.891 51.3877 152.338 51.3877 152.891Z'
            fill='#242631'
          />
          <path
            d='M57.3877 152.891C57.3877 153.443 56.94 153.891 56.3877 153.891C55.8354 153.891 55.3877 153.443 55.3877 152.891C55.3877 152.338 55.8354 151.891 56.3877 151.891C56.94 151.891 57.3877 152.338 57.3877 152.891Z'
            fill='#242631'
          />
          <path
            d='M63.3877 152.891C63.3877 153.443 62.94 153.891 62.3877 153.891C61.8354 153.891 61.3877 153.443 61.3877 152.891C61.3877 152.338 61.8354 151.891 62.3877 151.891C62.94 151.891 63.3877 152.338 63.3877 152.891Z'
            fill='#242631'
          />
          <path
            d='M69.3877 152.891C69.3877 153.443 68.94 153.891 68.3877 153.891C67.8354 153.891 67.3877 153.443 67.3877 152.891C67.3877 152.338 67.8354 151.891 68.3877 151.891C68.94 151.891 69.3877 152.338 69.3877 152.891Z'
            fill='#242631'
          />
          <path
            d='M75.3877 152.891C75.3877 153.443 74.94 153.891 74.3877 153.891C73.8354 153.891 73.3877 153.443 73.3877 152.891C73.3877 152.338 73.8354 151.891 74.3877 151.891C74.94 151.891 75.3877 152.338 75.3877 152.891Z'
            fill='#242631'
          />
          <path
            d='M81.3877 152.891C81.3877 153.443 80.94 153.891 80.3877 153.891C79.8354 153.891 79.3877 153.443 79.3877 152.891C79.3877 152.338 79.8354 151.891 80.3877 151.891C80.94 151.891 81.3877 152.338 81.3877 152.891Z'
            fill='#242631'
          />
          <path
            d='M87.3877 152.891C87.3877 153.443 86.94 153.891 86.3877 153.891C85.8354 153.891 85.3877 153.443 85.3877 152.891C85.3877 152.338 85.8354 151.891 86.3877 151.891C86.94 151.891 87.3877 152.338 87.3877 152.891Z'
            fill='#242631'
          />
          <path
            d='M93.3877 152.891C93.3877 153.443 92.94 153.891 92.3877 153.891C91.8354 153.891 91.3877 153.443 91.3877 152.891C91.3877 152.338 91.8354 151.891 92.3877 151.891C92.94 151.891 93.3877 152.338 93.3877 152.891Z'
            fill='#242631'
          />
          <path
            d='M99.3877 152.891C99.3877 153.443 98.94 153.891 98.3877 153.891C97.8354 153.891 97.3877 153.443 97.3877 152.891C97.3877 152.338 97.8354 151.891 98.3877 151.891C98.94 151.891 99.3877 152.338 99.3877 152.891Z'
            fill='#242631'
          />
          <path
            d='M105.388 152.891C105.388 153.443 104.94 153.891 104.388 153.891C103.835 153.891 103.388 153.443 103.388 152.891C103.388 152.338 103.835 151.891 104.388 151.891C104.94 151.891 105.388 152.338 105.388 152.891Z'
            fill='#242631'
          />
          <path
            d='M111.388 152.891C111.388 153.443 110.94 153.891 110.388 153.891C109.835 153.891 109.388 153.443 109.388 152.891C109.388 152.338 109.835 151.891 110.388 151.891C110.94 151.891 111.388 152.338 111.388 152.891Z'
            fill='#242631'
          />
          <path
            d='M117.388 152.891C117.388 153.443 116.94 153.891 116.388 153.891C115.835 153.891 115.388 153.443 115.388 152.891C115.388 152.338 115.835 151.891 116.388 151.891C116.94 151.891 117.388 152.338 117.388 152.891Z'
            fill='#242631'
          />
          <path
            d='M123.388 152.891C123.388 153.443 122.94 153.891 122.388 153.891C121.835 153.891 121.388 153.443 121.388 152.891C121.388 152.338 121.835 151.891 122.388 151.891C122.94 151.891 123.388 152.338 123.388 152.891Z'
            fill='#242631'
          />
          <path
            d='M129.388 152.891C129.388 153.443 128.94 153.891 128.388 153.891C127.835 153.891 127.388 153.443 127.388 152.891C127.388 152.338 127.835 151.891 128.388 151.891C128.94 151.891 129.388 152.338 129.388 152.891Z'
            fill='#242631'
          />
          <circle cx='134.388' cy='152.891' r='1' fill='#242631' />
          <circle cx='140.388' cy='152.891' r='1' fill='#242631' />
          <circle cx='146.388' cy='152.891' r='1' fill='#242631' />
          <circle cx='152.388' cy='152.891' r='1' fill='#242631' />
          <circle cx='158.388' cy='152.891' r='1' fill='#242631' />
          <circle cx='164.388' cy='152.891' r='1' fill='#242631' />
          <circle cx='170.388' cy='152.891' r='1' fill='#242631' />
          <circle cx='176.388' cy='152.891' r='1' fill='#242631' />
          <circle cx='182.388' cy='152.891' r='1' fill='#242631' />
          <circle cx='188.388' cy='152.891' r='1' fill='#242631' />
          <circle cx='194.388' cy='152.891' r='1' fill='#242631' />
          <circle cx='200.388' cy='152.891' r='1' fill='#242631' />
          <circle cx='2.3877' cy='57.5859' r='1' fill='#242631' />
          <circle cx='8.3877' cy='57.5859' r='1' fill='#242631' />
          <path
            d='M15.3877 57.5859C15.3877 58.1382 14.94 58.5859 14.3877 58.5859C13.8354 58.5859 13.3877 58.1382 13.3877 57.5859C13.3877 57.0337 13.8354 56.5859 14.3877 56.5859C14.94 56.5859 15.3877 57.0337 15.3877 57.5859Z'
            fill='#242631'
          />
          <path
            d='M21.3877 57.5859C21.3877 58.1382 20.94 58.5859 20.3877 58.5859C19.8354 58.5859 19.3877 58.1382 19.3877 57.5859C19.3877 57.0337 19.8354 56.5859 20.3877 56.5859C20.94 56.5859 21.3877 57.0337 21.3877 57.5859Z'
            fill='#242631'
          />
          <path
            d='M27.3877 57.5859C27.3877 58.1382 26.94 58.5859 26.3877 58.5859C25.8354 58.5859 25.3877 58.1382 25.3877 57.5859C25.3877 57.0337 25.8354 56.5859 26.3877 56.5859C26.94 56.5859 27.3877 57.0337 27.3877 57.5859Z'
            fill='#242631'
          />
          <path
            d='M33.3877 57.5859C33.3877 58.1382 32.94 58.5859 32.3877 58.5859C31.8354 58.5859 31.3877 58.1382 31.3877 57.5859C31.3877 57.0337 31.8354 56.5859 32.3877 56.5859C32.94 56.5859 33.3877 57.0337 33.3877 57.5859Z'
            fill='#242631'
          />
          <path
            d='M39.3877 57.5859C39.3877 58.1382 38.94 58.5859 38.3877 58.5859C37.8354 58.5859 37.3877 58.1382 37.3877 57.5859C37.3877 57.0337 37.8354 56.5859 38.3877 56.5859C38.94 56.5859 39.3877 57.0337 39.3877 57.5859Z'
            fill='#242631'
          />
          <path
            d='M45.3877 57.5859C45.3877 58.1382 44.94 58.5859 44.3877 58.5859C43.8354 58.5859 43.3877 58.1382 43.3877 57.5859C43.3877 57.0337 43.8354 56.5859 44.3877 56.5859C44.94 56.5859 45.3877 57.0337 45.3877 57.5859Z'
            fill='#242631'
          />
          <path
            d='M51.3877 57.5859C51.3877 58.1382 50.94 58.5859 50.3877 58.5859C49.8354 58.5859 49.3877 58.1382 49.3877 57.5859C49.3877 57.0337 49.8354 56.5859 50.3877 56.5859C50.94 56.5859 51.3877 57.0337 51.3877 57.5859Z'
            fill='#242631'
          />
          <path
            d='M57.3877 57.5859C57.3877 58.1382 56.94 58.5859 56.3877 58.5859C55.8354 58.5859 55.3877 58.1382 55.3877 57.5859C55.3877 57.0337 55.8354 56.5859 56.3877 56.5859C56.94 56.5859 57.3877 57.0337 57.3877 57.5859Z'
            fill='#242631'
          />
          <path
            d='M63.3877 57.5859C63.3877 58.1382 62.94 58.5859 62.3877 58.5859C61.8354 58.5859 61.3877 58.1382 61.3877 57.5859C61.3877 57.0337 61.8354 56.5859 62.3877 56.5859C62.94 56.5859 63.3877 57.0337 63.3877 57.5859Z'
            fill='#242631'
          />
          <path
            d='M69.3877 57.5859C69.3877 58.1382 68.94 58.5859 68.3877 58.5859C67.8354 58.5859 67.3877 58.1382 67.3877 57.5859C67.3877 57.0337 67.8354 56.5859 68.3877 56.5859C68.94 56.5859 69.3877 57.0337 69.3877 57.5859Z'
            fill='#242631'
          />
          <path
            d='M75.3877 57.5859C75.3877 58.1382 74.94 58.5859 74.3877 58.5859C73.8354 58.5859 73.3877 58.1382 73.3877 57.5859C73.3877 57.0337 73.8354 56.5859 74.3877 56.5859C74.94 56.5859 75.3877 57.0337 75.3877 57.5859Z'
            fill='#242631'
          />
          <path
            d='M81.3877 57.5859C81.3877 58.1382 80.94 58.5859 80.3877 58.5859C79.8354 58.5859 79.3877 58.1382 79.3877 57.5859C79.3877 57.0337 79.8354 56.5859 80.3877 56.5859C80.94 56.5859 81.3877 57.0337 81.3877 57.5859Z'
            fill='#242631'
          />
          <path
            d='M87.3877 57.5859C87.3877 58.1382 86.94 58.5859 86.3877 58.5859C85.8354 58.5859 85.3877 58.1382 85.3877 57.5859C85.3877 57.0337 85.8354 56.5859 86.3877 56.5859C86.94 56.5859 87.3877 57.0337 87.3877 57.5859Z'
            fill='#242631'
          />
          <path
            d='M93.3877 57.5859C93.3877 58.1382 92.94 58.5859 92.3877 58.5859C91.8354 58.5859 91.3877 58.1382 91.3877 57.5859C91.3877 57.0337 91.8354 56.5859 92.3877 56.5859C92.94 56.5859 93.3877 57.0337 93.3877 57.5859Z'
            fill='#242631'
          />
          <path
            d='M99.3877 57.5859C99.3877 58.1382 98.94 58.5859 98.3877 58.5859C97.8354 58.5859 97.3877 58.1382 97.3877 57.5859C97.3877 57.0337 97.8354 56.5859 98.3877 56.5859C98.94 56.5859 99.3877 57.0337 99.3877 57.5859Z'
            fill='#242631'
          />
          <path
            d='M105.388 57.5859C105.388 58.1382 104.94 58.5859 104.388 58.5859C103.835 58.5859 103.388 58.1382 103.388 57.5859C103.388 57.0337 103.835 56.5859 104.388 56.5859C104.94 56.5859 105.388 57.0337 105.388 57.5859Z'
            fill='#242631'
          />
          <path
            d='M111.388 57.5859C111.388 58.1382 110.94 58.5859 110.388 58.5859C109.835 58.5859 109.388 58.1382 109.388 57.5859C109.388 57.0337 109.835 56.5859 110.388 56.5859C110.94 56.5859 111.388 57.0337 111.388 57.5859Z'
            fill='#242631'
          />
          <path
            d='M117.388 57.5859C117.388 58.1382 116.94 58.5859 116.388 58.5859C115.835 58.5859 115.388 58.1382 115.388 57.5859C115.388 57.0337 115.835 56.5859 116.388 56.5859C116.94 56.5859 117.388 57.0337 117.388 57.5859Z'
            fill='#242631'
          />
          <path
            d='M123.388 57.5859C123.388 58.1382 122.94 58.5859 122.388 58.5859C121.835 58.5859 121.388 58.1382 121.388 57.5859C121.388 57.0337 121.835 56.5859 122.388 56.5859C122.94 56.5859 123.388 57.0337 123.388 57.5859Z'
            fill='#242631'
          />
          <path
            d='M129.388 57.5859C129.388 58.1382 128.94 58.5859 128.388 58.5859C127.835 58.5859 127.388 58.1382 127.388 57.5859C127.388 57.0337 127.835 56.5859 128.388 56.5859C128.94 56.5859 129.388 57.0337 129.388 57.5859Z'
            fill='#242631'
          />
          <circle cx='134.388' cy='57.5859' r='1' fill='#242631' />
          <circle cx='140.388' cy='57.5859' r='1' fill='#242631' />
          <circle cx='146.388' cy='57.5859' r='1' fill='#242631' />
          <circle cx='152.388' cy='57.5859' r='1' fill='#242631' />
          <circle cx='158.388' cy='57.5859' r='1' fill='#242631' />
          <circle cx='164.388' cy='57.5859' r='1' fill='#242631' />
          <circle cx='170.388' cy='57.5859' r='1' fill='#242631' />
          <circle cx='176.388' cy='57.5859' r='1' fill='#242631' />
          <circle cx='182.388' cy='57.5859' r='1' fill='#242631' />
          <circle cx='188.388' cy='57.5859' r='1' fill='#242631' />
          <circle cx='194.388' cy='57.5859' r='1' fill='#242631' />
          <circle cx='200.388' cy='57.5859' r='1' fill='#242631' />
          <circle cx='2.3877' cy='176.717' r='1' fill='#242631' />
          <circle cx='8.3877' cy='176.717' r='1' fill='#242631' />
          <path
            d='M15.3877 176.717C15.3877 177.269 14.94 177.717 14.3877 177.717C13.8354 177.717 13.3877 177.269 13.3877 176.717C13.3877 176.165 13.8354 175.717 14.3877 175.717C14.94 175.717 15.3877 176.165 15.3877 176.717Z'
            fill='#242631'
          />
          <path
            d='M21.3877 176.717C21.3877 177.269 20.94 177.717 20.3877 177.717C19.8354 177.717 19.3877 177.269 19.3877 176.717C19.3877 176.165 19.8354 175.717 20.3877 175.717C20.94 175.717 21.3877 176.165 21.3877 176.717Z'
            fill='#242631'
          />
          <path
            d='M27.3877 176.717C27.3877 177.269 26.94 177.717 26.3877 177.717C25.8354 177.717 25.3877 177.269 25.3877 176.717C25.3877 176.165 25.8354 175.717 26.3877 175.717C26.94 175.717 27.3877 176.165 27.3877 176.717Z'
            fill='#242631'
          />
          <path
            d='M33.3877 176.717C33.3877 177.269 32.94 177.717 32.3877 177.717C31.8354 177.717 31.3877 177.269 31.3877 176.717C31.3877 176.165 31.8354 175.717 32.3877 175.717C32.94 175.717 33.3877 176.165 33.3877 176.717Z'
            fill='#242631'
          />
          <path
            d='M39.3877 176.717C39.3877 177.269 38.94 177.717 38.3877 177.717C37.8354 177.717 37.3877 177.269 37.3877 176.717C37.3877 176.165 37.8354 175.717 38.3877 175.717C38.94 175.717 39.3877 176.165 39.3877 176.717Z'
            fill='#242631'
          />
          <path
            d='M45.3877 176.717C45.3877 177.269 44.94 177.717 44.3877 177.717C43.8354 177.717 43.3877 177.269 43.3877 176.717C43.3877 176.165 43.8354 175.717 44.3877 175.717C44.94 175.717 45.3877 176.165 45.3877 176.717Z'
            fill='#242631'
          />
          <path
            d='M51.3877 176.717C51.3877 177.269 50.94 177.717 50.3877 177.717C49.8354 177.717 49.3877 177.269 49.3877 176.717C49.3877 176.165 49.8354 175.717 50.3877 175.717C50.94 175.717 51.3877 176.165 51.3877 176.717Z'
            fill='#242631'
          />
          <path
            d='M57.3877 176.717C57.3877 177.269 56.94 177.717 56.3877 177.717C55.8354 177.717 55.3877 177.269 55.3877 176.717C55.3877 176.165 55.8354 175.717 56.3877 175.717C56.94 175.717 57.3877 176.165 57.3877 176.717Z'
            fill='#242631'
          />
          <path
            d='M63.3877 176.717C63.3877 177.269 62.94 177.717 62.3877 177.717C61.8354 177.717 61.3877 177.269 61.3877 176.717C61.3877 176.165 61.8354 175.717 62.3877 175.717C62.94 175.717 63.3877 176.165 63.3877 176.717Z'
            fill='#242631'
          />
          <path
            d='M69.3877 176.717C69.3877 177.269 68.94 177.717 68.3877 177.717C67.8354 177.717 67.3877 177.269 67.3877 176.717C67.3877 176.165 67.8354 175.717 68.3877 175.717C68.94 175.717 69.3877 176.165 69.3877 176.717Z'
            fill='#242631'
          />
          <path
            d='M75.3877 176.717C75.3877 177.269 74.94 177.717 74.3877 177.717C73.8354 177.717 73.3877 177.269 73.3877 176.717C73.3877 176.165 73.8354 175.717 74.3877 175.717C74.94 175.717 75.3877 176.165 75.3877 176.717Z'
            fill='#242631'
          />
          <path
            d='M81.3877 176.717C81.3877 177.269 80.94 177.717 80.3877 177.717C79.8354 177.717 79.3877 177.269 79.3877 176.717C79.3877 176.165 79.8354 175.717 80.3877 175.717C80.94 175.717 81.3877 176.165 81.3877 176.717Z'
            fill='#242631'
          />
          <path
            d='M87.3877 176.717C87.3877 177.269 86.94 177.717 86.3877 177.717C85.8354 177.717 85.3877 177.269 85.3877 176.717C85.3877 176.165 85.8354 175.717 86.3877 175.717C86.94 175.717 87.3877 176.165 87.3877 176.717Z'
            fill='#242631'
          />
          <path
            d='M93.3877 176.717C93.3877 177.269 92.94 177.717 92.3877 177.717C91.8354 177.717 91.3877 177.269 91.3877 176.717C91.3877 176.165 91.8354 175.717 92.3877 175.717C92.94 175.717 93.3877 176.165 93.3877 176.717Z'
            fill='#242631'
          />
          <path
            d='M99.3877 176.717C99.3877 177.269 98.94 177.717 98.3877 177.717C97.8354 177.717 97.3877 177.269 97.3877 176.717C97.3877 176.165 97.8354 175.717 98.3877 175.717C98.94 175.717 99.3877 176.165 99.3877 176.717Z'
            fill='#242631'
          />
          <path
            d='M105.388 176.717C105.388 177.269 104.94 177.717 104.388 177.717C103.835 177.717 103.388 177.269 103.388 176.717C103.388 176.165 103.835 175.717 104.388 175.717C104.94 175.717 105.388 176.165 105.388 176.717Z'
            fill='#242631'
          />
          <path
            d='M111.388 176.717C111.388 177.269 110.94 177.717 110.388 177.717C109.835 177.717 109.388 177.269 109.388 176.717C109.388 176.165 109.835 175.717 110.388 175.717C110.94 175.717 111.388 176.165 111.388 176.717Z'
            fill='#242631'
          />
          <path
            d='M117.388 176.717C117.388 177.269 116.94 177.717 116.388 177.717C115.835 177.717 115.388 177.269 115.388 176.717C115.388 176.165 115.835 175.717 116.388 175.717C116.94 175.717 117.388 176.165 117.388 176.717Z'
            fill='#242631'
          />
          <path
            d='M123.388 176.717C123.388 177.269 122.94 177.717 122.388 177.717C121.835 177.717 121.388 177.269 121.388 176.717C121.388 176.165 121.835 175.717 122.388 175.717C122.94 175.717 123.388 176.165 123.388 176.717Z'
            fill='#242631'
          />
          <path
            d='M129.388 176.717C129.388 177.269 128.94 177.717 128.388 177.717C127.835 177.717 127.388 177.269 127.388 176.717C127.388 176.165 127.835 175.717 128.388 175.717C128.94 175.717 129.388 176.165 129.388 176.717Z'
            fill='#242631'
          />
          <circle cx='134.388' cy='176.717' r='1' fill='#242631' />
          <circle cx='140.388' cy='176.717' r='1' fill='#242631' />
          <circle cx='146.388' cy='176.717' r='1' fill='#242631' />
          <circle cx='152.388' cy='176.717' r='1' fill='#242631' />
          <circle cx='158.388' cy='176.717' r='1' fill='#242631' />
          <circle cx='164.388' cy='176.717' r='1' fill='#242631' />
          <circle cx='170.388' cy='176.717' r='1' fill='#242631' />
          <circle cx='176.388' cy='176.717' r='1' fill='#242631' />
          <circle cx='182.388' cy='176.717' r='1' fill='#242631' />
          <circle cx='188.388' cy='176.717' r='1' fill='#242631' />
          <circle cx='194.388' cy='176.717' r='1' fill='#242631' />
          <circle cx='200.388' cy='176.717' r='1' fill='#242631' />
          <circle cx='2.3877' cy='81.4121' r='1' fill='#242631' />
          <circle cx='8.3877' cy='81.4121' r='1' fill='#242631' />
          <path
            d='M15.3877 81.4121C15.3877 81.9644 14.94 82.4121 14.3877 82.4121C13.8354 82.4121 13.3877 81.9644 13.3877 81.4121C13.3877 80.8598 13.8354 80.4121 14.3877 80.4121C14.94 80.4121 15.3877 80.8598 15.3877 81.4121Z'
            fill='#242631'
          />
          <path
            d='M21.3877 81.4121C21.3877 81.9644 20.94 82.4121 20.3877 82.4121C19.8354 82.4121 19.3877 81.9644 19.3877 81.4121C19.3877 80.8598 19.8354 80.4121 20.3877 80.4121C20.94 80.4121 21.3877 80.8598 21.3877 81.4121Z'
            fill='#242631'
          />
          <path
            d='M27.3877 81.4121C27.3877 81.9644 26.94 82.4121 26.3877 82.4121C25.8354 82.4121 25.3877 81.9644 25.3877 81.4121C25.3877 80.8598 25.8354 80.4121 26.3877 80.4121C26.94 80.4121 27.3877 80.8598 27.3877 81.4121Z'
            fill='#242631'
          />
          <path
            d='M33.3877 81.4121C33.3877 81.9644 32.94 82.4121 32.3877 82.4121C31.8354 82.4121 31.3877 81.9644 31.3877 81.4121C31.3877 80.8598 31.8354 80.4121 32.3877 80.4121C32.94 80.4121 33.3877 80.8598 33.3877 81.4121Z'
            fill='#242631'
          />
          <path
            d='M39.3877 81.4121C39.3877 81.9644 38.94 82.4121 38.3877 82.4121C37.8354 82.4121 37.3877 81.9644 37.3877 81.4121C37.3877 80.8598 37.8354 80.4121 38.3877 80.4121C38.94 80.4121 39.3877 80.8598 39.3877 81.4121Z'
            fill='#242631'
          />
          <path
            d='M45.3877 81.4121C45.3877 81.9644 44.94 82.4121 44.3877 82.4121C43.8354 82.4121 43.3877 81.9644 43.3877 81.4121C43.3877 80.8598 43.8354 80.4121 44.3877 80.4121C44.94 80.4121 45.3877 80.8598 45.3877 81.4121Z'
            fill='#242631'
          />
          <path
            d='M51.3877 81.4121C51.3877 81.9644 50.94 82.4121 50.3877 82.4121C49.8354 82.4121 49.3877 81.9644 49.3877 81.4121C49.3877 80.8598 49.8354 80.4121 50.3877 80.4121C50.94 80.4121 51.3877 80.8598 51.3877 81.4121Z'
            fill='#242631'
          />
          <path
            d='M57.3877 81.4121C57.3877 81.9644 56.94 82.4121 56.3877 82.4121C55.8354 82.4121 55.3877 81.9644 55.3877 81.4121C55.3877 80.8598 55.8354 80.4121 56.3877 80.4121C56.94 80.4121 57.3877 80.8598 57.3877 81.4121Z'
            fill='#242631'
          />
          <path
            d='M63.3877 81.4121C63.3877 81.9644 62.94 82.4121 62.3877 82.4121C61.8354 82.4121 61.3877 81.9644 61.3877 81.4121C61.3877 80.8598 61.8354 80.4121 62.3877 80.4121C62.94 80.4121 63.3877 80.8598 63.3877 81.4121Z'
            fill='#242631'
          />
          <path
            d='M69.3877 81.4121C69.3877 81.9644 68.94 82.4121 68.3877 82.4121C67.8354 82.4121 67.3877 81.9644 67.3877 81.4121C67.3877 80.8598 67.8354 80.4121 68.3877 80.4121C68.94 80.4121 69.3877 80.8598 69.3877 81.4121Z'
            fill='#242631'
          />
          <path
            d='M75.3877 81.4121C75.3877 81.9644 74.94 82.4121 74.3877 82.4121C73.8354 82.4121 73.3877 81.9644 73.3877 81.4121C73.3877 80.8598 73.8354 80.4121 74.3877 80.4121C74.94 80.4121 75.3877 80.8598 75.3877 81.4121Z'
            fill='#242631'
          />
          <path
            d='M81.3877 81.4121C81.3877 81.9644 80.94 82.4121 80.3877 82.4121C79.8354 82.4121 79.3877 81.9644 79.3877 81.4121C79.3877 80.8598 79.8354 80.4121 80.3877 80.4121C80.94 80.4121 81.3877 80.8598 81.3877 81.4121Z'
            fill='#242631'
          />
          <path
            d='M87.3877 81.4121C87.3877 81.9644 86.94 82.4121 86.3877 82.4121C85.8354 82.4121 85.3877 81.9644 85.3877 81.4121C85.3877 80.8598 85.8354 80.4121 86.3877 80.4121C86.94 80.4121 87.3877 80.8598 87.3877 81.4121Z'
            fill='#242631'
          />
          <path
            d='M93.3877 81.4121C93.3877 81.9644 92.94 82.4121 92.3877 82.4121C91.8354 82.4121 91.3877 81.9644 91.3877 81.4121C91.3877 80.8598 91.8354 80.4121 92.3877 80.4121C92.94 80.4121 93.3877 80.8598 93.3877 81.4121Z'
            fill='#242631'
          />
          <path
            d='M99.3877 81.4121C99.3877 81.9644 98.94 82.4121 98.3877 82.4121C97.8354 82.4121 97.3877 81.9644 97.3877 81.4121C97.3877 80.8598 97.8354 80.4121 98.3877 80.4121C98.94 80.4121 99.3877 80.8598 99.3877 81.4121Z'
            fill='#242631'
          />
          <path
            d='M105.388 81.4121C105.388 81.9644 104.94 82.4121 104.388 82.4121C103.835 82.4121 103.388 81.9644 103.388 81.4121C103.388 80.8598 103.835 80.4121 104.388 80.4121C104.94 80.4121 105.388 80.8598 105.388 81.4121Z'
            fill='#242631'
          />
          <path
            d='M111.388 81.4121C111.388 81.9644 110.94 82.4121 110.388 82.4121C109.835 82.4121 109.388 81.9644 109.388 81.4121C109.388 80.8598 109.835 80.4121 110.388 80.4121C110.94 80.4121 111.388 80.8598 111.388 81.4121Z'
            fill='#242631'
          />
          <path
            d='M117.388 81.4121C117.388 81.9644 116.94 82.4121 116.388 82.4121C115.835 82.4121 115.388 81.9644 115.388 81.4121C115.388 80.8598 115.835 80.4121 116.388 80.4121C116.94 80.4121 117.388 80.8598 117.388 81.4121Z'
            fill='#242631'
          />
          <path
            d='M123.388 81.4121C123.388 81.9644 122.94 82.4121 122.388 82.4121C121.835 82.4121 121.388 81.9644 121.388 81.4121C121.388 80.8598 121.835 80.4121 122.388 80.4121C122.94 80.4121 123.388 80.8598 123.388 81.4121Z'
            fill='#242631'
          />
          <path
            d='M129.388 81.4121C129.388 81.9644 128.94 82.4121 128.388 82.4121C127.835 82.4121 127.388 81.9644 127.388 81.4121C127.388 80.8598 127.835 80.4121 128.388 80.4121C128.94 80.4121 129.388 80.8598 129.388 81.4121Z'
            fill='#242631'
          />
          <circle cx='134.388' cy='81.4121' r='1' fill='#242631' />
          <circle cx='140.388' cy='81.4121' r='1' fill='#242631' />
          <circle cx='146.388' cy='81.4121' r='1' fill='#242631' />
          <circle cx='152.388' cy='81.4121' r='1' fill='#242631' />
          <circle cx='158.388' cy='81.4121' r='1' fill='#242631' />
          <circle cx='164.388' cy='81.4121' r='1' fill='#242631' />
          <circle cx='170.388' cy='81.4121' r='1' fill='#242631' />
          <circle cx='176.388' cy='81.4121' r='1' fill='#242631' />
          <circle cx='182.388' cy='81.4121' r='1' fill='#242631' />
          <circle cx='188.388' cy='81.4121' r='1' fill='#242631' />
          <circle cx='194.388' cy='81.4121' r='1' fill='#242631' />
          <circle cx='200.388' cy='81.4121' r='1' fill='#242631' />
          <circle cx='2.3877' cy='200.543' r='1' fill='#242631' />
          <circle cx='8.3877' cy='200.543' r='1' fill='#242631' />
          <path
            d='M15.3877 200.543C15.3877 201.095 14.94 201.543 14.3877 201.543C13.8354 201.543 13.3877 201.095 13.3877 200.543C13.3877 199.991 13.8354 199.543 14.3877 199.543C14.94 199.543 15.3877 199.991 15.3877 200.543Z'
            fill='#242631'
          />
          <path
            d='M21.3877 200.543C21.3877 201.095 20.94 201.543 20.3877 201.543C19.8354 201.543 19.3877 201.095 19.3877 200.543C19.3877 199.991 19.8354 199.543 20.3877 199.543C20.94 199.543 21.3877 199.991 21.3877 200.543Z'
            fill='#242631'
          />
          <path
            d='M27.3877 200.543C27.3877 201.095 26.94 201.543 26.3877 201.543C25.8354 201.543 25.3877 201.095 25.3877 200.543C25.3877 199.991 25.8354 199.543 26.3877 199.543C26.94 199.543 27.3877 199.991 27.3877 200.543Z'
            fill='#242631'
          />
          <path
            d='M33.3877 200.543C33.3877 201.095 32.94 201.543 32.3877 201.543C31.8354 201.543 31.3877 201.095 31.3877 200.543C31.3877 199.991 31.8354 199.543 32.3877 199.543C32.94 199.543 33.3877 199.991 33.3877 200.543Z'
            fill='#242631'
          />
          <path
            d='M39.3877 200.543C39.3877 201.095 38.94 201.543 38.3877 201.543C37.8354 201.543 37.3877 201.095 37.3877 200.543C37.3877 199.991 37.8354 199.543 38.3877 199.543C38.94 199.543 39.3877 199.991 39.3877 200.543Z'
            fill='#242631'
          />
          <path
            d='M45.3877 200.543C45.3877 201.095 44.94 201.543 44.3877 201.543C43.8354 201.543 43.3877 201.095 43.3877 200.543C43.3877 199.991 43.8354 199.543 44.3877 199.543C44.94 199.543 45.3877 199.991 45.3877 200.543Z'
            fill='#242631'
          />
          <path
            d='M51.3877 200.543C51.3877 201.095 50.94 201.543 50.3877 201.543C49.8354 201.543 49.3877 201.095 49.3877 200.543C49.3877 199.991 49.8354 199.543 50.3877 199.543C50.94 199.543 51.3877 199.991 51.3877 200.543Z'
            fill='#242631'
          />
          <path
            d='M57.3877 200.543C57.3877 201.095 56.94 201.543 56.3877 201.543C55.8354 201.543 55.3877 201.095 55.3877 200.543C55.3877 199.991 55.8354 199.543 56.3877 199.543C56.94 199.543 57.3877 199.991 57.3877 200.543Z'
            fill='#242631'
          />
          <path
            d='M63.3877 200.543C63.3877 201.095 62.94 201.543 62.3877 201.543C61.8354 201.543 61.3877 201.095 61.3877 200.543C61.3877 199.991 61.8354 199.543 62.3877 199.543C62.94 199.543 63.3877 199.991 63.3877 200.543Z'
            fill='#242631'
          />
          <path
            d='M69.3877 200.543C69.3877 201.095 68.94 201.543 68.3877 201.543C67.8354 201.543 67.3877 201.095 67.3877 200.543C67.3877 199.991 67.8354 199.543 68.3877 199.543C68.94 199.543 69.3877 199.991 69.3877 200.543Z'
            fill='#242631'
          />
          <path
            d='M75.3877 200.543C75.3877 201.095 74.94 201.543 74.3877 201.543C73.8354 201.543 73.3877 201.095 73.3877 200.543C73.3877 199.991 73.8354 199.543 74.3877 199.543C74.94 199.543 75.3877 199.991 75.3877 200.543Z'
            fill='#242631'
          />
          <path
            d='M81.3877 200.543C81.3877 201.095 80.94 201.543 80.3877 201.543C79.8354 201.543 79.3877 201.095 79.3877 200.543C79.3877 199.991 79.8354 199.543 80.3877 199.543C80.94 199.543 81.3877 199.991 81.3877 200.543Z'
            fill='#242631'
          />
          <path
            d='M87.3877 200.543C87.3877 201.095 86.94 201.543 86.3877 201.543C85.8354 201.543 85.3877 201.095 85.3877 200.543C85.3877 199.991 85.8354 199.543 86.3877 199.543C86.94 199.543 87.3877 199.991 87.3877 200.543Z'
            fill='#242631'
          />
          <path
            d='M93.3877 200.543C93.3877 201.095 92.94 201.543 92.3877 201.543C91.8354 201.543 91.3877 201.095 91.3877 200.543C91.3877 199.991 91.8354 199.543 92.3877 199.543C92.94 199.543 93.3877 199.991 93.3877 200.543Z'
            fill='#242631'
          />
          <path
            d='M99.3877 200.543C99.3877 201.095 98.94 201.543 98.3877 201.543C97.8354 201.543 97.3877 201.095 97.3877 200.543C97.3877 199.991 97.8354 199.543 98.3877 199.543C98.94 199.543 99.3877 199.991 99.3877 200.543Z'
            fill='#242631'
          />
          <path
            d='M105.388 200.543C105.388 201.095 104.94 201.543 104.388 201.543C103.835 201.543 103.388 201.095 103.388 200.543C103.388 199.991 103.835 199.543 104.388 199.543C104.94 199.543 105.388 199.991 105.388 200.543Z'
            fill='#242631'
          />
          <path
            d='M111.388 200.543C111.388 201.095 110.94 201.543 110.388 201.543C109.835 201.543 109.388 201.095 109.388 200.543C109.388 199.991 109.835 199.543 110.388 199.543C110.94 199.543 111.388 199.991 111.388 200.543Z'
            fill='#242631'
          />
          <path
            d='M117.388 200.543C117.388 201.095 116.94 201.543 116.388 201.543C115.835 201.543 115.388 201.095 115.388 200.543C115.388 199.991 115.835 199.543 116.388 199.543C116.94 199.543 117.388 199.991 117.388 200.543Z'
            fill='#242631'
          />
          <path
            d='M123.388 200.543C123.388 201.095 122.94 201.543 122.388 201.543C121.835 201.543 121.388 201.095 121.388 200.543C121.388 199.991 121.835 199.543 122.388 199.543C122.94 199.543 123.388 199.991 123.388 200.543Z'
            fill='#242631'
          />
          <path
            d='M129.388 200.543C129.388 201.095 128.94 201.543 128.388 201.543C127.835 201.543 127.388 201.095 127.388 200.543C127.388 199.991 127.835 199.543 128.388 199.543C128.94 199.543 129.388 199.991 129.388 200.543Z'
            fill='#242631'
          />
          <circle cx='134.388' cy='200.543' r='1' fill='#242631' />
          <circle cx='140.388' cy='200.543' r='1' fill='#242631' />
          <circle cx='146.388' cy='200.543' r='1' fill='#242631' />
          <circle cx='152.388' cy='200.543' r='1' fill='#242631' />
          <circle cx='158.388' cy='200.543' r='1' fill='#242631' />
          <circle cx='164.388' cy='200.543' r='1' fill='#242631' />
          <circle cx='170.388' cy='200.543' r='1' fill='#242631' />
          <circle cx='176.388' cy='200.543' r='1' fill='#242631' />
          <circle cx='182.388' cy='200.543' r='1' fill='#242631' />
          <circle cx='188.388' cy='200.543' r='1' fill='#242631' />
          <circle cx='194.388' cy='200.543' r='1' fill='#242631' />
          <circle cx='200.388' cy='200.543' r='1' fill='#242631' />
          <circle cx='2.3877' cy='105.238' r='1' fill='#242631' />
          <circle cx='8.3877' cy='105.238' r='1' fill='#242631' />
          <path
            d='M15.3877 105.238C15.3877 105.791 14.94 106.238 14.3877 106.238C13.8354 106.238 13.3877 105.791 13.3877 105.238C13.3877 104.686 13.8354 104.238 14.3877 104.238C14.94 104.238 15.3877 104.686 15.3877 105.238Z'
            fill='#242631'
          />
          <path
            d='M21.3877 105.238C21.3877 105.791 20.94 106.238 20.3877 106.238C19.8354 106.238 19.3877 105.791 19.3877 105.238C19.3877 104.686 19.8354 104.238 20.3877 104.238C20.94 104.238 21.3877 104.686 21.3877 105.238Z'
            fill='#242631'
          />
          <path
            d='M27.3877 105.238C27.3877 105.791 26.94 106.238 26.3877 106.238C25.8354 106.238 25.3877 105.791 25.3877 105.238C25.3877 104.686 25.8354 104.238 26.3877 104.238C26.94 104.238 27.3877 104.686 27.3877 105.238Z'
            fill='#242631'
          />
          <path
            d='M33.3877 105.238C33.3877 105.791 32.94 106.238 32.3877 106.238C31.8354 106.238 31.3877 105.791 31.3877 105.238C31.3877 104.686 31.8354 104.238 32.3877 104.238C32.94 104.238 33.3877 104.686 33.3877 105.238Z'
            fill='#242631'
          />
          <path
            d='M39.3877 105.238C39.3877 105.791 38.94 106.238 38.3877 106.238C37.8354 106.238 37.3877 105.791 37.3877 105.238C37.3877 104.686 37.8354 104.238 38.3877 104.238C38.94 104.238 39.3877 104.686 39.3877 105.238Z'
            fill='#242631'
          />
          <path
            d='M45.3877 105.238C45.3877 105.791 44.94 106.238 44.3877 106.238C43.8354 106.238 43.3877 105.791 43.3877 105.238C43.3877 104.686 43.8354 104.238 44.3877 104.238C44.94 104.238 45.3877 104.686 45.3877 105.238Z'
            fill='#242631'
          />
          <path
            d='M51.3877 105.238C51.3877 105.791 50.94 106.238 50.3877 106.238C49.8354 106.238 49.3877 105.791 49.3877 105.238C49.3877 104.686 49.8354 104.238 50.3877 104.238C50.94 104.238 51.3877 104.686 51.3877 105.238Z'
            fill='#242631'
          />
          <path
            d='M57.3877 105.238C57.3877 105.791 56.94 106.238 56.3877 106.238C55.8354 106.238 55.3877 105.791 55.3877 105.238C55.3877 104.686 55.8354 104.238 56.3877 104.238C56.94 104.238 57.3877 104.686 57.3877 105.238Z'
            fill='#242631'
          />
          <path
            d='M63.3877 105.238C63.3877 105.791 62.94 106.238 62.3877 106.238C61.8354 106.238 61.3877 105.791 61.3877 105.238C61.3877 104.686 61.8354 104.238 62.3877 104.238C62.94 104.238 63.3877 104.686 63.3877 105.238Z'
            fill='#242631'
          />
          <path
            d='M69.3877 105.238C69.3877 105.791 68.94 106.238 68.3877 106.238C67.8354 106.238 67.3877 105.791 67.3877 105.238C67.3877 104.686 67.8354 104.238 68.3877 104.238C68.94 104.238 69.3877 104.686 69.3877 105.238Z'
            fill='#242631'
          />
          <path
            d='M75.3877 105.238C75.3877 105.791 74.94 106.238 74.3877 106.238C73.8354 106.238 73.3877 105.791 73.3877 105.238C73.3877 104.686 73.8354 104.238 74.3877 104.238C74.94 104.238 75.3877 104.686 75.3877 105.238Z'
            fill='#242631'
          />
          <path
            d='M81.3877 105.238C81.3877 105.791 80.94 106.238 80.3877 106.238C79.8354 106.238 79.3877 105.791 79.3877 105.238C79.3877 104.686 79.8354 104.238 80.3877 104.238C80.94 104.238 81.3877 104.686 81.3877 105.238Z'
            fill='#242631'
          />
          <path
            d='M87.3877 105.238C87.3877 105.791 86.94 106.238 86.3877 106.238C85.8354 106.238 85.3877 105.791 85.3877 105.238C85.3877 104.686 85.8354 104.238 86.3877 104.238C86.94 104.238 87.3877 104.686 87.3877 105.238Z'
            fill='#242631'
          />
          <path
            d='M93.3877 105.238C93.3877 105.791 92.94 106.238 92.3877 106.238C91.8354 106.238 91.3877 105.791 91.3877 105.238C91.3877 104.686 91.8354 104.238 92.3877 104.238C92.94 104.238 93.3877 104.686 93.3877 105.238Z'
            fill='#242631'
          />
          <path
            d='M99.3877 105.238C99.3877 105.791 98.94 106.238 98.3877 106.238C97.8354 106.238 97.3877 105.791 97.3877 105.238C97.3877 104.686 97.8354 104.238 98.3877 104.238C98.94 104.238 99.3877 104.686 99.3877 105.238Z'
            fill='#242631'
          />
          <path
            d='M105.388 105.238C105.388 105.791 104.94 106.238 104.388 106.238C103.835 106.238 103.388 105.791 103.388 105.238C103.388 104.686 103.835 104.238 104.388 104.238C104.94 104.238 105.388 104.686 105.388 105.238Z'
            fill='#242631'
          />
          <path
            d='M111.388 105.238C111.388 105.791 110.94 106.238 110.388 106.238C109.835 106.238 109.388 105.791 109.388 105.238C109.388 104.686 109.835 104.238 110.388 104.238C110.94 104.238 111.388 104.686 111.388 105.238Z'
            fill='#242631'
          />
          <path
            d='M117.388 105.238C117.388 105.791 116.94 106.238 116.388 106.238C115.835 106.238 115.388 105.791 115.388 105.238C115.388 104.686 115.835 104.238 116.388 104.238C116.94 104.238 117.388 104.686 117.388 105.238Z'
            fill='#242631'
          />
          <path
            d='M123.388 105.238C123.388 105.791 122.94 106.238 122.388 106.238C121.835 106.238 121.388 105.791 121.388 105.238C121.388 104.686 121.835 104.238 122.388 104.238C122.94 104.238 123.388 104.686 123.388 105.238Z'
            fill='#242631'
          />
          <path
            d='M129.388 105.238C129.388 105.791 128.94 106.238 128.388 106.238C127.835 106.238 127.388 105.791 127.388 105.238C127.388 104.686 127.835 104.238 128.388 104.238C128.94 104.238 129.388 104.686 129.388 105.238Z'
            fill='#242631'
          />
          <circle cx='134.388' cy='105.238' r='1' fill='#242631' />
          <circle cx='140.388' cy='105.238' r='1' fill='#242631' />
          <circle cx='146.388' cy='105.238' r='1' fill='#242631' />
          <circle cx='152.388' cy='105.238' r='1' fill='#242631' />
          <circle cx='158.388' cy='105.238' r='1' fill='#242631' />
          <circle cx='164.388' cy='105.238' r='1' fill='#242631' />
          <circle cx='170.388' cy='105.238' r='1' fill='#242631' />
          <circle cx='176.388' cy='105.238' r='1' fill='#242631' />
          <circle cx='182.388' cy='105.238' r='1' fill='#242631' />
          <circle cx='188.388' cy='105.238' r='1' fill='#242631' />
          <circle cx='194.388' cy='105.238' r='1' fill='#242631' />
          <circle cx='200.388' cy='105.238' r='1' fill='#242631' />
          <circle cx='2.3877' cy='21.8477' r='1' fill='#242631' />
          <circle cx='8.3877' cy='21.8477' r='1' fill='#242631' />
          <path
            d='M15.3877 21.8477C15.3877 22.3999 14.94 22.8477 14.3877 22.8477C13.8354 22.8477 13.3877 22.3999 13.3877 21.8477C13.3877 21.2954 13.8354 20.8477 14.3877 20.8477C14.94 20.8477 15.3877 21.2954 15.3877 21.8477Z'
            fill='#242631'
          />
          <path
            d='M21.3877 21.8477C21.3877 22.3999 20.94 22.8477 20.3877 22.8477C19.8354 22.8477 19.3877 22.3999 19.3877 21.8477C19.3877 21.2954 19.8354 20.8477 20.3877 20.8477C20.94 20.8477 21.3877 21.2954 21.3877 21.8477Z'
            fill='#242631'
          />
          <path
            d='M27.3877 21.8477C27.3877 22.3999 26.94 22.8477 26.3877 22.8477C25.8354 22.8477 25.3877 22.3999 25.3877 21.8477C25.3877 21.2954 25.8354 20.8477 26.3877 20.8477C26.94 20.8477 27.3877 21.2954 27.3877 21.8477Z'
            fill='#242631'
          />
          <path
            d='M33.3877 21.8477C33.3877 22.3999 32.94 22.8477 32.3877 22.8477C31.8354 22.8477 31.3877 22.3999 31.3877 21.8477C31.3877 21.2954 31.8354 20.8477 32.3877 20.8477C32.94 20.8477 33.3877 21.2954 33.3877 21.8477Z'
            fill='#242631'
          />
          <path
            d='M39.3877 21.8477C39.3877 22.3999 38.94 22.8477 38.3877 22.8477C37.8354 22.8477 37.3877 22.3999 37.3877 21.8477C37.3877 21.2954 37.8354 20.8477 38.3877 20.8477C38.94 20.8477 39.3877 21.2954 39.3877 21.8477Z'
            fill='#242631'
          />
          <path
            d='M45.3877 21.8477C45.3877 22.3999 44.94 22.8477 44.3877 22.8477C43.8354 22.8477 43.3877 22.3999 43.3877 21.8477C43.3877 21.2954 43.8354 20.8477 44.3877 20.8477C44.94 20.8477 45.3877 21.2954 45.3877 21.8477Z'
            fill='#242631'
          />
          <path
            d='M51.3877 21.8477C51.3877 22.3999 50.94 22.8477 50.3877 22.8477C49.8354 22.8477 49.3877 22.3999 49.3877 21.8477C49.3877 21.2954 49.8354 20.8477 50.3877 20.8477C50.94 20.8477 51.3877 21.2954 51.3877 21.8477Z'
            fill='#242631'
          />
          <path
            d='M57.3877 21.8477C57.3877 22.3999 56.94 22.8477 56.3877 22.8477C55.8354 22.8477 55.3877 22.3999 55.3877 21.8477C55.3877 21.2954 55.8354 20.8477 56.3877 20.8477C56.94 20.8477 57.3877 21.2954 57.3877 21.8477Z'
            fill='#242631'
          />
          <path
            d='M63.3877 21.8477C63.3877 22.3999 62.94 22.8477 62.3877 22.8477C61.8354 22.8477 61.3877 22.3999 61.3877 21.8477C61.3877 21.2954 61.8354 20.8477 62.3877 20.8477C62.94 20.8477 63.3877 21.2954 63.3877 21.8477Z'
            fill='#242631'
          />
          <path
            d='M69.3877 21.8477C69.3877 22.3999 68.94 22.8477 68.3877 22.8477C67.8354 22.8477 67.3877 22.3999 67.3877 21.8477C67.3877 21.2954 67.8354 20.8477 68.3877 20.8477C68.94 20.8477 69.3877 21.2954 69.3877 21.8477Z'
            fill='#242631'
          />
          <path
            d='M75.3877 21.8477C75.3877 22.3999 74.94 22.8477 74.3877 22.8477C73.8354 22.8477 73.3877 22.3999 73.3877 21.8477C73.3877 21.2954 73.8354 20.8477 74.3877 20.8477C74.94 20.8477 75.3877 21.2954 75.3877 21.8477Z'
            fill='#242631'
          />
          <path
            d='M81.3877 21.8477C81.3877 22.3999 80.94 22.8477 80.3877 22.8477C79.8354 22.8477 79.3877 22.3999 79.3877 21.8477C79.3877 21.2954 79.8354 20.8477 80.3877 20.8477C80.94 20.8477 81.3877 21.2954 81.3877 21.8477Z'
            fill='#242631'
          />
          <path
            d='M87.3877 21.8477C87.3877 22.3999 86.94 22.8477 86.3877 22.8477C85.8354 22.8477 85.3877 22.3999 85.3877 21.8477C85.3877 21.2954 85.8354 20.8477 86.3877 20.8477C86.94 20.8477 87.3877 21.2954 87.3877 21.8477Z'
            fill='#242631'
          />
          <path
            d='M93.3877 21.8477C93.3877 22.3999 92.94 22.8477 92.3877 22.8477C91.8354 22.8477 91.3877 22.3999 91.3877 21.8477C91.3877 21.2954 91.8354 20.8477 92.3877 20.8477C92.94 20.8477 93.3877 21.2954 93.3877 21.8477Z'
            fill='#242631'
          />
          <path
            d='M99.3877 21.8477C99.3877 22.3999 98.94 22.8477 98.3877 22.8477C97.8354 22.8477 97.3877 22.3999 97.3877 21.8477C97.3877 21.2954 97.8354 20.8477 98.3877 20.8477C98.94 20.8477 99.3877 21.2954 99.3877 21.8477Z'
            fill='#242631'
          />
          <path
            d='M105.388 21.8477C105.388 22.3999 104.94 22.8477 104.388 22.8477C103.835 22.8477 103.388 22.3999 103.388 21.8477C103.388 21.2954 103.835 20.8477 104.388 20.8477C104.94 20.8477 105.388 21.2954 105.388 21.8477Z'
            fill='#242631'
          />
          <path
            d='M111.388 21.8477C111.388 22.3999 110.94 22.8477 110.388 22.8477C109.835 22.8477 109.388 22.3999 109.388 21.8477C109.388 21.2954 109.835 20.8477 110.388 20.8477C110.94 20.8477 111.388 21.2954 111.388 21.8477Z'
            fill='#242631'
          />
          <path
            d='M117.388 21.8477C117.388 22.3999 116.94 22.8477 116.388 22.8477C115.835 22.8477 115.388 22.3999 115.388 21.8477C115.388 21.2954 115.835 20.8477 116.388 20.8477C116.94 20.8477 117.388 21.2954 117.388 21.8477Z'
            fill='#242631'
          />
          <path
            d='M123.388 21.8477C123.388 22.3999 122.94 22.8477 122.388 22.8477C121.835 22.8477 121.388 22.3999 121.388 21.8477C121.388 21.2954 121.835 20.8477 122.388 20.8477C122.94 20.8477 123.388 21.2954 123.388 21.8477Z'
            fill='#242631'
          />
          <path
            d='M129.388 21.8477C129.388 22.3999 128.94 22.8477 128.388 22.8477C127.835 22.8477 127.388 22.3999 127.388 21.8477C127.388 21.2954 127.835 20.8477 128.388 20.8477C128.94 20.8477 129.388 21.2954 129.388 21.8477Z'
            fill='#242631'
          />
          <circle cx='134.388' cy='21.8477' r='1' fill='#242631' />
          <circle cx='140.388' cy='21.8477' r='1' fill='#242631' />
          <circle cx='146.388' cy='21.8477' r='1' fill='#242631' />
          <circle cx='152.388' cy='21.8477' r='1' fill='#242631' />
          <circle cx='158.388' cy='21.8477' r='1' fill='#242631' />
          <circle cx='164.388' cy='21.8477' r='1' fill='#242631' />
          <circle cx='170.388' cy='21.8477' r='1' fill='#242631' />
          <circle cx='176.388' cy='21.8477' r='1' fill='#242631' />
          <circle cx='182.388' cy='21.8477' r='1' fill='#242631' />
          <circle cx='188.388' cy='21.8477' r='1' fill='#242631' />
          <circle cx='194.388' cy='21.8477' r='1' fill='#242631' />
          <circle cx='200.388' cy='21.8477' r='1' fill='#242631' />
          <circle cx='2.3877' cy='140.977' r='1' fill='#242631' />
          <circle cx='8.3877' cy='140.977' r='1' fill='#242631' />
          <path
            d='M15.3877 140.977C15.3877 141.529 14.94 141.977 14.3877 141.977C13.8354 141.977 13.3877 141.529 13.3877 140.977C13.3877 140.424 13.8354 139.977 14.3877 139.977C14.94 139.977 15.3877 140.424 15.3877 140.977Z'
            fill='#242631'
          />
          <path
            d='M21.3877 140.977C21.3877 141.529 20.94 141.977 20.3877 141.977C19.8354 141.977 19.3877 141.529 19.3877 140.977C19.3877 140.424 19.8354 139.977 20.3877 139.977C20.94 139.977 21.3877 140.424 21.3877 140.977Z'
            fill='#242631'
          />
          <path
            d='M27.3877 140.977C27.3877 141.529 26.94 141.977 26.3877 141.977C25.8354 141.977 25.3877 141.529 25.3877 140.977C25.3877 140.424 25.8354 139.977 26.3877 139.977C26.94 139.977 27.3877 140.424 27.3877 140.977Z'
            fill='#242631'
          />
          <path
            d='M33.3877 140.977C33.3877 141.529 32.94 141.977 32.3877 141.977C31.8354 141.977 31.3877 141.529 31.3877 140.977C31.3877 140.424 31.8354 139.977 32.3877 139.977C32.94 139.977 33.3877 140.424 33.3877 140.977Z'
            fill='#242631'
          />
          <path
            d='M39.3877 140.977C39.3877 141.529 38.94 141.977 38.3877 141.977C37.8354 141.977 37.3877 141.529 37.3877 140.977C37.3877 140.424 37.8354 139.977 38.3877 139.977C38.94 139.977 39.3877 140.424 39.3877 140.977Z'
            fill='#242631'
          />
          <path
            d='M45.3877 140.977C45.3877 141.529 44.94 141.977 44.3877 141.977C43.8354 141.977 43.3877 141.529 43.3877 140.977C43.3877 140.424 43.8354 139.977 44.3877 139.977C44.94 139.977 45.3877 140.424 45.3877 140.977Z'
            fill='#242631'
          />
          <path
            d='M51.3877 140.977C51.3877 141.529 50.94 141.977 50.3877 141.977C49.8354 141.977 49.3877 141.529 49.3877 140.977C49.3877 140.424 49.8354 139.977 50.3877 139.977C50.94 139.977 51.3877 140.424 51.3877 140.977Z'
            fill='#242631'
          />
          <path
            d='M57.3877 140.977C57.3877 141.529 56.94 141.977 56.3877 141.977C55.8354 141.977 55.3877 141.529 55.3877 140.977C55.3877 140.424 55.8354 139.977 56.3877 139.977C56.94 139.977 57.3877 140.424 57.3877 140.977Z'
            fill='#242631'
          />
          <path
            d='M63.3877 140.977C63.3877 141.529 62.94 141.977 62.3877 141.977C61.8354 141.977 61.3877 141.529 61.3877 140.977C61.3877 140.424 61.8354 139.977 62.3877 139.977C62.94 139.977 63.3877 140.424 63.3877 140.977Z'
            fill='#242631'
          />
          <path
            d='M69.3877 140.977C69.3877 141.529 68.94 141.977 68.3877 141.977C67.8354 141.977 67.3877 141.529 67.3877 140.977C67.3877 140.424 67.8354 139.977 68.3877 139.977C68.94 139.977 69.3877 140.424 69.3877 140.977Z'
            fill='#242631'
          />
          <path
            d='M75.3877 140.977C75.3877 141.529 74.94 141.977 74.3877 141.977C73.8354 141.977 73.3877 141.529 73.3877 140.977C73.3877 140.424 73.8354 139.977 74.3877 139.977C74.94 139.977 75.3877 140.424 75.3877 140.977Z'
            fill='#242631'
          />
          <path
            d='M81.3877 140.977C81.3877 141.529 80.94 141.977 80.3877 141.977C79.8354 141.977 79.3877 141.529 79.3877 140.977C79.3877 140.424 79.8354 139.977 80.3877 139.977C80.94 139.977 81.3877 140.424 81.3877 140.977Z'
            fill='#242631'
          />
          <path
            d='M87.3877 140.977C87.3877 141.529 86.94 141.977 86.3877 141.977C85.8354 141.977 85.3877 141.529 85.3877 140.977C85.3877 140.424 85.8354 139.977 86.3877 139.977C86.94 139.977 87.3877 140.424 87.3877 140.977Z'
            fill='#242631'
          />
          <path
            d='M93.3877 140.977C93.3877 141.529 92.94 141.977 92.3877 141.977C91.8354 141.977 91.3877 141.529 91.3877 140.977C91.3877 140.424 91.8354 139.977 92.3877 139.977C92.94 139.977 93.3877 140.424 93.3877 140.977Z'
            fill='#242631'
          />
          <path
            d='M99.3877 140.977C99.3877 141.529 98.94 141.977 98.3877 141.977C97.8354 141.977 97.3877 141.529 97.3877 140.977C97.3877 140.424 97.8354 139.977 98.3877 139.977C98.94 139.977 99.3877 140.424 99.3877 140.977Z'
            fill='#242631'
          />
          <path
            d='M105.388 140.977C105.388 141.529 104.94 141.977 104.388 141.977C103.835 141.977 103.388 141.529 103.388 140.977C103.388 140.424 103.835 139.977 104.388 139.977C104.94 139.977 105.388 140.424 105.388 140.977Z'
            fill='#242631'
          />
          <path
            d='M111.388 140.977C111.388 141.529 110.94 141.977 110.388 141.977C109.835 141.977 109.388 141.529 109.388 140.977C109.388 140.424 109.835 139.977 110.388 139.977C110.94 139.977 111.388 140.424 111.388 140.977Z'
            fill='#242631'
          />
          <path
            d='M117.388 140.977C117.388 141.529 116.94 141.977 116.388 141.977C115.835 141.977 115.388 141.529 115.388 140.977C115.388 140.424 115.835 139.977 116.388 139.977C116.94 139.977 117.388 140.424 117.388 140.977Z'
            fill='#242631'
          />
          <path
            d='M123.388 140.977C123.388 141.529 122.94 141.977 122.388 141.977C121.835 141.977 121.388 141.529 121.388 140.977C121.388 140.424 121.835 139.977 122.388 139.977C122.94 139.977 123.388 140.424 123.388 140.977Z'
            fill='#242631'
          />
          <path
            d='M129.388 140.977C129.388 141.529 128.94 141.977 128.388 141.977C127.835 141.977 127.388 141.529 127.388 140.977C127.388 140.424 127.835 139.977 128.388 139.977C128.94 139.977 129.388 140.424 129.388 140.977Z'
            fill='#242631'
          />
          <circle cx='134.388' cy='140.977' r='1' fill='#242631' />
          <circle cx='140.388' cy='140.977' r='1' fill='#242631' />
          <circle cx='146.388' cy='140.977' r='1' fill='#242631' />
          <circle cx='152.388' cy='140.977' r='1' fill='#242631' />
          <circle cx='158.388' cy='140.977' r='1' fill='#242631' />
          <circle cx='164.388' cy='140.977' r='1' fill='#242631' />
          <circle cx='170.388' cy='140.977' r='1' fill='#242631' />
          <circle cx='176.388' cy='140.977' r='1' fill='#242631' />
          <circle cx='182.388' cy='140.977' r='1' fill='#242631' />
          <circle cx='188.388' cy='140.977' r='1' fill='#242631' />
          <circle cx='194.388' cy='140.977' r='1' fill='#242631' />
          <circle cx='200.388' cy='140.977' r='1' fill='#242631' />
          <circle cx='2.3877' cy='45.6738' r='1' fill='#242631' />
          <circle cx='8.3877' cy='45.6738' r='1' fill='#242631' />
          <path
            d='M15.3877 45.6738C15.3877 46.2261 14.94 46.6738 14.3877 46.6738C13.8354 46.6738 13.3877 46.2261 13.3877 45.6738C13.3877 45.1215 13.8354 44.6738 14.3877 44.6738C14.94 44.6738 15.3877 45.1215 15.3877 45.6738Z'
            fill='#242631'
          />
          <path
            d='M21.3877 45.6738C21.3877 46.2261 20.94 46.6738 20.3877 46.6738C19.8354 46.6738 19.3877 46.2261 19.3877 45.6738C19.3877 45.1215 19.8354 44.6738 20.3877 44.6738C20.94 44.6738 21.3877 45.1215 21.3877 45.6738Z'
            fill='#242631'
          />
          <path
            d='M27.3877 45.6738C27.3877 46.2261 26.94 46.6738 26.3877 46.6738C25.8354 46.6738 25.3877 46.2261 25.3877 45.6738C25.3877 45.1215 25.8354 44.6738 26.3877 44.6738C26.94 44.6738 27.3877 45.1215 27.3877 45.6738Z'
            fill='#242631'
          />
          <path
            d='M33.3877 45.6738C33.3877 46.2261 32.94 46.6738 32.3877 46.6738C31.8354 46.6738 31.3877 46.2261 31.3877 45.6738C31.3877 45.1215 31.8354 44.6738 32.3877 44.6738C32.94 44.6738 33.3877 45.1215 33.3877 45.6738Z'
            fill='#242631'
          />
          <path
            d='M39.3877 45.6738C39.3877 46.2261 38.94 46.6738 38.3877 46.6738C37.8354 46.6738 37.3877 46.2261 37.3877 45.6738C37.3877 45.1215 37.8354 44.6738 38.3877 44.6738C38.94 44.6738 39.3877 45.1215 39.3877 45.6738Z'
            fill='#242631'
          />
          <path
            d='M45.3877 45.6738C45.3877 46.2261 44.94 46.6738 44.3877 46.6738C43.8354 46.6738 43.3877 46.2261 43.3877 45.6738C43.3877 45.1215 43.8354 44.6738 44.3877 44.6738C44.94 44.6738 45.3877 45.1215 45.3877 45.6738Z'
            fill='#242631'
          />
          <path
            d='M51.3877 45.6738C51.3877 46.2261 50.94 46.6738 50.3877 46.6738C49.8354 46.6738 49.3877 46.2261 49.3877 45.6738C49.3877 45.1215 49.8354 44.6738 50.3877 44.6738C50.94 44.6738 51.3877 45.1215 51.3877 45.6738Z'
            fill='#242631'
          />
          <path
            d='M57.3877 45.6738C57.3877 46.2261 56.94 46.6738 56.3877 46.6738C55.8354 46.6738 55.3877 46.2261 55.3877 45.6738C55.3877 45.1215 55.8354 44.6738 56.3877 44.6738C56.94 44.6738 57.3877 45.1215 57.3877 45.6738Z'
            fill='#242631'
          />
          <path
            d='M63.3877 45.6738C63.3877 46.2261 62.94 46.6738 62.3877 46.6738C61.8354 46.6738 61.3877 46.2261 61.3877 45.6738C61.3877 45.1215 61.8354 44.6738 62.3877 44.6738C62.94 44.6738 63.3877 45.1215 63.3877 45.6738Z'
            fill='#242631'
          />
          <path
            d='M69.3877 45.6738C69.3877 46.2261 68.94 46.6738 68.3877 46.6738C67.8354 46.6738 67.3877 46.2261 67.3877 45.6738C67.3877 45.1215 67.8354 44.6738 68.3877 44.6738C68.94 44.6738 69.3877 45.1215 69.3877 45.6738Z'
            fill='#242631'
          />
          <path
            d='M75.3877 45.6738C75.3877 46.2261 74.94 46.6738 74.3877 46.6738C73.8354 46.6738 73.3877 46.2261 73.3877 45.6738C73.3877 45.1215 73.8354 44.6738 74.3877 44.6738C74.94 44.6738 75.3877 45.1215 75.3877 45.6738Z'
            fill='#242631'
          />
          <path
            d='M81.3877 45.6738C81.3877 46.2261 80.94 46.6738 80.3877 46.6738C79.8354 46.6738 79.3877 46.2261 79.3877 45.6738C79.3877 45.1215 79.8354 44.6738 80.3877 44.6738C80.94 44.6738 81.3877 45.1215 81.3877 45.6738Z'
            fill='#242631'
          />
          <path
            d='M87.3877 45.6738C87.3877 46.2261 86.94 46.6738 86.3877 46.6738C85.8354 46.6738 85.3877 46.2261 85.3877 45.6738C85.3877 45.1215 85.8354 44.6738 86.3877 44.6738C86.94 44.6738 87.3877 45.1215 87.3877 45.6738Z'
            fill='#242631'
          />
          <path
            d='M93.3877 45.6738C93.3877 46.2261 92.94 46.6738 92.3877 46.6738C91.8354 46.6738 91.3877 46.2261 91.3877 45.6738C91.3877 45.1215 91.8354 44.6738 92.3877 44.6738C92.94 44.6738 93.3877 45.1215 93.3877 45.6738Z'
            fill='#242631'
          />
          <path
            d='M99.3877 45.6738C99.3877 46.2261 98.94 46.6738 98.3877 46.6738C97.8354 46.6738 97.3877 46.2261 97.3877 45.6738C97.3877 45.1215 97.8354 44.6738 98.3877 44.6738C98.94 44.6738 99.3877 45.1215 99.3877 45.6738Z'
            fill='#242631'
          />
          <path
            d='M105.388 45.6738C105.388 46.2261 104.94 46.6738 104.388 46.6738C103.835 46.6738 103.388 46.2261 103.388 45.6738C103.388 45.1215 103.835 44.6738 104.388 44.6738C104.94 44.6738 105.388 45.1215 105.388 45.6738Z'
            fill='#242631'
          />
          <path
            d='M111.388 45.6738C111.388 46.2261 110.94 46.6738 110.388 46.6738C109.835 46.6738 109.388 46.2261 109.388 45.6738C109.388 45.1215 109.835 44.6738 110.388 44.6738C110.94 44.6738 111.388 45.1215 111.388 45.6738Z'
            fill='#242631'
          />
          <path
            d='M117.388 45.6738C117.388 46.2261 116.94 46.6738 116.388 46.6738C115.835 46.6738 115.388 46.2261 115.388 45.6738C115.388 45.1215 115.835 44.6738 116.388 44.6738C116.94 44.6738 117.388 45.1215 117.388 45.6738Z'
            fill='#242631'
          />
          <path
            d='M123.388 45.6738C123.388 46.2261 122.94 46.6738 122.388 46.6738C121.835 46.6738 121.388 46.2261 121.388 45.6738C121.388 45.1215 121.835 44.6738 122.388 44.6738C122.94 44.6738 123.388 45.1215 123.388 45.6738Z'
            fill='#242631'
          />
          <path
            d='M129.388 45.6738C129.388 46.2261 128.94 46.6738 128.388 46.6738C127.835 46.6738 127.388 46.2261 127.388 45.6738C127.388 45.1215 127.835 44.6738 128.388 44.6738C128.94 44.6738 129.388 45.1215 129.388 45.6738Z'
            fill='#242631'
          />
          <circle cx='134.388' cy='45.6738' r='1' fill='#242631' />
          <circle cx='140.388' cy='45.6738' r='1' fill='#242631' />
          <circle cx='146.388' cy='45.6738' r='1' fill='#242631' />
          <circle cx='152.388' cy='45.6738' r='1' fill='#242631' />
          <circle cx='158.388' cy='45.6738' r='1' fill='#242631' />
          <circle cx='164.388' cy='45.6738' r='1' fill='#242631' />
          <circle cx='170.388' cy='45.6738' r='1' fill='#242631' />
          <circle cx='176.388' cy='45.6738' r='1' fill='#242631' />
          <circle cx='182.388' cy='45.6738' r='1' fill='#242631' />
          <circle cx='188.388' cy='45.6738' r='1' fill='#242631' />
          <circle cx='194.388' cy='45.6738' r='1' fill='#242631' />
          <circle cx='200.388' cy='45.6738' r='1' fill='#242631' />
          <circle cx='2.3877' cy='164.803' r='1' fill='#242631' />
          <circle cx='8.3877' cy='164.803' r='1' fill='#242631' />
          <path
            d='M15.3877 164.803C15.3877 165.355 14.94 165.803 14.3877 165.803C13.8354 165.803 13.3877 165.355 13.3877 164.803C13.3877 164.25 13.8354 163.803 14.3877 163.803C14.94 163.803 15.3877 164.25 15.3877 164.803Z'
            fill='#242631'
          />
          <path
            d='M21.3877 164.803C21.3877 165.355 20.94 165.803 20.3877 165.803C19.8354 165.803 19.3877 165.355 19.3877 164.803C19.3877 164.25 19.8354 163.803 20.3877 163.803C20.94 163.803 21.3877 164.25 21.3877 164.803Z'
            fill='#242631'
          />
          <path
            d='M27.3877 164.803C27.3877 165.355 26.94 165.803 26.3877 165.803C25.8354 165.803 25.3877 165.355 25.3877 164.803C25.3877 164.25 25.8354 163.803 26.3877 163.803C26.94 163.803 27.3877 164.25 27.3877 164.803Z'
            fill='#242631'
          />
          <path
            d='M33.3877 164.803C33.3877 165.355 32.94 165.803 32.3877 165.803C31.8354 165.803 31.3877 165.355 31.3877 164.803C31.3877 164.25 31.8354 163.803 32.3877 163.803C32.94 163.803 33.3877 164.25 33.3877 164.803Z'
            fill='#242631'
          />
          <path
            d='M39.3877 164.803C39.3877 165.355 38.94 165.803 38.3877 165.803C37.8354 165.803 37.3877 165.355 37.3877 164.803C37.3877 164.25 37.8354 163.803 38.3877 163.803C38.94 163.803 39.3877 164.25 39.3877 164.803Z'
            fill='#242631'
          />
          <path
            d='M45.3877 164.803C45.3877 165.355 44.94 165.803 44.3877 165.803C43.8354 165.803 43.3877 165.355 43.3877 164.803C43.3877 164.25 43.8354 163.803 44.3877 163.803C44.94 163.803 45.3877 164.25 45.3877 164.803Z'
            fill='#242631'
          />
          <path
            d='M51.3877 164.803C51.3877 165.355 50.94 165.803 50.3877 165.803C49.8354 165.803 49.3877 165.355 49.3877 164.803C49.3877 164.25 49.8354 163.803 50.3877 163.803C50.94 163.803 51.3877 164.25 51.3877 164.803Z'
            fill='#242631'
          />
          <path
            d='M57.3877 164.803C57.3877 165.355 56.94 165.803 56.3877 165.803C55.8354 165.803 55.3877 165.355 55.3877 164.803C55.3877 164.25 55.8354 163.803 56.3877 163.803C56.94 163.803 57.3877 164.25 57.3877 164.803Z'
            fill='#242631'
          />
          <path
            d='M63.3877 164.803C63.3877 165.355 62.94 165.803 62.3877 165.803C61.8354 165.803 61.3877 165.355 61.3877 164.803C61.3877 164.25 61.8354 163.803 62.3877 163.803C62.94 163.803 63.3877 164.25 63.3877 164.803Z'
            fill='#242631'
          />
          <path
            d='M69.3877 164.803C69.3877 165.355 68.94 165.803 68.3877 165.803C67.8354 165.803 67.3877 165.355 67.3877 164.803C67.3877 164.25 67.8354 163.803 68.3877 163.803C68.94 163.803 69.3877 164.25 69.3877 164.803Z'
            fill='#242631'
          />
          <path
            d='M75.3877 164.803C75.3877 165.355 74.94 165.803 74.3877 165.803C73.8354 165.803 73.3877 165.355 73.3877 164.803C73.3877 164.25 73.8354 163.803 74.3877 163.803C74.94 163.803 75.3877 164.25 75.3877 164.803Z'
            fill='#242631'
          />
          <path
            d='M81.3877 164.803C81.3877 165.355 80.94 165.803 80.3877 165.803C79.8354 165.803 79.3877 165.355 79.3877 164.803C79.3877 164.25 79.8354 163.803 80.3877 163.803C80.94 163.803 81.3877 164.25 81.3877 164.803Z'
            fill='#242631'
          />
          <path
            d='M87.3877 164.803C87.3877 165.355 86.94 165.803 86.3877 165.803C85.8354 165.803 85.3877 165.355 85.3877 164.803C85.3877 164.25 85.8354 163.803 86.3877 163.803C86.94 163.803 87.3877 164.25 87.3877 164.803Z'
            fill='#242631'
          />
          <path
            d='M93.3877 164.803C93.3877 165.355 92.94 165.803 92.3877 165.803C91.8354 165.803 91.3877 165.355 91.3877 164.803C91.3877 164.25 91.8354 163.803 92.3877 163.803C92.94 163.803 93.3877 164.25 93.3877 164.803Z'
            fill='#242631'
          />
          <path
            d='M99.3877 164.803C99.3877 165.355 98.94 165.803 98.3877 165.803C97.8354 165.803 97.3877 165.355 97.3877 164.803C97.3877 164.25 97.8354 163.803 98.3877 163.803C98.94 163.803 99.3877 164.25 99.3877 164.803Z'
            fill='#242631'
          />
          <path
            d='M105.388 164.803C105.388 165.355 104.94 165.803 104.388 165.803C103.835 165.803 103.388 165.355 103.388 164.803C103.388 164.25 103.835 163.803 104.388 163.803C104.94 163.803 105.388 164.25 105.388 164.803Z'
            fill='#242631'
          />
          <path
            d='M111.388 164.803C111.388 165.355 110.94 165.803 110.388 165.803C109.835 165.803 109.388 165.355 109.388 164.803C109.388 164.25 109.835 163.803 110.388 163.803C110.94 163.803 111.388 164.25 111.388 164.803Z'
            fill='#242631'
          />
          <path
            d='M117.388 164.803C117.388 165.355 116.94 165.803 116.388 165.803C115.835 165.803 115.388 165.355 115.388 164.803C115.388 164.25 115.835 163.803 116.388 163.803C116.94 163.803 117.388 164.25 117.388 164.803Z'
            fill='#242631'
          />
          <path
            d='M123.388 164.803C123.388 165.355 122.94 165.803 122.388 165.803C121.835 165.803 121.388 165.355 121.388 164.803C121.388 164.25 121.835 163.803 122.388 163.803C122.94 163.803 123.388 164.25 123.388 164.803Z'
            fill='#242631'
          />
          <path
            d='M129.388 164.803C129.388 165.355 128.94 165.803 128.388 165.803C127.835 165.803 127.388 165.355 127.388 164.803C127.388 164.25 127.835 163.803 128.388 163.803C128.94 163.803 129.388 164.25 129.388 164.803Z'
            fill='#242631'
          />
          <circle cx='134.388' cy='164.803' r='1' fill='#242631' />
          <circle cx='140.388' cy='164.803' r='1' fill='#242631' />
          <circle cx='146.388' cy='164.803' r='1' fill='#242631' />
          <circle cx='152.388' cy='164.803' r='1' fill='#242631' />
          <circle cx='158.388' cy='164.803' r='1' fill='#242631' />
          <circle cx='164.388' cy='164.803' r='1' fill='#242631' />
          <circle cx='170.388' cy='164.803' r='1' fill='#242631' />
          <circle cx='176.388' cy='164.803' r='1' fill='#242631' />
          <circle cx='182.388' cy='164.803' r='1' fill='#242631' />
          <circle cx='188.388' cy='164.803' r='1' fill='#242631' />
          <circle cx='194.388' cy='164.803' r='1' fill='#242631' />
          <circle cx='200.388' cy='164.803' r='1' fill='#242631' />
          <circle cx='2.3877' cy='69.5' r='1' fill='#242631' />
          <circle cx='8.3877' cy='69.5' r='1' fill='#242631' />
          <path
            d='M15.3877 69.5C15.3877 70.0523 14.94 70.5 14.3877 70.5C13.8354 70.5 13.3877 70.0523 13.3877 69.5C13.3877 68.9477 13.8354 68.5 14.3877 68.5C14.94 68.5 15.3877 68.9477 15.3877 69.5Z'
            fill='#242631'
          />
          <path
            d='M21.3877 69.5C21.3877 70.0523 20.94 70.5 20.3877 70.5C19.8354 70.5 19.3877 70.0523 19.3877 69.5C19.3877 68.9477 19.8354 68.5 20.3877 68.5C20.94 68.5 21.3877 68.9477 21.3877 69.5Z'
            fill='#242631'
          />
          <path
            d='M27.3877 69.5C27.3877 70.0523 26.94 70.5 26.3877 70.5C25.8354 70.5 25.3877 70.0523 25.3877 69.5C25.3877 68.9477 25.8354 68.5 26.3877 68.5C26.94 68.5 27.3877 68.9477 27.3877 69.5Z'
            fill='#242631'
          />
          <path
            d='M33.3877 69.5C33.3877 70.0523 32.94 70.5 32.3877 70.5C31.8354 70.5 31.3877 70.0523 31.3877 69.5C31.3877 68.9477 31.8354 68.5 32.3877 68.5C32.94 68.5 33.3877 68.9477 33.3877 69.5Z'
            fill='#242631'
          />
          <path
            d='M39.3877 69.5C39.3877 70.0523 38.94 70.5 38.3877 70.5C37.8354 70.5 37.3877 70.0523 37.3877 69.5C37.3877 68.9477 37.8354 68.5 38.3877 68.5C38.94 68.5 39.3877 68.9477 39.3877 69.5Z'
            fill='#242631'
          />
          <path
            d='M45.3877 69.5C45.3877 70.0523 44.94 70.5 44.3877 70.5C43.8354 70.5 43.3877 70.0523 43.3877 69.5C43.3877 68.9477 43.8354 68.5 44.3877 68.5C44.94 68.5 45.3877 68.9477 45.3877 69.5Z'
            fill='#242631'
          />
          <path
            d='M51.3877 69.5C51.3877 70.0523 50.94 70.5 50.3877 70.5C49.8354 70.5 49.3877 70.0523 49.3877 69.5C49.3877 68.9477 49.8354 68.5 50.3877 68.5C50.94 68.5 51.3877 68.9477 51.3877 69.5Z'
            fill='#242631'
          />
          <path
            d='M57.3877 69.5C57.3877 70.0523 56.94 70.5 56.3877 70.5C55.8354 70.5 55.3877 70.0523 55.3877 69.5C55.3877 68.9477 55.8354 68.5 56.3877 68.5C56.94 68.5 57.3877 68.9477 57.3877 69.5Z'
            fill='#242631'
          />
          <path
            d='M63.3877 69.5C63.3877 70.0523 62.94 70.5 62.3877 70.5C61.8354 70.5 61.3877 70.0523 61.3877 69.5C61.3877 68.9477 61.8354 68.5 62.3877 68.5C62.94 68.5 63.3877 68.9477 63.3877 69.5Z'
            fill='#242631'
          />
          <path
            d='M69.3877 69.5C69.3877 70.0523 68.94 70.5 68.3877 70.5C67.8354 70.5 67.3877 70.0523 67.3877 69.5C67.3877 68.9477 67.8354 68.5 68.3877 68.5C68.94 68.5 69.3877 68.9477 69.3877 69.5Z'
            fill='#242631'
          />
          <path
            d='M75.3877 69.5C75.3877 70.0523 74.94 70.5 74.3877 70.5C73.8354 70.5 73.3877 70.0523 73.3877 69.5C73.3877 68.9477 73.8354 68.5 74.3877 68.5C74.94 68.5 75.3877 68.9477 75.3877 69.5Z'
            fill='#242631'
          />
          <path
            d='M81.3877 69.5C81.3877 70.0523 80.94 70.5 80.3877 70.5C79.8354 70.5 79.3877 70.0523 79.3877 69.5C79.3877 68.9477 79.8354 68.5 80.3877 68.5C80.94 68.5 81.3877 68.9477 81.3877 69.5Z'
            fill='#242631'
          />
          <path
            d='M87.3877 69.5C87.3877 70.0523 86.94 70.5 86.3877 70.5C85.8354 70.5 85.3877 70.0523 85.3877 69.5C85.3877 68.9477 85.8354 68.5 86.3877 68.5C86.94 68.5 87.3877 68.9477 87.3877 69.5Z'
            fill='#242631'
          />
          <path
            d='M93.3877 69.5C93.3877 70.0523 92.94 70.5 92.3877 70.5C91.8354 70.5 91.3877 70.0523 91.3877 69.5C91.3877 68.9477 91.8354 68.5 92.3877 68.5C92.94 68.5 93.3877 68.9477 93.3877 69.5Z'
            fill='#242631'
          />
          <path
            d='M99.3877 69.5C99.3877 70.0523 98.94 70.5 98.3877 70.5C97.8354 70.5 97.3877 70.0523 97.3877 69.5C97.3877 68.9477 97.8354 68.5 98.3877 68.5C98.94 68.5 99.3877 68.9477 99.3877 69.5Z'
            fill='#242631'
          />
          <path
            d='M105.388 69.5C105.388 70.0523 104.94 70.5 104.388 70.5C103.835 70.5 103.388 70.0523 103.388 69.5C103.388 68.9477 103.835 68.5 104.388 68.5C104.94 68.5 105.388 68.9477 105.388 69.5Z'
            fill='#242631'
          />
          <path
            d='M111.388 69.5C111.388 70.0523 110.94 70.5 110.388 70.5C109.835 70.5 109.388 70.0523 109.388 69.5C109.388 68.9477 109.835 68.5 110.388 68.5C110.94 68.5 111.388 68.9477 111.388 69.5Z'
            fill='#242631'
          />
          <path
            d='M117.388 69.5C117.388 70.0523 116.94 70.5 116.388 70.5C115.835 70.5 115.388 70.0523 115.388 69.5C115.388 68.9477 115.835 68.5 116.388 68.5C116.94 68.5 117.388 68.9477 117.388 69.5Z'
            fill='#242631'
          />
          <path
            d='M123.388 69.5C123.388 70.0523 122.94 70.5 122.388 70.5C121.835 70.5 121.388 70.0523 121.388 69.5C121.388 68.9477 121.835 68.5 122.388 68.5C122.94 68.5 123.388 68.9477 123.388 69.5Z'
            fill='#242631'
          />
          <path
            d='M129.388 69.5C129.388 70.0523 128.94 70.5 128.388 70.5C127.835 70.5 127.388 70.0523 127.388 69.5C127.388 68.9477 127.835 68.5 128.388 68.5C128.94 68.5 129.388 68.9477 129.388 69.5Z'
            fill='#242631'
          />
          <circle cx='134.388' cy='69.5' r='1' fill='#242631' />
          <circle cx='140.388' cy='69.5' r='1' fill='#242631' />
          <circle cx='146.388' cy='69.5' r='1' fill='#242631' />
          <circle cx='152.388' cy='69.5' r='1' fill='#242631' />
          <circle cx='158.388' cy='69.5' r='1' fill='#242631' />
          <circle cx='164.388' cy='69.5' r='1' fill='#242631' />
          <circle cx='170.388' cy='69.5' r='1' fill='#242631' />
          <circle cx='176.388' cy='69.5' r='1' fill='#242631' />
          <circle cx='182.388' cy='69.5' r='1' fill='#242631' />
          <circle cx='188.388' cy='69.5' r='1' fill='#242631' />
          <circle cx='194.388' cy='69.5' r='1' fill='#242631' />
          <circle cx='200.388' cy='69.5' r='1' fill='#242631' />
          <circle cx='2.3877' cy='188.629' r='1' fill='#242631' />
          <circle cx='8.3877' cy='188.629' r='1' fill='#242631' />
          <path
            d='M15.3877 188.629C15.3877 189.181 14.94 189.629 14.3877 189.629C13.8354 189.629 13.3877 189.181 13.3877 188.629C13.3877 188.077 13.8354 187.629 14.3877 187.629C14.94 187.629 15.3877 188.077 15.3877 188.629Z'
            fill='#242631'
          />
          <path
            d='M21.3877 188.629C21.3877 189.181 20.94 189.629 20.3877 189.629C19.8354 189.629 19.3877 189.181 19.3877 188.629C19.3877 188.077 19.8354 187.629 20.3877 187.629C20.94 187.629 21.3877 188.077 21.3877 188.629Z'
            fill='#242631'
          />
          <path
            d='M27.3877 188.629C27.3877 189.181 26.94 189.629 26.3877 189.629C25.8354 189.629 25.3877 189.181 25.3877 188.629C25.3877 188.077 25.8354 187.629 26.3877 187.629C26.94 187.629 27.3877 188.077 27.3877 188.629Z'
            fill='#242631'
          />
          <path
            d='M33.3877 188.629C33.3877 189.181 32.94 189.629 32.3877 189.629C31.8354 189.629 31.3877 189.181 31.3877 188.629C31.3877 188.077 31.8354 187.629 32.3877 187.629C32.94 187.629 33.3877 188.077 33.3877 188.629Z'
            fill='#242631'
          />
          <path
            d='M39.3877 188.629C39.3877 189.181 38.94 189.629 38.3877 189.629C37.8354 189.629 37.3877 189.181 37.3877 188.629C37.3877 188.077 37.8354 187.629 38.3877 187.629C38.94 187.629 39.3877 188.077 39.3877 188.629Z'
            fill='#242631'
          />
          <path
            d='M45.3877 188.629C45.3877 189.181 44.94 189.629 44.3877 189.629C43.8354 189.629 43.3877 189.181 43.3877 188.629C43.3877 188.077 43.8354 187.629 44.3877 187.629C44.94 187.629 45.3877 188.077 45.3877 188.629Z'
            fill='#242631'
          />
          <path
            d='M51.3877 188.629C51.3877 189.181 50.94 189.629 50.3877 189.629C49.8354 189.629 49.3877 189.181 49.3877 188.629C49.3877 188.077 49.8354 187.629 50.3877 187.629C50.94 187.629 51.3877 188.077 51.3877 188.629Z'
            fill='#242631'
          />
          <path
            d='M57.3877 188.629C57.3877 189.181 56.94 189.629 56.3877 189.629C55.8354 189.629 55.3877 189.181 55.3877 188.629C55.3877 188.077 55.8354 187.629 56.3877 187.629C56.94 187.629 57.3877 188.077 57.3877 188.629Z'
            fill='#242631'
          />
          <path
            d='M63.3877 188.629C63.3877 189.181 62.94 189.629 62.3877 189.629C61.8354 189.629 61.3877 189.181 61.3877 188.629C61.3877 188.077 61.8354 187.629 62.3877 187.629C62.94 187.629 63.3877 188.077 63.3877 188.629Z'
            fill='#242631'
          />
          <path
            d='M69.3877 188.629C69.3877 189.181 68.94 189.629 68.3877 189.629C67.8354 189.629 67.3877 189.181 67.3877 188.629C67.3877 188.077 67.8354 187.629 68.3877 187.629C68.94 187.629 69.3877 188.077 69.3877 188.629Z'
            fill='#242631'
          />
          <path
            d='M75.3877 188.629C75.3877 189.181 74.94 189.629 74.3877 189.629C73.8354 189.629 73.3877 189.181 73.3877 188.629C73.3877 188.077 73.8354 187.629 74.3877 187.629C74.94 187.629 75.3877 188.077 75.3877 188.629Z'
            fill='#242631'
          />
          <path
            d='M81.3877 188.629C81.3877 189.181 80.94 189.629 80.3877 189.629C79.8354 189.629 79.3877 189.181 79.3877 188.629C79.3877 188.077 79.8354 187.629 80.3877 187.629C80.94 187.629 81.3877 188.077 81.3877 188.629Z'
            fill='#242631'
          />
          <path
            d='M87.3877 188.629C87.3877 189.181 86.94 189.629 86.3877 189.629C85.8354 189.629 85.3877 189.181 85.3877 188.629C85.3877 188.077 85.8354 187.629 86.3877 187.629C86.94 187.629 87.3877 188.077 87.3877 188.629Z'
            fill='#242631'
          />
          <path
            d='M93.3877 188.629C93.3877 189.181 92.94 189.629 92.3877 189.629C91.8354 189.629 91.3877 189.181 91.3877 188.629C91.3877 188.077 91.8354 187.629 92.3877 187.629C92.94 187.629 93.3877 188.077 93.3877 188.629Z'
            fill='#242631'
          />
          <path
            d='M99.3877 188.629C99.3877 189.181 98.94 189.629 98.3877 189.629C97.8354 189.629 97.3877 189.181 97.3877 188.629C97.3877 188.077 97.8354 187.629 98.3877 187.629C98.94 187.629 99.3877 188.077 99.3877 188.629Z'
            fill='#242631'
          />
          <path
            d='M105.388 188.629C105.388 189.181 104.94 189.629 104.388 189.629C103.835 189.629 103.388 189.181 103.388 188.629C103.388 188.077 103.835 187.629 104.388 187.629C104.94 187.629 105.388 188.077 105.388 188.629Z'
            fill='#242631'
          />
          <path
            d='M111.388 188.629C111.388 189.181 110.94 189.629 110.388 189.629C109.835 189.629 109.388 189.181 109.388 188.629C109.388 188.077 109.835 187.629 110.388 187.629C110.94 187.629 111.388 188.077 111.388 188.629Z'
            fill='#242631'
          />
          <path
            d='M117.388 188.629C117.388 189.181 116.94 189.629 116.388 189.629C115.835 189.629 115.388 189.181 115.388 188.629C115.388 188.077 115.835 187.629 116.388 187.629C116.94 187.629 117.388 188.077 117.388 188.629Z'
            fill='#242631'
          />
          <path
            d='M123.388 188.629C123.388 189.181 122.94 189.629 122.388 189.629C121.835 189.629 121.388 189.181 121.388 188.629C121.388 188.077 121.835 187.629 122.388 187.629C122.94 187.629 123.388 188.077 123.388 188.629Z'
            fill='#242631'
          />
          <path
            d='M129.388 188.629C129.388 189.181 128.94 189.629 128.388 189.629C127.835 189.629 127.388 189.181 127.388 188.629C127.388 188.077 127.835 187.629 128.388 187.629C128.94 187.629 129.388 188.077 129.388 188.629Z'
            fill='#242631'
          />
          <circle cx='134.388' cy='188.629' r='1' fill='#242631' />
          <circle cx='140.388' cy='188.629' r='1' fill='#242631' />
          <circle cx='146.388' cy='188.629' r='1' fill='#242631' />
          <circle cx='152.388' cy='188.629' r='1' fill='#242631' />
          <circle cx='158.388' cy='188.629' r='1' fill='#242631' />
          <circle cx='164.388' cy='188.629' r='1' fill='#242631' />
          <circle cx='170.388' cy='188.629' r='1' fill='#242631' />
          <circle cx='176.388' cy='188.629' r='1' fill='#242631' />
          <circle cx='182.388' cy='188.629' r='1' fill='#242631' />
          <circle cx='188.388' cy='188.629' r='1' fill='#242631' />
          <circle cx='194.388' cy='188.629' r='1' fill='#242631' />
          <circle cx='200.388' cy='188.629' r='1' fill='#242631' />
          <circle cx='2.3877' cy='93.3262' r='1' fill='#242631' />
          <circle cx='8.3877' cy='93.3262' r='1' fill='#242631' />
          <path
            d='M15.3877 93.3262C15.3877 93.8785 14.94 94.3262 14.3877 94.3262C13.8354 94.3262 13.3877 93.8785 13.3877 93.3262C13.3877 92.7739 13.8354 92.3262 14.3877 92.3262C14.94 92.3262 15.3877 92.7739 15.3877 93.3262Z'
            fill='#242631'
          />
          <path
            d='M21.3877 93.3262C21.3877 93.8785 20.94 94.3262 20.3877 94.3262C19.8354 94.3262 19.3877 93.8785 19.3877 93.3262C19.3877 92.7739 19.8354 92.3262 20.3877 92.3262C20.94 92.3262 21.3877 92.7739 21.3877 93.3262Z'
            fill='#242631'
          />
          <path
            d='M27.3877 93.3262C27.3877 93.8785 26.94 94.3262 26.3877 94.3262C25.8354 94.3262 25.3877 93.8785 25.3877 93.3262C25.3877 92.7739 25.8354 92.3262 26.3877 92.3262C26.94 92.3262 27.3877 92.7739 27.3877 93.3262Z'
            fill='#242631'
          />
          <path
            d='M33.3877 93.3262C33.3877 93.8785 32.94 94.3262 32.3877 94.3262C31.8354 94.3262 31.3877 93.8785 31.3877 93.3262C31.3877 92.7739 31.8354 92.3262 32.3877 92.3262C32.94 92.3262 33.3877 92.7739 33.3877 93.3262Z'
            fill='#242631'
          />
          <path
            d='M39.3877 93.3262C39.3877 93.8785 38.94 94.3262 38.3877 94.3262C37.8354 94.3262 37.3877 93.8785 37.3877 93.3262C37.3877 92.7739 37.8354 92.3262 38.3877 92.3262C38.94 92.3262 39.3877 92.7739 39.3877 93.3262Z'
            fill='#242631'
          />
          <path
            d='M45.3877 93.3262C45.3877 93.8785 44.94 94.3262 44.3877 94.3262C43.8354 94.3262 43.3877 93.8785 43.3877 93.3262C43.3877 92.7739 43.8354 92.3262 44.3877 92.3262C44.94 92.3262 45.3877 92.7739 45.3877 93.3262Z'
            fill='#242631'
          />
          <path
            d='M51.3877 93.3262C51.3877 93.8785 50.94 94.3262 50.3877 94.3262C49.8354 94.3262 49.3877 93.8785 49.3877 93.3262C49.3877 92.7739 49.8354 92.3262 50.3877 92.3262C50.94 92.3262 51.3877 92.7739 51.3877 93.3262Z'
            fill='#242631'
          />
          <path
            d='M57.3877 93.3262C57.3877 93.8785 56.94 94.3262 56.3877 94.3262C55.8354 94.3262 55.3877 93.8785 55.3877 93.3262C55.3877 92.7739 55.8354 92.3262 56.3877 92.3262C56.94 92.3262 57.3877 92.7739 57.3877 93.3262Z'
            fill='#242631'
          />
          <path
            d='M63.3877 93.3262C63.3877 93.8785 62.94 94.3262 62.3877 94.3262C61.8354 94.3262 61.3877 93.8785 61.3877 93.3262C61.3877 92.7739 61.8354 92.3262 62.3877 92.3262C62.94 92.3262 63.3877 92.7739 63.3877 93.3262Z'
            fill='#242631'
          />
          <path
            d='M69.3877 93.3262C69.3877 93.8785 68.94 94.3262 68.3877 94.3262C67.8354 94.3262 67.3877 93.8785 67.3877 93.3262C67.3877 92.7739 67.8354 92.3262 68.3877 92.3262C68.94 92.3262 69.3877 92.7739 69.3877 93.3262Z'
            fill='#242631'
          />
          <path
            d='M75.3877 93.3262C75.3877 93.8785 74.94 94.3262 74.3877 94.3262C73.8354 94.3262 73.3877 93.8785 73.3877 93.3262C73.3877 92.7739 73.8354 92.3262 74.3877 92.3262C74.94 92.3262 75.3877 92.7739 75.3877 93.3262Z'
            fill='#242631'
          />
          <path
            d='M81.3877 93.3262C81.3877 93.8785 80.94 94.3262 80.3877 94.3262C79.8354 94.3262 79.3877 93.8785 79.3877 93.3262C79.3877 92.7739 79.8354 92.3262 80.3877 92.3262C80.94 92.3262 81.3877 92.7739 81.3877 93.3262Z'
            fill='#242631'
          />
          <path
            d='M87.3877 93.3262C87.3877 93.8785 86.94 94.3262 86.3877 94.3262C85.8354 94.3262 85.3877 93.8785 85.3877 93.3262C85.3877 92.7739 85.8354 92.3262 86.3877 92.3262C86.94 92.3262 87.3877 92.7739 87.3877 93.3262Z'
            fill='#242631'
          />
          <path
            d='M93.3877 93.3262C93.3877 93.8785 92.94 94.3262 92.3877 94.3262C91.8354 94.3262 91.3877 93.8785 91.3877 93.3262C91.3877 92.7739 91.8354 92.3262 92.3877 92.3262C92.94 92.3262 93.3877 92.7739 93.3877 93.3262Z'
            fill='#242631'
          />
          <path
            d='M99.3877 93.3262C99.3877 93.8785 98.94 94.3262 98.3877 94.3262C97.8354 94.3262 97.3877 93.8785 97.3877 93.3262C97.3877 92.7739 97.8354 92.3262 98.3877 92.3262C98.94 92.3262 99.3877 92.7739 99.3877 93.3262Z'
            fill='#242631'
          />
          <path
            d='M105.388 93.3262C105.388 93.8785 104.94 94.3262 104.388 94.3262C103.835 94.3262 103.388 93.8785 103.388 93.3262C103.388 92.7739 103.835 92.3262 104.388 92.3262C104.94 92.3262 105.388 92.7739 105.388 93.3262Z'
            fill='#242631'
          />
          <path
            d='M111.388 93.3262C111.388 93.8785 110.94 94.3262 110.388 94.3262C109.835 94.3262 109.388 93.8785 109.388 93.3262C109.388 92.7739 109.835 92.3262 110.388 92.3262C110.94 92.3262 111.388 92.7739 111.388 93.3262Z'
            fill='#242631'
          />
          <path
            d='M117.388 93.3262C117.388 93.8785 116.94 94.3262 116.388 94.3262C115.835 94.3262 115.388 93.8785 115.388 93.3262C115.388 92.7739 115.835 92.3262 116.388 92.3262C116.94 92.3262 117.388 92.7739 117.388 93.3262Z'
            fill='#242631'
          />
          <path
            d='M123.388 93.3262C123.388 93.8785 122.94 94.3262 122.388 94.3262C121.835 94.3262 121.388 93.8785 121.388 93.3262C121.388 92.7739 121.835 92.3262 122.388 92.3262C122.94 92.3262 123.388 92.7739 123.388 93.3262Z'
            fill='#242631'
          />
          <path
            d='M129.388 93.3262C129.388 93.8785 128.94 94.3262 128.388 94.3262C127.835 94.3262 127.388 93.8785 127.388 93.3262C127.388 92.7739 127.835 92.3262 128.388 92.3262C128.94 92.3262 129.388 92.7739 129.388 93.3262Z'
            fill='#242631'
          />
          <circle cx='134.388' cy='93.3262' r='1' fill='#242631' />
          <circle cx='140.388' cy='93.3262' r='1' fill='#242631' />
          <circle cx='146.388' cy='93.3262' r='1' fill='#242631' />
          <circle cx='152.388' cy='93.3262' r='1' fill='#242631' />
          <circle cx='158.388' cy='93.3262' r='1' fill='#242631' />
          <circle cx='164.388' cy='93.3262' r='1' fill='#242631' />
          <circle cx='170.388' cy='93.3262' r='1' fill='#242631' />
          <circle cx='176.388' cy='93.3262' r='1' fill='#242631' />
          <circle cx='182.388' cy='93.3262' r='1' fill='#242631' />
          <circle cx='188.388' cy='93.3262' r='1' fill='#242631' />
          <circle cx='194.388' cy='93.3262' r='1' fill='#242631' />
          <circle cx='200.388' cy='93.3262' r='1' fill='#242631' />
          <circle cx='2.3877' cy='117.152' r='1' fill='#242631' />
          <circle cx='8.3877' cy='117.152' r='1' fill='#242631' />
          <path
            d='M15.3877 117.152C15.3877 117.705 14.94 118.152 14.3877 118.152C13.8354 118.152 13.3877 117.705 13.3877 117.152C13.3877 116.6 13.8354 116.152 14.3877 116.152C14.94 116.152 15.3877 116.6 15.3877 117.152Z'
            fill='#242631'
          />
          <path
            d='M21.3877 117.152C21.3877 117.705 20.94 118.152 20.3877 118.152C19.8354 118.152 19.3877 117.705 19.3877 117.152C19.3877 116.6 19.8354 116.152 20.3877 116.152C20.94 116.152 21.3877 116.6 21.3877 117.152Z'
            fill='#242631'
          />
          <path
            d='M27.3877 117.152C27.3877 117.705 26.94 118.152 26.3877 118.152C25.8354 118.152 25.3877 117.705 25.3877 117.152C25.3877 116.6 25.8354 116.152 26.3877 116.152C26.94 116.152 27.3877 116.6 27.3877 117.152Z'
            fill='#242631'
          />
          <path
            d='M33.3877 117.152C33.3877 117.705 32.94 118.152 32.3877 118.152C31.8354 118.152 31.3877 117.705 31.3877 117.152C31.3877 116.6 31.8354 116.152 32.3877 116.152C32.94 116.152 33.3877 116.6 33.3877 117.152Z'
            fill='#242631'
          />
          <path
            d='M39.3877 117.152C39.3877 117.705 38.94 118.152 38.3877 118.152C37.8354 118.152 37.3877 117.705 37.3877 117.152C37.3877 116.6 37.8354 116.152 38.3877 116.152C38.94 116.152 39.3877 116.6 39.3877 117.152Z'
            fill='#242631'
          />
          <path
            d='M45.3877 117.152C45.3877 117.705 44.94 118.152 44.3877 118.152C43.8354 118.152 43.3877 117.705 43.3877 117.152C43.3877 116.6 43.8354 116.152 44.3877 116.152C44.94 116.152 45.3877 116.6 45.3877 117.152Z'
            fill='#242631'
          />
          <path
            d='M51.3877 117.152C51.3877 117.705 50.94 118.152 50.3877 118.152C49.8354 118.152 49.3877 117.705 49.3877 117.152C49.3877 116.6 49.8354 116.152 50.3877 116.152C50.94 116.152 51.3877 116.6 51.3877 117.152Z'
            fill='#242631'
          />
          <path
            d='M57.3877 117.152C57.3877 117.705 56.94 118.152 56.3877 118.152C55.8354 118.152 55.3877 117.705 55.3877 117.152C55.3877 116.6 55.8354 116.152 56.3877 116.152C56.94 116.152 57.3877 116.6 57.3877 117.152Z'
            fill='#242631'
          />
          <path
            d='M63.3877 117.152C63.3877 117.705 62.94 118.152 62.3877 118.152C61.8354 118.152 61.3877 117.705 61.3877 117.152C61.3877 116.6 61.8354 116.152 62.3877 116.152C62.94 116.152 63.3877 116.6 63.3877 117.152Z'
            fill='#242631'
          />
          <path
            d='M69.3877 117.152C69.3877 117.705 68.94 118.152 68.3877 118.152C67.8354 118.152 67.3877 117.705 67.3877 117.152C67.3877 116.6 67.8354 116.152 68.3877 116.152C68.94 116.152 69.3877 116.6 69.3877 117.152Z'
            fill='#242631'
          />
          <path
            d='M75.3877 117.152C75.3877 117.705 74.94 118.152 74.3877 118.152C73.8354 118.152 73.3877 117.705 73.3877 117.152C73.3877 116.6 73.8354 116.152 74.3877 116.152C74.94 116.152 75.3877 116.6 75.3877 117.152Z'
            fill='#242631'
          />
          <path
            d='M81.3877 117.152C81.3877 117.705 80.94 118.152 80.3877 118.152C79.8354 118.152 79.3877 117.705 79.3877 117.152C79.3877 116.6 79.8354 116.152 80.3877 116.152C80.94 116.152 81.3877 116.6 81.3877 117.152Z'
            fill='#242631'
          />
          <path
            d='M87.3877 117.152C87.3877 117.705 86.94 118.152 86.3877 118.152C85.8354 118.152 85.3877 117.705 85.3877 117.152C85.3877 116.6 85.8354 116.152 86.3877 116.152C86.94 116.152 87.3877 116.6 87.3877 117.152Z'
            fill='#242631'
          />
          <path
            d='M93.3877 117.152C93.3877 117.705 92.94 118.152 92.3877 118.152C91.8354 118.152 91.3877 117.705 91.3877 117.152C91.3877 116.6 91.8354 116.152 92.3877 116.152C92.94 116.152 93.3877 116.6 93.3877 117.152Z'
            fill='#242631'
          />
          <path
            d='M99.3877 117.152C99.3877 117.705 98.94 118.152 98.3877 118.152C97.8354 118.152 97.3877 117.705 97.3877 117.152C97.3877 116.6 97.8354 116.152 98.3877 116.152C98.94 116.152 99.3877 116.6 99.3877 117.152Z'
            fill='#242631'
          />
          <path
            d='M105.388 117.152C105.388 117.705 104.94 118.152 104.388 118.152C103.835 118.152 103.388 117.705 103.388 117.152C103.388 116.6 103.835 116.152 104.388 116.152C104.94 116.152 105.388 116.6 105.388 117.152Z'
            fill='#242631'
          />
          <path
            d='M111.388 117.152C111.388 117.705 110.94 118.152 110.388 118.152C109.835 118.152 109.388 117.705 109.388 117.152C109.388 116.6 109.835 116.152 110.388 116.152C110.94 116.152 111.388 116.6 111.388 117.152Z'
            fill='#242631'
          />
          <path
            d='M117.388 117.152C117.388 117.705 116.94 118.152 116.388 118.152C115.835 118.152 115.388 117.705 115.388 117.152C115.388 116.6 115.835 116.152 116.388 116.152C116.94 116.152 117.388 116.6 117.388 117.152Z'
            fill='#242631'
          />
          <path
            d='M123.388 117.152C123.388 117.705 122.94 118.152 122.388 118.152C121.835 118.152 121.388 117.705 121.388 117.152C121.388 116.6 121.835 116.152 122.388 116.152C122.94 116.152 123.388 116.6 123.388 117.152Z'
            fill='#242631'
          />
          <path
            d='M129.388 117.152C129.388 117.705 128.94 118.152 128.388 118.152C127.835 118.152 127.388 117.705 127.388 117.152C127.388 116.6 127.835 116.152 128.388 116.152C128.94 116.152 129.388 116.6 129.388 117.152Z'
            fill='#242631'
          />
          <circle cx='134.388' cy='117.152' r='1' fill='#242631' />
          <circle cx='140.388' cy='117.152' r='1' fill='#242631' />
          <circle cx='146.388' cy='117.152' r='1' fill='#242631' />
          <circle cx='152.388' cy='117.152' r='1' fill='#242631' />
          <circle cx='158.388' cy='117.152' r='1' fill='#242631' />
          <circle cx='164.388' cy='117.152' r='1' fill='#242631' />
          <circle cx='170.388' cy='117.152' r='1' fill='#242631' />
          <circle cx='176.388' cy='117.152' r='1' fill='#242631' />
          <circle cx='182.388' cy='117.152' r='1' fill='#242631' />
          <circle cx='188.388' cy='117.152' r='1' fill='#242631' />
          <circle cx='194.388' cy='117.152' r='1' fill='#242631' />
          <circle cx='200.388' cy='117.152' r='1' fill='#242631' />
        </g>
      </g>
      <rect
        width='11.8044'
        height='29.511'
        transform='matrix(0.703658 -0.710539 0.703658 0.710539 138.514 146.262)'
        fill='#6F717C'
      />
      <ellipse
        cx='98.3783'
        cy='101.303'
        rx='67.5443'
        ry='68.2049'
        fill='#89F7C4'
      />
      <ellipse
        cx='101.315'
        cy='98.3355'
        rx='66.5654'
        ry='67.2164'
        fill='white'
      />
      <ellipse
        cx='101.314'
        cy='98.3359'
        rx='58.7342'
        ry='59.3086'
        fill='white'
      />
      <circle cx='100.5' cy='97.5' r='54.5' fill='white' />
      <mask
        id='mask1_6961_37080'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='46'
        y='43'
        width='109'
        height='109'
      >
        <circle cx='100.5' cy='97.5' r='54' fill='#453838' stroke='#242631' />
      </mask>
      <g mask='url(#mask1_6961_37080)'>
        <path
          d='M100.712 55.1826L100.562 54.8076C100.521 54.7058 100.5 54.5971 100.5 54.4874C100.5 54.0112 100.886 53.6252 101.363 53.6252H101.955C102.34 53.6252 102.71 53.7784 102.983 54.0511L103.544 54.6119C103.945 55.0134 103.945 55.6645 103.544 56.0661C103.351 56.259 103.089 56.3673 102.817 56.3673H102.462C101.691 56.3673 100.998 55.8981 100.712 55.1826Z'
          fill='#FF9B63'
        />
        <path
          d='M106.172 58.4455L106.016 58.2378C105.802 57.9512 105.83 57.5502 106.083 57.2969C106.393 56.9872 106.905 57.0236 107.168 57.374L107.324 57.5817C107.539 57.8683 107.51 58.2693 107.257 58.5226C106.947 58.8323 106.435 58.7959 106.172 58.4455Z'
          fill='#FF9B63'
        />
        <path
          d='M122.741 50.8438L121.664 49.7665C121.168 49.2703 121.226 48.4497 121.787 48.0287C121.987 47.879 122.23 47.7981 122.479 47.7981H124.271C124.86 47.7981 125.432 47.9944 125.897 48.3559L127.144 49.3258C127.739 49.7887 127.794 50.6683 127.261 51.2014C127.026 51.4365 126.707 51.5685 126.374 51.5685H124.491C123.834 51.5685 123.205 51.3079 122.741 50.8438Z'
          fill='#FF9B63'
        />
        <path
          d='M75.5906 62.7616L75.1828 61.946C75.1514 61.8831 75.1244 61.8181 75.1022 61.7514L75.0018 61.4501C74.7303 60.6358 75.3364 59.795 76.1947 59.795C76.605 59.795 76.9896 59.9952 77.2249 60.3313L79.0802 62.9818C79.191 63.1401 79.263 63.3224 79.2904 63.5137L79.3582 63.9885C79.462 64.715 78.7409 65.2817 78.0595 65.0092C77.9396 64.9612 77.8306 64.8894 77.7393 64.798L76.6841 63.7429C76.5666 63.6253 76.4309 63.5275 76.2822 63.4532C75.9829 63.3035 75.7402 63.0609 75.5906 62.7616Z'
          fill='#FF9B63'
        />
        <path
          d='M62.4805 69.8949L62.0309 69.9214C60.3101 70.0226 58.6786 70.7217 57.4184 71.8979L55.1312 74.0326C54.5377 74.5865 54.0923 75.2802 53.8356 76.0503C53.4277 77.2741 53.5226 78.6098 54.0995 79.7636L55.2857 82.1361C55.7144 82.9935 55.9046 83.9504 55.8363 84.9066L55.7453 86.1799C55.6551 87.4428 56.3067 96.0292 55.1312 96.4994C54.2672 96.845 53.5469 92.0102 51.4416 89.4735C51.0355 88.9842 50.4969 88.6314 49.9487 88.309C47.8386 87.0677 46.8656 84.5336 47.6028 82.1991L48.3997 79.6755L49.3573 75.8451C50.0801 72.9541 51.466 70.2712 53.4053 68.0086L59.6848 60.6825C60.3693 59.8839 61.2944 59.3288 62.3212 59.1007C63.2993 58.8833 64.3207 58.9735 65.2455 59.3589L65.6 59.5066C67.064 60.1166 68.1357 61.4057 68.4681 62.9565C68.782 64.4214 68.3991 65.949 67.4314 67.0926L66.952 67.6592C65.8302 68.9849 64.2142 69.7929 62.4805 69.8949Z'
          fill='#FF9B63'
        />
        <path
          d='M56.359 75.9631L57.0744 74.8898C57.2227 74.6675 57.4722 74.534 57.7394 74.534C58.2332 74.534 58.6089 74.9774 58.5277 75.4645L58.3787 76.3582C58.353 76.5122 58.3045 76.6616 58.2346 76.8013L57.7238 77.823C57.505 78.2606 56.9521 78.4075 56.545 78.1361C56.3767 78.0238 56.2562 77.8529 56.2071 77.6566L56.1235 77.3222C56.007 76.8562 56.0925 76.3627 56.359 75.9631Z'
          fill='#FF9B63'
        />
        <path
          d='M76.2123 104.041L73.6117 102.481C71.9038 101.456 70.0031 100.794 68.0281 100.536L64.1067 100.025C63.479 99.9431 62.8961 99.6561 62.4485 99.2085C61.8047 98.5647 60.8937 98.2646 59.9933 98.3996L57.8085 98.7274C55.7607 99.0345 53.9626 100.254 52.9193 102.042L52.8262 102.202C52.3939 102.943 52.126 103.768 52.0406 104.622L51.9654 105.374C51.8762 106.266 52.0993 107.162 52.5966 107.908C52.9903 108.498 53.2141 109.186 53.2437 109.895L53.3998 113.642C53.4924 115.865 54.1787 118.022 55.3874 119.89C56.6453 121.834 58.4203 123.389 60.513 124.381L63.8243 125.949C66.435 127.254 68.3449 129.635 69.0528 132.467L69.6513 134.861L72.193 142.168C72.3257 142.55 72.3935 142.951 72.3935 143.355C72.3935 144.482 72.9198 145.545 73.8165 146.228L74.6043 146.828C77.2648 148.856 81.0966 148.12 82.8174 145.252L82.8876 145.135C83.4223 144.244 83.7048 143.224 83.7048 142.185V136.888C83.7048 135.1 84.0801 133.331 84.8064 131.697C85.8741 129.295 87.6564 127.28 89.9105 125.927L91.6433 124.888C92.7367 124.232 93.6777 123.35 94.4035 122.302C95.6795 120.459 96.2192 118.205 95.9163 115.983L95.8483 115.485C95.5316 113.162 94.4295 111.018 92.7253 109.408L92.4578 109.156C90.3586 107.173 87.5804 106.069 84.6929 106.069H83.5324C80.9537 106.069 78.4235 105.368 76.2123 104.041Z'
          fill='#FF9B63'
        />
        <path
          d='M71.5244 56.3071L68.6291 54.5253C68.3979 54.3831 68.1903 54.2058 68.0137 53.9997C67.0398 52.8635 67.2153 51.1436 68.3986 50.2275L74.4786 45.5204C76.0247 44.3234 77.8897 43.6094 79.8398 43.4679L115.015 40.9148C117.153 40.7596 118.818 42.7395 118.298 44.819C118.095 45.6291 117.579 46.3257 116.863 46.7553L113.873 48.5492C112.076 49.6277 110.019 50.1975 107.922 50.1975H103.896C103.01 50.1975 102.132 50.3542 101.301 50.6603C98.1828 51.809 96.1911 54.871 96.4051 58.187L96.4349 58.6489C96.6179 61.4853 95.2953 64.2089 92.9531 65.8192C91.833 66.5893 90.5365 67.064 89.1839 67.1993L89.0799 67.2097C86.1637 67.5013 83.3037 66.266 81.5167 63.943L80.9626 63.2226L78.3418 59.2913C77.4094 57.8928 75.8399 57.0528 74.1591 57.0528C73.2288 57.0528 72.3167 56.7947 71.5244 56.3071Z'
          fill='#FF9B63'
        />
        <path
          d='M126.903 53.8284L133.231 51.5685C134.942 50.927 136.767 50.9183 138.418 51.4582C141.392 52.4311 142.469 55.8158 142.907 58.9143L143.522 63.2664C143.557 63.5102 143.655 63.7399 143.794 63.943C145.613 66.5991 146.207 70.6762 145.307 74.4426C144.934 76.0056 144.617 77.6039 144.768 79.2038L144.774 79.2709C144.951 81.1375 145.687 82.9074 146.885 84.3491L147.617 85.2292C148.535 86.3334 149.037 87.724 149.037 89.16V89.8193C149.037 91.2025 148.431 92.5161 147.378 93.4131C145.959 94.6216 145.384 96.5515 145.91 98.3394L146.464 100.22C147.402 103.411 147.615 106.79 148.409 110.02C149.598 114.853 152.232 118.69 151.183 121.147C151.079 121.389 150.966 121.629 150.897 121.883L150.019 125.147C149.368 127.565 148.35 130.604 145.849 130.458C145.581 130.443 145.311 130.396 145.045 130.316C144.49 130.149 143.985 129.848 143.575 129.438L141.176 127.039C140.224 126.087 139.163 125.252 138.013 124.551L128.521 118.764C126.654 117.625 125.042 116.112 123.788 114.32C121.903 111.628 119.229 109.588 116.134 108.483L106.495 105.04L99.3701 101.478C96.9268 100.256 95.1278 98.0417 94.4327 95.4C94.0258 93.854 94.0158 92.2303 94.4035 90.6793L95.6898 85.5342C96.252 83.2853 96.0913 80.9164 95.2303 78.7641L94.9359 78.0279C94.2081 76.2086 94.1131 74.1975 94.666 72.3177C95.2231 70.4235 96.4053 68.7739 98.0199 67.6377L100.832 65.659C103.422 63.8365 106.434 62.7059 109.584 62.3743L112.569 62.0601C115.939 61.7053 119.058 60.1099 121.318 57.5839C122.837 55.8858 124.757 54.5947 126.903 53.8284Z'
          fill='#FF9B63'
        />
        <path
          d='M100.712 55.1826L100.562 54.8076C100.521 54.7058 100.5 54.5971 100.5 54.4874C100.5 54.0112 100.886 53.6252 101.363 53.6252H101.955C102.34 53.6252 102.71 53.7784 102.983 54.0511L103.544 54.6119C103.945 55.0134 103.945 55.6645 103.544 56.0661C103.351 56.259 103.089 56.3673 102.817 56.3673H102.462C101.691 56.3673 100.998 55.8981 100.712 55.1826Z'
          stroke='#242631'
        />
        <path
          d='M106.172 58.4455L106.016 58.2378C105.802 57.9512 105.83 57.5502 106.083 57.2969C106.393 56.9872 106.905 57.0236 107.168 57.374L107.324 57.5817C107.539 57.8683 107.51 58.2693 107.257 58.5226C106.947 58.8323 106.435 58.7959 106.172 58.4455Z'
          stroke='#242631'
        />
        <path
          d='M122.741 50.8438L121.664 49.7665C121.168 49.2703 121.226 48.4497 121.787 48.0287C121.987 47.879 122.23 47.7981 122.479 47.7981H124.271C124.86 47.7981 125.432 47.9944 125.897 48.3559L127.144 49.3258C127.739 49.7887 127.794 50.6683 127.261 51.2014C127.026 51.4365 126.707 51.5685 126.374 51.5685H124.491C123.834 51.5685 123.205 51.3079 122.741 50.8438Z'
          stroke='#242631'
        />
        <path
          d='M75.5906 62.7616L75.1828 61.946C75.1514 61.8831 75.1244 61.8181 75.1022 61.7514L75.0018 61.4501C74.7303 60.6358 75.3364 59.795 76.1947 59.795C76.605 59.795 76.9896 59.9952 77.2249 60.3313L79.0802 62.9818C79.191 63.1401 79.263 63.3224 79.2904 63.5137L79.3582 63.9885C79.462 64.715 78.7409 65.2817 78.0595 65.0092C77.9396 64.9612 77.8306 64.8894 77.7393 64.798L76.6841 63.7429C76.5666 63.6253 76.4309 63.5275 76.2822 63.4532C75.9829 63.3035 75.7402 63.0609 75.5906 62.7616Z'
          stroke='#242631'
        />
        <path
          d='M62.4805 69.8949L62.0309 69.9214C60.3101 70.0226 58.6786 70.7217 57.4184 71.8979L55.1312 74.0326C54.5377 74.5865 54.0923 75.2802 53.8356 76.0503C53.4277 77.2741 53.5226 78.6098 54.0995 79.7636L55.2857 82.1361C55.7144 82.9935 55.9046 83.9504 55.8363 84.9066L55.7453 86.1799C55.6551 87.4428 56.3067 96.0292 55.1312 96.4994C54.2672 96.845 53.5469 92.0102 51.4416 89.4735C51.0355 88.9842 50.4969 88.6314 49.9487 88.309C47.8386 87.0677 46.8656 84.5336 47.6028 82.1991L48.3997 79.6755L49.3573 75.8451C50.0801 72.9541 51.466 70.2712 53.4053 68.0086L59.6848 60.6825C60.3693 59.8839 61.2944 59.3288 62.3212 59.1007C63.2993 58.8833 64.3207 58.9735 65.2455 59.3589L65.6 59.5066C67.064 60.1166 68.1357 61.4057 68.4681 62.9565C68.782 64.4214 68.3991 65.949 67.4314 67.0926L66.952 67.6592C65.8302 68.9849 64.2142 69.7929 62.4805 69.8949Z'
          stroke='#242631'
        />
        <path
          d='M56.359 75.9631L57.0744 74.8898C57.2227 74.6675 57.4722 74.534 57.7394 74.534C58.2332 74.534 58.6089 74.9774 58.5277 75.4645L58.3787 76.3582C58.353 76.5122 58.3045 76.6616 58.2346 76.8013L57.7238 77.823C57.505 78.2606 56.9521 78.4075 56.545 78.1361C56.3767 78.0238 56.2562 77.8529 56.2071 77.6566L56.1235 77.3222C56.007 76.8562 56.0925 76.3627 56.359 75.9631Z'
          stroke='#242631'
        />
        <path
          d='M76.2123 104.041L73.6117 102.481C71.9038 101.456 70.0031 100.794 68.0281 100.536L64.1067 100.025C63.479 99.9431 62.8961 99.6561 62.4485 99.2085C61.8047 98.5647 60.8937 98.2646 59.9933 98.3996L57.8085 98.7274C55.7607 99.0345 53.9626 100.254 52.9193 102.042L52.8262 102.202C52.3939 102.943 52.126 103.768 52.0406 104.622L51.9654 105.374C51.8762 106.266 52.0993 107.162 52.5966 107.908C52.9903 108.498 53.2141 109.186 53.2437 109.895L53.3998 113.642C53.4924 115.865 54.1787 118.022 55.3874 119.89C56.6453 121.834 58.4203 123.389 60.513 124.381L63.8243 125.949C66.435 127.254 68.3449 129.635 69.0528 132.467L69.6513 134.861L72.193 142.168C72.3257 142.55 72.3935 142.951 72.3935 143.355C72.3935 144.482 72.9198 145.545 73.8165 146.228L74.6043 146.828C77.2648 148.856 81.0966 148.12 82.8174 145.252L82.8876 145.135C83.4223 144.244 83.7048 143.224 83.7048 142.185V136.888C83.7048 135.1 84.0801 133.331 84.8064 131.697C85.8741 129.295 87.6564 127.28 89.9105 125.927L91.6433 124.888C92.7367 124.232 93.6777 123.35 94.4035 122.302C95.6795 120.459 96.2192 118.205 95.9163 115.983L95.8483 115.485C95.5316 113.162 94.4295 111.018 92.7253 109.408L92.4578 109.156C90.3586 107.173 87.5804 106.069 84.6929 106.069H83.5324C80.9537 106.069 78.4235 105.368 76.2123 104.041Z'
          stroke='#242631'
        />
        <path
          d='M71.5244 56.3071L68.6291 54.5253C68.3979 54.3831 68.1903 54.2058 68.0137 53.9997C67.0398 52.8635 67.2153 51.1436 68.3986 50.2275L74.4786 45.5204C76.0247 44.3234 77.8897 43.6094 79.8398 43.4679L115.015 40.9148C117.153 40.7596 118.818 42.7395 118.298 44.819C118.095 45.6291 117.579 46.3257 116.863 46.7553L113.873 48.5492C112.076 49.6277 110.019 50.1975 107.922 50.1975H103.896C103.01 50.1975 102.132 50.3542 101.301 50.6603C98.1828 51.809 96.1911 54.871 96.4051 58.187L96.4349 58.6489C96.6179 61.4853 95.2953 64.2089 92.9531 65.8192C91.833 66.5893 90.5365 67.064 89.1839 67.1993L89.0799 67.2097C86.1637 67.5013 83.3037 66.266 81.5167 63.943L80.9626 63.2226L78.3418 59.2913C77.4094 57.8928 75.8399 57.0528 74.1591 57.0528C73.2288 57.0528 72.3167 56.7947 71.5244 56.3071Z'
          stroke='#242631'
        />
        <path
          d='M126.903 53.8284L133.231 51.5685C134.942 50.927 136.767 50.9183 138.418 51.4582C141.392 52.4311 142.469 55.8158 142.907 58.9143L143.522 63.2664C143.557 63.5102 143.655 63.7399 143.794 63.943C145.613 66.5991 146.207 70.6762 145.307 74.4426C144.934 76.0056 144.617 77.6039 144.768 79.2038L144.774 79.2709C144.951 81.1375 145.687 82.9074 146.885 84.3491L147.617 85.2292C148.535 86.3334 149.037 87.724 149.037 89.16V89.8193C149.037 91.2025 148.431 92.5161 147.378 93.4131C145.959 94.6216 145.384 96.5515 145.91 98.3394L146.464 100.22C147.402 103.411 147.615 106.79 148.409 110.02C149.598 114.853 152.232 118.69 151.183 121.147C151.079 121.389 150.966 121.629 150.897 121.883L150.019 125.147C149.368 127.565 148.35 130.604 145.849 130.458C145.581 130.443 145.311 130.396 145.045 130.316C144.49 130.149 143.985 129.848 143.575 129.438L141.176 127.039C140.224 126.087 139.163 125.252 138.013 124.551L128.521 118.764C126.654 117.625 125.042 116.112 123.788 114.32C121.903 111.628 119.229 109.588 116.134 108.483L106.495 105.04L99.3701 101.478C96.9268 100.256 95.1278 98.0417 94.4327 95.4C94.0258 93.854 94.0158 92.2303 94.4035 90.6793L95.6898 85.5342C96.252 83.2853 96.0913 80.9164 95.2303 78.7641L94.9359 78.0279C94.2081 76.2086 94.1131 74.1975 94.666 72.3177C95.2231 70.4235 96.4053 68.7739 98.0199 67.6377L100.832 65.659C103.422 63.8365 106.434 62.7059 109.584 62.3743L112.569 62.0601C115.939 61.7053 119.058 60.1099 121.318 57.5839C122.837 55.8858 124.757 54.5947 126.903 53.8284Z'
          stroke='#242631'
        />
        <path
          d='M98.7111 55.1826L98.5611 54.8076C98.5203 54.7058 98.4994 54.5971 98.4994 54.4874C98.4994 54.0112 98.8854 53.6252 99.3616 53.6252H99.9536C100.339 53.6252 100.709 53.7784 100.982 54.0511L101.543 54.6119C101.944 55.0134 101.944 55.6645 101.543 56.0661C101.35 56.259 101.088 56.3673 100.816 56.3673H100.461C99.6903 56.3673 98.9973 55.8981 98.7111 55.1826Z'
          stroke='white'
        />
        <path
          d='M104.171 58.4455L104.015 58.2378C103.801 57.9512 103.829 57.5502 104.082 57.2969C104.392 56.9872 104.904 57.0236 105.167 57.374L105.323 57.5817C105.538 57.8683 105.509 58.2693 105.256 58.5226C104.946 58.8323 104.434 58.7959 104.171 58.4455Z'
          stroke='white'
        />
        <path
          d='M120.74 50.8438L119.663 49.7665C119.167 49.2703 119.225 48.4497 119.786 48.0287C119.986 47.879 120.229 47.7981 120.478 47.7981H122.27C122.859 47.7981 123.431 47.9944 123.896 48.3559L125.143 49.3258C125.738 49.7887 125.793 50.6683 125.26 51.2014C125.025 51.4365 124.706 51.5685 124.373 51.5685H122.49C121.833 51.5685 121.204 51.3079 120.74 50.8438Z'
          stroke='white'
        />
        <path
          d='M73.5896 62.7616L73.1819 61.946C73.1504 61.8831 73.1235 61.8181 73.1012 61.7514L73.0008 61.4501C72.7294 60.6358 73.3354 59.795 74.1937 59.795C74.6041 59.795 74.9886 59.9952 75.2239 60.3313L77.0792 62.9818C77.1901 63.1401 77.2621 63.3224 77.2894 63.5137L77.3572 63.9885C77.461 64.715 76.7399 65.2817 76.0586 65.0092C75.9386 64.9612 75.8296 64.8894 75.7383 64.798L74.6831 63.7429C74.5656 63.6253 74.4299 63.5275 74.2812 63.4532C73.9819 63.3035 73.7393 63.0609 73.5896 62.7616Z'
          stroke='white'
        />
        <path
          d='M60.4796 69.8949L60.0299 69.9214C58.3091 70.0226 56.6777 70.7217 55.4175 71.8979L53.1302 74.0326C52.5367 74.5865 52.0913 75.2802 51.8346 76.0503C51.4267 77.2741 51.5216 78.6098 52.0985 79.7636L53.2847 82.1361C53.7134 82.9935 53.9036 83.9504 53.8353 84.9066L53.7444 86.1799C53.6542 87.4428 54.3057 96.0292 53.1302 96.4994C52.2662 96.845 51.5459 92.0102 49.4407 89.4735C49.0345 88.9842 48.4959 88.6314 47.9477 88.309V88.309C45.8376 87.0677 44.8646 84.5336 45.6018 82.1991L46.3988 79.6755L47.3564 75.8451C48.0791 72.9541 49.465 70.2712 51.4043 68.0086L57.6838 60.6825C58.3684 59.8839 59.2934 59.3288 60.3202 59.1007C61.2983 58.8833 62.3197 58.9735 63.2446 59.3589L63.5991 59.5066C65.063 60.1166 66.1348 61.4057 66.4671 62.9565C66.781 64.4214 66.3981 65.949 65.4304 67.0926L64.951 67.6592C63.8292 68.9849 62.2132 69.7929 60.4796 69.8949Z'
          stroke='white'
        />
        <path
          d='M54.358 75.9631L55.0735 74.8898C55.2217 74.6675 55.4712 74.534 55.7384 74.534C56.2322 74.534 56.6079 74.9774 56.5267 75.4645L56.3777 76.3582C56.3521 76.5122 56.3035 76.6616 56.2337 76.8013L55.7228 77.823C55.504 78.2606 54.9511 78.4075 54.5441 78.1361C54.3757 78.0238 54.2552 77.8529 54.2061 77.6566L54.1226 77.3222C54.0061 76.8562 54.0915 76.3627 54.358 75.9631Z'
          stroke='white'
        />
        <path
          d='M74.2113 104.041L71.6107 102.481C69.9028 101.456 68.0021 100.794 66.0271 100.536L62.1057 100.025C61.4781 99.9431 60.8951 99.6561 60.4475 99.2085C59.8037 98.5647 58.8927 98.2646 57.9924 98.3996L55.8076 98.7274C53.7598 99.0345 51.9617 100.254 50.9183 102.042L50.8252 102.202C50.3929 102.943 50.125 103.768 50.0397 104.622L49.9644 105.374C49.8752 106.266 50.0983 107.162 50.5956 107.908C50.9893 108.498 51.2131 109.186 51.2427 109.895L51.3988 113.642C51.4914 115.865 52.1777 118.022 53.3864 119.89C54.6443 121.834 56.4193 123.389 58.512 124.381L61.8233 125.949C64.434 127.254 66.3439 129.635 67.0519 132.467L67.6503 134.861L70.192 142.168C70.3247 142.55 70.3925 142.951 70.3925 143.355C70.3925 144.482 70.9188 145.545 71.8156 146.228L72.6033 146.828C75.2638 148.856 79.0956 148.12 80.8164 145.252L80.8867 145.135C81.4214 144.244 81.7038 143.224 81.7038 142.185V136.888C81.7038 135.1 82.0791 133.331 82.8055 131.697C83.8731 129.295 85.6554 127.28 87.9095 125.927L89.6423 124.888C90.7357 124.232 91.6767 123.35 92.4025 122.302C93.6785 120.459 94.2182 118.205 93.9153 115.983L93.8473 115.485C93.5306 113.162 92.4285 111.018 90.7243 109.408L90.4568 109.156C88.3576 107.173 85.5794 106.069 82.6919 106.069H81.5314C78.9528 106.069 76.4226 105.368 74.2113 104.041Z'
          stroke='white'
        />
        <path
          d='M69.5235 56.3071L66.6281 54.5253C66.3969 54.3831 66.1893 54.2058 66.0127 53.9997C65.0389 52.8635 65.2144 51.1436 66.3976 50.2275L72.4776 45.5204C74.0237 44.3234 75.8887 43.6094 77.8389 43.4679L113.014 40.9148C115.152 40.7596 116.817 42.7395 116.297 44.819C116.094 45.6291 115.578 46.3257 114.862 46.7553L111.872 48.5492C110.075 49.6277 108.018 50.1975 105.921 50.1975H101.895C101.009 50.1975 100.131 50.3542 99.2998 50.6603C96.1818 51.809 94.1901 54.871 94.4041 58.187L94.4339 58.6489C94.6169 61.4853 93.2944 64.2089 90.9522 65.8192C89.832 66.5893 88.5355 67.064 87.1829 67.1993L87.0789 67.2097C84.1627 67.5013 81.3027 66.266 79.5158 63.943L78.9617 63.2226L76.3408 59.2913C75.4085 57.8928 73.8389 57.0528 72.1581 57.0528C71.2278 57.0528 70.3158 56.7947 69.5235 56.3071Z'
          stroke='white'
        />
        <path
          d='M124.902 53.8284L131.23 51.5685C132.941 50.927 134.766 50.9183 136.417 51.4582C139.391 52.4311 140.468 55.8158 140.906 58.9143L141.568 63.6C141.572 63.625 141.582 63.6485 141.597 63.6688C143.083 65.6612 143.829 68.5554 143.708 71.5292C143.564 75.0655 142.711 78.6126 143.044 82.1361V82.1361L145.616 85.2292C146.534 86.3334 147.036 87.724 147.036 89.16V89.8193C147.036 91.2025 146.43 92.5161 145.377 93.4131V93.4131C143.958 94.6216 143.383 96.5515 143.909 98.3394L144.463 100.22L144.535 100.467C145.426 103.494 145.63 106.696 146.348 109.769C147.08 112.901 148.447 115.623 149.082 117.834C149.775 120.241 148.669 122.728 148.018 125.147V125.147C147.367 127.565 146.349 130.604 143.848 130.458C143.58 130.443 143.31 130.396 143.044 130.316C142.489 130.149 141.984 129.848 141.574 129.438L139.175 127.039C138.223 126.087 137.162 125.252 136.012 124.551L126.52 118.764C124.653 117.625 123.041 116.112 121.787 114.32C119.902 111.628 117.228 109.588 114.133 108.483L104.494 105.04L97.3692 101.478C94.9259 100.256 93.1269 98.0417 92.4317 95.4C92.0248 93.8539 92.0148 92.2303 92.4025 90.6793L93.6888 85.5342C94.2511 83.2853 94.0903 80.9164 93.2294 78.7641L92.9349 78.0279C92.2072 76.2086 92.1121 74.1975 92.665 72.3177C93.2221 70.4235 94.4043 68.7739 96.019 67.6377L98.8307 65.659C101.421 63.8365 104.433 62.7059 107.583 62.3743L110.568 62.0601C113.938 61.7053 117.057 60.1099 119.317 57.5839C120.836 55.8858 122.756 54.5947 124.902 53.8284Z'
          stroke='white'
        />
      </g>
      <mask id='path-1167-inside-1_6961_37080' fill='white'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M101.316 163.575C136.997 163.575 165.923 134.366 165.923 98.3351C165.923 62.3044 136.997 33.0957 101.316 33.0957C65.6338 33.0957 36.708 62.3044 36.708 98.3351C36.708 134.366 65.6338 163.575 101.316 163.575ZM101.315 151.713C130.51 151.713 154.176 127.815 154.176 98.3352C154.176 68.8555 130.51 44.9575 101.315 44.9575C72.1211 44.9575 48.4546 68.8555 48.4546 98.3352C48.4546 127.815 72.1211 151.713 101.315 151.713Z'
        />
      </mask>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M101.316 163.575C136.997 163.575 165.923 134.366 165.923 98.3351C165.923 62.3044 136.997 33.0957 101.316 33.0957C65.6338 33.0957 36.708 62.3044 36.708 98.3351C36.708 134.366 65.6338 163.575 101.316 163.575ZM101.315 151.713C130.51 151.713 154.176 127.815 154.176 98.3352C154.176 68.8555 130.51 44.9575 101.315 44.9575C72.1211 44.9575 48.4546 68.8555 48.4546 98.3352C48.4546 127.815 72.1211 151.713 101.315 151.713Z'
        fill='#6F717C'
      />
      <path
        d='M164.923 98.3351C164.923 133.823 136.436 162.575 101.316 162.575V164.575C137.559 164.575 166.923 134.909 166.923 98.3351H164.923ZM101.316 34.0957C136.436 34.0957 164.923 62.8475 164.923 98.3351H166.923C166.923 61.7613 137.559 32.0957 101.316 32.0957V34.0957ZM37.708 98.3351C37.708 62.8475 66.1952 34.0957 101.316 34.0957V32.0957C65.0724 32.0957 35.708 61.7613 35.708 98.3351H37.708ZM101.316 162.575C66.1952 162.575 37.708 133.823 37.708 98.3351H35.708C35.708 134.909 65.0724 164.575 101.316 164.575V162.575ZM153.176 98.3352C153.176 127.272 129.948 150.713 101.315 150.713V152.713C131.071 152.713 155.176 128.358 155.176 98.3352H153.176ZM101.315 45.9575C129.948 45.9575 153.176 69.3986 153.176 98.3352H155.176C155.176 68.3124 131.071 43.9575 101.315 43.9575V45.9575ZM49.4546 98.3352C49.4546 69.3986 72.6826 45.9575 101.315 45.9575V43.9575C71.5597 43.9575 47.4546 68.3124 47.4546 98.3352H49.4546ZM101.315 150.713C72.6826 150.713 49.4546 127.272 49.4546 98.3352H47.4546C47.4546 128.358 71.5597 152.713 101.315 152.713V150.713Z'
        fill='#242631'
        mask='url(#path-1167-inside-1_6961_37080)'
      />
      <rect
        x='60.7012'
        y='81.043'
        width='128.215'
        height='32.6082'
        rx='3.5'
        fill='#E5E5E6'
        stroke='#242631'
      />
      <rect
        x='69.71'
        y='90.1465'
        width='14.264'
        height='14.4035'
        rx='7.13201'
        stroke='#FF9B63'
      />
      <rect
        x='73.2764'
        y='93.7461'
        width='7.13201'
        height='7.20175'
        rx='3.566'
        fill='#6F717C'
      />
      <rect
        x='91.1064'
        y='91.3477'
        width='35.66'
        height='4.80117'
        rx='2.40058'
        fill='#6F717C'
      />
      <rect
        x='91.1064'
        y='99.75'
        width='17.83'
        height='3.60088'
        rx='1.80044'
        fill='#6F717C'
      />
      <rect
        x='168.369'
        y='91.3477'
        width='11.8867'
        height='4.80117'
        rx='2.40058'
        fill='#6F717C'
      />
      <rect
        x='158.86'
        y='99.75'
        width='21.396'
        height='3.60088'
        rx='1.80044'
        fill='#2DA08D'
      />
      <path
        d='M170.458 157.02L157.474 170.131L189.609 202.58C190.386 203.365 191.646 203.365 192.423 202.58L202.593 192.311C203.37 191.526 203.37 190.254 202.593 189.469L170.458 157.02Z'
        fill='#FF9B63'
        stroke='#242631'
      />
      <defs>
        <linearGradient
          id='paint0_linear_6961_37080'
          x1='101.029'
          y1='0'
          x2='101.029'
          y2='197.084'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF9B63' stopOpacity='0.37' />
          <stop offset='1' stopColor='#F5F5F5' stopOpacity='0' />
        </linearGradient>
      </defs>
    </svg>
  )
}
