import { InfoIcon } from '@assets/icons'
import { formatCurrency } from '@utils/helpers'
import { ProgressBar, Spacer, Text } from 'components'
import { brokerTierConstants } from '../../../config'
import { BrokerTier, BrokerTierUserData } from 'interfaces'
import { useTranslation } from 'hooks/useTranslation'

export const BrokerTierStatus = ({
  openGlobalPosition,
  completedOrdersThisMonth,
  totalPortfolio,
}: {
  openGlobalPosition: () => void
} & BrokerTierUserData &
  BrokerTier): JSX.Element => {
  const { t } = useTranslation()

  return (
    <>
      <div className='w-50 d-flex d-flex-col'>
        <div className='h-50 d-flex align-start'>
          <Text
            text={t('brokerTier.makeNTrades', {
              number: brokerTierConstants.TPR.minimumTradesPerMonth,
            })}
            preset='paragraph2'
            align='center'
            color='secondary'
          />
        </div>
        <div className='h-50'>
          <Spacer preset='small' />
          <ProgressBar
            steps={brokerTierConstants.TPR.minimumTradesPerMonth}
            value={completedOrdersThisMonth}
            maxValue={brokerTierConstants.TPR.minimumTradesPerMonth}
          />
          <Spacer preset='smaller' />
          <div className='d-flex justify-center align-center'>
            <Text
              text={`${completedOrdersThisMonth} / ${brokerTierConstants.TPR.minimumTradesPerMonth} trades`}
              preset='paragraph2'
              color='secondary'
            />
          </div>
          <Spacer preset='smaller' />
        </div>
      </div>
      <Spacer preset='medium' />
      <div
        className='w-50 d-flex d-flex-col'
        onClick={openGlobalPosition}
        aria-hidden
      >
        <div className='h-50 d-flex align-start'>
          <Text
            text={t('brokerTier.keepYourGlobalPosition', {
              minimumGlobalPosition: formatCurrency(
                brokerTierConstants.TPR.minimumGlobalPosition,
              ),
            })}
            preset='paragraph2'
            align='center'
            color='secondary'
          />
        </div>
        <div className='h-50'>
          <Spacer preset='small' />
          <ProgressBar
            steps={5}
            value={totalPortfolio?.amount}
            maxValue={brokerTierConstants.TPR.minimumGlobalPosition.amount}
          />
          <Spacer preset='smaller' />
          <div className='d-flex justify-center align-center'>
            <InfoIcon size={16} color='iconNeutralMedium' />
            <Spacer preset='tiny' />
            <Text
              text={`${formatCurrency(totalPortfolio)} / ${formatCurrency(
                brokerTierConstants.TPR.minimumGlobalPosition,
              )}`}
              preset='paragraph2'
              color='secondary'
            />
          </div>
        </div>
      </div>
    </>
  )
}
