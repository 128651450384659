import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState, fetchUserBalances } from '@store'
import { BalanceState } from '@interfaces'

export function useBalancesInfo(): BalanceState {
  const dispatch = useDispatch()
  const userBalances: BalanceState = useSelector(
    (state: RootState) => state.userBalance,
  )

  const loadUserBalances = useCallback(
    () => dispatch(fetchUserBalances()),
    [dispatch],
  )

  useEffect(() => {
    if (!userBalances) {
      loadUserBalances()
    }
  }, [userBalances, loadUserBalances])

  return userBalances
}
