import './bannerSlider.styles.scss'
import { useState } from 'react'
import { NotificationMessageType } from '@interfaces'
import { ReactComponent as PortfolioTransferIcon } from '@assets/svg/portfolioTransfer.svg'
import { ReactComponent as LimitOrderIcon } from '@assets/svg/sellLimitOrder.svg'
import { ReactComponent as Etf } from '@assets/svg/etfs.svg'
import { useTranslation } from '@hooks'
import { addNotification } from '@store/commonReducers/notifications'
import { useDispatch } from 'react-redux'
import { BannerProps } from './bannerSlider.props'
import { PromotionalBannerEvents } from '@utils/eventTracker/eventKeys'
import { trackingService } from '@services'
import {
  AnniversaryIcon,
  FilterIcon,
  GlobalSearchIcon,
  TimerIcon,
} from '@assets/icons'
import { BuySellFlowIllustration } from '@assets/illustrations'
import { PromotionVisibilityConfigModal } from '@shared/modals'
import { DonutChartIcon } from '@assets/icons/donutChartIcon'

export function BannerItem({
  title,
  subtitle,
  icon,
  logo,
  menu,
  id,
  highlighted,
}: BannerProps): JSX.Element {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [showMenu, setShowMenu] = useState(false)

  const { learnMoreAction, remindLaterAction, notInterestedAction } = menu

  const onPress = (): void => {
    trackingService.trackEvent({
      event: PromotionalBannerEvents.clickedBanner,
      props: { id, highlighted },
    })
    learnMoreAction()
    setShowMenu(false)
  }

  const handleThreeDots = (): void => {
    trackingService.trackEvent({
      event: PromotionalBannerEvents.clickedThreeDots,
      props: { id, highlighted },
    })
    setShowMenu(true)
  }

  const handleLearnMore = (): void => {
    trackingService.trackEvent({
      event: PromotionalBannerEvents.clickedAction,
      props: {
        id: id,
        highlighted: highlighted,
        action: 'learnMore',
      },
    })
    learnMoreAction()
    setShowMenu(false)
  }

  const handleRemindMeLater = (): void => {
    trackingService.trackEvent({
      event: PromotionalBannerEvents.clickedAction,
      props: {
        id: id,
        highlighted: highlighted,
        action: 'remindLater',
      },
    })
    remindLaterAction()
    setShowMenu(false)
    dispatch(
      addNotification({
        type: NotificationMessageType.positive,
        message: `${t('promotions.bannerRemoved')}`,
      }),
    )
  }

  const handleNotInterested = (): void => {
    trackingService.trackEvent({
      event: PromotionalBannerEvents.clickedAction,
      props: {
        id: id,
        highlighted: highlighted,
        action: 'notInterested',
      },
    })
    notInterestedAction()
    setShowMenu(false)
    dispatch(
      addNotification({
        type: NotificationMessageType.positive,
        message: `${t('promotions.bannerRemoved')}`,
      }),
    )
  }

  let localIcon: JSX.Element = null
  switch (icon?.name) {
    case 'portfolioTransfer':
      localIcon = <PortfolioTransferIcon />
      break
    case 'etf':
      localIcon = <Etf />
      break
    case 'sellLimitOrder':
      localIcon = <LimitOrderIcon />
      break
    case 'dynamicSearch':
      localIcon = <FilterIcon />
      break
    case 'searchFilters':
      localIcon = <GlobalSearchIcon />
      break
    case 'anniversary':
      localIcon = <AnniversaryIcon />
      break
    case 'selector':
      localIcon = <BuySellFlowIllustration />
      break
    case 'clock':
      localIcon = <TimerIcon />
      break
    case 'donut':
      localIcon = <DonutChartIcon />
      break
  }

  return (
    <div className={`banner ${highlighted && 'banner--highlighted'}`}>
      <div
        className='banner__clickable cursor-pointer'
        aria-hidden
        onClick={onPress}
      >
        <div
          style={{ backgroundColor: icon?.backgroundColor }}
          className='banner__logo'
        >
          {logo ? (
            <img className='banner__logo__img' alt='' src={logo} />
          ) : (
            <div className='banner__logo__img'>{localIcon}</div>
          )}
        </div>
        <div className='banner__text'>
          <p className='caption2'>{title}</p>

          <p className='banner__text__subtitle paragraph2'>{subtitle}</p>
        </div>
      </div>
      <div
        className='banner__menu cursor-pointer'
        aria-hidden
        onClick={handleThreeDots}
      >
        <p>···</p>
      </div>
      {showMenu && (
        <PromotionVisibilityConfigModal
          close={() => setShowMenu(false)}
          onLearnMore={handleLearnMore}
          onNotInterested={handleNotInterested}
          onRemindMeLater={handleRemindMeLater}
          title={title}
        />
      )}
    </div>
  )
}
