import './selectionSummary.styles.scss'
import { ChevronRight } from '@assets/icons'
import { Text } from 'components/text/text'
import { Spacer } from 'components/spacer'

export interface SelectionSummaryProps {
  onClick: () => void
  items: Array<{
    title: string
    description: string
  }>
}

export const SelectionSummary = ({
  onClick,
  items,
}: SelectionSummaryProps): JSX.Element => {
  return (
    <div
      className='selection-summary d-flex cursor-pointer'
      onClick={() => onClick()}
      aria-hidden
    >
      <div className='selection-summary__items d-flex'>
        {items?.map((i, idx) => {
          return (
            <>
              <div
                key={`${i?.title}-${i?.description}-${idx}`}
                className='selection-summary__item d-flex --capitalize'
              >
                <Text preset='paragraph2' text={i?.title} />
                <Text
                  preset='paragraph2'
                  text={i?.description}
                  align='right'
                  color='iconPrimaryInitial'
                />
              </div>
              {idx < items?.length - 1 ? <Spacer preset='tiny' /> : null}
            </>
          )
        })}
      </div>
      <div className='selection-summary__arrow d-flex align-center justify-content-center'>
        <Spacer preset='tiny' />
        <ChevronRight color='iconPrimaryInitial' />
      </div>
    </div>
  )
}
