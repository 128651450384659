import './transactionItem.style.scss'
import {
  OrderCancellationReason,
  OrderTransactionChildStatusEnum,
  OrderTransactionStatusEnum,
  OrderTypeEnum,
  Transaction,
  TransactionType,
} from '@interfaces'
import { formatMoney, getFormattedShares } from '@utils/helpers'
import { t } from 'i18next'
import { useMemo } from 'react'
import {
  BuyLimitOrder,
  Dividend,
  MarketOrder,
  SellLimitOrder,
  TransferIncoming,
  TransferOutgoing,
} from '@assets/svg'
import { Avatar } from 'components'
import { CashoutIcon, TopUpIcon } from '@assets/icons'
export interface TransactionItemInterface {
  handleOnClick: () => void
  data: Transaction
  animated?: boolean
}

export function TransactionItem({
  handleOnClick,
  data,
  animated = false,
}: TransactionItemInterface): JSX.Element {
  const {
    amount,
    logo,
    title,
    type,
    orderDetails,
    ticker,
    dividendSharesDetails,
  } = data

  const config = useMemo(() => {
    return {
      [TransactionType.deposit]: {
        signSymbol: '+',
        sharesSymbol: '',
      },
      [TransactionType.withdrawal]: {
        signSymbol: '-',
        sharesSymbol: '',
      },
      [TransactionType.dividendCash]: {
        signSymbol: '+',
        sharesSymbol: '',
      },
      [TransactionType.dividendShares]: {
        signSymbol: '+',
        sharesSymbol: '+',
      },
      [TransactionType.buy]: {
        signSymbol: '-',
        sharesSymbol: '+',
      },
      [TransactionType.sell]: {
        signSymbol: '+',
        sharesSymbol: '-',
      },
    }
  }, [])

  const isOrder = useMemo(() => {
    return type === TransactionType.buy || type === TransactionType.sell
  }, [type])

  const isCashoutTopUp = useMemo(() => {
    return (
      type === TransactionType.withdrawal || type === TransactionType.deposit
    )
  }, [type])

  const topRightInfo = useMemo(() => {
    if (!type) return
    const { signSymbol } = config[type]
    if (type === TransactionType.dividendShares) {
      return `${signSymbol}${dividendSharesDetails?.shares} ${ticker}`
    }

    const isBuy = type === TransactionType.buy

    const isNotCompleted =
      orderDetails?.status !== OrderTransactionStatusEnum.completed

    const total =
      isBuy && isNotCompleted && orderDetails?.lockedCash
        ? formatMoney(
            orderDetails?.lockedCash?.amount,
            orderDetails?.lockedCash?.currency,
          )
        : formatMoney(amount?.amount, amount?.currency)

    const amountAsString = total.toString()

    const alreadyWithSymbol =
      amountAsString?.includes('+') || amountAsString?.includes('-')

    const symbol = alreadyWithSymbol ? '' : signSymbol

    return `${symbol}${total}`
  }, [config, type, orderDetails, amount, dividendSharesDetails, ticker])

  const bottomRightPriceInfo = useMemo(() => {
    if (isOrder) {
      return `${t(
        orderDetails?.method === OrderTypeEnum.limit
          ? 'transactions.limitPrice'
          : 'transactions.marketPrice',
      )}: ${formatMoney(
        orderDetails?.sharePrice?.amount,
        orderDetails?.sharePrice?.currency,
      )}`
    }
    return null
  }, [isOrder, orderDetails?.method, orderDetails?.sharePrice])

  const bottomLeftSharesInfo = useMemo(() => {
    if (isOrder) {
      const { sharesSymbol } = config[type]

      const shares = `${getFormattedShares(
        orderDetails?.shares?.executed,
        orderDetails?.shares?.requested,
      )} `

      return orderDetails?.shares?.executed
        ? `${sharesSymbol}${shares}${ticker}`
        : `${shares}${ticker}`
    }
    return null
  }, [config, type, orderDetails?.shares, ticker, isOrder])

  const extraStatus = useMemo(() => {
    if (!orderDetails) {
      return null
    }

    const { status, childStatus, childStatusText, reason, reasonText } =
      orderDetails

    const isPartiallyCompleted =
      status === OrderTransactionStatusEnum.completed &&
      childStatus === OrderTransactionChildStatusEnum.partiallyCompleted

    const isCancelled =
      status === OrderTransactionStatusEnum.cancelled &&
      childStatus === OrderTransactionChildStatusEnum.cancelled

    const isExpired =
      status === OrderTransactionStatusEnum.cancelled &&
      reason === OrderCancellationReason.expired

    let displayedStatus: string

    if (isPartiallyCompleted || isCancelled) {
      displayedStatus = childStatusText
    }

    if (isExpired) {
      displayedStatus = reasonText
    }

    return displayedStatus ? (
      <p className={`secondary--text paragraph3`}>{displayedStatus}</p>
    ) : null
  }, [orderDetails])

  const transferInfo = useMemo(() => {
    const isLimitOrder = orderDetails?.method === OrderTypeEnum.limit

    switch (type) {
      case TransactionType.deposit:
        return (
          <div className='transaction-item__transferInfo'>
            <TransferIncoming />
            <p className={'positive--text paragraph3'}>
              &nbsp;{t('transactions.transfer')}
            </p>
          </div>
        )
      case TransactionType.withdrawal:
        return (
          <div className='transaction-item__transferInfo'>
            <TransferOutgoing />
            <p className={'negative--text paragraph3 transaction-item__label'}>
              {t('transactions.transfer')}
            </p>
          </div>
        )
      case TransactionType.dividendCash:
      case TransactionType.dividendShares:
        return (
          <div className='transaction-item__transferInfo'>
            <Dividend />
            <p className={'paragraph3 positive--text transaction-item__label'}>
              {t('transactions.dividend')}
            </p>
          </div>
        )
      case TransactionType.buy:
        return (
          <div className='transaction-item__transferInfo'>
            {isLimitOrder ? (
              <BuyLimitOrder className={'positive--text'} />
            ) : (
              <MarketOrder className={'positive--text'} />
            )}
            <p className={'positive--text paragraph3 transaction-item__label'}>
              {t(
                isLimitOrder
                  ? 'transactions.buyLimitOrder'
                  : 'transactions.buy',
              )}
            </p>
          </div>
        )
      case TransactionType.sell:
        return (
          <div className='transaction-item__transferInfo'>
            {isLimitOrder ? (
              <SellLimitOrder className={'negative--text'} />
            ) : (
              <MarketOrder className={'negative--text'} />
            )}
            <p className={'negative--text paragraph3 transaction-item__label'}>
              {t(
                isLimitOrder
                  ? 'transactions.sellLimitOrder'
                  : 'transactions.sell',
              )}
            </p>
          </div>
        )
      default:
        return null
    }
  }, [orderDetails?.method, type])

  return (
    <div
      className={`transaction-item cursor-pointer ${
        animated ? '--fade-in-animation' : ''
      }`}
      onClick={handleOnClick}
      aria-hidden
    >
      <div className='transaction-item__topSection'>
        {isCashoutTopUp ? (
          <>
            {type === TransactionType.withdrawal ? (
              <Avatar
                roundness='full'
                size='large'
                bgColor='statusNegative'
                image={
                  <CashoutIcon color='surfaceCanvasPrimaryInitial' size={18} />
                }
              />
            ) : null}
            {type === TransactionType.deposit ? (
              <Avatar
                roundness='full'
                size='large'
                bgColor='statusPositive'
                image={
                  <TopUpIcon color='surfaceCanvasPrimaryInitial' size={40} />
                }
              />
            ) : null}
          </>
        ) : (
          <Avatar
            bordered={false}
            objectFit='cover'
            roundness='full'
            size='large'
            image={logo}
          />
        )}
        <div className='transaction-item__titleSection'>
          <p className='transaction-item__title paragraph2'>{title}</p>
          {transferInfo}
        </div>
        <p className='transaction-item__amount paragraph2'>{topRightInfo}</p>
      </div>
      {isOrder ? (
        <div className='transaction-item__bottomSection'>
          <div className='transaction-item__tradedAmount paragraph2'>
            {bottomLeftSharesInfo}
            {extraStatus}
          </div>
          <div className='transaction-item__limitPrice paragraph2'>
            {bottomRightPriceInfo}
          </div>
        </div>
      ) : null}
    </div>
  )
}
