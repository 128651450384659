import { ReactComponent as QRIcon } from '@assets/svg/webapp-stores.svg'
import { ReactComponent as CompleteProfile } from '@assets/svg/profile/complete_profile.svg'
import { useTranslation } from 'hooks/useTranslation'
import { Card, Spacer, Text } from 'components'

export const OnBoardingNotCompleted = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <div className='home__widget__portfolio'>
      <Card width='full'>
        <div className='d-flex justify-space-between'>
          <div className='w-60'>
            <CompleteProfile className='profile__icon mb-2' />
            <Text
              text={t('portfolio.complete_profile_start_investing')}
              preset='headline1'
            />
            <Spacer preset='small' />
            <Text
              preset='paragraph2'
              text={t('portfolio.complete_qr_link_text')}
              color='secondary'
            />
          </div>
          <div className='w-20 qr__code__wrapper'>
            <QRIcon className='qr__code' />
          </div>
        </div>
      </Card>
    </div>
  )
}
