import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import { Onboarding, PortfolioState } from '@interfaces'
import { fetchOnboardingStatus } from './thunk'

const initialState: PortfolioState = {
  onboarding: null,
  isLoading: false,
  hardLoading: true,
  onboardingError: null,
}

export const onboardingStatusReducer = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(fetchOnboardingStatus.pending, (acc: PortfolioState) => {
        acc.isLoading = true
        acc.onboardingError = null
      })
      .addCase(
        fetchOnboardingStatus.fulfilled,
        (acc: PortfolioState, action: PayloadAction<Onboarding>) => {
          const { payload } = action
          acc.isLoading = false
          acc.hardLoading = false
          acc.onboarding = payload
        },
      )
      .addCase(
        fetchOnboardingStatus.rejected,
        (acc: PortfolioState, { error }) => {
          acc.isLoading = false
          acc.hardLoading = false
          acc.onboardingError = error.message || ''
        },
      )
  },
)
