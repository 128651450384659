import { PeriodicityEnum } from '@interfaces'
import { addDays, addMonths } from 'date-fns'

export const calculatePaymentsAmount = (
  startingDate: Date,
  periodicity: PeriodicityEnum,
  expiration: Date,
): {
  totalPayments: number
  payments: Array<Date>
} | null => {
  let currentDate = new Date(startingDate)
  const expirationDate = new Date(expiration)

  const payments: Array<Date> = [currentDate]

  // Loop through the dates until the expiration date is reached
  while (currentDate < expiration) {
    // Calculate the next payment date based on the selected periodicity
    switch (periodicity) {
      case PeriodicityEnum.weekly:
        currentDate = addDays(currentDate, 7)
        break
      case PeriodicityEnum.biweekly:
        currentDate = addDays(currentDate, 14)
        break
      case PeriodicityEnum.monthly:
        currentDate = addMonths(currentDate, 1)
        break
      case PeriodicityEnum.bimonthly:
        currentDate = addMonths(currentDate, 2)
        break
      default:
        return null
    }

    if (currentDate < expirationDate) payments.push(currentDate)
  }

  return {
    totalPayments: payments?.length,
    payments: payments,
  }
}
