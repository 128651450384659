import {
  UIIcon,
  UIIconColorProps,
  UISecondaryColorProp,
  getIconColorSVGFill,
} from 'components/utils'

export const FilterIcon = ({
  color,
  size = 24,
}: UIIcon & UIIconColorProps & UISecondaryColorProp): JSX.Element => {
  return (
    <svg width={size} height={size} viewBox='0 0 25 24' fill='none'>
      <rect
        x='16.7334'
        y='15'
        width='4'
        height='2'
        rx='1'
        fill={color ? getIconColorSVGFill(color) : 'currentColor'}
      />
      <rect
        x='4.7334'
        y='15'
        width='5'
        height='2'
        rx='1'
        fill={color ? getIconColorSVGFill(color) : 'currentColor'}
      />
      <circle
        cx='14.7334'
        cy='16'
        r='2'
        stroke={color ? getIconColorSVGFill(color) : 'currentColor'}
        strokeWidth='2'
      />
      <rect
        width='4'
        height='2'
        rx='1'
        transform='matrix(-1 0 0 1 8.7334 6)'
        fill={color ? getIconColorSVGFill(color) : 'currentColor'}
      />
      <rect
        width='5'
        height='2'
        rx='1'
        transform='matrix(-1 0 0 1 20.7334 6)'
        fill={color ? getIconColorSVGFill(color) : 'currentColor'}
      />
      <circle
        cx='3'
        cy='3'
        r='2'
        transform='matrix(-1 0 0 1 13.7334 4)'
        stroke={color ? getIconColorSVGFill(color) : 'currentColor'}
        strokeWidth='2'
      />
    </svg>
  )
}
