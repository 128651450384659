import './filterDropdownContent.styles.scss'
import { RawTreeNode } from 'tr33'
import { FilterValue, FocusedFilter } from 'interfaces'
import { Button, Spacer } from 'components'
import { ChevronLeft } from '@assets/icons'
import { FilterOption } from '../filtersOption/filtersOption'
import { t } from 'i18next'

export const FiltersCheckboxOptions = ({
  filter,
  focusedFilterRootId,
  focusedFilter,
  onClose,
  onGoBack,
  onReset,
  focusFilter,
  toggleFilter,
}: {
  filter: RawTreeNode<FilterValue>
  focusedFilterRootId: string
  focusedFilter: FocusedFilter
  onClose: () => void
  onGoBack: () => void
  onReset: () => void
  focusFilter: (id: string) => void
  toggleFilter: (id: string) => void
}): JSX.Element => {
  const optionsToRender =
    focusedFilterRootId !== filter?.id
      ? filter?.children
      : focusedFilter?.options

  const filtersOptionsClasses = focusedFilter?.isRoot
    ? filter?.children?.length <= 6
      ? '--two-columns'
      : '--three-columns'
    : ''

  return (
    <>
      <div className={`filters-dropdown__grid ${filtersOptionsClasses}`}>
        {optionsToRender?.map((f: RawTreeNode<FilterValue>) => (
          <FilterOption
            key={`${f.id}-${f?.value?.name}`}
            checked={
              f?.children?.length
                ? filter?.children?.some((c) => c?.value?.checked)
                : f?.value?.checked
            }
            logo={f?.value?.logo}
            name={f?.value?.name}
            width={f?.value?.level > 1 ? 'fluid' : 'fixed'}
            animateFadeIn={f?.value?.level > 1}
            onClick={() => {
              if (f?.value?.hasChildren) {
                focusFilter(f?.id)
              } else {
                toggleFilter(f.id)
              }
            }}
            hasChildren={f?.value?.hasChildren}
          />
        ))}
      </div>
      <div
        className={`filters-dropdown__filters-footer w-100 d-flex align-center ${
          !focusedFilter?.isRoot ? '--with-go-back' : ''
        }`}
      >
        {!focusedFilter?.isRoot && (
          <>
            <Button
              buttonType='secondary'
              shape='square'
              size='small'
              autoWidth
              onClick={onGoBack}
              left={<ChevronLeft size={32} color='iconPrimaryInitial' />}
            />
            <Spacer preset='small' />
          </>
        )}
        <div className='filters-dropdown__filters-footer-buttons d-flex'>
          <Button
            autoWidth
            text={t('common.reset')}
            size='tiny'
            textPreset='paragraph2'
            buttonType='ghost'
            onClick={onReset}
          />
          <Spacer preset='smaller' />
          <Button
            autoWidth
            text={t('common.showResults')}
            size='tiny'
            textPreset='paragraph2'
            onClick={onClose}
          />
        </div>
      </div>
    </>
  )
}
