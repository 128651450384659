import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { BankCashoutData, getPortfolioBalance, withdrawMoney } from '@services'
import { PortfolioBalance } from '@interfaces'

export const clearCashoutResult = createAction('clearCashoutResult')

export const fetchCashout = createAsyncThunk<void, BankCashoutData>(
  'fetchCashout',
  async (data: BankCashoutData) => {
    await withdrawMoney(data)
  },
)
export const fetchPortfolioBalance = createAsyncThunk<PortfolioBalance>(
  'fetchPortfolioBalance',
  () => getPortfolioBalance(),
)
