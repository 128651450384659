import { Button, CustomModal, Text } from '@components'
import { useTranslation } from '@hooks'

export const LowLiquidityInfoModal = ({
  symbol,
  close,
}: {
  symbol: string
  close: () => void
}): JSX.Element => {
  const { t } = useTranslation()

  return (
    <CustomModal
      size='small'
      show={true}
      onClose={close}
      dismissable
      theme='lighter'
    >
      <CustomModal.Header text={t('companyDetails.isLowLiquidityTitle')} />
      <CustomModal.Content>
        <div className='d-flex d-flex-col w-100'>
          <Text
            align='center'
            preset='paragraph2'
            text={t('companyDetails.isLowLiquidityDesc', {
              asset: symbol,
            })}
          />
        </div>
      </CustomModal.Content>
      <CustomModal.Footer>
        <Button onClick={() => close()} text={t('common.close')} />
      </CustomModal.Footer>
    </CustomModal>
  )
}
