import { createReducer } from '@reduxjs/toolkit'
import { BankTransferInfo, ReducerState } from '@interfaces'
import { clearSepaConditions, fetchSepaConditions } from './thunk'

const initialState: ReducerState & BankTransferInfo = {
  loading: false,
  error: null,
  destinationIban: '',
  destinationName: '',
  destinationConcept: '',
}

const reducers = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchSepaConditions.pending, (acc) => {
      acc.loading = true
      acc.error = null
    })
    .addCase(fetchSepaConditions.fulfilled, (acc, { payload }) => {
      acc.loading = false
      acc.error = null
      acc.destinationIban = payload.destinationIban
      acc.destinationName = payload.destinationName
      acc.destinationConcept = payload.destinationConcept
    })
    .addCase(fetchSepaConditions.rejected, (acc, { error }) => {
      acc.loading = false
      acc.error = error.message || ''
    })
    .addCase(clearSepaConditions, (acc) => {
      acc.loading = false
      acc.error = null
      acc.destinationIban = ''
      acc.destinationName = ''
      acc.destinationConcept = ''
    })
})

export default reducers
