import { UIIcon, UIIconColorProps, getIconColorSVGFill } from 'components/utils'

export const HelpIcon = ({
  color,
  size = 24,
}: UIIcon & UIIconColorProps): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 10C0 4.48 4.48 0 10 0C15.52 0 20 4.48 20 10C20 15.52 15.52 20 10 20C4.48 20 0 15.52 0 10ZM2 10C2 14.41 5.59 18 10 18C14.41 18 18 14.41 18 10C18 5.59 14.41 2 10 2C5.59 2 2 5.59 2 10ZM11 14V16H9V14H11ZM6.18 6.83C6.73 5.01 8.55 3.74 10.61 4.04C12.35 4.3 13.72 5.77 13.96 7.51C14.1881 9.12354 13.2957 9.9021 12.4344 10.6535C12.2762 10.7916 12.119 10.9288 11.97 11.07C11.85 11.18 11.74 11.29 11.64 11.41C11.635 11.415 11.63 11.4225 11.625 11.43C11.62 11.4375 11.615 11.445 11.61 11.45C11.51 11.57 11.41 11.7 11.33 11.84C11.32 11.855 11.3125 11.87 11.305 11.885C11.2975 11.9 11.29 11.915 11.28 11.93C11.11 12.23 11 12.58 11 13H9C9 12.5 9.08 12.09 9.2 11.75C9.20438 11.7368 9.20685 11.7256 9.20907 11.7155C9.21192 11.7025 9.21438 11.6912 9.22 11.68C9.225 11.665 9.2325 11.6525 9.24 11.64C9.2475 11.6275 9.255 11.615 9.26 11.6C9.33 11.42 9.42 11.25 9.51 11.1C9.515 11.09 9.52 11.0825 9.525 11.075C9.53 11.0675 9.535 11.06 9.54 11.05C9.54 11.045 9.5425 11.0425 9.545 11.04C9.5475 11.0375 9.55 11.035 9.55 11.03C9.89058 10.5171 10.3473 10.1658 10.7745 9.83728C11.3883 9.3652 11.941 8.94014 12 8.15C12.08 7.18 11.38 6.25 10.43 6.05C9.4 5.83 8.45 6.44 8.13 7.33C7.99 7.71 7.66 8 7.25 8H7.05C6.44 8 6 7.41 6.18 6.83Z'
        fill={color ? getIconColorSVGFill(color) : 'currentColor'}
      />
    </svg>
  )
}
