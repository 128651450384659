import { Card, Spacer } from 'components'
import { PortfolioItem } from 'features'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import './portfoliosList.styles.scss'

type PortfolioList = {
  selectedPortfolio: number | null
  setSelectedPortfolio: (id: number) => void
}
export const PortfoliosList = ({
  selectedPortfolio,
  setSelectedPortfolio,
}: PortfolioList): JSX.Element => {
  const { investments } = useSelector((state: RootState) => state)

  return (
    <>
      {investments.portfolios.map((portfolio, idx) => {
        return portfolio.positions?.length ? (
          <React.Fragment key={`positions-summary-I-${portfolio.id}-${idx}`}>
            <Card padding='none' bgColor='transparent' width='full'>
              <div className='cursor-pointer'>
                <PortfolioItem
                  {...portfolio}
                  length={portfolio.positions?.length}
                  togglable
                  focused={selectedPortfolio === idx}
                  onPress={() => {
                    setSelectedPortfolio(idx)
                  }}
                />
              </div>
            </Card>
            <Spacer preset='tinyPlus' />
          </React.Fragment>
        ) : null
      })}
    </>
  )
}
