import './watchlistItem.styles.scss'
import { CompanyStocks } from '@interfaces'
import { StockPerformance } from '@features'
import { useDispatch } from 'react-redux'
import { BinIcon } from '@assets/svg'
import {
  removeItemFromWatchlist,
  unsetWatchlistStockState,
} from '@screens/watchlist'
import { ReactComponent as UpIcon } from '@assets/svg/stocks_up.svg'
import { Button } from '@components'
import { trackingService } from '@services'
import { CompanyDetailsEvents } from '@utils/eventTracker/eventKeys'
import { useNavigate } from '@hooks'

export function WatchlistItem({
  companyStocks: {
    logoUrl,
    id,
    price,
    ticker,
    increaseMoney,
    increasePercentage,
  },
  onBuy,
}: {
  companyStocks: CompanyStocks
  onBuy: (id: string) => void
}): JSX.Element {
  const dispatch = useDispatch()
  const { navigate } = useNavigate()

  const linkTo = `/company/${id}`

  const onDelete = (id: string): void => {
    dispatch(removeItemFromWatchlist(id))
    dispatch(unsetWatchlistStockState(id))
  }

  const handleViewCompanyDetail = (): void => {
    trackingService.trackEvent({
      event: CompanyDetailsEvents.opened,
      props: {
        id,
        ticker,
        origin: 'watchlist-home',
      },
    })
    navigate(linkTo)
  }

  return (
    <div
      className='cursor-pointer d-flex d-flex-row watchlistItem justify-start'
      onClick={handleViewCompanyDetail}
      aria-hidden
    >
      <div className='d-flex d-flex-col w-100 mr-4'>
        <div className='d-flex d-flex-row mb-1'>
          <div className='watchlistItem__company d-flex justify-center align-center'>
            <img
              className='border-radius-circle payments__icons mr-1'
              src={logoUrl}
              alt={ticker}
            />
          </div>
          <div className='d-flex-col ml-1 justify-start'>
            <p className='paragraph3 tertiary--text'>{ticker}</p>
            <div className='d-flex d-flex-row'>
              <p className='paragraph2 primary--text pr-1'>{price?.amount}</p>
              <p className='paragraph2 primary--text'>{price?.currency}</p>
            </div>
          </div>
        </div>
        <StockPerformance
          className='paragraph3'
          performanceAmount={increaseMoney}
          performancePercentage={increasePercentage}
          noWrap={true}
          plusSymbol={true}
        />
      </div>

      <div className='d-flex d-flex-col align-center watchlistItem__wrapperButton '>
        <Button
          loading={false}
          onClick={() => onBuy(id)}
          type='button'
          autoWidth={true}
          textPreset='subtitle2'
          size='tiny'
          shape='square'
          content={<UpIcon />}
        />
        <div className='mt-1'>
          <Button
            loading={false}
            onClick={() => onDelete(id)}
            type='button'
            autoWidth={true}
            buttonType='dark--negative'
            size='tiny'
            shape='square'
            content={<BinIcon className='icon--smaller' />}
          />
        </div>
      </div>
    </div>
  )
}
