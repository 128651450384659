import './changePaymentMethod.style.scss'
import { useTranslation } from 'react-i18next'
import { Avatar, Button, CustomModal, Divider, InputRadio } from '@components'
import { PaymentMethod, PaymentMethodTypeEnum } from '@interfaces'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@store'
import { setSelectedPaymentMethod } from 'features/moneyOperations/topup/thunk'
import { ReactComponent as AddIcon } from '@assets/svg/add_circle.svg'
import { BankIcon } from '@assets/svg'

export function ChangePaymentMethod(props: {
  show: boolean
  onClose: () => void
  onTransfer: () => void
  methods: PaymentMethod[]
}): JSX.Element {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { addMoney } = useSelector((state: RootState) => state)

  const handleChange = (selectedPaymentMethod: PaymentMethod): void => {
    dispatch(setSelectedPaymentMethod(selectedPaymentMethod))
    props.onClose()
  }

  const getPaymentTypeSubtitle = (el: PaymentMethod): string => {
    switch (el?.type) {
      case PaymentMethodTypeEnum.BANK:
        return t('paymentMethods.bank')
      case PaymentMethodTypeEnum.CARD:
        return t('paymentMethods.card')
      default:
        return ''
    }
  }

  return (
    <CustomModal
      show={props.show}
      theme='lighter'
      size='big'
      dismissable
      onClose={props?.onClose}
    >
      <CustomModal.Header
        linkText={t('common.cancel')}
        text={t('addMoney.changePaymentMethod')}
        onClick={props.onClose}
      />
      <Divider />
      <CustomModal.Content className='d-flex-col changePayment'>
        <div className='d-flex d-flex-col bg-secondary-base w-100 align-self-start'>
          {props?.methods?.map((el, i) => {
            return (
              <InputRadio
                key={`bank_${i}`}
                containerStyle='infoCard__content__item changePayment__item paragraph2'
                defaultChecked={addMoney.selectedPaymentMethod?.id === el.id}
                onChange={() => handleChange(el)}
                id={`bank_${i}`}
                value={el.id}
                name='account'
                textContainerStyle='flex d-flex-row'
              >
                {el.type === PaymentMethodTypeEnum.MANUAL_TRANSFER ? (
                  <label htmlFor={`bank_${i}`} className='d-flex align-center'>
                    <div className='logo mr-2'>
                      <BankIcon className='black' />
                    </div>
                    <div className='payments__screen__item__content__titles text-left'>
                      <p>
                        {el.type === PaymentMethodTypeEnum.MANUAL_TRANSFER
                          ? t('paymentMethods.manualTransfer')
                          : getPaymentTypeSubtitle(el)}
                      </p>
                      <small>
                        {`${el.manualTransferDetails?.bankName} ${el.manualTransferDetails.accountNumber}`}
                      </small>
                    </div>
                  </label>
                ) : (
                  <div className='w-100 d-flex align-center'>
                    <Avatar image={el?.iconUrl} roundness='full' size='large' />
                    <div className='infoCard__content__item__label w-50 mr-1 text-left ml-2'>
                      <span className='paragraph2'>
                        <span className='primary--text'>{`${el.name}`}</span>
                        <p className='secondary--text'>
                          {getPaymentTypeSubtitle(el)}
                        </p>
                      </span>
                    </div>
                  </div>
                )}
              </InputRadio>
            )
          })}
        </div>
      </CustomModal.Content>
      <CustomModal.Footer className='footer_left_padding_0'>
        <div className='w-100'>
          <Button
            text={t('addMoney.selectNewPaymentMethod')}
            onClick={props.onTransfer}
            buttonType='link'
            justifyContent='flex-start'
            size='tiny'
            textPreset='caption'
            left={<AddIcon className='mr-1' />}
          />
        </div>
      </CustomModal.Footer>
    </CustomModal>
  )
}
