import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import {
  AssetsInfo,
  AssetType,
  CompanyLiquidity,
  GraphButton,
  GraphButtons,
  PeriodEnum,
  ReducerState,
  StockWatchlistState,
} from '@interfaces'
import {
  clearWatchlistState,
  fetchAssetLiquidity,
  fetchCompanyStocks,
  fetchWatchlistStockState,
  setPeriod,
  setWatchlistStockState,
  unsetWatchlistStock,
  updateCompanyStocks,
  updateGraph,
} from './thunk'

const btnSwitcher = (
  buttons: GraphButton[],
  period: PeriodEnum,
): GraphButton[] =>
  buttons.map((btn) => ({ ...btn, active: btn.period === period }))

const defaultButtons = [
  { label: '1D', active: true, period: PeriodEnum.day },
  { label: '7D', active: false, period: PeriodEnum.week },
  { label: '1M', active: false, period: PeriodEnum.month },
  { label: '1Y', active: false, period: PeriodEnum.year },
  { label: 'MAX', active: false, period: PeriodEnum.max },
]

export type CompanyStocksState = AssetsInfo &
  ReducerState &
  GraphButtons &
  StockWatchlistState

const initialState: CompanyStocksState = {
  id: '',
  companyName: '',
  price: { amount: 0, currency: 'USD' },
  increasePercentage: 0,
  increaseMoney: { amount: 0, currency: 'USD' },
  lastClosed: { amount: 0, currency: 'USD' },
  chart: [],
  loading: false,
  loadingChart: false,
  error: null,
  errorChart: null,
  marketStatus: null,
  symbol: '',
  buttons: defaultButtons,
  period: PeriodEnum.day,
  priceTime: undefined,
  kiidUrl: '',
  assetManager: null,
  type: AssetType.stock,
  logoUrl: '',
  isInWatchlist: false,
  isWatchlistStateLoading: false,
  errorWatchlist: null,
  market: null,
  isEnabled: false,
  isLowLiquidity: false,
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(setPeriod, (acc, { payload }) => {
      acc.period = payload

      acc.buttons = btnSwitcher(acc.buttons, payload)
    })
    .addCase(fetchCompanyStocks.pending, (acc) => {
      acc.loading = true
      acc.error = null
      acc.errorChart = null
    })
    .addCase(
      fetchCompanyStocks.fulfilled,
      (acc, { payload }: PayloadAction<AssetsInfo>) => {
        const {
          companyName,
          price,
          increasePercentage,
          increaseMoney,
          chart,
          assetManager,
          id,
          marketStatus,
          symbol,
          logoUrl,
          priceTime,
          lastClosed,
          kiidUrl,
          type,
          market,
          isEnabled,
        } = payload
        acc.id = id
        acc.companyName = companyName
        acc.price = price
        acc.symbol = symbol
        acc.increaseMoney = increaseMoney
        acc.increasePercentage = increasePercentage
        acc.chart = chart
        acc.assetManager = assetManager ? assetManager : null
        acc.loading = false
        acc.marketStatus = marketStatus
        acc.lastClosed = lastClosed
        acc.logoUrl = logoUrl
        acc.priceTime = priceTime
        acc.kiidUrl = kiidUrl
        acc.type = type
        acc.market = market
        acc.isEnabled = isEnabled
      },
    )
    .addCase(fetchCompanyStocks.rejected, (acc, { error }) => {
      acc.loading = false
      acc.error = error.message || ''
      acc.errorChart = null
    })
    .addCase(
      updateCompanyStocks.fulfilled,
      (acc, { payload }: PayloadAction<AssetsInfo>) => {
        const {
          companyName,
          price,
          increasePercentage,
          increaseMoney,
          chart,
          id,
          marketStatus,
          symbol,
          logoUrl,
          priceTime,
          isEnabled,
        } = payload
        acc.id = id
        acc.companyName = companyName
        acc.price = price
        acc.symbol = symbol
        acc.increaseMoney = increaseMoney
        acc.increasePercentage = increasePercentage
        acc.chart = chart
        acc.marketStatus = marketStatus
        acc.logoUrl = logoUrl
        acc.priceTime = priceTime
        acc.isEnabled = isEnabled
      },
    )
    .addCase(
      updateGraph.fulfilled,
      (acc, action: PayloadAction<AssetsInfo>) => {
        acc.loadingChart = false
        acc.errorChart = null
        const { price, increasePercentage, increaseMoney, chart, symbol } =
          action.payload
        acc.price = price
        acc.symbol = symbol
        acc.increaseMoney = increaseMoney
        acc.increasePercentage = increasePercentage
        acc.chart = chart
      },
    )
    .addCase(updateGraph.pending, (acc) => {
      acc.loadingChart = true
      acc.errorChart = null
    })
    .addCase(updateGraph.rejected, (acc, { error }) => {
      acc.errorChart = error.message
      acc.loadingChart = false
    })
    .addCase(
      fetchWatchlistStockState.fulfilled,
      (acc, action: PayloadAction<{ uuid: string; isFave?: boolean }>) => {
        const {
          payload: { isFave },
        } = action
        acc.isInWatchlist = isFave || false
        acc.isWatchlistStateLoading = false
        acc.errorWatchlist = null
      },
    )
    .addCase(fetchWatchlistStockState.pending, (acc) => {
      acc.isInWatchlist = false
      acc.isWatchlistStateLoading = true
      acc.errorWatchlist = null
    })
    .addCase(fetchWatchlistStockState.rejected, (acc, { error }) => {
      acc.isInWatchlist = false
      acc.isWatchlistStateLoading = false
      acc.errorWatchlist = error.message || ''
    })
    .addCase(setWatchlistStockState.fulfilled, (acc) => {
      acc.isInWatchlist = true
      acc.isWatchlistStateLoading = false
      acc.errorWatchlist = null
    })
    .addCase(setWatchlistStockState.pending, (acc) => {
      acc.isWatchlistStateLoading = true
      acc.errorWatchlist = null
    })
    .addCase(setWatchlistStockState.rejected, (acc, { error }) => {
      acc.isWatchlistStateLoading = false
      acc.errorWatchlist = error.message || ''
    })
    .addCase(unsetWatchlistStock.fulfilled, (acc) => {
      acc.isInWatchlist = false
      acc.isWatchlistStateLoading = false
      acc.errorWatchlist = null
    })
    .addCase(unsetWatchlistStock.pending, (acc) => {
      acc.isWatchlistStateLoading = true
      acc.errorWatchlist = null
    })
    .addCase(unsetWatchlistStock.rejected, (acc, { error }) => {
      acc.isWatchlistStateLoading = false
      acc.errorWatchlist = error.message || ''
    })
    .addCase(clearWatchlistState, (acc) => {
      acc.isInWatchlist = false
      acc.isWatchlistStateLoading = false
      acc.errorWatchlist = null
      acc.error = null
    })
    .addCase(
      fetchAssetLiquidity.fulfilled,
      (acc, action: PayloadAction<CompanyLiquidity>) => {
        const {
          payload: { isLowLiquidity },
        } = action
        acc.isLowLiquidity = isLowLiquidity
      },
    )
    .addCase(fetchAssetLiquidity.pending, (acc) => {
      acc.isLowLiquidity = false
    })
    .addCase(fetchAssetLiquidity.rejected, (acc) => {
      acc.isLowLiquidity = false
    }),
)
