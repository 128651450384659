import { ReactNode } from 'react'

export enum DropdownState {
  CLOSED = 'CLOSED',
  OPENED = 'OPENED',
  CLOSING = 'CLOSING',
}

export interface DropdownProps {
  button: ReactNode
  content: ReactNode
  onStateChange?: (v: DropdownState) => void
  closeOnSelection?: boolean
  forceDropdownState?: DropdownState
}
