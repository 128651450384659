import { UIIcon, UIIconColorProps, getIconColorSVGFill } from 'components/utils'

export const VectorDown = ({
  color,
  size = 24,
}: UIIcon & UIIconColorProps): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      transform='rotate(180 0 0)'
    >
      <path
        d='M3 10L8 5L13 10H3Z'
        fill={color ? getIconColorSVGFill(color) : 'currentColor'}
      />
    </svg>
  )
}
