/* eslint-disable sonarjs/no-nested-template-literals */
import { CheckIcon, CloseIcon } from '@assets/icons'
import { Button, CustomModal, Divider, Spacer, TabBar, Text } from '@components'
import { useTranslation } from 'hooks/useTranslation'
import { BrokerTierEnum } from 'interfaces'
import { ReactElement, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { typography } from './brokerTierFeesModal.constant'

export const BrokerTierFeesModal = ({
  close,
}: {
  close: () => void
}): JSX.Element => {
  const { t } = useTranslation()

  const { brokerTier } = useSelector((state: RootState) => state)
  const isPro = brokerTier?.tier === BrokerTierEnum.pro

  const [tab, setTab] = useState(
    isPro ? BrokerTierEnum.pro : BrokerTierEnum.lite,
  )
  const free = (
    <div className='d-flex'>
      <CheckIcon color='positive' /> <Spacer preset='tiny' />
      <Text preset='paragraph2' text={t('common.free')} />
    </div>
  )

  const textTheme = (text): ReactElement => {
    if (text === 'Free') {
      return free
    }
    return (
      <Text preset='paragraph2' align='right' color='positive' text={text} />
    )
  }

  type Table = {
    sectionColumn1: string
    sectionColumn2: string
    sectionColumn3: string
    row: {
      row1: string
      basic: { row2: ReactElement | string; row3: ReactElement | string }
      pro: { row2: ReactElement | string; row3: ReactElement | string }
    }[]
  }

  const features: Table[] = [
    {
      sectionColumn1: t(typography.northAmerica),
      sectionColumn2: t(typography.feesPerShare),
      sectionColumn3: t(typography.minFee),
      row: [
        {
          row1: t(typography.newYorkExchange),
          basic: {
            row2: textTheme(`0.02 ${t(typography.usd)}`),
            row3: textTheme(`4.00 ${t(typography.usd)}`),
          },
          pro: {
            row2: textTheme(`0.018 ${t(typography.usd)}`),
            row3: textTheme(`3.60 ${t(typography.usd)}`),
          },
        },
        {
          row1: t(typography.torontoStockExchange),
          basic: {
            row2: textTheme(`0.03 ${t(typography.cad)}`),
            row3: textTheme(`20.00 ${t(typography.cad)}`),
          },
          pro: {
            row2: textTheme(`0.027 ${t(typography.cad)}`),
            row3: textTheme(`18.00 ${t(typography.cad)}`),
          },
        },
      ],
    },
    {
      sectionColumn1: t(typography.restWorld),
      sectionColumn2: t(typography.feePerTransactionAmount),
      sectionColumn3: t(typography.minFee),
      row: [
        {
          row1: t(typography.budapestStockExchange),
          basic: {
            row2: textTheme('0.60%'),
            row3: textTheme(`1,000.00 ${t(typography.HUF)}`),
          },
          pro: {
            row2: textTheme('0.54%'),
            row3: textTheme(`900.00 ${t(typography.HUF)}`),
          },
        },
        {
          row1: t(typography.deutscheBörse),
          basic: {
            row2: textTheme('0.15%'),
            row3: textTheme(`12.00 ${t(typography.eur)}`),
          },
          pro: {
            row2: textTheme('0.14%'),
            row3: textTheme(`10.80 ${t(typography.eur)}`),
          },
        },
        {
          row1: t(typography.johannesburgStockExchanget),
          basic: {
            row2: textTheme('0.40%'),
            row3: textTheme(`120.00 ${t(typography.ZAR)}`),
          },
          pro: {
            row2: textTheme('0.36%'),
            row3: textTheme(`108.00 ${t(typography.ZAR)}`),
          },
        },
        {
          row1: t(typography.londonStockExchange),
          basic: {
            row2: textTheme('0.10%'),
            row3: textTheme(`8.00 ${t(typography.GBP)}`),
          },
          pro: {
            row2: textTheme('0.09%'),
            row3: textTheme(`7.20 ${t(typography.GBP)}`),
          },
        },
        {
          row1: t(typography.londonStockExchangeIOB),
          basic: {
            row2: textTheme('0.10%'),
            row3: textTheme(`20.00 ${t(typography.usd)}`),
          },
          pro: {
            row2: textTheme('0.09%'),
            row3: textTheme(`18.00 ${t(typography.usd)}`),
          },
        },
        {
          row1: t(typography.NASDAQ_OMX_Copenhagen),
          basic: {
            row2: textTheme('0.15%'),
            row3: textTheme(`80.00 ${t(typography.DKK)}`),
          },
          pro: {
            row2: textTheme('0.14%'),
            row3: textTheme(`72.00 ${t(typography.DKK)}`),
          },
        },
        {
          row1: t(typography.NASDAQ_OMX_Stockholm),
          basic: {
            row2: textTheme('0.15%'),
            row3: textTheme(`80.00 ${t(typography.SEK)}`),
          },
          pro: {
            row2: textTheme('0.14%'),
            row3: textTheme(`72.00 ${t(typography.SEK)}`),
          },
        },
        {
          row1: t(typography.NASDAQ_OMX_Helsinki),
          basic: {
            row2: textTheme('0.15%'),
            row3: textTheme(`12.00 ${t(typography.eur)}`),
          },
          pro: {
            row2: textTheme('0.14%'),
            row3: textTheme(`10.80 ${t(typography.eur)}`),
          },
        },
        {
          row1: t(typography.osloStockExchange),
          basic: {
            row2: textTheme('0.15%'),
            row3: textTheme(`80.00 ${t(typography.NOK)}`),
          },
          pro: {
            row2: textTheme('0.14%'),
            row3: textTheme(`72.00 ${t(typography.NOK)}`),
          },
        },
        {
          row1: t(typography.pragueStockExchange),
          basic: {
            row2: textTheme('0.35%'),
            row3: textTheme(`500.00 ${t(typography.CZK)}`),
          },
          pro: {
            row2: textTheme('0.32%'),
            row3: textTheme(`450.00 ${t(typography.CZK)}`),
          },
        },
        {
          row1: t(typography.SIX_Swiss_Exchange),
          basic: {
            row2: textTheme('0.15%'),
            row3: textTheme(`15.00 ${t(typography.CHF)}`),
          },
          pro: {
            row2: textTheme('0.14%'),
            row3: textTheme(`13.50 ${t(typography.CHF)}`),
          },
        },
        {
          row1: t(typography.warsawStockExchange),
          basic: {
            row2: textTheme('0.35%'),
            row3: textTheme(`100.00 ${t(typography.PLN)}`),
          },
          pro: {
            row2: textTheme('0.32%'),
            row3: textTheme(`90.00 ${t(typography.PLN)}`),
          },
        },
        {
          row1: t(typography.australianSecuritiesExchange),
          basic: {
            row2: textTheme('0.15%'),
            row3: textTheme(`12.00 ${t(typography.AUD)}`),
          },
          pro: {
            row2: textTheme('0.14%'),
            row3: textTheme(`10.80 ${t(typography.AUD)}`),
          },
        },
        {
          row1: t(typography.hongKongExchanges),
          basic: {
            row2: textTheme('0.35%'),
            row3: textTheme(`120.00 ${t(typography.HKD)}`),
          },
          pro: {
            row2: textTheme('0.32%'),
            row3: textTheme(`108.00 ${t(typography.HKD)}`),
          },
        },
        {
          row1: t(typography.singaporeExchange),
          basic: {
            row2: textTheme('0.30%'),
            row3: textTheme(`40.00 ${t(typography.SGD)}`),
          },
          pro: {
            row2: textTheme('0.27%'),
            row3: textTheme(`36.00 ${t(typography.SGD)}`),
          },
        },
        {
          row1: t(typography.tokyoStockExchange),
          basic: {
            row2: textTheme('0.20%'),
            row3: textTheme(`1,500.00 ${t(typography.JPY)}`),
          },
          pro: {
            row2: textTheme('0.18%'),
            row3: textTheme(`1,350.00 ${t(typography.JPY)}`),
          },
        },
      ],
    },
    {
      sectionColumn1: t(typography.safekeeping),
      sectionColumn2: t(typography.monthly),
      sectionColumn3: t(typography.yearly),
      row: [
        {
          row1: t(typography.foreign),
          basic: {
            row2: textTheme(t(typography.foreignDetails)),
            row3: textTheme('0.12%'),
          },
          pro: {
            row2: textTheme(t(typography.foreignDetails)),
            row3: textTheme('0.12%'),
          },
        },
        {
          row1: t(typography.local),
          basic: { row2: textTheme('Free'), row3: textTheme('Free') },
          pro: { row2: textTheme('Free'), row3: textTheme('Free') },
        },
      ],
    },
    {
      sectionColumn1: t(typography.exchangeRates),
      sectionColumn2: '',
      sectionColumn3: '',
      row: [
        {
          row1: t(typography.currencyExchange),
          basic: {
            row2: '',
            row3: textTheme(t(typography.exchangeRatesDetails)),
          },
          pro: {
            row2: '',
            row3: textTheme(t(typography.exchangeRatesDetails)),
          },
        },
      ],
    },
  ]

  return (
    <CustomModal
      size='big'
      height='fullHeight'
      show
      placeOn='right'
      dismissable
      onClose={close}
    >
      <CustomModal.Header noBorder text={t('common.applicableFees')}>
        <Button
          onClick={() => close()}
          buttonType='link'
          autoWidth
          content={<CloseIcon color='iconPrimaryInitial' size={16} />}
          shape='square'
        />
      </CustomModal.Header>
      <Spacer preset='small' />
      <TabBar
        fullWidthTabs
        tabs={[
          {
            id: BrokerTierEnum.pro,
            title: t('common.pro'),
          },
          {
            id: BrokerTierEnum.lite,
            title: t('common.basic'),
          },
        ]}
        activeTab={tab}
        setActiveTab={(t) => setTab(t as BrokerTierEnum)}
      />
      <Divider />
      <CustomModal.Content>
        {features.map((table, tableIndex) => (
          <div className='mb-1 w-100' key={tableIndex}>
            <div className='w-100'>
              <Spacer preset='smaller' />
              <div className='d-flex justify-space-between'>
                <div className='w-55'>
                  <Text
                    text={table.sectionColumn1}
                    preset='bigText'
                    color='primary'
                  />
                </div>
                <div className='w-25'>
                  <Text
                    align='right'
                    text={table.sectionColumn2}
                    preset='bodyRegular'
                    color='primary'
                  />
                </div>
                <div className='w-25'>
                  <Text
                    align='right'
                    text={table.sectionColumn3}
                    preset='bodyRegular'
                    color='primary'
                  />
                </div>
              </div>
              <Spacer preset='smaller' />
              <Divider />
            </div>
            {table.row.map((rowItem, rowIndex) => (
              <div key={rowIndex}>
                <Spacer preset='smaller' />
                <div className='d-flex justify-space-between'>
                  <div className='w-50'>
                    <Text
                      text={rowItem.row1}
                      preset='paragraph2'
                      color='secondary'
                    />
                  </div>

                  {rowItem.basic.row2 && (
                    <div className='w-25 d-flex justify-end'>
                      {BrokerTierEnum.pro === tab
                        ? rowItem.pro.row2
                        : rowItem.basic.row2}
                    </div>
                  )}

                  <div
                    className={`${
                      !rowItem.basic.row2 ? 'w-50' : 'w-25'
                    } d-flex justify-end`}
                  >
                    {BrokerTierEnum.pro === tab
                      ? rowItem.pro.row3
                      : rowItem.basic.row3}
                  </div>
                </div>
                <Spacer preset='smaller' />
                <Divider />
              </div>
            ))}
          </div>
        ))}
      </CustomModal.Content>
    </CustomModal>
  )
}
