import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { getBankConnectionLink } from '@services'
import { AggregationAuthLink, BankConnection } from '@interfaces'

export const clearAggregationLink = createAction('clearAggregationLink')

export const requestAggregationAuthLink = createAsyncThunk<
  AggregationAuthLink,
  BankConnection
>('requestAggregationAuthLink', async (data: BankConnection) => {
  return getBankConnectionLink(data)
})
