import React, { useEffect, useRef } from 'react'
import './tradeViewChartContainer.styles.scss'
import {
  widget,
  ChartingLibraryWidgetOptions,
  TickMarkType,
} from '../../../charting_library'
import { datafeedNew } from '../datafeed/datafeed'
import { getTimeFrame } from '../datafeed/datafeed.helper'
import { TVChartContainerProps } from './tradeViewChartContainer.props'
import { getLanguageFromURL } from './tradeViewChartContainer.helper'
import {
  defaultProps,
  tradeViewColorTheme,
} from './tradeViewChartContainer.constant'
import { ThemeName } from '../../../charting_library'

export const TradeViewChartContainer = ({
  chartData,
  symbolInfo,
  disabled_features,
  candlesticksEnabled = false,
}: TVChartContainerProps): React.JSX.Element => {
  const chartContainerRef =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>
  const mode = document
    .getElementsByTagName('body')[0]
    .getAttribute('data-theme')
  const theme = tradeViewColorTheme[mode]

  const customFormatter = (date: Date, tickMarkType: TickMarkType): string => {
    switch (tickMarkType) {
      case 'Year':
        return date.toLocaleDateString('default', {
          year: 'numeric',
        })

      case 'Month':
      case 'DayOfMonth':
        return `${date.toLocaleString('default', {
          day: '2-digit',
        })} ${date.toLocaleString('default', {
          month: 'short',
        })} ${date.toLocaleString('default', { year: '2-digit' })}`

      case 'Time':
        return date.getUTCHours() + ':' + date.getUTCMinutes()

      case 'TimeWithSeconds':
        return (
          date.getUTCHours() +
          ':' +
          date.getUTCMinutes() +
          ':' +
          date.getUTCSeconds()
        )
    }

    throw new Error('unhandled tick mark type ' + tickMarkType)
  }
  useEffect(() => {
    const widgetOptions: ChartingLibraryWidgetOptions = {
      symbol: defaultProps.symbol as string,
      datafeed: datafeedNew(chartData, symbolInfo),
      theme: mode as ThemeName,
      interval:
        defaultProps.interval as ChartingLibraryWidgetOptions['interval'],
      container: chartContainerRef.current,
      library_path: defaultProps.libraryPath as string,
      timeframe: getTimeFrame(chartData),
      locale: getLanguageFromURL() || 'en',
      disabled_features: disabled_features,
      enabled_features: [
        'fix_left_edge',
        'lock_visible_time_range_when_adjusting_percentage_right_margin',
        'use_last_visible_bar_value_in_legend',
        'determine_first_data_request_size_using_visible_range',
      ],
      charts_storage_url: defaultProps.chartsStorageUrl,
      charts_storage_api_version: defaultProps.chartsStorageApiVersion,
      client_id: defaultProps.clientId,
      user_id: defaultProps.userId,
      fullscreen: defaultProps.fullscreen,
      autosize: defaultProps.autosize,
      studies_overrides: {
        ...defaultProps.studiesOverrides,
        'volume.volume.color.0': theme.volumeBarColor,
        'volume.volume.color.1': theme.volumeBarColor,
      },
      overrides: {
        'paneProperties.background': theme.backgroundColor,
        'paneProperties.backgroundType': 'solid',
        'mainSeriesProperties.style': candlesticksEnabled ? 1 : 2,
        'paneProperties.crossHairProperties.style': 1, // Dotted
        'paneProperties.crossHairProperties.color': theme.crossHairColor,
        'scalesProperties.crosshairLabelBgColorLight': theme.crossHairBgColor,
        'scalesProperties.crosshairLabelBgColorDark': theme.crossHairBgColor,
      },
      loading_screen: {
        backgroundColor: theme.backgroundColor,
        foregroundColor: theme.color,
      },
      custom_css_url: 'custom.css',
      custom_formatters:
        symbolInfo.symbol === 'Portfolio'
          ? {
              tickMarkFormatter: customFormatter,
            }
          : undefined,
    }

    const tvWidget = new widget(widgetOptions)

    tvWidget.onChartReady(async () => {
      const originalRange = getTimeFrame(chartData)
      await tvWidget.chart().setVisibleRange(originalRange)
      tvWidget
        .chart()
        .onVisibleRangeChanged()
        .subscribe(null, function (range) {
          if (range.to > originalRange.to) {
            tvWidget.chart().getTimeScale().setRightOffset(0)
          }
        })
      await tvWidget.chart().setVisibleRange(getTimeFrame(chartData))
      await tvWidget.changeTheme(mode as ThemeName)
      tvWidget.applyOverrides({
        'paneProperties.background': theme.backgroundColor,
        'scalesProperties.textColor': theme.textColor,
        'paneProperties.horzGridProperties.color': theme.backgroundColor,
        'paneProperties.vertGridProperties.color': theme.backgroundColor,
        'paneProperties.backgroundType': 'solid',
        'mainSeriesProperties.style': candlesticksEnabled ? 1 : 2,
        'mainSeriesProperties.showPriceLine': true,
        'mainSeriesProperties.priceLineWidth': 1,
        'mainSeriesProperties.priceLineColor': candlesticksEnabled
          ? ''
          : theme.color,
      })

      if (candlesticksEnabled) {
        tvWidget.chart().getSeries().setChartStyleProperties(1, {
          upColor: theme.barUpColor,
          borderUpColor: theme.barUpColor,
          wickUpColor: theme.barUpColor,
          downColor: theme.barDownColor,
          borderDownColor: theme.barDownColor,
          wickDownColor: theme.barDownColor,
        })
      } else {
        tvWidget.chart().getSeries().setChartStyleProperties(2, {
          color: theme.color,
        })
      }
    })
    return () => {
      tvWidget.remove()
    }
  }, [
    mode,
    theme,
    chartData,
    symbolInfo,
    disabled_features,
    candlesticksEnabled,
  ])

  return <div ref={chartContainerRef} className={'TVChartContainer'} />
}
